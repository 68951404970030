// libs
import { css, useTheme } from 'styled-components';
import { GatewayPriorityContainer, ValueContainer } from './styled';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { useSelector } from 'react-redux';

// selectors
import { selectAppDetailCurrentPolicyGatewayPriority } from 'redux/selectors';
import { CustomText } from 'typography/Text';
import { IPolicyGatewayPriorityType } from 'types';

const GatewayTranslationName = (type: IPolicyGatewayPriorityType) => {
  switch (type) {
    case 'Balanced':
      return 'balanced';
    case 'Ordered':
      return 'ordered';
    case 'Geographic':
      return 'geographic';
    default:
      return '';
  }
};

export const GatewayPriorityView = () => {
  const { translate } = useTranslations('policies');

  const {
    palette: { fonts },
  } = useTheme();

  const gatewayPriority = useSelector(selectAppDetailCurrentPolicyGatewayPriority);

  return (
    <GatewayPriorityContainer>
      <ValueContainer>
        <CustomText type="body-2" color={fonts.bodyLight}>
          {translate('details.gateway_priority.title')}
        </CustomText>

        <CustomText
          type="body-2"
          color={fonts.bodyDark}
          css={css`
            margin-left: 2rem;
          `}
        >
          {translate(`details.gateway_priority.${GatewayTranslationName(gatewayPriority)}.name`)}
        </CustomText>
      </ValueContainer>
      <CustomText
        type="hint"
        color={fonts.bodyLight}
        css={css`
          white-space: pre-line;
          display: block;
          margin-top: 0.25rem;
          margin-left: 9.75rem;
        `}
      >
        {translate(
          `details.gateway_priority.${GatewayTranslationName(gatewayPriority)}.description`
        )}
      </CustomText>
    </GatewayPriorityContainer>
  );
};
