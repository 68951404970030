// components
import { Tags } from './Tags';
import { Systems } from './Systems';
import { Content, ITargetsTabs } from '../Content';

// hooks
import { useSelector } from 'react-redux';

// selectors
import {
  selectAppDetailsDnsAutoGenerated,
  selectAppDetailsDnsNewSystemsAmount,
  selectAppDetailsDnsNewTagsAmount,
} from 'redux/selectors';

interface ITargetsEdit {
  selectedTab: ITargetsTabs;
  setSelectedTab: (tab: ITargetsTabs) => void;
}

export const TargetsEdit = ({ selectedTab, setSelectedTab }: ITargetsEdit) => {
  const systemsAmount = useSelector(selectAppDetailsDnsNewSystemsAmount);
  const tagsAmount = useSelector(selectAppDetailsDnsNewTagsAmount);
  const autoGenerated = useSelector(selectAppDetailsDnsAutoGenerated);

  return (
    <Content
      edit={true}
      isAutoGenerated={autoGenerated || false}
      withEmptyState={false}
      selectedTab={selectedTab}
      setSelectedTab={setSelectedTab}
      systemsAmount={systemsAmount || 0}
      tagsAmount={tagsAmount || 0}
      tagsContent={<Tags />}
      systemsContent={<Systems />}
    />
  );
};
