// libs
import { memo, SyntheticEvent, useCallback, useMemo } from 'react';
import dayjs from 'dayjs';

// components
import Skeleton from 'react-loading-skeleton';
import { ActionDropdown } from 'components';

// hooks
import { useDispatch } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';

// actions
import {
  disableEnrolmentKey,
  enableEnrolmentKey,
  enableUntilEnrolmentKey,
} from 'redux/actions/enrolment-keys';
import {
  loadEnrolmentKeyDetails,
  createEnrolmentKeySideMenu,
  setModalContent,
} from 'redux/actions/app';

// types
import { ISimpleEnrolmentKey } from 'types';

interface IActionDropdown {
  record: ISimpleEnrolmentKey;
  isLoading: boolean;
  setBulkSelectedKeys: (enrolmentKeys: ISimpleEnrolmentKey[]) => void;
}

export const EnrolmentKeyActionDropdown = memo(
  ({ isLoading, setBulkSelectedKeys, record }: IActionDropdown) => {
    const dispatch = useDispatch();

    const { translate } = useTranslations('enrolment-keys');
    const { translate: translateGlobal } = useTranslations('global');

    const enableUntilData: { text: string; value: () => dayjs.Dayjs | 'custom' }[] = useMemo(() => {
      return [
        {
          text: translateGlobal('action_dropdown_time.15_mins'),
          value: () => dayjs().add(15, 'minute'),
        } as { text: string; value: () => dayjs.Dayjs | 'custom' },
        {
          text: translateGlobal('action_dropdown_time.30_mins'),
          value: () => dayjs().add(30, 'minute'),
        },
        {
          text: translateGlobal('action_dropdown_time.1_hour'),
          value: () => dayjs().add(1, 'hour'),
        },
        {
          text: translateGlobal('action_dropdown_time.3_hours'),
          value: () => dayjs().add(3, 'hour'),
        },
        {
          text: translateGlobal('action_dropdown_time.8_hours'),
          value: () => dayjs().add(8, 'hour'),
        },
        {
          text: translateGlobal('action_dropdown_time.1_day'),
          value: () => dayjs().add(1, 'day'),
        },
        {
          text: translateGlobal('action_dropdown_time.5_days'),
          value: () => dayjs().add(5, 'day'),
        },
        {
          text: translateGlobal('action_dropdown_time.1_week'),
          value: () => dayjs().add(1, 'week'),
        },
        {
          text: translateGlobal('action_dropdown_time.1_month'),
          value: () => dayjs().add(1, 'month'),
        },
        {
          text: translateGlobal('action_dropdown_time.custom'),
          value: () => 'custom',
        },
      ];
    }, [translateGlobal]);

    const onEnableDisableClick = useCallback(() => {
      const action = record.isEnabled ? disableEnrolmentKey : enableEnrolmentKey;
      dispatch(
        action({
          enrolmentKeyId: String(record.id),
        })
      );

      setBulkSelectedKeys([]);
    }, [dispatch, record.id, record.isEnabled, setBulkSelectedKeys]);

    const onEnableUntilClick = useCallback(
      (value: () => dayjs.Dayjs | 'custom', text: string) => {
        const extract = value();

        if (extract === 'custom') {
          dispatch(
            loadEnrolmentKeyDetails({
              enrolmentKeyId: String(record.id),
              mode: 'edit',
              scrollToAutoExpire: true,
            })
          );
        } else {
          dispatch(
            enableUntilEnrolmentKey({
              enrolmentKeyId: String(record.id),
              text: text,
              action: record.isEnabled ? 'DisableAfter' : 'EnableFor',
              updatedParams: {
                expiryDateTime: extract.toISOString(),
                expiryAction: 'Disable',
                timeZoneId: null,
              },
            })
          );
        }
      },
      [dispatch, record.id, record.isEnabled]
    );

    const onEditClick = useCallback(() => {
      dispatch(loadEnrolmentKeyDetails({ enrolmentKeyId: String(record.id), mode: 'edit' }));
    }, [dispatch, record.id]);

    const onDuplicateClick = useCallback(() => {
      dispatch(createEnrolmentKeySideMenu({ enrolmentKeyId: String(record.id) }));
    }, [dispatch, record.id]);

    const onRemoveClick = useCallback(() => {
      dispatch(
        setModalContent({
          type: 'remove-enrolment-keys',
          data: {
            keys: [record],
          },
        })
      );
    }, [dispatch, record]);

    return (
      <>
        {isLoading ? (
          <Skeleton />
        ) : (
          <ActionDropdown
            data-test="enrolment-keys-table-action-dropdown-column"
            onContainerClick={(event: SyntheticEvent) => event.stopPropagation()}
            data={[
              {
                text: record.isEnabled
                  ? translate('table_actions.disable')
                  : translate('table_actions.enable'),
                onClick: onEnableDisableClick,
              },
              {
                isDropdown: true,
                text: record.isEnabled
                  ? translateGlobal('action_dropdown_time.disable_after')
                  : translateGlobal('action_dropdown_time.enable_for'),
                data: enableUntilData,
                onClick: onEnableUntilClick,
              },
              {
                text: translate('table_actions.edit'),
                onClick: onEditClick,
              },
              {
                text: translate('table_actions.duplicate'),
                onClick: onDuplicateClick,
              },
              {
                text: translate('table_actions.remove'),
                onClick: onRemoveClick,
              },
            ]}
          />
        )}
      </>
    );
  }
);
