// libs
import { css, useTheme } from 'styled-components';

// components
import { CustomText } from 'typography/Text';
import {
  AnnouncementGridItem,
  IconContainer,
  RightDirectionIcon,
  SystemsLinkA,
} from '../../styled';
import { ContentContainer, SectionIconContainer, ContentTopRowContainer } from './styled';
import { IconAnnouncementErrorTriangle, IconAnnouncementInfoRectangle } from 'assets/svg';

// hooks
import { useState } from 'react';
import { useTranslations } from 'hooks/useTranslations';

// constants
import bannerConfig from 'assets/configs/enclave/banner-config.json';

export const AnnouncementSection = () => {
  const {
    palette: { fonts },
  } = useTheme();

  const { translate } = useTranslations('landing-page');

  const [isActive, setIsActive] = useState<boolean>(false);

  const announcementConfig = bannerConfig?.dashboard?.announcement as {
    type?: 'info' | 'error';
    title?: string;
    description?: string;
    url?: string;
  };

  return (
    <>
      {announcementConfig && (
        <SystemsLinkA
          aria-label={translate('announcent_section.aria_labels.follow_link')}
          rel="noreferrer"
          target="_blank"
          href={announcementConfig?.url}
          onMouseEnter={() => setIsActive(true)}
          onMouseLeave={() => setIsActive(false)}
        >
          <AnnouncementGridItem>
            <ContentContainer>
              <ContentTopRowContainer>
                <SectionIconContainer>
                  {announcementConfig.type === 'info' && (
                    <IconAnnouncementInfoRectangle width="2.5rem" height="2.5rem" />
                  )}
                  {announcementConfig.type === 'error' && (
                    <IconAnnouncementErrorTriangle width="2.5rem" height="2.5rem" />
                  )}
                </SectionIconContainer>

                <CustomText
                  color={fonts.bodyDark}
                  type="sub-heading-1"
                  css={css`
                    margin-left: 1.125rem;
                    line-height: 2.5rem;
                  `}
                >
                  {announcementConfig?.title}
                </CustomText>
              </ContentTopRowContainer>
              <CustomText color={fonts.bodyLight} type="body-2">
                {announcementConfig?.description}
              </CustomText>
            </ContentContainer>
            <IconContainer $isActive={isActive}>
              <RightDirectionIcon $isActive={isActive} />
            </IconContainer>
          </AnnouncementGridItem>
        </SystemsLinkA>
      )}
    </>
  );
};
