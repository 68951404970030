import { css } from 'styled-components';

export const tableStyles = css`
  width: 100%;
  cursor: default;
  margin-bottom: 2rem;

  .ant-table-tbody > tr:hover > td {
    background: unset;
  }

  td:nth-child(1),
  th:nth-child(1) {
    width: 22.25%;
  }
  td:nth-child(2),
  th:nth-child(2) {
    width: 14.75%;
  }

  tbody tr {
    cursor: default;
  }
`;
