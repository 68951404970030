import { SyntheticEvent } from 'react';
import { css, CSSProp, useTheme } from 'styled-components';
import { Switch } from 'antd';
import { SwitchProps } from 'antd/lib/switch';
import { CustomText } from 'typography/Text';
import { StyledContainer } from './styled';
import { TextTypes } from 'types';

export interface IToggle extends Omit<SwitchProps, 'onClick'> {
  labelLeft?: boolean;
  enabled?: boolean;
  loading?: boolean;
  label?: JSX.Element | string;
  labelType?: TextTypes;
  onClick?: (event: SyntheticEvent) => void;
  containerCss?: CSSProp;
  labelCss?: CSSProp;
}

export const CustomToggle = ({
  enabled,
  label,
  onClick,
  labelLeft,
  loading = false,
  labelType = 'body-2',
  containerCss,
  labelCss,
  ...rest
}: IToggle) => {
  const {
    palette: { fonts },
  } = useTheme();

  const disabled = rest.disabled || loading;

  return (
    <StyledContainer onClick={!disabled ? onClick : undefined} css={containerCss}>
      {labelLeft && label && (
        <CustomText
          type={labelType}
          css={css`
            margin-right: 0.5rem;
          `}
          color={fonts.title}
        >
          {label}
        </CustomText>
      )}
      <Switch checked={enabled} disabled={disabled} loading={loading} {...rest} />

      {!labelLeft && label && (
        <CustomText type={labelType} color={fonts.title} css={labelCss}>
          {label}
        </CustomText>
      )}
    </StyledContainer>
  );
};
