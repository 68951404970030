import styled from 'styled-components';

export const StyledContainer = styled.div`
  width: fit-content;
  display: flex;
  cursor: pointer;

  .ant-switch-checked {
    background-color: ${({ theme }) => theme.palette.icons.primary};
  }
`;
