// libs
import { css } from 'styled-components';

// components
import { Details } from 'components';
import { ConditionsSection } from './styled';
import { IconTrustRequirementDetailsConditionsSection } from 'assets/svg';

// hooks
import { useTranslations } from 'hooks/useTranslations';

interface IWrapper {
  children: React.ReactNode;
}

export const Wrapper = ({ children }: IWrapper) => {
  const { translate } = useTranslations('trust-requirements');

  return (
    <ConditionsSection>
      <Details.SectionHeader
        Icon={IconTrustRequirementDetailsConditionsSection}
        title={translate('details.conditions')}
        css={css`
          margin-bottom: 0.5rem;
        `}
      />
      {children}
    </ConditionsSection>
  );
};
