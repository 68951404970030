// components
import { TagSelect } from 'components';

// hooks
import { useDispatch, useSelector } from 'react-redux';

// selectors
import { selectAppDetailsEnrolmentKeyTags } from 'redux/selectors';

// actions
import { updateEnrolmentKeyDetails } from 'redux/actions/app';

// utils
import { TagReference } from 'types';

export const AutoTagEdit = () => {
  const dispatch = useDispatch();

  const keyTags = useSelector(selectAppDetailsEnrolmentKeyTags);

  const handleTagUpdate = (selectedTags: TagReference[]) => {
    dispatch(
      updateEnrolmentKeyDetails({
        tags: selectedTags,
      })
    );
  };

  return <TagSelect preSelected={keyTags} changeCallback={handleTagUpdate} />;
};
