// libs
import { css, useTheme } from 'styled-components';

// components
import { IconForeignAuthGithub, IconForeignAuthGoogle, IconForeignAuthMicrosoft } from 'assets/svg';
import { CustomText, StyledSubtitle } from 'typography/Text';
import { Wrapper, Info, DescriptionRow } from './styled';
import { Row } from './Row';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { useSelector } from 'react-redux';

// selectors
import {
  selectAccountMemberSince,
  selectAccountEmail,
  selectAccountUserName,
} from 'redux/selectors';

// types
import { IForeignSignInForeignMethod } from 'types';

// utils
import { formatTime } from 'utils';

const ICONS_MAP: Record<IForeignSignInForeignMethod, JSX.Element> = {
  AzureAD: <IconForeignAuthMicrosoft />,
  Google: <IconForeignAuthGoogle />,
  Github: <IconForeignAuthGithub />,
};

interface IPersonalInfoBlockForeign {
  signInMethod: IForeignSignInForeignMethod;
}

export const PersonalInfoBlockForeign = ({ signInMethod }: IPersonalInfoBlockForeign) => {
  const { translate } = useTranslations('account');

  const {
    palette: { fonts },
  } = useTheme();

  const userName = useSelector(selectAccountUserName);
  const email = useSelector(selectAccountEmail);
  const memberSince = useSelector(selectAccountMemberSince);

  return (
    <Wrapper>
      <StyledSubtitle>{translate('personal_info')}</StyledSubtitle>
      <DescriptionRow>
        <span>{ICONS_MAP[signInMethod]}</span>

        <CustomText
          css={css`
            margin-left: 1rem;
          `}
          type="body-2"
          color={fonts.bodyLight}
        >
          {translate('personal_info_foreign_description', { ForeignAuth: signInMethod })}
        </CustomText>
      </DescriptionRow>
      <Info>
        <Row name={translate('user_name')} value={userName} />
        <Row name={translate('email_address')} value={email} />
        <Row
          name={translate('member_since')}
          value={formatTime({ date: memberSince, format: 'ddd, D MMM YYYY HH:mm GMT' }) || ''}
        />
      </Info>
    </Wrapper>
  );
};
