// libs
import { call, put } from 'typed-redux-saga';

import { setDetailsContent } from 'redux/actions/app';

// utils
import { ApiTypeCaller } from '../../api/utils';

// types
import { IActionPayload, ILoadDnsRecordDetails } from 'types';

// constants
import { DNS_RECORD_INITIAL_VALIDATION_STATE, ROUTE_PATHS } from 'appConstants';
import { orgPush } from 'redux/sagas/utils';

export function* loadRecordDetailsSaga({
  payload: { recordId, zoneId, mode, zoneName },
}: IActionPayload<ILoadDnsRecordDetails>) {
  if (mode === 'create-record') {
    yield put(
      setDetailsContent({
        type: 'dns-record-create',
        dataNew: {
          name: '',
          fqdn: '',
          zoneId,
          zoneName,
          notes: '',
          systems: [],
          tags: [],
          type: 'ENCLAVE',
        },
        validationState: DNS_RECORD_INITIAL_VALIDATION_STATE,
        isLoading: false,
      })
    );
    return;
  }

  const { result, success } = yield* ApiTypeCaller('getDnsRecordDetails', { recordId });

  if (success && result) {
    if (mode === 'view') {
      yield put(
        setDetailsContent({
          type: 'dns-record-view',
          data: result,
          isLoading: false,
        })
      );
    } else if (mode === 'edit') {
      yield put(
        setDetailsContent({
          type: 'dns-record-edit',
          data: result,
          dataNew: result,
          validationState: {},
          isLoading: false,
        })
      );
    }
  } else {
    yield call(orgPush, `${ROUTE_PATHS.dns}/zones/${zoneId}`);
  }
}
