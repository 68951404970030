import styled from 'styled-components';
import { IconDashboardSystems } from 'assets/svg';

export const StyledTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1.125rem;
  sup {
    top: 0;
    margin-right: 0.5rem;
  }
`;

export const SystemContentContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const StyledSystemsIcon = styled(IconDashboardSystems)`
  width: 2.5rem;
  height: 2.5rem;
  rect {
    fill: ${({ theme }) => theme.palette.icons.primary};
  }

  path:not(:nth-last-child(1)),
  path:not(:nth-last-child(2)) {
    fill: ${({ theme }) => theme.palette.background.panel};
  }

  path:nth-last-child(1),
  path:nth-last-child(2) {
    fill: ${({ theme }) => theme.palette.icons.primary};
  }
`;

export const SystemIconContainer = styled.div`
  display: flex;
`;
