// components
import { TitleBlock, UpgradePlanMessageBlock } from '../../Blocks';
import { Details } from 'components';

export const Header = () => {
  return (
    <Details.HeaderSection data-test="system-details-header" withExtraMarginTop={true}>
      <TitleBlock />
      <UpgradePlanMessageBlock />
    </Details.HeaderSection>
  );
};
