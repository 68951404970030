// components
import { IpRange } from './IpRange';
import { Title } from './Title';
import { Description } from './Description';
import { IsBlocked } from './IsBlocked';

// hooks
import { useTranslations } from 'hooks/useTranslations';

// types
import { ITrustRequirementPublicIpIpRangeConditionModified } from 'types';
import { ColumnsType } from 'antd/lib/table';

export const useColumnsView =
  (): ColumnsType<ITrustRequirementPublicIpIpRangeConditionModified> => {
    const { translate } = useTranslations('trust-requirements');

    return [
      {
        title: <Title>{translate('details.types.PublicIp.type')}</Title>,
        dataIndex: 'isBlocked',
        width: '7.5rem',
        render: (type: ITrustRequirementPublicIpIpRangeConditionModified['isBlocked']) => (
          <IsBlocked isBlocked={type === 'true'} />
        ),
      },
      {
        title: <Title>{translate('details.types.PublicIp.ip_range')}</Title>,
        dataIndex: 'value',
        width: '16.1875rem',
        render: (ipRange: ITrustRequirementPublicIpIpRangeConditionModified['value']) => (
          <IpRange>{ipRange}</IpRange>
        ),
      },
      {
        title: <Title>{translate('details.types.PublicIp.description')}</Title>,
        dataIndex: 'description',
        render: (description: ITrustRequirementPublicIpIpRangeConditionModified['description']) => (
          <Description>{description}</Description>
        ),
      },
    ];
  };
