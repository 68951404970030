import { ModuleName } from 'types';

export const DETAILS_FORM_ERRORS: Partial<Record<ModuleName, Record<string, boolean>>> = {
  keys: { description: true },
  dns: { name: true },
  tags: { name: true, tag: true },
};

export const ACCOUNT_RE_AUTH_PASSWORD_ERROR_FIELD = 're-auth-password';
export const ACCOUNT_RE_AUTH_PASSWORD_WARNING_ERROR_FIELD = 're-auth-password-warning';
export const ACCOUNT_ENABLE_2FA_AUTH_CODE_ERROR_FIELD = 'enable-2fa-auth-code';
export const ACCOUNT_CHANGE_PASSWORD_WARNING_ERROR_FIELD = 'change-password-warning';
export const ACCOUNT_CREATE_ACCESS_TOKEN_ERROR_FIELD = 'create-access-token';
export const ACCOUNT_CREATE_ACCESS_TOKEN_WARNING_ERROR_FIELD = 'create-access-token-warning';
export const ACCOUNT_DELETE_ACCESS_TOKEN_ERROR_FIELD = 'delete-access-token';
export const DNS_DELETE_ZONE_ERROR_FIELD = 'delete-zone';
export const DNS_UPDATE_ZONE_ERROR_FIELD = 'update-zone';
export const DNS_CREATE_ZONE_ERROR_FIELD = 'create-zone';
export const SETTINGS_CREATE_INVITE_ERROR_FIELD = 'create-invite';
