// libs
import { useState, ChangeEvent, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css, useTheme } from 'styled-components';

// components
import {
  CustomButton,
  CustomButtonLink,
  CustomMessage,
  ExternalLink,
  Input,
  Loader,
} from 'components';
import { ModalFooter, ModalHeader, ModalScrollablePart } from 'components/Modal';
import { CustomText, stylesMap } from 'typography/Text';
import { IconAccountEnable2FAModal } from 'assets/svg';
import { ImageQRfor2FA } from './styled';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { useSubmitOnEnter } from 'hooks/useSubmitOnEnter';

// actions
import { validateAuthCode } from 'redux/actions/account';
import { clearErrors, setModalContent } from 'redux/actions/app';

// selectors
import { selectAccount2FAQR, selectFormError, selectModalIsLoading } from 'redux/selectors';

// constants
import {
  LINK_TO_GOOGLE_AUTH,
  LINK_TO_LASTPASS_AUTH,
  LINK_TO_MICROSOFT_AUTH,
  LINK_TO_DUO_MOBILE_AUTH,
} from 'appConstants/links/enclave';
import { ACCOUNT_ENABLE_2FA_AUTH_CODE_ERROR_FIELD } from 'appConstants';

const List = styled.ol`
  padding: 0 0 0 1.25rem;
`;

const ListItem = styled.li`
  ::marker {
    ${stylesMap['sub-heading-1']};
    color: ${({ theme }) => theme.palette.fonts.title};
  }
`;

const minAuthCodeLength = 6;

export const Enable2FAModal = () => {
  const dispatch = useDispatch();

  const { translate } = useTranslations('account');

  const [authCode, setAuthCode] = useState('');

  const qrCode = useSelector(selectAccount2FAQR);
  const error = useSelector(selectFormError(ACCOUNT_ENABLE_2FA_AUTH_CODE_ERROR_FIELD));
  const isLoading = useSelector(selectModalIsLoading);

  const isButtonEnabled = authCode.length >= minAuthCodeLength && !isLoading;

  const onClose = () => {
    if (error) dispatch(clearErrors());
    dispatch(setModalContent({ type: null, data: null }));
  };

  const {
    palette: {
      fonts: { bodyDark, title },
    },
  } = useTheme();

  const onAuthCode = useCallback(() => {
    if (authCode.length >= minAuthCodeLength) {
      if (error) dispatch(clearErrors());
      dispatch(validateAuthCode({ code: authCode }));
    }
  }, [authCode, error, dispatch]);

  const onChangeAuthCode = (e: ChangeEvent<HTMLInputElement>) => {
    setAuthCode(e.target.value);
  };

  useSubmitOnEnter({ callback: isButtonEnabled ? onAuthCode : () => {} });

  return (
    <>
      <ModalHeader
        title={translate('enable_2fa_modal.title')}
        icon={<IconAccountEnable2FAModal width="2.5rem" height="2.5rem" />}
        withCloseButton={true}
        onClose={onClose}
      />

      <ModalScrollablePart>
        {error && (
          <CustomMessage
            type="error"
            message={error}
            withIcon
            css={css`
              margin-bottom: 1rem;
              width: auto;
            `}
          />
        )}
        <List>
          <ListItem>
            <CustomText
              type="sub-heading-1"
              color={title}
              css={css`
                display: block;
                margin-bottom: 0.25rem;
              `}
            >
              {translate('enable_2fa_modal.step_one')}
            </CustomText>
            <CustomText
              type="body-2"
              color={bodyDark}
              css={css`
                display: block;
                margin-bottom: 2rem;
              `}
            >
              {translate('enable_2fa_modal.step_one_desc_part1')}{' '}
              <ExternalLink
                text={translate('enable_2fa_modal.google_auth')}
                href={LINK_TO_GOOGLE_AUTH}
              />
              ,{' '}
              <ExternalLink
                text={translate('enable_2fa_modal.duo_mobi')}
                href={LINK_TO_DUO_MOBILE_AUTH}
              />
              ,{' '}
              <ExternalLink
                text={translate('enable_2fa_modal.microsoft_auth')}
                href={LINK_TO_MICROSOFT_AUTH}
              />{' '}
              {translate('enable_2fa_modal.or')}{' '}
              <ExternalLink
                text={translate('enable_2fa_modal.lastpass_auth')}
                href={LINK_TO_LASTPASS_AUTH}
              />{' '}
              {translate('enable_2fa_modal.step_one_desc_part2')}
            </CustomText>
            {qrCode && (
              <ImageQRfor2FA
                css={css`
                  display: block;
                  margin-left: 12.5rem;
                  margin-bottom: 2rem;
                `}
                src={`${qrCode}`}
                width={128}
                height={128}
              />
            )}
          </ListItem>
          <ListItem>
            <CustomText type="sub-heading-1" color={title}>
              {translate('enable_2fa_modal.step_two')}
            </CustomText>
            <CustomText
              type="body-2"
              color={bodyDark}
              css={css`
                display: block;
                margin-bottom: 1.5rem;
              `}
            >
              {translate('enable_2fa_modal.step_two_desc')}
            </CustomText>
            <Input
              type="new-password"
              name="authCode"
              value={authCode}
              placeholder={translate('enable_2fa_modal.auth_input_placeholder')}
              onChange={onChangeAuthCode}
              label={translate('enable_2fa_modal.auth_input_label')}
              containerCss={css`
                margin-bottom: 0.75rem;
              `}
            />
          </ListItem>
        </List>
      </ModalScrollablePart>
      <ModalFooter>
        <CustomButtonLink size="large" onClick={onClose}>
          {translate('cancel')}
        </CustomButtonLink>
        <CustomButton
          size="large"
          width="8.1875rem"
          kind={isButtonEnabled ? 'primary' : 'disabled'}
          onClick={onAuthCode}
        >
          {isLoading ? <Loader /> : translate('verify')}
        </CustomButton>
      </ModalFooter>
    </>
  );
};
