// components
import { CustomText } from 'typography/Text';

// hooks
import { useTheme } from 'styled-components';

// types
import { IPolicyModifiedGatewayAllowedIpRange } from 'types';

interface IRange {
  range: IPolicyModifiedGatewayAllowedIpRange['ipRange'];
}

export const Range = ({ range, ...rest }: IRange) => {
  const {
    palette: { fonts },
  } = useTheme();

  return (
    <CustomText
      data-test="policy-details-subnets-table-range"
      type="body-2"
      color={fonts.bodyDark}
      {...rest}
    >
      {range}
    </CustomText>
  );
};
