// components
import { IconArrowDown, IconArrowUp } from 'assets/svg';
import { GatewayContainer, GatewayName, GatewayRoute } from '../styled';
import { OrderingIconContainer, OrderingSingleIconContainer, TagItemContainer } from './styled';
import { IPolicyGatewayRouteEntry } from 'types';

interface ITag {
  name: string;
  routeEntry: IPolicyGatewayRouteEntry;
  isFirst: boolean;
  isLast: boolean;
  sortUpEnabled: boolean;
  sortDownEnabled: boolean;
  onClose: () => void;
  onSortUp: () => void;
  onSortDown: () => void;
}

export const Tag = ({
  name,
  routeEntry,
  isFirst,
  isLast,
  sortUpEnabled,
  sortDownEnabled,
  onClose,
  onSortUp,
  onSortDown,
  ...rest
}: ITag) => {
  return (
    <GatewayContainer {...rest} data-test="gateway-select-tag">
      {isFirst && (
        <TagItemContainer>
          <GatewayName>{name}</GatewayName>
          <OrderingIconContainer className="ordering-icons">
            <OrderingSingleIconContainer
              data-test="gateway-route-order-up"
              disabled={!sortUpEnabled}
              onMouseDown={e => {
                e.preventDefault();
                e.stopPropagation();
              }}
              onClick={() => sortUpEnabled && onSortUp()}
            >
              <IconArrowUp />
            </OrderingSingleIconContainer>
            <OrderingSingleIconContainer
              data-test="gateway-route-order-down"
              disabled={!sortDownEnabled}
              onMouseDown={e => {
                e.preventDefault();
                e.stopPropagation();
              }}
              onClick={() => sortDownEnabled && onSortDown()}
            >
              <IconArrowDown />
            </OrderingSingleIconContainer>
          </OrderingIconContainer>
        </TagItemContainer>
      )}
      <GatewayRoute
        route={routeEntry.route}
        name={routeEntry.gatewayName}
        editable={true}
        isPreferred={routeEntry.gatewayWeight > 0}
        isFirst={isFirst || false}
        isLast={isLast || false}
        onCloseIconClick={onClose}
      />
    </GatewayContainer>
  );
};
