import { equals } from 'ramda';
// this function returns an object
// that only contains keys that are present in first object but not present in second one
export function diff<T extends Record<string, any>>(obj1: T, obj2: T): T {
  const obj2Keys = Object.keys(obj2);
  const obj1Filtered = Object.keys(obj1).reduce((acc, key) => {
    if (!obj2Keys.includes(key) || !equals(obj1[key], obj2[key])) {
      acc[key as keyof T] = obj1[key] as ({ [key: string]: any } & T)[keyof T];
    }
    return acc;
  }, {} as { [key: string]: unknown } & T);
  return obj1Filtered;
}
