// libs
import styled, { css } from 'styled-components';

// components
import { TabButtons } from 'components';
import { ContentContainer } from './styled';
import { Empty } from './Empty';

// hooks
import { useTranslations } from 'hooks/useTranslations';

// types
import { ITabsNumber } from 'components/TabButtons/NumberTabPane';

export const TabsWrapper = styled.div`
  border-bottom: 0.0625rem solid ${({ theme }) => theme.palette.fills.strokeMedium};
  margin-bottom: 1rem;
`;

export type IConnectionsTabs = 'keys' | 'systems' | 'dnsRecords' | 'dnsZones' | 'policies';

interface IContent {
  keysAmount: number;
  keysContent: React.ReactNode;
  systemsAmount: number;
  systemsContent: React.ReactNode;
  dnsRecordAmount: number;
  dnsRecordContent: React.ReactNode;
  dnsZoneAmount: number;
  dnsZoneContent: React.ReactNode;
  policiesAmount: number;
  policiesContent: React.ReactNode;
  selectedTab: IConnectionsTabs;
  setSelectedTab: (tab: IConnectionsTabs) => void;
  withEmptyState: boolean;
}

export const Content = ({
  keysContent,
  keysAmount,
  systemsContent,
  systemsAmount,
  dnsRecordAmount,
  dnsRecordContent,
  dnsZoneAmount,
  dnsZoneContent,
  policiesAmount,
  policiesContent,
  selectedTab,
  setSelectedTab,
  withEmptyState,
  ...rest
}: IContent) => {
  const { translate } = useTranslations('tags');

  const tabs: ITabsNumber[] = [
    {
      type: 'number',
      key: 'keys',
      dataFormat: 'show',
      title: translate('details.connections.tabs.keys.name'),
      data: keysAmount,
      titleCss: css`
        width: 2rem;
      `,
    },
    {
      type: 'number',
      key: 'systems',
      dataFormat: 'show',
      data: systemsAmount,
      title: translate('details.connections.tabs.systems.name'),
      titleCss: css`
        width: 3.5rem;
      `,
    },
    {
      type: 'number',
      key: 'policies',
      dataFormat: 'show',
      data: policiesAmount,
      title: translate('details.connections.tabs.policies.name'),
      titleCss: css`
        width: 3.5rem;
      `,
    },
    {
      type: 'number',
      key: 'dnsRecords',
      dataFormat: 'show',
      data: dnsRecordAmount,
      title: translate('details.connections.tabs.dnsRecords.name'),
      titleCss: css`
        width: 5.6rem;
      `,
    },
    {
      type: 'number',
      key: 'dnsZones',
      dataFormat: 'show',
      data: dnsZoneAmount,
      title: translate('details.connections.tabs.dnsZones.name'),
      titleCss: css`
        width: 5rem;
      `,
    },
  ];

  return (
    <ContentContainer {...rest}>
      <TabsWrapper>
        <TabButtons
          tabs={tabs}
          selectedKey={selectedTab}
          onChange={newKey => setSelectedTab(newKey as IConnectionsTabs)}
          tabCss={css`
            :not(:first-child) {
              margin-left: 0.5rem !important;
            }
          `}
        />
      </TabsWrapper>
      {selectedTab === 'keys' ? (
        <>{withEmptyState && keysAmount === 0 ? <Empty module="keys" /> : <>{keysContent}</>}</>
      ) : null}
      {selectedTab === 'systems' ? (
        <>
          {withEmptyState && systemsAmount === 0 ? (
            <Empty module="systems" />
          ) : (
            <>{systemsContent}</>
          )}
        </>
      ) : null}
      {selectedTab === 'dnsRecords' ? (
        <>
          {withEmptyState && dnsRecordAmount === 0 ? (
            <Empty module="dnsRecords" />
          ) : (
            <>{dnsRecordContent}</>
          )}
        </>
      ) : null}
      {selectedTab === 'dnsZones' ? (
        <>
          {withEmptyState && dnsZoneAmount === 0 ? (
            <Empty module="dnsZones" />
          ) : (
            <>{dnsZoneContent}</>
          )}
        </>
      ) : null}
      {selectedTab === 'policies' ? (
        <>
          {withEmptyState && policiesAmount === 0 ? (
            <Empty module="policies" />
          ) : (
            <>{policiesContent}</>
          )}
        </>
      ) : null}
    </ContentContainer>
  );
};
