// Libs
import { css, useTheme } from 'styled-components';

// Components
import { ExternalLink, Tooltip } from 'components';
import { CustomText } from 'typography/Text';
import { List } from './styled';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { LINK_TO_DNS_DOCS } from 'appConstants';
import { useState } from 'react';

interface IInputErrorTooltip {
  children: React.ReactNode;
  isVisible: boolean;
}

export const InputTooltip = ({ children, isVisible }: IInputErrorTooltip) => {
  const { translate } = useTranslations('dns');

  const {
    palette: { fonts },
  } = useTheme();

  const [hover, setHover] = useState(false);

  const onMouseEnter = () => {
    setHover(true);
  };
  const onMouseLeave = () => {
    setHover(false);
  };

  return (
    <Tooltip
      visible={isVisible || hover}
      placement="bottom"
      width="25.5rem"
      align={{ targetOffset: [50, 250] }}
      passedCss={css`
        width: 100%;
        position: static;
        .ant-tooltip-inner {
          padding: 1rem;
          background-color: ${({ theme }) => theme.palette.popovers.backgound} !important;
          box-shadow: ${({ theme }) => theme.palette.elevationShadows[400]};
        }
      `}
      overlay={
        <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
          <CustomText type="label-2" color={fonts.bodyLight}>
            {translate('records.details.title_error_invalid_tooltip_header')}
          </CustomText>
          <List
            css={css`
              li {
                list-style-type: disc;

                ::marker {
                  color: ${({ theme }) => theme.palette.fonts.bodyLight};
                }
              }
            `}
          >
            <li>
              <CustomText type="label-2" color={fonts.bodyLight}>
                {translate('records.details.title_error_invalid_tooltip_1')}
              </CustomText>
            </li>

            <li>
              <CustomText type="label-2" color={fonts.bodyLight}>
                {translate('records.details.title_error_invalid_tooltip_2')}
              </CustomText>
            </li>
            <li>
              <CustomText type="label-2" color={fonts.bodyLight}>
                {translate('records.details.title_error_invalid_tooltip_3')}
              </CustomText>
            </li>
          </List>
          <ExternalLink
            kind="light"
            tabIndex={-1}
            textType="label-2"
            href={LINK_TO_DNS_DOCS}
            text={translate('records.details.title_error_invalid_tooltip_link')}
            css={css`
              margin-top: 0.5rem;
              margin-left: 1.25rem;
            `}
            withIcon
          />
        </div>
      }
    >
      {children}
    </Tooltip>
  );
};
