import { useEffect, useState, useRef, useCallback } from 'react';
import { css } from 'styled-components';

// components
import {
  CustomTable,
  ColumnTitle,
  CustomButton,
  Input,
  Loader,
  CustomButtonLink,
} from 'components';
import { CustomMessage } from 'components';
import { IconModalDelete } from 'assets/svg';
import { ModalFooter, ModalHeader, ModalScrollablePart } from 'components/Modal';
import { Description, Status } from './Columns';
import { CustomText } from 'typography/Text';
import { tableStyles } from './styled';

// hooks
import { useSelector, useDispatch } from 'react-redux';
import { useSubmitOnEnter } from 'hooks/useSubmitOnEnter';
import { useTranslations } from 'hooks/useTranslations';

// selectors
import { selectModalData, selectModalIsLoading } from 'redux/selectors';

// actions
import { setModalContent } from 'redux/actions/app';
import { deletePolicies, deletePolicy } from 'redux/actions/policies';

// types
import { IPolicy } from 'types';

// constants
import { REMOVE_MODAL_CONFIRMATION_TEXT } from 'appConstants';

export const RemovePolicyModal = () => {
  const dispatch = useDispatch();

  const { translate } = useTranslations('policies');

  const [enabled, setEnabled] = useState(false);
  const [inputText, setInputText] = useState('');

  const isLoading = useSelector(selectModalIsLoading);
  const policies = useSelector(selectModalData) as IPolicy[];

  const isButtonEnabled = enabled && !isLoading;

  const inputRef = useRef<HTMLInputElement>(null);

  const onClose = () => {
    dispatch(setModalContent({ type: null, data: null }));
  };

  const columns = [
    {
      title: <ColumnTitle>{translate('name')}</ColumnTitle>,
      key: 'descriptionModal',
      dataIndex: 'description',
      render: (description: IPolicy['description']) => <Description description={description} />,
      ellipsis: true,
    },
    {
      title: <ColumnTitle>{translate('state')}</ColumnTitle>,
      key: 'isEnabledModal',
      dataIndex: 'isEnabled',
      render: (isEnabled: IPolicy['isEnabled']) => <Status isEnabled={isEnabled} />,
      ellipsis: true,
    },
  ];

  const onRemove = useCallback(() => {
    if (policies.length > 1) {
      dispatch(deletePolicies({ policyIds: policies.map(policy => policy.id) }));
    } else {
      dispatch(deletePolicy({ policyId: String(policies[0].id) }));
    }
  }, [dispatch, policies]);

  useEffect(() => {
    if (inputText === REMOVE_MODAL_CONFIRMATION_TEXT) {
      setEnabled(true);
    } else {
      if (enabled) setEnabled(false);
    }
  }, [inputText, enabled]);

  useSubmitOnEnter({ callback: isButtonEnabled ? onRemove : () => {} });

  useEffect(() => {
    inputRef?.current?.focus();
  }, [inputRef]);

  return (
    <div data-test="policies-remove-modal">
      <ModalHeader
        title={translate('modal.remove.title', { count: policies?.length || 0 })}
        icon={<IconModalDelete width="2.5rem" height="2.5rem" />}
        withCloseButton={true}
        onClose={onClose}
      />
      <ModalScrollablePart>
        <CustomMessage
          type="error"
          message={translate('modal.remove.warning')}
          css={css`
            margin-bottom: 2.25rem;
          `}
        />
        <CustomText
          type="sub-heading-2"
          css={css`
            display: block;
            margin-bottom: 0.5rem;
          `}
        >
          {translate('modal.remove.selected_policies')} ({policies?.length || 0})
        </CustomText>

        <CustomTable
          elevated={false}
          rowHeight="2.375rem"
          css={tableStyles}
          rowKey="id"
          columns={columns}
          dataSource={policies}
        />
        <Input
          ref={inputRef}
          data-test="policies-remove-modal-confirm-input-wrapper"
          type="text"
          name="yescheck"
          value={inputText}
          label={translate('modal.remove.confirm_cta')}
          maxLength={128}
          placeholder={translate('modal.remove.confirm_placeholder')}
          onChange={e => setInputText(e.target.value.toUpperCase())}
        />
      </ModalScrollablePart>
      <ModalFooter>
        <CustomButtonLink size="large" onClick={onClose}>
          {translate('modal.remove.cancel')}
        </CustomButtonLink>
        <CustomButton
          data-test="policies-remove-submit"
          width="8.875rem"
          size="large"
          kind={isButtonEnabled ? 'primaryDanger' : 'disabled'}
          onClick={onRemove}
        >
          {isLoading ? <Loader /> : translate('modal.remove.remove')}
        </CustomButton>
      </ModalFooter>
    </div>
  );
};
