// libs
import { useTheme } from 'styled-components';

// components
import { CustomText } from 'typography/Text';

// hooks
import { useSelector } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';

// selectors
import { selectAppDetailsEnrolmentKeyUsesRemaining } from 'redux/selectors';

// constants
import { UNLIMITED_USES_AMOUNT } from 'appConstants';

export const UseLimitView = () => {
  const { translate } = useTranslations('enrolment-keys');

  const {
    palette: { fonts, state },
  } = useTheme();

  const usesRemaining = useSelector(selectAppDetailsEnrolmentKeyUsesRemaining);

  const usesRemainingMessage =
    usesRemaining === UNLIMITED_USES_AMOUNT
      ? translate('unlimited')
      : !usesRemaining
      ? translate('details.0_uses_remaining')
      : usesRemaining;

  return (
    <CustomText type="body-2" color={usesRemaining ? fonts.title : state.error}>
      {usesRemainingMessage}
    </CustomText>
  );
};
