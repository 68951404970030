// components
import { SpanStyledAsLargeInput } from 'components';

// selectors
import { selectAppDetailsSystemDescription, selectAppDetailsSystemHostname } from 'redux/selectors';

// hooks
import { useTheme } from 'styled-components';
import { useSelector } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';

export const TitleView = () => {
  const { translate } = useTranslations('systems');

  const {
    palette: { fonts },
  } = useTheme();

  const description = useSelector(selectAppDetailsSystemDescription);
  const hostname = useSelector(selectAppDetailsSystemHostname);

  return (
    <SpanStyledAsLargeInput type="heading-2" color={description ? fonts.bodyDark : fonts.disabled}>
      {description || hostname || translate('details.no_description')}
    </SpanStyledAsLargeInput>
  );
};
