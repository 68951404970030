// components
import { IsBlockedEdit } from './IsBlockedEdit';
import { IsBlockedContext } from '../EditContent';

// hooks
import { useContext } from 'react';

export const LocationsAccessEdit = () => {
  const { isBlocked, setIsBlocked } = useContext(IsBlockedContext);

  return <IsBlockedEdit isBlocked={isBlocked} setIsBlocked={setIsBlocked} />;
};
