// libs
import { call, put, select } from 'typed-redux-saga';

// actions
import { setDnsRecordsShouldUpdate, setDnsZonesShouldUpdate } from 'redux/actions/dns';

// itils
import { orgPush } from '../../utils';

// constants
import { ROUTE_PATHS } from 'appConstants';

// actions
import { pushNotification } from 'redux/actions/app';

// types
import { INotificationType, IRecordCreateDetails } from 'types';
import { selectAppDetailsTabDataNew } from 'redux/selectors';

// utils
import { ApiTypeCaller } from '../../api/utils';
import { systemsToStrings, tagsToStrings, translator } from 'utils';

export function* createRecordSaga() {
  const translate = translator('notifications');

  const { zoneId, name, notes, systems, tags }: IRecordCreateDetails = yield select(
    selectAppDetailsTabDataNew
  );

  const { result, success } = yield* ApiTypeCaller('createDnsRecord', {
    zoneId,
    name,
    notes,
    systems: systemsToStrings(systems),
    tags: tagsToStrings(tags),
  });

  if (success && result) {
    yield call(orgPush, `${ROUTE_PATHS.dns}/zones/${result.zoneId}/record/${result.id}`, {
      keepSearch: true,
    });
    yield put(setDnsRecordsShouldUpdate());
    yield put(setDnsZonesShouldUpdate());
    yield put(
      pushNotification({
        showType: INotificationType.Success,
        description: translate('dns_record_created', {
          recordId: result.id,
          fqdn: result.fqdn,
        }),
      })
    );
  }
}
