// Components
import { css } from 'styled-components';
import { Trans } from 'react-i18next';
import { InternalLinkInline } from 'components';
import { StyledInfoContainer, StyledCustomMessage, StyledEnrolmentKeyCode } from './styled';

// Hooks
import { useTranslations } from 'hooks/useTranslations';
import { useSelector } from 'react-redux';

// Types
import { IEnrolmentKey } from 'types';

// Selectors
import { selectCurrentOrganisationId } from 'redux/selectors';

interface ISelectedKeyInfo {
  enrolmentKey: IEnrolmentKey;
}
export const SelectedKeyInfo = ({ enrolmentKey }: ISelectedKeyInfo) => {
  const orgId = useSelector(selectCurrentOrganisationId);

  const { usesRemaining, key, id } = enrolmentKey;
  const { translate } = useTranslations('enrol-new-system');
  return (
    <StyledInfoContainer>
      {usesRemaining === 0 && (
        <StyledCustomMessage
          type="warning"
          message={
            <Trans
              i18nKey={translate('uses_exhausted.text')}
              components={[
                <InternalLinkInline
                  key={0}
                  title={translate('uses_exhausted.link_title')}
                  to={`/org/${orgId}/keys/${id}`}
                  css={css`
                    display: inline !important;
                  `}
                  inlineText={translate('uses_exhausted.link_title')}
                />,
              ]}
            />
          }
        />
      )}
      <StyledEnrolmentKeyCode enrolmentKey={key} textType="command-line" />
    </StyledInfoContainer>
  );
};
