// components
import { CustomText } from 'typography/Text';

// hooks
import { useTheme } from 'styled-components';

// types
import { IEnrolmentKey } from 'types';

interface IDescription {
  description: IEnrolmentKey['description'];
  isEnabled?: IEnrolmentKey['isEnabled'];
}

export const Description = ({ description, isEnabled = true, ...rest }: IDescription) => {
  const theme = useTheme();

  return (
    <CustomText
      type="body-2"
      color={isEnabled ? theme.palette.fonts.bodyDark : theme.palette.fonts.disabled}
      {...rest}
      title={description}
    >
      {description}
    </CustomText>
  );
};
