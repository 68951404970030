import { ISystemModel, ISystemModelModified, IValidationState } from 'types';
import { randomHash } from './hash';
import { filter, mergeWithKey, modify, omit } from 'ramda';
import { SYSTEM_GATEWAY_ROUTE_VALIDATION_STATE_PREFIX } from 'appConstants';

export const modifySystemDetails = (system: ISystemModel): ISystemModelModified => {
  return modify(
    'gatewayRoutes',
    (routes: ISystemModel['gatewayRoutes']): ISystemModelModified['gatewayRoutes'] => {
      return routes.map(route => ({ ...route, id: randomHash() }));
    },
    system
  );
};

export const demodifySystemDetails = (system: ISystemModelModified): ISystemModel => {
  return modify(
    'gatewayRoutes',
    (routes: ISystemModelModified['gatewayRoutes']): ISystemModel['gatewayRoutes'] =>
      routes.map(route => omit(['id'], route)),
    system
  );
};

export const findSystemSubnetDuplicates = ({
  routes,
  validationValue,
  currentValidationState,
}: {
  routes: ISystemModelModified['gatewayRoutes'];
  validationValue: IValidationState[0];
  currentValidationState: IValidationState;
}): IValidationState => {
  const userEnteredRoutes = routes.filter(route => route.userEntered);

  const routesWithDuplicates = userEnteredRoutes.filter(userEnteredRoute => {
    return routes.some(
      route => userEnteredRoute.id !== route.id && userEnteredRoute.subnet === route.subnet
    );
  });

  const currentValidationWithoutDuplicates = filter(currentValidationValue => {
    // if the error is not duplicates
    return currentValidationValue.state.error?.message !== validationValue.state.error?.message;
  }, currentValidationState);

  const dupllicatesValidationState = routesWithDuplicates.reduce((total, currentValue) => {
    return {
      ...total,
      [SYSTEM_GATEWAY_ROUTE_VALIDATION_STATE_PREFIX + currentValue.id]: validationValue,
    };
  }, {} as IValidationState);

  return mergeWithKey(
    (_, left: IValidationState[0], right: IValidationState[0]) => {
      if (left.state.error === null) {
        return right;
      }
      if (left.state.error?.message !== validationValue.state.error?.message) {
        return left;
      }
      return right;
    },
    currentValidationWithoutDuplicates,
    dupllicatesValidationState
  );
};
