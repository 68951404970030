// Hooks
import { useSelector } from 'react-redux';

// Selectors
import { selectLastBillingEvent } from 'redux/selectors';

// Components
import { CustomMessage, IMessageType } from 'components';
import { BillingMessageStyle } from './styled';

// Types
import { IBillingEventLevel } from 'types';

const messageTypeMap: Record<IBillingEventLevel, IMessageType> = {
  Information: 'info',
  Error: 'error',
  Warning: 'warning',
};

export const BillingMessage = () => {
  const billingMessage = useSelector(selectLastBillingEvent);

  if (billingMessage)
    return (
      <CustomMessage
        message={billingMessage.message}
        type={messageTypeMap[billingMessage.level]}
        withIcon
        css={BillingMessageStyle}
      />
    );

  return null;
};
