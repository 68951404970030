// libs
import styled, { css, useTheme } from 'styled-components';

// components
import { IconPoliciesRemoveGateway, IconSystemsDetailsPreferred } from 'assets/svg';
import { CustomText } from 'typography/Text';
import { Tooltip } from 'components/Tooltip';
import { useTranslations } from 'hooks/useTranslations';

export const GatewayContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const GatewayNameContainer = styled.div`
  padding: 0.5rem 0 0.25rem;
`;

interface IGatewayName {
  children: React.ReactNode;
}

export const GatewayName = ({ children }: IGatewayName) => {
  const {
    palette: { fonts },
  } = useTheme();

  return (
    <GatewayNameContainer>
      <CustomText type="sub-heading-2" color={fonts.bodyDark}>
        {children}
      </CustomText>
    </GatewayNameContainer>
  );
};

export const GatewayRouteContainer = styled.div<{ editable: boolean }>`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-left: 1rem;
  padding: 0 0.5rem;
  border-radius: 0.25rem;

  ${({ editable, theme }) =>
    editable &&
    css`
      :hover {
        background-color: ${theme.palette.background.sections};
      }
    `}
`;

export const GatewayRouteTextContainer = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 11rem;

  span:nth-child(2) {
    margin-top: -0.2rem;
    margin-bottom: 0.2rem;
  }
`;

export const IconContainer = styled.div`
  width: 1.625rem;
  height: 1.625rem;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const Tree = styled.div<{ isFirst: boolean }>`
  position: absolute;

  left: -0.6875rem;
  top: -0.175rem;

  height: 1rem;
  /*${({ isFirst }) => isFirst && 'height: 60%;'};*/
  width: 0.6875rem;

  border-bottom-left-radius: 0.25rem;

  border-left: 0.125rem solid ${({ theme }) => theme.palette.fills.stroke};
  border-bottom: 0.125rem solid ${({ theme }) => theme.palette.fills.stroke};
`;

export const TreeContinue = styled.div`
  position: absolute;

  left: -0.6875rem;
  top: 0.7rem;

  height: 70%;
  width: 0.6875rem;

  border-left: 0.125rem solid ${({ theme }) => theme.palette.fills.stroke};
`;

export const PreferredIconToolTip = ({ title }: { title: string }) => (
  <Tooltip
    title={title}
    passedCss={css`
      display: inline-block;
      position: relative;
      top: 0.25rem;
      left: 0.25rem;
      cursor: default;

      svg {
        height: 1.1rem;
      }
    `}
  >
    <IconSystemsDetailsPreferred />
  </Tooltip>
);

export const PreferredIcon = () => (
  <IconSystemsDetailsPreferred
    css={css`
      position: relative;
      top: 0.25rem;
      left: 0.25rem;
      cursor: default;
      height: 1.1rem;
    `}
  />
);

interface IGatewayRoute {
  route: string;
  name?: string | null;
  isPreferred: boolean;
  editable?: boolean;
  onCloseIconClick?: () => void;
  isFirst: boolean;
  isLast: boolean;
}

export const GatewayRoute = ({
  route,
  name,
  isPreferred,
  editable = false,
  onCloseIconClick,
  isFirst,
  isLast,
}: IGatewayRoute) => {
  const {
    palette: { fonts },
  } = useTheme();

  const { translate } = useTranslations('policies');

  return (
    <GatewayRouteContainer editable={editable}>
      <GatewayRouteTextContainer>
        <CustomText type="body-2" color={fonts.bodyDark}>
          {route}
          {isPreferred && (
            <PreferredIconToolTip title={translate('details.gateway_preferred_tooltip')} />
          )}
        </CustomText>

        {name && (
          <CustomText type="hint" color={fonts.bodyLight}>
            {name}
          </CustomText>
        )}
      </GatewayRouteTextContainer>

      {editable && (
        <IconContainer
          data-test="gateway-route-deselect"
          onMouseDown={e => {
            e.preventDefault();
            e.stopPropagation();
          }}
          onClick={onCloseIconClick}
        >
          <IconPoliciesRemoveGateway />
        </IconContainer>
      )}

      <Tree isFirst={isFirst} />
      {!isLast && <TreeContinue className="tree-continue" />}
    </GatewayRouteContainer>
  );
};
