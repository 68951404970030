import { QueryParamsType } from 'types';

export const encodeURI = (payload: QueryParamsType['data']) =>
  Object.keys(payload)
    .filter(key => Boolean(payload[key as keyof QueryParamsType['data']]))
    .map(
      (key: string | undefined) =>
        `${key}=${encodeURIComponent(
          payload[key as keyof QueryParamsType['data']] as string | number | boolean
        )}`
    )
    .join('&');
