import { MainState } from 'types';

export const selectFormErrors = (state: MainState) => state.App.errors.form;
export const selectNotificationData = (state: MainState) => state.App.notifications.data;
export const selectNotificationIsLocked = (state: MainState) => state.App.notifications.isLocked;
export const selectAppIsLoading = (state: MainState) => state.App.isLoading;
export const selectModalIsLoading = (state: MainState) => state.App.modal.isLoading;
export const selectAppIsLoaded = (state: MainState) => state.App.loaded;
export const selectIsStripeLoaded = (state: MainState) => state.App.stripeLoaded;
export const selectNetworkConnectionError = (state: MainState) =>
  state.App.networkConnectionError?.error;

export const selectFormError = (field: string) => (state: MainState) => {
  return state.App.errors.form?.[field]?.[0];
};
