// libs
import { memo } from 'react';
import styled from 'styled-components';
import { css, useTheme } from 'styled-components';

// components
import { CustomText } from 'typography/Text';
import { StyledRow, StyledCol, ControllableIcon } from 'components';
import Skeleton from 'react-loading-skeleton';
import { IconEnrolmentKey } from 'assets/svg';

// types
import { IIconState, ISimpleEnrolmentKey } from 'types';

interface IDescription {
  description: ISimpleEnrolmentKey['description'];
  isEnabled?: ISimpleEnrolmentKey['isEnabled'];
  isLoading: boolean;
  ephemeral: boolean;
  state: IIconState;
}

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

export const Description = memo(
  ({ description, isLoading, ephemeral, state, isEnabled = true }: IDescription) => {
    const theme = useTheme();
    return (
      <>
        {isLoading ? (
          <Skeleton />
        ) : (
          <StyledRow>
            <StyledCol $asFlex={true} $middle={true} $justify="flex-start">
              <DescriptionContainer>
                <ControllableIcon
                  icon={<IconEnrolmentKey />}
                  ephemeral={ephemeral}
                  size="large"
                  state={state}
                  css={css`
                    margin-right: 0.75rem;
                  `}
                />
                <CustomText
                  data-test="enrolment-keys-table-description-column"
                  css={css`
                    display: block;
                  `}
                  type="body-2"
                  color={isEnabled ? theme.palette.fonts.bodyDark : theme.palette.fonts.disabled}
                  ellipsis={true}
                  title={description}
                >
                  {description}
                </CustomText>
              </DescriptionContainer>
            </StyledCol>
          </StyledRow>
        )}
      </>
    );
  }
);
