// libs
import { Route, Routes } from 'react-router';

// components
import { PrivateRoute } from './PrivateRoute';
import { Systems } from '../Systems';
import { EnrolmentKeys } from '../EnrolmentKeys';
import { SecurityEvents } from '../SecurityEvents';
import { OIDCCallback } from '../OIDCCallback';
import { Account } from '../Account';
import { EnrolNewSystem } from 'modules/EnrolNewSystem';
import { Settings } from 'modules/Settings';
import { Dns } from 'modules/Dns';
import { Tags } from 'modules/Tags';
import { Policies } from '../Policies';
import { TrustRequirements } from '../TrustRequirements';
import { LandingPage } from '../LandingPage';
import { Page404 } from './404';

// hooks
import { useSelector } from 'react-redux';

// constants
import { OIDCConstants, ORG_PATH } from 'appConstants';
import { ROUTE_PATHS } from 'appConstants';

// selectors
import { selectCurrentOrganisationExists, selectUserAuthState } from 'redux/selectors';

export const DefaultRoutes = () => {
  const isLoggedIn = useSelector(selectUserAuthState);

  return (
    <Routes>
      <Route path={`/org`}>
        <Route
          path={`:orgId`}
          element={<PrivateRoute component={LandingPage} isLoggedIn={isLoggedIn} />}
        />
      </Route>

      <Route path={`${ORG_PATH}/tags`}>
        <Route index element={<PrivateRoute component={Tags} isLoggedIn={isLoggedIn} />} />
        <Route
          path={`:tagRef`}
          element={<PrivateRoute component={Tags} isLoggedIn={isLoggedIn} />}
        />
      </Route>

      <Route path={`${ORG_PATH}${ROUTE_PATHS.systems}`}>
        <Route index element={<PrivateRoute component={Systems} isLoggedIn={isLoggedIn} />} />
        <Route
          path={`:systemId`}
          element={<PrivateRoute component={Systems} isLoggedIn={isLoggedIn} />}
        />
      </Route>

      <Route path={`${ORG_PATH}${ROUTE_PATHS['unapproved-systems']}`}>
        <Route index element={<PrivateRoute component={Systems} isLoggedIn={isLoggedIn} />} />

        <Route
          path={`:systemId`}
          element={<PrivateRoute component={Systems} isLoggedIn={isLoggedIn} />}
        />
      </Route>

      <Route
        path={`${ORG_PATH}${ROUTE_PATHS['new-system']}`}
        element={<PrivateRoute component={EnrolNewSystem} isLoggedIn={isLoggedIn} />}
      />

      <Route path={`${ORG_PATH}${ROUTE_PATHS.keys}`}>
        <Route index element={<PrivateRoute component={EnrolmentKeys} isLoggedIn={isLoggedIn} />} />
        <Route
          path={`:keyId`}
          element={<PrivateRoute component={EnrolmentKeys} isLoggedIn={isLoggedIn} />}
        />
      </Route>

      <Route path={`${ORG_PATH}/policies`}>
        <Route index element={<PrivateRoute component={Policies} isLoggedIn={isLoggedIn} />} />
        <Route
          path={`:policyId`}
          element={<PrivateRoute component={Policies} isLoggedIn={isLoggedIn} />}
        />
      </Route>

      <Route path={`${ORG_PATH}${ROUTE_PATHS.dns}/zones`}>
        <Route index element={<PrivateRoute component={Dns} isLoggedIn={isLoggedIn} />} />
        <Route path={`:zoneId`} element={<PrivateRoute component={Dns} isLoggedIn={isLoggedIn} />}>
          <Route
            path={`record/:recordId`}
            element={<PrivateRoute component={Dns} isLoggedIn={isLoggedIn} />}
          />
        </Route>
      </Route>

      <Route path={`${ORG_PATH}${ROUTE_PATHS['trust-requirements']}`}>
        <Route
          index
          element={<PrivateRoute component={TrustRequirements} isLoggedIn={isLoggedIn} />}
        />
        <Route
          path={`:requirementId`}
          element={<PrivateRoute component={TrustRequirements} isLoggedIn={isLoggedIn} />}
        />
      </Route>

      <Route
        path={`${ORG_PATH}${ROUTE_PATHS.events}`}
        element={<PrivateRoute component={SecurityEvents} isLoggedIn={isLoggedIn} />}
      />

      <Route
        path={`${ORG_PATH}${ROUTE_PATHS.settings}`}
        element={<PrivateRoute component={Settings} isLoggedIn={isLoggedIn} />}
      />

      <Route
        path={`${ROUTE_PATHS.account}`}
        element={<PrivateRoute component={Account} isLoggedIn={isLoggedIn} />}
      />

      <Route path={`/${OIDCConstants.oidcRedirect}`} element={<OIDCCallback silent={false} />} />

      <Route
        path={`/${OIDCConstants.oidcSilentRedirect}`}
        element={<OIDCCallback silent={true} />}
      />

      <Route path="*" element={<Page404 />} />
    </Routes>
  );
};

export const NoOrgRoutes = () => {
  const isLoggedIn = useSelector(selectUserAuthState);

  return (
    <Routes>
      <Route
        path={`${ROUTE_PATHS.account}`}
        element={<PrivateRoute component={Account} isLoggedIn={isLoggedIn} />}
      />
    </Routes>
  );
};

export const ContentRoutes = () => {
  const DoesOrgExist = useSelector(selectCurrentOrganisationExists);

  if (DoesOrgExist) {
    return <DefaultRoutes />;
  }

  return <NoOrgRoutes />;
};
