import { PolicyTypeBlock } from '../../Blocks';
import { PolicyTypeSection } from './styled';

export const PolicyType = () => {
  return (
    <PolicyTypeSection>
      <PolicyTypeBlock />
    </PolicyTypeSection>
  );
};
