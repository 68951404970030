import styled, { useTheme, css } from 'styled-components';
import { CustomText } from 'typography/Text';
import { CommandLineCode } from 'typography/Code';

export const LinuxDropdownContainer = styled.div`
  display: flex;
  align-items: center;
`;

type ComponentProps = {
  children: React.ReactNode;
};

export const DownloadLink = ({ children }: ComponentProps) => {
  const {
    palette: { fonts },
  } = useTheme();
  return (
    <CustomText
      type="body-2"
      color={fonts.bodyLight}
      css={css`
        display: block;
        margin-top: 0.5rem;
        height: 1.375rem;
      `}
    >
      {children}
    </CustomText>
  );
};

export const WindowsLink = styled.a`
  display: block;
  width: min-content;
`;

export const StyledCommandLineCode = styled(CommandLineCode)`
  margin: 0;
`;

type StyledCodeTextProps = {
  text: string;
};
export const StyledCodeText = ({ text }: StyledCodeTextProps) => (
  <CustomText
    type="command-line"
    css={css`
      padding: 0.25rem 0.5rem;
      border-radius: 0.75rem;
      background-color: ${props => props.theme.palette.background.base};
    `}
  >
    {text}
  </CustomText>
);

export const InfoContainer = styled.div`
  flex-direction: column;

  width: 100%;
  margin-top: 1rem;
  border-radius: 0.25rem;
  padding: 2rem 2.5rem;
  background-color: ${props => props.theme.palette.background.panel};
`;

export const TabsContainer = styled.div`
  border-bottom: 0.0625rem solid ${props => props.theme.palette.fills.strokeMedium};
  margin-bottom: 2rem;
`;
