import { ModuleName } from 'types';

// dashboard has to be last key in object because in sagas/errors.ts we get
// current module by comparing to pathname, and "/" exists in every module, so
// if dashboard is first key it will always consider as if the module is dashboard.
export const ROUTE_PATHS: Record<ModuleName, string> = {
  systems: '/systems',
  'unapproved-systems': '/unapproved-systems',
  keys: '/keys',
  'new-system': '/new-system',
  policies: '/policies',
  events: '/events',
  settings: '/settings',
  account: '/account',
  dns: '/dns',
  'trust-requirements': '/trust-requirements',
  tags: '/tags',
  dashboard: '/',
};

export const SIDE_MENU_ROUTE_PATHS = {
  systems: ROUTE_PATHS.systems,
  keys: ROUTE_PATHS.keys,
  dashboard: ROUTE_PATHS.dashboard,
  'new-system': ROUTE_PATHS['new-system'],
  'unapproved-systems': ROUTE_PATHS.systems,
  policies: ROUTE_PATHS.policies,
  events: ROUTE_PATHS.events,
  settings: ROUTE_PATHS.settings,
  account: ROUTE_PATHS.account,
  dns: ROUTE_PATHS.dns,
  'trust-requirements': ROUTE_PATHS['trust-requirements'],
  tags: ROUTE_PATHS.tags,
};

export const MODULES_WITH_DETAILS_PANE: ModuleName[] = [
  'new-system', // No details pane but has same width
  'systems',
  'unapproved-systems',
  'keys',
  'policies',
  'events',
  'dns',
  'trust-requirements',
  'tags',
];
export const MODULES_WITHOUT_SIDEBAR: ModuleName[] = ['account'];
export const DNS_MODULE: ModuleName[] = ['dns'];
export const WITH_CUSTOM_SCROLL: ModuleName[] = ['dns'];

export const REGISTER_URL = '/register';
export const ORG_PATH = `/org/:orgId`;
