// libs
import { Trans } from 'react-i18next';
import { css } from 'styled-components';

// hooks
import { useTranslations } from 'hooks/useTranslations';

// components
import { CustomMessage, ExternalLink } from 'components';

//types
import { OSType } from 'types';

// constants
import {
  LINK_TO_DOCKER_SETUP_DOCS,
  LINK_TO_KUBERNETES_SETUP_DOCS,
  LINK_TO_LINUX_SETUP_DOCS,
  LINK_TO_MAC_SETUP_DOCS,
  LINK_TO_WINDOWS_SETUP_DOCS,
  LINK_TO_ANDROID_SETUP_DOCS,
  LINK_TO_IOS_SETUP_DOCS,
} from 'appConstants';

interface IDocsLinkMessageProps {
  OS: OSType;
}

export const DocsLinkMessage = ({ OS }: IDocsLinkMessageProps) => {
  const { translate } = useTranslations('enrol-new-system');

  return (
    <CustomMessage
      type="info"
      message={
        <Trans
          i18nKey={translate('docs_link.text')}
          components={[
            <BoldText key={0} text={OS} />,
            <ExternalLink
              key={1}
              textType="body-1"
              withIcon
              href={getOsLink(OS)}
              text={translate('docs_link.link_title')}
            />,
          ]}
        />
      }
      withIcon
      css={css`
        margin-bottom: 1rem;
        padding: 1rem;
        width: auto;
      `}
    />
  );
};

function getOsLink(os: OSType) {
  switch (os) {
    case OSType.Docker:
      return LINK_TO_DOCKER_SETUP_DOCS;
    case OSType.MacOS:
      return LINK_TO_MAC_SETUP_DOCS;
    case OSType.Kubernetes:
      return LINK_TO_KUBERNETES_SETUP_DOCS;
    case OSType.Linux:
      return LINK_TO_LINUX_SETUP_DOCS;
    case OSType.Windows:
      return LINK_TO_WINDOWS_SETUP_DOCS;
    case OSType.Android:
      return LINK_TO_ANDROID_SETUP_DOCS;
    case OSType.IOS:
      return LINK_TO_IOS_SETUP_DOCS;
  }
}

function BoldText({ text }: { text: string }) {
  return <b>{text}</b>;
}
