import styled from 'styled-components';

export const List = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;
export const ListItem = styled.li`
  display: flex;
  flex-direction: column;
`;

export const MainContentContainer = styled.div`
  padding: 1.5rem 1.5rem 1.75rem 1.5rem;
`;

export const QuickStartContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1.5rem 1.5rem 2rem 1.5rem;
  background-color: ${({ theme }) => theme.palette.fills.primaryHover};
  flex-grow: 1;
`;

export const QuickStartTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const QuickStartButtonContainer = styled.div`
  display: flex;
  padding-left: 2rem;
  align-items: center;
`;
