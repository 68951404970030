// components
import { Select } from 'components';
import { TypeOption } from './TypeOption';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// selectors
import {
  selectAppDetailsPolicyScrollToReceiverAccessControls,
  selectAppDetailsTabValidationEntry,
} from 'redux/selectors';

// actions
import { updatePolicyDetailsAcl, updateValidationStateEntry } from 'redux/actions/app';

// types
import { IPolicyAclType, IPolicyModifiedAcl } from 'types';

// helpers
import { aclToType, changeType } from '../../helpers';

// constants
import { ACLS_TYPES_CONFIG } from 'appConstants/policiesAcls';
import { POLICY_TRAFFIC_VALIDATION_STATE_PREFIX } from 'appConstants/validation';
import { css } from 'styled-components';

interface IType {
  acl: IPolicyModifiedAcl;
}

const ACLS_TYPES_DATA_SOURCE: { type: IPolicyAclType }[] = [
  ...(Object.keys(ACLS_TYPES_CONFIG) as IPolicyAclType[]),
].map(type => ({
  type: type as IPolicyAclType,
}));

export const Type = ({ acl }: IType) => {
  const { translate } = useTranslations('policies');

  const dispatch = useDispatch();

  const type = useMemo(() => aclToType({ acl }), [acl]);

  const validationState = useSelector(
    selectAppDetailsTabValidationEntry(POLICY_TRAFFIC_VALIDATION_STATE_PREFIX + acl.id)
  );
  const scrollToReceiverAccessControls = useSelector(
    selectAppDetailsPolicyScrollToReceiverAccessControls
  );

  const myRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    scrollToReceiverAccessControls && myRef.current?.focus();
  }, [scrollToReceiverAccessControls]);

  const setNewType = useCallback(
    ({ type }: { type: IPolicyAclType }) => {
      const newAcl = changeType({
        type,
        acl,
      });

      dispatch(
        updatePolicyDetailsAcl({
          id: acl.id,
          newProtocol: newAcl.protocol,
          newPorts: newAcl.ports,
          renewPortsUpdateStatus: newAcl.renewPortsUpdateStatus,
        })
      );
    },
    [acl, dispatch]
  );

  const onBlur = useCallback(() => {
    if (type === null) {
      dispatch(
        updateValidationStateEntry({ key: POLICY_TRAFFIC_VALIDATION_STATE_PREFIX + acl.id })
      );
    }
  }, [acl.id, dispatch, type]);

  return (
    <Select
      aria-label={translate('details.acls_table.aria_labels.type')}
      ref={myRef}
      data-test="policy-details-acl-type-select"
      dataSource={ACLS_TYPES_DATA_SOURCE}
      valueField="type"
      keyField="type"
      placeholder={translate('details.acls_table.type_placeholder')}
      OptionElement={option => {
        return <TypeOption type={option.type} data-test="policy-details-acl-type-select-option" />;
      }}
      setSelectedValue={option => {
        setNewType({ type: option.type });
      }}
      value={type || undefined}
      onBlur={onBlur}
      height="2rem"
      containerCss={css`
        flex: 10.5 10.5 0;
      `}
      defaultValueFieldValue={type || undefined}
      error={
        validationState?.updated && validationState?.state.error !== null
          ? { message: validationState.state.error.message }
          : null
      }
    />
  );
};
