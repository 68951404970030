// libs
import { css } from 'styled-components';

// components
import { CustomMessage, InternalLink } from 'components';
import { CustomText } from 'typography/Text';

// hooks
import { useSelector } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';

// selectors
import {
  selectCurrentOrganisationInfo,
  selectCurrentOrgSystems,
  selectOrgId,
} from 'redux/selectors';

// constants
import { PLANS_N_SUBS_ANCHOR, UNLIMITED_USES_AMOUNT } from 'appConstants';

export const UpgradePlanMessageBlock = () => {
  const { translate } = useTranslations('systems');

  const orgId = useSelector(selectOrgId);
  const systemsAmount = useSelector(selectCurrentOrgSystems);
  const { enrolledSystems, maxSystems } = useSelector(selectCurrentOrganisationInfo);

  if (systemsAmount.capacity === 0 || systemsAmount.enrolled < systemsAmount.capacity) return null;

  return maxSystems !== UNLIMITED_USES_AMOUNT && enrolledSystems >= maxSystems ? (
    <CustomMessage
      type="warning"
      message={
        <CustomText type="body-2">
          {translate('limit.reached_limit_left')}
          <InternalLink to={`/org/${orgId}/settings#${PLANS_N_SUBS_ANCHOR}`}>
            {translate('limit.upgrade_url')}
          </InternalLink>{' '}
          {translate('limit.reached_limit_right')}
        </CustomText>
      }
      withIcon
      css={css`
        margin-bottom: 1rem;
      `}
    />
  ) : null;
};
