type TextSizeType = { [index: number]: { fontSize: string; lineHeight: string } };

export const ITEMS_PER_TABLE_PAGE = 100;
export const ITEMS_PER_DNS_ZONES_PAGE = 50;
export const ITEMS_FOR_SKELETON = 8;
export const NOTIFICATION_POSITION = 'bottomLeft';
export const OFFLINE_RETRY_INTERVAL = 3000;
export const NOTIFICATION_MESSAGE_DURATION = 4.5;
export const TAG_SELECT_LINE_HEIGHT = 40;
export const PLANS_N_SUBS_ANCHOR = 'plans-n-subs';
export const NOTES_CHARACTERS_LIMIT = 1024;
export const TRIAL_EXPIRY_WARNING_DAYS = 7;

export const UI_LAYOUT_SIZES = {
  siderWidth: '15rem',
  headerHeight: '3.75rem',
  detailsNarrow: '37rem',
  detailsWide: '43rem',
  contentPaddingTop: '1.4375em',
  // it need to be in number because it's what antd accepts
  rowHorizonralGutter: 16,
  tableActionColumnWidth: '5.25rem',
  dnsZonesWidth: '17.5rem',
};

export const UI_TEXT_SIZES: TextSizeType = {
  1: {
    fontSize: '0.75rem',
    lineHeight: '1.25rem',
  },
  2: {
    fontSize: '0.875rem',
    lineHeight: '1.375rem',
  },
  3: {
    fontSize: '1rem',
    lineHeight: '1.5rem',
  },
  4: {
    fontSize: '1.25rem',
    lineHeight: '1.75rem',
  },
  5: {
    fontSize: '1.5rem',
    lineHeight: '2rem',
  },
  6: {
    fontSize: '1.75rem',
    lineHeight: '2.0625rem',
  },
  7: {
    fontSize: '1.875rem',
    lineHeight: '2.375rem',
  },
  8: {
    fontSize: '2.375rem',
    lineHeight: '2.875rem',
  },
  9: {
    fontSize: '2.875rem',
    lineHeight: '3.375rem',
  },
  10: {
    fontSize: '3.5rem',
    lineHeight: '4rem',
  },
};

export const UI_MEDIA_SIZES = {
  s3440: 3440 as const,
  s1920: 1920 as const,
  s1600: 1600 as const,
  s1440: 1440 as const,
  s1280: 1280 as const,
};

export const UI_MEDIA_QUERIES: Record<keyof typeof UI_MEDIA_SIZES, string> = {
  s3440: '@media(min-width: 215rem)',
  s1920: '@media(min-width: 120rem)',
  s1600: '@media(min-width: 100rem)',
  s1440: '@media(min-width: 90rem)',
  s1280: '@media(min-width: 80rem)',
};
