// Lib
import { put, select } from 'typed-redux-saga';
import { omit } from 'ramda';

// Actions
import { setDetailsContent } from 'redux/actions/app';
import { replace } from 'redux/actions/router';

// Selectors
import { selectPathName } from 'redux/selectors';

// Types
import { IActionPayload, DNSRecordDetailsPayload, IRecordCreateDetails } from 'types';

//Utils
import { ApiTypeCaller } from 'redux/sagas/api/utils';

// Constants
import { DEFAULT_DNS_RECORD_TYPE, DNS_RECORD_INITIAL_VALIDATION_STATE } from 'appConstants';

const recordRegex = /(\/record\/\d+)/;

export function* createRecordDetailsSaga({
  payload: { zoneId, zoneName, id },
}: IActionPayload<DNSRecordDetailsPayload>) {
  const pathName = yield* select(selectPathName);

  if (new RegExp(recordRegex).test(pathName)) {
    yield put(replace([pathName.replace(recordRegex, '')]));
  }

  let dataNew: IRecordCreateDetails = {
    fqdn: '',
    name: '',
    notes: null,
    systems: [],
    tags: [],
    type: DEFAULT_DNS_RECORD_TYPE,
    zoneId,
    zoneName,
  };

  if (id) {
    const { result } = yield* ApiTypeCaller('getDnsRecordDetails', {
      recordId: String(id),
    });

    if (result) {
      if (result.autoGenerated) {
        dataNew = { ...omit(['id', 'created'], result), name: result.name, systems: [], tags: [] };
      } else {
        const newName = `${result?.name}-copy`;
        dataNew = { ...omit(['id', 'created'], result), name: newName };
      }
    }
  }

  yield put(
    setDetailsContent({
      type: 'dns-record-create',
      dataNew,
      validationState: DNS_RECORD_INITIAL_VALIDATION_STATE,
    })
  );
}
