// libs
import { call, put, select } from 'typed-redux-saga';

// actions
import {
  closeDetails,
  setModalIsLoading,
  pushNotification,
  setModalContent,
} from 'redux/actions/app';
import { setEnrolmentKeysShouldUpdate } from 'redux/actions/enrolment-keys';

// selectors
import { selectAppDetailsEnrolmentKeyId } from 'redux/selectors';

// types
import { IActionPayload, INotificationType, IDeleteEnrolmentKeyProps } from 'types';

// utils
import { ApiTypeCaller } from '../../api/utils';
import { translator } from 'utils';

// sagas
import { getCurrentOrganisationSaga } from '../organisation/getCurrentOrganisationSaga';

export function* deleteEnrolmentKeySaga({
  payload: { enrolmentKeyId },
}: IActionPayload<IDeleteEnrolmentKeyProps>) {
  yield put(setModalIsLoading(true));

  const selectedEnrolmentKeyId = yield* select(selectAppDetailsEnrolmentKeyId);

  if (selectedEnrolmentKeyId !== undefined && enrolmentKeyId === String(selectedEnrolmentKeyId)) {
    yield* put(closeDetails({}));
  }

  const { result, success } = yield* ApiTypeCaller('deleteEnrolmentKey', { enrolmentKeyId });

  if (success && result) {
    const translate = translator('notifications');

    yield put(setEnrolmentKeysShouldUpdate());
    yield put(setModalContent({ type: null, data: null }));
    yield call(getCurrentOrganisationSaga);
    yield put(
      pushNotification({
        showType: INotificationType.Success,
        description: translate('enrolment_key_removed', {
          keyId: enrolmentKeyId,
        }),
      })
    );
  }
  yield put(setModalIsLoading(false));
}
