// libs
import styled, { css, useTheme } from 'styled-components';
import { memo } from 'react';

// components
import Skeleton from 'react-loading-skeleton';
import { CustomText } from 'typography/Text';
import { StyledRow, StyledCol, ControllableOSIcon } from 'components';

// utils
import { equals } from 'ramda';

// types
import { IIconState, ISystemSummaryModel, OSTypes } from 'types';

interface IDescription {
  description: string;
  isEnabled?: boolean;
  isLoading: boolean;
  icon: { osVersion: OSTypes; state: IIconState };
  type: ISystemSummaryModel['systemType'];
}

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

export const Description = memo(
  ({ description, isEnabled = true, type, icon, isLoading, ...rest }: IDescription) => {
    const theme = useTheme();

    return (
      <>
        {isLoading ? (
          <Skeleton />
        ) : (
          <StyledRow>
            <StyledCol $asFlex={true} $middle={true} $justify="flex-start">
              <DescriptionContainer>
                <ControllableOSIcon
                  size="large"
                  platformType={icon.osVersion}
                  state={icon.state}
                  ephemeral={type === 'Ephemeral'}
                />
                <CustomText
                  ellipsis={true}
                  title={description}
                  css={css`
                    display: block;
                    margin-left: 2rem;
                  `}
                  type="body-2"
                  color={isEnabled ? theme.palette.fonts.bodyDark : theme.palette.fonts.disabled}
                  {...rest}
                >
                  {description}
                </CustomText>
              </DescriptionContainer>
            </StyledCol>
          </StyledRow>
        )}
      </>
    );
  },
  equals
);
