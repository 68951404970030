import { IconModalClose } from 'assets/svg';
import React from 'react';
import { css, useTheme } from 'styled-components';
import { CustomText } from 'typography/Text';
import { HeaderContainer, TitleIconWrapper } from './styled';
import { IconButton } from 'components';

interface IHeader {
  icon: React.ReactNode;
  title: string;
  withCloseButton?: boolean;
  onClose?: () => void;
}
export const ModalHeader = ({ icon, title, withCloseButton, onClose, ...rest }: IHeader) => {
  const {
    palette: { fonts },
  } = useTheme();
  return (
    <HeaderContainer {...rest}>
      <TitleIconWrapper>
        {icon}
        <CustomText
          css={css`
            margin-left: 1rem;
          `}
          color={fonts.title}
          type="heading-2"
          ellipsis={true}
          title={title}
        >
          {title}
        </CustomText>
      </TitleIconWrapper>
      {withCloseButton && (
        <IconButton
          icon={<IconModalClose width="1.5rem" height="1.5rem" onClick={() => onClose?.()} />}
        />
      )}
    </HeaderContainer>
  );
};
