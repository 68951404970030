// components
import { Empty } from './Empty';
import { Suggestion } from './Suggestion';

// hooks
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// actions
import { getEnrolmentKeys, setEnrolmentKeys } from 'redux/actions/enrolment-keys';

// selectors
import { selectEnrolmentKeys } from 'redux/selectors';

// constants
import { initialEnrolmentKeysResponse } from 'redux/reducers/enrolment-keys';

// types
import { ISearchKey } from 'types';
import { IRange, ISearchQueryDetail } from '../helpers';
import { IInputArrowConfig, ISelectedIndex } from '..';

// utils
import { debounce } from 'utils';

interface IEnrolmentKeySuggestions {
  keyName: ISearchKey['name'];
  canHaveMultiple: ISearchKey['canHaveMultiple'];
  existingValues: ISearchQueryDetail['values'];
  searchValue: string | null;
  addValue: (newValue: string) => void;
  setInputArrowNavigationConfig: (config: IInputArrowConfig) => void;
  selectedIndex: ISelectedIndex;
  range: IRange | null;
}

const SEARCH_FIELD = 'description';

export const EnrolmentKeySuggestions = ({
  searchValue,
  keyName,
  addValue,
  canHaveMultiple,
  existingValues,
  setInputArrowNavigationConfig,
  selectedIndex,
  range,
}: IEnrolmentKeySuggestions) => {
  const dispatch = useDispatch();

  const enrolmentKeys = useSelector(selectEnrolmentKeys);

  // making sure not to show selected values in suggestions
  const filteredEnrolmentKeys = useMemo(
    () =>
      canHaveMultiple
        ? enrolmentKeys.filter(enrolmentKey => {
            if (
              existingValues.some(
                existingValue => existingValue.value === enrolmentKey[SEARCH_FIELD]
              )
            ) {
              return false;
            }
            return true;
          })
        : enrolmentKeys,
    [canHaveMultiple, enrolmentKeys, existingValues]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchKeys = useCallback(
    debounce((newSearchValue: string) => {
      dispatch(
        getEnrolmentKeys({
          search: newSearchValue,
          syncQueryParams: false,
        })
      );
    }, 250),
    []
  );

  useEffect(() => {
    searchValue !== null && searchKeys(searchValue);
  }, [dispatch, searchKeys, searchValue]);

  useEffect(() => {
    return () => {
      dispatch(setEnrolmentKeys(initialEnrolmentKeysResponse));
    };
  }, [dispatch]);

  // for navigation input with UP and DOWN arrows
  useEffect(() => {
    setInputArrowNavigationConfig({
      length: filteredEnrolmentKeys.length,
      onEnter: () =>
        selectedIndex !== null &&
        filteredEnrolmentKeys?.[selectedIndex] &&
        addValue(filteredEnrolmentKeys[selectedIndex][SEARCH_FIELD]),
      isKeyInactive: false,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIndex, setInputArrowNavigationConfig, range, filteredEnrolmentKeys.length]);

  return (
    <>
      {filteredEnrolmentKeys.length ? (
        filteredEnrolmentKeys.map((enrolmentKey, index) => {
          return (
            <Suggestion
              isSelected={index === selectedIndex}
              key={enrolmentKey.id}
              keyName={keyName}
              value={enrolmentKey.description}
              onClick={() => addValue(enrolmentKey[SEARCH_FIELD])}
            />
          );
        })
      ) : (
        <Empty searchKeyName={keyName} />
      )}
    </>
  );
};
