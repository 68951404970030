import { useTheme } from 'styled-components';
import { ThemeMap } from './styled';

export type IButtonTypes =
  | 'primary'
  | 'primaryHoverLight'
  | 'secondary'
  | 'primaryDanger'
  | 'secondaryDanger'
  | 'disabled'
  | 'link';

export const useStylesMap = (kind: IButtonTypes): ThemeMap => {
  const theme = useTheme();

  const themeMap: Record<IButtonTypes, ThemeMap> = {
    primary: {
      background: theme.palette.fills.primary,
      backgroundHover: theme.palette.fills.primaryHover,
      backgroundFocus: theme.palette.fills.primaryHover,
      backgroundPressed: theme.palette.fills.primaryPressed,
      borderColor: theme.palette.fills.primary,
      borderColorHover: theme.palette.fills.primaryHover,
      borderColorFocus: theme.palette.fills.primaryHover,
      borderColorPressed: theme.palette.fills.primaryPressed,
      color: theme.palette.fonts.primaryButton,
      hoverBoxShadow: theme.palette.elevationShadows[100],
      focusBoxShadow: theme.palette.elevationShadows[100],
      pressedBoxShadow: theme.palette.elevationShadows[100],
    },
    primaryHoverLight: {
      background: theme.palette.fills.primary,
      backgroundHover: theme.palette.fills.stroke,
      backgroundFocus: theme.palette.fills.stroke,
      backgroundPressed: theme.palette.fills.primaryPressed,
      borderColor: theme.palette.fills.primary,
      borderColorHover: theme.palette.fills.stroke,
      borderColorFocus: theme.palette.fills.stroke,
      borderColorPressed: theme.palette.fills.primaryPressed,
      color: theme.palette.fonts.primaryButton,
      hoverBoxShadow: theme.palette.elevationShadows[100],
      focusBoxShadow: theme.palette.elevationShadows[100],
      pressedBoxShadow: theme.palette.elevationShadows[100],
    },
    secondary: {
      background: theme.palette.background.panel,
      backgroundHover: theme.palette.background.hover,
      backgroundFocus: theme.palette.background.hover,
      backgroundPressed: theme.palette.background.panel,
      borderColor: theme.palette.fills.primary,
      borderColorHover: theme.palette.fills.primaryHover,
      borderColorFocus: theme.palette.fills.primaryHover,
      borderColorPressed: theme.palette.fills.primaryPressed,
      color: theme.palette.fills.primary,
      hoverBoxShadow: theme.palette.elevationShadows[100],
      focusBoxShadow: theme.palette.elevationShadows[100],
      pressedBoxShadow: theme.palette.elevationShadows[100],
    },
    primaryDanger: {
      background: theme.palette.state.error,
      backgroundHover: theme.palette.state.errorHover,
      backgroundFocus: theme.palette.state.errorHover,
      backgroundPressed: theme.palette.state.errorPressed,
      borderColor: theme.palette.state.error,
      borderColorHover: theme.palette.state.errorHover,
      borderColorFocus: theme.palette.state.errorHover,
      borderColorPressed: theme.palette.state.errorPressed,
      color: theme.palette.fonts.primaryButton,
      hoverBoxShadow: theme.palette.elevationShadows[100],
      focusBoxShadow: theme.palette.elevationShadows[100],
      pressedBoxShadow: theme.palette.elevationShadows[100],
    },
    secondaryDanger: {
      background: theme.palette.background.panel,
      backgroundHover: theme.palette.state.errorBackground,
      backgroundFocus: theme.palette.state.errorBackground,
      backgroundPressed: theme.palette.background.panel,
      borderColor: theme.palette.state.error,
      borderColorHover: theme.palette.state.errorHover,
      borderColorFocus: theme.palette.state.errorHover,
      borderColorPressed: theme.palette.state.errorPressed,
      color: theme.palette.state.error,
      hoverBoxShadow: theme.palette.elevationShadows[100],
      focusBoxShadow: theme.palette.elevationShadows[100],
      pressedBoxShadow: theme.palette.elevationShadows[100],
    },
    disabled: {
      background: theme.palette.background.sections,
      backgroundHover: theme.palette.background.sections,
      backgroundFocus: theme.palette.background.sections,
      backgroundPressed: theme.palette.background.sections,
      borderColor: theme.palette.fills.strokeMedium,
      borderColorHover: theme.palette.fills.strokeMedium,
      borderColorFocus: theme.palette.fills.strokeMedium,
      borderColorPressed: theme.palette.fills.strokeMedium,
      color: theme.palette.fills.strokeMedium,
      hoverBoxShadow: 'none',
      focusBoxShadow: 'none',
      pressedBoxShadow: 'none',
    },
    link: {
      background: theme.palette.background.panel,
      backgroundHover: theme.palette.background.panel,
      backgroundFocus: theme.palette.background.panel,
      backgroundPressed: theme.palette.background.panel,
      borderColor: 'none',
      borderColorHover: 'none',
      borderColorFocus: 'none',
      borderColorPressed: 'none',
      color: theme.palette.fills.primary,
      hoverBoxShadow: 'none',
      focusBoxShadow: 'none',
      pressedBoxShadow: 'none',
    },
  };

  return themeMap[kind];
};
