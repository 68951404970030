// libs
import { css } from 'styled-components';

// components
import { Details } from 'components';
import { TagsView } from './View';
import { TagsEdit } from './Edit';

// selectors
import { selectAppDetailsTabType } from 'redux/selectors';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { useSelector } from 'react-redux';

export const TagsBlock = () => {
  const { translate } = useTranslations('systems');

  const type = useSelector(selectAppDetailsTabType);

  return (
    <>
      <Details.KeyValue
        css={css`
          box-sizing: content-box;

          padding-bottom: 1.375rem;
        `}
        descriptionCss={css`
          ${(type === 'system-edit' || type === 'unapproved-system-edit') &&
          css`
            margin-top: -0.5rem;
          `}
        `}
        height="auto"
        title={translate('details.tags')}
      >
        {(type === 'system-view' || type === 'unapproved-system-view') && <TagsView />}
        {(type === 'system-edit' || type === 'unapproved-system-edit') && <TagsEdit />}
      </Details.KeyValue>
    </>
  );
};
