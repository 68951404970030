import { User, UserManager, UserManagerSettings } from 'oidc-client';

import { OIDCConstants, REGISTER_URL, SILENT_RENEW_OUTSIDE_COUNT_MAX } from 'appConstants';
import { removeLsItemsByPrefix } from 'utils/removeLsItemsByPrefix';

export class AuthService {
  userManager: UserManager;
  public userManagerInit: UserManager;
  private visible: boolean;
  private idleRenewCounter: number;

  constructor() {
    if (!window) {
      throw new Error('Window object does not exists');
    }
    const origin = window.location.origin;
    const settings: UserManagerSettings = {
      authority: OIDCConstants.authority,
      client_id: OIDCConstants.clientId,
      redirect_uri: `${origin}/${OIDCConstants.oidcRedirect}`,
      silent_redirect_uri: `${origin}/${OIDCConstants.oidcSilentRedirect}`,
      post_logout_redirect_uri: `${origin}/${OIDCConstants.oidcLogoutRedirect}`,
      response_type: 'code',
      scope: OIDCConstants.clientScope,
      automaticSilentRenew: false,
      monitorSession: true,
      checkSessionInterval: 10_000,
    };

    this.visible = true;
    this.idleRenewCounter = 0;

    this.userManager = new UserManager({ ...settings });
    this.userManagerInit = new UserManager({ response_mode: 'query' });

    this.userManager.events.addAccessTokenExpiring(() => {
      console.log('Token expiring...');

      if (!this.visible) {
        if (this.idleRenewCounter < SILENT_RENEW_OUTSIDE_COUNT_MAX) {
          this.idleRenewCounter++;

          this.initiateSilentRenew();
        } else {
        }
      } else {
        this.initiateSilentRenew();
      }
    });

    this.userManager.events.addUserSessionChanged(() => {
      this.silentRenewIfNoToken();
    });
  }

  initiateSilentRenew = () => {
    this.userManager
      .signinSilent()
      .then(() => {
        console.log('silent token renew success');
      })
      .catch(() => {
        this.login();
      });
  };

  setVisible = (state: boolean): void => {
    this.visible = state;
    if (state) {
      this.idleRenewCounter = 0;

      this.silentRenewIfNoToken();
    }
  };

  silentRenewIfNoToken = (): void => {
    this.getUser().then(u => {
      // Only do the silent renew when we become visible again if the
      // access token has expired, or is going to expire in less than a minute.
      if (!u || u.expired || u.expires_in < 60) {
        this.initiateSilentRenew();
      }
    });
  };

  getUser = async (): Promise<User | null> => this.userManager.getUser();

  login = async (): Promise<void> => {
    const extraQueryParams: Record<string, string> = {};
    const OIDCParams: Record<string, unknown> = {
      state: `${window.location.pathname}${window.location.search}`,
    };

    for (const item of new URLSearchParams(window.location.search).entries()) {
      extraQueryParams['qs_' + decodeURIComponent(item[0])] = decodeURIComponent(item[1]);
    }

    if (window.location.pathname === REGISTER_URL) {
      extraQueryParams.registerLanding = '1';
    }

    OIDCParams.extraQueryParams = extraQueryParams;

    return this.userManager.signinRedirect(OIDCParams);
  };

  loginSilent = async (): Promise<User | null> => this.userManager.signinSilent();

  handleSilentTokenRenew = async (): Promise<User | undefined> => {
    return this.userManager.signinSilentCallback();
  };

  handleNormalAuthRedirect = async () => this.userManagerInit.signinRedirectCallback();

  logout = async (): Promise<void> => {
    removeLsItemsByPrefix('oidc');

    sessionStorage.clear();
    this.userManager.signoutRedirect();
  };
}
