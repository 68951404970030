// libs
import { css, useTheme } from 'styled-components';
import dayjs from 'dayjs';

// components
import { Trans } from 'react-i18next';
import { InternalLink } from 'components/Links/InternalLink';
import { ExternalLink } from 'components';
import { IconTrialError, IconTrialInfo, IconTrialWarning } from 'assets/svg';
import { Bold } from '../styled';

// hooks
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';

// selectors
import { selectAccountPartnerAccess, selectCurrentOrganisationPartnerId } from 'redux/selectors';

// constants
import {
  LINK_TO_QUICK_START_GUIDE,
  LINK_TO_SALES_MAIL,
  LINK_TO_SUPPORT_MAIL,
  ROUTE_PATHS,
  TRIAL_EXPIRY_WARNING_DAYS,
} from 'appConstants';

//  types
import {
  AccountOrganisationModel,
  OrganisationPartnerModel,
  OrganisationPropertiesModel,
} from 'types';

export interface IData {
  type: 'info' | 'warning' | 'error';
  id: string;
  title: string;
  description: string | React.ReactNode;
  partnerId: OrganisationPartnerModel['partnerId'] | undefined | null;
  partnerAccess: AccountOrganisationModel['partnerAccess'] | null;
}

interface IType {
  background: string;
  border: string;
  closeIcon: string;
  Icon: React.ReactNode;
}

interface IUseData {
  orgId: OrganisationPropertiesModel['id'];
  trialState: OrganisationPropertiesModel['trialState'];
  trialExpiry: OrganisationPropertiesModel['trialExpiry'];
  suspendedByAdmin: OrganisationPropertiesModel['isSuspendedByAdmin'];
}

export const useTrialBannerData = ({
  orgId,
  trialState,
  trialExpiry,
  suspendedByAdmin,
}: IUseData) => {
  const { translate } = useTranslations('global');

  const partnerId = useSelector(selectCurrentOrganisationPartnerId);
  const partnerAccess = useSelector(selectAccountPartnerAccess);

  const translatePartnerFolder = useMemo(() => {
    if (partnerId && partnerAccess) {
      return 'partner_access';
    }
    if (partnerId && !partnerAccess) {
      return 'partner_no_access';
    }
    return 'default';
  }, [partnerAccess, partnerId]);

  const { palette } = useTheme();

  const data: IData | null = useMemo(() => {
    if (trialState === 'None') return null;

    if (suspendedByAdmin) {
      return {
        id: `partner.${translatePartnerFolder}-69`,
        type: 'error',
        title: translate(`trial_banner.${translatePartnerFolder}.error_trial_suspended_title`),
        partnerId,
        partnerAccess,
        description: (
          <Trans
            i18nKey={translate(
              `trial_banner.${translatePartnerFolder}.error_trial_suspended_description`
            )}
            components={{
              a: <ExternalLink textType="body-2" href={LINK_TO_SUPPORT_MAIL} />,
            }}
          />
        ),
      };
    }

    if (trialState === 'NotStarted') {
      return {
        id: `partner.${translatePartnerFolder}-1`,
        type: 'info',
        partnerId,
        partnerAccess,
        title: translate(`trial_banner.${translatePartnerFolder}.info_trial_ready_title`),
        description: (
          <Trans
            i18nKey={translate(
              `trial_banner.${translatePartnerFolder}.info_trial_ready_description`
            )}
            components={{
              a: (
                <InternalLink textType="body-2" to={`/org/${orgId}${ROUTE_PATHS['new-system']}`} />
              ),
            }}
          />
        ),
      };
    }

    if (trialState === 'Active') {
      const daysDiff = dayjs(trialExpiry).diff(dayjs(), 'd', true);
      const daysBeforeExpiry = Math.round(daysDiff);

      const count = daysDiff < 1 ? 0 : daysBeforeExpiry;

      if (trialExpiry && daysBeforeExpiry <= TRIAL_EXPIRY_WARNING_DAYS) {
        return {
          id: `partner.${translatePartnerFolder}-2`,
          type: 'warning',
          partnerId,
          partnerAccess,
          title: translate(`trial_banner.${translatePartnerFolder}.warning_trial_ends_soon_title`),
          description: (
            <Trans
              i18nKey={translate(
                `trial_banner.${translatePartnerFolder}.warning_trial_ends_soon_description`,
                {
                  count,
                }
              )}
              components={{
                a: <ExternalLink textType="body-2" href={LINK_TO_SALES_MAIL} />,
              }}
            />
          ),
        };
      } else {
        return {
          id: `partner.${translatePartnerFolder}-3`,
          type: 'info',
          partnerId,
          partnerAccess,
          title: translate(`trial_banner.${translatePartnerFolder}.info_trial_currently_title`),
          description: (
            <Trans
              i18nKey={translate(
                `trial_banner.${translatePartnerFolder}.info_trial_currently_description`
              )}
              values={{ days: daysBeforeExpiry }}
              components={{
                bold: <Bold />,
                a: <ExternalLink textType="body-2" href={LINK_TO_QUICK_START_GUIDE} />,
              }}
            />
          ),
        };
      }
    }

    if (trialState === 'Suspended') {
      return {
        id: `partner.${translatePartnerFolder}-4`,
        type: 'error',
        partnerId,
        partnerAccess,
        title: translate(`trial_banner.${translatePartnerFolder}.error_trial_ended_title`),
        description: (
          <Trans
            i18nKey={translate(
              `trial_banner.${translatePartnerFolder}.error_trial_ended_description`
            )}
            components={{
              a: <ExternalLink textType="body-2" href={LINK_TO_SALES_MAIL} />,
            }}
          />
        ),
      };
    }

    return null;
  }, [
    orgId,
    partnerAccess,
    partnerId,
    suspendedByAdmin,
    translate,
    translatePartnerFolder,
    trialExpiry,
    trialState,
  ]);

  const TrialTypes: Record<IData['type'], IType> = useMemo(
    () => ({
      info: {
        background: palette.background.hover,
        border: palette.fills.iconHover,
        closeIcon: palette.icons.primary,
        Icon: (
          <IconTrialInfo
            width="2.5rem"
            height="2.5rem"
            css={css`
              path {
                fill: ${palette.icons.primary};
              }
            `}
          />
        ),
      },
      warning: {
        background: palette.state.alertBackground,
        border: palette.state.alertStroke,
        closeIcon: palette.state.alert,
        Icon: <IconTrialWarning width="2.5rem" height="2.5rem" />,
      },
      error: {
        background: palette.state.errorBackground,
        border: palette.state.errorStroke,
        closeIcon: palette.state.error,
        Icon: <IconTrialError width="2.5rem" height="2.5rem" />,
      },
    }),
    [palette]
  );

  return { data, TrialTypes };
};
