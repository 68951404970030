// libs
import { css, useTheme } from 'styled-components';

// components
import { Select } from 'components';

// hooks
import { useDispatch } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';
import { useCallback, useEffect, useState } from 'react';

// actions
import { updateSystemDetailsRoute } from 'redux/actions/app';

// types
import { ISystemGatewayRouteModified } from 'types';
import { IconSystemsDetailsPreferred } from 'assets/svg';
import { CustomText } from 'typography/Text';
import { SelectIconContainer } from './styled';

interface ISubnetName {
  route: ISystemGatewayRouteModified;
}

export const PreferredSelector = ({ route }: ISubnetName) => {
  const dispatch = useDispatch();

  const { translate } = useTranslations('systems');

  const [value, setValue] = useState(route.weight);

  useEffect(() => {
    setValue(route.weight);
  }, [route.weight]);

  const GATEWAY_PRIORITY_OPTIONS = [
    { weight: 0, text: translate('details.gateway.priority_normal') },
    {
      weight: 100,
      text: translate('details.gateway.priority_preferred'),
      hint: translate('details.gateway.priority_preferred_hint'),
      icon: <IconSystemsDetailsPreferred />,
    },
  ];

  const {
    palette: { fonts },
  } = useTheme();

  const onChange = useCallback(
    (weight: number) => {
      setValue(weight);

      dispatch(
        updateSystemDetailsRoute({
          id: route.id,
          name: route.name,
          subnet: route.subnet,
          weight: weight,
        })
      );
    },
    [route, dispatch]
  );

  return (
    <Select
      aria-label={translate('details.aria_labels.remove_after')}
      height="2rem"
      dataSource={GATEWAY_PRIORITY_OPTIONS}
      valueField="weight"
      keyField="weight"
      width="15rem"
      // Hide the hint text when the option is selected.
      css={css`
        .ant-select-selection-item .select-hint {
          display: none;
        }
      `}
      allowOptionWrap={true}
      OptionElement={option => {
        return (
          <>
            <CustomText
              css={css`
                display: block;
              `}
              type="body-2"
              color={fonts.title}
            >
              {option.text}
              {option.icon && <SelectIconContainer>{option.icon}</SelectIconContainer>}
            </CustomText>
            {option.hint && (
              <CustomText
                className="select-hint"
                type="hint"
                css={css`
                  width: 12rem;
                `}
                color={fonts.help}
              >
                {option.hint}
              </CustomText>
            )}
          </>
        );
      }}
      setSelectedValue={v => onChange(v.weight)}
      defaultValueFieldValue={value}
    />
    /*
    <CustomToggle
      enabled={value > 0}
      onClick={onClick}
      checkedChildren={
        <IconSystemsDetailsPreferred
          css={css`
            path {
              fill: white;
            }
            margin-top: 0.02rem;
            margin-bottom: 0.02rem;
          `}
        />
      }
      unCheckedChildren={
        <IconSystemsDetailsPreferred
          css={css`
            margin-top: 0.02rem;
            margin-bottom: 0.02rem;
          `}
        />
      }
      checked={route.weight > 0}
    />*/
  );
};
