import { createSelector } from '@reduxjs/toolkit';
import { noErrorsInDetails } from 'utils';
import { MainState } from 'types';
import { pick } from 'ramda';

export const selectAppDetailsTabType = (state: MainState) => state.App.detailsTab.type;
export const selectAppDetailsTabData = (state: MainState) => state.App.detailsTab.data;

export const selectAppDetailsTabIsLoading = (state: MainState) => state.App.detailsTab.isLoading;
export const selectAppDetailsTabDataNew = (state: MainState) => state.App.detailsTab.dataNew;

export const selectAppDetailsTabValidationState = (state: MainState) => {
  if (
    state.App.detailsTab.type === 'enrolment-key-edit' ||
    state.App.detailsTab.type === 'enrolment-key-create' ||
    state.App.detailsTab.type === 'tag-edit' ||
    state.App.detailsTab.type === 'tag-create' ||
    state.App.detailsTab.type === 'policy-edit' ||
    state.App.detailsTab.type === 'policy-create' ||
    state.App.detailsTab.type === 'system-edit' ||
    state.App.detailsTab.type === 'unapproved-system-edit' ||
    state.App.detailsTab.type === 'dns-record-edit' ||
    state.App.detailsTab.type === 'dns-record-create' ||
    state.App.detailsTab.type === 'trust-requirement-edit' ||
    state.App.detailsTab.type === 'trust-requirement-create'
  ) {
    return state.App.detailsTab.validationState;
  }
};

export const selectAppDetailsTabIsValid = createSelector(
  selectAppDetailsTabValidationState,
  noErrorsInDetails
);

export const selectAppDetailsTabValidationStateEntries = (keys: string[]) => (state: MainState) => {
  if (
    state.App.detailsTab.type === 'enrolment-key-edit' ||
    state.App.detailsTab.type === 'enrolment-key-create' ||
    state.App.detailsTab.type === 'tag-edit' ||
    state.App.detailsTab.type === 'tag-create' ||
    state.App.detailsTab.type === 'policy-edit' ||
    state.App.detailsTab.type === 'policy-create' ||
    state.App.detailsTab.type === 'system-edit' ||
    state.App.detailsTab.type === 'unapproved-system-edit' ||
    state.App.detailsTab.type === 'dns-record-edit' ||
    state.App.detailsTab.type === 'dns-record-create' ||
    state.App.detailsTab.type === 'trust-requirement-edit' ||
    state.App.detailsTab.type === 'trust-requirement-create'
  ) {
    return pick(keys, state.App.detailsTab.validationState);
  }
};

export const selectAppDetailsTabValidationEntry =
  (key: string | undefined) => (state: MainState) => {
    if (
      (state.App.detailsTab.type === 'enrolment-key-edit' ||
        state.App.detailsTab.type === 'enrolment-key-create' ||
        state.App.detailsTab.type === 'tag-edit' ||
        state.App.detailsTab.type === 'tag-create' ||
        state.App.detailsTab.type === 'policy-edit' ||
        state.App.detailsTab.type === 'policy-create' ||
        state.App.detailsTab.type === 'system-edit' ||
        state.App.detailsTab.type === 'unapproved-system-edit' ||
        state.App.detailsTab.type === 'dns-record-edit' ||
        state.App.detailsTab.type === 'dns-record-create' ||
        state.App.detailsTab.type === 'trust-requirement-edit' ||
        state.App.detailsTab.type === 'trust-requirement-create') &&
      key !== undefined
    ) {
      return state.App.detailsTab.validationState[key];
    }
  };

export const selectAppDetailsTabValidationIsUpdated =
  (key: string | undefined) => (state: MainState) => {
    if (
      (state.App.detailsTab.type === 'enrolment-key-edit' ||
        state.App.detailsTab.type === 'enrolment-key-create' ||
        state.App.detailsTab.type === 'tag-edit' ||
        state.App.detailsTab.type === 'tag-create' ||
        state.App.detailsTab.type === 'policy-edit' ||
        state.App.detailsTab.type === 'policy-create' ||
        state.App.detailsTab.type === 'system-edit' ||
        state.App.detailsTab.type === 'unapproved-system-edit' ||
        state.App.detailsTab.type === 'dns-record-edit' ||
        state.App.detailsTab.type === 'dns-record-create' ||
        state.App.detailsTab.type === 'trust-requirement-edit' ||
        state.App.detailsTab.type === 'trust-requirement-create') &&
      key !== undefined
    ) {
      return (
        state.App.detailsTab.validationState[key] &&
        state.App.detailsTab.validationState[key].updated
      );
    }
  };
