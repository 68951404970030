// libs
import styled, { css } from 'styled-components';

// components
import { IconPoliciesDetailsDeleteTrustRequirement } from 'assets/svg';

interface IDeleteTrustRequirementButton {
  onClick: () => void;
}

const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const DeleteTrustRequirementButton = ({
  onClick,
  ...rest
}: IDeleteTrustRequirementButton) => {
  return (
    <Container>
      <IconPoliciesDetailsDeleteTrustRequirement
        onClick={onClick}
        css={css`
          cursor: pointer;
          margin-left: 1rem;
        `}
        {...rest}
      />
    </Container>
  );
};
