// libs
import { css } from 'styled-components';

// components
import { Select } from 'components';
import { OptionElement } from './OptionElement';
import { Container } from './styled';
import { DeleteSystemButton } from '../DeleteSystemButton';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSystemsEmptyText } from 'modules/Systems/hooks/useSystemsEmptyText';

// actions
import { getSystems, setSystems } from 'redux/actions/systems';

// selectors
import { selectAppDetailsDnsNewSystems, selectApprovedSystems } from 'redux/selectors';

// constants
import { initialSystemsResponse } from 'redux/reducers/systems';
import { ITEMS_PER_TABLE_PAGE } from 'appConstants';

// types
import { ISystemSummaryModel } from 'types';

// utils
import { debounce } from 'utils';

interface ISelectSystem {
  onSelect: (system: ISystemSummaryModel) => void;
  onCancel: () => void;
}

export const SelectSystem = ({ onSelect, onCancel }: ISelectSystem) => {
  const dispatch = useDispatch();

  // it's already selected systems
  const systems = useSelector(selectAppDetailsDnsNewSystems);

  // it's query from which we select new systems,
  // we ensure that we don't show alraedy selected systems
  // and disabled systems
  const systemsQuery = useSelector(selectApprovedSystems)
    .filter(querySystem => systems?.every(system => system.id !== querySystem.systemId))
    .filter(querySystem => querySystem.isEnabled);

  const [inputText, setInputText] = useState('');

  const [searchValue, setSearchValue] = useState<null | {
    current: string;
    previous: string;
  }>(null);

  const ref = useRef<HTMLInputElement>(null);

  const fetchData = useCallback(
    () =>
      dispatch(
        getSystems({
          include_disabled: true,
          search: searchValue?.current ?? '',
          page: 0,
        })
      ),
    [dispatch, searchValue]
  );

  useEffect(() => {
    dispatch(fetchData());
  }, [dispatch, fetchData]);

  useEffect(() => {
    return () => {
      dispatch(setSystems(initialSystemsResponse));
    };
  }, [dispatch]);

  useEffect(() => {
    ref?.current?.focus();
  }, [ref]);

  const { translate } = useTranslations('dns');

  const setSearchValueDebounced = debounce((newSearchValue: string) => {
    setInputText(newSearchValue);
    if (
      systemsQuery.length === ITEMS_PER_TABLE_PAGE ||
      (searchValue && !newSearchValue.includes(searchValue.current))
    ) {
      setSearchValue({
        previous: searchValue?.current || ' ',
        current: newSearchValue,
      });
    }
  }, 250);

  const { emptyText } = useSystemsEmptyText({
    searchValue: inputText,
    css: css`
      padding-top: 0;
      height: 11.75rem;
      width: 100%;
    `,
  });

  return (
    <Container>
      <Select
        width="27.25rem"
        ref={ref}
        dataSource={systemsQuery}
        setSearchValue={setSearchValueDebounced}
        setSelectedValue={onSelect}
        showSearch={true}
        OptionElement={(record, _index, isSelected) => (
          <OptionElement record={record} isSelected={isSelected} />
        )}
        suffix={null}
        height="2.5rem"
        valueField={['description', 'systemId', 'hostname']}
        titleField="description"
        keyField="systemId"
        placeholder={translate('records.details.system_placeholder')}
        notFoundContent={emptyText}
      />
      <DeleteSystemButton onClick={onCancel} />
    </Container>
  );
};
