// libs
import { css } from 'styled-components';

// components
import { TagsList } from 'components';

// hooks
import { useSelector } from 'react-redux';

// selectors
import { selectAppDetailsDnsTags } from 'redux/selectors';

export const Tags = () => {
  const tags = useSelector(selectAppDetailsDnsTags) || [];

  return (
    <TagsList
      tags={tags}
      customTagCss={css`
        max-width: 100%;
      `}
      width="100%"
      blocksMode={true}
    />
  );
};
