// libs
import { call, select, all, put } from 'typed-redux-saga';
import { equals } from 'ramda';

// actions
import { setModalIsLoading, pushNotification } from 'redux/actions/app';

// selectors
import { selectAccountId, selectAccountMainOrganisation } from 'redux/selectors';

// types
import { IActionPayload, INotificationType, IRemoveOrganisationUserProps } from 'types';

// sagas
import { getOrganisationsSaga } from '../account/getOrganisationsSaga';
import { changeCurrentOrganisationSaga } from '../../business/organisation/changeCurrentOrgSaga';
import { redirectControlSaga } from '../app/redirectSaga/redirectContolSaga';
import { getOrganisationUsersSaga } from './getOrganisationUsersSaga';

// utils
import { ApiTypeCaller } from '../../api/utils';
import { translator } from 'utils';

export function* removeOrganisationUserSaga({
  payload,
}: IActionPayload<IRemoveOrganisationUserProps>) {
  yield put(setModalIsLoading(true));

  const translate = translator('notifications');

  const { result, success } = yield* ApiTypeCaller('removeOrganisationUser', payload);

  if (success && result) {
    const currentAccountId = yield* select(selectAccountId);
    const removedAccountId = payload.accountId;

    if (equals(currentAccountId, removedAccountId)) {
      const mainOrg = yield* select(selectAccountMainOrganisation);

      if (mainOrg) {
        yield* all([
          call(changeCurrentOrganisationSaga, { payload: mainOrg.orgId }),
          call(getOrganisationsSaga),
          call(redirectControlSaga, { payload: { route: '/' } }),
        ]);
      }
    } else {
      yield* call(getOrganisationUsersSaga);
    }

    yield put(
      pushNotification({
        description: translate('user_removed', { user: result.emailAddress }),
        showType: INotificationType.Success,
      })
    );
  }
  yield put(setModalIsLoading(false));
}
