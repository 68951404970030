import { translator } from '../utils/nonComponentTranslations';

const translate = translator('enrolment-keys');

export const DISCONNECTED_RETENTION_MINUTES_CONFIG = [
  { disconnectedRetentionMinutes: 1, text: translate('details.remove_after_time.1_min') },
  { disconnectedRetentionMinutes: 5, text: translate('details.remove_after_time.5_mins') },
  { disconnectedRetentionMinutes: 30, text: translate('details.remove_after_time.30_mins') },
  { disconnectedRetentionMinutes: 480, text: translate('details.remove_after_time.8_hours') },
  { disconnectedRetentionMinutes: 20160, text: translate('details.remove_after_time.2_weeks') },
  { disconnectedRetentionMinutes: 40320, text: translate('details.remove_after_time.1_month') },
];

export const DEFAULT_RETENTION_MINUTES = 30;
export const MAX_ENROLMENT_KEYS_USES_REMAINING = 999999;
