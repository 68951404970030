import styled from 'styled-components';
import { IProgressProps } from './index';

export const ProgressContainer = styled('div')<{
  sharp: boolean;
  active: boolean;
  backgroundColor?: string;
}>`
  background-color: ${({ active, backgroundColor, theme }) =>
    active && (backgroundColor || theme.palette.background.iconBackground)};
  position: relative;
  display: inline-block;
  width: 100%;
  overflow: hidden;
  vertical-align: middle;
  border-radius: ${({ sharp }) => (sharp ? '0' : '100px')};
`;

export const ProgressBG = styled('div')<Partial<IProgressProps>>`
  background-color: ${({ color }) => color};
  width: ${({ percent }) => (percent ? `${percent}%` : 0)};
  height: 0.5rem;
`;
