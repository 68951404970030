// components
import { Details } from 'components';

// hooks
import { useSelector } from 'react-redux';

// selectors
import { selectAppDetailsTabType } from 'redux/selectors';
import { StepInfo } from '../../Blocks/StepInfo';

export const TopMenuButtons = () => {
  const type = useSelector(selectAppDetailsTabType);

  return (
    <>
      {type === 'trust-requirement-create' && <StepInfo />}
      <Details.TopMenuButtons showEditLink={type === 'trust-requirement-view'} />
    </>
  );
};
