// libs
import styled, { css } from 'styled-components';

// components
import { CustomButton, Input, CustomButtonLink, Loader } from 'components';
import { CustomMessage } from 'components';
import { IconModalDelete } from 'assets/svg';
import { ModalFooter, ModalHeader, ModalScrollablePart } from 'components/Modal';
import { CustomText } from 'typography/Text';

// hooks
import { useSelector, useDispatch } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';
import { useSubmitOnEnter } from 'hooks/useSubmitOnEnter';
import { useEffect, useState, useRef, useCallback } from 'react';

// selectors
import { selectModalData, selectModalIsLoading } from 'redux/selectors';

// actions
import { setModalContent } from 'redux/actions/app';
import { deleteTrustRequirement, deleteTrustRequirements } from 'redux/actions/trust-requirements';

// types
import { IModalRemoveTrustRequirements } from 'types';

// constants
import { REMOVE_MODAL_CONFIRMATION_TEXT } from 'appConstants';

const List = styled.ul`
  padding-left: 1.75rem;
  margin-bottom: 2.5rem;
`;

export const RemoveTrustRequirementsModal = () => {
  const { translate } = useTranslations('trust-requirements');

  const dispatch = useDispatch();

  const [enabled, setEnabled] = useState(false);
  const [inputText, setInputText] = useState('');

  const modalData = useSelector(selectModalData) as IModalRemoveTrustRequirements['data'] | null;
  const isLoading = useSelector(selectModalIsLoading);

  const isButtonEnabled = enabled && !isLoading;

  const inputRef = useRef<HTMLInputElement>(null);

  const onClose = useCallback(() => {
    dispatch(setModalContent({ type: null, data: null }));
  }, [dispatch]);

  const onRemove = useCallback(() => {
    if (modalData && modalData.requirements.length > 1) {
      dispatch(
        deleteTrustRequirements({
          requirementIds: modalData?.requirements.map(requiremnet => requiremnet.id),
        })
      );
    }
    if (modalData && modalData.requirements.length === 1) {
      dispatch(
        deleteTrustRequirement({
          requirementId: String(modalData.requirements[0].id),
        })
      );
    }
  }, [dispatch, modalData]);

  useSubmitOnEnter({ callback: isButtonEnabled ? onRemove : () => {} });

  useEffect(() => {
    if (inputText === REMOVE_MODAL_CONFIRMATION_TEXT) {
      setEnabled(true);
    } else {
      if (enabled) setEnabled(false);
    }
  }, [inputText, enabled]);

  useEffect(() => {
    inputRef?.current?.focus();
  }, [inputRef]);

  return (
    <div data-test="trust-requirements-remove-modal">
      <ModalHeader
        title={translate('remove_requirements_modal.title', {
          count: modalData?.requirements.length,
        })}
        icon={<IconModalDelete width="2.5rem" height="2.5rem" />}
        withCloseButton={true}
        onClose={onClose}
      />
      <ModalScrollablePart>
        <CustomMessage
          type="error"
          message={translate('remove_requirements_modal.warning')}
          css={css`
            margin-bottom: 2.25rem;
          `}
        />
        <CustomText
          type="sub-heading-2"
          css={css`
            display: block;
            margin-bottom: 0.5rem;
          `}
        >
          {translate('remove_requirements_modal.selected_requirements', {
            requirementsAmount: modalData?.requirements.length || '',
          })}
        </CustomText>
        <List>
          {modalData?.requirements.map(requirement => {
            return <li key={requirement.id}>{requirement.name}</li>;
          })}
        </List>

        <Input
          ref={inputRef}
          data-test="trust-requirements-remove-modal-confirm-input-wrapper"
          type="text"
          name="yescheck"
          value={inputText}
          label={translate('remove_requirements_modal.input_label')}
          maxLength={128}
          placeholder={translate('remove_requirements_modal.input_placeholder')}
          onChange={e => setInputText(e.target.value.toUpperCase())}
          containerCss={css`
            margin-bottom: 0.75rem;
          `}
        />
      </ModalScrollablePart>
      <ModalFooter>
        <CustomButtonLink size="large" onClick={onClose}>
          {translate('remove_requirements_modal.cancel')}
        </CustomButtonLink>
        <CustomButton
          data-test="trust-requirements-remove-submit"
          width="6.75rem"
          size="large"
          kind={isButtonEnabled ? 'primaryDanger' : 'disabled'}
          onClick={onRemove}
        >
          {isLoading ? <Loader /> : translate('remove_requirements_modal.remove')}
        </CustomButton>
      </ModalFooter>
    </div>
  );
};
