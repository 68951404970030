// Lib
import { put, select } from 'typed-redux-saga';

// Actions
import { updateTagsId } from 'redux/actions/tags';

// Selectors
import { selectTagsItems } from 'redux/selectors';

export function* checkCreatedSaga(updatedTags: string[] | null | undefined) {
  const storeTags = yield* select(selectTagsItems);

  if (updatedTags) {
    const createdNewTags = updatedTags.some(
      updatedTag => !storeTags.find(({ tag }) => tag === updatedTag)
    );

    if (createdNewTags) {
      yield put(updateTagsId());
    }
  }
}
