import styled from 'styled-components';
import { IBadgeProps } from './index';

export const StyledBadge = styled.div<IBadgeProps>`
  position: relative;
  width: ${({ size }) => (size === 'medium' ? '1.875rem;' : '3.25rem')};
  min-width: ${({ size }) => (size === 'medium' ? '1.875rem;' : '3.25rem')};
  height: ${({ size }) => (size === 'medium' ? '1.875rem;' : '3.25rem')};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.palette.background.panel};
  text-transform: uppercase;
  border-radius: 0.3125rem;
`;

export const Circle = styled.div`
  position: absolute;
  box-sizing: content-box;
  right: -0.125rem;
  top: -0.125rem;
  width: 0.3125rem;
  height: 0.3125rem;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.state.alert};
  border: 0.0625rem solid ${({ theme }) => theme.palette.fonts.primaryButton};
`;
