// Lib
import { call, put, select } from 'typed-redux-saga';

// actions
import { setSystemsShouldUpdate } from 'redux/actions/systems';
import { closeDetails, setModalIsLoading, pushNotification } from 'redux/actions/app';

// selectors
import { selectAppDetailsSystemSystemId } from 'redux/selectors';

// types
import { IActionPayload, IRevokeSystemParams, INotificationType } from 'types';

// Utils
import { ApiTypeCaller } from '../../api/utils';
import { translator } from 'utils';

// sagas
import { getCurrentOrganisationSaga } from '../organisation/getCurrentOrganisationSaga';

export function* revokeSystemSaga({ payload: { systemId } }: IActionPayload<IRevokeSystemParams>) {
  yield put(setModalIsLoading(true));

  const selectedSystemId = yield* select(selectAppDetailsSystemSystemId);

  if (selectedSystemId !== undefined && systemId === selectedSystemId) {
    yield* put(closeDetails({}));
  }

  const { result, success } = yield* ApiTypeCaller('revokeSystem', { systemId });

  if (success && result) {
    const translate = translator('notifications');

    yield put(setSystemsShouldUpdate());
    yield call(getCurrentOrganisationSaga);
    yield put(
      pushNotification({
        showType: INotificationType.Success,
        description: translate('system_removed', {
          systemId,
        }),
      })
    );
  }
  yield put(setModalIsLoading(false));
}
