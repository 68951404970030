// components
import { CustomText } from 'typography/Text';
import { ListItem, ZonesAmountContainer, ListItemWrapper } from './styled';
import { ZoneName } from './ZoneName';

// hooks
import { useTheme } from 'styled-components';

// types
import { IZoneBasic, TypeWithDefaultDivProps } from 'types';

interface IListItem {
  name: IZoneBasic['name'];
  recordCount: IZoneBasic['recordCount'];
  isSelected: boolean;
  onClick: () => void;
  height: string;
}

export const ZonesListItem = ({
  name,
  recordCount,
  isSelected,
  onClick,
  height,
  ...rest
}: TypeWithDefaultDivProps<IListItem>) => {
  const {
    palette: { fonts },
  } = useTheme();

  return (
    <ListItemWrapper height={height}>
      <ListItem data-test="dns-zones-list-item" isSelected={isSelected} onClick={onClick} {...rest}>
        <ZoneName data-test="dns-zones-list-item-text" isSelected={isSelected}>
          {name}
        </ZoneName>
        <ZonesAmountContainer>
          <CustomText type="hint" color={fonts.bodyLight}>
            {recordCount}
          </CustomText>
        </ZonesAmountContainer>
      </ListItem>
    </ListItemWrapper>
  );
};
