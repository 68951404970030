// Libs
import { useEffect, useState } from 'react';

// components
import { useTranslations } from 'hooks/useTranslations';
import { Select } from 'components';
import { SearchSelectOptionElement } from './SearchSelectOptionElement';
import { EmptyDropdown, emptyDropdownType as EmptyDropdownType } from './EmptyDropdown';
import { SelectedKeyInfo } from './SelectedKeyInfo';
import { SearchSelectContainer, SelectKeyContainer, SelectKeySectionContainer } from './styled';

// selectors
import { selectEnrolmentKeys, selectSelectedEnrolmentKey } from 'redux/selectors';

// hooks
import { useDispatch, useSelector } from 'react-redux';

// utils
import { debounce } from 'utils';

// actions
import {
  getEnrolmentKeys,
  setEnrolmentKeys,
  setSelectedEnrolmentKey,
} from 'redux/actions/enrolment-keys';

// types
import { IEnrolmentKey } from 'types';

// constants
import { ITEMS_PER_TABLE_PAGE } from 'appConstants';
import { initialEnrolmentKeysResponse } from 'redux/reducers/enrolment-keys';

export const SelectKeySection = () => {
  const dispatch = useDispatch();
  const selectedValue = useSelector(selectSelectedEnrolmentKey);

  const { translate } = useTranslations('enrol-new-system');

  const [emptyDropdownType, setEmptyDropdownType] = useState<EmptyDropdownType>('no-keys');
  const [searchValue, setSearchValue] = useState<null | {
    current: string;
    previous: string;
  }>(null);

  const enrolmentKeys = useSelector(selectEnrolmentKeys);

  useEffect(() => {
    dispatch(
      getEnrolmentKeys({
        search: searchValue?.current ?? '',
        include_disabled: false,
        page: 0,
      })
    );
    return () => {
      dispatch(setEnrolmentKeys(initialEnrolmentKeysResponse));
    };
  }, [dispatch, searchValue]);

  const setSearchValueDebounced = debounce((newSearchValue: string) => {
    setEmptyDropdownType(newSearchValue === '' ? 'no-keys' : 'no-matching-keys');

    if (
      enrolmentKeys.length === ITEMS_PER_TABLE_PAGE ||
      (searchValue && !newSearchValue.includes(searchValue.current))
    ) {
      setSearchValue({
        previous: searchValue?.current || '',
        current: newSearchValue,
      });
    }
  }, 250);

  const handleSelectedKey = (selectedValue: IEnrolmentKey) => {
    dispatch(setSelectedEnrolmentKey(selectedValue));
  };

  return (
    <SelectKeySectionContainer>
      <SelectKeyContainer>
        <SearchSelectContainer>
          <Select
            aria-labelledby="label-select-new-system-enrolment-key"
            dataSource={enrolmentKeys}
            setSearchValue={setSearchValueDebounced}
            setSelectedValue={handleSelectedKey}
            defaultValueFieldValue={selectedValue?.description}
            showSearch={true}
            width="100%"
            height="2.5rem"
            valueField={['description', 'id']}
            titleField="description"
            keyField="id"
            shownSelectClassSelector=".select-shown"
            placeholder={translate('placeholder')}
            OptionElement={(record, _index, isSelected) => {
              return <SearchSelectOptionElement record={record} isSelected={isSelected} />;
            }}
            notFoundContent={<EmptyDropdown type={emptyDropdownType} />}
          />
        </SearchSelectContainer>
      </SelectKeyContainer>
      {selectedValue && <SelectedKeyInfo enrolmentKey={selectedValue} />}
    </SelectKeySectionContainer>
  );
};
