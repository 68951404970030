// lib
import { call, select } from 'typed-redux-saga';
import { put } from '@redux-saga/core/effects';

// selectors
import { selectPathName, selectOrgId } from 'redux/selectors';

// actions
import { push } from 'redux/actions/router';

// utils
import { myUrlToModule } from 'utils/myUrlToModule';
import { orgPush } from 'redux/sagas/utils';

export function* redirectToOrganisationSaga() {
  const pathname = yield* select(selectPathName);
  const orgId = yield* select(selectOrgId);

  if (orgId) {
    if (pathname === '/') {
      yield put(push([`/org/${orgId}`]));
    }

    const corePath = myUrlToModule({ pathname });
    if (typeof corePath === 'string') {
      yield* call(orgPush, corePath);
    }
  }
}
