// libs
import { css, useTheme } from 'styled-components';

// Components
import { CustomButton, BulkClearButton } from 'components';
import { CustomText } from 'typography/Text';
import { BulkActionsContainer } from 'components/BulkActions/styled';

// Hooks
import { useTranslations } from 'hooks/useTranslations';
import { useDispatch } from 'react-redux';

// actions
import { setModalContent } from 'redux/actions/app';

// types
import { IRecord } from 'types';

interface IBulkActions {
  bulkSelectedRecords: IRecord[];
  setBulkSelectedRecords: (bulkSelectedRecords: IRecord[]) => void;
}
export const BulkActions = ({
  bulkSelectedRecords,
  setBulkSelectedRecords,
  ...rest
}: IBulkActions) => {
  const dispatch = useDispatch();

  const { translate } = useTranslations('dns');
  const { translate: translateGlobal } = useTranslations('global');

  const {
    palette: { fonts },
  } = useTheme();

  const onRemove = () => {
    dispatch(
      setModalContent({
        type: 'remove-dns-records',
        data: {
          records: bulkSelectedRecords.map(({ id, name }) => ({ id, name })),
          isBulk: true,
        },
      })
    );
  };

  return (
    <BulkActionsContainer {...rest}>
      <CustomText type="sub-heading-2" color={fonts.title}>
        {bulkSelectedRecords.length}{' '}
        {bulkSelectedRecords.length !== 1
          ? translateGlobal('bulk.items_selected', {
              module: translate('modules.items'),
            })
          : translateGlobal('bulk.item_selected', {
              module: translate('modules.item'),
            })}
      </CustomText>
      <BulkClearButton type="link" onClick={() => setBulkSelectedRecords([])}>
        {translateGlobal('bulk.clear_all')}
      </BulkClearButton>

      <CustomButton
        kind="secondaryDanger"
        css={css`
          margin-right: 1rem;
        `}
        onClick={onRemove}
      >
        {translateGlobal('bulk.remove')}
      </CustomButton>
    </BulkActionsContainer>
  );
};
