// libs
import styled, { css, useTheme } from 'styled-components';

// components
import { IconTrustRequirementBlocked, IconTrustRequirementRequired } from 'assets/svg';
import { CustomText } from 'typography/Text';

// hooks
import { useTranslations } from 'hooks/useTranslations';

// types
import { ITrustRequirementPublicIpLocationConditionModified } from 'types/trust-requirements';

interface IIsBlocked {
  isBlocked: ITrustRequirementPublicIpLocationConditionModified['isBlocked'];
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const IsBlockedView = ({ isBlocked }: IIsBlocked) => {
  const { translate } = useTranslations('trust-requirements');

  const {
    palette: { fonts },
  } = useTheme();

  return (
    <Container>
      {isBlocked === 'true' ? (
        <IconTrustRequirementBlocked width="0.75rem" height="0.75rem" />
      ) : (
        <IconTrustRequirementRequired width="0.75rem" height="0.75rem" />
      )}
      <CustomText
        css={css`
          margin-left: 0.25rem;
          overflow-wrap: anywhere;
        `}
        type="body-2"
        color={fonts.title}
      >
        {translate(`details.types.PublicIp.${isBlocked === 'true' ? 'deny_all' : 'allow_all'}`)}
      </CustomText>
    </Container>
  );
};
