// libs
import { css, useTheme } from 'styled-components';

// components
import { List, ListItem } from './styled';
import { InternalLink } from 'components/Links/InternalLink';
import { ControllableIcon } from 'components';
import { IconSideMenuPolicies } from 'assets/svg';
import { CustomText } from 'typography/Text';
import { IconArrowRight } from '../styled';

// hooks
import { useSelector } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';

// selectors
import { selectCurrentOrganisationId, selectModalData } from 'redux/selectors';

// constants
import { ROUTE_PATHS } from 'appConstants';

//types
import { IModalDeleteTags, IPolicy } from 'types';

interface IPoliciesTab {
  policies?: IPolicy[];
  deletingMultiple: boolean;
}

export const PoliciesTab = ({ policies, deletingMultiple }: IPoliciesTab) => {
  const {
    palette: { fonts },
  } = useTheme();

  const { translate } = useTranslations('tags');

  const orgId = useSelector(selectCurrentOrganisationId);
  const modalData = useSelector(selectModalData) as IModalDeleteTags['data'] | null;
  const tags = modalData?.records;

  return (
    <div>
      {policies && (
        <>
          <CustomText
            css={css`
              white-space: pre;
              display: block;
              margin-top: 1.5rem;
              margin-bottom: 0.75rem;
              > span {
                margin-left: 0.5rem;
              }
            `}
            type="body-2"
            color={fonts.bodyLight}
          >
            {translate(
              `modal.remove.tabs.policies.description.${
                deletingMultiple ? 'multiple_tags' : 'single_tag'
              }-${policies?.length > 1 ? 'multiple_items' : 'single_item'}`
            )}
          </CustomText>
          <List>
            {policies.map(policy => {
              const policyName = policy.description;

              return (
                <ListItem tabIndex={0} key={policy.id}>
                  <ControllableIcon
                    icon={<IconSideMenuPolicies />}
                    size="medium"
                    css={css`
                      margin-right: 0.75rem;
                    `}
                  />
                  <InternalLink
                    title={policyName}
                    type="body-2"
                    to={`/org/${orgId}${ROUTE_PATHS.policies}/${policy.id}`}
                    ellipsis={true}
                  >
                    {policyName}
                  </InternalLink>
                </ListItem>
              );
            })}
            {tags && (
              <InternalLink
                type="body-2"
                to={`/org/${orgId}${ROUTE_PATHS.policies}/zones/?search=tags:${
                  tags.length > 1 ? '|' : ''
                }${tags.map(tag => tag.tag).join(',')}`}
                ellipsis={true}
                css={css`
                  margin-top: 1rem;
                `}
              >
                {translate('details.connections.tabs.policies.all_attached')} <IconArrowRight />
              </InternalLink>
            )}
          </List>
        </>
      )}
    </div>
  );
};
