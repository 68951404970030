import { css, CSSProp } from 'styled-components';

interface IIconWithSelectedState {
  icon: React.ReactNode;
  isSelected: boolean;
  defaultCss?: CSSProp;
  defaultStateCss?: CSSProp;
  selectedStateCss?: CSSProp;
}

export const IconWithSelectedState = ({
  icon,
  isSelected,
  defaultStateCss,
  selectedStateCss,
  defaultCss,
}: IIconWithSelectedState) => {
  return (
    <span
      css={css`
        ${defaultCss}
        ${isSelected ? selectedStateCss : defaultStateCss}
      `}
    >
      {icon}
    </span>
  );
};
