// Lib
import { useMemo, useRef } from 'react';
import { CSSProp, useTheme } from 'styled-components';

// Hooks
import { useTagSelect } from './useTagSelect';
import { useTranslations } from 'hooks/useTranslations';

// Components
import { DropdownContainer, Select, TagsSelectContainer } from './styled';
import { NoExistingTags, SelectOptions, InvalidCharacter, InvalidFormat } from './components';
import { CustomText } from 'typography/Text';
import { CustomTag } from '../Tag';
import { InvalidTagLength } from './components/ErrorMsg';

// Types
import { SelectProps } from 'antd/lib/select';
import { TagReference } from 'types';

// Constants
import { TAG_SELECT_LINE_HEIGHT } from 'appConstants';

export interface ITagsProps<VT> extends Omit<SelectProps<VT>, 'onChange'> {
  preSelected: TagReference[];
  changeCallback?: (tags: TagReference[]) => void;
  width?: string;
  containerCss?: CSSProp;
  forceAtBottom?: boolean;
}

export const TagSelect = <VT extends unknown>({
  preSelected,
  changeCallback,
  width,
  containerCss,
  forceAtBottom = true,
}: ITagsProps<VT>) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const {
    search,
    tagsToRender,
    tagsSelected,
    shouldShowDropDown,
    handleSearch,
    handleInputKeyDown,
    handleSelect,
    handleDeselect,
    onDropdownVisibilityChange,
    isOpen,
  } = useTagSelect({
    preSelected,
    changeCallback,
  });
  const { translate } = useTranslations('tags');

  const {
    palette: { fonts },
  } = useTheme();

  const smallScreen = document.documentElement.clientHeight <= 768;

  // 16 is .rc-virtual-list-holder vertical padding
  const listHeight = useMemo(() => {
    return TAG_SELECT_LINE_HEIGHT * Math.min(tagsToRender.length + 1, smallScreen ? 5 : 10) + 16;
  }, [tagsToRender, smallScreen]);

  return (
    <TagsSelectContainer css={containerCss} ref={containerRef}>
      <Select
        aria-label={translate('aria_labels.select_tags')}
        virtual={false}
        $empty={tagsSelected.length === 0}
        $error={search.wrongTag}
        $width={width}
        dropdownAlign={
          forceAtBottom
            ? {
                // Docs - https://github.com/yiminghe/dom-align
                points: ['tl', 'bl'],
                offset: [0, 2],
                overflow: {
                  adjustX: 0,
                  adjustY: 0,
                },
              }
            : {
                offset: [0, 2],
              }
        }
        getPopupContainer={trigger => trigger.parentElement}
        mode="tags"
        placeholder={
          <CustomText type="body-2" color={fonts.disabled}>
            {translate('none_selected')}
          </CustomText>
        }
        value={tagsSelected.map(tag => tag.tag)}
        onSearch={handleSearch}
        searchValue={search.value}
        optionLabelProp="label"
        menuItemSelectedIcon={null}
        onDropdownVisibleChange={onDropdownVisibilityChange}
        open={isOpen}
        onInputKeyDown={handleInputKeyDown}
        dropdownRender={originNode => {
          if (shouldShowDropDown) {
            return (
              <DropdownContainer data-test="tags-select-dropdown-container">
                {originNode}
              </DropdownContainer>
            );
          } else {
            return <NoExistingTags />;
          }
        }}
        onSelect={handleSelect}
        onDeselect={handleDeselect}
        listHeight={listHeight}
        listItemHeight={0}
        dropdownClassName="tags-select-dropdown"
        tagRender={({ value, onClose }) => {
          //! to be optimized
          const tagToRender = tagsSelected.find(tag => tag.tag === value);
          return (
            <>
              {tagToRender && (
                <CustomTag
                  tag={tagToRender}
                  withCloseIcon={true}
                  onCloseClick={onClose}
                  data-test="tags-select-selected-item"
                />
              )}
            </>
          );
        }}
        data-test="tags-select-container"
      >
        {SelectOptions(tagsToRender)}
      </Select>
      {search.wrongTag && <InvalidFormat />}
      {search.wrongChar && <InvalidCharacter />}
      {search.tooLongTagName && <InvalidTagLength />}
    </TagsSelectContainer>
  );
};
