// components
import { StyledCommandLineCode } from '../../../styled';
import { StyledItem } from '../../../components/List';

// hooks
import { useTranslations } from 'hooks/useTranslations';

export const LinuxApt = () => {
  const { translate } = useTranslations('enrol-new-system');

  return (
    <>
      <StyledItem
        title={translate('linux.distros.apt.1.title')}
        content={
          <StyledCommandLineCode
            code={`curl -fsSL https://packages.enclave.io/apt/enclave.stable.gpg | sudo gpg --dearmor -o /usr/share/keyrings/enclave.gpg
echo "deb [arch=$(dpkg --print-architecture) signed-by=/usr/share/keyrings/enclave.gpg] https://packages.enclave.io/apt stable main" | sudo tee /etc/apt/sources.list.d/enclave.stable.list
sudo apt update`}
          />
        }
      />
      <StyledItem
        title={translate('linux.distros.apt.2.title')}
        content={<StyledCommandLineCode code={`sudo apt install enclave`} />}
      />
      <StyledItem
        title={translate('linux.distros.apt.3.title')}
        content={<StyledCommandLineCode code={`sudo enclave enrol`} />}
      />
    </>
  );
};
