// components
import { CustomText } from 'typography/Text';
import {
  EditContainer,
  EditInfoContainer,
  StyledControllableIcon,
  StyledOptionDescription,
} from './styled';
import { IconEnrolmentKeysDetailsViewKey, IconEnrolmentKey } from 'assets/svg';
import { CustomButtonLink } from 'components';

// hooks
import { css, useTheme } from 'styled-components';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';

// actions
import { setModalContent } from 'redux/actions/app';

// selectors
import { selectAppDetailsEnrolmentKeyCurrentType } from 'redux/selectors';

export const PurposeView = () => {
  const dispatch = useDispatch();

  const { translate } = useTranslations('enrolment-keys');

  const {
    palette: {
      fonts: { bodyLight },
    },
  } = useTheme();

  const purpose = useSelector(selectAppDetailsEnrolmentKeyCurrentType);

  const openViewActualKeyModal = useCallback(() => {
    dispatch(setModalContent({ type: 'view-actual-key', data: null }));
  }, [dispatch]);

  return (
    <EditContainer>
      <EditInfoContainer data-test="enrolment-key-view-details-purpose">
        <StyledControllableIcon
          ephemeral={purpose === 'Ephemeral'}
          size="large"
          state="default"
          icon={<IconEnrolmentKey />}
        />
        <StyledOptionDescription>
          <CustomText type="body-2">
            {purpose === 'Ephemeral'
              ? translate('details.ephemeral_switch.ephemeral_purpose')
              : translate('details.ephemeral_switch.general_purpose')}
          </CustomText>
          <CustomText type="hint" color={bodyLight}>
            {purpose === 'Ephemeral'
              ? translate('details.ephemeral_switch.ephemeral_purpose_hint')
              : translate('details.ephemeral_switch.general_purpose_hint')}
          </CustomText>
        </StyledOptionDescription>
      </EditInfoContainer>
      <CustomButtonLink
        size="large"
        focusCss={css`
          svg {
            path:last-child,
            path:nth-last-child(2) {
              fill: ${({ theme }) => theme.palette.fills.primaryHover};
            }
          }
        `}
        textCss={css`
          margin-left: 0.25rem;
        `}
        onClick={openViewActualKeyModal}
        icon={<IconEnrolmentKeysDetailsViewKey />}
      >
        {translate('details.view_key')}
      </CustomButtonLink>
    </EditContainer>
  );
};
