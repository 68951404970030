// Libs
import { LayoutProps } from 'antd/lib/layout';
import { MenuItemProps } from 'antd/lib/menu/MenuItem';
import { IconWithSelectedState } from 'components';
import { css, CSSProp, useTheme } from 'styled-components';

// Components
import { CustomText } from 'typography/Text';
import { StyledSideMenuItem, StyledSideMenuNavLink } from './Styled';

export interface ICustomLayoutProps extends LayoutProps {
  $fullHeight?: boolean;
  isaccpage?: string;
}

interface IStyledItemLink extends MenuItemProps {
  to: string;
  newWindow?: boolean;
  children: React.ReactNode;
  selectedKey?: string;
  customDefaultStateIconCss?: CSSProp;
  customSelectedStateIconCss?: CSSProp;
}

export const SideMenuItemLink = ({
  to,
  newWindow,
  icon,
  selectedKey,
  children,
  customDefaultStateIconCss,
  customSelectedStateIconCss,
  ...props
}: IStyledItemLink) => {
  const {
    palette: { fonts, icons, background },
  } = useTheme();
  const isSelected = props.eventKey === selectedKey;

  return (
    <StyledSideMenuItem
      $customSelectedStateIconCss={customSelectedStateIconCss}
      role="listitem"
      {...props}
      icon={
        <IconWithSelectedState
          icon={icon}
          isSelected={isSelected}
          defaultCss={css`
            display: flex;
          `}
          defaultStateCss={css`
            ${customDefaultStateIconCss
              ? customDefaultStateIconCss
              : `
              svg {
                path {
                  fill: ${icons.disabled};
                }
                rect {
                  fill: ${background.iconBackground};
                }
              }`};
          `}
          selectedStateCss={css`
            ${customSelectedStateIconCss
              ? customSelectedStateIconCss
              : `
              svg {
                path {
                  fill: ${icons.white};
              }
                rect {
                  fill: ${icons.primary};
                }
              }`};
          `}
        />
      }
    >
      <StyledSideMenuNavLink target={newWindow ? '_blank' : '_self'} to={to} tabIndex={0}>
        <CustomText
          css={css`
            margin-left: 0.5rem;
          `}
          type={isSelected ? 'sub-heading-2' : 'body-2'}
          color={isSelected ? fonts.title : fonts.bodyLight}
        >
          {children}
        </CustomText>
      </StyledSideMenuNavLink>
    </StyledSideMenuItem>
  );
};
