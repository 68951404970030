// libs
import { memo } from 'react';
import { css, useTheme } from 'styled-components';

// components
import Skeleton from 'react-loading-skeleton';
import { CustomText } from 'typography/Text';
import { KeysStatusBadge } from 'components';

// hooks
import { useTranslations } from 'hooks/useTranslations';

// types
import { IEnrolmentKeyStatus, ISimpleEnrolmentKey } from 'types';

interface IStatus {
  status: IEnrolmentKeyStatus;
  isEnabled: ISimpleEnrolmentKey['isEnabled'];
  isLoading: boolean;
}

export const Status = memo(({ isLoading, status, isEnabled }: IStatus) => {
  const { translate } = useTranslations('enrolment-keys');
  const {
    palette: { fonts },
  } = useTheme();
  const text =
    status === 'Enabled'
      ? translate('enabled')
      : status === 'Disabled'
      ? translate('disabled')
      : translate('no_uses_remaining');
  return (
    <>
      {isLoading ? (
        <Skeleton />
      ) : (
        <div
          css={css`
            display: flex;
            flex-direction: row;
          `}
          data-test="enrolment-keys-table-status-column"
        >
          <KeysStatusBadge state={status} />
          <CustomText
            type="body-2"
            color={isEnabled ? fonts.title : fonts.disabled}
            css={css`
              display: block;
            `}
            ellipsis={true}
            title={text}
          >
            {text}
          </CustomText>
        </div>
      )}
    </>
  );
});
