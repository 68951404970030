import { RouteProps, Navigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { doLogin } from '../../redux/actions/user';

interface IPrivateRoute extends RouteProps {
  isLoggedIn: boolean;
  component: React.ComponentType<any>;
}

export const PrivateRoute = ({ component: Component, isLoggedIn }: IPrivateRoute) => {
  const dispatch = useDispatch();
  if (!isLoggedIn) dispatch(doLogin());
  return isLoggedIn ? (
    <>
      <Component />
    </>
  ) : (
    <Navigate to="/" />
  );
};
