// components
import { PreviewBlockCreate } from './Create';
import { PreviewBlockEdit } from './Edit';
import { PreviewBlockView } from './View';

// hooks
import { useSelector } from 'react-redux';

// selectors
import { selectAppDetailsTabType } from 'redux/selectors';

export const PreviewBlock = () => {
  const type = useSelector(selectAppDetailsTabType);

  return (
    <>
      {type === 'tag-edit' && <PreviewBlockEdit />}
      {type === 'tag-create' && <PreviewBlockCreate />}
      {type === 'tag-view' && <PreviewBlockView />}
    </>
  );
};
