import { CustomText } from 'typography/Text';
import { SystemStatusBadge } from 'components';

// hooks
import { useTheme } from 'styled-components';
import { useTranslations } from 'hooks/useTranslations';

// types
import { ISystemReferenceModel } from 'types';

interface IConnectedFrom {
  connectedFrom: ISystemReferenceModel['connectedFrom'];
  state: ISystemReferenceModel['state'];
}

export const ConnectedFrom = ({ connectedFrom, state, ...rest }: IConnectedFrom) => {
  const {
    palette: { fonts },
  } = useTheme();
  const { translate } = useTranslations('systems');

  const connectedFromText: Record<ISystemReferenceModel['state'], string | null> = {
    Connected: connectedFrom,
    Disabled: translate('disabled'),
    Disconnected: translate('disconnected'),
  };

  return (
    <CustomText
      ellipsis={true}
      title={connectedFromText[state] || undefined}
      type="body-2"
      color={fonts.title}
      {...rest}
    >
      <SystemStatusBadge state={state} />
      {connectedFromText[state]}
    </CustomText>
  );
};
