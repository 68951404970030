import styled, { css } from 'styled-components';

export const MenuButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const IconButtonWrapper = styled.div`
  margin-left: 0.3125rem;
`;

export const iconEditHoverCss = css`
  svg path {
    stroke: ${({ theme }) => theme.palette.fills.primaryHover};
  }
`;

export const iconEditFocusCss = css`
  svg path {
    stroke: ${({ theme }) => theme.palette.icons.primary};
  }
`;
