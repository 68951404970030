// libs
import { CSSProp, useTheme } from 'styled-components';

// components
import { QuestionCircleOutlined } from '@ant-design/icons';
import {
  IconWindowsOS,
  IconLinuxOS,
  IconRaspberryPiOS,
  IconMacOS,
  IconIosOS,
  IconAndroidOS,
} from 'assets/svg';
import { IconContainer, StyledEphemeralIcon } from './styled';

// types
import { IIconState, OSTypes, ITrustRequirementsTypeLocal } from 'types';

// constants
import { TRUST_REQUIREMENTS_ICONS } from 'appConstants';

type StateCss = Partial<Record<IIconState, CSSProp>>;

export interface IControllableIcon {
  icon?: React.ReactNode;
  state?: IIconState;
  ephemeral?: boolean;
  size?: 'medium' | 'large';
  withPadding?: boolean;
  stateCss?: StateCss;
}

export const ControllableIcon = ({
  icon,
  state = 'default',
  ephemeral = false,
  size = 'medium',
  withPadding = false,
  stateCss,
  ...rest
}: IControllableIcon) => {
  const theme = useTheme();

  const fillMap: Record<IIconState, string> = {
    default: theme.palette.icons.secondary,
    muted: theme.palette.fonts.disabled,
    active: theme.palette.background.panel,
  };

  const active = state === 'active';

  const currentStateCss = stateCss?.[state];

  return (
    <IconContainer
      size={size}
      css={currentStateCss}
      fill={currentStateCss ? undefined : fillMap[state]}
      active={active}
      withPadding={withPadding}
      {...rest}
    >
      {ephemeral && <StyledEphemeralIcon $active={active} />}
      {icon || <QuestionCircleOutlined />}
    </IconContainer>
  );
};

// OS ICON
const ICONS_MAP: { [index in OSTypes]: JSX.Element } = {
  Linux: <IconLinuxOS />,
  Windows: <IconWindowsOS />,
  RaspberryPi: <IconRaspberryPiOS />,
  Darwin: <IconMacOS />,
  iOS: <IconIosOS />,
  Android: <IconAndroidOS />,
};

export interface IControllableOSIcon extends IControllableIcon {
  platformType?: OSTypes | null;
}

export const ControllableOSIcon = ({ platformType, ...rest }: IControllableOSIcon) => (
  <ControllableIcon
    icon={platformType ? ICONS_MAP[platformType] : <QuestionCircleOutlined />}
    {...rest}
  />
);

interface IControllableTrustRequirementIcon extends IControllableIcon {
  type: ITrustRequirementsTypeLocal;
}
export const ControllableTrustRequirementIcon = ({
  type,
  ...rest
}: IControllableTrustRequirementIcon) => {
  const Icon = TRUST_REQUIREMENTS_ICONS[type];

  return <ControllableIcon icon={<Icon width="1.5rem" height="1.5rem" />} {...rest} />;
};
