// Lib
import { put } from 'typed-redux-saga';
import { setSystems, setIsLoading } from '../../../actions/unapproved-systems';

// Utils
import { SyncQueryParams } from '../../utils';
import { ApiTypeCaller } from '../../api/utils';

// Types
import { IActionPayload, IGetUnapprovedSystemsParams } from '../../../../types';

export function* getSystemsSaga({ payload }: IActionPayload<IGetUnapprovedSystemsParams>) {
  yield put(setIsLoading(true));

  payload = yield* SyncQueryParams(payload);

  const { result, success } = yield* ApiTypeCaller('getUnapprovedSystems', payload);
  if (success && result) yield put(setSystems(result));
}
