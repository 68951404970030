// libs
import { call, select } from 'typed-redux-saga';
import { User } from 'oidc-client';
import { put } from '@redux-saga/core/effects';

// selectors
import { selectAccountOrganisations, selectAppIsLoaded } from 'redux/selectors';

// constants
import { OIDCConstants, REGISTER_URL } from 'appConstants';

// utils
import { preloadUserDataSaga } from './preloadUserDataSaga';
import { myUrlToModule } from 'utils/myUrlToModule';
import { reLoginSaga } from './reLoginSaga';
import { apiService } from '../../../../api/ApiService';
import { orgPush } from '../../utils';

// sagas
import { validateUserAuthSaga } from './validateUserAuthSaga';

// actions
import { push } from 'redux/actions/router';

export function* normalAuthRedirectSaga() {
  yield reLoginSaga();
  const appIsLoaded = yield* select(selectAppIsLoaded);

  if (appIsLoaded) return;

  try {
    const user: User = yield* call([apiService, apiService.handleNormalAuthRedirect]);

    if (user) {
      yield* call(preloadUserDataSaga, user.profile);
      const orgs = yield* select(selectAccountOrganisations);

      if (user.state && user.state !== `/${OIDCConstants.oidcLogoutRedirect}`) {
        const pathname = user.state;

        const corePath = myUrlToModule({ pathname });

        if (orgs.length) {
          if (corePath) {
            yield* call(orgPush, corePath);
          } else if (
            pathname === '/' ||
            pathname.startsWith(REGISTER_URL) ||
            pathname.startsWith(`/${OIDCConstants.oidcRedirect}`)
          ) {
            yield* call(orgPush, '/');
          } else {
            yield put(push([pathname]));
          }
        } else {
          yield* call(orgPush, '/');
        }
      }
    } else {
      yield* validateUserAuthSaga();
    }
  } catch (error) {
    console.log(`OIDC Redirect error: ${error}`);
    yield* call(orgPush, '/');
  }
}
