// libs
import React from 'react';
import { css, CSSProp, useTheme } from 'styled-components';

// components
import { Input } from 'antd';
import { TextareaContainer, Label } from './styled';
import { CustomText } from 'typography/Text';

// hooks
import { useTranslations } from 'hooks/useTranslations';

// types
import { TextAreaProps } from 'antd/lib/input/TextArea';

const { TextArea } = Input;

interface ITextarea extends TextAreaProps {
  children?: React.ReactNode;
  label?: string;
  id?: string;
  passedCss?: CSSProp;
  charactersLimit?: { characters: number; charactersLimit: number };
}

export const Textarea = ({
  children,
  passedCss,
  label,
  id,
  charactersLimit,
  ...rest
}: ITextarea) => {
  const { translate } = useTranslations('global');

  const {
    palette: { fonts },
  } = useTheme();

  return (
    <TextareaContainer
      css={css`
        ${passedCss};
      `}
    >
      {label && <Label htmlFor={id}>{label}</Label>}
      <TextArea maxLength={charactersLimit?.charactersLimit} id={id} {...rest}>
        {children}
      </TextArea>
      {charactersLimit && (
        <CustomText
          css={css`
            display: block;
            margin-top: 0.1875rem;

            ${charactersLimit.characters === 0 &&
            css`
              opacity: 0;
            `}
          `}
          type="hint"
          color={fonts.bodyLight}
        >
          {charactersLimit.characters !== 0
            ? translate('textarea_characters_limit', {
                characters: charactersLimit.characters,
                character_limit: charactersLimit.charactersLimit,
              })
            : 'placeholder'}
        </CustomText>
      )}
    </TextareaContainer>
  );
};
