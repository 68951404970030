// libs
import { css } from 'styled-components';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { useSelector } from 'react-redux';

// selectors
import { selectAppDetailsTabType } from 'redux/selectors';

// components
import { TimeZoneContent } from './styled';
import { TimeZoneView } from './View';
import { TimeZoneEdit } from './Edit';
import { Details } from 'components';

interface IActiveHoursBlock {
  isOpen: boolean;
}

export const ActiveHoursTimeZoneBlock = ({ isOpen }: IActiveHoursBlock) => {
  const { translate } = useTranslations('policies');

  const type = useSelector(selectAppDetailsTabType);

  return (
    <TimeZoneContent isOpen={isOpen}>
      <Details.KeyValue
        contentCss={css`
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
        `}
        css={css`
          padding: 0;
        `}
        descriptionCss={css`
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
        `}
        title={translate('details.time_limitations.time_zone.title')}
      >
        {type === 'policy-view' && <TimeZoneView />}
        {(type === 'policy-edit' || type === 'policy-create') && <TimeZoneEdit />}
      </Details.KeyValue>
    </TimeZoneContent>
  );
};
