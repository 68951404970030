import { useSelector } from 'react-redux';
import { selectAccountSignInMethod } from 'redux/selectors';
import { PersonalInfoBlockForeign } from './ForeignSignIn';
import { PersonalInfoBlockLocal } from './LocalSignIn';

export const PersonalInfoBlock = () => {
  const signInMethod = useSelector(selectAccountSignInMethod);

  if (signInMethod === 'Local' || signInMethod === 'PersonalAccessToken') {
    return <PersonalInfoBlockLocal />;
  }

  return <PersonalInfoBlockForeign signInMethod={signInMethod} />;
};
