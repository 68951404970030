// libs
import { css, useTheme } from 'styled-components';

// components
import { CustomButton, ExternalLink } from 'components';
import { ButtonWrapper, Column, DangeZoneSection, Flex, LinkWrapper, Row } from './styled';
import { DescriptionContainer, ContentContainer } from '../styled';
import { CustomText } from 'typography/Text';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { useDispatch, useSelector } from 'react-redux';

// actions
import { setModalContent } from 'redux/actions/app';

// selectors
import { selectDnsSelectedZone } from 'redux/selectors';

// constants
import { DEFAULT_DNS_ZONE_NAME, LINK_TO_DOCS } from 'appConstants';

// utils
import { pick } from 'ramda';

export const DangerZone = () => {
  const dispatch = useDispatch();

  const { translate } = useTranslations('dns');

  const {
    palette: { state, fonts },
  } = useTheme();

  const selectedZone = useSelector(selectDnsSelectedZone);

  const onRenameZoneClick = () => {
    selectedZone &&
      dispatch(
        setModalContent({
          type: 'rename-dns-zone',
          data: pick(['id', 'name'], selectedZone),
        })
      );
  };
  const onDeleteZoneClick = () => {
    selectedZone &&
      dispatch(
        setModalContent({
          type: 'delete-dns-zone',
          data: pick(['id', 'name', 'notes', 'recordCount'], selectedZone),
        })
      );
  };

  return (
    <DangeZoneSection>
      <DescriptionContainer>
        <CustomText
          type="sub-heading-1"
          color={state.error}
          css={css`
            display: block;
            margin-bottom: 1rem;
          `}
        >
          {translate('zone_properties.danger_zone.danger_zone')}
        </CustomText>
        <CustomText type="body-2" color={fonts.bodyLight}>
          {translate('zone_properties.danger_zone.irreversible_and_destructive_actions')}
        </CustomText>
      </DescriptionContainer>
      <ContentContainer>
        <Row>
          <Flex>
            <Column>
              <CustomText type="sub-heading-2" color={fonts.bodyLight}>
                {translate('zone_properties.danger_zone.rename_zone')}
              </CustomText>
              <CustomText
                css={css`
                  margin-top: 0.5rem;
                `}
                type="body-2"
                color={fonts.bodyLight}
              >
                {selectedZone?.name !== DEFAULT_DNS_ZONE_NAME
                  ? translate('zone_properties.danger_zone.rename_zone_description')
                  : translate('zone_properties.danger_zone.rename_zone_description_default_zone')}
              </CustomText>
            </Column>
            <LinkWrapper>
              <ExternalLink
                href={LINK_TO_DOCS}
                text={translate('zone_properties.danger_zone.link')}
                textType="body-2"
                withIcon={true}
              />
            </LinkWrapper>
          </Flex>
          {selectedZone?.name !== DEFAULT_DNS_ZONE_NAME && (
            <ButtonWrapper>
              <CustomButton
                data-test="dns-zone-properties-rename-button"
                kind="secondaryDanger"
                onClick={onRenameZoneClick}
              >
                {translate('zone_properties.danger_zone.rename_zone')}
              </CustomButton>
            </ButtonWrapper>
          )}
        </Row>
        <Row>
          <Column>
            <CustomText type="sub-heading-2" color={fonts.bodyLight}>
              {translate('zone_properties.danger_zone.delete_zone')}
            </CustomText>
            <CustomText
              css={css`
                margin-top: 0.5rem;
                padding-bottom: 0.5rem;
              `}
              type="body-2"
              color={fonts.bodyLight}
            >
              {selectedZone?.name !== DEFAULT_DNS_ZONE_NAME
                ? translate('zone_properties.danger_zone.delete_zone_description')
                : translate('zone_properties.danger_zone.delete_zone_description_default_zone')}
            </CustomText>
          </Column>
          {selectedZone?.name !== DEFAULT_DNS_ZONE_NAME && (
            <ButtonWrapper>
              <CustomButton
                data-test="dns-zone-properties-delete-button"
                kind="secondaryDanger"
                onClick={onDeleteZoneClick}
              >
                {translate('zone_properties.danger_zone.delete_zone')}
              </CustomButton>
            </ButtonWrapper>
          )}
        </Row>
      </ContentContainer>
    </DangeZoneSection>
  );
};
