// libs
import { css } from 'styled-components';

// components
import { CloseButtonContainer } from './styled';
import { IconSystemsDetailsDeleteSubnet } from 'assets/svg';

// hooks
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

// actions
import { deleteSystemDetailsRoute } from 'redux/actions/app';

// types
import { ISystemGatewayRouteModified } from 'types';

interface ICloseButton {
  route: ISystemGatewayRouteModified;
  onClick?: () => void;
}

export const CloseButton = ({ route, onClick }: ICloseButton) => {
  const dispatch = useDispatch();

  const onDelete = useCallback(() => {
    dispatch(
      deleteSystemDetailsRoute({
        id: route.id,
      })
    );
    onClick?.();
  }, [dispatch, onClick, route.id]);

  return (
    <CloseButtonContainer>
      <IconSystemsDetailsDeleteSubnet
        data-test="system-details-gateway-route-close"
        onClick={onDelete}
        css={css`
          cursor: pointer;
        `}
        width="1.25rem"
        height="1.25rem"
      />
    </CloseButtonContainer>
  );
};
