// libs
import { css, useTheme } from 'styled-components';

// components
import { CustomText } from 'typography/Text';
import { IconTagDetailsAddTrustRequirement } from 'assets/svg';
import { CustomButtonLink } from 'components';
import { TrustRequirementsList } from 'modules/TrustRequirements/TrustRequirementsList';
import { SelectTrustRequirement } from 'modules/TrustRequirements/SelectTrustRequirement';
import { DeleteTrustRequirementButton } from 'modules/TrustRequirements/SelectTrustRequirement/DeleteTrustRequirementButton';

// hooks
import { useState } from 'react';
import { useTranslations } from 'hooks/useTranslations';
import { useDispatch, useSelector } from 'react-redux';

// actions
import { updateTagDetails } from 'redux/actions/app';

// selectors
import { selectAppDetailsTagNewTrustRequirements } from 'redux/selectors';

// types
import { IUsedTrustRequirement, ITrustRequirementBasic } from 'types';

export const TrustRequirementsEdit = () => {
  const {
    palette: { fills },
  } = useTheme();

  const dispatch = useDispatch();

  // if it's active - selector where we select new trust requirement
  // is shown
  const [addTrustRequirementActive, setAddTrustRequirementActive] = useState(false);

  const { translate } = useTranslations('tags');

  const trustRequirements = useSelector(selectAppDetailsTagNewTrustRequirements) || [];

  const updateTrustRequirements = (trustRequirements: IUsedTrustRequirement[]) => {
    dispatch(updateTagDetails({ trustRequirements }));
  };

  const addTrustRequirement = (trustRequirement: ITrustRequirementBasic) => {
    const { description, id, type } = trustRequirement;

    // trust requirements in query and trust requirements in a way we store
    // in details are of different type, here we are reconciling
    // those two types by transforming ITrustRequirementBasicModel to IUsedTrustRequirementModel
    updateTrustRequirements([
      ...trustRequirements,
      {
        id,
        description,
        type,
      },
    ]);
    setAddTrustRequirementActive(false);
  };

  const handleAddNewTrustRequirement = () => {
    setAddTrustRequirementActive(true);
  };

  const handleCancel = () => {
    setAddTrustRequirementActive(false);
  };

  const deleteTrustRequirement = (id: IUsedTrustRequirement['id']) => {
    updateTrustRequirements(
      trustRequirements.filter(trustRequirement => trustRequirement.id !== id)
    );
    setAddTrustRequirementActive(false);
  };

  return (
    <>
      <TrustRequirementsList
        trustRequirements={trustRequirements}
        suffix={({ onClick }) => <DeleteTrustRequirementButton onClick={() => onClick?.()} />}
        onSuffixClick={({ id }) => {
          deleteTrustRequirement(id);
        }}
      />
      {addTrustRequirementActive && (
        <SelectTrustRequirement
          placeholder={translate('details.trust_requirement_placeholder')}
          trustRequirements={trustRequirements}
          onSelect={addTrustRequirement}
          onCancel={handleCancel}
        />
      )}
      <CustomButtonLink
        onClick={() => handleAddNewTrustRequirement()}
        css={css`
          width: fit-content;
          margin-top: 1rem;
        `}
      >
        <IconTagDetailsAddTrustRequirement />
        <CustomText
          type="body-1"
          color={fills.primary}
          css={css`
            margin-left: 0.3125rem;
          `}
        >
          {translate('details.add_trust_requirement')}
        </CustomText>
      </CustomButtonLink>
    </>
  );
};
