// components
import { CustomText } from 'typography/Text';

// hooks
import { useTheme } from 'styled-components';

// utils
import { formTimeString } from 'utils';

interface IProps {
  hours: number;
  minutes: number;
  is12HFormat: boolean;
}

export const TimeOption = ({ hours, minutes, is12HFormat }: IProps) => {
  const {
    palette: { fonts },
  } = useTheme();

  return (
    <CustomText type="body-2" color={fonts.title}>
      {formTimeString(hours, minutes, is12HFormat)}
    </CustomText>
  );
};
