// libs
import { css, useTheme } from 'styled-components';

// components
import { List, ListItem } from './styled';
import { InternalLink } from 'components/Links/InternalLink';
import { ControllableIcon } from 'components';
import { IconSystemsDnsFromDirectAssignment } from 'assets/svg';
import { CustomText } from 'typography/Text';
import { IconArrowRight } from '../styled';

// hooks
import { useSelector } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';

// selectors
import { selectCurrentOrganisationId, selectModalData } from 'redux/selectors';

// constants
import { ROUTE_PATHS } from 'appConstants';

//types
import { IModalDeleteTags, IRecord } from 'types';

interface IDnsTab {
  dnsRecords?: IRecord[];
  deletingMultiple: boolean;
}

export const DnsTab = ({ dnsRecords, deletingMultiple }: IDnsTab) => {
  const {
    palette: { fonts },
  } = useTheme();

  const { translate } = useTranslations('tags');

  const orgId = useSelector(selectCurrentOrganisationId);
  const modalData = useSelector(selectModalData) as IModalDeleteTags['data'] | null;
  const tags = modalData?.records;

  return (
    <div>
      <div>
        {dnsRecords && (
          <>
            <CustomText
              css={css`
                white-space: pre;
                display: block;
                margin-top: 1.5rem;
                margin-bottom: 0.75rem;
                > span {
                  margin-left: 0.5rem;
                }
              `}
              type="body-2"
              color={fonts.bodyLight}
            >
              {translate(
                `modal.remove.tabs.dns.description.${
                  deletingMultiple ? 'multiple_tags' : 'single_tag'
                }-${dnsRecords?.length > 1 ? 'multiple_items' : 'single_item'}`
              )}
            </CustomText>
            <List>
              {dnsRecords.map(record => {
                const recordName = record.name;

                return (
                  <ListItem tabIndex={0} key={record.id}>
                    <ControllableIcon
                      icon={<IconSystemsDnsFromDirectAssignment />}
                      size="medium"
                      css={css`
                        margin-right: 0.75rem;
                      `}
                    />
                    <InternalLink
                      title={recordName}
                      type="body-2"
                      to={`/org/${orgId}${ROUTE_PATHS.dns}/zones/${record.zoneId}/record/${record.id}`}
                      ellipsis={true}
                    >
                      {recordName}
                    </InternalLink>
                  </ListItem>
                );
              })}
              {tags && (
                <InternalLink
                  type="body-2"
                  to={`/org/${orgId}${ROUTE_PATHS.dns}/zones/?search=tags:${
                    tags.length > 1 ? '|' : ''
                  }${tags.map(tag => tag.tag).join(',')}`}
                  ellipsis={true}
                  css={css`
                    margin-top: 1rem;
                  `}
                >
                  {translate('details.connections.tabs.dns.all_attached')} <IconArrowRight />
                </InternalLink>
              )}
            </List>
          </>
        )}
      </div>
    </div>
  );
};
