// components
import { Details } from 'components';
import { CustomText } from 'typography/Text';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';

// selectors
import { selectAppDetailsEnrolmentKeyLastUsed, selectAppDetailsTabType } from 'redux/selectors';

// utils
import { localizedTime } from 'utils';

export const LastUsedBlock = () => {
  const { translate } = useTranslations('enrolment-keys');

  const {
    palette: { fonts },
  } = useTheme();

  const type = useSelector(selectAppDetailsTabType);
  const lastUsed = useSelector(selectAppDetailsEnrolmentKeyLastUsed);

  if (type === 'enrolment-key-create') return null;

  return (
    <Details.KeyValue height="2.875rem" title={translate('details.last_used')}>
      <CustomText type="body-2" color={fonts.title}>
        {lastUsed ? localizedTime(lastUsed) : translate('details.never_used')}
      </CustomText>
    </Details.KeyValue>
  );
};
