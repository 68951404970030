// Lib
import { createReducer } from '@reduxjs/toolkit';
import {
  setPolicies,
  setIsLoading,
  updatePolicy,
  setPoliciesShouldUpdate,
  setPolicySearchKeys,
} from '../actions/policies';
import { IPoliciesStore, IPolicy, IGetPoliciesResponse } from 'types';
import { randomHash } from 'utils/hash';

export const initialPoliciesResponse: IGetPoliciesResponse = {
  items: [],
  metadata: {
    total: 0,
    firstPage: 0,
    prevPage: null,
    lastPage: 0,
    nextPage: null,
  },
  links: {
    first: '',
    prev: null,
    next: null,
    last: '',
  },
};

const initialState: IPoliciesStore = {
  ...initialPoliciesResponse,
  isLoading: true,
  updateId: randomHash(),
  searchKeys: null,
};

export const policiesReducer = createReducer(initialState, builder => {
  builder
    .addCase(setPolicies, (state, { payload }) => {
      return {
        ...state,
        ...payload,
        isLoading: false,
      };
    })
    .addCase(updatePolicy, (state, { payload }) => {
      state.items = state.items.map((item: IPolicy) => (payload.id === item.id ? payload : item));
    })
    .addCase(setIsLoading, (state, { payload }) => {
      state.isLoading = payload;
    })
    .addCase(setPoliciesShouldUpdate, state => {
      state.updateId = randomHash();
    })
    .addCase(setPolicySearchKeys, (state, { payload }) => {
      state.searchKeys = payload;
    });
});
