// components
import { IconSecurityEventsTableWarning, IconSecurityEventsTableError } from 'assets/svg';

// types
import { ISecurityEvent } from 'types';

interface ILevel {
  level: ISecurityEvent['level'];
}

export const Level = ({ level }: ILevel) => {
  return (
    <>
      {level === 'Error' && <IconSecurityEventsTableError />}
      {level === 'Warning' && <IconSecurityEventsTableWarning />}
    </>
  );
};
