import { ROUTE_PATHS } from 'appConstants';
import { MainState } from 'types';

export const selectEnrolmentKeys = (state: MainState) => state.EnrolmentKeys.items;
export const selectEnrolmentKeysIsLoading = (state: MainState) => state.EnrolmentKeys.isLoading;
export const selectEnrolmentKeysTotal = (state: MainState) => state.EnrolmentKeys.metadata?.total;
export const selectEnrolmentKeySearchKeys = (state: MainState) => state.EnrolmentKeys.searchKeys;
export const selectEnrolmentKeysUpdateId = (state: MainState) => state.EnrolmentKeys.updateId;
export const selectSelectedEnrolmentKey = (state: MainState) => state.EnrolmentKeys.selectedKey;

// Details Data
export const selectAppDetailsEnrolmentKeyId = ({ App: { detailsTab } }: MainState) => {
  if (detailsTab.type === 'enrolment-key-view' || detailsTab.type === 'enrolment-key-edit') {
    return detailsTab.data.id;
  }
};

export const selectAppDetailsEnrolmentKeyDescription = ({ App: { detailsTab } }: MainState) => {
  if (detailsTab.type === 'enrolment-key-view' || detailsTab.type === 'enrolment-key-edit') {
    return detailsTab.data.description;
  }
};

export const selectAppDetailsEnrolmentKeyUsesRemaining = ({ App: { detailsTab } }: MainState) => {
  if (detailsTab.type === 'enrolment-key-view' || detailsTab.type === 'enrolment-key-edit') {
    return detailsTab.data.usesRemaining;
  }
};

export const selectAppDetailsEnrolmentKeyKey = ({ App: { detailsTab } }: MainState) => {
  if (detailsTab.type === 'enrolment-key-view' || detailsTab.type === 'enrolment-key-edit') {
    return detailsTab.data.key;
  }
};

export const selectAppDetailsEnrolmentKeyIsEnabled = ({ App: { detailsTab } }: MainState) => {
  if (detailsTab.type === 'enrolment-key-view' || detailsTab.type === 'enrolment-key-edit') {
    return detailsTab.data.isEnabled;
  }
};

export const selectAppDetailsEnrolmentKeyStatus = ({ App: { detailsTab } }: MainState) => {
  if (detailsTab.type === 'enrolment-key-view' || detailsTab.type === 'enrolment-key-edit') {
    return detailsTab.data.status;
  }
};

export const selectAppDetailsEnrolmentKeyLastUsed = ({ App: { detailsTab } }: MainState) => {
  if (detailsTab.type === 'enrolment-key-view' || detailsTab.type === 'enrolment-key-edit') {
    return detailsTab.data.lastUsed;
  }
};

export const selectAppDetailsEnrolmentKeyEnrolledCount = ({ App: { detailsTab } }: MainState) => {
  if (detailsTab.type === 'enrolment-key-view' || detailsTab.type === 'enrolment-key-edit') {
    return detailsTab.data.enrolledCount;
  }
};

export const selectAppDetailsEnrolmentKeyUnapprovedCount = ({ App: { detailsTab } }: MainState) => {
  if (detailsTab.type === 'enrolment-key-view' || detailsTab.type === 'enrolment-key-edit') {
    return detailsTab.data.unapprovedCount;
  }
};

export const selectAppDetailsEnrolmentKeyApprovalMode = ({ App: { detailsTab } }: MainState) => {
  if (detailsTab.type === 'enrolment-key-view' || detailsTab.type === 'enrolment-key-edit') {
    return detailsTab.data.approvalMode;
  }
};

export const selectAppDetailsEnrolmentKeyIpConstraints = ({ App: { detailsTab } }: MainState) => {
  if (detailsTab.type === 'enrolment-key-view' || detailsTab.type === 'enrolment-key-edit') {
    return detailsTab.data.ipConstraints;
  }
};

export const selectAppDetailsEnrolmentKeyTags = ({ App: { detailsTab } }: MainState) => {
  if (detailsTab.type === 'enrolment-key-view' || detailsTab.type === 'enrolment-key-edit') {
    return detailsTab.data.tags;
  }

  if (detailsTab.type === 'enrolment-key-create') {
    return detailsTab.dataNew.tags;
  }

  return [];
};

export const selectAppDetailsEnrolmentKeyNotes = ({ App: { detailsTab } }: MainState) => {
  if (detailsTab.type === 'enrolment-key-view' || detailsTab.type === 'enrolment-key-edit') {
    return detailsTab.data.notes;
  }
};

export const selectAppDetailsEnrolmentKeyDisconnectedRetentionMinutes = ({
  App: { detailsTab },
}: MainState) => {
  if (detailsTab.type === 'enrolment-key-view' || detailsTab.type === 'enrolment-key-edit') {
    return detailsTab.data.disconnectedRetentionMinutes;
  }
};

export const selectAppDetailsEnrolmentKeyAutoExpireData = ({ App }: MainState) => {
  if (App.detailsTab.type === 'enrolment-key-view') {
    return App.detailsTab.data.autoExpire;
  }
  if (
    App.detailsTab.type === 'enrolment-key-edit' ||
    App.detailsTab.type === 'enrolment-key-create'
  ) {
    return App.detailsTab.dataNew.autoExpire;
  }
};

export const selectAppDetailsEnrolmentKeyAutoExpireAction = ({ App }: MainState) => {
  if (App.detailsTab.type === 'enrolment-key-view') {
    return App.detailsTab.data.autoExpire?.expiryAction;
  }
};

export const selectAppDetailsEnrolmentKeyAutoExpireDate = ({ App }: MainState) => {
  if (App.detailsTab.type === 'enrolment-key-view') {
    return App.detailsTab.data.autoExpire?.expiryDateTime;
  }
};

export const selectAppDetailsEnrolmentKeyAutoExpireTimeZone = ({ App }: MainState) => {
  if (App.detailsTab.type === 'enrolment-key-view') {
    return App.detailsTab.data.autoExpire?.timeZoneId;
  }
};

// Details New Data
export const selectAppDetailsEnrolmentKeyNewIsEnabled = ({ App: { detailsTab } }: MainState) => {
  if (detailsTab.type === 'enrolment-key-edit') return detailsTab.dataNew.isEnabled;
};

export const selectAppDetailsEnrolmentKeyNewDescription = ({ App: { detailsTab } }: MainState) => {
  if (detailsTab.type === 'enrolment-key-create' || detailsTab.type === 'enrolment-key-edit') {
    return detailsTab.dataNew.description;
  }
};

export const selectAppDetailsEnrolmentKeyNewUsesRemaining = ({
  App: { detailsTab },
}: MainState) => {
  if (detailsTab.type === 'enrolment-key-create' || detailsTab.type === 'enrolment-key-edit') {
    return detailsTab.dataNew.usesRemaining;
  }
};

export const selectAppDetailsEnrolmentKeyNewIpConstraints = ({
  App: { detailsTab },
}: MainState) => {
  if (detailsTab.type === 'enrolment-key-create' || detailsTab.type === 'enrolment-key-edit') {
    return detailsTab.dataNew.ipConstraints;
  }
};

export const selectAppDetailsEnrolmentKeyNewApprovalMode = ({ App: { detailsTab } }: MainState) => {
  if (detailsTab.type === 'enrolment-key-create' || detailsTab.type === 'enrolment-key-edit') {
    return detailsTab.dataNew.approvalMode;
  }
};

export const selectAppDetailsEnrolmentKeyNewDisconnectedRetentionMinutes = ({
  App: { detailsTab },
}: MainState) => {
  if (detailsTab.type === 'enrolment-key-edit' || detailsTab.type === 'enrolment-key-create') {
    return detailsTab.dataNew.disconnectedRetentionMinutes;
  }
};

export const selectAppDetailsEnrolmentKeyNewNotes = ({ App: { detailsTab } }: MainState) => {
  if (detailsTab.type === 'enrolment-key-edit' || detailsTab.type === 'enrolment-key-create') {
    return detailsTab.dataNew.notes;
  }
};

export const selectAppDetailsEnrolmentKeyAutoExpireNewDate = ({ App }: MainState) => {
  if (
    App.detailsTab.type === 'enrolment-key-edit' ||
    App.detailsTab.type === 'enrolment-key-create'
  ) {
    return App.detailsTab.dataNew.autoExpire?.expiryDateTime;
  }
};

export const selectAppDetailsEnrolmentKeyAutoExpireNewAction = ({ App }: MainState) => {
  if (
    App.detailsTab.type === 'enrolment-key-edit' ||
    App.detailsTab.type === 'enrolment-key-create'
  ) {
    return App.detailsTab.dataNew.autoExpire?.expiryAction;
  }
};

export const selectAppDetailsEnrolmentKeyAutoExpireNewTimeZone = ({ App }: MainState) => {
  if (
    App.detailsTab.type === 'enrolment-key-edit' ||
    App.detailsTab.type === 'enrolment-key-create'
  ) {
    return App.detailsTab.dataNew.autoExpire?.timeZoneId;
  }
};

export const selectAppDetailsEnrolmentKeyAutoExpireScrollTo = ({ App }: MainState) => {
  if (App.detailsTab.type === 'enrolment-key-edit') {
    return App.detailsTab.scrollToAutoExpire;
  }
};

// Details Current Data
export const selectAppDetailsEnrolmentKeyCurrentType = ({ App: { detailsTab } }: MainState) => {
  if (detailsTab.type === 'enrolment-key-view') return detailsTab.data.type;
  if (detailsTab.type === 'enrolment-key-edit' || detailsTab.type === 'enrolment-key-create')
    return detailsTab.dataNew.type;
};

export const selectIsEnrolmentKeysModule = ({
  router: {
    location: { pathname },
  },
  Account: { currentOrg },
}: MainState) => {
  return pathname.includes(`${currentOrg}${ROUTE_PATHS.keys}`);
};
