// libs
import { css } from 'styled-components';

// components
import { Details } from 'components';
import { ConfigurationSection } from './styled';
import { IconTrustRequirementDetailsConfigurationSection } from 'assets/svg';
import { ConfigurationDescriptionBlock } from '../../Blocks';

// hooks
import { useTranslations } from 'hooks/useTranslations';

interface IWrapper {
  children: React.ReactNode;
}

export const Wrapper = ({ children }: IWrapper) => {
  const { translate } = useTranslations('trust-requirements');

  return (
    <ConfigurationSection>
      <Details.SectionHeader
        Icon={IconTrustRequirementDetailsConfigurationSection}
        title={translate('details.configuration')}
        css={css`
          margin-bottom: 0.5rem;
        `}
      />
      <ConfigurationDescriptionBlock />
      {children}
    </ConfigurationSection>
  );
};
