// libs
import { css, useTheme } from 'styled-components';

// components
import { GatewayName, GatewayRoute } from '../styled';
import { GatewaysContainer } from './styled';
import { CustomText } from 'typography/Text';

// hooks
import { useTranslations } from 'hooks/useTranslations';

// types
import { PolicyGatewayDetails } from 'types';

interface IGatewayList {
  gateways: PolicyGatewayDetails[];
  width?: string;
  blocksMode?: boolean;
  likeSelect?: boolean;
}

export const GatewaysList = ({
  gateways,
  width,
  blocksMode = false,
  likeSelect = false,
  ...rest
}: IGatewayList) => {
  const { translate } = useTranslations('policies');

  const {
    palette: { fonts },
  } = useTheme();

  return gateways.length ? (
    <GatewaysContainer blocksMode={blocksMode} width={width} {...rest}>
      {gateways &&
        gateways.map(gateway => (
          <div key={gateway.systemId}>
            <GatewayName>
              {gateway.systemName || gateway.machineName || gateway.systemId}
            </GatewayName>

            <div>
              {gateway.routes.map((routeEntry, index) => (
                <GatewayRoute
                  route={routeEntry.route}
                  name={routeEntry.gatewayName}
                  key={routeEntry.route}
                  isPreferred={routeEntry.gatewayWeight > 0}
                  isFirst={index === 0}
                  isLast={index === gateway.routes.length - 1}
                />
              ))}
            </div>
          </div>
        ))}
    </GatewaysContainer>
  ) : likeSelect ? (
    <CustomText
      type="body-2"
      color={fonts.disabled}
      css={css`
        display: block;
        margin-left: 0.7rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
      `}
    >
      {translate('none')}
    </CustomText>
  ) : (
    <CustomText type="body-2" color={fonts.bodyDark}>
      {translate('none')}
    </CustomText>
  );
};
