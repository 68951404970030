import styled from 'styled-components';

export const OptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InvitationRecord = styled.div<{ noDivider?: boolean }>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: ${({ noDivider }) => (noDivider ? '0' : '1rem')};
`;

export const InvitationDescription = styled.div`
  display: flex;
  align-items: center;
  flex: 0 1 70%;
  div:nth-child(1) {
    margin-right: 0.5rem;
  }
`;

export const ActionsWrapper = styled.div`
  display: flex;
`;
