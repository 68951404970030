// libs
import { css } from 'styled-components';
import { forwardRef } from 'react';

// components
import { IconDnsAddZoneButton } from 'assets/svg';
import { CustomButton } from 'components';

// hooks
import { useTranslations } from 'hooks/useTranslations';

interface IButton {
  isEnabled: boolean;
  onClick?: () => void;
}

export const Button = forwardRef<HTMLDivElement, IButton>(
  ({ isEnabled, onClick, ...rest }, ref) => {
    const { translate } = useTranslations('dns');

    return (
      <div ref={ref} {...rest}>
        <CustomButton
          aria-label={translate('zones.aria_labels.create_zone')}
          allowDisabledFunctionality={false}
          kind={isEnabled ? 'primary' : 'disabled'}
          onClick={isEnabled ? onClick : undefined}
          width="2rem"
          css={css`
            padding-left: 0;
            padding-right: 0;
            ${!isEnabled &&
            css`
              svg path {
                fill: ${({ theme }) => theme.palette.icons.disabled};
              }
            `}
          `}
        >
          <IconDnsAddZoneButton />
        </CustomButton>
      </div>
    );
  }
);
