// components
import { CustomText } from 'typography/Text';
import { Select } from 'components';
import { TimeOption, EndTimeOption, IEndTime } from './TimeOption';

// hooks
import styled, { css, useTheme } from 'styled-components';
import { KeyboardEventHandler, useCallback, useEffect, useState } from 'react';
import { useTranslations } from 'hooks/useTranslations';
import { useDispatch, useSelector } from 'react-redux';

// selectors
import {
  selectAppDetailsPolicyEndTime,
  selectAppDetailsPolicyStartTime,
  selectAppDetailsPolicyNewEndTime,
  selectAppDetailsPolicyNewStartTime,
} from 'redux/selectors';

// actions
import { updatePolicyDetailsActiveHours } from 'redux/actions/app';

// utils
import { checkIfValidTimeString, convertTime12to24, formTimeString, is12HFormatUsed } from 'utils';
import { checkIfNextDay } from '../helpers';

interface ISetNewEndTime {
  [index: string]: any;
  hours: IEndTime['hours'];
  minutes: IEndTime['minutes'];
}

const ACTIVE_HOURS_DATA_SOURCE = [...Array(24).keys()].map(time => {
  return { hours: time, minutes: 0 };
});

const is12HFormat = is12HFormatUsed();

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const ActiveHoursEdit = () => {
  const dispatch = useDispatch();

  const { translate } = useTranslations('policies');

  const {
    palette: { fonts },
  } = useTheme();

  const [endTimeDataSource, setEndTimeDataSource] = useState<IEndTime[]>([]);

  const selectedStartTime = useSelector(selectAppDetailsPolicyStartTime);
  const newStartTime = useSelector(selectAppDetailsPolicyNewStartTime);
  const displayedStartTime = selectedStartTime || newStartTime || ACTIVE_HOURS_DATA_SOURCE[8];

  const selectedEndTime = useSelector(selectAppDetailsPolicyEndTime);
  const newEndTime = useSelector(selectAppDetailsPolicyNewEndTime);
  const displayedEndTime = selectedEndTime || newEndTime || ACTIVE_HOURS_DATA_SOURCE[17];

  const setNewStartTime = useCallback(
    (newStartTime: typeof ACTIVE_HOURS_DATA_SOURCE[0]) => {
      dispatch(
        updatePolicyDetailsActiveHours({
          startTime: { hours: newStartTime.hours, minutes: newStartTime.minutes },
        })
      );
    },
    [dispatch]
  );

  const setNewEndTime = useCallback(
    (newEndTime: ISetNewEndTime) => {
      dispatch(
        updatePolicyDetailsActiveHours({
          endTime: { hours: newEndTime.hours, minutes: newEndTime.minutes },
        })
      );
    },
    [dispatch]
  );

  const getEndTimeDisplayedValue = useCallback(() => {
    const isToNextDay = checkIfNextDay(displayedEndTime, displayedStartTime);

    return `${formTimeString(displayedEndTime.hours, displayedEndTime.minutes, is12HFormat)}${
      isToNextDay ? ' (+ next day)' : ''
    }`;
  }, [displayedStartTime, displayedEndTime]);

  const handleFromManualInput = useCallback(
    (inputValue: string) => {
      if (checkIfValidTimeString(inputValue, is12HFormat)) {
        let hours, minutes;
        if (is12HFormat) {
          const { hours: convertedHours, minutes: convertedMinutes } =
            convertTime12to24(inputValue);
          hours = convertedHours;
          minutes = convertedMinutes;
        } else {
          const [parsedHours, parsedMinutes] = inputValue.split(':');
          hours = Number(parsedHours);
          minutes = Number(parsedMinutes);
        }

        setNewStartTime({ hours, minutes });
      }
    },
    [setNewStartTime]
  );

  const handleUntilManualInput = useCallback(
    (inputValue: string) => {
      if (checkIfValidTimeString(inputValue, is12HFormat)) {
        let hours, minutes;
        if (is12HFormat) {
          const { hours: convertedHours, minutes: convertedMinutes } =
            convertTime12to24(inputValue);
          hours = convertedHours;
          minutes = convertedMinutes;
        } else {
          const [parsedHours, parsedMinutes] = inputValue.split(':');
          hours = Number(parsedHours);
          minutes = Number(parsedMinutes);
        }

        setNewEndTime({ hours, minutes, nextDay: false, divider: false });
      }
    },
    [setNewEndTime]
  );

  useEffect(() => {
    const currentDayHours = [
      ...ACTIVE_HOURS_DATA_SOURCE.slice(
        displayedStartTime.hours + 1,
        ACTIVE_HOURS_DATA_SOURCE.length
      ).map(time => {
        return { ...time, nextDay: false, divider: false };
      }),
    ];

    const nextDayHours = ACTIVE_HOURS_DATA_SOURCE.slice(0, displayedStartTime.hours).map(time => {
      return { ...time, nextDay: true, divider: false };
    });

    const newEndTimeDataSource = [
      ...currentDayHours,
      { divider: true, hours: 99, minutes: 0, nextDay: false },
      ...nextDayHours,
    ];

    setEndTimeDataSource(newEndTimeDataSource);
  }, [displayedStartTime]);

  const onKeyDownStart: KeyboardEventHandler<HTMLInputElement> = useCallback(
    e => {
      if (e.key === 'Enter') {
        //@ts-ignore
        e.target?.blur();

        setNewStartTime({ hours: displayedStartTime?.hours, minutes: displayedStartTime?.minutes });
      }
    },
    [displayedStartTime?.hours, displayedStartTime?.minutes, setNewStartTime]
  );

  const onKeyDownEnd: KeyboardEventHandler<HTMLInputElement> = useCallback(
    e => {
      if (e.key === 'Enter') {
        //@ts-ignore
        e.target?.blur();

        setNewEndTime({ hours: displayedEndTime?.hours, minutes: displayedEndTime?.minutes });
      }
    },
    [displayedEndTime?.hours, displayedEndTime?.minutes, setNewEndTime]
  );

  return (
    <Container>
      <Select
        showSearch={true}
        onSearch={handleFromManualInput}
        filterOption={false}
        aria-label={translate('details.active_hours_from')}
        data-test="policy-details-active-hours-from-select"
        dataSource={ACTIVE_HOURS_DATA_SOURCE}
        valueField="hours"
        keyField="hours"
        onKeyDown={onKeyDownStart}
        OptionElement={option => {
          return (
            <TimeOption hours={option.hours} minutes={option.minutes} is12hFormat={is12HFormat} />
          );
        }}
        setSelectedValue={setNewStartTime}
        value={formTimeString(displayedStartTime?.hours, displayedStartTime?.minutes, is12HFormat)}
        height="2rem"
        containerCss={css`
          flex: 10 10 0;
          max-width: 9.375rem;
        `}
        defaultValueFieldValue={undefined}
        error={null}
        tooltipProps={{ placement: 'topRight' }}
      />
      <CustomText
        type="body-2"
        color={fonts.bodyLight}
        css={css`
          margin: 0 1rem;
        `}
      >
        {translate('details.time_limitations.until')}
      </CustomText>
      <Select
        showSearch={true}
        onSearch={handleUntilManualInput}
        filterOption={false}
        aria-label={translate('details.active_hours_from')}
        data-test="policy-details-active-hours-to-select"
        dataSource={endTimeDataSource}
        valueField="hours"
        keyField="hours"
        onKeyDown={onKeyDownEnd}
        fieldIsDisabled={option => option.divider}
        OptionElement={option => {
          return (
            <EndTimeOption
              hours={option.hours}
              minutes={option.minutes}
              divider={option.divider}
              nextDay={option.nextDay}
              is12hFormat={is12HFormat}
            />
          );
        }}
        setSelectedValue={setNewEndTime}
        value={getEndTimeDisplayedValue()}
        height="2rem"
        containerCss={css`
          flex: 10 10 0;
          max-width: 12.5rem;
        `}
        defaultValueFieldValue={undefined}
        error={null}
        tooltipProps={{ placement: 'topRight' }}
      />
    </Container>
  );
};
