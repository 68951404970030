// Lib
import { call, put, select } from 'typed-redux-saga';

// selectors
import { selectAppDetailsTabDataNew } from 'redux/selectors';

// actions
import { updateTagsId } from 'redux/actions/tags';

// Utils
import { orgPush } from '../../utils';
import { ApiTypeCaller } from '../../api/utils';
import { translator, trustRequirementsToNumbers } from 'utils';

// types
import { ICreateTagParams, IDetailedTag, INotificationType } from 'types';

// constants
import { ROUTE_PATHS } from 'appConstants';
import { pushNotification } from 'redux/actions/app';

export function* createTagSaga() {
  const translate = translator('tags');

  const { tag, colour, notes, trustRequirements }: IDetailedTag = yield select(
    selectAppDetailsTabDataNew
  );

  const trustRequirementsNumbers = trustRequirementsToNumbers(trustRequirements);

  const createTagParams: ICreateTagParams = {
    tag,
    colour: colour === '' ? null : colour,
    notes,
    trustRequirements: trustRequirementsNumbers,
  };

  const { result, success } = yield* ApiTypeCaller('createTag', createTagParams);

  if (success && result) {
    yield call(orgPush, `${ROUTE_PATHS.tags}/${result.tag}`, { keepSearch: true });
    yield put(updateTagsId());
    yield put(
      pushNotification({
        showType: INotificationType.Success,
        description: translate('created'),
      })
    );
  }
}
