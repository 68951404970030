// libs
import { css } from 'styled-components';

// components
import { Details } from 'components';
import { SelectType } from './SelectType';
import {
  CancelSave,
  TopMenuButtons,
  Header,
  Overview,
  Configuration,
  Conditions,
} from './Sections';

// hooks
import { useSelector } from 'react-redux';

// selectors
import { selectAppDetailsTrustRequirementIsTypeSelected } from 'redux/selectors';

export const TrustRequirementDetails = () => {
  // we only select type on create mode
  const isTypeSelected = useSelector(selectAppDetailsTrustRequirementIsTypeSelected);

  return (
    <>
      <Details.Pane>
        <Details.ContentWrapper
          data-test="trust-requirement-details-content"
          css={css`
            position: relative;
          `}
        >
          <Details.ScrollPaddingContainer wide={true}>
            <TopMenuButtons />
            {!isTypeSelected ? (
              <SelectType />
            ) : (
              <>
                <Header />
                <Overview />
                <Configuration />
                <Conditions />
              </>
            )}
          </Details.ScrollPaddingContainer>
        </Details.ContentWrapper>
        <CancelSave />
      </Details.Pane>
    </>
  );
};
