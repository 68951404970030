// Libs
import { useCallback, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

// Components
import { FilterAndBulkActionsContainer, HeaderRow } from './styled';
import { CustomText } from 'typography/Text';
import { CustomButton, PageTitle, Filter, TrialBanner } from 'components';
import { BulkActions } from './BulkActions';
import { Table } from './Table';

// Hooks
import { useSelector, useDispatch } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';
import { useEnrolmentKeysEmptyText } from './useEnrolmentKeysEmptyText';
import { useParams } from 'react-router';

// Actions
import {
  getEnrolmentKeys,
  getEnrolmentKeySearchKeys,
  setEnrolmentKeys,
} from 'redux/actions/enrolment-keys';
import {
  closeDetails,
  createEnrolmentKeySideMenu,
  loadEnrolmentKeyDetails,
} from 'redux/actions/app';

// Selectors
import {
  selectEnrolmentKeysIsLoading,
  selectEnrolmentKeysTotal,
  selectEnrolmentKeysUpdateId,
  selectAppDetailsEnrolmentKeyId,
  selectAppDetailsTabType,
  selectIsEnrolmentKeysModule,
  selectPathQueryIncludeDisabled,
  selectPathQuerySearch,
  selectPathQuerySort,
  selectEnrolmentKeySearchKeys,
} from 'redux/selectors';

// types
import { IEnrolmentKey } from 'types';

// constants
import { ITEMS_FOR_SKELETON, KEYS_SORT_OPTIONS } from 'appConstants';
import { initialEnrolmentKeysResponse } from 'redux/reducers/enrolment-keys';

export const emptyEnrolledKeys = Array.from({ length: ITEMS_FOR_SKELETON }, (_el, index) => ({
  id: index.toString(),
})) as IEnrolmentKey[];

interface IEnrolmentKeyParams {
  keyId: string;
}

export const EnrolmentKeys = () => {
  const dispatch = useDispatch();

  const { translate } = useTranslations('enrolment-keys');

  const { keyId } = useParams<keyof IEnrolmentKeyParams>();

  const total = useSelector(selectEnrolmentKeysTotal);
  const updateId = useSelector(selectEnrolmentKeysUpdateId);
  const isLoading = useSelector(selectEnrolmentKeysIsLoading);
  const queryParamsSort = useSelector(selectPathQuerySort);
  const queryParamsSearch = useSelector(selectPathQuerySearch);
  const queryParamsIncludeDisabed = useSelector(selectPathQueryIncludeDisabled);
  const detailsType = useSelector(selectAppDetailsTabType);
  const selectedKeyId = useSelector(selectAppDetailsEnrolmentKeyId);
  const isEnrolmentKeysModule = useSelector(selectIsEnrolmentKeysModule);
  const searchKeys = useSelector(selectEnrolmentKeySearchKeys);

  const [page, setPage] = useState(0);
  const [searchValue, setSearchValue] = useState(queryParamsSearch || '');

  const [showDisabled, setShowDisabled] = useState<boolean>(queryParamsIncludeDisabed || false);
  const [bulkSelectedKeys, setBulkSelectedKeys] = useState<IEnrolmentKey[]>([]);
  const [sortBy, setSortBy] = useState(
    KEYS_SORT_OPTIONS.find(option => option.value === queryParamsSort) || KEYS_SORT_OPTIONS[0]
  );

  const {
    palette: { fonts },
  } = useTheme();

  useEffect(() => {
    dispatch(getEnrolmentKeySearchKeys());
  }, [dispatch]);

  useEffect(() => {
    if (isEnrolmentKeysModule && keyId) {
      if (detailsType !== 'enrolment-key-view' && detailsType !== 'enrolment-key-edit') {
        dispatch(
          loadEnrolmentKeyDetails({ enrolmentKeyId: String(keyId), mode: 'view', redirect: false })
        );
        return;
      }
      if (
        (detailsType === 'enrolment-key-view' || detailsType === 'enrolment-key-edit') &&
        keyId !== String(selectedKeyId)
      ) {
        dispatch(
          loadEnrolmentKeyDetails({ enrolmentKeyId: String(keyId), mode: 'view', redirect: false })
        );
        return;
      }
    }

    if (!keyId && detailsType === 'enrolment-key-view') {
      dispatch(closeDetails({ redirect: false }));
    }
    /*
      Temporary solution. Covers back-button behavior that should close details pane in edit-mode
      Causes bug, that prevents details pane from opening via context-menu
    
      if (!keyId && selectedKeyId) dispatch(closeDetails({ redirect: false }));
    */
  }, [keyId, detailsType, selectedKeyId, dispatch, isEnrolmentKeysModule]);

  const { emptyText } = useEnrolmentKeysEmptyText({
    searchValue,
  });

  const createNewKey = useCallback(() => dispatch(createEnrolmentKeySideMenu()), [dispatch]);

  const fetchData = useCallback(
    () =>
      dispatch(
        getEnrolmentKeys({
          include_disabled: showDisabled,
          search: searchValue,
          page: page || 0,
          sort: sortBy.value,
        })
      ),
    [dispatch, page, searchValue, showDisabled, sortBy]
  );

  useEffect(() => {
    dispatch(fetchData());
  }, [dispatch, updateId, fetchData]);

  useEffect(() => {
    return () => {
      dispatch(setEnrolmentKeys(initialEnrolmentKeysResponse));
    };
  }, [dispatch]);

  useEffect(() => {
    setPage(0);
  }, [searchValue]);

  return (
    <>
      <PageTitle title={translate('enrolment_keys')} />
      <HeaderRow>
        <CustomText data-test="enrolment-keys-header" type="heading-1" color={fonts.title}>
          {translate('enrolment_keys')}
        </CustomText>
        <CustomButton
          kind="primary"
          size="large"
          onClick={createNewKey}
          disabled={detailsType === 'enrolment-key-create'}
          data-test="create-enrolment-key"
        >
          {translate('create_enrolment_key')}
        </CustomButton>
      </HeaderRow>
      <TrialBanner />
      <FilterAndBulkActionsContainer>
        {bulkSelectedKeys.length ? (
          <BulkActions
            bulkSelectedKeys={bulkSelectedKeys}
            setBulkSelectedKeys={setBulkSelectedKeys}
          />
        ) : (
          <Filter
            {...{ total, page, fetchData }}
            {...{ searchValue, setSearchValue }}
            {...{ showDisabled, setShowDisabled }}
            {...{ sortBy, setSortBy, isLoading, searchKeys }}
            sortOptions={KEYS_SORT_OPTIONS}
          />
        )}
      </FilterAndBulkActionsContainer>
      <Table
        page={page}
        setPage={setPage}
        isLoading={isLoading}
        total={total}
        bulkSelectedKeys={bulkSelectedKeys}
        setBulkSelectedKeys={setBulkSelectedKeys}
        emptyText={emptyText}
        selectedKeyId={keyId}
      />
    </>
  );
};
