export const ENC_HOSTS = {
  API: `${process.env.ENC_API_HOST}/`,
  OIDC: `${process.env.ENC_OIDC_HOST}/`,
};

export const API_ENDPOINTS = {
  systems: 'systems',
  enrolmentKeys: 'enrolment-keys',
  authority: 'authority',
  organisation: 'organisation',
  unapprovedSystems: 'unapproved-systems',
  securityEvents: 'logs',
  account: 'account',
  tags: 'tags',
  policies: 'policies',
  dns: 'dns',
  'trust-requirements': 'trust-requirements',
};

export const ENDPOINT_RETRY_DELAY = 5000;
export const ENDPOINT_RETRY_NUMBER = 5;
