// Libs
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Hooks
import { useDispatch } from 'react-redux';

// Selectors
import { selectLastLocation } from 'redux/selectors';

// Actions
import { redirectControl } from 'redux/actions/app';

// Variables
import { OIDCConstants } from 'appConstants';

export const useGoBack = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const lastLocation = useSelector(selectLastLocation);

  const canGoBack = useMemo(() => {
    if (!lastLocation || lastLocation === `/${OIDCConstants.oidcRedirect}`) return false;
    return true;
  }, [lastLocation]);

  return canGoBack ? () => navigate(-1) : () => dispatch(redirectControl({ route: '/' }));
};
