import styled, { css } from 'styled-components';

export const Container = styled.div<{ isActive: boolean; warning?: boolean }>`
  border: 0.0625rem solid;
  border-color: ${({ theme, isActive }) =>
    isActive ? theme.palette.fills.primary : theme.palette.fills.strokeMedium};
  border-color: ${({ theme, warning }) =>
    warning ? theme.palette.state.inputWarning : theme.palette.fills.strokeMedium};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  border-radius: 0.25rem;
  margin-bottom: ${({ warning }) => (warning ? '2.25rem' : '0')};

  // calendar input icon
  .ant-picker-suffix {
    display: none;
  }

  // caledar dropdown container
  .ant-picker-panel-container {
    box-shadow: none;
    border: none;
    border: 0.0625rem solid;
    border-color: ${({ theme }) => theme.palette.fills.strokeMedium};
    border-radius: none;
    border-radius: 0.25rem 0 0 0.25rem;
  }
  .ant-picker-panel-container .ant-picker-panel {
    box-shadow: none;
    border: none;
  }

  // calendar dropdown top buttons
  .ant-picker-decade-btn:hover {
    color: ${({ theme }) => theme.palette.fills.primary};
  }

  .ant-picker-year-btn:hover {
    color: ${({ theme }) => theme.palette.fills.primary};
  }

  .ant-picker-month-btn:hover {
    color: ${({ theme }) => theme.palette.fills.primary};
  }

  // calendar selected date
  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
    background: ${({ theme }) => theme.palette.fills.primary};
  }

  // calendar today
  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border-color: ${({ theme }) => theme.palette.fills.strokeMedium};
  }

  // time select dropdown
  .rc-virtual-list {
    border: none;
    margin: 1px 0;
    border: 0.0625rem solid;
    border-color: ${({ theme }) => theme.palette.fills.strokeMedium};
    border-left: none;
    border-radius: 0 0.25rem 0.25rem 0 !important;
  }

  .rc-virtual-list-holder {
    min-height: 269px !important;
  }

  // time select active option
  .ant-select-item-option-active {
    border-color: ${({ theme }) => theme.palette.background.hover};
    background-color: ${({ theme }) => theme.palette.background.hover};
  }

  // empty search
  .ant-select-item-empty {
    min-height: 269px !important;
  }

  .ant-select-dropdown {
    border: none;
    box-shadow: none;
  }

  .ant-select-dropdown .ant-select-dropdown-empty {
    border: 0.0625rem solid;
    border-color: ${({ theme }) => theme.palette.fills.strokeMedium};
  }

  .ant-select-selector {
    box-shadow: none !important;
    border: none !important;
  }
`;

export const timeSelectContainerCss = css`
  flex: 10 10 0;
  border-left: 0.0625rem solid;
  border-color: ${({ theme }) => theme.palette.fills.strokeMedium};
`;

export const datePickerCss = css`
  width: 280px;
  border: none;
  box-shadow: none;
`;

export const WarningMessageContainer = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  top: 2.25rem;
`;
