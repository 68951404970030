import {
  createSystemDetailsAutoExpire,
  createSystemDetailsRoute,
  deleteSystemDetailsRoute,
  setSystemDetailsKnownRoutes,
  setSystemDetailsRoutes,
  updateSystemDetails,
  updateSystemDetailsAutoExpire,
  updateSystemDetailsRoute,
} from '../../../actions/app';

import { appReducerBuilder } from '..';
import { findSystemSubnetDuplicates, getTimeZoneOffset, randomHash, translator } from 'utils';
import {
  defaultTimeZone,
  DEFAULT_AUTO_EXPIRE_DATA,
  SYSTEM_GATEWAY_ROUTE_VALIDATION_STATE_PREFIX,
} from 'appConstants';
import { ISystemGatewayRouteModified } from 'types';
import { validateSubnet } from 'modules/Systems/Details/Blocks/Gateway/helpers';
import dayjs from 'dayjs';

const translate = translator('systems');
const duplicatesMessage = translate('details.gateway.subnet_duplicate_error');

export const detailsTabSystemsReducerSlice: appReducerBuilder = builder => {
  builder
    .addCase(updateSystemDetails, (state, { payload }) => {
      if (
        state.detailsTab.type === 'system-edit' ||
        state.detailsTab.type === 'unapproved-system-edit'
      ) {
        state.detailsTab.dataNew = {
          ...state.detailsTab.dataNew,
          ...payload,
        };
      }
    })
    .addCase(setSystemDetailsRoutes, (state, { payload }) => {
      if (state.detailsTab.type === 'system-edit') {
        state.detailsTab.dataNew.gatewayRoutes = payload;
      }
    })
    .addCase(setSystemDetailsKnownRoutes, (state, { payload }) => {
      if (state.detailsTab.type === 'system-edit') {
        const existingRoutes = state.detailsTab.dataNew.gatewayRoutes;

        if (!existingRoutes?.length) {
          state.detailsTab.dataNew.gatewayRoutes = payload.map(route => ({
            id: randomHash(),
            subnet: route,
            userEntered: false,
            weight: 0,
            name: null,
          }));
        } else {
          const existingKnownSubnets = state.detailsTab.dataNew.gatewayRoutes.filter(
            route => !route.userEntered
          );

          // when we reset subnets; make sure we preserve any existing known subnet items,
          // since they may have been marked as preferred,
          // or had a specific name added.
          const knownSubnets = payload.map(route => {
            const existing = existingKnownSubnets.find(
              existingRoute => existingRoute.subnet === route
            );

            return (
              existing || {
                id: randomHash(),
                subnet: route,
                userEntered: false,
                weight: 0,
                name: null,
              }
            );
          });

          state.detailsTab.dataNew.gatewayRoutes = [
            ...knownSubnets,
            ...state.detailsTab.dataNew.gatewayRoutes.filter(route => route.userEntered),
          ];

          state.detailsTab.validationState = findSystemSubnetDuplicates({
            routes: state.detailsTab.dataNew.gatewayRoutes,
            validationValue: {
              state: { error: { message: duplicatesMessage } },
              updated: true,
            },
            currentValidationState: state.detailsTab.validationState,
          });
        }
      }
    })
    .addCase(deleteSystemDetailsRoute, (state, { payload }) => {
      if (state.detailsTab.type === 'system-edit') {
        state.detailsTab.dataNew.gatewayRoutes = state.detailsTab.dataNew.gatewayRoutes.filter(
          route => route.id !== payload.id
        );

        delete state.detailsTab.validationState[
          SYSTEM_GATEWAY_ROUTE_VALIDATION_STATE_PREFIX + payload.id
        ];

        state.detailsTab.validationState = findSystemSubnetDuplicates({
          routes: state.detailsTab.dataNew.gatewayRoutes,
          validationValue: {
            state: { error: { message: duplicatesMessage } },
            updated: true,
          },
          currentValidationState: state.detailsTab.validationState,
        });
      }
    })
    .addCase(createSystemDetailsRoute, state => {
      if (state.detailsTab.type === 'system-edit') {
        const newRoute: ISystemGatewayRouteModified = {
          id: randomHash(),
          subnet: '',
          userEntered: true,
          name: null,
          weight: 0,
        };
        state.detailsTab.dataNew.gatewayRoutes.push(newRoute);

        state.detailsTab.validationState[
          SYSTEM_GATEWAY_ROUTE_VALIDATION_STATE_PREFIX + newRoute.id
        ] = {
          state: { error: { message: translate('details.gateway.subnet_error') } },
          updated: false,
        };
      }
    })
    .addCase(updateSystemDetailsRoute, (state, { payload }) => {
      if (state.detailsTab.type === 'system-edit') {
        const index = state.detailsTab.dataNew.gatewayRoutes.findIndex(
          route => route.id === payload.id
        );

        if (index !== -1) {
          const validationState = validateSubnet({ cidr: payload.subnet });

          state.detailsTab.validationState[
            SYSTEM_GATEWAY_ROUTE_VALIDATION_STATE_PREFIX + payload.id
          ] = {
            state: validationState,
            updated: true,
          };

          const routeEntry = state.detailsTab.dataNew.gatewayRoutes[index];

          routeEntry.subnet = payload.subnet;
          routeEntry.name = payload.name;
          routeEntry.weight = payload.weight;

          state.detailsTab.validationState = findSystemSubnetDuplicates({
            routes: state.detailsTab.dataNew.gatewayRoutes,
            validationValue: {
              state: { error: { message: duplicatesMessage } },
              updated: true,
            },
            currentValidationState: state.detailsTab.validationState,
          });
        }
      }
    })
    .addCase(createSystemDetailsAutoExpire, state => {
      if (state.detailsTab.type === 'system-edit' && !state.detailsTab.dataNew.autoExpire) {
        state.detailsTab.dataNew.autoExpire = {
          ...DEFAULT_AUTO_EXPIRE_DATA,
        };
      }
    })
    .addCase(updateSystemDetailsAutoExpire, (state, { payload }) => {
      if (state.detailsTab.type === 'system-edit') {
        if (payload === null) {
          state.detailsTab.dataNew.autoExpire = null;
        } else {
          let assignLocalTimezone = false;
          let reformatDateTime = false;
          const dateTime =
            payload.expiryDateTime || state.detailsTab.dataNew.autoExpire?.expiryDateTime;

          if (!state.detailsTab.dataNew.autoExpire?.timeZoneId && !payload.timeZoneId) {
            assignLocalTimezone = true;
          }

          if (!state.detailsTab.dataNew.autoExpire?.timeZoneId) {
            if (dateTime && dateTime?.length > 19) {
              reformatDateTime = true;
            }
          }

          //@ts-ignore
          state.detailsTab.dataNew.autoExpire = {
            ...state.detailsTab.dataNew.autoExpire,
            ...payload,
            ...(assignLocalTimezone
              ? {
                  timeZoneId: defaultTimeZone.zone,
                }
              : {}),
            ...(reformatDateTime
              ? {
                  expiryDateTime: dayjs
                    .utc(dateTime)
                    .utcOffset(
                      getTimeZoneOffset(
                        payload.timeZoneId ||
                          state.detailsTab.dataNew.autoExpire?.timeZoneId ||
                          defaultTimeZone.zone
                      )
                    )
                    .format('YYYY-MM-DDTHH:mm:ss'),
                }
              : {}),
          };
        }
      }
    });
};
