// Libs
import { css } from 'styled-components';

// Components
import { PlanContainer, PlanDetails, UpgradeLink, IconArrowRight } from './styled';
import { CustomText } from 'typography/Text';
import { InternalLink, CustomProgress, PlanDependentContent } from 'components';

// Hooks
import { useTheme } from 'styled-components';
import { useSelector } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';

// Selectors
import {
  selectCurrentOrganisationCapacity,
  selectCurrentOrganisationInfo,
  selectCurrentOrganisationPlan,
} from 'redux/selectors';

// Constants
import {
  EXTERNAL_PLAN,
  MSP_MANAGED_PLAN,
  MSP_PLAN,
  PLANS_N_SUBS_ANCHOR,
  UNLIMITED_USES_AMOUNT,
} from 'appConstants';

// types
import { HTMLAttributes } from 'react';

export const OrganisationPlan = (props: HTMLAttributes<HTMLDivElement>) => {
  const { translate } = useTranslations('settings');

  const {
    palette: { fonts, background },
  } = useTheme();

  const orgData = useSelector(selectCurrentOrganisationInfo);
  const currentPlan = useSelector(selectCurrentOrganisationPlan);
  const maxSystems = useSelector(selectCurrentOrganisationCapacity);

  return maxSystems !== UNLIMITED_USES_AMOUNT || currentPlan !== EXTERNAL_PLAN ? (
    <PlanContainer {...props}>
      <PlanDetails>
        <PlanDependentContent
          plan={currentPlan}
          hiddenWith={[MSP_PLAN, MSP_MANAGED_PLAN, EXTERNAL_PLAN]}
        >
          <CustomText type="sub-heading-2">
            {currentPlan} {translate('plan')}
          </CustomText>
        </PlanDependentContent>

        {maxSystems !== UNLIMITED_USES_AMOUNT && (
          <>
            <CustomText type="hint" color={fonts.bodyLight}>
              {orgData.enrolledSystems}/{orgData.maxSystems} {translate('systems_used')}
            </CustomText>
            <CustomProgress
              backgroundColor={background.panel}
              percent={(orgData.enrolledSystems / orgData.maxSystems) * 100}
              css={css`
                margin-top: 0.25rem;
                margin-bottom: 0.75rem;
              `}
            />
          </>
        )}

        <PlanDependentContent
          plan={orgData.plan}
          hiddenWith={[MSP_PLAN, MSP_MANAGED_PLAN, EXTERNAL_PLAN]}
        >
          <InternalLink to={`/org/${orgData.id}/settings#${PLANS_N_SUBS_ANCHOR}`}>
            <UpgradeLink>
              {translate('upgrade')}
              <IconArrowRight />
            </UpgradeLink>
          </InternalLink>
        </PlanDependentContent>
      </PlanDetails>
    </PlanContainer>
  ) : null;
};
