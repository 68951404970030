import { Typography } from 'antd';
import styled, {
  css,
  useTheme,
  FlattenSimpleInterpolation,
  CSSProperties,
} from 'styled-components';
import { UI_TEXT_SIZES } from 'appConstants';
import { TextTypes, TypeWithDefaultSpanProps } from 'types';
import { TextProps } from 'antd/lib/typography/Text';

const { Text } = Typography;

export const StyledHeader = styled.h2`
  font-size: ${UI_TEXT_SIZES[5].fontSize};
  line-height: ${UI_TEXT_SIZES[5].lineHeight};
  color: ${props => props.theme.palette.fonts.bodyLight};
`;

export const stylesMap: Record<TextTypes, FlattenSimpleInterpolation> = {
  'heading-1': css`
    font-weight: 500;
    font-family: Barlow, sans-serif;
    font-size: ${UI_TEXT_SIZES[6].fontSize};
    line-height: ${UI_TEXT_SIZES[6].lineHeight};
  `,
  'heading-2': css`
    font-weight: 700;
    font-family: Roboto, sans-serif;
    font-size: ${UI_TEXT_SIZES[5].fontSize};
    line-height: ${UI_TEXT_SIZES[5].lineHeight};
  `,
  'sub-heading-1': css`
    font-weight: 700;
    font-family: Roboto, sans-serif;
    font-size: ${UI_TEXT_SIZES[3].fontSize};
    line-height: ${UI_TEXT_SIZES[3].lineHeight};
    letter-spacing: 0.009375rem;
  `,
  'body-1': css`
    font-weight: 400;
    font-family: Roboto, sans-serif;
    font-size: ${UI_TEXT_SIZES[3].fontSize};
    line-height: ${UI_TEXT_SIZES[3].lineHeight};
    letter-spacing: 0.009375rem;
  `,
  'sub-heading-2': css`
    font-weight: 700;
    font-family: Roboto, sans-serif;
    font-size: ${UI_TEXT_SIZES[2].fontSize};
    line-height: ${UI_TEXT_SIZES[2].lineHeight};
    letter-spacing: 0.00625rem;
  `,
  'body-2': css`
    font-weight: 400;
    font-family: Roboto, sans-serif;
    font-size: ${UI_TEXT_SIZES[2].fontSize};
    line-height: ${UI_TEXT_SIZES[2].lineHeight};
    letter-spacing: 0.03125rem;
  `,
  'label-1': css`
    font-weight: 700;
    font-family: Roboto, sans-serif;
    font-size: ${UI_TEXT_SIZES[1].fontSize};
    line-height: ${UI_TEXT_SIZES[1].lineHeight};
    letter-spacing: 0.078125rem;
    text-transform: uppercase;
  `,
  'label-2': css`
    font-weight: 700;
    font-family: Roboto, sans-serif;
    font-size: ${UI_TEXT_SIZES[1].fontSize};
    line-height: ${UI_TEXT_SIZES[1].lineHeight};
    letter-spacing: 0.025rem;
  `,
  hint: css`
    font-weight: 400;
    font-family: Roboto, sans-serif;
    font-size: ${UI_TEXT_SIZES[1].fontSize};
    line-height: ${UI_TEXT_SIZES[1].lineHeight};
    letter-spacing: 0.025rem;
  `,
  'command-line': css`
    font-weight: 400;
    font-family: Consolas, Monaco, sans-serif;
    font-size: ${UI_TEXT_SIZES[1].fontSize};
    line-height: ${UI_TEXT_SIZES[1].lineHeight};
    letter-spacing: 0.04rem;
  `,
  system: css`
    font-weight: 700;
    font-family: Nunito, sans-serif;
    font-size: ${UI_TEXT_SIZES[2].fontSize};
    line-height: ${UI_TEXT_SIZES[2].lineHeight};
    letter-spacing: 0.06rem;
  `,
};

export const StyledTitle = styled.h3`
  ${stylesMap['heading-1']}
  margin: 0;
  color: ${props => props.theme.palette.fonts.title};
`;
export const StyledSubtitle = styled.h4`
  ${stylesMap['sub-heading-1']}
  margin-bottom: 1rem;
  color: ${({ color, theme }) => (color ? color : theme.palette.fonts.title)};
`;

export interface ICustomText extends Omit<TextProps, 'type'> {
  type: TextTypes;
  color?: string;
  title?: string;
  children?: React.ReactNode;
  className?: string;
  ellipsis?: boolean;
  lineBreak?: CSSProperties['lineBreak'];
}
export const CustomText = ({
  type,
  color,
  children,
  title,
  ellipsis,
  lineBreak,
  ...rest
}: TypeWithDefaultSpanProps<ICustomText>) => {
  const theme = useTheme();

  return (
    <Text
      css={css`
        ${ellipsis &&
        css`
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        `};
        color: ${color || theme.palette.fonts.bodyDark};
        line-break: ${lineBreak || 'auto'};
        ${stylesMap[type]}
      `}
      title={title}
      {...rest}
    >
      {children}
    </Text>
  );
};
