// components
import { Details } from 'components';
import {
  TopMenuButtons,
  Header,
  Purpose,
  Overview,
  Usage,
  IpConstraints,
  CancelSave,
  AutoExpire,
} from './Sections';

export const EnrolmentKeysDetails = () => {
  return (
    <Details.Pane data-test="enrolment-key-details-content">
      <Details.ContentWrapper>
        <Details.ScrollPaddingContainer wide={true}>
          <TopMenuButtons />
          <Header />
          <Purpose />
          <Overview />
          <Usage />
          <IpConstraints />
          <AutoExpire />
        </Details.ScrollPaddingContainer>
      </Details.ContentWrapper>
      <CancelSave />
    </Details.Pane>
  );
};
