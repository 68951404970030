import styled from 'styled-components';

export const OsPlatformWrapper = styled.div`
  display: flex;
`;

export const OsPlatformIconWrapper = styled.div`
  margin-right: 1rem;
`;

export const OsPlatformDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
