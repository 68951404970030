// components
import { CustomMessage } from 'components';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { useSelector } from 'react-redux';

// selectors
import { selectAccountInvites, selectCurrentOrganisationExists } from 'redux/selectors';

export const MessageBlock = () => {
  const DoesOrgExist = useSelector(selectCurrentOrganisationExists);

  const invites = useSelector(selectAccountInvites);

  const { translate } = useTranslations('account');

  return !DoesOrgExist ? (
    <CustomMessage
      type="info"
      message={translate(
        invites.length ? 'no_org_yes_invites_message' : 'no_org_no_invites_message'
      )}
      textType="body-2"
      withIcon
    />
  ) : null;
};
