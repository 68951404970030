// components
import { IsBlockedView } from './IsBlockedView';

// hooks
import { useSelector } from 'react-redux';

// selectors
import { selectAppDetailsTrustRequirementCurrentLocationsIsBlocked } from 'redux/selectors';

export const LocationsAccessView = () => {
  const isBlocked =
    useSelector(selectAppDetailsTrustRequirementCurrentLocationsIsBlocked) || 'true';

  return <IsBlockedView isBlocked={isBlocked} />;
};
