import {
  setValidationState,
  deleteValidationStateEntry,
  updateValidationStateEntry,
  addValidationStateEntry,
  updateValidationStateUpdates,
  deactivateValidationState,
  activateValidationState,
} from '../../../actions/app';

import { IValidationState } from 'types';

import { appReducerBuilder } from '..';

export const detailsTabValidationReducerSlice: appReducerBuilder = builder => {
  builder
    .addCase(setValidationState, (state, { payload }) => {
      if (
        state.detailsTab.type === 'enrolment-key-create' ||
        state.detailsTab.type === 'enrolment-key-edit' ||
        state.detailsTab.type === 'policy-create' ||
        state.detailsTab.type === 'policy-edit' ||
        state.detailsTab.type === 'unapproved-system-edit' ||
        state.detailsTab.type === 'system-edit' ||
        state.detailsTab.type === 'dns-record-edit' ||
        state.detailsTab.type === 'dns-record-create' ||
        state.detailsTab.type === 'trust-requirement-edit' ||
        state.detailsTab.type === 'trust-requirement-create' ||
        state.detailsTab.type === 'tag-create' ||
        state.detailsTab.type === 'tag-edit'
      ) {
        state.detailsTab.validationState = payload;
      }
    })
    .addCase(updateValidationStateEntry, (state, { payload }) => {
      if (
        state.detailsTab.type === 'enrolment-key-create' ||
        state.detailsTab.type === 'enrolment-key-edit' ||
        state.detailsTab.type === 'policy-create' ||
        state.detailsTab.type === 'policy-edit' ||
        state.detailsTab.type === 'unapproved-system-edit' ||
        state.detailsTab.type === 'system-edit' ||
        state.detailsTab.type === 'dns-record-edit' ||
        state.detailsTab.type === 'dns-record-create' ||
        state.detailsTab.type === 'trust-requirement-edit' ||
        state.detailsTab.type === 'trust-requirement-create' ||
        state.detailsTab.type === 'tag-create' ||
        state.detailsTab.type === 'tag-edit'
      ) {
        // if payload.state is not defined then we just change "updated" stauts
        if (payload.state) {
          state.detailsTab.validationState = {
            ...state.detailsTab.validationState,
            [payload.key]: {
              state: payload.state,
              updated: true,
            },
          };
        } else {
          state.detailsTab.validationState[payload.key].updated = true;
        }
      }
    })
    .addCase(addValidationStateEntry, (state, { payload }) => {
      if (
        state.detailsTab.type === 'enrolment-key-create' ||
        state.detailsTab.type === 'enrolment-key-edit' ||
        state.detailsTab.type === 'policy-create' ||
        state.detailsTab.type === 'policy-edit' ||
        state.detailsTab.type === 'unapproved-system-edit' ||
        state.detailsTab.type === 'system-edit' ||
        state.detailsTab.type === 'dns-record-edit' ||
        state.detailsTab.type === 'dns-record-create' ||
        state.detailsTab.type === 'trust-requirement-edit' ||
        state.detailsTab.type === 'trust-requirement-create' ||
        state.detailsTab.type === 'tag-create' ||
        state.detailsTab.type === 'tag-edit'
      ) {
        state.detailsTab.validationState = {
          ...state.detailsTab.validationState,
          [payload.key]: { state: payload.state, updated: false },
        };
      }
    })
    .addCase(deleteValidationStateEntry, (state, { payload }) => {
      if (
        state.detailsTab.type === 'enrolment-key-create' ||
        state.detailsTab.type === 'enrolment-key-edit' ||
        state.detailsTab.type === 'policy-create' ||
        state.detailsTab.type === 'policy-edit' ||
        state.detailsTab.type === 'unapproved-system-edit' ||
        state.detailsTab.type === 'system-edit' ||
        state.detailsTab.type === 'dns-record-edit' ||
        state.detailsTab.type === 'dns-record-create' ||
        state.detailsTab.type === 'trust-requirement-edit' ||
        state.detailsTab.type === 'trust-requirement-create' ||
        state.detailsTab.type === 'tag-create' ||
        state.detailsTab.type === 'tag-edit'
      ) {
        const newValidationState: IValidationState = Object.assign(
          state.detailsTab.validationState
        );
        delete newValidationState[payload.key];
        state.detailsTab.validationState = newValidationState;
      }
    })
    .addCase(updateValidationStateUpdates, state => {
      if (
        state.detailsTab.type === 'enrolment-key-create' ||
        state.detailsTab.type === 'enrolment-key-edit' ||
        state.detailsTab.type === 'policy-create' ||
        state.detailsTab.type === 'policy-edit' ||
        state.detailsTab.type === 'unapproved-system-edit' ||
        state.detailsTab.type === 'system-edit' ||
        state.detailsTab.type === 'dns-record-edit' ||
        state.detailsTab.type === 'dns-record-create' ||
        state.detailsTab.type === 'trust-requirement-edit' ||
        state.detailsTab.type === 'trust-requirement-create' ||
        state.detailsTab.type === 'tag-create' ||
        state.detailsTab.type === 'tag-edit'
      ) {
        const validationState = state.detailsTab.validationState;
        Object.keys(validationState).filter(item => (validationState[item].updated = true));
        state.detailsTab.validationState = validationState;
      }
    })
    .addCase(deactivateValidationState, (state, { payload }) => {
      if (
        state.detailsTab.type === 'enrolment-key-create' ||
        state.detailsTab.type === 'enrolment-key-edit' ||
        state.detailsTab.type === 'policy-create' ||
        state.detailsTab.type === 'policy-edit' ||
        state.detailsTab.type === 'unapproved-system-edit' ||
        state.detailsTab.type === 'system-edit' ||
        state.detailsTab.type === 'dns-record-edit' ||
        state.detailsTab.type === 'dns-record-create' ||
        state.detailsTab.type === 'trust-requirement-edit' ||
        state.detailsTab.type === 'trust-requirement-create' ||
        state.detailsTab.type === 'tag-create' ||
        state.detailsTab.type === 'tag-edit'
      ) {
        const validationState = state.detailsTab.validationState;

        payload.forEach(id => {
          if (validationState[id]) {
            validationState[id].active = false;
          }
        });
      }
    })
    .addCase(activateValidationState, (state, { payload }) => {
      if (
        state.detailsTab.type === 'enrolment-key-create' ||
        state.detailsTab.type === 'enrolment-key-edit' ||
        state.detailsTab.type === 'policy-create' ||
        state.detailsTab.type === 'policy-edit' ||
        state.detailsTab.type === 'unapproved-system-edit' ||
        state.detailsTab.type === 'system-edit' ||
        state.detailsTab.type === 'dns-record-edit' ||
        state.detailsTab.type === 'dns-record-create' ||
        state.detailsTab.type === 'trust-requirement-edit' ||
        state.detailsTab.type === 'trust-requirement-create' ||
        state.detailsTab.type === 'tag-create' ||
        state.detailsTab.type === 'tag-edit'
      ) {
        const validationState = state.detailsTab.validationState;

        payload.forEach(id => {
          if (validationState[id]) {
            validationState[id].active = true;
          }
        });
        state.detailsTab.validationState = validationState;
      }
    });
};
