import styled, { css, useTheme } from 'styled-components';
import { CustomText } from 'typography/Text';
import { ISimpleEnrolmentKey } from 'types';
import { useTranslations } from 'hooks/useTranslations';
import { CustomBadge, ControllableIcon } from 'components';
import { IconEnrolmentKey } from 'assets/svg';
import { useMemo } from 'react';
import { DISCONNECTED_RETENTION_MINUTES_CONFIG, UNLIMITED_USES_AMOUNT } from 'appConstants';

interface ISearchSelectOptionElement {
  record: ISimpleEnrolmentKey;
  isSelected: boolean;
}

const SearchSelectOptionElementContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
`;
export const SearchSelectOptionElement = ({ record, isSelected }: ISearchSelectOptionElement) => {
  const {
    palette: { fonts },
  } = useTheme();
  const { translate } = useTranslations('enrol-new-system');
  const { translate: keysTranslate } = useTranslations('enrolment-keys');

  const retentionMinutes = useMemo(() => {
    if (record.type === 'GeneralPurpose') return null;

    const defaultRetention = DISCONNECTED_RETENTION_MINUTES_CONFIG.find(
      element => element.disconnectedRetentionMinutes === record.disconnectedRetentionMinutes
    );

    if (defaultRetention) return `${defaultRetention.text} ${keysTranslate('retention')}`;

    return `${record.disconnectedRetentionMinutes} ${keysTranslate('details.mins')} ${keysTranslate(
      'retention'
    )}`;
  }, [record, keysTranslate]);

  return (
    <SearchSelectOptionElementContainer>
      <ControllableIcon
        icon={<IconEnrolmentKey />}
        ephemeral={record.type === 'Ephemeral'}
        size="medium"
        state={isSelected ? 'active' : 'default'}
        css={css`
          margin-right: 0.75rem;
        `}
      />
      <CustomText
        ellipsis={true}
        title={record.description}
        type="body-2"
        color={fonts.bodyDark}
        className="select-shown"
      >
        {record.description}
      </CustomText>
      {record.type === 'GeneralPurpose' && (
        <>
          <CustomText
            type="hint"
            color={fonts.disabled}
            css={css`
              margin-left: 0.625rem;
            `}
          >
            {record.usesRemaining === UNLIMITED_USES_AMOUNT
              ? translate('unlimited')
              : `${record.usesRemaining} ${translate('uses')}`}
          </CustomText>
          <CustomBadge
            color="gray"
            badgeCss={css`
              margin-left: 0.625rem;
            `}
          />
          <CustomText
            type="hint"
            color={fonts.disabled}
            css={css`
              margin-left: 0.625rem;
            `}
          >
            {record.approvalMode === 'Automatic'
              ? translate('auto_approval')
              : translate('manual_approval')}
          </CustomText>
        </>
      )}
      {record.type === 'Ephemeral' && (
        <CustomText
          type="hint"
          color={fonts.disabled}
          css={css`
            margin-left: 0.625rem;
          `}
        >
          {retentionMinutes}
        </CustomText>
      )}
    </SearchSelectOptionElementContainer>
  );
};
