import { memo } from 'react';
import styled from 'styled-components';
import { CustomTag } from 'components';
import Skeleton from 'react-loading-skeleton';
import { ITag } from 'types';

interface IDescription {
  tag: ITag;
  isLoading: boolean;
}

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

export const Description = memo(({ tag, isLoading }: IDescription) => {
  return (
    <>
      {isLoading ? (
        <Skeleton />
      ) : (
        <DescriptionContainer data-test="tags-table-description-column">
          <CustomTag tag={tag} selectable={false} />
        </DescriptionContainer>
      )}
    </>
  );
});
