// components
import { Description } from './Description';
import { UsesRemaining } from './UsesRemaining';
import { ApprovalMode } from './ApprovalMode';
import { Status } from './Status';
import { EnrolledCount } from './EnrolledCount';
import { LastUsed } from './LastUsed';
import { EnrolmentKeyActionDropdown } from './ActionDropdown';
import { ColumnTitle } from 'components';

// types
import { TFunction } from 'react-i18next/.';
import { translations, IEnrolmentKeyStatus, ISimpleEnrolmentKey, TagReference } from 'types';
import { ColumnType } from 'antd/lib/table';
import { Tags } from './Tags';

interface IEnrolledKeysCols {
  isLoading: boolean;
  translate: TFunction<translations>;
  bulkSelectedKeys: ISimpleEnrolmentKey[];
  setBulkSelectedKeys: (enrolmentKeys: ISimpleEnrolmentKey[]) => void;
  selectedKeyId?: ISimpleEnrolmentKey['id'];
}

export const EnrolledKeysCols = ({
  isLoading,
  translate,
  bulkSelectedKeys,
  setBulkSelectedKeys,
  selectedKeyId,
}: IEnrolledKeysCols): ColumnType<ISimpleEnrolmentKey>[] => [
  {
    title: <ColumnTitle>{translate('name')}</ColumnTitle>,
    dataIndex: 'description',
    className: 'name-column',

    render: (description: string, { isEnabled, id, type }: ISimpleEnrolmentKey) => {
      const isSelected = !!bulkSelectedKeys.find(key => key.id === id) || selectedKeyId === id;
      return (
        <Description
          description={description}
          isEnabled={isEnabled}
          isLoading={isLoading}
          ephemeral={type === 'Ephemeral'}
          state={isSelected ? 'active' : 'default'}
        />
      );
    },
  },
  {
    title: <ColumnTitle>{translate('auto_tags')}</ColumnTitle>,
    dataIndex: 'tags',
    render: (tags: TagReference[]) => <Tags isLoading={isLoading} tags={tags} />,
  },
  {
    title: <ColumnTitle>{translate('uses_remaining')}</ColumnTitle>,
    dataIndex: 'usesRemaining',
    width: '5rem',

    render: (usesRemaining: number, record: ISimpleEnrolmentKey) => (
      <UsesRemaining
        usesRemaining={usesRemaining}
        isEnabled={record.isEnabled}
        isLoading={isLoading}
        ephemeral={record.type === 'Ephemeral'}
      />
    ),
  },
  {
    title: <ColumnTitle>{translate('approval_mode')}</ColumnTitle>,
    dataIndex: 'approvalMode',
    width: '5rem',
    render: (approvalMode: ISimpleEnrolmentKey['approvalMode'], record: ISimpleEnrolmentKey) => (
      <ApprovalMode
        approvalMode={approvalMode}
        isEnabled={record.isEnabled}
        isLoading={isLoading}
        ephemeral={record.type === 'Ephemeral'}
      />
    ),
  },
  {
    title: <ColumnTitle>{translate('state')}</ColumnTitle>,
    dataIndex: 'status',
    width: '5rem',
    render: (status: IEnrolmentKeyStatus, record: ISimpleEnrolmentKey) => (
      <Status status={status} isEnabled={record.isEnabled} isLoading={isLoading} />
    ),
  },
  {
    title: <ColumnTitle>{translate('systems_enrolled')}</ColumnTitle>,
    dataIndex: 'enrolledCount',
    width: '4rem',
    render: (enrolledCount: number, record: ISimpleEnrolmentKey) => (
      <EnrolledCount
        enrolledCount={enrolledCount}
        isEnabled={record.isEnabled}
        isLoading={isLoading}
      />
    ),
  },
  {
    title: <ColumnTitle>{translate('last_used')}</ColumnTitle>,
    dataIndex: 'lastUsed',
    width: '5rem',
    render: (lastUsed: string, record: ISimpleEnrolmentKey) => (
      <LastUsed lastUsed={lastUsed} isEnabled={record.isEnabled} isLoading={isLoading} />
    ),
  },
  {
    title: '',
    dataIndex: '',
    key: 'x',
    className: 'action-column',
    render: (record: ISimpleEnrolmentKey) => (
      <EnrolmentKeyActionDropdown
        record={record}
        setBulkSelectedKeys={setBulkSelectedKeys}
        isLoading={isLoading}
      />
    ),
  },
];
