import React from 'react';
import styled from 'styled-components';

interface IOrderedList {
  children: React.ReactNode;
}

const List = styled.ol`
  padding: 0;
  counter-reset: list-counter;
`;

const ListContainer = styled.div`
  padding-left: 0.75rem;
  overflow: visible;
  position: relative;
`;

export const OrderedList = ({ children, ...rest }: IOrderedList) => {
  return (
    <ListContainer>
      <List {...rest}>{children}</List>
    </ListContainer>
  );
};
