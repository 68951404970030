import styled, { useTheme, CSSProp } from 'styled-components';

import { CustomText } from '../Text';
import { useCopy } from 'hooks/useCopy';
import { CopyTool } from 'components';

const Wrapper = styled.div`
  display: flex;
  width: fit-content;
  height: 2rem;
  align-items: center;
  padding: 0 0.5rem;
  background: ${props => props.theme.palette.background.iconBackground};
  border-radius: 0.25rem;

  .console-text {
    font-weight: bold;
    font-family: Nunito;
  }
`;

const TextContainer = styled.div`
  flex-grow: 1;
  white-space: nowrap;
  width: fit-content;
  overflow: auto;
  display: flex;
`;

const IconContainer = styled.div`
  width: 2.75rem;
  display: flex;
  justify-content: flex-end;
`;

interface ICodeTextProps {
  removeBotPadding?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  IconContainerCss?: CSSProp;
  onCopyCode?: (key: string) => void;
  code: string;
}

export const CodeText: React.FC<ICodeTextProps> = ({
  disabled,
  IconContainerCss,
  onCopyCode,
  code,
  ...rest
}) => {
  const { onCopyClick, copied } = useCopy({
    copyingValue: code,
    onCopyCode,
  });
  const theme = useTheme();

  const textColor = disabled ? theme.palette.fonts.disabled : theme.palette.fonts.bodyDark;

  return (
    <Wrapper {...rest}>
      <TextContainer>
        <CustomText type="system" color={textColor}>
          {code}
        </CustomText>
      </TextContainer>
      <IconContainer css={IconContainerCss}>
        <CopyTool
          disabled={disabled}
          copied={copied}
          onCopyClick={onCopyClick}
          iconSize="1.25rem"
        />
      </IconContainer>
    </Wrapper>
  );
};
