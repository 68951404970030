// libs
import { put } from 'typed-redux-saga';

// types
import { IActionPayload, IGetPoliciesParams } from 'types';

// actions
import { setTrustRequirements, setIsLoading } from 'redux/actions/trust-requirements';

// Utils
import { SyncQueryParams } from '../../utils';
import { ApiTypeCaller } from '../../api/utils';

export function* getTrustRequirementsSaga({ payload }: IActionPayload<IGetPoliciesParams>) {
  yield put(setIsLoading(true));

  payload = yield* SyncQueryParams(payload);

  const { result, success } = yield* ApiTypeCaller('getTrustRequirements', payload);

  if (success && result) yield put(setTrustRequirements(result));
}
