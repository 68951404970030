import styled, { css } from 'styled-components';
import { IconPoliciesInfoCircle } from 'assets/svg';
import { CustomText } from 'typography/Text';

export const borderRadiusCss = css`
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-left-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2.625rem;
  margin-left: 2rem;
`;

export const TagsContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Traffic = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  min-width: 0;
  min-height: 4rem;
`;

export const TagListWrapper = styled.div`
  flex: 0 1 0;
  border: 0.075rem solid ${({ theme }) => theme.palette.background.iconBackground};
  box-shadow: ${({ theme }) => theme.palette.elevationShadows[100]};
  ${borderRadiusCss}
`;

export const TagTitleCustomText = styled(CustomText)`
  background-color: ${({ theme }) => theme.palette.background.sections};

  border: 0.0625rem solid ${({ theme }) => theme.palette.background.iconBackground};
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;

  display: block;
  padding-left: 0.625rem;
`;

export const IconContainer = styled.div`
  display: flex;
  width: 3.5rem;
  flex-direction: row;
  justify-content: center;
`;

export const NotificationWrapper = styled.div`
  display: flex;
  padding: 0.5625rem 1rem;
  margin-top: 1rem;
  background-color: ${({ theme }) => theme.palette.background.hover};
`;

export const NotificationIcon = styled(IconPoliciesInfoCircle)`
  min-width: 1rem;
  margin-top: 0.1875rem;
  margin-right: 0.5rem;
`;
