// libs
import { memo, SyntheticEvent } from 'react';

// Components
import Skeleton from 'react-loading-skeleton';
import { ActionDropdown } from 'components';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { useDispatch } from 'react-redux';

// acitons
import { createDNSRecordSideMenu, redirectControl, setModalContent } from 'redux/actions/app';

// types
import { IRecord } from 'types';

interface IActionDropdown {
  id: IRecord['id'];
  name: IRecord['name'];
  zoneId: IRecord['zoneId'];
  autoGenerated: boolean;
  isLoading: boolean;
}

export const RecordActionDropdown = memo(
  ({ id, name, autoGenerated, zoneId, isLoading }: IActionDropdown) => {
    const dispatch = useDispatch();
    const { translate } = useTranslations('dns');

    const onRemoveClick = () => {
      dispatch(
        setModalContent({
          type: 'remove-dns-records',
          data: {
            records: [{ id: id, name: name }],
            isBulk: false,
          },
        })
      );
    };

    const onEditClick = () => {
      dispatch(redirectControl({ route: `/dns/zones/${zoneId}/record/${id}` }));
    };

    const onDuplicateClick = () => {
      dispatch(createDNSRecordSideMenu({ zoneId: zoneId, zoneName: name, id }));
    };

    return (
      <>
        {isLoading ? (
          <Skeleton />
        ) : (
          <ActionDropdown
            data-test="dns-records-table-action-dropdown-column"
            onContainerClick={(event: SyntheticEvent) => event.stopPropagation()}
            data={
              autoGenerated
                ? [
                    {
                      text: translate('records.table_actions.override'),
                      onClick: onDuplicateClick,
                    },
                  ]
                : [
                    {
                      text: translate('records.table_actions.edit'),
                      onClick: onEditClick,
                    },
                    {
                      text: translate('records.table_actions.duplicate'),
                      onClick: onDuplicateClick,
                    },
                    {
                      text: translate('records.table_actions.remove'),
                      onClick: onRemoveClick,
                    },
                  ]
            }
          />
        )}
      </>
    );
  }
);
