import { css } from 'styled-components';

export const wrapperCss = css`
  display: flex;
`;

export const inputContainerCss = css`
  width: 6rem;
  margin-right: 1rem;
  height: 3.25rem;
  padding: 0;
`;

export const inputCss = css`
  height: 2rem;
  max-height: 2rem;
  width: 6rem;
`;

export const buttonCss = css`
  padding: 0px;
  width: 2rem;
  height: 2rem;
`;
