// components
import { CustomText } from 'typography/Text';
import { CustomButton } from 'components';
import { InvitationDescription, InvitationRecord, ActionsWrapper } from './styled';

// hooks
import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { useTranslations } from 'hooks/useTranslations';

// actions
import { changeCurrentOrganisation } from 'redux/actions/account';

// types
import { IAccountInvite } from 'types';

type Props = {
  invite: IAccountInvite;
};

export const InvitationAccepted = ({ invite }: Props) => {
  const dispatch = useDispatch();

  const { translate } = useTranslations('account');

  const handleVisitOrganisation = useCallback(() => {
    dispatch(changeCurrentOrganisation(invite.orgId));
  }, [dispatch, invite.orgId]);

  return (
    <InvitationRecord>
      <InvitationDescription>
        <CustomText type="body-2">
          {translate('accepted_invitation', { orgName: invite.orgName })}
        </CustomText>
      </InvitationDescription>
      <ActionsWrapper>
        <CustomButton kind="link" onClick={handleVisitOrganisation}>
          {translate('visit_organisation')}
        </CustomButton>
      </ActionsWrapper>
    </InvitationRecord>
  );
};
