import { css, useTheme } from 'styled-components';

// components
import { IconButton, Popover, TagsWithPopupList, InternalLink, Copy } from 'components';
import { IconSystemsDnsFromDirectAssignment, IconTagsPopupList } from 'assets/svg';
import { CustomText } from 'typography/Text';
import { ListElementContainer, StateDescription } from './styled';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { useSelector } from 'react-redux';
import { useCallback, useState } from 'react';

// selectors
import { selectCurrentOrganisationId } from 'redux/selectors';

// constants
import { ROUTE_PATHS } from 'appConstants';

// types
import { ISystemDnsEntry } from 'types';

interface IListElement {
  dnsEntry: ISystemDnsEntry;
}

export const ListElement = ({ dnsEntry }: IListElement) => {
  const {
    palette: { fonts },
  } = useTheme();

  const orgId = useSelector(selectCurrentOrganisationId);

  const [visible, setVisible] = useState(false);

  const onMouseEnter = useCallback(() => {
    setVisible(true);
  }, []);

  const onMouseLeave = useCallback(() => {
    setVisible(false);
  }, []);

  const { translate } = useTranslations('systems');

  return (
    <ListElementContainer key={dnsEntry.fqdn}>
      <Copy
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        visible={visible}
        height="3.875rem"
        css={css`
          min-width: 0;
          margin-right: 2rem;
          flex-grow: 1;
        `}
        copyingValue={dnsEntry.fqdn}
      >
        {dnsEntry.fromZoneId !== null && dnsEntry.fromRecordId !== null ? (
          <InternalLink
            ellipsis={true}
            css={css`
              max-width: 20rem;
            `}
            to={`/org/${orgId}${ROUTE_PATHS.dns}/zones/${dnsEntry.fromZoneId}/record/${dnsEntry.fromRecordId}`}
            title={dnsEntry.fqdn}
          >
            {dnsEntry.fqdn}
          </InternalLink>
        ) : (
          <CustomText
            color={fonts.title}
            type="body-2"
            ellipsis={true}
            css={css`
              display: block;
            `}
            title={dnsEntry.fqdn}
          >
            {dnsEntry.fqdn}
          </CustomText>
        )}
      </Copy>
      <StateDescription>
        {dnsEntry.fromZoneId === null && (
          <CustomText
            type="body-2"
            color={fonts.bodyLight}
            css={css`
              margin-right: 0.25rem;
            `}
          >
            {translate('details.dns.default')}
          </CustomText>
        )}
        {dnsEntry.fromDirectAssignment && (
          <Popover
            trigger={['hover', 'focus', 'click']}
            arrowPointAtCenter={true}
            placement="bottomLeft"
            popoverCss={css`
              .ant-popover-content {
                width: 17.5rem;

                .ant-popover-inner {
                  padding: 0.5rem 1rem;
                }
              }
            `}
            content={
              <CustomText type="body-2" color={fonts.bodyLight}>
                {translate('details.dns.dns_direcrly_assigned')}
              </CustomText>
            }
          >
            <IconButton
              width="1.5rem"
              height="1.5rem"
              icon={<IconSystemsDnsFromDirectAssignment width="1rem" height="1rem" />}
            />
          </Popover>
        )}

        {dnsEntry.tags.length > 0 && (
          <TagsWithPopupList
            tags={dnsEntry.tags}
            placement="bottomLeft"
            content={
              <IconButton
                aria-label={translate('details.dns.aria_labels.show_tags')}
                width="1.5rem"
                height="1.5rem"
                icon={<IconTagsPopupList width="1rem" height="1rem" />}
              />
            }
          />
        )}
      </StateDescription>
    </ListElementContainer>
  );
};
