// Libs
import { css } from 'styled-components';

// Components
import { Details } from 'components';
import { OverviewSection } from './styled';
import { IconEnrolmentKeysDetailsOverviewSection } from 'assets/svg';
import { NotesBlock, PreviewBlock, ColourBlock } from '../../Blocks';

// hooks
import { useTranslations } from 'hooks/useTranslations';

export const Overview = () => {
  const { translate } = useTranslations('tags');

  return (
    <OverviewSection>
      <Details.SectionHeader
        Icon={IconEnrolmentKeysDetailsOverviewSection}
        title={translate('details.overview')}
        css={css`
          margin-bottom: 1.8125rem;
        `}
      />
      <ColourBlock />
      <PreviewBlock />
      <NotesBlock />
    </OverviewSection>
  );
};
