// Lib
import { createReducer } from '@reduxjs/toolkit';
import { IUserStore } from 'types';
import { storeUser, clearUser } from '../actions/user';
import { equals } from 'ramda';

const initialState: IUserStore = {
  isLoggedIn: false,
  profile: null,
};

export const userReducer = createReducer(initialState, builder => {
  builder
    .addCase(storeUser, (state, { payload }) => {
      if (!equals(state.profile, payload)) {
        state.profile = payload;
        state.isLoggedIn = Boolean(payload);
      }
    })
    .addCase(clearUser, state => {
      state.isLoggedIn = false;
      state.profile = null;
    });
});
