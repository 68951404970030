// Types
import { call, put } from 'typed-redux-saga';
import { IActionPayload, ICancelUserInviteParams, INotificationType } from 'types';
import { pushNotification } from 'redux/actions/app';

// Utils
import { ApiTypeCaller } from '../../api/utils';

import { getOrganisationInvitesSaga } from './getOrganisationInvitesSaga';
import { translator } from 'utils';

export function* cancelUserInviteSaga({ payload }: IActionPayload<ICancelUserInviteParams>) {
  const { result, success } = yield* ApiTypeCaller('cancelUserInvite', payload);
  const translate = translator('notifications');
  if (success && result) {
    yield put(
      pushNotification({
        showType: INotificationType.Info,
        description: translate('invite_cancelled'),
      })
    );
    yield call(getOrganisationInvitesSaga);
  }
}
