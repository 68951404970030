// components
import { List } from './List';
import { ListWrapper } from './styled';

// hooks
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useCallback, useEffect, useRef } from 'react';
import { useGetZone, useGetZones } from './hooks';

// selectors
import {
  selectDnsZones,
  selectDnsZonesIsLoading,
  selectDnsZonesHasNextPage,
  selectDnsZonesTotal,
} from 'redux/selectors';

// types
import { IDnsParams } from 'types';

// utils
import { equals } from 'ramda';

export const ZonesList = () => {
  const zones = useSelector(selectDnsZones, equals);
  const total = useSelector(selectDnsZonesTotal);
  const isLoading = useSelector(selectDnsZonesIsLoading);
  const hasNextPage = useSelector(selectDnsZonesHasNextPage);

  const resolveRef = useRef<(value: boolean | PromiseLike<boolean>) => void>();

  const { zoneId } = useParams<keyof IDnsParams>();
  const selectedZoneId: number | undefined = zoneId === undefined ? zones?.[0]?.id : Number(zoneId);

  const {} = useGetZone();
  const { setPage } = useGetZones();

  // Resolve promise when it's loaded
  // for loadMoreItems function
  useEffect(() => {
    if (!isLoading) {
      resolveRef.current && resolveRef.current(true);
      resolveRef.current = () => {};
    }
  }, [isLoading]);

  const goToNextPage = useCallback(() => {
    hasNextPage && setPage(prevPage => prevPage + 1);
  }, [hasNextPage, setPage]);

  // if we can't find zone in list but we know it exists(because selectedZone exists)
  // then we fetch pages untill we find the one containing our zone

  const loadMoreItems = useCallback(
    async (startIndex: number) => {
      if (zones.length && startIndex > zones.length - 1 && startIndex <= total) {
        goToNextPage();

        await new Promise(resolve => {
          resolveRef.current = resolve;
        });

        return;
      }
    },
    [goToNextPage, total, zones.length]
  );

  return (
    <ListWrapper>
      {typeof selectedZoneId === 'number' && (
        <List
          selectedZoneId={selectedZoneId}
          hasNextPage={hasNextPage}
          list={zones}
          total={total}
          loadMoreItems={loadMoreItems}
        />
      )}
    </ListWrapper>
  );
};
