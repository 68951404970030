export const MODAL_MAX_HEIGHT = 'calc(100vh - 8rem)';
export const MODAL_MIN_HEIGHT = '20.5625rem';

export const MODAL_HEADER_HEIGHT = '6.5rem';

export const MODAL_FOOTER_HEIGHT = '6rem';

export const MODAL_SCROLLABLE_PART_MAX_HEIGHT = `calc(${MODAL_MAX_HEIGHT} - ${MODAL_HEADER_HEIGHT} - ${MODAL_FOOTER_HEIGHT})`;
export const MODAL_SCROLLABLE_PART_MIN_HEIGHT = `calc(${MODAL_MIN_HEIGHT} - ${MODAL_HEADER_HEIGHT} - ${MODAL_FOOTER_HEIGHT})`;

export const REMOVE_MODAL_CONFIRMATION_TEXT = 'REMOVE';
