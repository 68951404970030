import React, { FC } from 'react';

import { EmptyContainer } from './EmptyContainer';
import { getNoDataIcon } from 'utils';
import { IconNoFoundSearchData } from 'assets/svg';

export type TextData = {
  title: string;
  description: string;
};

type NoDataFoundProps = {
  searchValue: string;
  noDataFoundText: TextData;
};

type NoDataProps = {
  additionalText?: React.ReactElement;
  noDataText: TextData;
  module: string;
};

export const NoData: FC<NoDataProps> = ({ noDataText, additionalText, module, ...rest }) => {
  return (
    <EmptyContainer
      Icon={getNoDataIcon(module)}
      primaryText={noDataText.title}
      secondaryText={noDataText.description}
      additionalText={additionalText}
      {...rest}
    />
  );
};

export const NoDataFound: FC<NoDataFoundProps> = ({ noDataFoundText, ...rest }) => {
  return (
    <EmptyContainer
      Icon={<IconNoFoundSearchData />}
      primaryText={`${noDataFoundText.title}`}
      secondaryText={noDataFoundText.description}
      secondaryLight={true}
      {...rest}
    />
  );
};
