// components
import { selectAppDetailsTrustRequirementCurrentAuthority } from 'redux/selectors';
import { UserAuthenticationBlocks } from '../../Blocks';
import { Wrapper } from './Wrapper';
import { ConditionsDescriptionBlock } from '../../Blocks';

// hooks
import { useSelector } from 'react-redux';

export const UserAuthentication = () => {
  const authority = useSelector(selectAppDetailsTrustRequirementCurrentAuthority);

  if (authority !== 'azure') {
    return null;
  }

  return (
    <Wrapper>
      <ConditionsDescriptionBlock />
      {authority === 'azure' && <UserAuthenticationBlocks.Authorities.Microsoft.GroupIdBlock />}
    </Wrapper>
  );
};
