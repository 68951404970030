// libs
import styled, { useTheme } from 'styled-components';

// components
import { CustomText } from 'typography/Text';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${({ theme }) => theme.palette.background.base};

  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
`;

interface IEmpty {
  text: string;
}
export const Empty = ({ text, ...rest }: IEmpty) => {
  const {
    palette: { fonts },
  } = useTheme();
  return (
    <Container {...rest}>
      <CustomText type="body-2" color={fonts.disabled}>
        {text}
      </CustomText>
    </Container>
  );
};
