// Lib
import { useEffect, useState, useMemo, useCallback, useRef } from 'react';

// Types
import { RawValueType, LabelInValueType } from 'rc-select/lib/Select';
import { ITag, TagReference } from 'types';
import { ITagsProps } from './index';

// Utils
import { isCharValid, isTagValid, initTag } from './utils';
import { debounce } from 'utils/debouce';

// Hooks
import { useDispatch, useSelector } from 'react-redux';

// Selectors
import { selectTagsResponse, selectTagsUpdateRef } from 'redux/selectors';

// Actions
import { getTags, updateTagsId } from 'redux/actions/tags';

type ISearchState = {
  value: string;
  wrongChar?: boolean;
  wrongTag?: boolean;
  tooLongTagName?: boolean;
};

type ITagsState = {
  items: ITag[];
  nextPage: number | null | undefined;
  query: string;
};

type SelectedTags = TagReference[];

type SelectedTag = RawValueType | LabelInValueType;

type TagMemoStore = {
  items: ITagsState['items'];
  nextPage: number | null | undefined;
};

let neverSearchAgain = false;
let lastUpdateRef = '';

const memoStore: Map<ISearchState['value'], TagMemoStore> = new Map();

const saveResponse = (response: ITagsState) => {
  if (response.items.length === 0 && response.nextPage === null && response.query === '') return;
  memoStore.set(response.query, {
    items: response.items,
    nextPage: response.nextPage,
  });

  if (response.query === '' && response.nextPage === null) neverSearchAgain = true;
};

export const useTagSelect = <VT extends unknown>({
  preSelected,
  changeCallback = () => null,
}: ITagsProps<VT>) => {
  const dispatch = useDispatch();
  // Api response
  const tagsResponse = useSelector(selectTagsResponse);
  const tagsUpdateRef = useSelector(selectTagsUpdateRef);

  const [tags, setTags] = useState<ITagsState>(tagsResponse);
  // Manually created tags
  const [createdTags, setCreatedTags] = useState<ITag[]>([]);

  // Selected tags
  const [selected, setSelected] = useState<SelectedTags>(preSelected);

  useEffect(() => {
    setSelected(preSelected);
  }, [preSelected]);

  // Calculated tags to render
  const [tagsToRender, setTagsToRender] = useState<ITag[]>([]);

  const [isOpen, setIsOpen] = useState(false);

  const [search, setSearch] = useState<ISearchState>({
    value: '',
    wrongChar: false,
    wrongTag: false,
    tooLongTagName: false,
  });

  const isValid = !search.wrongChar && !search.wrongTag && !search.tooLongTagName;

  const handleSelect = (value: SelectedTag) => {
    const newTag = String(value);
    if (newTag.length === 0) return;
    if (!isTagValid(newTag)) {
      setSearch({
        value: search.value,
        wrongTag: true,
        wrongChar: false,
      });
      return;
    }
    const notYetSelected = !selected.some(({ tag }) => tag === newTag);

    if (notYetSelected) {
      const addedTag = tagsToRender.find(({ tag }) => tag === newTag);
      let selectedTags = addedTag ? [...selected, addedTag] : [...selected];

      if (memoStore.has('')) {
        const memoizedRecord = memoStore.get('');
        const record = memoizedRecord as TagMemoStore;
        const tagExists = record.items.some(({ tag }) => tag === newTag);
        if (!tagExists) {
          const createdTag = initTag(newTag);
          setCreatedTags(prev => prev.concat(createdTag));
          selectedTags = [...selectedTags, createdTag];
        }
      } else {
        const createdTag = initTag(newTag);
        setCreatedTags(prev => prev.concat(createdTag));
        selectedTags = [...selectedTags, createdTag];
      }
      setSelected([...selectedTags]);
      setSearch({
        value: '',
        wrongTag: false,
        wrongChar: false,
      });
      changeCallback([...selectedTags]);

      if (tags.items.every(({ tag }) => selectedTags.find(item => item.tag === tag)) && isOpen)
        setIsOpen(false);
    }
  };

  const handleDeselect = (value: SelectedTag) => {
    const removedTag = String(value);
    const newSelected = selected.filter(tag => tag.tag !== removedTag);
    setSelected(newSelected);

    if (createdTags.find(({ tag }) => tag === removedTag)) {
      setCreatedTags(createdTags.filter(({ tag }) => tag !== removedTag));
    } else {
      if (!isOpen) setIsOpen(true);
    }

    changeCallback(newSelected);
  };

  const handleSearch = (value: string) => {
    const lowerCase = value.toLowerCase().trim();

    if (lowerCase.length > search.value.length) {
      // New string is bigger, validate new character

      const newChar = lowerCase.replace(search.value, '');

      if (!isCharValid(newChar)) {
        setSearch({
          value: search.value,
          wrongChar: true,
        });
        return;
      }
      if (lowerCase.length > 64) {
        setSearch({
          value: search.value,
          tooLongTagName: true,
        });
        return;
      }
    }

    setSearch({
      value: lowerCase,
    });

    if (!isOpen) {
      setIsOpen(true);
    } else {
      if (lowerCase === '' && tagsToRender.length === 0) {
        setIsOpen(false);
      }
    }
  };

  const handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      if (search.value === '') return;
      if (isOpen) {
        handleSelect(search.value);
      }
    }
  };

  const handeSearchUpdate = debounce(query => {
    if (memoStore.size > 0) {
      if (memoStore.has(query)) {
        // Memo has exact search value match
        setTags(tags => ({ ...tags, ...memoStore.get(query) }));
        return;
      }

      for (const [storeKey, memoizedRecord] of memoStore) {
        if (storeKey && query.includes(storeKey)) {
          // Memo has sub-string match
          const { items, nextPage } = memoizedRecord as TagMemoStore;
          if (nextPage === null) {
            // Response was complete -> set to store
            setTags(tags => ({ ...tags, items, nextPage }));
            return;
          }
        }
      }
    }

    if (neverSearchAgain) return;

    dispatch(getTags({ search: query }));
  }, 100);

  useEffect(() => {
    if (lastUpdateRef !== tagsUpdateRef) {
      dispatch(getTags({}));
      lastUpdateRef = tagsUpdateRef;
    }
  }, [dispatch, tagsUpdateRef]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const deboucedSearchUpdate = useCallback((query: string) => handeSearchUpdate(query), []);

  useEffect(() => {
    setTagsToRender(
      tags.items
        .concat(createdTags)
        .filter(({ tag }) => !selected.find(item => item.tag === tag) && tag.includes(search.value))
    );
  }, [tags.items, createdTags, selected, search.value]);

  useEffect(() => {
    deboucedSearchUpdate(search.value);
  }, [dispatch, deboucedSearchUpdate, search.value]);

  useEffect(() => {
    saveResponse(tagsResponse);
    setTags(tagsResponse);
    return () => {
      memoStore.clear();
      neverSearchAgain = false;
    };
  }, [tagsResponse]);

  // this is to show alternative dropdown that shows that we don't have existing tags with such names
  const shouldShowDropDown = useMemo(() => {
    if (
      tags.items.filter(({ tag }) => selected.filter(item => item.tag === tag)).length +
        createdTags.length ===
      0
    ) {
      return false;
    }
    if (tagsToRender.length === 0) {
      return false;
    }
    return true;
  }, [selected, tags, createdTags, tagsToRender]);

  // this is timeout to delay display of dropdown so it would happen after search height
  // animation via transform, would show snapping behaviour otherwise
  const timeout = useRef<ReturnType<typeof setTimeout> | undefined>();

  const visibilityChange = useCallback(
    (visibility: boolean) => {
      if (visibility === true && isOpen === false && isValid && tagsToRender.length) {
        timeout.current = setTimeout(() => {
          setIsOpen(true);
        }, 300);
      } else if (isOpen === true && search) {
        setIsOpen(false);
      }
    },
    [search, isOpen, tagsToRender, isValid]
  );

  useEffect(() => {
    return () => {
      timeout.current && clearTimeout(timeout.current);
      dispatch(updateTagsId());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!isValid && isOpen) {
      visibilityChange(false);
    } else if (isValid && !isOpen && search.value !== '') {
      visibilityChange(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid]);

  return {
    search,
    tagsToRender,
    tagsSelected: selected,
    shouldShowDropDown,
    handleSearch,
    handleInputKeyDown,
    handleSelect,
    handleDeselect,
    onDropdownVisibilityChange: visibilityChange,
    isOpen,
  };
};
