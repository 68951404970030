import styled from 'styled-components';

export const Wrapper = styled.div`
  grid-row: 2 / span 1;
  grid-column: 1 / span 1;

  padding: 2rem;
  background-color: ${({ theme }) => theme.palette.background.panel};
  box-shadow: ${({ theme }) => theme.palette.elevationShadows[200]};
  border-radius: 0.25rem;
`;

export const DescriptionRow = styled.div`
  display: flex;

  svg {
    vertical-align: text-top;
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  margin-left: 2.375rem;
`;

export const InfoRow = styled.div`
  display: flex;
  flex-direction: row;

  :not(:last-child) {
    margin-bottom: 1.5rem;
  }
`;

export const InfoRowName = styled.div`
  min-width: 9.375rem;
  margin-right: 1rem;
`;

export const InfoRowValue = styled.div``;
