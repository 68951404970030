import { createAction } from '@reduxjs/toolkit';

import {
  IEnableEnrolmentKeyProps,
  IDisableEnrolmentKeyProps,
  IEnableEnrolmentKeysBulkParams,
  IDisableEnrolmentKeysBulkParams,
  IUpdateEnrolmentKeysState,
  IGetEnrolmentKeysResponse,
  IEnrolmentKey,
  ISearchKey,
  IDeleteEnrolmentKeyProps,
  IDeleteEnrolmentKeysBulkParams,
  IEnableUntilEnrolmentKeyProps,
  IGetEnrolmentKeys,
  EnableUntilType,
} from 'types';

export const getEnrolmentKeys = createAction<IGetEnrolmentKeys>('enrolment_keys/GET_KEYS');
export const createEnrolmentKey = createAction('enrolment_keys/CREATE_KEY');
export const updateEnrolmentKeyState = createAction<IEnrolmentKey>(
  'enrolment_keys/UPDATE_KEY_STATE'
);
export const updateEnrolmentKeysState = createAction<IUpdateEnrolmentKeysState>(
  'enrolment_keys/UPDATE_KEYS_STATE'
);
export const enableEnrolmentKey = createAction<IEnableEnrolmentKeyProps>(
  'enrolment_keys/ENABLE_KEY'
);
export const enableUntilEnrolmentKey = createAction<
  IEnableUntilEnrolmentKeyProps & EnableUntilType
>('enrolment_keys/ENABLE_UNTIL_KEY');

export const enableEnrolmentKeysBulk = createAction<IEnableEnrolmentKeysBulkParams>(
  'enrolment_keys/ENABLE_KEYS_BULK'
);
export const disableEnrolmentKeysBulk = createAction<IDisableEnrolmentKeysBulkParams>(
  'enrolment_keys/DISABLE_KEYS_BULK'
);
export const disableEnrolmentKey = createAction<IDisableEnrolmentKeyProps>(
  'enrolment_keys/DISABLE_KEY'
);

export const deleteEnrolmentKey = createAction<IDeleteEnrolmentKeyProps>(
  'enrolment_keys/DELETE_KEY'
);
export const deleteEnrolmentKeys = createAction<IDeleteEnrolmentKeysBulkParams>(
  'enrolment_keys/DELETE_KEYS'
);

export const setEnrolmentKeys = createAction<IGetEnrolmentKeysResponse>('enrolment_keys/SET_KEYS');
export const setIsLoading = createAction<boolean>('enrolment_keys/IS_LOADING');
export const saveEnrolmentKeyDetails = createAction('enrolment_keys/SAVE_KEY_DETAILS');

export const setEnrolmentKeysShouldUpdate = createAction('enrolment_keys/SHOULD_UPDATE');

export const setSelectedEnrolmentKey = createAction<IEnrolmentKey | null>(
  'enrolment_keys/SET_SELECTED'
);

export const getEnrolmentKeySearchKeys = createAction('enrolment_keys/GET_SEARCH_KEYS');

export const setEnrolmentKeySearchKeys = createAction<ISearchKey[]>(
  'enrolment_keys/SET_SEARCH_KEYS'
);
