// libs
import { SyntheticEvent, useCallback, memo, useMemo, useEffect } from 'react';

// hooks
import { useDispatch, useSelector } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';

// components
import { CustomTable, TableSelectionContainer } from 'components';
import { tableCss } from './styled';
import { useTrustRequirementCols } from './Columns';

// utils
import { emptyTrustRequirements } from '.';
import { equals } from 'ramda';

// actions
import { redirectControl } from 'redux/actions/app';

// selectors
import { selectTrustRequirements } from 'redux/selectors';

// types
import { ITrustRequirementBasic } from 'types';
import { TableProps } from 'antd/lib/table';

// constants
import { ITEMS_PER_TABLE_PAGE } from 'appConstants/ui';

interface ITable {
  total: number;
  page: number;
  setPage: (page: number) => void;
  isLoading: boolean;
  bulkSelectedTrustRequirements: ITrustRequirementBasic[];
  setBulkSelectedTrustRequirements: (
    BulkSelectedTrustRequirements: ITrustRequirementBasic[]
  ) => void;
  selectedRequirementId: string | undefined;
  emptyText?: React.ReactNode;
}

export const Table = memo(
  ({
    page,
    setPage,
    total,
    isLoading,
    bulkSelectedTrustRequirements,
    setBulkSelectedTrustRequirements,
    selectedRequirementId,
    emptyText,
  }: ITable) => {
    const dispatch = useDispatch();

    const { translate: translateGlobal } = useTranslations('global');

    const trustRequirements = useSelector(selectTrustRequirements);

    const onRowClick = useCallback(
      (trustRequirement: ITrustRequirementBasic) => {
        if (!isLoading) {
          {
            dispatch(
              redirectControl({
                route: `/trust-requirements/${trustRequirement.id}`,
                routePush: true,
              })
            );
          }
        }
      },
      [dispatch, isLoading]
    );

    const columns = useTrustRequirementCols({
      isLoading,
      bulkSelectedTrustRequirements,
      selectedTrustRequirementId: selectedRequirementId ? Number(selectedRequirementId) : undefined,
    });

    const rowSelectionRender = useCallback(
      (
        _checked: boolean,
        _record: ITrustRequirementBasic,
        _index: number,
        originNode: React.ReactNode
      ) => {
        return (
          <TableSelectionContainer
            data-test="trust-requirements-table-selection"
            onClick={(event: SyntheticEvent) => event.stopPropagation()}
          >
            {originNode}
          </TableSelectionContainer>
        );
      },
      []
    );

    const rowSelection: TableProps<ITrustRequirementBasic>['rowSelection'] = useMemo(
      () => ({
        checkStrictly: true,
        onChange: (_, selectedTrustRequirements: ITrustRequirementBasic[]) => {
          if (!isLoading) {
            setBulkSelectedTrustRequirements(selectedTrustRequirements);
          }
        },
        selectedRowKeys: bulkSelectedTrustRequirements.map(e => e.id),
        renderCell: rowSelectionRender,
        //@ts-ignore
        getCheckboxProps: () => ({
          'aria-label': translateGlobal('aria_labels.select_row'),
        }),
      }),
      [
        bulkSelectedTrustRequirements,
        isLoading,
        rowSelectionRender,
        setBulkSelectedTrustRequirements,
        translateGlobal,
      ]
    );

    // when we bulk select element and then remove it through action dropdown
    // bulkSelected elements doesn't update without this useEffect
    useEffect(() => {
      const newBulkSelectedTrustRequirements = bulkSelectedTrustRequirements.filter(
        bulkSelectedTrustRequirement =>
          trustRequirements.some(
            trustRequirements => trustRequirements.id === bulkSelectedTrustRequirement.id
          )
      );
      if (!equals(newBulkSelectedTrustRequirements, bulkSelectedTrustRequirements)) {
        setBulkSelectedTrustRequirements(newBulkSelectedTrustRequirements);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trustRequirements]);

    return (
      <CustomTable
        data-test="trust-requirements-table"
        locale={{ emptyText }}
        headerHeight="2.5rem"
        rowHeight="3.375rem"
        rowKey="id"
        columns={columns}
        dataSource={
          isLoading && !trustRequirements.length ? emptyTrustRequirements : trustRequirements
        }
        onRowClick={onRowClick}
        elevated={true}
        selectedRowKey={selectedRequirementId}
        rowSelection={rowSelection}
        css={tableCss}
        pagination={{
          showSizeChanger: false,
          hideOnSinglePage: true,
          pageSize: ITEMS_PER_TABLE_PAGE,
          position: ['bottomCenter'],
          total: total,
          defaultCurrent: page + 1,
          onChange: page => {
            setPage(page - 1);
          },
        }}
      />
    );
  },
  equals
);
