// Lib
import { call, put } from 'typed-redux-saga';

// Actions
import { setDnsSelectedZone } from 'redux/actions/dns';

// Types
import { IActionPayload, IGetDnsZoneProps } from 'types';

// constants
import { ROUTE_PATHS } from 'appConstants';

// Utils
import { ApiTypeCaller } from '../../api/utils';
import { orgPush } from 'redux/sagas/utils';

export function* getZoneDetailsSaga({ payload }: IActionPayload<IGetDnsZoneProps>) {
  const { result, success } = yield* ApiTypeCaller('getDnsZoneDetails', payload);

  if (success && result) {
    yield put(setDnsSelectedZone(result));
  } else {
    yield call(orgPush, `${ROUTE_PATHS.dns}/zones`);
  }
}
