// components
import { CustomText } from 'typography/Text';

// hooks
import { css, useTheme } from 'styled-components';

interface IDescription {
  children: React.ReactNode;
}

export const Description = ({ children, ...rest }: IDescription) => {
  const {
    palette: { fonts },
  } = useTheme();

  return (
    <CustomText
      css={css`
        overflow-wrap: anywhere;
      `}
      type="hint"
      color={fonts.bodyLight}
      {...rest}
    >
      {children}
    </CustomText>
  );
};
