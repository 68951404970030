import { useTheme } from 'styled-components';
import { ColorTypes } from '.';
import { ThemeMap } from './styled';

export const useStylesMap = (kind: ColorTypes): ThemeMap => {
  const {
    palette: { fills },
  } = useTheme();

  const themeMap: Record<ColorTypes, ThemeMap> = {
    default: {
      color: fills.primary,
      colorHover: fills.primaryHover,
      colorFocus: fills.primaryHover,
      colorPressed: fills.primaryPressed,
    },
    light: {
      color: fills.stroke,
      colorHover: fills.stroke,
      colorFocus: fills.stroke,
      colorPressed: fills.stroke,
    },
  };
  return themeMap[kind];
};
