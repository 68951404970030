import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const List = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;

  margin-top: 1rem;
`;

export const ListItem = styled(NavLink)`
  cursor: pointer;

  display: flex;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;

  padding-left: 1.75rem;
  margin-left: -1.75rem;

  padding-right: 1.75rem;
  margin-right: -1.75rem;

  outline: none !important;

  border-top: 0.0625rem solid transparent;
  border-bottom: 0.0625rem solid transparent;

  :hover {
    background-color: ${({ theme }) => theme.palette.background.base};

    border-color: ${({ theme }) => theme.palette.background.iconBackground};
  }

  :focus {
    background-color: ${({ theme }) => theme.palette.background.focus};

    border-color: ${({ theme }) => theme.palette.background.iconBackground};
  }
`;

export const DashboardEmptySystemsContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const DashboardEmptySystemsContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 75%;
`;
