import { CustomText } from 'typography/Text';
import { useTheme } from 'styled-components';
interface IDescription {
  hostname: string;
  isEnabled?: boolean;
}

export const Hostname = ({ hostname, isEnabled = true, ...rest }: IDescription) => {
  const theme = useTheme();
  return (
    <CustomText
      type="body-2"
      color={isEnabled ? theme.palette.fonts.bodyDark : theme.palette.fonts.disabled}
      title={hostname}
      {...rest}
    >
      {hostname}
    </CustomText>
  );
};
