// libs
import { css } from 'styled-components';

// components
import { Details } from 'components';
import { NotesView } from './View';
import { NotesEdit } from './Edit';

// hooks
import { useSelector } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';

// selectors
import { selectAppDetailsTabType } from 'redux/selectors';

export const NotesBlock = () => {
  const { translate } = useTranslations('systems');

  const type = useSelector(selectAppDetailsTabType);

  return (
    <Details.KeyValue
      css={css`
        min-height: 4.875rem;
        padding-bottom: 0.125rem;
      `}
      title={translate('details.notes')}
    >
      {(type === 'system-view' || type === 'unapproved-system-view') && <NotesView />}
      {(type === 'system-edit' || type === 'unapproved-system-edit') && <NotesEdit />}
    </Details.KeyValue>
  );
};
