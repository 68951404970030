// components
import { StyledItem, EnrolmentKeySelect, GoToSystems } from '../components';
import { StyledCommandLineCode } from '../styled';
import { ExternalLink } from 'components';
import { Trans } from 'react-i18next';

// Hooks
import { useTranslations } from 'hooks/useTranslations';

// Constants
import { LINK_TO_HOMEBREW } from 'appConstants/links/enclave';

export const MacOs = () => {
  const { translate } = useTranslations('enrol-new-system');

  return (
    <>
      <StyledItem
        title={
          <>
            <Trans
              i18nKey={translate('mac.steps.1.title')}
              components={[
                <ExternalLink
                  key={0}
                  textType="body-1"
                  href={LINK_TO_HOMEBREW}
                  text={translate('mac.steps.1.homebrew')}
                />,
              ]}
            />
          </>
        }
        content={<StyledCommandLineCode code={`brew install enclave`} />}
      />
      <StyledItem
        title={translate('mac.steps.2.title')}
        content={<StyledCommandLineCode code={`sudo enclave enrol`} />}
      />
      <EnrolmentKeySelect />
      <GoToSystems />
    </>
  );
};
