// libs
import { css, useTheme } from 'styled-components';

// components
import { CustomButton, CustomButtonLink, Loader, OrganisationBadge } from 'components';
import { ModalOrgWrapper } from './styled';
import { IconSideMenuJoinOrgModal } from 'assets/svg';
import { ModalFooter, ModalHeader, ModalScrollablePart } from 'components/Modal';
import { CustomText } from 'typography/Text';

// hooks
import { useCallback } from 'react';
import { useTranslations } from 'hooks/useTranslations';
import { useDispatch, useSelector } from 'react-redux';
import { useSubmitOnEnter } from 'hooks/useSubmitOnEnter';

// selectors
import { selectModalData, selectModalIsLoading } from 'redux/selectors';

// actions
import { setModalContent } from 'redux/actions/app';
import { acceptInvite, declineInvite } from 'redux/actions/account';

// types
import { IModalJoinOrg } from 'types';

export const JoinOrgModal = () => {
  const dispatch = useDispatch();

  const { translate } = useTranslations('menu');

  const {
    palette: { fonts },
  } = useTheme();

  const modalData = useSelector(selectModalData) as IModalJoinOrg['data'] | null;
  const isLoading = useSelector(selectModalIsLoading);

  const isButtonEnabled = !isLoading;

  const onDeny = useCallback(() => {
    modalData && dispatch(declineInvite({ orgId: modalData?.orgId, fromModal: true }));
  }, [dispatch, modalData]);

  const onAccept = useCallback(() => {
    modalData && dispatch(acceptInvite({ orgId: modalData?.orgId, fromModal: true }));
  }, [dispatch, modalData]);

  const onClose = useCallback(() => {
    dispatch(setModalContent({ type: null, data: null }));
  }, [dispatch]);

  useSubmitOnEnter({ callback: onDeny });

  return (
    <>
      <ModalHeader
        icon={<IconSideMenuJoinOrgModal width="2.5rem" height="2.5rem" />}
        title={translate('join_org_modal.title')}
        withCloseButton={true}
        onClose={onClose}
      />
      <ModalScrollablePart withMinHeight={false}>
        <CustomText type="body-1" color={fonts.bodyLight}>
          {translate('join_org_modal.description')}
        </CustomText>
        <ModalOrgWrapper>
          <OrganisationBadge altName={modalData?.orgName} />
          <CustomText
            type="body-2"
            color={fonts.bodyDark}
            css={css`
              margin-left: 0.5rem;
            `}
          >
            {modalData?.orgName}
          </CustomText>
        </ModalOrgWrapper>
      </ModalScrollablePart>
      <ModalFooter>
        <CustomButtonLink size="large" onClick={onDeny}>
          {translate('join_org_modal.cancel')}
        </CustomButtonLink>
        <CustomButton
          size="large"
          width="11.25rem"
          kind={isButtonEnabled ? 'primary' : 'disabled'}
          onClick={onAccept}
        >
          {isLoading ? <Loader /> : translate('join_org_modal.join')}
        </CustomButton>
      </ModalFooter>
    </>
  );
};
