import {
  ROUTE_PATHS,
  USER_AUTHENTICATION_CONDITION_GROUP_ID,
  USER_AUTHENTICATION_CONDITION_GOOGLE_DOMAIN_ID,
  USER_AUTHENTICATION_CONFIGURATION_TENANT_ID,
  PUBLIC_IP_CONDITION_IP_RANGE,
  PUBLIC_IP_CONDITION_LOCATION,
  USER_AUTHENTICATION_CONFIGURATION_AUTHORITY_URI,
  USER_AUTHENTICATION_CONFIGURATION_CLIENT_ID,
  USER_AUTHENTICATION_CONFIGURATION_AUDIENCE,
} from 'appConstants';
import {
  ITrustRequirementPublicIpIpRangeConditionModified,
  MainState,
  ITrustRequirementPublicIpLocationConditionModified,
} from 'types';

export const selectTrustRequirementsIsLoading = (state: MainState) =>
  state.TrustRequirements.isLoading;

export const selectTrustRequirementsTotal = (state: MainState) =>
  state.TrustRequirements.metadata.total;

export const selectTrustRequirementsUpdateId = (state: MainState) =>
  state.TrustRequirements.updateId;

export const selectTrustRequirements = (state: MainState) => state.TrustRequirements.items;

export const selectTrustRequirementsSearchKeys = (state: MainState) =>
  state.TrustRequirements.searchKeys;

export const selectAppDetailsTrustRequirementIsTypeSelected = ({ App }: MainState) => {
  if (App.detailsTab.type === 'trust-requirement-create' && !App.detailsTab.dataNew?.type) {
    return false;
  }
  return true;
};

export const selectAppDetailsTrustRequirementId = ({ App }: MainState) => {
  if (
    App.detailsTab.type === 'trust-requirement-view' ||
    App.detailsTab.type === 'trust-requirement-edit'
  ) {
    return App.detailsTab.data.id;
  }
};

export const selectAppDetailsTrustRequirementDescription = ({ App }: MainState) => {
  if (
    App.detailsTab.type === 'trust-requirement-view' ||
    App.detailsTab.type === 'trust-requirement-edit'
  ) {
    return App.detailsTab.data.description;
  }
};
export const selectAppDetailsTrustRequirementNewDescription = ({ App }: MainState) => {
  if (
    App.detailsTab.type === 'trust-requirement-create' ||
    App.detailsTab.type === 'trust-requirement-edit'
  ) {
    return App.detailsTab.dataNew?.description;
  }
};

export const selectAppDetailsTrustRequirementType = ({ App }: MainState) => {
  if (
    App.detailsTab.type === 'trust-requirement-view' ||
    App.detailsTab.type === 'trust-requirement-edit'
  ) {
    return App.detailsTab.data.type;
  }
};
export const selectAppDetailsTrustRequirementNewType = ({ App }: MainState) => {
  if (
    App.detailsTab.type === 'trust-requirement-create' ||
    App.detailsTab.type === 'trust-requirement-edit'
  ) {
    return App.detailsTab.dataNew?.type;
  }
};

export const selectAppDetailsTrustRequirementCurrentType = ({ App }: MainState) => {
  if (
    App.detailsTab.type === 'trust-requirement-create' ||
    App.detailsTab.type === 'trust-requirement-edit'
  ) {
    return App.detailsTab.dataNew?.type;
  } else if (App.detailsTab.type === 'trust-requirement-view') {
    return App.detailsTab.data?.type;
  }
};

export const selectAppDetailsTrustRequirementNotes = ({ App }: MainState) => {
  if (
    App.detailsTab.type === 'trust-requirement-view' ||
    App.detailsTab.type === 'trust-requirement-edit'
  ) {
    return App.detailsTab.data.notes;
  }
};
export const selectAppDetailsTrustRequirementNewNotes = ({ App }: MainState) => {
  if (
    App.detailsTab.type === 'trust-requirement-create' ||
    App.detailsTab.type === 'trust-requirement-edit'
  ) {
    return App.detailsTab.dataNew?.notes;
  }
};

export const selectAppDetailsTrustRequirementAuthority = ({ App }: MainState) => {
  if (
    App.detailsTab.type === 'trust-requirement-view' ||
    App.detailsTab.type === 'trust-requirement-edit'
  ) {
    return App.detailsTab.data?.settings?.configuration?.authority;
  }
};

export const selectAppDetailsTrustRequirementNewAuthority = ({ App }: MainState) => {
  if (
    App.detailsTab.type === 'trust-requirement-create' ||
    App.detailsTab.type === 'trust-requirement-edit'
  ) {
    return App.detailsTab.dataNew?.settings?.configuration?.authority;
  }
};

export const selectAppDetailsTrustRequirementCurrentAuthority = ({ App }: MainState) => {
  if (
    App.detailsTab.type === 'trust-requirement-create' ||
    App.detailsTab.type === 'trust-requirement-edit'
  ) {
    return App.detailsTab.dataNew?.settings?.configuration?.authority;
  }

  if (App.detailsTab.type === 'trust-requirement-view') {
    return App.detailsTab.data?.settings?.configuration?.authority;
  }
};

// Microsoft Authority
export const selectAppDetailsTrustRequirementMicrosoftAuthorityTenantId = ({ App }: MainState) => {
  if (
    App.detailsTab.type === 'trust-requirement-view' ||
    App.detailsTab.type === 'trust-requirement-edit'
  ) {
    return App.detailsTab.data?.settings?.configuration?.[
      USER_AUTHENTICATION_CONFIGURATION_TENANT_ID
    ];
  }
};

export const selectAppDetailsTrustRequirementMicrosoftAuthorityNewTenantId = ({
  App,
}: MainState) => {
  if (
    App.detailsTab.type === 'trust-requirement-create' ||
    App.detailsTab.type === 'trust-requirement-edit'
  ) {
    return App.detailsTab.dataNew?.settings?.configuration?.[
      USER_AUTHENTICATION_CONFIGURATION_TENANT_ID
    ];
  }
};

export const selectAppDetailsTrustRequirementMicrosoftAuthorityGroupId = ({ App }: MainState) => {
  if (
    App.detailsTab.type === 'trust-requirement-view' ||
    App.detailsTab.type === 'trust-requirement-edit'
  ) {
    return App.detailsTab.data?.settings?.conditions?.find(
      condition => condition.claim === USER_AUTHENTICATION_CONDITION_GROUP_ID
    )?.value;
  }
};

export const selectAppDetailsTrustRequirementMicrosoftAuthorityNewGroupId = ({
  App,
}: MainState) => {
  if (
    App.detailsTab.type === 'trust-requirement-create' ||
    App.detailsTab.type === 'trust-requirement-edit'
  ) {
    return App.detailsTab.dataNew?.settings?.conditions?.find(
      condition => condition.claim === USER_AUTHENTICATION_CONDITION_GROUP_ID
    )?.value;
  }
};

export const selectAppDetailsTrustRequirementGoogleAuthorityDomainId = ({ App }: MainState) => {
  if (
    App.detailsTab.type === 'trust-requirement-view' ||
    App.detailsTab.type === 'trust-requirement-edit'
  ) {
    return App.detailsTab.data?.settings?.conditions?.find(
      condition => condition.claim === USER_AUTHENTICATION_CONDITION_GOOGLE_DOMAIN_ID
    )?.value;
  }
};

export const selectAppDetailsTrustRequirementGoogleAuthorityNewDomainId = ({ App }: MainState) => {
  if (
    App.detailsTab.type === 'trust-requirement-create' ||
    App.detailsTab.type === 'trust-requirement-edit'
  ) {
    return App.detailsTab.dataNew?.settings?.conditions?.find(
      condition => condition.claim === USER_AUTHENTICATION_CONDITION_GOOGLE_DOMAIN_ID
    )?.value;
  }
};

// All OIDC Authority
export const selectAppDetailsTrustRequirementOIDCAuthorityUri = ({ App }: MainState) => {
  if (
    App.detailsTab.type === 'trust-requirement-view' ||
    App.detailsTab.type === 'trust-requirement-edit'
  ) {
    return App.detailsTab.data?.settings?.configuration?.[
      USER_AUTHENTICATION_CONFIGURATION_AUTHORITY_URI
    ];
  }
};

export const selectAppDetailsTrustRequirementNewOIDCAuthorityUri = ({ App }: MainState) => {
  if (
    App.detailsTab.type === 'trust-requirement-create' ||
    App.detailsTab.type === 'trust-requirement-edit'
  ) {
    return App.detailsTab.dataNew?.settings?.configuration?.[
      USER_AUTHENTICATION_CONFIGURATION_AUTHORITY_URI
    ];
  }
};

export const selectAppDetailsTrustRequirementOIDCClientId = ({ App }: MainState) => {
  if (
    App.detailsTab.type === 'trust-requirement-view' ||
    App.detailsTab.type === 'trust-requirement-edit'
  ) {
    return App.detailsTab.data?.settings?.configuration?.[
      USER_AUTHENTICATION_CONFIGURATION_CLIENT_ID
    ];
  }
};

export const selectAppDetailsTrustRequirementNewOIDCClientId = ({ App }: MainState) => {
  if (
    App.detailsTab.type === 'trust-requirement-create' ||
    App.detailsTab.type === 'trust-requirement-edit'
  ) {
    return App.detailsTab.dataNew?.settings?.configuration?.[
      USER_AUTHENTICATION_CONFIGURATION_CLIENT_ID
    ];
  }
};

export const selectAppDetailsTrustRequirementOIDCAudience = ({ App }: MainState) => {
  if (
    App.detailsTab.type === 'trust-requirement-view' ||
    App.detailsTab.type === 'trust-requirement-edit'
  ) {
    return App.detailsTab.data?.settings?.configuration?.[
      USER_AUTHENTICATION_CONFIGURATION_AUDIENCE
    ];
  }
};

export const selectAppDetailsTrustRequirementNewOIDCAudience = ({ App }: MainState) => {
  if (
    App.detailsTab.type === 'trust-requirement-create' ||
    App.detailsTab.type === 'trust-requirement-edit'
  ) {
    return App.detailsTab.dataNew?.settings?.configuration?.[
      USER_AUTHENTICATION_CONFIGURATION_AUDIENCE
    ];
  }
};

// // Duo Authority
// export const selectAppDetailsTrustRequirementDuoAuthorityUri = ({ App }: MainState) => {
//   if (
//     App.detailsTab.type === 'trust-requirement-view' ||
//     App.detailsTab.type === 'trust-requirement-edit'
//   ) {
//     return App.detailsTab.data?.settings?.configuration?.[
//       USER_AUTHENTICATION_CONFIGURATION_AUTHORITY_URI
//     ];
//   }
// };

// export const selectAppDetailsTrustRequirementNewDuoAuthorityUri = ({ App }: MainState) => {
//   if (
//     App.detailsTab.type === 'trust-requirement-create' ||
//     App.detailsTab.type === 'trust-requirement-edit'
//   ) {
//     return App.detailsTab.dataNew?.settings?.configuration?.[
//       USER_AUTHENTICATION_CONFIGURATION_AUTHORITY_URI
//     ];
//   }
// };

// export const selectAppDetailsTrustRequirementDuoClientId = ({ App }: MainState) => {
//   if (
//     App.detailsTab.type === 'trust-requirement-view' ||
//     App.detailsTab.type === 'trust-requirement-edit'
//   ) {
//     return App.detailsTab.data?.settings?.configuration?.[
//       USER_AUTHENTICATION_CONFIGURATION_CLIENT_ID
//     ];
//   }
// };

// export const selectAppDetailsTrustRequirementNewDuoClientId = ({ App }: MainState) => {
//   if (
//     App.detailsTab.type === 'trust-requirement-create' ||
//     App.detailsTab.type === 'trust-requirement-edit'
//   ) {
//     return App.detailsTab.dataNew?.settings?.configuration?.[
//       USER_AUTHENTICATION_CONFIGURATION_CLIENT_ID
//     ];
//   }
// };

// export const selectAppDetailsTrustRequirementDuoAudience = ({ App }: MainState) => {
//   if (
//     App.detailsTab.type === 'trust-requirement-view' ||
//     App.detailsTab.type === 'trust-requirement-edit'
//   ) {
//     return App.detailsTab.data?.settings?.configuration?.[
//       USER_AUTHENTICATION_CONFIGURATION_AUDIENCE
//     ];
//   }
// };

// export const selectAppDetailsTrustRequirementNewDuoAudience = ({ App }: MainState) => {
//   if (
//     App.detailsTab.type === 'trust-requirement-create' ||
//     App.detailsTab.type === 'trust-requirement-edit'
//   ) {
//     return App.detailsTab.dataNew?.settings?.configuration?.[
//       USER_AUTHENTICATION_CONFIGURATION_AUDIENCE
//     ];
//   }
// };

// // Okta Authority
// export const selectAppDetailsTrustRequirementOktaAuthorityUri = ({ App }: MainState) => {
//   if (
//     App.detailsTab.type === 'trust-requirement-view' ||
//     App.detailsTab.type === 'trust-requirement-edit'
//   ) {
//     return App.detailsTab.data?.settings?.configuration?.[
//       USER_AUTHENTICATION_CONFIGURATION_AUTHORITY_URI
//     ];
//   }
// };

// export const selectAppDetailsTrustRequirementNewOktaAuthorityUri = ({ App }: MainState) => {
//   if (
//     App.detailsTab.type === 'trust-requirement-create' ||
//     App.detailsTab.type === 'trust-requirement-edit'
//   ) {
//     return App.detailsTab.dataNew?.settings?.configuration?.[
//       USER_AUTHENTICATION_CONFIGURATION_AUTHORITY_URI
//     ];
//   }
// };

// export const selectAppDetailsTrustRequirementOktaClientId = ({ App }: MainState) => {
//   if (
//     App.detailsTab.type === 'trust-requirement-view' ||
//     App.detailsTab.type === 'trust-requirement-edit'
//   ) {
//     return App.detailsTab.data?.settings?.configuration?.[
//       USER_AUTHENTICATION_CONFIGURATION_CLIENT_ID
//     ];
//   }
// };

// export const selectAppDetailsTrustRequirementNewOktaClientId = ({ App }: MainState) => {
//   if (
//     App.detailsTab.type === 'trust-requirement-create' ||
//     App.detailsTab.type === 'trust-requirement-edit'
//   ) {
//     return App.detailsTab.dataNew?.settings?.configuration?.[
//       USER_AUTHENTICATION_CONFIGURATION_CLIENT_ID
//     ];
//   }
// };

// export const selectAppDetailsTrustRequirementOktaAudience = ({ App }: MainState) => {
//   if (
//     App.detailsTab.type === 'trust-requirement-view' ||
//     App.detailsTab.type === 'trust-requirement-edit'
//   ) {
//     return App.detailsTab.data?.settings?.configuration?.[
//       USER_AUTHENTICATION_CONFIGURATION_AUDIENCE
//     ];
//   }
// };

// export const selectAppDetailsTrustRequirementNewOktaAudience = ({ App }: MainState) => {
//   if (
//     App.detailsTab.type === 'trust-requirement-create' ||
//     App.detailsTab.type === 'trust-requirement-edit'
//   ) {
//     return App.detailsTab.dataNew?.settings?.configuration?.[
//       USER_AUTHENTICATION_CONFIGURATION_AUDIENCE
//     ];
//   }
// };

export const selectAppDetailsTrustRequirementCurrentIpRanges = ({
  App,
}: MainState): ITrustRequirementPublicIpIpRangeConditionModified[] | undefined => {
  if (App.detailsTab.type === 'trust-requirement-view') {
    return App.detailsTab.data?.settings?.conditions?.filter(
      condition => condition.type === PUBLIC_IP_CONDITION_IP_RANGE
    ) as ITrustRequirementPublicIpIpRangeConditionModified[];
  }

  if (
    App.detailsTab.type === 'trust-requirement-create' ||
    App.detailsTab.type === 'trust-requirement-edit'
  ) {
    return App.detailsTab.dataNew?.settings?.conditions?.filter(
      condition => condition.type === PUBLIC_IP_CONDITION_IP_RANGE
    ) as ITrustRequirementPublicIpIpRangeConditionModified[];
  }
};

export const selectAppDetailsTrustRequirementCurrentLocations = ({
  App,
}: MainState): ITrustRequirementPublicIpLocationConditionModified[] | undefined => {
  if (App.detailsTab.type === 'trust-requirement-view') {
    return App.detailsTab.data?.settings?.conditions?.filter(
      condition => condition.type === PUBLIC_IP_CONDITION_LOCATION
    ) as ITrustRequirementPublicIpLocationConditionModified[];
  }

  if (
    App.detailsTab.type === 'trust-requirement-create' ||
    App.detailsTab.type === 'trust-requirement-edit'
  ) {
    return App.detailsTab.dataNew?.settings?.conditions?.filter(
      condition => condition.type === PUBLIC_IP_CONDITION_LOCATION
    ) as ITrustRequirementPublicIpLocationConditionModified[];
  }
};

export const selectAppDetailsTrustRequirementCurrentLocationsIsBlocked = ({
  App,
}: MainState): ITrustRequirementPublicIpLocationConditionModified['isBlocked'] | undefined => {
  if (App.detailsTab.type === 'trust-requirement-view') {
    return App.detailsTab.data?.settings?.conditions?.[0][
      'isBlocked'
    ] as ITrustRequirementPublicIpLocationConditionModified['isBlocked'];
  }

  if (
    App.detailsTab.type === 'trust-requirement-create' ||
    App.detailsTab.type === 'trust-requirement-edit'
  ) {
    return App.detailsTab.dataNew?.settings?.conditions?.[0]?.[
      'isBlocked'
    ] as ITrustRequirementPublicIpLocationConditionModified['isBlocked'];
  }
};

export const selectAppDetailsTrustRequirementCurrentLocationsValues = ({
  App,
}: MainState): ITrustRequirementPublicIpLocationConditionModified['value'][] | undefined => {
  if (App.detailsTab.type === 'trust-requirement-view') {
    return App.detailsTab.data?.settings?.conditions
      ?.filter(condition => condition.type === PUBLIC_IP_CONDITION_LOCATION)
      .map(
        condition => condition.value
      ) as ITrustRequirementPublicIpLocationConditionModified['value'][];
  }

  if (
    App.detailsTab.type === 'trust-requirement-create' ||
    App.detailsTab.type === 'trust-requirement-edit'
  ) {
    return App.detailsTab.dataNew?.settings?.conditions
      ?.filter(condition => condition.type === PUBLIC_IP_CONDITION_LOCATION)
      .map(
        condition => condition.value
      ) as ITrustRequirementPublicIpLocationConditionModified['value'][];
  }
};

export const selectIsTrustRequirementsModule = ({
  router: {
    location: { pathname },
  },
  Account: { currentOrg },
}: MainState) => {
  return pathname.includes(`${currentOrg}${ROUTE_PATHS['trust-requirements']}`);
};
