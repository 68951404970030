import { createAction } from '@reduxjs/toolkit';
import {
  IValidationState,
  IDeleteValidationStateEntry,
  IAddValidationStateEntry,
  IUpdateValidationStateEntry,
} from 'types';

export const setValidationState = createAction<IValidationState>('main/SET_VALIDATION_STATE');
export const deleteValidationStateEntry = createAction<IDeleteValidationStateEntry>(
  'main/DELETE_VALIDATION_STATE_ENTRY'
);
export const addValidationStateEntry = createAction<IAddValidationStateEntry>(
  'main/ADD_VALIDATION_STATE_ENTRY'
);

export const updateValidationStateEntry = createAction<IUpdateValidationStateEntry>(
  'main/UPDATE_VALIDATION_STATE_ENTRY'
);

export const updateValidationStateUpdates = createAction('main/UPDATE_VALIDATION_UPDATE_ID');

export const activateValidationState = createAction<string[]>('main/ACTIVATE_VALIDATION_STATE');
export const deactivateValidationState = createAction<string[]>('main/DEACTIVATE_VALIDATION_STATE');
