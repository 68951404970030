// libs
import { css } from 'styled-components';

// components
import { Input } from 'components';

// hooks
import { useDispatch, useSelector } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';
import { useCallback, useEffect, useState } from 'react';

// actions
import { updatePolicyDetailsAcl } from 'redux/actions/app';

// selectors
import { selectAppDetailsTabValidationEntry } from 'redux/selectors';

// types
import { BaseSyntheticEvent } from 'react';
import { IPolicyModifiedAcl } from 'types';

// constants
import { POLICY_PORTS_VALIDATION_STATE_PREFIX, REGEX_NON_DIGIT } from 'appConstants';
import { ACLS_PROTOCOLS_CONFIG } from 'appConstants/policiesAcls';

// utils
import { parsePortString } from '../../helpers';

interface IPorts {
  acl: IPolicyModifiedAcl;
}

export const Ports = ({ acl }: IPorts) => {
  const dispatch = useDispatch();

  const { translate } = useTranslations('policies');

  const validationState = useSelector(
    selectAppDetailsTabValidationEntry(POLICY_PORTS_VALIDATION_STATE_PREFIX + acl.id)
  );

  const [value, setValue] = useState(acl.ports || '');

  useEffect(() => {
    setValue(acl.ports || '');
  }, [acl.ports]);

  const onChange = useCallback(
    (event: BaseSyntheticEvent) => {
      const newValue = event.target.value;

      const parsedValue = parsePortString({
        ports: event.target.value,
        removing: newValue.length < value.length,
      });

      const result = parsedValue.hasHyphen
        ? `${parsedValue.value1 || ''} - ${parsedValue.value2 || ''}`
        : `${parsedValue.value1 || ''}`;

      setValue(result);
    },
    [value]
  );

  const onBlur = useCallback(
    (event: BaseSyntheticEvent) => {
      const value = event.target.value;

      const newPorts = value.endsWith(' - ') ? value.replace(REGEX_NON_DIGIT, '') : value;

      dispatch(
        updatePolicyDetailsAcl({
          id: acl.id,
          newPorts: newPorts,
        })
      );
    },
    [acl, dispatch]
  );

  return (
    <Input
      aria-label={translate('details.acls_table.aria_labels.port_range')}
      placeholder={
        acl.protocol && ACLS_PROTOCOLS_CONFIG[acl.protocol].ports
          ? translate('details.acls_table.port_range_placeholder', {
              range: ACLS_PROTOCOLS_CONFIG[acl.protocol].ports || '',
            })
          : ''
      }
      showStateIcon={validationState?.updated && validationState?.state.error !== null}
      suffixMessage={validationState?.state.error?.message}
      tooltipProps={{ width: '9.75rem' }}
      error={
        validationState?.updated && validationState?.state.error !== null
          ? { message: validationState.state.error.message }
          : null
      }
      textStyle="body-2"
      width="11.8125rem"
      size="middle"
      disabled={acl.ports === null}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      labelCss={css`
        display: none;
      `}
      stateMessageCss={css`
        display: none;
      `}
      containerCss={css`
        margin-left: 0.75rem;
      `}
    />
  );
};
