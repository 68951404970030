import { createReducer } from '@reduxjs/toolkit';
import { ITagsStore, IGetTagsResponse, ITag } from 'types';
import {
  setTags,
  setIsLoading,
  updateTagsId,
  updateTagState,
  setTagSearchKeys,
} from 'redux/actions/tags';
import { randomHash } from 'utils/hash';

export const initialTagsResponse: IGetTagsResponse = {
  items: [],
  metadata: {
    total: 0,
    firstPage: 0,
    prevPage: null,
    lastPage: 0,
    nextPage: null,
  },
  links: {
    first: '',
    prev: null,
    next: null,
    last: '',
  },
};

const initialState: ITagsStore = {
  ...initialTagsResponse,
  isLoading: false,
  updateId: randomHash(),
  searchKeys: null,
  query: '',
};

export const tagsReducer = createReducer(initialState, builder => {
  builder
    .addCase(setTags, (state, { payload }) => {
      return {
        ...state,
        ...payload,
        isLoading: false,
      };
    })
    .addCase(updateTagState, (state, { payload }) => {
      state.items = state.items.map((tag: ITag) => (payload.ref === tag.ref ? payload : tag));
    })
    .addCase(setIsLoading, (state, { payload }) => {
      state.isLoading = payload;
    })
    .addCase(updateTagsId, state => {
      state.updateId = randomHash();
    })
    .addCase(setTagSearchKeys, (state, { payload }) => {
      state.searchKeys = payload;
    });
});
