import styled from 'styled-components';
import { IconEphemeral } from 'assets/svg';

interface IIconContainer {
  fill?: string;
  active: boolean;
  size: 'medium' | 'large';
  withPadding: boolean;
}

const sizeMap: Record<IIconContainer['size'], string> = {
  medium: '1.5rem',
  large: '2rem',
};

export const IconContainer = styled.div<IIconContainer>`
  min-height: ${({ size }) => sizeMap[size]};
  min-width: ${({ size }) => sizeMap[size]};
  max-height: ${({ size }) => sizeMap[size]};
  max-width: ${({ size }) => sizeMap[size]};

  padding: ${({ withPadding }) => withPadding && '0.25rem'};

  background-color: ${props =>
    props.active
      ? props.theme.palette.fills.primary
      : props.theme.palette.background.iconBackground};
  border-radius: 0.25rem;

  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  path {
    fill: ${props => props.fill && props.fill};
  }
`;

interface IIcon {
  $active: boolean;
}

export const StyledEphemeralIcon = styled(IconEphemeral)<IIcon>`
  position: absolute;
  top: -0.25rem;
  right: -0.25rem;
  path {
    fill: white;
  }
  circle {
    fill: ${props => props.$active && props.theme.palette.fills.stroke};
  }
`;
