// components
import { CustomText } from 'typography/Text';
import { Icons } from './Icons';
import { ViewAuthorityContainer } from './styled';

// hooks
import { useTheme } from 'styled-components';
import { useSelector } from 'react-redux';

// selectors
import { selectAppDetailsTrustRequirementAuthority } from 'redux/selectors';

// types
import { ITypeTrustRequirementUserAuthenticationAuthority } from 'types';

// constants
import { USER_AUTHENTICATION_AUTHORITIES } from 'appConstants';

export const AuthorityView = () => {
  const {
    palette: { fonts },
  } = useTheme();

  const authority = useSelector(
    selectAppDetailsTrustRequirementAuthority
  ) as ITypeTrustRequirementUserAuthenticationAuthority;

  return (
    <ViewAuthorityContainer data-test="trust-requirement-authority">
      {authority && Icons[authority]}

      <CustomText type="body-2" color={fonts.title}>
        {authority && USER_AUTHENTICATION_AUTHORITIES[authority].name}
      </CustomText>
    </ViewAuthorityContainer>
  );
};
