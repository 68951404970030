// libs
import { memo } from 'react';

// components
import { CustomText } from 'typography/Text';
import Skeleton from 'react-loading-skeleton';

// hooks
import { useTheme } from 'styled-components';

// types
import { ISimpleEnrolmentKey } from 'types';

interface IEnrolledCount {
  enrolledCount: ISimpleEnrolmentKey['enrolledCount'];
  isEnabled?: ISimpleEnrolmentKey['isEnabled'];
  isLoading: boolean;
}

export const EnrolledCount = memo(
  ({ enrolledCount, isEnabled = true, isLoading }: IEnrolledCount) => {
    const theme = useTheme();
    return (
      <>
        {isLoading ? (
          <Skeleton />
        ) : (
          <CustomText
            type="body-2"
            color={isEnabled ? theme.palette.fonts.bodyDark : theme.palette.fonts.disabled}
          >
            {enrolledCount}
          </CustomText>
        )}
      </>
    );
  }
);
