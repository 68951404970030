// libs
import { useMemo } from 'react';
import { intersection } from 'ramda';
import { css, useTheme } from 'styled-components';

// components
import { TagSelect } from 'components';
import {
  Traffic,
  Container,
  TagTitleCustomText,
  IconContainer,
  borderRadiusCss,
  TagsContainer,
} from '../styled';
import {
  IconPoliciesDetailsFullMesh,
  IconPoliciesTrafficAllowed,
  IconPoliciesTrafficDenied,
} from 'assets/svg';
import { FullMeshNotification } from './FullMeshNotification';

// hooks
import { useDispatch, useSelector } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';

// selectors
import {
  selectAppDetailsPolicyNewAcls,
  selectAppDetailsPolicyNewReceiverTags,
  selectAppDetailsPolicyNewSenderTags,
} from 'redux/selectors';

// actions
import { updatePolicyDetails } from 'redux/actions/app';

// types
import { TagReference } from 'types';

interface IPolicyMembersTags {
  isNew?: boolean;
}

export const PolicyMembersTagsEditableContent = ({ isNew = false }: IPolicyMembersTags) => {
  const dispatch = useDispatch();

  const { translate } = useTranslations('policies');

  const {
    palette: { fonts },
  } = useTheme();

  const senderTagsNew = useSelector(selectAppDetailsPolicyNewSenderTags);
  const receiverTagsNew = useSelector(selectAppDetailsPolicyNewReceiverTags);
  const newAcls = useSelector(selectAppDetailsPolicyNewAcls);

  const handleSenderTagUpdate = (selectedTags: TagReference[]) => {
    dispatch(
      updatePolicyDetails({
        senderTags: selectedTags,
      })
    );
  };

  const handleReceiverTagUpdate = (selectedTags: TagReference[]) => {
    dispatch(
      updatePolicyDetails({
        receiverTags: selectedTags,
      })
    );
  };

  const isMesh = useMemo(
    () => Boolean(intersection(senderTagsNew, receiverTagsNew)?.length),
    [receiverTagsNew, senderTagsNew]
  );

  return (
    <Container>
      <TagsContainer>
        <Traffic>
          <TagTitleCustomText type="body-2" color={fonts.bodyLight}>
            {translate('details.senders')}
          </TagTitleCustomText>
          <TagSelect
            width="100%"
            containerCss={css`
              .ant-select-selector {
                ${borderRadiusCss}
              }
            `}
            preSelected={senderTagsNew}
            changeCallback={handleSenderTagUpdate}
          />
        </Traffic>
        <IconContainer>
          {senderTagsNew.length === 0 || newAcls?.length === 0 || receiverTagsNew.length === 0 ? (
            <IconPoliciesTrafficDenied />
          ) : !isNew && isMesh ? (
            <IconPoliciesDetailsFullMesh />
          ) : (
            <IconPoliciesTrafficAllowed
              css={
                isNew
                  ? css`
                      path {
                        fill: ${({ theme }) => theme.palette.fills.strokeMedium};
                      }
                    `
                  : undefined
              }
            />
          )}
        </IconContainer>
        <Traffic>
          <TagTitleCustomText type="body-2" color={fonts.bodyLight}>
            {translate('details.receivers')}
          </TagTitleCustomText>
          <TagSelect
            width="100%"
            containerCss={css`
              .ant-select-selector {
                ${borderRadiusCss}
              }
            `}
            preSelected={receiverTagsNew}
            changeCallback={handleReceiverTagUpdate}
          />
        </Traffic>
      </TagsContainer>
      {isMesh && <FullMeshNotification mode={isNew ? 'create' : 'view'} />}
    </Container>
  );
};
