// Hooks
import { useTheme } from 'styled-components';
import { useTranslations } from 'hooks/useTranslations';

// Components
import { CustomText } from 'typography/Text';

export const InvalidFormat = () => {
  const { translate } = useTranslations('tags');
  const { palette } = useTheme();

  return (
    <CustomText
      type="hint"
      color={palette.state.error}
      data-test="tags-select-error-invalid-tag-format"
    >
      {translate('invalid_tag_format')}
    </CustomText>
  );
};

export const InvalidCharacter = () => {
  const { translate } = useTranslations('tags');
  const { palette } = useTheme();

  return (
    <CustomText
      type="hint"
      color={palette.fonts.help}
      data-test="tags-select-error-invalid-character"
    >
      {translate('invalid_character')}
    </CustomText>
  );
};

export const InvalidTagLength = () => {
  const { translate } = useTranslations('tags');
  const { palette } = useTheme();

  return (
    <CustomText type="hint" color={palette.fonts.help}>
      {translate('this_tag_name_is_too_long')}
    </CustomText>
  );
};
