// libs
import { css, useTheme } from 'styled-components';

// components
import { ExternalLink } from 'components';
import { Column, Flex, LinkWrapper, Row } from './styled';
import { CustomText } from 'typography/Text';

// hooks
import { useTranslations } from 'hooks/useTranslations';

// constants
import {
  LINK_TO_KNOWLEDGE_BASE_CANCEL_SUBSCRIPTION,
  LINK_TO_KNOWLEDGE_BASE_TRANSFER_OWNERSHIP,
} from 'appConstants';

// utils
import { BlockWrapper, ContentWrapper } from '../../styled';

export const DangerZone = () => {
  const { translate } = useTranslations('settings');

  const {
    palette: { state, fonts },
  } = useTheme();

  return (
    <BlockWrapper>
      <ContentWrapper justify="flex-start">
        <CustomText
          type="sub-heading-1"
          color={state.error}
          css={css`
            display: block;
            margin-bottom: 1rem;
          `}
        >
          {translate('danger_zone.danger_zone')}
        </CustomText>
        <CustomText type="body-2" color={fonts.bodyLight}>
          {translate('danger_zone.irreversible_and_destructive_actions')}
        </CustomText>
      </ContentWrapper>
      <ContentWrapper>
        <Row>
          <Flex>
            <Column>
              <CustomText type="sub-heading-2" color={fonts.bodyLight}>
                {translate('danger_zone.transfer_ownership')}
              </CustomText>
              <CustomText
                css={css`
                  margin-top: 0.5rem;
                `}
                type="body-2"
                color={fonts.bodyLight}
              >
                {translate('danger_zone.transfer_ownership_description')}
              </CustomText>
            </Column>
            <LinkWrapper>
              <ExternalLink
                href={LINK_TO_KNOWLEDGE_BASE_TRANSFER_OWNERSHIP}
                text={translate('danger_zone.link')}
                textType="body-1"
                withIcon={true}
              />
            </LinkWrapper>
          </Flex>
        </Row>
        <Row>
          <Column>
            <CustomText type="sub-heading-2" color={fonts.bodyLight}>
              {translate('danger_zone.cancel_payment')}
            </CustomText>
            <CustomText
              css={css`
                margin-top: 0.5rem;
                padding-bottom: 0.5rem;
              `}
              type="body-2"
              color={fonts.bodyLight}
            >
              {translate('danger_zone.cancel_payment_description')}
            </CustomText>
          </Column>
          <LinkWrapper>
            <ExternalLink
              href={LINK_TO_KNOWLEDGE_BASE_CANCEL_SUBSCRIPTION}
              text={translate('danger_zone.link')}
              textType="body-1"
              withIcon={true}
            />
          </LinkWrapper>
        </Row>
      </ContentWrapper>
    </BlockWrapper>
  );
};
