// hooks
import { useTranslations } from 'hooks/useTranslations';

// types
import {
  IPolicyAcl,
  IPolicyAclProtocol,
  IPolicyAclType,
  IPolicyModifiedAcl,
  IValidationStateValue,
} from 'types';

// constants
import { ACLS_PROTOCOLS_CONFIG, ACLS_TYPES_CONFIG } from 'appConstants/policiesAcls';

// utils
import { translator } from 'utils';

interface IAclToType {
  acl: IPolicyModifiedAcl | IPolicyAcl;
}

export const aclToType = ({ acl }: IAclToType): IPolicyAclType | null => {
  if (acl.protocol === null) return null;
  if (acl.protocol === 'Any' && acl.ports !== null) return 'Custom';
  if (acl.protocol === 'Any') return 'Any';

  const aclActiveConfig = Object.values(ACLS_TYPES_CONFIG).find(config => {
    return acl.protocol === config.protocol && acl.ports === config.ports;
  });

  if (aclActiveConfig) {
    return aclActiveConfig.type;
  } else {
    return 'Custom';
  }
};

interface IChangeResponse extends IPolicyModifiedAcl {
  renewPortsUpdateStatus?: boolean;
}
export const changeType = ({
  type,
  acl,
}: {
  type: IPolicyAclType;
  acl: IPolicyModifiedAcl;
}): IChangeResponse => {
  if (type === 'Any') return { ...acl, protocol: 'Any', ports: null };

  const typeConfig = ACLS_TYPES_CONFIG[type];
  let renewPortsUpdateStatus = false;

  const newAcl = { ...acl };

  if (type === 'Custom') {
    renewPortsUpdateStatus = true;
    newAcl.ports = '';

    if (newAcl.protocol === 'Any') {
      newAcl.protocol = 'Tcp';
    }
  }

  return { ...newAcl, ...typeConfig, renewPortsUpdateStatus };
};

export const changeProtocol = ({
  protocol,
  acl,
}: {
  protocol: IPolicyAclProtocol;
  acl: IPolicyModifiedAcl;
}): IPolicyModifiedAcl => {
  const protocolConfig = Object.assign({}, ACLS_PROTOCOLS_CONFIG[protocol]);

  if (protocolConfig.ports !== null) {
    protocolConfig.ports = '';
  }

  return { ...acl, ...protocolConfig };
};

export const useAclTypeToText = (type: IPolicyAclType | null) => {
  const { translate } = useTranslations('policies');

  if (type === null) {
    return '';
  }

  if (type === 'Any') {
    return translate('details.acls_table.all_traffic');
  }
  if (type === 'Custom') {
    return translate('details.acls_table.custom');
  }
  return type;
};

export const useAclProtocolToText = (protocol: IPolicyModifiedAcl['protocol']) => {
  const { translate } = useTranslations('policies');

  if (protocol === null) return '';
  if (protocol === 'Any') {
    return translate('details.acls_table.all_protocols');
  }
  return protocol.toUpperCase();
};

export interface IParsedPortString {
  value1: null | string;
  hasHyphen: boolean;
  value2: null | string;
}

export const parsePortString = ({
  ports,
  removing = false,
}: {
  ports: string;
  removing?: boolean;
}) => {
  const result: IParsedPortString = {
    value1: null,
    hasHyphen: false,
    value2: null,
  };

  for (let index = 0; index < ports.length; index++) {
    const character = ports[index];

    // when we are trying to delete hyphen
    if (result.value1 !== null && character === '-' && (!removing || !ports.endsWith('-'))) {
      result.hasHyphen = true;
      continue;
    }

    if (!Number.isNaN(parseInt(character))) {
      if (result.hasHyphen) {
        result.value2 = result.value2 === null ? character : result.value2 + character;
      } else {
        result.value1 = result.value1 === null ? character : result.value1 + character;
      }
    }
  }

  return result;
};

export const validatePortsString = ({
  ports,
}: {
  ports: IPolicyModifiedAcl['ports'];
}): IValidationStateValue => {
  const translate = translator('policies');

  if (ports === null) {
    return { error: null };
  }

  const { value1, value2 } = parsePortString({ ports });

  if (value1 === null) {
    return { error: { message: translate('details.acls_errors.wrong_port_range') } };
  }

  if (value1 !== null && Number(value1) > 65535) {
    return { error: { message: translate('details.acls_errors.wrong_port_range') } };
  }

  if (value2 !== null && Number(value2) > 65535) {
    return { error: { message: translate('details.acls_errors.wrong_port_range') } };
  }

  if (value1 !== null && value2 !== null && Number(value1) > Number(value2)) {
    return { error: { message: translate('details.acls_errors.wrong_port_range') } };
  }

  return { error: null };
};
