// libs
import { useTheme } from 'styled-components';

// components
import { CustomText } from 'typography/Text';

// hooks
import { useSelector } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';

// selectors
import { selectAppDetailsEnrolmentKeyApprovalMode } from 'redux/selectors';

export const ApprovalModeView = () => {
  const { translate } = useTranslations('enrolment-keys');

  const {
    palette: { fonts },
  } = useTheme();

  const approvalMode = useSelector(selectAppDetailsEnrolmentKeyApprovalMode);

  return (
    <CustomText type="body-2" color={fonts.title}>
      {approvalMode === 'Manual' ? translate('details.gated') : translate('details.automatic')}
    </CustomText>
  );
};
