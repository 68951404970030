// libs
import { css } from 'styled-components';

// components
import { CustomButton } from 'components';
import { CancelSaveSection } from './styled';

// hooks
import { useTranslations } from 'hooks/useTranslations';

type Props = {
  handleCancel?: () => void;
  handleSave?: () => void;
  disabled?: boolean;
};

export const DetailsCancelSave = ({ handleCancel, handleSave, disabled, ...props }: Props) => {
  const { translate } = useTranslations('global');
  return (
    <CancelSaveSection {...props}>
      {handleCancel && (
        <CustomButton
          data-test="details-cancel"
          kind="secondary"
          onClick={handleCancel}
          size="large"
        >
          {translate('details_pane.cancel')}
        </CustomButton>
      )}
      {handleSave && (
        <CustomButton
          data-test="details-save"
          kind={disabled ? 'disabled' : 'primary'}
          onClick={event => {
            event.currentTarget.focus();
            handleSave();
          }}
          size="large"
          css={css`
            margin-left: 1rem;
          `}
        >
          {translate('details_pane.save')}
        </CustomButton>
      )}
    </CancelSaveSection>
  );
};
