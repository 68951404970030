// libs
import { css, CSSProp } from 'styled-components';

// components
import { TabPaneContainer } from './styled';

// types
import { TabWithActiveParam } from './';

export interface ITabsDefault {
  type: 'default';
  width?: string;
  title: string;
  key: string;
  elementCss?: CSSProp;
  titleCss?: CSSProp;
}

export const DefaultTabPane = ({
  tab: { key, elementCss, title, titleCss, width },
  active,
}: TabWithActiveParam<ITabsDefault>) => {
  return (
    <TabPaneContainer
      data-test={key}
      width={width}
      css={css`
        ${elementCss}
      `}
    >
      <span
        className={active ? 'title title-active' : 'title'}
        css={css`
          ${titleCss}
        `}
      >
        {title}
      </span>
    </TabPaneContainer>
  );
};
