// components
import { PricingContainer } from '../../styled';
import { CustomText } from 'typography/Text';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { useTheme } from 'styled-components';

// types
import { IOrganisationPricingCard, IPricingModel } from 'types';

// constants
import { BUSINESS_PLAN, ENTERPRISE_PLAN, STARTER_PLAN } from 'appConstants';

interface PricingProps {
  symbol: IPricingModel['currencySymbol'];
  type: IOrganisationPricingCard;
  rate: string | null;
}

export const Pricing = ({ symbol, rate, type }: PricingProps) => {
  const {
    palette: { fonts },
  } = useTheme();

  const { translate } = useTranslations('settings');

  return (
    <PricingContainer>
      <CustomText type="heading-2" color={fonts.title}>
        {type === STARTER_PLAN && translate('plans.free')}
        {type === BUSINESS_PLAN && `${symbol}${rate}`}
        {type === ENTERPRISE_PLAN && !rate && translate('plans.custom')}
        {type === ENTERPRISE_PLAN && !!rate && rate}{' '}
        <sub>
          {type === STARTER_PLAN && `${translate('plans.free_plan_limit')}`}
          {type === BUSINESS_PLAN &&
            `${translate('plans.per_system')} / ${translate('plans.per_month')}`}
          {type === ENTERPRISE_PLAN &&
            !!rate &&
            `${translate('plans.per_system')} / ${translate('plans.per_month')}`}
        </sub>
      </CustomText>
    </PricingContainer>
  );
};
