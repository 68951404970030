// libs
import { css, useTheme } from 'styled-components';

// components
import { IconArrowRight, List, ListItem } from './styled';
import { InternalLink } from 'components/Links/InternalLink';
import { CustomTag, OSIcon } from 'components';
import { CustomText } from 'typography/Text';

// hooks
import { useSelector, useDispatch } from 'react-redux';
import { useCallback, useEffect } from 'react';
import { useTranslations } from 'hooks/useTranslations';

// selectors
import {
  selectAppDetailsTagData,
  selectAppDetailsTagSystemsItems,
  selectCurrentOrganisationId,
  selectAppDetailsTagColour,
  selectAppDetailsTagDescription,
  selectAppDetailsTagNewColour,
  selectAppDetailsTagNewDescription,
  selectAppDetailsTagRef,
} from 'redux/selectors';

//actions
import { extendTagDetails } from 'redux/actions/tags';

// constants
import { ROUTE_PATHS } from 'appConstants';

// types
import { OSTypes } from 'types';
import { TAG_DETAILS_ELEMENTS_NUM, TAGS_DEFAULT_COLOUR } from 'appConstants/tags';

export const SystemsTab = () => {
  const {
    palette: { fonts },
  } = useTheme();

  const { translate } = useTranslations('tags');
  const dispatch = useDispatch();

  const tag = useSelector(selectAppDetailsTagData);
  const colour = useSelector(selectAppDetailsTagColour);
  const newColour = useSelector(selectAppDetailsTagNewColour);
  const description = useSelector(selectAppDetailsTagDescription);
  const newDescription = useSelector(selectAppDetailsTagNewDescription);
  const tagRef = useSelector(selectAppDetailsTagRef);

  const systems = useSelector(selectAppDetailsTagSystemsItems);
  const orgId = useSelector(selectCurrentOrganisationId);

  const fetchData = useCallback(
    () => dispatch(extendTagDetails({ tag: tag?.tag || '', module: 'systems' })),
    [dispatch, tag]
  );

  const getDescription = useCallback(() => {
    if (systems) {
      if (systems.length === 1) {
        return `details.connections.tabs.systems.description.title_one`;
      }
      if (systems.length > TAG_DETAILS_ELEMENTS_NUM) {
        return `details.connections.tabs.systems.description.title_first`;
      }
      return `details.connections.tabs.systems.description.title_all`;
    }

    return 'details.connections.tabs.systems.description.title_all';
  }, [systems]);

  useEffect(() => {
    if (!systems) {
      fetchData();
    }
  }, [dispatch, systems, fetchData]);

  return (
    <div>
      {systems && (
        <>
          <CustomText
            css={css`
              white-space: pre;
              display: flex;
              margin-top: 1.5rem;
              margin-bottom: 0.75rem;
              > span {
                margin-left: 0.5rem;
              }
            `}
            type="body-2"
            color={fonts.bodyLight}
          >
            {translate(getDescription(), { number: TAG_DETAILS_ELEMENTS_NUM })}
            {tagRef && description && (
              <CustomTag
                disableHoverEffect
                tag={{
                  ref: tagRef,
                  colour: newColour || colour || TAGS_DEFAULT_COLOUR,
                  tag: newDescription !== undefined ? newDescription : description,
                }}
              />
            )}
          </CustomText>
          {systems?.length && (
            <InternalLink
              type="body-2"
              to={`/org/${orgId}${ROUTE_PATHS.systems}/?include_disabled=true&search=tags%3A${tag?.tag}`}
              ellipsis={true}
              icon={
                <IconArrowRight
                  css={css`
                    margin-left: 0.125rem;
                  `}
                />
              }
            >
              {translate('details.connections.tabs.systems.all_attached')}
            </InternalLink>
          )}
          <List>
            {systems.slice(0, TAG_DETAILS_ELEMENTS_NUM).map(system => {
              const systemName = system.description || system.hostname || system.systemId;

              return (
                <ListItem tabIndex={0} key={system.systemId}>
                  <OSIcon
                    platformType={system.platformType as OSTypes}
                    css={css`
                      display: inline-flex;
                      margin-right: 1rem;
                    `}
                  />
                  <InternalLink
                    title={systemName}
                    type="body-2"
                    to={`/org/${orgId}${ROUTE_PATHS.systems}/${system.systemId}?include_disabled=true?`}
                    ellipsis={true}
                  >
                    {systemName}
                  </InternalLink>
                  <CustomText
                    type="body-2"
                    color={fonts.disabled}
                    css={css`
                      white-space: pre;
                    `}
                  >
                    {!system.isEnabled && ' ' + `(${translate('details.connections.disabled')})`}
                  </CustomText>
                </ListItem>
              );
            })}
          </List>
        </>
      )}
    </div>
  );
};
