// libs
import { css } from 'styled-components';

// hooks
import { useSelector, useDispatch } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';

// components
import { Tag } from './Tag';
import { CustomText } from 'typography/Text';
import { IconSettingsInvite } from 'assets/svg';
import { InviteActionDropdown, InviteActions } from './InviteActionDropdown';
import { InviteColumn, InviteRow, InvitesCountContainer } from '../styled';
import { UpgradePlanContainer, StyledUpgradeToBusinessIcon } from './styled';

// selectors
import {
  selectCurrentOrganisationHasAdminSlotsLeft,
  selectCurrentOrganisationInvites,
} from 'redux/selectors';

// types
import { IOrganisationInvite } from 'types';

// actions
import { cancelUserInvite, sendUserInvite } from 'redux/actions/organisation';

export const InviteRows = () => {
  const dispatch = useDispatch();

  const { translate } = useTranslations('settings');

  const invites = useSelector(selectCurrentOrganisationInvites);
  const hasAdminSlotsLeft = useSelector(selectCurrentOrganisationHasAdminSlotsLeft);

  const handleInviteAction = (
    action: InviteActions,
    email: IOrganisationInvite['emailAddress']
  ) => {
    if (action === 'cancel') {
      dispatch(cancelUserInvite({ emailAddress: email }));
      return;
    }
    if (action === 'resend') {
      dispatch(sendUserInvite({ emailAddress: email }));
      return;
    }
  };

  if (!hasAdminSlotsLeft)
    return (
      <UpgradePlanContainer>
        <StyledUpgradeToBusinessIcon />
        <CustomText type="body-2">{translate('invite_business_only')}</CustomText>
      </UpgradePlanContainer>
    );

  return (
    <>
      {invites.length > 0 && (
        <InvitesCountContainer>
          <CustomText type="sub-heading-1">
            {translate('pending_invites', { amount: invites.length })}
          </CustomText>
        </InvitesCountContainer>
      )}

      {invites.length > 0 &&
        invites.map(({ emailAddress }) => (
          <InviteRow key={emailAddress}>
            <InviteColumn col={1}>
              <IconSettingsInvite
                css={css`
                  margin-right: 1rem;
                `}
              />
              <CustomText type="body-1">{emailAddress}</CustomText>
            </InviteColumn>
            <InviteColumn col={2}>
              <Tag>{translate('pending')}</Tag>
            </InviteColumn>
            <InviteColumn col={3}>
              <InviteActionDropdown email={emailAddress} onActionClick={handleInviteAction} />
            </InviteColumn>
          </InviteRow>
        ))}
    </>
  );
};
