// libs
import { call, put, select } from 'typed-redux-saga';

// actions
import { setDnsZonesShouldUpdate } from 'redux/actions/dns';
import { setModalContent, setModalIsLoading, pushNotification } from 'redux/actions/app';

// selectors
import { selectDnsZones } from 'redux/selectors';

// yypes
import { IActionPayload, IDeleteDnsZoneProps, INotificationType } from 'types';

// utils
import { ApiTypeCaller } from '../../api/utils';
import { translator } from 'utils';
import { orgPush } from 'redux/sagas/utils';

// constants
import { DNS_DELETE_ZONE_ERROR_FIELD, ROUTE_PATHS } from 'appConstants';

export function* deleteZoneSaga({ payload: { zoneId } }: IActionPayload<IDeleteDnsZoneProps>) {
  yield put(setModalIsLoading(true));

  const translate = translator('notifications');

  const { result, success } = yield* ApiTypeCaller(
    'deleteDnsZone',
    { zoneId },
    { customErrorField: DNS_DELETE_ZONE_ERROR_FIELD }
  );

  const zones = yield* select(selectDnsZones);

  if (success && result) {
    const index = zones.findIndex(zone => Number(zoneId) === zone.id);
    let newIndex: number;

    if (index === 0) {
      newIndex = 1;
    } else {
      newIndex = index - 1;
    }
    const newSelectedZone = zones[newIndex];

    yield call(orgPush, `${ROUTE_PATHS.dns}/zones/${newSelectedZone.id}`, { keepSearch: true });
    yield put(setDnsZonesShouldUpdate());
    yield put(setModalContent({ data: null, type: null }));

    yield put(
      pushNotification({
        showType: INotificationType.Success,
        description: translate('dns_zone_removed', { zoneId: result.id, name: result.name }),
      })
    );
  }
  yield put(setModalIsLoading(false));
}
