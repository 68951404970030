// libs
import React, { forwardRef } from 'react';
import { css } from 'styled-components';

// components
import { Input as StyledInput, InputWrapper, TextDecorated, InputStc } from './styled';
import { IconInputSuccess, IconInputWarning, IconInputError } from 'assets/svg';
export { SpanStyledAsLargeInput } from './styled';
import { InputPassword } from '../InputPassword';
import { CustomText } from 'typography/Text';
import { Tooltip } from 'components';

// hooks
import { useTheme } from 'styled-components';

// types
import { TextTypes } from 'types';
import { InputProps } from 'antd/lib/input';
import { CSSProp } from 'styled-components';
import { SizeType } from './styled';
import { ITooltip } from 'components/Tooltip';

export interface MyInputProps extends InputProps {
  label?: string;
  mRight?: string;
  mTop?: string;
  error?: {
    message: string | React.ReactElement;
  } | null;
  warning?: string | React.ReactElement;
  suffixIcon?: React.ReactNode;
  desc?: string | React.ReactElement;
  showStateIcon?: boolean;
  textStyle?: TextTypes;
  width?: string;
  containerCss?: CSSProp;
  labelCss?: CSSProp;
  labelTextStyle?: TextTypes;
  stateMessageCss?: CSSProp;
  sharpborders?: boolean;
  'data-test'?: string;
  suffixMessage?: string;
  tooltipProps?: Partial<ITooltip>;
  size?: SizeType;
  additionalCss?: CSSProp;
  additionalHoverCss?: CSSProp;
  additionalFocusCss?: CSSProp;
}

export const Input = forwardRef<HTMLInputElement, MyInputProps>((props, ref) => {
  const { mTop, error, value, name, label, warning, placeholder, textStyle } = props;
  const { desc, mRight, maxLength, onChange, onBlur, type, color, width, onFocus } = props;
  const { size = 'large', disabled = false, autoComplete = 'off', showStateIcon = false } = props;
  const { containerCss, labelCss, stateMessageCss, labelTextStyle } = props;
  const { sharpborders, suffix, onKeyDown, suffixMessage, 'data-test': dataTest } = props;
  const { tooltipProps, additionalCss, additionalHoverCss, additionalFocusCss } = props;
  const { 'aria-label': ariaLabel, suffixIcon } = props;

  const {
    palette: { fonts, state },
  } = useTheme();

  return (
    <InputWrapper
      size={size}
      {...{ mRight, mTop, textStyle, width }}
      css={containerCss}
      data-test={dataTest}
      disabled={disabled}
      error={Boolean(error?.message)}
      warning={Boolean(warning)}
      additionalCss={additionalCss}
      additionalHoverCss={additionalHoverCss}
      additionalFocusCss={additionalFocusCss}
      className={Boolean(error?.message) ? 'error' : undefined}
    >
      {label && (
        <TextDecorated
          css={labelCss}
          type={labelTextStyle || 'sub-heading-2'}
          color={disabled ? fonts.disabled : fonts.bodyLight}
        >
          {label}
        </TextDecorated>
      )}
      {type === 'password' ? (
        <InputPassword
          {...{ disabled, placeholder, name, value, onFocus, $sharpborders: sharpborders }}
          {...{ size, type, onChange, onBlur, autoComplete, ref, color, onKeyDown }}
          $error={Boolean(error?.message)}
          $warning={Boolean(warning)}
          aria-label={ariaLabel}
        />
      ) : (
        <StyledInput
          {...{ disabled, placeholder, name, value, onFocus, $sharpborders: sharpborders }}
          {...{ size, type, onChange, onBlur, autoComplete, maxLength, ref, color }}
          {...{ onKeyDown }}
          aria-label={ariaLabel}
          suffix={
            suffix ||
            (showStateIcon ? (
              <Tooltip
                passedCss={css`
                  display: flex;
                `}
                placement="top"
                overlay={
                  suffixMessage ? (
                    <CustomText type="body-2" color={fonts.primaryButton}>
                      {suffixMessage}
                    </CustomText>
                  ) : null
                }
                {...tooltipProps}
              >
                {suffixIcon || (
                  <InputStc
                    as={
                      error?.message
                        ? IconInputError
                        : warning
                        ? IconInputWarning
                        : IconInputSuccess
                    }
                    size={size}
                  />
                )}
              </Tooltip>
            ) : (
              <></>
            ))
          }
        />
      )}

      <TextDecorated
        type="hint"
        color={disabled ? fonts.disabled : error ? state.error : fonts.bodyLight}
        css={stateMessageCss}
      >
        {error?.message ? error?.message : warning ? warning : desc ? desc : ''}
      </TextDecorated>
    </InputWrapper>
  );
});
