// libs
import { useRef, useEffect, useCallback, BaseSyntheticEvent } from 'react';
import { equals } from 'ramda';

// components
import { CustomButton } from 'components';
import { IconRandomize } from 'assets/svg';
import { buttonCss, inputContainerCss, inputCss, wrapperCss } from './styled';
import { Input } from 'components/Input/Default';

// hooks
import { useDispatch, useSelector } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';

// selectors
import {
  selectAppDetailsTabValidationStateEntries,
  selectAppDetailsTagNewColour,
} from 'redux/selectors';

// actions
import {
  addValidationStateEntry,
  updateTagDetails,
  updateValidationStateEntry,
} from 'redux/actions/app';

// types
import { TextAreaRef } from 'antd/lib/input/TextArea';

//constants
import { TAGS_DEFAULT_COLOUR, TAGS_PRESET_COLOURS } from 'appConstants/tags';

//utils
import { isHexColourValue } from 'utils/isHexColourValue';
import { getRandomArrayElement } from 'utils/getRandomArrayElement';

const titleErrorKey = 'tagColour';

export const ColourEdit = () => {
  const dispatch = useDispatch();

  const { translate } = useTranslations('tags');

  const newColour = useSelector(selectAppDetailsTagNewColour);

  const validationState = useSelector(
    selectAppDetailsTabValidationStateEntries([titleErrorKey]),
    equals
  );

  const ref = useRef<TextAreaRef>(null);

  useEffect(() => {
    dispatch(addValidationStateEntry({ key: titleErrorKey, state: { error: null } }));
  }, [dispatch]);

  const onColourChange = (event: BaseSyntheticEvent) => {
    const colour = event.target.value;

    const error =
      !colour.length || isHexColourValue(colour)
        ? null
        : { message: translate('details.invalid_colour_error') };

    if (
      validationState?.[titleErrorKey]?.state?.error !== error ||
      !validationState?.[titleErrorKey]?.updated
    ) {
      dispatch(updateValidationStateEntry({ key: titleErrorKey, state: { error } }));
    }

    dispatch(updateTagDetails({ colour }));
  };

  const getRandomTagColour = useCallback(() => {
    const colour: string = getRandomArrayElement(TAGS_PRESET_COLOURS);
    if (colour.toLowerCase() === newColour?.toLowerCase()) {
      getRandomTagColour();
    }
    return colour;
  }, [newColour]);

  const handleButtonClick = useCallback(() => {
    const randomColour = getRandomTagColour();
    dispatch(updateTagDetails({ colour: randomColour }));
  }, [dispatch, getRandomTagColour]);

  useEffect(() => {
    ref?.current?.focus();
  }, [ref]);

  return (
    <div css={wrapperCss}>
      <Input
        value={newColour || ''}
        id="tag-colour-input"
        placeholder={TAGS_DEFAULT_COLOUR}
        onBlur={onColourChange}
        onChange={onColourChange}
        containerCss={inputContainerCss}
        additionalCss={inputCss}
        error={validationState?.[titleErrorKey]?.state?.error}
        maxLength={7}
      />
      <CustomButton
        kind="secondary"
        title={translate('details.randomize_colour')}
        css={buttonCss}
        onClick={handleButtonClick}
      >
        <IconRandomize />
      </CustomButton>
    </div>
  );
};
