import styled from 'styled-components';

type BWProps = {
  mt?: string;
};
type CWProps = {
  justify?: string;
  align?: string;
  mb?: string;
};

export const SettingsWrapper = styled.div`
  margin: 0 auto 6.375rem;
  max-width: 72.625rem;
`;

export const BlockWrapper = styled.div<BWProps>`
  display: grid;
  grid-template-columns: 12.75rem auto;
  gap: 0 2rem;
  padding: 1.5rem;

  ${props => props.theme.media_queries.s1440} {
    grid-template-columns: 15.3125rem auto;
    gap: 0 2.875rem;
    padding: 2rem;
  }

  grid-template-rows: 1fr;
  grid-template-areas: '. .';
  max-width: 72.625rem;
  background-color: ${({ theme }) => theme.palette.background.panel};

  box-shadow: ${({ theme }) => theme.palette.elevationShadows[200]};
  border-radius: 0.25rem;
  margin-top: ${({ mt }) => mt || '1.5rem'};
`;

export const ContentWrapper = styled.div<CWProps>`
  display: flex;
  flex-direction: column;
  justify-content: ${({ justify }) => justify || 'space-around'};
  margin-bottom: ${({ mb }) => mb || 0};
  ${({ align }) => `align-items: ${align || ''}`};
`;
