import styled from 'styled-components';
import { Section } from '../styled';

export const SafeZoneSection = styled(Section)`
  margin-bottom: 1.5rem;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 1.5rem;
`;

export const Column = styled.div`
  :first-child {
    margin-right: 7.875rem;
  }
`;
