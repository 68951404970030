// libs
import { css, useTheme } from 'styled-components';

// components
import { IconArrowRight, List, ListItem } from './styled';
import { InternalLink } from 'components/Links/InternalLink';
import { ControllableIcon, CustomTag } from 'components';
import { IconSystemsDnsFromDirectAssignment } from 'assets/svg';
import { CustomText } from 'typography/Text';

// hooks
import { useSelector, useDispatch } from 'react-redux';
import { useCallback, useEffect } from 'react';
import { useTranslations } from 'hooks/useTranslations';

// selectors
import {
  selectAppDetailsTagData,
  selectAppDetailsTagDnsRecordItems,
  selectAppDetailsTagColour,
  selectAppDetailsTagDescription,
  selectAppDetailsTagNewColour,
  selectAppDetailsTagNewDescription,
  selectAppDetailsTagRef,
  selectCurrentOrganisationId,
} from 'redux/selectors';
import { extendTagDetails } from 'redux/actions/tags';

// constants
import { ROUTE_PATHS } from 'appConstants';
import { TAG_DETAILS_ELEMENTS_NUM, TAGS_DEFAULT_COLOUR } from 'appConstants/tags';

export const DnsRecordsTab = () => {
  const {
    palette: { fonts },
  } = useTheme();

  const { translate } = useTranslations('tags');
  const dispatch = useDispatch();

  const tag = useSelector(selectAppDetailsTagData);
  const colour = useSelector(selectAppDetailsTagColour);
  const newColour = useSelector(selectAppDetailsTagNewColour);
  const description = useSelector(selectAppDetailsTagDescription);
  const newDescription = useSelector(selectAppDetailsTagNewDescription);
  const tagRef = useSelector(selectAppDetailsTagRef);

  const dnsRecords = useSelector(selectAppDetailsTagDnsRecordItems);
  const orgId = useSelector(selectCurrentOrganisationId);

  const fetchData = useCallback(() => {
    dispatch(extendTagDetails({ tag: tag?.tag || '', module: 'dnsRecords' }));
  }, [dispatch, tag]);

  const getDescription = useCallback(() => {
    if (dnsRecords) {
      if (dnsRecords.length === 1) {
        return `details.connections.tabs.dnsRecords.description.title_one`;
      }
      if (dnsRecords.length > TAG_DETAILS_ELEMENTS_NUM) {
        return `details.connections.tabs.dnsRecords.description.title_first`;
      }
      return `details.connections.tabs.dnsRecords.description.title_all`;
    }

    return 'details.connections.tabs.dnsRecords.description.title_all';
  }, [dnsRecords]);

  useEffect(() => {
    if (!dnsRecords) {
      fetchData();
    }
  }, [dispatch, dnsRecords, fetchData]);

  return (
    <div>
      <div>
        {dnsRecords && (
          <>
            <CustomText
              css={css`
                white-space: pre;
                display: flex;
                margin-top: 1.5rem;
                margin-bottom: 0.75rem;
                > span {
                  margin-left: 0.5rem;
                }
              `}
              type="body-2"
              color={fonts.bodyLight}
            >
              {translate(getDescription(), { number: TAG_DETAILS_ELEMENTS_NUM })}
              {tagRef && description && (
                <CustomTag
                  disableHoverEffect
                  tag={{
                    ref: tagRef,
                    colour: newColour || colour || TAGS_DEFAULT_COLOUR,
                    tag: newDescription !== undefined ? newDescription : description,
                  }}
                />
              )}
            </CustomText>
            {dnsRecords?.length && (
              <InternalLink
                type="body-2"
                to={`/org/${orgId}${ROUTE_PATHS.dns}/zones/?search=tags%3A${tag?.tag}`}
                ellipsis={true}
                icon={
                  <IconArrowRight
                    css={css`
                      margin-left: 0.125rem;
                    `}
                  />
                }
              >
                {translate('details.connections.tabs.dnsRecords.all_attached')}
              </InternalLink>
            )}
            <List>
              {dnsRecords.slice(0, TAG_DETAILS_ELEMENTS_NUM).map(record => {
                const recordName = record.name;

                return (
                  <ListItem tabIndex={0} key={record.id}>
                    <ControllableIcon
                      icon={<IconSystemsDnsFromDirectAssignment />}
                      size="medium"
                      css={css`
                        margin-right: 0.75rem;
                      `}
                    />
                    <InternalLink
                      title={recordName}
                      type="body-2"
                      to={`/org/${orgId}${ROUTE_PATHS.dns}/zones/${record.zoneId}/record/${record.id}`}
                      ellipsis={true}
                    >
                      {recordName}
                    </InternalLink>
                  </ListItem>
                );
              })}
            </List>
          </>
        )}
      </div>
    </div>
  );
};
