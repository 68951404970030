// components
import { CustomText } from 'typography/Text';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { css, useTheme } from 'styled-components';
import { useSelector } from 'react-redux';

// selectors
import { selectAppDetailsPolicyAutoExpireAction, selectAppDetailsTabType } from 'redux/selectors';

const Description = () => {
  const { translate } = useTranslations('policies');

  const type = useSelector(selectAppDetailsTabType);
  const expiryAction = useSelector(selectAppDetailsPolicyAutoExpireAction);

  const {
    palette: { fonts },
  } = useTheme();

  return (
    <CustomText
      data-test="policy-details-auto-expire-description"
      type="body-2"
      color={fonts.bodyLight}
      css={css`
        margin: 0 0 1rem 2rem;
      `}
    >
      {(type === 'policy-edit' || type === 'policy-create') &&
        translate('details.auto_expire.description_edit')}
      {type === 'policy-view' &&
        expiryAction === 'Delete' &&
        translate('details.auto_expire.description_view.remove')}
      {type === 'policy-view' &&
        expiryAction === 'Disable' &&
        translate('details.auto_expire.description_view.disable')}
    </CustomText>
  );
};

export default Description;
