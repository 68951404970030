// libs
import { css, useTheme } from 'styled-components';

// components
import { CustomText } from 'typography/Text';

interface IZoneName {
  isSelected: boolean;
  children: string;
}

export const ZoneName = ({ children, isSelected, ...rest }: IZoneName) => {
  const {
    palette: { fonts },
  } = useTheme();

  return (
    <CustomText
      css={css`
        max-width: 10.625rem;
      `}
      ellipsis={true}
      type={isSelected ? 'sub-heading-1' : 'body-1'}
      color={isSelected ? fonts.title : fonts.bodyDark}
      title={children}
      {...rest}
    >
      {children}
    </CustomText>
  );
};
