import { useEffect, useState } from 'react';

type Props = {
  copyingValue: string;
  onCopyCode?: (value: string) => void;
};

export const useCopy = ({ copyingValue, onCopyCode }: Props) => {
  const [copied, setCopied] = useState(false);
  const [copyTimeout, setCopyTimeout] = useState<ReturnType<typeof setTimeout>>();

  const onCopyClick = () => {
    navigator.clipboard.writeText(copyingValue);
    onCopyCode?.(copyingValue);
    setCopied(true);

    if (copyTimeout) {
      clearTimeout(copyTimeout);
    }

    setCopyTimeout(
      setTimeout(() => {
        setCopied(false);
      }, 5000)
    );
  };

  useEffect(() => {
    return () => {
      copyTimeout && clearTimeout(copyTimeout);
    };
  }, [copyTimeout]);

  return { onCopyClick, copied, setCopied };
};
