import { Modal } from 'antd';
import { ModalProps } from 'antd/lib/modal';
import { useDispatch } from 'react-redux';
import { setModalContent } from 'redux/actions/app';
import { css } from 'styled-components';

interface ICustomModal extends ModalProps {
  children: React.ReactNode;
}

export const CustomModal = ({ visible, children }: ICustomModal) => {
  const dispatch = useDispatch();
  const onClose = () => {
    dispatch(setModalContent({ type: null, data: null }));
  };
  return (
    <Modal
      title={null}
      centered
      keyboard
      maskClosable
      destroyOnClose
      onCancel={onClose}
      visible={visible}
      width="42rem"
      footer={null}
      closable={false}
      css={css`
        // setting max-height can help to handle margins from the modal to viewport

        .ant-modal-content {
          border-radius: 0.25rem;
          box-shadow: ${({ theme }) => theme.palette.elevationShadows[600]};
        }

        .ant-modal-body {
          // paddings are assigned to overwrite antd padding
          padding: 0;
        }
      `}
    >
      {children}
    </Modal>
  );
};
