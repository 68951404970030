import styled from 'styled-components';

export const DnsContent = styled.div`
  margin-top: 0.5rem;
  margin-left: 2rem;
`;

export const ListContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const ListElementContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 0.0625rem solid ${({ theme }) => theme.palette.background.iconBackground};
`;

export const StateDescription = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
