// Lib
import { createReducer } from '@reduxjs/toolkit';

// Types
import { ISecurityEventsStore, IGetSecurityEventsResponse } from 'types';

// Actions
import { setSecurityEvents, setIsLoading } from '../actions/security-events';

export const initialSecurityEventsResponse: IGetSecurityEventsResponse = {
  items: [],
  metadata: {
    total: 0,
    firstPage: 0,
    prevPage: null,
    lastPage: 0,
    nextPage: null,
  },
  links: {
    first: '',
    prev: null,
    next: null,
    last: '',
  },
};

const initialState: ISecurityEventsStore = {
  ...initialSecurityEventsResponse,
  isLoading: true,
};

export const securityEventsReducer = createReducer(initialState, builder => {
  builder
    .addCase(setSecurityEvents, (state, { payload }) => {
      return {
        ...state,
        ...payload,
        isLoading: false,
      };
    })
    .addCase(setIsLoading, (state, { payload }) => {
      state.isLoading = payload;
    });
});
