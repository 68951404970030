// libs
import { takeEvery, takeLatest } from 'redux-saga/effects';

// actions
import {
  saveSystemDetails,
  enableSystems,
  disableSystems,
  enableSystem,
  disableSystem,
  revokeSystem,
  revokeSystems,
  getSystems,
  getSystemSearchKeys,
  enableUntilSystem,
} from 'redux/actions/systems';

// sagas
import { updateSystemSaga } from './updateSystemSaga';
import { enableDisableSystemSaga } from './enableDisableSystemSaga';
import { enableDisableSystemsSaga } from './enableDisableSystemsSaga';
import { revokeSystemSaga } from './revokeSystemSaga';
import { revokeSystemsSaga } from './revokeSystemsSaga';
import { getSystemsSaga } from './getSystemsSaga';
import { getSystemSearchKeysSaga } from './getSystemSearchKeysSaga';
import { enableUntilSystemSaga } from './enableUntilSystemSaga';

function* systemsSaga() {
  yield takeEvery(enableSystem, payload => enableDisableSystemSaga(payload, true));
  yield takeEvery(enableUntilSystem, enableUntilSystemSaga);
  yield takeEvery(disableSystem, payload => enableDisableSystemSaga(payload, false));
  yield takeEvery(enableSystems, payload => enableDisableSystemsSaga(payload, true));
  yield takeEvery(disableSystems, payload => enableDisableSystemsSaga(payload, false));
  yield takeEvery(saveSystemDetails, updateSystemSaga);
  yield takeEvery(revokeSystem, revokeSystemSaga);
  yield takeEvery(revokeSystems, revokeSystemsSaga);
  yield takeEvery(getSystemSearchKeys, getSystemSearchKeysSaga);
  yield takeLatest(getSystems, getSystemsSaga);
}

export default systemsSaga;
