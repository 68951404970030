import styled from 'styled-components';
import { stylesMap } from 'typography/Text';

type IState = {
  error?: boolean;
};

export const Suffix = styled.div`
  position: absolute;
  right: 0;
  top: 0.25rem;

  width: 1.5rem;
  height: 1.5rem;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 2.5rem;
  width: 100%;
  padding-right: 0.25rem;
  margin: 0 -0.0625rem;
`;

export const InputWrapper = styled.div<IState>`
  position: relative;
  width: 100%;

  ${Suffix} path {
    stroke: ${({ theme, error }) => !error && theme.palette.icons.disabled} !important;
  }

  textarea {
    word-break: break-all;

    border-color: transparent !important;
    border-width: 0.0625rem;
    border-style: solid;
    border-bottom-color: ${({ theme, error }) =>
      `${error ? theme.palette.state.error : theme.palette.background.sections}
     `} !important;

    box-shadow: ${({ theme }) => theme.palette.elevationShadows[100]} !important;
    clip-path: inset(0 0.0625rem -0.25rem 0.0625rem);
    border-radius: initial;

    overflow: hidden;

    padding-top: 0;
    padding-right: 2.125rem;
    padding-bottom: 0;
    padding-left: 0;

    min-height: 2.25rem !important;
    ${stylesMap['heading-2']}
    color: ${({ theme }) => theme.palette.fonts.title};

    ::placeholder {
      ${stylesMap['heading-2']}
      color: ${({ theme }) => theme.palette.fonts.disabled};
    }
  }

  :hover {
    textarea {
      border-bottom-color: ${({ theme, error }) =>
        `${error ? theme.palette.state.error : theme.palette.fills.stroke}
     `} !important;
    }
    ${Suffix} path {
      stroke: ${({ theme, error }) => !error && theme.palette.fills.stroke} !important;
    }
  }

  :focus-within {
    textarea {
      border-bottom-color: ${({ theme, error }) =>
        `${error ? theme.palette.state.error : theme.palette.fills.primary}
     `} !important;
    }
    ${Suffix} path {
      stroke: ${({ theme, error }) => !error && theme.palette.fills.primary} !important;
    }
  }
`;
