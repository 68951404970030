// components
import { ColumnNameText, DropdownKeyButton } from './styled';
import { CustomText } from 'typography/Text';

// hooks
import { css, useTheme } from 'styled-components';

// types
import { ISearchKey, TypeWithDefaultButtonProps } from 'types';

interface IDrpodownKey {
  searchKey: ISearchKey;
  isSelected?: boolean;
}

export const DropdownKey = ({
  searchKey,
  isSelected,
  ...rest
}: TypeWithDefaultButtonProps<IDrpodownKey>) => {
  const {
    palette: { fonts },
  } = useTheme();

  return (
    <DropdownKeyButton isActive={false} isSelected={isSelected} {...rest}>
      <ColumnNameText type="body-2" color={fonts.bodyLight}>
        {searchKey.name}
      </ColumnNameText>
      <CustomText
        css={css`
          display: block;
        `}
        type="body-2"
        color={fonts.help}
      >
        {searchKey.hintText}
      </CustomText>
    </DropdownKeyButton>
  );
};
