// libs
import { useTheme } from 'styled-components';

// components
import { ViewContainer } from './styled';
import { CustomText } from 'typography/Text';

// hooks
import { useSelector } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';

// selectors
import { selectAppDetailsPolicyEndTime, selectAppDetailsPolicyStartTime } from 'redux/selectors';

// utils
import { formTimeString } from 'utils';
import { checkIfNextDay } from '../helpers';

export const ActiveHoursView = () => {
  const { translate } = useTranslations('policies');

  const {
    palette: { fonts },
  } = useTheme();

  const selectedFrom = useSelector(selectAppDetailsPolicyStartTime);
  const selectedTo = useSelector(selectAppDetailsPolicyEndTime);

  return (
    <ViewContainer data-test="policy-details-active-hours-view">
      <CustomText type="body-2" color={fonts.bodyDark}>
        {selectedFrom && formTimeString(selectedFrom.hours, selectedFrom.minutes)}{' '}
        {translate('details.time_limitations.until')}{' '}
        {selectedTo && formTimeString(selectedTo.hours, selectedTo.minutes)}
        {selectedFrom && selectedTo && checkIfNextDay(selectedTo, selectedFrom)
          ? ' ' + translate('details.time_limitations.active_hours.next_day.view')
          : ''}
      </CustomText>
    </ViewContainer>
  );
};
