// Hooks
import { useTranslations } from 'hooks/useTranslations';
import { useSelector } from 'react-redux';

// Selectors
import { selectSelectedEnrolmentKey } from 'redux/selectors';

// Components
import { StyledItem, EnrolmentKeySelect, GoToSystems } from '../components';
import { StyledCommandLineCode, StyledCodeText } from '../styled';
import { Trans } from 'react-i18next';

export const Docker = () => {
  const { translate } = useTranslations('enrol-new-system');
  const selectedKey = useSelector(selectSelectedEnrolmentKey);

  return (
    <>
      <StyledItem
        title={
          <Trans
            i18nKey={translate('docker.steps.1.title')}
            components={[<StyledCodeText key={0} text="docker-compose.yml" />]}
          />
        }
        content={
          <StyledCommandLineCode
            code={`version: '3.1'

services:
  enclave-fabric:
    container_name: fabric
    image: enclavenetworks/enclave:latest
    restart: always

    cap_add:
      - NET_ADMIN
    devices:
      - /dev/net/tun
    environment:
      ENCLAVE_ENROLMENT_KEY: XXXXX-XXXXX-XXXXX-XXXXX-XXXXX
    volumes:
      - enclave-config:/etc/enclave/profiles
      - enclave-logs:/var/log/enclave

volumes:
  enclave-config:
  enclave-logs:`}
          />
        }
      />
      <EnrolmentKeySelect alternativeTitle={translate('docker.steps.2.title')} />
      <StyledItem
        title={
          <Trans
            i18nKey={translate('docker.steps.3.title')}
            components={[
              <StyledCodeText key={0} text="XXXXX-XXXXX-XXXXX-XXXXX-XXXXX" />,
              <StyledCodeText
                key={1}
                text={selectedKey ? selectedKey.key : translate('docker.steps.3.key')}
              />,
            ]}
          />
        }
      />
      <StyledItem
        title={
          <>
            {translate('docker.steps.4.title')} <StyledCodeText text="docker-compose up -d" />
          </>
        }
      />
      <StyledItem
        title={
          <>
            {translate('docker.steps.5.title')}{' '}
            <StyledCodeText text="docker exec fabric enclave status" />
          </>
        }
      />
      <GoToSystems />
    </>
  );
};
