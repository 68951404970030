export const checkIfNextDay = (
  time: {
    hours: number;
    minutes: number;
  },
  compareTime: { hours: number; minutes: number }
) => {
  if (time.hours < compareTime.hours) {
    return true;
  }

  if (time.hours === compareTime.hours) {
    if (time.minutes < compareTime.minutes) {
      return true;
    }
  }

  return false;
};
