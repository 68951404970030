export const getOrgIdFromUrl = (pathname: string) => {
  let urlOrg = null;

  if (pathname.includes('org/')) {
    const urlArray = pathname.split('/');
    for (let i = 0; i < urlArray.length; i++) {
      if (urlArray[i] === 'org' && urlArray[i + 1]) urlOrg = urlArray[i + 1];
    }
  }

  return urlOrg;
};
