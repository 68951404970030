// sagas
import { reLoginSaga } from 'redux/sagas/business/user/reLoginSaga';
import { closeDetailsOnModuleChangeSaga } from './closeDetailsOnModuleChangeSaga';
import { redirectToOrganisationSaga } from './redirectToOrganisationSaga';
import { handleGoBackSaga } from './handleGoBackSaga';
import { switchOrgOnUrlChangeSaga } from './switchOrgOnUrlChangeSaga';

export function* locationChangeSaga() {
  yield handleGoBackSaga();
  yield closeDetailsOnModuleChangeSaga();
  yield redirectToOrganisationSaga();
  yield reLoginSaga();
  yield switchOrgOnUrlChangeSaga();
}
