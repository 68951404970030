import { CustomText } from 'typography/Text';
import { useTheme } from 'styled-components';

interface IIpRange {
  children: React.ReactNode;
}

export const IpRange = ({ children, ...rest }: IIpRange) => {
  const {
    palette: { fonts },
  } = useTheme();

  return (
    <CustomText
      data-test="trust-requirement-details-ip-range"
      type="body-2"
      color={fonts.title}
      {...rest}
    >
      {children}
    </CustomText>
  );
};
