// hooks
import { useMemo } from 'react';

// types
import { IData } from './useTrialBannerData';
import { OrganisationPropertiesModel } from 'types';

interface IUseIsDismissed {
  orgId: OrganisationPropertiesModel['id'];
  data: IData | null;
  isDashboard: boolean;
  localStorageDismissedState: Record<string, string>;
}

export const useIsDismissed = ({
  isDashboard,
  data,
  orgId,
  localStorageDismissedState,
}: IUseIsDismissed) => {
  const isDismissed = useMemo(() => {
    return data && data.id === localStorageDismissedState?.[orgId] && !isDashboard;
  }, [data, isDashboard, localStorageDismissedState, orgId]);

  return isDismissed;
};
