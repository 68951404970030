// libs
import React, { FC, useCallback } from 'react';
import { css, useTheme } from 'styled-components';

// components
import { CustomText } from 'typography/Text';
import { CustomToggle } from 'components/CustomToggle';
import { HeadingWrapper } from './styled';

// hooks
import { useTranslations } from 'hooks/useTranslations';

// types
import { TypeWithDefaultDivProps } from 'types';

interface ISectionHeader {
  Icon: FC;
  title: string;
  info?: React.ReactElement | null;
  switchConfig?: { value: boolean; setValue: React.Dispatch<React.SetStateAction<boolean>> };
}

export const DetailsSectionHeader = ({
  Icon,
  title,
  info: InfoIcon,
  switchConfig,
  ...rest
}: TypeWithDefaultDivProps<ISectionHeader>) => {
  const { translate } = useTranslations('global');

  const {
    palette: { fonts },
  } = useTheme();

  const onClick = useCallback(() => {
    switchConfig?.setValue(value => !value);
  }, [switchConfig]);

  return (
    <HeadingWrapper {...rest}>
      <Icon />
      <CustomText
        type="label-1"
        color={fonts.title}
        css={css`
          margin-left: 0.5rem;
        `}
      >
        {title}
      </CustomText>
      {InfoIcon && InfoIcon}
      {switchConfig && (
        <CustomToggle
          data-test="section-header-toggle"
          containerCss={css`
            position: absolute;
            right: 0;
          `}
          aria-label={translate('details_pane.aria_labels.switch_state')}
          enabled={switchConfig.value}
          onClick={onClick}
        />
      )}
    </HeadingWrapper>
  );
};
