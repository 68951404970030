import { Address4 } from 'ip-address';
import { BigInteger } from 'jsbn';

// types
import { IValidationStateValue } from 'types';

// utils
import { translator } from 'utils';

export const isIpValid = (ipRange: string) => {
  const parsedIpRange = ipRange.replaceAll(' ', '');

  if (parsedIpRange.indexOf('-') !== -1) {
    if (parsedIpRange.indexOf('/') !== -1) {
      return false;
    }

    const split = parsedIpRange.split('-');

    if (split.length != 2) {
      return false;
    }

    if (!split[0] || !split[1]) {
      return false;
    }

    try {
      const leftAddress = new Address4(split[0]);
      const rightAddress = new Address4(split[1]);

      return rightAddress.bigInteger().compareTo(leftAddress.bigInteger()) >= 0;
    } catch (error) {
      return false;
    }
  } else if (parsedIpRange.indexOf('/') !== -1) {
    try {
      const ipAddress = new Address4(parsedIpRange);

      if (ipAddress.subnetMask < 0 || ipAddress.subnetMask > 32) {
        return false;
      }
      return true;
    } catch (error) {
      return false;
    }
  } else {
    try {
      new Address4(parsedIpRange);
      return true;
    } catch (error) {
      return false;
    }
  }
};

export type IpRange = {
  ipRange: string;
  start: BigInteger;
  end: BigInteger;
  subnet: Address4 | null;
};

export const getIpRange = (ipRange: string): IpRange | null => {
  const parsedIpRange = ipRange.replaceAll(' ', '');

  if (parsedIpRange.indexOf('-') !== -1) {
    if (parsedIpRange.indexOf('/') !== -1) {
      return null;
    }

    const split = parsedIpRange.split('-');

    if (split.length != 2) {
      return null;
    }

    if (!split[0] || !split[1]) {
      return null;
    }

    try {
      const leftAddress = new Address4(split[0]);
      const rightAddress = new Address4(split[1]);

      return {
        ipRange,
        start: leftAddress.bigInteger(),
        end: rightAddress.bigInteger(),
        subnet: null,
      };
    } catch (error) {
      return null;
    }
  } else if (parsedIpRange.indexOf('/') !== -1) {
    try {
      const subnet = new Address4(parsedIpRange);

      if (subnet.subnetMask < 0 || subnet.subnetMask > 32) {
        return null;
      }
      return {
        ipRange,
        start: subnet.startAddress().bigInteger(),
        end: subnet.endAddress().bigInteger(),
        subnet: subnet,
      };
    } catch (error) {
      return null;
    }
  } else {
    try {
      const address = new Address4(parsedIpRange);
      const addressNumeric = address.bigInteger();

      return { ipRange, start: addressNumeric, end: addressNumeric, subnet: null };
    } catch (error) {
      return null;
    }
  }
};

export const isSubnetValid = (subnet: string) => {
  try {
    const address = new Address4(subnet);

    if (address.parsedSubnet) {
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const validateIpRangeString = ({ ipRange }: { ipRange: string }): IValidationStateValue => {
  const translate = translator('global');

  if (!isIpValid(ipRange)) {
    return { error: { message: translate('ip_range_error') } };
  }

  return { error: null };
};
