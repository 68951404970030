// libs
import { createContext, useEffect, useState } from 'react';

// components
import { LocationsAccessContent } from './Access/Content';
import { LocationsAccessEdit } from './Access/Edit';
import { LocationsCountriesContent } from './Countries/Content';
import { LocationsCountriesEdit } from './Countries/Edit';

// hooks
import { useDispatch, useSelector } from 'react-redux';

// selectors
import { selectAppDetailsTrustRequirementCurrentLocationsIsBlocked } from 'redux/selectors';

// actions
import { updateTrustRequirementDetailsPublicIpLocationConditionIsBlocked } from 'redux/actions/app';

// constants
import { PUBLIC_IP_CONDITION_LOCATION_IS_BLOCKED_DEFAULT } from 'appConstants';

// types
import { ITrustRequirementPublicIpLocationConditionModified } from 'types';

interface IContext {
  isBlocked: ITrustRequirementPublicIpLocationConditionModified['isBlocked'];
  setIsBlocked: (
    isBlocked: ITrustRequirementPublicIpLocationConditionModified['isBlocked']
  ) => void;
}
export const IsBlockedContext = createContext<IContext>({
  isBlocked: PUBLIC_IP_CONDITION_LOCATION_IS_BLOCKED_DEFAULT,
  setIsBlocked: () => {},
});

export const EditContent = () => {
  const dispatch = useDispatch();

  const isBlockedSelect = useSelector(selectAppDetailsTrustRequirementCurrentLocationsIsBlocked);

  const [isBlocked, setIsBlocked] = useState(
    isBlockedSelect || PUBLIC_IP_CONDITION_LOCATION_IS_BLOCKED_DEFAULT
  );

  const value = { isBlocked, setIsBlocked };

  useEffect(() => {
    dispatch(updateTrustRequirementDetailsPublicIpLocationConditionIsBlocked({ isBlocked }));
  }, [dispatch, isBlocked]);

  return (
    <IsBlockedContext.Provider value={value}>
      <LocationsAccessContent>
        <LocationsAccessEdit />
      </LocationsAccessContent>
      <LocationsCountriesContent edit={true}>
        <LocationsCountriesEdit />
      </LocationsCountriesContent>
    </IsBlockedContext.Provider>
  );
};
