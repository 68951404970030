// components
import { ExternalLink } from 'components';
import { CustomText } from 'typography/Text';
import { NotificationWrapper, NotificationIcon } from '../styled';
import { Trans } from 'react-i18next';

// hooks
import { useTheme } from 'styled-components';
import { useTranslations } from 'hooks/useTranslations';

// constants
import { LINK_TO_MESH_DOCS } from 'appConstants';

interface IFullMeshNotification {
  mode: 'view' | 'create';
}

export const FullMeshNotification = ({ mode }: IFullMeshNotification) => {
  const { translate } = useTranslations('policies');

  const {
    palette: { fonts },
  } = useTheme();

  return (
    <div>
      <NotificationWrapper>
        <NotificationIcon />
        <CustomText type="hint" color={fonts.bodyLight}>
          <Trans
            i18nKey={
              mode === 'view' ? translate('details.mesh_created') : translate('details.mesh_create')
            }
            components={[
              <ExternalLink key={0} href={LINK_TO_MESH_DOCS} text={translate('details.docs')} />,
            ]}
          />
        </CustomText>
      </NotificationWrapper>
    </div>
  );
};
