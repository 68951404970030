// components
import { Details } from 'components';
import { StateView } from './View';
import { StateEdit } from './Edit';

// hooks
import { useSelector } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';

// selectors
import { selectAppDetailsTabType } from 'redux/selectors';

export const StateBlock = () => {
  const { translate } = useTranslations('policies');

  const type = useSelector(selectAppDetailsTabType);

  return (
    <Details.KeyValue height="2.875rem" title={translate('details.state')}>
      {type === 'policy-view' && <StateView />}
      {(type === 'policy-edit' || type == 'policy-create') && <StateEdit />}
    </Details.KeyValue>
  );
};
