import { createAction } from '@reduxjs/toolkit';

import {
  IGetUnapprovedSystemsParams,
  IDeclineUnapprovedSystemProps,
  IGetUnapprovedSystemsResponse,
  IUnapprovedSystem,
  IDeclineUnapprovedSystemsParams,
  IApproveUnapprovedSystemProps,
  IApproveUnapprovedSystemsParams,
  ISearchKey,
} from 'types';

export const getSystems = createAction<IGetUnapprovedSystemsParams>(
  'unapproved-systems/GET_SYSTEMS'
);
export const setSystems = createAction<IGetUnapprovedSystemsResponse>(
  'unapproved-systems/SET_SYSTEMS'
);
export const setSystem = createAction<IUnapprovedSystem>('unapproved-systems/SET_SYSTEM');
export const declineSystem = createAction<IDeclineUnapprovedSystemProps>(
  'unapproved-systems/DECLINE_SYSTEM'
);
export const declineSystemFromDetails = createAction<IDeclineUnapprovedSystemProps>(
  'unapproved-systems/DECLINE_SYSTEM_FROM_DETAILS'
);
export const declineSystems = createAction<IDeclineUnapprovedSystemsParams>(
  'unapproved-systems/DECLINE_SYSTEMS'
);
export const removeSystems = createAction<string[]>('unapproved-systems/REMOVE_SYSTEMS');
export const approveSystem = createAction<IApproveUnapprovedSystemProps>(
  'unapproved-systems/APPROVE_SYSTEM'
);
export const approveSystemFromDetails = createAction<IApproveUnapprovedSystemProps>(
  'unapproved-systems/APPROVE_SYSTEM_FROM_DETAILS'
);
export const approveSystems = createAction<IApproveUnapprovedSystemsParams>(
  'unapproved-systems/APPROVE_SYSTEMS'
);
export const setIsLoading = createAction<boolean>('unapproved-systems/SET_IS_LOADING');
export const saveSystemDetails = createAction('unapproved-systems/SAVE_SYSTEM_DETAILS');

export const setSystemsShouldUpdate = createAction('unapproved-systems/SHOULD_UPDATE');

export const getSystemSearchKeys = createAction('unapproved-systems/GET_SEARCH_KEYS');
export const setSystemSearchKeys = createAction<ISearchKey[]>('unapproved-systems/SET_SEARCH_KEYS');
