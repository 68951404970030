// hooks
import React, { useState, useEffect, useCallback } from 'react';

// types
import { ISearchKey } from 'types';
import { IActiveKey, ISearchQueryDetails } from '../helpers';

interface IUseKeyboardControl {
  searchKeys: ISearchKey[] | null;
  activeKey: IActiveKey | null;
  keyConfig: ISearchQueryDetails | null;
  dropdownKeyOnClick: () => void;
  dropdownKeyOnMouseDown: (name: string) => () => void;
  dropdownRef: React.RefObject<HTMLDivElement>;
  setDropdownIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export type IInputArrowConfig = {
  length: number;
  onEnter: () => void;
  isKeyInactive: boolean;
} | null;

export type ISelectedIndex = null | number;

export const useKeyboardControl = ({
  searchKeys,
  activeKey,
  keyConfig,
  dropdownKeyOnClick,
  dropdownKeyOnMouseDown,
  dropdownRef,
  setDropdownIsOpen,
}: IUseKeyboardControl) => {
  const [inputArrowNavigationConfig, setInputArrowNavigationConfig] =
    useState<IInputArrowConfig>(null);

  const [selectedIndex, setSelectedIndex] = useState<ISelectedIndex>(null);

  const keyboardControl = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (inputArrowNavigationConfig) {
        if (e.key === 'Escape') {
          setDropdownIsOpen(false);
        }

        if (e.key !== 'Escape') {
          setDropdownIsOpen(true);
        }

        if (e.key === 'ArrowUp') {
          e.preventDefault();
          setSelectedIndex(selectedIndex => {
            // we start from index 1 to exclude default key
            if (
              selectedIndex === null ||
              selectedIndex === (inputArrowNavigationConfig.isKeyInactive ? 1 : 0)
            ) {
              return inputArrowNavigationConfig.length - 1;
            } else {
              return selectedIndex - 1;
            }
          });
        }

        if (e.key === 'ArrowDown') {
          e.preventDefault();
          setSelectedIndex(selectedIndex => {
            if (selectedIndex === null || selectedIndex === inputArrowNavigationConfig.length - 1) {
              return inputArrowNavigationConfig.isKeyInactive ? 1 : 0;
            } else {
              return selectedIndex + 1;
            }
          });
        }

        if (e.key === 'Enter') {
          if (selectedIndex !== null) {
            inputArrowNavigationConfig.onEnter();

            Promise.resolve(() => {
              if (selectedIndex === inputArrowNavigationConfig.length - 1) {
                setSelectedIndex(inputArrowNavigationConfig.length - 2);
              }
            });
            return;
          }

          setDropdownIsOpen(false);
        }
      }
    },
    [inputArrowNavigationConfig, selectedIndex, setDropdownIsOpen]
  );

  const isActiveKeyNull = activeKey === null;
  const isSearchKeysKeyNull = searchKeys === null;

  // if we are not focused on particular search key
  // we need to navigatite through search key selection
  useEffect(() => {
    if (!activeKey && searchKeys) {
      setInputArrowNavigationConfig({
        length: searchKeys.length,
        onEnter: () => {
          if (selectedIndex !== null) {
            dropdownKeyOnMouseDown(searchKeys[selectedIndex].name)();
            dropdownKeyOnClick();
          }
        },
        isKeyInactive: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActiveKeyNull, dropdownKeyOnClick, isSearchKeysKeyNull, selectedIndex]);

  // we need to clean selected index when searchModifier changes
  const searchModifier =
    keyConfig && activeKey && keyConfig[activeKey.searchKey.name]?.['searchModifier'];

  useEffect(() => {
    setSelectedIndex(null);
  }, [activeKey?.searchKey.name, activeKey?.modifiedValue?.value, searchModifier]);

  useEffect(() => {
    if (inputArrowNavigationConfig && selectedIndex !== null) {
      if (selectedIndex > inputArrowNavigationConfig.length - 2) {
        setSelectedIndex(
          inputArrowNavigationConfig.length !== 0 ? inputArrowNavigationConfig.length - 1 : null
        );
      }
    }
  }, [keyboardControl, inputArrowNavigationConfig, selectedIndex]);

  useEffect(() => {
    if (selectedIndex !== null) {
      const target = dropdownRef.current?.querySelectorAll('button')[selectedIndex];

      if (target) {
        // if is not visible and at the bottom
        if (
          target.offsetTop + target.offsetHeight >=
          dropdownRef.current.offsetHeight + dropdownRef.current.scrollTop
        ) {
          target.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }

        // if is not visible and at the top
        if (target.offsetTop - target.offsetHeight <= dropdownRef.current.scrollTop) {
          target.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
      }
    }
  }, [dropdownRef, selectedIndex]);

  return { keyboardControl, selectedIndex, setInputArrowNavigationConfig };
};
