// libs
import { put, call, select, all } from 'typed-redux-saga';
import { apiService } from 'api/ApiService';

import { INotificationConfig, INotificationType, ModuleName } from 'types';

// actions
import { setFormErrors, pushNotification } from 'redux/actions/app';

// selectors
import { selectPathName } from 'redux/selectors';

// constants
import { DETAILS_FORM_ERRORS, ROUTE_PATHS } from 'appConstants';

// utils
import { mapError } from 'api/responseHandlers';
import { hasJsonParsingError, translator } from 'utils';
import { dissoc } from 'ramda';

function* unauthorizedErrorSaga(error: any) {
  // Unauthorized error
  if (error.text === 'Unauthorized' || error.title === 'Unauthorized') {
    yield* call([apiService, apiService.login]);
    return true;
  }
  return false;
}

function* jsonParsingErrorSaga(error: any) {
  const translate = translator('notifications');

  // The JSON value could not be converted Error
  if (error.status === 400 && hasJsonParsingError(error?.errors)) {
    yield* put(
      pushNotification({
        showType: INotificationType.Error,
        description: translate('400_json_parsing_error'),
      })
    );
    return true;
  }
  return false;
}

function* validationFailure(error: any) {
  if (error.status === 400 && error?.type.endsWith('validation-failure')) {
    const pathName: string = yield* select(selectPathName);
    const errorFields: string[] = Object.keys(error.errors);

    const consumedErrors: any = { ...dissoc('errors', error), errors: {} };
    const notConsumedErrors: any = { ...dissoc('errors', error), errors: {} };

    // it finds the module by pathname
    const [moduleKey] = (Object.entries(ROUTE_PATHS).find(moduleKeyValue =>
      pathName.includes(moduleKeyValue[1])
    ) || []) as [ModuleName | undefined, string | undefined];

    errorFields.map(field => {
      if (moduleKey && DETAILS_FORM_ERRORS?.[moduleKey]?.[field]) {
        consumedErrors.errors[field] = error.errors[field];
      } else {
        notConsumedErrors.errors[field] = error.errors[field];
      }
    });

    yield* put(setFormErrors(consumedErrors.errors));

    yield all(
      Object.values(notConsumedErrors.errors).map(notConsumedError =>
        put(
          pushNotification({
            showType: INotificationType.Error,
            message: error.title,
            description: Array.isArray(notConsumedError) ? notConsumedError?.join(' ') : '',
          })
        )
      )
    );

    return true;
  }
}

function* tagErrorSaga(error: any) {
  const tagNameValidationErrorTypes = ['tag-already-exists'];

  if (
    error.status === 422 &&
    tagNameValidationErrorTypes.some(type => error?.type.endsWith(type))
  ) {
    yield* put(setFormErrors({ name: [error.detail] }));
    return true;
  }

  return false;
}

function* notFoundSaga(error: any, url: string) {
  // Not found error
  const notFoundNotificationConfig = {
    ...mapError(error),
    showType: INotificationType.Error,
  };
  if (error.title === 'Not Found') notFoundNotificationConfig.description = url;
  yield* put(pushNotification(notFoundNotificationConfig));
  return true;
}

function* customError(error: any, customErrorField?: ((error: any) => string) | string) {
  if (!customErrorField) return false;

  const errorField =
    typeof customErrorField === 'string' ? customErrorField : customErrorField(error);

  // sometimes error.errors is object of arrays, sometimes it's array, sometimes it doesn't exist.
  if (typeof error?.errors === 'object' && !Array.isArray(error?.errors)) {
    yield* put(setFormErrors({ [errorField]: Object.values(error.errors)[0] as string[] }));
  } else if (Array.isArray(error?.errors)) {
    yield* put(setFormErrors({ [errorField]: error.errors }));
  } else {
    yield* put(setFormErrors({ [errorField]: [error.detail] }));
  }

  return true;
}

function* showCustomNotification(customNotification?: INotificationConfig) {
  if (!customNotification) return false;

  yield* put(pushNotification(customNotification));

  return true;
}

export function* handleErrorsSaga(
  error: any,
  url: string,
  customErrorField?: ((error: any) => string) | string,
  customNotification?: INotificationConfig
): any {
  if (yield* showCustomNotification(customNotification)) return;
  if (yield* customError(error, customErrorField)) return;
  if (yield* validationFailure(error)) return;
  if (yield* tagErrorSaga(error)) return;
  if (yield* unauthorizedErrorSaga(error)) return;
  if (yield* jsonParsingErrorSaga(error)) return;
  if (yield* notFoundSaga(error, url)) return;
}
