import styled from 'styled-components';
import { stylesMap } from 'typography/Text';

type ITCProps = {
  disabled?: boolean;
  $secondary?: boolean;
  $iconSize?: string;
};

export const Button = styled.button<ITCProps>`
  background: transparent;
  box-shadow: 0px 0px 0px transparent;
  border: 0px solid transparent;
  text-shadow: 0px 0px 0px transparent;

  :hover {
    background: transparent;
    box-shadow: 0px 0px 0px transparent;
    border: 0px solid transparent;
    text-shadow: 0px 0px 0px transparent;
  }

  :active {
    outline: none;
    border: none;
  }

  :focus {
    outline: 0;
  }

  display: flex;
  border-radius: 0.25rem;
  padding: ${({ $secondary, $iconSize }) =>
    $iconSize ? '0.125rem' : $secondary ? '0.125rem' : '0.3125rem'};

  svg {
    width: ${({ $secondary, $iconSize }) => $iconSize || ($secondary ? '0.75rem' : '1.25rem')};
    height: ${({ $secondary, $iconSize }) => $iconSize || ($secondary ? '0.75rem' : '1.25rem')};

    path {
      fill: ${({ theme }) => theme.palette.fonts.bodyLight};
      fill: ${({ $secondary, theme }) => $secondary && theme.palette.fonts.bodyLight};
      fill: ${({ disabled, theme: { palette } }) => disabled && palette.fonts.disabled};
    }
  }

  :enabled {
    &:hover {
      background-color: ${({ $secondary, theme: { palette } }) =>
        $secondary ? palette.background.iconBackground : palette.fills.iconHover};
      cursor: pointer;

      svg path {
        fill: ${({ $secondary, theme: { palette } }) =>
          $secondary ? palette.icons.secondary : palette.fills.primaryHover};
      }
    }

    &:focus {
      background-color: ${({ theme: { palette } }) => palette.fills.iconHover};
      cursor: pointer;

      svg path {
        fill: ${({ $secondary, theme: { palette } }) =>
          $secondary ? palette.icons.secondary : palette.fills.primaryHover};
      }
    }
  }
`;

export const Wrapper = styled.div<{ showTooltip: boolean }>`
  position: relative;
  display: inline-flex;

  .tooltiptext {
    opacity: ${({ showTooltip }) => showTooltip && '1'};
  }

  &:hover .tooltiptext {
    opacity: 1;
  }
`;

export const TooltipTop = styled.span<{ backgroundcolor: string; color: string; copied: boolean }>`
  user-select: none;
  ${stylesMap['body-2']}
  z-index: 1;

  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.5s;
  padding: 0.3125rem 0.5rem;
  margin-left: -1.25rem;
  margin-left: ${({ copied }) => (copied ? '-1.6875rem' : '-1.3125rem')};

  position: absolute;
  top: auto;
  bottom: 120%;
  left: 65%;

  background-color: ${props => props.backgroundcolor};
  color: ${props => props.color};
  text-align: center;
  border-radius: 0.25rem;
  width: fit-content;

  &::after {
    content: ' ';
    position: absolute;
    top: 100%;
    left: 35%;
    margin-left: -0.3125rem;
    border-width: 0.3125rem;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }
`;
