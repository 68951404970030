// libs
import { css } from 'styled-components';

// components
import { Select } from 'components';
import { ProtocolOption } from './ProtocolOption';

// hooks
import { useCallback } from 'react';
import { useTranslations } from 'hooks/useTranslations';
import { useDispatch, useSelector } from 'react-redux';

// selectors
import { selectAppDetailsTabValidationEntry } from 'redux/selectors';

// actions
import { updatePolicyDetailsAcl, updateValidationStateEntry } from 'redux/actions/app';

// types
import { IPolicyAclProtocol, IPolicyModifiedAcl } from 'types';

// utils
import { changeProtocol } from '../../helpers';

// constants
import { ACLS_PROTOCOLS_CONFIG } from 'appConstants/policiesAcls';
import { POLICY_PROTOCOL_VALIDATION_STATE_PREFIX } from 'appConstants';

interface IProtocol {
  acl: IPolicyModifiedAcl;
}

const ACLS_PROTOCOLS_DATA_SOURCE: { protocol: IPolicyAclProtocol }[] = (
  Object.keys(ACLS_PROTOCOLS_CONFIG) as IPolicyAclProtocol[]
).map(protocol => ({ protocol }));

export const Protocol = ({ acl }: IProtocol) => {
  const { translate } = useTranslations('policies');

  const dispatch = useDispatch();

  const validationState = useSelector(
    selectAppDetailsTabValidationEntry(POLICY_PROTOCOL_VALIDATION_STATE_PREFIX + acl.id)
  );

  const setNewProtocol = useCallback(
    ({ protocol }: { protocol: IPolicyAclProtocol }) => {
      const newAcl = changeProtocol({ protocol, acl });

      dispatch(
        updatePolicyDetailsAcl({
          id: acl.id,
          newProtocol: newAcl.protocol,
          newPorts: acl.ports === null || newAcl.ports === null ? newAcl.ports : undefined,
        })
      );
    },
    [acl, dispatch]
  );

  const onBlur = useCallback(() => {
    if (acl.protocol === null) {
      dispatch(
        updateValidationStateEntry({ key: POLICY_PROTOCOL_VALIDATION_STATE_PREFIX + acl.id })
      );
    }
  }, [acl.id, acl.protocol, dispatch]);

  return (
    <Select
      aria-label={translate('details.acls_table.aria_labels.protocol')}
      data-test="policy-details-acl-protocol-select"
      dataSource={ACLS_PROTOCOLS_DATA_SOURCE}
      valueField="protocol"
      keyField="protocol"
      placeholder={translate('details.acls_table.protocol_placeholder')}
      OptionElement={option => {
        return <ProtocolOption protocol={option.protocol} />;
      }}
      setSelectedValue={option => {
        setNewProtocol({ protocol: option.protocol });
      }}
      value={acl.protocol || undefined}
      onBlur={onBlur}
      height="2rem"
      containerCss={css`
        flex: 10 10 0;
        margin-left: 0.75rem;
      `}
      defaultValueFieldValue={acl.protocol || undefined}
      error={
        validationState?.updated && validationState?.state.error !== null
          ? { message: validationState.state.error.message }
          : null
      }
      tooltipProps={{ placement: 'topRight' }}
    />
  );
};
