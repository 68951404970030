// libs
import { css } from 'styled-components';

// components
import { Textarea } from 'components';

// hooks
import { useDispatch } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';
import { useCallback, useEffect, useState } from 'react';

// actions
import { updatePolicyDetailsAcl } from 'redux/actions/app';

// types
import { BaseSyntheticEvent } from 'react';
import { IPolicyModifiedAcl } from 'types';

// constants
import { ACL_DESCRIPTION_CHARACTERS_LIMIT } from 'appConstants/policiesAcls';

interface IDescription {
  acl: IPolicyModifiedAcl;
}

export const Description = ({ acl }: IDescription) => {
  const dispatch = useDispatch();

  const { translate } = useTranslations('policies');

  const [value, setValue] = useState(acl.description || '');

  useEffect(() => {
    setValue(acl.description || '');
  }, [acl.description]);

  const onChange = useCallback((event: BaseSyntheticEvent) => {
    setValue(event.target.value);
  }, []);

  const onBlur = useCallback(
    (event: BaseSyntheticEvent) => {
      const newValue = event.target.value;

      dispatch(
        updatePolicyDetailsAcl({
          id: acl.id,
          newDescription: newValue,
        })
      );
    },
    [acl, dispatch]
  );

  return (
    <Textarea
      placeholder={translate('details.acls_table.description_placeholder')}
      value={value}
      autoSize={{ minRows: 1, maxRows: 6 }}
      passedCss={css`
        width: 100%;
      `}
      onChange={onChange}
      onBlur={onBlur}
      charactersLimit={{
        characters: value.length || 0,
        charactersLimit: ACL_DESCRIPTION_CHARACTERS_LIMIT,
      }}
    />
  );
};
