// libs
import { ChangeEvent, useState } from 'react';

// components
import { Textarea } from 'components';

// hooks
import { useDispatch, useSelector } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';

// selectors
import { selectAppDetailsPolicyNewNotes } from 'redux/selectors';

// actions
import { updatePolicyDetails } from 'redux/actions/app';

// constants
import { NOTES_CHARACTERS_LIMIT } from 'appConstants';

export const NotesEdit = () => {
  const dispatch = useDispatch();

  const { translate } = useTranslations('policies');

  const notes = useSelector(selectAppDetailsPolicyNewNotes);

  const [textareaInput, setTextareaInput] = useState<string | null>();

  const updateNotes = (newNotes: string) => {
    dispatch(updatePolicyDetails({ notes: newNotes }));
  };

  const onChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.currentTarget.value;
    setTextareaInput(newValue);
  };

  return (
    <Textarea
      value={textareaInput ?? notes ?? ''}
      placeholder={translate('details.notes_placeholder')}
      onBlur={() => updateNotes(textareaInput || '')}
      charactersLimit={{
        characters: textareaInput?.length || 0,
        charactersLimit: NOTES_CHARACTERS_LIMIT,
      }}
      onChange={onChange}
    />
  );
};
