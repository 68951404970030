// components
import { RadioSwitch } from 'components';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { useDispatch } from 'react-redux';

// actions
import { updateTrustRequirementDetailsPublicIpIpRangeCondition } from 'redux/actions/app';

// types
import { ITrustRequirementPublicIpIpRangeConditionModified } from 'types';

interface IIsBlocked {
  ipRange: ITrustRequirementPublicIpIpRangeConditionModified;
}

export const IsBlocked = ({ ipRange }: IIsBlocked) => {
  const dispatch = useDispatch();

  const { translate } = useTranslations('trust-requirements');

  const onChange = () => {
    dispatch(
      updateTrustRequirementDetailsPublicIpIpRangeCondition({
        id: ipRange.id,
        range: { isBlocked: ipRange.isBlocked === 'true' ? 'false' : 'true' },
      })
    );
  };

  return (
    <RadioSwitch
      onChange={onChange}
      value={ipRange.isBlocked === 'true' ? 'blocked' : 'required'}
      left={{
        text: translate('details.types.PublicIp.allow'),
        value: 'required',
      }}
      right={{
        text: translate('details.types.PublicIp.deny'),
        value: 'blocked',
      }}
    ></RadioSwitch>
  );
};
