// Components
import { ColumnTitle } from 'components';
import { Description } from './Description';
import { CountInfo } from './CountInfo';
import { TagActionDropdown } from './ActionDropdown';

// Types
import { TFunction } from 'react-i18next/.';
import { translations, ITag } from 'types';
import { ColumnType } from 'antd/lib/table';

interface ITagsCols {
  isLoading: boolean;
  translate: TFunction<translations>;
  bulkSelectedTags: ITag[];
  setBulkSelectedTags: (tags: ITag[]) => void;
  selectedTag?: ITag['tag'];
}

export const TagsCols = ({ isLoading, translate }: ITagsCols): ColumnType<ITag>[] => [
  {
    title: <ColumnTitle>{translate('table_columns.tag')}</ColumnTitle>,
    dataIndex: 'tag',
    className: 'name-column',
    render: (tagName: string, tag: ITag) => {
      return <Description tag={tag} isLoading={isLoading} />;
    },
  },
  {
    title: <ColumnTitle>{translate('table_columns.enrolment_keys')}</ColumnTitle>,
    dataIndex: 'keys',
    width: '2rem',
    render: (enrolmentKeysCount: ITag['keys']) => (
      <CountInfo data={enrolmentKeysCount} isLoading={isLoading} />
    ),
  },
  {
    title: <ColumnTitle>{translate('table_columns.systems')}</ColumnTitle>,
    dataIndex: 'systems',
    width: '2rem',
    render: (systemsCount: ITag['systems']) => (
      <CountInfo data={systemsCount} isLoading={isLoading} />
    ),
  },
  {
    title: <ColumnTitle>{translate('table_columns.dns_records')}</ColumnTitle>,
    dataIndex: 'dnsRecords',
    width: '2rem',
    render: (dnsCount: ITag['dnsRecords']) => <CountInfo data={dnsCount} isLoading={isLoading} />,
  },
  {
    title: <ColumnTitle>{translate('table_columns.dns_zones')}</ColumnTitle>,
    dataIndex: 'dnsZones',
    width: '2rem',
    render: (dnsZoneCount: ITag['dnsZones']) => (
      <CountInfo data={dnsZoneCount} isLoading={isLoading} />
    ),
  },
  {
    title: <ColumnTitle>{translate('table_columns.policies')}</ColumnTitle>,
    dataIndex: 'policies',
    width: '2rem',
    render: (policiesCount: ITag['policies']) => (
      <CountInfo data={policiesCount} isLoading={isLoading} />
    ),
  },
  {
    title: '',
    dataIndex: '',
    key: 'x',
    className: 'action-column',
    render: (record: ITag) => <TagActionDropdown tag={record} isLoading={isLoading} />,
  },
];
