import { css } from 'styled-components';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from 'styled-components';

// components
import { Select } from 'components';

// actions
import { updatePolicyDetails } from 'redux/actions/app';

// selectors
import { selectAppDetailCurrentPolicyGatewayPriority } from 'redux/selectors';
import { IPolicyGatewayPriorityType } from 'types';
import { CustomText } from 'typography/Text';
import { DropdownContainer, GatewayPriorityContainer } from './styled';

const PRIORITY_OPTIONS_CONFIG: Record<
  Exclude<IPolicyGatewayPriorityType, null>,
  { type: IPolicyGatewayPriorityType; translationLabel: string; translationDescription: string }
> = {
  Balanced: {
    type: 'Balanced' as const,
    translationLabel: 'details.gateway_priority.balanced.name' as const,
    translationDescription: 'details.gateway_priority.balanced.description' as const,
  },
  Ordered: {
    type: 'Ordered' as const,
    translationLabel: 'details.gateway_priority.ordered.name' as const,
    translationDescription: 'details.gateway_priority.ordered.description' as const,
  },
  Geographic: {
    type: 'Geographic' as const,
    translationLabel: 'details.gateway_priority.geographic.name' as const,
    translationDescription: 'details.gateway_priority.geographic.description' as const,
  },
};

const PRIORITY_OPTIONS_DATA_SOURCE: {
  type: IPolicyGatewayPriorityType;
  translationLabel: string;
  translationDescription: string;
}[] = (Object.keys(PRIORITY_OPTIONS_CONFIG) as IPolicyGatewayPriorityType[]).map(
  type => PRIORITY_OPTIONS_CONFIG[type ?? 'Balanced']
);

export const GatewayPriorityEdit = () => {
  const dispatch = useDispatch();

  const {
    palette: { fonts },
  } = useTheme();

  const { translate } = useTranslations('policies');

  const gatewayPriority = useSelector(selectAppDetailCurrentPolicyGatewayPriority);

  const setSelectedGatewayPriority = (gatewayPriority: IPolicyGatewayPriorityType) => {
    dispatch(updatePolicyDetails({ gatewayPriority: gatewayPriority }));
  };

  return (
    <GatewayPriorityContainer>
      <DropdownContainer>
        <CustomText type="body-2" color={fonts.bodyLight}>
          {translate('details.gateway_priority.title')}
        </CustomText>

        <Select
          data-test="policy-gateway-priority-select"
          aria-labelledby="label-policy-gateway-priority"
          dataSource={PRIORITY_OPTIONS_DATA_SOURCE}
          valueField="type"
          keyField="type"
          value={gatewayPriority ?? 'Balanced'}
          height="2rem"
          width="10rem"
          containerCss={css`
            margin-left: 2rem;
            margin-top: -0.5rem;
          `}
          defaultValueFieldValue={gatewayPriority ?? 'Balanced'}
          setSelectedValue={option => {
            setSelectedGatewayPriority(option.type);
          }}
          OptionElement={option => {
            return (
              <CustomText type="body-2" color={fonts.title}>
                {translate(option.translationLabel)}
              </CustomText>
            );
          }}
        />
      </DropdownContainer>

      <CustomText
        type="hint"
        css={css`
          white-space: pre-line;
          display: block;
          margin-top: 0.25rem;
          margin-left: 9.75rem;
        `}
        color={fonts.bodyLight}
      >
        {translate(PRIORITY_OPTIONS_CONFIG[gatewayPriority ?? 'Balanced'].translationDescription)}
      </CustomText>
    </GatewayPriorityContainer>
  );
};
