import { CSSProp } from 'styled-components';
import { ButtonProps } from 'antd/lib/button';
import { CustomText } from 'typography/Text';
import { Status, StyledButton } from './styled';
import { TextTypes } from 'types';

type IButtonForms = 'middle' | 'large';

export interface ICustomButtonProps extends Omit<ButtonProps, 'size'> {
  size?: IButtonForms;
  status?: Status;
  icon?: React.ReactElement;
  textCss?: CSSProp;
  focusCss?: CSSProp;
  disabled?: boolean;
}

const buttonSizes: Record<IButtonForms, TextTypes> = {
  middle: 'body-2',
  large: 'body-1',
};

export const CustomButtonLink = ({
  children,
  icon,
  size = 'middle',
  textCss,
  focusCss,
  status = 'default',
  ...rest
}: ICustomButtonProps) => {
  return (
    <StyledButton size={size} $focusCss={focusCss} type="link" status={status} {...rest}>
      {icon}
      <CustomText
        css={`
          display: flex !important;
          align-items: center;
          ${textCss}
        `}
        type={buttonSizes[size]}
      >
        {children}
      </CustomText>
    </StyledButton>
  );
};
