// components
import { PageTitle } from 'components';
import { TitleEdit } from './Edit';
import { TitleView } from './View';
import { TitleCreate } from './Create';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { useSelector } from 'react-redux';

// selectors
import { selectAppDetailsDnsRecordName, selectAppDetailsTabType } from 'redux/selectors';

export const TitleBlock = () => {
  const type = useSelector(selectAppDetailsTabType);
  const name = useSelector(selectAppDetailsDnsRecordName);

  const { translate } = useTranslations('dns');

  return (
    <>
      <PageTitle title={`${translate('page_title')}${name ? ` | ${name}` : ''}`} />
      {type === 'dns-record-view' && <TitleView />}
      {type === 'dns-record-edit' && <TitleEdit />}
      {type === 'dns-record-create' && <TitleCreate />}
    </>
  );
};
