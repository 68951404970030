import styled from 'styled-components';

export const HeaderRow = styled.div`
  width: 100%;
  margin-bottom: 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const FilterAndBulkActionsContainer = styled.div`
  height: 2.125rem;
  margin-bottom: 1rem;
`;
