// components
import { CountrySelect } from '../Countries/CountrySelect';
import { IsBlockedContext } from '../EditContent';

// hooks
import { useContext } from 'react';

export const LocationsCountriesEdit = () => {
  const { isBlocked } = useContext(IsBlockedContext);

  return <CountrySelect isBlocked={isBlocked} />;
};
