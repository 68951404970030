export type IInstructionsType = 'apt' | 'apt+transport' | 'dnf' | 'zypper' | 'yum' | 'script';

export interface ILinuxDistros {
  Ubuntu: IInstructionsType;
  'Amazon Linux 2': IInstructionsType;
  Debian: IInstructionsType;
  'RHEL 7': IInstructionsType;
  'RHEL 8': IInstructionsType;
  'CentOS 7': IInstructionsType;
  'CentOS 8': IInstructionsType;
  Fedora: IInstructionsType;
  openSUSE: IInstructionsType;
  SLES: IInstructionsType;
  'Ubuntu 16.04': IInstructionsType;
  'Raspberry Pi': IInstructionsType;
  Other: IInstructionsType;
}

export const LINUX_DISTROS: ILinuxDistros = {
  Ubuntu: 'apt',
  'Ubuntu 16.04': 'apt+transport',
  'Amazon Linux 2': 'script',
  Debian: 'apt+transport',
  'RHEL 7': 'yum',
  'RHEL 8': 'dnf',
  'CentOS 7': 'script',
  'CentOS 8': 'dnf',
  openSUSE: 'zypper',
  SLES: 'zypper',
  Fedora: 'dnf',
  'Raspberry Pi': 'apt+transport',
  Other: 'script',
};

export const DEFAULT_DISTRO: keyof typeof LINUX_DISTROS = 'Ubuntu';
