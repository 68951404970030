// libs
import { useCallback } from 'react';
import { useTranslations } from 'hooks/useTranslations';
import { css, useTheme } from 'styled-components';

// components
import { IconAccountSavePATokenModal } from 'assets/svg';
import { CustomButton, CustomMessage } from 'components';
import { CommandLineCode } from 'typography/Code';
import { ModalFooter, ModalHeader, ModalScrollablePart } from 'components/Modal';
import { CustomText } from 'typography/Text';

// hooks
import { useDispatch, useSelector } from 'react-redux';
import { useSubmitOnEnter } from 'hooks/useSubmitOnEnter';

// actions
import { setModalContent } from 'redux/actions/app';

// selectors
import { selectModalData } from 'redux/selectors';

// types
import { IAccountAccessToken } from 'types';

export const SavePATokenModal = () => {
  const dispatch = useDispatch();

  const { translate } = useTranslations('account');

  const {
    palette: { fonts },
  } = useTheme();

  const tokenInfo = useSelector(selectModalData) as IAccountAccessToken;

  const onClose = useCallback(() => {
    dispatch(setModalContent({ type: null, data: null }));
  }, [dispatch]);

  useSubmitOnEnter({ callback: onClose });

  return (
    <>
      <ModalHeader
        title={translate('save_access_token_modal.title')}
        icon={<IconAccountSavePATokenModal width="2.5rem" height="2.5rem" />}
      />

      <ModalScrollablePart>
        <CustomMessage
          type="success"
          message={translate('save_access_token_modal.token_created')}
          withIcon
          css={css`
            margin-bottom: 2rem;
          `}
        />

        <CustomText
          type="sub-heading-1"
          color={fonts.title}
          css={css`
            display: block;
            margin-bottom: 0.25rem;
          `}
        >
          {tokenInfo?.description}
        </CustomText>
        <CustomText
          type="body-1"
          color={fonts.bodyLight}
          css={css`
            display: block;
            margin-bottom: 1rem;
          `}
        >
          {translate('save_access_token_modal.save_token')}
        </CustomText>
        <CommandLineCode code={tokenInfo?.reference} />
      </ModalScrollablePart>
      <ModalFooter
        css={css`
          justify-content: flex-end;
        `}
      >
        <CustomButton size="large" width="5.375rem" kind={'primary'} onClick={onClose}>
          {translate('done')}
        </CustomButton>
      </ModalFooter>
    </>
  );
};
