// libs
import { pick } from 'ramda';
import { put, select } from 'typed-redux-saga';

// actions
import { pushNotification, setDetailsContent } from 'redux/actions/app';

// types
import {
  INotificationType,
  ITrustRequirementDetailModified,
  IUpdateTrustRequirementParams,
} from 'types';

// selectors
import { selectAppDetailsTabData, selectAppDetailsTabDataNew } from 'redux/selectors';

// actions
import { setTrustRequirementsShouldUpdate } from 'redux/actions/trust-requirements';

// utils
import { ApiTypeCaller } from '../../api/utils';
import {
  translator,
  demodifyTrustRequirementDetails,
  modifyTrustRequirementDetails,
  diff,
} from 'utils';

export function* updateTrustRequirementSaga() {
  const trustRequirement: ITrustRequirementDetailModified = yield select(selectAppDetailsTabData);
  const updatedTrustRequirement: ITrustRequirementDetailModified = yield select(
    selectAppDetailsTabDataNew
  );

  const updatedParams: IUpdateTrustRequirementParams = pick(
    ['description', 'settings', 'notes'],
    demodifyTrustRequirementDetails(diff(updatedTrustRequirement, trustRequirement))
  );

  const { success, result } = yield* ApiTypeCaller('updateTrustRequirement', {
    requirementId: String(updatedTrustRequirement.id),
    updatedParams,
  });

  const translate = translator('notifications');

  if (success && result) {
    yield put(
      setDetailsContent({
        data: modifyTrustRequirementDetails(result),
        type: 'trust-requirement-view',
        dataNew: null,
        isLoading: false,
      })
    );
    yield put(setTrustRequirementsShouldUpdate());
    yield put(
      pushNotification({
        showType: INotificationType.Success,
        description: translate('trust_requirement_updated', {
          requirementId: updatedTrustRequirement.id,
        }),
      })
    );
  }
}
