// libs
import styled from 'styled-components';

// components
import { DayButton } from '../DayButton';

// hooks
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// selectors
import {
  selectAppDetailsPolicyActiveDays,
  selectAppDetailsPolicyNewActiveDays,
} from 'redux/selectors';

// actions
import { updatePolicyDetailsActiveHours } from 'redux/actions/app';

// types
import { IPolicyActiveDays } from 'types';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const days: IPolicyActiveDays = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

export const ActiveDaysEdit = () => {
  const dispatch = useDispatch();

  const selectedDays = useSelector(selectAppDetailsPolicyActiveDays);
  const newDays = useSelector(selectAppDetailsPolicyNewActiveDays);

  const displayedDays = selectedDays || newDays;

  const handleDayClick = useCallback(
    (day: IPolicyActiveDays[0]) => {
      const dayIndex = displayedDays?.findIndex(item => item === day);

      if (!displayedDays) return;

      if (typeof dayIndex === 'number' && dayIndex > -1) {
        const newSelectedDays = [...displayedDays];
        newSelectedDays.splice(dayIndex, 1);

        dispatch(updatePolicyDetailsActiveHours({ daysOfWeek: newSelectedDays }));
        return;
      }

      const newSelectedDays = [...displayedDays, day];
      dispatch(updatePolicyDetailsActiveHours({ daysOfWeek: newSelectedDays }));
    },
    [dispatch, displayedDays]
  );

  return (
    <Container>
      {days.map(day => (
        <DayButton
          active={displayedDays?.includes(day) || false}
          onClick={handleDayClick}
          day={day}
          key={`daybutton_${day}`}
        />
      ))}
    </Container>
  );
};
