// libs
import { css } from 'styled-components';

// components
import { CustomToggle } from 'components';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { useDispatch, useSelector } from 'react-redux';

// selectors
import { selectAppDetailsEnrolmentKeyNewIsEnabled } from 'redux/selectors';

// actions
import { updateEnrolmentKeyDetails } from 'redux/actions/app';

export const StatusEdit = () => {
  const dispatch = useDispatch();

  const { translate } = useTranslations('enrolment-keys');
  const { translate: translateGlobal } = useTranslations('global');

  const newIsEnabled = useSelector(selectAppDetailsEnrolmentKeyNewIsEnabled);

  const handleClick = () => {
    dispatch(updateEnrolmentKeyDetails({ isEnabled: !newIsEnabled }));
  };

  return (
    <CustomToggle
      css={css`
        margin-right: 0.5rem;
      `}
      aria-label={translateGlobal('details_pane.aria_labels.switch_state')}
      enabled={newIsEnabled ?? false}
      label={newIsEnabled ? translate('enabled') : translate('disabled')}
      onClick={handleClick}
    />
  );
};
