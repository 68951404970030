// libs
import { call, put, select } from 'typed-redux-saga';

// selectors
import { selectAppDetailsTabDataNew } from 'redux/selectors';
import { setEnrolmentKeysShouldUpdate } from 'redux/actions/enrolment-keys';

// actions
import { pushNotification } from 'redux/actions/app';

// Utils
import { orgPush } from '../../utils';
import { ApiTypeCaller } from '../../api/utils';
import { tagsToStrings, translator } from 'utils';

// constants
import { ROUTE_PATHS, UNLIMITED_USES_AMOUNT } from 'appConstants';

// types
import { ICreateEnrolmentKeyParams, IEnrolmentStoredKey, INotificationType } from 'types';

export function* createEnrolmentKeySaga() {
  const {
    description,
    usesRemaining,
    approvalMode,
    ipConstraints,
    tags,
    type,
    notes,
    disconnectedRetentionMinutes,
    autoExpire,
  }: IEnrolmentStoredKey = yield select(selectAppDetailsTabDataNew);

  const translate = translator('notifications');

  const createKeyParams: ICreateEnrolmentKeyParams = {
    description,
    usesRemaining: type === 'Ephemeral' ? UNLIMITED_USES_AMOUNT : usesRemaining,
    approvalMode: type === 'Ephemeral' ? 'Automatic' : approvalMode,
    ipConstraints: ipConstraints?.map(ipConstraint => {
      return {
        range: ipConstraint.range as string,
        description: ipConstraint.description as string,
      };
    }),
    tags: tagsToStrings(tags),
    type,
    notes,
    disconnectedRetentionMinutes: type === 'Ephemeral' ? disconnectedRetentionMinutes : null,
    autoExpire,
  };

  const { result, success } = yield* ApiTypeCaller('createEnrolmentKey', createKeyParams);

  if (success && result) {
    yield call(orgPush, `${ROUTE_PATHS.keys}/${result.id}`, { keepSearch: true });
    yield put(setEnrolmentKeysShouldUpdate());
    yield put(
      pushNotification({
        showType: INotificationType.Success,
        description: translate('enrolment_key_created', {
          keyId: result.id,
          name: result.description,
        }),
      })
    );
  }
}
