// Components
import { TagsList } from 'components';

// hooks
import { useSelector } from 'react-redux';

// selectors
import { selectAppDetailsEnrolmentKeyTags } from 'redux/selectors';

export const AutoTagView = () => {
  const keyTags = useSelector(selectAppDetailsEnrolmentKeyTags);

  return <TagsList width="27.5rem" tags={keyTags} blocksMode={true} />;
};
