// libs
import { pick } from 'ramda';
import { call, put, select } from 'typed-redux-saga';

// selectors
import { selectAppDetailsTabDataNew } from 'redux/selectors';

// actions
import { setPoliciesShouldUpdate } from 'redux/actions/policies';
import { pushNotification } from 'redux/actions/app';

// constants
import { ROUTE_PATHS } from 'appConstants';

// utils
import { ApiTypeCaller } from '../../api/utils';
import {
  tagsToStrings,
  translator,
  trustRequirementsToNumbers,
  demodifyPolicyDetails,
} from 'utils';
import { orgPush } from 'redux/sagas/utils';

// types
import {
  ICreatePolicyParams,
  INotificationType,
  IPolicyDetailsModified,
  IPolicyGateway,
} from 'types';

export function* createPolicySaga() {
  const translate = translator('notifications');

  const newPolicy: IPolicyDetailsModified = yield select(selectAppDetailsTabDataNew);
  const demodifiedPolicy = demodifyPolicyDetails(newPolicy);

  const updatedParams = pick(
    [
      'description',
      'senderTags',
      'receiverTags',
      'senderTrustRequirements',
      'isEnabled',
      'notes',
      'acls',
      'type',
      'gateways',
      'gatewayTrafficDirection',
      'senderTrustRequirements',
      'gatewayAllowedIpRanges',
      'gatewayPriority',
      'autoExpire',
      'activeHours',
    ],
    demodifiedPolicy
  );

  const senderTags = tagsToStrings(updatedParams.senderTags);
  const receiverTags = tagsToStrings(updatedParams.receiverTags);
  const senderTrustRequirements = trustRequirementsToNumbers(updatedParams.senderTrustRequirements);

  const gateways: IPolicyGateway[] = updatedParams.gateways.map(gw => ({
    systemId: gw.systemId,
    routes: gw.routes.map(r => r.route),
  }));

  const gatewayTrafficDirection =
    updatedParams.type === 'General' ? undefined : updatedParams.gatewayTrafficDirection;

  const payload: ICreatePolicyParams = {
    ...updatedParams,
    gateways,
    senderTags,
    receiverTags,
    senderTrustRequirements,
    gatewayTrafficDirection,
  };

  const { result, success } = yield* ApiTypeCaller('createPolicy', payload);

  if (success && result) {
    yield call(orgPush, `${ROUTE_PATHS.policies}/${result.id}`, { keepSearch: true });
    yield put(setPoliciesShouldUpdate());
    yield put(
      pushNotification({
        showType: INotificationType.Success,
        description: translate('policy_added'),
      })
    );
  }
}
