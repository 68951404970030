// lib
import { call, select } from 'typed-redux-saga';

// selectors
import { selectOrgId, selectPathName } from 'redux/selectors';

// sagas
import { setCurrentOrganisationSaga } from 'redux/sagas/business/organisation/setCurrentOrganisationSaga';
import { getCurrentOrganisationSaga } from 'redux/sagas/business/organisation/getCurrentOrganisationSaga';

// utils
import { getOrgIdFromUrl } from 'utils/getOrgIdFromUrl';

export function* switchOrgOnUrlChangeSaga() {
  const pathname = yield* select(selectPathName);
  const orgId = yield* select(selectOrgId);

  const urlOrg = getOrgIdFromUrl(pathname);

  if (orgId !== urlOrg) {
    yield call(setCurrentOrganisationSaga);
    yield call(getCurrentOrganisationSaga);
  }
}
