// components
import { Subnet } from './Subnet';
import { CustomText } from 'typography/Text';
import { GatewayContext } from 'modules/Systems/Details/Sections/Gateway';
import { CustomButtonLink, InfoIconButton } from 'components';
import { IconRandomize, IconSystemsDetailsAddNewSubnet } from 'assets/svg';
import { ButtonsContainer, EditContainer, SubnetsContainer } from './styled';

// hooks
import { css, useTheme } from 'styled-components';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useTranslations } from 'hooks/useTranslations';
import { useDispatch, useSelector } from 'react-redux';

// selectors
import {
  selectAppDetailsSystemCurrentKnownSubnets,
  selectAppDetailsSystemCurrentRoutes,
} from 'redux/selectors';

// actions
import {
  createSystemDetailsRoute,
  setSystemDetailsKnownRoutes,
  setSystemDetailsRoutes,
} from 'redux/actions/app';

export const GatewayEdit = () => {
  const dispatch = useDispatch();

  const { translate } = useTranslations('systems');

  const { isOpen, rememberedRoutes, setRememberedRoutes } = useContext(GatewayContext);

  const {
    palette: { fonts, fills, icons, background },
  } = useTheme();

  const routes = useSelector(selectAppDetailsSystemCurrentRoutes);
  const knownSubnets = useSelector(selectAppDetailsSystemCurrentKnownSubnets);

  const hasKnownSubnetChanged = useMemo(() => {
    // if routes dot not include all known subnets
    return !knownSubnets?.every(knownSubnet => {
      const routeIncludesKnownSubnet = routes
        ?.filter(route => !route.userEntered)
        .some(route => route.subnet === knownSubnet);

      return routeIncludesKnownSubnet;
    });
  }, [knownSubnets, routes]);

  // if we don't have routes we set knownSubnets as routes
  useEffect(() => {
    if (!routes?.length && knownSubnets?.length) {
      dispatch(setSystemDetailsKnownRoutes(knownSubnets));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isOpen]);

  // if we close gateways and open them again we
  // assign previous values to routes
  useEffect(() => {
    if (isOpen === true && rememberedRoutes.length) {
      dispatch(setSystemDetailsRoutes(rememberedRoutes));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isOpen]);

  // if we close gateways we remember our routes while
  // we send empty array to the redux
  useEffect(() => {
    if (isOpen === false) {
      setRememberedRoutes(routes || []);
      dispatch(setSystemDetailsRoutes([]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isOpen]);

  const handleCreateNewSubnet = useCallback(() => {
    dispatch(createSystemDetailsRoute());
  }, [dispatch]);

  const handleResetSubnet = useCallback(() => {
    knownSubnets?.length && dispatch(setSystemDetailsKnownRoutes(knownSubnets));
  }, [dispatch, knownSubnets]);

  return (
    <EditContainer>
      <CustomText type="body-2" color={fonts.bodyLight}>
        {translate('details.gateway.subnets')}
      </CustomText>
      <SubnetsContainer>
        {routes?.map(route => (
          <Subnet key={route.id} route={route} />
        ))}
      </SubnetsContainer>
      <ButtonsContainer>
        <CustomButtonLink
          data-test="system-details-add-new-gateway-route"
          onClick={handleCreateNewSubnet}
        >
          <IconSystemsDetailsAddNewSubnet />
          <CustomText
            type="body-1"
            color={fills.primary}
            css={css`
              margin-left: 0.3125rem;
            `}
          >
            {translate('details.gateway.add_subnet')}
          </CustomText>
        </CustomButtonLink>

        <CustomButtonLink
          disabled={!hasKnownSubnetChanged}
          data-test="system-details-reset-subnets"
          onClick={handleResetSubnet}
          css={css`
            margin-left: 1.5rem;
            svg path {
              fill: ${hasKnownSubnetChanged ? icons.primary : icons.disabled};
            }
          `}
        >
          <IconRandomize width="0.75rem" height="0.75rem" />
          <CustomText
            type="body-1"
            color={fills.primary}
            css={css`
              margin-left: 0.3125rem;
            `}
          >
            {translate('details.gateway.reset_subnets')}
          </CustomText>
        </CustomButtonLink>
        <InfoIconButton
          iconContainerCss={css`
            svg {
              width: 1rem;
              height: 1rem;
            }
          `}
          tooltipPlacement="topLeft"
          title={translate('details.gateway.reset_subnets_info')}
          textColor={fonts.bodyDark}
          tooltipBackgroundColor={background.panel}
          containerCss={css`
            margin-left: 0.25rem;
            width: 1.5rem;
            height: 1.5rem;
          `}
        />
      </ButtonsContainer>
    </EditContainer>
  );
};
