// libs
import { css } from 'styled-components';

// components
import { Details } from 'components';
import { ZoneView } from './View';
import { ZoneCreate } from './Create';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { useSelector } from 'react-redux';

// selectors
import { selectAppDetailsTabType } from 'redux/selectors';

export const ZoneBlock = () => {
  const type = useSelector(selectAppDetailsTabType);
  const { translate } = useTranslations('dns');

  return (
    <Details.KeyValue
      alignItems="center"
      height="2.375rem"
      css={css`
        margin-bottom: 0.5rem;
      `}
      descriptionCss={css`
        min-width: 0;
      `}
      title={translate('records.details.zone')}
    >
      {(type === 'dns-record-view' || type === 'dns-record-edit') && <ZoneView />}
      {type === 'dns-record-create' && <ZoneCreate />}
    </Details.KeyValue>
  );
};
