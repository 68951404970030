// libs
import { css } from 'styled-components';

// components
import { Details } from 'components';
import { IconTagsOutlined } from 'assets/svg';
import { PolicyMembersSection } from './styled';
import { PolicyMembersBlock } from '../../Blocks';

// hooks
import { useTranslations } from 'hooks/useTranslations';

export const PolicyMembers = () => {
  const { translate } = useTranslations('policies');

  return (
    <PolicyMembersSection>
      <Details.SectionHeader
        Icon={IconTagsOutlined}
        title={translate('details.policy_members')}
        css={css`
          margin-bottom: 0.5rem;
        `}
      />

      <PolicyMembersBlock />
    </PolicyMembersSection>
  );
};
