import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  margin: 0 0.75rem;
  margin-bottom: 1.5rem;
`;

export const OptionItem = styled.div`
  display: flex;
  align-items: center;
  min-width: 0;

  div:first-child {
    margin-right: 0.5rem;
  }
`;

export const NoOrgsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2.5rem 0.75rem 1.125rem 0.75rem;
  text-align: center;
  align-items: center;

  svg {
    margin-bottom: 1.5rem;
  }
`;

export const ModalOrgWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1rem;
`;

export const InvitesIndicator = styled.div`
  position: absolute;
  top: -0.1875rem;
  right: -0.1875rem;

  width: 0.875rem;
  height: 0.875rem;

  background-color: ${({ theme }) => theme.palette.state.alert};
  border-radius: 50%;
`;
