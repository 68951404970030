// libs
import { css } from 'styled-components';

// components
import { Input } from 'components';

// hooks
import { useDispatch, useSelector } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';
import { useCallback, useEffect, useState } from 'react';

// actions
import { updateSystemDetailsRoute } from 'redux/actions/app';

// selectors
import { selectAppDetailsTabValidationEntry } from 'redux/selectors';

// types
import { BaseSyntheticEvent } from 'react';
import { ISystemGatewayRouteModified } from 'types';

// constants
import {
  REGEX_INVALID_SUBNET_CHARACTERS,
  SYSTEM_GATEWAY_ROUTE_VALIDATION_STATE_PREFIX,
} from 'appConstants';

// utils

interface ISubnetInput {
  route: ISystemGatewayRouteModified;
}

export const SubnetInput = ({ route }: ISubnetInput) => {
  const dispatch = useDispatch();

  const { translate } = useTranslations('systems');

  const validationState = useSelector(
    selectAppDetailsTabValidationEntry(SYSTEM_GATEWAY_ROUTE_VALIDATION_STATE_PREFIX + route.id)
  );

  const [value, setValue] = useState(route.subnet || '');

  useEffect(() => {
    setValue(route.subnet || '');
  }, [route.subnet]);

  const onChange = useCallback((event: BaseSyntheticEvent) => {
    const newValue = event.target.value.replaceAll(REGEX_INVALID_SUBNET_CHARACTERS, '');

    setValue(newValue);
  }, []);

  const onBlur = useCallback(
    (event: BaseSyntheticEvent) => {
      const value = event.target.value;

      dispatch(
        updateSystemDetailsRoute({
          id: route.id,
          subnet: value,
          name: route.name,
          weight: route.weight,
        })
      );
    },
    [route, dispatch]
  );

  return (
    <Input
      data-test="system-details-gateway-route-input"
      aria-label={translate('details.aria_labels.ip_range')}
      placeholder={translate('details.gateway.subnet_placeholder')}
      showStateIcon={validationState?.updated && validationState?.state.error !== null}
      suffixMessage={validationState?.state.error?.message}
      error={
        validationState?.updated && validationState?.state.error !== null
          ? validationState?.state.error
          : null
      }
      textStyle="body-2"
      size="middle"
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      labelCss={css`
        display: none;
      `}
      stateMessageCss={css`
        display: none;
      `}
      containerCss={css`
        flex: 1;
        margin-left: 0.75rem;
      `}
    />
  );
};
