// Libs
import { css, useTheme } from 'styled-components';

// components
import { LinuxDropdownContainer } from '../../styled';
import { Select } from 'components';
import { CustomText } from 'typography/Text';
import { StyledItem, EnrolmentKeySelect, GoToSystems } from '../../components';
import { LinuxApt, LinuxAptTransport, LinuxScript, LinuxDnf, LinuxYum, LinuxZypper } from './Types';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { useState } from 'react';

// constants
import { LINUX_DISTROS, DEFAULT_DISTRO, IInstructionsType } from 'appConstants';

const DistroMap: Record<IInstructionsType, React.ReactNode> = {
  apt: <LinuxApt />,
  script: <LinuxScript />,
  yum: <LinuxYum />,
  dnf: <LinuxDnf />,
  zypper: <LinuxZypper />,
  'apt+transport': <LinuxAptTransport />,
};

export const Linux = () => {
  const { translate } = useTranslations('enrol-new-system');

  const {
    palette: { fonts },
  } = useTheme();

  const [selectedDistro, setSelectedDistro] = useState<IInstructionsType>(
    LINUX_DISTROS[DEFAULT_DISTRO]
  );

  return (
    <>
      <StyledItem
        title={translate('linux.steps.1.title')}
        titleId="label-select-new-system-linux-distribution"
        content={
          <LinuxDropdownContainer>
            <Select
              aria-labelledby="label-select-new-system-linux-distribution"
              dataSource={Object.keys(LINUX_DISTROS).map(distro => {
                return { distro } as { distro: keyof typeof LINUX_DISTROS };
              })}
              valueField="distro"
              keyField="distro"
              OptionElement={option => {
                return (
                  <CustomText type="body-2" color={fonts.title}>
                    {option.distro}
                  </CustomText>
                );
              }}
              setSelectedValue={option => {
                setSelectedDistro(LINUX_DISTROS[option.distro]);
              }}
              defaultValueFieldValue={DEFAULT_DISTRO}
              width="16.8125rem"
              additionalCss={css`
                margin-left: 0.75rem;
              `}
            />
          </LinuxDropdownContainer>
        }
      />
      {DistroMap[selectedDistro]}
      <EnrolmentKeySelect />
      <GoToSystems />
    </>
  );
};
