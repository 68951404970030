// libs
import { css, useTheme } from 'styled-components';

// components
import { DetailRow } from 'components/DetailsPanel/KeyValue/styled';
import { CustomText } from 'typography/Text';

// selectors
import {
  selectAppDetailsTabType,
  selectAppDetailsTrustRequirementCurrentType,
} from 'redux/selectors';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { useSelector } from 'react-redux';

export const OverviewDescriptionBlock = () => {
  const { translate } = useTranslations('trust-requirements');

  const {
    palette: { fonts },
  } = useTheme();

  const type = useSelector(selectAppDetailsTrustRequirementCurrentType);
  const tabType = useSelector(selectAppDetailsTabType);

  return (
    <DetailRow>
      <CustomText
        css={css`
          margin-bottom: 1.5rem;
        `}
        type="body-2"
        color={fonts.bodyLight}
      >
        {translate(
          `details.types.${type}.overview_description_${
            tabType === 'trust-requirement-view' ? 'view' : 'edit'
          }`
        )}
      </CustomText>
    </DetailRow>
  );
};
