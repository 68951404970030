// libs
import { css, useTheme } from 'styled-components';

// components
import { TagsList } from 'components';
import {
  IconPoliciesDetailsFullMesh,
  IconPoliciesTrafficAllowed,
  IconPoliciesTrafficDenied,
} from 'assets/svg';
import {
  Traffic,
  Container,
  TagTitleCustomText,
  IconContainer,
  TagListWrapper,
  TagsContainer,
} from '../styled';
import { FullMeshNotification } from './FullMeshNotification';

// hooks
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';

// selectors
import {
  selectAppDetailsPolicyReceiverTags,
  selectAppDetailsPolicySenderTags,
  selectAppDetailsPolicyState,
} from 'redux/selectors';

// utils
import { intersection } from 'ramda';

export const PolicyMembersView = () => {
  const { translate } = useTranslations('policies');

  const {
    palette: { fonts },
  } = useTheme();

  const state = useSelector(selectAppDetailsPolicyState);
  const senderTags = useSelector(selectAppDetailsPolicySenderTags);
  const receiverTags = useSelector(selectAppDetailsPolicyReceiverTags);

  const isMesh = useMemo(
    () => Boolean(intersection(senderTags, receiverTags)?.length),
    [senderTags, receiverTags]
  );

  return (
    <Container>
      <TagsContainer>
        <Traffic>
          <TagTitleCustomText type="body-2" color={fonts.bodyLight}>
            {translate('details.senders')}
          </TagTitleCustomText>
          <TagListWrapper>
            <TagsList
              width="100%"
              tags={senderTags}
              css={css`
                padding: 0.5rem;
              `}
              likeSelect={true}
              blocksMode={true}
            />
          </TagListWrapper>
        </Traffic>
        <IconContainer>
          {state === 'InactiveNoAcls' || senderTags.length === 0 || receiverTags.length === 0 ? (
            <IconPoliciesTrafficDenied />
          ) : isMesh ? (
            <IconPoliciesDetailsFullMesh />
          ) : (
            <IconPoliciesTrafficAllowed />
          )}
        </IconContainer>
        <Traffic>
          <TagTitleCustomText type="body-2" color={fonts.bodyLight}>
            {translate('details.receivers')}
          </TagTitleCustomText>
          <TagListWrapper>
            <TagsList
              width="100%"
              tags={receiverTags}
              css={css`
                padding: 0.5rem;
              `}
              likeSelect={true}
              blocksMode={true}
            />
          </TagListWrapper>
        </Traffic>
      </TagsContainer>
      {isMesh && <FullMeshNotification mode="view" />}
    </Container>
  );
};
