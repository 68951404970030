// components
import { CustomText } from 'typography/Text';

// hooks
import { useTheme } from 'styled-components';
import { useSelector } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';

// selectors
import { selectAppDetailsTrustRequirementMicrosoftAuthorityTenantId } from 'redux/selectors';

export const TenantIdView = () => {
  const { translate } = useTranslations('trust-requirements');

  const {
    palette: { fonts },
  } = useTheme();

  const tenantId = useSelector(selectAppDetailsTrustRequirementMicrosoftAuthorityTenantId);

  return (
    <CustomText type="body-2" color={fonts.title}>
      {tenantId ||
        translate('details.types.UserAuthentication.authorities.azure.tenant_id_empty_state')}
    </CustomText>
  );
};
