// libs
import { memo, useCallback, useState } from 'react';
import { css, useTheme } from 'styled-components';

// components
import { Container, HeaderRow, ScrollBarContainer, TabsWrapper, Wrapper } from './styled';
import { CustomButton, TabButtons, TrialBanner } from 'components';
import { Records } from './Records';
import { ZoneProperties } from './ZoneProperties';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { useDispatch, useSelector } from 'react-redux';

// actions
import { createDNSRecordSideMenu } from 'redux/actions/app';

// selectors
import { selectAppDetailsTabType, selectDnsSelectedZone } from 'redux/selectors';

// types
import { IDnsTabs } from 'types/dns';
import { CustomText } from 'typography/Text';
import { ITabsDefault } from 'components/TabButtons/DefaultTabPane';

// utils
import { equals } from 'ramda';

export const Content = memo(() => {
  const dispatch = useDispatch();

  const {
    palette: { fonts },
  } = useTheme();

  const [selectedTab, setSelectedTab] = useState<IDnsTabs>('records');

  const selectedZone = useSelector(selectDnsSelectedZone);
  const detailsType = useSelector(selectAppDetailsTabType);

  const { translate } = useTranslations('dns');

  const onCreateNewRecord = useCallback(() => {
    selectedTab !== 'records' && setSelectedTab('records');

    selectedZone &&
      dispatch(createDNSRecordSideMenu({ zoneId: selectedZone.id, zoneName: selectedZone.name }));
  }, [dispatch, selectedTab, selectedZone]);

  const tabs: ITabsDefault[] = [
    {
      type: 'default',
      key: 'records',
      title: translate('tabs.records'),
      titleCss: css`
        width: 3.5625rem;
      `,
    },
    {
      type: 'default',
      key: 'zone-properties',
      title: translate('tabs.zone_properties'),
      titleCss: css`
        width: 7rem;
      `,
    },
  ];

  return (
    <Wrapper>
      <ScrollBarContainer>
        <Container>
          <HeaderRow>
            <CustomText
              data-test="dns-content-header"
              type="heading-1"
              color={fonts.title}
              title={selectedZone?.name}
              ellipsis={true}
              css={css`
                margin-left: 0.25rem;
                margin-right: 2rem;
              `}
            >
              {selectedZone?.name}
            </CustomText>
            <CustomButton
              data-test="dns-create"
              kind="primary"
              size="large"
              onClick={onCreateNewRecord}
              disabled={detailsType === 'dns-record-create'}
            >
              {translate('crete_new_record')}
            </CustomButton>
          </HeaderRow>
          <TrialBanner />
          <TabsWrapper>
            <TabButtons
              data-test="dns-tabs"
              tabs={tabs}
              selectedKey={selectedTab}
              onChange={newKey => setSelectedTab(newKey as IDnsTabs)}
              tabCss={css`
                :not(:first-child) {
                  margin-left: 1rem;
                }
              `}
            />
          </TabsWrapper>
          {selectedTab === 'records' ? <Records /> : <ZoneProperties />}
        </Container>
      </ScrollBarContainer>
    </Wrapper>
  );
}, equals);
