// libs
import { takeEvery, all } from 'redux-saga/effects';

// actions
import { redirectControl } from 'redux/actions/app';
import { locationChange } from 'redux/actions/router';

// sagas
import { redirectControlSaga } from './redirectContolSaga';
import { locationChangeSaga } from './locationChangeSaga';

export function* redirectSaga() {
  yield all([
    takeEvery(redirectControl, redirectControlSaga),
    takeEvery(locationChange, locationChangeSaga),
  ]);
}
