// libs
import { css, useTheme } from 'styled-components';

// components
import { CustomText } from 'typography/Text';
import { CustomTable, CustomButtonLink } from 'components';
import { IconEnrolmentKeyDetailsAddIpConstraint } from 'assets/svg';
import { columnsEdit } from './Columns';
import { tableCss } from './styled';

// hooks
import { useDispatch, useSelector } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';

// selectors
import { selectAppDetailsEnrolmentKeyNewIpConstraints } from 'redux/selectors';

// actions
import {
  addValidationStateEntry,
  deleteValidationStateEntry,
  updateEnrolmentKeyDetails,
  updateValidationStateEntry,
} from 'redux/actions/app';

// utils
import { randomHash } from 'utils/hash';

// constants
import { ENROLMENT_KEYS_RANGE_VALIDATION_STATE_PREFIX } from 'appConstants/validation';

export const IpConstraintsEdit = () => {
  const dispatch = useDispatch();

  const { translate } = useTranslations('enrolment-keys');

  const {
    palette: { fills },
  } = useTheme();

  const newIpConstraints = useSelector(selectAppDetailsEnrolmentKeyNewIpConstraints);

  const handleRangeChange = (newRange: string, index: number, isValid: boolean, id?: string) => {
    const error = isValid ? null : { message: translate('details.ip_constraints_range_error') };

    dispatch(
      updateValidationStateEntry({
        key: ENROLMENT_KEYS_RANGE_VALIDATION_STATE_PREFIX + id,
        state: { error },
      })
    );
    if (newIpConstraints !== null && newIpConstraints !== undefined) {
      const updatedIpConstraints = newIpConstraints && Array.from(newIpConstraints);
      updatedIpConstraints[index] = {
        ...updatedIpConstraints[index],
        range: newRange,
      };
      dispatch(updateEnrolmentKeyDetails({ ipConstraints: updatedIpConstraints }));
    }
  };

  const handleDescriptionChange = (newDescription: string, index: number) => {
    const updatedIpConstraints = Array.from(newIpConstraints || []);
    updatedIpConstraints[index] = {
      ...updatedIpConstraints[index],
      description: newDescription,
    };

    dispatch(updateEnrolmentKeyDetails({ ipConstraints: updatedIpConstraints }));
  };

  const handleCreateNewIpConstraint = () => {
    const id = randomHash();
    dispatch(
      addValidationStateEntry({
        key: ENROLMENT_KEYS_RANGE_VALIDATION_STATE_PREFIX + id,
        state: { error: { message: translate('details.ip_constraints_range_error') } },
      })
    );

    newIpConstraints &&
      dispatch(
        updateEnrolmentKeyDetails({
          ipConstraints: [...Array.from(newIpConstraints), { id }],
        })
      );
  };

  const handleRemoveConstraint = (index: number, id?: string) => {
    dispatch(
      deleteValidationStateEntry({ key: ENROLMENT_KEYS_RANGE_VALIDATION_STATE_PREFIX + id })
    );

    const updatedIpConstraints = Array.from(newIpConstraints || []);
    updatedIpConstraints.splice(index, 1);
    dispatch(updateEnrolmentKeyDetails({ ipConstraints: updatedIpConstraints }));
  };

  return (
    <>
      {newIpConstraints?.length !== 0 && (
        <CustomTable
          columns={columnsEdit({
            translate,
            rangeChange: handleRangeChange,
            descriptionChange: handleDescriptionChange,
            handleRemoveConstraint,
          })}
          headerHeight="2.375rem"
          rowHeight="2.375rem"
          css={tableCss}
          rowKey="id"
          dataSource={newIpConstraints ? newIpConstraints : []}
        />
      )}
      <CustomButtonLink
        onClick={() => handleCreateNewIpConstraint()}
        css={css`
          margin-left: 2rem;
          margin-top: 0.75rem;
        `}
      >
        <IconEnrolmentKeyDetailsAddIpConstraint />
        <CustomText
          type="body-1"
          color={fills.primary}
          css={css`
            margin-left: 0.3125rem;
          `}
        >
          {translate('details.add_a_constraint')}
        </CustomText>
      </CustomButtonLink>
    </>
  );
};
