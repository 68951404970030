import { createAction } from '@reduxjs/toolkit';

import {
  IGetPoliciesParams,
  IGetPoliciesResponse,
  IDeletePoliciesParams,
  IEnablePoliciesParams,
  IDisablePoliciesParams,
  IPolicy,
  IDisablePolicyProps,
  IEnablePolicyProps,
  ISearchKey,
  IDeletePolicyProps,
  IEnableUntilPolicyProps,
  EnableUntilType,
} from 'types';

export const setIsLoading = createAction<boolean>('policies/SET_IS_LOADING');
export const setPoliciesShouldUpdate = createAction('policies/SHOULD_UPDATE');

export const getPolicies = createAction<IGetPoliciesParams>('policies/GET_POLICIES');
export const setPolicies = createAction<IGetPoliciesResponse>('policies/SET_POLICIES');

export const createPolicy = createAction('policies/CREATE_POLICY');
export const deletePolicies = createAction<IDeletePoliciesParams>('policies/DELETE_POLICIES');

export const saveUpdatedPolicy = createAction('policies/SAVE_UPDATED_POLICY');
export const updatePolicy = createAction<IPolicy>('policies/UPDATE_POLICY');
export const deletePolicy = createAction<IDeletePolicyProps>('policies/DELETE_POLICY');

export const enablePolicy = createAction<IEnablePolicyProps>('policies/ENABLE_POLICY');
export const enableUntilPolicy = createAction<IEnableUntilPolicyProps & EnableUntilType>(
  'policies/ENABLE_UNTIL_POLICY'
);
export const disablePolicy = createAction<IDisablePolicyProps>('policies/DISABLE_POLICY');

export const enablePolicies = createAction<IEnablePoliciesParams>('policies/ENABLE_POLICIES');
export const disablePolicies = createAction<IDisablePoliciesParams>('policies/DISABLE_POLICIES');

export const getPolicySearchKeys = createAction('policies/GET_SEARCH_KEYS');
export const setPolicySearchKeys = createAction<ISearchKey[]>('policies/SET_SEARCH_KEYS');
