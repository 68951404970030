import { css } from 'styled-components';

export const tableCss = css`
  margin-top: 1.5rem;
  margin-left: 2rem;

  cursor: default;
  background-color: ${({ theme }) => theme.palette.background.panel};

  tbody tr {
    cursor: default;
    background: none !important;
  }

  td {
    height: inherit;
    padding: 0 !important;
  }
  th {
    border-bottom: none !important;
  }
  td:not(:last-child) {
    border-bottom: none !important;
  }
  tbody td:not(:last-child) {
    border: none !important;
  }

  td:last-child {
    border-bottom: 0.0625rem solid ${props => props.theme.palette.background.iconBackground};
    border-top: 0.0625rem solid ${props => props.theme.palette.background.iconBackground};

    // so tooltip would be shown
    overflow: visible;
  }
`;
