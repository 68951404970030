// Lib
import { takeEvery } from 'redux-saga/effects';

// Actions
import {
  getOrganisationUsers,
  getOrganisationInvites,
  sendUserInvite,
  cancelUserInvite,
  updateOrganisation,
  getOrganisationPlans,
  removeOrganisationUser,
  getCurrentOrganisation,
} from '../../../actions/organisation';
import { changeCurrentOrganisation } from 'redux/actions/account';

// Sagas
import { getOrganisationUsersSaga } from './getOrganisationUsersSaga';
import { getOrganisationInvitesSaga } from './getOrganisationInvitesSaga';
import { sendUserInviteSaga } from './sendUserInviteSaga';
import { cancelUserInviteSaga } from './cancelUserInviteSaga';
import { changeCurrentOrganisationSaga } from './changeCurrentOrgSaga';
import { updateOrganisationSaga } from './updateOrganisationSaga';
import { getOrganisationPlansSaga } from './getOrganisationPlansSaga';
import { removeOrganisationUserSaga } from './removeOrganisationUserSaga';
import { getCurrentOrganisationSaga } from './getCurrentOrganisationSaga';

function* organisationSaga() {
  yield takeEvery(getOrganisationUsers, getOrganisationUsersSaga);
  yield takeEvery(getOrganisationInvites, getOrganisationInvitesSaga);
  yield takeEvery(sendUserInvite, sendUserInviteSaga);
  yield takeEvery(cancelUserInvite, cancelUserInviteSaga);
  yield takeEvery(changeCurrentOrganisation, changeCurrentOrganisationSaga);
  yield takeEvery(updateOrganisation, updateOrganisationSaga);
  yield takeEvery(getOrganisationPlans, getOrganisationPlansSaga);
  yield takeEvery(removeOrganisationUser, removeOrganisationUserSaga);
  yield takeEvery(getCurrentOrganisation, getCurrentOrganisationSaga);
}

export default organisationSaga;
