import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const StyledNavLink = styled(NavLink)<{ disabled?: boolean; width?: string }>`
  display: inline-flex;
  align-items: center;
  width: ${({ width }) => (width ? width : 'fit-content')};

  span {
    color: ${({ disabled, theme }) =>
      disabled ? theme.palette.fonts.disabled : theme.palette.icons.primary};
  }
  svg path {
    fill: ${({ disabled, theme }) =>
      disabled ? theme.palette.fonts.disabled : theme.palette.icons.primary};
  }

  &:hover {
    span {
      color: ${({ theme }) => theme.palette.fills.primaryHover};
    }
    svg path {
      fill: ${({ theme }) => theme.palette.fills.primaryHover};
    }
  }

  &:focus {
    span {
      color: ${({ theme }) => theme.palette.fills.primaryHover};
    }
    svg path {
      fill: ${({ theme }) => theme.palette.fills.primaryHover};
    }
  }

  &:active {
    span {
      color: ${({ theme }) => theme.palette.fills.primaryPressed};
    }
    svg path {
      fill: ${({ theme }) => theme.palette.fills.primaryPressed};
    }
  }
`;
