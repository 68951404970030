// hooks
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useEffect, useRef, useState } from 'react';

// selectors
import {
  selectDnsZones,
  selectDnsZonesIsLoading,
  selectDnsZonesHasNextPage,
  selectDnsZonesUpdateId,
  selectDnsSelectedZone,
} from 'redux/selectors';

// actions
import { getDnsZones } from 'redux/actions/dns';

// types
import { IDnsParams } from 'types';

// utils
import { equals } from 'ramda';

export const useGetZones = () => {
  const dispatch = useDispatch();

  const [page, setPage] = useState(0);

  const zones = useSelector(selectDnsZones, equals);
  const updateId = useSelector(selectDnsZonesUpdateId);
  const isLoading = useSelector(selectDnsZonesIsLoading);
  const hasNextPage = useSelector(selectDnsZonesHasNextPage);
  const selectedZone = useSelector(selectDnsSelectedZone);

  const mountedRef = useRef<boolean>(false);

  // we are using params to pass selected zone to list because
  // they are faster than redux
  const { zoneId } = useParams<keyof IDnsParams>();

  useEffect(() => {
    dispatch(getDnsZones({ page }));
  }, [dispatch, page]);

  // when we update zones we need to update all pages
  useEffect(() => {
    if (mountedRef.current) {
      for (let i = 0; i <= page; i++) {
        dispatch(getDnsZones({ page: i }));
      }
    }
    mountedRef.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateId]);

  // if we can't find zone in list but we know it exists(because selectedZone exists)
  // then we fetch pages untill we find the one containing our zone
  useEffect(() => {
    const zoneIsNotFound =
      Boolean(zoneId) &&
      Boolean(selectedZone) &&
      !isLoading &&
      !zones.some(zone => zone.id === Number(selectedZone?.id));

    zoneIsNotFound && hasNextPage && setPage(prevPage => prevPage + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zones.length]);

  return { page, setPage };
};
