import styled from 'styled-components';

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  :first-child {
    padding-bottom: 1rem;
    border-bottom: 0.0625rem solid ${({ theme }) => theme.palette.fills.strokeMedium};
  }
  :nth-child(2) {
    padding-top: 1rem;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Flex = styled.div`
  width: 100%;
  display: flex;

  justify-content: space-between;
  flex-direction: row;
`;

export const LinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-left: 0.875rem;

  margin-bottom: 1.125rem;
`;
