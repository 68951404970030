// libs
import { Route, Routes } from 'react-router';

// components
import { OIDCCallback } from '../OIDCCallback';
import { Preloader } from './Preloader';

interface ILoader {
  oidcNormalAuthRedirectRoute: string;
  oidcSilentRedirectRoute: string;
}
export const Loader = ({ oidcNormalAuthRedirectRoute, oidcSilentRedirectRoute }: ILoader) => {
  return (
    <>
      <Preloader />
      <Routes>
        <Route path={oidcNormalAuthRedirectRoute} element={<OIDCCallback silent={false} />} />
        <Route path={oidcSilentRedirectRoute} element={<OIDCCallback silent={true} />} />
      </Routes>
    </>
  );
};
