// libs
import { put, select } from 'typed-redux-saga';

// actions
import {
  pushNotification,
  setModalContent,
  closeDetails,
  setModalIsLoading,
} from 'redux/actions/app';
import { updateTagsId } from 'redux/actions/tags';

// selectors
import { selectAppDetailsTagRef } from 'redux/selectors';

// types
import { IActionPayload, INotificationType, IDeleteTagProps } from 'types';

// utils
import { ApiTypeCaller } from '../../api/utils';
import { translator } from 'utils';

export function* deleteTagSaga({ payload: { tagOrRef } }: IActionPayload<IDeleteTagProps>) {
  yield put(setModalIsLoading(true));

  const selectedTagRef = yield* select(selectAppDetailsTagRef);

  if (selectedTagRef !== undefined && selectedTagRef === tagOrRef) {
    yield* put(closeDetails({}));
  }

  const { result, success } = yield* ApiTypeCaller('deleteTag', { tagOrRef });

  if (success && result) {
    const translate = translator('notifications');

    yield put(setModalContent({ data: null, type: null }));

    yield put(
      pushNotification({
        showType: INotificationType.Success,
        description: translate('tag_deleted'),
      })
    );
  }
  yield put(updateTagsId());
  yield put(setModalIsLoading(false));
}
