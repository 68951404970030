import { css } from 'styled-components';

export const tableCss = css`
  margin-top: 1.5rem;
  margin-left: 2rem;

  cursor: default;

  tbody tr {
    cursor: default;
  }
`;
