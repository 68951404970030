// libs
import { css } from 'styled-components';

// components
import { Select } from 'components';
import { OptionElement } from './OptionElement';
import { Container } from './styled';
import { DeleteTrustRequirementButton } from './DeleteTrustRequirementButton';

// hooks
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTrustRequirementsEmptyText } from '../useTrustRequirementsEmptyText';

// actions
import { getTrustRequirements, setTrustRequirements } from 'redux/actions/trust-requirements';

// selectors
import { selectTrustRequirements } from 'redux/selectors';

// constants
import { initialTrustRequirementsResponse } from 'redux/reducers/trust-requirements';
import { ITEMS_PER_TABLE_PAGE } from 'appConstants';

// types
import { IUsedTrustRequirement, ITrustRequirementBasic } from 'types';

// utils
import { debounce } from 'utils';

interface ISelectSystem {
  trustRequirements: IUsedTrustRequirement[];
  onSelect: (trustRequirement: ITrustRequirementBasic) => void;
  onCancel: () => void;
  placeholder: string;
}

export const SelectTrustRequirement = ({
  trustRequirements,
  onSelect,
  onCancel,
  placeholder,
}: ISelectSystem) => {
  const dispatch = useDispatch();

  // it's query from which we select new trust requirements,
  // we ensure that we don't show alraedy selected trust requirements
  const trustRequirementsQuery = useSelector(selectTrustRequirements).filter(
    queryTrustRequirement =>
      trustRequirements?.every(trustRequirement => trustRequirement.id !== queryTrustRequirement.id)
  );

  const [inputText, setInputText] = useState('');

  const [searchValue, setSearchValue] = useState<null | {
    current: string;
    previous: string;
  }>(null);

  const ref = useRef<HTMLInputElement>(null);

  const fetchData = useCallback(
    () =>
      dispatch(
        getTrustRequirements({
          search: searchValue?.current ?? '',
          page: 0,
        })
      ),
    [dispatch, searchValue]
  );

  useEffect(() => {
    dispatch(fetchData());
  }, [dispatch, fetchData]);

  useEffect(() => {
    return () => {
      dispatch(setTrustRequirements(initialTrustRequirementsResponse));
    };
  }, [dispatch]);

  useEffect(() => {
    ref?.current?.focus();
  }, [ref]);

  const setSearchValueDebounced = debounce((newSearchValue: string) => {
    setInputText(newSearchValue);
    if (
      trustRequirementsQuery.length === ITEMS_PER_TABLE_PAGE ||
      (searchValue && !newSearchValue.includes(searchValue.current))
    ) {
      setSearchValue({
        previous: searchValue?.current || ' ',
        current: newSearchValue,
      });
    }
  }, 250);

  const { emptyText } = useTrustRequirementsEmptyText({
    searchValue: inputText,
    fullWidth: false,
    withLink: false,
    css: css`
      justify-content: center;
      align-items: center;
      text-align: center;
      padding-top: 0;
      height: 11.75rem;
      width: 100%;
    `,
  });

  return (
    <Container>
      <Select
        containerCss={css`
          flex: 1;
        `}
        width="100%"
        ref={ref}
        dataSource={trustRequirementsQuery}
        setSearchValue={setSearchValueDebounced}
        setSelectedValue={onSelect}
        showSearch={true}
        OptionElement={(record, _index, isSelected) => (
          <OptionElement record={record} isSelected={isSelected} />
        )}
        suffix={null}
        height="2.5rem"
        valueField={['description', 'id']}
        titleField="description"
        keyField="id"
        placeholder={placeholder}
        notFoundContent={emptyText}
      />
      <DeleteTrustRequirementButton onClick={onCancel} />
    </Container>
  );
};
