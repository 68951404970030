// components
import { Details, Copy } from 'components';
import { CustomText } from 'typography/Text';

// selectors
import { selectAppDetailsApprovedSystemVirtualNetwork } from 'redux/selectors';

// hooks
import { useCallback, useState } from 'react';
import { useTheme } from 'styled-components';
import { useTranslations } from 'hooks/useTranslations';
import { useSelector } from 'react-redux';

export const VirtualNetworkBlock = () => {
  const { translate } = useTranslations('systems');

  const {
    palette: { fonts },
  } = useTheme();

  const virtualNetwork = useSelector(selectAppDetailsApprovedSystemVirtualNetwork);

  const [visible, setVisible] = useState(false);

  const onMouseEnter = useCallback(() => {
    setVisible(true);
  }, []);
  const onMouseLeave = useCallback(() => {
    setVisible(false);
  }, []);

  return (
    <Details.KeyValue height="2.875rem" title={translate('details.virtual_subnet')}>
      <CustomText type="body-2" color={fonts.title}>
        {virtualNetwork ? (
          <Copy
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            visible={visible}
            height="1.375rem"
            copyingValue={virtualNetwork}
          >
            {virtualNetwork}
          </Copy>
        ) : (
          '-'
        )}
      </CustomText>
    </Details.KeyValue>
  );
};
