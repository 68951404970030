// libs
import { css, useTheme } from 'styled-components';

// components
import { TagSelect } from 'components';
import {
  Traffic,
  Container,
  TagTitleCustomText,
  IconContainer,
  borderRadiusCss,
  TagsContainer as TrafficContainer,
} from '../styled';
import { IconPoliciesTrafficAllowed, IconPoliciesTrafficDenied } from 'assets/svg';
import { SubnetFilterEdit } from './SubnetFilter/Edit';
import { GatewaySelect } from './GatewaySelect';
import { Trans } from 'react-i18next';

// hooks
import { useCallback } from 'react';
import { useTranslations } from 'hooks/useTranslations';
import { useDispatch, useSelector } from 'react-redux';

// selectors
import {
  selectAppDetailCurrentPolicyGateways,
  selectAppDetailsPolicyNewAcls,
  selectAppDetailsPolicyNewSenderTags,
} from 'redux/selectors';

// actions
import { updatePolicyDetails } from 'redux/actions/app';

// types
import { TagReference } from 'types';
import { GatewayPriorityEdit } from './GatewayPriority/Edit';

interface IPolicyMembers {
  isNew?: boolean;
}

export const PolicyMembersEditableContent = ({ isNew = false }: IPolicyMembers) => {
  const dispatch = useDispatch();

  const { translate } = useTranslations('policies');

  const {
    palette: { fonts },
  } = useTheme();

  const senderTagsNew = useSelector(selectAppDetailsPolicyNewSenderTags);
  const gateways = useSelector(selectAppDetailCurrentPolicyGateways);
  const newAcls = useSelector(selectAppDetailsPolicyNewAcls);

  const handleSenderTagUpdate = useCallback(
    (selectedTags: TagReference[]) => {
      dispatch(
        updatePolicyDetails({
          senderTags: selectedTags,
        })
      );
    },
    [dispatch]
  );

  return (
    <Container>
      <TrafficContainer>
        <Traffic>
          <TagTitleCustomText type="body-2" color={fonts.bodyLight}>
            <Trans
              i18nKey={translate('details.gateway_senders')}
              components={[
                <span
                  key={0}
                  css={css`
                    color: ${fonts.help};
                  `}
                />,
              ]}
            />
          </TagTitleCustomText>
          <TagSelect
            width="100%"
            containerCss={css`
              .ant-select-selector {
                ${borderRadiusCss}
              }
            `}
            preSelected={senderTagsNew}
            changeCallback={handleSenderTagUpdate}
          />
        </Traffic>
        <IconContainer>
          {senderTagsNew.length === 0 || newAcls?.length === 0 || gateways.length === 0 ? (
            <IconPoliciesTrafficDenied />
          ) : (
            <IconPoliciesTrafficAllowed
              css={
                isNew
                  ? css`
                      path {
                        fill: ${({ theme }) => theme.palette.fills.strokeMedium};
                      }
                    `
                  : undefined
              }
            />
          )}
        </IconContainer>
        <Traffic>
          <TagTitleCustomText type="body-2" color={fonts.bodyLight}>
            {translate('details.gateway_receivers')}
          </TagTitleCustomText>
          <GatewaySelect />
        </Traffic>
      </TrafficContainer>

      <GatewayPriorityEdit />

      <SubnetFilterEdit />
    </Container>
  );
};
