import { createAction } from '@reduxjs/toolkit';
import {
  ILoadViewEditTagDetailsParams,
  IDetailsTagSetData,
  IDetailsTagExtendData,
  IDetailedTag,
} from 'types';

export const updateTagDetails = createAction<Partial<IDetailedTag>>('main/UPDATE_TAG_DETAILS');

export const setTagDetailsContent = createAction<IDetailsTagSetData>(
  'main/SET_TAG_DETAILS_CONTENT'
);

export const extendTagDetailsContent = createAction<IDetailsTagExtendData>(
  'main/EXTEND_TAG_DETAILS_CONTENT'
);

export const loadTagDetails = createAction<ILoadViewEditTagDetailsParams>('main/LOAD_TAG_DETAILS');

export const createTagSideMenu = createAction('main/CREATE_TAG_SIDE_MENU');
