//@ts-ignore
import { dissoc, modifyPath } from 'ramda';
import { ITrustRequirementDetail, ITrustRequirementDetailModified } from 'types';
import { randomHash } from './hash';

export const modifyTrustRequirementDetails = (
  trustRequirement: ITrustRequirementDetail
): ITrustRequirementDetailModified => {
  return modifyPath(
    ['settings', 'conditions'],
    (conditons: ITrustRequirementDetail['settings']['conditions']) =>
      conditons?.map(condition => ({ ...condition, id: randomHash() })),
    trustRequirement
  );
};

export const demodifyTrustRequirementDetails = (
  trustRequirement: ITrustRequirementDetailModified
): ITrustRequirementDetail => {
  return modifyPath(
    ['settings', 'conditions'],
    (conditons: ITrustRequirementDetail['settings']['conditions']) =>
      conditons?.map(condition => dissoc('id', condition)),
    trustRequirement
  );
};
