import { SyntheticEvent } from 'react';
import { css } from 'styled-components';
import { MyInputProps, Input } from '../Default';
import { ArrowsContainer, IconArrowUp, IconArrowDown, ArrowContainer } from './styled';

interface IInputNumber extends MyInputProps {
  setValue: (value: string) => void;
}

export const InputNumber = ({ setValue, disabled, containerCss, ...props }: IInputNumber) => {
  const onUpClickHandler = (event: SyntheticEvent) => {
    event.preventDefault();
    !disabled && setValue(String(Number(props.value ? props.value : 0) + 1));
  };

  const onDownClickHandler = (event: SyntheticEvent) => {
    event.preventDefault();
    !disabled && setValue(String(Number(props.value ? props.value : 0) - 1));
  };

  return (
    <Input
      textStyle="body-2"
      containerCss={css`
        ${containerCss};
        .ant-input-affix-wrapper {
          padding-top: 0 !important;
          padding-right: 0 !important;
          padding-bottom: 0 !important;
        }
      `}
      onChange={event => setValue(event.target.value)}
      onKeyDown={event => {
        if (event.key === 'ArrowUp') {
          onUpClickHandler(event);
        }
        if (event.key === 'ArrowDown') {
          onDownClickHandler(event);
        }
      }}
      disabled={disabled}
      {...props}
      suffix={
        <ArrowsContainer>
          <ArrowContainer onClick={onUpClickHandler}>
            <IconArrowUp />
          </ArrowContainer>
          <ArrowContainer onClick={onDownClickHandler}>
            <IconArrowDown />
          </ArrowContainer>
        </ArrowsContainer>
      }
    />
  );
};
