// components
import { CustomText } from 'typography/Text';
import { GatewayContent } from './styled';
import { GatewayView } from './View';
import { GatewayEdit } from './Edit';
import { Trans } from 'react-i18next';
import { ExternalLink } from 'components';

// hooks
import { useTheme } from 'styled-components';
import { useTranslations } from 'hooks/useTranslations';
import { useSelector } from 'react-redux';

// selectors
import { selectAppDetailsSystemPlatformType, selectAppDetailsTabType } from 'redux/selectors';
// constants
import { LINK_TO_MESH_DOCS } from 'appConstants';

interface IGatewayBlock {
  isOpen: boolean;
}

export const GatewayBlock = ({ isOpen }: IGatewayBlock) => {
  const { translate } = useTranslations('systems');

  const {
    palette: { fonts },
  } = useTheme();

  const type = useSelector(selectAppDetailsTabType);
  const platformType = useSelector(selectAppDetailsSystemPlatformType);

  return (
    <GatewayContent isOpen={isOpen}>
      {platformType !== 'Linux' ? (
        <CustomText type="body-2" color={fonts.bodyLight}>
          <Trans
            i18nKey={translate('details.gateway.info_edit_not_supported')}
            components={[<ExternalLink key={0} href={LINK_TO_MESH_DOCS} textType="body-2" />]}
          />
        </CustomText>
      ) : (
        <CustomText type="body-2" color={fonts.bodyLight}>
          {translate(type === 'system-view' ? 'details.gateway.info' : 'details.gateway.info_edit')}
        </CustomText>
      )}

      {type === 'system-view' && platformType === 'Linux' && <GatewayView />}
      {type === 'system-edit' && platformType === 'Linux' && <GatewayEdit />}
    </GatewayContent>
  );
};
