// Libs
import { BaseSyntheticEvent, useEffect, useRef } from 'react';

// components
import { CustomMessage, Details } from 'components';
import { UsesRemainingWarningContainer } from './styled';

// hooks
import { useDispatch, useSelector } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';

// selectors
import {
  selectAppDetailsEnrolmentKeyNewDescription,
  selectAppDetailsEnrolmentKeyUsesRemaining,
  selectAppDetailsTabValidationStateEntries,
  selectFormError,
} from 'redux/selectors';

// actions
import {
  addValidationStateEntry,
  clearErrors,
  updateEnrolmentKeyDetails,
  updateValidationStateEntry,
} from 'redux/actions/app';
import { equals } from 'ramda';

// types
import { TextAreaRef } from 'antd/lib/input/TextArea';

const titleErrorKey = 'keyTitle';
const errorFormKey = 'description';

export const TitleEdit = () => {
  const dispatch = useDispatch();

  const { translate } = useTranslations('enrolment-keys');

  const nameError = useSelector(selectFormError(errorFormKey));

  const newDescription = useSelector(selectAppDetailsEnrolmentKeyNewDescription);
  const usesRemaining = useSelector(selectAppDetailsEnrolmentKeyUsesRemaining);
  const validationState = useSelector(
    selectAppDetailsTabValidationStateEntries([titleErrorKey]),
    equals
  );

  const ref = useRef<TextAreaRef>(null);

  useEffect(() => {
    nameError && dispatch(clearErrors());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, newDescription]);

  useEffect(() => {
    dispatch(addValidationStateEntry({ key: titleErrorKey, state: { error: null } }));
  }, [dispatch]);

  const onDescriptionChange = (event: BaseSyntheticEvent) => {
    const description = event.target.value;

    const error = description.trim().length
      ? null
      : { message: translate('details.no_name_error') };

    if (
      validationState?.[titleErrorKey]?.state?.error !== error ||
      !validationState?.[titleErrorKey]?.updated
    ) {
      dispatch(updateValidationStateEntry({ key: titleErrorKey, state: { error } }));
    }

    dispatch(updateEnrolmentKeyDetails({ description }));
  };

  useEffect(() => {
    ref?.current?.focus();
  }, [ref]);

  return (
    <>
      <Details.Input
        ref={ref}
        name={titleErrorKey}
        value={newDescription ?? ''}
        placeholder={translate('details.name_placeholder')}
        onChange={onDescriptionChange}
        error={nameError ? { message: nameError } : validationState?.[titleErrorKey]?.state?.error}
        onBlur={onDescriptionChange}
      />

      {!usesRemaining && (
        <UsesRemainingWarningContainer>
          <CustomMessage type="warning" message={translate('details.exhausted_uses_warning')} />
        </UsesRemainingWarningContainer>
      )}
    </>
  );
};
