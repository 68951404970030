// libs
import styled from 'styled-components';

export const ApproveDeclineSection = styled.section`
  padding: 2.5rem;
  background-color: ${props => props.theme.palette.background.base};
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
`;
