import { IPricingModel, MainState } from 'types';
import { createSelector } from '@reduxjs/toolkit';
import { selectAccountOrganisations } from './account';
import { selectApprovedSystemsTotal, selectUnapprovedSystemsTotal } from './system';
import { ENTERPRISE_PLAN } from 'appConstants';

export const selectCurrentOrganisationExists = (state: MainState) =>
  Boolean(state.Organisation.info.id);
export const selectCurrentOrganisation = (state: MainState) => state.Organisation;
export const selectCurrentOrganisationId = (state: MainState) => state.Organisation.info.id;
export const selectCurrentOrganisationPartnerId = (state: MainState) => {
  return state.Organisation.info.partner?.partnerId;
};
export const selectCurrentOrganisationPlan = (state: MainState) => state.Organisation.info.plan;
export const selectCurrentOrganisationCapacity = (state: MainState) =>
  state.Organisation.info.maxSystems;
export const selectCurrentOrganisationUsedCapacity = (state: MainState) =>
  state.Organisation.info.enrolledSystems;
export const selectCurrentOrganisationBillingIsYearly = (state: MainState) =>
  state.Organisation.info.billingIsPaidYearly || false;
export const selectCurrentOrganisationBillingRate = (state: MainState) => {
  return state.Organisation.info.billingRate;
};
export const selectCurrentOrganisationBillingCurrencySymbol = (state: MainState) => {
  return state.Organisation.info.billingCurrencySymbol;
};

export const selectCurrentOrganisationBillingDisableUpdate = (state: MainState) => {
  return state.Organisation.info.billingDisableUpdate;
};
export const selectManageBillingUrl = (state: MainState) => state.Organisation.manageBillingUrl;
export const selectCurrentOrganisationInfo = createSelector(
  selectCurrentOrganisation,
  org => org.info
);

export const selectCurrentOrganisationUsers = (state: MainState) => state.Organisation.users;
export const selectCurrentOrganisationInvites = (state: MainState) => state.Organisation.invites;
export const selectOrganisationPlans = (state: MainState) => state.Organisation.plans;

export const selectOrganisationPlansTypes = (state: MainState) => {
  if (!state.Organisation.plans) return null;

  const enterprise: IPricingModel = {
    currencySymbol: '$',
    enabled: true,
    rate:
      state.Organisation.info.plan === ENTERPRISE_PLAN
        ? state.Organisation.info.billingRate || 0
        : 0,
  };

  return {
    Starter: state.Organisation.plans.starter,
    Business: state.Organisation.plans.business,
    Enterprise: enterprise,
  };
};

export const selectOrganisationBusinessPlanYearly = (state: MainState) =>
  state.Organisation.plans?.businessYearly;

export const selectLastBillingEvent = createSelector(
  selectOrganisationPlans,
  plans => plans?.lastBillingEvent
);

export const selectCurrentOrganisationName = createSelector(
  selectCurrentOrganisation,
  org => org.info.name
);
export const selectCurrentOrganisationRole = createSelector(
  selectCurrentOrganisationInfo,
  selectAccountOrganisations,
  (currentOrg, orgs) => {
    const org = orgs.find(({ orgId }) => orgId === currentOrg.id);
    if (org) return org.role;
    return null;
  }
);

export const selectCurrentOrganisationIsOwner = createSelector(
  selectCurrentOrganisationInfo,
  selectAccountOrganisations,
  (currentOrg, orgs) => {
    const org = orgs.find(({ orgId }) => orgId === currentOrg.id);
    if (org) return org.role === 'Owner';
    return false;
  }
);

export const selectCurrentOrgSystems = createSelector(
  selectCurrentOrganisationInfo,
  selectApprovedSystemsTotal,
  selectUnapprovedSystemsTotal,
  (org, enrolledMetaTotal, unapprovedMetaTotal) => ({
    enrolled: org.enrolledSystems,
    enrolledMetaTotal,
    unapproved: org.unapprovedSystems,
    unapprovedMetaTotal,
    capacity: org.maxSystems,
  })
);

export const selectCurrentOrgSystemsUnapproved = createSelector(
  selectCurrentOrganisationInfo,
  org => org.unapprovedSystems
);

export const selectCurrentOrganisationHasDnsSpaceLeft = (state: MainState) => {
  const featureLimits = state.Organisation?.info?.featureLimits?.find(
    ({ type }) => type === 'CustomDnsZones'
  );

  if (!featureLimits || featureLimits?.hasBeenFullyUsed) {
    return false;
  }

  return true;
};

export const selectCurrentOrganisationHasAdminSlotsLeft = (state: MainState) => {
  const featureLimits = state.Organisation?.info?.featureLimits?.find(
    ({ type }) => type === 'MultipleAdmins'
  );

  if (!featureLimits || featureLimits?.hasBeenFullyUsed) {
    return false;
  }

  return true;
};

export const selectCurrentOrganisationTrialState = (state: MainState) => {
  return state.Organisation.info.trialState;
};

export const selectCurrentOrganisationTrialExpiry = (state: MainState) => {
  return state.Organisation.info.trialExpiry;
};

export const selectCurrentOrganisationTrialIsSuspendedByAdmin = (state: MainState) => {
  return state.Organisation.info.isSuspendedByAdmin;
};
