// Lib
import { takeEvery } from 'redux-saga/effects';

// Actions
import { go, goBack, goForward, push, replace } from 'redux/actions/router';

// Sagas
import { routerGoBackSaga } from './goBackSaga';
import { routerGoForwardSaga } from './goForwardSaga';
import { routerGoSaga } from './goSaga';
import { routerPushSaga } from './pushSaga';
import { routerReplaceSaga } from './replaceSaga';

function* routerEventsSaga() {
  yield takeEvery(go, routerGoSaga);
  yield takeEvery(push, routerPushSaga);
  yield takeEvery(replace, routerReplaceSaga);
  yield takeEvery(goBack, routerGoBackSaga);
  yield takeEvery(goForward, routerGoForwardSaga);
}

export default routerEventsSaga;
