// libs
import { put } from 'typed-redux-saga';

// Actions
import { setOrganisationPlans } from '../../../actions/organisation';

// Utils
import { ApiTypeCaller } from '../../api/utils';

export function* getOrganisationPlansSaga() {
  const { success, result } = yield* ApiTypeCaller('getOrganisationPlans');

  if (success && result) {
    yield put(setOrganisationPlans(result));
  }
}
