// libs
import { css, useTheme } from 'styled-components';

// hooks
import { useSelector } from 'react-redux';

// components
import { Tag } from './Tag';
import { CustomText } from 'typography/Text';
import { ReactComponent as IconUser } from 'assets/svg/settings-user.svg';
import { UserActionDropdown } from './UserActionDropdown';
import { UserColumn, UserRow } from '../styled';

// selectors
import { selectCurrentOrganisationUsers } from 'redux/selectors';

export const UserRows = () => {
  const users = useSelector(selectCurrentOrganisationUsers);

  const {
    palette: { fills, icons },
  } = useTheme();

  return (
    <>
      {users.map(({ emailAddress, fullName, role, id }) => (
        <UserRow key={id}>
          <UserColumn col={1}>
            <IconUser
              css={css`
                margin-right: 1rem;
                circle {
                  fill: ${role === 'Owner' ? fills.iconHover : ''};
                }
                path {
                  fill: ${role === 'Owner' ? icons.primary : ''};
                }
              `}
            />
            <CustomText type="body-1">{emailAddress}</CustomText>
          </UserColumn>
          <UserColumn col={2}>
            <CustomText type="body-1">{fullName}</CustomText>
          </UserColumn>
          <UserColumn col={3}>
            <Tag>{role}</Tag>
          </UserColumn>
          <UserColumn col={4}>
            {role !== 'Owner' && (
              <UserActionDropdown id={id} fullName={fullName} userName={emailAddress} />
            )}
          </UserColumn>
        </UserRow>
      ))}
    </>
  );
};
