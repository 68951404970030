// components
import { CustomText } from 'typography/Text';
import { Container, Header } from './styled';
import { AddZoneIconButton } from './AddZonesIconButton';
import { ZonesList } from './List';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { useTheme } from 'styled-components';

export const Zones = () => {
  const {
    palette: { fonts },
  } = useTheme();

  const { translate } = useTranslations('dns');

  return (
    <Container>
      <Header>
        <CustomText data-test="dns-zones-header" type="sub-heading-1" color={fonts.title}>
          {translate('zones.title')}
        </CustomText>
        <AddZoneIconButton />
      </Header>
      <ZonesList />
    </Container>
  );
};
