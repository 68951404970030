// Lib
import { put, select, call } from 'typed-redux-saga';
import { omit } from 'ramda';

import { setDetailsContent } from 'redux/actions/app';

import { orgPush } from '../../utils';

import { selectPathName } from 'redux/selectors';
import { IActionPayload, ICreateEnrolmentKeyNewData, ICreateEnrolmentKeyPayload } from 'types';
import { ApiTypeCaller } from 'redux/sagas/api/utils';

export function* createEnrolmentKeyDetailsSaga({
  payload,
}: IActionPayload<ICreateEnrolmentKeyPayload | undefined>) {
  const enrolmentKeyId = payload?.enrolmentKeyId;
  const pathName = yield* select(selectPathName);

  if (new RegExp(/(keys\/\d+)/).test(pathName)) yield call(orgPush, `/keys`);

  let dataNew: ICreateEnrolmentKeyNewData = {
    approvalMode: 'Manual',
    description: '',
    tags: [],
    usesRemaining: -1,
    ipConstraints: [],
    type: 'GeneralPurpose',
    autoExpire: null,
  };

  if (enrolmentKeyId) {
    const { result } = yield* ApiTypeCaller('getEnrolmentKey', { enrolmentKeyId });

    if (result) {
      const newDescription = `${result.description} (copy)`;
      dataNew = { ...omit(['id', 'created'], result), description: newDescription };
    }
  }

  yield put(
    setDetailsContent({
      type: 'enrolment-key-create',
      dataNew,
      validationState: {},
    })
  );
}
