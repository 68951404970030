// components
import { Range } from './Type';
import { Description } from './Description';
import { ColumnTitle } from 'components';

// hooks
import { useTranslations } from 'hooks/useTranslations';

// types
import { IPolicyModifiedGatewayAllowedIpRange } from 'types';
import { ColumnsType } from 'antd/lib/table';

export const useColumnsView = (): ColumnsType<{
  id: IPolicyModifiedGatewayAllowedIpRange['id'];
  ipRange: IPolicyModifiedGatewayAllowedIpRange['ipRange'];
  description: IPolicyModifiedGatewayAllowedIpRange['description'] | null;
}> => {
  const { translate } = useTranslations('policies');

  return [
    {
      title: <ColumnTitle>{translate('details.subnets_table.allowed_ranges')}</ColumnTitle>,
      dataIndex: 'ipRange',
      width: '50%',
      render: (range: IPolicyModifiedGatewayAllowedIpRange['ipRange']) => <Range range={range} />,
    },
    {
      title: <ColumnTitle>{translate('details.subnets_table.description')}</ColumnTitle>,
      dataIndex: 'description',
      width: '50%',
      render: (description: IPolicyModifiedGatewayAllowedIpRange['description']) => (
        <Description>{description}</Description>
      ),
    },
  ];
};
