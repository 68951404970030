// components
import { Details } from 'components';
import { RecordTypeView } from './View';
import { RecordTypeCreate } from './Create';

// hooks
import { useSelector } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';

// selectors
import { selectAppDetailsTabType } from 'redux/selectors';

export const RecordType = () => {
  const type = useSelector(selectAppDetailsTabType);
  const { translate } = useTranslations('dns');

  return (
    <Details.KeyValue height="2.875rem" title={translate('records.details.record_type')}>
      {(type === 'dns-record-view' || type == 'dns-record-edit') && <RecordTypeView />}
      {type === 'dns-record-create' && <RecordTypeCreate />}
    </Details.KeyValue>
  );
};
