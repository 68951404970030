// сomponents
import { MouseEventHandler, SyntheticEvent } from 'react';
import { Tag, TagIconCloseContainer } from './styled';
import { CustomText } from 'typography/Text';
import { IconTagClose } from 'assets/svg';

// types
import { css, CSSProp, useTheme } from 'styled-components';
import { ITag, TagReference } from 'types';
import { TAGS_DEFAULT_COLOUR } from 'appConstants/tags';

// utils
import { getColourByBgColour } from 'utils/getColourByBgColour';

interface ICustomTag {
  tag: ITag | TagReference;
  selectable?: boolean;
  withCloseIcon?: boolean;
  onCloseClick?: MouseEventHandler<HTMLDivElement>;
  customTagBoxCss?: CSSProp;
  customTagCss?: CSSProp;
  disableHoverEffect?: boolean;
}

const handleTagSelectionClick = (event: SyntheticEvent) => {
  event.stopPropagation();

  const selection = getSelection();
  const preselected = selection?.getRangeAt(0);
  const node = event.target as Node;
  if (preselected && preselected.startOffset !== preselected.endOffset) return;
  if (!selection || !node) return;
  selection.removeAllRanges();
  const range = document.createRange();
  range.selectNode(node);
  selection.addRange(range);
};

const handleMouseDown = (event: SyntheticEvent) => event.stopPropagation();
const handleMouseUp = (event: SyntheticEvent) => event.stopPropagation();

export const CustomTag = ({
  tag,
  withCloseIcon = false,
  customTagCss,
  customTagBoxCss,
  selectable = true,
  onCloseClick,
  disableHoverEffect,
  ...rest
}: ICustomTag) => {
  const {
    palette: { fonts },
  } = useTheme();

  return (
    <Tag
      onClick={selectable ? handleTagSelectionClick : undefined}
      onMouseDown={selectable ? handleMouseDown : undefined}
      onMouseUp={selectable ? handleMouseUp : undefined}
      color={tag?.colour || TAGS_DEFAULT_COLOUR}
      $hoverEffect={!disableHoverEffect}
      css={customTagBoxCss}
      {...rest}
    >
      <CustomText
        ellipsis={true}
        type="body-2"
        title={tag?.tag}
        color={getColourByBgColour(
          tag?.colour || TAGS_DEFAULT_COLOUR,
          fonts.bodyDark,
          fonts.primaryButton
        )}
        css={css`
          max-width: 100%;
          ${customTagCss}
        `}
      >
        {tag?.tag}
      </CustomText>
      {withCloseIcon && (
        <TagIconCloseContainer onClick={onCloseClick} data-test="tags-select-selected-item-remove">
          <IconTagClose
            width="0.75rem"
            height="0.75rem"
            css={css`
              path {
                fill: ${fonts.bodyDark};
              }
            `}
          />
        </TagIconCloseContainer>
      )}
    </Tag>
  );
};
