import styled from 'styled-components';

export const CodesDescWrapper = styled.div`
  padding: 0.5625rem 1rem;
  background-color: ${({ theme }) => theme.palette.background.hover};
  margin-bottom: 1.5rem;
`;

export const ImageQRfor2FA = styled.img`
  align-self: center;
  margin: 0.75rem 0;
`;
