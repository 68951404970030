import { createAction } from '@reduxjs/toolkit';
import {
  ILoadSystemDetails,
  ILoadUnapprovedSystemDetails,
  ISystemModelModified,
  ISystemGatewayRouteModified,
} from 'types';

export const updateSystemDetails = createAction<Partial<ISystemModelModified>>(
  'main/UPDATE_DETAILS_PROPS'
);
export const loadSystemDetails = createAction<ILoadSystemDetails>('main/LOAD_SYSTEM_DETAILS');

export const loadUnapprovedSystemDetails = createAction<ILoadUnapprovedSystemDetails>(
  'main/LOAD_UNAPPROVED_SYSTEM_DETAILS'
);

export const setSystemDetailsKnownRoutes =
  createAction<ISystemModelModified['knownSubnets']>('main/SET_KNOWN_ROUTES');
export const setSystemDetailsRoutes =
  createAction<ISystemModelModified['gatewayRoutes']>('main/SET_ROUTES');
export const deleteSystemDetailsRoute =
  createAction<Pick<ISystemGatewayRouteModified, 'id'>>('main/DELETE_ROUTE');
export const createSystemDetailsRoute = createAction('main/CREATE_ROUTE');
export const updateSystemDetailsRoute =
  createAction<Pick<ISystemGatewayRouteModified, 'id' | 'subnet' | 'name' | 'weight'>>(
    'main/UPDATE_ROUTE'
  );

export const createSystemDetailsAutoExpire = createAction('main/CREATE_SYSTEM_DETAILS_AUTO_EXPIRE');
export const updateSystemDetailsAutoExpire = createAction<
  Partial<ISystemModelModified['autoExpire'] | null>
>('main/UPDATE_SYSTEM_DETAILS_AUTO_EXPIRE');
