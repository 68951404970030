import styled from 'styled-components';

export const Container = styled.div`
  padding: 0.5rem;
  margin-left: -0.5rem;
  border-radius: 0.25rem;
  background-color: ${({ theme }) => theme.palette.background.base};
  display: flex;
  flex-direction: row;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.25rem;

  :not(:first-child) {
    margin-top: 0.25rem;
  }
`;

export const SubnetTextContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  margin-left: 1.25rem;
  margin-top: 0.25rem;
`;

export const CloseButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;

  margin-left: 0.75rem;
`;

export const SelectIconContainer = styled.div`
  display: inline-block;
  position: relative;
  margin-left: 0.25rem;
  top: 0.25rem;

  svg {
    height: 1.2rem;
  }
`;
