import styled, { css } from 'styled-components';
import { stylesMap } from 'typography/Text';

export const FilterAndBulkActionsContainer = styled.div`
  height: 2.125rem;
  margin-bottom: 1rem;
`;

export const HeaderRow = styled.div`
  width: 100%;
  margin-bottom: 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const tableCss = css`
  table {
    table-layout: fixed !important;
  }

  .ant-table-selection-col {
    // this class sets width to first col group
    width: auto;
  }

  // first and last element should take all the space
  // so when you click within first column(select-row)
  // but outside of checkbox itself it would select row.
  // And when you click outside of last column it should do
  // nothing rather than open details

  td:first-child,
  td:last-child {
    height: inherit;
    padding: 0 !important;
  }

  td:last-child,
  th:last-child {
    width: 4rem;
    max-width: 4rem;

    ${({ theme }) => theme.media_queries.s1440} {
      width: 5.25rem;
      max-width: 5.25rem;
    }
  }

  td:nth-child(1),
  th:nth-child(1) {
    width: 2.5rem;
    max-width: 2.5rem;

    ${({ theme }) => theme.media_queries.s1440} {
      width: 4.1875rem;
      max-width: 4.1875rem;
    }
  }

  td:nth-child(2),
  th:nth-child(2) {
    width: 187.5%;

    ${({ theme }) => theme.media_queries.s1440} {
      width: auto;
    }

    ${({ theme }) => theme.media_queries.s1600} {
      width: 40.75rem;
    }
  }

  td:nth-child(3),
  th:nth-child(3) {
    width: 276.25%;

    ${({ theme }) => theme.media_queries.s1440} {
      width: 27.625rem;
    }

    ${({ theme }) => theme.media_queries.s1600} {
      width: 276.25%;
    }
  }

  td:nth-child(4),
  th:nth-child(4) {
    width: 80%;

    ${({ theme }) => theme.media_queries.s1440} {
      width: 8rem;
    }

    ${({ theme }) => theme.media_queries.s1600} {
      width: 80%;
    }
  }

  td:nth-child(5),
  th:nth-child(5) {
    width: 80%;

    ${({ theme }) => theme.media_queries.s1440} {
      width: 8rem;
    }

    ${({ theme }) => theme.media_queries.s1600} {
      width: 80%;
    }
  }
`;

export const CreateTrustRequirement = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    margin-left: 0.25rem;
  }
  cursor: pointer;

  color: ${({ theme }) => theme.palette.icons.primary};
  ${stylesMap['body-1']}
`;
