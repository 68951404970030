import React from 'react';
// Components
import { CustomModal } from 'components';
import {
  PasswordModal,
  Disable2FAModal,
  RecoveryCodesModal,
  Enable2FAModal,
  CreatePATokenModal,
  SavePATokenModal,
  DeletePATokenModal,
  ReAuthModal,
} from 'modules/Account/components';
import { RemoveEnrolledModal } from 'modules/Systems/RemoveEnrolledModal';
import { UpgradePlanModal } from 'modules/Settings/components/PlansNSubs/components/UpgradePlanModal';

// Hooks
import { useSelector } from 'react-redux';

// Selectors
import { selectModalType } from 'redux/selectors';
import { ViewActualKeyModal } from 'modules/EnrolmentKeys/Details/ViewActualKeyModal';
import { RemovePolicyModal } from 'modules/Policies/RemovePolicyModal';
import { RemoveUserModal } from 'modules/Settings/components/Users/components/RemoveUserModal';
import { CreateZoneModal } from 'modules/Dns/Zones/CreateNewZoneModal';
import { RemoveDnsRecordsModal } from 'modules/Dns/Content/Records/RemoveDnsRecordsModal';
import { RenameZoneModal } from 'modules/Dns/Content/ZoneProperties/DangerZone/RenameZoneModal';
import { DeleteZoneModal } from 'modules/Dns/Content/ZoneProperties/DangerZone/DeleteZoneModal';
import { JoinOrgModal } from '../OrgSelect/JoinOrgModal';
import { RemoveTrustRequirementsModal } from 'modules/TrustRequirements/RemoveTrustRequirementsModal';
import { DeleteTagsModal } from 'modules/Tags/DeleteTagsModal';
import { RemoveEnrolmemntKeyModal } from 'modules/EnrolmentKeys/RemoveEnrolmentKeyModal';

export const Modal = () => {
  const type = useSelector(selectModalType);

  const ModalContent = () => {
    switch (type) {
      case 'systems-remove':
        return <RemoveEnrolledModal />;
      case 'policies-remove':
        return <RemovePolicyModal />;
      case 'password-change':
        return <PasswordModal />;
      case 'enable-2FA':
        return <Enable2FAModal />;
      case 'disable-2FA':
        return <Disable2FAModal />;
      case 'recovery-codes':
        return <RecoveryCodesModal />;
      case 're-auth-request':
        return <ReAuthModal />;
      case 'create-PAToken':
        return <CreatePATokenModal />;
      case 'save-PAToken':
        return <SavePATokenModal />;
      case 'delete-PAToken':
        return <DeletePATokenModal />;
      case 'view-actual-key':
        return <ViewActualKeyModal />;
      case 'update-plan-capacity':
        return <UpgradePlanModal />;
      case 'remove-user':
        return <RemoveUserModal />;
      case 'create-new-zone':
        return <CreateZoneModal />;
      case 'remove-dns-records':
        return <RemoveDnsRecordsModal />;
      case 'rename-dns-zone':
        return <RenameZoneModal />;
      case 'delete-dns-zone':
        return <DeleteZoneModal />;
      case 'tag-delete':
        return <DeleteTagsModal />;
      case 'join-org':
        return <JoinOrgModal />;
      case 'remove-trust-requirements':
        return <RemoveTrustRequirementsModal />;
      case 'remove-enrolment-keys':
        return <RemoveEnrolmemntKeyModal />;
      default:
        return null;
    }
  };

  return (
    <CustomModal visible={type !== null}>
      <ModalContent />
    </CustomModal>
  );
};
