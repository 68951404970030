// libs
import { put } from 'typed-redux-saga';
import { setEnrolmentKeySearchKeys } from 'redux/actions/enrolment-keys';

// api
import { ApiTypeCaller } from '../../api/utils';

export function* getEnrolmentKeySearchKeysSaga() {
  const { result, success } = yield* ApiTypeCaller('getEnrolmentKeySearchKeys');

  if (success && result) yield put(setEnrolmentKeySearchKeys(result));
}
