import styled, { css } from 'styled-components';

export const StyledEmptyWrapper = styled.div<{ transparent?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 31.375rem;
  padding-top: 7.5rem;
  background-color: ${({ theme, transparent }) =>
    transparent ? 'transparent' : theme.palette.background.panel};

  cursor: default;
`;

export const extraTextStyles = css`
  max-width: 32rem;
  white-space: pre-wrap;
  margin: 0 auto 0.5rem;
`;

export const titleTextStyles = css`
  max-width: 14.5rem;
  white-space: pre-wrap;
  margin: 1.5rem auto 0.5rem;
`;
