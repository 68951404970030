// components
import { Type } from './Type';
import { Ports } from './Ports';
import { Protocol } from './Protocol';
import { CloseButton } from './CloseButton';
import { Description } from './Description';
import { Container, ContentContainer, Row } from './styled';

// types
import { IPolicyModifiedAcl } from 'types';

interface IAclEditable {
  acl: IPolicyModifiedAcl;
}

export const AclEditable = ({ acl }: IAclEditable) => {
  return (
    <Container>
      <ContentContainer>
        <Row>
          <Type acl={acl} />
          <Protocol acl={acl} />
          <Ports acl={acl} />
        </Row>
        <Row>
          <Description acl={acl} />
        </Row>
      </ContentContainer>
      <CloseButton acl={acl} />
    </Container>
  );
};
