// Lib
import { put, all } from 'typed-redux-saga';

// Actions
import { setSystems } from 'redux/actions/systems';
import { setSystems as setUnapprovedSystems } from 'redux/actions/unapproved-systems';
import { setEnrolmentKeys } from 'redux/actions/enrolment-keys';
import { setPolicies } from 'redux/actions/policies';
import { setTags } from 'redux/actions/tags';
import { setSecurityEvents } from 'redux/actions/security-events';

import { initialSystemsResponse } from 'redux/reducers/systems';
import { initialSystemsResponse as initialUnapprovedSystemsResponse } from 'redux/reducers/unapproved-systems';
import { initialEnrolmentKeysResponse } from 'redux/reducers/enrolment-keys';
import { initialPoliciesResponse } from 'redux/reducers/policies';
import { initialTagsResponse } from 'redux/reducers/tags';
import { initialSecurityEventsResponse } from 'redux/reducers/security-events';

export function* clearOrgDataSaga() {
  yield all([
    put(setSystems(initialSystemsResponse)),
    put(setUnapprovedSystems(initialUnapprovedSystemsResponse)),
    put(setEnrolmentKeys(initialEnrolmentKeysResponse)),
    put(setPolicies(initialPoliciesResponse)),
    put(setTags(initialTagsResponse)),
    put(setSecurityEvents(initialSecurityEventsResponse)),
  ]);
}
