import styled from 'styled-components';

export const TypesContainer = styled.div`
  margin-top: 1.5rem;
`;

export const TypeContainer = styled.button`
  cursor: ${({ disabled }) => !disabled && 'pointer'};

  outline: none;
  text-align: initial;

  display: flex;
  flex-direction: row;
  padding: 1rem;
  margin-bottom: 1rem;
  border: 0.0625rem solid ${({ theme }) => theme.palette.fills.strokeMedium};
  border-radius: 0.25rem;

  :not(:disabled) {
    :hover {
      border-color: ${({ theme }) => theme.palette.fills.primary};
    }

    :focus {
      border-color: ${({ theme }) => theme.palette.fills.primaryHover};
    }

    .select-type-link {
      span {
        color: ${({ theme }) => theme.palette.fills.primary} !important;
      }

      svg path {
        fill: ${({ theme }) => theme.palette.fills.primary} !important;
      }
    }

    :hover,
    :focus {
      .select-type-link {
        span {
          color: ${({ theme }) => theme.palette.fills.primaryHover} !important;
        }

        svg path {
          fill: ${({ theme }) => theme.palette.fills.primaryHover} !important;
        }
      }
    }
  }
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.palette.background.panel : theme.palette.background.base};
`;

export const TypeIconContainer = styled.div<{ isActive: boolean }>`
  svg {
    box-sizing: content-box;
    width: 3.75rem;
    height: 3.75rem;

    margin-right: 1rem;

    path {
      fill: ${({ theme, isActive }) => isActive && theme.palette.icons.primary};
    }
  }
`;

export const TypeContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LinkLikeTextContainer = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  align-self: end;
`;
