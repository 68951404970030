import { Helmet } from 'react-helmet-async';

interface IPageTitleProps {
  title: string | null;
}
const defaultTitle = process.env.ENC_APP_TITLE;

export const PageTitle = ({ title }: IPageTitleProps) => {
  return (
    <Helmet>
      <title>{title ? `${defaultTitle} | ${title}` : defaultTitle}</title>
    </Helmet>
  );
};
