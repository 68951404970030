// components
import { TagSelect } from 'components';

// selectors
import { selectAppDetailsSystemTags } from 'redux/selectors';

// hooks
import { useSelector, useDispatch } from 'react-redux';

// actions
import { updateSystemDetails } from 'redux/actions/app';

// utils
import { TagReference } from 'types';

export const TagsEdit = () => {
  const dispatch = useDispatch();

  const systemTags = useSelector(selectAppDetailsSystemTags) || [];

  const updateTagsCallback = (tags: TagReference[]) => {
    dispatch(
      updateSystemDetails({
        tags,
      })
    );
  };

  return <TagSelect preSelected={systemTags} changeCallback={updateTagsCallback} />;
};
