import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { useTranslations } from 'hooks/useTranslations';
import { selectUnapprovedSystemsIsLoading } from 'redux/selectors';
import { useEmptyText } from 'hooks/useEmptyText';
import { USYSTEMS_MODULE } from 'appConstants';

type Props = {
  searchValue: string;
};

export const useUnapprovedSystemsEmptyText = ({ searchValue }: Props) => {
  const { translate } = useTranslations('systems');
  const isLoading = useSelector(selectUnapprovedSystemsIsLoading);

  const noDataText = useMemo(
    () => ({ title: translate('no_enrolled_u'), description: translate('start_using_u') }),
    [translate]
  );

  const noDataFoundText = useMemo(
    () => ({
      title: translate('no_data_found'),
      description: translate('search_in_docs'),
    }),
    [translate]
  );

  const { emptyText } = useEmptyText({
    searchValue,
    noDataText,
    noDataFoundText,
    isLoading,
    module: USYSTEMS_MODULE,
  });

  return {
    emptyText,
  };
};
