// Lib
import { put } from 'typed-redux-saga';

// Actions
import {
  addDnsZonesDataPage,
  setDnsZones,
  setRecordsIsLoading,
  setZonesIsLoading,
} from 'redux/actions/dns';

// Types
import { IActionPayload, IGetDnsZonesParams } from 'types';

// Utils
import { ApiTypeCaller } from '../../api/utils';

// constants
import { ITEMS_PER_DNS_ZONES_PAGE } from 'appConstants';

export function* getZonesSaga({
  payload: { page, per_page = ITEMS_PER_DNS_ZONES_PAGE },
}: IActionPayload<IGetDnsZonesParams>) {
  if (page === 0) {
    yield put(setRecordsIsLoading(true));
    yield put(setZonesIsLoading(true));
  }

  const { result, success } = yield* ApiTypeCaller('getDnsZones', { page, per_page });

  if (success && result) {
    yield put(setDnsZones(result));
    yield put(addDnsZonesDataPage({ page: page || 0, data: result.items }));
  }
}
