import { CustomText } from 'typography/Text';
import { CustomButton, BulkClearButton } from 'components';
import { css, useTheme } from 'styled-components';

// Hooks
import { useTranslations } from 'hooks/useTranslations';
import { UnapprovedSystemRecordActions, IUnapprovedSystem } from 'types';
import { BulkActionsContainer } from 'components/BulkActions/styled';

interface IUActionButtonProps {
  selectedSystems: IUnapprovedSystem[];
  resetSelection: () => void;
  onActionClick: (type: UnapprovedSystemRecordActions) => void;
}

export const BulkUSystemActions = ({
  selectedSystems,
  resetSelection,
  onActionClick,
}: IUActionButtonProps) => {
  const { translate } = useTranslations('systems');
  const {
    palette: { fonts },
  } = useTheme();
  const { translate: translateGlobal } = useTranslations('global');

  return (
    <BulkActionsContainer data-test="unapproved-systems-bulk-actions">
      <CustomText type="sub-heading-2" color={fonts.title}>
        {selectedSystems.length}{' '}
        {selectedSystems.length !== 1
          ? translateGlobal('bulk.items_selected', {
              module: translate('modules.items'),
            })
          : translateGlobal('bulk.item_selected', {
              module: translate('modules.item'),
            })}
      </CustomText>
      <BulkClearButton type="link" onClick={resetSelection}>
        {translateGlobal('bulk.clear_all')}
      </BulkClearButton>

      <CustomButton
        kind="primary"
        onClick={() => onActionClick('approve')}
        css={css`
          margin-right: 1rem;
        `}
      >
        {translateGlobal('bulk.approve')}
      </CustomButton>

      <CustomButton
        kind="primaryDanger"
        onClick={() => onActionClick('reject')}
        css={css`
          margin-right: 1rem;
        `}
      >
        {translateGlobal('bulk.reject')}
      </CustomButton>
    </BulkActionsContainer>
  );
};
