// Lib
import { takeEvery, takeLatest } from 'redux-saga/effects';

// Actions
import {
  getPolicies,
  createPolicy,
  deletePolicies,
  deletePolicy,
  enablePolicy,
  disablePolicy,
  enablePolicies,
  disablePolicies,
  saveUpdatedPolicy,
  getPolicySearchKeys,
  enableUntilPolicy,
} from 'redux/actions/policies';
import { createPolicySideMenu } from 'redux/actions/app';

// Sagas
import { getPoliciesSaga } from './getPoliciesSaga';
import { createPolicySaga } from './createPolicySaga';
import { deletePoliciesSaga } from './deletePoliciesSaga';
import { saveUpdatedPolicySaga } from './saveUpdatedPolicySaga';
import { deletePolicySaga } from './deletePolicySaga';
import { enableDisablePolicySaga } from './enableDisablePolicySaga';
import { enableDisablePoliciesSaga } from './enableDisablePoliciesSaga';
import { createPolicyDetailsSaga } from './createPolicyDetailsSaga';
import { getPolicySearchKeysSaga } from './getPolicySearchKeysSaga';
import { enablePolicyUntilSaga } from './EnablePolicySagaUntil';

function* policiesSaga() {
  yield takeLatest(getPolicies, getPoliciesSaga);
  yield takeEvery(createPolicy, createPolicySaga);
  yield takeEvery(deletePolicies, deletePoliciesSaga);
  yield takeEvery(saveUpdatedPolicy, saveUpdatedPolicySaga);
  yield takeEvery(deletePolicy, deletePolicySaga);
  yield takeEvery(enablePolicy, payload => enableDisablePolicySaga(payload, true));
  yield takeEvery(enableUntilPolicy, enablePolicyUntilSaga);
  yield takeEvery(disablePolicy, payload => enableDisablePolicySaga(payload, false));
  yield takeEvery(enablePolicies, payload => enableDisablePoliciesSaga(payload, true));
  yield takeEvery(disablePolicies, payload => enableDisablePoliciesSaga(payload, false));
  yield takeEvery(createPolicySideMenu, createPolicyDetailsSaga);
  yield takeEvery(getPolicySearchKeys, getPolicySearchKeysSaga);
}

export default policiesSaga;
