import styled, { css } from 'styled-components';

export const TagListWrapper = styled.div<{ width?: string; blocksMode?: boolean }>`
  width: ${({ width }) => width};
  align-items: center;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  ${({ blocksMode }) =>
    blocksMode
      ? css`
          span {
            display: block;
            width: fit-content;
            margin-bottom: 0.25rem;
          }
        `
      : css`
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          gap: 0.25rem;
        `}
`;

export const tagCss = css`
  margin-right: 0px;
`;
