// components
import { CustomText } from 'typography/Text';
import { TypesContainer } from './styled';
import { Type } from './Type';
import { Details, SpanStyledAsLargeInput } from 'components';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { css, useTheme } from 'styled-components';

// constants
import { TRUST_REQUIREMENTS_TYPES } from 'appConstants';

export const SelectType = () => {
  const { translate } = useTranslations('trust-requirements');

  const {
    palette: { fonts },
  } = useTheme();

  return (
    <Details.HeaderSection>
      <SpanStyledAsLargeInput
        type="heading-2"
        color={fonts.bodyDark}
        css={css`
          margin-bottom: 0.625rem;
          padding: 0;
          min-height: auto;
        `}
      >
        {translate('details.select_type.title')}
      </SpanStyledAsLargeInput>
      <CustomText type="body-2" color={fonts.bodyLight}>
        {translate('details.select_type.description')}
      </CustomText>

      <TypesContainer>
        {TRUST_REQUIREMENTS_TYPES.map(type => (
          <Type key={type.name} config={type} />
        ))}
      </TypesContainer>
    </Details.HeaderSection>
  );
};
