import { useTheme } from 'styled-components';
import { AlertContainer } from './styled';
import { CustomText } from 'typography/Text';
import { Loading3QuartersOutlined } from '@ant-design/icons';
import { CustomButtonLink } from 'components';

interface IAlertAction {
  text: string;
  onClick: () => void;
}

export interface IAlertProps {
  message: string;
  action?: IAlertAction;
}

export const CustomAlert = ({ message, action, ...rest }: IAlertProps) => {
  const theme = useTheme();

  return (
    <AlertContainer {...rest}>
      <CustomText type="body-2" color={theme.palette.fonts.bodyDark}>
        {message}
      </CustomText>
      {action ? (
        <CustomButtonLink onClick={action.onClick}>{action.text}</CustomButtonLink>
      ) : (
        <Loading3QuartersOutlined spin={true} />
      )}
    </AlertContainer>
  );
};
