// libs
import { css } from 'styled-components';

// components
import { IconSystemsDetailsDetailsSection } from 'assets/svg';
import { Details } from 'components';
import { DetailSection } from './styled';
import {
  MachineNameBlock,
  EnclaveVersionBlock,
  OsDetailsBlock,
  EnrolmentKeyBlock,
} from '../../Blocks';

// hooks
import { useTranslations } from 'hooks/useTranslations';

export const Detail = () => {
  const { translate } = useTranslations('systems');

  return (
    <DetailSection>
      <Details.SectionHeader
        css={css`
          margin-bottom: 1.5rem;
        `}
        Icon={IconSystemsDetailsDetailsSection}
        title={translate('details.detail')}
      />
      <MachineNameBlock />
      <EnclaveVersionBlock />
      <OsDetailsBlock />
      <EnrolmentKeyBlock />
    </DetailSection>
  );
};
