// components
import { Subnet } from './Subnet';
import { ColumnTitle } from 'components';

// hooks
import { useTranslations } from 'hooks/useTranslations';

// types
import { ISystemGatewayRouteModified } from 'types';

// types
import { ColumnsType } from 'antd/lib/table';
import { Priority } from './Priority';
import { SubnetName } from './SubnetName';

export const useColumnsView = (): ColumnsType<ISystemGatewayRouteModified> => {
  const { translate } = useTranslations('systems');

  return [
    {
      title: <ColumnTitle>{translate('details.gateway.table_column_subnet')}</ColumnTitle>,
      render: (_, route) => <Subnet route={route} />,
    },
    {
      render: (_, route) => <SubnetName route={route} />,
    },
    {
      title: <ColumnTitle>{translate('details.gateway.table_column_priority')}</ColumnTitle>,
      render: (_, route) => <Priority route={route} />,
    },
  ];
};
