// libs
import styled, { CSSProp } from 'styled-components';

// components
import { Select } from 'components';
import { TimeZoneOption } from './TimeZoneOption';
import { timeZoneSelectCss } from './styled';

// hooks
import { useCallback, useState } from 'react';
import { useTranslations } from 'hooks/useTranslations';

// constants
import { TIME_ZONES_DATA_SOURCE } from 'appConstants/time';
import { defaultTimeZone } from 'appConstants';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  min-width: 100%;
`;

interface ITimeZonePickerProps {
  newTimeZone?: string | null;
  currentTimeZone?: string | null;
  setNewTimeZone: (newZone: typeof TIME_ZONES_DATA_SOURCE[0]) => void;
  dataTest?: string;
  ariaLabel?: string;
  containerCss?: CSSProp;
}

export const TimeZoneSelect = ({
  newTimeZone,
  currentTimeZone,
  setNewTimeZone,
  dataTest,
  ariaLabel,
  containerCss,
}: ITimeZonePickerProps) => {
  const { translate } = useTranslations('policies');

  const displayedTimeZone = currentTimeZone || newTimeZone || defaultTimeZone.zone;

  const selectedTimeZone = TIME_ZONES_DATA_SOURCE.find(zone => zone.zone === displayedTimeZone);

  const [searchValue, setSearchValue] = useState('');

  const handleManualInput = useCallback((inputValue: string) => {
    setSearchValue(inputValue);
  }, []);

  return (
    <Container>
      <Select
        placeholder={translate('details.time_limitations.time_zone.placeholder')}
        showSearch={true}
        listHeight={269}
        autoClearSearchValue={true}
        onSearch={handleManualInput}
        searchValue={searchValue}
        aria-label={ariaLabel}
        data-test={dataTest}
        dataSource={TIME_ZONES_DATA_SOURCE}
        valueField="zone"
        keyField="zone"
        OptionElement={option => {
          return <TimeZoneOption zone={option.zone} offset={option.offset} />;
        }}
        setSelectedValue={setNewTimeZone}
        value={selectedTimeZone?.zone || searchValue || undefined}
        height="2rem"
        containerCss={containerCss}
        defaultValueFieldValue={undefined}
        error={null}
        tooltipProps={{ placement: 'topRight' }}
        getPopupContainer={trigger => {
          return trigger?.parentElement || trigger;
        }}
        css={timeZoneSelectCss}
      />
    </Container>
  );
};
