import { createAction } from '@reduxjs/toolkit';
import { IUserProfile } from 'types';

export const checkUserAuth = createAction('user/CHECK_USER_AUTH');
export const doLogin = createAction('user/DO_LOGIN');
export const doLogout = createAction('user/DO_LOGOUT');
export const reLogin = createAction('user/RE_LOGIN');
export const handleSilentTokenRenew = createAction('user/HANDLE_SILENT_TOKEN_RENEW');
export const handleNormalAuthRedirect = createAction('user/HANDLE_NORMAL_AUTH_REDIRECT');
export const storeUser = createAction<IUserProfile>('user/STORE_USER');
export const clearUser = createAction('user/CLEAR_USER');
