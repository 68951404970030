// libraries
import { useState, useCallback, useEffect } from 'react';

// Components
import { PageTitle, Filter } from 'components';
import { BulkUSystemActions } from '../BulkActions';
import { FilterAndBulkActionsContainer } from '../styled';
import { Table } from './Table';
import { UpgradePlanMessageBlock } from '../Details/Blocks';

// hooks
import { useUnapprovedSystemsEmptyText } from '../hooks/useUnapprovedSystemsEmptyText';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';
import { useParams } from 'react-router';

// actions
import { getCurrentOrganisation } from 'redux/actions/organisation';
import { closeDetails, loadUnapprovedSystemDetails } from 'redux/actions/app';
import {
  approveSystems,
  declineSystems,
  getSystems as getUnapprovedSystems,
  getSystemSearchKeys,
  setSystems,
} from 'redux/actions/unapproved-systems';

// selectors
import {
  selectUnapprovedSystemsUpdateId,
  selectUnapprovedSystemsIsLoading,
  selectAppDetailsTabType,
  selectAppDetailsSystemSystemId,
  selectCurrentOrgSystems,
  selectUnapprovedSystemsTotal,
  selectIsUnapprovedSystemsModule,
  selectPathQuerySearch,
  selectPathQuerySort,
  selectUnapprovedSystemSearchKeys,
} from 'redux/selectors';

// Types
import { IUnapprovedSystem, UnapprovedSystemRecordActions } from 'types';

// constants
import { USYSTEMS_SORT_OPTIONS } from 'appConstants';
import { initialSystemsResponse } from 'redux/reducers/unapproved-systems';

type ISystemsParams = {
  systemId?: string;
};

export const ToApproveTable = () => {
  const dispatch = useDispatch();

  const { translate } = useTranslations('systems');

  const { systemId } = useParams<ISystemsParams>();

  const total = useSelector(selectUnapprovedSystemsTotal);
  const updateId = useSelector(selectUnapprovedSystemsUpdateId);
  const isLoading = useSelector(selectUnapprovedSystemsIsLoading);
  const queryParamsSort = useSelector(selectPathQuerySort);
  const queryParamsSearch = useSelector(selectPathQuerySearch);
  const detailsType = useSelector(selectAppDetailsTabType);
  const systemsAmount = useSelector(selectCurrentOrgSystems);
  const selectedSystemId = useSelector(selectAppDetailsSystemSystemId);
  const isUnapprovedSystemsModule = useSelector(selectIsUnapprovedSystemsModule);
  const searchKeys = useSelector(selectUnapprovedSystemSearchKeys);

  const [page, setPage] = useState(0);
  const [searchValue, setSearchValue] = useState(queryParamsSearch || '');
  const [bulkSelectedUSystems, setBulkSelectedUSystems] = useState<IUnapprovedSystem[]>([]);
  const [sortBy, setSortBy] = useState(
    USYSTEMS_SORT_OPTIONS.find(option => option.value === queryParamsSort) ||
      USYSTEMS_SORT_OPTIONS[0]
  );

  const { emptyText } = useUnapprovedSystemsEmptyText({
    searchValue,
  });

  const bulkActionHandler = (type: UnapprovedSystemRecordActions) => {
    switch (type) {
      case 'approve': {
        const systemIds = bulkSelectedUSystems.map(system => system.systemId);
        if (systemIds.length) dispatch(approveSystems({ systemIds: systemIds as string[] }));
        break;
      }

      case 'reject': {
        const systemIds = bulkSelectedUSystems.map(system => system.systemId);
        if (systemIds.length) dispatch(declineSystems({ systemIds: systemIds as string[] }));
        break;
      }
    }
    setBulkSelectedUSystems([]);
  };

  const onBulkActionClick = useCallback(bulkActionHandler, [
    bulkSelectedUSystems,
    setBulkSelectedUSystems,
    dispatch,
  ]);

  useEffect(() => {
    dispatch(getSystemSearchKeys());
  }, [dispatch]);

  useEffect(() => {
    if (systemsAmount.unapproved !== systemsAmount.unapprovedMetaTotal)
      dispatch(getCurrentOrganisation());
  }, [dispatch, systemsAmount.unapproved, systemsAmount.unapprovedMetaTotal]);

  useEffect(() => {
    if (isUnapprovedSystemsModule && systemId) {
      if (detailsType !== 'unapproved-system-view' && detailsType !== 'unapproved-system-edit') {
        dispatch(loadUnapprovedSystemDetails({ systemId }));
        return;
      }
      if (
        (detailsType === 'unapproved-system-view' || detailsType === 'unapproved-system-edit') &&
        systemId !== selectedSystemId
      ) {
        dispatch(loadUnapprovedSystemDetails({ systemId }));
        return;
      }
    }

    if (!systemId && detailsType === 'unapproved-system-view') dispatch(closeDetails({}));

    /*
      Temporary solution. Covers back-button behavior that should close details pane in edit-mode
      Causes bug, that prevents details pane from opening via context-menu
    
    if (!systemId && selectedSystemId) dispatch(closeDetails({}));
    */
  }, [systemId, detailsType, selectedSystemId, dispatch, isUnapprovedSystemsModule]);

  const fetchData = useCallback(
    () =>
      dispatch(
        getUnapprovedSystems({
          search: searchValue,
          page: page || 0,
          sort: sortBy.value,
        })
      ),
    [dispatch, searchValue, page, sortBy]
  );

  useEffect(() => {
    fetchData();
  }, [updateId, fetchData, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(setSystems(initialSystemsResponse));
    };
  }, [dispatch]);

  useEffect(() => {
    setPage(0);
  }, [searchValue]);

  return (
    <>
      <UpgradePlanMessageBlock />
      <PageTitle title={translate('systems_unapproved')} />
      <FilterAndBulkActionsContainer>
        {bulkSelectedUSystems.length ? (
          <BulkUSystemActions
            selectedSystems={bulkSelectedUSystems}
            resetSelection={() => setBulkSelectedUSystems([])}
            onActionClick={onBulkActionClick}
          />
        ) : (
          <Filter
            {...{ total, page, fetchData }}
            {...{ searchValue, setSearchValue }}
            {...{ sortBy, setSortBy, isLoading, searchKeys }}
            sortOptions={USYSTEMS_SORT_OPTIONS}
          />
        )}
      </FilterAndBulkActionsContainer>
      <Table
        page={page}
        setPage={setPage}
        isLoading={isLoading}
        total={total}
        bulkSelectedUSystems={bulkSelectedUSystems}
        setBulkSelectedUSystems={setBulkSelectedUSystems}
        selectedSystemId={systemId}
        emptyText={emptyText}
      />
    </>
  );
};
