// constants
import {
  PUBLIC_IP_CONDITION_IP_RANGE,
  POLICY_PORTS_VALIDATION_STATE_PREFIX,
  POLICY_PROTOCOL_VALIDATION_STATE_PREFIX,
  POLICY_TRAFFIC_VALIDATION_STATE_PREFIX,
  TRUST_REQUIREMENT_IP_RANGE_VALIDATION_STATE_PREFIX,
} from 'appConstants';

// utils
import { validatePortsString } from 'modules/Policies/Details/Blocks/ReceiverAccessControls/helpers';
import { translator } from 'utils';

// types
import {
  IPolicyModifiedAcl,
  ITrustRequirementDetailModified,
  ITrustRequirementPublicIpIpRangeConditionModified,
  IValidationState,
} from 'types';

export const validateEmail = (email: string) => {
  const index = email.indexOf('@');

  return index > 0 && index !== email.length - 1 && index === email.lastIndexOf('@');
};

interface IGetPolicyValidationState {
  acls: IPolicyModifiedAcl[];
}

export const getPolicyAclsValidationState = ({
  acls,
}: IGetPolicyValidationState): IValidationState => {
  return acls.reduce((previousValue, currentValue) => {
    const translate = translator('policies');

    const withPorts = currentValue.ports !== null;

    const newValue: IValidationState = {
      ...previousValue,
      [POLICY_TRAFFIC_VALIDATION_STATE_PREFIX + currentValue.id]: {
        state: { error: null },
        updated: false,
      },
      [POLICY_PROTOCOL_VALIDATION_STATE_PREFIX + currentValue.id]: {
        state: { error: null },
        updated: false,
      },
    };

    const isTrafficInvalid = currentValue.protocol === null;
    const isProtocolInvalid = currentValue.protocol === null;

    if (currentValue.protocol === null) {
      newValue[POLICY_TRAFFIC_VALIDATION_STATE_PREFIX + currentValue.id] = {
        state: {
          error: isTrafficInvalid
            ? {
                message: translate('details.acls_errors.traffic_not_selected'),
                critical: true,
              }
            : null,
        },
        updated: false,
      };
      newValue[POLICY_PROTOCOL_VALIDATION_STATE_PREFIX + currentValue.id] = {
        state: {
          error: isProtocolInvalid
            ? {
                message: translate('details.acls_errors.protocol_not_selected'),
                critical: true,
              }
            : null,
        },
        updated: false,
      };
    }

    if (withPorts) {
      const portsValidationState = validatePortsString({ ports: currentValue.ports });

      newValue[POLICY_PORTS_VALIDATION_STATE_PREFIX + currentValue.id] = {
        state: portsValidationState,
        updated: false,
      };
    }

    return newValue;
  }, {} as IValidationState);
};

interface IGetTrustRequirementIpRangesValidationState {
  ipRanges: ITrustRequirementPublicIpIpRangeConditionModified[];
}

export const getTrustRequirementIpRangesValidationState = ({
  ipRanges,
}: IGetTrustRequirementIpRangesValidationState) => {
  const ipRangesValidationState: IValidationState = ipRanges?.reduce(
    (previousValue, currentValue) => {
      return {
        ...previousValue,
        [TRUST_REQUIREMENT_IP_RANGE_VALIDATION_STATE_PREFIX + currentValue.id]: {
          state: { error: null },
          updated: false,
        },
      };
    },
    {} as IValidationState
  );

  return ipRangesValidationState;
};

export const getTrustRequirementValidationState = ({
  settings,
}: ITrustRequirementDetailModified): IValidationState => {
  const ipRanges: ITrustRequirementPublicIpIpRangeConditionModified[] =
    (settings.conditions?.filter(
      condition => condition.type === PUBLIC_IP_CONDITION_IP_RANGE
    ) as ITrustRequirementPublicIpIpRangeConditionModified[]) || [];

  return {
    ...getTrustRequirementIpRangesValidationState({ ipRanges }),
  };
};
