import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 1.75rem;
`;

export const Section = styled.section`
  display: flex;
  flex-direction: row;
  border-radius: 0.25rem;
  box-shadow: ${({ theme }) => theme.palette.elevationShadows[200]};
  background-color: ${({ theme }) => theme.palette.background.panel};
  padding: 2rem 1.5rem 1.5rem 2rem;
`;

export const DescriptionContainer = styled.div`
  padding-right: 1rem;
  width: 30%;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;
