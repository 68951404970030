import styled from 'styled-components';

export const DetailsContentWrapper = styled.div`
  height: 100%;
  overflow-y: auto;
`;

export const DetailsPane = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const DetailsScrollPaddingContainer = styled.div<{ wide: boolean }>`
  overflow: hidden;
  padding-top: 1.25rem;

  padding-right: calc(
    2.5rem -
      (
        ${({ theme, wide }) =>
            wide ? theme.layout_sizes.detailsWide : theme.layout_sizes.detailsNarrow} - 100%
      )
  );
  padding-bottom: 2.5rem;
  padding-left: 2.5rem;
`;

export const DetailsHeaderSection = styled.header<{ withExtraMarginTop?: boolean }>`
  display: flex;
  flex-direction: column;
  margin-top: ${({ withExtraMarginTop }) => (withExtraMarginTop ? '2rem' : '1.25rem')};
  margin-bottom: 0.75rem;
`;
