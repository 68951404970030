// Components
import { CustomMessage, SpanStyledAsLargeInput } from 'components';
import { UsesRemainingWarningContainer } from './styled';

// hooks
import { useTheme } from 'styled-components';
import { useSelector } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';

// selectors
import {
  selectAppDetailsEnrolmentKeyDescription,
  selectAppDetailsEnrolmentKeyUsesRemaining,
} from 'redux/selectors';

export const TitleView = () => {
  const { translate } = useTranslations('enrolment-keys');

  const {
    palette: { fonts },
  } = useTheme();

  const description = useSelector(selectAppDetailsEnrolmentKeyDescription);
  const usesRemaining = useSelector(selectAppDetailsEnrolmentKeyUsesRemaining);

  return (
    <>
      <SpanStyledAsLargeInput
        data-test="enrolment-key-details-title-view"
        type="heading-2"
        color={fonts.bodyDark}
      >
        {description || translate('details.no_name')}
      </SpanStyledAsLargeInput>

      {!usesRemaining && (
        <UsesRemainingWarningContainer>
          <CustomMessage type="warning" message={translate('details.exhausted_uses_warning')} />
        </UsesRemainingWarningContainer>
      )}
    </>
  );
};
