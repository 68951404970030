// libs
import { useState, useCallback, useEffect } from 'react';

// components
import { PageTitle, Filter, BulkActions } from 'components';
import { FilterAndBulkActionsContainer } from '../styled';
import { Table } from './Table';

// hooks
import { useSystemsEmptyText } from '../hooks/useSystemsEmptyText';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';
import { useParams } from 'react-router';

// utils
import { isEnabled, isDisabled, mapSystemId } from '../helpers';

// actions
import {
  disableSystems,
  enableSystems,
  getSystems,
  getSystemSearchKeys,
  setSystems,
} from 'redux/actions/systems';
import { loadSystemDetails, setModalContent } from 'redux/actions/app';
import { getCurrentOrganisation } from 'redux/actions/organisation';

// selectors
import {
  selectAppDetailsSystemSystemId,
  selectAppDetailsTabType,
  selectCurrentOrgSystems,
  selectIsApprovedSystemsModule,
  selectApprovedSystemsIsLoading,
  selectApprovedSystemsTotal,
  selectApprovedSystemsUpdateId,
  selectPathQuerySearch,
  selectPathQueryIncludeDisabled,
  selectPathQuerySort,
  selectApprovedSystemSearchKeys,
} from 'redux/selectors';

// constants
import { SYSTEMS_SORT_OPTIONS } from 'appConstants';
import { initialSystemsResponse } from 'redux/reducers/systems';

// types
import { ISystemSummaryModel, SystemRecordActions } from 'types';

type ISystemsParams = {
  systemId?: string;
};

export const EnrolledTable = () => {
  const dispatch = useDispatch();

  const { translate } = useTranslations('systems');

  const { systemId } = useParams<ISystemsParams>();

  const total = useSelector(selectApprovedSystemsTotal);
  const updateId = useSelector(selectApprovedSystemsUpdateId);
  const isLoading = useSelector(selectApprovedSystemsIsLoading);
  const detailsType = useSelector(selectAppDetailsTabType);
  const queryParamsSort = useSelector(selectPathQuerySort);
  const queryParamsSearch = useSelector(selectPathQuerySearch);
  const queryParamsIncludeDisabed = useSelector(selectPathQueryIncludeDisabled);
  const systemsAmount = useSelector(selectCurrentOrgSystems);
  const isSystemsModule = useSelector(selectIsApprovedSystemsModule);
  const selectedSystemId = useSelector(selectAppDetailsSystemSystemId);
  const searchKeys = useSelector(selectApprovedSystemSearchKeys);

  const [page, setPage] = useState(0);
  const [searchValue, setSearchValue] = useState(queryParamsSearch || '');
  const [showDisabled, setShowDisabled] = useState<boolean>(queryParamsIncludeDisabed || false);
  const [bulkSelectedSystems, setBulkSelectedSystems] = useState<ISystemSummaryModel[]>([]);
  const [sortBy, setSortBy] = useState(
    SYSTEMS_SORT_OPTIONS.find(option => option.value === queryParamsSort) || SYSTEMS_SORT_OPTIONS[0]
  );

  const { emptyText } = useSystemsEmptyText({
    searchValue,
  });

  const bulkActionHandler = (type: SystemRecordActions) => {
    switch (type) {
      case 'enable': {
        const systemIds = bulkSelectedSystems.filter(isDisabled).map(mapSystemId);
        if (systemIds.length) dispatch(enableSystems({ systemIds }));
        setBulkSelectedSystems([]);
        break;
      }

      case 'disable': {
        const systemIds = bulkSelectedSystems.filter(isEnabled).map(mapSystemId);
        if (systemIds.length) dispatch(disableSystems({ systemIds }));
        setBulkSelectedSystems([]);
        break;
      }

      case 'remove': {
        if (bulkSelectedSystems.length) {
          dispatch(setModalContent({ type: 'systems-remove', data: bulkSelectedSystems }));
        }
        break;
      }
    }
  };

  const onBulkActionClick = useCallback(bulkActionHandler, [
    bulkSelectedSystems,
    setBulkSelectedSystems,
    dispatch,
  ]);

  useEffect(() => {
    dispatch(getSystemSearchKeys());
  }, [dispatch]);

  useEffect(() => {
    if (systemsAmount.enrolled !== systemsAmount.enrolledMetaTotal)
      dispatch(getCurrentOrganisation());
  }, [dispatch, systemsAmount.enrolled, systemsAmount.enrolledMetaTotal]);

  useEffect(() => {
    if (isSystemsModule && systemId) {
      if (detailsType !== 'system-view' && detailsType !== 'system-edit') {
        dispatch(loadSystemDetails({ systemId }));
        return;
      }
      if (
        (detailsType === 'system-view' || detailsType === 'system-edit') &&
        systemId !== selectedSystemId
      ) {
        dispatch(loadSystemDetails({ systemId }));
        return;
      }
    }

    /*
      Temporary solution. Covers back-button behavior that should close details pane in edit-mode
      Causes bug, that prevents details pane from opening via context-menu
    
    if (!systemId && selectedSystemId) dispatch(closeDetails({}));
    */
  }, [detailsType, dispatch, isSystemsModule, selectedSystemId, systemId]);

  const fetchData = useCallback(
    () =>
      dispatch(
        getSystems({
          include_disabled: showDisabled,
          search: searchValue,
          page: page || 0,
          sort: sortBy.value,
        })
      ),
    [dispatch, page, searchValue, showDisabled, sortBy.value]
  );

  useEffect(() => {
    dispatch(fetchData());
  }, [dispatch, updateId, fetchData]);

  useEffect(() => {
    return () => {
      dispatch(setSystems(initialSystemsResponse));
    };
  }, [dispatch]);

  useEffect(() => {
    setPage(0);
  }, [searchValue]);

  return (
    <>
      <PageTitle title={translate('systems_enrolled')} />
      <FilterAndBulkActionsContainer>
        {bulkSelectedSystems.length ? (
          <BulkActions
            data-test="approved-systems-bulk-actions"
            translate={translate}
            selectedItems={bulkSelectedSystems}
            resetSelection={() => setBulkSelectedSystems([])}
            onActionClick={onBulkActionClick}
          />
        ) : (
          <Filter
            {...{ total, page, fetchData }}
            {...{ searchValue, setSearchValue }}
            {...{ showDisabled, setShowDisabled }}
            {...{ sortBy, setSortBy, isLoading, searchKeys }}
            sortOptions={SYSTEMS_SORT_OPTIONS}
          />
        )}
      </FilterAndBulkActionsContainer>
      <Table
        page={page}
        setPage={setPage}
        isLoading={isLoading}
        total={total}
        bulkSelectedSystems={bulkSelectedSystems}
        setBulkSelectedSystems={setBulkSelectedSystems}
        emptyText={emptyText}
        selectedSystemId={systemId}
      />
    </>
  );
};
