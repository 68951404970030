// libs
import { memo } from 'react';

// components
import Skeleton from 'react-loading-skeleton';
import { CustomText } from 'typography/Text';

// hooks
import { useTheme } from 'styled-components';
import { useTranslations } from 'hooks/useTranslations';

// constants
import { UNLIMITED_USES_AMOUNT } from 'appConstants';

// types
import { ISimpleEnrolmentKey } from 'types';

interface IUsesRemaining {
  usesRemaining: ISimpleEnrolmentKey['usesRemaining'];
  isEnabled?: ISimpleEnrolmentKey['isEnabled'];
  isLoading: boolean;
  ephemeral: boolean;
}

export const UsesRemaining = memo(
  ({ usesRemaining, isEnabled = true, isLoading, ephemeral }: IUsesRemaining) => {
    const theme = useTheme();
    const { translate } = useTranslations('enrolment-keys');

    return (
      <>
        {isLoading ? (
          <Skeleton />
        ) : !ephemeral ? (
          <CustomText
            type="body-2"
            color={isEnabled ? theme.palette.fonts.bodyDark : theme.palette.fonts.disabled}
          >
            {usesRemaining === UNLIMITED_USES_AMOUNT ? translate('unlimited') : usesRemaining}
          </CustomText>
        ) : (
          '--'
        )}
      </>
    );
  }
);
