// libs
import { call, put } from 'typed-redux-saga';

// actions
import { setModalContent, setModalIsLoading } from 'redux/actions/app';

// types
import { IActionPayload, ICreateAccountAccessTokenParams, IAccountAccessToken } from 'types';

// sagas
import { validateStepUpTokenSaga } from './validateStepUpTokenSaga';
import { getAccountSaga } from './getAccountSaga';

// utils
import { ApiTypeCaller } from '../../api/utils';

// constants
import {
  ACCOUNT_CREATE_ACCESS_TOKEN_ERROR_FIELD,
  ACCOUNT_CREATE_ACCESS_TOKEN_WARNING_ERROR_FIELD,
} from 'appConstants';

export function* CreateAccessTokenSaga({
  payload: updateParams,
}: IActionPayload<Omit<ICreateAccountAccessTokenParams, 'stepUpToken'>>) {
  yield put(setModalIsLoading(true));

  const token = yield* call(validateStepUpTokenSaga);

  if (token) {
    const { success, result } = yield* ApiTypeCaller(
      'createAccountAccessToken',
      {
        ...updateParams,

        stepUpToken: token,
      },
      {
        customErrorField: error => {
          if (error.status === 400) {
            return ACCOUNT_CREATE_ACCESS_TOKEN_ERROR_FIELD;
          }
          return ACCOUNT_CREATE_ACCESS_TOKEN_WARNING_ERROR_FIELD;
        },
      }
    );

    if (success && result) {
      const { token = '' } = result;
      const tokenObject: IAccountAccessToken = {
        description: updateParams.description,
        lastUsed: null,
        reference: token,
      };

      yield call(getAccountSaga);
      yield put(setModalContent({ type: 'save-PAToken', data: tokenObject }));
    }
  }
  yield put(setModalIsLoading(false));
}
