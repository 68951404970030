import { Radio as antdRadio } from 'antd';
import styled from 'styled-components';

export const Radio = styled(antdRadio)`
  display: flex;
  align-items: center;
  width: fit-content;

  // default styles
  .ant-radio-inner {
    border: 0.0625rem solid ${({ theme }) => theme.palette.fills.strokeMedium}!important;
  }

  // styles when checked
  .ant-radio-checked .ant-radio-inner {
    border: 0.0625rem solid ${({ theme }) => theme.palette.fills.primary}!important;
    ::after {
      background-color: ${({ theme }) => theme.palette.fills.primary}!important;
    }
  }

  // control hover states
  .ant-radio:hover .ant-radio-inner {
    border: 0.0625rem solid ${({ theme }) => theme.palette.fills.primary}!important;
  }
  .ant-radio-checked:hover .ant-radio-inner {
    border: 0.0625rem solid ${({ theme }) => theme.palette.fills.primaryHover}!important;
    ::after {
      background-color: ${({ theme }) => theme.palette.fills.primaryHover}!important;
    }
  }

  // control focus states
  input:focus + .ant-radio-inner {
    box-shadow: none !important;
  }
`;
