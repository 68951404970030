import { ENC_HOSTS, ENDPOINT_RETRY_DELAY, ENDPOINT_RETRY_NUMBER } from 'appConstants';
import { parseResponse } from './responseHandlers';
import { AuthService } from './AuthService';
import { IRequestResponse, IRequestParams, ValidateUserResponse, RequestMethods } from 'types';
import { HttpRequestHeader } from 'antd/lib/upload/interface';
import { translator } from 'utils';

const baseOptions: Record<string, unknown> = {
  mode: 'cors',
};

export class ApiService {
  private _authService: AuthService;
  public serverAddress: string;
  public accessToken: string | null;
  public accessTokenHeader: string;
  public headers: HttpRequestHeader;

  public silentRenewOutsideCount: number;
  public visible: boolean;

  constructor(serverAddress: string) {
    this.serverAddress = serverAddress;
    this.accessToken = null;
    this.accessTokenHeader = 'Authorization';
    this.headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
    this.silentRenewOutsideCount = 0;
    this.visible = true;
    this._authService = new AuthService();
  }

  translate = translator('notifications');

  setVisible = (value: boolean) => {
    this._authService.setVisible(value);
  };

  setAccessToken = (newToken: string) => {
    this.accessToken = newToken ? `Bearer ${newToken}` : newToken;
  };

  purgeAccessToken = () => {
    this.accessToken = null;
  };

  loginSilent = async () => {
    try {
      const user = await this._authService.loginSilent();
      return user;
    } catch (error) {
      await this._authService.login();
      return false;
    }
  };

  logoutUser = () => this._authService.logout();

  handleSilentTokenRenew = () => {
    this._authService.handleSilentTokenRenew();
  };

  handleNormalAuthRedirect = () => this._authService.handleNormalAuthRedirect();

  validateUserAuth = async (): Promise<ValidateUserResponse> => {
    const user = await this._authService.getUser();

    for (let i = 0; i < ENDPOINT_RETRY_NUMBER; i++) {
      try {
        if (!user) {
          this.purgeAccessToken();
          await this._authService.login();
        } else {
          if (user.access_token) {
            this.setAccessToken(user.access_token);
          } else {
            this.purgeAccessToken();

            await this._authService.login();
          }

          return { success: true, profile: user.profile };
        }

        if (i < ENDPOINT_RETRY_NUMBER - 1) {
          await new Promise(r => setTimeout(r, ENDPOINT_RETRY_DELAY));
        }
      } catch (error) {
        if (i < ENDPOINT_RETRY_NUMBER - 1) {
          await new Promise(r => setTimeout(r, ENDPOINT_RETRY_DELAY));
        }
      }
    }

    return { success: false, error: this.translate('auth_failed') };
  };

  login = () => this._authService.login();

  makeRequest = async (
    type: RequestMethods,
    apiEndpoint: string,
    params?: IRequestParams
  ): Promise<IRequestResponse> => {
    let actionFunction;

    switch (type) {
      case 'GET':
        actionFunction = this.get;
        break;
      default:
        actionFunction = this.nonGet(type);
        break;
    }

    if (this.accessToken) this.headers[this.accessTokenHeader] = this.accessToken;

    return await actionFunction(apiEndpoint, params);
  };

  get = (apiEndpoint: string, params: IRequestParams = {}): Promise<IRequestResponse> => {
    const paramsString = Object.keys(params)
      .filter(
        key =>
          params[key as keyof IRequestParams] !== null &&
          params[key as keyof IRequestParams] !== undefined
      )
      .map(
        (key: string | undefined) =>
          `${key}=${encodeURIComponent(
            params[key as keyof IRequestParams] as string | number | boolean
          )}`
      )
      .join('&');

    return fetch(this.serverAddress + apiEndpoint + (paramsString ? `?${paramsString}` : ''), {
      ...baseOptions,
      headers: this.headers,
    }).then(parseResponse);
  };

  nonGet =
    (method: RequestMethods) =>
    (apiEndpoint: string, params: IRequestParams = {}): Promise<IRequestResponse> =>
      fetch(this.serverAddress + apiEndpoint, {
        ...baseOptions,
        method,
        body: JSON.stringify(params),
        headers: this.headers,
      }).then(parseResponse);
}

export const apiService = new ApiService(ENC_HOSTS.API);

window.addEventListener('visibilitychange', () => {
  apiService.setVisible(!document.hidden);
});
