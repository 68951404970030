// components
import { ExpiryDateContent } from './styled';
import { ExpiryDateView } from './View';
import { ExpiryDateEdit } from './Edit';

// hooks
import { css } from 'styled-components';
import { useTranslations } from 'hooks/useTranslations';
import { useSelector } from 'react-redux';

// selectors
import { selectAppDetailsTabType } from 'redux/selectors';
import { Details } from 'components';

interface IExpiryDateBlock {
  isOpen: boolean;
}

export const ExpiryDateBlock = ({ isOpen }: IExpiryDateBlock) => {
  const { translate } = useTranslations('policies');

  const type = useSelector(selectAppDetailsTabType);

  return (
    <ExpiryDateContent isOpen={isOpen}>
      <Details.KeyValue
        contentCss={css`
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: baseline;
        `}
        css={css`
          padding: 0;
        `}
        title={translate('details.auto_expire.expiry_date.title')}
      >
        {type === 'policy-view' && <ExpiryDateView />}
        {(type === 'policy-edit' || type === 'policy-create') && <ExpiryDateEdit />}
      </Details.KeyValue>
    </ExpiryDateContent>
  );
};
