// libs
import { css } from 'styled-components';

// components
import { CustomText } from 'typography/Text';
import { IconJsError } from 'assets/svg';
import { CustomButton, ExternalLink } from 'components';
import { StyledEmptyWrapper, extraTextStyles, titleTextStyles } from './styled';

// Hooks
import { useTheme } from 'styled-components';
import { useTranslations } from 'hooks/useTranslations';

// constants
import { LINK_TO_COMMUNITY_SUPPORT } from 'appConstants';

interface IEmptyContainerProps {
  onRefreshClick: () => void;
}

export const ErrorFallback = ({ onRefreshClick, ...rest }: IEmptyContainerProps) => {
  const { translate } = useTranslations('global');

  const {
    palette: { fonts },
  } = useTheme();

  return (
    <StyledEmptyWrapper {...rest}>
      <IconJsError />
      <CustomText
        type="sub-heading-2"
        color={fonts.bodyDark}
        css={css`
          ${titleTextStyles}
        `}
      >
        {translate('js_error.title')}
      </CustomText>
      <ExternalLink
        withIcon={true}
        css={css`
          ${extraTextStyles}
        `}
        href={LINK_TO_COMMUNITY_SUPPORT}
        text={translate('js_error.link')}
      />

      <CustomButton onClick={onRefreshClick}>{translate('js_error.reload_button')}</CustomButton>
    </StyledEmptyWrapper>
  );
};
