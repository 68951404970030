import { Checkbox } from 'antd';
import styled from 'styled-components';

export const CustomCheckbox = styled(Checkbox)`
  // default styles
  .ant-checkbox-inner {
    border: 0.0625rem solid ${({ theme }) => theme.palette.fills.strokeMedium}!important;
    transition: none;
    box-shadow: none;
  }
  // styles when checked
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${({ theme }) => theme.palette.fills.primary}!important;
    border-color: ${({ theme }) => theme.palette.fills.primary}!important;
    box-shadow: none;
  }
  .ant-checkbox-checked::after {
    border: 0.0625rem solid ${({ theme }) => theme.palette.fills.primaryHover}!important;
    box-shadow: none;
  }

  // control hover states
  &:hover .ant-checkbox-inner {
    border: 0.0625rem solid ${({ theme }) => theme.palette.fills.primary}!important;
    box-shadow: none;
  }
  &:hover .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${({ theme }) => theme.palette.fills.primaryHover}!important;
    border-color: ${({ theme }) => theme.palette.fills.primaryHover}!important;
    box-shadow: none;
  }
  .ant-checkbox:hover .ant-checkbox-inner {
    border: 0.0625rem solid ${({ theme }) => theme.palette.fills.primary}!important;
    box-shadow: none;
  }
  .ant-checkbox-checked:hover .ant-checkbox-inner {
    background-color: ${({ theme }) => theme.palette.fills.primaryHover}!important;
    border-color: ${({ theme }) => theme.palette.fills.primaryHover}!important;
    animation: none;
    box-shadow: none;
  }

  // control focus states
  &:focus .ant-checkbox-inner {
    border: 0.0625rem solid ${({ theme }) => theme.palette.fills.primary}!important;
    box-shadow: none;
  }
  input:focus + .ant-checkbox-inner {
    border: 0.0625rem solid ${({ theme }) => theme.palette.fills.primary}!important;
    box-shadow: none;
  }
  .ant-checkbox:focus .ant-checkbox-inner {
    border: 0.0625rem solid ${({ theme }) => theme.palette.fills.primary}!important;
    box-shadow: none;
  }
`;

export const TableSelectionContainer = styled.div`
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  label.ant-checkbox-wrapper {
    span.ant-checkbox {
      top: 0;
    }

    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  * {
    transition-duration: 0ms;
  }

  // Default-default

  // Default-checked
  span.ant-checkbox-checked span.ant-checkbox-inner {
    background-color: ${({ theme }) => theme.palette.fills.primary};
    border-color: ${({ theme }) => theme.palette.fills.primary};
  }

  // Hover-default
  label.ant-checkbox-wrapper:hover .ant-checkbox .ant-checkbox-inner {
    border-color: ${({ theme }) => theme.palette.fills.primary};
  }
  input.ant-checkbox-input:focus + span.ant-checkbox-inner {
    border-color: ${({ theme }) => theme.palette.fills.primary};
  }

  // Hover-checked
  label.ant-checkbox-wrapper:hover .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${({ theme }) => theme.palette.fills.primaryHover};
  }
  label.ant-checkbox-wrapper:hover .ant-checkbox-checked::after {
    border-color: ${({ theme }) => theme.palette.fills.primaryHover};
  }
  span.ant-checkbox-checked:hover span.ant-checkbox-inner {
    background-color: ${({ theme }) => theme.palette.fills.primaryHover};
  }
  span.ant-checkbox-checked:hover::after {
    border-color: ${({ theme }) => theme.palette.fills.primaryHover};
  }
`;
