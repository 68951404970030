// libs
import { ReactNode, useCallback } from 'react';

// components
import { TrueErrorBoundary } from './TrueErrorBoundary';

// hooks
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

// selectors
import { selectPathName } from 'redux/selectors';

interface Props {
  children: ReactNode;
}

function usePrevious<T>(value: T) {
  const ref = useRef(value);

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

export const ErrorBoundary = ({ children }: Props) => {
  const [trackPathChange, setTrackPathChange] = useState(false);

  const [key, setKey] = useState(0);

  const pathname = useSelector(selectPathName);

  const navigate = useNavigate();

  const previousPathname = usePrevious(pathname);

  useEffect(() => {
    if (trackPathChange && pathname !== previousPathname) {
      setKey(key => key + 1);
      setTrackPathChange(false);
    }
  }, [pathname, previousPathname, trackPathChange]);

  const onError = useCallback(() => {
    setTrackPathChange(true);
  }, []);

  const onRefresh = useCallback(() => {
    localStorage.clear();
    sessionStorage.clear();

    navigate(0);
  }, [navigate]);

  return (
    <TrueErrorBoundary key={key} onError={onError} onRefresh={onRefresh}>
      {children}
    </TrueErrorBoundary>
  );
};
