// Components
import { CustomText } from 'typography/Text';

// Hooks
import { useTranslations } from 'hooks/useTranslations';
import { useTheme } from 'styled-components';

export const OrganisationTitle = () => {
  const { translate } = useTranslations('settings');
  const {
    palette: { fonts },
  } = useTheme();
  return (
    <CustomText type="heading-1" color={fonts.title}>
      {translate('organisation_settings')}
    </CustomText>
  );
};
