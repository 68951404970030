import styled, { CSSProperties } from 'styled-components';

export const DetailRow = styled.div<{
  height?: string;
  withMargintTop?: boolean;
  alignItems?: CSSProperties['alignItems'];
}>`
  height: ${({ height }) => height || 'unset'};
  align-items: ${({ alignItems }) => alignItems};
  display: flex;
  flex-direction: row;
  padding-left: 2rem;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
`;
export const DetailNameCol = styled.div<{ width?: string }>`
  height: fit-content;
  flex: 0 0 8.25rem;
`;

export const DetailDescriptionCol = styled.div`
  height: fit-content;
  flex: 1 1;
  min-width: 0;
`;
