// components
import { Empty } from './Empty';
import { Suggestion } from './Suggestion';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

// selectors
import { selectSearchData } from 'redux/selectors';

// types
import { ISearchKey, ISimpleEnrolmentKey, ISystemSummaryModel } from 'types';
import { ISearchModifier } from 'types/search';
import { IInputArrowConfig, ISelectedIndex } from '..';
import { IRange } from '../helpers';

// constants
import { UNLIMITED_USES_AMOUNT } from 'appConstants';

interface ISearchModifierSuggestions {
  keyName: ISearchKey['name'];
  modifiers: NonNullable<ISearchKey['modifiers']>;
  addValue: (newValue: string) => void;
  searchModifier: ISearchModifier | null;
  setInputArrowNavigationConfig: (config: IInputArrowConfig) => void;
  selectedIndex: ISelectedIndex;
  range: IRange | null;
}

export const SearchModifierSuggestions = ({
  keyName,
  modifiers,
  addValue,
  searchModifier,
  setInputArrowNavigationConfig,
  selectedIndex,
  range,
}: ISearchModifierSuggestions) => {
  const data = useSelector(selectSearchData);

  const { translate } = useTranslations('global');

  const modifierToText = useCallback(
    (modifier: ISearchModifier) => {
      switch (modifier) {
        case 'GreaterThan':
          return { text: translate('search.greater_than'), symbol: '>' };
        case 'LessThan':
          return { text: translate('search.less_than'), symbol: '<' };
        case 'Not':
          return { text: translate('search.not'), symbol: '!' };
        case 'Or':
          return { text: translate('search.or'), symbol: '|' };
      }
    },
    [translate]
  );

  const Items = useMemo(() => {
    if (!searchModifier) {
      return null;
    }

    if (data?.module === 'keys') {
      if (keyName === 'uses') {
        const usedValues: ISimpleEnrolmentKey['usesRemaining'][] = [];

        // making sure not to show selected values in suggestions
        const filteredItems = data.items.filter(item => {
          const usesRemaining = item.usesRemaining;

          if (usesRemaining === UNLIMITED_USES_AMOUNT) {
            return false;
          }

          if (usedValues.includes(usesRemaining)) {
            return false;
          }

          usedValues.push(usesRemaining);
          return true;
        });

        const result = filteredItems.map((item, index) => {
          const usesRemaining = item.usesRemaining;

          return (
            <Suggestion
              isSelected={index === selectedIndex}
              key={item.id}
              keyName={keyName}
              value={String(usesRemaining)}
              onClick={() => addValue(String(usesRemaining))}
            />
          );
        });

        if (!usedValues.length) return <Empty searchKeyName={keyName} />;
        return result;
      }

      if (keyName === 'systems-enrolled') {
        const usedValues: ISimpleEnrolmentKey['enrolledCount'][] = [];

        // making sure not to show selected values in suggestions
        const filteredItems = data.items.filter(item => {
          const enrolledCount = item.enrolledCount;

          if (usedValues.includes(enrolledCount)) {
            return false;
          }

          usedValues.push(enrolledCount);
          return true;
        });

        const result = filteredItems.map((item, index) => {
          const enrolledCount = item.enrolledCount;

          return (
            <Suggestion
              isSelected={index === selectedIndex}
              key={item.id}
              keyName={keyName}
              value={String(enrolledCount)}
              onClick={() => addValue(String(enrolledCount))}
            />
          );
        });

        if (!usedValues.length) return <Empty searchKeyName={keyName} />;
        return result;
      }
    }

    if (data?.module === 'systems') {
      if (keyName === 'version') {
        const usedValues: ISystemSummaryModel['enclaveVersion'][] = [];

        // making sure not to show selected values in suggestions
        const filteredItems = data.items.filter(item => {
          const osVersion = item.enclaveVersion;

          if (!osVersion) {
            return false;
          }

          if (usedValues.includes(osVersion)) {
            return false;
          }

          usedValues.push(osVersion);
          return true;
        });

        const result = filteredItems
          .sort((a, b) => {
            const versionNumberA = Number(a.enclaveVersion?.replaceAll('.', '') || 0);
            const versionNumberB = Number(b.enclaveVersion?.replaceAll('.', '') || 0);

            return versionNumberB - versionNumberA;
          })
          .map((item, index) => {
            // it's filtered already above
            const version = item.enclaveVersion as NonNullable<typeof item.enclaveVersion>;
            let result = version;

            const numberOfDotsInString = version.match(/\./g)?.length;

            if (numberOfDotsInString && numberOfDotsInString >= 3) {
              const lastIndex = version?.lastIndexOf('.');

              result = lastIndex ? version?.slice(0, lastIndex) : version;
            }

            return (
              <Suggestion
                isSelected={index === selectedIndex}
                key={item.systemId}
                keyName={keyName}
                value={result}
                onClick={() => addValue(result)}
              />
            );
          });

        if (!usedValues.length) return <Empty searchKeyName={keyName} />;
        return result;
      }
    }

    if (data?.module === 'unapproved-systems') {
      if (keyName === 'version') {
        const usedValues: ISystemSummaryModel['enclaveVersion'][] = [];

        // making sure not to show selected values in suggestions
        const filteredItems = data.items.filter(item => {
          const osVersion = item.enclaveVersion;

          if (!osVersion) {
            return false;
          }

          if (usedValues.includes(osVersion)) {
            return false;
          }

          usedValues.push(osVersion);
          return true;
        });

        const result = filteredItems
          .sort((a, b) => {
            const versionNumberA = Number(a.enclaveVersion?.replaceAll('.', '') || 0);
            const versionNumberB = Number(b.enclaveVersion?.replaceAll('.', '') || 0);

            return versionNumberB - versionNumberA;
          })
          .map((item, index) => {
            // it's filtered already above
            const version = item.enclaveVersion as NonNullable<typeof item.enclaveVersion>;
            let result = version;

            const numberOfDotsInString = version.match(/\./g)?.length;

            if (numberOfDotsInString && numberOfDotsInString >= 3) {
              const lastIndex = version?.lastIndexOf('.');

              result = lastIndex ? version?.slice(0, lastIndex) : version;
            }

            return (
              <Suggestion
                isSelected={index === selectedIndex}
                key={item.systemId}
                keyName={keyName}
                value={result}
                onClick={() => addValue(result)}
              />
            );
          });

        if (!usedValues.length) return <Empty searchKeyName={keyName} />;
        return result;
      }
    }
    return null;
  }, [addValue, data?.items, data?.module, keyName, searchModifier, selectedIndex]);

  // for navigation input with UP and DOWN arrows
  useEffect(() => {
    if (!searchModifier) {
      setInputArrowNavigationConfig({
        length: modifiers.length,
        onEnter: () => {
          if (selectedIndex !== null) {
            const { symbol } = modifierToText(modifiers[selectedIndex]);

            addValue(symbol);
          }
        },
        isKeyInactive: false,
      });
    }

    if (searchModifier && Array.isArray(Items)) {
      setInputArrowNavigationConfig({
        length: Items.length,
        onEnter: () => {
          if (selectedIndex !== null) {
            Items[selectedIndex].props.onClick();
          }
        },
        isKeyInactive: false,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedIndex,
    setInputArrowNavigationConfig,
    range,
    searchModifier,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    Array.isArray(Items) && Items.length,
  ]);

  return (
    <>
      {!searchModifier &&
        modifiers.map((modifier, index) => {
          const { text, symbol } = modifierToText(modifier);

          return (
            <Suggestion
              isSelected={index === selectedIndex}
              key={modifier}
              keyName={keyName}
              value={text}
              onClick={() => addValue(symbol)}
            />
          );
        })}

      {Items}
    </>
  );
};
