import { createAction } from '@reduxjs/toolkit';
import {
  IDuplicatePolicyPayload,
  ILoadPoliciesDetails,
  IPolicyDetails,
  IPolicyDetailsModified,
  IPolicyGatewayAllowedIpRange,
  IPolicyGatewayRoute,
  IPolicyModifiedAcl,
  IPolicyModifiedGatewayAllowedIpRange,
} from 'types';

export const updatePolicyDetails = createAction<Partial<IPolicyDetailsModified>>(
  'main/UPDATE_POLICY_DETAILS'
);
export const createPolicyDetailsAcl = createAction('main/CREATE_POLICY_DETAILS_ACL');

export const updatePolicyDetailsAcl = createAction<{
  id: IPolicyModifiedAcl['id'];
  newProtocol?: IPolicyModifiedAcl['protocol'];
  newPorts?: IPolicyModifiedAcl['ports'];
  newDescription?: IPolicyModifiedAcl['description'];
  renewPortsUpdateStatus?: boolean;
}>('main/UPDATE_POLICY_DETAILS_ACL');

export const deletePolicyDetailsAcl = createAction<{
  id: IPolicyModifiedAcl['id'];
}>('main/DELETE_POLICY_DETAILS_ACL');

export const createPolicySideMenu = createAction<IDuplicatePolicyPayload | undefined>(
  'main/CREATE_POLICY_SIDE_MENU'
);

export const loadPolicyDetails = createAction<ILoadPoliciesDetails>('main/LOAD_POLICY_DETAILS');
export const loadPolicyDetailsWithNewRule = createAction('main/LOAD_POLICY_DETAILS_WITH_NEW_RULE');

export const setPolicyDetailsAllowedSubnets = createAction<IPolicyModifiedGatewayAllowedIpRange[]>(
  'main/SET_POLICY_DETAILS_ALLOWED_SUBNETS'
);
export const createPolicyDetailsAllowedSubnet = createAction<Partial<IPolicyGatewayAllowedIpRange>>(
  'main/CREATE_POLICY_DETAILS_ALLOWED_SUBNET'
);
export const updatePolicyDetailsAllowedSubnet = createAction<{
  id: IPolicyModifiedGatewayAllowedIpRange['id'];
  range: Partial<IPolicyGatewayAllowedIpRange>;
}>('main/UPDATE_POLICY_DETAILS_ALLOWED_SUBNET');
export const deletePolicyDetailsAllowedSubnet = createAction<{
  id: IPolicyModifiedGatewayAllowedIpRange['id'];
}>('main/DELETE_POLICY_DETAILS_ALLOWED_SUBNET');

export const addPolicyDetailsGateway = createAction<IPolicyGatewayRoute>(
  'main/ADD_POLICY_DETAILS_GATEWAY'
);

export const movePolicyDetailsGatewayUp = createAction<string>(
  'main/MOVE_POLICY_DETAILS_GATEWAY_UP'
);

export const movePolicyDetailsGatewayDown = createAction<string>(
  'main/MOVE_POLICY_DETAILS_GATEWAY_DOWN'
);

export const removePolicyDetailsGateway = createAction<IPolicyGatewayRoute>(
  'main/REMOVE_POLICY_DETAILS_GATEWAY'
);

export const createPolicyDetailsActiveHours = createAction(
  'main/CREATE_POLICY_DETAILS_ACTIVE_HOURS'
);
export const updatePolicyDetailsActiveHours = createAction<Partial<
  IPolicyDetails['activeHours']
> | null>('main/UPDATE_POLICY_DETAILS_ACTIVE_HOURS');

export const createPolicyDetailsAutoExpire = createAction('main/CREATE_POLICY_DETAILS_AUTO_EXPIRE');
export const updatePolicyDetailsAutoExpire = createAction<Partial<
  IPolicyDetails['autoExpire']
> | null>('main/UPDATE_POLICY_DETAILS_AUTO_EXPIRE');
