import styled from 'styled-components';

export const NoTagsWrapper = styled.div`
  height: 5.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > span:first-child {
    margin-bottom: 0.5rem;
  }
`;
