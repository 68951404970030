// libs
import { useEffect } from 'react';

// hooks
import { useDispatch, useSelector } from 'react-redux';

// selectors
import { selectOrganisationPlansTypes } from 'redux/selectors';

// components
import { PlansWrapper } from '../styled';
import { PlanCardStarter } from './Cards/Starter';
import { PlanCardBusiness } from './Cards/Business';
import { PlanCardEnterprise } from './Cards/Enterprise';

// actions
import { getOrganisationPlans } from 'redux/actions/organisation';

export const Plans = () => {
  const dispatch = useDispatch();

  const plans = useSelector(selectOrganisationPlansTypes);

  useEffect(() => {
    dispatch(getOrganisationPlans());
  }, [dispatch]);

  if (!plans) return null;

  return (
    <PlansWrapper>
      <PlanCardStarter plan={plans.Starter} />
      <PlanCardBusiness plan={plans.Business} />
      <PlanCardEnterprise plan={plans.Enterprise} />
    </PlansWrapper>
  );
};
