// libs
import { put, call } from 'typed-redux-saga';

// actions
import { changeCurrentOrganisation, getAccount, setInviteStatus } from 'redux/actions/account';
import { setModalContent, setModalIsLoading } from 'redux/actions/app';

// sagas
import { getOrganisationsSaga } from './getOrganisationsSaga';

// utils
import { ApiTypeCaller } from '../../api/utils';

// types
import { IActionPayload, IAcceptOrganisationInvite } from 'types';

export function* acceptInviteSaga({
  payload: { orgId, fromModal = false },
}: IActionPayload<IAcceptOrganisationInvite>) {
  if (fromModal) {
    yield put(setModalIsLoading(true));
  }

  const { success } = yield* ApiTypeCaller('acceptInvite', { orgId });

  if (success) {
    yield call(getOrganisationsSaga);
    yield put(setInviteStatus({ orgId, status: 'accepted' }));
    yield put(getAccount());
    yield put(changeCurrentOrganisation(orgId));
  }

  if (fromModal) {
    yield put(setModalIsLoading(false));
    yield put(setModalContent({ type: null, data: null }));
  }
}
