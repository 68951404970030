// components
import { Copy } from 'components';
import { CustomText } from 'typography/Text';

// hooks
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';

// selectors
import { selectAppDetailsDnsRecordNewZoneFqdn } from 'redux/selectors';

interface IFqdnEdit {
  visilbe: boolean;
}

export const FqdnEdit = ({ visilbe }: IFqdnEdit) => {
  const {
    palette: { fonts },
  } = useTheme();

  const fqdn = useSelector(selectAppDetailsDnsRecordNewZoneFqdn);

  return (
    <>
      {fqdn && (
        <Copy visible={visilbe} copyingValue={fqdn}>
          <CustomText ellipsis={true} title={fqdn} type="body-2" color={fonts.title}>
            {fqdn}
          </CustomText>
        </Copy>
      )}
    </>
  );
};
