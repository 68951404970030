import React from 'react';
import styled, { useTheme } from 'styled-components';
import { CustomText } from 'typography/Text';

const TagContainer = styled.div`
  padding: 0 0.5rem;

  background-color: ${({ theme }) => theme.palette.background.sections};
  border: 0.0625rem solid ${({ theme }) => theme.palette.fills.strokeMedium};
  border-radius: 0.25rem;
`;

interface ITag {
  children: React.ReactNode;
}
export const Tag = ({ children }: ITag) => {
  const {
    palette: { fonts },
  } = useTheme();
  return (
    <TagContainer>
      <CustomText type="hint" color={fonts.bodyLight}>
        {children}
      </CustomText>
    </TagContainer>
  );
};
