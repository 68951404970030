// libs
import styled from 'styled-components';

// components
import { TimeZoneOption } from 'components';

// hooks
import { useSelector } from 'react-redux';

// selectors
import { selectAppDetailsPolicyAutoExpireTimeZone } from 'redux/selectors';

// constants
import { TIME_ZONES_DATA_SOURCE } from 'appConstants/time';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const TimeZoneView = () => {
  const currentTimeZoneName = useSelector(selectAppDetailsPolicyAutoExpireTimeZone);

  const currentTimeZone = TIME_ZONES_DATA_SOURCE?.find(zone => zone.zone === currentTimeZoneName);

  return (
    <Container>
      <TimeZoneOption zone={currentTimeZone?.zone} offset={currentTimeZone?.offset} />
    </Container>
  );
};
