import styled from 'styled-components';

export const Container = styled.div`
  padding: 0.5rem;
  margin-left: -0.5rem;

  :first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  :last-child {
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }

  background-color: ${({ theme }) => theme.palette.background.base};
  display: flex;
  flex-direction: row;
`;

export const CloseButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;

  margin-top: 0.375rem;
  margin-left: 0.75rem;
`;
