// libs
import { Component, ReactNode } from 'react';

// components
import { ErrorFallback } from './ErrorFallback';

interface Props {
  children: ReactNode;
  onError?: () => void;
  onRefresh: () => void;
}

interface State {
  error: boolean;
}

export class TrueErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { error: false };
  }

  public componentDidCatch() {
    this.props.onError?.();
  }

  static getDerivedStateFromError() {
    return { error: true };
  }

  public render() {
    if (this.state.error) {
      return <ErrorFallback onRefreshClick={this.props.onRefresh} />;
    }
    return this.props.children;
  }
}
