// libs
import { css } from 'styled-components';

// components
import { CustomText } from 'typography/Text';
import { IconArrowLeft } from 'assets/svg';
import { PageWrapper } from './styled';

// Hooks
import { useTranslations } from 'hooks/useTranslations';
import { useGoBack } from 'hooks/useGoBack';
import { useTheme } from 'styled-components';

export const LinkBack = () => {
  const { translate } = useTranslations('account');

  const {
    palette: { icons },
  } = useTheme();

  const goBack = useGoBack();

  return (
    <PageWrapper onClick={goBack}>
      <IconArrowLeft
        css={css`
          margin-right: 0.375rem;
        `}
      />
      <CustomText type="body-1" color={icons.primary}>
        {translate('back')}
      </CustomText>
    </PageWrapper>
  );
};
