// libs
import { useTheme } from 'styled-components';

// components
import { CustomText } from 'typography/Text';
import { CustomMarker, ListItem } from './styled';

export const ListElement = ({ children }: { children: React.ReactNode }) => {
  const {
    palette: { fonts },
  } = useTheme();

  return (
    <ListItem>
      <CustomMarker />
      <CustomText type="hint" color={fonts.bodyDark}>
        {children}
      </CustomText>
    </ListItem>
  );
};
