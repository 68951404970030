import styled from 'styled-components';
import { ReactComponent as IconArrowLeft } from 'assets/svg/arrow-left.svg';

export const ContentContainer = styled.div`
  margin-top: 1rem;
  margin-bottom: 2rem;
`;

export const IconArrowRight = styled(IconArrowLeft)`
  transform: rotate(180deg);
  width: 0.875rem;
  height: 0.875rem;
  vertical-align: middle;
`;
