// libs
import { memo, useMemo } from 'react';
import styled from 'styled-components';

// components
import Skeleton from 'react-loading-skeleton';
import { Tooltip } from 'components';
import { CustomText } from 'typography/Text';
import {
  IconPoliciesDetailsFullMesh,
  IconPoliciesTrafficAllowed,
  IconPoliciesTrafficDenied,
} from 'assets/svg';

// hooks
import { useTheme } from 'styled-components';
import { useTranslations } from 'hooks/useTranslations';

// types
import { IPolicy, IReceiver } from 'types';
import { intersection, uniq } from 'ramda';

interface IDirectionState {
  state: IPolicy['state'];
  senderTags: IPolicy['senderTags'];
  receiver: IReceiver;
  isEnabled: boolean;
  isLoading: boolean;
}

export const IconContainer = styled.div<{ isEnabled?: boolean }>`
  display: flex;
  width: 2.5rem;
  flex-direction: row;
  justify-content: center;
  opacity: ${({ isEnabled }) => (isEnabled ? 1 : 0.6)};
`;

export const PoliciesDirection = memo(
  ({ state, senderTags, receiver, isEnabled, isLoading }: IDirectionState) => {
    const {
      palette: { fonts },
    } = useTheme();

    const { translate } = useTranslations('policies');

    const tagReceiver = receiver.type === 'tags' && receiver.data;

    const gatewayReceiver =
      receiver.type === 'gateways' &&
      uniq(receiver.data.flatMap(item => item.routes.map(route => route)));

    const receiverLength = tagReceiver
      ? tagReceiver.length
      : gatewayReceiver
      ? gatewayReceiver.length
      : 0;

    const isMesh = useMemo(
      () => tagReceiver && Boolean(intersection(senderTags, tagReceiver)?.length),
      [senderTags, tagReceiver]
    );

    return (
      <>
        {isLoading ? (
          <Skeleton />
        ) : (
          <Tooltip
            overlay={
              state === 'InactiveNoAcls' ? (
                <CustomText type="body-2" color={fonts.primaryButton}>
                  {translate('no_acl_tooltip')}
                </CustomText>
              ) : receiverLength === 0 ? (
                <CustomText type="body-2" color={fonts.primaryButton}>
                  {translate('no_receiver_tooltip')}
                </CustomText>
              ) : null
            }
          >
            <IconContainer isEnabled={isEnabled}>
              {state === 'InactiveNoAcls' || receiverLength === 0 ? (
                <IconPoliciesTrafficDenied />
              ) : isMesh ? (
                <IconPoliciesDetailsFullMesh />
              ) : (
                <IconPoliciesTrafficAllowed />
              )}
            </IconContainer>
          </Tooltip>
        )}
      </>
    );
  }
);
