// Lib
import { useState, useRef } from 'react';
import { Offline, PollingConfig } from 'react-detect-offline';

// Components
import { CustomAlert } from 'components';

// Hooks
import { useTranslations } from 'hooks/useTranslations';

// Constants
import { OFFLINE_RETRY_INTERVAL } from 'appConstants/ui';

const pollingConfig: PollingConfig = { url: '', enabled: false, interval: 5000, timeout: 5000 };

export const OfflineDetector = () => {
  const timer = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [reconnecting, setReconnecting] = useState<boolean>(true);
  const { translate } = useTranslations('global');

  const onTryAgainClick = () => {
    setReconnecting(true);
    if (timer.current) {
      clearInterval(timer.current);
      timer.current = setTimeout(() => setReconnecting(false), OFFLINE_RETRY_INTERVAL);
    }
  };

  const onStateChange = (newState: boolean) => {
    if (timer.current) {
      clearInterval(timer.current || 0);
      timer.current = setTimeout(
        () => setReconnecting(newState),
        newState ? 0 : OFFLINE_RETRY_INTERVAL
      );
    }
  };

  return (
    <Offline polling={pollingConfig} onChange={onStateChange}>
      <CustomAlert
        message={translate('lost_connection')}
        action={
          reconnecting ? undefined : { text: translate('try_again'), onClick: onTryAgainClick }
        }
      />
    </Offline>
  );
};
