import { IconDnsAddZoneButtonPopupLink } from 'assets/svg';
import styled from 'styled-components';

export const PopupTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ArrowRightIcon = styled(IconDnsAddZoneButtonPopupLink)`
  transform: rotate(180deg);
`;
