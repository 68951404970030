// Lib
import { put } from 'typed-redux-saga';
import { setIsLoading, setTags } from 'redux/actions/tags';

// Types
import { IActionPayload, IGetTags } from 'types';

// Constants
import { ITEMS_PER_TABLE_PAGE } from 'appConstants/ui';

// Utils
import { ApiTypeCaller } from '../../api/utils';
import { SyncQueryParams } from 'redux/sagas/utils';

export function* getTagsSaga({
  payload: { syncQueryParams, ...payload },
}: IActionPayload<IGetTags>) {
  yield put(setIsLoading(true));

  if (syncQueryParams) {
    payload = yield* SyncQueryParams(payload);
  }

  payload.per_page = ITEMS_PER_TABLE_PAGE;
  const { result, success } = yield* ApiTypeCaller('getTags', payload);

  if (success && result) yield put(setTags({ ...result, query: payload.search || '' }));
}
