// components
import { CustomText } from 'typography/Text';

// hooks
import { useTheme } from 'styled-components';

// types
import { IPolicyModifiedAcl } from 'types';

// utils
import { useAclProtocolToText } from '../../helpers';

interface IProtocol {
  protocol: IPolicyModifiedAcl['protocol'];
}

export const Protocol = ({ protocol, ...rest }: IProtocol) => {
  const {
    palette: { fonts },
  } = useTheme();

  const text = useAclProtocolToText(protocol);

  return (
    <CustomText
      data-test="policy-details-acls-table-protocol"
      type="body-2"
      color={fonts.bodyDark}
      {...rest}
    >
      {text}
    </CustomText>
  );
};
