// Components
import { Details } from 'components';
import { CustomText } from 'typography/Text';

// Selectors
import { selectAppDetailsSystemEnrolledAt, selectAppDetailsTabType } from 'redux/selectors';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { useSelector } from 'react-redux';

// utils
import { localizedTime } from 'utils/timeFormatting';

export const EnrolledAtBlock = () => {
  const { translate } = useTranslations('systems');

  const enrolledAt = useSelector(selectAppDetailsSystemEnrolledAt);
  const type = useSelector(selectAppDetailsTabType);

  if (type === 'system-view' || type === 'system-edit') return null;

  return (
    <Details.KeyValue height="2.875rem" title={translate('details.enrolled_at')}>
      <CustomText type="body-2">{enrolledAt && localizedTime(enrolledAt)}</CustomText>
    </Details.KeyValue>
  );
};
