import { css } from 'styled-components';

export const tableStyles = css`
  width: 100%;
  cursor: default;
  margin-bottom: 2rem;

  .ant-table-tbody > tr:hover > td {
    background: unset;
  }
  td:nth-child(1),
  th:nth-child(1) {
    width: 16.5%;
  }
  td:nth-child(2),
  th:nth-child(2) {
    width: 9.75%;
  }
  td:nth-child(3),
  th:nth-child(3) {
    width: 9.75%;
  }
  tbody tr:last-child td {
  }
  tbody tr {
    cursor: default;
  }
`;
