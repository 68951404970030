// Lib
import { call, put } from 'typed-redux-saga';

// Actions
import { setModalContent } from 'redux/actions/app';
import { create2FAQRCodeSaga } from './create2FAQRCodeSaga';

export function* openEnable2FAModalSaga() {
  yield call(create2FAQRCodeSaga);
  yield put(setModalContent({ type: 'enable-2FA', data: null }));
}
