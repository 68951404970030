// libs
import { css, useTheme } from 'styled-components';

// components
import { IconArrowRight, List, ListItem } from './styled';
import { InternalLink } from 'components/Links/InternalLink';
import { ControllableIcon, CustomTag } from 'components';
import { IconEnrolmentKey } from 'assets/svg';
import { CustomText } from 'typography/Text';

// hooks
import { useSelector, useDispatch } from 'react-redux';
import { useCallback, useEffect } from 'react';
import { useTranslations } from 'hooks/useTranslations';

// selectors
import {
  selectAppDetailsTagData,
  selectAppDetailsTagKeysItems,
  selectCurrentOrganisationId,
  selectAppDetailsTagColour,
  selectAppDetailsTagDescription,
  selectAppDetailsTagNewColour,
  selectAppDetailsTagNewDescription,
  selectAppDetailsTagRef,
} from 'redux/selectors';
import { extendTagDetails } from 'redux/actions/tags';

// constants
import { ROUTE_PATHS } from 'appConstants';
import { TAG_DETAILS_ELEMENTS_NUM, TAGS_DEFAULT_COLOUR } from 'appConstants/tags';

export const KeysTab = () => {
  const {
    palette: { fonts },
  } = useTheme();

  const { translate } = useTranslations('tags');
  const dispatch = useDispatch();

  const tag = useSelector(selectAppDetailsTagData);
  const colour = useSelector(selectAppDetailsTagColour);
  const newColour = useSelector(selectAppDetailsTagNewColour);
  const description = useSelector(selectAppDetailsTagDescription);
  const newDescription = useSelector(selectAppDetailsTagNewDescription);
  const tagRef = useSelector(selectAppDetailsTagRef);

  const keys = useSelector(selectAppDetailsTagKeysItems);
  const orgId = useSelector(selectCurrentOrganisationId);

  const fetchData = useCallback(
    () => dispatch(extendTagDetails({ tag: tag?.tag || '', module: 'keys' })),
    [dispatch, tag]
  );

  const getDescription = useCallback(() => {
    if (keys) {
      if (keys.length === 1) {
        return `details.connections.tabs.keys.description.title_one`;
      }
      if (keys.length > TAG_DETAILS_ELEMENTS_NUM) {
        return `details.connections.tabs.keys.description.title_first`;
      }
      return `details.connections.tabs.keys.description.title_all`;
    }

    return 'details.connections.tabs.keys.description.title_all';
  }, [keys]);

  useEffect(() => {
    if (!keys) {
      fetchData();
    }
  }, [dispatch, keys, fetchData]);

  return (
    <div>
      {keys && (
        <>
          <CustomText
            css={css`
              white-space: pre;
              display: flex;
              margin-top: 1.5rem;
              margin-bottom: 0.75rem;
              > span {
                margin-left: 0.5rem;
              }
            `}
            type="body-2"
            color={fonts.bodyLight}
          >
            {translate(getDescription(), { number: TAG_DETAILS_ELEMENTS_NUM })}
            {tagRef && description && (
              <CustomTag
                disableHoverEffect
                tag={{
                  ref: tagRef,
                  colour: newColour || colour || TAGS_DEFAULT_COLOUR,
                  tag: newDescription !== undefined ? newDescription : description,
                }}
              />
            )}
          </CustomText>
          {keys?.length && (
            <InternalLink
              type="body-2"
              to={`/org/${orgId}${ROUTE_PATHS.keys}/?include_disabled=true&search=tags%3A${tag?.tag}`}
              ellipsis={true}
              icon={
                <IconArrowRight
                  css={css`
                    margin-left: 0.125rem;
                  `}
                />
              }
            >
              {translate('details.connections.tabs.keys.all_attached')}
            </InternalLink>
          )}

          <List>
            {keys.slice(0, TAG_DETAILS_ELEMENTS_NUM).map(key => {
              const keyName = key.description;

              return (
                <ListItem tabIndex={0} key={key.id}>
                  <ControllableIcon
                    icon={<IconEnrolmentKey />}
                    ephemeral={key.type === 'Ephemeral'}
                    size="medium"
                    css={css`
                      margin-right: 0.75rem;
                    `}
                  />
                  <InternalLink
                    title={keyName}
                    type="body-2"
                    to={`/org/${orgId}${ROUTE_PATHS.keys}/${key.id}?include_disabled=true?`}
                    ellipsis={true}
                  >
                    {keyName}
                  </InternalLink>
                  <CustomText
                    type="body-2"
                    color={fonts.disabled}
                    css={css`
                      white-space: pre;
                    `}
                  >
                    {!key.isEnabled && ' ' + `(${translate('details.connections.disabled')})`}
                  </CustomText>
                </ListItem>
              );
            })}
          </List>
        </>
      )}
    </div>
  );
};
