// components
import { CustomText } from 'typography/Text';
import { Details, ControllableOSIcon } from 'components';
import { OsPlatformWrapper, OsPlatformIconWrapper, OsPlatformDetailsWrapper } from './styled';

// selectors
import {
  selectAppDetailsApprovedSystemState,
  selectAppDetailsSystemOsVersion,
  selectAppDetailsSystemPlatformType,
} from 'redux/selectors';

// hooks
import { css, useTheme } from 'styled-components';
import { useSelector } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';

// types
import { OSTypes } from 'types';

export const OsDetailsBlock = () => {
  const { translate } = useTranslations('systems');

  const {
    palette: { fonts },
  } = useTheme();

  const osVersion = useSelector(selectAppDetailsSystemOsVersion);
  const platformType = useSelector(selectAppDetailsSystemPlatformType);
  const state = useSelector(selectAppDetailsApprovedSystemState);

  return (
    <Details.KeyValue
      css={css`
        margin-bottom: 1.5rem;
      `}
      title={translate('details.os_details')}
    >
      <OsPlatformWrapper>
        <OsPlatformIconWrapper>
          <ControllableOSIcon
            platformType={platformType as OSTypes}
            size="large"
            state={state === 'Disabled' ? 'muted' : 'default'}
          />
        </OsPlatformIconWrapper>
        <OsPlatformDetailsWrapper>
          <CustomText type="body-2" color={fonts.title}>
            {platformType}
          </CustomText>
          <CustomText type="hint" color={fonts.bodyLight}>
            {osVersion}
          </CustomText>
        </OsPlatformDetailsWrapper>
      </OsPlatformWrapper>
    </Details.KeyValue>
  );
};
