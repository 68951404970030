// libs
import { put, select, call } from 'typed-redux-saga';

// selectors
import { selectAccountMainOrganisation, selectAccountOrganisations } from 'redux/selectors';

// actions
import { setOrganisationInfo } from 'redux/actions/organisation';
import { setCurrentOrganisation } from 'redux/actions/account';
import { push } from 'redux/actions/router';

// utils
import { ApiTypeCaller } from '../../api/utils';
import { translator } from 'utils';

// types
import { INotificationType } from 'types';

// constants
import { defaultOrgInfo } from 'redux/reducers/organisation';

export function* getCurrentOrganisationSaga() {
  const orgs = yield* select(selectAccountOrganisations);

  const translate = translator('notifications');

  if (orgs.length) {
    const { result, success } = yield* ApiTypeCaller('getCurrentOrganisation', undefined, {
      customNotification: {
        description: translate('cannot_get_org'),
        showType: INotificationType.Error,
      },
    });
    if (success && result) {
      yield put(setOrganisationInfo(result));
    } else {
      const mainOrg = yield* select(selectAccountMainOrganisation);
      if (mainOrg) {
        yield put(push([`/org/${mainOrg.orgId}`]));
      }
    }

    return success;
  } else {
    yield* call(setCurrentOrganisation, null);
    yield* call(setOrganisationInfo, defaultOrgInfo);
  }
}
