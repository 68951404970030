// libs
import styled from 'styled-components';

export const ZoneInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  border-radius: 0.25rem;
  background-color: ${({ theme }) => theme.palette.background.base};
  padding: 1.5rem 1rem;
  margin-bottom: 1.5rem;
`;

export const ZoneInfoRow = styled.div`
  display: flex;
  flex-direction: row;

  :not(:last-child) {
    margin-bottom: 1rem;
  }
`;

export const ZoneInfoColumnName = styled.div`
  width: 30%;
`;
export const ZoneInfoColumnDescription = styled.div`
  width: 70%;
`;
