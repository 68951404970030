// Lib
import { put, all } from 'typed-redux-saga';

import { setEnrolmentKeyDetailsContent } from 'redux/actions/app';

import { IActionPayload, ILoadViewEditEnrolmentKeyDetailsParams } from 'types';

// Utils
import { ApiTypeCaller } from '../../api/utils';

export function* loadEnrolmentKeyDetailsSaga({
  payload: { enrolmentKeyId, mode, scrollToAutoExpire },
}: IActionPayload<ILoadViewEditEnrolmentKeyDetailsParams>) {
  const { result, success } = yield* ApiTypeCaller('getEnrolmentKey', { enrolmentKeyId });

  if (success && result) {
    if (mode === 'edit') {
      yield put(
        setEnrolmentKeyDetailsContent({
          type: 'enrolment-key-edit',
          data: result,
          dataNew: result,
          isLoading: false,
          scrollToAutoExpire,
          validationState: {},
        })
      );
    } else if (mode === 'view') {
      yield all([
        put(
          setEnrolmentKeyDetailsContent({
            type: 'enrolment-key-view',
            data: result,
            isLoading: false,
          })
        ),
      ]);
    }
  }
}
