import styled from 'styled-components';
import { Tag as AntdTag } from 'antd';

export const Tag = styled(AntdTag)<{ $hoverEffect: boolean }>`
  display: inline-flex;
  max-width: 100%;
  align-items: center;
  height: 1.375rem;
  line-height: 1.375rem;
  border-radius: 0.25rem;
  background: ${({ color }) => color};
  margin-right: 0.375rem;
  padding: 0 0.5rem;
  border: 0.0625rem solid ${({ theme }) => theme.palette.background.iconBackground};
  :hover {
    opacity: ${props => (props.$hoverEffect ? 0.85 : 1)};
  }
`;

export const TagIconCloseContainer = styled.div`
  display: flex;
  cursor: pointer;
`;
