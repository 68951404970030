// libs
import { CancelEffect, ForkEffect } from 'redux-saga/effects';
import { select, call, fork, put } from 'typed-redux-saga';
import { takeEvery, takeLatest } from 'redux-saga/effects';
import { Task } from 'redux-saga';

// selectors
import { selectPathName } from 'redux/selectors';

// types
import { IActionPayload, ICloseDetails } from 'types';

// utils
import { orgPush, loadingHandler } from '../../utils';

// actions
import {
  closeDetails,
  clearDetails,
  loadSystemDetails,
  loadEnrolmentKeyDetails,
  loadUnapprovedSystemDetails,
  loadDnsRecordDetails,
  loadTagDetails,
  loadPolicyDetails,
  loadTrustRequirementDetails,
} from 'redux/actions/app';

// sagas
import { loadPolicyDetailsSaga } from '../policies/loadPolicyDetailsSaga';
import { loadRecordDetailsSaga } from '../dns/loadRecordDetails';
import { loadTagDetailsSaga } from '../tags/loadTagDetailsSaga';
import { loadTrustRequirementDetailsSaga } from '../trust-requirements/loadTrustRequirementDetails';
import { loadSystemDetailsSaga } from '../systems/loadSystemDetailsSaga';
import { loadSystemDetailsSaga as loadUnapprovedSystemDetailsSaga } from '../unapproved-systems/loadSystemDetailsSaga';
import { loadEnrolmentKeyDetailsSaga } from '../enrolment-keys/loadKeyDetailsSaga';

// components
import { ROUTE_PATHS } from 'appConstants';

let openingTask: Task | ForkEffect | CancelEffect | null | any = null;

const actionMap: Record<string, any> = {
  [loadSystemDetails.type]: loadSystemDetailsSaga,
  [loadUnapprovedSystemDetails.type]: loadUnapprovedSystemDetailsSaga,
  [loadEnrolmentKeyDetails.type]: loadEnrolmentKeyDetailsSaga,
  [loadTagDetails.type]: loadTagDetailsSaga,
  [loadPolicyDetails.type]: loadPolicyDetailsSaga,
  [loadDnsRecordDetails.type]: loadRecordDetailsSaga,
  [loadTrustRequirementDetails.type]: loadTrustRequirementDetailsSaga,
};

export function* HandleCloseDetails({
  payload: { redirect = true },
}: IActionPayload<ICloseDetails>) {
  if (openingTask?.isRunning()) return;

  const pathName: string = yield select(selectPathName);

  if (redirect) {
    if (pathName.includes(ROUTE_PATHS.systems))
      yield call(orgPush, `${ROUTE_PATHS.systems}`, { keepSearch: true });
    if (pathName.includes(ROUTE_PATHS['unapproved-systems']))
      yield call(orgPush, `${ROUTE_PATHS['unapproved-systems']}`, { keepSearch: true });
    if (pathName.includes(ROUTE_PATHS.keys))
      yield call(orgPush, `${ROUTE_PATHS.keys}`, { keepSearch: true });
    if (pathName.includes(ROUTE_PATHS.tags))
      yield call(orgPush, `${ROUTE_PATHS.tags}`, { keepSearch: true });
    if (pathName.includes(ROUTE_PATHS.policies))
      yield call(orgPush, `${ROUTE_PATHS.policies}`, { keepSearch: true });
    if (pathName.includes(ROUTE_PATHS.dns))
      // we need to open dns at selected zone
      yield call(orgPush, `${ROUTE_PATHS.dns}/${pathName.match(/zones\/\d+/)?.[0] || 'zones'}`, {
        keepSearch: true,
      });
    if (pathName.includes(ROUTE_PATHS['trust-requirements']))
      yield call(orgPush, `${ROUTE_PATHS['trust-requirements']}`, { keepSearch: true });
  }

  yield put(clearDetails());
}

export function* HandleDetailsLoading(action: IActionPayload<any>) {
  if (openingTask?.isRunning()) return;
  openingTask = yield* fork(() => loadingHandler(actionMap[action.type as string], action));
}

export function* detailsSaga() {
  yield takeEvery(closeDetails, HandleCloseDetails);
  yield takeLatest(
    [
      loadSystemDetails,
      loadEnrolmentKeyDetails,
      loadUnapprovedSystemDetails,
      loadPolicyDetails,
      loadDnsRecordDetails,
      loadTagDetails,
      loadTrustRequirementDetails,
    ],
    HandleDetailsLoading
  );
}
