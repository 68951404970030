// libs
import { css, useTheme } from 'styled-components';

// components
import {
  CardWrapper,
  CardContainer,
  ContentContainer,
  DescriptionText,
  StaticContentContainer,
} from '../styled';
import { PlanHeader, Pricing, FeatureList } from './components';
import { CustomText } from 'typography/Text';
import { CustomProgress, CustomButton } from 'components';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { useSelector } from 'react-redux';

// selectors
import { selectCurrentOrganisationInfo, selectCurrentOrganisationIsOwner } from 'redux/selectors';

// types
import { IPricingModel } from 'types';

// constants
import { ENTERPRISE_PLAN, LINK_TO_SALES_MAIL } from 'appConstants';

// utils
import { formatCurrencyAmount } from 'utils';

interface PlanCardProps {
  plan: IPricingModel;
}

const type = ENTERPRISE_PLAN;

export const PlanCardEnterprise = ({ plan }: PlanCardProps) => {
  const {
    palette: { fonts },
  } = useTheme();

  const { translate } = useTranslations('settings');

  const { enabled, currencySymbol, rate } = plan;

  const orgData = useSelector(selectCurrentOrganisationInfo);
  const isOwner = useSelector(selectCurrentOrganisationIsOwner);

  const formattedRate = formatCurrencyAmount({ value: rate });
  const isCurrentPlan = orgData.plan === type;

  return (
    <CardWrapper enabled={enabled}>
      <PlanHeader
        text={`${translate('plans.n_systems_used', {
          from: orgData.enrolledSystems,
          to: orgData.maxSystems,
        })}`}
        active={isCurrentPlan && enabled}
      />
      <CardContainer active={isCurrentPlan}>
        {isCurrentPlan && enabled ? (
          <CustomProgress percent={(orgData.enrolledSystems / orgData.maxSystems) * 100} sharp />
        ) : (
          <CustomProgress active={false} percent={100} sharp color="transparent" />
        )}
        <ContentContainer>
          <StaticContentContainer>
            <CustomText
              type="sub-heading-1"
              css={css`
                margin-bottom: 0.25rem;
              `}
              color={enabled ? fonts.bodyDark : fonts.disabled}
            >
              {translate(`plans.${type.toLowerCase()}`)}
            </CustomText>
            <Pricing symbol={currencySymbol} rate={rate ? formattedRate : null} type={type} />
            <DescriptionText
              type="hint"
              color={enabled && isOwner ? fonts.bodyLight : fonts.disabled}
            >
              {translate(`plans.${type.toLowerCase()}_description`)}
            </DescriptionText>
            <FeatureList type={type} />
          </StaticContentContainer>

          {enabled && isOwner && !isCurrentPlan && (
            <a
              aria-label={translate('aria_labels.sales')}
              href={LINK_TO_SALES_MAIL}
              target="_blank"
              rel="noopener noreferrer"
              css={css`
                align-self: center;
              `}
            >
              <CustomButton kind="primary" size="large">
                {translate('plans.talk_to_sales')}
              </CustomButton>
            </a>
          )}
        </ContentContainer>
      </CardContainer>
    </CardWrapper>
  );
};
