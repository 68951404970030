// libraries
import { useTheme } from 'styled-components';

// components
import { CustomText } from 'typography/Text';
import { SectionHeaderContainer, IconWrapper, RightDirectionIcon } from './styled';
import { InternalLink } from 'components';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { useSelector } from 'react-redux';
import { selectCurrentOrganisationInfo } from 'redux/selectors';

interface ISectionHeader {
  title: string;
  route?: string | React.ReactElement;
}

export const SectionHeader = ({ title, route, ...rest }: ISectionHeader) => {
  const {
    palette: { fonts },
  } = useTheme();

  const orgInfo = useSelector(selectCurrentOrganisationInfo);
  const { translate } = useTranslations('landing-page');
  return (
    <SectionHeaderContainer {...rest}>
      <CustomText color={fonts.title} type="sub-heading-1">
        {title}
      </CustomText>
      {typeof route === 'string' && (
        <InternalLink to={`/org/${orgInfo.id}${route}`} size="medium">
          <IconWrapper>
            {translate('view_all')}
            <RightDirectionIcon />
          </IconWrapper>
        </InternalLink>
      )}
      {typeof route === 'object' && route}
    </SectionHeaderContainer>
  );
};
