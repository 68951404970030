import { useCallback, useEffect, useState } from 'react';

interface ILocalStorage<T> {
  key: string;
  defaultValue: T;
}

const getStorageValue = <T extends unknown>({ key, defaultValue }: ILocalStorage<T>) => {
  // getting stored value
  const saved = localStorage.getItem(key);
  const initial = saved ? JSON.parse(saved) : null;
  return initial || defaultValue;
};

export const useLocalStorage = <T extends unknown>({ key, defaultValue }: ILocalStorage<T>) => {
  const [value, setValue] = useState<string>(() => {
    return getStorageValue({ key, defaultValue });
  });

  const removeValue = useCallback(() => {
    localStorage.removeItem(key);
  }, [key]);

  useEffect(() => {
    // storing input name
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return { value, setValue, removeValue };
};
