// libs
import { css } from 'styled-components';

// Components
import Skeleton from 'react-loading-skeleton';
import { DateColumnTitle } from './DateColumnTitle';
import { ColumnTitle } from 'components';
import { CustomText } from 'typography/Text';
import { Level } from './Level';

// Types
import { TFunction } from 'react-i18next/.';
import { translations, ISecurityEvent } from 'types';

// utils
import { localizedTime } from 'utils/timeFormatting';

interface ISecurityEventsProps {
  isLoading: boolean;
  translate: TFunction<translations>;
}

export const SecurityEventsColumns = ({ isLoading, translate }: ISecurityEventsProps) => [
  {
    title: <></>,
    dataIndex: 'level',
    render: (level: ISecurityEvent['level']) =>
      isLoading ? <Skeleton /> : <Level level={level} />,
  },
  {
    title: <ColumnTitle>{translate('table.message')}</ColumnTitle>,
    dataIndex: 'message',
    render: (message: ISecurityEvent['message']) =>
      isLoading ? (
        <Skeleton />
      ) : (
        <CustomText
          css={css`
            white-space: normal;
          `}
          type="body-2"
        >
          {message}
        </CustomText>
      ),
  },
  {
    title: <ColumnTitle>{translate('table.user')}</ColumnTitle>,
    dataIndex: 'userName',
    render: (userName: ISecurityEvent['userName']) =>
      isLoading ? <Skeleton /> : <CustomText type="body-2">{userName}</CustomText>,
  },
  {
    title: <DateColumnTitle />,
    dataIndex: 'timeStamp',
    render: (timestamp: ISecurityEvent['timeStamp']) =>
      isLoading ? <Skeleton /> : <CustomText type="body-2">{localizedTime(timestamp)}</CustomText>,
  },
];
