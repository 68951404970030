// libs
import { put, call } from 'typed-redux-saga';

// actions
import { setSystem } from 'redux/actions/systems';
import { pushNotification } from 'redux/actions/app';

// utils
import { ApiTypeCaller } from '../../api/utils';
import { translator } from 'utils';

// types
import { IActionPayload, INotificationType, IEnableUntilSystemProps, EnableUntilType } from 'types';

// sagas
import { getCurrentOrganisationSaga } from '../organisation/getCurrentOrganisationSaga';

export function* enableUntilSystemSaga({
  payload: { systemId, updatedParams, action, text },
}: IActionPayload<IEnableUntilSystemProps & EnableUntilType>) {
  const { success, result } = yield* ApiTypeCaller('enableSystemUntil', {
    systemId,
    updatedParams,
  });

  const translate = translator('notifications');

  if (success && result) {
    yield put(setSystem(result));
    yield call(getCurrentOrganisationSaga);

    yield put(
      pushNotification({
        showType: INotificationType.Success,
        description: translate(
          action === 'EnableFor' ? 'system_enabled_for' : 'system_disabled_after',
          {
            time: text,
          }
        ),
      })
    );
  }
}
