// Hooks
import { useTranslations } from 'hooks/useTranslations';
import { useSelector } from 'react-redux';

// Selectors
import { selectSelectedEnrolmentKey } from 'redux/selectors';

// Components
import { StyledItem, EnrolmentKeySelect, GoToSystems } from '../components';
import { StyledCommandLineCode, StyledCodeText } from '../styled';
import { Trans } from 'react-i18next';

export const Kubernetes = () => {
  const { translate } = useTranslations('enrol-new-system');
  const selectedKey = useSelector(selectSelectedEnrolmentKey);

  return (
    <>
      <StyledItem
        title={translate('kubernetes.steps.1.title')}
        content={
          <StyledCommandLineCode
            code={`spec:
  containers:
  - name: nginx-container
    ...

  - name: enclave-sidecar
    image: enclavenetworks/enclave:latest
    env:
      - name: "ENCLAVE_ENROLMENT_KEY"
        value: "XXXXX-XXXXX-XXXXX-XXXXX-XXXXX"
    securityContext:
      capabilities:
        add: ['NET_ADMIN']
    volumeMounts:
      - name: tun
        mountPath: /dev/net/tun
  volumes:
    - name: tun
      hostPath:
        type: 'CharDevice'
        path: /dev/net/tun`}
          />
        }
      />
      <EnrolmentKeySelect alternativeTitle={translate('kubernetes.steps.2.title')} />
      <StyledItem
        title={
          <Trans
            i18nKey={translate('kubernetes.steps.3.title')}
            components={[
              <StyledCodeText key={0} text="XXXXX-XXXXX-XXXXX-XXXXX-XXXXX" />,
              <StyledCodeText
                key={1}
                text={selectedKey ? selectedKey.key : translate('docker.steps.3.key')}
              />,
            ]}
          />
        }
      />
      <StyledItem
        title={
          <>
            {translate('kubernetes.steps.4.title')} <StyledCodeText text="kubectl apply" />{' '}
            {translate('kubernetes.steps.4.title_alt')}
          </>
        }
      />
      <GoToSystems />
    </>
  );
};
