// libs
import styled from 'styled-components';

// hooks
import { useDispatch, useSelector } from 'react-redux';

// selctors
import { selectAccountSessionLogoutUrl } from 'redux/selectors';

// actions
import { setSessionLogoutUrl } from 'redux/actions/account';
import { unlockNotifications } from 'redux/actions/app';

const HiddenIframe = styled.iframe`
  width: 0;
  height: 0;
`;

export const SessionLogoutUrl = () => {
  const dispatch = useDispatch();

  const sessionLogoutUrl = useSelector(selectAccountSessionLogoutUrl);

  if (!sessionLogoutUrl) return null;

  const onLoad = () => {
    dispatch(setSessionLogoutUrl(null));
    dispatch(unlockNotifications());
  };

  return <HiddenIframe hidden src={sessionLogoutUrl} onLoad={onLoad} />;
};
