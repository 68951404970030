// libs
import { memo } from 'react';

// components
import Skeleton from 'react-loading-skeleton';

// types
import { IRecord } from 'types/dns';
import { TagAndSystemList } from 'components/TagAndSystemList';

interface ITargets {
  isLoading: boolean;
  tags: IRecord['tags'];
  systems: IRecord['systems'];
}

export const Targets = memo(({ tags, systems, isLoading }: ITargets) => {
  return <>{isLoading ? <Skeleton /> : <TagAndSystemList tags={tags} systems={systems} />}</>;
});
