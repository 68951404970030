// libs
import { IActionPayload, IRemoveAccountAccessTokenParams, INotificationType } from 'types';

// actions
import { setModalContent, setModalIsLoading, pushNotification } from 'redux/actions/app';
import { takeAwayAccessToken } from 'redux/actions/account';

// types
import { call, put } from 'typed-redux-saga';

// utils
import { ApiTypeCaller } from '../../api/utils';
import { translator } from 'utils';

// sagas
import { validateStepUpTokenSaga } from './validateStepUpTokenSaga';

// constants
import { ACCOUNT_DELETE_ACCESS_TOKEN_ERROR_FIELD } from 'appConstants';

export function* removeAccessTokenSaga({
  payload,
}: IActionPayload<IRemoveAccountAccessTokenParams>) {
  yield put(setModalIsLoading(true));

  const translate = translator('notifications');

  const token = yield* call(validateStepUpTokenSaga);

  if (token) {
    const { success } = yield* ApiTypeCaller('deleteAccountAccessToken', payload, {
      customErrorField: ACCOUNT_DELETE_ACCESS_TOKEN_ERROR_FIELD,
    });
    if (success) {
      yield put(takeAwayAccessToken(payload.tokenRef));
      yield put(
        pushNotification({
          showType: INotificationType.Success,
          description: translate('token_removed'),
        })
      );
      yield put(setModalContent({ type: null, data: null }));
    }
  }
  yield put(setModalIsLoading(false));
}
