// libs
import { css } from 'styled-components';
import { memo } from 'react';

// components
import { TagsList, Popover } from 'components';
import { TagsContainerWithPopup } from './styled';
import { TagsLabel } from '../TagsLabel';

// types
import { TagReference } from 'types';
import { PopoverProps } from 'antd/lib/popover';

interface ITagsProps {
  tags?: TagReference[];
  disabled?: boolean;
  disabledAlike?: boolean;
  placement?: PopoverProps['placement'];
  content?: React.ReactNode;
  popoverContent?: React.ReactNode;
  blocksMode?: boolean;
}

export const TagsWithPopupList = memo(
  ({
    tags,
    disabled = false,
    disabledAlike = false,
    content,
    popoverContent,
    placement,
    blocksMode = false,
    ...rest
  }: ITagsProps) => {
    return (
      <TagsContainerWithPopup onClick={e => e.stopPropagation()} {...rest}>
        <Popover
          trigger={disabled ? [] : ['hover', 'focus', 'click']}
          placement={placement || 'bottomRight'}
          arrowPointAtCenter={true}
          popoverCss={css`
            .ant-popover-content {
              max-width: 29rem;

              .ant-popover-arrow span {
                border: none;

                ${(placement === 'left' || placement === 'leftBottom' || placement === 'leftTop') &&
                css`
                  border-right: 1px solid ${({ theme }) => theme.palette.popovers.shadowBorder};
                  border-top: 1px solid ${({ theme }) => theme.palette.popovers.shadowBorder};
                `}

                ${(placement === 'right' ||
                  placement === 'rightBottom' ||
                  placement === 'rightTop') &&
                css`
                  border-left: 1px solid ${({ theme }) => theme.palette.popovers.shadowBorder};
                  border-bottom: 1px solid ${({ theme }) => theme.palette.popovers.shadowBorder};
                `}

                ${(placement === 'bottom' ||
                  placement === 'bottomLeft' ||
                  placement === 'bottomRight') &&
                css`
                  border-left: 1px solid ${({ theme }) => theme.palette.popovers.shadowBorder};
                  border-top: 1px solid ${({ theme }) => theme.palette.popovers.shadowBorder};
                `}

                ${(placement === 'top' || placement === 'topLeft' || placement === 'topRight') &&
                css`
                  border-right: 1px solid ${({ theme }) => theme.palette.popovers.shadowBorder};
                  border-bottom: 1px solid ${({ theme }) => theme.palette.popovers.shadowBorder};
                  bottom: 4px;
                `}

                z-index: 1;

                background-color: ${({ theme }) => theme.palette.popovers.backgound};
              }
              .ant-popover-inner {
                position: relative;
                padding: 0.75rem;

                border: none;
                box-shadow: ${({ theme }) => theme.palette.elevationShadows[400]};
              }
            }
          `}
          content={popoverContent || (tags && <TagsList tags={tags} blocksMode={blocksMode} />)}
        >
          {content ||
            (tags && (
              <div>
                <TagsLabel disabled={disabledAlike || disabled} tagsLength={tags.length} />
              </div>
            ))}
        </Popover>
      </TagsContainerWithPopup>
    );
  }
);
