import { MainState } from '../../types';
import { createSelector } from '@reduxjs/toolkit';
import { ModuleName } from 'types';

import {
  MODULES_WITH_DETAILS_PANE,
  MODULES_WITHOUT_SIDEBAR,
  DNS_MODULE,
  WITH_CUSTOM_SCROLL,
} from 'appConstants';

export const selectPathName = (state: MainState) => state.router.location.pathname;
export const selectPathSearch = (state: MainState) => state.router.location.search;
export const selectPathQuery = (state: MainState) => state.router.location.query;
export const selectPathQuerySearch = (state: MainState) => {
  const search = state.router.location?.query?.search;
  return search && decodeURIComponent(search);
};
export const selectPathQueryHostname = (state: MainState) => {
  const hostname = state.router.location?.query?.hostname;
  return hostname && decodeURIComponent(hostname);
};
export const selectPathQueryIncludeDisabled = (state: MainState) => {
  const includeDisabled = state.router.location?.query?.include_disabled;
  return includeDisabled && Boolean(decodeURIComponent(includeDisabled));
};
export const selectPathQuerySort = (state: MainState) => {
  const sort = state.router.location?.query?.sort;
  return sort && decodeURIComponent(sort);
};
export const selectPathHash = (state: MainState) => state.router.location.hash;
export const selectRouteAction = (state: MainState) => state.router.action;
export const selectLastLocation = (state: MainState) => state.router.lastLocation;

export const selectModuleName = createSelector(selectPathName, pathname => {
  if (!pathname.includes('/org')) return pathname.slice(1, pathname.length) as ModuleName;
  const pathnameArr = pathname.split('/');
  if (!pathnameArr[3]) return 'dashboard';
  const moduleName = pathnameArr[3].includes('?') ? pathnameArr[3].split('?')[0] : pathnameArr[3];
  return moduleName as ModuleName;
});

export const selectLayoutSettings = createSelector(selectModuleName, moduleName => ({
  withDetailsPane: MODULES_WITH_DETAILS_PANE.some(module => moduleName === module),
  withSideMenu: !MODULES_WITHOUT_SIDEBAR.includes(moduleName),
  isDnsPage: DNS_MODULE.some(module => moduleName === module),
  withCustomScroll: WITH_CUSTOM_SCROLL.includes(moduleName),
}));
