// Lib
import {
  takeEvery,
  call,
  delay,
  fork,
  cancel,
  StrictEffect,
  CancelEffect,
  ForkEffect,
} from 'redux-saga/effects';

// Actions
import { setPollingSource } from 'redux/actions/app';

// Sagas
import { getSystemsSaga } from '../systems/getSystemsSaga';
import { detailsSaga } from './detailsSaga';
import { redirectSaga } from './redirectSaga';

// Types
import { Task } from 'redux-saga';
import { ISetPollingSource } from 'types';

let pollingTask: Task | ForkEffect | CancelEffect | null | any = null;

function* poller(func: any, params: Record<string, unknown>): Generator<StrictEffect, void, never> {
  while (true) {
    yield call(func, params);
    yield delay(2000);
  }
}

function* handlePolling({
  payload,
}: ISetPollingSource): IterableIterator<ForkEffect | CancelEffect> {
  switch (payload) {
    case 'systems':
      if (!pollingTask) {
        pollingTask = yield fork(poller, getSystemsSaga, {});
      }
      break;
    default:
      if (pollingTask) {
        yield cancel(pollingTask);
        pollingTask = null;
      }
      break;
  }
}

function* appSaga() {
  yield takeEvery(setPollingSource, handlePolling);
  yield fork(redirectSaga);
  yield fork(detailsSaga);
}

export default appSaga;
