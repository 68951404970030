// Lib
import { put } from 'typed-redux-saga';
import { setModalContent, setModalIsLoading } from 'redux/actions/app';

import { IActionPayload, IExtendTagsModalConnectionsParams } from 'types';

// Utils
import { ApiTypeCaller } from '../../api/utils';

export function* extendTagsModalConnectionsSaga({
  payload: { tags },
}: IActionPayload<IExtendTagsModalConnectionsParams>) {
  yield put(setModalIsLoading(true));

  let systems, keys, dnsRecords, policies;
  const searchParams = `tags:${tags.length > 1 ? '|' : ''}${tags.map(tag => tag.tag).join(',')}`;
  const sortOptions = 'Description';

  {
    const { result, success } = yield* ApiTypeCaller('getSystems', {
      search: searchParams,
      sort: sortOptions,
    });

    if (result && success) {
      systems = result.items;
    }
  }

  {
    const { result, success } = yield* ApiTypeCaller('getEnrolmentKeys', {
      search: searchParams,
      sort: sortOptions,
    });

    if (result && success) {
      keys = result.items;
    }
  }

  {
    const { result, success } = yield* ApiTypeCaller('getPolicies', {
      search: searchParams,
      sort: sortOptions,
    });

    if (result && success) {
      policies = result.items;
    }
  }

  {
    const { result, success } = yield* ApiTypeCaller('getDnsRecords', {
      search: searchParams,
    });

    if (result && success) {
      dnsRecords = result.items;
    }
  }

  yield put(
    setModalContent({
      type: 'tag-delete',
      data: { records: tags, connections: { keys, systems, dnsRecords, policies } },
    })
  );
  yield put(setModalIsLoading(false));
}
