// libs
import { useTheme } from 'styled-components';

// components
import { CustomText } from 'typography/Text';
import { Details } from 'components';

// selectors
import { selectAppDetailsSystemHostname } from 'redux/selectors';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { useSelector } from 'react-redux';

export const MachineNameBlock = () => {
  const { translate } = useTranslations('systems');

  const {
    palette: { fonts },
  } = useTheme();

  const hostname = useSelector(selectAppDetailsSystemHostname);

  return (
    <Details.KeyValue height="2.875rem" title={translate('details.machine_name')}>
      <CustomText type="body-2" color={fonts.title}>
        {hostname || translate('unknown')}
      </CustomText>
    </Details.KeyValue>
  );
};
