// libs
import styled, { css, useTheme } from 'styled-components';

// components
import { OSIcon, SystemStatusBadge } from 'components';
import { CustomText } from 'typography/Text';

// types
import { ISystemSummaryModel, OSTypes } from 'types';

interface ISearchSelectOptionElement {
  record: ISystemSummaryModel;
  isSelected: boolean;
}

const SearchSelectOptionElementContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const OptionElement = ({
  record: { description, systemId, platformType, hostname, state },
}: ISearchSelectOptionElement) => {
  const {
    palette: { fonts },
  } = useTheme();

  const name = description ?? hostname;

  return (
    <SearchSelectOptionElementContainer>
      <OSIcon
        platformType={platformType as OSTypes}
        css={css`
          margin-right: 0.5rem;
        `}
      />

      <CustomText
        ellipsis={true}
        type="body-2"
        color={fonts.bodyDark}
        css={css`
          margin-right: 0.625rem;
        `}
      >
        {name || systemId}
      </CustomText>

      <SystemStatusBadge state={state} />

      {name && (
        <CustomText
          type="hint"
          color={fonts.disabled}
          css={css`
            margin-left: 0.625rem;
          `}
        >
          {systemId}
        </CustomText>
      )}
    </SearchSelectOptionElementContainer>
  );
};
