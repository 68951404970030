// libs
import React, { forwardRef, Ref, useCallback, useMemo, useState } from 'react';
import { css, CSSProp, useTheme } from 'styled-components';

// components
import { DropdownContainer, OptionTextContainer, SelectContainer, selectCss } from './styled';
import { Select as SelectAntd } from 'antd';
import { CustomText } from 'typography/Text';
import { IconCheck, IconPlus } from 'assets/svg';
import { Tag } from './Tag';
import { Empty } from './Empty';

// types
import { RefSelectProps, SelectProps } from 'antd/lib/select';
import { IPolicyGatewayRoute, ISystemSummaryModel, PolicyGatewayDetails } from 'types';

// utils
import reactStringReplace from 'react-string-replace';
import { PreferredIcon } from '../styled';

const { Option, OptGroup } = SelectAntd;

type IPolicyGatewayRouteModified = IPolicyGatewayRoute & { first: boolean; last: boolean };
export type IPolicyGatewayInputType = IPolicyGatewayRoute;

export interface ISearchSelect extends Omit<SelectProps<string[]>, 'value'> {
  value: PolicyGatewayDetails[] | undefined;
  dataSource: ISystemSummaryModel[];
  setSelectedValue: (args: IPolicyGatewayRoute) => void;
  removeSelectedValue: (args: IPolicyGatewayRoute) => void;
  moveGatewayUp: (systemId: string) => void;
  moveGatewayDown: (systemId: string) => void;
  height?: string;
  placeholder?: string;
  forceAtBottom?: boolean;
  additionalCss?: CSSProp;
  containerCss?: CSSProp;
}

const transformValue = (value: IPolicyGatewayRoute[]): IPolicyGatewayRouteModified[] => {
  const systemIndexFirst: { [key: string]: number } = {};
  const systemIndexLast: { [key: string]: number } = {};

  value.forEach((item, index) => {
    if (systemIndexFirst[item.systemId] === undefined) {
      systemIndexFirst[item.systemId] = index;
    }

    systemIndexLast[item.systemId] = index;
  });

  return value.map((item, index) => ({
    ...item,
    first: systemIndexFirst[item.systemId] === index,
    last: systemIndexLast[item.systemId] === index,
  }));
};

const GatewaySearchSelect = (
  {
    value,
    height,
    dataSource,
    placeholder,
    setSelectedValue,
    removeSelectedValue,
    moveGatewayUp,
    moveGatewayDown,
    additionalCss,
    containerCss,
    forceAtBottom = true,
    ...rest
  }: ISearchSelect,
  ref:
    | ((instance: RefSelectProps | null) => void)
    | React.RefObject<RefSelectProps>
    | null
    | undefined
) => {
  const {
    palette: { fonts },
  } = useTheme();

  const [searchValue, setSearchValue] = useState('');

  const parsedValue: IPolicyGatewayRoute[] | undefined = useMemo(() => {
    return value?.flatMap((gateway, idx) =>
      gateway.routes.map(routeEntry => ({
        isFirstGateway: idx === 0,
        isLastGateway: idx === value.length - 1,
        systemId: gateway.systemId,
        systemName: gateway.systemName,
        machineName: gateway.machineName,
        routeEntry: routeEntry,
      }))
    );
  }, [value]);

  // we add property isFirst to account for first route in each system
  // to show system name abobe it.
  const transformedValue: IPolicyGatewayRouteModified[] | undefined = useMemo(
    () => (parsedValue ? transformValue(parsedValue) : undefined),
    [parsedValue]
  );

  const stringifiedValue = useMemo(
    () => (parsedValue ? parsedValue.map(item => JSON.stringify(item)) : undefined),
    [parsedValue]
  );

  const filteredDataSource = dataSource
    .filter(dataItem => {
      // if all items subnets in system are selected we don't need to display system in search
      if (
        dataItem.gatewayRoutes.length ===
        value?.find(item => item.systemId === dataItem.systemId)?.routes.length
      ) {
        return false;
      } else return true;
    })
    .map(dataItem => {
      // filter out already selected items
      return {
        ...dataItem,
        gatewayRoutes: dataItem.gatewayRoutes.filter(route => {
          if (
            transformedValue?.some(
              valueItem =>
                dataItem.systemId === valueItem.systemId &&
                route.subnet === valueItem.routeEntry.route
            )
          ) {
            return false;
          } else return true;
        }),
      };
    });

  const onBlur = useCallback(() => {
    setSearchValue('');
  }, []);

  return (
    <SelectContainer css={containerCss}>
      <SelectAntd
        ref={ref}
        virtual={false}
        mode="multiple"
        onBlur={onBlur}
        dropdownAlign={
          forceAtBottom
            ? {
                // Docs - https://github.com/yiminghe/dom-align
                points: ['tl', 'bl'],
                offset: [0, 2],
                overflow: {
                  adjustX: 0,
                  adjustY: 0,
                },
              }
            : {
                offset: [0, 2],
              }
        }
        getPopupContainer={trigger => {
          return trigger?.parentElement;
        }}
        value={stringifiedValue}
        searchValue={searchValue}
        // for accessibility, otherwise required attrubute aria-expanded would not be present initially
        defaultOpen={false}
        onSelect={(optionValue: string) => {
          if (optionValue) {
            const value: IPolicyGatewayRoute = JSON.parse(optionValue);

            setSelectedValue(value);
          }
        }}
        onDeselect={(optionValue: string) => {
          if (optionValue) {
            const value: IPolicyGatewayRoute = JSON.parse(optionValue);

            removeSelectedValue(value);
          }
        }}
        onSearch={setSearchValue}
        autoClearSearchValue={true}
        menuItemSelectedIcon={<IconCheck />}
        css={css`
          ${selectCss({
            height,
            empty: !value?.length || value.length === 0,
          })}
          ${additionalCss}
        `}
        notFoundContent={<Empty />}
        dropdownRender={originNode => {
          return <DropdownContainer>{originNode}</DropdownContainer>;
        }}
        showSearch={true}
        placeholder={placeholder}
        tagRender={({ value, onClose }) => {
          const parsed: IPolicyGatewayInputType = JSON.parse(value);

          const item = transformedValue?.find(
            item =>
              item.systemId === parsed.systemId && item.routeEntry.route === parsed.routeEntry.route
          );

          return (
            <Tag
              key={value}
              name={parsed.systemName || parsed.machineName || parsed.systemId}
              routeEntry={parsed.routeEntry}
              isFirst={item?.first || false}
              isLast={item?.last || false}
              sortDownEnabled={!parsed.isLastGateway}
              sortUpEnabled={!parsed.isFirstGateway}
              onClose={onClose}
              onSortUp={() => moveGatewayUp(parsed.systemId)}
              onSortDown={() => moveGatewayDown(parsed.systemId)}
            />
          );
        }}
        filterOption={(inputValue, option) => {
          if (typeof option?.value === 'string') {
            const parsed: IPolicyGatewayInputType = JSON.parse(option.value);

            const searchValue =
              parsed.systemName || parsed.machineName || parsed.systemId + parsed.routeEntry.route;

            if (searchValue.toLowerCase().includes(inputValue.toLowerCase())) {
              return true;
            } else return false;
          } else return false;
        }}
        {...rest}
      >
        {filteredDataSource.map(dataElement => {
          const valueItem = value?.find(item => item.systemId === dataElement.systemId);

          const name = dataElement.description || dataElement.hostname || dataElement.systemId;

          return (
            <OptGroup
              key={dataElement.systemId}
              // we ensure that "unknown" word is not made bold on search
              // because we can't search upon it anyway
              label={
                <CustomText type="body-2" color={fonts.disabled}>
                  {reactStringReplace(name, searchValue, (match, index) =>
                    index === 1 ? (
                      <b
                        css={css`
                          color: ${fonts.title};
                        `}
                      >
                        {match}
                      </b>
                    ) : (
                      match
                    )
                  )}
                </CustomText>
              }
            >
              {dataElement.gatewayRoutes.map(route => {
                if (valueItem?.routes.find(r => r.route == route.subnet)) {
                  return null;
                }

                const newValue: IPolicyGatewayRoute = {
                  isFirstGateway: false,
                  isLastGateway: false,
                  systemId: dataElement.systemId,
                  systemName: dataElement.description || '',
                  machineName: dataElement.hostname || '',
                  routeEntry: {
                    route: route.subnet,
                    gatewayName: route.name,
                    gatewayWeight: route.weight,
                  },
                };

                const stringifiedValue = JSON.stringify(newValue);

                return (
                  <Option
                    // we exract data from key on select
                    key={`${dataElement['systemId']} ${route.subnet}`}
                    title={route.subnet}
                    // we are cheking search against value
                    value={stringifiedValue}
                    data-test="gateway-select-option"
                  >
                    <OptionTextContainer>
                      <CustomText
                        css={css`
                          margin-left: 1.25rem;
                        `}
                        type="body-2"
                        color={fonts.title}
                      >
                        {reactStringReplace(route.subnet, searchValue, (match, index) =>
                          index === 1 ? <b>{match}</b> : match
                        )}
                        {route.weight > 0 && <PreferredIcon />}
                      </CustomText>
                      {route.name && (
                        <CustomText
                          css={css`
                            margin-left: 1.25rem;
                            max-width: 11rem;
                          `}
                          type="hint"
                          color={fonts.bodyLight}
                        >
                          {route.name}
                        </CustomText>
                      )}
                    </OptionTextContainer>
                    <IconPlus className="add-icon" />
                  </Option>
                );
              })}
            </OptGroup>
          );
        })}
      </SelectAntd>
    </SelectContainer>
  );
};

export const GatewaySelect = forwardRef(GatewaySearchSelect) as (
  props: ISearchSelect & { ref?: Ref<HTMLDivElement> }
) => React.ReactElement;
