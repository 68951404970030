// Lib
import { put } from 'typed-redux-saga';
import { setPolicies, setIsLoading } from 'redux/actions/policies';

// Types
import { IActionPayload, IGetPoliciesParams } from 'types';

// Utils
import { SyncQueryParams } from '../../utils';
import { ApiTypeCaller } from '../../api/utils';

export function* getPoliciesSaga({ payload }: IActionPayload<IGetPoliciesParams>) {
  yield put(setIsLoading(true));

  payload = yield* SyncQueryParams(payload);

  const { result, success } = yield* ApiTypeCaller('getPolicies', payload);
  if (success && result) yield put(setPolicies(result));
}
