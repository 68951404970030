// components
import { CreateKey } from './styled';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEmptyText } from 'hooks/useEmptyText';

// actions
import { createEnrolmentKeySideMenu } from 'redux/actions/app';

// selectors
import { selectEnrolmentKeysIsLoading } from 'redux/selectors';

// constants
import { ENROLMENT_KEYS_MODULE } from 'appConstants';

type Props = {
  searchValue: string;
};

export const useEnrolmentKeysEmptyText = ({ searchValue }: Props) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectEnrolmentKeysIsLoading);
  const { translate } = useTranslations(ENROLMENT_KEYS_MODULE);

  const noDataText = useMemo(
    () => ({ title: translate('no_enrolled'), description: translate('start_using') }),
    [translate]
  );
  const noDataFoundText = useMemo(
    () => ({
      title: translate('no_data_found'),
      description: translate('search_in_docs'),
    }),
    [translate]
  );

  const createNewKey = useCallback(() => dispatch(createEnrolmentKeySideMenu()), [dispatch]);

  const additionalText = useMemo(
    () => <CreateKey onClick={createNewKey}>{translate('start_using_link')}</CreateKey>,
    [createNewKey, translate]
  );

  const { emptyText } = useEmptyText({
    searchValue,
    noDataText,
    noDataFoundText,
    isLoading,
    additionalText,
    module: ENROLMENT_KEYS_MODULE,
  });

  return { emptyText };
};
