// libs
import { pick } from 'ramda';
import { put, select, call } from 'typed-redux-saga';

// actions
import { pushNotification, setTagDetailsContent } from 'redux/actions/app';

// selectors
import {
  selectAppDetailsTabData,
  selectAppDetailsTabDataNew,
  selectAppDetailsTag,
} from 'redux/selectors';
import { updateTagState } from 'redux/actions/tags';

// types
import {
  IApiResponse,
  IDetailedTag,
  INotificationType,
  IUpdateTagParams,
  IUpdateTagResponse,
} from 'types';

// utils
import { ApiTypeCaller } from '../../api/utils';
import { diff, removeUndefinedKeys, translator, trustRequirementsToNumbers } from 'utils';
import { orgPush } from 'redux/sagas/utils';

// constants
import { ROUTE_PATHS } from 'appConstants';

export function* updateTagSaga() {
  const currentTag: string = yield select(selectAppDetailsTag);

  const tag: IDetailedTag = yield select(selectAppDetailsTabData);
  const updatedTag: IDetailedTag = yield select(selectAppDetailsTabDataNew);

  const difference = diff(updatedTag, tag);
  const updatedParams: IUpdateTagParams = pick(['tag', 'colour', 'notes'], difference);

  updatedParams.trustRequirements = difference.trustRequirements
    ? trustRequirementsToNumbers(difference.trustRequirements)
    : undefined;

  const { success, result }: IApiResponse<IUpdateTagResponse> = yield ApiTypeCaller('updateTag', {
    ref: currentTag,
    updatedParams: removeUndefinedKeys(updatedParams),
  });
  const translate = translator('notifications');

  if (success && result) {
    yield put(
      pushNotification({
        showType: INotificationType.Success,
        description: translate('tag_updated', {
          tagRef: updatedTag.tag,
        }),
      })
    );

    if (currentTag !== updatedTag.tag) {
      yield call(orgPush, `${ROUTE_PATHS.tags}/${result.tag}`, { keepSearch: true });
    } else {
      yield put(
        setTagDetailsContent({
          data: result,
          type: 'tag-view',
        })
      );
    }

    yield put(updateTagState(result));
  }
}
