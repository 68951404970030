// libs
import moment from 'moment';
import dayjs from 'dayjs';

// hooks
import { KeyboardEventHandler, useCallback, useRef, useState } from 'react';

// components
import { DatePicker } from 'antd';
import { Select } from 'components';
import { TimeOption } from './TimeOption';
import { Container, datePickerCss, timeSelectContainerCss } from './styled';
import { WarningMessage } from './WarningMessage';

// utils
import { checkIfValidTimeString, convertTime12to24, formTimeString, is12HFormatUsed } from 'utils';

const EXPIRY_TIME_DATA_SOURCE = [...Array(24).keys()].map(time => {
  return { hours: time, minutes: 0 };
});

const is12HFormat = is12HFormatUsed();

interface DateTimePickerProps {
  dateTimeValue: string | undefined;
  onDateTimeChange: (newDate: string) => void;
  datePickerInputPlaceholder: string;
  timeSelectInputPlaceholder: string;
  timeSelectInputAriaLabel?: string;
  warning?: boolean;
  warningMessage?: string;
}

export const DateTimePicker = ({
  dateTimeValue,
  onDateTimeChange,
  datePickerInputPlaceholder,
  timeSelectInputPlaceholder,
  timeSelectInputAriaLabel,
  warning,
  warningMessage,
}: DateTimePickerProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isManualInput, setIsManualInput] = useState(false);

  const ref = useRef<HTMLInputElement>(null);

  const datePickerValue = moment(dateTimeValue);

  const displayedHours = dayjs(dateTimeValue).hour();
  const displayedMinutes = dayjs(dateTimeValue).minute();

  const handleFocus = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  const handleBlur = useCallback(() => {
    setIsOpen(false);
    setIsManualInput(false);
  }, [setIsOpen]);

  const handleDateChange = useCallback(
    // !!! to fix any
    (e: any) => {
      const newDate = new Date(e._d);

      newDate.setHours(displayedHours);
      newDate.setMinutes(displayedMinutes);

      let formattedDate = moment(newDate).toISOString(true);
      //cut offset, millisecnds, seconds
      formattedDate = formattedDate.slice(0, formattedDate.length - 13);

      onDateTimeChange(formattedDate);
    },
    [onDateTimeChange, displayedHours, displayedMinutes]
  );

  const setNewTime = useCallback(
    ({ hours, minutes }: typeof EXPIRY_TIME_DATA_SOURCE[0]) => {
      const newDate = dateTimeValue ? new Date(dateTimeValue) : new Date();

      newDate.setHours(hours);
      newDate.setMinutes(minutes);

      let formattedDate = moment(newDate).toISOString(true);
      //cut offset, millisecnds, seconds
      formattedDate = formattedDate.slice(0, formattedDate.length - 13);

      onDateTimeChange(formattedDate);

      ref?.current?.blur();
    },
    [dateTimeValue, onDateTimeChange]
  );

  const handleManualInput = useCallback(
    (inputValue: string) => {
      setIsManualInput(true);

      if (checkIfValidTimeString(inputValue, is12HFormat)) {
        let hours, minutes;
        if (is12HFormat) {
          const { hours: convertedHours, minutes: convertedMinutes } =
            convertTime12to24(inputValue);
          hours = convertedHours;
          minutes = convertedMinutes;
        } else {
          const [parsedHours, parsedMinutes] = inputValue.split(':');
          hours = Number(parsedHours);
          minutes = Number(parsedMinutes);
        }

        const newDate = dateTimeValue ? new Date(dateTimeValue) : new Date();
        newDate.setHours(hours);
        newDate.setMinutes(minutes);

        let formattedDate = moment(newDate).toISOString(true);
        //cut offset, millisecnds, seconds
        formattedDate = formattedDate.slice(0, formattedDate.length - 13);

        onDateTimeChange(formattedDate);
      }
    },
    [dateTimeValue, onDateTimeChange]
  );

  const onKeyDown: KeyboardEventHandler<HTMLInputElement> = useCallback(
    e => {
      if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
        setIsManualInput(false);
      }

      if (e.key === 'Enter') {
        if (isManualInput) {
          setNewTime({ hours: displayedHours, minutes: displayedMinutes });
        }
      }
    },
    [displayedHours, displayedMinutes, isManualInput, setNewTime]
  );

  return (
    <>
      <Container isActive={isOpen} warning={warning}>
        <DatePicker
          css={datePickerCss}
          format={'dddd, MMMM D, YYYY'}
          value={dateTimeValue ? datePickerValue : null}
          onChange={handleDateChange}
          showTime={false}
          showToday={false}
          allowClear={false}
          clearIcon={null}
          inputReadOnly={true}
          open={isOpen}
          onFocus={handleFocus}
          onBlur={handleBlur}
          placeholder={datePickerInputPlaceholder}
          getPopupContainer={trigger => {
            return trigger?.parentElement || trigger;
          }}
        />
        <Select
          ref={ref}
          showSearch={true}
          setSearchValue={handleManualInput}
          filterOption={false}
          placeholder={timeSelectInputPlaceholder}
          listHeight={269}
          getPopupContainer={trigger => {
            return trigger?.parentElement || trigger;
          }}
          open={isOpen}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onKeyDown={onKeyDown}
          aria-label={timeSelectInputAriaLabel}
          dataSource={EXPIRY_TIME_DATA_SOURCE}
          valueField="hours"
          keyField="hours"
          OptionElement={option => {
            return (
              <TimeOption hours={option.hours} minutes={option.minutes} is12HFormat={is12HFormat} />
            );
          }}
          setSelectedValue={setNewTime}
          // if we don't have minutes displayed
          // then the value selected is correponded with
          // value present in EXPIRY_TIME_DATA_SOURCE
          // otherwise it's custom data
          value={
            displayedMinutes
              ? formTimeString(displayedHours, displayedMinutes, is12HFormat)
              : String(displayedHours)
          }
          height="2rem"
          containerCss={timeSelectContainerCss}
          defaultValueFieldValue={undefined}
          showSelectedOptionInDropdown={true}
          error={null}
          tooltipProps={{ placement: 'topRight' }}
        />
        {warning && <WarningMessage message={warningMessage} />}
      </Container>
    </>
  );
};
