import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { combineReducers } from 'redux';

import { MainState } from 'types';

import { appReducer } from './app';
import { userReducer } from './user';
import { enrolmentKeysReducer } from './enrolment-keys';
import { systemsReducer } from './systems';
import { unapprovedSystemsReducer } from './unapproved-systems';
import { organisationsReducer } from './organisation';
import { securityEventsReducer } from './security-events';
import { accountReducer } from './account';
import { tagsReducer } from './tags';
import { policiesReducer } from './policies';
import { dnsReducer } from './dns';
import { trustRequirementsReducer } from './trust-requirements';
import { createRouterReducer } from './router';
import { history } from 'utils';

export const userPersistConfig = {
  key: 'User',
  storage,
  whitelist: ['isLoggedIn', 'profile'],
};

export const accountPersistConfig = {
  key: 'Account',
  storage,
  whitelist: ['stepUpToken'],
};

const createRootReducer = () =>
  combineReducers<MainState>({
    router: createRouterReducer(history),
    App: appReducer,
    User: persistReducer<any, any>(userPersistConfig, userReducer),
    Account: persistReducer<any, any>(accountPersistConfig, accountReducer),
    EnrolmentKeys: enrolmentKeysReducer,
    Systems: systemsReducer,
    UnapprovedSystems: unapprovedSystemsReducer,
    Organisation: organisationsReducer,
    SecurityEvents: securityEventsReducer,
    Tags: tagsReducer,
    Policies: policiesReducer,
    Dns: dnsReducer,
    TrustRequirements: trustRequirementsReducer,
  });

export default createRootReducer;
