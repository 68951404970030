// libs
import { css } from 'styled-components';

// components
import { Details } from 'components';

// hooks
import { useDispatch, useSelector } from 'react-redux';

// actions
import { saveEnrolmentKeyDetails } from 'redux/actions/enrolment-keys';
import { cancelEditDetails, updateValidationStateUpdates } from 'redux/actions/app';

// selectors
import { selectAppDetailsTabIsValid } from 'redux/selectors';

export const CancelSaveEdit = () => {
  const dispatch = useDispatch();

  const isValid = useSelector(selectAppDetailsTabIsValid);

  const handleCancel = () => {
    dispatch(cancelEditDetails());
  };

  const handleSave = () => {
    if (isValid) {
      dispatch(saveEnrolmentKeyDetails());
    } else {
      dispatch(updateValidationStateUpdates());
    }
  };

  return (
    <Details.CancelSave
      css={css`
        margin: 0 2.5rem;
      `}
      handleCancel={handleCancel}
      handleSave={handleSave}
    />
  );
};
