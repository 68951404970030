import { DetailsTopMenuButtons } from './TopMenuButtons';
import { DetailsCancelSave } from './CancelSave';
import { DetailsSectionHeader } from './SectionHeader';
import { DetailsKeyValue } from './KeyValue';
import {
  DetailsContentWrapper,
  DetailsHeaderSection,
  DetailsPane,
  DetailsScrollPaddingContainer,
} from './styled';
import { DetailsInput } from 'components/Input/InputDetails';

export const Details = {
  KeyValue: DetailsKeyValue,
  Input: DetailsInput,
  TopMenuButtons: DetailsTopMenuButtons,
  CancelSave: DetailsCancelSave,
  SectionHeader: DetailsSectionHeader,
  ContentWrapper: DetailsContentWrapper,
  HeaderSection: DetailsHeaderSection,
  Pane: DetailsPane,
  ScrollPaddingContainer: DetailsScrollPaddingContainer,
};
