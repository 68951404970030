// components
import { PageTitle } from 'components';
import { TitleEdit } from './Edit';
import { TitleView } from './View';
import { TitleCreate } from './Create';

// hooks
import { useSelector } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';

// selectors
import { selectAppDetailsEnrolmentKeyDescription, selectAppDetailsTabType } from 'redux/selectors';

export const TitleBlock = () => {
  const { translate } = useTranslations('enrolment-keys');

  const type = useSelector(selectAppDetailsTabType);
  const description = useSelector(selectAppDetailsEnrolmentKeyDescription);

  return (
    <>
      <PageTitle
        title={`${translate('enrolment_keys')}${description ? ` | ${description}` : ''}`}
      />
      {type === 'enrolment-key-view' && <TitleView />}
      {type === 'enrolment-key-edit' && <TitleEdit />}
      {type === 'enrolment-key-create' && <TitleCreate />}
    </>
  );
};
