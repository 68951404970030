// libs
import { select, put, all } from 'typed-redux-saga';

// actions
import { setOrganisationInfo, setOrganisationPlans } from 'redux/actions/organisation';
import { pushNotification, setModalContent, setModalIsLoading } from 'redux/actions/app';

// selectors
import { selectModalType } from 'redux/selectors';

// types
import { IActionPayload, INotificationType, IUpgradePlanProps } from 'types';

// Utils
import { ApiTypeCaller } from '../../api/utils';
import { selectIsStripeLoaded } from 'redux/selectors/app';
import { Stripe, translator } from 'utils';

export function* upgradePlanSaga({ payload }: IActionPayload<IUpgradePlanProps>) {
  yield put(setModalIsLoading(true));

  const translate = translator('notifications');

  const { result, success } = yield* ApiTypeCaller('upgradePlan', payload);
  if (success && result) {
    if (result.stripePublicKey && result.stripeSessionId) {
      // Going to Stripe portal
      const stripeIsLoaded = yield* select(selectIsStripeLoaded);
      if (stripeIsLoaded && Stripe) {
        const stripe = Stripe(result.stripePublicKey);
        stripe.redirectToCheckout({ sessionId: result.stripeSessionId });
      }
    } else {
      const modalType = yield* select(selectModalType);
      // Simple plan update
      if (result.pricing && result.organisation) {
        yield all([
          put(setOrganisationPlans(result.pricing)),
          put(setOrganisationInfo(result.organisation)),
          put(
            pushNotification({
              showType: INotificationType.Success,
              description: translate('capacity_updated', {
                capacity: result.organisation.maxSystems,
              }),
            })
          ),
        ]);
      }

      if (modalType !== null) yield put(setModalContent({ type: null, data: null }));
    }
  }
  yield put(setModalIsLoading(false));
}
