import { IconDown } from 'assets/svg';
import styled, { css, CSSProp } from 'styled-components';

const selectedRowClassName = 'ant-table-row-selected-custom';

export const IconLeftDirection = styled(IconDown)`
  transform: rotate(90deg);
  width: 0.875rem;
  height: 0.875rem;
  path {
    fill: ${({ theme }) => theme.palette.icons.primary};
  }
`;

export const IconRightDirection = styled(IconDown)`
  transform: rotate(-90deg);
  width: 0.875rem;
  height: 0.875rem;
  path {
    fill: ${({ theme }) => theme.palette.icons.primary};
  }
`;

export const paginationCss = css`
  .ant-pagination {
    li {
      border-radius: 0.25rem;
    }
    .ant-pagination-item:not(.ant-pagination-item-active) {
      border: 0.0625rem solid ${({ theme }) => theme.palette.fills.strokeMedium};
      a {
        color: ${({ theme }) => theme.palette.fonts.bodyLight};
      }

      :hover {
        border: 0.0625rem solid ${({ theme }) => theme.palette.fills.primary};
        a {
          color: ${({ theme }) => theme.palette.fills.primary};
        }
      }
      :focus {
        border: 0.0625rem solid ${({ theme }) => theme.palette.fills.primary};
        a {
          color: ${({ theme }) => theme.palette.fills.primary};
        }
      }
    }

    .ant-pagination-item.ant-pagination-item-active {
      border: 0.0625rem solid ${({ theme }) => theme.palette.fills.primary};
      a {
        color: ${({ theme }) => theme.palette.fills.primary};
      }

      :hover {
        border: 0.0625rem solid ${({ theme }) => theme.palette.fills.primaryHover};
        a {
          color: ${({ theme }) => theme.palette.fills.primaryHover};
        }
      }
      :focus {
        border: 0.0625rem solid ${({ theme }) => theme.palette.fills.primaryHover};
        a {
          color: ${({ theme }) => theme.palette.fills.primaryHover};
        }
      }
    }
    .ant-pagination-prev,
    .ant-pagination-next {
      display: flex;
      justify-content: center;
      align-items: center;

      &[aria-disabled='true'] {
        border: 0.0625rem solid ${({ theme }) => theme.palette.fills.strokeMedium};
        background-color: ${({ theme }) => theme.palette.background.sections};
        svg path {
          fill: ${({ theme }) => theme.palette.icons.disabled};
        }
      }

      &[aria-disabled='false'] {
        border: 0.0625rem solid ${({ theme }) => theme.palette.fills.strokeMedium};
        svg path {
          fill: ${({ theme }) => theme.palette.icons.secondary};
        }

        :focus {
          border: 0.0625rem solid ${({ theme }) => theme.palette.background.focus};
          background-color: ${({ theme }) => theme.palette.background.focus};
          svg path {
            fill: ${({ theme }) => theme.palette.icons.primary};
          }
        }
        :hover {
          border: 0.0625rem solid ${({ theme }) => theme.palette.fills.iconHover};
          background-color: ${({ theme }) => theme.palette.fills.iconHover};
          svg path {
            fill: ${({ theme }) => theme.palette.fills.primaryHover};
          }
        }
      }
    }
    .ant-pagination-jump-prev,
    .ant-pagination-jump-next {
      display: flex;
      align-items: center;
      justify-content: center;

      :hover {
        background-color: ${({ theme }) => theme.palette.fills.iconHover};

        svg path {
          fill: ${({ theme }) => theme.palette.fills.primaryHover};
        }
      }

      :focus {
        background-color: ${({ theme }) => theme.palette.background.focus};

        svg path {
          fill: ${({ theme }) => theme.palette.icons.primary};
        }
      }
    }
  }
`;

export const tableCss = ({
  headerHeight,
  rowHeight,
  focusedRowCss,
  elevated,
  withBorder,
}: {
  headerHeight: string;
  rowHeight: string;
  elevated: boolean;
  withBorder: boolean;
  focusedRowCss: CSSProp | undefined;
}) => css`
  cursor: default;

  tr {
    th,
    td {
      padding-left: 1rem;
      padding-right: 1rem;
      border-bottom: 0.0625rem solid ${({ theme }) => theme.palette.background.iconBackground};
    }
  }

  thead tr {
    height: ${headerHeight};

    th {
      padding-top: 0;
      padding-bottom: 0;

      .ant-checkbox-indeterminate .ant-checkbox-inner::after {
        background-color: ${({ theme }) => theme.palette.fills.primary}!important;
      }
      .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner {
        background-color: ${({ theme }) => theme.palette.fills.primary}!important;
        border-color: ${({ theme }) => theme.palette.fills.primary}!important;
      }

      .ant-checkbox-wrapper {
        align-items: center;
        justify-content: center;

        .ant-checkbox {
          top: 2px;
        }
      }

      .ant-checkbox-wrapper:hover .ant-checkbox-inner,
      .ant-checkbox:hover .ant-checkbox-inner,
      .ant-checkbox-input:focus + .ant-checkbox-inner {
        border-color: ${({ theme }) => theme.palette.fills.primary}!important;
      }

      .ant-checkbox:hover::after,
      .ant-checkbox-wrapper:hover .ant-checkbox::after {
        border-color: ${({ theme }) => theme.palette.fills.primary}!important;
      }
    }
  }

  /* it styles columns by default, we need to style rows */
  .ant-table-tbody > tr.ant-table-row > td {
    background: none !important;
  }

  tbody tr {
    overflow: visible;

    /* this is for selected on Click */
    &.${selectedRowClassName}.ant-table-row {
      background-color: ${({ theme }) => theme.palette.background.focus};
      ${focusedRowCss}

      td {
        border-top: 0.0625rem solid ${({ theme }) => theme.palette.fills.stroke};
        border-bottom: 0.0625rem solid ${({ theme }) => theme.palette.fills.stroke};
      }
    }

    /* this is for bulk selected  */
    &.ant-table-row-selected.ant-table-row {
      background-color: ${({ theme }) => theme.palette.background.focus};
      ${focusedRowCss}
    }

    :hover {
      background-color: ${({ theme }) => theme.palette.background.base};
    }
    height: ${rowHeight};
    td {
      padding-top: 0;
      padding-bottom: 0;

      border-top: 0.0625rem solid ${({ theme }) => theme.palette.background.iconBackground};
      border-bottom: 0.0625rem solid ${({ theme }) => theme.palette.background.iconBackground};
      vertical-align: middle;
    }
  }

  tbody {
    tr {
      cursor: pointer;
    }

    td {
      font-size: ${({ theme }) => theme.text_sizes[2].fontSize};
      line-height: ${({ theme }) => theme.text_sizes[2].lineHeight};
    }

    td.ant-table-selection-column {
      padding: 0;
    }

    .ant-table-title {
      display: none;
    }
  }

  table {
    border: ${({ theme }) =>
      withBorder && `0.0625rem solid ${theme.palette.background.iconBackground}`};

    border-bottom: none;

    border-radius: 0.25rem;
    box-shadow: ${({ theme }) => (elevated ? theme.palette.elevationShadows[200] : 'none')};
  }

  // Standard width rules
  table th.ant-table-selection-column {
    width: 3rem;
    min-width: 3rem;
    padding: 0 !important;
  }

  table th.action-column {
    width: 3rem;
    min-width: 3rem;
    padding: 0 !important;
  }

  table th.name-column {
    min-width: 10rem;
  }
`;
