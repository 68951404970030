// libs
import { useTheme, css } from 'styled-components';
import { memo } from 'react';

// components
import { Popover } from 'components';
import { SystemsContainerWithPopup, SystemsContentContainer } from './styled';
import { IconSystemsPopupList } from 'assets/svg';
import { CustomText } from 'typography/Text';
import { SystemsList } from '../SystemsList';

// hooks
import { useTranslations } from 'hooks/useTranslations';

// types
import { ISystemReferenceModel } from 'types';
import { TooltipPlacement } from 'antd/lib/tooltip';

interface ISystemsWithPopupList {
  systems: ISystemReferenceModel[];
  disabled?: boolean;
  placement?: TooltipPlacement;
}

export const SystemsWithPopupList = memo(
  ({ systems, placement = 'bottomRight', disabled = false }: ISystemsWithPopupList) => {
    const {
      palette: { background, fonts },
    } = useTheme();

    const { translate } = useTranslations('global');

    return (
      <SystemsContainerWithPopup>
        <Popover
          trigger={disabled ? [] : ['hover', 'focus', 'click']}
          placement={placement}
          popoverCss={css`
            .ant-popover-content {
              max-width: 31rem;
              .ant-popover-inner {
                position: relative;
                padding: 0.75rem;
              }
            }
          `}
          color={background.base}
          content={<SystemsList systems={systems} />}
        >
          <SystemsContentContainer>
            <IconSystemsPopupList width="1rem" height="1rem" />
            <CustomText
              ellipsis={true}
              type="body-2"
              color={disabled ? fonts.disabled : fonts.bodyDark}
              css={css`
                border-bottom: 0.0625rem dashed ${({ theme }) => theme.palette.fills.strokeDark};
                margin-left: 0.1875rem;
              `}
            >
              {systems.length}{' '}
              {translate('systems_list.systems', {
                count: systems.length,
              })}
            </CustomText>
          </SystemsContentContainer>
        </Popover>
      </SystemsContainerWithPopup>
    );
  }
);
