// components
import { PageTitle } from 'components';
import { TitleView } from './View';
import { TitleEdit } from './Edit';

// hooks
import { useSelector } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';

// selectors
import {
  selectAppDetailsPolicyDescription,
  selectAppDetailsPolicyId,
  selectAppDetailsTabType,
} from 'redux/selectors';

export const TitleBlock = () => {
  const { translate } = useTranslations('policies');

  const type = useSelector(selectAppDetailsTabType);
  const policyId = useSelector(selectAppDetailsPolicyId);
  const description = useSelector(selectAppDetailsPolicyDescription);

  return (
    <>
      <PageTitle
        title={`${translate('page_title')}${
          description || policyId ? ` | ${description || policyId}` : ''
        }`}
      />
      {type === 'policy-view' && <TitleView />}
      {(type === 'policy-edit' || type === 'policy-create') && <TitleEdit />}
    </>
  );
};
