// components
import {
  LayoutContent,
  LayoutContentContainer,
  LayoutContentWrapper,
  StyledLayout,
  StyledLayoutWrapper,
  StyledSider,
} from './Styled';
import { SideMenu } from './SideMenu';
import { ContentRoutes } from './Routes';
import { Modal } from './Modal';
import { OfflineDetector } from 'modules/Offline';
import { SessionLogoutUrl } from './SessionLogoutUrl';
import { TopBar } from './TopBar';
import { PageTitle } from 'components';
import { Details } from './Details';
import { ErrorBoundary } from '../ErrorBoundary';
import { Loader } from './Loader';

// hooks
import { useTheme } from 'styled-components';
import { useSelector } from 'react-redux';
import { useNotification } from 'hooks/useNotification';

// constants
import { OIDCConstants } from 'appConstants';

// selectors
import { selectAppIsLoaded, selectLayoutSettings } from 'redux/selectors';

// utils
import { equals } from 'ramda';

const AppLayout = () => {
  const theme = useTheme();

  const appIsLoaded = useSelector(selectAppIsLoaded);

  const { withDetailsPane, withSideMenu, isDnsPage, withCustomScroll } = useSelector(
    selectLayoutSettings,
    equals
  );

  useNotification();

  if (!appIsLoaded) {
    return (
      <Loader
        oidcNormalAuthRedirectRoute={`/${OIDCConstants.oidcRedirect}`}
        oidcSilentRedirectRoute={`/${OIDCConstants.oidcSilentRedirect}`}
      />
    );
  }

  return (
    <ErrorBoundary>
      <PageTitle title={null} />
      <TopBar />
      <StyledLayoutWrapper>
        {withSideMenu && (
          <StyledSider width={theme.layout_sizes.siderWidth}>
            <SideMenu />
          </StyledSider>
        )}
        <StyledLayout withSideMenu={withSideMenu} withCustomScroll={withCustomScroll}>
          <LayoutContentContainer
            withCustomScroll={withCustomScroll}
            withSideMenu={withSideMenu}
            withDetailsPane={withDetailsPane}
          >
            <LayoutContentWrapper withDetailsPane={withDetailsPane} isDnsPage={isDnsPage}>
              <ErrorBoundary>
                <LayoutContent isDnsPage={isDnsPage}>
                  <ContentRoutes />
                </LayoutContent>
              </ErrorBoundary>
            </LayoutContentWrapper>
          </LayoutContentContainer>

          <Details />
          <Modal />
          <OfflineDetector />
          <SessionLogoutUrl />
        </StyledLayout>
      </StyledLayoutWrapper>
    </ErrorBoundary>
  );
};

export default AppLayout;
