// components
import { ExpirationActionContent } from './styled';
import { ExpirationActionView } from './View';
import { ExpirationActionEdit } from './Edit';

// hooks
import { css } from 'styled-components';
import { useTranslations } from 'hooks/useTranslations';
import { useSelector } from 'react-redux';

// selectors
import { selectAppDetailsTabType } from 'redux/selectors';
import { Details } from 'components';

interface IExpirationActionBlock {
  isOpen: boolean;
}

export const ExpirationActionBlock = ({ isOpen }: IExpirationActionBlock) => {
  const { translate } = useTranslations('policies');

  const type = useSelector(selectAppDetailsTabType);

  return (
    <ExpirationActionContent
      isOpen={isOpen}
      data-test="system-details-auto-expire-expiration-action"
    >
      <Details.KeyValue
        contentCss={css`
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
        `}
        css={css`
          padding: 0;
        `}
        title={translate('details.auto_expire.expiration_action.title')}
      >
        {type === 'policy-view' && <ExpirationActionView />}
        {(type === 'policy-edit' || type === 'policy-create') && <ExpirationActionEdit />}
      </Details.KeyValue>
    </ExpirationActionContent>
  );
};
