// libs
import { css, useTheme } from 'styled-components';

// components
import { BlockWrapper, ContentWrapper } from 'modules/Account/styled';
import { CustomText, StyledSubtitle } from 'typography/Text';
import { CustomButton, CustomButtonLink, CustomTable, ExternalLink } from 'components';
import { StyledCustomTable, CustomTextWithOverflow } from './styled';

// hooks
import { useCallback } from 'react';
import { useTranslations } from 'hooks/useTranslations';
import { useDispatch, useSelector } from 'react-redux';

// selectors
import { selectAccountAccessTokens, selectAccountIsStepUpValid } from 'redux/selectors';

// acitons
import { setModalContent } from 'redux/actions/app';

// types
import { IAccountAccessToken } from 'types';

// constants
import { LINK_TO_API } from 'appConstants';

export const PATokensBlock = () => {
  const dispatch = useDispatch();

  const { translate } = useTranslations('account');

  const {
    palette: { fonts },
  } = useTheme();

  const stepUpIsOk = useSelector(selectAccountIsStepUpValid);
  const tokens = useSelector(selectAccountAccessTokens);

  const openDeletePAModal = useCallback(
    (token: IAccountAccessToken) => {
      if (stepUpIsOk) {
        if (token) dispatch(setModalContent({ type: 'delete-PAToken', data: token }));
      } else {
        if (token)
          dispatch(
            setModalContent({
              type: 're-auth-request',
              data: token,
              nextModal: 'delete-PAToken',
            })
          );
      }
    },
    [dispatch, stepUpIsOk]
  );

  const openCreatePAModal = useCallback(() => {
    if (stepUpIsOk) {
      dispatch(setModalContent({ type: 'create-PAToken', data: null }));
    } else {
      dispatch(
        setModalContent({
          type: 're-auth-request',
          data: null,
          nextModal: 'create-PAToken',
        })
      );
    }
  }, [dispatch, stepUpIsOk]);

  const columns = [
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: (description: IAccountAccessToken['description']) => (
        <CustomTextWithOverflow type="body-2">{description}</CustomTextWithOverflow>
      ),
    },
    {
      title: 'Usage',
      dataIndex: 'lastUsed',
      key: 'lastUsed',
      render: (usage: IAccountAccessToken['lastUsed']) => (
        <CustomText type="body-2">{usage ? usage : translate('token_never_been_used')}</CustomText>
      ),
    },
    {
      title: '',
      dataIndex: 'reference',
      key: 'reference',
      render: (_: IAccountAccessToken['reference'], token: IAccountAccessToken) => (
        <CustomButtonLink
          onClick={() => openDeletePAModal(token)}
          status="error"
          css={css`
            cursor: pointer;
          `}
        >
          {translate('delete')}
        </CustomButtonLink>
      ),
    },
  ];

  interface IEmptyDescription {
    text: string;
  }
  const EmptyDescription = ({ text }: IEmptyDescription) => {
    const {
      palette: { fonts },
    } = useTheme();
    return (
      <CustomTextWithOverflow type="body-2" color={fonts.bodyLight}>
        {text}
      </CustomTextWithOverflow>
    );
  };

  const emptyColumns = [
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: '19.3125rem',
      render: (description: IAccountAccessToken['description']) => {
        return <EmptyDescription text={description} />;
      },
    },
    {
      title: 'Usage',
      dataIndex: 'lastUsed',
      key: 'lastUsed',
      render: () => <></>,
    },
  ];

  return (
    <BlockWrapper>
      <ContentWrapper justify="flex-start">
        <StyledSubtitle>{translate('p_a_tokens')}</StyledSubtitle>
        <CustomText
          color={fonts.bodyLight}
          type="body-2"
          css={css`
            display: block;
            margin-bottom: 2rem;
          `}
        >
          {translate('p_a_tokens_desc')}
        </CustomText>
        <ExternalLink
          textType="body-1"
          href={LINK_TO_API}
          text={translate('p_a_tokens_api_link')}
          target="_self"
          withIcon
          css={css`
            padding: 0.5rem 0;
          `}
        />
      </ContentWrapper>
      <ContentWrapper
        justify={!tokens.length ? 'center' : ''}
        align={!tokens.length ? 'center' : ''}
      >
        {tokens.length ? (
          <CustomTable
            headerHeight="2.375rem"
            rowHeight="2.375rem"
            rowKey="reference"
            columns={columns}
            dataSource={tokens}
            pagination={false}
            css={StyledCustomTable}
          />
        ) : (
          <CustomTable
            headerHeight="2.375rem"
            rowHeight="2.375rem"
            rowKey="description"
            columns={emptyColumns}
            dataSource={[{ description: translate('no_p_a_token') }]}
            pagination={false}
            css={StyledCustomTable}
          />
        )}
        <CustomButton onClick={openCreatePAModal} size="large">
          {translate('create_new_token')}
        </CustomButton>
      </ContentWrapper>
    </BlockWrapper>
  );
};
