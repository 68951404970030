// libs
import { css } from 'styled-components';

// components
import { Input } from 'components';
import { IconInfoButton } from 'assets/svg';

// hooks
import { useDispatch, useSelector } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';
import { useCallback, useEffect, useState } from 'react';

// actions
import { updatePolicyDetailsAllowedSubnet } from 'redux/actions/app';

// selectorsd
import { selectAppDetailsTabValidationEntry } from 'redux/selectors';

// types
import { BaseSyntheticEvent } from 'react';
import { IPolicyModifiedGatewayAllowedIpRange } from 'types';

// constants
import { POLICY_SUBNET_VALIDATION_STATE_PREFIX } from 'appConstants';

interface ISubnet {
  allowedIpRange: IPolicyModifiedGatewayAllowedIpRange;
}

export const Subnet = ({ allowedIpRange }: ISubnet) => {
  const dispatch = useDispatch();

  const { translate } = useTranslations('policies');

  const validationState = useSelector(
    selectAppDetailsTabValidationEntry(POLICY_SUBNET_VALIDATION_STATE_PREFIX + allowedIpRange.id)
  );

  const [value, setValue] = useState(allowedIpRange.ipRange || '');

  useEffect(() => {
    setValue(allowedIpRange.ipRange || '');
  }, [allowedIpRange]);

  const onChange = useCallback((event: BaseSyntheticEvent) => {
    const newValue = event.target.value;

    setValue(newValue);
  }, []);

  const onBlur = useCallback(
    (event: BaseSyntheticEvent) => {
      const value = event.target.value;

      dispatch(
        updatePolicyDetailsAllowedSubnet({
          id: allowedIpRange.id,
          range: { ipRange: value },
        })
      );
    },
    [allowedIpRange, dispatch]
  );

  const isError =
    validationState?.updated && validationState?.state.error !== null
      ? validationState?.state.error
      : null;

  return (
    <Input
      data-test="policy-details-subnet-input"
      aria-label={translate('details.aria_labels.allowed_gateway_range')}
      placeholder={translate('details.allowed_gateway_range_placeholder')}
      showStateIcon={true}
      suffixMessage={translate('details.subnet_filter_info')}
      error={isError}
      textStyle="body-2"
      size="middle"
      value={value}
      onChange={onChange}
      tooltipProps={{
        placement: 'top',
        maxWidth: '16.25rem',

        passedCss: css`
          display: flex;
          white-space: pre-wrap;
        `,
      }}
      onBlur={onBlur}
      labelCss={css`
        display: none;
      `}
      suffixIcon={!isError && <IconInfoButton width="1rem" height="1rem" />}
      stateMessageCss={css`
        display: none;
      `}
      containerCss={css`
        flex: 1;
      `}
    />
  );
};
