// components
import { CustomText } from 'typography/Text';
import { StyledGroup, StyledButton, StyledOptionWrapper } from './styled';

// hooks
import styled, { useTheme } from 'styled-components';
import { useTranslations } from 'hooks/useTranslations';
import { useDispatch, useSelector } from 'react-redux';

// selectors
import {
  selectAppDetailsPolicyAutoExpireAction,
  selectAppDetailsPolicyAutoExpireNewAction,
} from 'redux/selectors';

// actions
import { updatePolicyDetailsAutoExpire } from 'redux/actions/app';

// types
import { RadioChangeEvent } from 'antd';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const ExpirationActionEdit = () => {
  const dispatch = useDispatch();

  const { translate } = useTranslations('policies');

  const {
    palette: { fills },
  } = useTheme();

  const selectedAction = useSelector(selectAppDetailsPolicyAutoExpireAction);
  const newAction = useSelector(selectAppDetailsPolicyAutoExpireNewAction);

  const displayedAction = selectedAction || newAction;

  const handleExpirationActionChange = (event: RadioChangeEvent) => {
    dispatch(updatePolicyDetailsAutoExpire({ expiryAction: event.target.value }));
  };

  return (
    <Container>
      <StyledGroup
        defaultValue={displayedAction || 'Disable'}
        onChange={handleExpirationActionChange}
      >
        <StyledButton value="Disable">
          <StyledOptionWrapper data-test="policy-details-select-expiration-action">
            <CustomText type="body-2" color={displayedAction === 'Disable' ? fills.primary : ''}>
              {translate('details.auto_expire.expiration_action.options.disable')}
            </CustomText>
          </StyledOptionWrapper>
        </StyledButton>
        <StyledButton value="Delete">
          <StyledOptionWrapper data-test="policy-details-select-expiration-action">
            <CustomText type="body-2" color={displayedAction === 'Delete' ? fills.primary : ''}>
              {translate('details.auto_expire.expiration_action.options.remove')}
            </CustomText>
          </StyledOptionWrapper>
        </StyledButton>
      </StyledGroup>
    </Container>
  );
};
