import { ISystemSummaryModel, IUnapprovedSystem } from 'types';
import { ITEMS_FOR_SKELETON } from 'appConstants/ui';

export const emptySystemsArray = Array.from({ length: ITEMS_FOR_SKELETON }, (_el, index) => ({
  systemId: String(index),
})) as ISystemSummaryModel[];

export const emptyUnapprovedSystemsArray = Array.from(
  { length: ITEMS_FOR_SKELETON },
  (_el, index) => ({
    systemId: String(index),
  })
) as IUnapprovedSystem[];

export const isEnabled = (system: ISystemSummaryModel) => system.isEnabled === true;
export const isDisabled = (system: ISystemSummaryModel) => system.isEnabled === false;
export const mapSystemId = (system: ISystemSummaryModel) => system.systemId;
