// components
import { DetailRow } from 'components/DetailsPanel/KeyValue/styled';
import { CustomText } from 'typography/Text';

// selectors
import {
  selectAppDetailsTabType,
  selectAppDetailsTrustRequirementCurrentAuthority,
  selectAppDetailsTrustRequirementCurrentType,
} from 'redux/selectors';

// hooks
import { useTheme } from 'styled-components';
import { useTranslations } from 'hooks/useTranslations';
import { useSelector } from 'react-redux';

export const ConditionsDescriptionBlock = () => {
  const { translate } = useTranslations('trust-requirements');

  const {
    palette: { fonts },
  } = useTheme();

  const type = useSelector(selectAppDetailsTrustRequirementCurrentType);
  const authority = useSelector(selectAppDetailsTrustRequirementCurrentAuthority);
  const tabType = useSelector(selectAppDetailsTabType);

  return (
    <DetailRow>
      <CustomText type="body-2" color={fonts.bodyLight}>
        {type === 'UserAuthentication' &&
          translate(
            `details.types.${type}.authorities.${
              authority || 'not_selected'
            }.conditions_description_${tabType === 'trust-requirement-view' ? 'view' : 'edit'}`
          )}
      </CustomText>
    </DetailRow>
  );
};
