// libs
import { createReducer } from '@reduxjs/toolkit';
import { STARTER_PLAN } from 'appConstants';
import { IOrganisationsStore } from 'types';
import {
  setOrganisationInfo,
  setOrganisationUsers,
  setOrganisationInvites,
  setOrganisationPlans,
} from '../actions/organisation';
import { setBillingPortalUrl } from '../actions/payment';

export const defaultOrgInfo: IOrganisationsStore['info'] = {
  id: '',
  name: '',
  plan: STARTER_PLAN,
  website: '',
  phone: '',
  maxSystems: 0,
  enrolledSystems: 0,
  unapprovedSystems: 0,
  created: '',
  featureLimits: [],
  billingIsPaidYearly: null,
  billingCurrencySymbol: '$',
  billingRate: null,
  billingDisableUpdate: null,
  isSuspendedByAdmin: false,
  trialExpiry: null,
  trialState: 'None',
  partner: null,
};

const initialState: IOrganisationsStore = {
  info: defaultOrgInfo,
  invites: [],
  users: [],
  plans: null,
  manageBillingUrl: undefined,
};

export const organisationsReducer = createReducer(initialState, builder => {
  builder
    .addCase(setOrganisationInfo, (state, { payload }) => {
      state.info = payload;
    })
    .addCase(setOrganisationUsers, (state, { payload }) => {
      state.users = payload;
    })
    .addCase(setOrganisationInvites, (state, { payload }) => {
      state.invites = payload;
    })
    .addCase(setOrganisationPlans, (state, { payload }) => {
      state.plans = payload;
    })
    .addCase(setBillingPortalUrl, (state, { payload }) => {
      state.manageBillingUrl = payload;
    });
});
