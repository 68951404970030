// libs
import { css, useTheme } from 'styled-components';

// components
import { IconArrowRight, List, ListItem } from './styled';
import { InternalLink } from 'components/Links/InternalLink';
import { ControllableIcon, CustomTag } from 'components';
import { IconSideMenuPolicies } from 'assets/svg';
import { CustomText } from 'typography/Text';

// hooks
import { useSelector, useDispatch } from 'react-redux';
import { useCallback, useEffect } from 'react';
import { useTranslations } from 'hooks/useTranslations';

// selectors
import {
  selectAppDetailsTagData,
  selectAppDetailsTagPoliciesItems,
  selectCurrentOrganisationId,
  selectAppDetailsTagColour,
  selectAppDetailsTagDescription,
  selectAppDetailsTagNewColour,
  selectAppDetailsTagNewDescription,
  selectAppDetailsTagRef,
} from 'redux/selectors';

//actions
import { extendTagDetails } from 'redux/actions/tags';

// constants
import { ROUTE_PATHS } from 'appConstants';
import { TAG_DETAILS_ELEMENTS_NUM, TAGS_DEFAULT_COLOUR } from 'appConstants/tags';

export const PoliciesTab = () => {
  const {
    palette: { fonts },
  } = useTheme();

  const { translate } = useTranslations('tags');
  const dispatch = useDispatch();

  const tag = useSelector(selectAppDetailsTagData);
  const colour = useSelector(selectAppDetailsTagColour);
  const newColour = useSelector(selectAppDetailsTagNewColour);
  const description = useSelector(selectAppDetailsTagDescription);
  const newDescription = useSelector(selectAppDetailsTagNewDescription);
  const tagRef = useSelector(selectAppDetailsTagRef);

  const policies = useSelector(selectAppDetailsTagPoliciesItems);
  const orgId = useSelector(selectCurrentOrganisationId);

  const fetchData = useCallback(
    () => dispatch(extendTagDetails({ tag: tag?.tag || '', module: 'policies' })),
    [dispatch, tag]
  );

  const getDescription = useCallback(() => {
    if (policies) {
      if (policies.length === 1) {
        return `details.connections.tabs.policies.description.title_one`;
      }
      if (policies.length > TAG_DETAILS_ELEMENTS_NUM) {
        return `details.connections.tabs.policies.description.title_first`;
      }
      return `details.connections.tabs.policies.description.title_all`;
    }

    return 'details.connections.tabs.policies.description.title_all';
  }, [policies]);

  useEffect(() => {
    if (!policies) {
      fetchData();
    }
  }, [dispatch, policies, fetchData]);

  return (
    <div>
      {policies && (
        <>
          <CustomText
            css={css`
              white-space: pre;
              display: flex;
              margin-top: 1.5rem;
              margin-bottom: 0.75rem;
              > span {
                margin-left: 0.5rem;
              }
            `}
            type="body-2"
            color={fonts.bodyLight}
          >
            {translate(getDescription(), { number: TAG_DETAILS_ELEMENTS_NUM })}
            {tagRef && description && (
              <CustomTag
                disableHoverEffect
                tag={{
                  ref: tagRef,
                  colour: newColour || colour || TAGS_DEFAULT_COLOUR,
                  tag: newDescription !== undefined ? newDescription : description,
                }}
              />
            )}
          </CustomText>
          <List>
            {policies?.length && (
              <InternalLink
                type="body-2"
                to={`/org/${orgId}${ROUTE_PATHS.policies}/?include_disabled=true&search=tags%3A${tag?.tag}`}
                ellipsis={true}
                icon={
                  <IconArrowRight
                    css={css`
                      margin-left: 0.125rem;
                    `}
                  />
                }
              >
                {translate('details.connections.tabs.policies.all_attached')}
              </InternalLink>
            )}

            {policies.slice(0, TAG_DETAILS_ELEMENTS_NUM).map(policy => {
              const policyName = policy.description;

              return (
                <ListItem tabIndex={0} key={policy.id}>
                  <ControllableIcon
                    icon={<IconSideMenuPolicies />}
                    size="medium"
                    css={css`
                      margin-right: 0.75rem;
                    `}
                  />
                  <InternalLink
                    title={policyName}
                    type="body-2"
                    to={`/org/${orgId}${ROUTE_PATHS.policies}/${policy.id}?include_disabled=true`}
                    ellipsis={true}
                  >
                    {policyName}
                  </InternalLink>
                  <CustomText
                    type="body-2"
                    color={fonts.disabled}
                    css={css`
                      white-space: pre;
                    `}
                  >
                    {!policy.isEnabled && ' ' + `(${translate('details.connections.disabled')})`}
                  </CustomText>
                </ListItem>
              );
            })}
          </List>
        </>
      )}
    </div>
  );
};
