import { IActiveHours, IAutoExpire } from 'types';
import { getDefaultAutoExpireDate, getUserTimeZone } from 'utils';
import { TIME_ZONES_DATA_SOURCE } from './time';

const userTimeZone = getUserTimeZone();

export const defaultTimeZone =
  TIME_ZONES_DATA_SOURCE.find(item => item.zone === userTimeZone) || TIME_ZONES_DATA_SOURCE[0];

export const DEFAULT_POLICY_ACTIVE_HOURS: IActiveHours = {
  timeZoneId: defaultTimeZone.zone,
  daysOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
  startTime: {
    hours: 9,
    minutes: 0,
  },
  endTime: {
    hours: 18,
    minutes: 0,
  },
};

export const DEFAULT_AUTO_EXPIRE_DATA: IAutoExpire = {
  timeZoneId: defaultTimeZone.zone,
  expiryAction: 'Disable',
  expiryDateTime: getDefaultAutoExpireDate(),
};
