// types
import { ISearchKey } from 'types';

// utils
import { ISearchQueryDetails, ISearchValue, charToSearchModifier } from './';

export interface IGetNewActiveKey {
  index: number;
  keyConfig: ISearchQueryDetails | null;
  searchKeys: ISearchKey[] | null;
  searchValue: string;
}

export interface IActiveKey {
  searchKey: ISearchKey;
  modifiedValue: ISearchValue | null;
}

export const getNewActiveKey = ({
  index,
  keyConfig,
  searchKeys,
  searchValue,
}: IGetNewActiveKey): IActiveKey | null => {
  if (!keyConfig || !searchKeys) return null;

  let modifiedValue: ISearchValue | null = null;

  let activeSearchKey: ISearchKey | null = null;

  const activeEntry =
    Object.entries(keyConfig).find(entry => {
      const totalRange = entry[1].totalRange;
      const keyRange = entry[1].keyRange;

      if (entry[1].isDefault || !totalRange || !keyRange) {
        console.warn('totalRange and KeyRange should exist');
        return false;
      }

      const isComma = searchValue[index] === ',';
      const isSpace = searchValue[index] === ' ';
      const isSearchModifier = Boolean(charToSearchModifier({ char: searchValue[index] }));

      if (
        // if index is within range or 1 character before range and is space, or 1 character after the end and is comma or has modifier,
        (index >= totalRange.start || (index === totalRange.start - 1 && isSpace)) &&
        (index < totalRange.end || (index === totalRange.end && (isComma || isSearchModifier)))
      ) {
        const values = entry[1].values;

        activeSearchKey = searchKeys.find(searchKey => searchKey.name === entry?.[0]) || null;

        // if key we searching on doesn't exist
        if (!activeSearchKey) {
          return null;
        }

        if (!activeSearchKey.canHaveMultiple) {
          modifiedValue = {
            value: searchValue.substring(keyRange.end, totalRange.end),
            range: {
              start: keyRange.end,
              // if we have comma after value we want to get rid of it
              // so we add it to range that will be replaced
              end: searchValue?.[totalRange.end] === ',' ? totalRange.end + 1 : totalRange.end,
            },
          };
          return true;
        }

        const activeValue =
          values.find(value => {
            if (index >= value.range.start && index < value.range.end) return true;
          }) || null;

        if (activeValue) {
          modifiedValue = activeValue;
          return true;
        }

        if (searchValue[index] === ',') {
          modifiedValue = { value: '', range: { start: index + 1, end: index + 1 } };
          return true;
        }

        // if we are focused on key, not on value we should modify first value
        if (!activeValue && index - 1 <= totalRange.end) {
          modifiedValue = values[0];
          return true;
        }

        return true;
      }
    }) || null;

  if (!activeSearchKey) {
    return null;
  }

  return {
    searchKey: activeSearchKey,
    modifiedValue:
      modifiedValue ||
      (activeEntry?.[1].totalRange
        ? {
            value: '',
            range: {
              start: activeEntry?.[1].totalRange?.end,
              end: activeEntry?.[1].totalRange?.end,
            },
          }
        : null),
  };
};
