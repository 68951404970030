import { useTheme } from 'styled-components';
import { ProgressContainer, ProgressBG } from './styled';

export interface IProgressProps {
  percent: number;
  sharp?: boolean;
  color?: string;
  active?: boolean;
  backgroundColor?: string;
}

export const CustomProgress = ({
  percent,
  sharp = false,
  active = true,
  color,
  backgroundColor,
  ...rest
}: IProgressProps) => {
  const {
    palette: { state },
  } = useTheme();

  return (
    <ProgressContainer sharp={sharp} active={active} backgroundColor={backgroundColor} {...rest}>
      <ProgressBG color={color || state.successStroke} percent={percent} />
    </ProgressContainer>
  );
};
