// Lib
import { select, put } from 'typed-redux-saga';
import { IStepUpToken } from 'types';
import { setModalContent } from 'redux/actions/app';
import {
  selectAccountIsStepUpValid,
  selectAccountSignInMethodIsLocal,
  selectAccountStepUpToken,
} from 'redux/selectors';

export function* validateStepUpTokenSaga(): Generator<any, string | null, IStepUpToken> {
  const signInMethodIsLocal = yield* select(selectAccountSignInMethodIsLocal);

  if (!signInMethodIsLocal) {
    return 'foreignAuth';
  }

  const stepUpToken = yield select(selectAccountStepUpToken);
  const stepUpIsOk = yield* select(selectAccountIsStepUpValid);

  if (stepUpToken && stepUpIsOk) {
    return stepUpToken.token;
  }
  yield put(setModalContent({ type: 're-auth-request', data: null }));
  return null;
}
