import styled from 'styled-components';

export const SystemsContainerWithPopup = styled.div`
  position: relative;
  width: fit-content;
`;

export const SystemsContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
