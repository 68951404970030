import { ROUTE_PATHS } from 'appConstants';
import { MainState } from 'types';

export const selectDnsZone =
  ({ zoneId }: { zoneId: number | undefined }) =>
  (state: MainState) =>
    zoneId !== undefined
      ? Object.values(state.Dns.zones.dataPagesItems)
          .flat()
          .find(zone => zone.id === zoneId)
      : undefined;

export const selectDnsZones = (state: MainState) =>
  Object.values(state.Dns.zones.dataPagesItems).flat();
export const selectDnsFirstZone = (state: MainState) =>
  Object.values(state.Dns.zones.dataPagesItems).flat()?.[0];
export const selectDnsZonesTotal = (state: MainState) => state.Dns.zones.data.metadata.total;
export const selectDnsZonesHasNextPage = (state: MainState) =>
  !!state.Dns.zones.data.metadata.nextPage;
export const selectDnsZonesUpdateId = (state: MainState) => state.Dns.zones.updateId;
export const selectDnsZonesIsLoading = (state: MainState) => state.Dns.zones.isLoading;
export const selectDnsSelectedZone = (state: MainState) => state.Dns.zones.selectedZone;

export const selectDnsRecordSearchKeys = (state: MainState) => state.Dns.records.searchKeys;
export const selectDnsRecords = (state: MainState) => state.Dns.records.data.items;
export const selectDnsRecordsUpdateId = (state: MainState) => state.Dns.records.updateId;
export const selectDnsRecordsTotal = (state: MainState) => state.Dns.records.data.metadata.total;
export const selectDnsRecordsIsLoading = (state: MainState) => state.Dns.records.isLoading;

// Details Data
export const selectAppDetailsDnsRecordId = ({ App: { detailsTab } }: MainState) => {
  if (detailsTab.type === 'dns-record-view' || detailsTab.type === 'dns-record-edit') {
    return detailsTab.data.id;
  }
};

export const selectAppDetailsDnsRecordName = ({ App: { detailsTab } }: MainState) => {
  if (detailsTab.type === 'dns-record-view' || detailsTab.type === 'dns-record-edit') {
    return detailsTab.data.name;
  }
};

export const selectAppDetailsDnsRecordZoneName = ({ App: { detailsTab } }: MainState) => {
  if (detailsTab.type === 'dns-record-view' || detailsTab.type === 'dns-record-edit') {
    return detailsTab.data.zoneName;
  }
};

export const selectAppDetailsDnsRecordFqdn = ({ App: { detailsTab } }: MainState) => {
  if (detailsTab.type === 'dns-record-view' || detailsTab.type === 'dns-record-edit') {
    return detailsTab.data.fqdn;
  }
};

export const selectAppDetailsDnsRecordType = ({ App: { detailsTab } }: MainState) => {
  if (detailsTab.type === 'dns-record-view' || detailsTab.type === 'dns-record-edit') {
    return detailsTab.data.type;
  }
};

export const selectAppDetailsDnsNotes = ({ App: { detailsTab } }: MainState) => {
  if (detailsTab.type === 'dns-record-view' || detailsTab.type === 'dns-record-edit') {
    return detailsTab.data.notes;
  }
};

export const selectAppDetailsDnsTags = ({ App: { detailsTab } }: MainState) => {
  if (detailsTab.type === 'dns-record-view' || detailsTab.type === 'dns-record-edit') {
    return detailsTab.data.tags;
  }
};

export const selectAppDetailsDnsTagsAmount = ({ App: { detailsTab } }: MainState) => {
  if (detailsTab.type === 'dns-record-view' || detailsTab.type === 'dns-record-edit') {
    return detailsTab.data.tags.length;
  }
};

export const selectAppDetailsDnsAutoGenerated = ({ App: { detailsTab } }: MainState) => {
  if (detailsTab.type === 'dns-record-view' || detailsTab.type === 'dns-record-edit') {
    return detailsTab.data.autoGenerated;
  }
};

export const selectAppDetailsDnsSystems = ({ App: { detailsTab } }: MainState) => {
  if (detailsTab.type === 'dns-record-view' || detailsTab.type === 'dns-record-edit') {
    return detailsTab.data.systems;
  }
};

export const selectAppDetailsDnsSystemsAmount = ({ App: { detailsTab } }: MainState) => {
  if (detailsTab.type === 'dns-record-view' || detailsTab.type === 'dns-record-edit') {
    return detailsTab.data.systems.length;
  }
};

// Details New Data
export const selectAppDetailsDnsRecordNewName = ({ App: { detailsTab } }: MainState) => {
  if (detailsTab.type === 'dns-record-edit' || detailsTab.type === 'dns-record-create') {
    return detailsTab.dataNew.name;
  }
};

export const selectAppDetailsDnsRecordNewZoneName = ({ App: { detailsTab } }: MainState) => {
  if (detailsTab.type === 'dns-record-edit' || detailsTab.type === 'dns-record-create') {
    return detailsTab.dataNew.zoneName;
  }
};

export const selectAppDetailsDnsRecordNewZoneFqdn = ({ App: { detailsTab } }: MainState) => {
  if (detailsTab.type === 'dns-record-edit' || detailsTab.type === 'dns-record-create') {
    return detailsTab.dataNew.fqdn;
  }
};

export const selectAppDetailsDnsNewRecordType = ({ App: { detailsTab } }: MainState) => {
  if (detailsTab.type === 'dns-record-edit' || detailsTab.type === 'dns-record-create') {
    return detailsTab.dataNew.type;
  }
};

export const selectAppDetailsDnsNewNotes = ({ App: { detailsTab } }: MainState) => {
  if (detailsTab.type === 'dns-record-edit' || detailsTab.type === 'dns-record-create') {
    return detailsTab.dataNew.notes;
  }
};

export const selectAppDetailsDnsNewTags = ({ App: { detailsTab } }: MainState) => {
  if (detailsTab.type === 'dns-record-edit' || detailsTab.type === 'dns-record-create') {
    return detailsTab.dataNew.tags;
  }
};

export const selectAppDetailsDnsNewTagsAmount = ({ App: { detailsTab } }: MainState) => {
  if (detailsTab.type === 'dns-record-edit' || detailsTab.type === 'dns-record-create') {
    return detailsTab.dataNew.tags.length;
  }
};

export const selectAppDetailsDnsNewSystems = ({ App: { detailsTab } }: MainState) => {
  if (detailsTab.type === 'dns-record-edit' || detailsTab.type === 'dns-record-create') {
    return detailsTab.dataNew.systems;
  }
};

export const selectAppDetailsDnsNewSystemsAmount = ({ App: { detailsTab } }: MainState) => {
  if (detailsTab.type === 'dns-record-edit' || detailsTab.type === 'dns-record-create') {
    return detailsTab.dataNew.systems.length;
  }
};

export const selectIsDnsModule = ({
  router: {
    location: { pathname },
  },
  Account: { currentOrg },
}: MainState) => {
  return pathname.includes(`${currentOrg}${ROUTE_PATHS.dns}`);
};
