// libs
import { forwardRef } from 'react';

// components
import { Input } from 'antd';
import { ReactComponent as EyeOpen } from 'assets/svg/eye-hidden.svg';
import { ReactComponent as EyeClose } from 'assets/svg/eye-shown.svg';
import { Input as StyledInput } from '../Default/styled';
import {} from '../Default';

// types
import { InputProps } from 'antd/lib/input';

interface IInputPassword extends InputProps {
  $warning?: boolean;
  $error?: boolean;
  $sharpborders?: boolean;
}

export const InputPassword = forwardRef<HTMLInputElement, IInputPassword>(({ ...rest }, ref) => {
  return (
    <StyledInput
      {...{ ...rest, ref }}
      as={Input.Password}
      visibilityToggle
      iconRender={(visible: boolean) => (visible ? <EyeOpen /> : <EyeClose />)}
    />
  );
});
