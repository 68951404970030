// components
import { StyledCommandLineCode } from '../../../styled';
import { StyledItem } from '../../../components/List';

// hooks
import { useTranslations } from 'hooks/useTranslations';

export const LinuxScript = () => {
  const { translate } = useTranslations('enrol-new-system');

  return (
    <>
      <StyledItem
        title={translate('linux.distros.script.1.title')}
        content={
          <StyledCommandLineCode code={`bash <(curl -Ss https://install.enclave.io/setup.sh)`} />
        }
      />
    </>
  );
};
