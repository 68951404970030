// libs
import { css } from 'styled-components';

// components
import { Textarea } from 'components';

// hooks
import { useDispatch } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';
import { useCallback, useEffect, useState } from 'react';

// actions
import { updateTrustRequirementDetailsPublicIpIpRangeCondition } from 'redux/actions/app';

// types
import { BaseSyntheticEvent } from 'react';
import { ITrustRequirementPublicIpIpRangeConditionModified } from 'types';

// constants
import { TRUST_REQUIREMENTS_IP_RANGE_DESCRIPTION_CHARACTERS_LIMIT } from 'appConstants';

interface IDescription {
  ipRange: ITrustRequirementPublicIpIpRangeConditionModified;
}

export const Description = ({ ipRange }: IDescription) => {
  const dispatch = useDispatch();

  const { translate } = useTranslations('trust-requirements');

  const [value, setValue] = useState(ipRange.description || '');

  useEffect(() => {
    setValue(ipRange.description || '');
  }, [ipRange.description]);

  const onChange = useCallback((event: BaseSyntheticEvent) => {
    setValue(event.target.value);
  }, []);

  const onBlur = useCallback(
    (event: BaseSyntheticEvent) => {
      const newValue = event.target.value;

      dispatch(
        updateTrustRequirementDetailsPublicIpIpRangeCondition({
          id: ipRange.id,
          range: {
            description: newValue,
          },
        })
      );
    },
    [ipRange.id, dispatch]
  );

  return (
    <Textarea
      placeholder={translate('details.types.PublicIp.description_placeholder')}
      value={value}
      autoSize={{ minRows: 1, maxRows: 6 }}
      passedCss={css`
        width: 100%;
      `}
      onChange={onChange}
      onBlur={onBlur}
      charactersLimit={{
        characters: value.length || 0,
        charactersLimit: TRUST_REQUIREMENTS_IP_RANGE_DESCRIPTION_CHARACTERS_LIMIT,
      }}
    />
  );
};
