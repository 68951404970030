import styled from 'styled-components';

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContentTopRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5rem;
`;

export const SectionIconContainer = styled.div`
  display: flex;
  align-self: flex-start;
`;
