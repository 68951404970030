import { createAction } from '@reduxjs/toolkit';
import {
  INotificationConfig,
  IModalContent,
  RedirectPayload,
  INetworkConnectionError,
} from 'types';

export * from './errors';
export * from './detailTab/default';
export * from './detailTab/dnsRecords';
export * from './detailTab/enrolmentKeys';
export * from './detailTab/policies';
export * from './detailTab/systems';
export * from './detailTab/validation';
export * from './detailTab/tags';
export * from './detailTab/trust-requirements';

export const initRequest = createAction<boolean>('main/INIT_REQUEST');
export const setStripeLoaded = createAction('main/STRIPE_LOADED');
export const setPollingSource = createAction<string | null>('main/SET_POLLING_SOURCE');
export const pushNotification = createAction<INotificationConfig & { isLocked?: boolean }>(
  'main/SHOW_NOTIFICATION'
);
export const unlockNotifications = createAction('main/UNLOCK_NOTIFICATIONS');
export const cleanNotifications = createAction('main/ClEAN_NOTIFICATIONS');
export const setModalContent = createAction<IModalContent>('main/SET_MODAL_CONTENT');
export const setModalIsLoading = createAction<boolean>('main/SET_MODAL_IS_LOADING');
export const redirectControl = createAction<RedirectPayload>('main/REDIRECT_CONTROL');
export const setAppLoaded = createAction('main/SET_APP_LOADED');
export const setNetworkConnectionError = createAction<INetworkConnectionError>(
  'main/SET_NETWORK_CONNECTION_ERROR'
);
