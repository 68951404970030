import styled from 'styled-components';

export const Container = styled.div<{
  backroundColor?: string;
  borderColor?: string;
}>`
  .ant-popover {
    .ant-popover-title {
      border: none;
      padding: 0;
    }

    .ant-popover-inner-content {
      padding: 0;
    }

    .ant-popover-content {
      .ant-popover-inner {
        padding: 1rem 1rem 1.375rem 1rem;

        border-radius: 0.25rem;
        border: 0.0625rem solid
          ${({ theme, borderColor }) => borderColor || theme.palette.background.iconBackground};

        box-shadow: none;

        background-color: ${({ theme, backroundColor }) =>
          backroundColor || theme.palette.background.panel} !important;
      }
    }
  }
`;
