import { createAction } from '@reduxjs/toolkit';

import {
  IGetSystemsResponse,
  ISystemSummaryModel,
  IEnableSystemProps,
  IDisableSystemProps,
  IRevokeSystemParams,
  IRevokeSystemsParams,
  IEnableSystemsParams,
  IDisableSystemsParams,
  IUpdateSystemsState,
  ISearchKey,
  IEnableUntilSystemProps,
  EnableUntilType,
  IGetSystems,
} from 'types';

export const getSystems = createAction<IGetSystems>('systems/GET_SYSTEMS');
export const setSystems = createAction<IGetSystemsResponse>('systems/SET_SYSTEMS');
export const setSystem = createAction<ISystemSummaryModel>('systems/SET_SYSTEM');
export const enableSystem = createAction<IEnableSystemProps>('systems/ENABLE_SYSTEM');
export const enableUntilSystem = createAction<IEnableUntilSystemProps & EnableUntilType>(
  'systems/ENABLE_UNTIL_SYSTEM'
);
export const disableSystem = createAction<IDisableSystemProps>('systems/DISABLE_SYSTEM');
export const revokeSystem = createAction<IRevokeSystemParams>('systems/REVOKE_SYSTEM');
export const revokeSystems = createAction<IRevokeSystemsParams>('systems/REVOKE_SYSTEMS');
export const removeSystems = createAction<string[]>('systems/REMOVE_SYSTEMS');
export const enableSystems = createAction<IEnableSystemsParams>('systems/ENABLE_SYSTEMS');
export const disableSystems = createAction<IDisableSystemsParams>('systems/DISABLE_SYSTEMS');
export const setSystemsState = createAction<IUpdateSystemsState>('systems/UPDATE_SYSTEM_STATE');
export const setIsLoading = createAction<boolean>('systems/SET_IS_LOADING');
export const saveSystemDetails = createAction('systems/SAVE_SYSTEM_DETAILS');
export const setSystemsShouldUpdate = createAction('systems/SHOULD_UPDATE');
export const getSystemSearchKeys = createAction('systems/GET_SEARCH_KEYS');
export const setSystemSearchKeys = createAction<ISearchKey[]>('systems/SET_SEARCH_KEYS');
