import styled from 'styled-components';

export const DetailSection = styled.section`
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    margin-bottom: 3.5rem;
  }
`;
