// libs
import { css, CSSProp } from 'styled-components';

// components
import { Tabs } from 'antd';
import { inherentTabsCss } from './styled';
import { DefaultTabPane, ITabsDefault } from './DefaultTabPane';
import { IconTabPane, ITabsIcon } from './IconTabPane';
import { NumberTabPane, ITabsNumber } from './NumberTabPane';

const { TabPane } = Tabs;

interface IProps {
  onChange: (activeKey: string) => void;
  selectedKey: string;
  tabs: ITabs[];
  tabCss?: CSSProp;
}

export type ITabs = ITabsDefault | ITabsNumber | ITabsIcon;

export type TabWithActiveParam<T> = { tab: T; active: boolean };

export const TabButtons = ({ tabs, selectedKey, onChange, tabCss, ...rest }: IProps) => {
  return (
    <Tabs
      activeKey={selectedKey}
      onChange={selectedKey => onChange(selectedKey)}
      css={css`
        ${inherentTabsCss({
          tabcss: tabCss,
        })}
      `}
      {...rest}
    >
      {tabs.map(tab => {
        const active = tab.key === selectedKey;

        return (
          <TabPane
            tab={
              tab.type === 'icon' ? (
                <IconTabPane key={tab.key} tab={tab} active={active} />
              ) : tab.type === 'number' ? (
                <NumberTabPane key={tab.key} tab={tab} active={active} />
              ) : (
                <DefaultTabPane key={tab.key} tab={tab} active={active} />
              )
            }
            key={tab.key}
          />
        );
      })}
    </Tabs>
  );
};
