import { createAction } from '@reduxjs/toolkit';
import {
  IEnrolmentStoredKey,
  IDetailsKeySetData,
  ILoadViewEditEnrolmentKeyDetailsParams,
  ICreateEnrolmentKeyPayload,
} from 'types';

export const updateEnrolmentKeyDetails = createAction<Partial<IEnrolmentStoredKey>>(
  'main/UPDATE_ENROLMENT_KEY_DETAILS'
);
export const setEnrolmentKeyDetailsContent = createAction<IDetailsKeySetData>(
  'main/SET_ENROLMENT_KEY_DETAILS_CONTENT'
);

export const loadEnrolmentKeyDetails = createAction<ILoadViewEditEnrolmentKeyDetailsParams>(
  'main/LOAD_ENROLMENT_KEY_DETAILS'
);

export const createEnrolmentKeySideMenu = createAction<ICreateEnrolmentKeyPayload | undefined>(
  'main/CREATE_KEY_SIDE_MENU'
);

export const createEnrolmentKeyDetailsAutoExpire = createAction(
  'main/CREATE_KEY_DETAILS_AUTO_EXPIRE'
);
export const updateEnrolmentKeyDetailsAutoExpire = createAction<
  Partial<IEnrolmentStoredKey['autoExpire'] | null>
>('main/UPDATE_KEY_DETAILS_AUTO_EXPIRE');
