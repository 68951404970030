// libs
import { CSSProp } from 'styled-components';

// hooks
import { useCallback, useEffect } from 'react';
import { useTranslations } from 'hooks/useTranslations';

// components
import { GatewaySelect as Select } from 'components';

// actions
import { getSystems, setSystems } from 'redux/actions/systems';
import {
  addPolicyDetailsGateway,
  movePolicyDetailsGatewayDown,
  movePolicyDetailsGatewayUp,
  removePolicyDetailsGateway,
} from 'redux/actions/app';

// selectors
import { selectAppDetailCurrentPolicyGateways, selectApprovedSystems } from 'redux/selectors';

// types
import { IPolicyGateway, IPolicyGatewayRoute } from 'types';

// constants
import { useDispatch, useSelector } from 'react-redux';
import { initialSystemsResponse } from 'redux/reducers/systems';

export interface IGatewaySelect {
  preSelected: IPolicyGateway[];
  changeCallback?: (policies: IPolicyGateway[]) => void;
  width?: string;
  containerCss?: CSSProp;
}

export const GatewaySelect = () => {
  const dispatch = useDispatch();

  const { translate } = useTranslations('policies');

  const systems = useSelector(selectApprovedSystems);
  const gateways = useSelector(selectAppDetailCurrentPolicyGateways);

  const fetchData = useCallback(
    () =>
      dispatch(
        getSystems({
          include_disabled: false,
          search: 'gateway:true',
          // Don't amend the search query.
          syncQueryParams: false,
        })
      ),
    [dispatch]
  );

  useEffect(() => {
    dispatch(fetchData());
  }, [dispatch, fetchData]);

  useEffect(() => {
    return () => {
      dispatch(setSystems(initialSystemsResponse));
    };
  }, [dispatch]);

  const onSelect = useCallback(
    (route: IPolicyGatewayRoute) => {
      dispatch(addPolicyDetailsGateway(route));
    },
    [dispatch]
  );

  const onDeselect = useCallback(
    (route: IPolicyGatewayRoute) => {
      dispatch(removePolicyDetailsGateway(route));
    },
    [dispatch]
  );

  const onMoveGatewayUp = useCallback(
    (systemId: string) => {
      dispatch(movePolicyDetailsGatewayUp(systemId));
    },
    [dispatch]
  );

  const onMoveGatewayDown = useCallback(
    (systemId: string) => {
      dispatch(movePolicyDetailsGatewayDown(systemId));
    },
    [dispatch]
  );

  return (
    <Select
      data-test="policy-edit-details-gateways-select"
      value={gateways}
      dataSource={systems}
      setSelectedValue={onSelect}
      removeSelectedValue={onDeselect}
      moveGatewayUp={onMoveGatewayUp}
      moveGatewayDown={onMoveGatewayDown}
      height="2.5rem"
      placeholder={translate('details.gateway_select_placeholder')}
    />
  );
};
