// components
import { TitleBlock } from '../../Blocks';
import { Details } from 'components';

export const Header = () => {
  return (
    <Details.HeaderSection data-test="dns-record-details-header">
      <TitleBlock />
    </Details.HeaderSection>
  );
};
