// Components
import { Select } from '../styled';
import { CustomBadge, CustomTag } from 'components';
import { CustomText } from 'typography/Text';

// Types
import { ITag } from 'types';

const { Option } = Select;

// Hooks
import { useTranslations } from 'hooks/useTranslations';
import { css, useTheme } from 'styled-components';

export const SelectOptions = (tagsToRender: ITag[]) => {
  const { translate } = useTranslations('tags');
  const {
    palette: { fonts },
  } = useTheme();

  return tagsToRender.map(tag => {
    return (
      <Option
        css={css`
          .ant-design-item-option-content {
            display: flex;
            align-items: center;
          }
        `}
        value={tag.tag}
        label={tag.tag}
        key={`tag_${tag.tag}`}
        className="tag-shown-option"
        data-test="tags-select-dd-item"
      >
        <CustomTag tag={tag} selectable={false} />
        <CustomBadge color="gray" size="0.15625rem" />
        <CustomText type="hint" color={fonts.disabled}>
          {`${tag.systems} ${translate('system', { count: tag.systems })}`}
        </CustomText>
      </Option>
    );
  });
};
