import { ArgsProps } from 'antd/lib/notification';
import {
  IFullEnrolmentKey,
  IModalContent,
  IEnrolmentKeyIpConstraints,
  IDetailsTabData,
  components,
} from '.';

export interface IErrorsType {
  form: Record<string, string[]>;
}

export type EnableUntilType = {
  text: string;
  action: 'EnableFor' | 'DisableAfter';
};

export interface IEnrolmentKeyDetailsData extends Omit<IFullEnrolmentKey, 'ipConstraints'> {
  ipConstraints?: IEnrolmentKeyIpConstraints;
}

export enum INotificationType {
  Error = 'error',
  Info = 'info',
  Warning = 'warning',
  Success = 'success',
}

export interface INotificationConfig extends Omit<ArgsProps, 'message' | 'description'> {
  showType: INotificationType;
  message?: string | React.ReactNode;
  description?: string | null;
}

export type IValidationStateValue = {
  error: {
    message: string;
    critical?: boolean;
  } | null;
};

export interface IValidationState {
  [key: string]: {
    state: IValidationStateValue;
    updated?: boolean;
    active?: boolean;
  };
}

export type INetworkConnectionError = { error: string } | null;

export interface IAppState {
  isLoading: boolean; // Probably rename later (used as network status)
  loaded: boolean; // App pre-loaded data and can be shown to user
  networkConnectionError: INetworkConnectionError;
  detailsTab: IDetailsTabData;
  modal: IModalContent;
  pollingSource: string | null;
  notifications: { data: INotificationConfig[] | null; isLocked: boolean };
  errors: IErrorsType;
  stripeLoaded: boolean;
}

export interface ISetLoadingAction {
  payload: boolean;
}

export interface ISetPollingSource {
  payload: string | null;
}

export interface ISetNotificationConfig {
  payload: INotificationConfig | null;
}

export interface IUpdateValidationStateEntry {
  key: string;
  state?: IValidationStateValue;
}

export interface IAddValidationStateEntry {
  key: string;
  state: IValidationStateValue;
}

export interface IDeleteValidationStateEntry {
  key: string;
}

export interface ICloseDetails {
  redirect?: boolean;
}

export type ModuleName =
  | 'systems'
  | 'unapproved-systems'
  | 'keys'
  | 'dashboard'
  | 'policies'
  | 'new-system'
  | 'events'
  | 'settings'
  | 'account'
  | 'dns'
  | 'trust-requirements'
  | 'tags';

export type ISearchKey = components['schemas']['SearchKey'];
