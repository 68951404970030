import styled, { css } from 'styled-components';

export const GatewaysContainer = styled.div<{ width?: string; blocksMode?: boolean }>`
  width: ${({ width }) => width};
  display: block;
  ${({ blocksMode }) =>
    blocksMode &&
    css`
      span {
        display: block;
        width: fit-content;
      }
    `}
`;
