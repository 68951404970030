// components
import { CustomText } from 'typography/Text';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';

// selectors
import { selectAppDetailsTrustRequirementGoogleAuthorityDomainId } from 'redux/selectors';

export const DomainIdView = () => {
  const { translate } = useTranslations('trust-requirements');

  const {
    palette: { fonts },
  } = useTheme();

  const domainId = useSelector(selectAppDetailsTrustRequirementGoogleAuthorityDomainId);
  1;

  return (
    <CustomText type="body-2" color={fonts.title}>
      {domainId ||
        translate('details.types.UserAuthentication.authorities.google.domain_id_empty_state')}
    </CustomText>
  );
};
