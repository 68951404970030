// libs
import { css } from 'styled-components';

// components
import { Details } from 'components';

// hooks
import { useTranslations } from 'hooks/useTranslations';

interface ILocationsAccessContent {
  children: React.ReactNode;
}

export const LocationsAccessContent = ({ children }: ILocationsAccessContent) => {
  const { translate } = useTranslations('trust-requirements');

  return (
    <Details.KeyValue
      css={css`
        margin-bottom: 1.5rem;
        padding-left: unset;
      `}
      contentCss={css`
        align-items: center;
      `}
      title={translate('details.types.PublicIp.location_access')}
    >
      {children}
    </Details.KeyValue>
  );
};
