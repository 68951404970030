// libs
import { css } from 'styled-components';

// components
import { CustomButton, EmptyContainer } from 'components';
import { IconNoFoundPolicyAcls } from 'assets/svg';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { useDispatch } from 'react-redux';
import { useCallback } from 'react';

//  actions
import { loadPolicyDetailsWithNewRule } from 'redux/actions/app';

export const EmptyAcls = () => {
  const dispatch = useDispatch();

  const { translate } = useTranslations('policies');

  const onClick = useCallback(() => {
    dispatch(loadPolicyDetailsWithNewRule());
  }, [dispatch]);

  return (
    <EmptyContainer
      css={css`
        height: auto;
        padding: 1.5rem 0 2rem 0;
        background-color: ${({ theme }) => theme.palette.background.base};
      `}
      titleCss={css`
        margin: 0.75rem 0 0.5rem 0;
      `}
      descriptionCss={css`
        color: ${({ theme }) => theme.palette.fonts.bodyLight};
        margin-bottom: 1.375rem;
        text-align: center;
      `}
      Icon={<IconNoFoundPolicyAcls />}
      primaryText={translate('details.acls_empty.title')}
      secondaryText={translate('details.acls_empty.description')}
      additionalText={
        <CustomButton onClick={onClick} kind="secondary">
          {translate('details.acls_empty.add_new_rule')}
        </CustomButton>
      }
    />
  );
};
