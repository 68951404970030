// libs
import { SyntheticEvent, memo, useCallback, useMemo } from 'react';
import dayjs from 'dayjs';

// components
import { ActionDropdown } from 'components';
import Skeleton from 'react-loading-skeleton';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { useDispatch } from 'react-redux';

// actions
import { disableSystem, enableSystem, enableUntilSystem } from 'redux/actions/systems';
import { loadSystemDetails, loadUnapprovedSystemDetails, setModalContent } from 'redux/actions/app';
import { approveSystem, declineSystem } from 'redux/actions/unapproved-systems';

// types
import { ISystemSummaryModel, IUnapprovedSystem } from 'types';

interface IEnrolledSystemActionMenuProps {
  isLoading: boolean;
  record: ISystemSummaryModel;
}

export const SystemsActionDropdown = memo(
  ({ record, isLoading, ...rest }: IEnrolledSystemActionMenuProps) => {
    const dispatch = useDispatch();

    const { translate } = useTranslations('systems');
    const { translate: translateGlobal } = useTranslations('global');

    const enableUntilData: { text: string; value: () => dayjs.Dayjs | 'custom' }[] = useMemo(() => {
      return [
        {
          text: translateGlobal('action_dropdown_time.15_mins'),
          value: () => dayjs().add(15, 'minute'),
        },
        {
          text: translateGlobal('action_dropdown_time.30_mins'),
          value: () => dayjs().add(30, 'minute'),
        },
        {
          text: translateGlobal('action_dropdown_time.1_hour'),
          value: () => dayjs().add(1, 'hour'),
        },
        {
          text: translateGlobal('action_dropdown_time.3_hours'),
          value: () => dayjs().add(3, 'hour'),
        },
        {
          text: translateGlobal('action_dropdown_time.8_hours'),
          value: () => dayjs().add(8, 'hour'),
        },
        {
          text: translateGlobal('action_dropdown_time.1_day'),
          value: () => dayjs().add(1, 'day'),
        },
        {
          text: translateGlobal('action_dropdown_time.5_days'),
          value: () => dayjs().add(5, 'day'),
        },
        {
          text: translateGlobal('action_dropdown_time.1_week'),
          value: () => dayjs().add(1, 'week'),
        },
        {
          text: translateGlobal('action_dropdown_time.1_month'),
          value: () => dayjs().add(1, 'month'),
        },
        {
          text: translateGlobal('action_dropdown_time.custom'),
          value: () => 'custom',
        },
      ];
    }, [translateGlobal]);

    const onDisableClick = useCallback(() => {
      dispatch(disableSystem({ systemId: record.systemId }));
    }, [dispatch, record.systemId]);

    const onEnableClick = useCallback(() => {
      dispatch(enableSystem({ systemId: record.systemId }));
    }, [dispatch, record.systemId]);

    const onEnableUntilClick = useCallback(
      (value: () => dayjs.Dayjs | 'custom', text: string) => {
        const extract = value();

        if (extract === 'custom') {
          dispatch(
            loadSystemDetails({ systemId: record.systemId, mode: 'edit', scrollToAutoExpire: true })
          );
        } else {
          dispatch(
            enableUntilSystem({
              systemId: record.systemId,
              action: record.isEnabled ? 'DisableAfter' : 'EnableFor',
              text,
              updatedParams: {
                expiryDateTime: extract.toISOString(),
                expiryAction: 'Disable',
                timeZoneId: null,
              },
            })
          );
        }
      },
      [dispatch, record.isEnabled, record.systemId]
    );
    const onRemoveClick = useCallback(() => {
      dispatch(setModalContent({ type: 'systems-remove', data: [record] }));
    }, [dispatch, record]);

    const onEditClick = useCallback(() => {
      dispatch(loadSystemDetails({ systemId: record.systemId, mode: 'edit' }));
    }, [dispatch, record.systemId]);

    return (
      <>
        {isLoading ? (
          <Skeleton />
        ) : (
          <ActionDropdown
            onContainerClick={(event: SyntheticEvent) => event.stopPropagation()}
            {...rest}
            data={[
              {
                text: translate('table_actions.edit'),
                onClick: onEditClick,
              },
              {
                text: record.isEnabled
                  ? translate('table_actions.disable')
                  : translate('table_actions.enable'),
                onClick: record.isEnabled ? onDisableClick : onEnableClick,
              },
              {
                isDropdown: true,
                text: record.isEnabled
                  ? translateGlobal('action_dropdown_time.disable_after')
                  : translateGlobal('action_dropdown_time.enable_for'),
                data: enableUntilData,
                onClick: onEnableUntilClick,
              },
              {
                text: translate('table_actions.remove'),
                onClick: onRemoveClick,
              },
            ]}
          />
        )}
      </>
    );
  }
);

interface IUnapprovedSystemActionMenuProps {
  isLoading: boolean;
  record: IUnapprovedSystem;
}

export const USystemsActionDropdown = memo(
  ({ isLoading, record, ...rest }: IUnapprovedSystemActionMenuProps) => {
    const dispatch = useDispatch();

    const { translate } = useTranslations('systems');

    const onApproveClick = useCallback(() => {
      dispatch(approveSystem({ systemId: record.systemId }));
    }, [dispatch, record.systemId]);

    const onRejectClick = useCallback(() => {
      dispatch(declineSystem({ systemId: record.systemId }));
    }, [dispatch, record.systemId]);

    const onEditClick = useCallback(() => {
      dispatch(loadUnapprovedSystemDetails({ systemId: record.systemId, mode: 'edit' }));
    }, [dispatch, record.systemId]);

    return (
      <>
        {isLoading ? (
          <Skeleton />
        ) : (
          <ActionDropdown
            onContainerClick={(event: SyntheticEvent) => event.stopPropagation()}
            {...rest}
            data={[
              {
                text: translate('table_actions.edit'),
                onClick: onEditClick,
              },
              {
                text: translate('table_actions.approve'),
                onClick: onApproveClick,
              },
              {
                text: translate('table_actions.reject'),
                onClick: onRejectClick,
              },
            ]}
          />
        )}
      </>
    );
  }
);
