// libs
import styled, { css } from 'styled-components';

// components
import { Radio } from 'antd';
import { ControllableIcon } from 'components';
import { IconQuestionCircle } from 'assets/svg';

export const StyledGroup = styled(Radio.Group)`
  width: 100%;
  margin-bottom: 2rem;
  display: flex;

  .ant-radio-button-wrapper {
    box-shadow: none !important;

    flex: 0 1 50%;
    padding: 0.75rem;
    height: auto;
    border-color: ${({ theme }) => theme.palette.fills.strokeMedium};

    :first-child {
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
    }
    :last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
    }
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: ${({ theme }) => theme.palette.fills.primary};
  }

  .ant-radio-button-wrapper.ant-radio-button-wrapper-checked,
  .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
    background-color: ${({ theme }) => theme.palette.background.hover};
    border-color: ${({ theme }) => theme.palette.fills.stroke} !important;
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: ${({ theme }) => theme.palette.fills.stroke};
  }
`;

export const StyledButton = styled(Radio.Button)``;

export const StyledOptionWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  position: relative;
`;

export const StyledControllableIcon = styled(ControllableIcon)`
  margin-right: 0.75rem;
`;

export const StyledOptionDescription = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const StyledIconQuestionCircle = styled(IconQuestionCircle)`
  width: 1rem;
  height: 1rem;
  position: absolute;
  right: 0;
  top: 0;
`;

export const tooltipCss = css`
  .ant-tooltip-inner {
    background-color: ${props => props.theme.palette.background.panel} !important;
    box-shadow: ${props => props.theme.palette.elevationShadows[200]};
    border: 0.0625rem solid ${props => props.theme.palette.background.iconBackground};
  }
`;

export const EditContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem;
  border: 0.0625rem solid ${({ theme }) => theme.palette.fills.strokeMedium};
  border-radius: 0.25rem;
`;

export const EditInfoContainer = styled.div`
  align-items: center;
  display: flex;
`;
