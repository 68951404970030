// components
import { PageTitle } from 'components';
import { TitleView } from './View';
import { TitleEdit } from './Edit';

// hooks
import { useSelector } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';

// selectors
import {
  selectAppDetailsTabType,
  selectAppDetailsTrustRequirementDescription,
  selectAppDetailsTrustRequirementId,
} from 'redux/selectors';

export const TitleBlock = () => {
  const { translate } = useTranslations('trust-requirements');

  const type = useSelector(selectAppDetailsTabType);
  const requirementId = useSelector(selectAppDetailsTrustRequirementId);
  const description = useSelector(selectAppDetailsTrustRequirementDescription);

  return (
    <>
      <PageTitle
        title={`${translate('page_title')}${
          description || requirementId ? ` | ${description || requirementId}` : ''
        }`}
      />
      {type === 'trust-requirement-view' && <TitleView />}
      {(type === 'trust-requirement-edit' || type === 'trust-requirement-create') && <TitleEdit />}
    </>
  );
};
