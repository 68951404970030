// libs
import { put, select } from 'typed-redux-saga';

// selectors
import { selectAppDetailsEnrolmentKeyId } from 'redux/selectors';

// actions
import {
  closeDetails,
  setModalContent,
  setModalIsLoading,
  pushNotification,
} from 'redux/actions/app';
import { setEnrolmentKeysShouldUpdate } from 'redux/actions/enrolment-keys';

// types
import { IActionPayload, IDeleteEnrolmentKeysBulkParams, INotificationType } from 'types';

// utils
import { ApiTypeCaller } from '../../api/utils';
import { translator } from 'utils';

export function* deleteEnrolmentKeysSaga({
  payload,
}: IActionPayload<IDeleteEnrolmentKeysBulkParams>) {
  yield put(setModalIsLoading(true));

  const selectedEnrolmentKeyId = yield* select(selectAppDetailsEnrolmentKeyId);

  if (selectedEnrolmentKeyId !== undefined && payload.keyIds.includes(selectedEnrolmentKeyId)) {
    yield* put(closeDetails({}));
  }

  const { success, result } = yield* ApiTypeCaller('deleteEnrolmentKeys', payload);

  if (success && result) {
    const translate = translator('notifications');

    yield put(setEnrolmentKeysShouldUpdate());
    yield put(setModalContent({ type: null, data: null }));
    yield put(
      pushNotification({
        showType: INotificationType.Success,
        description: translate('enrolment_keys_removed', {
          keyId: result.id,
          name: result.description,
        }),
      })
    );
  }
  yield put(setModalIsLoading(false));
}
