// libs
import { css } from 'styled-components';

// components
import { EmptyAcls } from './Empty';
import { CustomTable } from 'components';

// hooks
import { useSelector } from 'react-redux';
import { useColumnsView } from './Columns';

// selectors
import { selectAppDetailsPolicyAcls } from 'redux/selectors';

export const tableCss = css`
  td {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
    vertical-align: text-top;
  }

  cursor: default;

  tbody tr {
    cursor: default;
  }
`;

export const ReceiverAccessControlsView = () => {
  const acls = useSelector(selectAppDetailsPolicyAcls);

  const columns = useColumnsView();

  return (
    <>
      {acls?.length ? (
        <CustomTable
          data-test="policy-details-acls-table"
          withBorder={false}
          columns={columns}
          headerHeight="2.375rem"
          rowHeight="auto"
          css={tableCss}
          rowKey="id"
          dataSource={acls}
        />
      ) : (
        <EmptyAcls />
      )}
    </>
  );
};
