export type OSTypes = 'Linux' | 'Windows' | 'RaspberryPi' | 'Darwin' | 'iOS' | 'Android';

export type IIconState = 'default' | 'muted' | 'active';

export interface IInstallWindowsJson {
  ProductName: string;
  DocumentHash: null | string;
  DocumentSignature: null | string;
  TimeToLive: 60;
  ReleaseVersions: {
    MajorVersion: number;
    MinorVersion: number;
    BuildVersion: number;
    RevisionVersion: number;
    ReleaseType: string;
    ReleaseDate: number;
    HardStop: number;
    TargetEnvironment: string;
    Packages: {
      Platform: string;
      Architecture: string;
      Url: string;
      Hash: string;
      HashAlg: string;
    }[];
    ReleaseNotes: null;
  }[];
}

export enum OSType {
  Windows = 'Windows',
  Linux = 'Linux',
  MacOS = 'MacOS',
  Docker = 'Docker',
  Kubernetes = 'Kubernetes',
  Android = 'Android',
  IOS = 'iOS',
}
