import styled from 'styled-components';
import { IconExternalLink } from 'assets/svg';

export const LinkContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  width: 100%;
`;

export type ThemeMap = {
  color: string;
  colorHover: string;
  colorFocus: string;
  colorPressed: string;
};

interface Props {
  disabled: boolean;
  width?: string;
  styles: ThemeMap;
}

export const ExternalLinkContainer = styled.a<Props>`
  display: inline-flex;
  white-space: nowrap;
  align-items: center;
  width: ${({ width }) => (width ? width : 'fit-content')};

  span {
    color: ${({ disabled, theme, styles }) =>
      disabled ? theme.palette.fonts.disabled : styles.color};
  }
  svg path {
    fill: ${({ disabled, theme, styles }) =>
      disabled ? theme.palette.fonts.disabled : styles.color};
  }

  &:hover {
    span {
      color: ${({ styles }) => styles.colorHover};
    }
    svg path {
      fill: ${({ styles }) => styles.colorHover};
    }
  }

  &:focus {
    span {
      color: ${({ styles }) => styles.colorFocus};
    }
    svg path {
      fill: ${({ styles }) => styles.colorFocus};
    }
  }

  &:active {
    span {
      color: ${({ styles }) => styles.colorPressed};
    }
    svg path {
      fill: ${({ styles }) => styles.colorPressed};
    }
  }
`;

export const StyledExternalLinkIcon = styled(IconExternalLink)`
  margin-left: 0.25rem;
  path {
    fill: ${({ theme }) => theme.palette.icons.primary};
  }
`;
