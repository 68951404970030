// Lib
import { css } from 'styled-components';
import { useEffect, useState, useRef } from 'react';

// Components
import {
  CustomTable,
  ColumnTitle,
  CustomButton,
  Loader,
  Input,
  CustomButtonLink,
} from 'components';
import { CustomMessage } from 'components';
import { IconModalDelete } from 'assets/svg';
import { ModalFooter, ModalHeader, ModalScrollablePart } from 'components/Modal';
import { CustomText } from 'typography/Text';
import { ConnectedFrom, Description, Hostname } from './Columns';
import { tableStyles } from './styled';

// Hooks
import { useSelector, useDispatch } from 'react-redux';
import { useSubmitOnEnter } from 'hooks/useSubmitOnEnter';
import { useTranslations } from 'hooks/useTranslations';

// Selectors
import { selectModalData, selectModalIsLoading } from 'redux/selectors';

// Actions
import { setModalContent } from 'redux/actions/app';
import { revokeSystems } from 'redux/actions/systems';

// Types
import { ISystemSummaryModel } from 'types';

// constants
import { REMOVE_MODAL_CONFIRMATION_TEXT } from 'appConstants';

export const RemoveEnrolledModal = () => {
  const dispatch = useDispatch();

  const { translate } = useTranslations('systems');

  const [enabled, setEnabled] = useState(false);
  const [inputText, setInputText] = useState('');

  const isLoading = useSelector(selectModalIsLoading);
  const systems = useSelector(selectModalData) as ISystemSummaryModel[];

  const isButtonEnabled = enabled && !isLoading;

  const inputRef = useRef<HTMLInputElement>(null);

  const onClose = () => {
    dispatch(setModalContent({ type: null, data: null }));
  };

  const columns = [
    {
      title: <ColumnTitle>{translate('description')}</ColumnTitle>,
      dataIndex: 'description',
      render: (description: string, { isEnabled }: ISystemSummaryModel) => (
        <Description {...{ description, isEnabled }} />
      ),
      ellipsis: true,
    },
    {
      title: <ColumnTitle>{translate('machine_name')}</ColumnTitle>,
      dataIndex: 'hostname',
      render: (hostname: string, { isEnabled }: ISystemSummaryModel) => (
        <Hostname hostname={hostname} isEnabled={isEnabled} />
      ),
      ellipsis: true,
    },
    {
      title: <ColumnTitle>{translate('connection')}</ColumnTitle>,
      dataIndex: 'connectedFrom',
      render: (_: string, { isEnabled, connectedFrom, state }: ISystemSummaryModel) => (
        <ConnectedFrom {...{ connectedFrom, state, isEnabled }} />
      ),
    },
  ];

  const onRemove = () => {
    dispatch(revokeSystems({ systemIds: systems.map(system => system.systemId) }));
  };

  useEffect(() => {
    if (inputText === REMOVE_MODAL_CONFIRMATION_TEXT) {
      setEnabled(true);
    } else {
      if (enabled) setEnabled(false);
    }
  }, [inputText, enabled]);

  useEffect(() => {
    inputRef?.current?.focus();
  }, [inputRef]);

  useSubmitOnEnter({ callback: isButtonEnabled ? onRemove : () => {} });

  return (
    <div data-test="approved-systems-remove-modal">
      <ModalHeader
        icon={<IconModalDelete width="2.5rem" height="2.5rem" />}
        title={translate('modal.remove.title', { count: systems?.length || 0 })}
        withCloseButton={true}
        onClose={onClose}
      />
      <ModalScrollablePart>
        <CustomMessage
          type="error"
          message={translate('modal.remove.warning')}
          css={css`
            margin-bottom: 2rem;
          `}
        />
        <CustomText
          type="sub-heading-2"
          css={css`
            display: block;
            margin-bottom: 0.5rem;
          `}
        >
          {translate('modal.remove.selected_systems')} ({systems?.length || 0})
        </CustomText>
        <CustomTable
          elevated={false}
          rowHeight="2.375rem"
          css={tableStyles}
          rowKey="systemId"
          columns={columns}
          dataSource={systems}
        />
        <Input
          ref={inputRef}
          data-test="approved-systems-remove-modal-confirm-input-wrapper"
          type="text"
          name="yescheck"
          label={translate('modal.remove.confirm_cta')}
          value={inputText}
          maxLength={128}
          placeholder={translate('modal.remove.confirm_placeholder')}
          onChange={e => setInputText(e.target.value.toUpperCase())}
          containerCss={css`
            margin-bottom: 0.75rem;
          `}
        />
      </ModalScrollablePart>
      <ModalFooter>
        <CustomButtonLink size="large" onClick={onClose}>
          {translate('modal.remove.actions.cancel')}
        </CustomButtonLink>
        <CustomButton
          data-test="approved-systems-remove-submit"
          width="6.75rem"
          size="large"
          kind={isButtonEnabled ? 'primaryDanger' : 'disabled'}
          onClick={onRemove}
        >
          {isLoading ? <Loader /> : translate('modal.remove.actions.remove')}
        </CustomButton>
      </ModalFooter>
    </div>
  );
};
