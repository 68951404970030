// libs
import { css } from 'styled-components';
import { useEffect, useState, useRef, useCallback } from 'react';

// components
import { CustomButton, Loader, Input, CustomButtonLink, CustomTag } from 'components';
import { CustomMessage } from 'components';
import { IconModalDelete } from 'assets/svg';
import { ModalFooter, ModalHeader, ModalScrollablePart } from 'components/Modal';
import { Table } from './Table';

// hooks
import { useSelector, useDispatch } from 'react-redux';
import { useSubmitOnEnter } from 'hooks/useSubmitOnEnter';
import { useTranslations } from 'hooks/useTranslations';

// selectors
import { selectModalData, selectModalIsLoading } from 'redux/selectors';

// actions
import { setModalContent } from 'redux/actions/app';

// types
import { IModalDeleteTags } from 'types';
import { deleteTag, deleteTags, extendTagsModalConnections } from 'redux/actions/tags';

// constants
import { REMOVE_MODAL_CONFIRMATION_TEXT } from 'appConstants';

export const DeleteTagsModal = () => {
  const dispatch = useDispatch();

  const { translate } = useTranslations('tags');

  const [enabled, setEnabled] = useState(false);
  const [inputText, setInputText] = useState('');
  const [deletingMultiple, setDeletingMultiple] = useState<boolean>(false);

  const isLoading = useSelector(selectModalIsLoading);
  const modalData = useSelector(selectModalData) as IModalDeleteTags['data'] | null;
  const tags = modalData?.records;
  const connections = modalData?.connections;

  const isButtonEnabled = enabled && !isLoading;

  const inputRef = useRef<HTMLInputElement>(null);

  const onClose = useCallback(() => {
    dispatch(setModalContent({ type: null, data: null }));
  }, [dispatch]);

  const onDelete = useCallback(() => {
    const tagsToDelete = tags?.map(tag => tag.ref);

    if (!tagsToDelete) return;

    if (tagsToDelete.length > 1) {
      dispatch(deleteTags({ tags: tagsToDelete }));
    } else {
      dispatch(deleteTag({ tagOrRef: tagsToDelete[0] }));
    }
  }, [dispatch, tags]);

  const fetchData = useCallback(() => {
    tags &&
      connections &&
      Object.keys(connections)?.length === 0 &&
      dispatch(extendTagsModalConnections({ tags }));
  }, [dispatch, connections, tags]);

  const checkIfConnectionsEmpty = useCallback(() => {
    if (connections && Object.keys(connections)?.length !== 0) {
      for (const [, value] of Object.entries(connections)) {
        if (value?.length > 0) {
          return false;
        }
      }
    }

    return true;
  }, [connections]);

  const getWarning = useCallback(() => {
    if (connections && checkIfConnectionsEmpty()) {
      if (deletingMultiple) {
        return 'modal.remove.warning.no_references_multiple';
      }
      return 'modal.remove.warning.no_references';
    }

    if (deletingMultiple) {
      return 'modal.remove.warning.multiple';
    }

    return 'modal.remove.warning.single';
  }, [deletingMultiple, connections, checkIfConnectionsEmpty]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (inputText === REMOVE_MODAL_CONFIRMATION_TEXT) {
      setEnabled(true);
    } else {
      if (enabled) setEnabled(false);
    }
  }, [inputText, enabled]);

  useEffect(() => {
    inputRef?.current?.focus();
  }, [inputRef]);

  useEffect(() => {
    setDeletingMultiple(tags && tags?.length > 1 ? true : false);
  }, [tags, setDeletingMultiple]);

  useSubmitOnEnter({ callback: isButtonEnabled ? onDelete : () => {} });

  return (
    <div data-test="tags-delete-modal">
      <ModalHeader
        icon={<IconModalDelete width="2.5rem" height="2.5rem" />}
        title={
          deletingMultiple
            ? translate('modal.remove.title_multiple')
            : translate('modal.remove.title')
        }
        withCloseButton={true}
        onClose={onClose}
      />
      <ModalScrollablePart>
        <div
          css={css`
            margin-bottom: 1rem;
            > span {
              margin-bottom: 0.5rem;
              margin-right: 0.5rem;
            }
          `}
        >
          {tags?.map(tag => (
            <CustomTag key={tag.ref} tag={tag} />
          ))}
        </div>
        <CustomMessage
          type="error"
          message={translate(getWarning())}
          css={css`
            margin-bottom: 1rem;
          `}
        />
        {checkIfConnectionsEmpty() ? null : (
          <Table
            deletingMultiple={deletingMultiple}
            withEmptyState={true}
            connections={connections}
          />
        )}
        <Input
          ref={inputRef}
          data-test="tags-delete-modal-confirm-input-wrapper"
          type="text"
          name="yescheck"
          label={translate('modal.remove.confirm_cta')}
          value={inputText}
          maxLength={128}
          placeholder={translate('modal.remove.confirm_placeholder')}
          onChange={e => setInputText(e.target.value.toUpperCase())}
          containerCss={css`
            margin-bottom: 0.75rem;
          `}
        />
      </ModalScrollablePart>
      <ModalFooter>
        <CustomButtonLink size="large" onClick={onClose}>
          {translate('modal.remove.actions.cancel')}
        </CustomButtonLink>
        <CustomButton
          data-test="tags-delete-submit"
          width="6.75rem"
          size="large"
          kind={isButtonEnabled ? 'primaryDanger' : 'disabled'}
          onClick={onDelete}
        >
          {isLoading ? <Loader /> : translate('modal.remove.actions.remove')}
        </CustomButton>
      </ModalFooter>
    </div>
  );
};
