import styled from 'styled-components';
import { IconSettingsUpgradeBusiness } from 'assets/svg';

export const UpgradePlanContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${({ theme }) => theme.palette.background.iconBackground};
  border-radius: 0.25rem;
  margin-top: 1.25rem;
`;

export const StyledUpgradeToBusinessIcon = styled(IconSettingsUpgradeBusiness)`
  margin-bottom: 2rem;
`;
