import styled from 'styled-components';

export type MessageStyle = {
  background: string;
  border: string;
  Icon: React.ReactNode | null;
};

export const MessageContainer = styled('div')<Partial<MessageStyle>>`
  width: 100%;
  border-radius: 0.25rem;
  padding: 0.5rem 1rem;
  display: flex;
  background-color: ${props => props.background};
  border: 0.0625rem solid ${props => props.border};
  & svg {
    margin-top: 0.125rem;
    margin-right: 0.5rem;
  }
`;
