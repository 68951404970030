// libs
import { useCallback, useEffect, useState } from 'react';

// components
import { Filter } from 'components';
import { FilterAndBulkActionsContainer, RecordsContainer } from './styled';
import { Table } from './Table';
import { BulkActions } from './BulkActions';

// hooks
import { useDispatch, useSelector } from 'react-redux';
import { useDnsRecordsEmptyText } from './useDnsRecordsEmptyText';
import { useParams } from 'react-router';

// selectors
import {
  selectAppDetailsDnsRecordId,
  selectAppDetailsTabType,
  selectDnsFirstZone,
  selectDnsRecordSearchKeys,
  selectDnsRecordsIsLoading,
  selectDnsRecordsTotal,
  selectDnsRecordsUpdateId,
  selectDnsZone,
  selectIsDnsModule,
  selectPathQuerySearch,
} from 'redux/selectors';

// actions
import {
  getDnsRecords,
  getRecordSearchKeys,
  setDnsRecords,
  setDnsRecordsShouldUpdate,
  setDnsZonesShouldUpdate,
} from 'redux/actions/dns';
import { closeDetails, loadDnsRecordDetails } from 'redux/actions/app';

// constants
import { DNS_RECORD_HOSTNAME_CHARACTERS_LIMIT, ITEMS_FOR_SKELETON } from 'appConstants';
import { initialDnsRecordsData } from 'redux/reducers/dns';

// types
import { IDnsParams, IRecord } from 'types/dns';

// utils
import { equals } from 'ramda';

export const emptyRecords = Array.from({ length: ITEMS_FOR_SKELETON }, (_el, index) => ({
  id: index,
})) as IRecord[];

export const Records = () => {
  const dispatch = useDispatch();

  const total = useSelector(selectDnsRecordsTotal);
  const updateId = useSelector(selectDnsRecordsUpdateId);
  const firstZone = useSelector(selectDnsFirstZone, equals);
  const isDnsModule = useSelector(selectIsDnsModule);
  const queryParamsSearch = useSelector(selectPathQuerySearch);
  const detailsType = useSelector(selectAppDetailsTabType);
  const selectedRecordId = useSelector(selectAppDetailsDnsRecordId, equals);
  const recordsIsLoading = useSelector(selectDnsRecordsIsLoading);
  const searchKeys = useSelector(selectDnsRecordSearchKeys);

  const { zoneId: zoneIdParam, recordId } = useParams<keyof IDnsParams>();

  const zoneId: number | undefined =
    zoneIdParam === undefined ? firstZone?.id : Number(zoneIdParam);

  const zone = useSelector(selectDnsZone({ zoneId }));

  const [page, setPage] = useState(0);
  const [searchValue, setSearchValue] = useState(queryParamsSearch || '');
  const [bulkSelectedRecords, setBulkSelectedRecords] = useState<IRecord[]>([]);

  useEffect(() => {
    if (isDnsModule && recordId !== undefined && zoneId !== undefined && zone !== undefined) {
      if (detailsType !== 'dns-record-view' && detailsType !== 'dns-record-edit') {
        dispatch(
          loadDnsRecordDetails({
            zoneId: zone.id,
            recordId: recordId,
            mode: 'view',
            zoneName: zone.name,
          })
        );
        return;
      }
      if (
        (detailsType === 'dns-record-view' || detailsType === 'dns-record-edit') &&
        Number(recordId) !== selectedRecordId
      ) {
        dispatch(
          loadDnsRecordDetails({
            zoneId: zone.id,
            recordId: recordId,
            mode: 'view',
            zoneName: zone.name,
          })
        );
        return;
      }
    }

    if ((recordId === undefined || zoneId === undefined) && detailsType === 'dns-record-view') {
      dispatch(closeDetails({ redirect: false }));
    }
  }, [detailsType, dispatch, isDnsModule, recordId, selectedRecordId, zone, zoneId]);

  const fetchData = useCallback(() => {
    if (zoneId !== undefined && !Number.isNaN(zoneId)) {
      dispatch(
        getDnsRecords({
          search: searchValue,
          page: page || 0,
          zoneId: zoneId,
        })
      );
    }
  }, [dispatch, page, searchValue, zoneId]);

  const onRefresh = useCallback(() => {
    dispatch(setDnsRecordsShouldUpdate());
    dispatch(setDnsZonesShouldUpdate());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getRecordSearchKeys());
  }, [dispatch]);

  useEffect(() => {
    fetchData();
  }, [dispatch, updateId, fetchData, zoneId]);

  // it's in this useEffect because if it would be
  // in useEffect on top, cleanup would fire on every rerender.
  // and dependancy of selectedZone?.id guarantees that
  // when we swith zone it fires up to clean record state,
  // so when we switch zone, on loading it would show
  // default amount of skeletons, rathen than
  // what was left of another record
  useEffect(() => {
    return () => {
      dispatch(setDnsRecords(initialDnsRecordsData));
    };
  }, [dispatch, zoneId]);

  useEffect(() => {
    setPage(0);
  }, [searchValue]);

  const { emptyText } = useDnsRecordsEmptyText({
    searchValue,
  });

  return (
    <RecordsContainer>
      <FilterAndBulkActionsContainer>
        {bulkSelectedRecords.length ? (
          <BulkActions
            data-test="dns-records-bulk-actions"
            bulkSelectedRecords={bulkSelectedRecords}
            setBulkSelectedRecords={setBulkSelectedRecords}
          />
        ) : (
          <Filter
            total={total}
            page={page}
            fetchData={onRefresh}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            searchMaxLength={DNS_RECORD_HOSTNAME_CHARACTERS_LIMIT}
            isLoading={recordsIsLoading}
            searchKeys={searchKeys}
          />
        )}
      </FilterAndBulkActionsContainer>
      <Table
        emptyText={emptyText}
        page={page}
        setPage={setPage}
        isRecordsLoading={recordsIsLoading}
        total={total}
        selectedRecordId={Number(recordId)}
        bulkSelectedRecords={bulkSelectedRecords}
        setBulkSelectedRecords={setBulkSelectedRecords}
      />
    </RecordsContainer>
  );
};
