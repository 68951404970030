import { MODAL_SCROLLABLE_PART_MAX_HEIGHT, MODAL_SCROLLABLE_PART_MIN_HEIGHT } from 'appConstants';
import styled from 'styled-components';

export const ModalContentContainer = styled.div<{ withMinHeight: boolean }>`
  overflow-y: auto;
  min-height: ${({ withMinHeight }) => withMinHeight && MODAL_SCROLLABLE_PART_MIN_HEIGHT};
  max-height: ${MODAL_SCROLLABLE_PART_MAX_HEIGHT};
  padding-left: 2.5rem;
  padding-right: 2.5rem;
`;
