// libs
import { css } from 'styled-components';

// components
import { Input } from 'components';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { BaseSyntheticEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// actions
import { updateTrustRequirementDetails } from 'redux/actions/app';

// selectors
import { selectAppDetailsTrustRequirementMicrosoftAuthorityNewTenantId } from 'redux/selectors';

// constants
import { USER_AUTHENTICATION_CONFIGURATION_TENANT_ID } from 'appConstants';

export const TenantIdEdit = () => {
  const dispatch = useDispatch();

  const { translate } = useTranslations('trust-requirements');

  const tenantId = useSelector(selectAppDetailsTrustRequirementMicrosoftAuthorityNewTenantId);

  const onChange = (event: BaseSyntheticEvent) => {
    dispatch(
      updateTrustRequirementDetails({
        settings: {
          configuration: { [USER_AUTHENTICATION_CONFIGURATION_TENANT_ID]: event.target.value },
        },
      })
    );
  };

  return (
    <Input
      stateMessageCss={css`
        display: none;
      `}
      placeholder={translate(
        'details.types.UserAuthentication.authorities.azure.tenant_id_placeholder'
      )}
      value={tenantId}
      onChange={onChange}
    />
  );
};
