// sagas
import { select } from 'typed-redux-saga';
import { selectPathName, selectRouteAction } from 'redux/selectors';
import { selectAppIsLoaded } from 'redux/selectors';
import { history } from 'utils';

export function* handleGoBackSaga() {
  const pathname = yield* select(selectPathName);
  const isLoaded = yield* select(selectAppIsLoaded);
  const action = yield* select(selectRouteAction);

  if (pathname === '/oidc-redirect' && action === 'POP' && isLoaded) history.back();
}
