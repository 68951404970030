// libs
import { css, useTheme } from 'styled-components';

// components
import { GettingStartedGridItem } from '../../styled';
import { SectionHeader } from '../../SectionHeader';
import {
  MainContentContainer,
  List,
  ListItem,
  QuickStartContainer,
  QuickStartTextContainer,
  QuickStartButtonContainer,
} from './styled';
import { CustomButton, InternalLink } from 'components';
import { CustomText } from 'typography/Text';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { useSelector } from 'react-redux';

// seoectors
import { selectCurrentOrganisationInfo } from 'redux/selectors';

// consts
import { LINK_TO_QUICK_START_GUIDE, ROUTE_PATHS } from 'appConstants';

export const GettingStartedSection = () => {
  const { translate } = useTranslations('landing-page');
  const {
    palette: { fonts },
  } = useTheme();
  const orgInfo = useSelector(selectCurrentOrganisationInfo);

  return (
    <GettingStartedGridItem>
      <MainContentContainer>
        <SectionHeader title={translate('getting_started_section.title')} />
        <List>
          <ListItem
            css={css`
              margin-top: 1.625rem;
            `}
          >
            <InternalLink size="medium" to={`/org/${orgInfo.id}${ROUTE_PATHS['new-system']}`}>
              {translate('getting_started_section.enrol_a_new_system_title')}
            </InternalLink>
            <CustomText
              type="body-2"
              color={fonts.bodyLight}
              css={css`
                margin-top: 0.5rem;
              `}
            >
              {translate('getting_started_section.enrol_a_new_system_description')}
            </CustomText>
          </ListItem>
        </List>
      </MainContentContainer>

      <QuickStartContainer>
        <QuickStartTextContainer>
          <CustomText
            type="sub-heading-1"
            color={fonts.primaryButton}
            css={css`
              display: block;
            `}
          >
            {translate('getting_started_section.quick_start_guide_title')}
          </CustomText>
          <CustomText
            type="body-2"
            color={fonts.primaryButton}
            css={css`
              display: block;
              margin-top: 0.5rem;
            `}
          >
            {translate('getting_started_section.quick_start_guide_description')}
          </CustomText>
        </QuickStartTextContainer>
        <QuickStartButtonContainer>
          <a target="_blank" rel="noopener noreferrer" href={LINK_TO_QUICK_START_GUIDE}>
            <CustomButton kind="primaryHoverLight" size="large">
              {translate('getting_started_section.quick_start_guide_link')}
            </CustomButton>
          </a>
        </QuickStartButtonContainer>
      </QuickStartContainer>
    </GettingStartedGridItem>
  );
};
