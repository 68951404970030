// libs
import { put, select } from 'typed-redux-saga';

// actions
import { setCurrentOrganisation } from 'redux/actions/account';

// selectors
import { selectPathName, selectAccountOrganisations } from 'redux/selectors';

// constants
import { ORG_ID_KEY } from 'appConstants/localstorage';

// utils
import { getOrgIdFromUrl } from 'utils';

export function* setCurrentOrganisationSaga() {
  let currentOrgId: string | null = null;

  // Check URL
  const pathName = yield* select(selectPathName);
  /* Alternative regex logic
  const urlOrgMatch = pathName.match(urlOrgId);
  if (urlOrgMatch) {
    const urlOrgId = urlOrgMatch[1];
    if (urlOrgId) currentOrgId = urlOrgId;
  }
  */
  currentOrgId = getOrgIdFromUrl(pathName);

  const accountOrgs = yield* select(selectAccountOrganisations);

  // Check localStorage
  if (!currentOrgId) {
    const localStoreOrgId = localStorage.getItem(ORG_ID_KEY);
    if (localStoreOrgId && accountOrgs.find(({ orgId }) => orgId === localStoreOrgId))
      currentOrgId = localStoreOrgId;
  }

  // Pick Owner orgId from account organisations
  // if It's external account we might now have Owner
  // so we pick Admin
  if (!currentOrgId) {
    const ownerOrg = accountOrgs.find(({ role }) => role === 'Owner');
    if (ownerOrg) {
      currentOrgId = ownerOrg.orgId;
    } else {
      currentOrgId = accountOrgs.find(({ role }) => role === 'Admin')?.orgId || null;
    }
  }

  if (currentOrgId) {
    yield put(setCurrentOrganisation(currentOrgId));
  }
}
