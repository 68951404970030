// libs
import styled, { css } from 'styled-components';

// components
import { ColumnsType } from 'antd/lib/table';
import { Title } from './Title';
import { translations } from 'types';
import { TFunction } from 'react-i18next/.';
import { IconEnrolmentKeyDetailsDeleteIpConstraint } from 'assets/svg';
import { Tooltip } from 'components';
import { RangeField } from './RangeField';
import { DescriptionField } from './DescriptionField';
import { CustomText } from 'typography/Text';

interface ICols {
  translate: TFunction<translations>;
  rangeChange: (newRange: string, index: number, isValid: boolean, id?: string) => void;
  descriptionChange: (newDescription: string, index: number) => void;
  handleRemoveConstraint: (index: number, id?: string) => void;
}

const IconCloseContainer = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const columnsEdit = ({
  translate,
  rangeChange,
  descriptionChange,
  handleRemoveConstraint,
}: ICols): ColumnsType<{
  id?: string;
  range?: string;
  description?: string | null;
}> => [
  {
    title: <Title>{translate('details.ip_address')}</Title>,
    dataIndex: 'range',
    width: '55%',
    render: (range: string, record, index) => {
      return (
        <RangeField
          id={record.id}
          range={range}
          index={index}
          rangeChange={rangeChange}
          translate={translate}
        />
      );
    },
    ellipsis: true,
  },
  {
    title: <Title>{translate('details.description')}</Title>,
    dataIndex: 'description',
    width: '35%',
    render: (description: string | null, record, index) => (
      <DescriptionField
        description={description}
        descriptionChange={descriptionChange}
        index={index}
        translate={translate}
      ></DescriptionField>
    ),
    ellipsis: true,
  },
  {
    render: (_field, record, index) => (
      <Tooltip
        placement="top"
        autoAdjustOverflow={false}
        overlay={
          <CustomText
            type="body-2"
            css={css`
              color: ${({ theme }) => theme.palette.fonts.primaryButton};
            `}
          >
            {translate('details.remove_a_constraint_tooltip')}
          </CustomText>
        }
      >
        <IconCloseContainer
          onClick={() => {
            handleRemoveConstraint(index, record.id);
          }}
        >
          <IconEnrolmentKeyDetailsDeleteIpConstraint />
        </IconCloseContainer>
      </Tooltip>
    ),
    ellipsis: true,
    width: '10%',
  },
];
