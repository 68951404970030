// hooks
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// components
import { BlockWrapper, ContentWrapper } from '../../styled';
import { DescriptionPane, BillingMessage, Plans } from './components';

// actions
import { getOrganisationPlans } from 'redux/actions/organisation';
import { getBillingPortal } from 'redux/actions/payment';

// selectors
import {
  selectCurrentOrganisationBillingDisableUpdate,
  selectCurrentOrganisationInfo,
  selectCurrentOrganisationIsOwner,
  selectCurrentOrganisationPlan,
  selectOrganisationPlansTypes,
  selectPathHash,
  selectPathName,
} from 'redux/selectors';

// constants
import { PLANS_N_SUBS_ANCHOR, STARTER_PLAN } from 'appConstants';
import { CurrentPlan } from './components/CurrentPlan';

export const PlansNSubs = () => {
  const dispatch = useDispatch();

  const hash = useSelector(selectPathHash);
  const orgInfo = useSelector(selectCurrentOrganisationInfo);
  const isOwner = useSelector(selectCurrentOrganisationIsOwner);
  const pathName = useSelector(selectPathName);
  const plans = useSelector(selectOrganisationPlansTypes);
  const disableUpdate = useSelector(selectCurrentOrganisationBillingDisableUpdate);
  const currentPlan = useSelector(selectCurrentOrganisationPlan);

  const plansNSubsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    dispatch(getOrganisationPlans());
    if (orgInfo.plan !== STARTER_PLAN && isOwner)
      dispatch(
        getBillingPortal({
          returnUrl: `${window.location.origin}${pathName}#${PLANS_N_SUBS_ANCHOR}`,
        })
      );
  }, [orgInfo, dispatch, pathName, isOwner]);

  useEffect(() => {
    if (hash === `#${PLANS_N_SUBS_ANCHOR}` && plansNSubsRef.current !== null) {
      plansNSubsRef.current.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [plansNSubsRef, hash]);

  if (!plans) return null;

  return (
    <BlockWrapper mt="2.5rem" id={PLANS_N_SUBS_ANCHOR} ref={plansNSubsRef}>
      <BillingMessage />
      <DescriptionPane />
      <ContentWrapper>
        {!disableUpdate && currentPlan !== STARTER_PLAN && <CurrentPlan />}
        <Plans />
      </ContentWrapper>
    </BlockWrapper>
  );
};
