// components
import { Details } from 'components';
import { css } from 'styled-components';
import {
  TopMenuButtons,
  Header,
  Overview,
  CancelSave,
  Connections,
  TrustRequirements,
} from './Sections';

export const TagsDetails = () => {
  return (
    <Details.Pane data-test="tag-details-content">
      <Details.ContentWrapper>
        <Details.ScrollPaddingContainer
          wide={true}
          css={css`
            padding-bottom: 0px;
          `}
        >
          <TopMenuButtons />
          <Header />
          <Overview />
          <TrustRequirements />
          <Connections />
        </Details.ScrollPaddingContainer>
      </Details.ContentWrapper>
      <CancelSave />
    </Details.Pane>
  );
};
