// libs
import styled, { css, CSSProp } from 'styled-components';

// components
import { CreateTrustRequirement } from './styled';
import { IconArrowLeft } from 'assets/svg';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEmptyText } from 'hooks/useEmptyText';

// actions
import { createTrustRequirementSideMenu } from 'redux/actions/app';

// selectors
import { selectTrustRequirementsIsLoading } from 'redux/selectors';

// constants
import { TRUST_REQUIREMENTS_MODULE } from 'appConstants';

type Props = {
  searchValue: string;
  css?: CSSProp;
  withLink?: boolean;
  fullWidth?: boolean;
};

export const ArrowRightIcon = styled(IconArrowLeft)`
  transform: rotate(180deg);
`;

export const useTrustRequirementsEmptyText = ({
  searchValue,
  css: cssAll,
  withLink = true,
  fullWidth = true,
}: Props) => {
  const dispatch = useDispatch();

  const { translate } = useTranslations('trust-requirements');

  const isLoading = useSelector(selectTrustRequirementsIsLoading);

  const noDataText = useMemo(
    () => ({
      title: translate('empty.no_data.title'),
      description: translate('empty.no_data.description'),
    }),
    [translate]
  );
  const noDataFoundText = useMemo(
    () => ({
      title: translate('empty.no_data_found.title'),
      description: translate('empty.no_data_found.description'),
    }),
    [translate]
  );

  const createNewKey = useCallback(() => dispatch(createTrustRequirementSideMenu()), [dispatch]);

  const additionalText = useMemo(
    () => (
      <CreateTrustRequirement onClick={createNewKey}>
        {translate('empty.link')} <ArrowRightIcon />
      </CreateTrustRequirement>
    ),
    [createNewKey, translate]
  );

  const { emptyText } = useEmptyText({
    searchValue,
    noDataText,
    noDataFoundText,
    isLoading,
    additionalText: withLink ? additionalText : undefined,
    module: TRUST_REQUIREMENTS_MODULE,
    css: cssAll,
    noDataCss: fullWidth
      ? css`
          * {
            max-width: 100%;
          }
        `
      : undefined,
  });

  return { emptyText };
};
