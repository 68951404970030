import { ROUTE_PATHS } from 'appConstants';
import { MainState } from 'types';
import { createSelector } from '@reduxjs/toolkit';

export const selectTagsNextPage = (state: MainState) => state.Tags.metadata.nextPage;
export const selectTagsItems = (state: MainState) => state.Tags.items;
export const selectTagsLoading = (state: MainState) => state.Tags.isLoading;
export const selectTagsQuery = (state: MainState) => state.Tags.query;
export const selectTagsUpdateRef = (state: MainState) => state.Tags.updateId;
export const selectTagsTotal = (state: MainState) => state.Tags.metadata?.total;
export const selectTagsIsLoading = (state: MainState) => state.Tags.isLoading;
export const selectTagsSearchKeys = (state: MainState) => state.Tags.searchKeys;

export const selectTagsResponse = createSelector(
  selectTagsItems,
  selectTagsNextPage,
  selectTagsQuery,
  (items, nextPage, query) => ({ items, nextPage, query })
);

export const selectIsTagsModule = ({
  router: {
    location: { pathname },
  },
  Account: { currentOrg },
}: MainState) => {
  return pathname.includes(`${currentOrg}${ROUTE_PATHS.tags}`);
};

export const selectTagssUpdateRef = (state: MainState) => state.Tags.updateId;

export const selectAppDetailsTagData = ({ App: { detailsTab } }: MainState) => {
  if (detailsTab.type === 'tag-view' || detailsTab.type === 'tag-edit') {
    return detailsTab.data;
  }
};

export const selectAppDetailsTag = ({ App: { detailsTab } }: MainState) => {
  if (detailsTab.type === 'tag-view' || detailsTab.type === 'tag-edit') {
    return detailsTab.data.tag;
  }
};

export const selectAppDetailsTagRef = ({ App: { detailsTab } }: MainState) => {
  if (detailsTab.type === 'tag-view' || detailsTab.type === 'tag-edit') {
    return detailsTab.data.ref;
  }
};

export const selectAppDetailsTagDescription = ({ App: { detailsTab } }: MainState) => {
  if (detailsTab.type === 'tag-view' || detailsTab.type === 'tag-edit') {
    return detailsTab.data.tag;
  }
};

export const selectAppDetailsTagKeys = ({ App: { detailsTab } }: MainState) => {
  if (detailsTab.type === 'tag-view' || detailsTab.type === 'tag-edit') {
    return detailsTab.data.keys;
  }
};

export const selectAppDetailsTagKeysItems = ({ App: { detailsTab } }: MainState) => {
  if (detailsTab.type === 'tag-view' || detailsTab.type === 'tag-edit') {
    return detailsTab.data.keysItems;
  }
};

export const selectAppDetailsTagSystems = ({ App: { detailsTab } }: MainState) => {
  if (detailsTab.type === 'tag-view' || detailsTab.type === 'tag-edit') {
    return detailsTab.data.systems;
  }
};

export const selectAppDetailsTagSystemsItems = ({ App: { detailsTab } }: MainState) => {
  if (detailsTab.type === 'tag-view' || detailsTab.type === 'tag-edit') {
    return detailsTab.data.systemsItems;
  }
};

export const selectAppDetailsTagDnsRecords = ({ App: { detailsTab } }: MainState) => {
  if (detailsTab.type === 'tag-view' || detailsTab.type === 'tag-edit') {
    return detailsTab.data.dnsRecords;
  }
};

export const selectAppDetailsTagDnsRecordItems = ({ App: { detailsTab } }: MainState) => {
  if (detailsTab.type === 'tag-view' || detailsTab.type === 'tag-edit') {
    return detailsTab.data.dnsRecordsItems;
  }
};

export const selectAppDetailsTagDnsZones = ({ App: { detailsTab } }: MainState) => {
  if (detailsTab.type === 'tag-view' || detailsTab.type === 'tag-edit') {
    return detailsTab.data.dnsZones;
  }
};

export const selectAppDetailsTagDnsZoneItems = ({ App: { detailsTab } }: MainState) => {
  if (detailsTab.type === 'tag-view' || detailsTab.type === 'tag-edit') {
    return detailsTab.data.dnsZonesItems;
  }
};

export const selectAppDetailsTagPolicies = ({ App: { detailsTab } }: MainState) => {
  if (detailsTab.type === 'tag-view' || detailsTab.type === 'tag-edit') {
    return detailsTab.data.policies;
  }
};

export const selectAppDetailsTagPoliciesItems = ({ App: { detailsTab } }: MainState) => {
  if (detailsTab.type === 'tag-view' || detailsTab.type === 'tag-edit') {
    return detailsTab.data.policiesItems;
  }
};

export const selectAppDetailsTagNewDescription = ({ App: { detailsTab } }: MainState) => {
  if (detailsTab.type === 'tag-create' || detailsTab.type === 'tag-edit') {
    return detailsTab.dataNew.tag;
  }
};

export const selectAppDetailsTagNotes = ({ App: { detailsTab } }: MainState) => {
  if (detailsTab.type === 'tag-edit' || detailsTab.type === 'tag-view') {
    return detailsTab.data.notes;
  }
};

export const selectAppDetailsTagNewNotes = ({ App: { detailsTab } }: MainState) => {
  if (detailsTab.type === 'tag-edit' || detailsTab.type === 'tag-create') {
    return detailsTab.dataNew.notes;
  }
};

export const selectAppDetailsTagColour = ({ App: { detailsTab } }: MainState) => {
  if (detailsTab.type === 'tag-view' || detailsTab.type === 'tag-edit') {
    return detailsTab.data.colour;
  }
};

export const selectAppDetailsTagNewColour = ({ App: { detailsTab } }: MainState) => {
  if (detailsTab.type === 'tag-edit' || detailsTab.type === 'tag-create') {
    return detailsTab.dataNew.colour;
  }
};

export const selectAppDetailsTagTrustRequirements = ({ App }: MainState) => {
  if (App.detailsTab.type === 'tag-view' || App.detailsTab.type === 'tag-edit') {
    return App.detailsTab.data.trustRequirements;
  }
};
export const selectAppDetailsTagNewTrustRequirements = ({ App }: MainState) => {
  if (App.detailsTab.type === 'tag-edit' || App.detailsTab.type === 'tag-create') {
    return App.detailsTab.dataNew.trustRequirements;
  }
};
