import styled from 'styled-components';

export const UserRow = styled.div`
  display: grid;
  grid-template-columns: 12fr 7fr 3fr 2fr;

  padding-top: 1rem;
  padding-bottom: 1rem;

  border-bottom: 0.0625rem solid ${({ theme }) => theme.palette.background.iconBackground};
`;

export const UserColumn = styled.div<{ col: number }>`
  display: flex;
  align-items: center;
  grid-column: ${({ col }) => col} / span 1;
`;

export const InviteCreateContainer = styled.div`
  min-height: 4.8125rem;
`;

export const InviteCreateRow = styled.div<{ error: boolean }>`
  display: grid;
  grid-template-columns: 16fr 8fr;

  padding-top: 1rem;
  padding-bottom: ${({ error }) => (error ? '0.5rem' : '0.75rem')};

  border-bottom: 0.0625rem solid ${({ theme }) => theme.palette.background.iconBackground};
`;

export const InviteCreateColumn = styled.div<{ col: number }>`
  display: flex;
  align-items: flex-start;
  grid-column: ${({ col }) => col} / span 1;

  :last-child {
    justify-content: flex-end;
  }
`;

export const InvitesCountContainer = styled.div`
  margin-top: 3rem;
  margin-bottom: 1rem;
`;

export const InviteRow = styled.div`
  display: grid;
  grid-template-columns: 19fr 3fr 2fr;

  padding-top: 1rem;
  padding-bottom: 1rem;

  border-bottom: 0.0625rem solid ${({ theme }) => theme.palette.background.iconBackground};
`;

export const InviteColumn = styled.div<{ col: number }>`
  display: flex;
  align-items: center;
  grid-column: ${({ col }) => col} / span 1;

  :last-child {
    justify-content: flex-end;
  }
`;
