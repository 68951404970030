import { Microsoft } from './Microsoft';
import { GenericOIDC } from './GenericOIDC';
import { Duo } from './Duo';
import { Okta } from './Okta';
import { JumpCloud } from './JumpCloud';
import { Google } from './Google';

export const Authorities = {
  Microsoft,
  Google,
  GenericOIDC,
  Duo,
  Okta,
  JumpCloud,
};
