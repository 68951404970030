import styled from 'styled-components';
import { StyledSubtitle } from 'typography/Text';

export const CustomStyledSubtitle = styled(StyledSubtitle)`
  display: block;
  margin-bottom: 1rem;
`;

export const OptionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
