import ReactDOM from 'react-dom/client';
import { StrictMode } from 'react';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import App from 'modules/App';
import { store, persistor } from './redux/store';
import reportWebVitals from './reportWebVitals';
import { HelmetProvider } from 'react-helmet-async';
import { ThemeProvider } from 'styled-components';

import { themes } from 'themes';
import './i18n';
import './index.css';
import { history } from 'utils/history';
import ConnectedRouter from 'components/Router';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <StrictMode>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <PersistGate loading={null} persistor={persistor}>
          <HelmetProvider>
            <ThemeProvider theme={themes.light}>
              <App />
            </ThemeProvider>
          </HelmetProvider>
        </PersistGate>
      </ConnectedRouter>
    </Provider>
  </StrictMode>
);

reportWebVitals();
