// components
import { CustomText } from 'typography/Text';
import { StyledGroup, StyledButton, StyledOptionWrapper } from './styled';

// hooks
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';
import styled, { useTheme } from 'styled-components';

// selectors
import {
  selectAppDetailsEnrolmentKeyAutoExpireAction,
  selectAppDetailsEnrolmentKeyAutoExpireNewAction,
} from 'redux/selectors';

// actions
import { updateEnrolmentKeyDetailsAutoExpire } from 'redux/actions/app';

// types
import { RadioChangeEvent } from 'antd';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const ExpirationActionEdit = () => {
  const dispatch = useDispatch();

  const { translate } = useTranslations('enrolment-keys');

  const {
    palette: { fills },
  } = useTheme();

  const selectedAction = useSelector(selectAppDetailsEnrolmentKeyAutoExpireAction);
  const newAction = useSelector(selectAppDetailsEnrolmentKeyAutoExpireNewAction);

  const displayedAction = selectedAction || newAction;

  const handleExpirationActionChange = useCallback(
    (event: RadioChangeEvent) => {
      dispatch(updateEnrolmentKeyDetailsAutoExpire({ expiryAction: event.target.value }));
    },
    [dispatch]
  );

  return (
    <Container>
      <StyledGroup
        defaultValue={displayedAction || 'Disable'}
        onChange={handleExpirationActionChange}
      >
        <StyledButton value="Disable">
          <StyledOptionWrapper data-test="enrolment-key-details-select-expiration-action">
            <CustomText type="body-2" color={displayedAction === 'Disable' ? fills.primary : ''}>
              {translate('details.auto_expire.expiration_action.options.disable')}
            </CustomText>
          </StyledOptionWrapper>
        </StyledButton>
        <StyledButton value="Delete">
          <StyledOptionWrapper data-test="enrolment-key-details-select-expiration-action">
            <CustomText type="body-2" color={displayedAction === 'Delete' ? fills.primary : ''}>
              {translate('details.auto_expire.expiration_action.options.remove')}
            </CustomText>
          </StyledOptionWrapper>
        </StyledButton>
      </StyledGroup>
    </Container>
  );
};
