import styled from 'styled-components';

export const AlertContainer = styled.div`
  position: absolute;
  left: 50%;
  top: 1.5rem;
  transform: translateX(-50%) translateY(-50%);
  height: 2rem;
  background-color: ${props => props.theme.palette.state.alertStroke};
  border-color: ${props => props.theme.palette.state.alertStroke};
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  width: 30rem;
  justify-content: space-between;
  filter: drop-shadow(0px 0px 1px rgba(60, 64, 73, 0.15))
    drop-shadow(0px 20px 32px rgba(60, 64, 73, 0.1));
`;
