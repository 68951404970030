// libs
import { SyntheticEvent, useCallback, memo, useMemo, useEffect } from 'react';

// hooks
import { useDispatch, useSelector } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';

// Components
import { CustomTable, TableSelectionContainer } from 'components';
import { TagsCols } from './Columns';

// utils
import { emptyTags } from '.';
import { equals } from 'ramda';

// actions
import { redirectControl } from 'redux/actions/app';

// selectors
import { selectTagsItems } from 'redux/selectors';

// Types
import { ITag } from 'types';
import { TableProps } from 'antd/lib/table';

// constants
import { ITEMS_PER_TABLE_PAGE } from 'appConstants/ui';

interface ITable {
  total: number;
  page: number;
  setPage: (page: number) => void;
  isLoading: boolean;
  bulkSelectedTags: ITag[];
  setBulkSelectedTags: (bulkSelectedTags: ITag[]) => void;
  selectedTag: string | undefined;
  emptyText?: React.ReactNode;
}

export const Table = memo(
  ({
    page,
    setPage,
    total,
    isLoading,
    bulkSelectedTags,
    setBulkSelectedTags,
    selectedTag,
    emptyText,
  }: ITable) => {
    const dispatch = useDispatch();

    const { translate } = useTranslations('tags');
    const { translate: translateGlobal } = useTranslations('global');

    const tags = useSelector(selectTagsItems);

    const onRowClick = useCallback(
      (tag: ITag) => {
        if (!isLoading) {
          {
            dispatch(redirectControl({ route: `/tags/${tag.tag}`, routePush: true }));
          }
        }
      },
      [dispatch, isLoading]
    );

    const columns = TagsCols({
      isLoading,
      translate,
      bulkSelectedTags,
      setBulkSelectedTags,
      selectedTag,
    });

    const rowSelectionRender = useCallback(
      (_checked: boolean, _record: ITag, _index: number, originNode: React.ReactNode) => {
        return (
          <TableSelectionContainer
            data-test="tag-table-selection"
            onClick={(event: SyntheticEvent) => event.stopPropagation()}
          >
            {originNode}
          </TableSelectionContainer>
        );
      },
      []
    );

    const rowSelection: TableProps<ITag>['rowSelection'] = useMemo(
      () => ({
        checkStrictly: true,
        onChange: (_, selectedTags: ITag[]) => {
          if (!isLoading) {
            setBulkSelectedTags(selectedTags);
          }
        },
        selectedRowKeys: bulkSelectedTags.map(e => e.tag),
        renderCell: rowSelectionRender,
        //@ts-ignore
        getCheckboxProps: () => ({
          'aria-label': translateGlobal('aria_labels.select_row'),
        }),
      }),
      [bulkSelectedTags, isLoading, rowSelectionRender, setBulkSelectedTags, translateGlobal]
    );

    // when we bulk select element and then remove it through action dropdown
    // bulkSelected elements doesn't update without this useEffect
    useEffect(() => {
      const newBulkSelectedTags = bulkSelectedTags.filter(bulkSelectedTag =>
        tags.some(tag => tag.tag === bulkSelectedTag.tag)
      );
      if (!equals(newBulkSelectedTags, bulkSelectedTags)) {
        setBulkSelectedTags(newBulkSelectedTags);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tags]);

    return (
      <CustomTable
        data-test="tags-table"
        locale={{ emptyText }}
        headerHeight="2.5rem"
        rowHeight="3.375rem"
        rowKey="tag"
        columns={columns}
        dataSource={isLoading && !tags.length ? emptyTags : tags}
        onRowClick={onRowClick}
        elevated={true}
        selectedRowKey={selectedTag}
        rowSelection={rowSelection}
        pagination={{
          showSizeChanger: false,
          hideOnSinglePage: true,
          pageSize: ITEMS_PER_TABLE_PAGE,
          position: ['bottomCenter'],
          total: total,
          defaultCurrent: page + 1,
          onChange: page => {
            setPage(page - 1);
          },
        }}
      />
    );
  },
  equals
);
