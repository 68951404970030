// Lib
import { put } from 'typed-redux-saga';

// Actions
import { setDnsRecords, setRecordsIsLoading } from 'redux/actions/dns';

// Types
import { IActionPayload, IGetDnsRecordsParams } from 'types';

// Utils
import { SyncQueryParams } from '../../utils';
import { ApiTypeCaller } from '../../api/utils';
import { omit, mergeRight } from 'ramda';

export function* getRecordsSaga({ payload }: IActionPayload<IGetDnsRecordsParams>) {
  yield put(setRecordsIsLoading(true));

  // zoneId is not query param
  // so it doens't go to SyncQueryParams
  const queryParams = omit(['zoneId'], payload);
  const syncQueryParams = yield* SyncQueryParams(queryParams);

  payload = mergeRight(payload, syncQueryParams);

  const { result, success } = yield* ApiTypeCaller('getDnsRecords', payload);

  if (success && result) yield put(setDnsRecords(result));
}
