import { getTimeZoneOffset } from 'utils';

const compareTimeZonesOffset = (a: any, b: any) => {
  if (a.offset < b.offset) {
    return -1;
  }
  if (a.offset > b.offset) {
    return 1;
  }
  return 0;
};

export const TIME_ZONES_DATA_SOURCE: { zone: string; offset: number }[] = Intl.supportedValuesOf(
  'timeZone'
)
  .map((zone: string) => {
    return { zone, offset: getTimeZoneOffset(zone) };
  })
  .sort(compareTimeZonesOffset);
