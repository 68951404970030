import { NoData, NoDataFound, TextData } from 'components';
import React, { useMemo } from 'react';
import { CSSProp, css } from 'styled-components';

type Props = {
  isLoading: boolean;
  searchValue: string;
  noDataText: TextData;
  noDataFoundText: TextData;
  additionalText?: React.ReactElement;
  module: string;
  css?: CSSProp;
  noDataCss?: CSSProp;
  noDataFoundCss?: CSSProp;
};

export const useEmptyText = (props: Props) => {
  const {
    isLoading,
    searchValue,
    noDataText,
    noDataFoundText,
    additionalText,
    module,
    css: cssAll,
    noDataCss,
    noDataFoundCss,
  } = props;

  const emptyText: JSX.Element = useMemo(() => {
    if (!isLoading) {
      return searchValue ? (
        <NoDataFound
          searchValue={searchValue}
          noDataFoundText={noDataFoundText}
          css={css`
            ${cssAll};
            ${noDataFoundCss};
          `}
        />
      ) : (
        <NoData
          additionalText={additionalText}
          noDataText={noDataText}
          module={module}
          css={css`
            ${cssAll};
            ${noDataCss};
          `}
        />
      );
    }
    return (
      <NoData
        additionalText={additionalText}
        noDataText={noDataText}
        module={module}
        css={css`
          ${cssAll};
          ${noDataFoundCss};
        `}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, additionalText, noDataText, module, searchValue, noDataFoundText]);

  return { emptyText };
};
