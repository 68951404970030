// libs
import { css, useTheme } from 'styled-components';

// components
import { CustomText } from 'typography/Text';
import { Circle, StyledBadge } from './styled';

// hooks
import { useSelector } from 'react-redux';

// selectors
import { selectCurrentOrganisationName } from 'redux/selectors';

// utils
import { orgColor } from 'utils';

type BadgeSizes = 'medium' | 'large';

export type IBadgeProps = {
  altName?: string;
  size?: BadgeSizes;
  inviteIndicator?: boolean;
};

export const OrganisationBadge = ({
  altName,
  size = 'medium',
  inviteIndicator,
  ...rest
}: IBadgeProps) => {
  const {
    palette: { background },
  } = useTheme();

  const orgName = useSelector(selectCurrentOrganisationName);
  const badgeName = (altName || orgName).trimLeft();

  return (
    <StyledBadge
      css={css`
        background-color: ${orgColor(badgeName)};
      `}
      {...rest}
      size={size}
    >
      <CustomText type={size === 'medium' ? 'sub-heading-2' : 'heading-1'} color={background.base}>
        {badgeName.length ? badgeName[0] : '?'}
      </CustomText>
      {inviteIndicator && <Circle className="invite-indicator" />}
    </StyledBadge>
  );
};
