// libs
import { put, select } from 'typed-redux-saga';
import { setOrganisations } from 'redux/actions/account';

// utils
import { ApiTypeCaller } from '../../api/utils';

// actions
import { push } from 'redux/actions/router';

// selectors
import { selectPathName } from 'redux/selectors';

// constants
import { ROUTE_PATHS } from 'appConstants';

export function* getOrganisationsSaga(): any {
  const { result, success } = yield* ApiTypeCaller('getAccountOrganisations');

  if (success && result) {
    yield put(setOrganisations(result.orgs));
    const pathname = yield* select(selectPathName);

    if (!result.orgs.length && !pathname.includes(ROUTE_PATHS.account)) {
      yield put(push([ROUTE_PATHS.account]));
    }
  }

  return success;
}
