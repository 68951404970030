// libs
import { useCallback } from 'react';
import { css, useTheme } from 'styled-components';

// components
import { CustomButton, CustomButtonLink, CustomMessage, Loader } from 'components';
import { IconModalDelete } from 'assets/svg';
import { ModalFooter, ModalHeader, ModalScrollablePart } from 'components/Modal';
import { CustomText } from 'typography/Text';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { useDispatch, useSelector } from 'react-redux';
import { useSubmitOnEnter } from 'hooks/useSubmitOnEnter';

// acitons
import { clearErrors, setModalContent } from 'redux/actions/app';
import { removeAccessToken } from 'redux/actions/account';

// redux
import { selectFormError, selectModalData, selectModalIsLoading } from 'redux/selectors';

// types
import { IAccountAccessToken } from 'types';

// constants
import { ACCOUNT_DELETE_ACCESS_TOKEN_ERROR_FIELD } from 'appConstants';

export const DeletePATokenModal = () => {
  const dispatch = useDispatch();

  const { translate } = useTranslations('account');

  const {
    palette: { fonts },
  } = useTheme();

  const tokenInfo = useSelector(selectModalData) as IAccountAccessToken;
  const isLoading = useSelector(selectModalIsLoading);
  const error = useSelector(selectFormError(ACCOUNT_DELETE_ACCESS_TOKEN_ERROR_FIELD));

  const isButtonEnabled = !isLoading;

  const onClose = useCallback(() => {
    if (error) clearErrors();

    dispatch(setModalContent({ type: null, data: null }));
  }, [dispatch, error]);

  const onRemoveToken = useCallback(() => {
    if (error) clearErrors();

    if (tokenInfo) dispatch(removeAccessToken({ tokenRef: tokenInfo.reference }));
  }, [error, tokenInfo, dispatch]);

  useSubmitOnEnter({ callback: isButtonEnabled ? onRemoveToken : () => {} });

  return (
    <>
      <ModalHeader
        title={translate('delete_access_token_modal.title')}
        icon={<IconModalDelete width="2.5rem" height="2.5rem" />}
        withCloseButton={true}
        onClose={onClose}
      />

      <ModalScrollablePart>
        {error && (
          <CustomMessage
            type="error"
            message={error}
            withIcon
            css={css`
              margin-bottom: 1rem;
              width: auto;
            `}
          />
        )}

        <CustomMessage
          type="error"
          message={translate('delete_access_token_modal.warn')}
          css={css`
            margin-bottom: 2rem;
            padding-top: 0.5625rem;
            padding-bottom: 0.5625rem;
          `}
        />
        <CustomText
          type="body-1"
          color={fonts.bodyDark}
          css={css`
            display: block;
            margin-bottom: 0.5rem;
          `}
        >
          {translate('delete_access_token_modal.desc')}
        </CustomText>
        <CustomText
          css={css`
            display: block;
            margin-bottom: 1.5rem;
          `}
          type="sub-heading-1"
          color={fonts.bodyDark}
        >
          {tokenInfo?.description}
        </CustomText>
      </ModalScrollablePart>

      <ModalFooter>
        <CustomButtonLink size="large" onClick={onClose}>
          {translate('delete_access_token_modal.no_cancel')}
        </CustomButtonLink>
        <CustomButton
          size="large"
          width="8.3125rem"
          kind={isButtonEnabled ? 'primaryDanger' : 'disabled'}
          onClick={onRemoveToken}
        >
          {isLoading ? <Loader /> : translate('delete_access_token_modal.yes_delete')}
        </CustomButton>
      </ModalFooter>
    </>
  );
};
