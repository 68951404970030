import { IOrganisationWithInvite, MainState } from 'types';
import { secondsToDate } from 'utils';
import { createSelector } from '@reduxjs/toolkit';
import { uniqBy } from 'ramda';

export const selectOrgId = (state: MainState) => state.Account.currentOrg;
export const selectOrgPath = createSelector(selectOrgId, orgId => `/org/${orgId}`);

export const selectAccountStepUpToken = (state: MainState) => state.Account.stepUpToken;

export const selectAccountIsStepUpValid = ({ Account }: MainState) => {
  const signInMethod = Account.profile.signInMethod;
  if (signInMethod !== 'Local' && signInMethod !== 'PersonalAccessToken') {
    return true;
  }
  return !!Account.stepUpToken && secondsToDate(Account.stepUpToken.validUntil) > 300;
};

export const selectAccount2FAQR = (state: MainState) => state.Account.qrCode;
export const selectAccountId = (state: MainState) => state.Account.profile.id;

export const selectAccountSignInMethod = (state: MainState) => state.Account.profile.signInMethod;
export const selectAccountSignInMethodIsLocal = (state: MainState) => {
  const signInMethod = state.Account.profile.signInMethod;
  if (signInMethod === 'Local' || signInMethod === 'PersonalAccessToken') {
    return true;
  }
  return false;
};

export const selectAccountPartnerAccess = (state: MainState) => {
  const currentOrg = state.Account.currentOrg;
  return state.Account.orgs.find(org => org.orgId === currentOrg)?.partnerAccess || false;
};
export const selectPartnerAccess = (state: MainState) => {
  return state.Account.orgs.findIndex(org => org.partnerAccess) !== -1;
};
export const selectAccountProfile = (state: MainState) => state.Account.profile;
export const selectAccountUserName = (state: MainState) => state.Account.profile.userName;
export const selectAccountSessionLogoutUrl = (state: MainState) => state.Account.sessionLogoutUrl;
export const selectAccountEmail = (state: MainState) => state.Account.profile.email;
export const selectAccountMemberSince = (state: MainState) => state.Account.profile.memberSince;
export const selectAccountFirstName = (state: MainState) => state.Account.profile.firstName;
export const selectAccountLastName = (state: MainState) => state.Account.profile.lastName;
export const selectAccount2FAEnabled = (state: MainState) => state.Account.profile.twoFactorEnabled;
export const selectAccountEmailNotif = (state: MainState) =>
  state.Account.profile.emailNotificationsEnabled;
export const selectAccountInvites = (state: MainState) => state.Account.profile.invites;
export const selectAccountInviteStatus = (state: MainState) => state.Account.inviteStatus;
export const selectAccountAccessTokens = (state: MainState) => state.Account.profile.tokens;

export const selectAccountOrganisations = (state: MainState) => state.Account.orgs;
export const selectAccountCurrentOrganisation = createSelector(
  selectAccountOrganisations,
  selectOrgId,
  (orgs, currentOrgId) => orgs.find(({ orgId }) => orgId === currentOrgId)
);
export const selectAccountMainOrganisation = createSelector(selectAccountOrganisations, orgs => {
  const ownerOrg = orgs.find(({ role }) => role === 'Owner');

  if (ownerOrg) {
    return ownerOrg;
  } else {
    return orgs.find(({ role }) => role === 'Admin');
  }
});

export const selectAccountInvitesAndOrgs = (state: MainState): IOrganisationWithInvite[] => {
  const inviteOrgs: IOrganisationWithInvite[] = state.Account.profile.invites.map(
    ({ orgId, orgName }) => ({
      orgId,
      orgName,
      partnerAccess: false,
      role: 'Invite',
    })
  );

  return uniqBy(value => value.orgId, [...inviteOrgs, ...state.Account.orgs]);
};
