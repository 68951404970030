import { createAction } from '@reduxjs/toolkit';
import { Update } from 'history';
import {
  RouterBackActionPayload,
  RouterForwardActionPayload,
  RouterGoActionPayload,
  RouterPushActionPayload,
  RouterReplaceActionPayload,
} from 'types';

export const locationChange = createAction<Update>('@@router/LOCATION_CHANGE');
export const setLastLocation = createAction<string>('@@router/SET_LAST_LOCATION');

export const go = createAction<RouterGoActionPayload>('@@router/GO');
export const push = createAction<RouterPushActionPayload>('@@router/PUSH');
export const replace = createAction<RouterReplaceActionPayload>('@@router/REPLACE');
export const goBack = createAction<RouterBackActionPayload>('@@router/BACK');
export const goForward = createAction<RouterForwardActionPayload>('@@router/FORWARD');
