import { Button } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import { css, CSSProp, useTheme } from 'styled-components';

interface IIconButton extends ButtonProps {
  icon: React.ReactElement;
  width?: string;
  height?: string;
  hoverCss?: CSSProp;
  focusedCss?: CSSProp;
}

export const IconButton = ({
  icon,
  width = '2rem',
  height = '2rem',
  hoverCss,
  focusedCss,
  ...rest
}: IIconButton) => {
  const {
    palette: { fills, icons, background },
  } = useTheme();
  return (
    <Button
      icon={icon}
      css={css`
        display: flex;
        width: ${width};
        height: ${height};
        align-items: center;
        justify-content: center;
        border: none;
        background: none;
        box-shadow: none;
        :hover {
          background-color: ${fills.iconHover};
          ${hoverCss ||
          `
          svg path {
            fill: ${fills.primaryHover};
          }
          `}
        }

        :focus {
          background-color: ${background.focus};

          ${focusedCss ||
          `
            svg path {
            fill: ${icons.primary};
          }
          `}

          ${focusedCss}
        }
      `}
      {...rest}
    />
  );
};
