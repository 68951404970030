// libs
import { css, useTheme } from 'styled-components';

// components
import { CustomText } from 'typography/Text';
import { IconDnsRecordDetailsAddSystem } from 'assets/svg';
import { CustomButtonLink, SystemsListWithConnectedStatus } from 'components';
import { SelectSystem } from './SelectSystem';
import { DeleteSystemButton } from './DeleteSystemButton';

// hooks
import { useState } from 'react';
import { useTranslations } from 'hooks/useTranslations';
import { useDispatch, useSelector } from 'react-redux';

// actions
import { updateDnsRecordDetails } from 'redux/actions/app';

// selectors
import { selectAppDetailsDnsNewSystems } from 'redux/selectors';

// types
import { ISystemSummaryModel, ISystemModel, ISystemReferenceModel } from 'types';

export const Systems = () => {
  const {
    palette: { fills },
  } = useTheme();

  const dispatch = useDispatch();

  // if it's active - selector where we select new system
  // is shown
  const [addSystemActive, setAddSystemActive] = useState(false);

  const { translate } = useTranslations('dns');

  const systems = useSelector(selectAppDetailsDnsNewSystems) || [];

  const updateSystems = (systems: ISystemReferenceModel[]) => {
    dispatch(updateDnsRecordDetails({ systems }));
  };

  const addSystem = (system: ISystemSummaryModel) => {
    const { connectedFrom, systemId, description, platformType, hostname, state } = system;

    // systems in query and systems in a way we store
    // in details are of different type, here we are reconciling
    // those two types by transforming ISystem to ISystemReferenceModel
    updateSystems([
      ...systems,
      {
        state,
        platformType,
        connectedFrom,
        id: systemId,
        machineName: hostname,
        name: description,
      },
    ]);
    setAddSystemActive(false);
  };

  const handleAddNewSystem = () => {
    setAddSystemActive(true);
  };

  const handleCancel = () => {
    setAddSystemActive(false);
  };

  const deleteSystem = (id: ISystemModel['systemId']) => {
    updateSystems(systems.filter(system => system.id !== id));
    setAddSystemActive(false);
  };

  return (
    <>
      <SystemsListWithConnectedStatus
        systems={systems}
        suffix={({ onClick }) => <DeleteSystemButton onClick={() => onClick?.()} />}
        onSuffixClick={({ id }) => {
          deleteSystem(id);
        }}
      />
      {addSystemActive && <SelectSystem onSelect={addSystem} onCancel={handleCancel} />}
      <CustomButtonLink
        onClick={() => handleAddNewSystem()}
        css={css`
          width: fit-content;
          margin-top: 1.5rem;
        `}
      >
        <IconDnsRecordDetailsAddSystem />
        <CustomText
          type="body-1"
          color={fills.primary}
          css={css`
            margin-left: 0.3125rem;
          `}
        >
          {translate('records.details.add_system')}
        </CustomText>
      </CustomButtonLink>
    </>
  );
};
