// libs
import { css, useTheme } from 'styled-components';

// components
import { CustomText } from 'typography/Text';
import { IconContainer, RightDirectionIcon, SlackGridItem, SystemsLinkA } from '../../styled';
import { ContentContainer, SectionIconContainer, ContentTopRowContainer } from './styled';
import { IconDashboardSlack } from 'assets/svg';

// hooks
import { useState } from 'react';
import { useTranslations } from 'hooks/useTranslations';

// constants
import { LINK_TO_SLACK } from 'appConstants';

export const SlackSection = () => {
  const {
    palette: { fonts },
  } = useTheme();

  const { translate } = useTranslations('landing-page');

  const [isActive, setIsActive] = useState<boolean>(false);

  return (
    <SystemsLinkA
      aria-label={translate('slack_section.aria_labels.follow_link')}
      rel="noreferrer"
      target="_blank"
      href={LINK_TO_SLACK}
      onMouseEnter={() => setIsActive(true)}
      onMouseLeave={() => setIsActive(false)}
    >
      <SlackGridItem>
        <ContentContainer>
          <ContentTopRowContainer>
            <SectionIconContainer>
              <IconDashboardSlack width="2.5rem" height="2.5rem" />
            </SectionIconContainer>

            <CustomText
              color={fonts.bodyDark}
              type="sub-heading-1"
              css={css`
                line-height: 2.5rem;
                margin-left: 1.125rem;
              `}
            >
              {translate('slack_section.title')}
            </CustomText>
          </ContentTopRowContainer>
          <CustomText color={fonts.bodyLight} type="body-2">
            {translate('slack_section.description')}
          </CustomText>
        </ContentContainer>
        <IconContainer $isActive={isActive}>
          <RightDirectionIcon $isActive={isActive} />
        </IconContainer>
      </SlackGridItem>
    </SystemsLinkA>
  );
};
