// libs
import { SyntheticEvent, useCallback } from 'react';
import { css, CSSProp } from 'styled-components';

// components
import { Table } from 'antd';
import { IconEllipsis } from 'assets/svg';
import { IconLeftDirection, IconRightDirection, paginationCss, tableCss } from './styled';

// constants
import { SYSTEMS_PER_TABLE_PAGE } from 'appConstants/systems';

// types
import { TableProps } from 'antd/lib/table/Table';

export interface IStyledTableProps<T> extends TableProps<T> {
  onRowClick?: (record: T, event?: SyntheticEvent) => void;
  headerHeight?: string;
  rowHeight?: string;
  elevated?: boolean;
  focusedRowCss?: CSSProp;
  selectedRowKey?: string | number;
  withBorder?: boolean;
}

const selectedRowClassName = 'ant-table-row-selected-custom';

export const CustomTable = <T extends Record<string, unknown>>({
  rowKey,
  onRowClick,
  headerHeight = '2.5rem',
  rowHeight = '3.375rem',
  elevated = false,
  selectedRowKey,
  focusedRowCss,
  pagination = false,
  withBorder = true,
  ...rest
}: IStyledTableProps<T>) => {
  const getIsKeySelected = useCallback(
    (record: T) => {
      const recordKey =
        typeof rowKey === 'string'
          ? record[rowKey]
          : typeof rowKey === 'function'
          ? rowKey(record)
          : undefined;

      return String(recordKey) === String(selectedRowKey);
    },
    [rowKey, selectedRowKey]
  );

  const onRow = useCallback(
    (record: T) => {
      return {
        onClick: (event: SyntheticEvent) => {
          onRowClick?.(record, event);
        },
        className: getIsKeySelected(record) ? selectedRowClassName : undefined,
      };
    },
    [getIsKeySelected, onRowClick]
  );
  return (
    <Table
      rowKey={rowKey}
      onRow={onRow}
      pagination={
        typeof pagination === 'object'
          ? {
              itemRender: (_page, type, originalNode) => {
                if (type === 'page') {
                  return originalNode;
                } else if (type === 'jump-prev' || type === 'jump-next') {
                  return <IconEllipsis />;
                } else if (type === 'prev') {
                  return <IconLeftDirection />;
                } else if (type === 'next') {
                  return <IconRightDirection />;
                }
              },
              defaultPageSize: SYSTEMS_PER_TABLE_PAGE,
              position: ['bottomLeft'],
              hideOnSinglePage: true,
              ...pagination,
            }
          : pagination
      }
      css={css`
        cursor: default;

        ${tableCss({ headerHeight, elevated, focusedRowCss, rowHeight, withBorder })}
        ${paginationCss}
      `}
      {...rest}
    />
  );
};
