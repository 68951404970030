// Components
import { Details } from 'components';

// hooks
import { useSelector } from 'react-redux';

// selectors
import { selectAppDetailsTabType } from 'redux/selectors';

export const TopMenuButtons = () => {
  const type = useSelector(selectAppDetailsTabType);

  return <Details.TopMenuButtons showEditLink={type === 'tag-view'} />;
};
