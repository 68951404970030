// libs
import { css, useTheme } from 'styled-components';

// components
import { CustomText } from 'typography/Text';
import { Select, Tooltip } from 'components';
import { EditContainer, tooltipCss } from './styled';
import { IconQuestionCircle } from 'assets/svg';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { useDispatch, useSelector } from 'react-redux';

// actions
import { updateEnrolmentKeyDetails } from 'redux/actions/app';

// selectors
import { selectAppDetailsEnrolmentKeyNewDisconnectedRetentionMinutes } from 'redux/selectors';

// types
import { IEnrolmentKey } from 'types';

// constants
import { DEFAULT_RETENTION_MINUTES, DISCONNECTED_RETENTION_MINUTES_CONFIG } from 'appConstants';

export const RemoveAfterEdit = () => {
  const dispatch = useDispatch();

  const { translate } = useTranslations('enrolment-keys');

  const {
    palette: { fonts },
  } = useTheme();

  const disconnectedRetentionMinutes = useSelector(
    selectAppDetailsEnrolmentKeyNewDisconnectedRetentionMinutes
  );

  const setNewValue = (newValue: IEnrolmentKey['disconnectedRetentionMinutes']) => {
    dispatch(updateEnrolmentKeyDetails({ disconnectedRetentionMinutes: newValue }));
  };

  return (
    <EditContainer>
      <Select
        aria-label={translate('details.aria_labels.remove_after')}
        height="2rem"
        dataSource={DISCONNECTED_RETENTION_MINUTES_CONFIG}
        valueField="disconnectedRetentionMinutes"
        keyField="disconnectedRetentionMinutes"
        width="8.1875rem"
        OptionElement={option => {
          return (
            <CustomText type="body-2" color={fonts.title}>
              {option.text}
            </CustomText>
          );
        }}
        setSelectedValue={option => {
          setNewValue(option.disconnectedRetentionMinutes);
        }}
        defaultValueFieldValue={disconnectedRetentionMinutes || DEFAULT_RETENTION_MINUTES}
      />
      <Tooltip
        width="20rem"
        placement="top"
        trigger="hover"
        passedCss={tooltipCss}
        overlay={<CustomText type="body-2">{translate('details.remove_after_tooltip')}</CustomText>}
      >
        <IconQuestionCircle
          width="1rem"
          height="1rem"
          css={css`
            margin-left: 0.75rem;
          `}
        />
      </Tooltip>
    </EditContainer>
  );
};
