// Lib
import { call, put } from 'typed-redux-saga';

import { validateStepUpTokenSaga } from './validateStepUpTokenSaga';
import { setModalContent } from 'redux/actions/app';

// Utils
import { ApiTypeCaller } from '../../api/utils';

export function* generateRecoveryCodesSaga() {
  const token = yield* call(validateStepUpTokenSaga);
  if (token) {
    const { success, result } = yield* ApiTypeCaller('generateAccountRecoveryCodes', {
      stepUpToken: token,
    });
    if (success && result) {
      yield put(setModalContent({ type: 'recovery-codes', data: result.recoveryCodes }));
    }
  }
}
