// Libs
import { BaseSyntheticEvent, useEffect, useRef } from 'react';

// components
import { Details } from 'components';

// hooks
import { useDispatch, useSelector } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';

// selectors
import {
  selectAppDetailsEnrolmentKeyNewDescription,
  selectAppDetailsTabValidationStateEntries,
} from 'redux/selectors';

// actions
import {
  addValidationStateEntry,
  updateEnrolmentKeyDetails,
  updateValidationStateEntry,
} from 'redux/actions/app';
import { equals } from 'ramda';

// types
import { TextAreaRef } from 'antd/lib/input/TextArea';

const titleErrorKey = 'keyTitle';

export const TitleCreate = () => {
  const dispatch = useDispatch();

  const { translate } = useTranslations('enrolment-keys');

  const newDescription = useSelector(selectAppDetailsEnrolmentKeyNewDescription);
  const validationState = useSelector(
    selectAppDetailsTabValidationStateEntries([titleErrorKey]),
    equals
  );

  const ref = useRef<TextAreaRef>(null);

  useEffect(() => {
    dispatch(addValidationStateEntry({ key: titleErrorKey, state: { error: null } }));
  }, [dispatch]);

  const onDescriptionChange = (event: BaseSyntheticEvent) => {
    const description = event.target.value;

    const error = description.trim().length
      ? null
      : { message: translate('details.no_name_error') };

    if (
      validationState?.[titleErrorKey]?.state?.error !== error ||
      !validationState?.[titleErrorKey]?.updated
    ) {
      dispatch(updateValidationStateEntry({ key: titleErrorKey, state: { error } }));
    }

    dispatch(updateEnrolmentKeyDetails({ description }));
  };

  useEffect(() => {
    ref?.current?.focus();
  }, [ref]);

  return (
    <Details.Input
      ref={ref}
      name={titleErrorKey}
      value={newDescription ?? ''}
      placeholder={translate('details.name_placeholder')}
      onChange={onDescriptionChange}
      error={validationState?.[titleErrorKey]?.state?.error}
      onBlur={onDescriptionChange}
    />
  );
};
