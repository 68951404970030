import { createAction } from '@reduxjs/toolkit';

import {
  IUserAccount,
  IUpdateAccountParams,
  IUpdateAccountPasswordParams,
  ICreateAccountStepUpParams,
  ICreateAccountAccessTokenParams,
  IStepUpToken,
  IRemoveAccountAccessTokenParams,
  IVerifyAccount2FACodeParams,
  IChange2FAPayload,
  ICreateStepUpAndOpenModal,
  IOrganisation,
  IAccountInviteStatusPayload,
  IDeclineOrganisationInvite,
  IAcceptOrganisationInvite,
  ISessionLogoutUrl,
} from 'types';

export const getAccount = createAction('account/GET_INFO');
export const setAccount = createAction<IUserAccount>('account/SET_INFO');
export const set2FAStatus = createAction<IChange2FAPayload>('account/SET_2FA_STATUS');
export const setStepUpToken = createAction<IStepUpToken>('account/SET_STEP_UP_TOKEN');

export const updateAccount = createAction<IUpdateAccountParams>('account/UPDATE_ACCOUNT');
export const updatePassword = createAction<IUpdateAccountPasswordParams>('account/UPDATE_PASSWORD');

export const createStepUpToken = createAction<ICreateAccountStepUpParams>(
  'account/CREATE_STEP_UP_TOKEN'
);

export const createAccessToken = createAction<Omit<ICreateAccountAccessTokenParams, 'stepUpToken'>>(
  'account/CREATE_ACCESS_TOKEN'
);
export const removeAccessToken = createAction<IRemoveAccountAccessTokenParams>(
  'account/REMOVE_ACCESS_TOKEN'
);

export const takeAwayAccessToken = createAction<string>('account/TAKEAWAY_ACCESS_TOKEN');

export const create2FAQRCode = createAction('account/CREATE_2FA_QR_CODE');
export const set2FAQRCode = createAction<string | null>('account/SET_2FA_QR_CODE');

export const generateRecoveryCodes = createAction('account/GENERATE_RECOVERY_CODES');

export const verify2FACode =
  createAction<Omit<IVerifyAccount2FACodeParams, 'stepUpToken'>>('account/VERIFY_2FA_CODE');

export const openEnable2FAModal = createAction('account/OPEN_ENABLE_2FA_MODAL');
export const createStepUpAndOpenModal = createAction<ICreateStepUpAndOpenModal>(
  'account/CREATE_STEP_UP_AND_OPEN_MODAL'
);
export const validateAuthCode = createAction<Omit<IVerifyAccount2FACodeParams, 'stepUpToken'>>(
  'account/VALIDATE_AUTH_CODE'
);

export const enable2FA = createAction('account/ENABLE_2FA');
export const disable2FA = createAction('account/DISABLE_2FA');

export const setOrganisations = createAction<IOrganisation[]>('account/SET_ORGANISATIONS');
export const changeCurrentOrganisation = createAction<IOrganisation['orgId']>(
  'account/CHANGE_ORGANISATION'
);
export const setCurrentOrganisation = createAction<IOrganisation['orgId'] | null>(
  'account/SET_ORGANISATION'
);

export const acceptInvite = createAction<IAcceptOrganisationInvite>('account/ACCEPT_INVITE');
export const declineInvite = createAction<IDeclineOrganisationInvite>('account/DECLINE_INVITE');

export const setInviteStatus = createAction<IAccountInviteStatusPayload>(
  'account/SET_INVITE_STATUS'
);

export const setSessionLogoutUrl = createAction<ISessionLogoutUrl>(
  'account/SET_SESSION_LOGOUT_URL'
);
