// libs
import { takeEvery, takeLatest, takeLeading } from 'redux-saga/effects';

// actions
import {
  getEnrolmentKeys,
  createEnrolmentKey,
  enableEnrolmentKey,
  disableEnrolmentKey,
  enableEnrolmentKeysBulk,
  disableEnrolmentKeysBulk,
  saveEnrolmentKeyDetails,
  getEnrolmentKeySearchKeys,
  deleteEnrolmentKey,
  deleteEnrolmentKeys,
  enableUntilEnrolmentKey,
} from 'redux/actions/enrolment-keys';
import { createEnrolmentKeySideMenu } from 'redux/actions/app';

// sagas
import { getEnrolmentKeysSaga } from './getEnrolmentKeysSaga';
import { createEnrolmentKeySaga } from './createEnrolmentKeySaga';
import { enableDisableEnrolmentKeySaga } from './enableDisableEnrolmentKeySaga';
import { enableDisableEnrolmentKeysSaga } from './enableDisableEnrolmentKeysSaga';
import { updateEnrolmentKeySaga } from './updateKeySaga';
import { createEnrolmentKeyDetailsSaga } from './createKeyDetailsSaga';
import { getEnrolmentKeySearchKeysSaga } from './getEnrolmentKeySearchKeysSaga';
import { deleteEnrolmentKeySaga } from './deleteEnrolmentKeySaga';
import { deleteEnrolmentKeysSaga } from './deleteEnrolmentKeysSaga';
import { enableUntilEnrolmentKeySaga } from './enableUntilEnrolmentKeySaga';

function* systemsSaga() {
  yield takeLatest(getEnrolmentKeys, getEnrolmentKeysSaga);
  yield takeEvery(createEnrolmentKey, createEnrolmentKeySaga);
  yield takeEvery(enableEnrolmentKey, payload => enableDisableEnrolmentKeySaga(payload, true));
  yield takeEvery(enableUntilEnrolmentKey, enableUntilEnrolmentKeySaga);
  yield takeEvery(disableEnrolmentKey, payload => enableDisableEnrolmentKeySaga(payload, false));
  yield takeEvery(enableEnrolmentKeysBulk, payload =>
    enableDisableEnrolmentKeysSaga(payload, true)
  );
  yield takeEvery(saveEnrolmentKeyDetails, updateEnrolmentKeySaga);
  yield takeEvery(disableEnrolmentKeysBulk, payload =>
    enableDisableEnrolmentKeysSaga(payload, false)
  );
  yield takeEvery(createEnrolmentKeySideMenu, createEnrolmentKeyDetailsSaga);
  yield takeEvery(getEnrolmentKeySearchKeys, getEnrolmentKeySearchKeysSaga);
  yield takeLeading(deleteEnrolmentKey, deleteEnrolmentKeySaga);
  yield takeLeading(deleteEnrolmentKeys, deleteEnrolmentKeysSaga);
}

export default systemsSaga;
