// libs
import { equals } from 'ramda';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// components
import { Details } from 'components';

// selectors
import {
  selectAppDetailsTabValidationStateEntries,
  selectAppDetailsTrustRequirementNewDescription,
} from 'redux/selectors';

// actions
import { updateValidationStateEntry, updateTrustRequirementDetails } from 'redux/actions/app';

// types
import { BaseSyntheticEvent } from 'react';
import { TextAreaRef } from 'antd/lib/input/TextArea';

const titleErrorKey = 'title';

export const TitleEdit = () => {
  const dispatch = useDispatch();

  const { translate } = useTranslations('trust-requirements');

  const newDescription = useSelector(selectAppDetailsTrustRequirementNewDescription);
  const validationState = useSelector(
    selectAppDetailsTabValidationStateEntries([titleErrorKey]),
    equals
  );

  const ref = useRef<TextAreaRef>(null);

  const onDescriptionChange = (event: BaseSyntheticEvent) => {
    if (!event.target.value.trim().length) {
      dispatch(
        updateValidationStateEntry({
          key: titleErrorKey,
          state: { error: { message: translate('details.title_error') } },
        })
      );
    } else {
      dispatch(updateValidationStateEntry({ key: titleErrorKey, state: { error: null } }));
    }

    dispatch(updateTrustRequirementDetails({ description: event.target.value }));
  };

  useEffect(() => {
    ref?.current?.focus();
  }, [ref]);

  return (
    <>
      <Details.Input
        ref={ref}
        name={titleErrorKey}
        value={newDescription ?? ''}
        placeholder={translate('details.title_placeholder')}
        onChange={onDescriptionChange}
        error={validationState?.[titleErrorKey]?.state?.error}
        onBlur={onDescriptionChange}
      />
    </>
  );
};
