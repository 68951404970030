// libs
import { css, useTheme } from 'styled-components';

// Components
import { CustomButton, BulkClearButton } from 'components';
import { CustomText } from 'typography/Text';
import { BulkActionsContainer } from 'components/BulkActions/styled';

// Hooks
import { useTranslations } from 'hooks/useTranslations';
import { useDispatch } from 'react-redux';
import { useCallback } from 'react';

// actions
import { disableEnrolmentKeysBulk, enableEnrolmentKeysBulk } from 'redux/actions/enrolment-keys';
import { setModalContent } from 'redux/actions/app';

// types
import { IEnrolmentKey } from 'types';

interface IBulkActions {
  bulkSelectedKeys: IEnrolmentKey[];
  setBulkSelectedKeys: (bulkSelectedKeys: IEnrolmentKey[]) => void;
}
export const BulkActions = ({ bulkSelectedKeys, setBulkSelectedKeys }: IBulkActions) => {
  const dispatch = useDispatch();

  const { translate } = useTranslations('enrolment-keys');
  const { translate: translateGlobal } = useTranslations('global');

  const {
    palette: { fonts },
  } = useTheme();

  const onEnableDisableClick = useCallback(
    (enable: boolean) => {
      const action = enable ? enableEnrolmentKeysBulk : disableEnrolmentKeysBulk;
      dispatch(action({ keyIds: bulkSelectedKeys.map(key => key.id) }));
      setBulkSelectedKeys([]);
    },
    [bulkSelectedKeys, dispatch, setBulkSelectedKeys]
  );

  const onRemoveClick = useCallback(() => {
    dispatch(
      setModalContent({
        type: 'remove-enrolment-keys',
        data: {
          keys: bulkSelectedKeys,
        },
      })
    );
  }, [bulkSelectedKeys, dispatch]);

  return (
    <BulkActionsContainer data-test="enrolment-keys-bulk-actions">
      <CustomText type="sub-heading-2" color={fonts.title}>
        {bulkSelectedKeys.length}{' '}
        {bulkSelectedKeys.length !== 1
          ? translateGlobal('bulk.items_selected', {
              module: translate('modules.items'),
            })
          : translateGlobal('bulk.item_selected', {
              module: translate('modules.item'),
            })}
      </CustomText>
      <BulkClearButton
        type="link"
        onClick={() => {
          setBulkSelectedKeys([]);
        }}
      >
        {translateGlobal('bulk.clear_all')}
      </BulkClearButton>
      {bulkSelectedKeys.find(key => key.isEnabled) && (
        <CustomButton
          kind="secondary"
          onClick={() => onEnableDisableClick(false)}
          css={css`
            margin-right: 1rem;
          `}
        >
          {translateGlobal('bulk.disable')}
        </CustomButton>
      )}
      {bulkSelectedKeys.find(key => !key.isEnabled) && (
        <CustomButton
          kind="secondary"
          onClick={() => onEnableDisableClick(true)}
          css={css`
            margin-right: 1rem;
          `}
        >
          {translateGlobal('bulk.enable')}
        </CustomButton>
      )}

      <CustomButton
        kind="secondaryDanger"
        onClick={() => onRemoveClick()}
        css={css`
          margin-right: 1rem;
        `}
      >
        {translateGlobal('bulk.remove')}
      </CustomButton>
    </BulkActionsContainer>
  );
};
