import Skeleton from 'react-loading-skeleton';
import { StyledCol, StyledRow } from './Grid';
import styled, { css } from 'styled-components';

const StyledWrapper = styled.section`
  padding: 0 2.5rem;
`;

export const DetailsSkeleton = () => {
  return (
    <StyledWrapper>
      <StyledRow
        css={css`
          height: 2rem;
        `}
        mb="2rem"
        justify="end"
      >
        {Array.from({ length: 3 }, (_, idx) => (
          <StyledCol key={idx} span={1} offset={1}>
            <Skeleton height="1.375rem" />
          </StyledCol>
        ))}
      </StyledRow>

      <StyledRow
        css={css`
          height: 2rem;
        `}
        mb="2.625rem"
      >
        <StyledCol span={24} $fullHeight={true}>
          <Skeleton height="2rem" />
        </StyledCol>
      </StyledRow>

      {Array.from({ length: 5 }, (_, idx) => (
        <StyledRow key={idx} mb="1.5rem">
          <StyledCol span={4}>
            <Skeleton height="1.375rem" />
          </StyledCol>
          <StyledCol span={12} offset={2}>
            <Skeleton height="1.375rem" />
          </StyledCol>
        </StyledRow>
      ))}
    </StyledWrapper>
  );
};
