// hooks
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';

// selectors
import {
  selectAppDetailsSystemAutoExpireDate,
  selectAppDetailsSystemAutoExpireTimeZone,
} from 'redux/selectors';

// actions
import { updateSystemDetailsAutoExpire } from 'redux/actions/app';

// components
import { DateTimePicker } from 'components';
import { isDateTimeBeforeNow } from 'utils';

export const ExpiryDateEdit = () => {
  const dispatch = useDispatch();

  const { translate } = useTranslations('systems');
  const warningMessage = translate('details.auto_expire.warning');

  const [warning, setWarning] = useState(false);

  const dateTime = useSelector(selectAppDetailsSystemAutoExpireDate);
  const timeZone = useSelector(selectAppDetailsSystemAutoExpireTimeZone);

  const handleDateTimeChange = useCallback(
    (newValue: string) => {
      dispatch(updateSystemDetailsAutoExpire({ expiryDateTime: newValue }));
    },
    [dispatch]
  );

  useEffect(() => {
    const isTimeBeforeNow = isDateTimeBeforeNow(dateTime, timeZone);
    setWarning(isTimeBeforeNow);
  }, [dateTime, timeZone]);

  return (
    <DateTimePicker
      warning={warning}
      warningMessage={warningMessage}
      dateTimeValue={dateTime}
      onDateTimeChange={handleDateTimeChange}
      datePickerInputPlaceholder={translate('details.auto_expire.expiry_date.datePlaceholder')}
      timeSelectInputPlaceholder={translate('details.auto_expire.expiry_date.timePlaceholder')}
    />
  );
};
