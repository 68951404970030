// libs
import { css, useTheme } from 'styled-components';

// components
import { Details } from 'components';
import { CustomText } from 'typography/Text';
import { UseLimitView } from './View';
import { UseLimitEdit } from './Edit';
import { UsesRemainingTitleContainer } from './styled';
import { IconNotificationWarningCircle } from 'assets/svg';

// hooks
import { useSelector } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';

// selectors
import {
  selectAppDetailsEnrolmentKeyCurrentType,
  selectAppDetailsEnrolmentKeyUsesRemaining,
  selectAppDetailsTabType,
} from 'redux/selectors';

export const UseLimitBlock = () => {
  const { translate } = useTranslations('enrolment-keys');

  const {
    palette: { fonts },
  } = useTheme();

  const type = useSelector(selectAppDetailsTabType);
  const usesRemaining = useSelector(selectAppDetailsEnrolmentKeyUsesRemaining);
  const purpose = useSelector(selectAppDetailsEnrolmentKeyCurrentType);

  if (purpose === 'Ephemeral') return null;

  return (
    <Details.KeyValue
      height="auto"
      title={
        usesRemaining !== 0 ? (
          translate('details.use_limit')
        ) : (
          <UsesRemainingTitleContainer>
            <CustomText
              css={css`
                margin-right: 0.25rem;
              `}
              type="body-2"
              color={fonts.bodyLight}
            >
              {translate('details.use_limit')}
            </CustomText>
            <IconNotificationWarningCircle />
          </UsesRemainingTitleContainer>
        )
      }
    >
      {type === 'enrolment-key-view' && <UseLimitView />}
      {(type === 'enrolment-key-edit' || type === 'enrolment-key-create') && <UseLimitEdit />}
    </Details.KeyValue>
  );
};
