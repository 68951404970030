// libs
import { css } from 'styled-components';

// components
import { Radio } from 'antd';
import { CustomText } from 'typography/Text';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { useDispatch, useSelector } from 'react-redux';

// selectors
import { selectAppDetailsEnrolmentKeyNewApprovalMode } from 'redux/selectors';

// actions
import { updateEnrolmentKeyDetails } from 'redux/actions/app';

// types
import { IEnrolmentKeyApprovalMode } from 'types';

const Automatic: IEnrolmentKeyApprovalMode = 'Automatic';
const Manual: IEnrolmentKeyApprovalMode = 'Manual';

export const ApprovalModeEdit = () => {
  const dispatch = useDispatch();

  const { translate } = useTranslations('enrolment-keys');

  const approvalMode = useSelector(selectAppDetailsEnrolmentKeyNewApprovalMode);

  const handleApprovalModeChange = (newApprovalMode: IEnrolmentKeyApprovalMode) => {
    dispatch(updateEnrolmentKeyDetails({ approvalMode: newApprovalMode }));
  };

  return (
    <Radio.Group
      defaultValue={approvalMode}
      onChange={event => handleApprovalModeChange(event.target.value)}
      size="small"
      css={css`
        .ant-radio-button-wrapper {
          box-shadow: none !important;
          padding: 0 0.5rem;
          height: 2rem;
          line-height: 2rem;
        }

        .ant-radio-button-wrapper:first-child {
          border-radius: 0.25rem 0 0 0.25rem;
        }
        .ant-radio-button-wrapper:last-child {
          border-radius: 0 0.25rem 0.25rem 0;
        }

        .ant-radio-button-wrapper {
          display: inline-flex;
          align-items: center;
        }

        .ant-radio-button-wrapper:not(.ant-radio-button-wrapper-checked) {
          border-color: ${({ theme }) => theme.palette.fills.strokeMedium};

          span .ant-typography {
            color: ${({ theme }) => theme.palette.fonts.bodyLight};
          }
        }
        /* this is antd right switch element border color here  */
        .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
          background-color: ${({ theme }) => theme.palette.fills.primary};
        }

        .ant-radio-button-wrapper.ant-radio-button-wrapper-checked,
        .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
          background-color: ${({ theme }) => theme.palette.background.hover};
          border-color: ${({ theme }) => theme.palette.fills.primary};

          span .ant-typography {
            color: ${({ theme }) => theme.palette.fills.primary};
          }
        }
      `}
    >
      <Radio.Button value={Manual}>
        <CustomText data-test="enrolment-key-details-approval-mode-manual" type="body-2">
          {translate('details.gated')}
        </CustomText>
      </Radio.Button>
      <Radio.Button value={Automatic}>
        <CustomText type="body-2">{translate('details.automatic')}</CustomText>
      </Radio.Button>
    </Radio.Group>
  );
};
