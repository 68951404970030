import { appReducerBuilder, initialErrors } from '.';
import { clearErrors, clearFormError, setFormErrors } from 'redux/actions/app';

export const detailsTabErrorReducerSlice: appReducerBuilder = builder => {
  builder
    .addCase(clearErrors, state => {
      state.errors = initialErrors;
    })

    .addCase(clearFormError, (state, { payload }) => {
      if (typeof payload === 'string') {
        delete state.errors.form[payload];
      } else {
        payload.forEach(field => {
          delete state.errors.form[field];
        });
      }
    })
    .addCase(setFormErrors, (state, { payload }) => {
      state.errors.form = payload;
    });
};
