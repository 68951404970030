// libs
import { memo } from 'react';

// components
import Skeleton from 'react-loading-skeleton';
import { CustomText } from 'typography/Text';

// hooks
import { useTheme } from 'styled-components';

// types
import { ITrustRequirementBasic } from 'types';

interface IPolicies {
  policies: ITrustRequirementBasic['usedInPolicies'];
  isLoading: boolean;
}

export const Policies = memo(({ policies, isLoading }: IPolicies) => {
  const theme = useTheme();

  return (
    <>
      {isLoading ? (
        <Skeleton />
      ) : (
        <CustomText type="body-2" color={theme.palette.fonts.bodyDark}>
          {policies}
        </CustomText>
      )}
    </>
  );
});
