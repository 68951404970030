// components
import { TargetsView } from './View';
import { TargetsEdit } from './Edit';
import { ITargetsTabs } from './Content';

// hooks
import { useSelector } from 'react-redux';
import { useState } from 'react';

// selectors
import { selectAppDetailsTabType } from 'redux/selectors';

export const TargetsBlock = () => {
  const type = useSelector(selectAppDetailsTabType);
  const [selectedTab, setSelectedTab] = useState<ITargetsTabs>('tags');

  return (
    <>
      {type === 'dns-record-view' && (
        <TargetsView selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
      )}
      {(type === 'dns-record-edit' || type === 'dns-record-create') && (
        <TargetsEdit selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
      )}
    </>
  );
};
