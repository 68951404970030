// libs
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { css, useTheme } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

// components
import { CustomButton, CustomButtonLink, CustomMessage, Input, Loader } from 'components';
import { CustomText } from 'typography/Text';
import { IconAccountReAuthModal } from 'assets/svg';
import { ModalFooter, ModalHeader, ModalScrollablePart } from 'components/Modal';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { useSubmitOnEnter } from 'hooks/useSubmitOnEnter';

// acitons
import { clearErrors, setModalContent } from 'redux/actions/app';
import { updatePassword } from 'redux/actions/account';

// selectors
import { selectFormError, selectModalIsLoading } from 'redux/selectors';

// constants
import { ACCOUNT_CHANGE_PASSWORD_WARNING_ERROR_FIELD } from 'appConstants';

const newPasswordMinLength = 8;

export const PasswordModal = () => {
  const dispatch = useDispatch();

  const {
    palette: {
      fonts: { bodyLight },
    },
  } = useTheme();

  const { translate } = useTranslations('account');

  const [passwords, setPasswords] = useState({
    oldPw: '',
    newPw: '',
    confPw: '',
  });
  const [newPwError, setNewPwError] = useState({ message: '' });
  const [confPwError, setConfPwError] = useState({ message: '' });

  const error = useSelector(selectFormError(ACCOUNT_CHANGE_PASSWORD_WARNING_ERROR_FIELD));
  const isLoading = useSelector(selectModalIsLoading);

  const isButtonEnabled = !isLoading;

  const ref = useRef<HTMLInputElement>(null);

  const onClose = () => {
    if (error) dispatch(clearErrors());
    dispatch(setModalContent({ type: null, data: null }));
  };

  const onUpdatePassword = useCallback(() => {
    let valid = true;
    if (passwords.newPw !== passwords.confPw) {
      setConfPwError({ message: translate('do_not_match') });
      valid = false;
    }
    if (passwords.newPw.length < newPasswordMinLength) {
      setNewPwError({ message: translate('password_modal.at_least_8_characters') });
      valid = false;
    }
    if (valid) {
      dispatch(clearErrors());
      dispatch(updatePassword({ originalPassword: passwords.oldPw, newPassword: passwords.newPw }));
    }
  }, [dispatch, translate, passwords.newPw, passwords.confPw, passwords.oldPw]);

  const onChangePassword = (e: ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    setPasswords({
      ...passwords,
      [name]: value,
    });
    if (name === 'newPw' && newPwError.message !== '') {
      setNewPwError({ message: '' });
    }
    if (name === 'confPw' && confPwError.message !== '') {
      setConfPwError({ message: '' });
    }
  };

  useEffect(() => {
    ref?.current?.focus();
  }, [ref]);

  useSubmitOnEnter({ callback: isButtonEnabled ? onUpdatePassword : () => {} });

  return (
    <>
      <ModalHeader
        title={translate('change_password')}
        icon={<IconAccountReAuthModal width="2.5rem" height="2.5rem" />}
      />

      <ModalScrollablePart>
        {error && (
          <CustomMessage
            type="error"
            message={error}
            withIcon
            css={css`
              margin-bottom: 1rem;
            `}
          />
        )}
        <CustomText
          type="body-1"
          color={bodyLight}
          css={css`
            display: block;
            margin-bottom: 2rem;
          `}
        >
          {translate('change_pass_desc')}
        </CustomText>
        <Input
          ref={ref}
          type="password"
          name="oldPw"
          value={passwords.oldPw}
          placeholder={translate('curr_pass_placeholder')}
          onChange={onChangePassword}
          label={translate('curr_pass')}
          containerCss={css`
            margin-bottom: 0.75rem;
          `}
        />
        <Input
          type="password"
          name="newPw"
          value={passwords.newPw}
          placeholder={translate('new_pass_placeholder')}
          onChange={onChangePassword}
          label={translate('new_pass')}
          error={newPwError}
          containerCss={css`
            margin-bottom: 0.75rem;
          `}
        />
        <Input
          type="password"
          name="confPw"
          value={passwords.confPw}
          placeholder={translate('conf_pass_placeholder')}
          onChange={onChangePassword}
          label={translate('conf_pass')}
          error={confPwError}
          containerCss={css`
            margin-bottom: 0.75rem;
          `}
        />
      </ModalScrollablePart>
      <ModalFooter>
        <CustomButtonLink size="large" onClick={onClose}>
          {translate('cancel')}
        </CustomButtonLink>
        <CustomButton
          size="large"
          width="10.625rem"
          kind={isButtonEnabled ? 'primary' : 'disabled'}
          onClick={onUpdatePassword}
        >
          {isLoading ? <Loader /> : translate('verify_pass')}
        </CustomButton>
      </ModalFooter>
    </>
  );
};
