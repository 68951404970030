// libs
import { pick } from 'ramda';
import { call, put, select } from 'typed-redux-saga';

// selectors
import { selectAppDetailsTabDataNew } from 'redux/selectors';

// actions
import { pushNotification } from 'redux/actions/app';
import { setTrustRequirementsShouldUpdate } from 'redux/actions/trust-requirements';

// constants
import { ROUTE_PATHS } from 'appConstants';

// utils
import { ApiTypeCaller } from '../../api/utils';
import { translator, demodifyTrustRequirementDetails } from 'utils';
import { orgPush } from 'redux/sagas/utils';

// types
import { INotificationType, ITrustRequirementDetailModified } from 'types';

export function* createTrustRequirementSaga() {
  const translate = translator('notifications');

  const newTrustRequirement: ITrustRequirementDetailModified = yield select(
    selectAppDetailsTabDataNew
  );

  const payload = pick(
    ['description', 'type', 'notes', 'settings'],
    demodifyTrustRequirementDetails(newTrustRequirement)
  );

  const { result, success } = yield* ApiTypeCaller('createTrustRequirement', payload);

  if (success && result) {
    yield call(orgPush, `${ROUTE_PATHS['trust-requirements']}/${result.id}`, { keepSearch: true });
    yield put(setTrustRequirementsShouldUpdate());
    yield put(
      pushNotification({
        showType: INotificationType.Success,
        description: translate('trust_requirement_created', {
          requirementId: result.id,
          description: result.description,
        }),
      })
    );
  }
}
