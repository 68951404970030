import styled, { css, CSSProp } from 'styled-components';
import { Input as AntdInput } from 'antd';
import { CustomText, stylesMap } from 'typography/Text';
import { TextTypes } from 'types';

export type SizeType = 'large' | 'middle';

type TDProps = {
  height?: string;
};

type InputProps = {
  mRight?: string;
  mTop?: string;
  $sharpborders?: boolean;
  ref?: any;
};

type InputWrapperProps = {
  mRight?: string;
  mTop?: string;
  textStyle?: TextTypes;
  width?: string;
  size: SizeType;
  additionalCss?: CSSProp;
  additionalHoverCss?: CSSProp;
  additionalFocusCss?: CSSProp;
} & IColor;

type IconProps = {
  size: SizeType;
};

type IColor = {
  disabled?: boolean;
  warning?: boolean;
  error?: boolean;
};

export const cssColor = ({ disabled, error, warning }: IColor) => css`
  ${({ theme: { palette } }) =>
    `${
      disabled
        ? palette.fills.strokeMedium
        : error
        ? palette.state.error
        : warning
        ? palette.state.alert
        : palette.background.iconBackground
    }
     `}
`;

export const cssHoverColor = ({ disabled, error, warning }: IColor) => css`
  ${({ theme: { palette } }) =>
    `${
      disabled
        ? palette.fills.strokeMedium
        : error
        ? palette.state.error
        : warning
        ? palette.state.alert
        : palette.fills.stroke
    } `}
`;

export const cssFocusColor = ({ disabled, error, warning }: IColor) => css`
  ${({ theme: { palette } }) =>
    `${
      disabled
        ? palette.fills.strokeMedium
        : error
        ? palette.state.error
        : warning
        ? palette.state.alert
        : palette.fills.primary
    }
     `}
`;

export const InputStc = styled.div<IconProps>`
  width: ${({ size }) => (size === 'large' ? '1.5rem' : '1.25rem')};
  height: ${({ size }) => (size === 'large' ? '1.5rem' : '1.25rem')};
`;

export const TextDecorated = styled(CustomText)<TDProps>`
  min-height: 1.25rem;
  color: ${({ color }) => color};
  height: ${({ height }) => height || 'auto'};
`;

export const Input = styled(AntdInput)<InputProps>`
  border-radius: ${({ $sharpborders }) => (!$sharpborders ? '0.25rem' : 'unset')};
  height: ${({ size }) => (size === 'large' ? '2.5rem' : '2rem')};
  padding: 0 0.75rem !important;

  input {
    color: ${({ theme, color, disabled }) =>
      disabled ? theme.palette.fonts.disabled : color || theme.palette.fonts.bodyDark} !important;
  }
`;

export const InputWrapper = styled.div<InputWrapperProps>`
  border-radius: unset;
  display: flex;
  flex-direction: column;
  width: ${({ width }) => (width ? width : '100%')};

  margin-right: ${({ mRight }) => mRight || 0};
  margin-top: ${({ mTop }) => mTop || 0};

  input::placeholder {
    ${({ textStyle }) => (textStyle ? stylesMap[textStyle] : stylesMap['body-1'])};
    color: ${({ theme }) => theme.palette.fonts.disabled};
  }
  input {
    ${({ textStyle }) => textStyle && stylesMap[textStyle]};
  }

  .ant-input-affix-wrapper {
    box-shadow: none;

    border: 0.075rem solid
      ${({ disabled, error, warning }) => cssColor({ disabled, error, warning })};
    ${({ additionalCss }) => additionalCss};

    &:hover {
      border: 0.075rem solid
        ${({ disabled, error, warning }) => cssHoverColor({ disabled, error, warning })};
      ${({ additionalHoverCss }) => additionalHoverCss};
    }

    &:focus-within {
      border: 0.075rem solid
        ${({ disabled, error, warning }) => cssFocusColor({ disabled, error, warning })};
      ${({ additionalFocusCss }) => additionalFocusCss};
    }
  }
`;

export const SpanStyledAsLargeInput = styled(CustomText)`
  display: flex;
  align-items: flex-start;

  border: 0.0625rem solid transparent;
  margin: 0 -0.0625rem;
  padding: 0rem 2.25rem 1.5rem 0rem;
  box-sizing: border-box;
  min-height: 3.75rem;

  word-break: break-all;
`;
