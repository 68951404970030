// libs
import { put } from 'typed-redux-saga';

// actions
import { getAccount, setInviteStatus } from 'redux/actions/account';
import { setModalContent, setModalIsLoading, pushNotification } from 'redux/actions/app';

// utils
import { ApiTypeCaller } from '../../api/utils';
import { translator } from 'utils';

// types
import { IActionPayload, IDeclineOrganisationInvite, INotificationType } from 'types';

export function* declineInviteSaga({
  payload: { orgId, fromModal = false },
}: IActionPayload<IDeclineOrganisationInvite>) {
  if (fromModal) {
    yield put(setModalIsLoading(true));
  }

  const { success, result } = yield* ApiTypeCaller('declineInvite', { orgId });

  if (success) {
    yield put(setInviteStatus({ orgId, status: 'declined' }));
    yield put(getAccount());
  }

  if (fromModal) {
    yield put(setModalIsLoading(false));
    yield put(setModalContent({ type: null, data: null }));
  }

  if (success) {
    const translate = translator('notifications');

    yield put(
      pushNotification({
        showType: INotificationType.Success,
        description: translate('org_declined', { name: result?.orgName }),
      })
    );
  }
}
