// components
import { CustomText } from 'typography/Text';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';

// selectors
import { selectAppDetailsTrustRequirementMicrosoftAuthorityGroupId } from 'redux/selectors';

export const GroupIdView = () => {
  const { translate } = useTranslations('trust-requirements');

  const {
    palette: { fonts },
  } = useTheme();

  const groupId = useSelector(selectAppDetailsTrustRequirementMicrosoftAuthorityGroupId);

  return (
    <CustomText type="body-2" color={fonts.title}>
      {groupId ||
        translate(
          'details.types.UserAuthentication.authorities.azure.security_group_id_empty_state'
        )}
    </CustomText>
  );
};
