// components
import { UserAuthentication } from './UserAuthentication';

// hooks
import { useSelector } from 'react-redux';

// selectors
import { selectAppDetailsTrustRequirementCurrentType } from 'redux/selectors';

export const Configuration = () => {
  const type = useSelector(selectAppDetailsTrustRequirementCurrentType);

  if (type === 'UserAuthentication') return <UserAuthentication />;

  return null;
};
