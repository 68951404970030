// libs
import { Task } from 'redux-saga';
import { CallEffect, ForkEffect, fork } from 'redux-saga/effects';
import { call } from 'typed-redux-saga';
import { preloadUserDataSaga } from './preloadUserDataSaga';
import { validateUserAuthSaga } from './validateUserAuthSaga';

let task: Task | ForkEffect | null | any = null;

function* checkUserAuth() {
  const { success, profile } = yield* validateUserAuthSaga();

  if (success && profile) yield* call(preloadUserDataSaga, profile);

  task = null;
}

export function* checkUserAuthSaga(): IterableIterator<CallEffect | ForkEffect> {
  // Saga wrapper because takeLeading doesn't work as expected
  if (!task) {
    task = yield fork(checkUserAuth);
  }
}
