// libs
import { css, CSSProp } from 'styled-components';

// components
import { Radio } from 'antd';
import { RadioProps } from 'antd/lib/radio';
import { CustomText } from 'typography/Text';

interface IRadioSwitchElement {
  value: string;
  text: string;
  icon?: React.ReactNode;
}
interface IRadioSwitch extends RadioProps {
  left: IRadioSwitchElement;
  right: IRadioSwitchElement;
  cssPassed?: CSSProp;
}
export const RadioSwitch = ({ left, right, cssPassed, ...rest }: IRadioSwitch) => {
  return (
    <Radio.Group
      size="small"
      css={css`
        svg {
          margin-right: 0.25rem;
        }

        .ant-radio-button-wrapper {
          box-shadow: none !important;
          border-width: 0.0625rem;
          padding: 0 0.5rem;
          height: 2rem;
          line-height: 2rem;

          :first-child {
            border-right-width: 0;
          }

          &.ant-radio-button-wrapper-checked:first-child {
            border-right-width: 0.0625rem;
          }

          :last-child {
            border-left-width: 0;
          }

          &.ant-radio-button-wrapper-checked:last-child {
            border-left-width: 0.0625rem;
          }
        }

        .ant-radio-button-wrapper:first-child {
          border-radius: 0.25rem 0 0 0.25rem;
        }
        .ant-radio-button-wrapper:last-child {
          border-radius: 0 0.25rem 0.25rem 0;
        }

        .ant-radio-button-wrapper {
          display: inline-flex;
          align-items: center;
        }

        .ant-radio-button-wrapper:not(.ant-radio-button-wrapper-checked) {
          border-color: ${({ theme }) => theme.palette.fills.strokeMedium};

          span .ant-typography {
            color: ${({ theme }) => theme.palette.fonts.bodyLight};
          }
        }

        .ant-radio-button-wrapper::before {
          display: none;
        }

        .ant-radio-button-wrapper.ant-radio-button-wrapper-checked,
        .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
          background-color: ${({ theme }) => theme.palette.background.hover};
          border-color: ${({ theme }) => theme.palette.fills.primary};

          span .ant-typography {
            color: ${({ theme }) => theme.palette.fills.primary};
          }
        }
        ${cssPassed}
      `}
      {...rest}
    >
      <Radio.Button value={left.value}>
        {left.icon && left.icon}
        <CustomText type="body-2">{left.text}</CustomText>
      </Radio.Button>
      <Radio.Button value={right.value}>
        {right.icon && right.icon}
        <CustomText type="body-2">{right.text}</CustomText>
      </Radio.Button>
    </Radio.Group>
  );
};
