// components
import { CustomText, StyledSubtitle } from 'typography/Text';
import { EmailNotificationToggle } from '../EmailNotificationToggle';
import { EmailNotificationRow, Wrapper } from './styled';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { useTheme } from 'styled-components';

export const SettingsBlockForeign = () => {
  const { translate } = useTranslations('account');

  const {
    palette: { fonts },
  } = useTheme();

  return (
    <Wrapper>
      <StyledSubtitle>{translate('settings')}</StyledSubtitle>

      <EmailNotificationRow>
        <CustomText type="sub-heading-1" color={fonts.bodyLight}>
          {translate('email_notification')}
        </CustomText>
        <EmailNotificationToggle />
      </EmailNotificationRow>

      <CustomText type="body-2" color={fonts.bodyLight}>
        {translate('email_notification_description')}
      </CustomText>
    </Wrapper>
  );
};
