// libs
import { css, useTheme } from 'styled-components';

// components
import { OSIcon } from 'components';
import { CustomText } from 'typography/Text';
import { SystemsIconContainer, SystemsListWrapper, ListElement } from './styled';
import { ConnectedFrom } from './ConnectedFrom';

// types
import { ISystemReferenceModel, OSTypes } from 'types';

interface ISystemsProps {
  systems: ISystemReferenceModel[];
  suffix?: ({ onClick }: { onClick?: () => void }) => JSX.Element;
  onSuffixClick?: (system: ISystemReferenceModel) => void;
}

export const SystemsListWithConnectedStatus = ({
  systems,
  suffix: Suffix,
  onSuffixClick,
  ...rest
}: ISystemsProps) => {
  const {
    palette: { fonts },
  } = useTheme();

  return (
    <SystemsListWrapper {...rest}>
      {systems &&
        systems.map(system => {
          const { id, name, platformType, machineName, connectedFrom, state } = system;
          const title = name || machineName || id;
          return (
            <ListElement key={id}>
              <div>
                <SystemsIconContainer>
                  <OSIcon
                    platformType={platformType as OSTypes}
                    css={css`
                      margin-right: 0.375rem;
                    `}
                  />
                  <CustomText
                    ellipsis={true}
                    type="body-2"
                    color={fonts.bodyDark}
                    css={css`
                      white-space: nowrap;
                    `}
                    title={title}
                  >
                    {title}
                  </CustomText>
                </SystemsIconContainer>
                <ConnectedFrom connectedFrom={connectedFrom} state={state} />
              </div>
              {Suffix && <Suffix onClick={() => onSuffixClick?.(system)} />}
            </ListElement>
          );
        })}
    </SystemsListWrapper>
  );
};
