// components
import { SectionHeader } from '../../SectionHeader';
import { RecentActivityGridItem } from '../../styled';
import { List, ListItem } from './styled';
import { CustomText } from 'typography/Text';

// hooks
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';
import { css, useTheme } from 'styled-components';

// actions
import { getSecurityEvents } from 'redux/actions/security-events';

// selectors
import { selectCurrentOrganisationId, selectSecurityEventsItems } from 'redux/selectors';

// constants
import { ROUTE_PATHS } from 'appConstants';

// utils
import { formatTime } from 'utils';

export const RecentActivitySection = () => {
  const dispatch = useDispatch();
  const events = useSelector(selectSecurityEventsItems).slice(0, 5);
  const orgId = useSelector(selectCurrentOrganisationId);
  const { translate } = useTranslations('landing-page');
  const {
    palette: { fonts },
  } = useTheme();

  useEffect(() => {
    dispatch(
      getSecurityEvents({
        page: 0,
        per_page: 5,
      })
    );
  }, [dispatch, orgId]);

  return (
    <RecentActivityGridItem>
      <SectionHeader
        title={translate('recent_activity_section.title')}
        route={`${ROUTE_PATHS.events}`}
      />
      <List>
        {events.map(event => {
          return (
            <ListItem key={JSON.stringify(event)}>
              <CustomText type="body-2" color={fonts.bodyDark}>
                {event.message}
              </CustomText>
              <CustomText
                type="body-2"
                color={fonts.disabled}
                css={css`
                  margin-left: 0.5rem;
                `}
              >
                {`${formatTime({ date: event.timeStamp, format: 'ago' })}`}
              </CustomText>
            </ListItem>
          );
        })}
      </List>
    </RecentActivityGridItem>
  );
};
