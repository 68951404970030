// components
import { TooltipTop, Wrapper, Button } from './styled';
import { IconTextCopy, IconCopySuccess } from 'assets/svg';

// hooks
import { useCallback, useState } from 'react';
import { useTranslations } from 'hooks/useTranslations';
import { css, useTheme } from 'styled-components';

interface Props {
  disabled?: boolean;
  copied: boolean;
  onCopyClick: () => void;
  onMouseLeave?: () => void;
  secondary?: boolean;
  iconSize?: string;
}

export const CopyTool = ({
  onCopyClick,
  copied,
  disabled,
  secondary,
  onMouseLeave,
  iconSize,
  ...rest
}: Props) => {
  const {
    palette: { fonts, background },
  } = useTheme();

  const { translate } = useTranslations('global');

  const [isFocused, setIsFocused] = useState(false);

  const onFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const onBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  const onClick = useCallback(() => {
    !disabled && onCopyClick();
  }, [disabled, onCopyClick]);

  return (
    <Wrapper onMouseLeave={onMouseLeave} showTooltip={isFocused} {...rest}>
      {!disabled ? (
        <TooltipTop
          copied={copied}
          backgroundcolor={fonts.title}
          color={background.panel}
          className="tooltiptext"
          css={css`
            box-shadow: ${({ theme }) => theme.palette.elevationShadows[400]};
          `}
        >
          {copied ? translate('copy_tooltip.copied') : translate('copy_tooltip.copy')}
        </TooltipTop>
      ) : null}
      <Button
        aria-label={translate('copy_tooltip.aria_labels.copy')}
        disabled={disabled}
        onFocus={onFocus}
        onBlur={onBlur}
        onClick={onClick}
        $iconSize={iconSize}
        $secondary={secondary}
      >
        {copied && !secondary ? <IconCopySuccess /> : <IconTextCopy />}
      </Button>
    </Wrapper>
  );
};
