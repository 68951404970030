// libs
import { css, useTheme } from 'styled-components';

// components
import {
  CardWrapper,
  CardContainer,
  ContentContainer,
  DescriptionText,
  IsYearlyToggleContainer,
  ModifyContentContainer,
  StaticContentContainer,
} from '../styled';
import { PlanHeader, Pricing, FeatureList } from './components';
import { CustomText } from 'typography/Text';
import { CustomProgress, CustomButton, CustomMessage, CustomToggle, InputNumber } from 'components';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';

// selectors
import {
  selectCurrentOrganisationCapacity,
  selectCurrentOrganisationInfo,
  selectCurrentOrganisationIsOwner,
  selectCurrentOrganisationBillingIsYearly,
  selectCurrentOrganisationBillingRate,
  selectOrganisationBusinessPlanYearly,
  selectCurrentOrganisationBillingDisableUpdate,
} from 'redux/selectors';

// types
import { IPricingModel } from 'types';

// actions
import { setModalContent } from 'redux/actions/app';

// constants
import {
  BUSINESS_PLAN,
  DEFAULT_BUSINESS_CAPACITY,
  MAX_BUSINESS_CAPACITY,
  MIN_BUSINESS_CAPACITY,
  REGEX_NON_DIGIT,
  STARTER_PLAN,
} from 'appConstants';

// utils
import { formatCurrencyAmount } from 'utils';

interface PlanCardProps {
  plan: IPricingModel;
}

const type = BUSINESS_PLAN;

export const PlanCardBusiness = ({ plan }: PlanCardProps) => {
  const {
    palette: { fonts },
  } = useTheme();

  const dispatch = useDispatch();

  const { translate } = useTranslations('settings');

  const { enabled, currencySymbol, rate } = plan;

  const orgData = useSelector(selectCurrentOrganisationInfo);
  const isOwner = useSelector(selectCurrentOrganisationIsOwner);
  const currentCapacity = useSelector(selectCurrentOrganisationCapacity);
  const currentIsYearly = useSelector(selectCurrentOrganisationBillingIsYearly);
  const currentRate = useSelector(selectCurrentOrganisationBillingRate);
  const businessPlanYearly = useSelector(selectOrganisationBusinessPlanYearly);
  const disableUpdate = useSelector(selectCurrentOrganisationBillingDisableUpdate);

  const isCurrentPlan = orgData.plan === type;

  const [isYearly, setIsYearly] = useState(currentIsYearly || false);
  const [capacity, setCapacity] = useState(
    isCurrentPlan ? currentCapacity : DEFAULT_BUSINESS_CAPACITY
  );

  const newRate =
    isCurrentPlan && currentRate ? currentRate : isYearly ? businessPlanYearly?.rate || rate : rate;
  const formattedRate = formatCurrencyAmount({ value: newRate });

  const handlePlanUpgrade = () => {
    dispatch(
      setModalContent({
        type: 'update-plan-capacity',
        data: {
          type,
          capacity,
          currencySymbol,
          isCurrentPlan,
          isYearly,
          rate: newRate,
        },
      })
    );
  };

  const toggleIsYearly = () => {
    setIsYearly(isYearly => !isYearly);
  };

  const setNumberValue = (value: string) => {
    const parsedValue = value.includes('-') ? 0 : value.replace(REGEX_NON_DIGIT, '');
    const resultValue = Math.max(Math.min(Number(parsedValue), MAX_BUSINESS_CAPACITY));

    setCapacity(Number(resultValue));
  };

  return (
    <CardWrapper enabled={enabled}>
      <PlanHeader
        text={`${translate('plans.n_systems_used', {
          from: orgData.enrolledSystems,
          to: orgData.maxSystems,
        })}`}
        active={isCurrentPlan && enabled}
      />
      <CardContainer active={isCurrentPlan}>
        {isCurrentPlan && enabled ? (
          <CustomProgress percent={(orgData.enrolledSystems / orgData.maxSystems) * 100} sharp />
        ) : (
          <CustomProgress active={false} percent={100} sharp color="transparent" />
        )}
        <ContentContainer>
          <StaticContentContainer>
            <CustomText
              type="sub-heading-1"
              css={css`
                margin-bottom: 0.25rem;
              `}
              color={enabled ? fonts.bodyDark : fonts.disabled}
            >
              {translate(`plans.${type.toLowerCase()}`)}
            </CustomText>
            <Pricing symbol={currencySymbol} rate={formattedRate} type={type} />
            <DescriptionText
              type="hint"
              color={enabled && isOwner ? fonts.bodyLight : fonts.disabled}
            >
              {translate(`plans.${type.toLowerCase()}_description`)}
            </DescriptionText>
            <FeatureList type="Business" />
          </StaticContentContainer>
          {!disableUpdate && isOwner && orgData.plan === STARTER_PLAN && (
            <ModifyContentContainer>
              <IsYearlyToggleContainer>
                <CustomText
                  css={css`
                    flex: 1;
                    text-align: right;
                  `}
                  type="hint"
                  color={fonts.title}
                >
                  {translate('plans.switch_label_monthly')}
                </CustomText>
                <CustomToggle
                  containerCss={css`
                    margin-left: 0.5rem;
                    margin-right: 0.5rem;
                    flex: 0;
                  `}
                  aria-label={translate('aria_labels.switch_is_yearly')}
                  enabled={isYearly}
                  onClick={toggleIsYearly}
                ></CustomToggle>
                <div
                  css={css`
                    flex: 1;
                    text-align: left;
                  `}
                >
                  <CustomText type="hint" color={fonts.title}>
                    {translate('plans.switch_label_yearly')}
                    <br />
                    <small>{translate('plans.switch_label_discount')}</small>
                  </CustomText>
                </div>
              </IsYearlyToggleContainer>
              <CustomText
                css={css`
                  margin-bottom: 0.5rem;
                `}
                type="hint"
                color={fonts.title}
              >
                {translate('plans.add_more_systems')}
              </CustomText>

              <InputNumber
                type="text"
                size="middle"
                value={capacity}
                stateMessageCss={css`
                  display: none;
                `}
                labelCss={css`
                  display: none;
                `}
                setValue={setNumberValue}
                width="8.25rem"
              />
            </ModifyContentContainer>
          )}
          {!disableUpdate && enabled && isOwner && !isCurrentPlan && (
            <CustomButton
              disabled={capacity < MIN_BUSINESS_CAPACITY}
              kind="primary"
              onClick={handlePlanUpgrade}
              size="large"
              css={css`
                align-self: center;
              `}
            >
              {translate('plans.upgrade_plan')}
            </CustomButton>
          )}
          {!isOwner && (
            <CustomMessage
              withIcon={true}
              textType="hint"
              type="info"
              message={translate('plans.upgrade_plan_info')}
              css={css`
                padding: 1rem;
                align-self: center;
                width: calc(100% - 2rem);
              `}
            />
          )}
          {isOwner && disableUpdate && (
            <CustomMessage
              withIcon={true}
              textType="hint"
              type="info"
              message={translate('plans.update_disabled_info')}
              css={css`
                padding: 1rem;
                align-self: center;
                width: calc(100% - 2rem);
              `}
            />
          )}
        </ContentContainer>
      </CardContainer>
    </CardWrapper>
  );
};
