// Lib
import { put } from 'typed-redux-saga';
import { setAccount } from 'redux/actions/account';

// Utils
import { ApiTypeCaller } from '../../api/utils';

export function* getAccountSaga() {
  const { result, success } = yield* ApiTypeCaller('getAccount');

  if (success && result) {
    yield put(setAccount(result));
  }
  return success;
}
