// libs
import { createContext, useState } from 'react';

// components
import { Details } from 'components';
import { TopMenuButtons, Header, Overview, CancelSave, Targets } from './Sections';

export const ScrolledOnTopContext = createContext(true);

export const DnsRecordDetails = () => {
  const [scrolledOnTop, setScrolledOnTop] = useState(true);

  return (
    <Details.Pane data-test="dns-record-details-content">
      <Details.ContentWrapper
        onScroll={el => {
          const newScrolledOnTop = el.currentTarget?.scrollTop === 0;
          if (newScrolledOnTop !== scrolledOnTop) {
            setScrolledOnTop(newScrolledOnTop);
          }
        }}
      >
        <Details.ScrollPaddingContainer wide={false}>
          <ScrolledOnTopContext.Provider value={scrolledOnTop}>
            <TopMenuButtons />
            <Header />
            <Overview />
            <Targets />
          </ScrolledOnTopContext.Provider>
        </Details.ScrollPaddingContainer>
      </Details.ContentWrapper>
      <CancelSave />
    </Details.Pane>
  );
};
