import {
  updatePolicyDetails,
  updatePolicyDetailsAcl,
  deletePolicyDetailsAcl,
  createPolicyDetailsAcl,
  loadPolicyDetailsWithNewRule,
  createPolicyDetailsAllowedSubnet,
  deletePolicyDetailsAllowedSubnet,
  updatePolicyDetailsAllowedSubnet,
  setPolicyDetailsAllowedSubnets,
  addPolicyDetailsGateway,
  removePolicyDetailsGateway,
  createPolicyDetailsActiveHours,
  updatePolicyDetailsActiveHours,
  createPolicyDetailsAutoExpire,
  updatePolicyDetailsAutoExpire,
  movePolicyDetailsGatewayUp,
  movePolicyDetailsGatewayDown,
} from '../../../actions/app';

import { IPolicyModifiedAcl, IPolicyModifiedGatewayAllowedIpRange } from 'types';

import {
  POLICY_INITIAL_VALIDATION_STATE,
  POLICY_PORTS_VALIDATION_STATE_PREFIX,
  POLICY_PROTOCOL_VALIDATION_STATE_PREFIX,
  POLICY_SUBNET_VALIDATION_STATE_PREFIX,
  POLICY_TRAFFIC_VALIDATION_STATE_PREFIX,
} from 'appConstants/validation';

import { randomHash } from 'utils/hash';
import {
  getPolicyAclsValidationState,
  getTimeZoneOffset,
  translator,
  validateIpRangeString,
} from 'utils';
import { validatePortsString } from 'modules/Policies/Details/Blocks/ReceiverAccessControls/helpers';
import { appReducerBuilder } from '..';
import {
  REGEX_HYPHEN_WITH_SPACES,
  DEFAULT_POLICY_ACTIVE_HOURS,
  DEFAULT_AUTO_EXPIRE_DATA,
  defaultTimeZone,
} from 'appConstants';
import dayjs from 'dayjs';

const translate = translator('trust-requirements');

export const detailsTabPoliciesReducerSlice: appReducerBuilder = builder => {
  builder
    .addCase(updatePolicyDetails, (state, { payload }) => {
      if (state.detailsTab.type === 'policy-edit') {
        state.detailsTab.dataNew = {
          ...state.detailsTab.dataNew,
          ...payload,
        };
      }

      if (state.detailsTab.type === 'policy-create') {
        state.detailsTab.dataNew = {
          ...state.detailsTab.dataNew,
          ...payload,
        };
      }
    })
    .addCase(createPolicyDetailsAcl, state => {
      const id = randomHash();

      if (state.detailsTab.type === 'policy-edit' || state.detailsTab.type === 'policy-create') {
        const addedAcls: IPolicyModifiedAcl[] = [
          {
            protocol: null,
            description: null,
            id,
            ports: null,
          },
        ];
        state.detailsTab.dataNew.acls = [...state.detailsTab.dataNew.acls, ...addedAcls];

        state.detailsTab.validationState = {
          ...state.detailsTab.validationState,
          ...getPolicyAclsValidationState({ acls: addedAcls }),
        };
      }
    })
    .addCase(updatePolicyDetailsAcl, (state, { payload }) => {
      if (state.detailsTab.type === 'policy-edit' || state.detailsTab.type === 'policy-create') {
        state.detailsTab.dataNew.acls = state.detailsTab.dataNew.acls.map(acl => {
          if (acl.id === payload.id) {
            const newAcl = acl;

            // if we change DEFAULT protocol we also change description text, if it hasn't been
            // changed before
            if (
              state.detailsTab.type === 'policy-create' &&
              payload.newDescription === undefined &&
              payload.newProtocol &&
              payload.newProtocol !== acl.protocol &&
              acl.isDescriptionModified === false
            ) {
              newAcl.description = null;
            }

            if (
              state.detailsTab.type === 'policy-edit' ||
              state.detailsTab.type === 'policy-create'
            ) {
              if (
                (payload.newProtocol !== undefined || payload.newPorts !== undefined) &&
                (payload.newProtocol !== acl.protocol || payload.newPorts !== acl.ports)
              ) {
                state.detailsTab.validationState[POLICY_TRAFFIC_VALIDATION_STATE_PREFIX + acl.id] =
                  { state: { error: null }, updated: true };
              }

              if (payload.newProtocol !== undefined && payload.newProtocol !== acl.protocol) {
                newAcl.protocol = payload.newProtocol;

                state.detailsTab.validationState[POLICY_PROTOCOL_VALIDATION_STATE_PREFIX + acl.id] =
                  { state: { error: null }, updated: true };
              }

              if (payload.newPorts !== undefined && payload.newPorts !== acl.ports) {
                const updated =
                  payload.newPorts !== null &&
                  acl.ports !== null &&
                  payload?.renewPortsUpdateStatus !== true
                    ? true
                    : false;
                newAcl.ports = payload.newPorts;

                const validationState = validatePortsString({ ports: payload.newPorts });

                // if it was not disabled before and is not disabled afterwards is the only case when
                // it's update of previous ports value
                state.detailsTab.validationState[POLICY_PORTS_VALIDATION_STATE_PREFIX + acl.id] = {
                  state: validationState,
                  updated,
                };
              }

              if (
                payload.newDescription !== undefined &&
                payload.newDescription !== acl.description
              ) {
                newAcl.description = payload.newDescription;

                if (acl.isDescriptionModified === false) {
                  newAcl.isDescriptionModified = true;
                }
              }
            }

            return newAcl;
          } else return acl;
        });
      }
    })
    .addCase(deletePolicyDetailsAcl, (state, { payload }) => {
      if (state.detailsTab.type === 'policy-edit' || state.detailsTab.type === 'policy-create') {
        state.detailsTab.dataNew.acls = state.detailsTab.dataNew.acls.filter(
          acl => acl.id !== payload.id
        );

        delete state.detailsTab.validationState[
          POLICY_TRAFFIC_VALIDATION_STATE_PREFIX + payload.id
        ];
        delete state.detailsTab.validationState[
          POLICY_PROTOCOL_VALIDATION_STATE_PREFIX + payload.id
        ];
        delete state.detailsTab.validationState[POLICY_PORTS_VALIDATION_STATE_PREFIX + payload.id];
      }
    })
    .addCase(loadPolicyDetailsWithNewRule, state => {
      if (state.detailsTab.type === 'policy-view') {
        const newAcls = [{ id: randomHash(), protocol: null, ports: null, description: null }];

        state.detailsTab = {
          ...state.detailsTab,
          type: 'policy-edit',
          scrollToReceiverAccessControls: true,
          dataNew: { ...state.detailsTab.data, acls: newAcls },
          validationState: {
            ...POLICY_INITIAL_VALIDATION_STATE,
            ...getPolicyAclsValidationState({ acls: newAcls }),
          },
        };
      }
    });
  builder.addCase(createPolicyDetailsAllowedSubnet, (state, { payload }) => {
    if (
      (state.detailsTab.type === 'policy-edit' || state.detailsTab.type === 'policy-create') &&
      state.detailsTab.dataNew
    ) {
      const addedSubnet: IPolicyModifiedGatewayAllowedIpRange = {
        id: randomHash(),
        description: '',
        ipRange: '',
        ...payload,
      };

      state.detailsTab.dataNew.gatewayAllowedIpRanges.push(addedSubnet);

      state.detailsTab.validationState = {
        ...state.detailsTab.validationState,
        [POLICY_SUBNET_VALIDATION_STATE_PREFIX + addedSubnet.id]: {
          state: { error: { message: translate('details.subnet_error') } },
          updated: false,
        },
      };
    }
  });
  builder.addCase(updatePolicyDetailsAllowedSubnet, (state, { payload }) => {
    if (
      (state.detailsTab.type === 'policy-edit' || state.detailsTab.type === 'policy-create') &&
      state.detailsTab.dataNew
    ) {
      const index = state.detailsTab.dataNew.gatewayAllowedIpRanges.findIndex(
        subnet => subnet.id === payload.id
      );

      if (index > -1) {
        if (payload.range.ipRange !== undefined) {
          const validationState = validateIpRangeString({ ipRange: payload.range.ipRange });

          state.detailsTab.validationState[POLICY_SUBNET_VALIDATION_STATE_PREFIX + payload.id] = {
            state: validationState,
            updated: true,
          };

          if (!validationState.error && payload.range.ipRange) {
            payload.range.ipRange = payload.range?.ipRange?.replace(
              REGEX_HYPHEN_WITH_SPACES,
              ' - '
            );
          }
        }

        state.detailsTab.dataNew.gatewayAllowedIpRanges[index] = {
          ...state.detailsTab.dataNew.gatewayAllowedIpRanges[index],
          ...payload.range,
        };
      }
    }
  });
  builder
    .addCase(deletePolicyDetailsAllowedSubnet, (state, { payload }) => {
      if (
        (state.detailsTab.type === 'policy-edit' || state.detailsTab.type === 'policy-create') &&
        state.detailsTab.dataNew
      ) {
        const index = state.detailsTab.dataNew.gatewayAllowedIpRanges.findIndex(
          condition => condition.id === payload.id
        );

        if (index > -1) {
          state.detailsTab.dataNew.gatewayAllowedIpRanges.splice(index, 1);
          delete state.detailsTab.validationState[
            POLICY_SUBNET_VALIDATION_STATE_PREFIX + payload.id
          ];
        }
      }
    })
    .addCase(setPolicyDetailsAllowedSubnets, (state, { payload }) => {
      if (state.detailsTab.type === 'policy-edit' || state.detailsTab.type === 'policy-create') {
        state.detailsTab.dataNew.gatewayAllowedIpRanges = payload;
      }
    })
    .addCase(addPolicyDetailsGateway, (state, { payload }) => {
      if (state.detailsTab.type === 'policy-edit' || state.detailsTab.type === 'policy-create') {
        const index = state.detailsTab.dataNew.gateways.findIndex(
          gateway => gateway.systemId === payload.systemId
        );

        if (index !== -1) {
          state.detailsTab.dataNew.gateways[index].routes.push(payload.routeEntry);
        } else {
          state.detailsTab.dataNew.gateways.push({
            systemId: payload.systemId,
            routes: [payload.routeEntry],
            systemName: payload.systemName,
            machineName: payload.machineName,
          });
        }
      }
    })
    .addCase(movePolicyDetailsGatewayUp, (state, { payload }) => {
      if (state.detailsTab.type === 'policy-edit' || state.detailsTab.type === 'policy-create') {
        const index = state.detailsTab.dataNew.gateways.findIndex(
          gateway => gateway.systemId === payload
        );

        if (index > 0) {
          const existingItem = state.detailsTab.dataNew.gateways[index];
          const priorItem = state.detailsTab.dataNew.gateways[index - 1];

          // Take the existing item and the one before it out, and put them back in the opposite order.
          state.detailsTab.dataNew.gateways.splice(index - 1, 2, existingItem, priorItem);
        }
      }
    })
    .addCase(movePolicyDetailsGatewayDown, (state, { payload }) => {
      if (state.detailsTab.type === 'policy-edit' || state.detailsTab.type === 'policy-create') {
        const index = state.detailsTab.dataNew.gateways.findIndex(
          gateway => gateway.systemId === payload
        );

        // Don't do anything if this is the last item.
        if (index !== -1 && index < state.detailsTab.dataNew.gateways.length - 1) {
          const existingItem = state.detailsTab.dataNew.gateways[index];
          const nextItem = state.detailsTab.dataNew.gateways[index + 1];

          // Take the existing item and the one before it out, and put them back in the opposite order.
          state.detailsTab.dataNew.gateways.splice(index, 2, nextItem, existingItem);
        }
      }
    })
    .addCase(removePolicyDetailsGateway, (state, { payload }) => {
      if (state.detailsTab.type === 'policy-edit' || state.detailsTab.type === 'policy-create') {
        const gatewayIndex = state.detailsTab.dataNew.gateways.findIndex(
          gateway => gateway.systemId === payload.systemId
        );

        if (gatewayIndex !== -1) {
          const routeIndex = state.detailsTab.dataNew.gateways[gatewayIndex].routes.findIndex(
            routeEntry => routeEntry.route === payload.routeEntry.route
          );

          state.detailsTab.dataNew.gateways[gatewayIndex].routes.splice(routeIndex, 1);

          if (state.detailsTab.dataNew.gateways[gatewayIndex].routes.length === 0) {
            state.detailsTab.dataNew.gateways.splice(gatewayIndex, 1);
          }
        }
      }
    })
    .addCase(createPolicyDetailsActiveHours, state => {
      if (
        (state.detailsTab.type === 'policy-edit' || state.detailsTab.type === 'policy-create') &&
        !state.detailsTab.dataNew.activeHours
      ) {
        state.detailsTab.dataNew.activeHours = {
          ...DEFAULT_POLICY_ACTIVE_HOURS,
        };
      }
    })
    .addCase(updatePolicyDetailsActiveHours, (state, { payload }) => {
      if (state.detailsTab.type === 'policy-edit' || state.detailsTab.type === 'policy-create') {
        if (payload === null) {
          state.detailsTab.dataNew.activeHours = null;
        } else {
          //@ts-ignore
          state.detailsTab.dataNew.activeHours = {
            ...state.detailsTab.dataNew.activeHours,
            ...payload,
          };
        }
      }
    })
    .addCase(createPolicyDetailsAutoExpire, state => {
      if (
        (state.detailsTab.type === 'policy-edit' || state.detailsTab.type === 'policy-create') &&
        !state.detailsTab.dataNew.autoExpire
      ) {
        state.detailsTab.dataNew.autoExpire = {
          ...DEFAULT_AUTO_EXPIRE_DATA,
        };
      }
    })
    .addCase(updatePolicyDetailsAutoExpire, (state, { payload }) => {
      if (state.detailsTab.type === 'policy-edit' || state.detailsTab.type === 'policy-create') {
        if (payload === null) {
          state.detailsTab.dataNew.autoExpire = null;
        } else {
          let assignLocalTimezone = false;
          let reformatDateTime = false;
          const dateTime =
            payload.expiryDateTime || state.detailsTab.dataNew.autoExpire?.expiryDateTime;

          if (!state.detailsTab.dataNew.autoExpire?.timeZoneId && !payload.timeZoneId) {
            assignLocalTimezone = true;
          }

          if (!state.detailsTab.dataNew.autoExpire?.timeZoneId) {
            if (dateTime && dateTime?.length > 19) {
              reformatDateTime = true;
            }
          }

          //@ts-ignore
          state.detailsTab.dataNew.autoExpire = {
            ...state.detailsTab.dataNew.autoExpire,
            ...payload,
            ...(assignLocalTimezone
              ? {
                  timeZoneId: defaultTimeZone.zone,
                }
              : {}),
            ...(reformatDateTime
              ? {
                  expiryDateTime: dayjs
                    .utc(dateTime)
                    .utcOffset(
                      getTimeZoneOffset(
                        payload.timeZoneId ||
                          state.detailsTab.dataNew.autoExpire?.timeZoneId ||
                          defaultTimeZone.zone
                      )
                    )
                    .format('YYYY-MM-DDTHH:mm:ss'),
                }
              : {}),
          };
        }
      }
    });
};
