// libs
import styled, { css, useTheme } from 'styled-components';

// components
import {
  TypeIconContainer,
  TypeContainer,
  TypeContentContainer,
  LinkLikeTextContainer,
} from './styled';
import { IconTrustRequirementDetailsTypeLinkArrowLeft } from 'assets/svg';
import { CustomText } from 'typography/Text';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { useDispatch } from 'react-redux';

// actions
import { updateTrustRequirementDetails } from 'redux/actions/app';

// types
import { ITrustRequirementType, ITrustRequirementTypeConfig } from 'types';

interface IType {
  config: ITrustRequirementTypeConfig;
}

export const ArrowRightIcon = styled(IconTrustRequirementDetailsTypeLinkArrowLeft)`
  transform: rotate(180deg);
`;

export const Type = ({ config: { Icon, isActive, name } }: IType) => {
  const dispatch = useDispatch();

  const {
    palette: { fonts },
  } = useTheme();

  const { translate } = useTranslations('trust-requirements');

  const selectType = () => {
    dispatch(updateTrustRequirementDetails({ type: name as ITrustRequirementType }));
  };

  return (
    <TypeContainer
      data-test={`trust-requirement-select-${name}-type`}
      disabled={!isActive}
      onClick={selectType}
    >
      <TypeIconContainer isActive={isActive}>
        <Icon />
      </TypeIconContainer>
      <TypeContentContainer>
        <CustomText
          css={css`
            margin-bottom: 0.125rem;
          `}
          type="sub-heading-1"
          color={isActive ? fonts.title : fonts.disabled}
        >
          {translate(`details.types.${name}.name`)}
        </CustomText>
        <CustomText
          css={css`
            margin-bottom: 0.5rem;
          `}
          type="body-2"
          color={isActive ? fonts.bodyLight : fonts.disabled}
        >
          {translate(`details.types.${name}.select_type_description`)}
        </CustomText>
        {isActive ? (
          <LinkLikeTextContainer className="select-type-link">
            <CustomText type="body-2">{translate(`details.select_type.active`)}</CustomText>
            <ArrowRightIcon
              css={css`
                margin-left: 0.25rem;
              `}
            />
          </LinkLikeTextContainer>
        ) : (
          <CustomText
            css={css`
              align-self: end;
              margin-bottom: 0.5rem;
            `}
            type="body-2"
            color={isActive ? fonts.bodyLight : fonts.disabled}
          >
            {translate(`details.select_type.inactive`)}
          </CustomText>
        )}
      </TypeContentContainer>
    </TypeContainer>
  );
};
