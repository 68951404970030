// components
import { PolicyMembersCreate } from './Create';
import { PolicyMembersView } from './View';
import { PolicyMembersEdit } from './Edit';
import { CustomText } from 'typography/Text';

// hooks
import { useSelector } from 'react-redux';
import { css, useTheme } from 'styled-components';
import { useTranslations } from 'hooks/useTranslations';

// selectors
import { selectAppDetailsTabType } from 'redux/selectors';

export const PolicyMembersGatewayBlock = () => {
  const { translate } = useTranslations('policies');

  const type = useSelector(selectAppDetailsTabType);

  const {
    palette: { fonts },
  } = useTheme();

  return (
    <>
      <CustomText
        type="body-2"
        color={fonts.bodyLight}
        css={css`
          margin-left: 2rem;
        `}
      >
        {translate('details.policy_members_desc_gateway')}
      </CustomText>

      {type === 'policy-view' && <PolicyMembersView />}
      {type === 'policy-edit' && <PolicyMembersEdit />}
      {type === 'policy-create' && <PolicyMembersCreate />}
    </>
  );
};
