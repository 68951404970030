import React from 'react';
import { ModalContentContainer } from './styled';

interface IModalScrollablePart {
  children?: React.ReactNode;
  withMinHeight?: boolean;
}
export const ModalScrollablePart = ({
  children,
  withMinHeight = true,
  ...rest
}: IModalScrollablePart) => {
  return (
    <ModalContentContainer withMinHeight={withMinHeight} {...rest}>
      {children}
    </ModalContentContainer>
  );
};
