// components
import { Empty } from './Empty';
import { Suggestion } from './Suggestion';

// hooks
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

// selectors
import { selectSearchData } from 'redux/selectors';

// types
import { ISearchKey } from 'types';
import { IRange, ISearchQueryDetail } from '../helpers';
import { IInputArrowConfig, ISelectedIndex } from '..';

// utils
import { uniq } from 'ramda';

interface IIpAddressSuggestions {
  keyName: ISearchKey['name'];
  canHaveMultiple: ISearchKey['canHaveMultiple'];
  existingValues: ISearchQueryDetail['values'];
  addValue: (newValue: string) => void;
  setInputArrowNavigationConfig: (config: IInputArrowConfig) => void;
  selectedIndex: ISelectedIndex;
  range: IRange | null;
}

const IP_SEARCH_FIELD = 'connectedFrom';
const VIP_SEARCH_FIELD = 'virtualAddress';

export const IpAddressSuggestions = ({
  keyName,
  addValue,
  canHaveMultiple,
  existingValues,
  selectedIndex,
  setInputArrowNavigationConfig,
  range,
}: IIpAddressSuggestions) => {
  const data = useSelector(selectSearchData);

  // making sure not to show selected values in suggestions

  const filteredIps = useMemo(() => {
    if (data?.module === 'systems') {
      const searchField = keyName === 'ip' ? IP_SEARCH_FIELD : VIP_SEARCH_FIELD;

      const ips = uniq(data.items.map(item => item[searchField]).filter(ip => Boolean(ip)));

      if (!canHaveMultiple) {
        return { module: data.module, items: ips };
      }
      return {
        module: data.module,
        items: ips.filter(ip => {
          if (existingValues.some(existingValue => existingValue.value === ip)) {
            return false;
          }
          return true;
        }),
      };
    }

    if (data?.module === 'unapproved-systems') {
      const searchField = IP_SEARCH_FIELD;

      const ips = uniq(data.items.map(item => item[searchField]).filter(ip => Boolean(ip)));

      if (!canHaveMultiple) {
        return { module: data.module, items: ips };
      }

      return {
        module: data.module,
        items: ips.filter(ip => {
          if (existingValues.some(existingValue => existingValue.value === ip)) {
            return false;
          }
          return true;
        }),
      };
    }
  }, [canHaveMultiple, data, existingValues, keyName]);

  // for navigation input with UP and DOWN arrows
  useEffect(() => {
    if (filteredIps) {
      setInputArrowNavigationConfig({
        length: filteredIps.items.length,
        onEnter: () => {
          if (selectedIndex !== null) {
            const value = filteredIps.items?.[selectedIndex];

            value && addValue(value);
          }
        },
        isKeyInactive: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIndex, setInputArrowNavigationConfig, range, filteredIps?.items.length]);

  const Items = useMemo(() => {
    if (!filteredIps?.items.length) {
      return <Empty searchKeyName={keyName} />;
    }

    return filteredIps.items.map((ip, index) => {
      if (!ip) return null;

      return (
        <Suggestion
          isSelected={index === selectedIndex}
          key={ip}
          keyName={keyName}
          value={ip}
          onClick={() => addValue(ip)}
        />
      );
    });
  }, [filteredIps?.items, keyName, addValue, selectedIndex]);

  return <>{Items}</>;
};
