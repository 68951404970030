// libs
import { BaseSyntheticEvent, useEffect, useRef } from 'react';

// components
import { Details } from 'components';

// selectors
import {
  selectAppDetailsSystemNewDescription,
  selectAppDetailsTabType,
  selectAppDetailsSystemHostname,
} from 'redux/selectors';

// actions
import { updateSystemDetails } from 'redux/actions/app';

// hooks
import { useSelector, useDispatch } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';

// types
import { TextAreaRef } from 'antd/lib/input/TextArea';

const titleErrorKey = 'systemTitle';

export const TitleEdit = () => {
  const dispatch = useDispatch();

  const { translate } = useTranslations('systems');

  const type = useSelector(selectAppDetailsTabType);
  const newDescription = useSelector(selectAppDetailsSystemNewDescription);
  const hostname = useSelector(selectAppDetailsSystemHostname);

  const ref = useRef<TextAreaRef>(null);

  const onDescriptionChange = (event: BaseSyntheticEvent) => {
    dispatch(updateSystemDetails({ description: event.target.value }));
  };

  useEffect(() => {
    ref?.current?.focus();
  }, [ref, type]);

  return (
    <Details.Input
      ref={ref}
      name={titleErrorKey}
      value={newDescription || ''}
      placeholder={
        hostname
          ? translate('details.no_description_input', {
              description: hostname,
            })
          : translate('details.add_a_description')
      }
      onChange={onDescriptionChange}
    />
  );
};
