// Lib
import { put } from 'typed-redux-saga';

import { setDetailsContent } from 'redux/actions/app';

export function* createTagDetailsSaga() {
  yield put(
    setDetailsContent({
      type: 'tag-create',
      dataNew: {
        tag: '',
        colour: '',
        notes: '',
        trustRequirements: [],
      },
      validationState: {},
    })
  );
}
