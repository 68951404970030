// libs
import { css, useTheme } from 'styled-components';

// components
import { CustomTable } from 'components';
import { CustomText } from 'typography/Text';
import { SubnetFilterContainer, tableCss } from './styled';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { useSelector } from 'react-redux';
import { useColumnsView } from './Columns';

// selectors
import { selectAppDetailsPolicyCurrentGatewayAllowedIpRanges } from 'redux/selectors';

export const SubnetFilterView = () => {
  const { translate } = useTranslations('policies');

  const {
    palette: { fonts },
  } = useTheme();

  const ranges = useSelector(selectAppDetailsPolicyCurrentGatewayAllowedIpRanges);

  const columns = useColumnsView();

  if (!ranges.length) {
    return null;
  }

  return (
    <SubnetFilterContainer>
      <CustomText
        type="body-2"
        color={fonts.bodyLight}
        css={css`
          margin-top: 1rem;
          margin-bottom: 1rem;
        `}
      >
        {translate('details.subnet_filter_description')}
      </CustomText>

      <CustomTable
        data-test="policy-details-subnets-table"
        withBorder={false}
        columns={columns}
        headerHeight="2.375rem"
        rowHeight="auto"
        css={tableCss}
        rowKey="id"
        dataSource={ranges}
      />
    </SubnetFilterContainer>
  );
};
