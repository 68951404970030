import styled, { css } from 'styled-components';

export const ListWrapper = styled.div`
  display: flex;
  flex-grow: 1;
`;

export const SkeletonContainer = styled.div<{ height: string }>`
  display: flex;
  height: ${({ height }) => height};
  flex-direction: column;
  justify-content: center;
  padding-left: 1rem;
  padding-right: 1.5rem;
`;

export const ListItemWrapper = styled.div<{ height: string }>`
  display: flex;
  align-items: center;
  padding-left: 0.75rem;
  padding-right: 1rem;
  height: ${({ height }) => height};
`;

export const ListItem = styled.div<{ isSelected: boolean }>`
  cursor: pointer;
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.25rem;
  padding: 0.5rem;

  :hover {
    background-color: ${({ theme }) => theme.palette.background.base};
  }
  :focus {
    background-color: ${({ theme }) => theme.palette.background.iconBackground};
  }

  && {
    ${({ isSelected }) =>
      isSelected &&
      css`
        background-color: ${({ theme }) => theme.palette.background.focus};
        box-shadow: ${({ theme }) => theme.palette.elevationShadows['100']};
      `}
  }
`;

export const ZonesAmountContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.5rem 0 0.5rem;
  background-color: ${({ theme }) => theme.palette.background.panel};
  border-radius: 0.25rem;
`;
