// The properties that we copy into a mirrored div.
// Note that some browsers, such as Firefox,
// do not concatenate properties, i.e. padding-top, bottom etc. -> padding,

import { useCallback, useEffect, useMemo } from 'react';

// so we have to do every single property specifically.
const properties = [
  'direction', // RTL support
  'boxSizing',
  'width', // on Chrome and IE, exclude the scrollbar, so the mirror div wraps exactly as the textarea does
  'height',
  'overflowX',
  'overflowY', // copy the scrollbar for IE

  'borderTopWidth',
  'borderRightWidth',
  'borderBottomWidth',
  'borderLeftWidth',

  'paddingTop',
  'paddingRight',
  'paddingBottom',
  'paddingLeft',

  // https://developer.mozilla.org/en-US/docs/Web/CSS/font
  'fontStyle',
  'fontVariant',
  'fontWeight',
  'fontStretch',
  'fontSize',
  'fontSizeAdjust',
  'lineHeight',
  'fontFamily',

  'textAlign',
  'textTransform',
  'textIndent',
  'textDecoration', // might not make a difference, but better be safe

  'letterSpacing',
  'wordSpacing',
];

export function useGetCaretCoordinates(element: HTMLInputElement | null) {
  const mirroredDiv = document.createElement('div');
  const computed = useMemo(() => (element ? getComputedStyle(element) : null), [element]);

  const resize = useCallback(() => {
    if (computed?.width) {
      //@ts-ignore
      mirroredDiv.width = computed.width;
    }
  }, [computed?.width, mirroredDiv]);

  useEffect(() => {
    window.addEventListener('resize', resize);

    return () => {
      window.removeEventListener('resize', resize);
    };
  }, [resize]);

  const getCaretPosition = useCallback(
    (positionLeft: number) => {
      if (!element) {
        return null;
      }

      element.parentNode && element.parentNode.insertBefore(mirroredDiv, element);

      // default textarea styles
      mirroredDiv.style.whiteSpace = 'nowrap';

      // position off-screen
      mirroredDiv.style.position = 'absolute'; // required to return coordinates properly
      mirroredDiv.style.visibility = 'hidden'; // not 'display: none' because we want rendering

      // transfer the element's properties to the div
      properties.forEach(function (prop) {
        if (computed) {
          //@ts-ignore
          mirroredDiv.style[prop] = computed[prop];
        }
      });

      mirroredDiv.style.overflow = 'hidden'; // for Chrome to not render a scrollbar; IE keeps overflowY = 'scroll'

      const divText = document.createTextNode('');
      mirroredDiv.appendChild(divText);
      const span = document.createElement('span');
      const spanText = document.createTextNode('');

      span.appendChild(spanText);
      mirroredDiv.appendChild(span);

      window.addEventListener('resize', resize);

      divText.nodeValue = element.value.substring(0, positionLeft);

      divText.nodeValue = divText.nodeValue.replace(/\s/g, '\u00a0');

      spanText.nodeValue = '.';

      if (computed) {
        const left = span.offsetLeft + parseInt(computed['borderLeftWidth'], 10);

        return left;
      }

      return null;
    },
    [computed, element, mirroredDiv, resize]
  );

  return {
    getCaretPosition,
  };
}
