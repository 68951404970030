// components
import { CustomText } from 'typography/Text';
import { DropdownDefaultKeyContainer, ColumnNameText } from './styled';

// hooks
import { css, useTheme } from 'styled-components';

// types
import { ISearchKey } from 'types';

interface IDrpodownDescription {
  searchKey: ISearchKey;
  isActive: boolean;
}
export const DropdownDefaultKey = ({ searchKey, isActive }: IDrpodownDescription) => {
  const {
    palette: { fonts },
  } = useTheme();

  return (
    <DropdownDefaultKeyContainer isActiveByDefault={isActive}>
      <ColumnNameText type="body-2" color={fonts.bodyLight}>
        {searchKey.name}
      </ColumnNameText>
      <CustomText
        css={css`
          display: block;
        `}
        type="body-2"
        color={fonts.help}
      >
        {searchKey.hintText}
      </CustomText>
    </DropdownDefaultKeyContainer>
  );
};
