// components
import { Details } from 'components';
import { CustomText } from 'typography/Text';

// selectors
import {
  selectAppDetailsSystemUnapprovedEnrolledFrom,
  selectAppDetailsTabType,
} from 'redux/selectors';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { useSelector } from 'react-redux';

export const EnrolledFromBlock = () => {
  const { translate } = useTranslations('systems');

  const type = useSelector(selectAppDetailsTabType);
  const enrolledFrom = useSelector(selectAppDetailsSystemUnapprovedEnrolledFrom);

  if (type === 'system-view' || type === 'system-edit') return null;

  return (
    <Details.KeyValue height="2.875rem" title={translate('details.enrolled_from')}>
      <CustomText type="body-2">{enrolledFrom}</CustomText>
    </Details.KeyValue>
  );
};
