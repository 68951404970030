import { IconDown } from 'assets/svg';
import styled from 'styled-components';

export const List = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0.5rem 0 0 0;
`;
export const ListItem = styled.li`
  display: flex;
  align-items: center;
  padding: 1.5rem 0;

  :not(:last-child) {
    border-bottom: 0.0625rem solid ${({ theme }) => theme.palette.background.iconBackground};
  }
  :last-child {
    padding-bottom: 0;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const IconRightDirection = styled(IconDown)`
  transform: rotate(-90deg);
  width: 1rem;
  height: 1rem;
  path {
    fill: ${({ theme }) => theme.palette.icons.primary};
  }
`;
