// Lib
import { takeEvery, takeLatest } from 'redux-saga/effects';

// Actions
import {
  getTags,
  createTag,
  saveTagDetails,
  deleteTags,
  extendTagDetails,
  extendTagsModalConnections,
  getTagSearchKeys,
  deleteTag,
} from 'redux/actions/tags';
import { createTagSideMenu } from 'redux/actions/app';

// Sagas
import { getTagsSaga } from './getTagsSaga';
import { createTagSaga } from './createTagSaga';
import { updateTagSaga } from './updateTagSaga';
import { createTagDetailsSaga } from './createTagDetailsSaga';
import { deleteTagsSaga } from './deleteTagsSaga';
import { extendTagDetailsSaga } from './extendTagDetailsSaga';
import { extendTagsModalConnectionsSaga } from './extendTagsModalConnectionsSaga';
import { getTagSearchKeysSaga } from './getTagSearchKeysSaga';
import { deleteTagSaga } from './deleteTagSaga';

function* tagsSaga() {
  yield takeLatest(getTags, getTagsSaga);
  yield takeEvery(createTag, createTagSaga);
  yield takeEvery(saveTagDetails, updateTagSaga);
  yield takeEvery(deleteTags, deleteTagsSaga);
  yield takeEvery(deleteTag, deleteTagSaga);
  yield takeEvery(createTagSideMenu, createTagDetailsSaga);
  yield takeEvery(extendTagDetails, extendTagDetailsSaga);
  yield takeEvery(extendTagsModalConnections, extendTagsModalConnectionsSaga);
  yield takeEvery(getTagSearchKeys, getTagSearchKeysSaga);
}

export default tagsSaga;
