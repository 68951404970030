// components
import { DNSApproved } from './Approved';

// hooks
import { useSelector } from 'react-redux';

// selectors
import { selectAppDetailsTabType } from 'redux/selectors';

export const DNS = () => {
  const type = useSelector(selectAppDetailsTabType);

  return <>{(type === 'system-view' || type === 'system-edit') && <DNSApproved />}</>;
};
