// components
import { Select } from 'components';
import { CustomText } from 'typography/Text';
import { OptionContainer } from './styled';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from 'styled-components';

// actions
import {
  addValidationStateEntry,
  deleteValidationStateEntry,
  updateValidationStateEntry,
  updateTrustRequirementDetailsUserAuthenticationAuthority,
} from 'redux/actions/app';

// selectors
import {
  selectAppDetailsTabValidationEntry,
  selectAppDetailsTrustRequirementNewAuthority,
} from 'redux/selectors';

// constants
import { USER_AUTHENTICATION_AUTHORITIES } from 'appConstants';

// types
import { ITypeTrustRequirementUserAuthenticationAuthority } from 'types';
import { Icons } from './Icons';

const authorityErrorKey = 'authority';

export const AuthorityEdit = () => {
  const dispatch = useDispatch();

  const {
    palette: { fonts },
  } = useTheme();

  const { translate } = useTranslations('trust-requirements');

  const validationState = useSelector(selectAppDetailsTabValidationEntry(authorityErrorKey));
  const authority = useSelector(selectAppDetailsTrustRequirementNewAuthority);

  const setSelectedAuthority = (authority: ITypeTrustRequirementUserAuthenticationAuthority) => {
    dispatch(updateTrustRequirementDetailsUserAuthenticationAuthority(authority));

    dispatch(
      updateValidationStateEntry({
        key: authorityErrorKey,
        state: {
          error: null,
        },
      })
    );
  };

  useEffect(() => {
    dispatch(
      addValidationStateEntry({
        key: authorityErrorKey,
        state: {
          error: authority
            ? null
            : {
                message: translate('details.types.UserAuthentication.authority_empty_error'),
                critical: true,
              },
        },
      })
    );

    return () => {
      dispatch(deleteValidationStateEntry({ key: authorityErrorKey }));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, translate]);

  return (
    <Select
      data-test="trust-requirement-authority-select"
      aria-labelledby="label-trust-requirement-authority"
      dataSource={Object.values(USER_AUTHENTICATION_AUTHORITIES)}
      valueField="key"
      keyField="key"
      value={authority}
      placeholder={translate('details.types.UserAuthentication.authority_placeholder')}
      fieldIsDisabled={option => !option.isActive}
      OptionElement={option => {
        return (
          <OptionContainer
            data-test="trust-requirement-authority-select-option"
            isDisabled={!option.isActive}
          >
            {Icons[option.key]}
            <CustomText type="body-2" color={option.isActive ? fonts.bodyDark : fonts.disabled}>
              {`${option.name}${
                option.isActive
                  ? ''
                  : ' ' + translate('details.types.UserAuthentication.authority_inactive')
              }`}
            </CustomText>
          </OptionContainer>
        );
      }}
      setSelectedValue={option => {
        option.isActive && setSelectedAuthority(option.key);
      }}
      onBlur={() => {
        dispatch(updateValidationStateEntry({ key: authorityErrorKey }));
      }}
      error={
        validationState?.state.error && validationState?.updated
          ? { message: validationState.state.error.message }
          : null
      }
    />
  );
};
