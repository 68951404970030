// libs
import { css, useTheme } from 'styled-components';

// components
import { CustomButtonLink, CustomToggle } from 'components';
import { CustomText } from 'typography/Text';
import { SubnetFilterContainer, SubnetsContainer, SwitchContainer } from './styled';
import { SubnetEditable } from './SubnetEditable';
import { IconTrustRequirementDetailsAddNewCondition } from 'assets/svg';

// hooks
import { useCallback, useEffect, useState } from 'react';
import { useTranslations } from 'hooks/useTranslations';
import { useDispatch, useSelector } from 'react-redux';

// selectors
import {
  selectAppDetailCurrentPolicyGateways,
  selectAppDetailsPolicyCurrentGatewayAllowedIpRanges,
} from 'redux/selectors';

// actions
import {
  activateValidationState,
  createPolicyDetailsAllowedSubnet,
  deactivateValidationState,
  setPolicyDetailsAllowedSubnets,
} from 'redux/actions/app';

// types
import { IPolicyModifiedGatewayAllowedIpRange } from 'types';

// constants
import { POLICY_SUBNET_VALIDATION_STATE_PREFIX } from 'appConstants';

export const SubnetFilterEdit = () => {
  const dispatch = useDispatch();

  const { translate } = useTranslations('policies');

  const {
    palette: { fonts, fills },
  } = useTheme();

  const ranges = useSelector(selectAppDetailsPolicyCurrentGatewayAllowedIpRanges);
  const gateways = useSelector(selectAppDetailCurrentPolicyGateways);

  const [subnetFilter, setSubnetFilter] = useState(!!ranges.length);
  const [rememberedRanges, setRememberedRanges] = useState<IPolicyModifiedGatewayAllowedIpRange[]>(
    []
  );

  // if we close subnet filter and open it again we
  // assign previous values to routes
  useEffect(() => {
    if (subnetFilter === true && rememberedRanges.length) {
      dispatch(setPolicyDetailsAllowedSubnets(rememberedRanges));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, subnetFilter]);

  // if we close subnet filter we remember our allowed Subnets while
  // we send empty array to the redux
  useEffect(() => {
    if (subnetFilter === false) {
      setRememberedRanges(ranges || []);
      dispatch(setPolicyDetailsAllowedSubnets([]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, subnetFilter]);

  useEffect(() => {
    if (!gateways.length) {
      setSubnetFilter(false);
    }
  }, [gateways.length]);

  useEffect(() => {
    if (!ranges.length) {
      setSubnetFilter(false);
    }
  }, [ranges.length]);

  const handleCreateNewSubnet = useCallback(() => {
    dispatch(createPolicyDetailsAllowedSubnet({}));
  }, [dispatch]);

  const onSubnetSwith = useCallback(() => {
    // if we enable filter and we don't have subnets preserved we add one
    if (!subnetFilter && rememberedRanges.length === 0) {
      handleCreateNewSubnet();
    }

    const ids = ranges.map(range => POLICY_SUBNET_VALIDATION_STATE_PREFIX + range.id);
    const rememberedIds = rememberedRanges.map(
      range => POLICY_SUBNET_VALIDATION_STATE_PREFIX + range.id
    );

    if (subnetFilter) {
      dispatch(deactivateValidationState(ids));
    }
    if (!subnetFilter) {
      dispatch(activateValidationState(rememberedIds));
    }

    setSubnetFilter(subnetFilter => {
      return !subnetFilter;
    });
  }, [dispatch, handleCreateNewSubnet, ranges, rememberedRanges, subnetFilter]);

  return (
    <SubnetFilterContainer>
      <SwitchContainer>
        <CustomText type="body-2" color={fonts.bodyLight}>
          {translate('details.subnet_filter')}
        </CustomText>
        <CustomToggle
          data-test="policy-edit-details-subnet-filter-switch"
          disabled={!gateways.length}
          containerCss={css`
            margin-left: 4.5rem;
          `}
          labelCss={css`
            margin-left: 0.5rem;
          `}
          aria-label={translate('details.aria_labels.subnet_filter')}
          enabled={subnetFilter}
          onClick={onSubnetSwith}
          label={translate(
            subnetFilter ? 'details.subnet_filter_enabled' : 'details.subnet_filter_disabled'
          )}
        />
        {!subnetFilter && (
          <CustomText
            type="body-2"
            color={fonts.help}
            css={css`
              margin-left: 1rem;
            `}
          >
            {translate('details.subnet_filter_all_allowed')}
          </CustomText>
        )}
      </SwitchContainer>

      {subnetFilter && (
        <SubnetsContainer>
          <CustomText
            type="body-2"
            color={fonts.bodyLight}
            css={css`
              margin-top: 1rem;
              margin-bottom: 1rem;
            `}
          >
            {translate('details.subnet_filter_description')}
          </CustomText>
          {ranges?.map(range => {
            return <SubnetEditable key={range.id} allowedIpRange={range} />;
          })}
          <CustomButtonLink
            data-test="policy-details-add-new-subnet"
            onClick={handleCreateNewSubnet}
            css={css`
              width: fit-content;
              margin-top: 1.5rem;
            `}
          >
            <IconTrustRequirementDetailsAddNewCondition />
            <CustomText
              type="body-1"
              color={fills.primary}
              css={css`
                margin-left: 0.3125rem;
              `}
            >
              {translate('details.add_new_allowed_gateway_range')}
            </CustomText>
          </CustomButtonLink>
        </SubnetsContainer>
      )}
    </SubnetFilterContainer>
  );
};
