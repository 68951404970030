// Lib
import { takeEvery, takeLatest } from 'redux-saga/effects';
import {
  getSystems,
  saveSystemDetails,
  approveSystem,
  declineSystem,
  approveSystems,
  declineSystems,
  approveSystemFromDetails,
  declineSystemFromDetails,
  getSystemSearchKeys,
} from 'redux/actions/unapproved-systems';

// Sagas
import { getSystemsSaga } from './getSystemsSaga';
import { updateUnapprovedSystemSaga } from './updateUnapprovedSystemSaga';
import { approveSystemSaga, approveSystemFromDetailsSaga } from './approveSystemSaga';
import { declineSystemSaga, declineSystemFromDetailsSaga } from './declineSystemSaga';
import { approveSystemsSaga } from './approveSystemsSaga';
import { declineSystemsSaga } from './declineSystemsSaga';
import { getSystemSearchKeysSaga } from './getSystemSearchKeysSaga';

function* unapprovedSystemsSaga() {
  yield takeEvery(declineSystems, declineSystemsSaga);
  yield takeEvery(approveSystems, approveSystemsSaga);
  yield takeEvery(declineSystem, declineSystemSaga);
  yield takeEvery(approveSystem, approveSystemSaga);
  yield takeLatest(getSystems, getSystemsSaga);
  yield takeEvery(saveSystemDetails, updateUnapprovedSystemSaga);
  yield takeEvery(approveSystemFromDetails, approveSystemFromDetailsSaga);
  yield takeEvery(declineSystemFromDetails, declineSystemFromDetailsSaga);
  yield takeEvery(getSystemSearchKeys, getSystemSearchKeysSaga);
}

export default unapprovedSystemsSaga;
