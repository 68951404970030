// components
import { DnsRecordsTab, DnsZonesTab, KeysTab, PoliciesTab, SystemsTab } from './Tabs';
import { Content, IConnectionsTabs } from './Content';

// hooks
import { useSelector } from 'react-redux';
import { useState } from 'react';

// selectors
import {
  selectAppDetailsTagDnsRecords,
  selectAppDetailsTagDnsZones,
  selectAppDetailsTagKeys,
  selectAppDetailsTagPolicies,
  selectAppDetailsTagSystems,
} from 'redux/selectors';

export const ConnectionsBlock = () => {
  const [selectedTab, setSelectedTab] = useState<IConnectionsTabs>('keys');

  const keysAmount = useSelector(selectAppDetailsTagKeys);
  const systemsAmount = useSelector(selectAppDetailsTagSystems);
  const dnsRecordAmount = useSelector(selectAppDetailsTagDnsRecords);
  const dnsZoneAmount = useSelector(selectAppDetailsTagDnsZones);
  const policiesAmount = useSelector(selectAppDetailsTagPolicies);

  return (
    <Content
      withEmptyState={true}
      selectedTab={selectedTab}
      setSelectedTab={setSelectedTab}
      keysAmount={keysAmount || 0}
      systemsAmount={systemsAmount || 0}
      dnsRecordAmount={dnsRecordAmount || 0}
      dnsZoneAmount={dnsZoneAmount || 0}
      policiesAmount={policiesAmount || 0}
      keysContent={<KeysTab />}
      systemsContent={<SystemsTab />}
      dnsRecordContent={<DnsRecordsTab />}
      dnsZoneContent={<DnsZonesTab />}
      policiesContent={<PoliciesTab />}
    />
  );
};
