// components
import styled from 'styled-components';

export const Container = styled.div<{ width?: string; maxWidth?: string }>`
  position: relative;

  .ant-tooltip {
    max-width: none;
    box-shadow: none;

    .ant-tooltip-content {
      width: ${({ width }) => width || 'max-content'};
      max-width: ${({ maxWidth }) => maxWidth || 'max-content'};

      .ant-tooltip-inner {
        background-color: ${({ theme }) => theme.palette.background.modal};

        box-shadow: ${({ theme }) => theme.palette.elevationShadows[300]};
        border-radius: 0.25rem;
      }
    }
  }
`;
