// libs
import { css } from 'styled-components';

// components
import { EmptyContainer } from 'components';
import {
  IconNoFoundEnrolmentKey,
  IconNoFoundPolicy,
  IconNoFoundSystems,
  IconNoFoundSystems2,
} from 'assets/svg';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { IConnectionsTabs } from './Content';

interface IEmpty {
  module: IConnectionsTabs;
}

export const Empty = ({ module }: IEmpty) => {
  const { translate } = useTranslations('tags');

  const getIcon = (module: IConnectionsTabs) => {
    switch (module) {
      case 'keys':
        return <IconNoFoundEnrolmentKey />;
      case 'systems':
        return <IconNoFoundSystems2 />;
      case 'dnsRecords':
        return <IconNoFoundSystems />;
      case 'dnsZones':
        return <IconNoFoundSystems />;
      case 'policies':
        return <IconNoFoundPolicy />;

      default:
        return <IconNoFoundSystems2 />;
    }
  };

  return (
    <EmptyContainer
      css={css`
        height: auto;
        margin-top: 3rem;
        padding: 0;
        background: transparent;
      `}
      titleCss={css`
        margin: 0.75rem 0 0.5rem 0;
        max-width: 17rem;
      `}
      descriptionCss={css`
        color: ${({ theme }) => theme.palette.fonts.bodyLight};
        text-align: center;
      `}
      Icon={getIcon(module)}
      primaryText={translate(`details.connections.tabs.${module}.empty.title`)}
      secondaryText={translate(`details.connections.tabs.${module}.empty.description`)}
    />
  );
};
