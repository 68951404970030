// libs
import { memo } from 'react';

// components
import { SystemPill } from 'components';
import Skeleton from 'react-loading-skeleton';

// types
import { OSType, components } from 'types';

// utils
import { equals } from 'ramda';
import styled from 'styled-components';
import { useTranslations } from 'hooks/useTranslations';
import { IpRange, getIpRange } from 'utils/ip';
import { IconPoliciesTrafficAllowed, IconPoliciesTrafficDenied } from 'assets/svg';

type GatewayProps = {
  gateways: components['schemas']['PolicySummaryModel']['gateways'];
  gatewayAllowedIps: components['schemas']['PolicySummaryModel']['gatewayAllowedIpRanges'];
  isEnabled: boolean;
};

type GatewayIpSetEntry = {
  gateway: components['schemas']['PolicyGatewayDetailModel'];
  ranges: { ipRange: string }[];
};

const GatewayRangesTable = styled.div`
  display: table;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  border-collapse: separate;
  border-spacing: 0 0.5rem;
`;

const GatewayRangesTableRow = styled.div`
  display: table-row;

  :not(:last-child) > div {
    border-bottom: 1px solid ${({ theme }) => theme.palette.fills.strokeMedium};
    padding-bottom: 0.5rem;
  }
`;

const GatewayTableCell = styled.div`
  display: table-cell;
  vertical-align: middle;
`;

const GatewayRangesTableCell = styled.div`
  display: table-cell;
  vertical-align: middle;
  max-width: 20rem;
  margin-bottom: 0.5rem;
  padding-left: 0.5rem;
  font-size: ${({ theme }) => theme.text_sizes[1]};
`;

const GatewayColumnContainer = styled.div<{ isEnabled?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;

  opacity: ${({ isEnabled }) => (isEnabled ? 1 : 0.6)};
`;

const GatewayWrapper = styled.div<{ isEnabled?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.25rem;
`;

const IpRangeWrapper = styled.div<{ isEnabled?: boolean }>`
  padding-left: 0.5rem;
  max-width: 20rem;
  font-size: ${({ theme }) => theme.text_sizes[1]};
`;

export const IconContainer = styled.div<{ isEnabled?: boolean }>`
  padding-left: 0.5rem;
  display: flex;
  align-items: center;

  svg {
    width: 2rem;
  }
`;

export const Gateways = memo(({ gateways, gatewayAllowedIps, isEnabled }: GatewayProps) => {
  const { translate } = useTranslations('policies');

  const gatewaySet = getGatewayRangeSet(gateways, gatewayAllowedIps, 10, count =>
    translate('ip_range_more', { count })
  );

  if (gatewaySet.set.length === 0) {
    return <></>;
  } else if (gatewaySet.allRangesTheSame) {
    // If all ranges are the same, this can be a little simpler, we
    // can just display the items next to each other.
    return (
      <GatewayColumnContainer isEnabled={isEnabled}>
        <GatewayWrapper>
          {gateways &&
            gateways.map(gw => (
              <SystemPill
                id={gw.systemId}
                name={gw.systemName}
                machineName={gw.machineName}
                platformType={OSType.Linux}
                key={`gw_${gw.systemId}`}
              />
            ))}
        </GatewayWrapper>
        <IconContainer>
          {gatewaySet.set[0].ranges.length ? (
            <IconPoliciesTrafficAllowed />
          ) : (
            <IconPoliciesTrafficDenied />
          )}
        </IconContainer>
        <IpRangeWrapper>
          {gatewaySet.set[0].ranges.length
            ? gatewaySet.set[0].ranges.map(ip => ip.ipRange).join(', ')
            : ''}
        </IpRangeWrapper>
      </GatewayColumnContainer>
    );
  } else {
    return (
      <GatewayRangesTable>
        {gatewaySet.set.map(g => (
          <GatewayRangesTableRow key={g.gateway.systemId}>
            <GatewayTableCell>
              <SystemPill
                id={g.gateway.systemId}
                name={g.gateway.systemName}
                machineName={g.gateway.machineName}
                platformType={OSType.Linux}
                key={`gw_${g.gateway.systemId}`}
              />
            </GatewayTableCell>
            <GatewayTableCell>
              <IconContainer>
                {g.ranges.length ? <IconPoliciesTrafficAllowed /> : <IconPoliciesTrafficDenied />}
              </IconContainer>
            </GatewayTableCell>
            <GatewayRangesTableCell>
              {g.ranges.length ? g.ranges.map(ip => ip.ipRange).join(', ') : ''}
            </GatewayRangesTableCell>
          </GatewayRangesTableRow>
        ))}
      </GatewayRangesTable>
    );
  }
}, equals);

interface PoliciesGatewayProps extends GatewayProps {
  isLoading: boolean;
}

export const PoliciesGateways = ({
  isLoading,
  gateways,
  gatewayAllowedIps,
  isEnabled,
}: PoliciesGatewayProps) => {
  return (
    <>
      {isLoading ? (
        <Skeleton />
      ) : (
        <Gateways gateways={gateways} gatewayAllowedIps={gatewayAllowedIps} isEnabled={isEnabled} />
      )}
    </>
  );
};

function getGatewayRangeSet(
  gateways: GatewayProps['gateways'],
  gatewayAllowedIps: GatewayProps['gatewayAllowedIps'],
  maxRestrictionRanges: number,
  overRestrictionText: (count: number) => string
) {
  const gatewayNetworkSet: GatewayIpSetEntry[] = [];
  let firstGatewayRanges: { ipRange: string }[] = [];
  let allRangesTheSame = true;

  // Go through the list of gateways, and the list of allowed IPs.
  // For each allow IP, see if the current gateway allows it.
  // If so, put it next to it.
  for (const gw of gateways) {
    const gwRanges: IpRange[] = [];
    let actualRanges: { ipRange: string }[];

    for (const gwRoute of gw.routes) {
      const range = getIpRange(gwRoute.route);

      if (range === null) {
        continue;
      }

      gwRanges.push(range);
    }

    if (gatewayAllowedIps.length) {
      actualRanges = [];

      for (const restriction of gatewayAllowedIps) {
        const restrictionRange = getIpRange(restriction.ipRange);

        if (restrictionRange === null) {
          continue;
        }

        if (
          gwRanges.some(
            gwRange =>
              gwRange.start.compareTo(restrictionRange.start) <= 0 &&
              gwRange.end.compareTo(restrictionRange.end) >= 0
          )
        ) {
          actualRanges.push({ ipRange: restrictionRange.ipRange });
        }
      }

      if (actualRanges.length > maxRestrictionRanges) {
        const originalLength = actualRanges.length;
        actualRanges = actualRanges.slice(0, maxRestrictionRanges);
        actualRanges.push({
          ipRange: overRestrictionText(originalLength - maxRestrictionRanges),
        });
      }
    } else {
      actualRanges = gwRanges.map(x => ({ ipRange: x.ipRange }));
    }

    if (gatewayNetworkSet.length == 0) {
      firstGatewayRanges = actualRanges.map(x => ({ ipRange: x.ipRange }));
    } else if (!equals(firstGatewayRanges, actualRanges)) {
      allRangesTheSame = false;
    }

    gatewayNetworkSet.push({ gateway: gw, ranges: actualRanges });
  }

  return { set: gatewayNetworkSet, allRangesTheSame: allRangesTheSame };
}
