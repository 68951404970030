// libs
import { memo, SyntheticEvent } from 'react';

// Components
import Skeleton from 'react-loading-skeleton';
import { ActionDropdown } from 'components';

// hooks
import { useDispatch } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';

// actions
import { loadTagDetails, setModalContent } from 'redux/actions/app';

// Types
import { ITag } from 'types';

interface IActionDropdown {
  tag: ITag;
  isLoading: boolean;
}

export const TagActionDropdown = memo(({ tag, isLoading }: IActionDropdown) => {
  const dispatch = useDispatch();

  const { translate } = useTranslations('tags');

  const onEditClick = () => {
    dispatch(loadTagDetails({ ref: tag.ref, mode: 'edit' }));
  };

  const onDeleteClick = () => {
    dispatch(setModalContent({ type: 'tag-delete', data: { records: [tag], connections: {} } }));
  };

  return (
    <>
      {isLoading ? (
        <Skeleton />
      ) : (
        <ActionDropdown
          data-test="tags-table-action-dropdown-column"
          onContainerClick={(event: SyntheticEvent) => event.stopPropagation()}
          data={[
            {
              text: translate('table_actions.edit'),
              onClick: onEditClick,
            },
            {
              text: translate('table_actions.delete'),
              onClick: onDeleteClick,
            },
          ]}
        />
      )}
    </>
  );
});
