interface IProps {
  pathname: string;
}

export const myUrlToModule = ({ pathname }: IProps) => {
  if (pathname.startsWith('/my')) {
    const corePath = pathname.slice(3, pathname.length);
    if (pathname.endsWith('/dns')) {
      return `${corePath}/zones`;
    } else {
      return `${corePath}`;
    }
  }
  return false;
};
