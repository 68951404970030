// libs
import { css, useTheme } from 'styled-components';

// components
import { CustomText } from 'typography/Text';

// hooks
import { useSelector } from 'react-redux';

// selectors
import { selectAppDetailsTrustRequirementCurrentLocations } from 'redux/selectors';

// utils
import { locationToText } from '../helpers';

export const LocationsCountriesView = () => {
  const locations = useSelector(selectAppDetailsTrustRequirementCurrentLocations);

  const {
    palette: { fonts },
  } = useTheme();

  return (
    <>
      {locations?.length && (
        <CustomText
          css={css`
            white-space: pre;
            display: block;
          `}
          type="body-2"
          color={fonts.title}
        >
          {locations.map(
            (location, index) =>
              `${locationToText({ alpha: location.value })}${
                index === locations.length - 1 ? '.' : ';'
              }\n`
          )}
        </CustomText>
      )}
    </>
  );
};
