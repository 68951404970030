// libs
import { css } from 'styled-components';

// components
import { CustomText } from 'typography/Text';
import { AclEditable } from './AclEditable';
import { CustomButtonLink } from 'components';
import { IconPoliciesDetailsAddAcl } from 'assets/svg';

// hooks
import { useTheme } from 'styled-components';
import { useCallback } from 'react';
import { useTranslations } from 'hooks/useTranslations';
import { useDispatch, useSelector } from 'react-redux';

// actions
import { createPolicyDetailsAcl } from 'redux/actions/app';

// selectors
import { selectAppDetailsPolicyNewAcls } from 'redux/selectors';

export const ReceiverAccessControlsEdit = () => {
  const dispatch = useDispatch();

  const { translate } = useTranslations('policies');

  const {
    palette: { fills },
  } = useTheme();

  const acls = useSelector(selectAppDetailsPolicyNewAcls);

  const handleCreateNewAcl = useCallback(() => {
    dispatch(createPolicyDetailsAcl());
  }, [dispatch]);

  return (
    <>
      {acls?.map(acl => {
        return <AclEditable key={acl.id} acl={acl} />;
      })}
      <CustomButtonLink
        data-test="policy-details-add-new-rule"
        onClick={handleCreateNewAcl}
        css={css`
          width: fit-content;
          margin-top: 1.5rem;
        `}
      >
        <IconPoliciesDetailsAddAcl />
        <CustomText
          type="body-1"
          color={fills.primary}
          css={css`
            margin-left: 0.3125rem;
          `}
        >
          {translate('details.acls_table.add_new_rule')}
        </CustomText>
      </CustomButtonLink>
    </>
  );
};
