// libs
import { css } from 'styled-components';

// components
import { Details } from 'components';
import { AuthorityUriView } from './View';
import { AuthorityUriEdit } from './Edit';

// hooks
import { useSelector } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';

// selectors
import { selectAppDetailsTabType } from 'redux/selectors';

export const AuthorityUriBlock = () => {
  const { translate } = useTranslations('trust-requirements');

  const type = useSelector(selectAppDetailsTabType);

  return (
    <Details.KeyValue
      contentCss={css`
        margin-top: 1.5rem;
        min-height: 2.5rem;
        align-items: center;
      `}
      title={translate('details.types.UserAuthentication.authorities.jumpcloud.authority_uri')}
    >
      {type === 'trust-requirement-view' && <AuthorityUriView />}
      {(type === 'trust-requirement-edit' || type === 'trust-requirement-create') && (
        <AuthorityUriEdit />
      )}
    </Details.KeyValue>
  );
};
