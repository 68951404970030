// libs
import { put, select } from 'typed-redux-saga';

// selectors
import { selectAppDetailsTrustRequirementId } from 'redux/selectors';

// actions
import {
  closeDetails,
  setModalContent,
  setModalIsLoading,
  pushNotification,
} from 'redux/actions/app';
import { setTrustRequirementsShouldUpdate } from 'redux/actions/trust-requirements';

// types
import { IActionPayload, IDeleteTrustRequirementsParams, INotificationType } from 'types';

// utils
import { ApiTypeCaller } from '../../api/utils';
import { translator } from 'utils';

export function* deleteTrustRequirementsSaga({
  payload,
}: IActionPayload<IDeleteTrustRequirementsParams>) {
  yield put(setModalIsLoading(true));

  const selectedRequirementId = yield* select(selectAppDetailsTrustRequirementId);

  if (
    selectedRequirementId !== undefined &&
    payload.requirementIds?.includes(selectedRequirementId)
  ) {
    yield* put(closeDetails({}));
  }

  const { success, result } = yield* ApiTypeCaller('deleteTrustRequirements', payload);

  if (success && result) {
    const translate = translator('notifications');

    yield put(setTrustRequirementsShouldUpdate());
    yield put(setModalContent({ type: null, data: null }));
    yield put(
      pushNotification({
        showType: INotificationType.Success,
        description: translate('trust_requirements_removed'),
      })
    );
  }
  yield put(setModalIsLoading(false));
}
