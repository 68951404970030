// libs
import { call, put } from 'typed-redux-saga';

// actions
import { pushNotification } from 'redux/actions/app';

// utils
import { ApiTypeCaller } from '../../api/utils';
import { translator } from 'utils';

// types
import { IActionPayload, ISendUserInviteParams, INotificationType } from 'types';

// sagas
import { getOrganisationInvitesSaga } from './getOrganisationInvitesSaga';

// constants
import { SETTINGS_CREATE_INVITE_ERROR_FIELD } from 'appConstants';

export function* sendUserInviteSaga({ payload }: IActionPayload<ISendUserInviteParams>) {
  const { result, success } = yield* ApiTypeCaller('sendUserInvite', payload, {
    customErrorField: SETTINGS_CREATE_INVITE_ERROR_FIELD,
  });
  const translate = translator('notifications');

  if (success && result) {
    yield put(
      pushNotification({
        showType: INotificationType.Success,
        description: translate('invite_sent'),
      })
    );
    yield call(getOrganisationInvitesSaga);
  }
}
