// libs
import { css, useTheme } from 'styled-components';

// components
import { SectionHeader } from '../../SectionHeader';
import { RecentlyEnrolledGridItem } from '../../styled';
import { List, ListItem } from './styled';
import { CustomText } from 'typography/Text';
import { OSIcon } from 'components';
import { DashboardNoEnrolled } from './DashboardNoEnrolled';

// hooks
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';

// actions
import { getSystems, setSystems } from 'redux/actions/systems';

// seletors
import {
  selectApprovedSystems,
  selectAppIsLoading,
  selectCurrentOrganisationId,
} from 'redux/selectors';

// constants
import { ROUTE_PATHS } from 'appConstants';
import { initialSystemsResponse } from 'redux/reducers/systems';

// utils
import { formatTime } from 'utils';

// types
import { OSTypes } from 'types';

export const RecentlyEnrolledSection = () => {
  const dispatch = useDispatch();

  const {
    palette: { fonts },
  } = useTheme();

  const { translate } = useTranslations('landing-page');

  const systems = useSelector(selectApprovedSystems).slice(0, 5);
  const orgId = useSelector(selectCurrentOrganisationId);
  const isLoading = useSelector(selectAppIsLoading);

  useEffect(() => {
    dispatch(
      getSystems({
        page: 0,
        per_page: 5,
        sort: 'RecentlyEnrolled',
      })
    );
    return () => {
      dispatch(setSystems(initialSystemsResponse));
    };
  }, [dispatch, orgId]);

  return (
    <RecentlyEnrolledGridItem>
      <SectionHeader
        title={translate('recently_enrolled_systems_section.title')}
        route={`${ROUTE_PATHS.systems}`}
      />
      {systems.length ? (
        <List>
          {systems.map(system => {
            const systemName = system.description || system.hostname || system.systemId;

            return (
              <li key={system.systemId}>
                <ListItem
                  to={`/org/${orgId}${ROUTE_PATHS.systems}/${system.systemId}`}
                  tabIndex={0}
                >
                  <OSIcon
                    platformType={system.platformType as OSTypes}
                    css={css`
                      display: inline-flex;
                      margin-right: 1rem;
                    `}
                  />
                  <CustomText
                    title={systemName}
                    ellipsis={true}
                    type="body-2"
                    color={fonts.bodyDark}
                  >
                    {systemName}
                  </CustomText>
                  <CustomText
                    type="body-2"
                    color={fonts.disabled}
                    css={css`
                      width: 10rem;
                      margin-left: 0.5rem;
                    `}
                  >
                    {formatTime({ date: system.enrolledAt, format: 'ago' })}
                  </CustomText>
                </ListItem>
              </li>
            );
          })}
        </List>
      ) : null}

      {!systems.length && !isLoading ? <DashboardNoEnrolled /> : null}
    </RecentlyEnrolledGridItem>
  );
};
