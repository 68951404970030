// components

import { CustomButtonLink } from 'components';

// hooks
import { useDispatch, useSelector } from 'react-redux';
import { useEmptyText } from 'hooks/useEmptyText';
import { useCallback, useMemo } from 'react';
import { useTranslations } from 'hooks/useTranslations';

// selectors
import { selectDnsSelectedZone, selectEnrolmentKeysIsLoading } from 'redux/selectors';

// actions
import { setDetailsContent } from 'redux/actions/app';

// constants
import { DEFAULT_DNS_RECORD_TYPE, DNS_RECORD_INITIAL_VALIDATION_STATE } from 'appConstants';

type Props = {
  searchValue: string;
};

export const useDnsRecordsEmptyText = ({ searchValue }: Props) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectEnrolmentKeysIsLoading);
  const selectedZone = useSelector(selectDnsSelectedZone);

  const { translate } = useTranslations('dns');

  const onCreateNewRecordClick = useCallback(() => {
    selectedZone &&
      dispatch(
        setDetailsContent({
          type: 'dns-record-create',
          dataNew: {
            fqdn: '',
            name: '',
            notes: null,
            systems: [],
            tags: [],
            type: DEFAULT_DNS_RECORD_TYPE,
            zoneId: selectedZone?.id,
            zoneName: selectedZone.name,
          },
          validationState: DNS_RECORD_INITIAL_VALIDATION_STATE,
        })
      );
  }, [dispatch, selectedZone]);

  const additionalText = useMemo(
    () => (
      <CustomButtonLink onClick={onCreateNewRecordClick}>
        {translate('records.empty_records.link')}
      </CustomButtonLink>
    ),
    [onCreateNewRecordClick, translate]
  );
  const { emptyText } = useEmptyText({
    searchValue,
    noDataText: {
      title: translate('records.empty_records.no_data.title'),
      description: translate('records.empty_records.no_data.description'),
    },
    noDataFoundText: {
      title: translate('records.empty_records.no_data_found.title'),
      description: translate('records.empty_records.no_data_found.description'),
    },
    isLoading,
    additionalText,
    module: 'dns',
  });

  return { emptyText };
};
