// libs
import { css } from 'styled-components';
import { memo } from 'react';

// components
import { TagsContentContainer } from './styled';
import { IconTagsPopupList } from 'assets/svg';
import { CustomText } from 'typography/Text';

// hooks
import { useTheme } from 'styled-components';
import { useTranslations } from 'hooks/useTranslations';

interface ITagsLabel {
  tagsText?: string;
  tagsLength?: number;
  subnetsLength?: number;
  disabled?: boolean;
}

export const TagsLabel = memo(
  ({ tagsLength, subnetsLength, disabled = false, tagsText }: ITagsLabel) => {
    const {
      palette: { fonts },
    } = useTheme();

    const { translate } = useTranslations('global');

    return (
      <TagsContentContainer>
        <IconTagsPopupList width="1rem" height="1rem" />
        <CustomText
          type="body-2"
          color={disabled ? fonts.disabled : fonts.bodyDark}
          css={css`
            border-bottom: 0.0625rem dashed ${({ theme }) => theme.palette.fills.strokeDark};
            margin-left: 0.1875rem;
            white-space: pre-wrap;
          `}
        >
          {tagsText ||
            `${tagsLength} ${translate('tags_list.tags', { count: tagsLength || 0 })}` +
              (subnetsLength !== undefined
                ? `\n${subnetsLength} ${translate('subnets_list.subnets', {
                    count: subnetsLength || 0,
                  })}`
                : '')}
        </CustomText>
      </TagsContentContainer>
    );
  }
);
