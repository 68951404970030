// libs
import { css } from 'styled-components';

// components
import { CustomText } from 'typography/Text';
import { IpRangeEditable } from './IpRangeEditable';
import { CustomButtonLink } from 'components';
import { IconTrustRequirementDetailsAddNewCondition } from 'assets/svg';

// hooks
import { useTheme } from 'styled-components';
import { useCallback } from 'react';
import { useTranslations } from 'hooks/useTranslations';
import { useDispatch, useSelector } from 'react-redux';

// actions
import { createTrustRequirementDetailsPublicIpIpRangeCondition } from 'redux/actions/app';

// selectors
import { selectAppDetailsTrustRequirementCurrentIpRanges } from 'redux/selectors';

export const IpRangesEdit = () => {
  const dispatch = useDispatch();

  const { translate } = useTranslations('trust-requirements');

  const {
    palette: { fills },
  } = useTheme();

  const ipRanges = useSelector(selectAppDetailsTrustRequirementCurrentIpRanges);

  const handleCreateNewIpRange = useCallback(() => {
    dispatch(createTrustRequirementDetailsPublicIpIpRangeCondition({}));
  }, [dispatch]);

  return (
    <div>
      {ipRanges?.map(ipRange => {
        return <IpRangeEditable key={ipRange.id} ipRange={ipRange} />;
      })}
      <CustomButtonLink
        data-test="trust-requirement-details-add-new-ip-range"
        onClick={handleCreateNewIpRange}
        css={css`
          width: fit-content;
          margin-top: 1.5rem;
        `}
      >
        <IconTrustRequirementDetailsAddNewCondition />
        <CustomText
          type="body-1"
          color={fills.primary}
          css={css`
            margin-left: 0.3125rem;
          `}
        >
          {translate('details.types.PublicIp.add_new_ip_range')}
        </CustomText>
      </CustomButtonLink>
    </div>
  );
};
