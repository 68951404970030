import { css, CSSProp, useTheme } from 'styled-components';
import { Badge } from 'antd';

// Badge
interface IBadge {
  color: 'blue' | 'gray' | 'green' | 'red' | 'yellow';
  badgeCss?: CSSProp;
  size?: string;
}

export const CustomBadge = ({ color, badgeCss, size }: IBadge) => {
  const theme = useTheme();

  const colorMap: Record<IBadge['color'], string> = {
    blue: theme.palette.fills.primary,
    gray: theme.palette.fonts.disabled,
    green: theme.palette.state.success,
    red: theme.palette.state.error,
    yellow: theme.palette.state.alert,
  };

  return (
    <Badge
      css={css`
        .ant-badge-status-dot {
          width: ${size};
          height: ${size};
        }
        ${badgeCss}
      `}
      color={colorMap[color]}
    />
  );
};

// Systems implementation
type SystemStates = 'Connected' | 'Disconnected' | 'Disabled';
interface ISystemBadgeProps {
  state: SystemStates;
}

const systemColorMap: Record<ISystemBadgeProps['state'], IBadge['color']> = {
  Connected: 'green',
  Disconnected: 'red',
  Disabled: 'gray',
};

export const SystemStatusBadge = ({ state = 'Disabled' }: ISystemBadgeProps) => (
  <CustomBadge color={systemColorMap[state]} />
);

// Keys implementation
type KeyStates = 'Disabled' | 'Enabled' | 'NoUsesRemaining';
interface IKeysBadgeProps {
  state: KeyStates;
}

const keysColorMap: Record<IKeysBadgeProps['state'], IBadge['color']> = {
  Disabled: 'gray',
  Enabled: 'blue',
  NoUsesRemaining: 'yellow',
};

export const KeysStatusBadge = ({ state = 'Disabled' }: IKeysBadgeProps) => (
  <CustomBadge color={keysColorMap[state]} />
);
