// libs
import styled, { css, useTheme } from 'styled-components';

// components
import { TrustRequirementsView } from './View';
import { TrustRequirementsEdit } from './Edit';
import { CustomText } from 'typography/Text';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { useSelector } from 'react-redux';

// selectors
import { selectAppDetailsTabType } from 'redux/selectors';

const Container = styled.div`
  margin-left: 2rem;
`;

export const TrustRequirementsBlock = () => {
  const { translate } = useTranslations('policies');

  const {
    palette: { fonts },
  } = useTheme();

  const type = useSelector(selectAppDetailsTabType);

  return (
    <Container>
      <CustomText
        css={css`
          white-space: pre-wrap;
          display: block;
          margin-bottom: 1rem;
        `}
        type="body-2"
        color={fonts.bodyLight}
      >
        {translate('details.trust_requirements_desc')}
      </CustomText>

      {type === 'policy-view' && <TrustRequirementsView />}
      {(type === 'policy-edit' || type === 'policy-create') && <TrustRequirementsEdit />}
    </Container>
  );
};
