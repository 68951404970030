import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  // padding right is done at ScrollBarContainer to account for scrollbar
  padding-left: 1.5rem;

  max-width: calc(100rem - ${({ theme }) => theme.layout_sizes.dnsZonesWidth});
  min-width: calc(60rem - ${({ theme }) => theme.layout_sizes.dnsZonesWidth});
  margin: 0 auto;
`;

export const ScrollBarContainer = styled.div`
  display: flex;
  flex-direction: row;

  &:after {
    content: '';

    min-width: calc(
      2.5rem - 100vw + 100% +
        calc(
          ${({ theme }) => theme.layout_sizes.siderWidth} +
            ${({ theme }) => theme.layout_sizes.dnsZonesWidth}
        )
    );
  }

  flex-grow: 1;
`;

export const Wrapper = styled.div`
  overflow: auto;
  height: calc(100vh - ${({ theme }) => theme.layout_sizes.headerHeight});
  width: calc(100% - ${({ theme }) => theme.layout_sizes.dnsZonesWidth});
`;

export const TabsWrapper = styled.div`
  margin-top: 2rem;
  border-bottom: 0.0625rem solid ${({ theme }) => theme.palette.fills.strokeMedium};
`;

export const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
