// libs
import { useCallback } from 'react';
import { css, useTheme } from 'styled-components';

// components
import { IconPlanBusiness } from 'assets/svg';
import { InternalLink, ExternalLink, PlanDependentContent, Popover } from 'components';
import { PopupTitleContainer, ArrowRightIcon } from './styled';
import { CustomText } from 'typography/Text';
import { Button } from './Button';

// hooks
import { useDispatch, useSelector } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';

// selectors
import {
  selectAppIsLoaded,
  selectCurrentOrganisationHasDnsSpaceLeft,
  selectCurrentOrganisationPlan,
  selectOrgId,
} from 'redux/selectors';

// actions
import { setModalContent } from 'redux/actions/app';

// constants
import { LINK_TO_SUPPORT_MAIL, PLANS_N_SUBS_ANCHOR } from 'appConstants';

export const AddZoneIconButton = () => {
  const hasUsesRemaining = useSelector(selectCurrentOrganisationHasDnsSpaceLeft);
  const orgId = useSelector(selectOrgId);
  const dispatch = useDispatch();
  // application has default plan as Starter, so we need to wait untill app is loaded
  // to determinate true plan
  const appIsLoaded = useSelector(selectAppIsLoaded);
  const currentPlan = useSelector(selectCurrentOrganisationPlan);
  const isEnabled = !appIsLoaded || hasUsesRemaining;

  const {
    palette: { fonts, fills, background },
  } = useTheme();

  const { translate } = useTranslations('dns');

  const onAddZoneClick = useCallback(() => {
    dispatch(
      setModalContent({
        type: 'create-new-zone',
        data: null,
      })
    );
  }, [dispatch]);

  return (
    <Popover
      popoverCss={css`
        width: 18.75rem;
      `}
      arrowPointAtCenter={true}
      trigger={isEnabled ? [''] : ['hover', 'focus', 'click']}
      title={
        <PopupTitleContainer>
          <IconPlanBusiness width="1.5rem" height="1.5rem" />
          <CustomText
            type="sub-heading-2"
            color={fonts.bodyDark}
            css={css`
              margin-left: 0.5rem;
            `}
          >
            <PlanDependentContent
              plan={currentPlan}
              hiddenWith={['Business', 'Enterprise', 'External', 'Msp', 'MspManaged']}
            >
              {translate('zones.upgrade_to_business_popup.title')}
            </PlanDependentContent>
            <PlanDependentContent plan={currentPlan} hiddenWith={['Starter']}>
              {translate('zones.contact_enclave_popup.title')}
            </PlanDependentContent>
          </CustomText>
        </PopupTitleContainer>
      }
      content={
        <>
          <CustomText
            type="body-2"
            color={fonts.bodyLight}
            css={css`
              display: block;
              white-space: pre-wrap;
              margin-bottom: 0.75rem;
            `}
          >
            <PlanDependentContent
              plan={currentPlan}
              hiddenWith={['Business', 'Enterprise', 'External', 'Msp', 'MspManaged']}
            >
              {translate('zones.upgrade_to_business_popup.description')}
            </PlanDependentContent>
            <PlanDependentContent plan={currentPlan} hiddenWith={['Starter']}>
              {translate('zones.contact_enclave_popup.description')}
            </PlanDependentContent>
          </CustomText>
          <PlanDependentContent
            plan={currentPlan}
            hiddenWith={['Business', 'Enterprise', 'External', 'Msp', 'MspManaged']}
          >
            <InternalLink
              to={`/org/${orgId}/settings#${PLANS_N_SUBS_ANCHOR}`}
              icon={
                <ArrowRightIcon
                  css={css`
                    margin-left: 0.25rem;
                  `}
                />
              }
            >
              {translate('zones.upgrade_to_business_popup.link')}
            </InternalLink>
          </PlanDependentContent>
          <PlanDependentContent plan={currentPlan} hiddenWith={['Starter']}>
            <ExternalLink
              textType="body-2"
              href={LINK_TO_SUPPORT_MAIL}
              text={translate('zones.contact_enclave_popup.link')}
            />
          </PlanDependentContent>
        </>
      }
      placement="bottomRight"
      borderColor={fills.iconHover}
      backgroundColor={background.hover}
    >
      <div>
        <Button
          data-test="dns-add-zone-button"
          isEnabled={isEnabled}
          onClick={appIsLoaded ? onAddZoneClick : undefined}
        />
      </div>
    </Popover>
  );
};
