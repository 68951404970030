// Lib
import { put } from 'typed-redux-saga';

import { setSecurityEvents, setIsLoading } from 'redux/actions/security-events';

import { IActionPayload, IGetSecurityEventsParams } from 'types';

import { randomHash } from 'utils';

// Utils
import { ApiTypeCaller } from '../../api/utils';
import { ITEMS_PER_TABLE_PAGE } from 'appConstants';

export function* getSecurityEventsSaga({ payload }: IActionPayload<IGetSecurityEventsParams>) {
  yield put(setIsLoading(true));

  const { result, success } = yield* ApiTypeCaller('getSecurityEvents', {
    ...payload,
    per_page: payload.per_page || ITEMS_PER_TABLE_PAGE,
  });

  if (success && result) {
    result.items = result.items.map(securityEvent => ({
      ...securityEvent,
      id: randomHash(),
    }));
    yield put(setSecurityEvents(result));
  }
  yield put(setIsLoading(false));
}
