export const LINK_TO_DOCS = 'https://docs.enclave.io/';
export const STRIPE_LIB = 'https://js.stripe.com/v3/';
export const LINK_TO_WINDOWS_BUILD = 'https://install.enclave.io/manifest/windows/setup.json';
export const LINK_TO_QUICK_START_GUIDE = 'https://docs.enclave.io/quick-start';
export const LINK_TO_MESH_DOCS = 'https://docs.enclave.io/keywords/full-mesh';
export const LINK_TO_DNS_DOCS = 'https://docs.enclave.io/management/dns';
export const LINK_TO_PRICING = 'https://enclave.io/pricing';
export const LINK_TO_API = 'https://docs.enclave.io/api';
export const LINK_TO_COMMUNITY_SUPPORT = 'https://docs.enclave.io/support/community-support/';
export const LINK_TO_KNOWLEDGE_BASE_TRANSFER_OWNERSHIP =
  'https://docs.enclave.io/kb/transfer-ownership/';
export const LINK_TO_KNOWLEDGE_BASE_CANCEL_SUBSCRIPTION =
  'https://docs.enclave.io/kb/cancel-subscription/';

export const LINK_TO_GOOGLE_AUTH = 'https://support.google.com/accounts/answer/1066447';
export const LINK_TO_LASTPASS_AUTH = 'https://lastpass.com/auth/';
export const LINK_TO_MICROSOFT_AUTH = 'https://www.microsoft.com/en-us/account/authenticator';
export const LINK_TO_DUO_MOBILE_AUTH =
  'https://duo.com/product/multi-factor-authentication-mfa/duo-mobile-app';
export const LINK_TO_TUTORIALS = 'https://docs.enclave.io/tutorials/';

export const APP_CONFIG_FILE = 'config.json';
export const LINK_TO_HOMEBREW = 'https://brew.sh/';

export const LINK_TO_SUPPORT_MAIL = 'mailto:support@enclave.io';
export const LINK_TO_SALES_MAIL = 'mailto:sales@enclave.io';
export const LINK_TO_STATUS = 'https://status.enclave-networks.com/';

export const LINK_TO_WINDOWS_SETUP_DOCS = 'https://docs.enclave.io/setup/windows';
export const LINK_TO_WINDOWS_UNATTENDED_SETUP_DOCS =
  'https://docs.enclave.io/go/install-windows-unattended';
export const LINK_TO_MAC_SETUP_DOCS = 'https://docs.enclave.io/setup/macos';
export const LINK_TO_LINUX_SETUP_DOCS = 'https://docs.enclave.io/setup/linux';
export const LINK_TO_DOCKER_SETUP_DOCS = 'https://docs.enclave.io/setup/docker';
export const LINK_TO_KUBERNETES_SETUP_DOCS = 'https://docs.enclave.io/setup/kubernetes';
export const LINK_TO_ANDROID_SETUP_DOCS = 'https://docs.enclave.io/setup/android/';
export const LINK_TO_IOS_SETUP_DOCS = 'https://docs.enclave.io/setup/ios/';

export const LINK_TO_LINUX_GATEWAY_SETUP = 'https://docs.enclave.io/';

export const LINK_TO_PLAY_STORE =
  'https://play.google.com/store/apps/details?id=io.enclave.agent&gl=GB&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1';
export const LINK_TO_APP_STORE =
  'https://apps.apple.com/us/app/enclave-agent/id1640290068?itsct=apps_box_badge&itscg=30200';
export const LINK_TO_SLACK = 'https://enclave.io/slack';
