// libs
import { useState } from 'react';

// components
import {
  IconContainer,
  RightDirectionIcon,
  SystemsLink,
  SystemsNeedApprovalGridItem,
} from '../../styled';
import { SystemContent } from '../../SystemContent';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { useSelector } from 'react-redux';

// selectors
import { selectCurrentOrganisationInfo, selectOrgId } from 'redux/selectors';

export const SystemsNeedApprovalSection = () => {
  const { translate } = useTranslations('landing-page');

  const [isActive, setIsActive] = useState<boolean>(false);

  const orgId = useSelector(selectOrgId);
  const organizationInfo = useSelector(selectCurrentOrganisationInfo);

  return (
    <SystemsLink
      to={`/org/${orgId}/unapproved-systems/`}
      onMouseEnter={() => setIsActive(true)}
      onMouseLeave={() => setIsActive(false)}
    >
      <SystemsNeedApprovalGridItem>
        <SystemContent
          superscript={String(organizationInfo.unapprovedSystems)}
          subscript={translate('systems_need_your_approval_section.title', {
            count: organizationInfo.unapprovedSystems,
          })}
          description={translate('systems_need_your_approval_section.description', {
            count: organizationInfo.unapprovedSystems,
          })}
        />
        <IconContainer $isActive={isActive}>
          <RightDirectionIcon $isActive={isActive} />
        </IconContainer>
      </SystemsNeedApprovalGridItem>
    </SystemsLink>
  );
};
