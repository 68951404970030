import { Row, Col } from 'antd';
import styled from 'styled-components';
import { RowProps } from 'antd/lib/grid/row';
import { ColProps } from 'antd/lib/grid/col';

interface IStyledRowProps extends RowProps {
  $fullHeight?: boolean;
  mb?: string;
  mt?: string;
}

interface IStyledColProps extends ColProps {
  $fullHeight?: boolean;
  $asFlex?: boolean;
  $middle?: boolean;
  $vmiddle?: boolean;
  $hmiddle?: boolean;
  $valign?: string;
  $justify?: string;
}

export const StyledRow = styled(Row)<IStyledRowProps>`
  ${props => (props.$fullHeight ? 'height: 100%;' : '')}
  ${props => (props.mb ? `margin-bottom: ${props.mb};` : '')}
  ${props => (props.mt ? `margin-top: ${props.mt};` : '')}
`;

export const StyledCol = styled(Col)<IStyledColProps>`
  ${props => (props.$fullHeight ? 'height: 100%;' : '')}
  ${props => (props.$asFlex ? 'display: flex;' : '')}
  ${props => (props.$vmiddle ? 'align-items: center;' : '')}
  ${props => (props.$hmiddle ? 'justify-content: center' : '')}
  ${props => (props.$middle ? 'align-items: center;' : '')}
  ${props => (props.$middle ? 'justify-content: center;' : '')}
  ${props => (props.$valign ? `align-items: ${props.$valign};` : '')}
  ${props => (props.$justify ? `justify-content: ${props.$justify};` : '')}
`;
