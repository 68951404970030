import { ISystemGatewayRouteModified, IValidationStateValue } from 'types';
import { translator, isSubnetValid } from 'utils';

export const validateSubnet = ({
  cidr: subnet,
}: {
  cidr: ISystemGatewayRouteModified['subnet'];
}): IValidationStateValue => {
  const translate = translator('systems');

  if (!isSubnetValid(subnet)) {
    return { error: { message: translate('details.gateway.subnet_error') } };
  }

  return { error: null };
};

export const getRouteStatus = ({
  route,
  knownSubnets,
}: {
  route: ISystemGatewayRouteModified;
  knownSubnets: ISystemGatewayRouteModified['subnet'][];
}) => {
  if (route.userEntered) {
    return 'custom';
  }
  if (knownSubnets.includes(route.subnet)) {
    return 'known';
  }
  return 'formerly-known';
};
