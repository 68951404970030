// libs
import styled, { css } from 'styled-components';

// components
import { TimeZoneSelect } from 'components';

// hooks
import { useCallback } from 'react';
import { useTranslations } from 'hooks/useTranslations';
import { useDispatch, useSelector } from 'react-redux';

// selectors
import {
  selectAppDetailsEnrolmentKeyAutoExpireNewTimeZone,
  selectAppDetailsEnrolmentKeyAutoExpireTimeZone,
} from 'redux/selectors';

// actions
import { updateEnrolmentKeyDetailsAutoExpire } from 'redux/actions/app';

// constants
import { TIME_ZONES_DATA_SOURCE } from 'appConstants/time';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-width: 100%;
`;

export const TimeZoneEdit = () => {
  const dispatch = useDispatch();

  const { translate } = useTranslations('policies');

  const newTimeZoneName = useSelector(selectAppDetailsEnrolmentKeyAutoExpireNewTimeZone);
  const currentTimeZoneName = useSelector(selectAppDetailsEnrolmentKeyAutoExpireTimeZone);

  const setNewZone = useCallback(
    (newZone: typeof TIME_ZONES_DATA_SOURCE[0]) => {
      dispatch(updateEnrolmentKeyDetailsAutoExpire({ timeZoneId: newZone.zone }));
    },
    [dispatch]
  );

  return (
    <Container>
      <TimeZoneSelect
        currentTimeZone={currentTimeZoneName}
        newTimeZone={newTimeZoneName}
        setNewTimeZone={setNewZone}
        ariaLabel={translate('details.auto_expire.time_zone.title')}
        dataTest="enrolment-key-details-time-zone-select"
        containerCss={css`
          flex: 10 10 0;
        `}
      />
    </Container>
  );
};
