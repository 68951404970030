// Hooks
import { useTheme } from 'styled-components';

// Components
import { NoOrgsWrapper } from './styled';
import { CustomText } from 'typography/Text';
import { IconSingleOrg } from 'assets/svg';

// Hooks
import { useTranslations } from 'hooks/useTranslations';

export const NoOrganisations = () => {
  const { palette } = useTheme();
  const { translate } = useTranslations('account');

  return (
    <NoOrgsWrapper>
      <IconSingleOrg />
      <CustomText type="hint" color={palette.fonts.bodyLight}>
        {translate('single_organisation')}
      </CustomText>
    </NoOrgsWrapper>
  );
};
