import styled from 'styled-components';

export const TrustRequirementsListWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const TrustRequirementsIconContainer = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;

  flex-grow: 1;
  min-width: 0;

  svg {
    flex-shrink: 0;
    margin-right: 1rem;
  }
`;

export const ListElement = styled.div`
  padding-left: 0.5rem;
  height: 2.625rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.0625rem solid ${({ theme }) => theme.palette.background.iconBackground};
`;
