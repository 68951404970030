// hooks
import { useEffect } from 'react';
import { useTranslations } from 'hooks/useTranslations';
import { useDispatch, useSelector } from 'react-redux';

// components
import {
  OrganisationDetails,
  PlansNSubs,
  Users,
  OrganisationTitle,
  DangerZone,
} from './components';
import { SettingsWrapper } from './styled';
import { PageTitle, PlanDependentContent, TrialBanner } from 'components';

// utils
import { loadStripe } from 'utils';

// actions
import { setStripeLoaded } from 'redux/actions/app';

// selectors
import { selectIsStripeLoaded } from 'redux/selectors/app';
import { selectCurrentOrganisationPlan } from 'redux/selectors';

// constants
import { EXTERNAL_PLAN, MSP_MANAGED_PLAN, MSP_PLAN } from 'appConstants';

export const Settings = () => {
  const { translate } = useTranslations('settings');

  const dispatch = useDispatch();

  const stripeIsLoaded = useSelector(selectIsStripeLoaded);
  const currentPlan = useSelector(selectCurrentOrganisationPlan);

  useEffect(() => {
    if (!stripeIsLoaded) loadStripe(() => dispatch(setStripeLoaded()));
  }, [dispatch, stripeIsLoaded]);

  return (
    <SettingsWrapper>
      <PageTitle title={translate('settings')} />
      <OrganisationTitle />
      <TrialBanner />
      <OrganisationDetails />
      <PlanDependentContent
        plan={currentPlan}
        hiddenWith={[EXTERNAL_PLAN, MSP_PLAN, MSP_MANAGED_PLAN]}
      >
        <PlansNSubs />
      </PlanDependentContent>
      <Users />
      <PlanDependentContent
        plan={currentPlan}
        hiddenWith={[EXTERNAL_PLAN, MSP_PLAN, MSP_MANAGED_PLAN]}
      >
        <DangerZone />
      </PlanDependentContent>
    </SettingsWrapper>
  );
};
