import styled from 'styled-components';
import { CustomText } from 'typography/Text';

type BWProps = {
  mt?: string;
};
type CWProps = {
  justify?: string;
  align?: string;
  mb?: string;
};

export const TitleWrapper = styled.div`
  margin-bottom: 2.5rem;
`;
export const AccountWrapper = styled.div`
  display: grid;

  & > * {
    grid-column: 1 / span 2;
  }

  margin: 1.5rem auto 6.375rem;
  max-width: 72.625rem;

  grid-template-columns: 1fr 1fr;

  gap: 0 2rem;
  grid-template-areas: '. .';
  padding: 2rem;
`;

export const BlockWrapper = styled.div<BWProps>`
  display: grid;
  grid-template-columns: 1fr 2.35fr;
  grid-template-rows: 1fr;
  gap: 0 2.875rem;
  grid-template-areas: '. .';
  padding: 2rem;
  background-color: ${({ theme }) => theme.palette.background.panel};
  box-shadow: ${({ theme }) => theme.palette.elevationShadows[200]};
  border-radius: 0.25rem;
  margin-top: ${({ mt }) => mt || '1.5rem'};
`;

export const ContentWrapper = styled.div<CWProps>`
  display: flex;
  flex-direction: column;
  justify-content: ${({ justify }) => justify || 'space-around'};
  margin-bottom: ${({ mb }) => mb || 0};
  ${({ align }) => `align-items: ${align}` || ''};
`;

export const CustomTextStyled = styled(CustomText)`
  display: block;
  margin-bottom: 1rem;
`;
