// libs
import { memo } from 'react';
import { css, useTheme } from 'styled-components';

// components
import { CustomText } from 'typography/Text';
import Skeleton from 'react-loading-skeleton';

interface IHostname {
  hostname: string;
  isLoading: boolean;
}

export const Hostname = memo(({ hostname, isLoading }: IHostname) => {
  const theme = useTheme();
  return (
    <>
      {isLoading ? (
        <Skeleton />
      ) : (
        <CustomText
          data-test="dns-records-table-hostname-column"
          css={css`
            display: block;
          `}
          type="body-2"
          color={theme.palette.fonts.bodyDark}
          ellipsis={true}
          title={hostname}
        >
          {hostname}
        </CustomText>
      )}
    </>
  );
});
