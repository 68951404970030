import styled from 'styled-components';

export type MessageStyle = {
  background: string;
  border: string;
  Icon: React.ReactNode | null;
};

export const MessageContainer = styled.div`
  display: flex;
  flex-direction: row;

  & > svg {
    margin-top: 0.125rem;
    margin-right: 0.5rem;
  }
`;

export const Container = styled('div')<Partial<MessageStyle>>`
  position: relative;

  width: 100%;
  border-radius: 0.25rem;
  padding: 1.5rem 5rem 1.5rem 1.5rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${props => props.background};
  border: 0.125rem solid ${props => props.border};
`;

export const Bold = styled.span`
  font-weight: 700;
`;

export const CloseContainer = styled.div<{ closeIcon: string }>`
  position: absolute;
  right: 1.5rem;
  top: 1.5rem;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg path {
    fill: ${({ closeIcon }) => closeIcon};
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ConvertButtonContainer = styled.div`
  margin-left: 2rem;
  align-self: center;
  display: flex;
`;
