// Lib
import { pick } from 'ramda';
import { call, put, select } from 'typed-redux-saga';

// actions
import { setSystem } from 'redux/actions/unapproved-systems';

// selectors
import { setDetailsContent, pushNotification } from 'redux/actions/app';
import { selectAppDetailsTabData, selectAppDetailsTabDataNew } from 'redux/selectors';

// types
import {
  IApiResponse,
  IUpdateUnapprovedSystemParams,
  IUpdateUnapprovedSystemResponse,
  INotificationType,
  IUnapprovedSystemDetails,
} from 'types';

// Utils
import { checkCreatedSaga } from '../tags/checkCreatedSaga';
import { ApiTypeCaller } from '../../api/utils';
import { translator, diff, removeUndefinedKeys, tagsToStrings } from 'utils';

export function* updateUnapprovedSystemSaga() {
  const system: IUnapprovedSystemDetails = yield select(selectAppDetailsTabData);
  const updatedSystem: IUnapprovedSystemDetails = yield select(selectAppDetailsTabDataNew);

  const difference = diff(updatedSystem, system);
  const updatedParams: IUpdateUnapprovedSystemParams = pick(['description', 'notes'], difference);

  updatedParams.tags = difference.tags ? tagsToStrings(difference.tags) : undefined;

  const translate = translator('notifications');

  const { success, result }: IApiResponse<IUpdateUnapprovedSystemResponse> = yield ApiTypeCaller(
    'updateUnapprovedSystem',
    {
      updatedParams: removeUndefinedKeys(updatedParams),
      systemId: updatedSystem.systemId,
    }
  );

  if (success && result) {
    yield put(
      setDetailsContent({
        type: 'unapproved-system-view',
        dataNew: null,
        data: result,
      })
    );
    yield put(setSystem(result));
    yield put(
      pushNotification({
        showType: INotificationType.Success,
        description: translate('system_updated', {
          systemId: updatedSystem.systemId,
        }),
      })
    );
  }

  yield call(checkCreatedSaga, updatedParams.tags);
}
