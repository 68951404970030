// libs
import { css } from 'styled-components';

// components
import { CustomButton } from 'components';
import { CustomText } from 'typography/Text';
import { ApproveDeclineSection, ButtonsContainer } from './styled';

// hooks
import { useCallback } from 'react';
import { useTranslations } from 'hooks/useTranslations';
import { useDispatch, useSelector } from 'react-redux';

// actions
import {
  approveSystemFromDetails,
  declineSystemFromDetails,
} from 'redux/actions/unapproved-systems';

// selectors
import { selectAppDetailsSystemEnrolledAt, selectAppDetailsSystemSystemId } from 'redux/selectors';

// utils
import { formatTime } from 'utils';

export const Content = () => {
  const dispatch = useDispatch();

  const { translate } = useTranslations('systems');

  const systemId = useSelector(selectAppDetailsSystemSystemId);
  const enrolledAt = useSelector(selectAppDetailsSystemEnrolledAt);

  const handleApprove = useCallback(() => {
    systemId !== undefined && dispatch(approveSystemFromDetails({ systemId: systemId }));
  }, [dispatch, systemId]);

  const handleDecline = useCallback(() => {
    systemId !== undefined && dispatch(declineSystemFromDetails({ systemId: systemId }));
  }, [dispatch, systemId]);

  return (
    <ApproveDeclineSection>
      <CustomText
        css={css`
          display: block;
          margin-bottom: 0.5rem;
        `}
        type="heading-2"
      >
        {translate('approve_request')}
      </CustomText>
      <CustomText
        type="body-2"
        css={css`
          display: block;
        `}
      >
        {enrolledAt &&
          translate('approve_unapproved', {
            enrolledAt: formatTime({ date: enrolledAt, format: 'DD MMM YYYY' }),
          })}
      </CustomText>
      <ButtonsContainer>
        <CustomButton kind="primary" onClick={handleApprove}>
          {translate('details.approve')}
        </CustomButton>
        <CustomButton
          kind="primaryDanger"
          onClick={handleDecline}
          css={css`
            margin-left: 1rem;
          `}
        >
          {translate('details.reject')}
        </CustomButton>
      </ButtonsContainer>
    </ApproveDeclineSection>
  );
};
