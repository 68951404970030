// Lib
import { css } from 'styled-components';
import { pick } from 'ramda';

// Components
import { CustomButton, Input } from 'components';
import { BlockWrapper, ContentWrapper } from '../../styled';
import { CustomText, StyledSubtitle } from 'typography/Text';
import { InputWrapper } from './styled';

// Hooks
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { useTranslations } from 'hooks/useTranslations';

// Actions
import { updateOrganisation } from 'redux/actions/organisation';

// Selectors
import { selectCurrentOrganisationInfo, selectAccountMemberSince } from 'redux/selectors';

// Utils
import { formatTime } from 'utils';

type OrganisationState = {
  name: string;
  id: string;
  phone: string | null;
};

export const OrganisationDetails = () => {
  const dispatch = useDispatch();

  const { translate } = useTranslations('settings');

  const {
    palette: { fonts },
  } = useTheme();

  const organisation = useSelector(selectCurrentOrganisationInfo);
  const memberSince = useSelector(selectAccountMemberSince);

  const [dataLoaded, setDataLoaded] = useState(false);
  const [organisationData, setOrganisationData] = useState<OrganisationState>({
    name: organisation.name,
    id: organisation.id,
    phone: organisation.phone,
  });

  const inputValid = (value: string) => {
    if (value.length === 0 || !value) return { message: translate('form.no_empty_orgname') };
  };

  const onChangeData = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    setOrganisationData({
      ...organisationData,
      [name]: value,
    });
  };

  const onUpdateOrganisation = () => {
    if (organisationData.name !== '') {
      dispatch(updateOrganisation(organisationData));
    }
  };

  useEffect(() => {
    const orgData = pick(['name', 'id', 'website', 'phone'], organisation);
    setOrganisationData(orgData);
    if (!!orgData.name && !dataLoaded) setDataLoaded(true);
  }, [organisation, dataLoaded]);

  return (
    <BlockWrapper mt="2.5rem">
      <ContentWrapper justify="flex-start">
        <StyledSubtitle>{translate('organisation_details')}</StyledSubtitle>
        <CustomText
          color={fonts.bodyLight}
          type="body-2"
          css={css`
            margin-bottom: 1rem;
          `}
        >
          {translate('manage_details_here')}
        </CustomText>
        <CustomText color={fonts.bodyLight} type="body-2">
          <strong>{translate('created')}</strong>:{' '}
          {formatTime({ date: memberSince, format: 'ddd, D MMM YYYY HH:mm GMT' })}
        </CustomText>
      </ContentWrapper>
      <ContentWrapper>
        <InputWrapper>
          <Input
            type="text"
            name="name"
            value={organisationData.name || ''}
            placeholder={translate('form.organisation_name_placeholder')}
            onChange={onChangeData}
            label={translate('form.organisation_name')}
            labelTextStyle="sub-heading-2"
            mRight="1rem"
            error={dataLoaded ? inputValid(organisationData.name) : null}
            showStateIcon={dataLoaded && organisation.name !== organisationData.name}
          />
          <Input
            type="text"
            name="id"
            value={organisationData.id || ''}
            placeholder={translate('form.organisation_id_placeholder')}
            onChange={onChangeData}
            label={translate('form.organisation_id')}
            labelTextStyle="sub-heading-2"
            disabled
          />
        </InputWrapper>
        <InputWrapper
          css={css`
            margin-right: 1rem;
          `}
        >
          <Input
            width="50%"
            type="text"
            name="phone"
            value={organisationData.phone || ''}
            placeholder={translate('form.organisation_phone_placeholder')}
            onChange={onChangeData}
            label={translate('form.organisation_phone')}
            labelTextStyle="sub-heading-2"
          />
        </InputWrapper>
        <CustomButton onClick={onUpdateOrganisation} size="large">
          {translate('form.update')}
        </CustomButton>
      </ContentWrapper>
    </BlockWrapper>
  );
};
