// Components
import { ColumnTitle } from 'components';
import { Hostname } from './Hostname';
import { RecordType } from './RecordType';
import { Targets } from './Targets';
import { RecordActionDropdown } from './ActionDropdown';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { useSelector } from 'react-redux';

// selectors
import { selectDnsRecordsIsLoading } from 'redux/selectors';

// Types
import { IRecord } from 'types/dns';
import { IconCheck } from 'assets/svg';
import { ColumnType } from 'antd/lib/table';

export const useRecordsCols = () => {
  const { translate } = useTranslations('dns');

  const isLoading = useSelector(selectDnsRecordsIsLoading);

  const columns: ColumnType<IRecord>[] = [
    {
      title: <ColumnTitle>{translate('records.hostname')}</ColumnTitle>,
      dataIndex: 'name',
      className: 'name-column',
      render: (name: string) => <Hostname hostname={name} isLoading={isLoading} />,
    },
    {
      title: <ColumnTitle>{translate('records.record_type')}</ColumnTitle>,
      dataIndex: 'type',
      width: '4rem',
      render: (type: string) => <RecordType recordType={type} isLoading={isLoading} />,
    },
    {
      title: <ColumnTitle>{translate('records.auto_generated')}</ColumnTitle>,
      dataIndex: '',
      width: '2rem',
      align: 'center',
      render: (record: IRecord) => (record.autoGenerated ? <IconCheck /> : <></>),
    },
    {
      title: <ColumnTitle>{translate('records.targets')}</ColumnTitle>,
      dataIndex: '',
      render: (record: IRecord) => (
        <Targets tags={record.tags} systems={record.systems} isLoading={isLoading} />
      ),
    },
    {
      title: '',
      dataIndex: '',
      key: 'x',
      className: 'action-column',
      render: (record: IRecord) => (
        <RecordActionDropdown
          id={record.id}
          name={record.name}
          zoneId={record.zoneId}
          autoGenerated={record.autoGenerated}
          isLoading={isLoading}
        />
      ),
    },
  ];

  return columns;
};
