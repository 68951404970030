import { FC } from 'react';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useTheme } from 'styled-components';
import { useTranslations } from 'hooks/useTranslations';
import { CustomText } from 'typography/Text';
import { CustomCheckbox } from 'components';

type Props = {
  isDisabled: boolean;
  onChange: (event: CheckboxChangeEvent) => void;
  desc?: string;
  color?: string;
};

export const CheckboxWithDesc: FC<Props> = ({ isDisabled, onChange, desc, color, ...rest }) => {
  const { translate } = useTranslations('global');
  const {
    palette: { fonts },
  } = useTheme();
  return (
    <CustomCheckbox checked={isDisabled} onChange={onChange} {...rest}>
      <CustomText type="body-2" color={color ? color : fonts.disabled}>
        {desc ? desc : translate('show_disabled')}
      </CustomText>
    </CustomCheckbox>
  );
};
