// libs

import { css, useTheme } from 'styled-components';

// components
import { CustomButton, CustomButtonLink, Loader } from 'components';
import { ModalFooter, ModalHeader, ModalScrollablePart } from 'components/Modal';
import { CustomText } from 'typography/Text';
import { IconModalQuestionMarkModal } from 'assets/svg';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { useDispatch, useSelector } from 'react-redux';
import { useSubmitOnEnter } from 'hooks/useSubmitOnEnter';

// actions
import { setModalContent } from 'redux/actions/app';
import { removeOrganisationUser } from 'redux/actions/organisation';

// selectors
import { selectModalData, selectModalIsLoading } from 'redux/selectors';

// types
import { IModalRemoveUser } from 'types';

export const RemoveUserModal = () => {
  const dispatch = useDispatch();

  const { translate } = useTranslations('settings');

  const {
    palette: {
      fonts: { bodyDark },
    },
  } = useTheme();

  const data = useSelector(selectModalData) as IModalRemoveUser['data'] | null;
  const isLoading = useSelector(selectModalIsLoading);

  const isButtonEnabled = !isLoading;

  const onClose = () => {
    dispatch(setModalContent({ type: null, data: null }));
  };

  const onRemove = () => {
    onClose();
    data?.accountId !== undefined &&
      data?.accountId !== null &&
      dispatch(removeOrganisationUser({ accountId: data.accountId }));
  };

  useSubmitOnEnter({ callback: isButtonEnabled ? onRemove : () => {} });

  return (
    <>
      <ModalHeader
        title={translate('remove_user_modal.title')}
        icon={<IconModalQuestionMarkModal width="2.5rem" height="2.5rem" />}
        withCloseButton={true}
        onClose={onClose}
      />
      <ModalScrollablePart>
        <CustomText
          type="sub-heading-1"
          color={bodyDark}
          css={css`
            display: block;
            max-width: 32rem;
            margin-bottom: 2rem;
          `}
        >
          {translate('remove_user_modal.description', {
            fullName: data?.fullName,
            userName: data?.userName,
            orgName: data?.orgName,
          })}
        </CustomText>
      </ModalScrollablePart>

      <ModalFooter>
        <CustomButtonLink size="large" onClick={onClose}>
          {translate('remove_user_modal.cancel')}
        </CustomButtonLink>
        <CustomButton
          size="large"
          width="6.75rem"
          kind={isButtonEnabled ? 'primaryDanger' : 'disabled'}
          onClick={onRemove}
        >
          {isLoading ? <Loader /> : translate('remove_user_modal.remove')}
        </CustomButton>
      </ModalFooter>
    </>
  );
};
