// libs
import { css, useTheme } from 'styled-components';

// components
import { CustomText } from 'typography/Text';

// types
import { ITrustRequirementBasic } from 'types';
import { SearchSelectOptionElementContainer } from './styled';

// constants
import { TRUST_REQUIREMENTS_ICONS } from 'appConstants';

interface ISearchSelectOptionElement {
  record: ITrustRequirementBasic;
  isSelected: boolean;
}

export const OptionElement = ({
  record: { description, id, type },
}: ISearchSelectOptionElement) => {
  const {
    palette: { fonts },
  } = useTheme();

  const Icon = TRUST_REQUIREMENTS_ICONS[type];

  return (
    <SearchSelectOptionElementContainer>
      <Icon />
      <CustomText ellipsis={true} type="body-2" color={fonts.bodyDark}>
        {description}
      </CustomText>

      <CustomText
        type="hint"
        color={fonts.disabled}
        css={css`
          margin-left: 0.625rem;
        `}
      >
        #{id}
      </CustomText>
    </SearchSelectOptionElementContainer>
  );
};
