// libs
import { css } from 'styled-components';

// components
import { Input } from 'components';

// hooks
import { useDispatch } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';
import { useCallback, useEffect, useState } from 'react';

// actions
import { updateSystemDetailsRoute } from 'redux/actions/app';

// types
import { BaseSyntheticEvent } from 'react';
import { ISystemGatewayRouteModified } from 'types';

interface ISubnetName {
  route: ISystemGatewayRouteModified;
}

export const SubnetName = ({ route }: ISubnetName) => {
  const dispatch = useDispatch();

  const { translate } = useTranslations('systems');

  const [value, setValue] = useState(route.name || '');

  useEffect(() => {
    setValue(route.name || '');
  }, [route.name]);

  const onChange = useCallback((event: BaseSyntheticEvent) => {
    setValue(event.target.value);
  }, []);

  const onBlur = useCallback(
    (event: BaseSyntheticEvent) => {
      const newValue = event.target.value;

      dispatch(
        updateSystemDetailsRoute({
          id: route.id,
          name: newValue,
          subnet: route.subnet,
          weight: route.weight,
        })
      );
    },
    [route, dispatch]
  );

  return (
    <Input
      aria-label={translate('details.aria_labels.subnet_name')}
      placeholder={translate('details.gateway.subnet_name_placeholder')}
      textStyle="body-2"
      size="middle"
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      maxLength={64}
      labelCss={css`
        display: none;
      `}
      stateMessageCss={css`
        display: none;
      `}
      containerCss={css`
        flex: 1;
        margin-left: 0.75rem;
      `}
    />
  );
};
