// Lib
import { put } from 'typed-redux-saga';

// Actions
import { setEnrolmentKeys, setIsLoading } from 'redux/actions/enrolment-keys';

// Types
import { IActionPayload, IGetEnrolmentKeys } from 'types';

// Utils
import { SyncQueryParams } from '../../utils';
import { ApiTypeCaller } from '../../api/utils';

export function* getEnrolmentKeysSaga({
  payload: { syncQueryParams, ...payload },
}: IActionPayload<IGetEnrolmentKeys>) {
  yield put(setIsLoading(true));

  if (syncQueryParams) {
    payload = yield* SyncQueryParams(payload);
  }

  const { result, success } = yield* ApiTypeCaller('getEnrolmentKeys', payload);
  if (success && result) yield put(setEnrolmentKeys(result));
}
