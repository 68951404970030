import { CustomText } from 'typography/Text';
import { useTheme } from 'styled-components';

interface IPort {
  children: React.ReactNode;
}

export const Port = ({ children, ...rest }: IPort) => {
  const {
    palette: { fonts },
  } = useTheme();

  return (
    <CustomText
      data-test="policy-details-acls-table-ports"
      type="body-2"
      color={fonts.bodyDark}
      {...rest}
    >
      {children}
    </CustomText>
  );
};
