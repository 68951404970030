// components
import { IconNoFoundSearchInputData } from 'assets/svg';
import { EmptyContainer } from '../styled';
import { CustomText } from 'typography/Text';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { css, useTheme } from 'styled-components';

// types
import { ISearchKey } from 'types';

interface IEmpty {
  searchKeyName: ISearchKey['name'];
}

export const Empty = ({ searchKeyName }: IEmpty) => {
  const {
    palette: { fonts },
  } = useTheme();

  const { translate } = useTranslations('global');

  return (
    <EmptyContainer>
      <IconNoFoundSearchInputData
        css={css`
          margin-bottom: 1rem;
        `}
      />
      <CustomText
        css={css`
          max-width: 18.25rem;
          white-space: pre;
        `}
        type="body-2"
        color={fonts.bodyDark}
      >
        {translate('search.empty.no-data', { searchKey: searchKeyName })}
      </CustomText>
    </EmptyContainer>
  );
};
