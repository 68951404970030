// Lib
import { call, put, select } from 'typed-redux-saga';

// actions
import { setSystemsShouldUpdate } from 'redux/actions/unapproved-systems';
import { closeDetails, pushNotification } from 'redux/actions/app';

// selectors
import { selectAppDetailsSystemSystemId } from 'redux/selectors';

// types
import { IActionPayload, IDeclineUnapprovedSystemProps, INotificationType } from 'types';

// Utils
import { orgPush } from '../../utils';
import { translator } from 'utils';
import { ApiTypeCaller } from '../../api/utils';

// sagas
import { getCurrentOrganisationSaga } from '../organisation/getCurrentOrganisationSaga';

export function* declineSystemSaga({
  payload: { systemId },
}: IActionPayload<IDeclineUnapprovedSystemProps>) {
  const selectedSystemId = yield* select(selectAppDetailsSystemSystemId);

  if (selectedSystemId !== undefined && systemId === selectedSystemId) {
    yield* put(closeDetails({}));
  }
  const { result, success } = yield* ApiTypeCaller('declineUnapprovedSystem', { systemId });

  if (success && result) {
    const translate = translator('notifications');

    yield put(setSystemsShouldUpdate());
    yield call(getCurrentOrganisationSaga);

    yield put(
      pushNotification({
        showType: INotificationType.Success,
        description: translate('system_rejected', {
          systemId,
        }),
      })
    );
  }
  return { success, result };
}

export function* declineSystemFromDetailsSaga({
  payload: { systemId },
}: IActionPayload<IDeclineUnapprovedSystemProps>) {
  const { success } = yield* ApiTypeCaller('declineUnapprovedSystem', { systemId });
  const translate = translator('notifications');
  if (success) {
    yield call(orgPush, '/unapproved-systems', { keepSearch: true });
    yield put(setSystemsShouldUpdate());
    yield call(getCurrentOrganisationSaga);

    yield put(
      pushNotification({
        showType: INotificationType.Success,
        description: translate('system_withId_declined', {
          systemId,
        }),
      })
    );
  }
}
