// libs
import { useTheme } from 'styled-components';

// components
import { CustomText } from 'typography/Text';
import { Details } from 'components';

// selectors
import { selectAppDetailsSystemEnclaveVersion } from 'redux/selectors';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { useSelector } from 'react-redux';

export const EnclaveVersionBlock = () => {
  const { translate } = useTranslations('systems');

  const {
    palette: { fonts },
  } = useTheme();

  const enclaveVersion = useSelector(selectAppDetailsSystemEnclaveVersion);

  return (
    <Details.KeyValue height="2.875rem" title={translate('details.enclave_version')}>
      <CustomText type="body-2" color={fonts.title}>
        {enclaveVersion || translate('unknown')}
      </CustomText>
    </Details.KeyValue>
  );
};
