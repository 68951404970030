import { useEffect, useState, useRef } from 'react';
import styled, { css } from 'styled-components';

// Components
import { CustomButton, Input, CustomButtonLink, Loader } from 'components';
import { CustomMessage } from 'components';
import { IconModalDelete } from 'assets/svg';
import { ModalFooter, ModalHeader, ModalScrollablePart } from 'components/Modal';
import { CustomText } from 'typography/Text';

// Hooks
import { useSelector, useDispatch } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';
import { useSubmitOnEnter } from 'hooks/useSubmitOnEnter';

// Selectors
import { selectModalData, selectModalIsLoading } from 'redux/selectors';

// Actions
import { setModalContent } from 'redux/actions/app';
import { deleteDnsRecord, deleteDnsRecords } from 'redux/actions/dns';

// Types
import { IModalRemoveDnsRecords } from 'types';

// constants
import { REMOVE_MODAL_CONFIRMATION_TEXT } from 'appConstants';

const List = styled.ul`
  padding-left: 1.75rem;
  margin-bottom: 2.5rem;
`;

export const RemoveDnsRecordsModal = () => {
  const { translate } = useTranslations('dns');

  const dispatch = useDispatch();

  const [enabled, setEnabled] = useState(false);
  const [inputText, setInputText] = useState('');

  const modalData = useSelector(selectModalData) as IModalRemoveDnsRecords['data'] | null;
  const isLoading = useSelector(selectModalIsLoading);

  const isButtonEnabled = enabled && !isLoading;

  const inputRef = useRef<HTMLInputElement>(null);

  const onClose = () => {
    dispatch(setModalContent({ type: null, data: null }));
  };

  const onRemove = () => {
    if (modalData?.isBulk) {
      dispatch(deleteDnsRecords({ recordIds: modalData?.records.map(record => record.id) }));
    }
    if (!modalData?.isBulk && modalData?.records[0]) {
      dispatch(deleteDnsRecord({ recordId: String(modalData.records[0].id) }));
    }
  };

  useSubmitOnEnter({ callback: isButtonEnabled ? onRemove : () => {} });

  useEffect(() => {
    if (inputText === REMOVE_MODAL_CONFIRMATION_TEXT) {
      setEnabled(true);
    } else {
      if (enabled) setEnabled(false);
    }
  }, [inputText, enabled]);

  useEffect(() => {
    inputRef?.current?.focus();
  }, [inputRef]);

  return (
    <div data-test="dns-records-remove-modal">
      <ModalHeader
        title={translate('records.remove_records_modal.title', {
          count: modalData?.records.length,
        })}
        icon={<IconModalDelete width="2.5rem" height="2.5rem" />}
        withCloseButton={true}
        onClose={onClose}
      />
      <ModalScrollablePart>
        <CustomMessage
          type="error"
          message={translate('records.remove_records_modal.warning')}
          css={css`
            margin-bottom: 2.25rem;
          `}
        />
        <CustomText
          type="sub-heading-2"
          css={css`
            display: block;
            margin-bottom: 0.5rem;
          `}
        >
          {translate('records.remove_records_modal.selected_records', {
            recordsAmount: modalData?.records.length || '',
          })}
        </CustomText>
        <List>
          {modalData?.records.map(record => {
            return <li key={record.id}>{record.name}</li>;
          })}
        </List>

        <Input
          ref={inputRef}
          data-test="dns-records-remove-modal-confirm-input-wrapper"
          type="text"
          name="yescheck"
          value={inputText}
          label={translate('records.remove_records_modal.input_label')}
          maxLength={128}
          placeholder={translate('records.remove_records_modal.input_placeholder')}
          onChange={e => setInputText(e.target.value.toUpperCase())}
          containerCss={css`
            margin-bottom: 0.75rem;
          `}
        />
      </ModalScrollablePart>
      <ModalFooter>
        <CustomButtonLink size="large" onClick={onClose}>
          {translate('records.remove_records_modal.cancel')}
        </CustomButtonLink>
        <CustomButton
          data-test="dns-records-remove-submit"
          width="6.75rem"
          size="large"
          kind={isButtonEnabled ? 'primaryDanger' : 'disabled'}
          onClick={onRemove}
        >
          {isLoading ? <Loader /> : translate('records.remove_records_modal.remove')}
        </CustomButton>
      </ModalFooter>
    </div>
  );
};
