// Lib
import { select, put, call } from 'typed-redux-saga';

// Types
import { IActionPayload, IOrganisation } from 'types';

// Selectors
import { selectPathName, selectPathSearch, selectOrgId } from 'redux/selectors';

// Actions
import { setCurrentOrganisation } from 'redux/actions/account';
import { setEnrolmentKeysShouldUpdate } from 'redux/actions/enrolment-keys';
import { setSystemsShouldUpdate } from 'redux/actions/systems';
import { setSystemsShouldUpdate as setUnapprovedSystemsShouldUpdate } from 'redux/actions/unapproved-systems';
import { setPoliciesShouldUpdate } from 'redux/actions/policies';
import { getCurrentOrganisationSaga } from './getCurrentOrganisationSaga';
import { orgPush } from 'redux/sagas/utils';

// Sagas
import { clearOrgDataSaga } from './clearOrgDataSaga';

export function* changeCurrentOrganisationSaga({
  payload,
}: IActionPayload<IOrganisation['orgId']>) {
  const pathName = yield* select(selectPathName);
  const pathSearch = yield* select(selectPathSearch);
  const currentOrgId = String(yield* select(selectOrgId));
  const newPathName = pathName.replace(currentOrgId, payload) + pathSearch;

  // TODO: refactor
  if (newPathName.includes('/keys')) yield put(setEnrolmentKeysShouldUpdate());
  if (newPathName.includes('/systems')) yield put(setSystemsShouldUpdate());
  if (newPathName.includes('/unapproved-system')) yield put(setUnapprovedSystemsShouldUpdate());
  if (newPathName.includes('/policies')) yield put(setPoliciesShouldUpdate());

  yield call(clearOrgDataSaga);
  yield put(setCurrentOrganisation(payload));

  yield call(orgPush, '/');
  yield call(getCurrentOrganisationSaga);
}
