// components
import { SystemDetails } from '../Systems/Details';
import { DetailsSkeleton } from 'components';
import { StyledDetails } from './Styled';
import { PolicyDetails } from 'modules/Policies/Details';
import { DnsRecordDetails } from 'modules/Dns/Content/Records/Details';
import { EnrolmentKeysDetails } from '../EnrolmentKeys/Details';
import { TagsDetails } from '../Tags/Details';

// hooks
import { useTheme } from 'styled-components';
import { useSelector } from 'react-redux';

// selectors
import { selectAppDetailsTabType, selectAppDetailsTabIsLoading } from 'redux/selectors';
import { TrustRequirementDetails } from 'modules/TrustRequirements/Details';

export const Details = () => {
  const type = useSelector(selectAppDetailsTabType);
  const isLoading = useSelector(selectAppDetailsTabIsLoading);
  const { layout_sizes } = useTheme();
  const width =
    type == 'unapproved-system-view' ||
    type == 'unapproved-system-edit' ||
    type == 'dns-record-view' ||
    type == 'dns-record-edit' ||
    type == 'dns-record-create'
      ? layout_sizes.detailsNarrow
      : layout_sizes.detailsWide;

  const DetailsContent = () => {
    switch (type) {
      case 'system-view':
      case 'system-edit':
      case 'unapproved-system-view':
      case 'unapproved-system-edit':
        return <SystemDetails />;
      case 'enrolment-key-view':
      case 'enrolment-key-edit':
      case 'enrolment-key-create':
        return <EnrolmentKeysDetails />;
      case 'tag-view':
      case 'tag-edit':
      case 'tag-create':
        return <TagsDetails />;
      case 'policy-view':
      case 'policy-edit':
      case 'policy-create':
        return <PolicyDetails />;
      case 'dns-record-view':
      case 'dns-record-edit':
      case 'dns-record-create':
        return <DnsRecordDetails />;
      case 'trust-requirement-view':
      case 'trust-requirement-edit':
      case 'trust-requirement-create':
        return <TrustRequirementDetails />;

      default:
        return null;
    }
  };
  return (
    <StyledDetails data-test="details" shown={Boolean(type || isLoading)} width={width}>
      {isLoading ? <DetailsSkeleton data-test="details-skeleton" /> : DetailsContent()}
    </StyledDetails>
  );
};
