// components
import { PageTitle } from 'components';
import { TitleView } from './View';
import { TitleEdit } from './Edit';

// selectors
import {
  selectAppDetailsSystemDescription,
  selectAppDetailsSystemSystemId,
  selectAppDetailsTabType,
} from 'redux/selectors';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { useSelector } from 'react-redux';

export const TitleBlock = () => {
  const { translate } = useTranslations('systems');

  const type = useSelector(selectAppDetailsTabType);
  const description = useSelector(selectAppDetailsSystemDescription);
  const systemId = useSelector(selectAppDetailsSystemSystemId);

  const pageTitleDescription = description || systemId || '';

  return (
    <>
      <PageTitle
        title={`${
          type === 'system-view' || type === 'system-edit'
            ? translate('systems_enrolled')
            : translate('systems_unapproved')
        }${pageTitleDescription && ` |  ${pageTitleDescription}`}`}
      />
      {(type === 'system-view' || type === 'unapproved-system-view') && <TitleView />}
      {(type === 'system-edit' || type === 'unapproved-system-edit') && <TitleEdit />}
    </>
  );
};
