import { MainState, OSTypes } from 'types';
import { equals } from 'ramda';
import { ROUTE_PATHS } from 'appConstants';

// approved Systems
export const selectApprovedSystems = (state: MainState) => state.Systems.items;
export const selectApprovedSystemSearchKeys = (state: MainState) => state.Systems.searchKeys;
export const selectApprovedSystemsTotal = (state: MainState) => state.Systems.metadata?.total;
export const selectApprovedSystemsUpdateId = (state: MainState) => state.Systems.updateId;
export const selectApprovedSystemsIsLoading = (state: MainState) => state.Systems.isLoading;

export const selectIsApprovedSystemsModule = ({
  router: {
    location: { pathname },
  },
  Account: { currentOrg },
}: MainState) => {
  return pathname.includes(`${currentOrg}${ROUTE_PATHS.systems}`);
};

// unapproved Systems
export const selectUnapprovedSystems = (state: MainState) => state.UnapprovedSystems.items;
export const selectUnapprovedSystemSearchKeys = (state: MainState) =>
  state.UnapprovedSystems.searchKeys;
export const selectUnapprovedSystemsTotal = (state: MainState) =>
  state.UnapprovedSystems.metadata?.total;
export const selectUnapprovedSystemsUpdateId = (state: MainState) =>
  state.UnapprovedSystems.updateId;
export const selectUnapprovedSystemsIsLoading = (state: MainState) =>
  state.UnapprovedSystems.isLoading;

export const selectIsUnapprovedSystemsModule = ({
  router: {
    location: { pathname },
  },
  Account: { currentOrg },
}: MainState) => {
  return pathname.includes(`${currentOrg}${ROUTE_PATHS['unapproved-systems']}`);
};

// Details Data
export const selectAppDetailsSystemDescription = ({ App: { detailsTab } }: MainState) => {
  if (
    detailsTab.type === 'system-view' ||
    detailsTab.type === 'system-edit' ||
    detailsTab.type === 'unapproved-system-view' ||
    detailsTab.type === 'unapproved-system-edit'
  ) {
    return detailsTab.data.description;
  }
};

export const selectAppDetailsSystemTags = ({ App: { detailsTab } }: MainState) => {
  if (
    detailsTab.type === 'system-view' ||
    detailsTab.type === 'system-edit' ||
    detailsTab.type === 'unapproved-system-view' ||
    detailsTab.type === 'unapproved-system-edit'
  ) {
    return detailsTab.data.tags;
  }
};

export const selectAppDetailsSystemSystemId = ({ App: { detailsTab } }: MainState) => {
  if (
    detailsTab.type === 'system-view' ||
    detailsTab.type === 'system-edit' ||
    detailsTab.type === 'unapproved-system-view' ||
    detailsTab.type === 'unapproved-system-edit'
  ) {
    return detailsTab.data.systemId;
  }
};

export const selectAppDetailsSystemHostname = ({ App: { detailsTab } }: MainState) => {
  if (
    detailsTab.type === 'system-view' ||
    detailsTab.type === 'system-edit' ||
    detailsTab.type === 'unapproved-system-view' ||
    detailsTab.type === 'unapproved-system-edit'
  ) {
    return detailsTab.data.hostname;
  }
};

export const selectAppDetailsSystemEnclaveVersion = ({ App: { detailsTab } }: MainState) => {
  if (
    detailsTab.type === 'system-view' ||
    detailsTab.type === 'system-edit' ||
    detailsTab.type === 'unapproved-system-view' ||
    detailsTab.type === 'unapproved-system-edit'
  ) {
    return detailsTab.data.enclaveVersion;
  }
};

export const selectAppDetailsSystemOsVersion = ({ App: { detailsTab } }: MainState) => {
  if (
    detailsTab.type === 'system-view' ||
    detailsTab.type === 'system-edit' ||
    detailsTab.type === 'unapproved-system-view' ||
    detailsTab.type === 'unapproved-system-edit'
  ) {
    return detailsTab.data.osVersion;
  }
};

export const selectAppDetailsSystemConnectedFrom = ({ App: { detailsTab } }: MainState) => {
  if (
    detailsTab.type === 'system-view' ||
    detailsTab.type === 'system-edit' ||
    detailsTab.type === 'unapproved-system-view' ||
    detailsTab.type === 'unapproved-system-edit'
  ) {
    return detailsTab.data.connectedFrom;
  }
};

export const selectAppDetailsSystemEnrolledAt = ({ App: { detailsTab } }: MainState) => {
  if (
    detailsTab.type === 'system-view' ||
    detailsTab.type === 'system-edit' ||
    detailsTab.type === 'unapproved-system-view' ||
    detailsTab.type === 'unapproved-system-edit'
  ) {
    return detailsTab.data.enrolledAt;
  }
};

export const selectAppDetailsSystemEnrolmentKeyId = ({ App: { detailsTab } }: MainState) => {
  if (
    detailsTab.type === 'system-view' ||
    detailsTab.type === 'system-edit' ||
    detailsTab.type === 'unapproved-system-view' ||
    detailsTab.type === 'unapproved-system-edit'
  ) {
    return detailsTab.data.enrolmentKeyId;
  }
};

export const selectAppDetailsSystemEnrolmentKeyDescription = ({
  App: { detailsTab },
}: MainState) => {
  if (
    detailsTab.type === 'system-view' ||
    detailsTab.type === 'system-edit' ||
    detailsTab.type === 'unapproved-system-view' ||
    detailsTab.type === 'unapproved-system-edit'
  ) {
    return detailsTab.data.enrolmentKeyDescription;
  }
};

export const selectAppDetailsSystemNotes = ({ App: { detailsTab } }: MainState) => {
  if (
    detailsTab.type === 'system-view' ||
    detailsTab.type === 'system-edit' ||
    detailsTab.type === 'unapproved-system-view' ||
    detailsTab.type === 'unapproved-system-edit'
  ) {
    return detailsTab.data.notes;
  }
};

export const selectAppDetailsSystemType = ({ App: { detailsTab } }: MainState) => {
  if (
    detailsTab.type === 'system-view' ||
    detailsTab.type === 'system-edit' ||
    detailsTab.type === 'unapproved-system-view' ||
    detailsTab.type === 'unapproved-system-edit'
  ) {
    return detailsTab.data.systemType;
  }
};

export const selectAppDetailsSystemPlatformType = ({
  App: { detailsTab },
}: MainState): OSTypes | null | undefined => {
  if (
    detailsTab.type === 'system-view' ||
    detailsTab.type === 'system-edit' ||
    detailsTab.type === 'unapproved-system-view' ||
    detailsTab.type === 'unapproved-system-edit'
  ) {
    return detailsTab.data.platformType as OSTypes | null;
  }
};

// Details Data New
export const selectAppDetailsSystemNewNotes = ({ App: { detailsTab } }: MainState) => {
  if (detailsTab.type === 'system-edit' || detailsTab.type === 'unapproved-system-edit') {
    return detailsTab.dataNew.notes;
  }
};

export const selectAppDetailsSystemNewIsEnabled = ({ App: { detailsTab } }: MainState) => {
  if (detailsTab.type === 'system-edit' || detailsTab.type === 'unapproved-system-edit') {
    const system = detailsTab.dataNew;
    return system && 'isEnabled' in system ? system.isEnabled : false;
  }
};

export const selectAppDetailsSystemNewDescription = ({ App: { detailsTab } }: MainState) => {
  if (detailsTab.type === 'system-edit' || detailsTab.type === 'unapproved-system-edit') {
    return detailsTab.dataNew.description;
  }
};

export const selectAppDetailsSystemTagsChanged = ({ App }: MainState) => {
  if (App.detailsTab.type === 'system-view' || App.detailsTab.type === 'unapproved-system-view') {
    return false;
  }
  if (App.detailsTab.type === 'system-edit' || App.detailsTab.type === 'unapproved-system-edit') {
    return !equals(App.detailsTab.dataNew.tags, App.detailsTab.data.tags);
  }
};

// Approved Details Data
export const selectAppDetailsApprovedSystemState = ({ App: { detailsTab } }: MainState) => {
  if (detailsTab.type === 'system-view' || detailsTab.type === 'system-edit') {
    return detailsTab.data.state;
  }
};

export const selectAppDetailsApprovedSystemIsEnabled = ({ App: { detailsTab } }: MainState) => {
  if (detailsTab.type === 'system-view' || detailsTab.type === 'system-edit') {
    return detailsTab.data.isEnabled;
  }
};

export const selectAppDetailsApprovedSystemLastSeen = ({ App: { detailsTab } }: MainState) => {
  if (detailsTab.type === 'system-view' || detailsTab.type === 'system-edit') {
    return detailsTab.data.lastSeen;
  }
};

export const selectAppDetailsApprovedSystemEnrolmentKeyIsDeleted = ({
  App: { detailsTab },
}: MainState) => {
  if (
    detailsTab.type === 'system-view' ||
    detailsTab.type === 'system-edit' ||
    detailsTab.type === 'unapproved-system-view' ||
    detailsTab.type === 'unapproved-system-edit'
  ) {
    return detailsTab.data.enrolmentKeyIsDeleted;
  }
};

export const selectAppDetailsApprovedSystemVirtualAddress = ({
  App: { detailsTab },
}: MainState) => {
  if (detailsTab.type === 'system-view' || detailsTab.type === 'system-edit') {
    return detailsTab.data.virtualAddress;
  }
};

export const selectAppDetailsApprovedSystemVirtualNetwork = ({
  App: { detailsTab },
}: MainState) => {
  if (detailsTab.type === 'system-view' || detailsTab.type === 'system-edit') {
    return detailsTab.data.virtualNetwork;
  }
};

export const selectApprovedSystemDns = ({ App }: MainState) => {
  if (App.detailsTab.type === 'system-view' || App.detailsTab.type === 'system-edit') {
    return App.detailsTab.data.dns;
  }
};

export const selectAppDetailsApprovedDisconnectedRetentionMinutes = ({
  App: { detailsTab },
}: MainState) => {
  if (detailsTab.type === 'system-view' || detailsTab.type === 'system-edit') {
    return detailsTab.data.disconnectedRetentionMinutes;
  }
};

export const selectAppDetailsSystemCurrentKnownSubnets = ({ App: { detailsTab } }: MainState) => {
  if (detailsTab.type === 'system-edit') {
    return detailsTab.dataNew.knownSubnets;
  }
  if (detailsTab.type === 'system-view') {
    return detailsTab.data.knownSubnets;
  }
};

export const selectAppDetailsSystemAutoExpireData = ({ App: { detailsTab } }: MainState) => {
  if (detailsTab.type === 'system-edit') {
    return detailsTab.dataNew.autoExpire;
  }
  if (detailsTab.type === 'system-view') {
    return detailsTab.data.autoExpire;
  }
};

export const selectAppDetailsSystemAutoExpireAction = ({ App: { detailsTab } }: MainState) => {
  if (detailsTab.type === 'system-edit') {
    return detailsTab.dataNew.autoExpire?.expiryAction;
  }
  if (detailsTab.type === 'system-view') {
    return detailsTab.data.autoExpire?.expiryAction;
  }
};

export const selectAppDetailsSystemAutoExpireDate = ({ App: { detailsTab } }: MainState) => {
  if (detailsTab.type === 'system-edit') {
    return detailsTab.dataNew.autoExpire?.expiryDateTime;
  }
  if (detailsTab.type === 'system-view') {
    return detailsTab.data.autoExpire?.expiryDateTime;
  }
};

export const selectAppDetailsSystemAutoExpireTimeZone = ({ App: { detailsTab } }: MainState) => {
  if (detailsTab.type === 'system-edit') {
    return detailsTab.dataNew.autoExpire?.timeZoneId;
  }
  if (detailsTab.type === 'system-view') {
    return detailsTab.data.autoExpire?.timeZoneId;
  }
};

export const selectAppDetailsSystemAutoExpireScrollTo = ({ App: { detailsTab } }: MainState) => {
  if (detailsTab.type === 'system-edit') {
    return detailsTab.scrollToAutoExpire;
  }
};

// Approved Details Current Data
export const selectAppDetailsSystemCurrentRoutes = ({ App: { detailsTab } }: MainState) => {
  if (detailsTab.type === 'system-view') {
    return detailsTab.data.gatewayRoutes;
  }

  if (detailsTab.type === 'system-edit') {
    return detailsTab.dataNew.gatewayRoutes;
  }
};

// Unapproved Details Data
export const selectAppDetailsSystemUnapprovedEnrolledFrom = ({
  App: { detailsTab },
}: MainState) => {
  if (
    detailsTab.type === 'unapproved-system-view' ||
    detailsTab.type === 'unapproved-system-edit'
  ) {
    return detailsTab.data.enrolledFrom;
  }
};
