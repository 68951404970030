// Lib
import { call, put, select } from 'typed-redux-saga';

// actions
import { setSystemsShouldUpdate } from 'redux/actions/unapproved-systems';
import { closeDetails, pushNotification } from 'redux/actions/app';

// selectors
import { selectAppDetailsSystemSystemId } from 'redux/selectors';

// types
import { IActionPayload, IDeclineUnapprovedSystemsParams, INotificationType } from 'types';

// Utils
import { ApiTypeCaller } from '../../api/utils';
import { translator } from 'utils';

// sagas
import { getCurrentOrganisationSaga } from '../organisation/getCurrentOrganisationSaga';

export function* declineSystemsSaga({ payload }: IActionPayload<IDeclineUnapprovedSystemsParams>) {
  const selectedSystemId = yield* select(selectAppDetailsSystemSystemId);

  if (selectedSystemId !== undefined && payload.systemIds.includes(selectedSystemId)) {
    yield* put(closeDetails({}));
  }

  const { result, success } = yield* ApiTypeCaller('declineUnapprovedSystems', payload);

  if (success && result) {
    const translate = translator('notifications');

    if (result.systemsDeclined > 0) {
      yield put(setSystemsShouldUpdate());
      yield call(getCurrentOrganisationSaga);
    }

    yield put(
      pushNotification({
        showType: INotificationType.Success,
        description: translate('systems_rejected'),
      })
    );
  }
}
