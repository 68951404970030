// Lib
import { createReducer } from '@reduxjs/toolkit';
import { IUnapprovedSystemsStore, IGetUnapprovedSystemsResponse } from 'types';
import {
  setSystems,
  setSystem,
  removeSystems,
  setIsLoading,
  setSystemsShouldUpdate,
  setSystemSearchKeys,
} from '../actions/unapproved-systems';
import { randomHash } from 'utils/hash';

export const initialSystemsResponse: IGetUnapprovedSystemsResponse = {
  items: [],
  metadata: {
    total: 0,
    firstPage: 0,
    prevPage: null,
    lastPage: 0,
    nextPage: null,
  },
  links: {
    first: '',
    prev: null,
    next: null,
    last: '',
  },
};

const initialState: IUnapprovedSystemsStore = {
  ...initialSystemsResponse,
  isLoading: true,
  updateId: randomHash(),
  searchKeys: null,
};

export const unapprovedSystemsReducer = createReducer(initialState, builder => {
  builder
    .addCase(setSystems, (state, { payload }) => {
      return {
        ...state,
        ...payload,
        isLoading: false,
      };
    })
    .addCase(setSystem, (state, { payload }) => {
      state.items = state.items.find(item => item.systemId === payload.systemId)
        ? state.items.map(item => (item.systemId === payload.systemId ? payload : item))
        : [...state.items, payload];
    })
    .addCase(removeSystems, (state, { payload }) => {
      state.items = state.items.filter(system => !payload.includes(system.systemId as string));
    })
    .addCase(setIsLoading, (state, { payload: isLoading }) => {
      state.isLoading = isLoading;
    })
    .addCase(setSystemsShouldUpdate, state => {
      state.updateId = randomHash();
    })
    .addCase(setSystemSearchKeys, (state, { payload }) => {
      state.searchKeys = payload;
    });
});
