// libs
import { takeEvery, takeLatest, takeLeading } from 'redux-saga/effects';

// actions
import {
  createTrustRequirement,
  deleteTrustRequirement,
  deleteTrustRequirements,
  getTrustRequirements,
  getTrustRequirementSearchKeys,
  saveUpdatedTrustRequirement,
} from 'redux/actions/trust-requirements';

// sagas
import { getTrustRequirementsSaga } from './getTrustRequirements';
import { deleteTrustRequirementSaga } from './deleteTrustRequirement';
import { deleteTrustRequirementsSaga } from './deleteTrustRequirements';
import { createTrustRequirementSideMenu } from 'redux/actions/app';
import { createTrustRequirementDetailsSaga } from './createTrustRequirementDetails';
import { createTrustRequirementSaga } from './createTrustRequirement';
import { updateTrustRequirementSaga } from './updateTrustRequirement';
import { getTrustRequirementSearchKeysSaga } from './getTrustRequirementSearchKeys';

function* trustRequirementsSaga() {
  yield takeLatest(getTrustRequirements, getTrustRequirementsSaga);
  yield takeLeading(deleteTrustRequirement, deleteTrustRequirementSaga);
  yield takeLeading(deleteTrustRequirements, deleteTrustRequirementsSaga);
  yield takeLeading(createTrustRequirementSideMenu, createTrustRequirementDetailsSaga);
  yield takeLeading(createTrustRequirement, createTrustRequirementSaga);
  yield takeLeading(saveUpdatedTrustRequirement, updateTrustRequirementSaga);
  yield takeEvery(getTrustRequirementSearchKeys, getTrustRequirementSearchKeysSaga);
}

export default trustRequirementsSaga;
