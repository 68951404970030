// libs
import { css } from 'styled-components';

// Components
import { CodeText } from 'typography/Code';
import { Details } from 'components';

// Selectors
import { selectAppDetailsSystemSystemId, selectAppDetailsTabType } from 'redux/selectors';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { useSelector } from 'react-redux';

export const SystemIdBlock = () => {
  const { translate } = useTranslations('systems');

  const type = useSelector(selectAppDetailsTabType);
  const systemId = useSelector(selectAppDetailsSystemSystemId);

  return (
    <Details.KeyValue
      height="3.5rem"
      title={translate('system_id')}
      contentCss={css`
        align-items: center;
      `}
    >
      {systemId && (
        <CodeText
          disabled={type === 'unapproved-system-view' || type === 'unapproved-system-edit'}
          code={systemId}
        />
      )}
    </Details.KeyValue>
  );
};
