import styled from 'styled-components';
import { ReactComponent as IconArrowLeft } from 'assets/svg/arrow-left.svg';

export const List = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;

  li:not(:last-child) {
    border-bottom: 0.0625rem solid ${({ theme }) => theme.palette.fills.strokeMedium};
  }
`;

export const ListItem = styled('li')`
  display: flex;
  align-items: center;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;

  outline: none !important;
`;

export const IconArrowRight = styled(IconArrowLeft)`
  transform: rotate(180deg);
  width: 0.875rem;
  height: 0.875rem;
  vertical-align: middle;
`;
