import { FC } from 'react';
import { useTheme } from 'styled-components';
import { CustomText } from 'typography/Text';
import { SystemStatusBadge } from 'components';
import { useTranslations } from 'hooks/useTranslations';
import { ISystemSummaryModel } from 'types';

type IConnectedFrom = Required<Pick<ISystemSummaryModel, 'isEnabled' | 'connectedFrom' | 'state'>>;

export const ConnectedFrom: FC<IConnectedFrom> = ({ isEnabled, connectedFrom, state }) => {
  const {
    palette: { fonts },
  } = useTheme();
  const { translate } = useTranslations('systems');

  const connectedFromText: Record<ISystemSummaryModel['state'], string | null> = {
    Connected: connectedFrom,
    Disabled: translate('disabled'),
    Disconnected: translate('disconnected'),
  };

  return (
    <CustomText type="body-2" color={isEnabled ? fonts.title : fonts.disabled}>
      <SystemStatusBadge state={state} />
      {connectedFromText[state]}
    </CustomText>
  );
};
