// libs
import { useCallback } from 'react';
import { css } from 'styled-components';

// types
import { UpdatePlanData } from 'types';

// components
import { CustomText } from 'typography/Text';
import {
  UpdatePlanDescription,
  StyledKeyText,
  StyledModalSubHeader,
  UpdatePlanItem,
} from './styled';
import { Loader, CustomButton, CustomButtonLink, PlanDependentContent } from 'components';
import { IconUpdatePlan } from 'assets/svg';
import { ModalFooter, ModalHeader, ModalScrollablePart } from 'components/Modal';

// hooks
import { useDispatch, useSelector } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';
import { useSubmitOnEnter } from 'hooks/useSubmitOnEnter';

// actions
import { setModalContent } from 'redux/actions/app';
import { upgradePlan } from 'redux/actions/payment';

// selectors
import { selectModalData, selectModalIsLoading, selectPathName } from 'redux/selectors';

// constants
import { EXTERNAL_PLAN, MSP_MANAGED_PLAN, MSP_PLAN, PLANS_N_SUBS_ANCHOR } from 'appConstants';

// utils
import { formatCurrencyAmount } from 'utils';

export const UpgradePlanModal = () => {
  const dispatch = useDispatch();

  const { translate } = useTranslations('settings');

  const isLoading = useSelector(selectModalIsLoading);
  const newPlan = useSelector(selectModalData) as UpdatePlanData;
  const pathName = useSelector(selectPathName);

  const isButtonEnabled = !isLoading;

  const onClose = () => {
    dispatch(setModalContent({ type: null, data: null }));
  };

  const handlePlanUpgrade = useCallback(() => {
    dispatch(
      upgradePlan({
        isYearly: newPlan.isYearly,
        newCapacity: newPlan.capacity,
        plan: newPlan.type,
        successRedirectUrl: `${window.location.origin}${pathName}#${PLANS_N_SUBS_ANCHOR}`,
        cancelRedirectUrl: `${window.location.origin}${pathName}#${PLANS_N_SUBS_ANCHOR}`,
      })
    );
  }, [dispatch, newPlan, pathName]);

  useSubmitOnEnter({ callback: isButtonEnabled ? handlePlanUpgrade : () => {} });

  if (!newPlan) return null;

  const amount = formatCurrencyAmount({
    value: newPlan.isYearly
      ? newPlan.rate * newPlan.capacity * 12
      : newPlan.rate * newPlan.capacity,
  });

  return (
    <PlanDependentContent
      plan={newPlan.type}
      hiddenWith={[EXTERNAL_PLAN, MSP_PLAN, MSP_MANAGED_PLAN]}
    >
      <ModalHeader
        title={
          newPlan.isCurrentPlan
            ? translate('plans.update_capacity_modal')
            : translate('plans.upgrade_plan_modal', { newPlan: newPlan.type })
        }
        icon={<IconUpdatePlan width="2.5rem" height="2.5rem" />}
        withCloseButton={true}
        onClose={onClose}
      />
      <ModalScrollablePart>
        <StyledModalSubHeader type="sub-heading-1">
          {newPlan.isCurrentPlan
            ? translate('plans.sure_update_capacity')
            : translate('plans.sure_upgrade_plan', { newPlan: newPlan.type })}
        </StyledModalSubHeader>
        <UpdatePlanDescription>
          <UpdatePlanItem>
            <StyledKeyText type="body-1">{translate('plan')}</StyledKeyText>
            <div>
              <CustomText type="sub-heading-1">{newPlan.type}</CustomText>
            </div>
          </UpdatePlanItem>

          <UpdatePlanItem>
            <StyledKeyText type="body-1">{translate('plans.new_capacity')}</StyledKeyText>
            <div>
              <CustomText type="sub-heading-1">
                {translate('plans.systems_limit', { amount: newPlan.capacity })}
              </CustomText>
            </div>
          </UpdatePlanItem>

          <UpdatePlanItem>
            <StyledKeyText type="body-1">{translate('plans.amount')}</StyledKeyText>
            <CustomText type="sub-heading-1">
              {newPlan.currencySymbol} {amount} {translate('plans.currency.usd')} /{' '}
              {translate(newPlan.isYearly ? 'plans.per_year' : 'plans.per_month')}
            </CustomText>
          </UpdatePlanItem>
        </UpdatePlanDescription>

        <CustomText
          css={css`
            display: block;
            margin-bottom: 2rem;
          `}
          type="hint"
        >
          {translate('plans.update_message')}
        </CustomText>
      </ModalScrollablePart>
      <ModalFooter>
        <CustomButtonLink size="large" onClick={onClose}>
          {translate('plans.cancel_modal')}
        </CustomButtonLink>
        <CustomButton
          size="large"
          kind={isButtonEnabled ? 'primary' : 'disabled'}
          width={newPlan.isCurrentPlan ? '6.3125rem' : '9.1875rem'}
          onClick={handlePlanUpgrade}
        >
          {isLoading ? (
            <Loader />
          ) : newPlan.isCurrentPlan ? (
            translate('plans.actions.update')
          ) : (
            translate('plans.actions.upgrade')
          )}
        </CustomButton>
      </ModalFooter>
    </PlanDependentContent>
  );
};
