// Hooks
import { useSecurityEvents } from './hooks/useSecurityEvents';

// Components
import {
  CustomTable,
  StyledCol,
  StyledRow,
  PageTitle,
  RefreshButton,
  PaginationText,
  TrialBanner,
} from 'components';
import { CustomText, StyledHeader } from 'typography/Text';
import { tableCss } from './styled';

// Constants
import { ITEMS_PER_TABLE_PAGE } from 'appConstants';

// Helpers
import { emptySecurityEventsArray } from './helpers';

export const SecurityEvents = () => {
  const {
    columns,
    events,
    page,
    setPage,
    total,
    theme,
    translate,
    isLoading,
    fetchSecurityEvents,
  } = useSecurityEvents();

  return (
    <>
      <PageTitle title={translate('security_events')} />
      <StyledRow justify="space-between">
        <StyledCol>
          <StyledHeader>
            <CustomText type="heading-1" color={theme.palette.fonts.title}>
              {translate('security_events')}
            </CustomText>
          </StyledHeader>
        </StyledCol>
      </StyledRow>
      <TrialBanner />
      <StyledRow justify="end" mb="1.5rem" align="middle">
        <StyledCol>
          <RefreshButton onRefresh={() => fetchSecurityEvents()} isLoading={isLoading} />
        </StyledCol>
        <StyledCol>
          <PaginationText total={total} page={page} />
        </StyledCol>
      </StyledRow>
      <CustomTable
        locale={{ emptyText: 'No security events' }}
        columns={columns}
        dataSource={isLoading ? emptySecurityEventsArray : events}
        rowKey={record => JSON.stringify(record)}
        elevated={true}
        pagination={{
          showSizeChanger: false,
          hideOnSinglePage: true,
          pageSize: ITEMS_PER_TABLE_PAGE,
          position: ['bottomCenter'],
          current: page + 1,
          total: total,
          onChange: page => {
            setPage(page - 1);
          },
        }}
        headerHeight="2.5rem"
        rowHeight="auto"
        css={tableCss}
      />
    </>
  );
};
