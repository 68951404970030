// components
import { Tags } from './Tags';
import { Systems } from './Systems';
import { Content, ITargetsTabs } from '../Content';

// hooks
import { useSelector } from 'react-redux';

// selectors
import {
  selectAppDetailsDnsAutoGenerated,
  selectAppDetailsDnsSystemsAmount,
  selectAppDetailsDnsTagsAmount,
} from 'redux/selectors';

interface ITargetsView {
  selectedTab: ITargetsTabs;
  setSelectedTab: (tab: ITargetsTabs) => void;
}

export const TargetsView = ({ selectedTab, setSelectedTab }: ITargetsView) => {
  const systemsAmount = useSelector(selectAppDetailsDnsSystemsAmount);
  const tagsAmount = useSelector(selectAppDetailsDnsTagsAmount);
  const autoGenerated = useSelector(selectAppDetailsDnsAutoGenerated);

  return (
    <Content
      edit={false}
      isAutoGenerated={autoGenerated || false}
      withEmptyState={true}
      selectedTab={selectedTab}
      setSelectedTab={setSelectedTab}
      systemsAmount={systemsAmount || 0}
      tagsAmount={tagsAmount || 0}
      tagsContent={<Tags />}
      systemsContent={<Systems />}
    />
  );
};
