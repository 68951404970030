// Lib
import { takeEvery, all, takeLatest } from 'redux-saga/effects';
import {
  getAccount,
  updateAccount,
  updatePassword,
  createStepUpToken,
  createAccessToken,
  removeAccessToken,
  create2FAQRCode,
  generateRecoveryCodes,
  verify2FACode,
  enable2FA,
  disable2FA,
  openEnable2FAModal,
  validateAuthCode,
  createStepUpAndOpenModal,
  acceptInvite,
  declineInvite,
} from 'redux/actions/account';

import { getAccountSaga } from './getAccountSaga';
import { updateAccountSaga } from './updateAccountSaga';
import { updatePasswordSaga } from './updatePasswordSaga';
import { createStepUpSaga } from './createStepUpSaga';
import { CreateAccessTokenSaga } from './createAccessTokenSaga';
import { removeAccessTokenSaga } from './removeAccessTokenSaga';
import { create2FAQRCodeSaga } from './create2FAQRCodeSaga';
import { generateRecoveryCodesSaga } from './generateRecoveryCodesSaga';
import { verify2FACodeSaga } from './verify2FACodeSaga';
import { enable2FASaga } from './enable2FASaga';
import { disable2FASaga } from './disable2FASaga';
import { openEnable2FAModalSaga } from './openEnable2FAModalSaga';
import { validateAuthCodeSaga } from './validateAuthCodeSaga';
import { createStepUpAndOpenModalSaga } from './createStepUpAndOpenModalSaga';
import { acceptInviteSaga } from './acceptInviteSaga';
import { declineInviteSaga } from './declineInviteSaga';

function* accountSaga() {
  yield all([
    takeEvery(getAccount, getAccountSaga),
    takeLatest(updateAccount, updateAccountSaga),
    takeEvery(updatePassword, updatePasswordSaga),
    takeEvery(createStepUpToken, createStepUpSaga),
    takeEvery(createAccessToken, CreateAccessTokenSaga),
    takeEvery(removeAccessToken, removeAccessTokenSaga),
    takeEvery(create2FAQRCode, create2FAQRCodeSaga),
    takeEvery(generateRecoveryCodes, generateRecoveryCodesSaga),
    takeEvery(verify2FACode, verify2FACodeSaga),
    takeEvery(enable2FA, enable2FASaga),
    takeEvery(disable2FA, disable2FASaga),
    takeEvery(validateAuthCode, validateAuthCodeSaga),
    takeEvery(openEnable2FAModal, openEnable2FAModalSaga),
    takeEvery(createStepUpAndOpenModal, createStepUpAndOpenModalSaga),
    takeEvery(acceptInvite, acceptInviteSaga),
    takeEvery(declineInvite, declineInviteSaga),
  ]);
}

export default accountSaga;
