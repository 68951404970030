// components
import { TagSelect } from 'components';

// hooks
import { useDispatch, useSelector } from 'react-redux';

// selectors
import { selectAppDetailsDnsNewTags } from 'redux/selectors';

// actions
import { updateDnsRecordDetails } from 'redux/actions/app';

// utils
import { TagReference } from 'types';

export const Tags = () => {
  const dispatch = useDispatch();

  const tags = useSelector(selectAppDetailsDnsNewTags) || [];

  const updateTagsCallback = (newTags: TagReference[]) => {
    dispatch(updateDnsRecordDetails({ tags: newTags }));
  };

  return (
    <TagSelect
      width="100%"
      preSelected={tags}
      changeCallback={updateTagsCallback}
      forceAtBottom={false}
    />
  );
};
