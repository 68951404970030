// libs
import { css } from 'styled-components';

// components
import { Details } from 'components';
import { IconPoliciesDetailsOverviewSection } from 'assets/svg';
import { OverviewSection } from './styled';
import { NotesBlock, StateBlock } from '../../Blocks';

// hooks
import { useTranslations } from 'hooks/useTranslations';

export const Overview = () => {
  const { translate } = useTranslations('policies');

  return (
    <OverviewSection>
      <Details.SectionHeader
        Icon={IconPoliciesDetailsOverviewSection}
        title={translate('details.overview')}
        css={css`
          margin-bottom: 1.625rem;
        `}
      />
      <StateBlock />
      <NotesBlock />
    </OverviewSection>
  );
};
