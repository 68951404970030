// libs
import { put } from 'typed-redux-saga';

// actions
import { updatePolicy } from 'redux/actions/policies';
import { pushNotification } from 'redux/actions/app';

// utils
import { ApiTypeCaller } from '../../api/utils';
import { translator } from 'utils';

// types
import { IActionPayload, INotificationType, IEnableUntilPolicyProps, EnableUntilType } from 'types';

export function* enablePolicyUntilSaga({
  payload: { policyId, updatedParams, action, text },
}: IActionPayload<IEnableUntilPolicyProps & EnableUntilType>) {
  const { success, result } = yield* ApiTypeCaller('enablePolicyUntil', {
    policyId,
    updatedParams,
  });

  const translate = translator('notifications');

  if (success && result) {
    yield put(updatePolicy(result));
    yield put(
      pushNotification({
        showType: INotificationType.Success,
        description: translate(
          action === 'EnableFor' ? 'policy_enabled_for' : 'policy_disabled_after',
          {
            time: text,
          }
        ),
      })
    );
  }
}
