import { css, useTheme } from 'styled-components';
import { CustomText } from 'typography/Text';

interface ITitleProps {
  children: React.ReactNode;
}
export const ColumnTitle = ({ children }: ITitleProps) => {
  const theme = useTheme();

  return (
    <CustomText
      type="sub-heading-2"
      color={theme.palette.fonts.bodyDark}
      css={css`
        white-space: nowrap;
      `}
    >
      {children}
    </CustomText>
  );
};
