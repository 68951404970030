import { updateDnsRecordDetails } from '../../../actions/app';

import { appReducerBuilder } from '..';

export const detailsTabDnsRecordsReducerSlice: appReducerBuilder = builder => {
  builder.addCase(updateDnsRecordDetails, (state, { payload }) => {
    if (
      state.detailsTab.type === 'dns-record-edit' ||
      state.detailsTab.type === 'dns-record-create'
    ) {
      state.detailsTab.dataNew = {
        ...state.detailsTab.dataNew,
        ...payload,
      };

      let newFqdn;

      if (state.detailsTab.dataNew.name === '') {
        newFqdn = '';
      } else if (state.detailsTab.dataNew.name === '@') {
        newFqdn = `${state.detailsTab.dataNew.zoneName}`.toLowerCase();
      } else {
        newFqdn =
          `${state.detailsTab.dataNew.name}.${state.detailsTab.dataNew.zoneName}`.toLowerCase();
      }

      state.detailsTab.dataNew.fqdn = newFqdn;
    }
  });
};
