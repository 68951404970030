// libs
import { css } from 'styled-components';
import { useEffect, useRef } from 'react';

// hooks
import { useDispatch } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';
import { useState } from 'react';
import { useSelector } from 'react-redux';

// components
import { IconDetailsAutoExpire } from 'assets/svg';
import { Details } from 'components';
import { AutoExpireSection } from './styled';
import { AutoExpireTimeZoneBlock, ExpirationActionBlock, ExpiryDateBlock } from '../../Blocks';
import Description from './Description';

// selectors
import {
  selectAppDetailsEnrolmentKeyAutoExpireData,
  selectAppDetailsEnrolmentKeyAutoExpireScrollTo,
  selectAppDetailsEnrolmentKeyId,
  selectAppDetailsTabType,
} from 'redux/selectors';

// actions
import {
  createEnrolmentKeyDetailsAutoExpire,
  updateEnrolmentKeyDetailsAutoExpire,
} from 'redux/actions/app';

// types
import { IEnrolmentKeyAutoExpire } from 'types';

type IKeyAutoExpireRememberedData = Partial<IEnrolmentKeyAutoExpire> | null;

export const AutoExpire = () => {
  const { translate } = useTranslations('enrolment-keys');

  const dispatch = useDispatch();

  const myRef = useRef<HTMLDivElement>(null);

  const keyId = useSelector(selectAppDetailsEnrolmentKeyId);
  const type = useSelector(selectAppDetailsTabType);
  const autoExpireData = useSelector(selectAppDetailsEnrolmentKeyAutoExpireData);
  const scrollToAutoExpire = useSelector(selectAppDetailsEnrolmentKeyAutoExpireScrollTo);

  const [isOpen, setIsOpen] = useState(scrollToAutoExpire || false);
  const [rememberedData, setRememberedData] = useState<IKeyAutoExpireRememberedData>(null);

  useEffect(() => {
    if (autoExpireData) {
      setIsOpen(true);
      setRememberedData(null);
    } else {
      setIsOpen(false);
    }
  }, [autoExpireData, setRememberedData]);

  useEffect(() => {
    if (isOpen && !autoExpireData) {
      if (rememberedData) {
        dispatch(updateEnrolmentKeyDetailsAutoExpire({ ...rememberedData }));
      } else {
        dispatch(createEnrolmentKeyDetailsAutoExpire());
      }
    }

    if (!isOpen && autoExpireData) {
      setRememberedData({ ...autoExpireData });
      dispatch(updateEnrolmentKeyDetailsAutoExpire(null));
    }
  }, [dispatch, isOpen, autoExpireData, rememberedData]);

  useEffect(() => {
    return () => {
      setRememberedData(null);
      setIsOpen(false);
    };
  }, [keyId]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      scrollToAutoExpire && myRef.current?.scrollIntoView();
    });

    return () => {
      clearTimeout(timeout);
    };
  }, [scrollToAutoExpire]);

  if (type === 'enrolment-key-view' && !autoExpireData) {
    return null;
  }

  return (
    <AutoExpireSection data-test="enrolment-key-details-auto-expire-section" ref={myRef}>
      <Details.SectionHeader
        Icon={IconDetailsAutoExpire}
        title={translate('details.auto_expire.title')}
        css={css`
          margin-bottom: 0.65rem;
        `}
        switchConfig={
          type === 'enrolment-key-create' || type === 'enrolment-key-edit'
            ? { value: isOpen, setValue: setIsOpen }
            : undefined
        }
      />
      <Description />
      <ExpiryDateBlock isOpen={isOpen} />
      <ExpirationActionBlock isOpen={isOpen} />
      <AutoExpireTimeZoneBlock isOpen={isOpen} />
    </AutoExpireSection>
  );
};
