// libs
import { put } from 'typed-redux-saga';
import { IActionPayload, ICreateAccountStepUpParams } from 'types';

// actions
import { setStepUpToken } from 'redux/actions/account';
import { setModalContent, setModalIsLoading } from 'redux/actions/app';

// utils
import { ApiTypeCaller } from '../../api/utils';

// constants
import {
  ACCOUNT_RE_AUTH_PASSWORD_ERROR_FIELD,
  ACCOUNT_RE_AUTH_PASSWORD_WARNING_ERROR_FIELD,
} from 'appConstants';

export function* createStepUpSaga({
  payload: updateParams,
}: IActionPayload<ICreateAccountStepUpParams>) {
  yield put(setModalIsLoading(true));

  const { success, result } = yield* ApiTypeCaller('createAccountStepUp', updateParams, {
    customErrorField: error => {
      if (error.status === 406) {
        return ACCOUNT_RE_AUTH_PASSWORD_ERROR_FIELD;
      }
      return ACCOUNT_RE_AUTH_PASSWORD_WARNING_ERROR_FIELD;
    },
  });

  if (success && result) {
    yield put(setStepUpToken(result));
    yield put(setModalContent({ type: null, data: null }));
    return success;
  }

  yield put(setModalIsLoading(false));
  return null;
}
