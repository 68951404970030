// libs
import { css, useTheme, CSSProp } from 'styled-components';

// components
import { CustomText } from 'typography/Text';
import { TabPaneContainer, Badge } from './styled';

// types
import { TabWithActiveParam } from './';

export interface ITabsNumber {
  type: 'number';
  width?: string;
  title: string;
  data?: number;
  dataFormat?: 'show' | 'show-if-more-than-0';
  key: string;
  elementCss?: CSSProp;
  titleCss?: CSSProp;
}

export const NumberTabPane = ({
  tab: { key, elementCss, title, titleCss, width, data, dataFormat },
  active,
}: TabWithActiveParam<ITabsNumber>) => {
  const {
    palette: { fonts },
  } = useTheme();

  return (
    <TabPaneContainer
      data-test={key}
      width={width}
      css={css`
        ${elementCss}
      `}
    >
      <span
        className={active ? 'title title-active' : 'title'}
        css={css`
          ${titleCss}
        `}
      >
        {title}
      </span>
      {dataFormat === 'show' && (
        <Badge className="badge">
          <CustomText type="hint" color={fonts.primaryButton}>
            {data}
          </CustomText>
        </Badge>
      )}
      {dataFormat === 'show-if-more-than-0' && data !== 0 && (
        <Badge className="badge">
          <CustomText type="hint" color={fonts.primaryButton}>
            {data}
          </CustomText>
        </Badge>
      )}
    </TabPaneContainer>
  );
};
