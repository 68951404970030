// libs
import { put } from 'typed-redux-saga';
import { setPolicySearchKeys } from 'redux/actions/policies';

// api
import { ApiTypeCaller } from '../../api/utils';

export function* getPolicySearchKeysSaga() {
  const { result, success } = yield* ApiTypeCaller('getPolicySearchKeys');

  if (success && result) yield put(setPolicySearchKeys(result));
}
