import React from 'react';
import styled from 'styled-components';

interface IOrderedListItem {
  children: React.ReactNode;
}

const ListItem = styled.li`
  list-style: none;
  counter-increment: list-counter;
  padding-left: 1.5rem;
  position: relative;
  display: flex;
  flex-direction: column;

  ::before {
    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
    background-color: ${props => props.theme.palette.fills.primary};
    border-radius: 50%;
    color: ${props => props.theme.palette.background.panel};
    transform: translateX(calc(-50% - 1.5rem));
    content: counter(list-counter);
  }
  :not(:last-child)::after {
    content: '';
    position: absolute;
    left: 0;
    top: 2.75rem;
    height: calc(100% - 4rem);
    width: 0.0625rem;
    border-left: 0.0625rem solid ${props => props.theme.palette.fills.strokeMedium};
  }
`;

export const OrderedListItem = ({ children, ...rest }: IOrderedListItem) => {
  return <ListItem {...rest}>{children}</ListItem>;
};
