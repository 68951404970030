// components
import { Container } from './styled';
import { Tooltip as AntdTooltip } from 'antd';

// hooks
import { useRef } from 'react';

// types
import { CSSProp } from 'styled-components';
import { TooltipPropsWithOverlay } from 'antd/lib/tooltip';

export interface ITooltip extends TooltipPropsWithOverlay {
  width?: string;
  maxWidth?: string;
  passedCss?: CSSProp;
  children?: React.ReactNode;
}
export const Tooltip = ({ width, maxWidth, passedCss, children, ...rest }: ITooltip) => {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <Container width={width} maxWidth={maxWidth} css={passedCss} ref={ref}>
      <AntdTooltip
        getPopupContainer={trigger => {
          return ref.current || trigger;
        }}
        showArrow={false}
        {...rest}
      >
        {children}
      </AntdTooltip>
    </Container>
  );
};
