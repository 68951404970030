// libs
import { call, put } from 'typed-redux-saga';
import { storeUser } from 'redux/actions/user';

// actions
import { setAppLoaded, pushNotification } from 'redux/actions/app';

// sagas
import { getCurrentOrganisationSaga } from '../organisation/getCurrentOrganisationSaga';
import { getOrganisationsSaga } from '../account/getOrganisationsSaga';
import { setCurrentOrganisationSaga } from '../organisation/setCurrentOrganisationSaga';
import { getAccountSaga } from '../account/getAccountSaga';
import { redirectToOrganisationSaga } from '../app/redirectSaga/locationChangeSaga/redirectToOrganisationSaga';

// utils
import { translator } from 'utils';

// types
import { IUserProfile, INotificationType } from 'types';

export function* preloadUserDataSaga(profile: IUserProfile) {
  // Store user, preload user organisations
  const setProfile = yield* put(storeUser(profile));
  const getAccount = yield* call(getAccountSaga);
  const getOrganisations = yield* call(getOrganisationsSaga);

  const result = [setProfile, getAccount, getOrganisations];
  const translate = translator('notifications');

  // Set current organisation
  yield call(setCurrentOrganisationSaga);

  if (result.every(Boolean)) {
    yield call(redirectToOrganisationSaga);
    yield call(getCurrentOrganisationSaga);
    yield put(setAppLoaded());
  } else {
    yield put(
      pushNotification({
        showType: INotificationType.Error,
        description: translate('error_loading_user_data'),
      })
    );
  }
}
