// libs
import { css } from 'styled-components';

// components
import { Details } from 'components';
import { IconEnrolmentKeysDetailsDetailsSection } from 'assets/svg';
import {
  ApprovalModeBlock,
  RemoveAfterBlock,
  SystemsEnrolledBlock,
  UseLimitBlock,
} from '../../Blocks';

// hooks
import { useTranslations } from 'hooks/useTranslations';

export const Usage = () => {
  const { translate } = useTranslations('enrolment-keys');

  return (
    <section>
      <Details.SectionHeader
        Icon={IconEnrolmentKeysDetailsDetailsSection}
        title={translate('details.usage')}
        css={css`
          margin-bottom: 1.625rem;
        `}
      />
      <SystemsEnrolledBlock />
      <ApprovalModeBlock />
      <UseLimitBlock />
      <RemoveAfterBlock />
    </section>
  );
};
