// libs
import { css } from 'styled-components';

// components
import { Details } from 'components';

// hooks
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// actions
import { closeDetails, updateValidationStateUpdates } from 'redux/actions/app';
import { createTrustRequirement } from 'redux/actions/trust-requirements';

// selectors
import {
  selectAppDetailsTabIsValid,
  selectAppDetailsTrustRequirementIsTypeSelected,
} from 'redux/selectors';

export const CancelSaveCreate = () => {
  const dispatch = useDispatch();

  const isTypeSelected = useSelector(selectAppDetailsTrustRequirementIsTypeSelected);

  const isValid = useSelector(selectAppDetailsTabIsValid);

  const handleCancel = useCallback(() => {
    dispatch(closeDetails({}));
  }, [dispatch]);

  const handleSave = useCallback(() => {
    if (isValid) {
      dispatch(createTrustRequirement());
    } else {
      dispatch(updateValidationStateUpdates());
    }
  }, [dispatch, isValid]);

  return (
    <Details.CancelSave
      css={css`
        margin: 0 2.5rem;
      `}
      handleCancel={handleCancel}
      handleSave={isTypeSelected ? handleSave : undefined}
    />
  );
};
