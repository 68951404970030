// Lib
import { useEffect } from 'react';

// Actions
import { handleNormalAuthRedirect, handleSilentTokenRenew } from '../../redux/actions/user';

// Hooks
import { useDispatch } from 'react-redux';

interface IOIDCCallback {
  silent?: boolean;
}

export const OIDCCallback = ({ silent }: IOIDCCallback) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const actionHandle = silent ? handleSilentTokenRenew : handleNormalAuthRedirect;
    dispatch(actionHandle());
  }, [silent, dispatch]);
  return null;
};
