// components
import { IconSettingsPlanListMarker } from 'assets/svg';
import { CustomText } from 'typography/Text';
import { List } from '../../styled';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { useTheme } from 'styled-components';

// types
import { IOrganisationPricingCard } from 'types';

const featureListLengths: Record<IOrganisationPricingCard, number> = {
  Starter: 2,
  Business: 4,
  Enterprise: 5,
};

interface IFeatureList {
  type: IOrganisationPricingCard;
}

export const FeatureList = ({ type }: IFeatureList) => {
  const {
    palette: { fonts },
  } = useTheme();

  const { translate } = useTranslations('settings');

  return (
    <List>
      {Array.from({ length: featureListLengths[type] }, (_, index) => (
        <li key={index}>
          <IconSettingsPlanListMarker />
          <CustomText color={fonts.bodyDark} type="hint">
            {translate(`plans.feature_list.${type}.${index}`)}
          </CustomText>
        </li>
      ))}
    </List>
  );
};
