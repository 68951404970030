// components
import { TutorialsGridItem } from '../../styled';
import { List, ListItem, IconWrapper, IconRightDirection } from './styled';
import { SectionHeader } from '../../SectionHeader';
import { ExternalLink } from 'components';

// hooks
import { useTranslations } from 'hooks/useTranslations';

// constants
import { LINK_TO_TUTORIALS } from 'appConstants';
import bannerConfig from 'assets/configs/enclave/banner-config.json';

export const TutorialsSection = () => {
  const { translate } = useTranslations('landing-page');

  if (!bannerConfig?.dashboard?.tutorials?.length) {
    return null;
  }

  const tutorialsConfig = bannerConfig?.dashboard?.tutorials as {
    text?: string;
    url?: string;
  }[];

  return (
    <TutorialsGridItem>
      <SectionHeader
        title={translate('tutorials_section.title')}
        route={
          <ExternalLink
            textType="body-1"
            text={
              <IconWrapper>
                {translate('view_all')}
                <IconRightDirection />
              </IconWrapper>
            }
            href={LINK_TO_TUTORIALS}
          />
        }
      />
      <List>
        {tutorialsConfig &&
          tutorialsConfig.map(tutorialConfig => {
            return (
              tutorialConfig?.text &&
              tutorialConfig?.url && (
                <ListItem key={tutorialConfig.text}>
                  <ExternalLink
                    textType="body-2"
                    text={tutorialConfig.text}
                    href={tutorialConfig.url}
                    ellipsis={true}
                  />
                </ListItem>
              )
            );
          })}
      </List>
    </TutorialsGridItem>
  );
};
