import { CustomText } from 'typography/Text';

import { StyledEmptyWrapper, extraTextStyles, titleTextStyles } from './styled';

// Hooks
import { css, CSSProp, useTheme } from 'styled-components';
import { FC } from 'react';

interface IEmptyContainerProps {
  Icon: React.ReactElement;
  primaryText: string;
  secondaryText: string;
  secondaryLight?: boolean;
  additionalText?: React.ReactElement;
  transparent?: boolean;
  titleCss?: CSSProp;
  descriptionCss?: CSSProp;
}

export const EmptyContainer: FC<IEmptyContainerProps> = ({
  Icon,
  primaryText,
  secondaryText,
  secondaryLight = false,
  additionalText,
  transparent = false,
  titleCss,
  descriptionCss,
  ...rest
}) => {
  const {
    palette: { fonts },
  } = useTheme();

  return (
    <StyledEmptyWrapper transparent={transparent} {...rest}>
      {Icon}

      <CustomText
        className="title"
        type="sub-heading-2"
        color={fonts.bodyDark}
        css={css`
          ${titleTextStyles}
          ${titleCss}
        `}
      >
        {primaryText}
      </CustomText>

      <CustomText
        className="description"
        type={secondaryLight ? 'hint' : 'body-2'}
        color={secondaryLight ? fonts.bodyLight : fonts.bodyDark}
        css={css`
          ${extraTextStyles}
          ${descriptionCss}
        `}
      >
        {secondaryText}
      </CustomText>

      {additionalText}
    </StyledEmptyWrapper>
  );
};
