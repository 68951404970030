// Lib
import { call, put, select } from 'typed-redux-saga';

// actions
import { setSystemsShouldUpdate } from 'redux/actions/systems';
import {
  pushNotification,
  setModalContent,
  closeDetails,
  setModalIsLoading,
} from 'redux/actions/app';

// selectors
import { selectAppDetailsSystemSystemId } from 'redux/selectors';

// types
import { IActionPayload, IRevokeSystemsParams, INotificationType } from 'types';

// Utils
import { ApiTypeCaller } from '../../api/utils';
import { translator } from 'utils';

// sagas
import { getCurrentOrganisationSaga } from '../organisation/getCurrentOrganisationSaga';

export function* revokeSystemsSaga({
  payload: { systemIds },
}: IActionPayload<IRevokeSystemsParams>) {
  yield put(setModalIsLoading(true));

  const selectedSystemId = yield* select(selectAppDetailsSystemSystemId);

  if (selectedSystemId !== undefined && systemIds.includes(selectedSystemId)) {
    yield* put(closeDetails({}));
  }

  const { result, success } = yield* ApiTypeCaller('revokeSystems', { systemIds });

  if (success && result) {
    const translate = translator('notifications');

    if (result.systemsRevoked > 0) {
      yield put(setModalContent({ data: null, type: null }));
      yield put(setSystemsShouldUpdate());
      yield call(getCurrentOrganisationSaga);
    }
    yield put(
      pushNotification({
        showType: INotificationType.Success,
        description: translate('systems_removed'),
      })
    );
  }
  yield put(setModalIsLoading(false));
}
