// libs
import { put } from 'typed-redux-saga';
import { setTagSearchKeys } from 'redux/actions/tags';

// api
import { ApiTypeCaller } from '../../api/utils';

export function* getTagSearchKeysSaga() {
  const { result, success } = yield* ApiTypeCaller('getTagSearchKeys');

  if (success && result) yield put(setTagSearchKeys(result));
}
