import { paths, components } from './api';
import { ISearchKey } from './app';

export enum SystemStates {
  Connected = 'Connected',
  Disconnected = 'Disconnected',
  Disabled = 'Disabled',
}

export type ISystemReferenceModel = components['schemas']['ISystemReferenceModel'];
export type ISystemDnsEntry = components['schemas']['SystemDnsEntry'];

export interface ISystemsStore {
  searchKeys: ISearchKey[] | null;
  items: ISystemSummaryModel[];
  metadata: paths['/org/{orgId}/systems']['get']['responses'][200]['application/json']['metadata'];
  links: paths['/org/{orgId}/systems']['get']['responses'][200]['application/json']['links'];
  isLoading: boolean;
  updateId: string;
}

export type SystemsSortBy = components['schemas']['SystemQuerySortMode'];
export type ISystemSummaryModelPaginatedResponseModel =
  components['schemas']['SystemSummaryModelPaginatedResponseModel'];
export enum SystemSortOptionKeys {
  Approved = 'approved',
  LastSeen = 'lastSeen',
}

export type SystemsSortOptions = {
  [key in SystemSortOptionKeys]: string;
};

export type ISystemSummaryModel = components['schemas']['SystemSummaryModel'];
export type ISystemModel = components['schemas']['SystemModel'];

export type ISystemModelModified = Omit<ISystemModel, 'gatewayRoutes'> & {
  gatewayRoutes: ISystemGatewayRouteModified[];
};

export type ISystemGatewayRoute = components['schemas']['SystemGatewayRouteModel'];
export type ISystemGatewayRouteModified = ISystemGatewayRoute & { id: string };

export type ISystemAutoExpire = components['schemas']['SystemSummaryModel']['autoExpire'];
export type ISystemAutoExpireAction = components['schemas']['AutoExpireModel']['expiryAction'];
export type ISystemAutoExpireTimeZone = components['schemas']['AutoExpireModel']['timeZoneId'];
export type ISystemAutoExpireDate = components['schemas']['AutoExpireModel']['expiryDateTime'];

// Check DNS
export type SystemRecordActions =
  | 'disable'
  | 'enable'
  | 'remove'
  | 'edit'
  | 'reject'
  | 'enableUntil';

export type IApprovedSystemEnabledStates = 'enabled' | 'disabled';

export type IGetSystems = IGetSystemsParams & { syncQueryParams?: boolean };

export interface ILoadSystemDetails extends IGetSystemProps {
  mode?: 'view' | 'edit';
  scrollToAutoExpire?: boolean;
}

export type ISystemsType = 'systems' | 'unapproved-systems';

// Update systems enable/disable state
export interface IUpdateSystemsState {
  systemIds: string[];
  enabled: boolean;
}

// Get systems
export type IGetSystemsParams = paths['/org/{orgId}/systems']['get']['parameters']['query'];
export type IGetSystemsResponse =
  paths['/org/{orgId}/systems']['get']['responses'][200]['application/json'];

// Get single system
export type IGetSystemParams =
  paths['/org/{orgId}/systems/{systemId}']['get']['parameters']['path'];
export type IGetSystemProps = Omit<IGetSystemParams, 'orgId'>;
export type IGetSystemResponse =
  paths['/org/{orgId}/systems/{systemId}']['get']['responses'][200]['application/json'];

// Update single system settings
export type IUpdateSystemParams =
  paths['/org/{orgId}/systems/{systemId}']['patch']['requestBody']['application/json'];
export type IUpdateSystemResponse =
  paths['/org/{orgId}/systems/{systemId}']['patch']['responses'][200]['application/json'];
export interface IUpdateSystemPayload {
  updatedParams: IUpdateSystemParams;
  systemId: string;
}

// Enable system
export type IEnableSystemParams =
  paths['/org/{orgId}/systems/{systemId}/enable']['put']['parameters']['path'];
export type IEnableSystemProps = Omit<IEnableSystemParams, 'orgId'>;
export type IEnableSystemResponse =
  paths['/org/{orgId}/systems/{systemId}/enable']['put']['responses'][200]['application/json'];

// Enable policy until
export type IEnableUntilSystemParams =
  paths['/org/{orgId}/systems/{systemId}/enable-until']['put']['parameters']['path'];

export type IEnableUntilSystemUpdatedParams =
  paths['/org/{orgId}/systems/{systemId}/enable-until']['put']['requestBody']['application/json'];

export type IEnableUntilSystemProps = Omit<IEnableUntilSystemParams, 'orgId'> & {
  updatedParams: IEnableUntilSystemUpdatedParams;
};

export type IEnableUntilSystemResponse =
  paths['/org/{orgId}/systems/{systemId}/enable-until']['put']['responses']['200']['application/json'];

// Disable system
export type IDisableSystemParams =
  paths['/org/{orgId}/systems/{systemId}/disable']['put']['parameters']['path'];
export type IDisableSystemProps = Omit<IDisableSystemParams, 'orgId'>;
export type IDisableSystemResponse =
  paths['/org/{orgId}/systems/{systemId}/disable']['put']['responses'][200]['application/json'];

// Revoke system
export type IRevokeSystemParams =
  paths['/org/{orgId}/systems/{systemId}']['delete']['parameters']['path'];
export type IRevokeSystemProps = Omit<IRevokeSystemParams, 'orgId'>;
export type IRevokeSystemResponse =
  paths['/org/{orgId}/systems/{systemId}']['delete']['responses'][200]['application/json'];

// Revoke multiple systems
export type IRevokeSystemsParams =
  paths['/org/{orgId}/systems']['delete']['requestBody']['application/json'];
export type IRevokeSystemsResponse =
  paths['/org/{orgId}/systems']['delete']['responses'][200]['application/json'];

// Enable systems bulk
export type IEnableSystemsParams =
  paths['/org/{orgId}/systems/enable']['put']['requestBody']['application/json'];
export type IEnableSystemsResponse =
  paths['/org/{orgId}/systems/enable']['put']['responses'][200]['application/json'];

// Enable systems bulk
export type IDisableSystemsParams =
  paths['/org/{orgId}/systems/disable']['put']['requestBody']['application/json'];
export type IDisableSystemsResponse =
  paths['/org/{orgId}/systems/disable']['put']['responses'][200]['application/json'];

// Search keys
export type IGetSystemSearchKeysParams =
  paths['/org/{orgId}/systems/meta/search-keys']['get']['parameters']['path'];

export type IGetSystemSearchKeysResponse =
  paths['/org/{orgId}/systems/meta/search-keys']['get']['responses'][200]['application/json'];
