import { IApiError, INotificationError } from 'types';

export async function parseResponse(response: Response): Promise<any> {
  if (response.status >= 200 && response.status < 300) {
    return response
      .clone()
      .json()
      .catch(() => response.text());
  } else {
    const error = await response.json();
    throw error;
  }
}

export function mapError(error: Error | IApiError): INotificationError {
  if (error instanceof Error) {
    return {
      message: error.name,
      description: error.message,
    };
  }
  return {
    message: `${error.status ? `${error.status} ` : ''}${error.title}`,
    description: error.detail,
  };
}
