// libs
import { css, useTheme } from 'styled-components';

// components
import { List, ListItem } from './styled';
import { InternalLink } from 'components/Links/InternalLink';
import { ControllableIcon, CustomTag } from 'components';
import { CustomText } from 'typography/Text';

// hooks
import { useSelector, useDispatch } from 'react-redux';
import { useCallback, useEffect } from 'react';
import { useTranslations } from 'hooks/useTranslations';

// selectors
import {
  selectAppDetailsTagData,
  selectAppDetailsTagDnsZoneItems,
  selectAppDetailsTagColour,
  selectAppDetailsTagDescription,
  selectAppDetailsTagNewColour,
  selectAppDetailsTagNewDescription,
  selectAppDetailsTagRef,
  selectCurrentOrganisationId,
} from 'redux/selectors';
import { extendTagDetails } from 'redux/actions/tags';

// constants
import { ROUTE_PATHS } from 'appConstants';
import { TAG_DETAILS_ELEMENTS_NUM, TAGS_DEFAULT_COLOUR } from 'appConstants/tags';
import { IconSideMenuDns } from 'assets/svg';

export const DnsZonesTab = () => {
  const {
    palette: { fonts },
  } = useTheme();

  const { translate } = useTranslations('tags');
  const dispatch = useDispatch();

  const tag = useSelector(selectAppDetailsTagData);
  const colour = useSelector(selectAppDetailsTagColour);
  const newColour = useSelector(selectAppDetailsTagNewColour);
  const description = useSelector(selectAppDetailsTagDescription);
  const newDescription = useSelector(selectAppDetailsTagNewDescription);
  const tagRef = useSelector(selectAppDetailsTagRef);

  const dnsZones = useSelector(selectAppDetailsTagDnsZoneItems);
  const orgId = useSelector(selectCurrentOrganisationId);

  const fetchData = useCallback(() => {
    dispatch(extendTagDetails({ tag: tag?.tag || '', module: 'dnsZones' }));
  }, [dispatch, tag]);

  const getDescription = useCallback(() => {
    if (dnsZones) {
      if (dnsZones.length === 1) {
        return `details.connections.tabs.dnsZones.description.title_one`;
      }
      if (dnsZones.length > TAG_DETAILS_ELEMENTS_NUM) {
        return `details.connections.tabs.dnsZones.description.title_first`;
      }
      return `details.connections.tabs.dnsZones.description.title_all`;
    }

    return 'details.connections.tabs.dnsZones.description.title_all';
  }, [dnsZones]);

  useEffect(() => {
    if (!dnsZones) {
      fetchData();
    }
  }, [dispatch, dnsZones, fetchData]);

  return (
    <div>
      <div>
        {dnsZones && (
          <>
            <CustomText
              css={css`
                white-space: pre;
                display: flex;
                margin-top: 1.5rem;
                margin-bottom: 0.75rem;
                > span {
                  margin-left: 0.5rem;
                }
              `}
              type="body-2"
              color={fonts.bodyLight}
            >
              {translate(getDescription(), { number: TAG_DETAILS_ELEMENTS_NUM })}
              {tagRef && description && (
                <CustomTag
                  disableHoverEffect
                  tag={{
                    ref: tagRef,
                    colour: newColour || colour || TAGS_DEFAULT_COLOUR,
                    tag: newDescription !== undefined ? newDescription : description,
                  }}
                />
              )}
            </CustomText>
            <List>
              {dnsZones.slice(0, TAG_DETAILS_ELEMENTS_NUM).map(record => {
                const recordName = record.name;

                return (
                  <ListItem tabIndex={0} key={record.id}>
                    <ControllableIcon
                      icon={<IconSideMenuDns />}
                      size="medium"
                      state="default"
                      stateCss={{
                        default: css`
                          svg {
                            rect {
                              fill: ${({ theme }) => theme.palette.background.iconBackground};
                            }
                            path {
                              fill: ${({ theme }) => theme.palette.icons.disabled};
                            }
                          }
                        `,
                        active: css``,
                      }}
                      css={css`
                        margin-right: 0.75rem;
                      `}
                    />
                    <InternalLink
                      title={recordName}
                      type="body-2"
                      to={`/org/${orgId}${ROUTE_PATHS.dns}/zones/${record.id}`}
                      ellipsis={true}
                    >
                      {recordName}
                    </InternalLink>
                  </ListItem>
                );
              })}
            </List>
          </>
        )}
      </div>
    </div>
  );
};
