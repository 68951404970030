// libs
import { useMemo } from 'react';
import { css } from 'styled-components';

// hooks
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import { useTranslations } from 'hooks/useTranslations';

// selectors
import { selectCurrentOrganisationRole, selectManageBillingUrl } from 'redux/selectors';

// components
import { ContentWrapper } from 'modules/Account/styled';
import { CustomText, StyledSubtitle } from 'typography/Text';
import { ExternalLink } from 'components';

// constants
import { LINK_TO_PRICING } from 'appConstants/links/enclave';

export const DescriptionPane = () => {
  const {
    palette: { fonts },
  } = useTheme();

  const { translate } = useTranslations('settings');

  const role = useSelector(selectCurrentOrganisationRole);
  const billingUrl = useSelector(selectManageBillingUrl);

  const isOwner = useMemo(() => role && role === 'Owner', [role]);

  return (
    <ContentWrapper justify="flex-start">
      <StyledSubtitle>{translate('plans_n_subs')}</StyledSubtitle>
      <CustomText
        color={fonts.bodyLight}
        type="body-2"
        css={css`
          margin-bottom: 2rem;
        `}
      >
        {translate('upgrade_plan_here')}
      </CustomText>
      {billingUrl && isOwner && (
        <ExternalLink
          textType="body-1"
          href={billingUrl || ''}
          text={translate('manage_billing')}
          target="_self"
          width="8.5rem"
          css={css`
            padding: 0.5rem 0;
            margin-bottom: 0.5rem;
          `}
          withIcon
        />
      )}
      <ExternalLink
        textType="body-1"
        href={LINK_TO_PRICING}
        text={translate('compare_plans')}
        target="_self"
        width="8.5rem"
        css={css`
          padding: 0.5rem 0;
        `}
        withIcon
      />
    </ContentWrapper>
  );
};
