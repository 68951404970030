import styled from 'styled-components';

export const Container = styled.div<{ width?: string; height?: string }>`
  min-height: 1.5rem;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  display: flex;
  flex-direction: row;
  align-items: center;
`;
