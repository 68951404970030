// libs
import { put, call } from 'typed-redux-saga';

// actions
import { setSystem } from 'redux/actions/systems';
import { pushNotification } from 'redux/actions/app';

// utils
import { ApiTypeCaller } from '../../api/utils';
import { translator } from 'utils';

// types
import { IActionPayload, IEnableSystemProps, IDisableSystemProps, INotificationType } from 'types';

// sagas
import { getCurrentOrganisationSaga } from '../organisation/getCurrentOrganisationSaga';

export function* enableDisableSystemSaga(
  { payload: { systemId } }: IActionPayload<IEnableSystemProps | IDisableSystemProps>,
  enable = true
) {
  const { success, result } = yield* ApiTypeCaller(
    enable ? 'enableSystem' : 'disableSystem',
    systemId
  );
  const translate = translator('notifications');

  if (success && result) {
    yield put(setSystem(result));
    yield call(getCurrentOrganisationSaga);
    yield put(
      pushNotification({
        showType: INotificationType.Success,
        description: translate('system_state_changed', {
          systemId,
          state: enable ? translate('enabled_lowercase') : translate('disabled_lowercase'),
        }),
      })
    );
  }
}
