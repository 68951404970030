// libs
import { css } from 'styled-components';

// components
import { Empty } from '../../Empty';
import { CustomTable } from 'components';

// hooks
import { useSelector } from 'react-redux';
import { useColumnsView } from './Columns';
import { useTranslations } from 'hooks/useTranslations';

// selectors
import { selectAppDetailsTrustRequirementCurrentIpRanges } from 'redux/selectors';

export const tableCss = css`
  td {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
    vertical-align: text-top;
  }

  cursor: default;

  tbody tr {
    cursor: default;

    :last-child td {
      border: none;
    }
  }
`;

export const IpRangesView = () => {
  const ipRanges = useSelector(selectAppDetailsTrustRequirementCurrentIpRanges);

  const { translate } = useTranslations('trust-requirements');

  const columns = useColumnsView();

  return (
    <>
      {ipRanges?.length ? (
        <CustomTable
          data-test="trust-requirement-details-ip-ranges-table"
          withBorder={false}
          columns={columns}
          headerHeight="2.375rem"
          rowHeight="auto"
          css={tableCss}
          rowKey="id"
          dataSource={ipRanges}
        />
      ) : (
        <Empty text={translate('details.types.PublicIp.ip_ranges_empty_state')} />
      )}
    </>
  );
};
