// libs
import { css, useTheme } from 'styled-components';

// components
import { CustomText } from 'typography/Text';
import { Row, StatusIcon, IStatusColor } from '../styled';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { useSelector } from 'react-redux';

// selectors
import { selectAppDetailsSystemCurrentKnownSubnets } from 'redux/selectors';

// types
import { ISystemGatewayRouteModified } from 'types';

// utils
import { getRouteStatus } from '../../helpers';

interface ISubnet {
  route: ISystemGatewayRouteModified;
}

type IStatusTypes = 'custom' | 'known' | 'formerly-known';

export const Subnet = ({ route, ...rest }: ISubnet) => {
  const theme = useTheme();

  const themeMap: Record<IStatusTypes, IStatusColor> = {
    known: theme.palette.state.success,
    'formerly-known': theme.palette.state.alert,
    custom: null,
  };

  const { translate } = useTranslations('systems');

  const knownSubnets = useSelector(selectAppDetailsSystemCurrentKnownSubnets);

  const status = getRouteStatus({ route, knownSubnets: knownSubnets || [] });

  const {
    palette: { fonts },
  } = useTheme();

  return (
    <Row data-test="system-details-gateway-route">
      <StatusIcon colour={themeMap[status]} />
      <CustomText
        css={css`
          overflow-wrap: anywhere;
        `}
        type="body-2"
        color={fonts.bodyLight}
        {...rest}
      >
        {`${route.subnet}${
          route.userEntered ? '' : ' (' + translate('details.gateway.known') + ')'
        }`}
      </CustomText>
    </Row>
  );
};
