// libs
import { call, put, select } from 'typed-redux-saga';

// actions
import { pushNotification } from 'redux/actions/app';
import { setDnsRecordsShouldUpdate } from 'redux/actions/dns';
import { setDetailsContent } from 'redux/actions/app';

// selectors
import { selectAppDetailsTabData, selectAppDetailsTabDataNew } from 'redux/selectors';

// sagas
import { checkCreatedSaga } from '../tags/checkCreatedSaga';

// types
import {
  IApiResponse,
  INotificationType,
  IRecordDetails,
  IUpdateDnsRecordUpdatedParams,
  IUpdateDnsRecordResponse,
} from 'types';

// utils
import { ApiTypeCaller } from '../../api/utils';
import { systemsToStrings, tagsToStrings, translator, diff, removeUndefinedKeys } from 'utils';
import { pick } from 'ramda';

export function* updateRecordSaga() {
  const data: IRecordDetails = yield select(selectAppDetailsTabData);
  const dataNew: IRecordDetails = yield select(selectAppDetailsTabDataNew);

  const changedProps = diff(dataNew, data);

  const updatedParams: IUpdateDnsRecordUpdatedParams = pick(['name', 'notes'], changedProps);

  updatedParams.tags = changedProps.tags ? tagsToStrings(changedProps.tags) : undefined;
  updatedParams.systems = changedProps.systems ? systemsToStrings(changedProps.systems) : undefined;

  const { success, result }: IApiResponse<IUpdateDnsRecordResponse> = yield ApiTypeCaller(
    'updateDnsRecord',
    {
      recordId: String(dataNew.id),
      updatedParams: removeUndefinedKeys(updatedParams),
    }
  );
  const translate = translator('notifications');

  if (success && result) {
    yield put(
      setDetailsContent({
        data: result,
        type: 'dns-record-view',
      })
    );
    yield put(setDnsRecordsShouldUpdate());
    yield put(
      pushNotification({
        showType: INotificationType.Success,
        description: translate('dns_record_updated', {
          recordId: result.id,
          fqdn: result.fqdn,
        }),
      })
    );
  }

  if (updatedParams.tags) {
    yield call(checkCreatedSaga, updatedParams.tags);
  }
}
