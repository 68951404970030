// libs
import { memo } from 'react';

// components
import { CustomTag } from 'components';
import Skeleton from 'react-loading-skeleton';

// types
import { components } from 'types';

// utils
import { equals } from 'ramda';
import styled from 'styled-components';

type TagProps = {
  tags: components['schemas']['IUsedTagModel'][];
  isEnabled: boolean;
};

export const TagsWrapper = styled.div<{ isEnabled?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;

  opacity: ${({ isEnabled }) => (isEnabled ? 1 : 0.6)};

  span:not(:last-child) {
    margin-bottom: 0.25rem;
  }
`;

export const Tags = memo(({ tags, isEnabled }: TagProps) => {
  return (
    <TagsWrapper isEnabled={isEnabled}>
      {tags && tags.map(tag => <CustomTag tag={tag} key={`tags_${tag.ref}`} />)}
    </TagsWrapper>
  );
}, equals);

interface PoliciesTagProps extends TagProps {
  isLoading: boolean;
}

export const PoliciesTags = ({ isLoading, tags, isEnabled }: PoliciesTagProps) => {
  return <>{isLoading ? <Skeleton /> : <Tags tags={tags} isEnabled={isEnabled} />}</>;
};
