// libs
import { css } from 'styled-components';

// components
import { Details } from 'components';
import { OverviewSection } from './styled';
import { IconTrustRequirementDetailsOverviewSection } from 'assets/svg';
import { NotesBlock, OverviewDescriptionBlock, TypeBlock } from '../../Blocks';

// hooks
import { useTranslations } from 'hooks/useTranslations';

export const Overview = () => {
  const { translate } = useTranslations('trust-requirements');

  return (
    <OverviewSection>
      <Details.SectionHeader
        Icon={IconTrustRequirementDetailsOverviewSection}
        title={translate('details.overview')}
        css={css`
          margin-bottom: 0.5rem;
        `}
      />
      <OverviewDescriptionBlock />
      <TypeBlock />
      <NotesBlock />
    </OverviewSection>
  );
};
