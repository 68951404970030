// libs
import { css } from 'styled-components';

// components
import { Details, CustomToggle } from 'components';

// selectors
import { selectAppDetailsSystemNewIsEnabled } from 'redux/selectors';

// hooks
import { useCallback } from 'react';
import { useTranslations } from 'hooks/useTranslations';
import { useDispatch, useSelector } from 'react-redux';

// actions
import { updateSystemDetails } from 'redux/actions/app';

export const StateApprovedEdit = () => {
  const dispatch = useDispatch();

  const { translate } = useTranslations('systems');
  const { translate: translateGlobal } = useTranslations('global');

  const isNewEnabled = useSelector(selectAppDetailsSystemNewIsEnabled);

  const handleClick = useCallback(() => {
    dispatch(updateSystemDetails({ isEnabled: !isNewEnabled }));
  }, [dispatch, isNewEnabled]);

  return (
    <Details.KeyValue height="2.875rem" title={translate('details.state')}>
      <CustomToggle
        css={css`
          margin-right: 0.5rem;
        `}
        aria-label={translateGlobal('details_pane.aria_labels.switch_state')}
        enabled={isNewEnabled ?? false}
        label={isNewEnabled ? translate('enabled') : translate('disabled')}
        onClick={handleClick}
      />
    </Details.KeyValue>
  );
};
