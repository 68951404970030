import { appReducerBuilder } from '..';
import {
  createTrustRequirementDetailsPublicIpIpRangeCondition,
  deleteTrustRequirementDetailsPublicIpIpRangeCondition,
  updateTrustRequirementDetails,
  updateTrustRequirementDetailsUserAuthenticationAuthority,
  updateTrustRequirementDetailsPublicIpIpRangeCondition,
  addTrustRequirementDetailsPublicIpLocationCondition,
  updateTrustRequirementDetailsPublicIpLocationConditionIsBlocked,
  deleteTrustRequirementDetailsPublicIpLocationCondition,
  clearTrustRequirementDetailsPublicIpLocationConditions,
} from 'redux/actions/app';

import { mergeDeepRight, dissocPath } from 'ramda';
import { randomHash, translator, validateIpRangeString } from 'utils';

import {
  PUBLIC_IP_CONDITION_IP_RANGE,
  PUBLIC_IP_CONDITION_LOCATION,
  REGEX_HYPHEN_WITH_SPACES,
  TRUST_REQUIREMENT_IP_RANGE_VALIDATION_STATE_PREFIX,
} from 'appConstants';

import {
  ITrustRequirementPublicIpIpRangeConditionModified,
  ITrustRequirementPublicIpLocationConditionModified,
} from 'types';

const translate = translator('trust-requirements');

export const detailsTabTrustRequirementsReducerSlice: appReducerBuilder = builder => {
  builder.addCase(updateTrustRequirementDetails, (state, { payload }) => {
    const payloadWithoutConditions: typeof payload = dissocPath(['settings', 'condition'], payload);

    const newCondition = payload.settings?.condition;

    if (state.detailsTab.type === 'trust-requirement-edit') {
      state.detailsTab.dataNew = mergeDeepRight(state.detailsTab.dataNew, payloadWithoutConditions);

      if (newCondition) {
        const conditions = state.detailsTab.dataNew.settings.conditions || [];

        const index = conditions.findIndex(condition => condition.claim === newCondition.claim);

        if (index !== -1) {
          conditions[index] = newCondition;
        } else {
          state.detailsTab.dataNew.settings.conditions = [...conditions, newCondition];
        }
      }
    }

    if (state.detailsTab.type === 'trust-requirement-create') {
      if (state.detailsTab.dataNew) {
        state.detailsTab.dataNew = mergeDeepRight(
          state.detailsTab.dataNew,
          payloadWithoutConditions
        ) as typeof state.detailsTab.dataNew;

        const conditions = state?.detailsTab?.dataNew?.settings?.conditions || [];

        if (conditions && newCondition) {
          const index = conditions.findIndex(condition => condition.claim === newCondition.claim);

          if (index !== -1) {
            conditions[index] = newCondition;
          } else if (state.detailsTab.dataNew.settings) {
            state.detailsTab.dataNew.settings.conditions = [...conditions, newCondition];
          }
        }
      } else if (payload?.type) {
        // after we selected type
        state.detailsTab.dataNew = {
          description: '',
          notes: null,
          settings: { conditions: [], configuration: {} },
          type: payload.type,
        };
      }
    }
  });
  builder.addCase(
    updateTrustRequirementDetailsUserAuthenticationAuthority,
    (state, { payload }) => {
      if (
        (state.detailsTab.type === 'trust-requirement-edit' ||
          state.detailsTab.type === 'trust-requirement-create') &&
        state.detailsTab.dataNew
      ) {
        state.detailsTab.dataNew = {
          ...state.detailsTab.dataNew,
          settings: {
            conditions: [],
            configuration: { authority: payload },
          },
        };
      }
    }
  );
  builder.addCase(createTrustRequirementDetailsPublicIpIpRangeCondition, (state, { payload }) => {
    if (
      (state.detailsTab.type === 'trust-requirement-edit' ||
        state.detailsTab.type === 'trust-requirement-create') &&
      state.detailsTab.dataNew
    ) {
      const addedIpRange: ITrustRequirementPublicIpIpRangeConditionModified = {
        id: randomHash(),
        type: PUBLIC_IP_CONDITION_IP_RANGE,
        isBlocked: 'false',
        description: '',
        value: '',
        ...payload,
      };

      if (state.detailsTab.dataNew.settings?.conditions === null) {
        state.detailsTab.dataNew.settings.conditions = [addedIpRange];
      }

      if (state.detailsTab.dataNew.settings?.conditions) {
        state.detailsTab.dataNew.settings.conditions.push(addedIpRange);
      }

      state.detailsTab.validationState = {
        ...state.detailsTab.validationState,
        [TRUST_REQUIREMENT_IP_RANGE_VALIDATION_STATE_PREFIX + addedIpRange.id]: {
          state: { error: { message: translate('details.types.PublicIp.ip_range_error') } },
          updated: false,
        },
      };
    }
  });
  builder.addCase(updateTrustRequirementDetailsPublicIpIpRangeCondition, (state, { payload }) => {
    if (
      (state.detailsTab.type === 'trust-requirement-edit' ||
        state.detailsTab.type === 'trust-requirement-create') &&
      state.detailsTab.dataNew
    ) {
      if (state.detailsTab.dataNew.settings?.conditions) {
        const index = state.detailsTab.dataNew.settings.conditions.findIndex(
          condition => condition.id === payload.id
        );

        if (index > -1) {
          if (payload.range.value !== undefined) {
            const validationState = validateIpRangeString({ ipRange: payload.range.value });

            state.detailsTab.validationState[
              TRUST_REQUIREMENT_IP_RANGE_VALIDATION_STATE_PREFIX + payload.id
            ] = {
              state: validationState,
              updated: true,
            };

            if (!validationState.error && payload.range.value) {
              payload.range.value = payload.range?.value?.replace(REGEX_HYPHEN_WITH_SPACES, ' - ');
            }
          }

          state.detailsTab.dataNew.settings.conditions[index] = {
            ...state.detailsTab.dataNew.settings.conditions[index],
            ...payload.range,
          };
        }
      }
    }
  });
  builder.addCase(deleteTrustRequirementDetailsPublicIpIpRangeCondition, (state, { payload }) => {
    if (
      (state.detailsTab.type === 'trust-requirement-edit' ||
        state.detailsTab.type === 'trust-requirement-create') &&
      state.detailsTab.dataNew
    ) {
      if (state.detailsTab.dataNew.settings?.conditions) {
        const index = state.detailsTab.dataNew.settings.conditions.findIndex(
          condition => condition.id === payload.id
        );

        if (index > -1) {
          state.detailsTab.dataNew.settings.conditions.splice(index, 1);
          delete state.detailsTab.validationState[
            TRUST_REQUIREMENT_IP_RANGE_VALIDATION_STATE_PREFIX + payload.id
          ];
        }
      }
    }
  });
  builder.addCase(addTrustRequirementDetailsPublicIpLocationCondition, (state, { payload }) => {
    if (
      (state.detailsTab.type === 'trust-requirement-edit' ||
        state.detailsTab.type === 'trust-requirement-create') &&
      state.detailsTab.dataNew
    ) {
      const addedLocation: ITrustRequirementPublicIpLocationConditionModified = {
        id: randomHash(),
        type: PUBLIC_IP_CONDITION_LOCATION,
        ...payload,
      };

      if (state.detailsTab.dataNew.settings?.conditions === null) {
        state.detailsTab.dataNew.settings.conditions = [addedLocation];
      }

      if (state.detailsTab.dataNew.settings?.conditions) {
        state.detailsTab.dataNew.settings.conditions.push(addedLocation);
      }
    }
  });
  builder.addCase(deleteTrustRequirementDetailsPublicIpLocationCondition, (state, { payload }) => {
    if (
      (state.detailsTab.type === 'trust-requirement-edit' ||
        state.detailsTab.type === 'trust-requirement-create') &&
      state.detailsTab.dataNew
    ) {
      const index = state.detailsTab.dataNew.settings?.conditions?.findIndex(
        condition =>
          condition.type === PUBLIC_IP_CONDITION_LOCATION && condition.value === payload.value
      );

      if (index !== undefined && index !== -1) {
        state.detailsTab.dataNew.settings?.conditions?.splice(index, 1);
      }
    }
  });

  builder.addCase(
    updateTrustRequirementDetailsPublicIpLocationConditionIsBlocked,
    (state, { payload: { isBlocked } }) => {
      if (
        (state.detailsTab.type === 'trust-requirement-edit' ||
          state.detailsTab.type === 'trust-requirement-create') &&
        state.detailsTab.dataNew &&
        state.detailsTab.dataNew.settings?.conditions
      ) {
        for (let i = 0; i < state.detailsTab.dataNew.settings.conditions.length; i++) {
          const item = state.detailsTab.dataNew.settings.conditions[i];

          if (
            item.type === PUBLIC_IP_CONDITION_LOCATION &&
            item?.isBlocked &&
            item.isBlocked !== isBlocked
          ) {
            state.detailsTab.dataNew.settings.conditions[i].isBlocked = isBlocked;
          }
        }
      }
    }
  );
  builder.addCase(clearTrustRequirementDetailsPublicIpLocationConditions, state => {
    if (
      (state.detailsTab.type === 'trust-requirement-edit' ||
        state.detailsTab.type === 'trust-requirement-create') &&
      state.detailsTab.dataNew?.settings?.conditions
    ) {
      state.detailsTab.dataNew.settings.conditions =
        state.detailsTab.dataNew?.settings?.conditions.filter(
          condition => condition.type !== PUBLIC_IP_CONDITION_LOCATION
        );
    }
  });
};
