// libs
import { css, useTheme } from 'styled-components';

// components
import { Details } from 'components';
import { IconEnrolmentKeyDetailsDNSSection } from 'assets/svg';
import { DnsBlock } from '../../Blocks';
import { CustomText } from 'typography/Text';
import { DnsSection } from './styled';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { useSelector } from 'react-redux';

// selectors
import { selectAppDetailsSystemTagsChanged } from 'redux/selectors';

export const DNSApproved = () => {
  const { translate } = useTranslations('systems');

  const {
    palette: { fonts },
  } = useTheme();

  const tagsChanged = useSelector(selectAppDetailsSystemTagsChanged);

  return (
    <DnsSection changed={tagsChanged}>
      <Details.SectionHeader
        Icon={IconEnrolmentKeyDetailsDNSSection}
        title={translate('details.dns.dns')}
        info={
          tagsChanged ? (
            <CustomText
              css={css`
                margin-left: 0.625rem;
              `}
              type="hint"
              color={fonts.disabled}
            >
              {translate('details.dns.would_be_updated_prompt')}
            </CustomText>
          ) : null
        }
      />
      <DnsBlock />
    </DnsSection>
  );
};
