// components
import { CustomText } from 'typography/Text';
import { Details } from 'components';

// selectors
import { selectAppDetailsApprovedSystemIsEnabled } from 'redux/selectors';

// hooks
import { useTheme } from 'styled-components';
import { useTranslations } from 'hooks/useTranslations';
import { useSelector } from 'react-redux';

export const StateApprovedView = () => {
  const { translate } = useTranslations('systems');

  const {
    palette: { fonts },
  } = useTheme();

  const isEnabled = useSelector(selectAppDetailsApprovedSystemIsEnabled);

  return (
    <Details.KeyValue height="2.875rem" title={translate('details.state')}>
      <CustomText type="body-2" color={fonts.title}>
        {isEnabled ? translate('enabled') : translate('disabled')}
      </CustomText>
    </Details.KeyValue>
  );
};
