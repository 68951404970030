// libs
import { forwardRef, useCallback } from 'react';
import { css, useTheme } from 'styled-components';

// components
import { CustomText } from 'typography/Text';
import { IconDetailsInputEdit } from 'assets/svg';
import { IconInputError } from 'assets/svg';
import { Input } from 'antd';
import { Container, InputWrapper, Suffix } from './styled';
const { TextArea } = Input;

// types
import { TextAreaProps } from 'antd/lib/input';
import { TextAreaRef } from 'antd/lib/input/TextArea';

export interface IProps extends TextAreaProps {
  error?: {
    message: string | React.ReactElement;
  } | null;
}

export const DetailsInput = forwardRef<TextAreaRef, IProps>(({ error, onChange, ...rest }, ref) => {
  const {
    palette: { fonts, state },
  } = useTheme();

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      //trim newlines
      event.target.value = event.target.value.replace(/\r?\n|\r/g, '');

      if (onChange) {
        onChange(event);
      }
    },
    [onChange]
  );

  return (
    <Container>
      <InputWrapper error={Boolean(error)}>
        <TextArea
          {...rest}
          onChange={handleChange}
          bordered={true}
          autoSize={true}
          ref={ref}
          maxLength={128}
        />
        <Suffix as={error?.message ? IconInputError : IconDetailsInputEdit} />
      </InputWrapper>
      <CustomText
        type="hint"
        color={error ? state.error : fonts.bodyLight}
        css={css`
          display: block;
          margin-top: 0.25rem;
          min-height: 1.25rem;
        `}
      >
        {error?.message ? error?.message : ''}
      </CustomText>
    </Container>
  );
});
