import styled, { css, useTheme } from 'styled-components';

import { CopyTool } from 'components';
import { CustomText } from '../Text';
import { useCopy } from 'hooks/useCopy';
import { TextTypes } from 'types';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 3.5625rem;
  padding: 0 1rem;
  background-color: ${props => props.theme.palette.background.base};
  border: 0.0625rem solid ${props => props.theme.palette.background.iconBackground};
  border-radius: 0.25rem;

  // we have margin bottom here because otherwise tooltip would cause component to overflow
  margin-bottom: 1.5rem;

  .console-text {
    font-weight: bold;
    font-family: Nunito;
  }
`;

const TextContainer = styled.div`
  white-space: nowrap;
  width: fit-content;
  overflow: auto;
  display: block;
  width: 100%;
`;
const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 0.1875rem;
  border-left: 0.0625rem solid ${props => props.theme.palette.background.iconBackground};
  width: 2.6875rem;
  height: 2.25rem;
`;
interface IEnrolmentKeyCodeProps {
  enrolmentKey: string;
  disabled?: boolean;
  onCopyKey?: (key: string) => void;
  textType?: TextTypes;
}

export const EnrolmentKeyCode: React.FC<IEnrolmentKeyCodeProps> = ({
  enrolmentKey,
  disabled,
  onCopyKey,
  textType = 'system',
  ...rest
}) => {
  const { onCopyClick, copied } = useCopy({ copyingValue: enrolmentKey, onCopyCode: onCopyKey });
  const theme = useTheme();

  const textColor = disabled ? theme.palette.fonts.disabled : theme.palette.fonts.bodyDark;

  return (
    <Wrapper {...rest}>
      <TextContainer>
        <CustomText
          type={textType}
          color={textColor}
          css={css`
            white-space: pre;
            display: inline-block;
          `}
        >
          {enrolmentKey}
        </CustomText>
      </TextContainer>
      <IconContainer>
        <CopyTool disabled={disabled} copied={copied} onCopyClick={onCopyClick} />
      </IconContainer>
    </Wrapper>
  );
};
