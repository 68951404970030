// libs
import { useTheme } from 'styled-components';

// components
import { CustomText } from 'typography/Text';

// hooks
import { useSelector } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';

// selectors
import { selectAppDetailsEnrolmentKeyDisconnectedRetentionMinutes } from 'redux/selectors';

// constants
import { DISCONNECTED_RETENTION_MINUTES_CONFIG } from 'appConstants';

export const RemoveAfterView = () => {
  const { translate } = useTranslations('enrolment-keys');

  const {
    palette: { fonts },
  } = useTheme();

  const disconnectedRetentionMinutes = useSelector(
    selectAppDetailsEnrolmentKeyDisconnectedRetentionMinutes
  );

  return (
    <CustomText type="body-2" color={fonts.title}>
      {DISCONNECTED_RETENTION_MINUTES_CONFIG.find(
        element => element.disconnectedRetentionMinutes === disconnectedRetentionMinutes
      )?.text || `${disconnectedRetentionMinutes} ${translate('details.mins')}`}
    </CustomText>
  );
};
