// Lib
import { put } from 'typed-redux-saga';

import { extendTagDetailsContent } from 'redux/actions/app';

import { IActionPayload, IExtendTagDetailsParams } from 'types';

// Utils
import { ApiTypeCaller } from '../../api/utils';

export function* extendTagDetailsSaga({
  payload: { tag, module },
}: IActionPayload<IExtendTagDetailsParams>) {
  const sortOptions = 'Description';

  switch (module) {
    case 'systems': {
      const { result, success } = yield* ApiTypeCaller('getSystems', {
        search: `tags:${tag}`,
        sort: sortOptions,
        include_disabled: true,
      });

      if (success && result) {
        yield put(
          extendTagDetailsContent({
            module,
            data: result.items,
          })
        );
      }
      break;
    }

    case 'keys': {
      const { result, success } = yield* ApiTypeCaller('getEnrolmentKeys', {
        search: `tags:${tag}`,
        sort: sortOptions,
        include_disabled: true,
      });

      if (success && result) {
        yield put(
          extendTagDetailsContent({
            module,
            data: result.items,
          })
        );
      }
      break;
    }

    case 'policies': {
      const { result, success } = yield* ApiTypeCaller('getPolicies', {
        search: `tags:${tag}`,
        sort: sortOptions,
        include_disabled: true,
      });

      if (success && result) {
        yield put(
          extendTagDetailsContent({
            module,
            data: result.items,
          })
        );
      }
      break;
    }

    case 'dnsRecords': {
      const { result, success } = yield* ApiTypeCaller('getDnsRecords', {
        search: `tags:${tag}`,
      });

      if (success && result) {
        yield put(
          extendTagDetailsContent({
            module: 'dnsRecords',
            data: result.items,
          })
        );
      }
      break;
    }

    case 'dnsZones': {
      const { result, success } = yield* ApiTypeCaller('getDnsZones', {
        search: `auto-dns-tags:${tag}`,
      });

      if (success && result) {
        yield put(
          extendTagDetailsContent({
            module: 'dnsZones',
            data: result.items,
          })
        );
      }
      break;
    }

    default:
      break;
  }
}
