// Components
import {
  IconButtonWrapper,
  MenuButtonsWrapper,
  iconEditFocusCss,
  iconEditHoverCss,
} from './styled';
import { IconButton } from 'components';
import { IconDetailsTopMenuButtonsEdit, IconDetailsTopMenuButtonsClose } from 'assets/svg';

// hooks
import { useDispatch } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';

// types
import { closeDetails, editDetails } from 'redux/actions/app';

type Props = {
  showEditLink: boolean;
};

export const DetailsTopMenuButtons = ({ showEditLink }: Props) => {
  const dispatch = useDispatch();

  const { translate } = useTranslations('global');

  return (
    <MenuButtonsWrapper>
      {showEditLink && (
        <IconButtonWrapper>
          <IconButton
            aria-label={translate('details_pane.aria_labels.edit')}
            data-test="details-edit-icon"
            hoverCss={iconEditHoverCss}
            focusedCss={iconEditFocusCss}
            icon={<IconDetailsTopMenuButtonsEdit />}
            onClick={() => dispatch(editDetails())}
          />
        </IconButtonWrapper>
      )}

      <IconButtonWrapper>
        <IconButton
          aria-label={translate('details_pane.aria_labels.close')}
          icon={<IconDetailsTopMenuButtonsClose />}
          onClick={() => dispatch(closeDetails({}))}
        />
      </IconButtonWrapper>
    </MenuButtonsWrapper>
  );
};
