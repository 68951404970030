// libs
import { css } from 'styled-components';

// components
import { Details } from 'components';
import { ExpirationActionContent } from './styled';
import { ExpirationActionView } from './View';
import { ExpirationActionEdit } from './Edit';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { useSelector } from 'react-redux';

// selectors
import { selectAppDetailsTabType } from 'redux/selectors';

interface IExpirationActionBlock {
  isOpen: boolean;
}

export const ExpirationActionBlock = ({ isOpen }: IExpirationActionBlock) => {
  const { translate } = useTranslations('systems');

  const type = useSelector(selectAppDetailsTabType);

  return (
    <ExpirationActionContent isOpen={isOpen}>
      <Details.KeyValue
        contentCss={css`
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
        `}
        css={css`
          padding: 0;
        `}
        title={translate('details.auto_expire.expiration_action.title')}
      >
        {type === 'system-view' && <ExpirationActionView />}
        {type === 'system-edit' && <ExpirationActionEdit />}
      </Details.KeyValue>
    </ExpirationActionContent>
  );
};
