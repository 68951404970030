// libs
import { useMemo } from 'react';

// components
import { CustomButtonLink } from 'components';

// hooks
import { useEmptyText } from 'hooks/useEmptyText';
import { useSelector } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';

// selectors
import { selectPoliciesIsLoading } from 'redux/selectors';

// constant
import { POLICIES_MODULE } from 'appConstants';

type Props = {
  searchValue: string;
  createNewPolicy: () => void;
};

export const usePoliciesEmptyText = ({ searchValue, createNewPolicy }: Props) => {
  const isLoading = useSelector(selectPoliciesIsLoading);
  const { translate } = useTranslations(POLICIES_MODULE);

  const noDataText = useMemo(
    () => ({ title: translate('no_enrolled'), description: translate('start_using') }),
    [translate]
  );
  const noDataFoundText = useMemo(
    () => ({
      title: translate('no_data_found'),
      description: translate('search_in_docs'),
    }),
    [translate]
  );

  const additionalText = useMemo(
    () => (
      <CustomButtonLink size="large" onClick={createNewPolicy}>
        {translate('start_using_link')}
      </CustomButtonLink>
    ),
    [createNewPolicy, translate]
  );
  const { emptyText } = useEmptyText({
    searchValue,
    noDataText,
    noDataFoundText,
    isLoading,
    additionalText,
    module: POLICIES_MODULE,
  });

  return { emptyText };
};
