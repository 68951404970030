// Lib

import { put } from 'typed-redux-saga';

// actions
import { setPoliciesShouldUpdate } from 'redux/actions/policies';

// Utils
import { translator } from 'utils';
import { ApiTypeCaller } from '../../api/utils';
import { pushNotification } from 'redux/actions/app';

// types
import {
  IActionPayload,
  IEnablePoliciesParams,
  IDisablePoliciesParams,
  INotificationType,
} from 'types';

export function* enableDisablePoliciesSaga(
  { payload }: IActionPayload<IEnablePoliciesParams | IDisablePoliciesParams>,
  enable = true
) {
  const { result, success } = yield* ApiTypeCaller(
    enable ? 'enablePolicies' : 'disablePolicies',
    payload
  );

  const translate = translator('notifications');

  if (success && result) {
    yield put(setPoliciesShouldUpdate());
    yield put(
      pushNotification({
        showType: INotificationType.Success,
        description: translate('policies_state_changed', {
          state: enable ? translate('enabled_lowercase') : translate('disabled_lowercase'),
        }),
      })
    );
  }
}
