// components
import { Suggestion } from './Suggestion';

// hooks
import { useEffect, useMemo } from 'react';

// types
import { ISearchKey } from 'types';
import { IRange, ISearchQueryDetail } from '../helpers';
import { IInputArrowConfig, ISelectedIndex } from '..';

interface IDefaultSuggestions {
  keyName: ISearchKey['name'];
  canHaveMultiple: ISearchKey['canHaveMultiple'];
  existingValues: ISearchQueryDetail['values'];
  values: NonNullable<ISearchKey['hintValues']>;
  addValue: (newValue: string) => void;
  setInputArrowNavigationConfig: (config: IInputArrowConfig) => void;
  selectedIndex: ISelectedIndex;
  range: IRange | null;
}

export const DefaultSuggestions = ({
  keyName,
  values,
  addValue,
  canHaveMultiple,
  existingValues,
  setInputArrowNavigationConfig,
  selectedIndex,
  range,
}: IDefaultSuggestions) => {
  // making sure not to show selected values in suggestions
  const filteredValues = useMemo(
    () =>
      canHaveMultiple
        ? values.filter(value => {
            if (existingValues.some(existingValue => existingValue.value === value)) {
              return false;
            }
            return true;
          })
        : values,
    [canHaveMultiple, existingValues, values]
  );

  // for navigation input with UP and DOWN arrows
  useEffect(() => {
    setInputArrowNavigationConfig({
      length: filteredValues.length,
      onEnter: () => selectedIndex !== null && addValue(values[selectedIndex]),
      isKeyInactive: false,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIndex, setInputArrowNavigationConfig, range, filteredValues.length]);

  return (
    <>
      {filteredValues.map((value, index) => {
        return (
          <Suggestion
            isSelected={index === selectedIndex}
            key={value}
            keyName={keyName}
            value={value}
            onClick={() => addValue(value)}
          />
        );
      })}
    </>
  );
};
