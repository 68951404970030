import styled, { CSSProp } from 'styled-components';
import { Button } from 'antd';

export type Status = 'default' | 'error';

export const StyledButton = styled(Button)<{
  disabled?: boolean;
  status?: Status;
  $focusCss?: CSSProp;
}>`
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  border: none;
  border-radius: 0;

  .ant-typography {
    color: ${({ status, disabled, theme }) =>
      disabled
        ? theme.palette.fonts.disabled
        : status === 'error'
        ? theme.palette.state.error
        : theme.palette.fills.primary};
  }
  :hover,
  :focus,
  :active {
    .ant-typography {
      color: ${({ status, disabled, theme }) =>
        disabled
          ? theme.palette.fonts.disabled
          : status === 'error'
          ? theme.palette.state.errorHover
          : theme.palette.fills.primaryHover};
    }
    ${({ $focusCss, status, disabled, theme }) =>
      $focusCss
        ? $focusCss
        : `
          svg {
           fill: ${
             disabled
               ? theme.palette.fonts.disabled
               : status === 'error'
               ? theme.palette.state.errorHover
               : theme.palette.fills.primaryHover
           };
       
      }
  `}
  }
  :focus,
  :active {
    ${({ status, disabled, theme }) =>
      disabled
        ? 'transparent'
        : status === 'error'
        ? theme.palette.state.errorHover
        : theme.palette.fills.primaryHover};
  }
`;
