import {
  IconTrustRequirementDetailsCustomApiType,
  IconTrustRequirementDetailsInstalledSoftwareType,
  IconTrustRequirementDetailsOsPatchLevelType,
  IconTrustRequirementDetailsPublicIpAddressType,
  IconTrustRequirementDetailsUserAuthenticationType,
} from 'assets/svg';
import {
  ITrustRequirementIcons,
  ITrustRequirementTypeConfig,
  ITypeTrustRequirementUserAuthenticationAuthority,
} from 'types';

export const TRUST_REQUIREMENTS_ICONS: ITrustRequirementIcons = {
  UserAuthentication: IconTrustRequirementDetailsUserAuthenticationType,
  PublicIp: IconTrustRequirementDetailsPublicIpAddressType,
  OsPatchLevel: IconTrustRequirementDetailsOsPatchLevelType,
  InstalledSoftware: IconTrustRequirementDetailsInstalledSoftwareType,
  CustomApi: IconTrustRequirementDetailsCustomApiType,
};

export const TRUST_REQUIREMENTS_TYPES: ITrustRequirementTypeConfig[] = [
  {
    name: 'UserAuthentication',
    isActive: true,
    Icon: TRUST_REQUIREMENTS_ICONS['UserAuthentication'],
  },
  {
    name: 'PublicIp',
    isActive: true,
    Icon: TRUST_REQUIREMENTS_ICONS['PublicIp'],
  },
  {
    name: 'OsPatchLevel',
    isActive: false,
    Icon: TRUST_REQUIREMENTS_ICONS['OsPatchLevel'],
  },
  {
    name: 'InstalledSoftware',
    isActive: false,
    Icon: TRUST_REQUIREMENTS_ICONS['InstalledSoftware'],
  },
  {
    name: 'CustomApi',
    isActive: false,
    Icon: TRUST_REQUIREMENTS_ICONS['CustomApi'],
  },
];

export const USER_AUTHENTICATION_AUTHORITIES: Record<
  ITypeTrustRequirementUserAuthenticationAuthority,
  { key: ITypeTrustRequirementUserAuthenticationAuthority; name: string; isActive: boolean }
> = {
  portal: { key: 'portal', name: 'Enclave Portal', isActive: true },
  azure: { key: 'azure', name: 'Microsoft (Azure AD / Entra ID)', isActive: true },
  google: { key: 'google', name: 'Google', isActive: true },
  duo: { key: 'duo', name: 'Duo SSO', isActive: true },
  okta: { key: 'okta', name: 'Okta', isActive: true },
  jumpcloud: { key: 'jumpcloud', name: 'JumpCloud', isActive: true },
  oidc: { key: 'oidc', name: 'Custom OpenID Connect Authority', isActive: true },
  github: { key: 'github', name: 'Github', isActive: false },
};

export const USER_AUTHENTICATION_JUMPCLOUD_AUTHORITY_URI = 'https://oauth.id.jumpcloud.com/';

export const USER_AUTHENTICATION_DOCS_PREFIX = 'https://docs.enclave.io/go/auth-setup/';

export const USER_AUTHENTICATION_CONDITION_GROUP_ID = 'groups';
export const USER_AUTHENTICATION_CONFIGURATION_TENANT_ID = 'tenantId';

export const USER_AUTHENTICATION_CONDITION_GOOGLE_DOMAIN_ID = 'hd';

export const USER_AUTHENTICATION_CONFIGURATION_AUTHORITY_URI = 'authorityUri';
export const USER_AUTHENTICATION_CONFIGURATION_CLIENT_ID = 'clientId';
export const USER_AUTHENTICATION_CONFIGURATION_AUDIENCE = 'aud';

export const PUBLIC_IP_CONDITION_IP_RANGE = 'ip';
export const PUBLIC_IP_CONDITION_LOCATION = 'country';

export const PUBLIC_IP_CONDITION_LOCATION_IS_BLOCKED_DEFAULT = 'false';

export const TRUST_REQUIREMENTS_IP_RANGE_DESCRIPTION_CHARACTERS_LIMIT = 1024;
