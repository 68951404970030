// Lib
import { pick } from 'ramda';
import { call, put, select } from 'typed-redux-saga';
import { IActionPayload, IUpdateAccountParams, INotificationType } from 'types';

import { apiService } from 'api/ApiService';

// Actions
import { setAccount } from 'redux/actions/account';
import { pushNotification } from 'redux/actions/app';
import { storeUser } from 'redux/actions/user';

// selectors
import { selectAccountProfile } from 'redux/selectors';

// Utils
import { ApiTypeCaller } from '../../api/utils';
import { diff, translator } from 'utils';

export function* updateAccountSaga({
  payload: updateParams,
}: IActionPayload<IUpdateAccountParams>) {
  const profile = yield* select(selectAccountProfile);

  const currentParams: IUpdateAccountParams = pick(
    ['firstName', 'lastName', 'emailNotificationsEnabled'],
    profile
  );

  const { success, result } = yield* ApiTypeCaller(
    'updateAccountData',
    diff(updateParams, currentParams)
  );

  const translate = translator('notifications');

  if (success && result) {
    const updatedUser = yield* call([apiService, apiService.loginSilent]);
    if (updatedUser) {
      yield put(storeUser(updatedUser.profile));
      yield put(setAccount(result));
      yield put(
        pushNotification({
          showType: INotificationType.Success,
          description: translate('account_updated'),
        })
      );
    }
  }
}
