// Lib
import { createReducer } from '@reduxjs/toolkit';
import { History } from 'history';
import { locationChange, setLastLocation } from 'redux/actions/router';
import { IRouterStore } from 'types';

export const createRouterReducer = (history: History) => {
  const initialRouterState: IRouterStore = {
    location: injectQuery(history.location),
    action: history.action,
    lastLocation: '',
  };

  return createReducer(initialRouterState, builder => {
    builder.addCase(locationChange, (state, { payload }) => {
      const { location, action } = payload;
      state.location = injectQuery(location);
      state.action = action;
    });
    builder.addCase(setLastLocation, (state, { payload }) => {
      state.lastLocation = payload;
    });
  });
};

function injectQuery(location: any) {
  if (location && location.query) {
    // Don't inject query if it already exists in history
    return location;
  }

  const searchQuery = location && location.search;

  if (typeof searchQuery !== 'string' || searchQuery.length === 0) {
    return {
      ...location,
      query: {},
    };
  }

  // Ignore the `?` part of the search string e.g. ?username=codejockie
  const search = searchQuery.substring(1);
  // Split the query string on `&` e.g. ?username=codejockie&name=Kennedy
  const queries = search.split('&');
  // Contruct query
  const query = queries.reduce((acc, currentQuery) => {
    // Split on `=`, to get key and value
    const [queryKey, queryValue] = currentQuery.split('=');
    return {
      ...acc,
      [queryKey]: queryValue,
    };
  }, {});

  return {
    ...location,
    query,
  };
}
