// libs
import { css } from 'styled-components';
import { createContext, useEffect } from 'react';

// components
import { IconSystemsDetailsGatewaySection } from 'assets/svg';
import { Details } from 'components';
import { GatewaySection } from './styled';
import { GatewayBlock } from '../../Blocks';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { useState } from 'react';
import { useSelector } from 'react-redux';

// selectors
import {
  selectAppDetailsSystemCurrentRoutes,
  selectAppDetailsSystemPlatformType,
  selectAppDetailsTabType,
} from 'redux/selectors';

// types
import { ISystemGatewayRouteModified } from 'types';

interface IContext {
  isOpen: boolean;
  rememberedRoutes: ISystemGatewayRouteModified[];
  setRememberedRoutes: (routes: ISystemGatewayRouteModified[]) => void;
}

export const GatewayContext = createContext<IContext>({
  isOpen: false,
  rememberedRoutes: [],
  setRememberedRoutes: () => {},
});

export const Gateway = () => {
  const { translate } = useTranslations('systems');

  const [isOpen, setIsOpen] = useState(false);
  const [rememberedRoutes, setRememberedRoutes] = useState<ISystemGatewayRouteModified[]>([]);

  const value = { isOpen, rememberedRoutes, setRememberedRoutes };

  const type = useSelector(selectAppDetailsTabType);
  const routes = useSelector(selectAppDetailsSystemCurrentRoutes);
  const platformType = useSelector(selectAppDetailsSystemPlatformType);

  // only if we have routes it's open by default
  useEffect(() => {
    setIsOpen(!!routes?.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  if (type === 'system-view' && !routes?.length) {
    return null;
  }

  if (type === 'unapproved-system-view' || type === 'unapproved-system-edit') {
    return null;
  }

  return (
    <GatewayContext.Provider value={value}>
      <GatewaySection data-test="system-details-gateway-section">
        <Details.SectionHeader
          Icon={IconSystemsDetailsGatewaySection}
          title={
            type === 'system-view'
              ? translate('details.gateway.title')
              : translate(
                  platformType === 'Linux'
                    ? 'details.gateway.title_edit'
                    : 'details.gateway.title_edit_not_supported'
                )
          }
          css={css`
            margin-bottom: 1rem;
          `}
          switchConfig={
            type === 'system-edit' && platformType === 'Linux'
              ? { value: isOpen, setValue: setIsOpen }
              : undefined
          }
        />
        <GatewayBlock isOpen={isOpen || type === 'system-view' || platformType !== 'Linux'} />
      </GatewaySection>
    </GatewayContext.Provider>
  );
};
