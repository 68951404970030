//libs
import styled, { css, useTheme } from 'styled-components';

//components
import { CustomButton } from 'components';
import { CustomText } from 'typography/Text';

// types
import { IPolicyActiveDays } from 'types';

const daysMap = {
  Monday: 'Mon',
  Tuesday: 'Tue',
  Wednesday: 'Wed',
  Thursday: 'Thu',
  Friday: 'Fri',
  Saturday: 'Sat',
  Sunday: 'Sun',
};

type Day = 'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday' | 'Saturday' | 'Sunday';

interface IDayButtonProps {
  day: Day;
  active: boolean;
  onClick?: (day: IPolicyActiveDays[0]) => void;
  view?: boolean;
}

export const DayButton = ({ day, active, onClick, view = false }: IDayButtonProps) => {
  const handleClick = () => {
    onClick?.(day);
  };

  return (
    <CustomButton
      kind={active ? 'primary' : 'secondary'}
      onClick={handleClick}
      size="middle"
      css={css`
        margin-right: 0.5rem;
        max-width: 2rem;
        max-height: 1.5rem;

        ${view &&
        css`
          cursor: initial !important;
        `}
      `}
    >
      {daysMap[day]}
    </CustomButton>
  );
};

interface IDayButtonLookalike {
  day: Day;
  active: boolean;
}

const LookalikeContainer = styled.div<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.25rem;

  border: 0.0625rem solid
    ${({ theme, isActive }) =>
      isActive ? theme.palette.fills.iconHover : theme.palette.fills.strokeMedium};

  background-color: ${({ theme, isActive }) => isActive && theme.palette.fills.iconHover};

  padding: 0.3125rem 1.5rem;

  margin-right: 0.5rem;
  max-width: 2rem;
  max-height: 1.5rem;
`;
export const DayButtonLookalike = ({ active, day }: IDayButtonLookalike) => {
  const {
    palette: { fonts, fills },
  } = useTheme();

  return (
    <LookalikeContainer isActive={active}>
      <CustomText type="body-2" color={active ? fills.primary : fonts.disabled}>
        {daysMap[day]}
      </CustomText>
    </LookalikeContainer>
  );
};
