// libs
import { put } from 'typed-redux-saga';
import { setTrustRequirementSearchKeys } from 'redux/actions/trust-requirements';

// api
import { ApiTypeCaller } from '../../api/utils';

export function* getTrustRequirementSearchKeysSaga() {
  const { result, success } = yield* ApiTypeCaller('getTrustRequirementSearchKeys');

  if (success && result) yield put(setTrustRequirementSearchKeys(result));
}
