// Libs
import styled from 'styled-components';
import { InfoContainer } from '../../styled';
import { CustomMessage } from 'components';
import { EnrolmentKeyCode } from 'typography/Code';

export const SearchSelectContainer = styled.div`
  width: 100%;
`;

export const SelectKeyContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
`;

export const SelectKeySectionContainer = styled.section`
  display: flex;
  flex-direction: column;
  width: 42rem;
`;

export const StyledInfoContainer = styled(InfoContainer)`
  padding: 0;
`;

export const StyledCustomMessage = styled(CustomMessage)`
  margin-bottom: 1rem;
`;

export const StyledEnrolmentKeyCode = styled(EnrolmentKeyCode)`
  width: 100%;
  border: none;
  margin-bottom: 0;
`;
