// libs
import { useCallback, useEffect, useState } from 'react';
import { css, useTheme } from 'styled-components';

// components
import { CustomButton, CustomButtonLink, CustomMessage, Input, Loader } from 'components';
import { ModalFooter, ModalHeader, ModalScrollablePart } from 'components/Modal';
import { CustomText } from 'typography/Text';
import { IconDnsRenameZoneModal } from 'assets/svg';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { useDispatch, useSelector } from 'react-redux';
import { useSubmitOnEnter } from 'hooks/useSubmitOnEnter';

// actions
import { clearErrors, setModalContent } from 'redux/actions/app';
import { updateDnsZone } from 'redux/actions/dns';

// selectors
import {
  selectDnsZones,
  selectFormError,
  selectModalData,
  selectModalIsLoading,
} from 'redux/selectors';

// types
import { IModalRenameDnsZone } from 'types';

// constants
import { DNS_UPDATE_ZONE_ERROR_FIELD, REGEX_VALID_ZONE_REGEX } from 'appConstants';

export const RenameZoneModal = () => {
  const dispatch = useDispatch();

  const modalData = useSelector(selectModalData) as IModalRenameDnsZone['data'] | null;
  const zones = useSelector(selectDnsZones);
  const error = useSelector(selectFormError(DNS_UPDATE_ZONE_ERROR_FIELD));
  const isLoading = useSelector(selectModalIsLoading);

  const [inputText, setInputText] = useState(modalData?.name || '');

  const isStartValue = inputText === modalData?.name;
  const isUnique = isStartValue || zones.every(zone => zone.name !== inputText);
  const isValid = REGEX_VALID_ZONE_REGEX.test(inputText);
  const enabled = isUnique && isValid && !isStartValue;
  const isButtonEnabled = enabled && !isLoading;

  const { translate } = useTranslations('dns');

  const {
    palette: {
      fonts: { bodyDark },
    },
  } = useTheme();

  const onClose = useCallback(() => {
    dispatch(setModalContent({ type: null, data: null }));
  }, [dispatch]);

  const onRename = useCallback(() => {
    modalData &&
      dispatch(
        updateDnsZone({
          zoneId: String(modalData.id),
          updatedParams: { name: inputText },
        })
      );
  }, [dispatch, inputText, modalData]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    error && dispatch(clearErrors());
    setInputText(e.target.value);
  };

  useEffect(() => {
    error && dispatch(clearErrors());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useSubmitOnEnter({ callback: isButtonEnabled ? onRename : () => {} });

  return (
    <div data-test="dns-rename-zone-modal">
      <ModalHeader
        title={translate('zone_properties.danger_zone.rename_zone_model.title')}
        icon={<IconDnsRenameZoneModal width="2.5rem" height="2.5rem" />}
        withCloseButton={true}
        onClose={onClose}
      />
      <ModalScrollablePart>
        <CustomMessage
          type="warning"
          message={translate('zone_properties.danger_zone.rename_zone_model.warning')}
          withIcon
          css={css`
            margin-bottom: 2rem;
          `}
        />
        <CustomText
          type="body-1"
          color={bodyDark}
          css={css`
            display: block;
            margin-bottom: 1rem;
          `}
        >
          {translate('zone_properties.danger_zone.rename_zone_model.description')}
        </CustomText>

        <Input
          data-test="dns-rename-zone-modal-input-wrapper"
          type="text"
          value={inputText}
          label={translate('zone_properties.danger_zone.rename_zone_model.input_label')}
          placeholder={translate('zone_properties.danger_zone.rename_zone_model.input_placeholder')}
          containerCss={css`
            margin-bottom: 0.75rem;
          `}
          maxLength={128}
          showStateIcon={(inputText !== '' && !isStartValue) || !!error}
          error={
            !isUnique
              ? {
                  message: translate(
                    'zone_properties.danger_zone.rename_zone_model.input_zone_already_used_error'
                  ),
                }
              : inputText !== '' && !isValid
              ? {
                  message: translate(
                    'zone_properties.danger_zone.rename_zone_model.input_zone_invalid_error'
                  ),
                }
              : error
              ? { message: error }
              : null
          }
          onChange={onChange}
        />
      </ModalScrollablePart>

      <ModalFooter>
        <CustomButtonLink size="large" onClick={onClose}>
          {translate('zone_properties.danger_zone.rename_zone_model.cancel')}
        </CustomButtonLink>
        <CustomButton
          data-test="dns-rename-zone-submit"
          size="large"
          width="6.75rem"
          kind={isButtonEnabled ? 'primary' : 'disabled'}
          onClick={onRename}
        >
          {isLoading ? (
            <Loader />
          ) : (
            translate('zone_properties.danger_zone.rename_zone_model.rename')
          )}
        </CustomButton>
      </ModalFooter>
    </div>
  );
};
