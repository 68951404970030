// libs
import { useState } from 'react';
import { css } from 'styled-components';

// components
import {
  IconContainer,
  RightDirectionIcon,
  SystemsEnrolledGridItem,
  SystemsLink,
} from '../../styled';
import { SystemContent } from '../../SystemContent';
import { CustomProgress, PlanDependentContent } from 'components';

// hooks
import { useTheme } from 'styled-components';
import { useTranslations } from 'hooks/useTranslations';
import { useSelector } from 'react-redux';

// selectors
import {
  selectCurrentOrganisationCapacity,
  selectCurrentOrganisationInfo,
  selectCurrentOrganisationPlan,
  selectOrgId,
} from 'redux/selectors';

// constants
import { EXTERNAL_PLAN, MSP_MANAGED_PLAN, MSP_PLAN, UNLIMITED_USES_AMOUNT } from 'appConstants';

export const SystemsEnrolledSection = () => {
  const { translate } = useTranslations('landing-page');

  const {
    palette: { state },
  } = useTheme();

  const [isActive, setIsActive] = useState<boolean>(false);

  const orgId = useSelector(selectOrgId);
  const organizationInfo = useSelector(selectCurrentOrganisationInfo);
  const currentPlan = useSelector(selectCurrentOrganisationPlan);
  const maxSystems = useSelector(selectCurrentOrganisationCapacity);

  return (
    <SystemsLink
      to={`/org/${orgId}/systems/`}
      onMouseEnter={() => setIsActive(true)}
      onMouseLeave={() => setIsActive(false)}
    >
      <SystemsEnrolledGridItem>
        <PlanDependentContent
          plan={currentPlan}
          hiddenWith={[EXTERNAL_PLAN, MSP_PLAN, MSP_MANAGED_PLAN]}
        >
          {maxSystems !== UNLIMITED_USES_AMOUNT && (
            <CustomProgress
              percent={(organizationInfo.enrolledSystems / organizationInfo.maxSystems) * 100}
              sharp
              css={css`
                position: absolute;
                top: 0;
                left: 0;
              `}
              color={state.successStroke}
            />
          )}
        </PlanDependentContent>
        <SystemContent
          superscript={String(organizationInfo.enrolledSystems)}
          subscript={
            maxSystems !== UNLIMITED_USES_AMOUNT
              ? `/ ${String(organizationInfo.maxSystems)}`
              : translate('systems_enrolled_section.title', {
                  count: organizationInfo.enrolledSystems,
                })
          }
          description={translate(
            `systems_enrolled_section.${
              maxSystems === UNLIMITED_USES_AMOUNT ? 'description_alt' : 'description'
            }`,
            {
              count: maxSystems === UNLIMITED_USES_AMOUNT ? organizationInfo.enrolledSystems : 2,
            }
          )}
        />
        <IconContainer $isActive={isActive}>
          <RightDirectionIcon $isActive={isActive} />
        </IconContainer>
      </SystemsEnrolledGridItem>
    </SystemsLink>
  );
};
