import styled from 'styled-components';

export const SwitchContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const SubnetFilterContainer = styled.div`
  display: flex;
  margin-top: 2.25rem;
  flex-direction: column;
`;

export const SubnetsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
