import styled from 'styled-components';
import { MODAL_HEADER_HEIGHT } from 'appConstants';

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2.5rem 2.5rem 1.5rem 2.5rem;
  height: ${MODAL_HEADER_HEIGHT};
`;

export const TitleIconWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;
