// libs
import { css, useTheme } from 'styled-components';

// components
import { OSIcon } from 'components';
import { CustomText } from 'typography/Text';
import { SystemsIconContainer, SystemsListWrapper } from './styled';

// types
import { ISystemReferenceModel, OSTypes } from 'types';

interface ISystemsProps {
  systems: ISystemReferenceModel[];
  width?: string;
}

export const SystemsList = ({ systems, width, ...rest }: ISystemsProps) => {
  const {
    palette: { fonts },
  } = useTheme();

  return (
    <SystemsListWrapper width={width} {...rest}>
      {systems &&
        systems.map(({ id, name, platformType, machineName }) => (
          <SystemsIconContainer key={id}>
            <OSIcon
              platformType={platformType as OSTypes}
              css={css`
                margin-right: 0.375rem;
              `}
            />
            <CustomText
              type="body-2"
              color={fonts.bodyDark}
              css={css`
                white-space: nowrap;
              `}
            >
              {name || machineName || id}
            </CustomText>
          </SystemsIconContainer>
        ))}
    </SystemsListWrapper>
  );
};
