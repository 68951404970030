// components
import { CustomText } from 'typography/Text';

// hooks
import { useTheme } from 'styled-components';

// types
import { IPolicyAclProtocol } from 'types';

// utils
import { useAclProtocolToText } from '../../helpers';

interface IProtocolOption {
  protocol: IPolicyAclProtocol;
}
export const ProtocolOption = ({ protocol }: IProtocolOption) => {
  const {
    palette: { fonts },
  } = useTheme();

  const text = useAclProtocolToText(protocol);

  return (
    <CustomText type="body-2" color={fonts.title}>
      {text}
    </CustomText>
  );
};
