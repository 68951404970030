import { css } from 'styled-components';

// components
import { CustomText } from 'typography/Text';
import { DashboardEmptySystemsContainer, DashboardEmptySystemsContent } from './styled';
import { IconNoFoundSystems } from 'assets/svg';
import { InternalLink } from 'components';

// selectors
import { selectCurrentOrganisationInfo } from 'redux/selectors';

// hooks
import { useTheme } from 'styled-components';
import { useTranslations } from 'hooks/useTranslations';
import { useSelector } from 'react-redux';

// constants
import { ROUTE_PATHS } from 'appConstants';

export const DashboardNoEnrolled = () => {
  const theme = useTheme();
  const { translate } = useTranslations('landing-page');
  const orgInfo = useSelector(selectCurrentOrganisationInfo);

  return (
    <DashboardEmptySystemsContainer>
      <DashboardEmptySystemsContent>
        <IconNoFoundSystems />

        <CustomText
          css={css`
            margin-top: 1rem;
          `}
          type="heading-2"
          color={theme.palette.fonts.bodyDark}
        >
          {translate('systems_enrolled_section.empty.no_enrolled')}
        </CustomText>
        <CustomText
          type="body-2"
          color={theme.palette.fonts.bodyDark}
          css={css`
            margin-top: 1rem;
            width: 100%;
            word-break: break-word;
            white-space: normal;
          `}
        >
          {translate('systems_enrolled_section.empty.start_using')}
        </CustomText>

        <InternalLink
          css={css`
            margin-top: 1rem;
          `}
          to={`/org/${orgInfo.id}${ROUTE_PATHS['new-system']}`}
        >
          {translate('systems_enrolled_section.empty.enrol_a_new_system')}
        </InternalLink>
      </DashboardEmptySystemsContent>
    </DashboardEmptySystemsContainer>
  );
};
