// libs
import { useCallback, useEffect, useState } from 'react';
import { css, useTheme } from 'styled-components';

// components
import { CustomButton, CustomButtonLink, Input, Loader } from 'components';
import { ModalFooter, ModalHeader, ModalScrollablePart } from 'components/Modal';
import { ListElement } from './ListElement';
import { CustomText } from 'typography/Text';
import { IconDnsAddZoneModal } from 'assets/svg';
import { InfoList, SpecialCharacter } from './styled';
import { Trans } from 'react-i18next';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { useDispatch, useSelector } from 'react-redux';
import { useSubmitOnEnter } from 'hooks/useSubmitOnEnter';

// actions
import { clearErrors, clearFormError, setModalContent } from 'redux/actions/app';
import { createDnsZone } from 'redux/actions/dns';

// selectors
import { selectFormError, selectModalIsLoading } from 'redux/selectors';

// constants
import { DNS_CREATE_ZONE_ERROR_FIELD, REGEX_VALID_ZONE_REGEX } from 'appConstants';

export const CreateZoneModal = () => {
  const dispatch = useDispatch();

  const { translate } = useTranslations('dns');

  const {
    palette: { fonts },
  } = useTheme();

  const [inputText, setInputText] = useState('');

  const apiError = useSelector(selectFormError(DNS_CREATE_ZONE_ERROR_FIELD));
  const isLoading = useSelector(selectModalIsLoading);

  const isValid = REGEX_VALID_ZONE_REGEX.test(inputText);
  const haveCorrectLength = inputText.replaceAll('.', '').length > 1;
  const enabled = !apiError && isValid;

  const error = apiError
    ? { message: apiError }
    : inputText !== '' && !isValid
    ? { message: translate('zones.create_new_zone_modal.input_zone_invalid_error') }
    : null;
  const isButtonEnabled = enabled && !isLoading;

  useEffect(() => {
    apiError && dispatch(clearFormError(DNS_CREATE_ZONE_ERROR_FIELD));
    return () => {
      apiError && dispatch(clearFormError(DNS_CREATE_ZONE_ERROR_FIELD));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, inputText]);

  useEffect(() => {
    return () => {
      dispatch(clearErrors());
    };
  }, [dispatch]);

  const onClose = useCallback(() => {
    apiError && dispatch(clearErrors());
    dispatch(setModalContent({ type: null, data: null }));
  }, [dispatch, apiError]);

  const onCreate = useCallback(() => {
    apiError && dispatch(clearErrors());
    dispatch(createDnsZone({ name: inputText, autoDnsTags: [] }));
  }, [dispatch, apiError, inputText]);

  useSubmitOnEnter({ callback: isButtonEnabled ? onCreate : () => {} });

  return (
    <div data-test="dns-create-new-zone-modal">
      <ModalHeader
        title={translate('zones.create_new_zone_modal.title')}
        icon={<IconDnsAddZoneModal width="2.5rem" height="2.5rem" />}
        withCloseButton={true}
        onClose={onClose}
      />
      <ModalScrollablePart>
        <CustomText
          type="body-1"
          color={fonts.bodyDark}
          css={css`
            display: block;
            margin-bottom: 1rem;
          `}
        >
          {translate('zones.create_new_zone_modal.description')}
        </CustomText>

        <Input
          data-test="dns-create-new-zone-modal-input-wrapper"
          type="text"
          value={inputText}
          label={translate('zones.create_new_zone_modal.input_label')}
          placeholder={translate('zones.create_new_zone_modal.input_placeholder')}
          maxLength={128}
          containerCss={css`
            margin-bottom: 0.75rem;
          `}
          showStateIcon={haveCorrectLength || Boolean(error)}
          error={error}
          onChange={e => setInputText(e.target.value)}
        />

        <InfoList>
          <CustomText
            type="label-2"
            color={fonts.bodyDark}
            css={css`
              margin-left: -0.5rem;
            `}
          >
            {translate('zones.create_new_zone_modal.info_label')}
          </CustomText>
          <ListElement>
            <Trans
              i18nKey={translate('zones.create_new_zone_modal.info_1')}
              components={[<SpecialCharacter key={0} />]}
            />
          </ListElement>
          <ListElement>{translate('zones.create_new_zone_modal.info_2')}</ListElement>
          <ListElement>{translate('zones.create_new_zone_modal.info_3')}</ListElement>
          <ListElement>
            <Trans
              i18nKey={translate('zones.create_new_zone_modal.info_4')}
              components={[<SpecialCharacter key={0} />]}
            />
          </ListElement>
        </InfoList>
      </ModalScrollablePart>
      <ModalFooter>
        <CustomButtonLink size="large" onClick={onClose}>
          {translate('zones.create_new_zone_modal.cancel')}
        </CustomButtonLink>
        <CustomButton
          data-test="dns-create-new-zone-submit"
          size="large"
          width="6.75rem"
          kind={isButtonEnabled ? 'primary' : 'disabled'}
          onClick={onCreate}
        >
          {isLoading ? <Loader /> : translate('zones.create_new_zone_modal.create')}
        </CustomButton>
      </ModalFooter>
    </div>
  );
};
