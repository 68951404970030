// libs
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { css } from 'styled-components';

// components
import { Input } from 'components';

// hooks
import { useSelector } from 'react-redux';

// selectors
import { selectAppDetailsTabValidationIsUpdated } from 'redux/selectors';

// constants
import { ENROLMENT_KEYS_RANGE_VALIDATION_STATE_PREFIX } from 'appConstants/validation';
import { REGEX_HYPHEN_WITH_SPACES } from 'appConstants';

// types
import { TFunction } from 'react-i18next/.';
import { translations } from 'types';

// utils
import { isIpValid } from 'utils';

interface IRangeField {
  id?: string;
  translate: TFunction<translations>;
  range?: string | null;
  index: number;
  rangeChange: (newRange: string, index: number, isValid: boolean, id?: string) => void;
}

export const RangeField = ({ id, range, rangeChange, index, translate }: IRangeField) => {
  const [value, setValue] = useState(range);

  const [isValid, setisValid] = useState(true);
  const [isFocused, setIsFocused] = useState(false);
  const [isChanged, setIsChanged] = useState(false);

  const ref = useRef<HTMLInputElement | null>(null);

  const validationStateIsUpdated = useSelector(
    selectAppDetailsTabValidationIsUpdated(ENROLMENT_KEYS_RANGE_VALIDATION_STATE_PREFIX + id)
  );

  useEffect(() => {
    if (!value) {
      ref?.current?.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (validationStateIsUpdated) {
      validate(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validationStateIsUpdated]);

  const validate = (newValue: string | null | undefined) => {
    if (newValue !== null && newValue !== undefined && isIpValid(newValue)) {
      setisValid(true);
      return true;
    } else {
      setisValid(false);
      return false;
    }
  };

  const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;

    !isChanged && setIsChanged(true);
    validate(newValue);
    setValue(newValue);
  };

  const onFocusHandler = () => {
    setIsFocused(true);
  };

  const onBlurHandler = () => {
    const newIsValid = validate(value);

    setIsFocused(false);
    isChanged && setIsChanged(false);

    if (range !== value && value !== undefined && value !== null) {
      let newValue: string;

      if (newIsValid && value) {
        newValue = value.replace(REGEX_HYPHEN_WITH_SPACES, ' - ');
        setValue(newValue);
      } else {
        newValue = value;
      }

      rangeChange(newValue, index, newIsValid, id);
    }
  };

  const isValidationActive = (isFocused && isValid && isChanged) || (!isValid && !isFocused);

  return (
    <Input
      onFocus={onFocusHandler}
      onBlur={onBlurHandler}
      ref={ref}
      placeholder={translate('details.ip_address_range_placeholder')}
      value={value ?? undefined}
      onChange={onChangeHandler}
      sharpborders
      showStateIcon={isValidationActive}
      error={!isValid ? { message: ' ' } : null}
      stateMessageCss={css`
        display: none;
      `}
    />
  );
};
