// libs
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { css, useTheme } from 'styled-components';

// components
import { ReactComponent as IconModalLock } from 'assets/svg/modal-lock-icon.svg';
import { CustomButton, CustomMessage, Input, Loader, CustomButtonLink } from 'components';
import { ModalFooter, ModalHeader, ModalScrollablePart } from 'components/Modal';
import { CustomText } from 'typography/Text';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { useSubmitOnEnter } from 'hooks/useSubmitOnEnter';
import { useDispatch, useSelector } from 'react-redux';

// actions
import { clearErrors, clearFormError, setModalContent } from 'redux/actions/app';
import { createStepUpAndOpenModal, createStepUpToken } from 'redux/actions/account';

// selectors
import {
  selectModalData,
  selectModalNextModal,
  selectModalIsLoading,
  selectFormError,
} from 'redux/selectors';

// types
import { IAccountAccessToken } from 'types';

// constants
import {
  ACCOUNT_RE_AUTH_PASSWORD_ERROR_FIELD,
  ACCOUNT_RE_AUTH_PASSWORD_WARNING_ERROR_FIELD,
} from 'appConstants';

export const ReAuthModal = () => {
  const dispatch = useDispatch();

  const { translate } = useTranslations('account');
  const {
    palette: {
      fonts: { bodyDark },
    },
  } = useTheme();

  const [password, setPassword] = useState('');

  const modalData = useSelector(selectModalData) as IAccountAccessToken | null;
  const nextModal = useSelector(selectModalNextModal) as string;
  const warningError = useSelector(selectFormError(ACCOUNT_RE_AUTH_PASSWORD_WARNING_ERROR_FIELD));
  const passwordError = useSelector(selectFormError(ACCOUNT_RE_AUTH_PASSWORD_ERROR_FIELD));
  const isLoading = useSelector(selectModalIsLoading);

  const isButtonEnabled = !isLoading;

  const ref = useRef<HTMLInputElement>(null);

  const onClose = () => {
    dispatch(clearErrors());

    dispatch(setModalContent({ type: null, data: null }));
  };

  const onChangePassword = (e: ChangeEvent<HTMLInputElement>) => {
    if (passwordError) dispatch(clearFormError(ACCOUNT_RE_AUTH_PASSWORD_ERROR_FIELD));
    setPassword(e.target.value);
  };

  const onCreateStepUpToken = useCallback(() => {
    dispatch(clearErrors());

    if (nextModal) {
      dispatch(createStepUpAndOpenModal({ password, modalData, nextModal }));
      return;
    }
    dispatch(createStepUpToken({ password }));
  }, [dispatch, modalData, password, nextModal]);

  useEffect(() => {
    ref?.current?.focus();
  }, [ref]);

  useSubmitOnEnter({ callback: isButtonEnabled ? onCreateStepUpToken : () => {} });

  return (
    <>
      <ModalHeader
        title={translate('re_auth_modal.title')}
        icon={<IconModalLock width="2.5rem" height="2.5rem" />}
        withCloseButton={true}
        onClose={onClose}
      />
      <ModalScrollablePart>
        {warningError && (
          <CustomMessage
            type="error"
            message={warningError}
            withIcon
            css={css`
              margin-bottom: 1rem;
              width: auto;
            `}
          />
        )}
        <CustomText
          type="body-1"
          color={bodyDark}
          css={css`
            display: block;
            margin-bottom: 2rem;
          `}
        >
          {translate('re_auth_modal.desc')}
        </CustomText>
        <Input
          ref={ref}
          type="password"
          name="password"
          value={password}
          placeholder={translate('curr_pass_placeholder')}
          onChange={onChangePassword}
          label={translate('curr_pass')}
          error={{ message: passwordError || '' }}
          containerCss={css`
            margin-bottom: 0.5rem;
          `}
        />
      </ModalScrollablePart>

      <ModalFooter>
        <CustomButtonLink size="large" onClick={onClose}>
          {translate('cancel')}
        </CustomButtonLink>
        <CustomButton
          size="large"
          width="10.625rem"
          kind={isButtonEnabled ? 'primary' : 'disabled'}
          onClick={onCreateStepUpToken}
        >
          {isLoading ? <Loader /> : translate('verify_pass')}
        </CustomButton>
      </ModalFooter>
    </>
  );
};
