import { Details } from 'components';
import { TitleBlock } from '../../Blocks';

export const Header = () => {
  return (
    <Details.HeaderSection data-test="trust-requirement-details-header">
      <TitleBlock />
    </Details.HeaderSection>
  );
};
