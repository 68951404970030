import { ITag } from 'types';

const checkCharRe = /[a-z0-9-\.]/;
const checkTagRe = /^([a-z0-9]+[-\.])*[a-z0-9]+$/;
export const isCharValid = (filter: string) => filter === '' || checkCharRe.test(filter);
export const isTagValid = (filter: string) => filter === '' || checkTagRe.test(filter);
export const initTag = (tag: string): ITag => ({
  tag,
  ref: '0',
  colour: null,
  lastReferenced: '-',
  systems: 0,
  keys: 0,
  policies: 0,
  dnsRecords: 0,
  dnsZones: 0,
});
