// libs
import { css } from 'styled-components';

// components
import { Details } from 'components';
import { ColourView } from './View';
import { ColourEdit } from './Edit';

// hooks
import { useSelector } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';

// selectors
import { selectAppDetailsTabType } from 'redux/selectors';

export const ColourBlock = () => {
  const { translate } = useTranslations('tags');

  const type = useSelector(selectAppDetailsTabType);

  return (
    <Details.KeyValue
      nameCss={
        type === 'tag-edit' || type === 'tag-create'
          ? css`
              margin-top: 0.3125rem;
            `
          : css``
      }
      css={
        type === 'tag-view'
          ? css`
              margin-top: 0.3125rem;
              min-height: 3rem;
            `
          : css`
              min-height: 3rem;
            `
      }
      title={translate('details.colour')}
    >
      {type === 'tag-view' && <ColourView />}
      {(type === 'tag-edit' || type === 'tag-create') && <ColourEdit />}
    </Details.KeyValue>
  );
};
