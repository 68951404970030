import styled from 'styled-components';

export const SystemIconContainer = styled.div`
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  border: 0.0625rem solid ${({ theme }) => theme.palette.background.iconBackground};
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  box-sizing: border-box;
`;
