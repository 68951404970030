// Lib
import { createReducer } from '@reduxjs/toolkit';
import { IEnrolmentKeysStore, IEnrolmentKey, IGetEnrolmentKeysResponse } from 'types';
import { randomHash } from 'utils/hash';
import {
  setEnrolmentKeys,
  updateEnrolmentKeyState,
  setIsLoading,
  setEnrolmentKeysShouldUpdate,
  updateEnrolmentKeysState,
  setSelectedEnrolmentKey,
  setEnrolmentKeySearchKeys,
} from '../actions/enrolment-keys';

export const initialEnrolmentKeysResponse: IGetEnrolmentKeysResponse = {
  items: [],
  metadata: {
    total: 0,
    firstPage: 0,
    prevPage: null,
    lastPage: 0,
    nextPage: null,
  },
  links: {
    first: '',
    prev: null,
    next: null,
    last: '',
  },
};

const initialState: IEnrolmentKeysStore = {
  ...initialEnrolmentKeysResponse,
  updateId: randomHash(),
  isLoading: false,
  selectedKey: null,
  searchKeys: null,
};

export const enrolmentKeysReducer = createReducer(initialState, builder => {
  builder
    .addCase(setEnrolmentKeys, (state, { payload }) => {
      return {
        ...state,
        ...payload,
        isLoading: false,
      };
    })
    .addCase(updateEnrolmentKeyState, (state, { payload }) => {
      state.items = state.items.map((key: IEnrolmentKey) =>
        payload.id === key.id ? payload : key
      );
    })
    .addCase(setIsLoading, (state, { payload: isLoading }) => {
      state.isLoading = isLoading;
    })
    .addCase(setEnrolmentKeysShouldUpdate, state => {
      state.updateId = randomHash();
    })
    .addCase(updateEnrolmentKeysState, (state, { payload: { keyIds, enabled } }) => {
      state.items = state.items.map(item => {
        if (keyIds.includes(item.id)) item.isEnabled = enabled;
        return item;
      });
    })
    .addCase(setSelectedEnrolmentKey, (state, { payload }) => {
      state.selectedKey = payload;
    })
    .addCase(setEnrolmentKeySearchKeys, (state, { payload }) => {
      state.searchKeys = payload;
    });
});
