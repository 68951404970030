// components
import { CustomText } from 'typography/Text';
import {
  StyledGroup,
  StyledButton,
  StyledOptionWrapper,
  StyledControllableIcon,
  StyledOptionDescription,
  StyledIconQuestionCircle,
  tooltipCss,
} from './styled';
import { Tooltip } from 'components';
import { IconGateway, IconPolicy } from 'assets/svg';

// hooks
import { css, useTheme } from 'styled-components';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';

// actions
import { updatePolicyDetails } from 'redux/actions/app';

// selectors
import { selectAppDetailCurrentPolicyType } from 'redux/selectors';

// types
import { RadioChangeEvent } from 'antd/lib/radio';

export const PolicyTypeCreate = () => {
  const dispatch = useDispatch();

  const { translate } = useTranslations('policies');

  const {
    palette: {
      fonts: { bodyLight },
      icons: { primary, white },
    },
  } = useTheme();

  const policyType = useSelector(selectAppDetailCurrentPolicyType);

  const handleTypeChange = useCallback(
    (event: RadioChangeEvent) => {
      dispatch(
        updatePolicyDetails({
          type: event.target.value,
        })
      );
    },
    [dispatch]
  );

  return (
    <StyledGroup defaultValue={policyType} onChange={handleTypeChange}>
      <StyledButton value="General">
        <StyledOptionWrapper data-test="policy-create-details-select-general-type">
          <StyledControllableIcon
            state={policyType === 'General' ? 'active' : 'default'}
            size="medium"
            icon={<IconPolicy width="1rem" height="1rem" />}
            data-test={policyType === 'General' ? 'selected' : 'unselected'}
          />
          <StyledOptionDescription>
            <CustomText type="sub-heading-2">
              {translate('details.type_switch.general_type')}
            </CustomText>
            <CustomText type="hint" color={bodyLight}>
              {translate('details.type_switch.general_type_hint')}
            </CustomText>
          </StyledOptionDescription>
          <Tooltip
            width="12.5rem"
            placement="bottomRight"
            trigger="hover"
            passedCss={tooltipCss}
            overlay={
              <CustomText type="hint">
                {translate('details.type_switch.general_type_tooltip')}
              </CustomText>
            }
          >
            <StyledIconQuestionCircle />
          </Tooltip>
        </StyledOptionWrapper>
      </StyledButton>
      <StyledButton value="Gateway">
        <StyledOptionWrapper data-test="policy-create-details-select-gateway-type">
          <StyledControllableIcon
            stateCss={{
              active: css`
                path:first-child,
                path:last-child {
                  fill: ${white};
                }
                path:nth-child(even) {
                  stroke: ${primary};
                }
                path:nth-child(2n + 3):not(:last-child),
                ellipse {
                  fill: ${primary};
                }
              `,
              // to prevent default css
              default: css``,
            }}
            state={policyType === 'Gateway' ? 'active' : 'default'}
            size="medium"
            icon={<IconGateway width="1rem" height="1rem" />}
            data-test={policyType === 'Gateway' ? 'selected' : 'unselected'}
          />
          <StyledOptionDescription>
            <CustomText type="sub-heading-2">
              {translate('details.type_switch.gateway_type')}
            </CustomText>
            <CustomText type="hint" color={bodyLight}>
              {translate('details.type_switch.gateway_type_hint')}
            </CustomText>
          </StyledOptionDescription>

          <Tooltip
            width="12.5rem"
            placement="bottomRight"
            trigger="hover"
            passedCss={tooltipCss}
            overlay={
              <CustomText type="hint">
                {translate('details.type_switch.gateway_type_tooltip')}
              </CustomText>
            }
          >
            <StyledIconQuestionCircle />
          </Tooltip>
        </StyledOptionWrapper>
      </StyledButton>
    </StyledGroup>
  );
};
