// components
import { Details } from 'components';

import {
  PolicyMembers,
  ReceiverAccessControl,
  Header,
  Overview,
  CancelSave,
  TopMenuButtons,
  TrustRequirements,
  PolicyType,
  ActiveHours,
  AutoExpire,
} from './Sections';

export const PolicyDetails = () => {
  return (
    <>
      <Details.Pane>
        <Details.ContentWrapper data-test="policy-details-content">
          <Details.ScrollPaddingContainer wide={true}>
            <TopMenuButtons />
            <Header />
            <PolicyType />
            <Overview />
            <PolicyMembers />
            <TrustRequirements />
            <ReceiverAccessControl />
            <ActiveHours />
            <AutoExpire />
          </Details.ScrollPaddingContainer>
        </Details.ContentWrapper>
        <CancelSave />
      </Details.Pane>
    </>
  );
};
