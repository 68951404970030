import {
  updateEnrolmentKeyDetails,
  setEnrolmentKeyDetailsContent,
  createEnrolmentKeyDetailsAutoExpire,
  updateEnrolmentKeyDetailsAutoExpire,
} from '../../../actions/app';

import { IEnrolmentKeyIpConstraints } from 'types';

import { randomHash } from 'utils/hash';
import { appReducerBuilder } from '..';
import { defaultTimeZone, DEFAULT_AUTO_EXPIRE_DATA } from 'appConstants';
import dayjs from 'dayjs';
import { getTimeZoneOffset } from 'utils';

export const detailsTabEnrolmentKeysReducerSlice: appReducerBuilder = builder => {
  builder
    .addCase(updateEnrolmentKeyDetails, (state, { payload }) => {
      if (
        state.detailsTab.type === 'enrolment-key-edit' ||
        state.detailsTab.type === 'enrolment-key-create'
      ) {
        state.detailsTab.dataNew = {
          ...state.detailsTab.dataNew,
          ...payload,
        };
      }
    })
    .addCase(setEnrolmentKeyDetailsContent, (state, { payload }) => {
      let modifiedIpConstaints: IEnrolmentKeyIpConstraints = [];

      if (payload.type === 'enrolment-key-view' || payload.type === 'enrolment-key-edit') {
        modifiedIpConstaints = payload.data.ipConstraints
          ? payload.data.ipConstraints.map(el => {
              return {
                id: randomHash(),
                range: el.range,
                description: el.description,
              };
            })
          : [];
      }

      if (payload.type === 'enrolment-key-view') {
        payload.data.ipConstraints = modifiedIpConstaints;
      }
      if (payload.type === 'enrolment-key-edit') {
        payload.data.ipConstraints = modifiedIpConstaints;
        payload.dataNew.ipConstraints = modifiedIpConstaints;
      }

      state.detailsTab = {
        ...payload,
      };
    })
    .addCase(createEnrolmentKeyDetailsAutoExpire, state => {
      if (
        (state.detailsTab.type === 'enrolment-key-edit' ||
          state.detailsTab.type === 'enrolment-key-create') &&
        !state.detailsTab.dataNew.autoExpire
      ) {
        state.detailsTab.dataNew.autoExpire = {
          ...DEFAULT_AUTO_EXPIRE_DATA,
        };
      }
    })
    .addCase(updateEnrolmentKeyDetailsAutoExpire, (state, { payload }) => {
      if (
        state.detailsTab.type === 'enrolment-key-edit' ||
        state.detailsTab.type === 'enrolment-key-create'
      ) {
        if (payload === null) {
          state.detailsTab.dataNew.autoExpire = null;
        } else {
          let assignLocalTimezone = false;
          let reformatDateTime = false;
          const dateTime =
            payload.expiryDateTime || state.detailsTab.dataNew.autoExpire?.expiryDateTime;

          if (!state.detailsTab.dataNew.autoExpire?.timeZoneId && !payload.timeZoneId) {
            assignLocalTimezone = true;
          }

          if (!state.detailsTab.dataNew.autoExpire?.timeZoneId) {
            if (dateTime && dateTime?.length > 19) {
              reformatDateTime = true;
            }
          }

          //@ts-ignore
          state.detailsTab.dataNew.autoExpire = {
            ...state.detailsTab.dataNew.autoExpire,
            ...payload,
            ...(assignLocalTimezone
              ? {
                  timeZoneId: defaultTimeZone.zone,
                }
              : {}),
            ...(reformatDateTime
              ? {
                  expiryDateTime: dayjs
                    .utc(dateTime)
                    .utcOffset(
                      getTimeZoneOffset(
                        payload.timeZoneId ||
                          state.detailsTab.dataNew.autoExpire?.timeZoneId ||
                          defaultTimeZone.zone
                      )
                    )
                    .format('YYYY-MM-DDTHH:mm:ss'),
                }
              : {}),
          };
        }
      }
    });
};
