import { ENC_HOSTS } from './endpoints';

export const OIDCConstants = {
  authority: ENC_HOSTS.OIDC,
  clientId: process.env.ENC_OIDC_CLIENT_ID,
  clientScope:
    'openid profile email write:user read:systems write:systems delete:systems read:enrolment-keys write:enrolment-keys read:logs read:policies write:policies write:billing read:dns write:dns read:org:list read:trust write:trust write:tags',
  oidcRedirect: 'oidc-redirect',
  oidcSilentRedirect: 'oidc-silent-redirect',
  oidcLogoutRedirect: 'oidc-logout-redirect',
};
