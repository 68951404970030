import styled, { useTheme, css } from 'styled-components';
import { CustomText, ICustomText } from 'typography/Text';

export const InstructionTitle = styled.div`
  margin-bottom: 2rem;
`;
export const InstructionDetails = styled.div`
  padding: 0 0 2.5rem 0;
`;

type StepProps = {
  children: React.ReactNode;
};

export const StepTitle = ({ ...rest }: Omit<ICustomText, 'type'>) => {
  const {
    palette: { fonts },
  } = useTheme();
  return <CustomText color={fonts.title} {...rest} type="body-1"></CustomText>;
};

export const StepSubTitle = ({ children }: StepProps) => {
  const {
    palette: { fonts },
  } = useTheme();
  return (
    <CustomText
      type="body-2"
      color={fonts.bodyLight}
      css={css`
        margin-left: 0.5rem;
      `}
    >
      {children}
    </CustomText>
  );
};
