import { useTheme } from 'styled-components';
import { CustomText } from 'typography/Text';

export const FieldText = ({ children, ...rest }: { children: React.ReactNode }) => {
  const {
    palette: { fonts },
  } = useTheme();

  return (
    <CustomText type="body-2" color={fonts.bodyDark} {...rest}>
      {children}
    </CustomText>
  );
};
