// Lib
import { takeEvery, takeLatest, takeLeading, all } from 'redux-saga/effects';
import {
  checkUserAuth,
  doLogin,
  doLogout,
  handleSilentTokenRenew,
  handleNormalAuthRedirect,
  reLogin,
} from 'redux/actions/user';
import { apiService } from '../../../../api/ApiService';

import { normalAuthRedirectSaga } from './normalAuthRedirectSaga';
import { checkUserAuthSaga } from './checkUserAuthSaga';
import { reLoginSaga } from './reLoginSaga';
import { validateUserAuthSaga } from './validateUserAuthSaga';

function* authSaga() {
  yield all([
    takeEvery(doLogin, () => validateUserAuthSaga()),
    takeEvery(reLogin, reLoginSaga),
    takeEvery(doLogout, () => apiService.logoutUser()),
    takeEvery(checkUserAuth, checkUserAuthSaga),
    takeLatest(handleSilentTokenRenew, () => apiService.handleSilentTokenRenew()),
    takeLeading(handleNormalAuthRedirect, normalAuthRedirectSaga),
  ]);
}

export default authSaga;
