import { createAction } from '@reduxjs/toolkit';
import {
  IDeleteTrustRequirementProps,
  IDeleteTrustRequirementsParams,
  IGetTrustRequirementsParams,
  IGetTrustRequirementsResponse,
  ISearchKey,
} from 'types';

export const getTrustRequirements = createAction<IGetTrustRequirementsParams>(
  'trust_requirements/GET_TRUST_REQUIREMENTS'
);
export const setTrustRequirements = createAction<IGetTrustRequirementsResponse>(
  'trust_requirements/SET_TRUST_REQUIREMENTS'
);
export const deleteTrustRequirements = createAction<IDeleteTrustRequirementsParams>(
  'trust_requirements/DELETE_TRUST_REQUIREMENTS'
);

export const createTrustRequirement = createAction('trust_requirements/CREATE_TRUST_REQUIREMENT');
export const deleteTrustRequirement = createAction<IDeleteTrustRequirementProps>(
  'trust_requirements/DELETE_TRUST_REQUIREMENT'
);

export const setTrustRequirementsShouldUpdate = createAction('trust_requirements/SHOULD_UPDATE');

export const setIsLoading = createAction<boolean>('trust_requirements/SET_IS_LOADING');

export const saveUpdatedTrustRequirement = createAction(
  'trust_requirements/SAVE_UPDATED_TRUST_REQUIREMENT'
);

export const getTrustRequirementSearchKeys = createAction('trust_requirements/GET_SEARCH_KEYS');

export const setTrustRequirementSearchKeys = createAction<ISearchKey[]>(
  'trust_requirements/SET_SEARCH_KEYS'
);
