import { IconSystemsDetailsPreferred } from 'assets/svg';
import { useTranslations } from 'hooks/useTranslations';
import styled, { useTheme } from 'styled-components';
import { ISystemGatewayRouteModified } from 'types';
import { CustomText } from 'typography/Text';

interface IPriority {
  route: ISystemGatewayRouteModified;
}

const TextContainer = styled.div`
  text-wrap: nowrap;

  svg {
    position: relative;
    top: 0.2rem;
    height: 1.2rem;
  }
`;

export const Priority = ({ route, ...rest }: IPriority) => {
  const { translate } = useTranslations('systems');

  const {
    palette: { fonts },
  } = useTheme();

  return (
    <TextContainer>
      <CustomText type="body-2" color={fonts.bodyLight} {...rest}>
        {`${
          route.weight === 0
            ? translate('details.gateway.table_priority_normal')
            : translate('details.gateway.table_priority_preferred')
        }`}
      </CustomText>
      {route.weight > 0 && <IconSystemsDetailsPreferred />}
    </TextContainer>
  );
};
