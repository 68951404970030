// Lib
import { put, select } from 'typed-redux-saga';

// actions
import { checkUserAuth, clearUser, doLogin } from 'redux/actions/user';

// selectors
import { selectAppIsLoaded, selectPathName } from 'redux/selectors';

// constants
import { OIDCConstants } from 'appConstants';

export function* reLoginSaga() {
  const appIsLoaded = yield* select(selectAppIsLoaded);
  if (appIsLoaded) return;

  const pathname = yield* select(selectPathName);

  if (`/${OIDCConstants.oidcLogoutRedirect}` === pathname) {
    yield put(clearUser());
    yield put(doLogin());
  }

  if (
    ![
      `/${OIDCConstants.oidcLogoutRedirect}`,
      `/${OIDCConstants.oidcRedirect}`,
      `/${OIDCConstants.oidcSilentRedirect}`,
    ].includes(pathname)
  ) {
    yield put(checkUserAuth());
  }
}
