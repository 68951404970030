// libs
import { SyntheticEvent, useState, useEffect, useCallback } from 'react';
import { css } from 'styled-components';

// comppnents
import { StyledRefreshIcon, CustomButton } from './styled';

// hooks
import { useTranslations } from 'hooks/useTranslations';

interface PaginationBlockProps {
  onRefresh: (event?: SyntheticEvent) => void;
  isLoading?: boolean;
}

export const RefreshButton = ({ onRefresh, isLoading = false, ...rest }: PaginationBlockProps) => {
  const { translate } = useTranslations('global');
  const [animation, setAnimation] = useState(isLoading);

  // start animation on loading
  useEffect(() => {
    if (isLoading) {
      setAnimation(true);
    }
  }, [isLoading]);

  // end animation on iteration end if loading ends
  const handleAnimationIteration = useCallback(() => {
    if (!isLoading) {
      setAnimation(false);
    }
  }, [isLoading]);

  return (
    <CustomButton
      aria-label={translate('aria_labels.refresh')}
      css={css`
        width: 2.5rem;
        padding: 0;
        margin-left: 0.5rem;
      `}
      {...rest}
      size="large"
      onClick={onRefresh}
    >
      <StyledRefreshIcon $spin={animation} onAnimationIteration={handleAnimationIteration} />
    </CustomButton>
  );
};
