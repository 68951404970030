// Lib
import { call, put } from 'typed-redux-saga';

// actions
import { setModalContent, setModalIsLoading, pushNotification } from 'redux/actions/app';
import { set2FAStatus, setSessionLogoutUrl } from 'redux/actions/account';

// types
import { INotificationType } from 'types';

// Utils
import { ApiTypeCaller } from '../../api/utils';
import { translator } from 'utils';

// sagas
import { validateStepUpTokenSaga } from './validateStepUpTokenSaga';

export function* enable2FASaga() {
  yield put(setModalIsLoading(true));

  const translate = translator('notifications');

  const token = yield* call(validateStepUpTokenSaga);

  if (token) {
    const { success, result } = yield* ApiTypeCaller('enableAccount2FA', {
      stepUpToken: token,
    });

    if (success) {
      yield put(
        pushNotification({
          showType: INotificationType.Success,
          description: translate('2FA_enabled'),
          isLocked: true,
        })
      );
      yield put(set2FAStatus({ twoFactorEnabled: true }));
      yield put(setModalContent({ type: null, data: null }));

      if (result?.sessionLogoutUrl) {
        yield put(setSessionLogoutUrl(result.sessionLogoutUrl));
      }
    }
  }
  yield put(setModalIsLoading(false));
}
