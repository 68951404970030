// components
import { Details } from 'components';
import { ApprovalModeView } from './View';
import { ApprovalModeEdit } from './Edit';

// hooks
import { useSelector } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';

// selectors
import { selectAppDetailsEnrolmentKeyCurrentType, selectAppDetailsTabType } from 'redux/selectors';
import { css } from 'styled-components';

export const ApprovalModeBlock = () => {
  const { translate } = useTranslations('enrolment-keys');

  const type = useSelector(selectAppDetailsTabType);
  const purpose = useSelector(selectAppDetailsEnrolmentKeyCurrentType);

  if (purpose === 'Ephemeral') return null;

  return (
    <Details.KeyValue
      height="2.875rem"
      title={translate('details.approval_mode')}
      contentCss={css`
        align-items: center;
      `}
    >
      {type === 'enrolment-key-view' && <ApprovalModeView />}
      {(type === 'enrolment-key-edit' || type === 'enrolment-key-create') && <ApprovalModeEdit />}
    </Details.KeyValue>
  );
};
