// components
import { PageTitle } from 'components';
import { TitleEdit } from './Edit';
import { TitleView } from './View';

// hooks
import { useSelector } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';

// selectors
import { selectAppDetailsTabType, selectAppDetailsTagDescription } from 'redux/selectors';

export const TitleBlock = () => {
  const { translate } = useTranslations('tags');

  const type = useSelector(selectAppDetailsTabType);
  const description = useSelector(selectAppDetailsTagDescription);

  return (
    <>
      <PageTitle title={`${translate('tags')}${description ? ` | ${description}` : ''}`} />
      {type === 'tag-view' && <TitleView />}
      {(type === 'tag-edit' || type === 'tag-create') && <TitleEdit />}
    </>
  );
};
