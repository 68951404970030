// components
import { TagsList } from 'components';

// selectors
import { selectAppDetailsSystemTags } from 'redux/selectors';

// hooks
import { useSelector } from 'react-redux';

export const TagsView = () => {
  const systemTags = useSelector(selectAppDetailsSystemTags) || [];

  return <TagsList tags={systemTags} blocksMode={true} />;
};
