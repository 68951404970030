// libs
import styled, { css } from 'styled-components';

// components
import { IconNoFoundDashboardSystems } from 'assets/svg';
import { InternalLink } from 'components';
import { CustomText } from 'typography/Text';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { useSelector } from 'react-redux';

// selectors
import { selectOrgId } from 'redux/selectors';

const EmptySystemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 19.4375rem;
  background-color: ${props => props.theme.palette.background.panel};
`;

const TextContainer = styled.div`
  text-align: center;
  width: 18.4375rem;
`;

export type emptyDropdownType = 'no-keys' | 'no-matching-keys';
interface IEmptyDropdown {
  type: emptyDropdownType;
}
export const EmptyDropdown = ({ type }: IEmptyDropdown) => {
  const { translate } = useTranslations('enrol-new-system');
  const orgId = useSelector(selectOrgId);

  return (
    <EmptySystemsContainer>
      <IconNoFoundDashboardSystems />
      <TextContainer>
        {type === 'no-keys' ? (
          <>
            <CustomText
              type="body-2"
              css={css`
                display: inline;
              `}
            >
              {' '}
              {translate('empty_dropdown_part_1')}
            </CustomText>

            <InternalLink
              title={translate('empty_dropdown_part_2_link')}
              to={`/org/${orgId}/keys`}
              css={css`
                display: inline !important;
              `}
            >
              {translate('empty_dropdown_part_2_link')}{' '}
            </InternalLink>
            <CustomText
              css={css`
                display: inline;
              `}
              type="body-2"
            >
              {translate('empty_dropdown_part_3')}
            </CustomText>
          </>
        ) : (
          <CustomText
            type="body-2"
            css={css`
              display: inline;
            `}
          >
            {translate('empty_dropdown_no_matching_keys')}
          </CustomText>
        )}
      </TextContainer>
    </EmptySystemsContainer>
  );
};
