import { translator } from 'utils/nonComponentTranslations';
const translate = translator('global');

export const SYSTEMS_SORT_OPTIONS = [
  { name: translate('sort.recently_enrolled'), value: 'RecentlyEnrolled' as const },
  { name: translate('sort.recently_connected'), value: 'RecentlyConnected' as const },
  { name: translate('sort.description'), value: 'DescriptionOrHostname' as const },
  { name: translate('sort.enrolment_key_used'), value: 'EnrolmentKeyUsed' as const },
];

export const USYSTEMS_SORT_OPTIONS = [
  { name: translate('sort.recently_enrolled'), value: 'RecentlyEnrolled' as const },
  { name: translate('sort.description'), value: 'Description' as const },
  { name: translate('sort.enrolment_key_used'), value: 'EnrolmentKeyUsed' as const },
];

export const POLICIES_SORT_OPTIONS = [
  { name: translate('sort.description'), value: 'Description' as const },
  { name: translate('sort.recently_created'), value: 'RecentlyCreated' as const },
];

export const KEYS_SORT_OPTIONS = [
  { name: translate('sort.last_used'), value: 'LastUsed' as const },
  { name: translate('sort.description'), value: 'Description' as const },
  { name: translate('sort.approval_mode'), value: 'ApprovalMode' as const },
  { name: translate('sort.uses_remaining'), value: 'UsesRemaining' as const },
];

export const TAGS_SORT_OPTIONS = [
  { name: translate('sort.recently_used'), value: 'RecentlyUsed' as const },
  { name: translate('sort.alphabetical'), value: 'Alphabetical' as const },
  { name: translate('sort.referenced_systems'), value: 'ReferencedSystems' as const },
];

export const TRUST_REQUIREMENTS_SORT_OPTIONS = [
  { name: translate('sort.description'), value: 'Description' as const },
  { name: translate('sort.recently_created'), value: 'RecentlyCreated' as const },
];

export const USYSTEMS_MODULE = 'usystems';
export const SYSTEMS_MODULE = 'systems';
export const POLICIES_MODULE = 'policies';
export const ENROLMENT_KEYS_MODULE = 'enrolment-keys';
export const TAGS_MODULE = 'tags';
export const TRUST_REQUIREMENTS_MODULE = 'trust-requirements';
