// libs
import { put } from 'typed-redux-saga';
import { IActionPayload, IUpdateAccountPasswordParams, INotificationType } from 'types';

// actions
import { setModalContent, setModalIsLoading, pushNotification } from 'redux/actions/app';
import { setSessionLogoutUrl } from 'redux/actions/account';

// utils
import { ApiTypeCaller } from '../../api/utils';
import { translator } from 'utils';

// constants
import { ACCOUNT_CHANGE_PASSWORD_WARNING_ERROR_FIELD } from 'appConstants';

export function* updatePasswordSaga({
  payload: updateParams,
}: IActionPayload<IUpdateAccountPasswordParams>) {
  yield put(setModalIsLoading(true));

  const translate = translator('notifications');

  const { success, result } = yield* ApiTypeCaller('updateAccountPassword', updateParams, {
    customErrorField: ACCOUNT_CHANGE_PASSWORD_WARNING_ERROR_FIELD,
  });

  if (success) {
    yield put(
      pushNotification({
        showType: INotificationType.Success,
        description: translate('password_updated'),
        isLocked: true,
      })
    );

    yield put(setModalContent({ type: null, data: null }));

    if (result?.sessionLogoutUrl) {
      yield put(setSessionLogoutUrl(result.sessionLogoutUrl));
    }
  }

  yield put(setModalIsLoading(false));
}
