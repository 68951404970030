// Hooks
import { useTheme } from 'styled-components';

// Components
import { NoTagsWrapper } from './styled';
import { CustomText } from 'typography/Text';

// Hooks
import { useTranslations } from 'hooks/useTranslations';

export const NoExistingTags = () => {
  const { palette } = useTheme();
  const { translate } = useTranslations('tags');
  return (
    <NoTagsWrapper data-test="tags-select-empty-state">
      <CustomText type="label-2" color={palette.fonts.bodyLight}>
        {translate('no_found')}
      </CustomText>
      <CustomText type="hint" color={palette.fonts.bodyLight}>
        {translate('enter_to_create')}
      </CustomText>
    </NoTagsWrapper>
  );
};
