// components
import { CustomText } from 'typography/Text';

// hooks
import { useTheme } from 'styled-components';

// types
import { IPolicyModifiedAcl } from 'types';

// utils
import { aclToType, useAclTypeToText } from '../../helpers';

interface IType {
  acl: IPolicyModifiedAcl;
}

export const Type = ({ acl, ...rest }: IType) => {
  const {
    palette: { fonts },
  } = useTheme();

  const text = useAclTypeToText(aclToType({ acl }));

  return (
    <CustomText
      data-test="policy-details-acls-table-type"
      type="body-2"
      color={fonts.bodyDark}
      {...rest}
    >
      {text}
    </CustomText>
  );
};
