// components
import { ZonesListItem } from './ListItem';

import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import WindowInfiniteLoader from 'react-window-infinite-loader';
import Skeleton from 'react-loading-skeleton';
import { SkeletonContainer } from './styled';

// hooks
import { useCallback, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

// actions
import { redirectControl } from 'redux/actions/app';

// types
import { IZoneBasic } from 'types';

// constants
import { ITEMS_PER_DNS_ZONES_PAGE } from 'appConstants';

const ITEM_SIZE = 60;

interface IZonesList {
  total: number;
  list: IZoneBasic[];
  loadMoreItems: WindowInfiniteLoader['props']['loadMoreItems'];
  hasNextPage: boolean;
  selectedZoneId: number;
}
export const List = ({ total, loadMoreItems, list, hasNextPage, selectedZoneId }: IZonesList) => {
  const dispatch = useDispatch();

  const listRef = useRef<any>();
  const hasScrolled = useRef<boolean>(false);

  const isItemLoaded = useCallback(
    (index: number) => {
      return !hasNextPage || !!list?.[index];
    },
    [hasNextPage, list]
  );

  const itemRenderer = useCallback(
    ({ data: { list }, index, style }: ListChildComponentProps<{ list: IZoneBasic[] }>) => {
      let content;

      if (!list?.[index]) {
        content = (
          <SkeletonContainer height={`${ITEM_SIZE}px`}>
            <Skeleton />
          </SkeletonContainer>
        );
      } else {
        const zone = list?.[index];

        content = (
          <ZonesListItem
            height={`${ITEM_SIZE}px`}
            key={zone.id}
            name={zone?.name}
            recordCount={zone.recordCount}
            isSelected={zone.id === selectedZoneId}
            onClick={() =>
              dispatch(redirectControl({ route: `/dns/zones/${zone.id}`, routePush: true }))
            }
          />
        );
      }

      return (
        <div style={style} key={index}>
          {content}
        </div>
      );
    },
    [dispatch, selectedZoneId]
  );

  useEffect(() => {
    const index = list.findIndex(listElement => listElement.id == selectedZoneId);
    if (index !== -1 && !hasScrolled.current) {
      listRef?.current?.scrollToItem(index);
      hasScrolled.current = true;
    }
  }, [list, selectedZoneId]);

  useEffect(() => {
    if (hasScrolled.current !== false) {
      hasScrolled.current = false;
    }
  }, [selectedZoneId]);

  return (
    <WindowInfiniteLoader
      isItemLoaded={isItemLoaded}
      itemCount={total}
      loadMoreItems={loadMoreItems}
      threshold={ITEMS_PER_DNS_ZONES_PAGE}
      minimumBatchSize={ITEMS_PER_DNS_ZONES_PAGE}
    >
      {({ onItemsRendered }) => (
        <AutoSizer>
          {({ height, width }) => {
            return (
              <FixedSizeList
                itemSize={ITEM_SIZE}
                height={height}
                width={width}
                itemCount={total}
                ref={listRef}
                onItemsRendered={onItemsRendered}
                itemData={{ list }}
              >
                {itemRenderer}
              </FixedSizeList>
            );
          }}
        </AutoSizer>
      )}
    </WindowInfiniteLoader>
  );
};
