// libs
import { memo } from 'react';
import styled from 'styled-components';

// components
import Skeleton from 'react-loading-skeleton';
import { Tooltip } from 'components';
import { CustomText } from 'typography/Text';

// hooks
import { useTheme } from 'styled-components';
import { useTranslations } from 'hooks/useTranslations';

// types
import { IPolicy, components } from 'types';
import {
  aclToType,
  useAclProtocolToText,
  useAclTypeToText,
} from '../Details/Blocks/ReceiverAccessControls/helpers';

interface IAclState {
  state: IPolicy['state'];
  acls: IPolicy['acls'];
  isLoading: boolean;
}

interface IAclItem {
  state: IPolicy['state'];
  acl: components['schemas']['PolicyAclModel'];
}

const Container = styled.div`
  max-width: 10rem;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
`;

const AclWrapper = styled.div`
  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.palette.fills.strokeMedium};
  }
`;

const AclItem = memo(({ state, acl }: IAclItem) => {
  const {
    palette: { fonts },
  } = useTheme();

  const aclType = aclToType({ acl });

  const aclTypeText = useAclTypeToText(aclType);

  const aclProtocolText = useAclProtocolToText(acl.protocol);

  const formatAclProtocol = () => {
    if (aclType !== 'Custom') {
      return aclTypeText;
    }

    if (acl.ports) {
      return `${aclProtocolText}/${acl.ports}`;
    }

    return aclProtocolText;
  };

  return (
    <AclWrapper>
      <CustomText type="body-2" color={state === 'Disabled' ? fonts.disabled : fonts.bodyDark}>
        {formatAclProtocol()}
      </CustomText>
      {/* {acl.description && aclType != 'Any' && (
        <CustomText
          color={state === 'Disabled' ? fonts.disabled : fonts.bodyDark}
          css={css`
            margin-left: 0.25rem;
          `}
          type="hint"
        >
          {acl.description.length > 32 ? `${acl.description.slice(0, 32)}...` : acl.description}
        </CustomText>
      )} */}
    </AclWrapper>
  );
});

export const AclState = memo(({ state, acls, isLoading }: IAclState) => {
  const {
    palette: { fonts },
  } = useTheme();

  const { translate } = useTranslations('policies');

  return (
    <>
      {isLoading ? (
        <Skeleton />
      ) : (
        <Tooltip
          overlay={
            state === 'InactiveNoAcls' ? (
              <CustomText type="body-2" color={fonts.primaryButton}>
                {translate('no_acl_tooltip')}
              </CustomText>
            ) : null
          }
        >
          {acls.length == 0 ? (
            <CustomText
              type="body-2"
              color={state === 'Disabled' ? fonts.disabled : fonts.bodyDark}
            >
              None
            </CustomText>
          ) : (
            <Container>
              {acls.map((acl, idx) => (
                <AclItem key={idx} state={state} acl={acl} />
              ))}
            </Container>
          )}
        </Tooltip>
      )}
    </>
  );
});
