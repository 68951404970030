// libs
import { memo, SyntheticEvent, useCallback, useMemo } from 'react';
import dayjs from 'dayjs';

// components
import Skeleton from 'react-loading-skeleton';
import { ActionDropdown } from 'components';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { useDispatch } from 'react-redux';

// actions
import { disablePolicy, enablePolicy, enableUntilPolicy } from 'redux/actions/policies';
import { createPolicySideMenu, loadPolicyDetails, setModalContent } from 'redux/actions/app';

// types
import { IPolicy } from 'types';

interface IActionMenuProps {
  record: IPolicy;
  isLoading: boolean;
}

export const PoliciesActionDropdown = memo(({ isLoading, record }: IActionMenuProps) => {
  const dispatch = useDispatch();

  const { translate } = useTranslations('policies');
  const { translate: translateGlobal } = useTranslations('global');

  const enableUntilData: { text: string; value: () => dayjs.Dayjs | 'custom' }[] = useMemo(() => {
    return [
      {
        text: translateGlobal('action_dropdown_time.15_mins'),
        value: () => dayjs().add(15, 'minute'),
      } as { text: string; value: () => dayjs.Dayjs | 'custom' },
      {
        text: translateGlobal('action_dropdown_time.30_mins'),
        value: () => dayjs().add(30, 'minute'),
      },
      {
        text: translateGlobal('action_dropdown_time.1_hour'),
        value: () => dayjs().add(1, 'hour'),
      },
      {
        text: translateGlobal('action_dropdown_time.3_hours'),
        value: () => dayjs().add(3, 'hour'),
      },
      {
        text: translateGlobal('action_dropdown_time.8_hours'),
        value: () => dayjs().add(8, 'hour'),
      },
      {
        text: translateGlobal('action_dropdown_time.1_day'),
        value: () => dayjs().add(1, 'day'),
      },
      {
        text: translateGlobal('action_dropdown_time.5_days'),
        value: () => dayjs().add(5, 'day'),
      },
      {
        text: translateGlobal('action_dropdown_time.1_week'),
        value: () => dayjs().add(1, 'week'),
      },
      {
        text: translateGlobal('action_dropdown_time.1_month'),
        value: () => dayjs().add(1, 'month'),
      },
      {
        text: translateGlobal('action_dropdown_time.custom'),
        value: () => 'custom',
      },
    ];
  }, [translateGlobal]);

  const onDisableClick = useCallback(() => {
    dispatch(disablePolicy({ policyId: String(record.id) }));
  }, [dispatch, record.id]);

  const onEnableClick = useCallback(() => {
    dispatch(enablePolicy({ policyId: String(record.id) }));
  }, [dispatch, record.id]);

  const onEnableUntilClick = useCallback(
    (value: () => dayjs.Dayjs | 'custom', text: string) => {
      const extract = value();

      if (extract === 'custom') {
        dispatch(
          loadPolicyDetails({
            policyId: String(record.id),
            mode: 'edit',
            scrollToAutoExpire: true,
          })
        );
      } else {
        dispatch(
          enableUntilPolicy({
            policyId: String(record.id),
            action: record.isEnabled ? 'DisableAfter' : 'EnableFor',
            text,
            updatedParams: {
              expiryDateTime: extract.toISOString(),
              expiryAction: 'Disable',
              timeZoneId: null,
            },
          })
        );
      }
    },
    [dispatch, record.id, record.isEnabled]
  );

  const onRemoveClick = useCallback(() => {
    dispatch(setModalContent({ type: 'policies-remove', data: [record] }));
  }, [dispatch, record]);

  const onEditClick = useCallback(() => {
    dispatch(loadPolicyDetails({ policyId: String(record.id), mode: 'edit' }));
  }, [dispatch, record.id]);

  const onDuplicateClick = useCallback(() => {
    dispatch(createPolicySideMenu({ policyId: String(record.id) }));
  }, [dispatch, record.id]);

  return (
    <>
      {isLoading ? (
        <Skeleton />
      ) : (
        <ActionDropdown
          data-test="policies-table-action-dropdown-column"
          onContainerClick={(event: SyntheticEvent) => event.stopPropagation()}
          data={[
            { text: translate('table_actions.edit'), onClick: onEditClick },
            {
              text: translate('table_actions.duplicate'),
              onClick: onDuplicateClick,
            },
            {
              text: record.isEnabled
                ? translate('table_actions.disable')
                : translate('table_actions.enable'),
              onClick: record.isEnabled ? onDisableClick : onEnableClick,
            },
            {
              isDropdown: true,
              text: record.isEnabled
                ? translateGlobal('action_dropdown_time.disable_after')
                : translateGlobal('action_dropdown_time.enable_for'),
              data: enableUntilData,
              onClick: onEnableUntilClick,
            },
            {
              text: translate('table_actions.remove'),
              onClick: onRemoveClick,
            },
          ]}
        />
      )}
    </>
  );
});
