// components
import { IpRange } from './IpRange';
import { CloseButton } from './CloseButton';
import { Description } from './Description';
import { IsBlocked } from './IsBlocked';
import { Container, ContentContainer, Row } from './styled';

// types
import { ITrustRequirementPublicIpIpRangeConditionModified } from 'types';

interface IIpRangeEditable {
  ipRange: ITrustRequirementPublicIpIpRangeConditionModified;
}

export const IpRangeEditable = ({ ipRange }: IIpRangeEditable) => {
  return (
    <Container>
      <ContentContainer>
        <Row>
          <IsBlocked ipRange={ipRange} />
          <IpRange ipRange={ipRange} />
        </Row>
        <Row>
          <Description ipRange={ipRange} />
        </Row>
      </ContentContainer>
      <CloseButton ipRange={ipRange} />
    </Container>
  );
};
