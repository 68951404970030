// Libs
import { useCallback, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

// Components
import { FilterAndBulkActionsContainer, HeaderRow } from './styled';
import { CustomText } from 'typography/Text';
import { CustomButton, PageTitle, Filter, TrialBanner } from 'components';
import { Table } from './Table';
import { BulkActions } from './BulkActions';

// Hooks
import { useSelector, useDispatch } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';
import { ITag } from 'types';
import { useTagsEmptyText } from './useTagsEmptyText';
import { useParams } from 'react-router';

// Actions
import { getTags, getTagSearchKeys, setTags } from 'redux/actions/tags';
import { closeDetails, createTagSideMenu, loadTagDetails } from 'redux/actions/app';

// constants
import { ITEMS_FOR_SKELETON, TAGS_SORT_OPTIONS } from 'appConstants';
import { initialTagsResponse } from 'redux/reducers/tags';

// Selectors
import {
  selectTagsIsLoading,
  selectTagsTotal,
  selectTagsUpdateRef,
  selectAppDetailsTabType,
  selectIsTagsModule,
  selectPathQuerySearch,
  selectPathQuerySort,
  selectAppDetailsTag,
  selectTagsSearchKeys,
} from 'redux/selectors';

export const emptyTags = Array.from({ length: ITEMS_FOR_SKELETON }, (_el, index) => ({
  ref: String(index),
  tag: String(index),
})) as ITag[];

interface ITagParams {
  tagRef: string;
}

export const Tags = () => {
  const dispatch = useDispatch();

  const { translate } = useTranslations('tags');

  const { tagRef } = useParams<keyof ITagParams>();

  const total = useSelector(selectTagsTotal);
  const updateRef = useSelector(selectTagsUpdateRef);
  const isLoading = useSelector(selectTagsIsLoading);
  const queryParamsSort = useSelector(selectPathQuerySort);
  const queryParamsSearch = useSelector(selectPathQuerySearch);
  const detailsType = useSelector(selectAppDetailsTabType);
  const selectedTag = useSelector(selectAppDetailsTag);
  const isTagsModule = useSelector(selectIsTagsModule);
  const searchKeys = useSelector(selectTagsSearchKeys);

  const [page, setPage] = useState(0);
  const [searchValue, setSearchValue] = useState(queryParamsSearch || '');

  const [bulkSelectedTags, setBulkSelectedTags] = useState<ITag[]>([]);
  const [sortBy, setSortBy] = useState(
    TAGS_SORT_OPTIONS.find(option => option.value === queryParamsSort) || TAGS_SORT_OPTIONS[0]
  );

  const {
    palette: { fonts },
  } = useTheme();

  useEffect(() => {
    dispatch(getTagSearchKeys());
  }, [dispatch]);

  useEffect(() => {
    if (isTagsModule && tagRef) {
      if (detailsType !== 'tag-view' && detailsType !== 'tag-edit') {
        dispatch(loadTagDetails({ ref: tagRef, mode: 'view', redirect: false }));
        return;
      }
      if ((detailsType === 'tag-view' || detailsType === 'tag-edit') && tagRef !== selectedTag) {
        dispatch(loadTagDetails({ ref: tagRef, mode: 'view', redirect: false }));
        return;
      }
    }

    if (!tagRef && detailsType === 'tag-view') {
      dispatch(closeDetails({ redirect: false }));
    }
  }, [tagRef, detailsType, selectedTag, dispatch, isTagsModule]);

  const { emptyText } = useTagsEmptyText({
    searchValue,
  });

  const createNewTag = () => dispatch(createTagSideMenu());

  const fetchData = useCallback(
    () =>
      dispatch(
        getTags({
          search: searchValue,
          page: page || 0,
          sort: sortBy.value,
        })
      ),
    [dispatch, page, searchValue, sortBy]
  );

  useEffect(() => {
    dispatch(fetchData());
  }, [dispatch, updateRef, fetchData]);

  useEffect(() => {
    return () => {
      dispatch(setTags(initialTagsResponse));
    };
  }, [dispatch]);

  useEffect(() => {
    setPage(0);
  }, [searchValue]);

  return (
    <>
      <PageTitle title={translate('tags')} />
      <HeaderRow>
        <CustomText data-test="tags-header" type="heading-1" color={fonts.title}>
          {translate('tags')}
        </CustomText>
        <CustomButton
          kind="primary"
          size="large"
          onClick={createNewTag}
          disabled={false}
          data-test="create-tag"
        >
          {translate('create_tag')}
        </CustomButton>
      </HeaderRow>
      <TrialBanner />
      <FilterAndBulkActionsContainer>
        {bulkSelectedTags.length ? (
          <BulkActions
            bulkSelectedTags={bulkSelectedTags}
            setBulkSelectedTags={setBulkSelectedTags}
          />
        ) : (
          <Filter
            {...{ total, page, fetchData }}
            {...{ searchValue, setSearchValue }}
            {...{ sortBy, setSortBy, isLoading, searchKeys }}
            sortOptions={TAGS_SORT_OPTIONS}
          />
        )}
      </FilterAndBulkActionsContainer>
      <Table
        page={page}
        setPage={setPage}
        isLoading={isLoading}
        total={total}
        bulkSelectedTags={bulkSelectedTags}
        setBulkSelectedTags={setBulkSelectedTags}
        emptyText={emptyText}
        selectedTag={tagRef}
      />
    </>
  );
};
