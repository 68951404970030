// components
import { SpanStyledAsLargeInput } from 'components';

// hooks
import { useTheme } from 'styled-components';
import { useSelector } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';

// selectors
import { selectAppDetailsTrustRequirementDescription } from 'redux/selectors';

export const TitleView = () => {
  const { translate } = useTranslations('policies');

  const {
    palette: { fonts },
  } = useTheme();

  const description = useSelector(selectAppDetailsTrustRequirementDescription);

  return (
    <>
      <SpanStyledAsLargeInput
        type="heading-2"
        color={description ? fonts.bodyDark : fonts.disabled}
      >
        {description || translate('details.no_description')}
      </SpanStyledAsLargeInput>
    </>
  );
};
