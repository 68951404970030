// components
import { CreateTag } from './styled';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEmptyText } from 'hooks/useEmptyText';

// actions
import { createTagSideMenu } from 'redux/actions/app';

// selectors
import { selectEnrolmentKeysIsLoading } from 'redux/selectors';

// constants
import { TAGS_MODULE } from 'appConstants';

type Props = {
  searchValue: string;
};

export const useTagsEmptyText = ({ searchValue }: Props) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectEnrolmentKeysIsLoading);
  const { translate } = useTranslations(TAGS_MODULE);

  const noDataText = useMemo(
    () => ({
      title: translate('table_empty.no_created'),
      description: translate('table_empty.start_using'),
    }),
    [translate]
  );
  const noDataFoundText = useMemo(
    () => ({
      title: translate('table_empty.no_data_found'),
      description: translate('table_empty.search_in_docs'),
    }),
    [translate]
  );

  const createNewTag = useCallback(() => dispatch(createTagSideMenu()), [dispatch]);

  const additionalText = useMemo(
    () => <CreateTag onClick={createNewTag}>{translate('table_empty.start_using_link')}</CreateTag>,
    [createNewTag, translate]
  );

  const { emptyText } = useEmptyText({
    searchValue,
    noDataText,
    noDataFoundText,
    isLoading,
    additionalText,
    module: TAGS_MODULE,
  });

  return { emptyText };
};
