// libs
import { select, put, call } from 'typed-redux-saga';

// selectors
import { selectAppDetailsPolicyId } from 'redux/selectors';

// actions
import { setPoliciesShouldUpdate } from 'redux/actions/policies';
import { closeDetails, pushNotification, setModalContent } from 'redux/actions/app';

// types
import { IActionPayload, IDeletePolicyProps, INotificationType } from 'types';

// sagas
import { getCurrentOrganisationSaga } from '../organisation/getCurrentOrganisationSaga';

// utils
import { ApiTypeCaller } from '../../api/utils';
import { translator } from 'utils';

export function* deletePolicySaga({ payload: { policyId } }: IActionPayload<IDeletePolicyProps>) {
  const selectedPolicyId = yield* select(selectAppDetailsPolicyId);

  if (selectedPolicyId !== undefined && policyId === String(selectedPolicyId)) {
    yield* put(closeDetails({}));
  }

  const { success, result } = yield* ApiTypeCaller('deletePolicy', { policyId });

  if (success && result) {
    const translate = translator('notifications');

    yield put(setModalContent({ data: null, type: null }));
    yield put(setPoliciesShouldUpdate());
    yield call(getCurrentOrganisationSaga);

    yield put(
      pushNotification({
        showType: INotificationType.Success,
        description: translate('policy_removed', {
          policyId: selectedPolicyId,
        }),
      })
    );
  }
}
