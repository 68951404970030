// libs
import { css, useTheme } from 'styled-components';

// components
import { InfoIconButton, Details } from 'components';
import { IPAddressConstraintsSection } from './styled';
import { IpConstraintsBlock } from '../../Blocks';

import { IconEnrolmentKeysDetailsIpAddressConstraintsSection } from 'assets/svg';

// hooks
import { useSelector } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';

// selectors
import {
  selectAppDetailsEnrolmentKeyIpConstraints,
  selectAppDetailsTabType,
} from 'redux/selectors';

export const IpConstraints = () => {
  const { translate } = useTranslations('enrolment-keys');

  const {
    palette: { background, fonts },
  } = useTheme();

  const type = useSelector(selectAppDetailsTabType);
  const ipConstraints = useSelector(selectAppDetailsEnrolmentKeyIpConstraints);

  return (
    <IPAddressConstraintsSection>
      <Details.SectionHeader
        Icon={IconEnrolmentKeysDetailsIpAddressConstraintsSection}
        title={`${translate('details.ip_address_constraints')} (${ipConstraints?.length || 0})`}
        info={
          type === 'enrolment-key-edit' || type === 'enrolment-key-create' ? (
            <InfoIconButton
              tooltipPlacement="topLeft"
              title={translate('details.ip_constraints_info')}
              textColor={fonts.bodyDark}
              tooltipBackgroundColor={background.panel}
              containerCss={css`
                margin-left: 0.5625rem;
              `}
            />
          ) : null
        }
      />
      <IpConstraintsBlock />
    </IPAddressConstraintsSection>
  );
};
