// libs
import React from 'react';

// components
import { ExternalLinkContainer, StyledExternalLinkIcon, LinkContentContainer } from './styled';
import { CustomText } from 'typography/Text';

// hooks
import { useStylesMap } from './useStylesMap';

// types
import { TextTypes } from 'types';

export type ColorTypes = 'default' | 'light';

type ELProps = {
  href: string;
  text?: string | React.ReactNode;
  children?: React.ReactNode;
  withIcon?: boolean;
  textType?: TextTypes;
  target?: string;
  disabled?: boolean;
  width?: string;
  tabIndex?: number;
  kind?: ColorTypes;
  ellipsis?: boolean;
};

export const ExternalLink = ({
  text,
  href,
  withIcon,
  textType = 'hint',
  target = '_blank',
  disabled = false,
  width,
  tabIndex,
  kind = 'default',
  ellipsis = false,
  children,
  ...rest
}: ELProps) => {
  const styles = useStylesMap(kind);

  return (
    <ExternalLinkContainer
      tabIndex={tabIndex || (disabled ? -1 : undefined)}
      styles={styles}
      target={target}
      rel="noopener noreferrer"
      disabled={disabled}
      href={href}
      width={width}
      {...rest}
    >
      <LinkContentContainer>
        <CustomText type={textType} ellipsis={ellipsis}>
          {children || text}
        </CustomText>
        {withIcon && <StyledExternalLinkIcon />}
      </LinkContentContainer>
    </ExternalLinkContainer>
  );
};
