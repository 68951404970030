// components
import { CustomMessage, SpanStyledAsLargeInput } from 'components';

// hooks
import { useTheme } from 'styled-components';
import { useSelector } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';

// selectors
import { selectAppDetailsPolicyDescription, selectAppDetailsPolicyState } from 'redux/selectors';

export const TitleView = () => {
  const { translate } = useTranslations('policies');

  const {
    palette: { fonts },
  } = useTheme();

  const state = useSelector(selectAppDetailsPolicyState);
  const description = useSelector(selectAppDetailsPolicyDescription);

  return (
    <>
      <SpanStyledAsLargeInput
        type="heading-2"
        color={description ? fonts.bodyDark : fonts.disabled}
      >
        {description || translate('details.no_description')}
      </SpanStyledAsLargeInput>
      {state === 'InactiveNoAcls' && (
        <CustomMessage
          withIcon={true}
          type="warning"
          message={translate('details.no_acl_notification')}
        />
      )}
    </>
  );
};
