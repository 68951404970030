// libs
import { css, useTheme } from 'styled-components';

// components
import { CustomText } from 'typography/Text';
import { Menu } from 'antd';
import { IDataDropdown } from '.';
import { MenuItem } from './MenuItem';

const { SubMenu: AntdSubMenu } = Menu;

interface IMenuItem<T> {
  text: string;
  color?: string;
  subMenuData: IDataDropdown<T>;
  onClickAdditional: () => void;
}
export const SubMenu = <T extends any>({
  text,
  color,
  subMenuData,
  onClickAdditional,
  ...rest
}: IMenuItem<T>) => {
  const {
    palette: {
      fonts: { bodyLight },
    },
  } = useTheme();

  return (
    <AntdSubMenu
      {...rest}
      css={css`
        background: transparent;

        :hover {
          background-color: ${({ theme }) => theme.palette.background.iconBackground};
        }

        .ant-dropdown-menu-submenu-title {
          padding-top: 0.5625rem;
          padding-bottom: 0.5625rem;
        }
      `}
      title={
        <CustomText type="body-2" color={color || bodyLight}>
          {text}
        </CustomText>
      }
    >
      {subMenuData.data.map(el => {
        return (
          <MenuItem
            data-test="action-dropdown-menu-item"
            key={el.text}
            text={el.text}
            onClick={() => {
              subMenuData.onClick(el.value, el.text);
              onClickAdditional();
            }}
            color={subMenuData.color}
            css={css`
              margin-left: 0.25rem;
              margin-right: 0.25rem;

              :first-child {
                margin-top: 0.25rem;
              }
              :last-child {
                margin-bottom: 0.25rem;
              }
            `}
          />
        );
      })}
    </AntdSubMenu>
  );
};
