import { IOrganisationPlan } from 'types';

interface IPlanDependentContent {
  plan: IOrganisationPlan;
  hiddenWith: IOrganisationPlan[];
  children: React.ReactNode;
}

export const PlanDependentContent = ({ hiddenWith, plan, children }: IPlanDependentContent) => {
  if (hiddenWith.includes(plan)) {
    return null;
  }
  return <>{children}</>;
};
