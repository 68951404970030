import { useTheme } from 'styled-components';
import { CustomText } from 'typography/Text';

export const Title = ({ children }: { children: React.ReactNode }) => {
  const {
    palette: { fonts },
  } = useTheme();

  return (
    <CustomText type="sub-heading-2" color={fonts.bodyDark}>
      {children}
    </CustomText>
  );
};
