import { createAction } from '@reduxjs/toolkit';
import {
  ICreateTrustRequirementPayload,
  ILoadTrustRequirementDetails,
  ITrustRequirementConditionModified,
  ITrustRequirementPublicIpIpRangeCondition,
  ITrustRequirementPublicIpLocationCondition,
  ITypeTrustRequirementUserAuthenticationAuthority,
  IUpdateTrustRequirement,
} from 'types';

export const updateTrustRequirementDetails = createAction<IUpdateTrustRequirement>(
  'main/UPDATE_TRUST_REQUIREMENT_DETAILS'
);

export const updateTrustRequirementDetailsUserAuthenticationAuthority =
  createAction<ITypeTrustRequirementUserAuthenticationAuthority>(
    'main/UPDATE_TRUST_REQUIREMENT_DETAILS_AUTHORITY'
  );

export const createTrustRequirementSideMenu = createAction<
  ICreateTrustRequirementPayload | undefined
>('main/CREATE_TRUST_REQUIREMENT_SIDE_MENU');

export const loadTrustRequirementDetails = createAction<ILoadTrustRequirementDetails>(
  'main/LOAD_TRUST_REQUIREMENT_DETAILS'
);

export const createTrustRequirementDetailsPublicIpIpRangeCondition = createAction<
  Partial<ITrustRequirementPublicIpIpRangeCondition>
>('main/CREATE_TRUST_REQUIREMENT_DETAILS_PUBLIC_IP_IP_RANGE_CONDITION');
export const updateTrustRequirementDetailsPublicIpIpRangeCondition = createAction<{
  id: ITrustRequirementConditionModified['id'];
  range: Partial<ITrustRequirementPublicIpIpRangeCondition>;
}>('main/UPDATE_TRUST_REQUIREMENT_DETAILS_PUBLIC_IP_IP_RANGE_CONDITION');
export const deleteTrustRequirementDetailsPublicIpIpRangeCondition = createAction<{
  id: ITrustRequirementConditionModified['id'];
}>('main/DELETE_TRUST_REQUIREMENT_DETAILS_IP_RANGE_CONDITION');

export const updateTrustRequirementDetailsPublicIpLocationConditionIsBlocked = createAction<{
  isBlocked: ITrustRequirementPublicIpLocationCondition['isBlocked'];
}>('main/UPDATE_TRUST_REQUIREMENT_DETAILS_PUBLIC_IP_LOCATION_CONDITION_IS_BLOCKED');

export const addTrustRequirementDetailsPublicIpLocationCondition = createAction<{
  value: ITrustRequirementPublicIpLocationCondition['value'];
  isBlocked: ITrustRequirementPublicIpLocationCondition['isBlocked'];
}>('main/ADD_TRUST_REQUIREMENT_DETAILS_PUBLIC_IP_LOCATION_CONDITION');

export const deleteTrustRequirementDetailsPublicIpLocationCondition = createAction<{
  value: ITrustRequirementPublicIpLocationCondition['value'];
}>('main/DELETE_TRUST_REQUIREMENT_DETAILS_PUBLIC_IP_LOCATION_CONDITION');

export const clearTrustRequirementDetailsPublicIpLocationConditions = createAction(
  'main/CLEAR_TRUST_REQUIREMENT_DETAILS_PUBLIC_IP_LOCATION_CONDITIONS'
);
