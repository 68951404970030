import { css } from 'styled-components';

export const timeZoneSelectCss = css`
  // empty search
  .ant-select-item-empty {
    min-height: 269px !important;
  }

  // so that list keeps height on few items
  .rc-virtual-list-holder {
    min-height: 269px !important;
  }
`;
