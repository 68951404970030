import { memo } from 'react';
import Skeleton from 'react-loading-skeleton';
import { css, useTheme } from 'styled-components';
import { CustomText } from 'typography/Text';

interface ICountInfo {
  data: number;
  isLoading: boolean;
}

export const CountInfo = memo(({ data, isLoading }: ICountInfo) => {
  const {
    palette: { fonts },
  } = useTheme();
  return (
    <>
      {isLoading ? (
        <Skeleton />
      ) : (
        <CustomText
          ellipsis={true}
          css={css`
            display: block;
          `}
          title={`${data}`}
          type="body-2"
          color={fonts.bodyDark}
        >
          {data ? data : 0}
        </CustomText>
      )}
    </>
  );
});
