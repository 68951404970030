import { useTranslations } from 'hooks/useTranslations';
import { CustomButton } from 'components';
import { StyledItem } from './List';
import { useDispatch, useSelector } from 'react-redux';
import { redirectControl } from 'redux/actions/app';
import { selectSelectedEnrolmentKey } from 'redux/selectors';
import { useMemo } from 'react';

export const GoToSystems = () => {
  const dispatch = useDispatch();
  const selectedKey = useSelector(selectSelectedEnrolmentKey);
  const { translate } = useTranslations('enrol-new-system');

  const navigation = useMemo(() => {
    if (!selectedKey || selectedKey.approvalMode === 'Automatic') {
      return { path: '/systems', text: 'go_to_enrolled_systems', step: 'check_out_new_system' };
    }

    return {
      path: '/unapproved-systems',
      text: 'go_to_unapproved_systems',
      step: 'approve_new_system',
    };
  }, [selectedKey]);

  return (
    <StyledItem
      title={translate(navigation.step)}
      content={
        <CustomButton
          type="link"
          kind="secondary"
          size="large"
          onClick={() => dispatch(redirectControl({ route: navigation.path }))}
        >
          {translate(navigation.text)}
        </CustomButton>
      }
    />
  );
};
