import styled from 'styled-components';
import { MODAL_FOOTER_HEIGHT } from 'appConstants';

export const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${MODAL_FOOTER_HEIGHT};
  padding-left: 2.5rem;
  padding-right: 2.5rem;
`;
