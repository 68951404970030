// components
import { Details } from 'components';
import { PurposeBlock } from '../../Blocks/Purpose';

// hooks
import { useSelector } from 'react-redux';

// selectors
import { selectAppDetailsTabType } from 'redux/selectors';

export const TopMenuButtons = () => {
  const type = useSelector(selectAppDetailsTabType);

  return (
    <>
      <PurposeBlock />
      <Details.TopMenuButtons
        showEditLink={type === 'system-view' || type === 'unapproved-system-view'}
      />
    </>
  );
};
