// components
import { CustomText } from 'typography/Text';

// hooks
import { useSelector } from 'react-redux';
import { css, useTheme } from 'styled-components';

// selectors
import { selectAppDetailsTagColour } from 'redux/selectors';

// constants
import { TAGS_DEFAULT_COLOUR } from 'appConstants/tags';

export const ColourView = () => {
  const {
    palette: { fonts },
  } = useTheme();

  const colour = useSelector(selectAppDetailsTagColour) || TAGS_DEFAULT_COLOUR;

  return (
    <CustomText
      type="body-2"
      color={fonts.title}
      css={css`
        overflow-wrap: anywhere;
      `}
    >
      {colour}
    </CustomText>
  );
};
