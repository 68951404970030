import { useCallback, useEffect, useRef } from 'react';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { History, Listener } from 'history';
import { locationChange } from 'redux/actions/router';

interface ConnectedRouterProps {
  history: History;
  children: React.ReactNode;
}

const ConnectedRouter = ({ history, children }: ConnectedRouterProps) => {
  const dispatch = useDispatch();
  const firstRender = useRef(true);

  const handleLocationChange: Listener = useCallback(
    update => {
      dispatch(locationChange(update));
    },
    [dispatch]
  );

  const listen = useCallback(() => {
    history.listen(handleLocationChange);
  }, [history, handleLocationChange]);

  useEffect(() => {
    if (firstRender) {
      firstRender.current = false;
      listen();
    }
  }, [listen]);

  return <HistoryRouter history={history}>{children}</HistoryRouter>;
};

export default ConnectedRouter;
