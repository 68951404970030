// libs
import { css } from 'styled-components';

// components
import { Details } from 'components';
import { IconPoliciesDetailsTrustRequirementsSection } from 'assets/svg';
import { TrustRequirementsSection } from './styled';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { TrustRequirementsBlock } from '../../Blocks';

export const TrustRequirements = () => {
  const { translate } = useTranslations('policies');

  return (
    <TrustRequirementsSection>
      <Details.SectionHeader
        Icon={IconPoliciesDetailsTrustRequirementsSection}
        title={translate('details.trust_requirements')}
        css={css`
          margin-bottom: 0.5rem;
        `}
      />
      <TrustRequirementsBlock />
    </TrustRequirementsSection>
  );
};
