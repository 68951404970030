// components
import { CustomText } from 'typography/Text';

// hooks
import { useTheme } from 'styled-components';
import { useSelector } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';

// selectors
import { selectAppDetailsTrustRequirementOIDCClientId } from 'redux/selectors';

export const ClientIdView = () => {
  const { translate } = useTranslations('trust-requirements');

  const {
    palette: { fonts },
  } = useTheme();

  const authorityUri = useSelector(selectAppDetailsTrustRequirementOIDCClientId);

  return (
    <CustomText type="body-2" color={fonts.title}>
      {authorityUri ||
        translate('details.types.UserAuthentication.authorities.jumpcloud.client_id')}
    </CustomText>
  );
};
