import React, { FC } from 'react';
import { css, useTheme } from 'styled-components';
import { CustomText } from 'typography/Text';

interface Props {
  prefix: string;
  name: string;
}

export const OptionElement: FC<Props> = ({ prefix, name }) => {
  const {
    palette: { fonts },
  } = useTheme();

  return (
    <>
      <CustomText
        type="body-2"
        color={fonts.disabled}
        css={css`
          margin-right: 0.5rem;
        `}
      >
        {prefix}
      </CustomText>
      <CustomText className="shown" type="body-2" color={fonts.title}>
        {name}
      </CustomText>
    </>
  );
};
