// components
import { CustomTable } from 'components';
import { columnsView } from './Columns';
import { tableCss } from './styled';

// hooks
import { useSelector } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';

// selectors
import { selectAppDetailsEnrolmentKeyIpConstraints } from 'redux/selectors';

export const IpConstraintsView = () => {
  const { translate } = useTranslations('enrolment-keys');

  const ipConstraints = useSelector(selectAppDetailsEnrolmentKeyIpConstraints);

  return (
    <>
      {ipConstraints?.length !== 0 && (
        <CustomTable
          columns={columnsView({ translate })}
          headerHeight="2.375rem"
          rowHeight="2.375rem"
          css={tableCss}
          rowKey="id"
          dataSource={ipConstraints}
        />
      )}
    </>
  );
};
