// hooks
import { css, useTheme } from 'styled-components';

// components
import { CustomText } from 'typography/Text';
import { IconMessageWarningCircle } from 'assets/svg';
import { WarningMessageContainer } from './styled';

interface IProps {
  message?: string;
}

export const WarningMessage = ({ message }: IProps) => {
  const {
    palette: { fonts },
  } = useTheme();

  return (
    <WarningMessageContainer>
      <IconMessageWarningCircle
        css={css`
          margin-right: 0.5rem;
        `}
      />
      <CustomText type={'label-2'} color={fonts.bodyDark}>
        {message}
      </CustomText>
    </WarningMessageContainer>
  );
};
