import { PurposeBlock } from '../../Blocks';
import { PurposeSection } from './styled';

export const Purpose = () => {
  return (
    <PurposeSection>
      <PurposeBlock />
    </PurposeSection>
  );
};
