import styled, { css } from 'styled-components';
import { CustomText } from 'typography/Text';

export const StyledCustomTable = css`
  width: 100%;
  border-radius: 0.125rem;
  margin-bottom: 1rem;
  cursor: default;

  white-space: nowrap;

  td:nth-child(3) {
    width: 8.1rem;
  }
  thead tr {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  tbody tr {
    cursor: default;
  }
  tbody tr:last-child td {
    border-bottom: none;
  }
`;

export const CustomTextWithOverflow = styled(CustomText)`
  display: block;
  max-width: 19rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
