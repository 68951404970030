// components
import { CancelSaveUnapproved } from './EditUnapproved';
import { CancelSaveApproved } from './EditApproved';
import { selectAppDetailsTabType } from 'redux/selectors';

// hooks
import { useSelector } from 'react-redux';

export const CancelSave = () => {
  const type = useSelector(selectAppDetailsTabType);

  return (
    <>
      {type === 'system-edit' && <CancelSaveApproved />}
      {type === 'unapproved-system-edit' && <CancelSaveUnapproved />}
    </>
  );
};
