// libs
import { css, useTheme } from 'styled-components';

// components
import { Select } from 'components';
import { Container } from './styled';
import { CustomText } from 'typography/Text';
import { IconTrustRequirementDetailsDeleteCondition } from 'assets/svg';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';

// actions
import {
  addTrustRequirementDetailsPublicIpLocationCondition,
  clearTrustRequirementDetailsPublicIpLocationConditions,
  deleteTrustRequirementDetailsPublicIpLocationCondition,
} from 'redux/actions/app';

// selectors
import { selectAppDetailsTrustRequirementCurrentLocationsValues } from 'redux/selectors';

// types
import { RawValueType, LabelInValueType } from 'rc-select/lib/Select';
import { ITrustRequirementPublicIpLocationConditionModified } from 'types';

// constants
import { LOCATIONS } from 'appConstants';

interface ICountrySelect {
  isBlocked: ITrustRequirementPublicIpLocationConditionModified['isBlocked'];
}

export const CountrySelect = ({ isBlocked }: ICountrySelect) => {
  const dispatch = useDispatch();

  const {
    palette: { fonts },
  } = useTheme();

  const { translate } = useTranslations('trust-requirements');

  const selectValues = useSelector(selectAppDetailsTrustRequirementCurrentLocationsValues);

  const [values, setValues] = useState(selectValues || []);

  useEffect(() => {
    selectValues && setValues(selectValues);
  }, [selectValues]);

  const onSelect = useCallback(
    ({ value }: { value: ITrustRequirementPublicIpLocationConditionModified['value'] }) => {
      dispatch(
        addTrustRequirementDetailsPublicIpLocationCondition({
          isBlocked,
          value,
        })
      );
    },
    [dispatch, isBlocked]
  );

  const onDeselect = useCallback(
    ({ value }: { value: ITrustRequirementPublicIpLocationConditionModified['value'] }) => {
      dispatch(
        deleteTrustRequirementDetailsPublicIpLocationCondition({
          value,
        })
      );
    },
    [dispatch]
  );

  const onClear = useCallback(() => {
    dispatch(clearTrustRequirementDetailsPublicIpLocationConditions());
  }, [dispatch]);

  return (
    <Container>
      <Select
        value={values}
        aria-label={translate('details.aria_labels.location')}
        placeholder={translate('details.types.PublicIp.location_placeholder')}
        showSearch={true}
        dataSource={LOCATIONS}
        height="100%"
        showArrow={true}
        mode="multiple"
        OptionElement={option => {
          return (
            <CustomText type="body-2" color={fonts.title}>
              {option.name}
            </CustomText>
          );
        }}
        containerCss={css`
          flex: 1;
        `}
        width="100%"
        valueField="alpha"
        keyField="name"
        // on default we search by value
        // we need to search by key, because value is "alpha" field
        // while we need to search by "name" field, which corresponds to keyField prop
        filterOption={(inputValue, option) => {
          if (String(option?.key).toLowerCase().includes(inputValue.toLowerCase())) {
            return true;
          } else return false;
        }}
        setSelectedValue={({ alpha }) => {
          onSelect({ value: alpha });
        }}
        onDeselect={(value: RawValueType | LabelInValueType) => {
          value && onDeselect({ value: String(value) });
        }}
      />
      <IconTrustRequirementDetailsDeleteCondition
        onClick={onClear}
        css={css`
          cursor: pointer;
          margin-left: 0.5rem;
        `}
        width="1.25rem"
        height="1.25rem"
      />
    </Container>
  );
};
