// libs
import React, { useState, useEffect, useCallback } from 'react';

// hooks
import { useTheme, css } from 'styled-components';
import { useTranslations } from 'hooks/useTranslations';
import { useDispatch, useSelector } from 'react-redux';

// components
import { Button } from 'antd';
import { CustomText } from 'typography/Text';
import { Input, CustomButton, Loader } from 'components';
import { ReactComponent as IconInvite } from 'assets/svg/settings-invite.svg';
import { InviteCreateColumn, InviteCreateContainer, InviteCreateRow } from '../styled';

// actions
import { sendUserInvite } from 'redux/actions/organisation';
import { clearErrors } from 'redux/actions/app';

// selectors
import {
  selectAppIsLoading,
  selectCurrentOrganisationHasAdminSlotsLeft,
  selectCurrentOrganisationInvites,
  selectFormError,
} from 'redux/selectors';
import { IconSettingsCreateInvite } from 'assets/svg';

// utils
import { validateEmail } from 'utils';

// constants
import { SETTINGS_CREATE_INVITE_ERROR_FIELD } from 'appConstants';

export const InviteCreate = () => {
  const dispatch = useDispatch();

  const {
    palette: { fills },
  } = useTheme();

  const { translate } = useTranslations('settings');

  const [creating, setCreating] = useState<boolean>(false);
  const [emailAddress, setEmailAddress] = useState<string>('');
  const [error, setError] = useState<string>('');

  const isLoading = useSelector(selectAppIsLoading);
  const invites = useSelector(selectCurrentOrganisationInvites);
  const hasAdminSlotsRemaining = useSelector(selectCurrentOrganisationHasAdminSlotsLeft);
  const apiError: string | undefined = useSelector(
    selectFormError(SETTINGS_CREATE_INVITE_ERROR_FIELD)
  );

  useEffect(() => {
    apiError && setError(apiError);
  }, [dispatch, apiError]);

  useEffect(() => {
    return () => {
      if (apiError) dispatch(clearErrors());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const handleCreateInvite = useCallback(() => {
    setCreating(true);
  }, []);

  const handleCancel = useCallback(() => {
    setCreating(false);
    setEmailAddress('');
    setError('');
  }, []);

  const handleInvite = useCallback(() => {
    if (validateEmail(emailAddress)) {
      dispatch(sendUserInvite({ emailAddress }));
    } else {
      setError(translate('should_be_valid'));
    }
  }, [dispatch, emailAddress, translate]);

  useEffect(handleCancel, [handleCancel, invites]);

  const handleChangeEmail = useCallback(
    ({ target: { value } }: React.ChangeEvent<HTMLInputElement>): void => {
      setEmailAddress(value);
      if (error !== '') setError('');
    },
    [error]
  );

  const onKeyDown: React.KeyboardEventHandler<HTMLInputElement> = useCallback(
    e => {
      if (e.code === 'Enter') {
        handleInvite();
      }
    },
    [handleInvite]
  );
  return hasAdminSlotsRemaining ? (
    <InviteCreateContainer>
      {creating ? (
        <InviteCreateRow error={Boolean(error)}>
          <InviteCreateColumn col={1}>
            <IconInvite
              css={css`
                margin-top: 0.25rem;
                margin-right: 1rem;
              `}
            />
            <Input
              type="text"
              name="email"
              value={emailAddress}
              placeholder={translate('enter_email')}
              onChange={handleChangeEmail}
              onKeyDown={onKeyDown}
              error={{ message: error }}
              stateMessageCss={css`
                display: ${!error && 'none'};
              `}
            />
          </InviteCreateColumn>
          <InviteCreateColumn col={2}>
            <CustomButton
              kind="link"
              size="large"
              css={css`
                margin-right: 1rem;
              `}
              onClick={handleCancel}
            >
              {translate('invite_cancel')}
            </CustomButton>
            <CustomButton
              kind={isLoading || error ? 'disabled' : 'primary'}
              size="large"
              onClick={handleInvite}
            >
              {isLoading ? <Loader /> : translate('invite')}
            </CustomButton>
          </InviteCreateColumn>
        </InviteCreateRow>
      ) : (
        <Button
          css={css`
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 1.25rem;
          `}
          type="link"
          onClick={handleCreateInvite}
        >
          <IconSettingsCreateInvite />
          <CustomText
            type="body-1"
            color={fills.primary}
            css={css`
              margin-left: 0.3125rem;
            `}
          >
            {translate('invite_user')}
          </CustomText>
        </Button>
      )}
    </InviteCreateContainer>
  ) : null;
};
