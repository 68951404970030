// libs
import { css, useTheme } from 'styled-components';

// components
import { CustomButton, BulkClearButton } from 'components';
import { CustomText } from 'typography/Text';
import { BulkActionsContainer } from 'components/BulkActions/styled';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { useDispatch } from 'react-redux';
import { useCallback } from 'react';

// actions
import { setModalContent } from 'redux/actions/app';

// types
import { ITrustRequirementBasic } from 'types';

interface IBulkActions {
  bulkSelectedTrustRequirements: ITrustRequirementBasic[];
  setBulkSelectedTrustRequirements: (bulkTrustRequirement: ITrustRequirementBasic[]) => void;
}
export const BulkActions = ({
  bulkSelectedTrustRequirements,
  setBulkSelectedTrustRequirements,
}: IBulkActions) => {
  const dispatch = useDispatch();

  const { translate } = useTranslations('trust-requirements');
  const { translate: translateGlobal } = useTranslations('global');

  const {
    palette: { fonts },
  } = useTheme();

  const onRemoveClick = useCallback(() => {
    dispatch(
      setModalContent({
        type: 'remove-trust-requirements',
        data: {
          requirements: bulkSelectedTrustRequirements.map(requirement => ({
            id: requirement.id,
            name: requirement.description,
          })),
        },
      })
    );
  }, [bulkSelectedTrustRequirements, dispatch]);

  return (
    <BulkActionsContainer data-test="trust-requirements-bulk-actions">
      <CustomText type="sub-heading-2" color={fonts.title}>
        {bulkSelectedTrustRequirements.length}{' '}
        {bulkSelectedTrustRequirements.length !== 1
          ? translateGlobal('bulk.items_selected', {
              module: translate('modules.items'),
            })
          : translateGlobal('bulk.item_selected', {
              module: translate('modules.item'),
            })}
      </CustomText>
      <BulkClearButton
        type="link"
        onClick={() => {
          setBulkSelectedTrustRequirements([]);
        }}
      >
        {translateGlobal('bulk.clear_all')}
      </BulkClearButton>

      <CustomButton
        kind="secondaryDanger"
        onClick={onRemoveClick}
        css={css`
          margin-right: 1rem;
        `}
      >
        {translateGlobal('bulk.remove')}
      </CustomButton>
    </BulkActionsContainer>
  );
};
