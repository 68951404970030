// libs
import React, { CSSProperties } from 'react';
import { CSSProp, useTheme } from 'styled-components';

// components
import { CustomText } from 'typography/Text';
import { DetailDescriptionCol, DetailNameCol, DetailRow, ContentWrapper } from './styled';

// types
import { TypeWithDefaultDivProps } from 'types';

interface IKeyValueProps {
  title: string | React.ReactNode;
  titleId?: string;
  alignItems?: CSSProperties['alignItems'];
  height?: string;
  children: React.ReactNode;
  contentCss?: CSSProp;
  nameCss?: CSSProp;
  descriptionCss?: CSSProp;
}
export const DetailsKeyValue = ({
  title,
  titleId,
  height,
  children,
  contentCss,
  alignItems,
  nameCss,
  descriptionCss,
  ...rest
}: TypeWithDefaultDivProps<IKeyValueProps>) => {
  const {
    palette: { fonts },
  } = useTheme();
  return (
    <DetailRow alignItems={alignItems} height={height} {...rest}>
      <ContentWrapper css={contentCss}>
        <DetailNameCol css={nameCss}>
          {typeof title === 'string' ? (
            <CustomText id={titleId} type="body-2" color={fonts.bodyLight}>
              {title}
            </CustomText>
          ) : (
            title
          )}
        </DetailNameCol>
        <DetailDescriptionCol css={descriptionCss}>{children}</DetailDescriptionCol>
      </ContentWrapper>
    </DetailRow>
  );
};
