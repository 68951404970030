// libs
import { put } from 'typed-redux-saga';
import { setSystemSearchKeys } from 'redux/actions/systems';

// api
import { ApiTypeCaller } from '../../api/utils';

export function* getSystemSearchKeysSaga() {
  const { result, success } = yield* ApiTypeCaller('getSystemSearchKeys');

  if (success && result) yield put(setSystemSearchKeys(result));
}
