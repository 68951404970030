// Lib
import styled from 'styled-components';
import { IconLoader } from 'assets/svg';

export const Loader = styled(IconLoader)<{ height?: string }>`
  animation: spin 1s linear infinite;
  height: ${({ height }) => height || '1.5rem'};
  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;
