import styled, { css, useTheme } from 'styled-components';

import { ExternalLink, Loader } from 'components';
import { IconLoading } from 'assets/svg';
import { useSelector } from 'react-redux';
import { selectNetworkConnectionError } from 'redux/selectors';
import { CustomText } from 'typography/Text';
import { Trans } from 'react-i18next';
import { useTranslations } from 'hooks/useTranslations';
import { LINK_TO_STATUS } from 'appConstants';

const StyledLoader = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    margin-bottom: 2.5rem;
  }
`;

const StyledIconLoading = styled(IconLoading)`
  margin-bottom: 1rem;
`;

export const Preloader = () => {
  const { translate } = useTranslations('global');

  const {
    palette: { fonts },
  } = useTheme();

  const networkConnectionError = useSelector(selectNetworkConnectionError);

  if (networkConnectionError) {
    return (
      <CustomText
        css={css`
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translateX(-50%) translateY(-50%);
        `}
        type="heading-1"
        color={fonts.bodyDark}
      >
        <Trans
          i18nKey={networkConnectionError}
          components={[
            <ExternalLink
              key={0}
              textType="heading-1"
              href={LINK_TO_STATUS}
              text={translate('network_connection_error_link_text')}
            />,
          ]}
        />
      </CustomText>
    );
  }

  return (
    <StyledLoader>
      {<StyledIconLoading />}
      <Loader height="2.1875rem" />
    </StyledLoader>
  );
};
