// libs
import { memo } from 'react';
import styled, { css } from 'styled-components';

// components
import { CustomText } from 'typography/Text';
import Skeleton from 'react-loading-skeleton';

// hooks
import { useTheme } from 'styled-components';
import { IconPolicy, IconGateway } from 'assets/svg';
import { StyledRow, StyledCol, ControllableIcon } from 'components';
import { IIconState } from 'types';

interface IDescription {
  description: string;
  isEnabled?: boolean;
  isLoading: boolean;
  isGateway: boolean;
  state: IIconState;
}

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

export const Description = memo(
  ({ description, isEnabled = true, state, isLoading, isGateway }: IDescription) => {
    const {
      palette: {
        fonts: { bodyDark, disabled },
        icons: { primary, white },
      },
    } = useTheme();

    return (
      <>
        {isLoading ? (
          <Skeleton />
        ) : (
          <StyledRow>
            <StyledCol $asFlex={true} $middle={true} $justify="flex-start">
              <DescriptionContainer>
                {isGateway ? (
                  <ControllableIcon
                    icon={<IconGateway />}
                    size="large"
                    state={state}
                    stateCss={{
                      active: css`
                        path:first-child,
                        path:last-child {
                          fill: ${white};
                        }
                        path:nth-child(even) {
                          stroke: ${primary};
                        }
                        path:nth-child(2n + 3):not(:last-child),
                        ellipse {
                          fill: ${primary};
                        }
                      `,
                      // to prevent default css
                      default: css``,
                    }}
                    css={css`
                      margin-right: 0.75rem;
                    `}
                  />
                ) : (
                  <ControllableIcon
                    icon={<IconPolicy />}
                    size="large"
                    state={state}
                    css={css`
                      margin-right: 0.75rem;
                    `}
                  />
                )}

                <CustomText
                  css={css`
                    display: block;
                  `}
                  data-test="policies-table-description-column"
                  ellipsis={true}
                  type="body-2"
                  color={isEnabled ? bodyDark : disabled}
                  title={description}
                >
                  {description}
                </CustomText>
              </DescriptionContainer>
            </StyledCol>
          </StyledRow>
        )}
      </>
    );
  }
);
