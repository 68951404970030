// Lib
import { StrictEffect, CallEffect, PutEffect } from 'redux-saga/effects';
import { call, put, select, delay, fork, cancel } from 'typed-redux-saga';
import { equals, clone } from 'ramda';

import {
  ITEMS_PER_TABLE_PAGE,
  LOADIND_WAIT_TIME_MS,
  TAGS_SORT_OPTIONS,
  TRUST_REQUIREMENTS_SORT_OPTIONS,
} from 'appConstants';

import { setDetailsContentLoading } from 'redux/actions/app';

import { IActionPayload, QueryParamsType } from 'types';

// Utils
import { encodeURI } from 'utils';

// Selectors
import {
  selectPathQuery,
  selectOrgId,
  selectModuleName,
  selectPathSearch,
  selectAccountOrganisations,
} from 'redux/selectors';

import {
  SYSTEMS_SORT_OPTIONS,
  USYSTEMS_SORT_OPTIONS,
  KEYS_SORT_OPTIONS,
  POLICIES_SORT_OPTIONS,
} from 'appConstants';

import { apiService } from 'api/ApiService';
import { push, replace } from 'redux/actions/router';

function* setLoading(): Generator<CallEffect | PutEffect> {
  yield* delay(LOADIND_WAIT_TIME_MS);
  yield* put(setDetailsContentLoading(true));
}

export function* loadingHandler(
  func: (arg: IActionPayload<any>) => void,
  action: IActionPayload<any>
): Generator<StrictEffect> {
  const task: any = yield* fork(setLoading);
  yield* call(func, action);

  if (task.isRunning()) {
    yield* cancel(task);
  } else {
    yield* put(setDetailsContentLoading(false));
  }
}

function* SanitizeQueryObject<PayloadType extends QueryParamsType['data']>(
  payload: PayloadType
): Generator<any, PayloadType, PayloadType> {
  const moduleName = yield* select(selectModuleName);
  // Remove default sort option

  if (
    (moduleName === 'systems' || moduleName === 'dashboard') &&
    payload.sort === SYSTEMS_SORT_OPTIONS[0].value
  )
    delete payload.sort;
  if (moduleName === 'unapproved-systems' && payload.sort === USYSTEMS_SORT_OPTIONS[0].value)
    delete payload.sort;
  if (moduleName === 'keys' && payload.sort === KEYS_SORT_OPTIONS[0].value) delete payload.sort;
  if (moduleName === 'policies' && payload.sort === POLICIES_SORT_OPTIONS[0].value)
    delete payload.sort;
  if (
    moduleName === 'trust-requirements' &&
    payload.sort === TRUST_REQUIREMENTS_SORT_OPTIONS[0].value
  )
    delete payload.sort;

  if (moduleName === 'tags' && payload.sort === TAGS_SORT_OPTIONS[0].value) delete payload.sort;

  return payload;
}

export function* SyncQueryParams<PayloadType extends QueryParamsType['data']>(
  payload: PayloadType
): Generator<any, PayloadType, PayloadType> {
  const moduleName = yield* select(selectModuleName);

  const payloadForward = clone(payload);

  // Query
  const currentQueryString = encodeURI(yield* select(selectPathQuery));
  const payloadQueryString = encodeURI(yield* SanitizeQueryObject(clone(payload)));

  if (moduleName !== 'dashboard') {
    if (!equals(currentQueryString, payloadQueryString)) {
      yield put(replace([{ search: payloadQueryString }]));
    }

    payloadForward.per_page = ITEMS_PER_TABLE_PAGE;
  }
  return payloadForward;
}

type OrgPushOptions = {
  keepSearch?: boolean;
  routePush?: boolean;
};
export function* orgPush(
  url: string,
  options: OrgPushOptions = {
    keepSearch: false,
    routePush: false,
  }
) {
  const { keepSearch, routePush } = options;

  const orgId = yield* select(selectOrgId);
  const orgs = yield* select(selectAccountOrganisations);
  const pathSearch = yield* select(selectPathSearch);

  if (orgId && orgs.length) {
    const updateFunction = routePush ? push : replace;
    yield put(updateFunction([`/org/${orgId}${url}${keepSearch ? pathSearch : ''}`]));
  }

  if (!orgId && orgs.length) {
    yield call([apiService, apiService.login]);
  }
}
