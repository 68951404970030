// components
import { SpanStyledAsLargeInput } from 'components';

// hooks
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';

// selectors
import { selectAppDetailsDnsRecordName } from 'redux/selectors';

export const TitleView = () => {
  const {
    palette: { fonts },
  } = useTheme();

  const name = useSelector(selectAppDetailsDnsRecordName);

  return (
    <SpanStyledAsLargeInput type="heading-2" color={fonts.bodyDark}>
      {name}
    </SpanStyledAsLargeInput>
  );
};
