// libs
import { css } from 'styled-components';

// components
import { Details } from 'components';
import { AutoTagView } from './View';
import { AutoTagEdit } from './Edit';
import { AutoTagCreate } from './Create';

// hooks
import { useSelector } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';

// selectors
import { selectAppDetailsTabType } from 'redux/selectors';

export const AutoTagBlock = () => {
  const { translate } = useTranslations('enrolment-keys');

  const type = useSelector(selectAppDetailsTabType);

  return (
    <Details.KeyValue
      css={css`
        box-sizing: content-box;
        padding-bottom: 1.5rem;
      `}
      contentCss={css`
        align-items: flex-start;
      `}
      descriptionCss={css`
        ${(type === 'enrolment-key-edit' || type === 'enrolment-key-create') &&
        css`
          margin-top: -0.5rem;
        `}
      `}
      height="auto"
      title={translate('details.auto_tag')}
    >
      {type === 'enrolment-key-view' && <AutoTagView />}
      {type === 'enrolment-key-edit' && <AutoTagEdit />}
      {type === 'enrolment-key-create' && <AutoTagCreate />}
    </Details.KeyValue>
  );
};
