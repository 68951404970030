// libs
import { css, useTheme } from 'styled-components';

// components
import { CardWrapper, CardContainer, ContentContainer, DescriptionText } from '../styled';
import { PlanHeader, Pricing, FeatureList } from './components';
import { CustomText } from 'typography/Text';
import { CustomProgress } from 'components';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { useSelector } from 'react-redux';

// selectors
import { selectCurrentOrganisationInfo, selectCurrentOrganisationIsOwner } from 'redux/selectors';

// types
import { IPricingModel } from 'types';

// constants
import { STARTER_PLAN } from 'appConstants';

interface PlanCardProps {
  plan: IPricingModel;
}

const type = STARTER_PLAN;

export const PlanCardStarter = ({ plan }: PlanCardProps) => {
  const {
    palette: { fonts },
  } = useTheme();

  const { translate } = useTranslations('settings');

  const { enabled, currencySymbol } = plan;

  const orgData = useSelector(selectCurrentOrganisationInfo);
  const isOwner = useSelector(selectCurrentOrganisationIsOwner);

  const isCurrentPlan = type === orgData.plan;

  return (
    <CardWrapper enabled={enabled && isOwner}>
      <PlanHeader
        text={`${translate('plans.n_systems_used', {
          from: orgData.enrolledSystems,
          to: orgData.maxSystems,
        })}`}
        active={isCurrentPlan && enabled}
      />
      <CardContainer active={isCurrentPlan}>
        {isCurrentPlan && enabled ? (
          <CustomProgress percent={(orgData.enrolledSystems / orgData.maxSystems) * 100} sharp />
        ) : (
          <CustomProgress active={false} percent={100} sharp color="transparent" />
        )}
        <ContentContainer>
          <CustomText
            type="sub-heading-1"
            css={css`
              margin-bottom: 0.25rem;
            `}
            color={enabled ? fonts.bodyDark : fonts.disabled}
          >
            {translate(`plans.${type.toLowerCase()}`)}
          </CustomText>
          <Pricing symbol={currencySymbol} rate={null} type={type} />
          <DescriptionText
            type="hint"
            color={enabled && isOwner ? fonts.bodyLight : fonts.disabled}
          >
            {translate(`plans.${type.toLowerCase()}_description`)}
          </DescriptionText>
          <FeatureList type={type} />
        </ContentContainer>
      </CardContainer>
    </CardWrapper>
  );
};
