import {
  updateTagDetails,
  setTagDetailsContent,
  extendTagDetailsContent,
} from '../../../actions/app';

import { appReducerBuilder } from '..';

export const detailsTabTagsReducerSlice: appReducerBuilder = builder => {
  builder
    .addCase(updateTagDetails, (state, { payload }) => {
      if (state.detailsTab.type === 'tag-edit' || state.detailsTab.type === 'tag-create') {
        state.detailsTab.dataNew = {
          ...state.detailsTab.dataNew,
          ...payload,
        };
      }
    })
    .addCase(setTagDetailsContent, (state, { payload }) => {
      state.detailsTab = {
        ...payload,
      };
    })
    .addCase(extendTagDetailsContent, (state, { payload }) => {
      if (state.detailsTab.type === 'tag-edit' || state.detailsTab.type === 'tag-view') {
        switch (payload.module) {
          case 'systems':
            state.detailsTab.data = {
              ...state.detailsTab.data,
              systemsItems: payload.data,
            };
            break;

          case 'keys':
            state.detailsTab.data = {
              ...state.detailsTab.data,
              keysItems: payload.data,
            };
            break;

          case 'policies':
            state.detailsTab.data = {
              ...state.detailsTab.data,
              policiesItems: payload.data,
            };
            break;

          case 'dnsRecords':
            state.detailsTab.data = {
              ...state.detailsTab.data,
              dnsRecordsItems: payload.data,
            };
            break;

          case 'dnsZones':
            state.detailsTab.data = {
              ...state.detailsTab.data,
              dnsZonesItems: payload.data,
            };
            break;

          default:
            break;
        }
      }
    });
};
