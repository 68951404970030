// components
import { Container } from './styled';
import { CopyTool } from 'components';

// hooks
import { css } from 'styled-components';
import { useCopy } from 'hooks/useCopy';

// types
import { TypeWithDefaultDivProps } from 'types';

export interface ICopy {
  children: React.ReactNode;
  copyingValue: string;
  visible: boolean;
  width?: string;
  height?: string;
}

export const Copy = ({
  children,
  copyingValue,
  visible,
  height,
  width,
  ...rest
}: TypeWithDefaultDivProps<ICopy>) => {
  const { copied, onCopyClick, setCopied } = useCopy({ copyingValue });

  return (
    <Container height={height} width={width} {...rest}>
      {children}
      {visible ? (
        <CopyTool
          css={css`
            margin-left: 0.75rem;
          `}
          secondary={true}
          copied={copied}
          onCopyClick={onCopyClick}
          onMouseLeave={() => setCopied(false)}
        />
      ) : null}
    </Container>
  );
};
