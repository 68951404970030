import { pick } from 'ramda';
import { IEnrolmentStoredKey, IUpdateEnrolmentKeyParams } from 'types';

export const demodifyEnrolmmentKeyDetails = (
  enrolmentKey: IEnrolmentStoredKey
): IUpdateEnrolmentKeyParams => {
  const result = {
    ...enrolmentKey,

    ipConstraints: enrolmentKey?.ipConstraints?.map(constraint => ({
      range: constraint.range as string,
      description: constraint.description as string,
    })),

    tags: enrolmentKey?.tags?.map(({ tag }) => tag),
  };

  const updatedParams: IUpdateEnrolmentKeyParams = pick(
    [
      'description',
      'approvalMode',
      'isEnabled',
      'usesRemaining',
      'disconnectedRetentionMinutes',
      'notes',
      'ipConstraints',
      'tags',
      'autoExpire',
    ],
    result
  );

  return updatedParams;
};
