import styled from 'styled-components';

export const RecordsContainer = styled.div`
  margin-top: 1rem;
`;

export const FilterAndBulkActionsContainer = styled.div`
  height: 2.5rem;
  margin-bottom: 0.75rem;
`;
