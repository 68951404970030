// libs
import { memo } from 'react';
import styled from 'styled-components';
import { css, useTheme } from 'styled-components';

// components
import { CustomText } from 'typography/Text';
import Skeleton from 'react-loading-skeleton';

// types
import { IIconState, ITrustRequirementBasic } from 'types';
import { ControllableTrustRequirementIcon } from 'components';

interface IDescription {
  type: ITrustRequirementBasic['type'];
  description: ITrustRequirementBasic['description'];
  state: IIconState;
  isLoading: boolean;
}

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

export const Description = memo(({ type, description, isLoading, state }: IDescription) => {
  const theme = useTheme();

  return (
    <>
      {isLoading ? (
        <Skeleton />
      ) : (
        <DescriptionContainer>
          <ControllableTrustRequirementIcon type={type} state={state} size="large" />
          <CustomText
            data-test="trust-requirements-table-description-column"
            css={css`
              display: block;
              margin-left: 2rem;
            `}
            type="body-2"
            color={theme.palette.fonts.bodyDark}
            ellipsis={true}
            title={description}
          >
            {description}
          </CustomText>
        </DescriptionContainer>
      )}
    </>
  );
});
