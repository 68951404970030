// libs
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';

// components
import { IconLink } from 'assets/svg';
import { Details } from 'components';
import { ConnectionsBlock } from '../../Blocks';
import { CustomText } from 'typography/Text';

// hooks
import { useTranslations } from 'hooks/useTranslations';

// selectors
import { selectAppDetailsTabType } from 'redux/selectors';

const ConnectionsSection = styled.section<{ readonly: boolean }>`
  display: flex;
  flex-direction: column;
  background-color: ${({ readonly, theme }) => readonly && theme.palette.background.base};
  margin-left: -2.5rem;
  padding-left: 2.5rem;
  padding-right: calc(
    0px - calc(2.5rem - (${({ theme }) => theme.layout_sizes.detailsWide} - 100%))
  );
  margin-right: calc(2.5rem - (${({ theme }) => theme.layout_sizes.detailsWide} - 100%));
  padding-top: 2rem;
  padding-bottom: 2.3rem;
`;

export const Connections = () => {
  const { translate } = useTranslations('tags');
  const type = useSelector(selectAppDetailsTabType);

  const readonly = type === 'tag-edit' || type === 'tag-create';

  return (
    <ConnectionsSection readonly={readonly}>
      <Details.SectionHeader
        Icon={IconLink}
        title={translate('details.connections.title')}
        info={
          readonly ? (
            <CustomText
              type="hint"
              css={css`
                margin-left: 1rem;
              `}
            >
              {translate('details.connections.info')}
            </CustomText>
          ) : null
        }
        css={css`
          margin-bottom: 1.625rem;
        `}
      />
      <ConnectionsBlock />
    </ConnectionsSection>
  );
};
