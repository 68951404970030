import {
  ISystemReferenceModel,
  ITag,
  IUsedTrustRequirement,
  IValidationState,
  TagReference,
} from 'types';
import {
  ENROLMENT_KEYS_MODULE,
  POLICIES_MODULE,
  SYSTEMS_MODULE,
  USYSTEMS_MODULE,
  TAGS_MODULE,
  TRUST_REQUIREMENTS_MODULE,
} from 'appConstants';
import {
  IconNoFoundSystems,
  IconNoFoundUSystems,
  IconNoFoundEnrolmentKey,
  IconNoFoundPolicy,
  IconNoFoundTags,
  IconNoFoundTrustRequirements,
} from 'assets/svg';

export const tagsToStrings = (arr: TagReference[]) => arr.map(({ tag }) => tag);

export const systemsToStrings = (arr: ISystemReferenceModel[]) => arr.map(({ id }) => id);

export const trustRequirementsToNumbers = (arr: IUsedTrustRequirement[]) => arr.map(({ id }) => id);

export const tagsToObjects = (arr: ITag['tag'][]) => arr.map(tag => ({ tag }));

export const noErrorsInDetails = (validationState?: IValidationState) =>
  validationState &&
  Object.values(validationState).every(
    entry => !entry.state.error || entry.state.error.critical === false || entry.active === false
  );

export const hasJsonParsingError = (errors: Record<string, string> | undefined) => {
  if (!errors) return false;
  return Object.keys(errors)?.some((errorKey: string) => {
    return errorKey.startsWith('$');
  });
};

export const getNoDataIcon = (module: string) => {
  switch (module) {
    case SYSTEMS_MODULE:
      return <IconNoFoundSystems />;
    case USYSTEMS_MODULE:
      return <IconNoFoundUSystems />;
    case POLICIES_MODULE:
      return <IconNoFoundPolicy />;
    case ENROLMENT_KEYS_MODULE:
      return <IconNoFoundEnrolmentKey />;
    case TAGS_MODULE:
      return <IconNoFoundTags />;
    case TRUST_REQUIREMENTS_MODULE:
      return <IconNoFoundTrustRequirements />;
    default:
      return <IconNoFoundSystems />;
  }
};
