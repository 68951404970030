// components
import { Status } from '../../../Status';

// hooks
import { useSelector } from 'react-redux';

// selectors
import { selectAppDetailsPolicyIsEnabled } from 'redux/selectors';

export const StateView = () => {
  const isEnabled = useSelector(selectAppDetailsPolicyIsEnabled);

  {
    return <Status isEnabled={isEnabled} />;
  }
};
