import styled, { css } from 'styled-components';

export const SubnetFilterContainer = styled.div`
  display: flex;
  margin-top: 2.25rem;
  flex-direction: column;
`;

export const tableCss = css`
  td {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
    vertical-align: text-top;
  }

  cursor: default;

  tbody tr {
    cursor: default;
  }
`;
