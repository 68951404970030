// components
import { Description } from './Description';
import { TrustRequirementActionDropdown } from './ActionDropdown';
import { ColumnTitle } from 'components';
import { Policies } from './Policies';
import { Tags } from './Tags';
import { Summary } from './Summary';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { useSelector } from 'react-redux';

// selectors
import { selectTrustRequirementsIsLoading } from 'redux/selectors';

// types
import { ITrustRequirementBasic } from 'types';

interface IEnrolledKeysCols {
  isLoading: boolean;

  bulkSelectedTrustRequirements: ITrustRequirementBasic[];
  selectedTrustRequirementId?: ITrustRequirementBasic['id'];
}

export const useTrustRequirementCols = ({
  bulkSelectedTrustRequirements,
  selectedTrustRequirementId,
}: IEnrolledKeysCols) => {
  const { translate } = useTranslations('trust-requirements');
  const isLoading = useSelector(selectTrustRequirementsIsLoading);

  return [
    {
      title: <ColumnTitle>{translate('description')}</ColumnTitle>,
      dataIndex: 'description',
      render: (
        description: ITrustRequirementBasic['description'],
        record: ITrustRequirementBasic
      ) => {
        const isSelected =
          bulkSelectedTrustRequirements.find(
            trustRequirement => trustRequirement.id === record.id
          ) || selectedTrustRequirementId === record.id;

        const state = isSelected ? 'active' : 'muted';

        return (
          <Description
            description={description}
            type={record.type}
            state={state}
            isLoading={isLoading}
          />
        );
      },
    },
    {
      title: <ColumnTitle>{translate('summary')}</ColumnTitle>,
      dataIndex: 'summary',
      render: (summary: ITrustRequirementBasic['summary']) => {
        return <Summary summary={summary} isLoading={isLoading} />;
      },
    },
    {
      title: <ColumnTitle>{translate('policies')}</ColumnTitle>,
      dataIndex: 'usedInPolicies',
      render: (usedInPolicies: ITrustRequirementBasic['usedInPolicies']) => {
        return <Policies policies={usedInPolicies} isLoading={isLoading} />;
      },
    },
    {
      title: <ColumnTitle>{translate('tags')}</ColumnTitle>,
      dataIndex: 'usedInTags',
      render: (usedInTags: ITrustRequirementBasic['usedInTags']) => {
        return <Tags tags={usedInTags} isLoading={isLoading} />;
      },
    },
    {
      title: '',
      dataIndex: '',
      key: 'x',
      render: (record: ITrustRequirementBasic) => (
        <TrustRequirementActionDropdown
          id={record.id}
          description={record.description}
          isLoading={isLoading}
        />
      ),
    },
  ];
};
