// components
import { DefaultSuggestions } from './DefaultSuggestions';
import { EnrolmentKeySuggestions } from './EnrolmentKeySuggestions';
import { IpAddressSuggestions } from './IpAddressSuggestions';
import { SystemSuggestions } from './SystemSuggestions';
import { TagSuggestions } from './TagSuggestions';
import { SearchModifierSuggestions } from './SearchModifierSuggestions';

// hooks
import { useCallback } from 'react';

// types
import { ISearchKey } from 'types';
import { ISearchQueryDetail, ISearchValue } from '../helpers/parseSearchString';
import { IInputArrowConfig, ISelectedIndex } from '..';

interface ISuggestions {
  searchKey: ISearchKey;
  searchValue: string;
  activeKeyValue: ISearchValue | null;
  setSearchValue: (searchValue: string) => void;
  setSelectionRange: (index: number) => void;
  keyConfig: ISearchQueryDetail | null | undefined;
  setInputArrowNavigationConfig: (config: IInputArrowConfig) => void;
  selectedIndex: ISelectedIndex;
}

export const Suggestions = ({
  searchKey,
  searchValue,
  activeKeyValue,
  setSearchValue,
  keyConfig,
  setSelectionRange,
  setInputArrowNavigationConfig,
  selectedIndex,
}: ISuggestions) => {
  // adds value to search input
  const addValue = useCallback(
    (newValue: string) => {
      const wrapInQuotes = newValue.includes(' ');

      let selectionRange = 0;

      let left = '';
      let right = '';

      if (activeKeyValue && keyConfig?.keyRange) {
        if (searchKey.modifiers && searchKey.dataType !== 'Tags') {
          if (!keyConfig.searchModifier) {
            left = searchValue.slice(0, activeKeyValue.range.start);
            right = searchValue.slice(activeKeyValue.range.end);

            selectionRange = activeKeyValue.range.start + newValue.length;
          }

          // if we have searchModifer then we need to ignore it, we don't want to replace it
          if (keyConfig.searchModifier) {
            left = searchValue.slice(0, activeKeyValue.range.start + 1);
            right = searchValue.slice(activeKeyValue.range.end + 1);

            selectionRange = activeKeyValue.range.start + 1 + newValue.length;
          }

          setSearchValue(`${left}${wrapInQuotes ? `"${newValue}"` : newValue}${right}`);
          setSelectionRange(wrapInQuotes ? selectionRange + 2 : selectionRange);

          return;
        }

        if (!searchKey.canHaveMultiple) {
          left = searchValue.slice(0, activeKeyValue.range.start);
          right = searchValue.slice(activeKeyValue.range.end);

          selectionRange = activeKeyValue.range.start + newValue.length;
        }

        if (searchKey.canHaveMultiple) {
          const conditionalComma = searchValue[activeKeyValue.range.end] === ',' ? '' : ',';

          left = searchValue.slice(0, activeKeyValue.range.start);
          right = conditionalComma + searchValue.slice(activeKeyValue.range.end);

          // we need to set selection range after comma, that's why we add 1
          selectionRange = activeKeyValue.range.start + newValue.length + 1;
        }

        setSearchValue(`${left}${wrapInQuotes ? `"${newValue}"` : newValue}${right}`);
        setSelectionRange(wrapInQuotes ? selectionRange + 2 : selectionRange);
      }
    },
    [
      activeKeyValue,
      keyConfig?.keyRange,
      keyConfig?.searchModifier,
      searchKey?.canHaveMultiple,
      searchKey?.modifiers,
      searchKey?.dataType,
      searchValue,
      setSearchValue,
      setSelectionRange,
    ]
  );

  if (!keyConfig) return null;

  if (searchKey.hintValues) {
    return (
      <DefaultSuggestions
        canHaveMultiple={searchKey.canHaveMultiple}
        keyName={searchKey.name}
        existingValues={keyConfig.values}
        values={searchKey.hintValues}
        range={activeKeyValue !== null ? activeKeyValue.range : null}
        addValue={addValue}
        setInputArrowNavigationConfig={setInputArrowNavigationConfig}
        selectedIndex={selectedIndex}
      />
    );
  }
  if (searchKey.dataType === 'EnrolmentKey') {
    return (
      <EnrolmentKeySuggestions
        canHaveMultiple={searchKey.canHaveMultiple}
        keyName={searchKey.name}
        existingValues={keyConfig.values}
        searchValue={activeKeyValue !== null ? activeKeyValue.value : null}
        range={activeKeyValue !== null ? activeKeyValue.range : null}
        addValue={addValue}
        setInputArrowNavigationConfig={setInputArrowNavigationConfig}
        selectedIndex={selectedIndex}
      />
    );
  }

  if (searchKey.dataType === 'Tags') {
    return (
      <TagSuggestions
        canHaveMultiple={searchKey.canHaveMultiple}
        keyName={searchKey.name}
        existingValues={keyConfig.values}
        searchValue={activeKeyValue !== null ? activeKeyValue.value : null}
        range={activeKeyValue !== null ? activeKeyValue.range : null}
        addValue={addValue}
        setInputArrowNavigationConfig={setInputArrowNavigationConfig}
        selectedIndex={selectedIndex}
      />
    );
  }

  if (searchKey.dataType === 'System') {
    return (
      <SystemSuggestions
        canHaveMultiple={searchKey.canHaveMultiple}
        keyName={searchKey.name}
        existingValues={keyConfig.values}
        searchValue={activeKeyValue !== null ? activeKeyValue.value : null}
        range={activeKeyValue !== null ? activeKeyValue.range : null}
        addValue={addValue}
        setInputArrowNavigationConfig={setInputArrowNavigationConfig}
        selectedIndex={selectedIndex}
      />
    );
  }

  if (searchKey.modifiers) {
    return (
      <SearchModifierSuggestions
        keyName={searchKey.name}
        modifiers={searchKey.modifiers}
        range={activeKeyValue !== null ? activeKeyValue.range : null}
        addValue={addValue}
        searchModifier={keyConfig?.searchModifier || null}
        setInputArrowNavigationConfig={setInputArrowNavigationConfig}
        selectedIndex={selectedIndex}
      />
    );
  }

  if (searchKey.name === 'ip') {
    return (
      <IpAddressSuggestions
        canHaveMultiple={searchKey.canHaveMultiple}
        keyName={searchKey.name}
        existingValues={keyConfig.values}
        range={activeKeyValue !== null ? activeKeyValue.range : null}
        addValue={addValue}
        setInputArrowNavigationConfig={setInputArrowNavigationConfig}
        selectedIndex={selectedIndex}
      />
    );
  }
  if (searchKey.name === 'vip') {
    return (
      <IpAddressSuggestions
        canHaveMultiple={searchKey.canHaveMultiple}
        keyName={searchKey.name}
        existingValues={keyConfig.values}
        range={activeKeyValue !== null ? activeKeyValue.range : null}
        addValue={addValue}
        setInputArrowNavigationConfig={setInputArrowNavigationConfig}
        selectedIndex={selectedIndex}
      />
    );
  }
  return null;
};
