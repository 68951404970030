// components
import { StyledSubtitle } from 'typography/Text';
import { BlockWrapper, ContentWrapper, CustomTextStyled } from 'modules/Account/styled';
import { CustomStyledSubtitle, OptionWrapper } from './styled';
import { CustomToggle } from 'components';

// hooks
import { useDispatch, useSelector } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';
import { css, useTheme } from 'styled-components';

// selectors
import {
  selectAccount2FAEnabled,
  selectAppIsLoading,
  selectAccountIsStepUpValid,
} from 'redux/selectors';

// actions
import { openEnable2FAModal } from 'redux/actions/account';
import { setModalContent } from 'redux/actions/app';
import { EmailNotificationToggle } from '../EmailNotificationToggle';

export const SettingsBlockLocal = () => {
  const dispatch = useDispatch();

  const { translate } = useTranslations('account');

  const {
    palette: { fonts },
  } = useTheme();

  const is2FAOn = useSelector(selectAccount2FAEnabled);
  const isLoading = useSelector(selectAppIsLoading);
  const stepUpIsOk = useSelector(selectAccountIsStepUpValid);

  const open2FAChangeModal = async () => {
    if (stepUpIsOk) {
      if (is2FAOn) {
        dispatch(setModalContent({ type: 'disable-2FA', data: null }));
      } else {
        dispatch(openEnable2FAModal());
      }
    } else {
      if (is2FAOn) {
        dispatch(
          setModalContent({
            type: 're-auth-request',
            data: null,
            nextModal: 'disable-2FA',
          })
        );
      } else {
        dispatch(
          setModalContent({
            type: 're-auth-request',
            data: null,
            nextModal: 'enable-2FA',
          })
        );
      }
    }
  };

  return (
    <BlockWrapper>
      <ContentWrapper justify="flex-start">
        <StyledSubtitle>{translate('settings')}</StyledSubtitle>
      </ContentWrapper>
      <div>
        <ContentWrapper mb="4.375rem">
          <OptionWrapper>
            <CustomStyledSubtitle color={fonts.bodyLight}>
              {translate('two_factor_auth')}
            </CustomStyledSubtitle>
            <CustomToggle
              css={css`
                margin-right: 0.5rem;
              `}
              aria-label={translate('aria_labels.switch_two_factor_auth')}
              labelLeft
              enabled={!!is2FAOn}
              loading={isLoading}
              label={is2FAOn ? 'On' : 'Off'}
              labelType="sub-heading-2"
              onClick={open2FAChangeModal}
            />
          </OptionWrapper>
          <CustomTextStyled type="body-2" color={fonts.bodyLight}>
            {translate('two_factor_desc')}
          </CustomTextStyled>
        </ContentWrapper>
        <ContentWrapper>
          <OptionWrapper>
            <CustomStyledSubtitle color={fonts.bodyLight}>
              {translate('email_notification')}
            </CustomStyledSubtitle>
            <EmailNotificationToggle />
          </OptionWrapper>

          <CustomTextStyled type="body-2" color={fonts.bodyLight}>
            {translate('email_notification_description')}
          </CustomTextStyled>
        </ContentWrapper>
      </div>
    </BlockWrapper>
  );
};
