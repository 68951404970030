// components
import { StyledGroup, StyledButton, StyledOptionWrapper } from './styled';
import { CustomText } from 'typography/Text';

// hooks
import { useCallback } from 'react';
import { useTranslations } from 'hooks/useTranslations';
import { useDispatch, useSelector } from 'react-redux';
import styled, { useTheme } from 'styled-components';

// selectors
import { selectAppDetailsSystemAutoExpireAction } from 'redux/selectors';

// actions
import { updateSystemDetailsAutoExpire } from 'redux/actions/app';

// types
import { RadioChangeEvent } from 'antd';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const ExpirationActionEdit = () => {
  const dispatch = useDispatch();

  const { translate } = useTranslations('systems');

  const {
    palette: { fills },
  } = useTheme();

  const selectedAction = useSelector(selectAppDetailsSystemAutoExpireAction);

  const displayedAction = selectedAction || 'Disable';

  const handleExpirationActionChange = useCallback(
    (event: RadioChangeEvent) => {
      const newAction = event.target.value;
      dispatch(updateSystemDetailsAutoExpire({ expiryAction: newAction }));
    },
    [dispatch]
  );

  return (
    <Container>
      <StyledGroup defaultValue={displayedAction} onChange={handleExpirationActionChange}>
        <StyledButton value="Disable">
          <StyledOptionWrapper data-test="system-details-select-expiration-action">
            <CustomText type="body-2" color={displayedAction === 'Disable' ? fills.primary : ''}>
              {translate('details.auto_expire.expiration_action.options.disable')}
            </CustomText>
          </StyledOptionWrapper>
        </StyledButton>
        <StyledButton value="Delete">
          <StyledOptionWrapper data-test="system-details-select-expiration-action">
            <CustomText type="body-2" color={displayedAction === 'Delete' ? fills.primary : ''}>
              {translate('details.auto_expire.expiration_action.options.remove')}
            </CustomText>
          </StyledOptionWrapper>
        </StyledButton>
      </StyledGroup>
    </Container>
  );
};
