// libs
import { memo, SyntheticEvent, useCallback } from 'react';

// components
import Skeleton from 'react-loading-skeleton';
import { ActionDropdown } from 'components';

// hooks
import { useDispatch } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';

// actions
import {
  setModalContent,
  createTrustRequirementSideMenu,
  loadTrustRequirementDetails,
} from 'redux/actions/app';

// types
import { ITrustRequirementBasic } from 'types';

interface IActionDropdown {
  id: ITrustRequirementBasic['id'];
  description: ITrustRequirementBasic['description'];
  isLoading: boolean;
}

export const TrustRequirementActionDropdown = memo(
  ({ id, description, isLoading }: IActionDropdown) => {
    const dispatch = useDispatch();

    const { translate } = useTranslations('trust-requirements');

    const onRemove = useCallback(() => {
      dispatch(
        setModalContent({
          type: 'remove-trust-requirements',
          data: {
            requirements: [
              {
                id: id,
                name: description,
              },
            ],
          },
        })
      );
    }, [description, dispatch, id]);

    const onEditClick = () => {
      dispatch(loadTrustRequirementDetails({ requirementId: String(id), mode: 'edit' }));
    };

    const onDuplicateClick = () => {
      dispatch(createTrustRequirementSideMenu({ requirementId: String(id) }));
    };

    return (
      <>
        {isLoading ? (
          <Skeleton />
        ) : (
          <ActionDropdown
            data-test="trust-requirements-table-action-dropdown-column"
            onContainerClick={(event: SyntheticEvent) => event.stopPropagation()}
            data={[
              {
                text: translate('table_actions.edit'),
                onClick: onEditClick,
              },
              {
                text: translate('table_actions.duplicate'),
                onClick: onDuplicateClick,
              },
              {
                text: translate('table_actions.remove'),
                onClick: onRemove,
              },
            ]}
          />
        )}
      </>
    );
  }
);
