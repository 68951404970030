// libs
import { all, put } from 'typed-redux-saga';

// actions
import { setDetailsContent } from 'redux/actions/app';

// types
import { IActionPayload, ILoadSystemDetails, IDetailsSystemView, IDetailsSystemEdit } from 'types';

// utils
import { ApiTypeCaller } from '../../api/utils';
import { modifySystemDetails } from 'utils';

// constants
import { SYSTEM_EDIT_INITIAL_VALIDATION_STATE } from 'appConstants';

export function* loadSystemDetailsSaga({
  payload: { systemId, mode = 'view', scrollToAutoExpire },
}: IActionPayload<ILoadSystemDetails>) {
  const { result, success } = yield* ApiTypeCaller('getSystem', { systemId });

  if (success && result) {
    const updatedResult = modifySystemDetails(result);

    const tabData: IDetailsSystemView | IDetailsSystemEdit =
      mode === 'edit'
        ? {
            type: 'system-edit',
            data: updatedResult,
            dataNew: updatedResult,
            isLoading: false,
            scrollToAutoExpire,
            validationState: SYSTEM_EDIT_INITIAL_VALIDATION_STATE,
          }
        : { type: 'system-view', data: updatedResult, dataNew: null, isLoading: false };

    yield all([put(setDetailsContent(tabData))]);
  }
}
