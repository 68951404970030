import { ISearchKey } from 'types';
import { equals } from 'ramda';
import { charToSearchModifier } from './charToSearchModifier';
import { ISearchModifier } from 'types/search';

enum ParserState {
  Starting = 'Starting',
  WaitingForKey = 'WaitingForKey',
  LookingForKeyValue = 'LookingForKeyValue',
  LookingForNextListValue = 'LookingForNextListValue',
  LookingForModifierDetails = 'LookingForModifierDetails',
  LookingForEncapsulatedText = 'LookingForEncapsulatedText',
  LookingForDefaultValues = 'LookingForDefaultValues',
}

export type IRange = { start: number; end: number };

export type ISearchValue = { range: IRange; value: string };

interface IToken {
  range: IRange;
  type: 'text' | 'whitespace' | 'keyWithColon' | 'comma' | 'modifier' | 'doubleQuotes';
  used: boolean;
}

export interface ISearchQueryDetail {
  values: ISearchValue[];
  searchModifier: ISearchModifier | null;
  isDefault: boolean;
  useExactMatch: boolean;
  keyRange: IRange | null;
  totalRange: IRange | null;
}

interface IGetPositionAfterCurrentRange {
  activeRange: IRange;
}
const getPositionAfterCurrentRange = ({ activeRange }: IGetPositionAfterCurrentRange): IRange => {
  return { start: activeRange.end, end: activeRange.end + 1 };
};

interface IGetTokens {
  search: string;
}

const getTokens = ({ search }: IGetTokens): IToken[] => {
  const tokens: IToken[] = [];
  let activeRange: IRange = { start: 0, end: 1 };

  for (let i = 0; i < search.length; i++) {
    if (search[i] === ' ') {
      // this will ignore whitespace at the current index so we can instead get just the text
      activeRange.end = i;

      // Get the current value so we can check if we have text or if it's just whitespace.
      const currentValue = search.substring(activeRange.start, activeRange.end);

      // Get the existing token if it already exists it shouldn't but best to be sure
      const existing = tokens.find(token => equals(token.range, activeRange)) || null;

      // check if this range is already in the list and that the value isn't just whitespace
      if (existing === null && currentValue !== null && currentValue.trim() !== '') {
        tokens.push({ range: activeRange, type: 'text', used: false });
      }

      activeRange = getPositionAfterCurrentRange({ activeRange });

      // Add token to the list.
      tokens.push({ range: activeRange, type: 'whitespace', used: false });

      activeRange = getPositionAfterCurrentRange({ activeRange });
    } else if (search[i] === ':') {
      tokens.push({ range: activeRange, type: 'keyWithColon', used: false });
      activeRange = getPositionAfterCurrentRange({ activeRange });
    } else if (search[i] === ',') {
      // this will ignore whitespace at the current index
      activeRange.end = i;

      const currentValue = search.substring(activeRange.start, activeRange.end);
      // We've hit whitespace so add the current range to the tokens
      const existing = tokens.find(token => equals(token.range, activeRange)) || null;

      if (existing === null && currentValue !== null && currentValue.trim() !== '') {
        tokens.push({ range: activeRange, type: 'text', used: false });
      }

      activeRange = getPositionAfterCurrentRange({ activeRange });

      // Add token to the list.
      tokens.push({ range: activeRange, type: 'comma', used: false });

      activeRange = getPositionAfterCurrentRange({ activeRange });
    } else if (charToSearchModifier({ char: search[i] })) {
      tokens.push({ range: activeRange, type: 'modifier', used: false });

      activeRange = getPositionAfterCurrentRange({ activeRange });
    } else if (search[i] === '"') {
      // this will ignore whitespace at the current index
      activeRange.end = i;

      const currentValue = search.substring(activeRange.start, activeRange.end);
      // We've hit whitespace so add the current range to the tokens
      const existing = tokens.find(token => equals(token.range, activeRange)) || null;
      // check if this range is already in the list and that the value isn't just whitespace
      if (existing === null && currentValue !== null && currentValue.trim() !== '') {
        tokens.push({ range: activeRange, type: 'text', used: false });
      }

      activeRange = getPositionAfterCurrentRange({ activeRange });

      tokens.push({ range: activeRange, type: 'doubleQuotes', used: false });
      activeRange = getPositionAfterCurrentRange({ activeRange });
    } else {
      // Move end cursor by one
      activeRange.end = activeRange.end + 1;

      // if we're at the end of the loop
      if (i === search.length - 1) {
        // Set active range to be inclusive to end of span

        activeRange.end = search.length;
        tokens.push({ range: activeRange, type: 'text', used: false });
      }
    }
  }
  return tokens;
};

interface IParse {
  search: string;
  searchKeys: ISearchKey[];
  tokens: IToken[];
}

export interface ISearchQueryDetails {
  [key: string]: ISearchQueryDetail;
}

const parse = ({ search, searchKeys, tokens }: IParse) => {
  const searchQueryDetails: ISearchQueryDetails = {};

  // Token index not string index
  let consumedIndex = 0;

  let currentState = ParserState.Starting;

  let currentValues: ISearchValue[] = [];
  let currentKey: ISearchKey | null = null;
  let searchModifier: ISearchModifier | null = null;
  let searchKeyRange: IRange | null = null;

  // equal to so we can do the final run.
  while (consumedIndex <= tokens.length) {
    const safeIndex = consumedIndex === tokens.length ? consumedIndex - 1 : consumedIndex;

    const token = tokens[safeIndex];

    // Adjust for array size and set to null if no token is present.
    const nextToken = safeIndex === tokens.length - 1 ? null : tokens[safeIndex + 1];

    // get the value of the current token
    const value = search.substring(token.range.start, token.range.end);

    switch (currentState) {
      case ParserState.Starting: {
        // do we have search keys if so let's continue to waiting for key
        const hasSearchKeys = tokens.some(token => token.type === 'keyWithColon');
        if (hasSearchKeys) {
          // Start looking for keys
          currentState = ParserState.WaitingForKey;
        } else {
          // Get all the default search keys however there should only ever be one
          const defaultKeys = searchKeys.filter(searchKey => searchKey.isDefault);

          if (defaultKeys.length !== 1) {
            throw Error('Invalid set of keys only one Search Key can be marked as default.');
          }

          // Get the first key then change the state.
          currentKey = defaultKeys[0];
          currentState = ParserState.LookingForDefaultValues;
        }
        break;
      }
      case ParserState.WaitingForKey: {
        // Reset all cached fields;
        currentValues = currentValues.length > 0 ? [] : currentValues;
        currentKey = null;
        searchKeyRange = null;
        searchModifier = null;

        // Do we have text or double quotes instead of a KeyWithColon.
        // we've found a default key move to LookingForDefaultValues.
        if (token.type == 'text' || token.type == 'doubleQuotes') {
          const defaultKeys = searchKeys.filter(searchKey => searchKey.isDefault);
          if (defaultKeys.length != 1) {
            throw Error('Invalid set of keys only one Search Key can be marked as default.');
          }

          currentKey = defaultKeys[0];
          searchKeyRange = token.range;
          currentState = ParserState.LookingForDefaultValues;
          continue;
        }

        consumedIndex++;

        // Keep going until we find a token of type KeyWithColon
        if (token.type !== 'keyWithColon') {
          continue;
        }

        for (let i = 0; i < searchKeys.length; i++) {
          const trimmedRange = { start: token.range.start, end: token.range.end - 1 };
          if (
            equals(
              search.substring(trimmedRange.start, trimmedRange.end).toLowerCase(),
              searchKeys[i].name.toLowerCase()
            )
          ) {
            searchKeyRange = token.range;
            currentKey = searchKeys[i];
            break;
          }
        }

        if (currentKey === null) {
          // Not a valid key let's ignore the next text token.
          // This does not account for modifiers but should handle most common cases.
          if (nextToken?.type === 'text') {
            token.used = true;
            nextToken.used = true;
            consumedIndex++;
          }

          continue;
        }

        AddOrUpdateQueryDetails({
          searchQueryDetails,
          currentValues,
          currentKey,
          searchModifier,
          keyRange: searchKeyRange,
        });

        // If the next token is a modifier let's go to the modifier step if not we'll just go and grab the value
        if (nextToken?.type === 'modifier') {
          currentState = ParserState.LookingForModifierDetails;
        } else if (nextToken?.type === 'doubleQuotes') {
          currentState = ParserState.LookingForEncapsulatedText;
        } else {
          currentState = ParserState.LookingForKeyValue;
        }

        token.used = true;
        break;
      }
      case ParserState.LookingForKeyValue: {
        consumedIndex++;

        // This accounts for malformed search strings without values on multiple search keys
        if (token.type === 'whitespace' && nextToken?.type === 'keyWithColon') {
          currentState = ParserState.WaitingForKey;
          token.used = true;
          continue;
        }

        // this is to account for blank whitespace between a key and it's value
        // set it to used so it doesn't get included in the description
        if (token.type === 'whitespace') {
          token.used = true;
          continue;
        }

        // If the key is null continue the loop, this is unlikely to happen.
        if (currentKey === null) {
          currentState = ParserState.WaitingForKey;
          continue;
        }

        // Found text let's add it to the list of current values.
        if (token.type === 'text') {
          currentValues.push({ value, range: token.range });
          token.used = true;
        }

        // if the key can have multiple values let's move to the LookingForNextListValue state. We also check we have a nextToken
        if (currentKey.canHaveMultiple && nextToken !== null) {
          currentState = ParserState.LookingForNextListValue;
          continue;
        }

        // Add the current key value to the query details. As we can't have multiple values.
        AddOrUpdateQueryDetails({
          searchQueryDetails,
          currentValues,
          currentKey,
          searchModifier,
          keyRange: searchKeyRange,
        });

        // back to waiting for a key
        currentState = ParserState.WaitingForKey;
        token.used = true;
        break;
      }
      case ParserState.LookingForNextListValue: {
        consumedIndex++;

        // if the current token is white space or the next token is white space we can add the list to SearchQueryDetails
        if (token.type === 'whitespace' || nextToken === null || nextToken.type === 'whitespace') {
          // Has this token not been added before and is it of type Text?
          // we add it to the current values text.
          if (!token.used && token.type == 'text') {
            currentValues.push({ value, range: token.range });
          }

          // Do we have any current values? let's add to the searchQueryDetails list.
          if (currentValues.length > 0 && currentKey !== null) {
            AddOrUpdateQueryDetails({
              searchQueryDetails,
              currentValues,
              currentKey,
              searchModifier,
              keyRange: searchKeyRange,
            });
          }

          // Move back to waiting for key.
          currentState = ParserState.WaitingForKey;
          token.used = true;
          continue;
        }

        // If it's just a text let's add it to the current values and loop back around.
        if (token.type === 'text') {
          currentValues.push({ value, range: token.range });
        }

        token.used = true;
        break;
      }
      case ParserState.LookingForModifierDetails: {
        consumedIndex++;
        // Token isn't a modifier? let's reset the state to WaitingForKey
        if (token.type !== 'modifier') {
          currentState = ParserState.WaitingForKey;
          continue;
        }

        // Is the modifier present valid for this search key?
        searchModifier = charToSearchModifier({ char: value });

        if (!searchModifier) {
          console.warn('Search modifier not found');
        } else if (
          currentKey?.modifiers === null ||
          !currentKey?.modifiers?.includes(searchModifier)
        ) {
          console.warn(`${value} is not an appropriate modifier for {currentKey.Name}`);
        }

        if (currentKey) {
          // If a search key is defined and a modifier is present we should add to it anyway
          AddOrUpdateQueryDetails({
            searchQueryDetails,
            currentValues,
            currentKey,
            searchModifier,
            keyRange: searchKeyRange,
          });
        }

        token.used = true;
        currentState = ParserState.LookingForKeyValue;
        break;
      }
      case ParserState.LookingForEncapsulatedText: {
        consumedIndex++;
        if (currentKey === null) {
          currentState = ParserState.WaitingForKey;
          continue;
        }

        // Return a query detail with an empty value (for uncompleted encapsulated strings)
        // such as 'searchkey:"'
        if (consumedIndex >= tokens.length) {
          AddOrUpdateQueryDetails({
            searchQueryDetails,
            currentValues: [],
            currentKey,
            searchModifier,
            keyRange: searchKeyRange,
          });
          currentState = ParserState.WaitingForKey;
          continue;
        }

        // Setup some default values.
        let lookingForValues = true;
        let readIndex = consumedIndex;
        const quoteRange: IRange = { start: token.range.start, end: token.range.start };

        if (
          nextToken !== null &&
          token.type === 'doubleQuotes' &&
          nextToken.type !== 'doubleQuotes'
        ) {
          quoteRange.start = nextToken.range.start;
          quoteRange.end = nextToken.range.start;
        }

        let readToken: IToken | null = null;

        // Let's lookahead until we find another Double quote.
        while (lookingForValues) {
          // We've reached the last token set the range to include the end.

          if (readIndex === tokens.length) {
            quoteRange.end = search.length;
          } else {
            readToken = tokens[readIndex];
            if (readToken.type === 'text' || readToken.type === 'whitespace') {
              quoteRange.end = readToken.range.end;
            }

            readToken.used = true;
          }

          // We've found a double quotes let's add it to the list and stop the loop.
          // or if we've reached the end let's treat the string as an encapsulated one.

          if (readToken?.type === 'doubleQuotes' || readIndex === tokens.length) {
            lookingForValues = false;
            const value = search.substring(quoteRange.start, quoteRange.end);
            if (readIndex !== tokens.length) {
              quoteRange.end++; // include trailing double quote in range
            }
            const encapsulatedValues: ISearchValue[] = [
              {
                value,
                range: quoteRange,
              },
            ];

            AddOrUpdateQueryDetails({
              searchQueryDetails,
              currentValues: encapsulatedValues,
              currentKey,
              searchModifier,
              keyRange: searchKeyRange,
            });
          }

          readIndex++;
        }
        token.used = true;
        consumedIndex = readIndex;
        currentState = ParserState.WaitingForKey;
        break;
      }
      case ParserState.LookingForDefaultValues: {
        consumedIndex++;

        // We should have a key here if not loop back around.
        if (currentKey === null) {
          currentState = ParserState.WaitingForKey;
          continue;
        }

        // Default value has quotes let's move to that stage.
        if (token.type === 'doubleQuotes') {
          currentState = ParserState.LookingForEncapsulatedText;
          continue;
        }

        // Token hasn't been used and type isn't whitespace and add to currentvalues.
        if (!token.used && token.type !== 'whitespace') {
          currentValues.push({ value, range: token.range });
        }

        // Do we have any current values? let's add to the searchQueryDetails list.
        if (currentValues.length > 0) {
          AddOrUpdateQueryDetails({
            searchQueryDetails,
            currentValues,
            currentKey,
            searchModifier,
            keyRange: searchKeyRange,
          });
        }

        currentState =
          nextToken?.type === 'doubleQuotes'
            ? ParserState.LookingForEncapsulatedText
            : ParserState.WaitingForKey;
        token.used = true;
        break;
      }
    }
  }
  return searchQueryDetails;
};

interface IAddToQueryDetails {
  searchQueryDetails: ISearchQueryDetails;
  currentValues: ISearchValue[];
  currentKey: ISearchKey;
  searchModifier: ISearchModifier | null;
  keyRange: IRange | null;
}

const AddOrUpdateQueryDetails = ({
  currentKey,
  currentValues,
  searchModifier,
  searchQueryDetails,
  keyRange,
}: IAddToQueryDetails) => {
  const existingQueryDetail = searchQueryDetails?.[currentKey.name];
  if (existingQueryDetail) {
    existingQueryDetail.values.push(...currentValues);
    existingQueryDetail.searchModifier = searchModifier;

    // does not exist in C# this is specific to the front end implementation
    if (existingQueryDetail?.keyRange) {
      const endRange =
        existingQueryDetail.values[existingQueryDetail.values.length - 1]?.range.end ??
        existingQueryDetail.keyRange.end;
      existingQueryDetail.totalRange = { start: existingQueryDetail.keyRange.start, end: endRange };
    }
  } else {
    // does not exist in C# this is specific to the front end implementation
    let totalRange: IRange | null = null;
    if (keyRange) {
      let endRange = keyRange.end;
      if (currentValues.length !== 0) {
        endRange = currentValues[currentValues.length - 1].range.end ?? keyRange.end;
      }
      totalRange = { start: keyRange.start, end: endRange };
    }

    searchQueryDetails[currentKey.name] = {
      values: [...currentValues],
      searchModifier,
      isDefault: currentKey.isDefault,
      useExactMatch: currentKey.useExactMatch,
      keyRange: keyRange,
      totalRange: totalRange,
    };
  }
};

interface IParseSearchString {
  search: string;
  searchKeys: ISearchKey[];
}

export const parseSearchString = ({ search, searchKeys }: IParseSearchString) => {
  if (search === null || search === '') {
    return null;
  }

  const tokens = getTokens({ search });

  const searchQueryDetails = parse({ searchKeys, search, tokens });

  return searchQueryDetails;
};
