// Lib
import { put, all } from 'typed-redux-saga';

import { setTagDetailsContent } from 'redux/actions/app';

import { IActionPayload, ILoadViewEditTagDetailsParams } from 'types';

// Utils
import { ApiTypeCaller } from '../../api/utils';

export function* loadTagDetailsSaga({
  payload: { ref, mode },
}: IActionPayload<ILoadViewEditTagDetailsParams>) {
  const { result, success } = yield* ApiTypeCaller('getTag', { ref });

  if (success && result) {
    if (mode === 'edit') {
      yield put(
        setTagDetailsContent({
          type: 'tag-edit',
          data: result,
          dataNew: result,
          isLoading: false,
          validationState: {},
        })
      );
    } else if (mode === 'view') {
      yield all([
        put(
          setTagDetailsContent({
            type: 'tag-view',
            data: result,
            isLoading: false,
          })
        ),
      ]);
    }
  }
}
