// components
import { PolicyTypeCreate } from './Create';
import { PolicyTypeView } from './View';

// hooks
import { useSelector } from 'react-redux';

// selectors
import { selectAppDetailsTabType } from 'redux/selectors';

export const PolicyTypeBlock = () => {
  const type = useSelector(selectAppDetailsTabType);

  return (
    <>
      {(type === 'policy-view' || type === 'policy-edit') && <PolicyTypeView />}
      {type === 'policy-create' && <PolicyTypeCreate />}
    </>
  );
};
