// libs
import { css } from 'styled-components';

// components
import { Details } from 'components';
import { NotesView } from './View';
import { NotesEdit } from './Edit';

// hooks
import { useSelector } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';

// selectors
import { selectAppDetailsTabType } from 'redux/selectors';

export const NotesBlock = () => {
  const { translate } = useTranslations('dns');

  const type = useSelector(selectAppDetailsTabType);

  return (
    <Details.KeyValue
      css={css`
        min-height: 4.875rem;
      `}
      title={translate('records.details.notes')}
    >
      {type === 'dns-record-view' && <NotesView />}
      {(type === 'dns-record-edit' || type === 'dns-record-create') && <NotesEdit />}
    </Details.KeyValue>
  );
};
