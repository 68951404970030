import { useTheme, css } from 'styled-components';
import { CustomText } from 'typography/Text';
import {
  StyledTextContainer,
  SystemContentContainer,
  StyledSystemsIcon,
  SystemIconContainer,
} from './styled';

interface ISystemContent {
  superscript: string;
  subscript: string;
  description: string;
  external?: boolean;
}

export const SystemContent = ({ superscript, subscript, description }: ISystemContent) => {
  const {
    palette: { fonts },
  } = useTheme();

  return (
    <SystemContentContainer>
      <SystemIconContainer>
        <StyledSystemsIcon />
      </SystemIconContainer>
      <StyledTextContainer>
        <div>
          <CustomText
            type="heading-1"
            css={css`
              line-height: 2.5rem;
              margin-right: 0.5rem;
            `}
          >
            {superscript}
          </CustomText>
          <CustomText type="body-2" color={fonts.bodyLight}>
            {subscript}
          </CustomText>
        </div>

        <CustomText
          type="label-1"
          color={fonts.bodyLight}
          css={css`
            margin-top: 0.5rem;
          `}
        >
          {description.toUpperCase()}
        </CustomText>
      </StyledTextContainer>
    </SystemContentContainer>
  );
};
