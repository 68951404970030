// libs
import { css, useTheme } from 'styled-components';

// components
import { CustomButton, CustomButtonLink, CustomMessage, Input, Loader } from 'components';
import { ModalFooter, ModalHeader, ModalScrollablePart } from 'components/Modal';
import { CustomText } from 'typography/Text';
import { IconModalDelete } from 'assets/svg';
import { Info } from './Info';

// hooks
import { useCallback, useEffect, useState } from 'react';
import { useTranslations } from 'hooks/useTranslations';
import { useDispatch, useSelector } from 'react-redux';
import { useSubmitOnEnter } from 'hooks/useSubmitOnEnter';

// actions
import { clearErrors, setModalContent } from 'redux/actions/app';

// selectors
import { selectFormError, selectModalData, selectModalIsLoading } from 'redux/selectors';

// types
import { IModalDeleteDnsZone } from 'types';
import { deleteDnsZone } from 'redux/actions/dns';

// constants
import { DNS_DELETE_ZONE_ERROR_FIELD, REMOVE_MODAL_CONFIRMATION_TEXT } from 'appConstants';

export const DeleteZoneModal = () => {
  const dispatch = useDispatch();

  const modalData = useSelector(selectModalData) as IModalDeleteDnsZone['data'] | null;
  const isLoading = useSelector(selectModalIsLoading);

  const [inputText, setInputText] = useState('');
  const [enabled, setEnabled] = useState(false);

  const isButtonEnabled = enabled && !isLoading;

  const error = useSelector(selectFormError(DNS_DELETE_ZONE_ERROR_FIELD));

  useEffect(() => {
    if (inputText === REMOVE_MODAL_CONFIRMATION_TEXT) {
      setEnabled(true);
    } else {
      if (enabled) setEnabled(false);
    }
  }, [inputText, enabled]);

  const { translate } = useTranslations('dns');

  const {
    palette: {
      fonts: { bodyDark },
    },
  } = useTheme();

  const onClose = useCallback(() => {
    dispatch(setModalContent({ type: null, data: null }));
  }, [dispatch]);

  const onDelete = useCallback(() => {
    modalData &&
      dispatch(
        deleteDnsZone({
          zoneId: String(modalData.id),
        })
      );
  }, [dispatch, modalData]);

  useEffect(() => {
    error && dispatch(clearErrors());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    error && dispatch(clearErrors());
    setInputText(e.target.value.toUpperCase());
  };

  useSubmitOnEnter({ callback: isButtonEnabled ? onDelete : () => {} });

  return (
    <div data-test="dns-delete-zone-modal">
      <ModalHeader
        title={translate('zone_properties.danger_zone.delete_zone_model.title')}
        icon={<IconModalDelete width="2.5rem" height="2.5rem" />}
        withCloseButton={true}
        onClose={onClose}
      />
      <ModalScrollablePart>
        <CustomMessage
          type="error"
          message={translate('zone_properties.danger_zone.delete_zone_model.warning', {
            recordCount: modalData?.recordCount || '',
          })}
          css={css`
            margin-bottom: 2rem;
          `}
        />
        <CustomText
          type="body-1"
          color={bodyDark}
          css={css`
            display: block;
            margin-bottom: 0.75rem;
          `}
        >
          {translate('zone_properties.danger_zone.delete_zone_model.description')}
        </CustomText>
        <Info
          rows={[
            {
              name: translate('zone_properties.danger_zone.delete_zone_model.zone'),
              description: modalData?.name || '',
              isBold: true,
            },
            {
              name: translate('zone_properties.danger_zone.delete_zone_model.records'),
              description:
                modalData?.recordCount !== null && modalData?.recordCount !== undefined
                  ? modalData?.recordCount
                  : '',
            },
            {
              name: translate('zone_properties.danger_zone.delete_zone_model.notes'),
              description: modalData?.notes || '',
            },
          ]}
        />
        <Input
          data-test="dns-delete-zone-modal-input-wrapper"
          type="text"
          name="yescheck"
          value={inputText}
          label={translate('zone_properties.danger_zone.delete_zone_model.input_label')}
          maxLength={128}
          placeholder={translate('zone_properties.danger_zone.delete_zone_model.input_placeholder')}
          onChange={onChange}
          showStateIcon={!!error}
          error={error ? { message: error } : null}
          containerCss={css`
            margin-bottom: 0.75rem;
          `}
        />
      </ModalScrollablePart>

      <ModalFooter>
        <CustomButtonLink size="large" onClick={onClose}>
          {translate('zone_properties.danger_zone.delete_zone_model.cancel')}
        </CustomButtonLink>
        <CustomButton
          width="8.125rem"
          data-test="dns-delete-zone-submit"
          size="large"
          kind={isButtonEnabled ? 'primaryDanger' : 'disabled'}
          onClick={onDelete}
        >
          {isLoading ? (
            <Loader />
          ) : (
            translate('zone_properties.danger_zone.delete_zone_model.delete')
          )}
        </CustomButton>
      </ModalFooter>
    </div>
  );
};
