import { getStoredState, PersistConfig } from 'redux-persist';
import { storeUser, doLogout } from 'redux/actions/user';
import { MainState } from 'types';
type CrossTabPersistedStore =
  | {
      isLoggedIn: boolean;
      profile: Record<string, string> | null;
    }
  | undefined;

let handledLogout = false;

export function crossBrowserListener(store: any, persistConfig: PersistConfig<any, any>) {
  return async function () {
    const state = await getStoredState(persistConfig);
    const crossTabState = state as CrossTabPersistedStore;
    const baseStore: MainState = store.getState();
    if (baseStore.User.profile !== null && crossTabState?.profile === null && !handledLogout) {
      handledLogout = true;
      store.dispatch({
        type: doLogout.type,
        payload: true,
      });
    }
    if (baseStore.User.profile === null && crossTabState?.profile !== null) {
      handledLogout = false;
      store.dispatch({
        type: storeUser.type,
        payload: crossTabState?.profile,
      });
    }
  };
}
