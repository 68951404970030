import { API_ENDPOINTS } from 'appConstants';

import {
  IUpdateSystemPayload,
  IUpdateSystemResponse,
  IEnableSystemResponse,
  IDisableSystemResponse,
  IEnableSystemsParams,
  IDisableSystemsParams,
  IEnableSystemsResponse,
  IDisableSystemsResponse,
  IRevokeSystemProps,
  IRevokeSystemResponse,
  IRevokeSystemsParams,
  IRevokeSystemsResponse,
  IGetSystemResponse,
  IGetSystemProps,
  IGetSystemsParams,
  IGetSystemsResponse,
  ISystemSummaryModel,
  IApiResponse,
  IGetUnapprovedSystemResponse,
  IGetUnapprovedSystemProps,
  IGetUnapprovedSystemsParams,
  IGetUnapprovedSystemsResponse,
  IUpdateUnapprovedSystemPayload,
  IUpdateUnapprovedSystemResponse,
  IApproveUnapprovedSystemProps,
  IDeclineUnapprovedSystemProps,
  IApproveUnapprovedSystemsParams,
  IApproveUnapprovedSystemsResponse,
  IDeclineUnapprovedSystemsParams,
  IDeclineUnapprovedSystemsResponse,
  IGetOrganisationResponse,
  IGetEnrolmentKeysResponse,
  IGetEnrolmentKeysParams,
  ICreateEnrolmentKeyParams,
  ICreateEnrolmentKeyResponse,
  IEnableEnrolmentKeyProps,
  IDisableEnrolmentKeyProps,
  IEnableEnrolmentKeyResponse,
  IDisableEnrolmentKeyResponse,
  IEnableEnrolmentKeysBulkParams,
  IDisableEnrolmentKeysBulkParams,
  IEnableEnrolmentKeysBulkResponse,
  IDisableEnrolmentKeysBulkResponse,
  IGetEnrolmentKeyProps,
  IGetEnrolmentKeyResponse,
  IUpdateEnrolmentKeyPayload,
  IUpdateEnrolmentKeyResponse,
  IGetTagsParams,
  IGetTagsResponse,
  IUserAccount,
  IUpdateAccountParams,
  IUpdateAccountResponse,
  IUpdateAccountPasswordParams,
  ICreateAccountStepUpParams,
  ICreateAccountStepUpResponse,
  ICreateAccountAccessTokenParams,
  ICreateAccountAccessTokenResponse,
  IRemoveAccountAccessTokenParams,
  ICreateAccount2FAQRParams,
  ICreateAccount2FAQRResponse,
  IGenerateAccountRecoveryCodesParams,
  IGenerateAccountRecoveryCodesResponse,
  IVerifyAccount2FACodeParams,
  IEnable2FAParams,
  IDisable2FAParams,
  IGetOrganisationsResponse,
  IGetPoliciesParams,
  IGetPoliciesResponse,
  ICreatePolicyParams,
  ICreatePolicyResponse,
  IDeletePoliciesParams,
  IDeletePoliciesResponse,
  IGetPolicyProps,
  IGetPolicyResponse,
  IUpdatePolicyPayload,
  IUpdatePolicyResponse,
  IDeletePolicyResponse,
  IEnablePolicyProps,
  IDisablePolicyProps,
  IEnablePolicyResponse,
  IDisablePolicyResponse,
  IEnablePoliciesParams,
  IDisablePoliciesParams,
  IEnablePoliciesResponse,
  IDisablePoliciesResponse,
  IGetSecurityEventsParams,
  IGetSecurityEventsResponse,
  IEnrolAuthorityParams,
  IEnrolAuthorityResponse,
  IGetOrganisationUsersResponse,
  IGetOrganisationInvitesResponse,
  ISendUserInviteParams,
  ISendUserInviteResponse,
  ICancelUserInviteParams,
  ICancelUserInviteResponse,
  IUpdateOrganisationParams,
  IUpdateOrganisationResponse,
  IGetOrganisationPlansResponse,
  IRemoveOrganisationUserProps,
  IRemoveOrganisationUserResponse,
  IAcceptOrganisationInviteParams,
  IAcceptOrganisationInviteResponse,
  IDeclineOrganisationInviteParams,
  IDeclineOrganisationInviteResponse,
  IUpgradePlanProps,
  IUpgradePlanResponse,
  IGetBillingPortalResponse,
  IGetBillingPortalParams,
  IGetDnsSummaryProps,
  IGetDnsSummaryResponse,
  IGetDnsZonesParams,
  IGetDnsZonesResponse,
  IGetDnsZoneProps,
  IGetDnsZoneResponse,
  IUpdateDnsZoneProps,
  IUpdateDnsZoneResponse,
  IDeleteDnsZoneProps,
  IDeleteDnsZoneResponse,
  ICreateDnsZoneParams,
  ICreateDnsZoneResponse,
  IGetDnsRecordsParams,
  IGetDnsRecordsResponse,
  IGetDnsRecordProps,
  IGetDnsRecordResponse,
  ICreateDnsRecordParams,
  ICreateDnsRecordResponse,
  IUpdateDnsRecordResponse,
  IDeleteDnsRecordResponse,
  IDeleteDnsRecordProps,
  IDeleteDnsRecordsProps,
  IDeleteDnsRecordsResponse,
  IGetDnsRecordSearchKeysResponse,
  IUpdateIUpdateDnsRecordProps,
  IUpdateAccountPasswordResponse,
  IEnable2FAResponse,
  IGetSystemSearchKeysResponse,
  IGetUnapprovedSystemSearchKeysResponse,
  IGetPolicySearchKeysResponse,
  IGetTrustRequirementsParams,
  IGetTrustRequirementsResponse,
  IGetTrustRequirementProps,
  IGetTrustRequirementResponse,
  IDeleteTrustRequirementResponse,
  IDeleteTrustRequirementsParams,
  IDeleteTrustRequirementProps,
  IDeleteTrustRequirementsResponse,
  ICreateTrustRequirementsParams,
  ICreateTrustRequirementsResponse,
  IUpdateTrustRequirementResponse,
  IGetTagResponse,
  IGetTagProps,
  ICreateTagParams,
  ICreateTagResponse,
  IUpdateTagPayload,
  IUpdateTagResponse,
  IDeleteTagsParams,
  IDeleteTagsResponse,
  IUpdateTrustRequirementPayload,
  IGetTagSearchKeysResponse,
  IGetTrustRequirementSearchKeysResponse,
  IGetEnrolmentKeySearchKeysResponse,
  IDeleteEnrolmentKeyProps,
  IDeleteEnrolmentKeyResponse,
  IDeleteEnrolmentKeysBulkParams,
  IDeletePolicyProps,
  IDeleteTagResponse,
  IDeleteTagProps,
  IEnableUntilPolicyProps,
  IEnableUntilPolicyResponse,
  IEnableUntilSystemProps,
  IEnableUntilSystemResponse,
  IEnableUntilEnrolmentKeyProps,
  IEnableUntilEnrolmentKeyResponse,
} from 'types';

export type apiTypesMap = {
  // SYSTEMS
  getSystems: {
    request: IGetSystemsParams;
    response: IApiResponse<IGetSystemsResponse>;
  };
  getSystem: {
    request: IGetSystemProps;
    response: IApiResponse<IGetSystemResponse>;
  };
  enableSystem: {
    request: ISystemSummaryModel['systemId'];
    response: IApiResponse<IEnableSystemResponse>;
  };
  enableSystemUntil: {
    request: IEnableUntilSystemProps;
    response: IApiResponse<IEnableUntilSystemResponse>;
  };
  disableSystem: {
    request: ISystemSummaryModel['systemId'];
    response: IApiResponse<IDisableSystemResponse>;
  };
  enableSystems: {
    request: IEnableSystemsParams;
    response: IApiResponse<IEnableSystemsResponse>;
  };
  disableSystems: {
    request: IDisableSystemsParams;
    response: IApiResponse<IDisableSystemsResponse>;
  };
  revokeSystem: {
    request: IRevokeSystemProps;
    response: IApiResponse<IRevokeSystemResponse>;
  };
  revokeSystems: {
    request: IRevokeSystemsParams;
    response: IApiResponse<IRevokeSystemsResponse>;
  };
  updateSystem: {
    request: IUpdateSystemPayload;
    response: IApiResponse<IUpdateSystemResponse>;
  };
  getSystemSearchKeys: {
    request: undefined;
    response: IApiResponse<IGetSystemSearchKeysResponse>;
  };
  // UNAPPROVED SYSTEMS
  getUnapprovedSystem: {
    request: IGetUnapprovedSystemProps;
    response: IApiResponse<IGetUnapprovedSystemResponse>;
  };
  getUnapprovedSystems: {
    request: IGetUnapprovedSystemsParams;
    response: IApiResponse<IGetUnapprovedSystemsResponse>;
  };
  updateUnapprovedSystem: {
    request: IUpdateUnapprovedSystemPayload;
    response: IApiResponse<IUpdateUnapprovedSystemResponse>;
  };
  approveUnapprovedSystem: {
    request: IApproveUnapprovedSystemProps;
    response: IApiResponse<null>;
  };
  declineUnapprovedSystem: {
    request: IDeclineUnapprovedSystemProps;
    response: IApiResponse<IDeclineUnapprovedSystemProps>;
  };
  approveUnapprovedSystems: {
    request: IApproveUnapprovedSystemsParams;
    response: IApiResponse<IApproveUnapprovedSystemsResponse>;
  };
  declineUnapprovedSystems: {
    request: IDeclineUnapprovedSystemsParams;
    response: IApiResponse<IDeclineUnapprovedSystemsResponse>;
  };
  getUnapprovedSystemSearchKeys: {
    request: undefined;
    response: IApiResponse<IGetUnapprovedSystemSearchKeysResponse>;
  };
  // ORGANISATIONS
  getCurrentOrganisation: {
    request: undefined;
    response: IApiResponse<IGetOrganisationResponse>;
  };
  getOrganisationUsers: {
    request: undefined;
    response: IApiResponse<IGetOrganisationUsersResponse>;
  };
  getOrganisationInvites: {
    request: undefined;
    response: IApiResponse<IGetOrganisationInvitesResponse>;
  };
  sendUserInvite: {
    request: ISendUserInviteParams;
    response: IApiResponse<ISendUserInviteResponse>;
  };
  cancelUserInvite: {
    request: ICancelUserInviteParams;
    response: IApiResponse<ICancelUserInviteResponse>;
  };
  updateOrganisation: {
    request: IUpdateOrganisationParams;
    response: IApiResponse<IUpdateOrganisationResponse>;
  };
  getOrganisationPlans: {
    request: undefined;
    response: IApiResponse<IGetOrganisationPlansResponse>;
  };
  removeOrganisationUser: {
    request: IRemoveOrganisationUserProps;
    response: IApiResponse<IRemoveOrganisationUserResponse>;
  };
  // KEYS
  getEnrolmentKeys: {
    request: IGetEnrolmentKeysParams | undefined;
    response: IApiResponse<IGetEnrolmentKeysResponse>;
  };
  createEnrolmentKey: {
    request: ICreateEnrolmentKeyParams;
    response: IApiResponse<ICreateEnrolmentKeyResponse>;
  };
  enableEnrolmentKey: {
    request: IEnableEnrolmentKeyProps;
    response: IApiResponse<IEnableEnrolmentKeyResponse>;
  };
  enableEnrolmentKeyUntil: {
    request: IEnableUntilEnrolmentKeyProps;
    response: IApiResponse<IEnableUntilEnrolmentKeyResponse>;
  };
  disableEnrolmentKey: {
    request: IDisableEnrolmentKeyProps;
    response: IApiResponse<IDisableEnrolmentKeyResponse>;
  };
  enableEnrolmentKeys: {
    request: IEnableEnrolmentKeysBulkParams;
    response: IApiResponse<IEnableEnrolmentKeysBulkResponse>;
  };
  disableEnrolmentKeys: {
    request: IDisableEnrolmentKeysBulkParams;
    response: IApiResponse<IDisableEnrolmentKeysBulkResponse>;
  };
  deleteEnrolmentKey: {
    request: IDeleteEnrolmentKeyProps;
    response: IApiResponse<IDeleteEnrolmentKeyResponse>;
  };
  deleteEnrolmentKeys: {
    request: IDeleteEnrolmentKeysBulkParams;
    response: IApiResponse<IDeleteEnrolmentKeyResponse>;
  };
  getEnrolmentKey: {
    request: IGetEnrolmentKeyProps;
    response: IApiResponse<IGetEnrolmentKeyResponse>;
  };
  updateEnrolmentKey: {
    request: IUpdateEnrolmentKeyPayload;
    response: IApiResponse<IUpdateEnrolmentKeyResponse>;
  };
  getEnrolmentKeySearchKeys: {
    request: undefined;
    response: IApiResponse<IGetEnrolmentKeySearchKeysResponse>;
  };
  // TAGS
  getTags: {
    request: IGetTagsParams;
    response: IApiResponse<IGetTagsResponse>;
  };
  getTag: {
    request: IGetTagProps;
    response: IApiResponse<IGetTagResponse>;
  };
  createTag: {
    request: ICreateTagParams;
    response: IApiResponse<ICreateTagResponse>;
  };
  updateTag: {
    request: IUpdateTagPayload;
    response: IApiResponse<IUpdateTagResponse>;
  };
  deleteTags: {
    request: IDeleteTagsParams;
    response: IApiResponse<IDeleteTagsResponse>;
  };
  deleteTag: {
    request: IDeleteTagProps;
    response: IApiResponse<IDeleteTagResponse>;
  };

  getTagSearchKeys: {
    request: undefined;
    response: IApiResponse<IGetTagSearchKeysResponse>;
  };
  // ACCOUNT
  getAccount: {
    request: undefined;
    response: IApiResponse<IUserAccount>;
  };
  updateAccountData: {
    request: IUpdateAccountParams;
    response: IApiResponse<IUpdateAccountResponse>;
  };
  updateAccountPassword: {
    request: IUpdateAccountPasswordParams;
    response: IApiResponse<IUpdateAccountPasswordResponse>;
  };
  createAccountStepUp: {
    request: ICreateAccountStepUpParams;
    response: IApiResponse<ICreateAccountStepUpResponse>;
  };
  createAccountAccessToken: {
    request: ICreateAccountAccessTokenParams;
    response: IApiResponse<ICreateAccountAccessTokenResponse>;
  };
  deleteAccountAccessToken: {
    request: IRemoveAccountAccessTokenParams;
    response: IApiResponse<null>;
  };
  createAccount2FAQR: {
    request: ICreateAccount2FAQRParams;
    response: IApiResponse<ICreateAccount2FAQRResponse>;
  };
  generateAccountRecoveryCodes: {
    request: IGenerateAccountRecoveryCodesParams;
    response: IApiResponse<IGenerateAccountRecoveryCodesResponse>;
  };
  verifyAccount2FACode: {
    request: IVerifyAccount2FACodeParams;
    response: IApiResponse<null>;
  };
  enableAccount2FA: {
    request: IEnable2FAParams;
    response: IApiResponse<IEnable2FAResponse>;
  };
  disableAccount2FA: {
    request: IDisable2FAParams;
    response: IApiResponse<null>;
  };
  getAccountOrganisations: {
    request: undefined;
    response: IApiResponse<IGetOrganisationsResponse>;
  };
  acceptInvite: {
    request: IAcceptOrganisationInviteParams;
    response: IApiResponse<IAcceptOrganisationInviteResponse>;
  };
  declineInvite: {
    request: IDeclineOrganisationInviteParams;
    response: IApiResponse<IDeclineOrganisationInviteResponse>;
  };
  // POLICIES
  getPolicies: {
    request: IGetPoliciesParams;
    response: IApiResponse<IGetPoliciesResponse>;
  };
  getPolicy: {
    request: IGetPolicyProps;
    response: IApiResponse<IGetPolicyResponse>;
  };
  createPolicy: {
    request: ICreatePolicyParams;
    response: IApiResponse<ICreatePolicyResponse>;
  };
  deletePolicies: {
    request: IDeletePoliciesParams;
    response: IApiResponse<IDeletePoliciesResponse>;
  };
  updatePolicy: {
    request: IUpdatePolicyPayload;
    response: IApiResponse<IUpdatePolicyResponse>;
  };
  deletePolicy: {
    request: IDeletePolicyProps;
    response: IApiResponse<IDeletePolicyResponse>;
  };
  enablePolicy: {
    request: IEnablePolicyProps;
    response: IApiResponse<IEnablePolicyResponse>;
  };
  enablePolicyUntil: {
    request: IEnableUntilPolicyProps;
    response: IApiResponse<IEnableUntilPolicyResponse>;
  };
  disablePolicy: {
    request: IDisablePolicyProps;
    response: IApiResponse<IDisablePolicyResponse>;
  };
  enablePolicies: {
    request: IEnablePoliciesParams;
    response: IApiResponse<IEnablePoliciesResponse>;
  };
  disablePolicies: {
    request: IDisablePoliciesParams;
    response: IApiResponse<IDisablePoliciesResponse>;
  };
  getPolicySearchKeys: {
    request: undefined;
    response: IApiResponse<IGetPolicySearchKeysResponse>;
  };
  // SECURITY EVENTS
  getSecurityEvents: {
    request: IGetSecurityEventsParams;
    response: IApiResponse<IGetSecurityEventsResponse>;
  };
  // ENROL AUTHORITY
  enrollAuthority: {
    request: IEnrolAuthorityParams;
    response: IApiResponse<IEnrolAuthorityResponse>;
  };
  // PAYMENT
  upgradePlan: {
    request: IUpgradePlanProps;
    response: IApiResponse<IUpgradePlanResponse>;
  };
  getBillingPortal: {
    request: IGetBillingPortalParams;
    response: IApiResponse<IGetBillingPortalResponse>;
  };
  // DNS
  getDnsSummary: {
    request: IGetDnsSummaryProps;
    response: IApiResponse<IGetDnsSummaryResponse>;
  };
  getDnsZones: {
    request: IGetDnsZonesParams;
    response: IApiResponse<IGetDnsZonesResponse>;
  };
  getDnsZoneDetails: {
    request: IGetDnsZoneProps;
    response: IApiResponse<IGetDnsZoneResponse>;
  };
  createDnsZone: {
    request: ICreateDnsZoneParams;
    response: IApiResponse<ICreateDnsZoneResponse>;
  };
  updateDnsZone: {
    request: IUpdateDnsZoneProps;
    response: IApiResponse<IUpdateDnsZoneResponse>;
  };
  deleteDnsZone: {
    request: IDeleteDnsZoneProps;
    response: IApiResponse<IDeleteDnsZoneResponse>;
  };
  getDnsRecords: {
    request: IGetDnsRecordsParams;
    response: IApiResponse<IGetDnsRecordsResponse>;
  };
  getDnsRecordDetails: {
    request: IGetDnsRecordProps;
    response: IApiResponse<IGetDnsRecordResponse>;
  };
  createDnsRecord: {
    request: ICreateDnsRecordParams;
    response: IApiResponse<ICreateDnsRecordResponse>;
  };
  updateDnsRecord: {
    request: IUpdateIUpdateDnsRecordProps;
    response: IApiResponse<IUpdateDnsRecordResponse>;
  };
  deleteDnsRecord: {
    request: IDeleteDnsRecordProps;
    response: IApiResponse<IDeleteDnsRecordResponse>;
  };
  deleteDnsRecords: {
    request: IDeleteDnsRecordsProps;
    response: IApiResponse<IDeleteDnsRecordsResponse>;
  };
  getDnsRecordSearchKeys: {
    request: undefined;
    response: IApiResponse<IGetDnsRecordSearchKeysResponse>;
  };
  // TRUST
  getTrustRequirements: {
    request: IGetTrustRequirementsParams;
    response: IApiResponse<IGetTrustRequirementsResponse>;
  };
  deleteTrustRequirements: {
    request: IDeleteTrustRequirementsParams;
    response: IApiResponse<IDeleteTrustRequirementsResponse>;
  };
  getTrustRequirement: {
    request: IGetTrustRequirementProps;
    response: IApiResponse<IGetTrustRequirementResponse>;
  };
  createTrustRequirement: {
    request: ICreateTrustRequirementsParams;
    response: IApiResponse<ICreateTrustRequirementsResponse>;
  };
  updateTrustRequirement: {
    request: IUpdateTrustRequirementPayload;
    response: IApiResponse<IUpdateTrustRequirementResponse>;
  };
  deleteTrustRequirement: {
    request: IDeleteTrustRequirementProps;
    response: IApiResponse<IDeleteTrustRequirementResponse>;
  };
  getTrustRequirementSearchKeys: {
    request: undefined;
    response: IApiResponse<IGetTrustRequirementSearchKeysResponse>;
  };
};

export const apiDefinitionMap: Record<keyof apiTypesMap, any> = {
  // SYSTEMS
  getSystems: (params: apiTypesMap['getSystems']['request']) => ({
    method: 'GET',
    url: `${API_ENDPOINTS.systems}`,
    needsOrgPrefix: true,
    payload: params,
  }),
  getSystem: ({ systemId }: apiTypesMap['getSystem']['request']) => ({
    method: 'GET',
    url: `${API_ENDPOINTS.systems}/${systemId}`,
    needsOrgPrefix: true,
    payload: undefined,
  }),
  enableSystem: (systemId: apiTypesMap['enableSystem']['request']) => ({
    method: 'PUT',
    url: `${API_ENDPOINTS.systems}/${systemId}/enable`,
    needsOrgPrefix: true,
    payload: undefined,
  }),
  enableSystemUntil: ({
    systemId,
    updatedParams,
  }: apiTypesMap['enableSystemUntil']['request']) => ({
    method: 'PUT',
    url: `${API_ENDPOINTS.systems}/${systemId}/enable-until`,
    needsOrgPrefix: true,
    payload: updatedParams,
  }),
  disableSystem: (systemId: apiTypesMap['disableSystem']['request']) => ({
    method: 'PUT',
    url: `${API_ENDPOINTS.systems}/${systemId}/disable`,
    needsOrgPrefix: true,
    payload: undefined,
  }),
  enableSystems: (payload: apiTypesMap['enableSystems']['request']) => ({
    method: 'PUT',
    url: `${API_ENDPOINTS.systems}/enable`,
    needsOrgPrefix: true,
    payload: payload,
  }),
  disableSystems: (payload: apiTypesMap['disableSystems']['request']) => ({
    method: 'PUT',
    url: `${API_ENDPOINTS.systems}/disable`,
    needsOrgPrefix: true,
    payload: payload,
  }),
  revokeSystem: ({ systemId }: apiTypesMap['revokeSystem']['request']) => ({
    method: 'DELETE',
    url: `${API_ENDPOINTS.systems}/${systemId}`,
    needsOrgPrefix: true,
    payload: undefined,
  }),
  revokeSystems: (payload: apiTypesMap['revokeSystems']['request']) => ({
    method: 'DELETE',
    url: `${API_ENDPOINTS.systems}`,
    needsOrgPrefix: true,
    payload: payload,
  }),
  updateSystem: ({ systemId, updatedParams }: apiTypesMap['updateSystem']['request']) => ({
    method: 'PATCH',
    url: `${API_ENDPOINTS.systems}/${systemId}`,
    needsOrgPrefix: true,
    payload: updatedParams,
  }),
  getSystemSearchKeys: () => ({
    method: 'GET',
    url: `${API_ENDPOINTS.systems}/meta/search-keys`,
    needsOrgPrefix: true,
    payload: undefined,
  }),
  // UNAPPROVED SYSTEMS
  getUnapprovedSystem: ({ systemId }: apiTypesMap['getUnapprovedSystem']['request']) => ({
    method: 'GET',
    url: `${API_ENDPOINTS.unapprovedSystems}/${systemId}`,
    needsOrgPrefix: true,
    payload: undefined,
  }),
  getUnapprovedSystems: (searchParams: apiTypesMap['getUnapprovedSystems']['request']) => ({
    method: 'GET',
    url: `${API_ENDPOINTS.unapprovedSystems}`,
    needsOrgPrefix: true,
    payload: searchParams,
  }),
  updateUnapprovedSystem: ({
    systemId,
    updatedParams,
  }: apiTypesMap['updateUnapprovedSystem']['request']) => ({
    method: 'PATCH',
    url: `${API_ENDPOINTS.unapprovedSystems}/${systemId}`,
    needsOrgPrefix: true,
    payload: updatedParams,
  }),
  approveUnapprovedSystem: ({ systemId }: apiTypesMap['approveUnapprovedSystem']['request']) => ({
    method: 'PUT',
    url: `${API_ENDPOINTS.unapprovedSystems}/${systemId}/approve`,
    needsOrgPrefix: true,
    payload: undefined,
  }),
  declineUnapprovedSystem: ({ systemId }: apiTypesMap['declineUnapprovedSystem']['request']) => ({
    method: 'DELETE',
    url: `${API_ENDPOINTS.unapprovedSystems}/${systemId}`,
    needsOrgPrefix: true,
    payload: undefined,
  }),
  approveUnapprovedSystems: (payload: apiTypesMap['approveUnapprovedSystems']['request']) => ({
    method: 'PUT',
    url: `${API_ENDPOINTS.unapprovedSystems}/approve`,
    needsOrgPrefix: true,
    payload: payload,
  }),
  declineUnapprovedSystems: (payload: apiTypesMap['declineUnapprovedSystems']['request']) => ({
    method: 'DELETE',
    url: `${API_ENDPOINTS.unapprovedSystems}`,
    needsOrgPrefix: true,
    payload: payload,
  }),
  getUnapprovedSystemSearchKeys: () => ({
    method: 'GET',
    url: `${API_ENDPOINTS.unapprovedSystems}/meta/search-keys`,
    needsOrgPrefix: true,
    payload: undefined,
  }),
  // ORGANISATIONS
  getCurrentOrganisation: () => ({
    method: 'GET',
    url: ``,
    needsOrgPrefix: true,
    payload: undefined,
  }),
  getOrganisationUsers: () => ({
    method: 'GET',
    url: `users`,
    needsOrgPrefix: true,
    payload: undefined,
  }),
  getOrganisationInvites: () => ({
    method: 'GET',
    url: `invites`,
    needsOrgPrefix: true,
    payload: undefined,
  }),
  sendUserInvite: (payload: apiTypesMap['sendUserInvite']['request']) => ({
    method: 'POST',
    url: `invites`,
    needsOrgPrefix: true,
    payload: payload,
  }),
  cancelUserInvite: (payload: apiTypesMap['cancelUserInvite']['request']) => ({
    method: 'DELETE',
    url: `invites`,
    needsOrgPrefix: true,
    payload: payload,
  }),
  updateOrganisation: (payload: apiTypesMap['updateOrganisation']['request']) => ({
    method: 'PATCH',
    url: ``,
    needsOrgPrefix: true,
    payload: payload,
  }),
  getOrganisationPlans: () => ({
    method: 'GET',
    url: `pricing`,
    needsOrgPrefix: true,
    payload: undefined,
  }),
  removeOrganisationUser: ({ accountId }: apiTypesMap['removeOrganisationUser']['request']) => ({
    method: 'DELETE',
    url: `users/${accountId}`,
    needsOrgPrefix: true,
    payload: undefined,
  }),
  // KEYS
  getEnrolmentKeys: (searchParams: apiTypesMap['getEnrolmentKeys']['request']) => ({
    method: 'GET',
    url: `${API_ENDPOINTS.enrolmentKeys}`,
    needsOrgPrefix: true,
    payload: searchParams,
  }),
  createEnrolmentKey: (payload: apiTypesMap['createEnrolmentKey']['request']) => ({
    method: 'POST',
    url: `${API_ENDPOINTS.enrolmentKeys}`,
    needsOrgPrefix: true,
    payload: payload,
  }),
  enableEnrolmentKey: ({ enrolmentKeyId }: apiTypesMap['enableEnrolmentKey']['request']) => ({
    method: 'PUT',
    url: `${API_ENDPOINTS.enrolmentKeys}/${enrolmentKeyId}/enable`,
    needsOrgPrefix: true,
    payload: undefined,
  }),
  enableEnrolmentKeyUntil: ({
    enrolmentKeyId,
    updatedParams,
  }: apiTypesMap['enableEnrolmentKeyUntil']['request']) => ({
    method: 'PUT',
    url: `${API_ENDPOINTS.enrolmentKeys}/${enrolmentKeyId}/enable-until`,
    needsOrgPrefix: true,
    payload: updatedParams,
  }),
  disableEnrolmentKey: ({ enrolmentKeyId }: apiTypesMap['disableEnrolmentKey']['request']) => ({
    method: 'PUT',
    url: `${API_ENDPOINTS.enrolmentKeys}/${enrolmentKeyId}/disable`,
    needsOrgPrefix: true,
    payload: undefined,
  }),
  deleteEnrolmentKey: ({ enrolmentKeyId }: apiTypesMap['deleteEnrolmentKey']['request']) => ({
    method: 'DELETE',
    url: `${API_ENDPOINTS.enrolmentKeys}/${enrolmentKeyId}`,
    needsOrgPrefix: true,
    payload: undefined,
  }),
  deleteEnrolmentKeys: (payload: apiTypesMap['deleteEnrolmentKeys']['request']) => ({
    method: 'DELETE',
    url: `${API_ENDPOINTS.enrolmentKeys}`,
    needsOrgPrefix: true,
    payload: payload,
  }),
  enableEnrolmentKeys: (payload: apiTypesMap['enableEnrolmentKeys']['request']) => ({
    method: 'PUT',
    url: `${API_ENDPOINTS.enrolmentKeys}/enable`,
    needsOrgPrefix: true,
    payload: payload,
  }),
  disableEnrolmentKeys: (payload: apiTypesMap['disableEnrolmentKeys']['request']) => ({
    method: 'PUT',
    url: `${API_ENDPOINTS.enrolmentKeys}/disable`,
    needsOrgPrefix: true,
    payload: payload,
  }),
  getEnrolmentKey: ({ enrolmentKeyId }: apiTypesMap['getEnrolmentKey']['request']) => ({
    method: 'GET',
    url: `${API_ENDPOINTS.enrolmentKeys}/${enrolmentKeyId}`,
    needsOrgPrefix: true,
    payload: undefined,
  }),
  updateEnrolmentKey: ({ keyId, updatedParams }: apiTypesMap['updateEnrolmentKey']['request']) => ({
    method: 'PATCH',
    url: `${API_ENDPOINTS.enrolmentKeys}/${keyId}`,
    needsOrgPrefix: true,
    payload: updatedParams,
  }),
  getEnrolmentKeySearchKeys: () => ({
    method: 'GET',
    url: `${API_ENDPOINTS.enrolmentKeys}/meta/search-keys`,
    needsOrgPrefix: true,
    payload: undefined,
  }),
  // TAGS
  getTags: (searchParams: apiTypesMap['getTags']['request']) => ({
    method: 'GET',
    url: `${API_ENDPOINTS.tags}`,
    needsOrgPrefix: true,
    payload: searchParams,
  }),
  getTag: ({ ref }: apiTypesMap['getTag']['request']) => ({
    method: 'GET',
    url: `${API_ENDPOINTS.tags}/${ref}`,
    needsOrgPrefix: true,
    payload: undefined,
  }),
  createTag: (payload: apiTypesMap['createTag']['request']) => ({
    method: 'POST',
    url: `${API_ENDPOINTS.tags}`,
    needsOrgPrefix: true,
    payload: payload,
  }),
  updateTag: ({ ref, updatedParams }: apiTypesMap['updateTag']['request']) => ({
    method: 'PATCH',
    url: `${API_ENDPOINTS.tags}/${ref}`,
    needsOrgPrefix: true,
    payload: updatedParams,
  }),
  deleteTags: (payload: apiTypesMap['deleteTags']['request']) => ({
    method: 'DELETE',
    url: `${API_ENDPOINTS.tags}`,
    needsOrgPrefix: true,
    payload,
  }),
  deleteTag: ({ tagOrRef }: apiTypesMap['deleteTag']['request']) => ({
    method: 'DELETE',
    url: `${API_ENDPOINTS.tags}/${tagOrRef}`,
    needsOrgPrefix: true,
    payload: undefined,
  }),
  getTagSearchKeys: () => ({
    method: 'GET',
    url: `${API_ENDPOINTS.tags}/meta/search-keys`,
    needsOrgPrefix: true,
    payload: undefined,
  }),
  // ACCOUNT
  getAccount: () => ({
    method: 'GET',
    url: `${API_ENDPOINTS.account}`,
    needsOrgPrefix: false,
    payload: undefined,
  }),
  updateAccountData: (updatedParams: apiTypesMap['updateAccountData']['request']) => ({
    method: 'PATCH',
    url: `${API_ENDPOINTS.account}`,
    needsOrgPrefix: false,
    payload: updatedParams,
  }),
  updateAccountPassword: (updatedParams: apiTypesMap['updateAccountPassword']['request']) => ({
    method: 'PUT',
    url: `${API_ENDPOINTS.account}/password-update`,
    needsOrgPrefix: false,
    payload: updatedParams,
  }),
  createAccountStepUp: (updatedParams: apiTypesMap['createAccountStepUp']['request']) => ({
    method: 'POST',
    url: `${API_ENDPOINTS.account}/step-up`,
    needsOrgPrefix: false,
    payload: updatedParams,
  }),
  createAccountAccessToken: (params: apiTypesMap['createAccountAccessToken']['request']) => ({
    method: 'POST',
    url: `${API_ENDPOINTS.account}/access-token`,
    needsOrgPrefix: false,
    payload: params,
  }),
  deleteAccountAccessToken: ({ tokenRef }: apiTypesMap['deleteAccountAccessToken']['request']) => ({
    method: 'DELETE',
    url: `${API_ENDPOINTS.account}/access-token/${tokenRef}`,
    needsOrgPrefix: false,
    payload: undefined,
  }),
  createAccount2FAQR: (params: apiTypesMap['createAccount2FAQR']['request']) => ({
    method: 'POST',
    url: `${API_ENDPOINTS.account}/two-factor-qrcode`,
    needsOrgPrefix: false,
    payload: params,
  }),
  generateAccountRecoveryCodes: (
    params: apiTypesMap['generateAccountRecoveryCodes']['request']
  ) => ({
    method: 'POST',
    url: `${API_ENDPOINTS.account}/two-factor-recoverycodes`,
    needsOrgPrefix: false,
    payload: params,
  }),
  verifyAccount2FACode: (params: apiTypesMap['verifyAccount2FACode']['request']) => ({
    method: 'POST',
    url: `${API_ENDPOINTS.account}/two-factor-verify-code`,
    needsOrgPrefix: false,
    payload: params,
  }),
  enableAccount2FA: (params: apiTypesMap['enableAccount2FA']['request']) => ({
    method: 'PUT',
    url: `${API_ENDPOINTS.account}/two-factor-enable`,
    needsOrgPrefix: false,
    payload: params,
  }),
  disableAccount2FA: (params: apiTypesMap['disableAccount2FA']['request']) => ({
    method: 'PUT',
    url: `${API_ENDPOINTS.account}/two-factor-disable`,
    needsOrgPrefix: false,
    payload: params,
  }),
  getAccountOrganisations: () => ({
    method: 'GET',
    url: `${API_ENDPOINTS.account}/orgs`,
    needsOrgPrefix: false,
    payload: undefined,
  }),
  acceptInvite: ({ orgId }: apiTypesMap['acceptInvite']['request']) => ({
    method: 'PUT',
    url: `${API_ENDPOINTS.account}/invite/${orgId}/accept`,
    needsOrgPrefix: false,
    payload: undefined,
  }),
  declineInvite: ({ orgId }: apiTypesMap['declineInvite']['request']) => ({
    method: 'DELETE',
    url: `${API_ENDPOINTS.account}/invite/${orgId}`,
    needsOrgPrefix: false,
    payload: undefined,
  }),
  // POLICIES
  getPolicies: (searchParams: apiTypesMap['getPolicies']['request']) => ({
    method: 'GET',
    url: `${API_ENDPOINTS.policies}`,
    needsOrgPrefix: true,
    payload: searchParams,
  }),
  getPolicy: ({ policyId }: apiTypesMap['getPolicy']['request']) => ({
    method: 'GET',
    url: `${API_ENDPOINTS.policies}/${policyId}`,
    needsOrgPrefix: true,
    payload: undefined,
  }),
  createPolicy: (payload: apiTypesMap['createPolicy']['request']) => ({
    method: 'POST',
    url: `${API_ENDPOINTS.policies}`,
    needsOrgPrefix: true,
    payload,
  }),
  deletePolicies: (payload: apiTypesMap['deletePolicies']['request']) => ({
    method: 'DELETE',
    url: `${API_ENDPOINTS.policies}`,
    needsOrgPrefix: true,
    payload,
  }),
  updatePolicy: ({ policyId, updatedParams }: apiTypesMap['updatePolicy']['request']) => ({
    method: 'PATCH',
    url: `${API_ENDPOINTS.policies}/${policyId}`,
    needsOrgPrefix: true,
    payload: updatedParams,
  }),
  deletePolicy: ({ policyId }: apiTypesMap['deletePolicy']['request']) => ({
    method: 'DELETE',
    url: `${API_ENDPOINTS.policies}/${policyId}`,
    needsOrgPrefix: true,
    payload: undefined,
  }),
  enablePolicy: ({ policyId }: apiTypesMap['enablePolicy']['request']) => ({
    method: 'PUT',
    url: `${API_ENDPOINTS.policies}/${policyId}/enable`,
    needsOrgPrefix: true,
    payload: undefined,
  }),
  enablePolicyUntil: ({
    policyId,
    updatedParams,
  }: apiTypesMap['enablePolicyUntil']['request']) => ({
    method: 'PUT',
    url: `${API_ENDPOINTS.policies}/${policyId}/enable-until`,
    needsOrgPrefix: true,
    payload: updatedParams,
  }),
  disablePolicy: ({ policyId }: apiTypesMap['disablePolicy']['request']) => ({
    method: 'PUT',
    url: `${API_ENDPOINTS.policies}/${policyId}/disable`,
    needsOrgPrefix: true,
    payload: undefined,
  }),
  enablePolicies: (payload: apiTypesMap['enablePolicies']['request']) => ({
    method: 'PUT',
    url: `${API_ENDPOINTS.policies}/enable`,
    needsOrgPrefix: true,
    payload: payload,
  }),
  disablePolicies: (payload: apiTypesMap['disablePolicies']['request']) => ({
    method: 'PUT',
    url: `${API_ENDPOINTS.policies}/disable`,
    needsOrgPrefix: true,
    payload: payload,
  }),
  getPolicySearchKeys: () => ({
    method: 'GET',
    url: `${API_ENDPOINTS.policies}/meta/search-keys`,
    needsOrgPrefix: true,
    payload: undefined,
  }),
  // SECURITY EVENTS
  getSecurityEvents: (searchParams: apiTypesMap['getSecurityEvents']['request']) => ({
    method: 'GET',
    url: `${API_ENDPOINTS.securityEvents}`,
    needsOrgPrefix: true,
    payload: searchParams,
  }),
  // ENROL AUTHORITY
  enrollAuthority: (payload: apiTypesMap['enrollAuthority']['request']) => ({
    method: 'POST',
    url: `${API_ENDPOINTS.authority}/enrol`,
    needsOrgPrefix: false,
    payload: payload,
  }),
  // PAYMENT
  upgradePlan: (payload: apiTypesMap['upgradePlan']['request']) => ({
    method: 'POST',
    url: `upgrade`,
    needsOrgPrefix: true,
    payload: payload,
  }),
  getBillingPortal: (payload: apiTypesMap['getBillingPortal']['request']) => ({
    method: 'GET',
    url: `billingportal`,
    needsOrgPrefix: true,
    payload,
  }),
  // DNS
  getDnsSummary: (payload: apiTypesMap['getDnsSummary']['request']) => ({
    method: 'GET',
    url: `${API_ENDPOINTS.dns}`,
    needsOrgPrefix: true,
    payload,
  }),
  getDnsZones: (searchParams: apiTypesMap['getDnsZones']['request']) => ({
    method: 'GET',
    url: `${API_ENDPOINTS.dns}/zones`,
    needsOrgPrefix: true,
    payload: searchParams,
  }),
  getDnsZoneDetails: ({ zoneId }: apiTypesMap['getDnsZoneDetails']['request']) => ({
    method: 'GET',
    url: `${API_ENDPOINTS.dns}/zones/${zoneId}`,
    needsOrgPrefix: true,
    payload: undefined,
  }),
  createDnsZone: (payload: apiTypesMap['createDnsZone']['request']) => ({
    method: 'POST',
    url: `${API_ENDPOINTS.dns}/zones`,
    needsOrgPrefix: true,
    payload,
  }),
  updateDnsZone: ({ zoneId, updatedParams }: apiTypesMap['updateDnsZone']['request']) => ({
    method: 'PATCH',
    url: `${API_ENDPOINTS.dns}/zones/${zoneId}`,
    needsOrgPrefix: true,
    payload: updatedParams,
  }),
  deleteDnsZone: ({ zoneId }: apiTypesMap['deleteDnsZone']['request']) => ({
    method: 'DELETE',
    url: `${API_ENDPOINTS.dns}/zones/${zoneId}`,
    needsOrgPrefix: true,
    payload: undefined,
  }),
  getDnsRecords: (searchParams: apiTypesMap['getDnsRecords']['request']) => ({
    method: 'GET',
    url: `${API_ENDPOINTS.dns}/records`,
    needsOrgPrefix: true,
    payload: searchParams,
  }),
  getDnsRecordDetails: ({ recordId }: apiTypesMap['getDnsRecordDetails']['request']) => ({
    method: 'GET',
    url: `${API_ENDPOINTS.dns}/records/${recordId}`,
    needsOrgPrefix: true,
    payload: undefined,
  }),
  createDnsRecord: (payload: apiTypesMap['createDnsRecord']['request']) => ({
    method: 'POST',
    url: `${API_ENDPOINTS.dns}/records`,
    needsOrgPrefix: true,
    payload,
  }),
  updateDnsRecord: ({ recordId, updatedParams }: apiTypesMap['updateDnsRecord']['request']) => ({
    method: 'PATCH',
    url: `${API_ENDPOINTS.dns}/records/${recordId}`,
    needsOrgPrefix: true,
    payload: updatedParams,
  }),
  deleteDnsRecord: ({ recordId }: apiTypesMap['deleteDnsRecord']['request']) => ({
    method: 'DELETE',
    url: `${API_ENDPOINTS.dns}/records/${recordId}`,
    needsOrgPrefix: true,
    payload: undefined,
  }),
  deleteDnsRecords: (payload: apiTypesMap['deleteDnsRecords']['request']) => ({
    method: 'DELETE',
    url: `${API_ENDPOINTS.dns}/records`,
    needsOrgPrefix: true,
    payload: payload,
  }),
  getDnsRecordSearchKeys: () => ({
    method: 'GET',
    url: `${API_ENDPOINTS.dns}/records/meta/search-keys`,
    needsOrgPrefix: true,
    payload: undefined,
  }),
  // TRUST REQUIREMENTS
  getTrustRequirements: (searchParams: apiTypesMap['getTrustRequirements']['request']) => ({
    method: 'GET',
    url: `${API_ENDPOINTS['trust-requirements']}`,
    needsOrgPrefix: true,
    payload: searchParams,
  }),
  deleteTrustRequirements: (payload: apiTypesMap['deleteTrustRequirements']['request']) => ({
    method: 'DELETE',
    url: `${API_ENDPOINTS['trust-requirements']}`,
    needsOrgPrefix: true,
    payload: payload,
  }),
  getTrustRequirement: ({ requirementId }: apiTypesMap['getTrustRequirement']['request']) => ({
    method: 'GET',
    url: `${API_ENDPOINTS['trust-requirements']}/${requirementId}`,
    needsOrgPrefix: true,
    payload: undefined,
  }),
  createTrustRequirement: (payload: apiTypesMap['createTrustRequirement']['request']) => ({
    method: 'POST',
    url: `${API_ENDPOINTS['trust-requirements']}`,
    needsOrgPrefix: true,
    payload,
  }),
  updateTrustRequirement: ({
    requirementId,
    updatedParams,
  }: apiTypesMap['updateTrustRequirement']['request']) => ({
    method: 'PATCH',
    url: `${API_ENDPOINTS['trust-requirements']}/${requirementId}`,
    needsOrgPrefix: true,
    payload: updatedParams,
  }),
  deleteTrustRequirement: ({
    requirementId,
  }: apiTypesMap['deleteTrustRequirement']['request']) => ({
    method: 'DELETE',
    url: `${API_ENDPOINTS['trust-requirements']}/${requirementId}`,
    needsOrgPrefix: true,
    payload: undefined,
  }),
  getTrustRequirementSearchKeys: () => ({
    method: 'GET',
    url: `${API_ENDPOINTS['trust-requirements']}/meta/search-keys`,
    needsOrgPrefix: true,
    payload: undefined,
  }),
};
