// Lib
import { put, call } from 'typed-redux-saga';
import { setSystemsShouldUpdate } from 'redux/actions/systems';
import { pushNotification } from 'redux/actions/app';

// Utils
import { ApiTypeCaller } from '../../api/utils';

import {
  IActionPayload,
  IEnableSystemsParams,
  IDisableSystemsParams,
  INotificationType,
} from 'types';
import { getCurrentOrganisationSaga } from '../organisation/getCurrentOrganisationSaga';
import { translator } from 'utils';

export function* enableDisableSystemsSaga(
  { payload }: IActionPayload<IEnableSystemsParams | IDisableSystemsParams>,
  enable = true
) {
  const { result, success } = yield* ApiTypeCaller(
    enable ? 'enableSystems' : 'disableSystems',
    payload
  );
  const translate = translator('notifications');

  if (success && result && result.systemsUpdated > 0) {
    yield put(setSystemsShouldUpdate());
    yield call(getCurrentOrganisationSaga);
    yield put(
      pushNotification({
        showType: INotificationType.Success,
        description: translate('systems_state_changed', {
          state: enable ? translate('enabled_lowercase') : translate('disabled_lowercase'),
        }),
      })
    );
  }
}
