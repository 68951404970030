// libs
import { call, select, put, delay } from 'typed-redux-saga';
import { CallEffect, SelectEffect } from 'redux-saga/effects';
import { apiService } from 'api/ApiService';

// selectors
import { selectNetworkConnectionError, selectOrgId } from 'redux/selectors';

// actions
import { pushNotification, setNetworkConnectionError } from 'redux/actions/app';

// Utils
import { PutEffect, SagaReturnType } from '@redux-saga/core/effects';
import { handleErrorsSaga } from './errors';

// types
import { apiDefinitionMap, apiTypesMap, INotificationConfig, INotificationType } from 'types';

// sagas
import { validateUserAuthSaga } from '../business/user/validateUserAuthSaga';
import { getOrganisationsSaga } from '../business/account/getOrganisationsSaga';
import { translator } from 'utils';

// constants
import { ENDPOINT_RETRY_DELAY, ENDPOINT_RETRY_NUMBER } from 'appConstants';

export function* ApiCaller<T extends (...args: any[]) => Promise<SagaReturnType<T>>>(
  apiFunction: T,
  ...params: Parameters<T>
): Generator<CallEffect<SagaReturnType<T>>, SagaReturnType<T>, SagaReturnType<T>> {
  const result = yield* call(apiFunction, ...params);
  return result;
}

function* AuthGuard() {
  try {
    const { success, error } = yield* validateUserAuthSaga();

    if (!success) {
      yield* put(
        pushNotification({
          description: error,
          showType: INotificationType.Error,
        })
      );
      return false;
    }
    return true;
  } catch {
    return false;
  }
}

export function* ApiTypeCaller<CallType extends keyof apiTypesMap>(
  type: CallType,
  params?: apiTypesMap[CallType]['request'],
  errorConfig?: {
    customErrorField?: ((error: any) => string) | string;
    customNotification?: INotificationConfig;
  }
): Generator<
  CallEffect | SelectEffect | PutEffect,
  apiTypesMap[CallType]['response'],
  apiTypesMap[CallType]['response']
> {
  // Auth guard
  const authorized = yield* call(AuthGuard);
  if (!authorized) return { success: false };

  const networkConnectionError = yield* select(selectNetworkConnectionError);

  // if we established that we have such error
  // on some request there is no need to try
  // it on other requests, we are just assuming
  // that server is down
  if (networkConnectionError) {
    return { success: false };
  }

  // Api call definition
  const { method, url, needsOrgPrefix, payload } = apiDefinitionMap[type](params);

  const orgId = yield* select(selectOrgId);
  const apiUrl = needsOrgPrefix ? `org/${orgId}/${url}` : url;

  for (let i = 0; i < ENDPOINT_RETRY_NUMBER; i++) {
    try {
      let response;
      if (payload) {
        response = yield* call(apiService.makeRequest, method, apiUrl, payload);
      } else {
        response = yield* call(apiService.makeRequest, method, apiUrl);
      }

      return {
        success: true,
        result: response,
      };
    } catch (error: any) {
      if (error?.status === 403) {
        if (i === 0) {
          yield* getOrganisationsSaga();
        } else {
          apiService.login();
        }
      }

      if (method === 'GET' && url === 'account') {
        if (i < ENDPOINT_RETRY_NUMBER - 1) {
          if (error?.status === 401) {
            apiService.login();
          }
          yield* delay(ENDPOINT_RETRY_DELAY);
          continue;
        } else {
          break;
        }
      }

      yield* handleErrorsSaga(
        error,
        url,
        errorConfig?.customErrorField,
        errorConfig?.customNotification
      );

      return { success: false };
    }
  }

  const translate = translator('global');

  yield* put(setNetworkConnectionError({ error: translate('network_connection_error') }));
  return { success: false };
}
