// hooks
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useEffect } from 'react';

// selectors
import { selectDnsZones, selectDnsSelectedZone } from 'redux/selectors';

// actions
import {
  getDnsZoneDetails,
  setDnsSelectedZone,
  setDnsZones,
  setDnsZonesDataPages,
} from 'redux/actions/dns';

// types
import { IDnsParams } from 'types';

// utils
import { equals } from 'ramda';

// constants
import { initialDnsZonesData } from 'redux/reducers/dns';

export const useGetZone = () => {
  const dispatch = useDispatch();

  const zones = useSelector(selectDnsZones, equals);
  const selectedZone = useSelector(selectDnsSelectedZone);

  const { zoneId } = useParams<keyof IDnsParams>();

  useEffect(() => {
    zoneId && dispatch(getDnsZoneDetails({ zoneId }));
  }, [dispatch, zoneId]);

  useEffect(() => {
    if (!zoneId && zones?.[0] && !selectedZone) {
      dispatch(getDnsZoneDetails({ zoneId: String(zones[0].id) }));
    }
  }, [dispatch, selectedZone, zoneId, zones]);

  useEffect(() => {
    return () => {
      dispatch(setDnsZones(initialDnsZonesData));
      dispatch(setDnsZonesDataPages({}));
      dispatch(setDnsSelectedZone(null));
    };
  }, [dispatch]);
  return { selectedZone };
};
