import { LOCATIONS } from 'appConstants';
import { ITrustRequirementPublicIpLocationCondition } from 'types/trust-requirements';

interface ILocationToText {
  alpha: ITrustRequirementPublicIpLocationCondition['value'];
}

export const locationToText = ({ alpha }: ILocationToText) => {
  return LOCATIONS.find(item => item.alpha === alpha)?.name;
};
