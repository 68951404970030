// Lib
import { useEffect } from 'react';

// Hooks
import { useDispatch } from 'react-redux';

// Actions
import { reLogin } from 'redux/actions/user';

// Components
import Layout from './Layout';

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(reLogin());
  }, [dispatch]);

  return <Layout />;
};

export default App;
