import { IPolicyAclProtocol, IPolicyAclType } from 'types';

export const ACLS_TYPES_CONFIG: Record<
  IPolicyAclType,
  { type: IPolicyAclType; protocol?: IPolicyAclProtocol; ports?: string | null }
> = {
  Any: {
    type: 'Any' as const,
    protocol: 'Any' as const,
    ports: null,
  },
  Custom: {
    type: 'Custom' as const,
  },
  HTTP: {
    type: 'HTTP' as const,
    protocol: 'Tcp' as const,
    ports: '80' as const,
  },
  HTTPS: {
    type: 'HTTPS' as const,
    protocol: 'Tcp' as const,
    ports: '443' as const,
  },
  SSH: {
    type: 'SSH' as const,
    protocol: 'Tcp' as const,
    ports: '22' as const,
  },
  RDP: {
    type: 'RDP' as const,
    protocol: 'Tcp' as const,
    ports: '3389' as const,
  },
  'SQL Server': {
    type: 'SQL Server' as const,
    protocol: 'Tcp' as const,
    ports: '1433' as const,
  },
  MySQL: {
    type: 'MySQL' as const,
    protocol: 'Tcp' as const,
    ports: '3306' as const,
  },
  PostgreSQL: {
    type: 'PostgreSQL' as const,
    protocol: 'Tcp' as const,
    ports: '5432' as const,
  },
  MongoDB: {
    type: 'MongoDB' as const,
    protocol: 'Tcp' as const,
    ports: '27017' as const,
  },
};

export const ACLS_PROTOCOLS_CONFIG: Record<
  Exclude<IPolicyAclProtocol, null>,
  { protocol: IPolicyAclProtocol; ports: string | null }
> = {
  Any: {
    protocol: 'Any' as const,
    ports: null,
  },
  Tcp: {
    protocol: 'Tcp' as const,
    ports: '0 - 65535' as const,
  },
  Udp: {
    protocol: 'Udp' as const,
    ports: '0 - 65535' as const,
  },
  Icmp: {
    protocol: 'Icmp' as const,
    ports: null,
  },
};

export const ACL_DESCRIPTION_CHARACTERS_LIMIT = 1024;
