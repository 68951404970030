import { ROUTE_PATHS } from 'appConstants';
import { IDetailsPolicyView, MainState } from 'types';

export const selectPolicySearchKeys = (state: MainState) => state.Policies.searchKeys;

export const selectPolicies = (state: MainState) => state.Policies.items;
export const selectPoliciesUpdateId = (state: MainState) => state.Policies.updateId;
export const selectPoliciesTotal = (state: MainState) => state.Policies.metadata?.total;
export const selectPoliciesIsLoading = (state: MainState) => state.Policies.isLoading;

// Details Data
export const selectAppDetailsPolicyId = ({ App }: MainState) => {
  if (App.detailsTab.type === 'policy-view' || App.detailsTab.type === 'policy-edit') {
    return App.detailsTab.data.id;
  }
};

export const selectAppDetailsPolicyDescription = ({ App }: MainState) => {
  if (App.detailsTab.type === 'policy-view' || App.detailsTab.type === 'policy-edit') {
    const policy = App.detailsTab.data as IDetailsPolicyView['data'];
    return policy.description;
  }
};
export const selectAppDetailsPolicyNewDescription = ({ App }: MainState) => {
  if (App.detailsTab.type === 'policy-create' || App.detailsTab.type === 'policy-edit') {
    return App.detailsTab.dataNew.description;
  }
};

export const selectAppDetailsPolicyNotes = ({ App }: MainState) => {
  if (App.detailsTab.type === 'policy-view' || App.detailsTab.type === 'policy-edit') {
    return App.detailsTab.data.notes;
  }
};

export const selectAppDetailsPolicyNewNotes = ({ App }: MainState) => {
  if (App.detailsTab.type === 'policy-create' || App.detailsTab.type === 'policy-edit') {
    return App.detailsTab.dataNew.notes;
  }
};

export const selectAppDetailsPolicyState = ({ App }: MainState) => {
  if (App.detailsTab.type === 'policy-view' || App.detailsTab.type === 'policy-edit') {
    return App.detailsTab.data.state;
  }
};

export const selectAppDetailsPolicyIsEnabled = ({ App }: MainState) => {
  if (App.detailsTab.type === 'policy-view' || App.detailsTab.type === 'policy-edit') {
    return App.detailsTab.data.isEnabled;
  }
};
export const selectAppDetailsPolicyNewIsEnabled = ({ App }: MainState) => {
  if (App.detailsTab.type === 'policy-edit' || App.detailsTab.type === 'policy-create') {
    return App.detailsTab.dataNew.isEnabled;
  }
};

export const selectAppDetailsPolicySenderTags = ({ App }: MainState) => {
  if (App.detailsTab.type === 'policy-view') {
    return App.detailsTab.data.senderTags;
  }
  return [];
};
export const selectAppDetailsPolicyReceiverTags = ({ App }: MainState) => {
  if (App.detailsTab.type === 'policy-view') {
    return App.detailsTab.data.receiverTags;
  }
  return [];
};

export const selectAppDetailsPolicyAcls = ({ App }: MainState) => {
  if (App.detailsTab.type === 'policy-view' || App.detailsTab.type === 'policy-edit') {
    return App.detailsTab.data.acls;
  }
};

export const selectAppDetailsPolicyTrustRequirements = ({ App }: MainState) => {
  if (App.detailsTab.type === 'policy-view' || App.detailsTab.type === 'policy-edit') {
    return App.detailsTab.data.senderTrustRequirements;
  }
};

export const selectAppDetailsPolicyActiveHoursData = ({ App }: MainState) => {
  if (App.detailsTab.type === 'policy-view') {
    return App.detailsTab.data.activeHours;
  }
  if (App.detailsTab.type === 'policy-edit' || App.detailsTab.type === 'policy-create') {
    return App.detailsTab.dataNew.activeHours;
  }
};

export const selectAppDetailsPolicyActiveDays = ({ App }: MainState) => {
  if (App.detailsTab.type === 'policy-view') {
    return App.detailsTab.data.activeHours?.daysOfWeek;
  }
};

export const selectAppDetailsPolicyStartTime = ({ App }: MainState) => {
  if (App.detailsTab.type === 'policy-view') {
    return App.detailsTab.data.activeHours?.startTime;
  }
};

export const selectAppDetailsPolicyEndTime = ({ App }: MainState) => {
  if (App.detailsTab.type === 'policy-view') {
    return App.detailsTab.data.activeHours?.endTime;
  }
};

export const selectAppDetailsPolicyActiveHoursTimeZone = ({ App }: MainState) => {
  if (App.detailsTab.type === 'policy-view') {
    return App.detailsTab.data.activeHours?.timeZoneId;
  }
};

export const selectAppDetailsPolicyAutoExpireData = ({ App }: MainState) => {
  if (App.detailsTab.type === 'policy-view') {
    return App.detailsTab.data.autoExpire;
  }
  if (App.detailsTab.type === 'policy-edit' || App.detailsTab.type === 'policy-create') {
    return App.detailsTab.dataNew.autoExpire;
  }
};

export const selectAppDetailsPolicyAutoExpireAction = ({ App }: MainState) => {
  if (App.detailsTab.type === 'policy-view') {
    return App.detailsTab.data.autoExpire?.expiryAction;
  }
};

export const selectAppDetailsPolicyAutoExpireDate = ({ App }: MainState) => {
  if (App.detailsTab.type === 'policy-view') {
    return App.detailsTab.data.autoExpire?.expiryDateTime;
  }
};

export const selectAppDetailsPolicyAutoExpireTimeZone = ({ App }: MainState) => {
  if (App.detailsTab.type === 'policy-view') {
    return App.detailsTab.data.autoExpire?.timeZoneId;
  }
};

// Details New Data
export const selectAppDetailsPolicyNewSenderTags = ({ App }: MainState) => {
  if (App.detailsTab.type === 'policy-edit') {
    return App.detailsTab.dataNew.senderTags;
  }
  if (App.detailsTab.type === 'policy-create') {
    return App.detailsTab.dataNew.senderTags;
  }
  return [];
};
export const selectAppDetailsPolicyNewReceiverTags = ({ App }: MainState) => {
  if (App.detailsTab.type === 'policy-edit') {
    return App.detailsTab.dataNew.receiverTags;
  }
  if (App.detailsTab.type === 'policy-create') {
    return App.detailsTab.dataNew.receiverTags;
  }
  return [];
};

export const selectAppDetailsPolicyNewAcls = ({ App }: MainState) => {
  if (App.detailsTab.type === 'policy-edit' || App.detailsTab.type === 'policy-create') {
    return App.detailsTab.dataNew.acls;
  }
};

export const selectAppDetailsPolicyNewTrustRequirements = ({ App }: MainState) => {
  if (App.detailsTab.type === 'policy-edit' || App.detailsTab.type === 'policy-create') {
    return App.detailsTab.dataNew.senderTrustRequirements;
  }
};

// Details Current Data
export const selectAppDetailsPolicyCurrentGatewayAllowedIpRanges = ({ App }: MainState) => {
  if (App.detailsTab.type === 'policy-view') {
    return App.detailsTab.data.gatewayAllowedIpRanges;
  }
  if (App.detailsTab.type === 'policy-edit' || App.detailsTab.type === 'policy-create') {
    return App.detailsTab.dataNew.gatewayAllowedIpRanges;
  }
  return [];
};

export const selectAppDetailCurrentPolicyType = ({ App: { detailsTab } }: MainState) => {
  if (detailsTab.type === 'policy-view') return detailsTab.data.type;
  if (detailsTab.type === 'policy-edit' || detailsTab.type === 'policy-create')
    return detailsTab.dataNew.type;
};

export const selectAppDetailCurrentPolicyGateways = ({ App: { detailsTab } }: MainState) => {
  if (detailsTab.type === 'policy-view') return detailsTab.data.gateways;
  if (detailsTab.type === 'policy-edit' || detailsTab.type === 'policy-create')
    return detailsTab.dataNew.gateways;
  return [];
};

export const selectAppDetailCurrentPolicyGatewayPriority = ({ App: { detailsTab } }: MainState) => {
  if (detailsTab.type === 'policy-view') return detailsTab.data.gatewayPriority;
  if (detailsTab.type === 'policy-edit' || detailsTab.type === 'policy-create')
    return detailsTab.dataNew.gatewayPriority;

  return null;
};

export const selectAppDetailsPolicyScrollToReceiverAccessControls = ({ App }: MainState) => {
  if (App.detailsTab.type === 'policy-edit') {
    return App.detailsTab.scrollToReceiverAccessControls;
  }
};

export const selectAppDetailsPolicyActiveNewHoursData = ({ App }: MainState) => {
  if (App.detailsTab.type === 'policy-edit' || App.detailsTab.type === 'policy-create') {
    return App.detailsTab.dataNew.activeHours;
  }
};

export const selectAppDetailsPolicyNewActiveDays = ({ App }: MainState) => {
  if (App.detailsTab.type === 'policy-edit' || App.detailsTab.type === 'policy-create') {
    return App.detailsTab.dataNew.activeHours?.daysOfWeek;
  }
};

export const selectAppDetailsPolicyNewStartTime = ({ App }: MainState) => {
  if (App.detailsTab.type === 'policy-edit' || App.detailsTab.type === 'policy-create') {
    return App.detailsTab.dataNew.activeHours?.startTime;
  }
};

export const selectAppDetailsPolicyNewEndTime = ({ App }: MainState) => {
  if (App.detailsTab.type === 'policy-edit' || App.detailsTab.type === 'policy-create') {
    return App.detailsTab.dataNew.activeHours?.endTime;
  }
};

export const selectAppDetailsPolicyActiveHoursNewTimeZone = ({ App }: MainState) => {
  if (App.detailsTab.type === 'policy-edit' || App.detailsTab.type === 'policy-create') {
    return App.detailsTab.dataNew.activeHours?.timeZoneId;
  }
};

export const selectAppDetailsPolicyAutoExpireNewAction = ({ App }: MainState) => {
  if (App.detailsTab.type === 'policy-edit' || App.detailsTab.type === 'policy-create') {
    return App.detailsTab.dataNew.autoExpire?.expiryAction;
  }
};

export const selectAppDetailsPolicyAutoExpireNewDate = ({ App }: MainState) => {
  if (App.detailsTab.type === 'policy-edit' || App.detailsTab.type === 'policy-create') {
    return App.detailsTab.dataNew.autoExpire?.expiryDateTime;
  }
};

export const selectAppDetailsPolicyAutoExpireNewTimeZone = ({ App }: MainState) => {
  if (App.detailsTab.type === 'policy-edit' || App.detailsTab.type === 'policy-create') {
    return App.detailsTab.dataNew.autoExpire?.timeZoneId;
  }
};

export const selectAppDetailsPolicyAutoExpireScrollTo = ({ App }: MainState) => {
  if (App.detailsTab.type === 'policy-edit') {
    return App.detailsTab.scrollToAutoExpire;
  }
};

export const selectIsPoliciesModule = ({
  router: {
    location: { pathname },
  },
  Account: { currentOrg },
}: MainState) => {
  return pathname.includes(`${currentOrg}${ROUTE_PATHS.policies}`);
};
