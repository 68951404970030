// Lib
import { pick } from 'ramda';
import { put, call, select } from 'typed-redux-saga';
import { IActionPayload, INotificationType, IUpdateOrganisationParams } from 'types';

// Actions
import { setOrganisationInfo } from '../../../actions/organisation';
import { getOrganisationsSaga } from '../account/getOrganisationsSaga';
import { pushNotification } from 'redux/actions/app';

// selectors
import { selectCurrentOrganisationInfo } from 'redux/selectors';

// Utils
import { ApiTypeCaller } from '../../api/utils';
import { diff, translator } from 'utils';

export function* updateOrganisationSaga({
  payload: updateParams,
}: IActionPayload<IUpdateOrganisationParams>) {
  const info = yield* select(selectCurrentOrganisationInfo);

  const currentParams: IUpdateOrganisationParams = pick(['name', 'website', 'phone'], info);

  const { success, result } = yield* ApiTypeCaller(
    'updateOrganisation',
    diff(updateParams, currentParams)
  );
  const translate = translator('notifications');

  if (success && result) {
    yield put(setOrganisationInfo(result));
    yield call(getOrganisationsSaga);
    yield put(
      pushNotification({
        showType: INotificationType.Success,
        description: translate('organisation_updated'),
      })
    );
  }
}
