// libs
import { call, put, select } from 'typed-redux-saga';

// actions
import { getSystems, setSystemsShouldUpdate } from 'redux/actions/unapproved-systems';
import { closeDetails, pushNotification } from 'redux/actions/app';

// selectors
import { selectAppDetailsSystemSystemId } from 'redux/selectors';

// types
import { IActionPayload, IApproveUnapprovedSystemsParams, INotificationType } from 'types';

// sagas
import { getCurrentOrganisationSaga } from '../organisation/getCurrentOrganisationSaga';

// utils
import { ApiTypeCaller } from '../../api/utils';
import { translator } from 'utils';

export function* approveSystemsSaga({ payload }: IActionPayload<IApproveUnapprovedSystemsParams>) {
  const selectedSystemId = yield* select(selectAppDetailsSystemSystemId);

  if (selectedSystemId !== undefined && payload.systemIds.includes(selectedSystemId)) {
    yield* put(closeDetails({}));
  }

  const { result, success } = yield* ApiTypeCaller('approveUnapprovedSystems', payload);

  if (success && result) {
    const translate = translator('notifications');

    if (result.systemsApproved > 0) {
      yield put(setSystemsShouldUpdate());
      yield call(getCurrentOrganisationSaga);
      yield put(
        pushNotification({
          showType: INotificationType.Success,
          description: translate('systems_approved'),
        })
      );
    } else {
      yield put(getSystems({}));
    }
  }
}
