// Lib
import { useEffect } from 'react';

// Hooks
import { useTheme } from 'styled-components';
import { useTranslations } from 'hooks/useTranslations';
import { useDispatch } from 'react-redux';

// Components
import { BlockWrapper, ContentWrapper } from '../../styled';
import { CustomText, StyledSubtitle } from 'typography/Text';
import { UserRows } from './components/UserRows';
import { InviteRows } from './components/InviteRows';
import { InviteCreate } from './components/InviteCreate';

// Actions
import { getOrganisationInvites, getOrganisationUsers } from 'redux/actions/organisation';

export const Users = () => {
  const dispatch = useDispatch();
  const {
    palette: { fonts },
  } = useTheme();
  const { translate } = useTranslations('settings');

  useEffect(() => {
    dispatch(getOrganisationInvites());
    dispatch(getOrganisationUsers());
  }, [dispatch]);

  return (
    <BlockWrapper mt="2.5rem">
      <ContentWrapper justify="flex-start">
        <StyledSubtitle>{translate('users')}</StyledSubtitle>
        <CustomText color={fonts.bodyLight} type="body-2">
          {translate('users_description')}
        </CustomText>
      </ContentWrapper>
      <ContentWrapper>
        <UserRows />
        <InviteRows />
        <InviteCreate />
      </ContentWrapper>
    </BlockWrapper>
  );
};
