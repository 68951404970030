// Lib
import { call, put, select } from 'typed-redux-saga';

// actions
import { setPoliciesShouldUpdate } from 'redux/actions/policies';
import {
  closeDetails,
  setModalContent,
  setModalIsLoading,
  pushNotification,
} from 'redux/actions/app';

// selectors
import { selectAppDetailsPolicyId } from 'redux/selectors';

// types
import { IActionPayload, IDeletePoliciesParams, INotificationType } from 'types';

// Utils
import { ApiTypeCaller } from '../../api/utils';
import { translator } from 'utils';

// sagas
import { getCurrentOrganisationSaga } from '../organisation/getCurrentOrganisationSaga';

export function* deletePoliciesSaga({ payload }: IActionPayload<IDeletePoliciesParams>) {
  yield put(setModalIsLoading(true));

  const selectedPolicyId = yield* select(selectAppDetailsPolicyId);

  if (selectedPolicyId !== undefined && payload.policyIds.includes(selectedPolicyId)) {
    yield* put(closeDetails({}));
  }
  const { result, success } = yield* ApiTypeCaller('deletePolicies', payload);

  if (success && result) {
    const translate = translator('notifications');

    if (result.policiesDeleted > 0) {
      yield put(setModalContent({ data: null, type: null }));
      yield put(setPoliciesShouldUpdate());
      yield call(getCurrentOrganisationSaga);
    }
    yield put(
      pushNotification({
        showType: INotificationType.Success,
        description: translate('policies_removed'),
      })
    );
  }
  yield put(setModalIsLoading(false));
}
