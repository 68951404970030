// libs
import { css } from 'styled-components';

// components
import { CloseButtonContainer } from './styled';
import { IconTrustRequirementDetailsDeleteCondition } from 'assets/svg';

// hooks
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

// actions
import { deleteTrustRequirementDetailsPublicIpIpRangeCondition } from 'redux/actions/app';

// types
import { ITrustRequirementPublicIpIpRangeConditionModified } from 'types';

interface ICloseButton {
  ipRange: ITrustRequirementPublicIpIpRangeConditionModified;
}

export const CloseButton = ({ ipRange }: ICloseButton) => {
  const dispatch = useDispatch();

  const onDelete = useCallback(() => {
    dispatch(
      deleteTrustRequirementDetailsPublicIpIpRangeCondition({
        id: ipRange.id,
      })
    );
  }, [ipRange.id, dispatch]);

  return (
    <CloseButtonContainer>
      <IconTrustRequirementDetailsDeleteCondition
        onClick={onDelete}
        css={css`
          cursor: pointer;
        `}
        width="1.25rem"
        height="1.25rem"
      />
    </CloseButtonContainer>
  );
};
