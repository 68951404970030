import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;

  top: 1.625rem;

  display: flex;
  flex-direction: row;
  align-items: center;
`;
