// libs
import { useCallback, useState } from 'react';
import { css, useTheme } from 'styled-components';
import { Trans } from 'react-i18next';

// components
import { CustomButton, CheckboxWithDesc, Loader, CustomButtonLink, ExternalLink } from 'components';
import { CustomText } from 'typography/Text';
import { CodesDescWrapper } from './styled';
import { ModalFooter, ModalHeader, ModalScrollablePart } from 'components/Modal';
import { CommandLineCode } from 'typography/Code';
import { IconAccountRecoveryCodesModal } from 'assets/svg';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { useDispatch, useSelector } from 'react-redux';
import { useSubmitOnEnter } from 'hooks/useSubmitOnEnter';

// actions
import { setModalContent } from 'redux/actions/app';
import { enable2FA } from 'redux/actions/account';

// selectors
import { selectModalData, selectModalIsLoading } from 'redux/selectors';

const LINK_LASTPASS = 'https://www.lastpass.com/';
const LINK_1PASSWORD = 'https://1password.com/';
const LINK_KEEPER = 'https://www.keepersecurity.com/';

export const RecoveryCodesModal = () => {
  const dispatch = useDispatch();

  const { translate } = useTranslations('account');

  const {
    palette: {
      fonts: { bodyDark, title },
    },
  } = useTheme();

  const [savedCheck, setSavedCheck] = useState(false);

  const recoveryArr = useSelector(selectModalData) as string[];
  const isLoading = useSelector(selectModalIsLoading);

  const isButtonEnabled = savedCheck && !isLoading;

  const onBack = () => {
    dispatch(setModalContent({ type: 'enable-2FA', data: null }));
  };

  const onEnable2FA = useCallback(() => {
    if (savedCheck) {
      dispatch(enable2FA());
    }
  }, [dispatch, savedCheck]);

  useSubmitOnEnter({ callback: isButtonEnabled ? onEnable2FA : () => {} });

  return (
    <>
      <ModalHeader
        title={translate('recovery_codes_modal.title')}
        icon={<IconAccountRecoveryCodesModal width="2.5rem" height="2.5rem" />}
      />
      <ModalScrollablePart>
        <CustomText
          css={css`
            display: block;
            margin-bottom: 2rem;
          `}
          type="body-2"
          color={bodyDark}
        >
          {translate('recovery_codes_modal.desc')}
        </CustomText>
        {/* recoveryApp somehow becomes null when you click enable, causing error */}
        <CommandLineCode code={recoveryArr && recoveryArr.join('\n')} />
        <CodesDescWrapper>
          <CustomText type="body-2" color={title}>
            <Trans
              i18nKey={translate('recovery_codes_modal.desc2')}
              components={[
                <br key={0} />, // Comma after Lastpass breaks UI (figma letter-spacing mismatch)
                <ExternalLink
                  key={1}
                  textType="body-2"
                  href={LINK_LASTPASS}
                  text={translate('recovery_codes_modal.lastpass')}
                />,
                <ExternalLink
                  key={2}
                  textType="body-2"
                  href={LINK_1PASSWORD}
                  text={translate('recovery_codes_modal.1password')}
                />,
                <ExternalLink
                  key={3}
                  textType="body-2"
                  href={LINK_KEEPER}
                  text={translate('recovery_codes_modal.keeper')}
                />,
              ]}
            />
          </CustomText>
        </CodesDescWrapper>
        <CheckboxWithDesc
          css={css`
            margin-bottom: 1.5rem;
          `}
          isDisabled={savedCheck}
          onChange={() => setSavedCheck(!savedCheck)}
          color={bodyDark}
          desc={translate('recovery_codes_modal.checkbox_desc')}
        />
      </ModalScrollablePart>

      <ModalFooter>
        <CustomButtonLink size="large" onClick={onBack}>
          {translate('back')}
        </CustomButtonLink>
        <CustomButton
          size="large"
          width="5.5625rem"
          kind={isButtonEnabled ? 'primary' : 'disabled'}
          onClick={onEnable2FA}
        >
          {isLoading ? <Loader /> : translate('recovery_codes_modal.enable')}
        </CustomButton>
      </ModalFooter>
    </>
  );
};
