// libraries
import { SyntheticEvent, useCallback, memo, useMemo, useEffect } from 'react';

// hooks

import { useDispatch, useSelector } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';

// Components
import { CustomTable, TableSelectionContainer } from 'components';
import { EnrolledKeysCols } from './Columns';

// utils
import { emptyEnrolledKeys } from '.';
import { equals } from 'ramda';

// actions
import { redirectControl } from 'redux/actions/app';

// selectors
import { selectEnrolmentKeys } from 'redux/selectors';

// Types
import { ISimpleEnrolmentKey } from 'types';
import { TableProps } from 'antd/lib/table';

// constants
import { ITEMS_PER_TABLE_PAGE } from 'appConstants/ui';

interface ITable {
  total: number;
  page: number;
  setPage: (page: number) => void;
  isLoading: boolean;
  bulkSelectedKeys: ISimpleEnrolmentKey[];
  setBulkSelectedKeys: (bulkSelectedKeys: ISimpleEnrolmentKey[]) => void;
  selectedKeyId: string | undefined;
  emptyText?: React.ReactNode;
}

export const Table = memo(
  ({
    page,
    setPage,
    total,
    isLoading,
    bulkSelectedKeys,
    setBulkSelectedKeys,
    selectedKeyId,
    emptyText,
  }: ITable) => {
    const dispatch = useDispatch();

    const { translate } = useTranslations('enrolment-keys');
    const { translate: translateGlobal } = useTranslations('global');

    const enrolmentKeys = useSelector(selectEnrolmentKeys);

    const onRowClick = useCallback(
      (enrolmemtKey: ISimpleEnrolmentKey) => {
        if (!isLoading) {
          {
            dispatch(redirectControl({ route: `/keys/${enrolmemtKey.id}`, routePush: true }));
          }
        }
      },
      [dispatch, isLoading]
    );

    const columns = EnrolledKeysCols({
      isLoading,
      translate,
      bulkSelectedKeys,
      setBulkSelectedKeys,
      selectedKeyId,
    });

    const rowSelectionRender = useCallback(
      (
        _checked: boolean,
        _record: ISimpleEnrolmentKey,
        _index: number,
        originNode: React.ReactNode
      ) => {
        return (
          <TableSelectionContainer
            data-test="enrolment-key-table-selection"
            onClick={(event: SyntheticEvent) => event.stopPropagation()}
          >
            {originNode}
          </TableSelectionContainer>
        );
      },
      []
    );

    const rowSelection: TableProps<ISimpleEnrolmentKey>['rowSelection'] = useMemo(
      () => ({
        checkStrictly: true,
        onChange: (_, selectedKeys: ISimpleEnrolmentKey[]) => {
          if (!isLoading) {
            setBulkSelectedKeys(selectedKeys);
          }
        },
        selectedRowKeys: bulkSelectedKeys.map(e => e.id),
        renderCell: rowSelectionRender,
        //@ts-ignore
        getCheckboxProps: () => ({
          'aria-label': translateGlobal('aria_labels.select_row'),
        }),
      }),
      [bulkSelectedKeys, isLoading, rowSelectionRender, setBulkSelectedKeys, translateGlobal]
    );

    // when we bulk select element and then remove it through action dropdown
    // bulkSelectedSystems doesn't update without this useEffect
    useEffect(() => {
      const newBulkSelectedKeys = bulkSelectedKeys.filter(bulkSelectedKey =>
        enrolmentKeys.some(key => key.id === bulkSelectedKey.id)
      );
      if (!equals(newBulkSelectedKeys, bulkSelectedKeys)) {
        setBulkSelectedKeys(newBulkSelectedKeys);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [enrolmentKeys]);

    return (
      <CustomTable
        data-test="enrolment-keys-table"
        locale={{ emptyText }}
        headerHeight="2.5rem"
        rowHeight="3.375rem"
        rowKey="id"
        columns={columns}
        dataSource={isLoading && !enrolmentKeys.length ? emptyEnrolledKeys : enrolmentKeys}
        onRowClick={onRowClick}
        elevated={true}
        selectedRowKey={selectedKeyId}
        rowSelection={rowSelection}
        pagination={{
          showSizeChanger: false,
          hideOnSinglePage: true,
          pageSize: ITEMS_PER_TABLE_PAGE,
          position: ['bottomCenter'],
          total: total,
          defaultCurrent: page + 1,
          onChange: page => {
            setPage(page - 1);
          },
        }}
      />
    );
  },
  equals
);
