// components
import { PolicyMembersGeneralBlock } from './General';
import { PolicyMembersGatewayBlock } from './Gateway';

// hooks
import { useSelector } from 'react-redux';

// selectors
import { selectAppDetailCurrentPolicyType } from 'redux/selectors';

export const PolicyMembersBlock = () => {
  const policyType = useSelector(selectAppDetailCurrentPolicyType);

  return (
    <>
      {policyType === 'General' && <PolicyMembersGeneralBlock />}
      {policyType === 'Gateway' && <PolicyMembersGatewayBlock />}
    </>
  );
};
