// libs
import { setNetworkConnectionError } from 'redux/actions/app';
import { call, put } from 'typed-redux-saga';
import { translator } from 'utils';
import { apiService } from '../../../../api/ApiService';

export function* validateUserAuthSaga() {
  const { success, profile, error } = yield* call([apiService, apiService.validateUserAuth]);

  const translate = translator('global');

  if (error) {
    yield* put(setNetworkConnectionError({ error: translate('network_connection_error') }));
  }

  return { success, profile, error };
}
