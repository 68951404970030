// libs
import { css } from 'styled-components';

// components
import { CustomText } from 'typography/Text';

// hooks
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';

// selectors
import { selectAppDetailsDnsRecordZoneName } from 'redux/selectors';

export const ZoneView = () => {
  const {
    palette: { fonts },
  } = useTheme();

  const zoneName = useSelector(selectAppDetailsDnsRecordZoneName);

  return (
    <CustomText
      css={css`
        display: block;
      `}
      ellipsis={true}
      title={zoneName}
      type="body-2"
      color={fonts.title}
    >
      {zoneName}
    </CustomText>
  );
};
