import { FC } from 'react';
import { CustomText } from 'typography/Text';
import { TFunction } from 'react-i18next/.';
import { CustomButton } from 'components';
import { css, useTheme } from 'styled-components';

import {
  SystemRecordActions,
  ISystemSummaryModel,
  IEnrolmentKey,
  IPolicy,
  translations,
  PolicyRecordActions,
} from 'types';
import { BulkActionsContainer, ClearButton } from './styled';
import { useTranslations } from 'hooks/useTranslations';

type SelectedItem = ISystemSummaryModel | IEnrolmentKey | IPolicy;

interface IActionButtonProps {
  translate: TFunction<translations>;
  selectedItems: SelectedItem[];
  resetSelection: () => void;
  onActionClick: (type: SystemRecordActions & PolicyRecordActions) => void;
}

export const BulkActions: FC<IActionButtonProps> = ({
  selectedItems,
  translate,
  resetSelection,
  onActionClick,
  ...rest
}) => {
  const {
    palette: { fonts },
  } = useTheme();

  const { translate: translateGlobal } = useTranslations('global');

  const disabledExists = selectedItems.find(system => system.isEnabled === false);
  const enabledExists = selectedItems.find(system => system.isEnabled === true);

  return (
    <BulkActionsContainer {...rest}>
      <CustomText type="sub-heading-2" color={fonts.title}>
        {selectedItems.length}{' '}
        {selectedItems.length !== 1
          ? translateGlobal('bulk.items_selected', {
              module: translate('modules.items'),
            })
          : translateGlobal('bulk.item_selected', {
              module: translate('modules.item'),
            })}
      </CustomText>
      <ClearButton type="link" onClick={resetSelection}>
        {translateGlobal('bulk.clear_all')}
      </ClearButton>

      {disabledExists && (
        <CustomButton
          kind="secondary"
          onClick={() => onActionClick('enable')}
          css={css`
            margin-right: 1rem;
          `}
        >
          {translateGlobal('bulk.enable')}
        </CustomButton>
      )}

      {enabledExists && (
        <CustomButton
          kind="secondary"
          onClick={() => onActionClick('disable')}
          css={css`
            margin-right: 1rem;
          `}
        >
          {translateGlobal('bulk.disable')}
        </CustomButton>
      )}

      <CustomButton
        kind="secondaryDanger"
        onClick={() => onActionClick('remove')}
        css={css`
          margin-right: 1rem;
        `}
      >
        {translateGlobal('bulk.remove')}
      </CustomButton>
    </BulkActionsContainer>
  );
};

export { ClearButton as BulkClearButton } from './styled';
