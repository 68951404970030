export const STARTER_PLAN = 'Starter';
export const BUSINESS_PLAN = 'Business';
export const ENTERPRISE_PLAN = 'Enterprise';
export const EXTERNAL_PLAN = 'External';
export const MSP_PLAN = 'Msp';
export const MSP_MANAGED_PLAN = 'MspManaged';

export const MAX_BUSINESS_CAPACITY = 999999;
export const MIN_BUSINESS_CAPACITY = 2;
export const DEFAULT_BUSINESS_CAPACITY = 5;

export const MAX_ENTERPRISE_CAPACITY = 999999;

export const UNLIMITED_USES_AMOUNT = -1;
