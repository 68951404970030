// components
import { IpConstraintsView } from './View';
import { IpConstraintsEdit } from './Edit';

// hooks
import { useSelector } from 'react-redux';

// selectors
import { selectAppDetailsTabType } from 'redux/selectors';

export const IpConstraintsBlock = () => {
  const type = useSelector(selectAppDetailsTabType);

  return (
    <>
      {type === 'enrolment-key-view' && <IpConstraintsView />}
      {(type === 'enrolment-key-edit' || type === 'enrolment-key-create') && <IpConstraintsEdit />}
    </>
  );
};
