import { createAction } from '@reduxjs/toolkit';
import {
  IRecordDetails,
  IRecordCreateDetails,
  ILoadDnsRecordDetails,
  DNSRecordDetailsPayload,
} from 'types';

export const updateDnsRecordDetails = createAction<Partial<IRecordDetails | IRecordCreateDetails>>(
  'main/UPDATE_DNS_RECORD_DETAILS'
);
export const loadDnsRecordDetails = createAction<ILoadDnsRecordDetails>(
  'main/LOAD_DNS_RECORD_DETAILS'
);
export const createDNSRecordSideMenu = createAction<DNSRecordDetailsPayload>(
  'main/CREATE_DNS_RECORD_SIDE_MENU'
);
