// components
import { Copy } from 'components';
import { CustomText } from 'typography/Text';

// hooks
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';

// selectors
import { selectAppDetailsDnsRecordFqdn } from 'redux/selectors';

interface IFqdnView {
  visilbe: boolean;
}

export const FqdnView = ({ visilbe }: IFqdnView) => {
  const {
    palette: { fonts },
  } = useTheme();

  const fqdn = useSelector(selectAppDetailsDnsRecordFqdn) || '';

  return (
    <Copy visible={visilbe} copyingValue={fqdn}>
      <CustomText ellipsis={true} title={fqdn} type="body-2" color={fonts.title}>
        {fqdn}
      </CustomText>
    </Copy>
  );
};
