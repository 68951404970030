// libs
import { call } from 'typed-redux-saga';
import { IVerifyAccount2FACodeParams, IActionPayload } from 'types';

import { validateStepUpTokenSaga } from './validateStepUpTokenSaga';

// utils
import { ApiTypeCaller } from '../../api/utils';

// constants
import { ACCOUNT_ENABLE_2FA_AUTH_CODE_ERROR_FIELD } from 'appConstants';

export function* verify2FACodeSaga({
  payload: { code },
}: IActionPayload<Omit<IVerifyAccount2FACodeParams, 'stepUpToken'>>) {
  const token = yield* call(validateStepUpTokenSaga);

  if (token) {
    const { success } = yield* ApiTypeCaller(
      'verifyAccount2FACode',
      {
        stepUpToken: token,
        code,
      },
      { customErrorField: ACCOUNT_ENABLE_2FA_AUTH_CODE_ERROR_FIELD }
    );

    if (success) {
      return success;
    }
  }
}
