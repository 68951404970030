// components
import { Details } from 'components';
import { CustomText } from 'typography/Text';

// hooks
import { useTheme } from 'styled-components';
import { useSelector } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';

// selectors
import {
  selectAppDetailsEnrolmentKeyEnrolledCount,
  selectAppDetailsEnrolmentKeyUnapprovedCount,
  selectAppDetailsTabType,
} from 'redux/selectors';

export const SystemsEnrolledBlock = () => {
  const { translate } = useTranslations('enrolment-keys');

  const {
    palette: { fonts },
  } = useTheme();

  const type = useSelector(selectAppDetailsTabType);

  const enrolledCount = useSelector(selectAppDetailsEnrolmentKeyEnrolledCount);
  const unapprovedCount = useSelector(selectAppDetailsEnrolmentKeyUnapprovedCount);

  if (type === 'enrolment-key-create') return null;

  return (
    <Details.KeyValue height="2.875rem" title={translate('details.systems_enrolled')}>
      {enrolledCount || unapprovedCount ? (
        <CustomText type="body-2" color={fonts.title}>
          {enrolledCount} {translate('details.enrolled')}, {unapprovedCount}{' '}
          {translate('details.to_approve')}
        </CustomText>
      ) : (
        <CustomText type="body-2" color={fonts.title}>
          {translate('details.not_used')}
        </CustomText>
      )}
    </Details.KeyValue>
  );
};
