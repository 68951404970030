export const stringToColour = (str: string) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let colour = '#';
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    colour += ('00' + value.toString(16)).substr(-2);
  }
  return colour;
};

const orgColors = [
  '#FF6B6B',
  '#FF8C56',
  '#69DB7C',
  '#FFA94D',
  '#FFD43B',
  '#A9E34B',
  '#38D9A9',
  '#3BC9DB',
  '#5C7CFA',
  '#CC5DE8',
  '#F06595',
  '#845EF7',
];

export const orgColor = (str: string) =>
  str.length === 0 ? orgColors[0] : orgColors[str.charCodeAt(0) % orgColors.length];
