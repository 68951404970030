// Lib
import { put } from 'typed-redux-saga';
import { setIsLoading, setSystems } from 'redux/actions/systems';

// Types
import { IActionPayload, IGetSystems } from 'types';

// Api
import { ApiTypeCaller } from '../../api/utils';

// Utils
import { SyncQueryParams } from '../../utils';

export function* getSystemsSaga({
  payload: { syncQueryParams = true, ...payload },
}: IActionPayload<IGetSystems>) {
  yield put(setIsLoading(true));

  if (syncQueryParams) {
    payload = yield* SyncQueryParams(payload);
  }

  const { success, result } = yield* ApiTypeCaller('getSystems', payload);
  if (success && result) yield put(setSystems(result));
}
