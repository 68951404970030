// libs
import { css } from 'styled-components';

// components
import { CloseButtonContainer } from './styled';
import { IconTrustRequirementDetailsDeleteCondition } from 'assets/svg';

// hooks
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

// actions
import { deletePolicyDetailsAllowedSubnet } from 'redux/actions/app';

// types
import { IPolicyModifiedGatewayAllowedIpRange } from 'types';

interface ICloseButton {
  allowedIpRange: IPolicyModifiedGatewayAllowedIpRange;
}

export const CloseButton = ({ allowedIpRange }: ICloseButton) => {
  const dispatch = useDispatch();

  const onDelete = useCallback(() => {
    dispatch(
      deletePolicyDetailsAllowedSubnet({
        id: allowedIpRange.id,
      })
    );
  }, [allowedIpRange.id, dispatch]);

  return (
    <CloseButtonContainer>
      <IconTrustRequirementDetailsDeleteCondition
        onClick={onDelete}
        css={css`
          cursor: pointer;
        `}
        width="1.25rem"
        height="1.25rem"
      />
    </CloseButtonContainer>
  );
};
