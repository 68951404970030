// libs
import { css } from 'styled-components';

// components
import { Details } from 'components';
import { RemoveAfterView } from './View';
import { RemoveAfterEdit } from './Edit';

// hooks
import { useSelector } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';

// selectors
import { selectAppDetailsEnrolmentKeyCurrentType, selectAppDetailsTabType } from 'redux/selectors';

export const RemoveAfterBlock = () => {
  const { translate } = useTranslations('enrolment-keys');

  const type = useSelector(selectAppDetailsTabType);
  const purpose = useSelector(selectAppDetailsEnrolmentKeyCurrentType);

  if (purpose === 'GeneralPurpose') return null;

  return (
    <Details.KeyValue
      alignItems="center"
      contentCss={css`
        align-items: center;
      `}
      height="1.375rem"
      title={translate('details.remove_after')}
    >
      {type === 'enrolment-key-view' && <RemoveAfterView />}
      {(type === 'enrolment-key-edit' || type === 'enrolment-key-create') && <RemoveAfterEdit />}
    </Details.KeyValue>
  );
};
