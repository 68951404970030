/* eslint-disable  @typescript-eslint/no-non-null-assertion */
// components
import { Input, InputRef } from 'antd';
import {
  DropdownContainer,
  DropdownContent,
  FocusContainer,
  InputWrapper,
  SearchPrefix,
  SearchSuffix,
  styledInput,
} from './styled';
import { CustomText } from 'typography/Text';
import { DropdownDefaultKey } from './DropdownDefaultKey';
import { DropdownKey } from './DropdownKey';
import { Suggestions } from './Suggestions';
import { Loading } from './Suggestions/Loading';

// hooks
import { useGetCaretCoordinates } from './hooks';
import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { css, useTheme } from 'styled-components';
import { useSelector } from 'react-redux';
import { useKeyboardControl } from './hooks/useKeyboardControl';

// selectors
import { selectSearchLoading } from 'redux/selectors';

// types
import { SearchProps } from 'antd/lib/input';
import { ISearchKey } from 'types';

// utils
import { parseSearchString, getNewActiveKey, IActiveKey } from './helpers';
import { useTranslations } from 'hooks/useTranslations';

interface ISearchFilter extends SearchProps {
  onSearchKeyUp: (newSearchValue: string) => void;
  initialValue?: string | null;
  setSearchValue?: (searchValue: string) => void;
  width?: string;
  searchKeys: ISearchKey[] | null;
}

export type IInputArrowConfig = {
  length: number;
  onEnter: () => void;
  isKeyInactive: boolean;
} | null;

export type ISelectedIndex = null | number;

export const SearchInput = ({
  onSearchKeyUp,
  initialValue,
  maxLength,
  width,
  searchKeys,
  ...rest
}: ISearchFilter) => {
  const {
    palette: { fonts },
  } = useTheme();

  const { translate } = useTranslations('global');

  const [searchValue, setSearchValue] = useState(initialValue || '');
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
  const [activeKey, setActiveKey] = useState<IActiveKey | null>(null);
  // this variable is for setting min-height for loading.
  // when you load items from systems, tags, keys; height of loading screen
  // should equal previous height to prevent jumping behaviour
  const [dropdownLoadingMinHeight, setDropdownLoadingMinHeight] = useState<number | null>(null);

  const isLoading = useSelector(selectSearchLoading);

  const ref = useRef<InputRef>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const setSearchValueRef = (newSearchValue: string) => {
    onChangeValue(newSearchValue);
  };

  const { getCaretPosition } = useGetCaretCoordinates(ref.current ? ref.current.input : null);

  useEffect(() => {
    setSearchValue(initialValue || '');
  }, [initialValue]);

  const keyConfig = useMemo(() => {
    if (!searchKeys) return null;

    return parseSearchString({ search: searchValue, searchKeys });
  }, [searchKeys, searchValue]);

  const onFocus = useCallback(() => {
    setDropdownIsOpen(true);
  }, []);

  const onBlur = useCallback(() => {
    setDropdownIsOpen(false);
  }, []);

  const getActiveKeyOnIndex = useCallback(
    (selectionStart: number | null) => {
      if (dropdownRef.current?.clientHeight !== dropdownLoadingMinHeight) {
        setDropdownLoadingMinHeight(dropdownRef.current?.clientHeight || null);
      }

      if (selectionStart === null) {
        return;
      }

      let index: number | null = null;
      index = selectionStart > 0 ? selectionStart - 1 : selectionStart;

      if (index !== null) {
        const newActiveKey = getNewActiveKey({ index, keyConfig, searchKeys, searchValue });

        setActiveKey(newActiveKey);
      } else {
        setActiveKey(null);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeKey, keyConfig, searchKeys, searchValue]
  );

  // this function moves caret position to end of value added to search
  // and scrolls to it to make it visible if it's not
  const setSelectionRange = useCallback(
    (index: number) => {
      Promise.resolve()
        .then(() => {
          ref.current && ref.current.input?.setSelectionRange(index, index);
        })
        .then(() => {
          if (ref.current?.input) {
            const left = getCaretPosition(index);

            if (left && left >= ref.current.input.offsetWidth + ref!.current.input.scrollLeft) {
              // we want to scroll to position where caret is in center
              ref!.current.input.scrollLeft = left - ref.current.input.offsetWidth / 2;
            }
          }
        });
    },
    [getCaretPosition]
  );

  const onSelect = useCallback(
    (e: React.SyntheticEvent<HTMLInputElement, Event>) => {
      getActiveKeyOnIndex(e.currentTarget.selectionStart);
    },
    [getActiveKeyOnIndex]
  );

  const onChangeValue = useCallback(
    (value: string) => {
      let resultValue: string;

      if (maxLength && value.length > maxLength) {
        const cutValue = value.slice(0, maxLength);
        resultValue = cutValue;
      } else {
        resultValue = value;
      }
      setSearchValue?.(resultValue);
      onSearchKeyUp && onSearchKeyUp(resultValue);
    },
    [maxLength, onSearchKeyUp]
  );

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChangeValue(e.target.value);
    },
    [onChangeValue]
  );

  const addKeyToSearch = useCallback(
    (key: string) => {
      const trimmedSearchValue = searchValue.trim();

      if (keyConfig === null) {
        if (trimmedSearchValue === '') {
          setSearchValue(`${key}:`);
        } else {
          setSearchValue(`${trimmedSearchValue} ${key}:`);
        }

        return;
      }

      const doesKeyExist = Object.keys(keyConfig).includes(key);

      if (doesKeyExist) {
        const rangeEnd = keyConfig[key].totalRange?.end;

        rangeEnd && setSelectionRange(rangeEnd);
      } else {
        setSearchValue(`${trimmedSearchValue} ${key}:`);

        Promise.resolve().then(() => {
          if (ref.current?.input) {
            ref!.current.input.scrollLeft = ref.current.input.scrollWidth;
          }
        });
      }
    },
    [keyConfig, searchValue, setSelectionRange]
  );

  const dropdownKeyOnClick = useCallback(() => {
    if (ref.current?.input) {
      ref.current.input.scrollLeft = ref.current.input.scrollWidth;
    }
  }, []);

  const dropdownKeyOnKeyDown = useCallback(
    (name: string) => (e: React.KeyboardEvent<HTMLButtonElement>) => {
      if (e.key === 'Enter') {
        addKeyToSearch(name);

        if (ref.current?.input) {
          ref.current.input.scrollLeft = ref.current.input.scrollWidth;
        }

        Promise.resolve().then(() => {
          ref?.current?.input?.focus();
        });
      }
    },
    [addKeyToSearch]
  );

  const dropdownKeyOnMouseDown = useCallback(
    (name: string) => () => {
      if (ref.current) {
        addKeyToSearch(name);
      }
    },
    [addKeyToSearch]
  );

  const { keyboardControl, selectedIndex, setInputArrowNavigationConfig } = useKeyboardControl({
    activeKey,
    dropdownKeyOnClick,
    dropdownKeyOnMouseDown,
    dropdownRef,
    keyConfig,
    searchKeys,
    setDropdownIsOpen,
  });

  return (
    <InputWrapper width={width}>
      <Input.Search
        ref={ref}
        tabIndex={0}
        value={searchValue}
        size="large"
        css={styledInput}
        placeholder={translate('search.placeholder')}
        maxLength={maxLength}
        prefix={<SearchPrefix />}
        suffix={searchValue ? <SearchSuffix {...{ onSearchKeyUp, setSearchValue }} /> : <span />}
        onChange={onChange}
        onSelect={onSelect}
        onFocus={onFocus}
        onBlur={onBlur}
        onKeyDown={keyboardControl}
        {...rest}
      />
      {searchKeys && (
        <DropdownContainer
          minHeight={isLoading ? dropdownLoadingMinHeight : null}
          ref={dropdownRef}
          isOpen={dropdownIsOpen}
          onMouseDown={event => {
            event?.preventDefault();
          }}
        >
          <DropdownContent isLoading={isLoading}>
            <FocusContainer>
              <CustomText
                css={css`
                  display: block;
                `}
                type="body-2"
                color={fonts.bodyLight}
              >
                {translate('search.focus')}
                {activeKey ? ` (${activeKey.searchKey?.name})` : ''}:
              </CustomText>

              {activeKey && (
                <CustomText
                  css={css`
                    display: block;
                    margin-top: 0.25rem;
                  `}
                  type="hint"
                  color={fonts.help}
                >
                  {activeKey.searchKey?.description}
                </CustomText>
              )}
            </FocusContainer>

            {!activeKey &&
              searchKeys.map((searchKey, index) =>
                searchKey.isDefault ? (
                  <DropdownDefaultKey
                    isActive={activeKey === null}
                    searchKey={searchKey}
                    key={searchKey.name}
                  />
                ) : (
                  <DropdownKey
                    isSelected={index === selectedIndex}
                    searchKey={searchKey}
                    key={searchKey.name}
                    onClick={dropdownKeyOnClick}
                    onKeyDown={dropdownKeyOnKeyDown(searchKey.name)}
                    onMouseDown={dropdownKeyOnMouseDown(searchKey.name)}
                  />
                )
              )}

            {activeKey && keyConfig && (
              <Suggestions
                searchValue={searchValue}
                setSearchValue={setSearchValueRef}
                activeKeyValue={activeKey.modifiedValue}
                searchKey={activeKey.searchKey}
                keyConfig={keyConfig[activeKey.searchKey.name]}
                setSelectionRange={setSelectionRange}
                setInputArrowNavigationConfig={setInputArrowNavigationConfig}
                selectedIndex={selectedIndex}
              />
            )}
          </DropdownContent>
          {isLoading && <Loading />}
        </DropdownContainer>
      )}
    </InputWrapper>
  );
};
