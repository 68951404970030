// components
import { CustomText } from 'typography/Text';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { css, useTheme } from 'styled-components';

export const Loading = () => {
  const { translate } = useTranslations('global');

  const {
    palette: { fonts },
  } = useTheme();

  return (
    <CustomText
      css={css`
        padding: 0.75rem;
      `}
      type="body-2"
      color={fonts.help}
    >
      {translate('search.loading')}
    </CustomText>
  );
};
