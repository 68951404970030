// components
import { HeaderContainer } from '../../styled';
import { CustomText } from 'typography/Text';

// hooks
import { useTheme } from 'styled-components';

interface PlanHeaderProps {
  text: React.ReactText;
  active: boolean;
}

export const PlanHeader = ({ text, active = false }: PlanHeaderProps) => {
  const {
    palette: { background },
  } = useTheme();
  return (
    <HeaderContainer active={active}>
      <CustomText type="sub-heading-2" color={background.base}>
        {active ? text : ' '}
      </CustomText>
    </HeaderContainer>
  );
};
