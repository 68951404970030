import styled from 'styled-components';

export const GatewayPriorityContainer = styled.div`
  display: flex;
  margin-top: 2.25rem;
  flex-direction: column;
`;

export const ValueContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
