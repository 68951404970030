import { ISearchModifier } from 'types/search';

interface ICharToSearchModifier {
  char: string;
}
export const charToSearchModifier = ({ char }: ICharToSearchModifier): ISearchModifier | null => {
  if (char.length != 1) {
    return null;
  }

  switch (char) {
    case '<':
      return 'LessThan';
    case '>':
      return 'GreaterThan';
    case '!':
      return 'Not';
    case '|':
      return 'Or';

    default:
      return null;
  }
};
