// Components
import { SpanStyledAsLargeInput } from 'components';

// hooks
import { useTheme } from 'styled-components';
import { useSelector } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';

// selectors
import { selectAppDetailsTagDescription } from 'redux/selectors';

export const TitleView = () => {
  const { translate } = useTranslations('tags');

  const {
    palette: { fonts },
  } = useTheme();

  const description = useSelector(selectAppDetailsTagDescription);

  return (
    <>
      <SpanStyledAsLargeInput
        data-test="tag-details-title-view"
        type="heading-2"
        color={fonts.bodyDark}
      >
        {description || translate('details.no_name')}
      </SpanStyledAsLargeInput>
    </>
  );
};
