import {
  setDetailsContent,
  setDetailsContentLoading,
  clearDetails,
  editDetails,
  cancelEditDetails,
} from '../../../actions/app';

import {
  DNS_RECORD_INITIAL_VALIDATION_STATE,
  POLICY_INITIAL_VALIDATION_STATE,
  SYSTEM_EDIT_INITIAL_VALIDATION_STATE,
  TRUST_REQUIREMENT_INITIAL_VALIDATION_STATE,
} from 'appConstants/validation';

import { getPolicyAclsValidationState } from 'utils';
import { appReducerBuilder, initialDetailsTab } from '..';

export const detailsTabDefaultReducerSlice: appReducerBuilder = builder => {
  builder
    .addCase(setDetailsContent, (state, { payload }) => {
      state.detailsTab = payload;
    })
    .addCase(setDetailsContentLoading, (state, { payload }) => {
      state.detailsTab.isLoading = payload;
    })
    .addCase(cancelEditDetails, state => {
      if (state.detailsTab.type === 'enrolment-key-edit') {
        state.detailsTab = {
          ...state.detailsTab,
          type: 'enrolment-key-view',
          dataNew: null,
        };
      }
      if (state.detailsTab.type === 'system-edit') {
        state.detailsTab = {
          ...state.detailsTab,
          type: 'system-view',
          dataNew: null,
        };
      }
      if (state.detailsTab.type === 'unapproved-system-edit') {
        state.detailsTab = {
          ...state.detailsTab,
          type: 'unapproved-system-view',
          dataNew: null,
        };
      }
      if (state.detailsTab.type === 'policy-edit') {
        state.detailsTab = {
          ...state.detailsTab,
          type: 'policy-view',
          dataNew: null,
        };
      }

      if (state.detailsTab.type === 'dns-record-edit') {
        state.detailsTab = {
          ...state.detailsTab,
          type: 'dns-record-view',
          dataNew: null,
        };
      }

      if (state.detailsTab.type === 'tag-edit') {
        state.detailsTab = {
          ...state.detailsTab,
          type: 'tag-view',
          dataNew: null,
        };
      }

      if (state.detailsTab.type === 'trust-requirement-edit') {
        state.detailsTab = {
          ...state.detailsTab,
          type: 'trust-requirement-view',
          dataNew: null,
        };
      }
    })
    .addCase(editDetails, state => {
      if (state.detailsTab.type === 'enrolment-key-view') {
        state.detailsTab = {
          ...state.detailsTab,
          type: 'enrolment-key-edit',
          dataNew: state.detailsTab.data,
          validationState: {},
        };
      } else if (state.detailsTab.type === 'system-view') {
        state.detailsTab = {
          ...state.detailsTab,
          type: 'system-edit',
          dataNew: state.detailsTab.data,
          validationState: SYSTEM_EDIT_INITIAL_VALIDATION_STATE,
        };
      } else if (state.detailsTab.type === 'unapproved-system-view') {
        state.detailsTab = {
          ...state.detailsTab,
          type: 'unapproved-system-edit',
          dataNew: state.detailsTab.data,
          validationState: SYSTEM_EDIT_INITIAL_VALIDATION_STATE,
        };
      } else if (state.detailsTab.type === 'policy-view') {
        state.detailsTab = {
          ...state.detailsTab,
          type: 'policy-edit',
          dataNew: state.detailsTab.data,
          validationState: {
            ...POLICY_INITIAL_VALIDATION_STATE,
            ...getPolicyAclsValidationState({ acls: state.detailsTab.data.acls }),
          },
        };
      } else if (state.detailsTab.type === 'dns-record-view') {
        state.detailsTab = {
          ...state.detailsTab,
          type: 'dns-record-edit',
          dataNew: state.detailsTab.data,
          validationState: DNS_RECORD_INITIAL_VALIDATION_STATE,
        };
      } else if (state.detailsTab.type === 'tag-view') {
        state.detailsTab = {
          ...state.detailsTab,
          type: 'tag-edit',
          dataNew: state.detailsTab.data,
          validationState: SYSTEM_EDIT_INITIAL_VALIDATION_STATE,
        };
      } else if (state.detailsTab.type === 'trust-requirement-view') {
        state.detailsTab = {
          ...state.detailsTab,
          type: 'trust-requirement-edit',
          dataNew: state.detailsTab.data,
          validationState: TRUST_REQUIREMENT_INITIAL_VALIDATION_STATE,
        };
      }
    })

    .addCase(clearDetails, state => {
      state.detailsTab = initialDetailsTab;
    });
};
