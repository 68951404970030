import styled from 'styled-components';
import { IconRefresh } from 'assets/svg';
import { Button } from 'antd';

export const FilterContainer = styled.div`
  display: flex;
  align-items: space-around;
`;

export const CustomButton = styled(Button)`
  display: flex;
  border-radius: 0.25rem;
  align-items: center;
  justify-content: center;
  padding: 0.5625rem 0.75rem;

  background: ${({ theme }) => theme.palette.background.base};
  border-color: ${({ theme }) => theme.palette.background.iconBackground};

  &:hover {
    background: ${({ theme }) => theme.palette.background.sections}!important;
    border-color: ${({ theme }) => theme.palette.background.iconBackground};
  }
  &:focus {
    background: ${({ theme }) => theme.palette.background.hover};
    border-color: ${({ theme }) => theme.palette.background.iconBackground};
  }
`;

export const StyledRefreshIcon = styled(IconRefresh)<{ $spin: boolean }>`
  cursor: pointer;

  path {
    fill: ${({ theme }) => theme.palette.icons.secondary};
    fill-opacity: 1;
  }

  animation: spin 1s linear infinite;
  animation-play-state: ${props => (props.$spin ? 'running' : 'paused')};
`;

export const InputWrapper = styled.div`
  flex-grow: 1;
  :not(:first-child) {
    margin-left: 0.5rem;
  }
`;

export const PaginationTextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  min-width: 6rem;
  margin-left: 0.5rem;
`;
