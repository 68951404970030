import { useTranslations } from 'hooks/useTranslations';
import { StyledItem } from './List';
import { SelectKeySection } from './SelectKeySection';

type EnrolmentKeySelectProps = {
  alternativeTitle?: string;
};
export const EnrolmentKeySelect = ({ alternativeTitle }: EnrolmentKeySelectProps) => {
  const { translate } = useTranslations('enrol-new-system');

  return (
    <StyledItem
      title={alternativeTitle || translate('select_key_when_prompted')}
      content={<SelectKeySection />}
    />
  );
};
