// components
import { CustomText } from 'typography/Text';

// hooks
import { useSelector } from 'react-redux';
import { css, useTheme } from 'styled-components';

// selectors
import { selectAppDetailsEnrolmentKeyNotes } from 'redux/selectors';

export const NotesView = () => {
  const {
    palette: { fonts },
  } = useTheme();

  const notes = useSelector(selectAppDetailsEnrolmentKeyNotes) || '';

  return (
    <CustomText
      type="body-2"
      color={fonts.title}
      css={css`
        overflow-wrap: anywhere;
      `}
    >
      {notes}
    </CustomText>
  );
};
