// Lib
import { call, put, select } from 'typed-redux-saga';
import { setEnrolmentKeyDetailsContent, pushNotification } from 'redux/actions/app';

import {
  IApiResponse,
  IUpdateEnrolmentKeyResponse,
  INotificationType,
  IEnrolmentStoredKey,
  IUpdateEnrolmentKeyParams,
} from 'types';

import { selectAppDetailsTabData, selectAppDetailsTabDataNew } from 'redux/selectors';

import { updateEnrolmentKeyState } from 'redux/actions/enrolment-keys';

import { checkCreatedSaga } from '../tags/checkCreatedSaga';

// Utils
import { ApiTypeCaller } from '../../api/utils';
import { translator, diff, demodifyEnrolmmentKeyDetails, removeUndefinedKeys } from 'utils';

export function* updateEnrolmentKeySaga() {
  const key: IEnrolmentStoredKey = yield select(selectAppDetailsTabData);
  const updatedKey: IEnrolmentStoredKey = yield select(selectAppDetailsTabDataNew);

  const updatedParams: IUpdateEnrolmentKeyParams = demodifyEnrolmmentKeyDetails(
    diff(updatedKey, key)
  );

  const { success, result }: IApiResponse<IUpdateEnrolmentKeyResponse> = yield ApiTypeCaller(
    'updateEnrolmentKey',
    {
      keyId: updatedKey.id,
      updatedParams: removeUndefinedKeys(updatedParams),
    }
  );
  const translate = translator('notifications');

  if (success && result) {
    yield put(
      setEnrolmentKeyDetailsContent({
        data: result,
        type: 'enrolment-key-view',
      })
    );
    yield put(updateEnrolmentKeyState(result));
    yield put(
      pushNotification({
        showType: INotificationType.Success,
        description: translate('enrolm_key_updated', {
          keyId: updatedKey.id,
        }),
      })
    );
  }

  yield call(checkCreatedSaga, updatedParams.tags);
}
