// libs
import { BaseSyntheticEvent, useCallback, useEffect, useRef } from 'react';

// components
import { Details } from 'components';

// hooks
import { useDispatch, useSelector } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';

// selectors
import {
  selectAppDetailsTabValidationStateEntries,
  selectAppDetailsTagNewDescription,
  selectFormError,
} from 'redux/selectors';

// actions
import {
  addValidationStateEntry,
  clearErrors,
  updateTagDetails,
  updateValidationStateEntry,
} from 'redux/actions/app';
import { equals } from 'ramda';

// types
import { TextAreaRef } from 'antd/lib/input/TextArea';

// constants
import { REGEX_VALID_TAG_NAME } from 'appConstants';

// utils
import { debounce } from 'utils';

const titleErrorKey = 'tagTitle';

const errorFormNameKey = 'name';
const errorFormTagKey = 'tag';

export const TitleEdit = () => {
  const dispatch = useDispatch();

  const { translate } = useTranslations('tags');

  const newDescription = useSelector(selectAppDetailsTagNewDescription);

  const validationState = useSelector(
    selectAppDetailsTabValidationStateEntries([titleErrorKey]),
    equals
  );
  const nameError = useSelector(selectFormError(errorFormNameKey));
  const titleSyntaxError = useSelector(selectFormError(errorFormTagKey));

  const ref = useRef<TextAreaRef>(null);

  useEffect(() => {
    dispatch(addValidationStateEntry({ key: titleErrorKey, state: { error: null } }));
  }, [dispatch]);

  const validateDescription = useCallback(
    (description: string) => {
      const isInvalid =
        !REGEX_VALID_TAG_NAME.test(description) || description.includes(' ') === true;

      const isEmpty = description.trim().length === 0;

      const error = isEmpty
        ? { message: translate('details.no_name_error') }
        : isInvalid
        ? { message: translate('details.invalid_name_error') }
        : null;

      if (
        validationState?.[titleErrorKey]?.state?.error !== error ||
        !validationState?.[titleErrorKey]?.updated
      ) {
        dispatch(updateValidationStateEntry({ key: titleErrorKey, state: { error } }));
      }
    },
    [validationState, dispatch, translate]
  );

  const validateWithDebounce = debounce((description: string) => {
    validateDescription(description);
  }, 250);

  const onBlur = (event: BaseSyntheticEvent) => {
    const description = event.target.value.toLowerCase();
    validateDescription(description);
    dispatch(updateTagDetails({ tag: description }));
  };

  const onDescriptionChange = (event: BaseSyntheticEvent) => {
    const description = event.target.value.toLowerCase();
    dispatch(updateTagDetails({ tag: description }));
    validateWithDebounce(description);
  };

  useEffect(() => {
    return () => {
      dispatch(clearErrors());
    };
  }, [dispatch]);

  useEffect(() => {
    ref?.current?.focus();
  }, [ref]);

  return (
    <Details.Input
      ref={ref}
      name={titleErrorKey}
      value={newDescription ?? ''}
      placeholder={translate('details.name_placeholder')}
      onChange={onDescriptionChange}
      onBlurCapture={onBlur}
      error={
        validationState?.[titleErrorKey]?.state?.error ||
        (nameError && { message: nameError, critical: true }) ||
        (titleSyntaxError && { message: titleSyntaxError, critical: true }) ||
        null
      }
      onBlur={onDescriptionChange}
    />
  );
};
