import { stylesMap } from 'typography/Text';
import styled from 'styled-components';
import { IconTextareaResizer } from 'assets/svg';

export const TextareaContainer = styled.div`
  textarea {
    border-radius: 0.25rem;
    border-width: 0.0625rem;
    border-style: solid;
    border-color: ${({ theme }) => theme.palette.background.iconBackground};
    box-shadow: ${({ theme }) => theme.palette.elevationShadows[100]} !important;

    min-height: 2rem !important;

    ${({ theme }) => theme.media_queries.s1920} {
      min-height: 3rem !important;
    }

    :hover {
      border-color: ${({ theme }) => theme.palette.fills.primaryHover};
    }

    :focus {
      border-color: ${props => props.theme.palette.fills.primaryHover};
    }

    ${stylesMap['body-2']}
    color: ${({ theme }) => theme.palette.fonts.title};

    ::placeholder {
      ${stylesMap['body-2']}
      color: ${({ theme }) => theme.palette.fonts.disabled};
    }
    ::-webkit-resizer {
      background: url(${IconTextareaResizer}) bottom right no-repeat;
      background-size: contain;
    }
  }
`;

export const Label = styled.label`
  ${stylesMap['sub-heading-2']};
  color: ${({ theme }) => theme.palette.fonts.bodyLight};
`;
