// Lib
import { useEffect, useMemo, useState, useCallback } from 'react';
import { useTheme } from 'styled-components';

// Hooks
import { useSelector, useDispatch } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';

// Selectors
import {
  selectSecurityEventsItems,
  selectSecurityEventsLoading,
  selectSecurityEventsTotal,
} from 'redux/selectors';

// Actions
import { getSecurityEvents, setSecurityEvents } from 'redux/actions/security-events';

// Components
import { SecurityEventsColumns } from '../Columns';
import { initialSecurityEventsResponse } from 'redux/reducers/security-events';

export const useSecurityEvents = () => {
  const dispatch = useDispatch();

  const { translate } = useTranslations('security-events');

  const theme = useTheme();

  const [page, setPage] = useState(0);

  const events = useSelector(selectSecurityEventsItems);
  const isLoading = useSelector(selectSecurityEventsLoading);
  const total = useSelector(selectSecurityEventsTotal);

  const fetchSecurityEvents = useCallback(
    (page?: number) => {
      dispatch(
        getSecurityEvents({
          page: page || 0,
        })
      );
    },

    [dispatch]
  );

  useEffect(() => {
    fetchSecurityEvents(page);
  }, [fetchSecurityEvents, page]);

  useEffect(() => {
    return () => {
      dispatch(setSecurityEvents(initialSecurityEventsResponse));
    };
  }, [dispatch]);

  const columns = useMemo(
    () =>
      SecurityEventsColumns({
        isLoading,
        translate,
      }),
    [isLoading, translate]
  );

  return {
    isLoading,
    columns,
    events,
    page,
    setPage,
    total,
    theme,
    translate,
    fetchSecurityEvents,
  };
};
