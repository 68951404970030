// components
import { CustomText } from 'typography/Text';
import { IconTrialClose } from 'assets/svg';
import {
  CloseContainer,
  Container,
  ConvertButtonContainer,
  MessageContainer,
  TextContainer,
} from './styled';
import { CustomButton } from 'components';

// hooks
import { useTheme } from 'styled-components';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useIsDismissed } from './hooks/useIsDismissed';
import { useTrialBannerData } from './hooks/useTrialBannerData';
import { useTranslations } from 'hooks/useTranslations';

// selectors
import {
  selectCurrentOrganisationId,
  selectCurrentOrganisationTrialExpiry,
  selectCurrentOrganisationTrialIsSuspendedByAdmin,
  selectCurrentOrganisationTrialState,
} from 'redux/selectors';

// constants
import { DISMISSED_TRIAL_BANNER_KEY } from 'appConstants';

// utils
import { dissoc } from 'ramda';

interface ITrialBanner {
  isDashboard?: boolean;
}

export const TrialBanner = ({ isDashboard = false }: ITrialBanner) => {
  const { palette } = useTheme();

  const { translate } = useTranslations('global');

  const { value: localStoreDismissedTrial, setValue: setLocalStoreDismissedTrial } =
    useLocalStorage({
      key: DISMISSED_TRIAL_BANNER_KEY,
      defaultValue: null,
    });

  const localStorageDismissedState = useMemo(
    () => JSON.parse(localStoreDismissedTrial),
    [localStoreDismissedTrial]
  );

  const orgId = useSelector(selectCurrentOrganisationId);
  const trialState = useSelector(selectCurrentOrganisationTrialState);

  const trialExpiry = useSelector(selectCurrentOrganisationTrialExpiry);
  const suspendedByAdmin = useSelector(selectCurrentOrganisationTrialIsSuspendedByAdmin);

  const { data, TrialTypes } = useTrialBannerData({
    orgId,
    suspendedByAdmin,
    trialExpiry,
    trialState,
  });

  const isDismissed = useIsDismissed({ data, orgId, isDashboard, localStorageDismissedState });

  const onClick = useCallback(() => {
    if (data) {
      setLocalStoreDismissedTrial(
        JSON.stringify({ ...localStorageDismissedState, [orgId]: data.id })
      );
    }
  }, [data, localStorageDismissedState, orgId, setLocalStoreDismissedTrial]);

  useEffect(() => {
    if (
      data &&
      localStorageDismissedState?.[orgId] &&
      data.id !== localStorageDismissedState?.[orgId]
    ) {
      setLocalStoreDismissedTrial(JSON.stringify(dissoc(orgId, localStorageDismissedState)));
    }
  }, [data, localStorageDismissedState, orgId, setLocalStoreDismissedTrial]);

  if (isDismissed) return null;

  if (!data) return null;

  const typeData = TrialTypes[data.type];

  return (
    <Container background={typeData.background} border={typeData.border}>
      <MessageContainer>
        {typeData.Icon}
        <TextContainer>
          <CustomText type="sub-heading-2" color={palette.fonts.title}>
            {data.title}
          </CustomText>
          <CustomText type="body-2" color={palette.fonts.bodyLight}>
            {data.description}
          </CustomText>
        </TextContainer>
        {!isDashboard && trialState !== 'Suspended' && !suspendedByAdmin && (
          <CloseContainer closeIcon={typeData.closeIcon} onClick={onClick}>
            <IconTrialClose width="1.5rem" height="1.5rem" />
          </CloseContainer>
        )}
      </MessageContainer>
      <MessageContainer>
        {data.partnerAccess && data.partnerId && !suspendedByAdmin && (
          <ConvertButtonContainer>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`${process.env.ENC_PARTNER_HOST}/customers/${orgId}/convert`}
            >
              <CustomButton kind="primary">{translate('trial_banner.convert_button')}</CustomButton>
            </a>
          </ConvertButtonContainer>
        )}
      </MessageContainer>
    </Container>
  );
};
