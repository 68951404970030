// libs
import { css, useTheme } from 'styled-components';
import { useCallback } from 'react';

// Components
import { CustomButton, BulkClearButton } from 'components';
import { CustomText } from 'typography/Text';
import { BulkActionsContainer } from 'components/BulkActions/styled';

// Hooks
import { useDispatch } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';

// actions
import { setModalContent } from 'redux/actions/app';

// types
import { ITag } from 'types';

interface IBulkActions {
  bulkSelectedTags: ITag[];
  setBulkSelectedTags: (bulkSelectedTags: ITag[]) => void;
}
export const BulkActions = ({ bulkSelectedTags, setBulkSelectedTags }: IBulkActions) => {
  const dispatch = useDispatch();

  const { translate } = useTranslations('tags');
  const { translate: translateGlobal } = useTranslations('global');

  const {
    palette: { fonts },
  } = useTheme();

  const bulkDelete = useCallback(() => {
    if (bulkSelectedTags.length) {
      dispatch(
        setModalContent({
          type: 'tag-delete',
          data: { records: bulkSelectedTags, connections: {} },
        })
      );
    }
  }, [dispatch, bulkSelectedTags]);

  return (
    <BulkActionsContainer>
      <CustomText type="sub-heading-2" color={fonts.title}>
        {bulkSelectedTags.length}{' '}
        {bulkSelectedTags.length !== 1
          ? translateGlobal('bulk.items_selected', {
              module: translate('modules.items'),
            })
          : translateGlobal('bulk.item_selected', {
              module: translate('modules.item'),
            })}
      </CustomText>
      <BulkClearButton
        type="link"
        onClick={() => {
          setBulkSelectedTags([]);
        }}
      >
        {translateGlobal('bulk.clear_all')}
      </BulkClearButton>
      <CustomButton
        kind="secondaryDanger"
        css={css`
          margin-right: 1rem;
        `}
        onClick={bulkDelete}
      >
        {translateGlobal('bulk.remove')}
      </CustomButton>
    </BulkActionsContainer>
  );
};
