import styled from 'styled-components';
import { Button as ButtonAntd } from 'antd';

export type ThemeMap = {
  background: string;
  backgroundHover: string;
  backgroundFocus: string;
  backgroundPressed: string;
  borderColor: string;
  borderColorHover: string;
  borderColorFocus: string;
  borderColorPressed: string;
  color: string;
  hoverBoxShadow: string;
  focusBoxShadow: string;
  pressedBoxShadow: string;
};

type Props = {
  $width?: string;
  $styles: ThemeMap;
};

export const Button = styled(ButtonAntd)<Props>`
  width: ${({ $width }) => $width || 'min-content'};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.25rem;
  padding: ${({ size }) => (size === 'middle' ? '0.3125rem 1.5rem' : '0.5rem 1.5rem')};

  background: ${({ $styles }) => $styles.background};
  border-color: ${({ $styles }) => $styles.borderColor};

  &:hover {
    background: ${({ $styles }) => $styles.backgroundHover};
    box-shadow: ${({ $styles }) => $styles.hoverBoxShadow};
    border-color: ${({ $styles }) => $styles.borderColorHover};
  }
  &:focus {
    background: ${({ $styles }) => $styles.backgroundFocus};
    box-shadow: ${({ $styles }) => $styles.focusBoxShadow};
    border-color: ${({ $styles }) => $styles.borderColorFocus};
  }
  &:active {
    background: ${({ $styles }) => $styles.backgroundPressed};
    box-shadow: ${({ $styles }) => $styles.pressedBoxShadow};
    border-color: ${({ $styles }) => $styles.borderColorPressed};
  }
  .ant-spin-spinning {
    display: flex;
  }
`;
