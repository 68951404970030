// libs
import { css } from 'styled-components';

// components
import { CustomText } from 'typography/Text';
import { Details, Tooltip } from 'components';
import { ReceiverAccessControlsBlock } from '../../Blocks';
import { ReceiverAccessControlSection } from './styled';
import { IconPoliciesNoAclsWarningCircle, IconPoliciesDetailsAclSection } from 'assets/svg';

// hooks
import { useTheme } from 'styled-components';
import { useSelector } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';
import { useEffect, useRef } from 'react';

// selectors
import {
  selectAppDetailsPolicyAcls,
  selectAppDetailsPolicyNewAcls,
  selectAppDetailsPolicyScrollToReceiverAccessControls,
  selectAppDetailsTabType,
} from 'redux/selectors';

export const ReceiverAccessControl = () => {
  const {
    palette: { fonts },
  } = useTheme();

  const { translate } = useTranslations('policies');

  const myRef = useRef<HTMLDivElement>(null);

  const type = useSelector(selectAppDetailsTabType);
  const acls = useSelector(selectAppDetailsPolicyAcls);
  const newAcls = useSelector(selectAppDetailsPolicyNewAcls);

  const scrollToReceiverAccessControls = useSelector(
    selectAppDetailsPolicyScrollToReceiverAccessControls
  );

  useEffect(() => {
    scrollToReceiverAccessControls && myRef.current?.scrollIntoView();
  }, [scrollToReceiverAccessControls]);

  return (
    <ReceiverAccessControlSection ref={myRef}>
      <Details.SectionHeader
        Icon={IconPoliciesDetailsAclSection}
        title={translate('details.receiver_access_controls')}
        css={css`
          margin-bottom: 0.5rem;
        `}
        info={
          (type === 'policy-view' && !acls?.length) ||
          ((type === 'policy-edit' || type === 'policy-create') && !newAcls?.length) ? (
            <Tooltip
              css={css`
                display: flex;
              `}
              overlay={
                <>
                  <CustomText type="body-2" color={fonts.primaryButton}>
                    {translate('no_acl_tooltip')}
                  </CustomText>
                </>
              }
            >
              <IconPoliciesNoAclsWarningCircle
                css={css`
                  margin-left: 0.25rem;
                `}
              />
            </Tooltip>
          ) : null
        }
      />
      <ReceiverAccessControlsBlock />
    </ReceiverAccessControlSection>
  );
};
