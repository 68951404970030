import { CustomText } from 'typography/Text';
import { useTheme } from 'styled-components';
import { IPolicy } from 'types';

interface IDescription {
  description: IPolicy['description'];
  isEnabled?: IPolicy['isEnabled'];
}

export const Description = ({ description, isEnabled = true, ...rest }: IDescription) => {
  const theme = useTheme();

  return (
    <CustomText
      type="body-2"
      color={isEnabled ? theme.palette.fonts.bodyDark : theme.palette.fonts.disabled}
      {...rest}
      title={description}
    >
      {description}
    </CustomText>
  );
};
