// Image
import image from 'assets/png/play-store-badge.png';

// Components
import { StyledItem, EnrolmentKeySelect, GoToSystems } from '../components';

// Hooks
import { useTranslations } from 'hooks/useTranslations';

// Link
import { LINK_TO_PLAY_STORE } from 'appConstants';

export const Android = () => {
  const { translate } = useTranslations('enrol-new-system');

  return (
    <>
      <StyledItem
        title={translate('android.steps.1.title')}
        titleId="label-select-new-system-enrolment-key"
        hint={translate('android.steps.1.hint')}
        content={
          <>
            <a target="_blank" rel="noopener noreferrer" href={LINK_TO_PLAY_STORE}>
              <img
                src={image}
                style={{ width: '14rem', marginTop: `-1rem`, marginLeft: `-1rem` }}
              />
            </a>
          </>
        }
      />
      <EnrolmentKeySelect />
      <GoToSystems />
    </>
  );
};
