// components
import { PublicIpBlocks } from '../../Blocks';
import { Wrapper } from './Wrapper';

export const PublicIp = () => {
  return (
    <Wrapper>
      <PublicIpBlocks.IpRangesBlock />
      <PublicIpBlocks.LocationsBlock />
    </Wrapper>
  );
};
