// hooks
import { useTranslations } from 'hooks/useTranslations';
import { css, useTheme } from 'styled-components';

// components
import { CustomText } from 'typography/Text';

// utils
import { formTimeString } from 'utils';

interface IOptionProps {
  hours: number;
  minutes: number;
  is12hFormat: boolean;
}

export const TimeOption = ({ hours, minutes, is12hFormat }: IOptionProps) => {
  const {
    palette: { fonts },
  } = useTheme();

  return (
    <CustomText type="body-2" color={fonts.title}>
      {formTimeString(hours, minutes, is12hFormat)}
    </CustomText>
  );
};

export type IEndTimeOptionProps = {
  hours: number;
  minutes: number;
  nextDay: boolean;
  divider: boolean;
  is12hFormat: boolean;
};

export type IEndTime = Omit<IEndTimeOptionProps, 'is12hFormat'>;

export const EndTimeOption = ({
  hours,
  minutes,
  nextDay,
  divider,
  is12hFormat,
}: IEndTimeOptionProps) => {
  const { translate } = useTranslations('policies');
  const {
    palette: { fonts },
  } = useTheme();

  if (nextDay) {
    return (
      <CustomText type="body-2" color={fonts.title}>
        {`${formTimeString(hours, minutes, is12hFormat)} ${translate(
          'details.time_limitations.active_hours.next_day.description'
        )}`}
      </CustomText>
    );
  }

  if (divider) {
    return (
      <CustomText
        type="label-1"
        color={fonts.title}
        css={css`
          display: flex;
          width: 100%;
          border-bottom: 1px solid black;
        `}
      >
        {translate('details.time_limitations.active_hours.next_day.divider')}
      </CustomText>
    );
  }

  return (
    <CustomText type="body-2" color={fonts.title}>
      {formTimeString(hours, minutes, is12hFormat)}
    </CustomText>
  );
};
