import styled from 'styled-components';

export const List = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;
export const ListItem = styled.li`
  margin-top: 1.5rem;
`;
