// components
import { Details, SystemStatusBadge } from 'components';
import { CustomText } from 'typography/Text';
import { Trans } from 'react-i18next';

// selectors
import {
  selectAppDetailsApprovedSystemState,
  selectAppDetailsSystemConnectedFrom,
  selectAppDetailsApprovedSystemIsEnabled,
  selectAppDetailsSystemType,
  selectAppDetailsSystemEnrolledAt,
  selectAppDetailsApprovedSystemLastSeen,
  selectAppDetailsApprovedDisconnectedRetentionMinutes,
} from 'redux/selectors';

// hooks
import { useEffect, useMemo, useState } from 'react';
import { css, useTheme } from 'styled-components';
import { useTranslations } from 'hooks/useTranslations';
import { useSelector } from 'react-redux';

// types
import { ISystemSummaryModel } from 'types';

// utils
import { formatTime } from 'utils';

interface IContent {
  extended?: boolean;
}
export const Content = ({ extended = true }: IContent) => {
  const { translate } = useTranslations('systems');

  const {
    palette: { fonts },
  } = useTheme();

  const state = useSelector(selectAppDetailsApprovedSystemState);
  const connectedFrom = useSelector(selectAppDetailsSystemConnectedFrom);
  const isEnabled = useSelector(selectAppDetailsApprovedSystemIsEnabled);
  const purpose = useSelector(selectAppDetailsSystemType);
  const disconnectedRetentionMinutes = useSelector(
    selectAppDetailsApprovedDisconnectedRetentionMinutes
  );
  const lastSeen = useSelector(selectAppDetailsApprovedSystemLastSeen);
  const enrolledAt = useSelector(selectAppDetailsSystemEnrolledAt);

  const connectedFromText: Record<ISystemSummaryModel['state'], string | null | undefined> = {
    Connected: connectedFrom,
    Disabled: translate('disabled'),
    Disconnected: translate('disconnected'),
  };

  const finishDate = useMemo(() => {
    if (!disconnectedRetentionMinutes) return null;

    return (
      new Date(lastSeen || enrolledAt || Date.now()).getTime() +
      disconnectedRetentionMinutes * 60 * 1000
    );
  }, [disconnectedRetentionMinutes, lastSeen, enrolledAt]);

  const [timeRemaining, setTimeRemaining] = useState(
    finishDate
      ? formatTime({
          date: finishDate,
          format: 'time_remaining',
        })
      : null
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeRemaining(
        finishDate
          ? formatTime({
              date: finishDate,
              format: 'time_remaining',
            })
          : null
      );
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [enrolledAt, lastSeen, finishDate]);

  const text = useMemo(() => {
    if (!lastSeen) {
      return 'details.connection_time.never_seen';
    }
    if (state === 'Connected' && isEnabled && connectedFrom) {
      return 'details.connection_time.connected';
    }
    if (state === 'Connected' && !isEnabled && connectedFrom) {
      return 'details.connection_time.disabled_connected';
    }
    if (state === 'Disconnected') {
      return 'details.connection_time.disconnected';
    }

    if (state === 'Disabled' && connectedFrom) {
      return 'details.connection_time.disabled';
    }
    return null;
  }, [connectedFrom, isEnabled, lastSeen, state]);

  const timeToNow = lastSeen ? formatTime({ date: lastSeen, format: 'ago' }) : '';

  const time = lastSeen
    ? formatTime({ date: lastSeen, format: 'Do MMMM, YYYY at h:mm A zzz' })
    : '';

  return (
    <Details.KeyValue
      height="auto"
      css={css`
        padding-bottom: 1.5rem;
      `}
      title={translate('details.connection')}
    >
      {extended ? (
        <CustomText type="body-2" color={fonts.title}>
          {state && <SystemStatusBadge state={state} />}
          {state && connectedFromText[state]}
        </CustomText>
      ) : (
        <CustomText type="body-2" color={fonts.title}>
          {connectedFrom || '-'}
        </CustomText>
      )}
      {text && (
        <CustomText
          type="hint"
          color={fonts.bodyLight}
          css={css`
            white-space: pre-line;
            display: block;
            margin-top: 0.25rem;
          `}
        >
          <Trans
            i18nKey={translate(text)}
            values={{ time_to_now: timeToNow, time: time, last_connected: connectedFrom }}
          />
        </CustomText>
      )}

      {purpose === 'Ephemeral' && finishDate && (
        <CustomText
          css={css`
            display: block;
            margin-top: 0.5rem;
          `}
          type="hint"
          color={fonts.bodyLight}
          data-test="system-details-enrolled-from-timeout"
        >
          {(state === 'Connected' || state === 'Disabled') &&
            translate('details.connection_ephemeral_connected')}
          {state === 'Disconnected' &&
            (timeRemaining !== null
              ? translate('details.connection_ephemeral_disconnected', {
                  timeLeft: timeRemaining,
                  date: formatTime({
                    date: finishDate,
                    format: 'Do MMMM, YYYY at h:mm A zzz',
                  }),
                })
              : translate('details.connection_ephemeral_disconnected_soon'))}
        </CustomText>
      )}
    </Details.KeyValue>
  );
};
