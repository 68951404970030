// libs
import styled from 'styled-components';

// components
import { DayButtonLookalike } from '../DayButton';

// hooks
import { useSelector } from 'react-redux';

// selectors
import { selectAppDetailsPolicyActiveDays } from 'redux/selectors';

// constants
import { days } from '../Edit';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const ActiveDaysView = () => {
  const selectedDays = useSelector(selectAppDetailsPolicyActiveDays);

  // return <Container>{selectedDays && getActiveDaysDisplayString(selectedDays)}</Container>;
  return (
    <Container>
      {days?.map(day => (
        <DayButtonLookalike
          active={selectedDays?.includes(day) || false}
          day={day}
          key={`daybutton_${day}`}
        />
      ))}
    </Container>
  );
};
