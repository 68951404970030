// components
import { CustomTag, SystemPill } from 'components';

// types
import { ISystemReferenceModel, TagReference } from 'types';
import { TagAndSystemListWrapper, tagCss } from './styled';

interface ITagsProps {
  tags: TagReference[];
  systems: ISystemReferenceModel[];
  width?: string;
  blocksMode?: boolean;
  likeSelect?: boolean;
}

export const TagAndSystemList = ({
  tags,
  systems,
  width,
  blocksMode = false,
  ...rest
}: ITagsProps) => {
  return tags.length + systems.length ? (
    <TagAndSystemListWrapper blocksMode={blocksMode} width={width} {...rest}>
      {tags &&
        tags.map(tag => <CustomTag customTagBoxCss={tagCss} tag={tag} key={`tag_${tag.tag}`} />)}
      {systems &&
        systems.map(({ id, name, platformType, machineName }) => (
          <SystemPill
            key={id}
            id={id}
            name={name}
            platformType={platformType}
            machineName={machineName}
          />
        ))}
    </TagAndSystemListWrapper>
  ) : (
    <></>
  );
};
