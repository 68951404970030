// libs
import styled from 'styled-components';

// components
import { CustomText } from 'typography/Text';
import { KeysStatusBadge } from 'components';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';

// selectors
import { selectAppDetailsEnrolmentKeyIsEnabled } from 'redux/selectors';

const StyledContainer = styled.div`
  padding-left: 0.125rem;
`;

export const StatusView = () => {
  const theme = useTheme();

  const { translate } = useTranslations('enrolment-keys');

  const isEnabled = useSelector(selectAppDetailsEnrolmentKeyIsEnabled);

  const status = isEnabled ? 'Enabled' : 'Disabled';

  return (
    <StyledContainer>
      <KeysStatusBadge state={status} />
      <CustomText
        type="body-2"
        color={isEnabled ? theme.palette.fonts.title : theme.palette.fonts.disabled}
      >
        {status === 'Enabled'
          ? translate('enabled')
          : status === 'Disabled'
          ? translate('disabled')
          : translate('no_uses_remaining')}
      </CustomText>
    </StyledContainer>
  );
};
