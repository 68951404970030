// libs
import { css } from 'styled-components';
import { useSelector } from 'react-redux';

// selectors
import { selectAppDetailsTabType } from 'redux/selectors';

// components
import { Details } from 'components';
import {
  TopMenuButtons,
  Header,
  Overview,
  Detail,
  DNS,
  CancelSave,
  ApproveDecline,
  Gateway,
  AutoExpire,
} from './Sections';

export const SystemDetails = () => {
  const type = useSelector(selectAppDetailsTabType);
  const isWideDetails =
    type === 'unapproved-system-view' || type === 'unapproved-system-edit' ? false : true;

  return (
    <Details.Pane>
      <Details.ContentWrapper
        data-test="system-details-content"
        css={css`
          position: relative;
        `}
      >
        <Details.ScrollPaddingContainer wide={isWideDetails}>
          <TopMenuButtons />
          <Header />
          <Overview />
          <Detail />
          <Gateway />
          <DNS />
          <AutoExpire />
        </Details.ScrollPaddingContainer>
      </Details.ContentWrapper>
      <ApproveDecline />
      <CancelSave />
    </Details.Pane>
  );
};
