import { ITEMS_FOR_SKELETON } from 'appConstants';
import { IPolicy } from 'types';

export const isEnabled = (policy: IPolicy) => policy.isEnabled === true;
export const isDisabled = (policy: IPolicy) => policy.isEnabled === false;
export const mapPolicyId = (policy: IPolicy) => policy.id;

export const emptyPolicies: IPolicy[] = Array.from(
  { length: ITEMS_FOR_SKELETON },
  (_el, index) => ({
    id: index.toString(),
    isEnabled: false,
    acls: [],
    created: '',
    description: '',
    notes: '',
    receiverTags: [],
    senderTags: [],
    state: 'Active',
    gateways: [],
    gatewayTrafficDirection: 'Exit',
    gatewayAllowedIpRanges: [],
    type: 'General',
    activeHours: null,
    autoExpire: null,
  })
);
