// Lib
import { call, put } from 'typed-redux-saga';

// Actions
import { set2FAQRCode } from 'redux/actions/account';

import { validateStepUpTokenSaga } from './validateStepUpTokenSaga';

// Utils
import { ApiTypeCaller } from '../../api/utils';

export function* create2FAQRCodeSaga() {
  const token = yield* call(validateStepUpTokenSaga);
  if (token) {
    const { success, result } = yield* ApiTypeCaller('createAccount2FAQR', {
      stepUpToken: token,
    });
    if (success && result) {
      yield put(set2FAQRCode(result.dataUri));
    }
  }
}
