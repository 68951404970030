import { OrderedListItem } from './OrderedListItem';
import { StepTitle, StepSubTitle, InstructionDetails, InstructionTitle } from './styled';

interface StyledItemProps {
  title: React.ReactNode;
  titleId?: string;
  hint?: React.ReactNode;
  content?: React.ReactNode;
}
export const StyledItem = ({ title, hint, content, titleId }: StyledItemProps) => {
  return (
    <OrderedListItem>
      <InstructionTitle>
        <StepTitle id={titleId}>{title}</StepTitle>
        {hint && <StepSubTitle>{hint}</StepSubTitle>}
      </InstructionTitle>
      <InstructionDetails>{content}</InstructionDetails>
    </OrderedListItem>
  );
};
