// libs
import { put, select } from 'typed-redux-saga';

// actions
import { updateEnrolmentKeysState } from 'redux/actions/enrolment-keys';

// selectors
import { selectAppDetailsTabData, selectAppDetailsTabType } from 'redux/selectors';

// actions
import { setDetailsContent, pushNotification } from 'redux/actions/app';

// utils
import { ApiTypeCaller } from '../../api/utils';
import { translator } from 'utils';

// types
import {
  IActionPayload,
  IEnableEnrolmentKeysBulkParams,
  IDisableEnrolmentKeysBulkParams,
  INotificationType,
  IEnrolmentStoredKey,
} from 'types';

export function* enableDisableEnrolmentKeysSaga(
  { payload }: IActionPayload<IEnableEnrolmentKeysBulkParams | IDisableEnrolmentKeysBulkParams>,
  enable = true
) {
  const translate = translator('notifications');
  const keyDetails: IEnrolmentStoredKey = yield select(selectAppDetailsTabData);
  const type = yield* select(selectAppDetailsTabType);

  const { result, success } = yield* ApiTypeCaller(
    enable ? 'enableEnrolmentKeys' : 'disableEnrolmentKeys',
    payload
  );

  if (success && result) {
    const { keyIds } = payload;
    yield put(updateEnrolmentKeysState({ keyIds, enabled: enable }));

    if (
      type === 'enrolment-key-view' &&
      keyDetails.isEnabled !== enable &&
      keyIds.some(id => id === keyDetails.id)
    ) {
      yield put(
        setDetailsContent({
          type: 'enrolment-key-view',
          data: { ...keyDetails, isEnabled: enable },
        })
      );
    }

    yield put(
      pushNotification({
        showType: INotificationType.Success,
        description: translate('enrolm_keys_state_changed', {
          state: enable ? translate('enabled_lowercase') : translate('disabled_lowercase'),
        }),
      })
    );
  }
}
