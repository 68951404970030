// libs
import { css } from 'styled-components';

// components
import { Input } from 'components';

// hooks
import { useDispatch, useSelector } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';
import { useCallback, useEffect, useState } from 'react';

// actions
import { updateTrustRequirementDetailsPublicIpIpRangeCondition } from 'redux/actions/app';

// selectors
import { selectAppDetailsTabValidationEntry } from 'redux/selectors';

// types
import { BaseSyntheticEvent } from 'react';
import { ITrustRequirementPublicIpIpRangeConditionModified } from 'types';

// constants
import { TRUST_REQUIREMENT_IP_RANGE_VALIDATION_STATE_PREFIX } from 'appConstants';

interface IIpRange {
  ipRange: ITrustRequirementPublicIpIpRangeConditionModified;
}

export const IpRange = ({ ipRange }: IIpRange) => {
  const dispatch = useDispatch();

  const { translate } = useTranslations('trust-requirements');

  const validationState = useSelector(
    selectAppDetailsTabValidationEntry(
      TRUST_REQUIREMENT_IP_RANGE_VALIDATION_STATE_PREFIX + ipRange.id
    )
  );

  const [value, setValue] = useState(ipRange.value || '');

  useEffect(() => {
    setValue(ipRange.value || '');
  }, [ipRange]);

  const onChange = useCallback((event: BaseSyntheticEvent) => {
    const newValue = event.target.value;

    setValue(newValue);
  }, []);

  const onBlur = useCallback(
    (event: BaseSyntheticEvent) => {
      const value = event.target.value;

      dispatch(
        updateTrustRequirementDetailsPublicIpIpRangeCondition({
          id: ipRange.id,
          range: { value: value },
        })
      );
    },
    [ipRange, dispatch]
  );

  return (
    <Input
      aria-label={translate('details.aria_labels.ip_range')}
      placeholder={translate('details.types.PublicIp.ip_range_placeholder')}
      showStateIcon={validationState?.updated && validationState?.state.error !== null}
      suffixMessage={validationState?.state.error?.message}
      error={
        validationState?.updated && validationState?.state.error !== null
          ? validationState?.state.error
          : null
      }
      textStyle="body-2"
      size="middle"
      value={value}
      onChange={onChange}
      tooltipProps={{
        placement: 'topLeft',
        maxWidth: '27rem',
      }}
      onBlur={onBlur}
      labelCss={css`
        display: none;
      `}
      stateMessageCss={css`
        display: none;
      `}
      containerCss={css`
        margin-left: 0.75rem;
        flex: 1;
      `}
    />
  );
};
