// libs
import styled, { css } from 'styled-components';

// components
import { CustomText } from 'typography/Text';
import { IpRangesView } from './View';
import { IpRangesEdit } from './Edit';

// hooks
import { useTheme } from 'styled-components';
import { useSelector } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';

// selectors
import { selectAppDetailsTabType } from 'redux/selectors';

const Container = styled.div`
  margin-left: 2rem;
  padding-bottom: 1.5rem;
  border-bottom: 0.0625rem solid ${({ theme }) => theme.palette.background.iconBackground};
  margin-bottom: 1rem;
`;

export const IpRangesBlock = () => {
  const { translate } = useTranslations('trust-requirements');

  const {
    palette: { fonts },
  } = useTheme();

  const type = useSelector(selectAppDetailsTabType);

  return (
    <Container>
      <CustomText
        css={css`
          display: block;
          margin-bottom: 0.5rem;
        `}
        type="body-2"
        color={fonts.bodyLight}
      >
        {translate('details.types.PublicIp.ip_ranges')}
      </CustomText>
      <CustomText
        type="body-2"
        color={fonts.title}
        css={css`
          display: block;
          margin-bottom: 0.75rem;
        `}
      >
        {translate(
          `details.types.PublicIp.conditions_ip_ranges_description_${
            type === 'trust-requirement-view' ? 'view' : 'edit'
          }`
        )}
      </CustomText>

      {type === 'trust-requirement-view' && <IpRangesView />}
      {(type === 'trust-requirement-edit' || type === 'trust-requirement-create') && (
        <IpRangesEdit />
      )}
    </Container>
  );
};
