// components
import { Subnet } from './Subnet';
import { CloseButton } from './CloseButton';
import { Description } from './Description';
import { Container } from './styled';

// types
import { IPolicyModifiedGatewayAllowedIpRange } from 'types';

interface ISubnetEditable {
  allowedIpRange: IPolicyModifiedGatewayAllowedIpRange;
}

export const SubnetEditable = ({ allowedIpRange }: ISubnetEditable) => {
  return (
    <Container>
      <Subnet allowedIpRange={allowedIpRange} />
      <Description allowedIpRange={allowedIpRange} />
      <CloseButton allowedIpRange={allowedIpRange} />
    </Container>
  );
};
