// components
import { FilterAndBulkActionsContainer, HeaderRow } from './styled';
import { CustomText } from 'typography/Text';
import { CustomButton, PageTitle, Filter, TrialBanner } from 'components';
import { BulkActions } from './BulkActions';
import { Table } from './Table';

// hooks
import { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';
import { useTrustRequirementsEmptyText } from './useTrustRequirementsEmptyText';
import { useParams } from 'react-router';
import { useTheme } from 'styled-components';

// actions
import {
  getTrustRequirements,
  getTrustRequirementSearchKeys,
  setTrustRequirements,
} from 'redux/actions/trust-requirements';

import {
  loadTrustRequirementDetails,
  createTrustRequirementSideMenu,
  closeDetails,
} from 'redux/actions/app';

// selectors
import {
  selectTrustRequirementsIsLoading,
  selectTrustRequirementsTotal,
  selectTrustRequirementsUpdateId,
  selectPathQuerySearch,
  selectPathQuerySort,
  selectAppDetailsTabType,
  selectAppDetailsTrustRequirementId,
  selectIsTrustRequirementsModule,
  selectTrustRequirementsSearchKeys,
} from 'redux/selectors';

// types
import { ITrustRequirementBasic } from 'types';

// constants
import { ITEMS_FOR_SKELETON, TRUST_REQUIREMENTS_SORT_OPTIONS } from 'appConstants';
import { initialTrustRequirementsResponse } from 'redux/reducers/trust-requirements';

export const emptyTrustRequirements = Array.from({ length: ITEMS_FOR_SKELETON }, (_el, index) => ({
  id: index,
})) as ITrustRequirementBasic[];

interface ITrustRequirementParams {
  requirementId: string;
}

export const TrustRequirements = () => {
  const dispatch = useDispatch();

  const { translate } = useTranslations('trust-requirements');

  const { requirementId } = useParams<keyof ITrustRequirementParams>();

  const total = useSelector(selectTrustRequirementsTotal);
  const updateId = useSelector(selectTrustRequirementsUpdateId);
  const isLoading = useSelector(selectTrustRequirementsIsLoading);
  const queryParamsSort = useSelector(selectPathQuerySort);
  const queryParamsSearch = useSelector(selectPathQuerySearch);
  const detailsType = useSelector(selectAppDetailsTabType);
  const selectedTrustRequirementId = useSelector(selectAppDetailsTrustRequirementId);
  const isTrustRequirementsModule = useSelector(selectIsTrustRequirementsModule);
  const searchKeys = useSelector(selectTrustRequirementsSearchKeys);

  const [page, setPage] = useState(0);
  const [searchValue, setSearchValue] = useState(queryParamsSearch || '');

  const [bulkSelectedTrustRequirements, setBulkSelectedTrustRequirements] = useState<
    ITrustRequirementBasic[]
  >([]);
  const [sortBy, setSortBy] = useState(
    TRUST_REQUIREMENTS_SORT_OPTIONS.find(option => option.value === queryParamsSort) ||
      TRUST_REQUIREMENTS_SORT_OPTIONS[0]
  );

  const {
    palette: { fonts },
  } = useTheme();

  useEffect(() => {
    dispatch(getTrustRequirementSearchKeys());
  }, [dispatch]);

  useEffect(() => {
    if (isTrustRequirementsModule && requirementId) {
      if (detailsType !== 'trust-requirement-view' && detailsType !== 'trust-requirement-edit') {
        dispatch(
          loadTrustRequirementDetails({
            requirementId,
            mode: 'view',
          })
        );
        return;
      }
      if (
        (detailsType === 'trust-requirement-view' || detailsType === 'trust-requirement-edit') &&
        requirementId !== String(selectedTrustRequirementId)
      ) {
        dispatch(
          loadTrustRequirementDetails({
            requirementId,
            mode: 'view',
          })
        );
        return;
      }
    }

    if (!requirementId && detailsType === 'trust-requirement-view') {
      dispatch(closeDetails({ redirect: false }));
    }
    /*
      Temporary solution. Covers back-button behavior that should close details pane in edit-mode
      Causes bug, that prevents details pane from opening via context-menu

      if (!requirementId && selectedTrustRequirementId) dispatch(closeDetails({ redirect: false }));
    */
  }, [requirementId, detailsType, selectedTrustRequirementId, dispatch, isTrustRequirementsModule]);

  const { emptyText } = useTrustRequirementsEmptyText({
    searchValue,
  });

  const createNewTrustRequirement = () => dispatch(createTrustRequirementSideMenu());

  const fetchData = useCallback(
    () =>
      dispatch(
        getTrustRequirements({
          search: searchValue,
          page: page || 0,
          sort: sortBy.value,
        })
      ),
    [dispatch, page, searchValue, sortBy.value]
  );

  useEffect(() => {
    dispatch(fetchData());
  }, [dispatch, updateId, fetchData]);

  useEffect(() => {
    return () => {
      dispatch(setTrustRequirements(initialTrustRequirementsResponse));
    };
  }, [dispatch]);

  useEffect(() => {
    setPage(0);
  }, [searchValue]);

  return (
    <>
      <PageTitle title={translate('trust_requirements')} />
      <HeaderRow>
        <CustomText data-test="trust-requirements-header" type="heading-1" color={fonts.title}>
          {translate('trust_requirements')}
        </CustomText>
        <CustomButton
          kind="primary"
          size="large"
          onClick={createNewTrustRequirement}
          disabled={detailsType === 'trust-requirement-create'}
          data-test="create-trust-requirement"
        >
          {translate('create_trust_requirement')}
        </CustomButton>
      </HeaderRow>
      <TrialBanner />
      <FilterAndBulkActionsContainer>
        {bulkSelectedTrustRequirements.length ? (
          <BulkActions
            bulkSelectedTrustRequirements={bulkSelectedTrustRequirements}
            setBulkSelectedTrustRequirements={setBulkSelectedTrustRequirements}
          />
        ) : (
          <Filter
            {...{ total, page, fetchData }}
            {...{ searchValue, setSearchValue }}
            {...{ sortBy, setSortBy, isLoading, searchKeys }}
            sortOptions={TRUST_REQUIREMENTS_SORT_OPTIONS}
          />
        )}
      </FilterAndBulkActionsContainer>
      <Table
        page={page}
        setPage={setPage}
        isLoading={isLoading}
        total={total}
        bulkSelectedTrustRequirements={bulkSelectedTrustRequirements}
        setBulkSelectedTrustRequirements={setBulkSelectedTrustRequirements}
        emptyText={emptyText}
        selectedRequirementId={requirementId}
      />
    </>
  );
};
