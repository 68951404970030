// components
import { CustomText } from 'typography/Text';
import { Container } from './styled';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';

// selectors
import { selectAppDetailsTrustRequirementIsTypeSelected } from 'redux/selectors';

export const StepInfo = () => {
  const { translate } = useTranslations('trust-requirements');

  const {
    palette: { fonts },
  } = useTheme();

  const isTypeSelected = useSelector(selectAppDetailsTrustRequirementIsTypeSelected);

  return (
    <Container>
      <CustomText type="sub-heading-1" color={fonts.disabled}>
        {isTypeSelected ? translate('details.step2') : translate('details.step1')}
      </CustomText>
    </Container>
  );
};
