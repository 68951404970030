import styled from 'styled-components';

export const UsesRemainingTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const UsesRemainingEditContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RadioManualContent = styled.div`
  display: flex;
  align-items: center;
  height: 2rem;
  display: inline-flex;
`;
