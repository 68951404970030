// Lib
import { takeLatest, takeEvery, takeLeading } from 'redux-saga/effects';

// Actions
import {
  getDnsZones,
  createDnsZone,
  updateDnsZone,
  deleteDnsZone,
  getDnsRecords,
  createDnsRecord,
  deleteDnsRecord,
  updateDnsRecord,
  deleteDnsRecords,
  getDnsZoneDetails,
  getRecordSearchKeys,
} from 'redux/actions/dns';
import { createDNSRecordSideMenu } from 'redux/actions/app';

// Sagas
import { getZonesSaga } from './getZones';
import { createZoneSaga } from './createZone';
import { getZoneDetailsSaga } from './getZoneDetails';
import { getRecordsSaga } from './getRecords';
import { createRecordSaga } from './createRecord';
import { deleteRecordSaga } from './deleteRecord';
import { deleteRecordsSaga } from './deleteRecords';
import { updateRecordSaga } from './updateRecord';
import { updateZoneSaga } from './updateZone';
import { deleteZoneSaga } from './deleteZone';
import { createRecordDetailsSaga } from './createRecordDetailsSaga';
import { getRecordSearchKeysSaga } from './getRecordSearchKeysSaga';

function* dnsSaga() {
  yield takeEvery(getDnsZones, getZonesSaga);
  yield takeEvery(createDnsZone, createZoneSaga);
  yield takeLeading(updateDnsZone, updateZoneSaga);
  yield takeLeading(deleteDnsZone, deleteZoneSaga);
  yield takeEvery(getDnsZoneDetails, getZoneDetailsSaga);
  yield takeLatest(updateDnsRecord, updateRecordSaga);
  yield takeEvery(createDnsRecord, createRecordSaga);
  yield takeLeading(getDnsRecords, getRecordsSaga);
  yield takeLeading(deleteDnsRecord, deleteRecordSaga);
  yield takeLeading(deleteDnsRecords, deleteRecordsSaga);
  yield takeLeading(createDNSRecordSideMenu, createRecordDetailsSaga);
  yield takeEvery(getRecordSearchKeys, getRecordSearchKeysSaga);
}

export default dnsSaga;
