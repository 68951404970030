import { memo } from 'react';
import Skeleton from 'react-loading-skeleton';
import { css, useTheme } from 'styled-components';
import { CustomText } from 'typography/Text';

interface IText {
  text: string;
  isLoading: boolean;
  isEnabled?: boolean;
}

export const Text = memo(({ text, isLoading, isEnabled = true }: IText) => {
  const {
    palette: { fonts },
  } = useTheme();
  return (
    <>
      {isLoading ? (
        <Skeleton />
      ) : (
        <CustomText
          ellipsis={true}
          css={css`
            display: block;
          `}
          title={text}
          type="body-2"
          color={isEnabled ? fonts.bodyDark : fonts.disabled}
        >
          {text}
        </CustomText>
      )}
    </>
  );
});
