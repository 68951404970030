// components
import { CustomText } from 'typography/Text';
import { ColumnNameText, DropdownKeyButton } from '../styled';

// hooks
import { css, useTheme } from 'styled-components';

// types
import { TypeWithDefaultButtonProps } from 'types';

interface ISuggestion {
  keyName: string;
  value: string;
  isSelected?: boolean;
}

export const Suggestion = ({
  keyName,
  value,
  isSelected,
  ...rest
}: TypeWithDefaultButtonProps<ISuggestion>) => {
  const {
    palette: { fonts },
  } = useTheme();

  return (
    <DropdownKeyButton isSelected={isSelected} {...rest}>
      <ColumnNameText type="body-2" color={fonts.help}>
        {keyName}
      </ColumnNameText>
      <CustomText
        ellipsis={true}
        title={value}
        css={css`
          display: block;
        `}
        type="body-2"
        color={fonts.bodyLight}
      >
        {value}
      </CustomText>
    </DropdownKeyButton>
  );
};
