// libs
import { createReducer } from '@reduxjs/toolkit';

// actions
import {
  setTrustRequirements,
  setTrustRequirementsShouldUpdate,
  setIsLoading,
  setTrustRequirementSearchKeys,
} from 'redux/actions/trust-requirements';

// types
import { ITrustRequirementsStore, IGetTrustRequirementsResponse } from 'types';

// utils
import { randomHash } from 'utils/hash';

export const initialTrustRequirementsResponse: IGetTrustRequirementsResponse = {
  items: [],
  metadata: {
    total: 0,
    firstPage: 0,
    prevPage: null,
    lastPage: 0,
    nextPage: null,
  },
  links: {
    first: '',
    prev: null,
    next: null,
    last: '',
  },
};

const initialState: ITrustRequirementsStore = {
  ...initialTrustRequirementsResponse,
  updateId: randomHash(),
  isLoading: false,
  searchKeys: null,
};

export const trustRequirementsReducer = createReducer(initialState, builder => {
  builder
    .addCase(setTrustRequirements, (state, { payload }) => {
      return {
        ...state,
        ...payload,
        isLoading: false,
      };
    })
    .addCase(setIsLoading, (state, { payload: isLoading }) => {
      state.isLoading = isLoading;
    })
    .addCase(setTrustRequirementsShouldUpdate, state => {
      state.updateId = randomHash();
    })
    .addCase(setTrustRequirementSearchKeys, (state, { payload }) => {
      state.searchKeys = payload;
    });
});
