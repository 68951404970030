import { STRIPE_LIB } from 'appConstants';

// Types
declare global {
  interface Window {
    Stripe: any;
  }
}

let Stripe: any = undefined;

const loadStripe = (callback: (...args: any) => any) => {
  const existingScript = document.getElementById('stripeLib');
  if (!existingScript) {
    const script = document.createElement('script');
    script.src = STRIPE_LIB;
    script.id = 'stripeLib';
    document.body.appendChild(script);
    script.onload = () => {
      if (callback) callback();
      Stripe = window.Stripe;
    };
  }
  if (existingScript && callback) callback();
};

export { Stripe, loadStripe };
