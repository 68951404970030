// libs
import { put } from 'typed-redux-saga';
import { setRecordSearchKeys } from 'redux/actions/dns';

// api
import { ApiTypeCaller } from '../../api/utils';

export function* getRecordSearchKeysSaga() {
  const { result, success } = yield* ApiTypeCaller('getDnsRecordSearchKeys');

  if (success && result) yield put(setRecordSearchKeys(result));
}
