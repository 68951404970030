// components
import { Zones } from './Zones';
import { Content } from './Content';
import { Container } from './styled';
import { PageTitle } from 'components';

// hooks
import { useTranslations } from 'hooks/useTranslations';

export const Dns = () => {
  const { translate } = useTranslations('dns');

  return (
    <>
      <PageTitle title={translate('page_title')} />
      <Container>
        <Zones />
        <Content />
      </Container>
    </>
  );
};
