// libs
import { css } from 'styled-components';
import { useEffect, useRef } from 'react';

// hooks
import { useDispatch } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';
import { useState } from 'react';
import { useSelector } from 'react-redux';

// components
import { IconDetailsAutoExpire } from 'assets/svg';
import { Details } from 'components';
import { AutoExpireSection } from './styled';
import { AutoExpireTimeZoneBlock, ExpirationActionBlock, ExpiryDateBlock } from '../../Blocks';
import Description from './Description';

// selectors
import {
  selectAppDetailsSystemAutoExpireData,
  selectAppDetailsSystemAutoExpireScrollTo,
  selectAppDetailsSystemSystemId,
  selectAppDetailsTabType,
} from 'redux/selectors';

// actions
import { createSystemDetailsAutoExpire, updateSystemDetailsAutoExpire } from 'redux/actions/app';

// types
import { ISystemAutoExpire } from 'types';

export const AutoExpire = () => {
  const { translate } = useTranslations('systems');

  const dispatch = useDispatch();

  const myRef = useRef<HTMLDivElement>(null);

  const systemId = useSelector(selectAppDetailsSystemSystemId);
  const type = useSelector(selectAppDetailsTabType);
  const autoExpireData = useSelector(selectAppDetailsSystemAutoExpireData);
  const scrollToAutoExpire = useSelector(selectAppDetailsSystemAutoExpireScrollTo);

  const [isOpen, setIsOpen] = useState(scrollToAutoExpire || false);
  const [rememberedData, setRememberedData] = useState<ISystemAutoExpire>(null);

  useEffect(() => {
    if (autoExpireData) {
      setIsOpen(true);
      setRememberedData(null);
    } else {
      setIsOpen(false);
    }
  }, [autoExpireData, setRememberedData]);

  useEffect(() => {
    if (isOpen && !autoExpireData) {
      if (rememberedData) {
        dispatch(updateSystemDetailsAutoExpire({ ...rememberedData }));
      } else {
        dispatch(createSystemDetailsAutoExpire());
      }
    }

    if (!isOpen && autoExpireData) {
      setRememberedData({ ...autoExpireData });
      dispatch(updateSystemDetailsAutoExpire(null));
    }
  }, [dispatch, isOpen, autoExpireData, rememberedData]);

  useEffect(() => {
    return () => {
      setRememberedData(null);
      setIsOpen(false);
    };
  }, [systemId]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      scrollToAutoExpire && myRef.current?.scrollIntoView();
    });

    return () => {
      clearTimeout(timeout);
    };
  }, [scrollToAutoExpire]);

  if (
    (type === 'system-view' && !autoExpireData) ||
    type === 'unapproved-system-view' ||
    type === 'unapproved-system-edit'
  ) {
    return null;
  }

  return (
    <AutoExpireSection data-test="system-details-auto-expire-section" ref={myRef}>
      <Details.SectionHeader
        Icon={IconDetailsAutoExpire}
        title={translate('details.auto_expire.title')}
        css={css`
          margin-bottom: 0.65rem;
        `}
        switchConfig={type === 'system-edit' ? { value: isOpen, setValue: setIsOpen } : undefined}
      />
      <Description />
      <ExpiryDateBlock isOpen={isOpen} />
      <ExpirationActionBlock isOpen={isOpen} />
      <AutoExpireTimeZoneBlock isOpen={isOpen} />
    </AutoExpireSection>
  );
};
