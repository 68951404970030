import { MainState, ModuleName } from 'types';
import { ROUTE_PATHS } from 'appConstants';

export const selectSearchData = (state: MainState) => {
  const location = state.router.location;
  let module: ModuleName;

  if (location.pathname.includes(ROUTE_PATHS['unapproved-systems'])) {
    module = 'unapproved-systems';
    return { module, items: state.UnapprovedSystems.items };
  }
  if (location.pathname.includes(ROUTE_PATHS['systems'])) {
    module = 'systems';
    return { module, items: state.Systems.items };
  }
  if (location.pathname.includes(ROUTE_PATHS['dns'])) {
    module = 'dns';
    return { module, items: state.Dns.records.data.items };
  }
  if (location.pathname.includes(ROUTE_PATHS['keys'])) {
    module = 'keys';
    return { module, items: state.EnrolmentKeys.items };
  }
  if (location.pathname.includes(ROUTE_PATHS['policies'])) {
    module = 'policies';
    return { module, items: state.Policies.items };
  }
};

export const selectSearchLoading = (state: MainState): boolean => {
  if (location.pathname.includes(ROUTE_PATHS['systems'])) {
    return state.EnrolmentKeys.isLoading || state.Tags.isLoading;
  }

  if (location.pathname.includes(ROUTE_PATHS['keys'])) {
    return state.Systems.isLoading || state.Tags.isLoading;
  }

  return state.Systems.isLoading || state.EnrolmentKeys.isLoading || state.Tags.isLoading;
};
