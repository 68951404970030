import styled, { css, useTheme } from 'styled-components';

import { CopyTool } from 'components';
import { CustomText } from '../Text';
import { useCopy } from 'hooks/useCopy';

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  background-color: ${props => props.theme.palette.background.base};
  border-radius: 0.25rem;
  // we have margin bottom here because otherwise tooltip would cause component to overflow
  margin-bottom: 1.5rem;
`;

const TextContainer = styled.div`
  flex-grow: 1;
  padding: 1.125rem;
  margin-right: 0.5rem;
  white-space: nowrap;
  overflow: auto;
  display: block;
`;
const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.1875rem;
  margin-top: 0.3125rem;
  border-left: 0.0625rem solid ${props => props.theme.palette.background.iconBackground};
  width: 3.5rem;
  padding: 0.5rem 0;
  height: 100%;
`;
interface ICommandLineCodeProps {
  code: string;
  disabled?: boolean;
  onCopyCode?: (key: string) => void;
}

export const CommandLineCode: React.FC<ICommandLineCodeProps> = ({
  code,
  disabled,
  onCopyCode,
  ...rest
}) => {
  const { onCopyClick, copied } = useCopy({ copyingValue: code, onCopyCode });
  const {
    palette: { fonts },
  } = useTheme();

  const textColor = disabled ? fonts.disabled : fonts.bodyDark;

  return (
    <Wrapper {...rest}>
      <TextContainer>
        <CustomText
          type="command-line"
          color={textColor}
          css={css`
            white-space: pre;
            // somehow it prevents css from adding space and line break after the element
            // but only if parent element is not flex or grid
            display: inline-block;
          `}
        >
          {code}
        </CustomText>
      </TextContainer>
      <IconContainer>
        <CopyTool disabled={disabled} copied={copied} onCopyClick={onCopyClick} />
      </IconContainer>
    </Wrapper>
  );
};
