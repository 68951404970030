// Lib
import { call } from 'typed-redux-saga';
import { getSystemsSaga } from '../systems/getSystemsSaga';
import { ITEMS_PER_TABLE_PAGE } from 'appConstants/ui';

import { IActionPayload, IEnrolAuthorityParams } from 'types';

// Utils
import { ApiTypeCaller } from '../../api/utils';

export function* enrolAuthoritySaga({ payload }: IActionPayload<IEnrolAuthorityParams>) {
  const { result, success } = yield* ApiTypeCaller('enrollAuthority', payload);

  if (success && result) {
    yield call(getSystemsSaga, { payload: { per_page: ITEMS_PER_TABLE_PAGE } });
  }
}
