// libs
import { css } from 'styled-components';

// components
import { SearchInput, RefreshButton, Select, CustomCheckbox } from 'components';
import { FilterContainer, InputWrapper, CustomButton } from './styled';
import { CustomText } from 'typography/Text';
import { OptionElement } from './OptionElement';
import { PaginationText } from './PaginationText';

// hooks
import { useTheme } from 'styled-components';
import { useCallback } from 'react';
import { useTranslations } from 'hooks/useTranslations';

// utils
import { debounce } from 'utils';

// types
import { ISearchKey } from 'types';

type SortOption = { value: string; name: string };
export interface IFilterProps<T> {
  page: number;
  total: number;
  fetchData: () => void;
  searchValue: string;
  setSearchValue: (value: string) => void;
  searchMaxLength?: number;
  showDisabled?: boolean;
  setShowDisabled?: (value: boolean) => void;
  sortOptions?: T[];
  sortBy?: T;
  setSortBy?: (value: T) => void;
  isLoading: boolean;
  searchKeys: ISearchKey[] | null;
}

export const Filter = <T extends SortOption>(props: IFilterProps<T>) => {
  const { total, page, fetchData } = props;
  const { searchValue, setSearchValue } = props;
  const { showDisabled, setShowDisabled } = props;
  const { sortOptions, sortBy, setSortBy } = props;
  const { isLoading, searchMaxLength, searchKeys } = props;

  const {
    palette: { fonts },
  } = useTheme();

  const { translate: translateGlobal } = useTranslations('global');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSearchKeyUp = useCallback(
    debounce((newSearchValue: string) => {
      setSearchValue(newSearchValue);
    }, 250),
    []
  );

  return (
    <FilterContainer data-test="table-filter">
      <InputWrapper>
        <SearchInput
          onSearchKeyUp={onSearchKeyUp}
          initialValue={searchValue}
          maxLength={searchMaxLength}
          searchKeys={searchKeys}
        />
      </InputWrapper>
      {showDisabled !== undefined && (
        <CustomButton
          size="large"
          css={css`
            min-width: 9.75rem;
            margin-left: 0.5rem;
            width: 9.75rem;
            justify-content: space-between;
          `}
          onClick={() => {
            setShowDisabled && setShowDisabled(!showDisabled);
          }}
        >
          <CustomText id="filter-show-disabled" color={fonts.bodyLight} type="body-2">
            {translateGlobal('show_disabled')}
          </CustomText>
          <CustomCheckbox
            aria-labelledby="filter-show-disabled"
            tabIndex={-1}
            checked={showDisabled}
          ></CustomCheckbox>
        </CustomButton>
      )}

      {sortOptions && (
        <Select
          aria-label={translateGlobal('sort.aria_labels.sort_by')}
          dataSource={sortOptions}
          valueField="value"
          keyField="value"
          defaultValueFieldValue={sortBy?.value}
          setSelectedValue={option => setSortBy && setSortBy(option)}
          shownDropdownClassSelector=".shown"
          height="2.5rem"
          additionalCss={css`
            margin-left: 0.5rem;
            width: 17.625rem;
          `}
          width="17.5rem"
          OptionElement={record => (
            <OptionElement prefix={translateGlobal('sort.sort_by')} name={record.name} />
          )}
        />
      )}

      <RefreshButton onRefresh={fetchData} isLoading={isLoading} />
      <PaginationText total={total} page={page} />
    </FilterContainer>
  );
};
