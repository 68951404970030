// libs
import { css } from 'styled-components';

// components
import { IconSystemsDetailsOverviewSection } from 'assets/svg';
import { Details } from 'components';
import { OverviewSection } from './styled';
import {
  SystemIdBlock,
  ConnectedFromBlock,
  EnrolledFromBlock,
  EnrolledAtBlock,
  TagsBlock,
  StateBlock,
  NotesBlock,
  VirtualNetworkBlock,
  VirtualAddressBlock,
} from '../../Blocks';

// hooks
import { useTranslations } from 'hooks/useTranslations';

export const Overview = () => {
  const { translate } = useTranslations('systems');

  return (
    <OverviewSection>
      <Details.SectionHeader
        Icon={IconSystemsDetailsOverviewSection}
        title={translate('details.overview')}
        css={css`
          margin-bottom: 1.5rem;
        `}
      />
      <SystemIdBlock />
      <StateBlock />
      <ConnectedFromBlock />
      <VirtualAddressBlock />
      <VirtualNetworkBlock />
      <EnrolledFromBlock />
      <EnrolledAtBlock />
      <TagsBlock />
      <NotesBlock />
    </OverviewSection>
  );
};
