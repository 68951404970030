import { useTheme } from 'styled-components';
import { CustomText } from 'typography/Text';
import { MessageContainer, MessageStyle } from './styled';

import {
  IconMessageSuccessCircle,
  IconMessageErrorCircle,
  IconMessageInfoCircle,
  IconMessageWarningCircle,
} from 'assets/svg';

import { IMessageType } from '../types';
import { TextTypes } from 'types';

interface IMessage {
  type: IMessageType;
  textType?: TextTypes;
  withIcon?: boolean;
  message: React.ReactNode;
}

export const CustomMessage = ({ type, message, withIcon, textType, ...rest }: IMessage) => {
  const { palette } = useTheme();

  const colorMap: Record<IMessage['type'], MessageStyle> = {
    info: {
      background: palette.background.hover,
      border: palette.fills.iconHover,
      Icon: <IconMessageInfoCircle />,
    },
    success: {
      background: palette.state.successBackground,
      border: palette.state.successStroke,
      Icon: <IconMessageSuccessCircle />,
    },
    warning: {
      background: palette.state.alertBackground,
      border: palette.state.alertStroke,
      Icon: <IconMessageWarningCircle />,
    },
    error: {
      background: palette.state.errorBackground,
      border: palette.state.errorStroke,
      Icon: <IconMessageErrorCircle />,
    },
    disabled: {
      background: palette.background.base,
      border: palette.fills.strokeMedium,
      Icon: null,
    },
  };

  const { background, border, Icon } = colorMap[type];

  return (
    <MessageContainer {...{ ...rest, background, border }}>
      {withIcon && <span>{Icon}</span>}
      <CustomText type={textType || 'body-2'} color={palette.fonts.bodyDark}>
        {message}
      </CustomText>
    </MessageContainer>
  );
};
