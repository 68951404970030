// components
import { QuestionCircleOutlined } from '@ant-design/icons';
import { IconContainer } from './styled';
import {
  IconWindowsOS,
  IconLinuxOS,
  IconRaspberryPiOS,
  IconMacOS,
  IconIosOS,
  IconAndroidOS,
} from 'assets/svg';

// hooks
import { useTheme } from 'styled-components';

// types
import { OSTypes } from 'types';

const ICONS_MAP: { [index in OSTypes]: JSX.Element } = {
  Linux: <IconLinuxOS width="1rem" height="1rem" />,
  Windows: <IconWindowsOS width="1rem" height="1rem" />,
  RaspberryPi: <IconRaspberryPiOS width="1rem" height="1rem" />,
  Darwin: <IconMacOS width="1rem" height="1rem" />,
  iOS: <IconIosOS width="1rem" height="1rem" />,
  Android: <IconAndroidOS width="1rem" height="1rem" />,
};

export interface IOSIcon {
  platformType?: OSTypes | null;
}

export const OSIcon = ({ platformType, ...rest }: IOSIcon) => {
  const {
    palette: { icons },
  } = useTheme();
  return (
    <IconContainer fill={icons.secondary} {...rest}>
      {platformType ? (
        ICONS_MAP[platformType]
      ) : (
        <QuestionCircleOutlined width="1rem" height="1rem" />
      )}
    </IconContainer>
  );
};
