// libs
import { useTheme } from 'styled-components';

// components
import { OSIcon } from 'components';

import { SystemIconContainer } from './styled';

// types
import { OSTypes } from 'types';
import { CustomText } from 'typography/Text';

interface ISystemPillProps {
  id: string;
  machineName: string | null;
  name: string | null;
  platformType: string | null;
}

export const SystemPill = ({ id, machineName, name, platformType, ...rest }: ISystemPillProps) => {
  const {
    palette: { fonts },
  } = useTheme();

  return (
    <SystemIconContainer {...rest}>
      <OSIcon platformType={platformType as OSTypes} css={{ marginRight: '0.375rem' }} />
      <CustomText type="body-2" color={fonts?.bodyDark} css={{ whiteSpace: 'nowrap' }}>
        {name || machineName || id}
      </CustomText>
    </SystemIconContainer>
  );
};
