//components
import { ActionDropdown } from 'components';

// Hooks
import { useTheme } from 'styled-components';
import { useTranslations } from 'hooks/useTranslations';
import { useDispatch, useSelector } from 'react-redux';

// actions
import { setModalContent } from 'redux/actions/app';

// Types
import { IOrganisationUser } from 'types';
import { selectCurrentOrganisationName } from 'redux/selectors';

export type UserActions = 'remove';

export type ActionMenuProps = {
  id: IOrganisationUser['id'];
  fullName: IOrganisationUser['fullName'];
  userName: string;
};

export const UserActionDropdown = ({ id, userName, fullName }: ActionMenuProps) => {
  const {
    palette: { state },
  } = useTheme();
  const { translate } = useTranslations('settings');
  const orgName = useSelector(selectCurrentOrganisationName);

  const dispatch = useDispatch();
  const openRemoveUserModal = () => {
    dispatch(
      setModalContent({
        type: 'remove-user',
        data: {
          accountId: id,
          fullName,
          userName,
          orgName: orgName,
        },
      })
    );
  };

  return (
    <ActionDropdown
      placement="bottomRight"
      data={[
        {
          text: translate('remove_user'),
          onClick: openRemoveUserModal,
          color: state.error,
        },
      ]}
    />
  );
};
