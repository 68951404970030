// components
import { RadioSwitch } from 'components';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { useCallback } from 'react';

// types
import { ITrustRequirementPublicIpLocationConditionModified } from 'types';

interface isBlocked {
  isBlocked: ITrustRequirementPublicIpLocationConditionModified['isBlocked'];
  setIsBlocked: (
    isBlocked: ITrustRequirementPublicIpLocationConditionModified['isBlocked']
  ) => void;
}

export const IsBlockedEdit = ({ isBlocked, setIsBlocked }: isBlocked) => {
  const { translate } = useTranslations('trust-requirements');

  const onChange = useCallback(() => {
    setIsBlocked(isBlocked === 'true' ? 'false' : 'true');
  }, [isBlocked, setIsBlocked]);

  return (
    <RadioSwitch
      onChange={onChange}
      value={isBlocked === 'true' ? 'blocked' : 'required'}
      left={{
        text: translate('details.types.PublicIp.location_access_allow'),
        value: 'required',
      }}
      right={{
        text: translate('details.types.PublicIp.location_access_deny'),
        value: 'blocked',
      }}
    ></RadioSwitch>
  );
};
