// libs
import { memo } from 'react';

// components
import { TagsList } from 'components';
import Skeleton from 'react-loading-skeleton';
import { CustomText } from 'typography/Text';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { useTheme } from 'styled-components';

// types
import { ISystemSummaryModel } from 'types';

interface ITagsProps {
  isEnabled?: boolean;
  tags: ISystemSummaryModel['tags'];
}
interface ISystemTags extends ITagsProps {
  tags: ISystemSummaryModel['tags'];
  isLoading: boolean;
}

const Tags = memo(({ tags, isEnabled }: ITagsProps) => {
  const { translate } = useTranslations('tags');

  const {
    palette: { fonts },
  } = useTheme();

  return (
    <>
      {tags.length ? (
        <TagsList tags={tags} />
      ) : (
        <CustomText type="body-2" color={isEnabled ? fonts.bodyDark : fonts.disabled}>
          {translate('none')}
        </CustomText>
      )}
    </>
  );
});

export const SystemTags = memo(({ tags, isLoading, isEnabled = true }: ISystemTags) => {
  return <>{isLoading ? <Skeleton /> : <Tags tags={tags} isEnabled={isEnabled} />}</>;
});
