// libs
import { css, useTheme } from 'styled-components';

// components
import { CustomButton, CustomMessage } from 'components';
import { EnrolmentKeyCode } from 'typography/Code';
import { IconEnrolmentKeyViewKeyModal } from 'assets/svg';
import { ModalFooter, ModalHeader, ModalScrollablePart } from 'components/Modal';
import { CustomText } from 'typography/Text';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { useDispatch, useSelector } from 'react-redux';
import { useSubmitOnEnter } from 'hooks/useSubmitOnEnter';

// selectors
import {
  selectAppDetailsEnrolmentKeyDescription,
  selectAppDetailsEnrolmentKeyKey,
  selectAppDetailsEnrolmentKeyNewDescription,
  selectAppDetailsEnrolmentKeyStatus,
} from 'redux/selectors';

// actions
import { setModalContent } from 'redux/actions/app';

export const ViewActualKeyModal = () => {
  const dispatch = useDispatch();

  const { translate } = useTranslations('enrolment-keys');

  const {
    palette: { fonts },
  } = useTheme();

  const key = useSelector(selectAppDetailsEnrolmentKeyKey);
  const description = useSelector(selectAppDetailsEnrolmentKeyDescription);
  const newDescription = useSelector(selectAppDetailsEnrolmentKeyNewDescription);
  const status = useSelector(selectAppDetailsEnrolmentKeyStatus);

  const onClose = () => {
    dispatch(setModalContent({ type: null, data: null }));
  };

  const getMessage = () => {
    if (status === 'Disabled') {
      return translate('details.view_key_modal.disabled');
    } else if (status === 'NoUsesRemaining') {
      return translate('details.exhausted_uses_warning');
    } else {
      return null;
    }
  };

  const message = getMessage();

  useSubmitOnEnter({ callback: onClose });

  return (
    <>
      <ModalHeader
        icon={<IconEnrolmentKeyViewKeyModal width="2.5rem" height="2.5rem" />}
        title={newDescription || description || ''}
      />
      <ModalScrollablePart>
        {message && (
          <CustomMessage
            css={css`
              margin-bottom: 2rem;
            `}
            type="warning"
            message={message}
            withIcon
          />
        )}
        <CustomText
          css={css`
            display: block;
            margin-bottom: 1.5rem;
          `}
          type="body-1"
          color={fonts.bodyLight}
        >
          {translate('details.view_key_modal.reminder')}
        </CustomText>
        {key && (
          <EnrolmentKeyCode
            enrolmentKey={key}
            css={css`
              margin-top: -0.5rem;
            `}
          />
        )}
      </ModalScrollablePart>

      <ModalFooter
        css={css`
          justify-content: flex-end;
        `}
      >
        <CustomButton size="large" onClick={onClose}>
          {translate('details.view_key_modal.done')}
        </CustomButton>
      </ModalFooter>
    </>
  );
};
