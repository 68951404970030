import { useEffect } from 'react';

type Props = {
  callback: () => void;
};

export const useSubmitOnEnter = ({ callback }: Props) => {
  useEffect(() => {
    const submitOnEnter = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        callback();
      }
    };
    window.addEventListener('keydown', submitOnEnter);
    return () => {
      window.removeEventListener('keydown', submitOnEnter);
    };
  }, [callback]);
};
