// components
import { CustomText } from 'typography/Text';

// hooks
import { css, useTheme } from 'styled-components';
import { useEffect, useState } from 'react';

// constants
import { defaultTimeZone } from 'appConstants';

interface ITimeZoneOptionProps {
  zone: string | undefined;
  offset: number | undefined;
}

function toHoursAndMinutes(totalMinutes: number) {
  const negative = totalMinutes < 0;
  const hours = Math.abs(totalMinutes / 60);
  const rhours = Math.floor(hours);
  const minutes = (hours - rhours) * 60;
  const rminutes = Math.round(minutes);

  return `${negative ? '-' : '+'}${rhours.toString().padStart(2, '0')}:${rminutes
    .toString()
    .padStart(2, '0')}`;
}

export const TimeZoneOption = ({ zone, offset }: ITimeZoneOptionProps) => {
  const {
    palette: { fonts },
  } = useTheme();

  const [newTimeZone, setNewTimeZone] = useState<{ zone: string; offset: number } | null>(null);

  useEffect(() => {
    if (!zone || offset === undefined) {
      // when timeZone is null it should be conveted to local timezone
      setNewTimeZone(defaultTimeZone);
    }
  }, [offset, zone]);

  return (
    <CustomText
      type="body-2"
      color={fonts.title}
      css={css`
        min-width: 350px;
      `}
    >
      {`(UTC ${toHoursAndMinutes(offset || newTimeZone?.offset || 0)})  ${(
        zone || newTimeZone?.zone
      )?.replaceAll('_', '')}`}
    </CustomText>
  );
};
