// components
import { CustomText } from 'typography/Text';
import { EditContainer, StyledControllableIcon, StyledOptionDescription } from './styled';
import { IconPolicyGatewayOutlined, IconPolicyOutlined } from 'assets/svg';

// hooks
import { useTheme } from 'styled-components';
import { useSelector } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';

// selectors
import { selectAppDetailCurrentPolicyType } from 'redux/selectors';

export const PolicyTypeView = () => {
  const { translate } = useTranslations('policies');

  const {
    palette: {
      fonts: { bodyLight },
    },
  } = useTheme();

  const policyType = useSelector(selectAppDetailCurrentPolicyType);

  return (
    <EditContainer data-test="policy-view-details-type">
      {policyType === 'General' && (
        <StyledControllableIcon size="large" state="default" icon={<IconPolicyOutlined />} />
      )}
      {policyType === 'Gateway' && (
        <StyledControllableIcon size="large" state="default" icon={<IconPolicyGatewayOutlined />} />
      )}
      <StyledOptionDescription>
        <CustomText type="body-2">
          {policyType === 'Gateway'
            ? translate('details.type_switch.gateway_type')
            : translate('details.type_switch.general_type')}
        </CustomText>
        <CustomText type="hint" color={bodyLight}>
          {policyType === 'Gateway'
            ? translate('details.type_switch.gateway_type_hint')
            : translate('details.type_switch.general_type_hint')}
        </CustomText>
      </StyledOptionDescription>
    </EditContainer>
  );
};
