// components
import { Content } from './Content';

// selectors
import { selectAppDetailsTabType } from 'redux/selectors';

// hooks
import { useSelector } from 'react-redux';

export const ConnectedFromBlock = () => {
  const type = useSelector(selectAppDetailsTabType);

  return <Content extended={type === 'system-view' || type === 'system-edit'} />;
};
