// Lib
import { persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import { configureStore } from '@reduxjs/toolkit';
import { Action } from '@reduxjs/toolkit';
import { crossBrowserListener } from './crossBrowserListener';
import { userPersistConfig } from 'redux/reducers/index';

// Root saga
import mainSaga from '../sagas';

// Root reducer
import rootReducer from '../reducers';

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: rootReducer(),
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
      immutableCheck: false,
    }).concat(sagaMiddleware),
});
const persistor = persistStore(store, {});

window.addEventListener('storage', crossBrowserListener(store, userPersistConfig));

sagaMiddleware.run(mainSaga);

const dispatchService = (action: Action<any>) => {
  const serviceStore = store;
  if (serviceStore && serviceStore.dispatch) serviceStore.dispatch(action);
};

export { store, persistor, dispatchService };
