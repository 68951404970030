// libs
import { css } from 'styled-components';

// Components
import { Details } from 'components';

// Hooks
import { useDispatch, useSelector } from 'react-redux';

// Actions
import { cancelEditDetails, updateValidationStateUpdates } from 'redux/actions/app';
import { updateDnsRecord } from 'redux/actions/dns';

// selectors
import { selectAppDetailsTabIsValid } from 'redux/selectors';

export const CancelSaveEdit = () => {
  const isValid = useSelector(selectAppDetailsTabIsValid);
  const dispatch = useDispatch();

  const handleCancel = () => {
    dispatch(cancelEditDetails());
  };

  const handleSave = () => {
    if (isValid) {
      dispatch(updateDnsRecord());
    } else {
      dispatch(updateValidationStateUpdates());
    }
  };

  return (
    <Details.CancelSave
      css={css`
        margin: 0 2.5rem;
      `}
      handleCancel={handleCancel}
      handleSave={handleSave}
    />
  );
};
