// Lib
import { put } from 'typed-redux-saga';

// Utils
import { ApiTypeCaller } from '../../api/utils';

// Actions
import { setOrganisationUsers } from 'redux/actions/organisation';

export function* getOrganisationUsersSaga() {
  const { result, success } = yield* ApiTypeCaller('getOrganisationUsers');
  if (success && result) yield put(setOrganisationUsers(result.users));
}
