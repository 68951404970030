import styled, { css } from 'styled-components';

export const TagAndSystemListWrapper = styled.div<{ width?: string; blocksMode?: boolean }>`
  width: ${({ width }) => width};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.25rem;
  align-items: center;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  border-radius: 0.25rem;
  ${({ blocksMode }) =>
    blocksMode &&
    css`
      span {
        display: block;
        width: fit-content;
      }
    `}
`;

export const tagCss = css`
  margin-right: 0px;
`;
