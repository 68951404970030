// libs
import { css } from 'styled-components';

// components
import { ActiveDaysContent } from './styled';
import { ActiveDaysView } from './View';
import { ActiveDaysEdit } from './Edit';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { useSelector } from 'react-redux';

// selectors
import { selectAppDetailsTabType } from 'redux/selectors';
import { Details } from 'components';

interface IActiveHoursBlock {
  isOpen: boolean;
}

export const ActiveDaysBlock = ({ isOpen }: IActiveHoursBlock) => {
  const { translate } = useTranslations('policies');

  const type = useSelector(selectAppDetailsTabType);

  return (
    <ActiveDaysContent isOpen={isOpen}>
      <Details.KeyValue
        contentCss={css`
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
        `}
        css={css`
          padding: 0;
        `}
        title={translate('details.time_limitations.active_days.title')}
      >
        {type === 'policy-view' && <ActiveDaysView />}
        {(type === 'policy-edit' || type === 'policy-create') && <ActiveDaysEdit />}
      </Details.KeyValue>
    </ActiveDaysContent>
  );
};
