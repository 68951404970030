// Lib
import { put } from 'typed-redux-saga';

// Utils
import { ApiTypeCaller } from '../../api/utils';

// Actions
import { setOrganisationInvites } from 'redux/actions/organisation';

export function* getOrganisationInvitesSaga() {
  const { result, success } = yield* ApiTypeCaller('getOrganisationInvites');
  if (success && result) yield put(setOrganisationInvites(result.invites));
}
