// components
import { SystemsListWithConnectedStatus } from 'components';

// hooks
import { useSelector } from 'react-redux';

// selectors
import { selectAppDetailsDnsSystems } from 'redux/selectors';

export const Systems = () => {
  const systems = useSelector(selectAppDetailsDnsSystems) || [];

  return <SystemsListWithConnectedStatus systems={systems} />;
};
