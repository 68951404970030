// components
import { selectAppDetailsTrustRequirementCurrentAuthority } from 'redux/selectors';
import { ConfigurationClarificationBlock, UserAuthenticationBlocks } from '../../Blocks/';
import { Wrapper } from './Wrapper';

// hooks
import { useSelector } from 'react-redux';

export const UserAuthentication = () => {
  const authority = useSelector(selectAppDetailsTrustRequirementCurrentAuthority);

  return (
    <Wrapper>
      <UserAuthenticationBlocks.AuthorityBlock />
      {authority && <ConfigurationClarificationBlock />}
      {authority === 'azure' && <UserAuthenticationBlocks.Authorities.Microsoft.TenantIdBlock />}
      {authority === 'google' && <UserAuthenticationBlocks.Authorities.Google.DomainIdBlock />}
      {authority === 'oidc' && (
        <div>
          <UserAuthenticationBlocks.Authorities.GenericOIDC.AuthorityUriBlock />
          <UserAuthenticationBlocks.Authorities.GenericOIDC.ClientIdBlock />
          <UserAuthenticationBlocks.Authorities.GenericOIDC.AudienceBlock />
        </div>
      )}
      {authority === 'duo' && (
        <div>
          <UserAuthenticationBlocks.Authorities.Duo.ClientIdBlock />
          <UserAuthenticationBlocks.Authorities.Duo.AuthorityUriBlock />
        </div>
      )}
      {authority === 'okta' && (
        <div>
          <UserAuthenticationBlocks.Authorities.Okta.ClientIdBlock />
          <UserAuthenticationBlocks.Authorities.Okta.AuthorityUriBlock />
          <UserAuthenticationBlocks.Authorities.Okta.AudienceBlock />
        </div>
      )}
      {authority === 'jumpcloud' && (
        <div>
          <UserAuthenticationBlocks.Authorities.JumpCloud.AuthorityUriBlock />
          <UserAuthenticationBlocks.Authorities.JumpCloud.ClientIdBlock />
        </div>
      )}
    </Wrapper>
  );
};
