// components
import { CustomText } from 'typography/Text';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { useTheme } from 'styled-components';

// utils
import { getLocalTimezoneAbbreviation } from 'utils';

export const DateColumnTitle = () => {
  const {
    palette: { fonts },
  } = useTheme();

  const { translate } = useTranslations('security-events');

  const timezoneAbbreviation = getLocalTimezoneAbbreviation();

  return (
    <>
      <CustomText type="sub-heading-2" color={fonts.bodyDark}>
        {translate('table.date')}{' '}
      </CustomText>
      <CustomText type="sub-heading-2" color={fonts.bodyLight}>
        {translate('table.date_parentheses', { abbreviation: timezoneAbbreviation })}
      </CustomText>
    </>
  );
};
