import { ITheme } from 'types/theme';
import { UI_TEXT_SIZES, UI_LAYOUT_SIZES, UI_MEDIA_QUERIES } from 'appConstants/ui';
import { percentToHex } from 'utils';

const lightTheme: ITheme = {
  name: 'light',
  palette: {
    background: {
      base: '#F9FAFA',
      panel: '#FFFFFF',
      sections: '#F4F6F7',
      iconBackground: '#EDF0F2',
      hover: '#F0F8FE',
      focus: '#F0F8FE',
      modal: '#1E2025E6',
    },
    fonts: {
      primaryButton: '#FFFFFF',
      title: '#1E2025',
      bodyDark: '#3C4049',
      bodyLight: '#656C7B',
      disabled: '#989FAE',
      help: '#989FAE',
    },
    icons: {
      primary: '#336ECC',
      secondary: '#656C7B',
      disabled: '#989FAE',
      white: '#FFFFFF',
    },
    state: {
      success: '#2FC47C',
      successStroke: '#A1E9C6',
      successBackground: '#ECFBF4',
      alert: '#FADB14',
      alertStroke: '#FFE58F',
      alertBackground: '#FFFBE6',
      error: '#F5222D',
      errorBackground: '#FFF1F0',
      errorHover: '#CF1322',
      errorPressed: '#A8071A',
      errorStroke: '#FFCCC7',
      inputWarning: '#FAC432',
    },
    fills: {
      primary: '#336ECC',
      stroke: '#73AAF7',
      strokeMedium: '#DFE1E7',
      strokeDark: '#C0C4CE',
      iconHover: '#D2E8FD',
      primaryHover: '#003A8C',
      primaryPressed: '#32559A',
    },
    elevationShadows: {
      100: `0 0 0.0625rem #3C4049${percentToHex(15)}, 0 0.0625rem 0.0625rem #3C4049${percentToHex(
        10
      )}`,
      200: `0 0 0.25rem #3C4049${percentToHex(1)}, 0 0.25rem 0.5rem #3C4049${percentToHex(6)}`,
      300: `0 0 0.0625rem #3C4049${percentToHex(15)}, 0px 0.5rem 1rem #3C4049${percentToHex(10)}`,
      400: `0 0 0.0625rem #3C4049${percentToHex(15)}, 0 0.75rem 1.5rem #3C4049${percentToHex(10)}`,
      500: `0 0 0.0625rem #3C4049${percentToHex(15)}, 0 1.25rem 2rem #3C4049${percentToHex(10)}`,
      600: `0 0 0.0625rem #3C4049${percentToHex(15)}, 0 1.25rem 2rem #3C4049${percentToHex(10)}`,
    },
    fieldShadows: {
      normal: `0 0.0625rem 0.375rem #336ECC${percentToHex(80)}`,
      error: `0 0.0625rem 0.375rem #F5222D${percentToHex(50)}`,
      alert: `0 0.0625rem 0.375rem #FADB14${percentToHex(50)}`,
    },
    popovers: {
      backgound: '#FFFFFF',
      shadowBorder: `#3C4049${percentToHex(10)}`,
      shadow: `#3C4049${percentToHex(50)}`,
    },
  },
  text_sizes: UI_TEXT_SIZES,
  layout_sizes: UI_LAYOUT_SIZES,
  media_queries: UI_MEDIA_QUERIES,
};

export const themes = {
  light: lightTheme,
};
