import styled from 'styled-components';
import { Button } from 'antd';

export const BulkActionsContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ClearButton = styled(Button)`
  color: ${({ theme }) => theme.palette.fills.primary}!important;
`;
