// components
import { PurposeCreate } from './Create';
import { PurposeView } from './View';

// hooks
import { useSelector } from 'react-redux';

// selectors
import { selectAppDetailsTabType } from 'redux/selectors';

export const PurposeBlock = () => {
  const type = useSelector(selectAppDetailsTabType);

  return (
    <>
      {(type === 'enrolment-key-view' || type === 'enrolment-key-edit') && <PurposeView />}
      {type === 'enrolment-key-create' && <PurposeCreate />}
    </>
  );
};
