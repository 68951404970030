// Lib
import { takeEvery } from 'redux-saga/effects';

// Actions
import { getSecurityEvents } from '../../../actions/security-events';

// Sagas
import { getSecurityEventsSaga } from './getSecurityEventsSaga';

function* securityEventsSaga() {
  yield takeEvery(getSecurityEvents, getSecurityEventsSaga);
}

export default securityEventsSaga;
