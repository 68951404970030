// Hooks
import { useTranslations } from 'hooks/useTranslations';
import { css, useTheme } from 'styled-components';
import { useSelector } from 'react-redux';

// components
import { Menu } from 'antd';
import { StyledMenuContainer } from './Styled';
import { SideMenuItemLink } from './MenuItems';
import { CustomText } from 'typography/Text';
import { useEffect, useState } from 'react';
import { OrgSelect } from './OrgSelect';
import { OrganisationPlan } from './OrganisationPlan';

import {
  IconCommunityPlan,
  IconSideMenuDashboard,
  IconSideMenuEnrolNewSystem,
  IconSideMenuEnrolmentKeys,
  IconSideMenuPolicies,
  IconSideMenuSecurityEvents,
  IconSideMenuSystems,
  IconSideMenuDns,
  IconSideMenuTrustRequirements,
  IconSideMenuTag,
} from 'assets/svg';

// selectors
import { selectOrgId, selectModuleName } from 'redux/selectors';

// types
import { ISideMenuRoutePathKey, ISideMenuRoutePathValue } from 'types';

// constants
import { SIDE_MENU_ROUTE_PATHS } from 'appConstants';

const { ItemGroup } = Menu;

const menuStyle = css`
  border: none;
  background: none;

  .ant-menu-item-group-title {
    margin: 0;
    padding: 0.125rem 1.5rem;
  }
`;

export const SideMenu = () => {
  const { translate } = useTranslations('menu');

  const {
    palette: { fonts, icons, background },
  } = useTheme();

  const moduleName = useSelector(selectModuleName);
  const orgId = useSelector(selectOrgId);

  const [selectedKey, setSelectedKey] = useState<ISideMenuRoutePathValue>(undefined);

  useEffect(() => {
    for (const routeStart in SIDE_MENU_ROUTE_PATHS) {
      if (moduleName === routeStart) {
        if (selectedKey !== SIDE_MENU_ROUTE_PATHS[routeStart as ISideMenuRoutePathKey])
          setSelectedKey(SIDE_MENU_ROUTE_PATHS[routeStart as ISideMenuRoutePathKey]);
        return;
      }
    }
    setSelectedKey(undefined);
  }, [moduleName, selectedKey, orgId]);

  return (
    <StyledMenuContainer>
      <OrgSelect />
      <nav>
        <Menu
          selectedKeys={selectedKey ? [selectedKey] : []}
          css={menuStyle}
          onSelect={selectedInfo => setSelectedKey(selectedInfo.key as ISideMenuRoutePathValue)}
          role="list"
        >
          <ItemGroup
            title={
              <CustomText type="label-1" color={fonts.bodyLight}>
                {translate('start')}
              </CustomText>
            }
          >
            <SideMenuItemLink
              selectedKey={selectedKey}
              key={SIDE_MENU_ROUTE_PATHS.dashboard}
              to={`/org/${orgId}${SIDE_MENU_ROUTE_PATHS.dashboard}`}
              icon={<IconSideMenuDashboard />}
            >
              {translate('Dashboard')}
            </SideMenuItemLink>
            <SideMenuItemLink
              selectedKey={selectedKey}
              key={SIDE_MENU_ROUTE_PATHS['new-system']}
              to={`/org/${orgId}${SIDE_MENU_ROUTE_PATHS['new-system']}`}
              icon={<IconSideMenuEnrolNewSystem />}
              customSelectedStateIconCss={css`
                svg {
                  path {
                    fill: ${icons.white};
                  }
                  path:last-child {
                    stroke: ${icons.white};
                  }

                  rect {
                    fill: ${icons.primary};
                  }
                }
              `}
            >
              {translate('enroll_a_new_system')}
            </SideMenuItemLink>
          </ItemGroup>
          <ItemGroup
            title={
              <CustomText type="label-1" color={fonts.bodyLight}>
                {translate('manage')}
              </CustomText>
            }
          >
            <SideMenuItemLink
              selectedKey={selectedKey}
              key={SIDE_MENU_ROUTE_PATHS.keys}
              to={`/org/${orgId}${SIDE_MENU_ROUTE_PATHS.keys}`}
              icon={<IconSideMenuEnrolmentKeys />}
            >
              {translate('enrolment_keys')}
            </SideMenuItemLink>
            <SideMenuItemLink
              selectedKey={selectedKey}
              key={SIDE_MENU_ROUTE_PATHS.systems}
              to={`/org/${orgId}${SIDE_MENU_ROUTE_PATHS.systems}`}
              icon={<IconSideMenuSystems />}
              customDefaultStateIconCss={css`
                svg {
                  path:not(:nth-last-child(1)):not(:nth-last-child(2)) {
                    fill: ${icons.disabled};
                  }
                  path:nth-last-child(1),
                  path:nth-last-child(2) {
                    fill: ${background.iconBackground};
                  }

                  rect {
                    fill: ${background.iconBackground};
                  }
                }
              `}
              customSelectedStateIconCss={css`
                svg {
                  path:not(:nth-last-child(1)):not(:nth-last-child(2)) {
                    fill: ${icons.white};
                  }
                  path:nth-last-child(1),
                  path:nth-last-child(2) {
                    fill: ${icons.primary};
                  }

                  rect {
                    fill: ${icons.primary};
                  }
                }
              `}
            >
              {translate('systems')}
            </SideMenuItemLink>
            <SideMenuItemLink
              selectedKey={selectedKey}
              key={SIDE_MENU_ROUTE_PATHS.dns}
              to={`/org/${orgId}${SIDE_MENU_ROUTE_PATHS.dns}/zones`}
              icon={<IconSideMenuDns />}
            >
              {translate('dns')}
            </SideMenuItemLink>
            <SideMenuItemLink
              selectedKey={selectedKey}
              key={SIDE_MENU_ROUTE_PATHS.policies}
              to={`/org/${orgId}${SIDE_MENU_ROUTE_PATHS.policies}`}
              icon={<IconSideMenuPolicies />}
            >
              {translate('policies')}
            </SideMenuItemLink>
            <SideMenuItemLink
              selectedKey={selectedKey}
              key={SIDE_MENU_ROUTE_PATHS.tags}
              to={`/org/${orgId}${SIDE_MENU_ROUTE_PATHS.tags}`}
              icon={<IconSideMenuTag />}
            >
              {translate('tags')}
            </SideMenuItemLink>
            <SideMenuItemLink
              selectedKey={selectedKey}
              key={SIDE_MENU_ROUTE_PATHS['trust-requirements']}
              to={`/org/${orgId}${SIDE_MENU_ROUTE_PATHS['trust-requirements']}`}
              icon={<IconSideMenuTrustRequirements />}
              customDefaultStateIconCss={css`
                svg {
                  path:first-child {
                    fill: ${background.iconBackground};
                  }

                  path:last-child {
                    fill: ${icons.disabled};
                  }
                }
              `}
              customSelectedStateIconCss={css`
                svg {
                  path:first-child {
                    fill: ${icons.primary};
                  }

                  path:last-child {
                    fill: ${icons.white};
                  }
                }
              `}
            >
              {translate('trust_requirements')}
            </SideMenuItemLink>
          </ItemGroup>
          <ItemGroup
            title={
              <CustomText type="label-1" color={fonts.bodyLight}>
                {translate('audit')}
              </CustomText>
            }
          >
            <SideMenuItemLink
              selectedKey={selectedKey}
              key={SIDE_MENU_ROUTE_PATHS.events}
              to={`/org/${orgId}${SIDE_MENU_ROUTE_PATHS.events}`}
              icon={<IconSideMenuSecurityEvents />}
            >
              {translate('security_events')}
            </SideMenuItemLink>
          </ItemGroup>
        </Menu>
      </nav>
      <OrganisationPlan
        css={css`
          margin-bottom: 0.25rem;
        `}
      />
      <Menu
        selectedKeys={selectedKey ? [selectedKey] : []}
        css={css`
          ${menuStyle}
          margin-top: auto;
          margin-bottom: 1rem;
          border-top: 0.0625rem solid ${({ theme }) => theme.palette.background.iconBackground};
        `}
        role="list"
      >
        <SideMenuItemLink
          selectedKey={selectedKey}
          key={SIDE_MENU_ROUTE_PATHS.settings}
          to={`/org/${orgId}/settings`}
          icon={<IconCommunityPlan />}
        >
          {translate('settings')}
        </SideMenuItemLink>
      </Menu>
    </StyledMenuContainer>
  );
};
