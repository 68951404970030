// libs
import { css, useTheme } from 'styled-components';

// components
import { Select } from 'components';
import { Container, InvitesIndicator, OptionItem } from './styled';
import { OrganisationBadge } from 'components/OrganisationBadge';
import { NoOrganisations } from './NoOrgs';
import { CustomText } from 'typography/Text';

// hooks
import { useDispatch, useSelector } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';

// selectors
import { selectAccountInvitesAndOrgs, selectAccountCurrentOrganisation } from 'redux/selectors';

// types
import { IOrganisationWithInvite } from 'types';

// actions
import { changeCurrentOrganisation } from 'redux/actions/account';
import { setModalContent } from 'redux/actions/app';

// utils
import { equals } from 'ramda';

export const OrgSelect = () => {
  const dispatch = useDispatch();

  const {
    palette: { fonts },
  } = useTheme();

  const { translate } = useTranslations('menu');

  const orgs = useSelector(selectAccountInvitesAndOrgs, equals);
  const currentOrg = useSelector(selectAccountCurrentOrganisation, equals);
  const hasInvites = orgs.find(org => org.role === 'Invite') !== undefined;

  const openJoinOrgModal = ({ record }: { record: IOrganisationWithInvite }) => {
    dispatch(setModalContent({ type: 'join-org', data: record }));
  };

  return (
    <Container>
      <Select
        dataSource={orgs}
        valueField="orgId"
        aria-label={translate('aria_labels.select_org')}
        keyField="orgId"
        containerCss={css`
          .ant-select-item-option-active {
            .invite-indicator {
              border-color: ${({ theme }) => theme.palette.background.base};
            }
          }
        `}
        showSelectedOptionInDropdown={false}
        defaultValueFieldValue={currentOrg?.orgId}
        value={currentOrg?.orgId}
        setSelectedValue={option => {
          option.role !== 'Invite' && dispatch(changeCurrentOrganisation(option.orgId));
        }}
        OptionElement={(record: IOrganisationWithInvite) => (
          <OptionItem
            onClick={record.role === 'Invite' ? () => openJoinOrgModal({ record }) : undefined}
          >
            <OrganisationBadge
              altName={record.orgName}
              inviteIndicator={record.role === 'Invite'}
            />
            <CustomText
              type="sub-heading-2"
              color={fonts.bodyDark}
              ellipsis={true}
              title={record.orgName}
            >
              {record.orgName}
            </CustomText>
          </OptionItem>
        )}
        height="3.5rem"
        alternativeDropdown={orgs.length === 1 ? <NoOrganisations /> : undefined}
      />

      {hasInvites && <InvitesIndicator />}
    </Container>
  );
};
