// libs
import { ChangeEvent, useState } from 'react';
import { css } from 'styled-components';

// components
import { Input } from 'components';

// types
import { TFunction } from 'react-i18next/.';
import { translations } from 'types';

interface IRangeField {
  description?: string | null;
  descriptionChange: (newRange: string, index: number) => void;
  index: number;
  translate: TFunction<translations>;
}

export const DescriptionField = ({
  description,
  descriptionChange,
  index,
  translate,
}: IRangeField) => {
  const [value, setValue] = useState(description);

  const [isChanged, setIsChanged] = useState(false);

  const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;

    !isChanged && setIsChanged(true);
    setValue(newValue);
  };

  const onBlurHandler = () => {
    if (description !== value && value !== undefined && value !== null) {
      descriptionChange(value, index);
    }
  };

  return (
    <Input
      placeholder={translate('details.ip_address_description_placeholder')}
      value={value ?? undefined}
      onBlur={onBlurHandler}
      onChange={onChangeHandler}
      sharpborders
      stateMessageCss={css`
        display: none;
      `}
    />
  );
};
