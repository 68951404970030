// libs
import { css, useTheme } from 'styled-components';

// components
import { DetailRow } from 'components/DetailsPanel/KeyValue/styled';
import { CustomText } from 'typography/Text';

// hooks
import { useSelector } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';
import { Trans } from 'react-i18next';

// selectors
import {
  selectAppDetailsTabType,
  selectAppDetailsTrustRequirementCurrentAuthority,
  selectAppDetailsTrustRequirementCurrentType,
} from 'redux/selectors';

import { ExternalLink } from 'components';
import { USER_AUTHENTICATION_DOCS_PREFIX } from 'appConstants';

export const ConfigurationClarificationBlock = () => {
  const { translate } = useTranslations('trust-requirements');

  const {
    palette: { fonts },
  } = useTheme();

  const type = useSelector(selectAppDetailsTrustRequirementCurrentType);
  const tabType = useSelector(selectAppDetailsTabType);
  const authority = useSelector(selectAppDetailsTrustRequirementCurrentAuthority);

  if (!authority) return null;

  return (
    <DetailRow>
      <CustomText
        css={css`
          margin-top: 1.5rem;
        `}
        type="body-2"
        color={fonts.bodyLight}
      >
        <Trans
          i18nKey={translate(
            `details.types.${type}.authorities.${authority}.configuration_clarification_${
              tabType === 'trust-requirement-view' ? 'view' : 'edit'
            }`
          )}
          components={[
            <ExternalLink
              key={0}
              textType="body-2"
              href={`${USER_AUTHENTICATION_DOCS_PREFIX}${authority}`}
              text={translate('details.docs')}
            />,
          ]}
        />
      </CustomText>
    </DetailRow>
  );
};
