// libs
import { css, useTheme } from 'styled-components';

// components
import { Details } from 'components';
import { CustomText } from 'typography/Text';

// selectors
import { selectAppDetailsTrustRequirementCurrentType } from 'redux/selectors';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { useSelector } from 'react-redux';

export const TypeBlock = () => {
  const { translate } = useTranslations('trust-requirements');

  const {
    palette: { fonts },
  } = useTheme();

  const type = useSelector(selectAppDetailsTrustRequirementCurrentType);

  return (
    <Details.KeyValue
      css={css`
        margin-bottom: 1.5rem;
      `}
      title={translate('details.type')}
    >
      <CustomText type="body-2" color={fonts.title}>
        {translate(`details.types.${type}.name`)}
      </CustomText>
    </Details.KeyValue>
  );
};
