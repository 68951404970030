import styled from 'styled-components';

export const InfoList = styled.ul`
  background-color: ${({ theme }) => theme.palette.background.base};
  border-radius: 0.25rem;
  padding: 1rem 1rem 1rem 1.5rem;
  margin: 0;
  list-style: none;
`;

export const ListItem = styled.li`
  display: flex;
  flex-direction: row;
  margin-bottom: 0.25rem;
`;

export const CustomMarker = styled.div`
  width: 0.125rem;
  height: 0.125rem;

  // on zoom sometimes it collapse into other shapes like square
  // if we don't have min characteristics
  min-width: 0.125rem;
  min-height: 0.125rem;
  border-radius: 50%;
  margin-top: 0.5625rem;
  margin-right: 0.5rem;
  background-color: ${({ theme }) => theme.palette.fonts.bodyDark};
`;

export const SpecialCharacter = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 1.125rem;
  height: 1.125rem;
  border-radius: 0.1875rem;
  background-color: ${({ theme }) => theme.palette.fills.iconHover};
`;
