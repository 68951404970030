// components
import { BlockWrapper, ContentWrapper } from 'modules/Account/styled';
import { OptionWrapper } from './styled';
import { StyledSubtitle } from 'typography/Text';
import { Invitation } from './Invitation';
import { InvitationDeclined } from './InvitationDeclined';
import { InvitationAccepted } from './InvitationAccepted';

// hooks
import { useSelector } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';

// selectors
import { selectAccountInvites, selectAccountInviteStatus } from 'redux/selectors';

export const InvitesBlock = () => {
  const { translate } = useTranslations('account');

  const invites = useSelector(selectAccountInvites);
  const inviteStatus = useSelector(selectAccountInviteStatus);

  if (invites.length === 0) return null;

  return (
    <BlockWrapper>
      <ContentWrapper justify="flex-start">
        <StyledSubtitle>
          {translate('organisation_invites', { invites: invites.length })}
        </StyledSubtitle>
      </ContentWrapper>
      <ContentWrapper>
        <OptionWrapper>
          {invites.map((invite, i) => {
            if (inviteStatus[invite.orgId] === 'accepted')
              return <InvitationAccepted key={invite.orgId} invite={invite} />;
            if (inviteStatus[invite.orgId] === 'declined')
              return <InvitationDeclined key={invite.orgId} invite={invite} />;
            return (
              <Invitation key={invite.orgId} invite={invite} noDivider={invites.length - 1 === i} />
            );
          })}
        </OptionWrapper>
      </ContentWrapper>
    </BlockWrapper>
  );
};
