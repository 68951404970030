// components
import { Suggestion } from './Suggestion';
import { Empty } from './Empty';

// hooks
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// actions
import { getTags, setTags } from 'redux/actions/tags';

// selectors
import { selectTagsItems } from 'redux/selectors';

// constants
import { initialTagsResponse } from 'redux/reducers/tags';

// types
import { ISearchKey } from 'types';
import { IRange, ISearchQueryDetail } from '../helpers';
import { IInputArrowConfig, ISelectedIndex } from '..';

// utils
import { debounce } from 'utils';

interface ITagSuggestions {
  keyName: ISearchKey['name'];
  canHaveMultiple: ISearchKey['canHaveMultiple'];
  existingValues: ISearchQueryDetail['values'];
  searchValue: string | null;
  addValue: (newValue: string) => void;
  setInputArrowNavigationConfig: (config: IInputArrowConfig) => void;
  selectedIndex: ISelectedIndex;
  range: IRange | null;
}

const SEARCH_FIELD = 'tag';

export const TagSuggestions = ({
  searchValue,
  keyName,
  addValue,
  canHaveMultiple,
  existingValues,
  setInputArrowNavigationConfig,
  selectedIndex,
  range,
}: ITagSuggestions) => {
  const dispatch = useDispatch();

  const tags = useSelector(selectTagsItems);

  // making sure not to show selected values in suggestions
  const filteredTags = useMemo(
    () =>
      canHaveMultiple
        ? tags.filter(tag => {
            if (existingValues.some(existingValue => existingValue.value === tag[SEARCH_FIELD])) {
              return false;
            }
            return true;
          })
        : tags,
    [canHaveMultiple, existingValues, tags]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchTags = useCallback(
    debounce((newSearchValue: string) => {
      dispatch(
        getTags({
          search: newSearchValue,
          syncQueryParams: false,
        })
      );
    }, 250),
    []
  );

  useEffect(() => {
    searchValue !== null && searchTags(searchValue);
  }, [dispatch, searchTags, searchValue]);

  useEffect(() => {
    return () => {
      dispatch(setTags(initialTagsResponse));
    };
  }, [dispatch]);

  // for navigation input with UP and DOWN arrows
  useEffect(() => {
    setInputArrowNavigationConfig({
      length: filteredTags.length,
      onEnter: () =>
        selectedIndex !== null &&
        filteredTags?.[selectedIndex] &&
        addValue(filteredTags[selectedIndex][SEARCH_FIELD]),
      isKeyInactive: false,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIndex, setInputArrowNavigationConfig, range, filteredTags.length]);

  return (
    <>
      {filteredTags.length ? (
        <>
          {filteredTags.map((tag, index) => {
            return (
              <Suggestion
                isSelected={index === selectedIndex}
                key={tag.tag}
                keyName={keyName}
                value={tag[SEARCH_FIELD]}
                onClick={() => addValue(tag[SEARCH_FIELD])}
              />
            );
          })}
        </>
      ) : (
        <Empty searchKeyName={keyName} />
      )}
    </>
  );
};
