// components
import { CustomButton } from 'components';
import { BlockWrapper, ContentWrapper, CustomTextStyled } from 'modules/Account/styled';
import { StyledSubtitle } from 'typography/Text';

// hooks
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import { useTranslations } from 'hooks/useTranslations';

// actions
import { setModalContent } from 'redux/actions/app';

// selectors
import { selectAccountSignInMethodIsLocal } from 'redux/selectors';

export const PasswordBlock = () => {
  const dispatch = useDispatch();

  const { translate } = useTranslations('account');

  const {
    palette: { fonts },
  } = useTheme();

  const signInMethodIsLocal = useSelector(selectAccountSignInMethodIsLocal);

  if (!signInMethodIsLocal) {
    return null;
  }

  const openPassChangeModal = () =>
    dispatch(setModalContent({ type: 'password-change', data: null }));

  return (
    <BlockWrapper>
      <ContentWrapper justify="flex-start">
        <StyledSubtitle>{translate('password')}</StyledSubtitle>
      </ContentWrapper>
      <ContentWrapper>
        <CustomTextStyled type="body-2" color={fonts.bodyLight}>
          {translate('password')}
        </CustomTextStyled>
        <CustomTextStyled type="body-2" color={fonts.bodyLight}>
          ********************
        </CustomTextStyled>
        <CustomButton onClick={openPassChangeModal} kind="primary" size="large">
          {translate('change_password')}
        </CustomButton>
      </ContentWrapper>
    </BlockWrapper>
  );
};
