import { ColumnsType } from 'antd/lib/table/Table';
import { translations } from 'types';
import { TFunction } from 'react-i18next/.';
import { Title } from './Title';
import { FieldText } from './FieldText';

interface ICols {
  translate: TFunction<translations>;
}

export const columnsView = ({
  translate,
}: ICols): ColumnsType<{
  range?: string;
  description?: string | null;
}> => [
  {
    title: <Title>{translate('details.ip_address')}</Title>,
    dataIndex: 'range',
    width: '55%',
    render: (range: string) => <FieldText>{range}</FieldText>,
    ellipsis: true,
  },
  {
    title: <Title>{translate('details.description')}</Title>,
    dataIndex: 'description',
    width: '45%',
    render: (description: string | null) => <FieldText>{description ? description : ''}</FieldText>,
    ellipsis: true,
  },
];
