import { createReducer } from '@reduxjs/toolkit';
import { IDnsStore } from 'types/dns';
import { randomHash } from 'utils';
import {
  setDnsRecords,
  setDnsRecordsShouldUpdate,
  setDnsZones,
  setDnsZonesShouldUpdate,
  setRecordsIsLoading,
  setZonesIsLoading,
  setDnsSelectedZone,
  addDnsZonesDataPage,
  setDnsZonesDataPages,
  setRecordSearchKeys,
} from '../actions/dns';

export const initialDnsZonesData: IDnsStore['zones']['data'] = {
  items: [],
  metadata: {
    total: 0,
    firstPage: 0,
    prevPage: null,
    lastPage: 0,
    nextPage: null,
  },
  links: {
    first: '',
    prev: null,
    next: null,
    last: '',
  },
};

const initialDnsZones: IDnsStore['zones'] = {
  selectedZone: null,
  data: initialDnsZonesData,
  dataPagesItems: {},
  updateId: randomHash(),
  isLoading: false,
};

export const initialDnsRecordsData = {
  items: [],
  metadata: {
    total: 0,
    firstPage: 0,
    prevPage: null,
    lastPage: 0,
    nextPage: null,
  },
  links: {
    first: '',
    prev: null,
    next: null,
    last: '',
  },
};

export const initialDnsRecords: IDnsStore['records'] = {
  updateId: randomHash(),
  isLoading: false,
  data: initialDnsRecordsData,
  searchKeys: null,
};

const initialState: IDnsStore = {
  zones: initialDnsZones,
  records: initialDnsRecords,
};

export const dnsReducer = createReducer(initialState, builder => {
  builder
    .addCase(setZonesIsLoading, (state, { payload: isLoading }) => {
      state.zones.isLoading = isLoading;
    })
    .addCase(setDnsZones, (state, { payload }) => {
      state.zones.data = payload;
      state.zones.isLoading = false;
    })
    .addCase(addDnsZonesDataPage, (state, { payload: { data, page } }) => {
      state.zones.dataPagesItems[page] = data;
    })
    .addCase(setDnsZonesDataPages, (state, { payload }) => {
      state.zones.dataPagesItems = payload;
    })
    .addCase(setDnsZonesShouldUpdate, state => {
      state.zones.updateId = randomHash();
    })
    .addCase(setDnsSelectedZone, (state, { payload }) => {
      state.zones.selectedZone = payload;
    })
    .addCase(setDnsRecords, (state, { payload }) => {
      state.records.data = payload;
      state.records.isLoading = false;
    })
    .addCase(setDnsRecordsShouldUpdate, state => {
      state.records.updateId = randomHash();
    })
    .addCase(setRecordsIsLoading, (state, { payload: isLoading }) => {
      state.records.isLoading = isLoading;
    })
    .addCase(setRecordSearchKeys, (state, { payload }) => {
      state.records.searchKeys = payload;
    });
});
