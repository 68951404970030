// components
import { UserAuthentication } from './UserAuthentication';
import { PublicIp } from './PublicIp';

// hooks
import { useSelector } from 'react-redux';

// selectors
import { selectAppDetailsTrustRequirementCurrentType } from 'redux/selectors';

export const Conditions = () => {
  const type = useSelector(selectAppDetailsTrustRequirementCurrentType);

  if (type === 'UserAuthentication') return <UserAuthentication />;
  if (type === 'PublicIp') return <PublicIp />;

  return null;
};
