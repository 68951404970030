// components
import { Details } from 'components';

// hooks
import { useSelector } from 'react-redux';

// selectors
import { selectAppDetailsDnsAutoGenerated, selectAppDetailsTabType } from 'redux/selectors';

export const TopMenuButtons = () => {
  const type = useSelector(selectAppDetailsTabType);
  const isAutoGenerated = useSelector(selectAppDetailsDnsAutoGenerated) || false;

  return <Details.TopMenuButtons showEditLink={type === 'dns-record-view' && !isAutoGenerated} />;
};
