// libs
import { SyntheticEvent, useCallback, memo, useMemo, useEffect } from 'react';

// hooks
import { useDispatch, useSelector } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';

// components
import { CustomTable, TableSelectionContainer } from 'components';
import { SystemsToApproveCols } from '../Columns';

// utils
import { emptyUnapprovedSystemsArray } from '../helpers';
import { equals } from 'ramda';

// actions
import { redirectControl } from 'redux/actions/app';

// selectors
import { selectUnapprovedSystems } from 'redux/selectors';

// types
import { IUnapprovedSystem } from 'types';
import { TableProps } from 'antd/lib/table';

// constants
import { ITEMS_PER_TABLE_PAGE } from 'appConstants/ui';

interface ITable {
  total: number;
  page: number;
  setPage: (page: number) => void;
  isLoading: boolean;
  bulkSelectedUSystems: IUnapprovedSystem[];
  setBulkSelectedUSystems: (bulkSelectedUSystems: IUnapprovedSystem[]) => void;
  selectedSystemId?: string;
  emptyText?: React.ReactNode;
}

export const Table = memo(
  ({
    page,
    setPage,
    total,
    isLoading,
    bulkSelectedUSystems,
    setBulkSelectedUSystems,
    selectedSystemId,
    emptyText,
  }: ITable) => {
    const dispatch = useDispatch();

    const { translate } = useTranslations('systems');
    const { translate: translateGlobal } = useTranslations('global');

    const systems = useSelector(selectUnapprovedSystems);

    const onRowClick = useCallback(
      (system: IUnapprovedSystem) => {
        {
          if (!isLoading) {
            dispatch(
              redirectControl({ route: `/unapproved-systems/${system.systemId}`, routePush: true })
            );
          }
        }
      },
      [dispatch, isLoading]
    );

    const columns = SystemsToApproveCols({
      isLoading,
      translate,
      bulkSelectedSystems: bulkSelectedUSystems,
      selectedSystemId,
    });

    const rowSelectionRender = useCallback(
      (
        _checked: boolean,
        _record: IUnapprovedSystem,
        _index: number,
        originNode: React.ReactNode
      ) => (
        <TableSelectionContainer
          data-test="unapproved-systems-table-selection"
          onClick={(event: SyntheticEvent) => event.stopPropagation()}
        >
          {originNode}
        </TableSelectionContainer>
      ),
      []
    );

    const rowSelection: TableProps<IUnapprovedSystem>['rowSelection'] = useMemo(
      () => ({
        checkStrictly: true,
        onChange: (_, selectedSystems: IUnapprovedSystem[]) => {
          if (!isLoading) {
            setBulkSelectedUSystems(selectedSystems);
          }
        },
        selectedRowKeys: bulkSelectedUSystems.map(e => e.systemId),
        renderCell: rowSelectionRender,
        //@ts-ignore
        getCheckboxProps: () => ({
          'aria-label': translateGlobal('aria_labels.select_row'),
        }),
      }),
      [
        bulkSelectedUSystems,
        isLoading,
        rowSelectionRender,
        setBulkSelectedUSystems,
        translateGlobal,
      ]
    );

    // when we bulk select element and then remove it through action dropdown
    // bulkSelectedSystems doesn't update without this useEffect
    useEffect(() => {
      const newBulkSelectedSystems = bulkSelectedUSystems.filter(bulkSelectedUSystem =>
        systems.some(system => system.systemId === bulkSelectedUSystem.systemId)
      );
      if (!equals(newBulkSelectedSystems, bulkSelectedUSystems)) {
        setBulkSelectedUSystems(newBulkSelectedSystems);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [systems]);

    return (
      <CustomTable
        data-test="unapproved-systems-table"
        locale={{ emptyText }}
        headerHeight="2.5rem"
        rowHeight="3.375rem"
        rowKey="systemId"
        columns={columns}
        dataSource={isLoading && !systems.length ? emptyUnapprovedSystemsArray : systems}
        onRowClick={onRowClick}
        elevated={true}
        selectedRowKey={selectedSystemId}
        rowSelection={rowSelection}
        pagination={{
          showSizeChanger: false,
          hideOnSinglePage: true,
          pageSize: ITEMS_PER_TABLE_PAGE,
          position: ['bottomCenter'],
          total: total,
          defaultCurrent: page + 1,
          onChange: page => {
            setPage(page - 1);
          },
        }}
      />
    );
  },
  equals
);
