// components
import { TagSelect } from 'components';

// hooks
import { useDispatch, useSelector } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';

// selectors
import { selectAppDetailsEnrolmentKeyTags } from 'redux/selectors';

// actions
import { updateEnrolmentKeyDetails } from 'redux/actions/app';
import { TagReference } from 'types';

export const AutoTagCreate = () => {
  const dispatch = useDispatch();

  const keyTags = useSelector(selectAppDetailsEnrolmentKeyTags);

  const { translate } = useTranslations('enrolment-keys');

  const handleTagUpdate = (selectedTags: TagReference[]) => {
    dispatch(
      updateEnrolmentKeyDetails({
        tags: selectedTags,
      })
    );
  };

  return (
    <TagSelect
      preSelected={keyTags}
      changeCallback={handleTagUpdate}
      placeholder={translate('details.auto_tag_placeholder_text')}
    />
  );
};
