// libs
import styled, { css, useTheme } from 'styled-components';

// components
import { TabButtons } from 'components';
import { ContentContainer } from './styled';
import { CustomText } from 'typography/Text';
import { EmptyTags } from './View/Empty/Tags';
import { EmptySystems } from './View/Empty';

// hooks
import { useTranslations } from 'hooks/useTranslations';

// types
import { ITabsNumber } from 'components/TabButtons/NumberTabPane';

export const TabsWrapper = styled.div`
  border-bottom: 0.0625rem solid ${({ theme }) => theme.palette.fills.strokeMedium};
  margin-bottom: 1rem;
`;

export type ITargetsTabs = 'tags' | 'systems';

interface IContent {
  edit: boolean;
  isAutoGenerated: boolean;
  tagsAmount: number;
  tagsContent: React.ReactNode;
  systemsAmount: number;
  systemsContent: React.ReactNode;
  selectedTab: ITargetsTabs;
  setSelectedTab: (tab: ITargetsTabs) => void;
  withEmptyState: boolean;
}

export const Content = ({
  edit,
  isAutoGenerated,
  tagsContent,
  tagsAmount,
  systemsContent,
  systemsAmount,
  selectedTab,
  setSelectedTab,
  withEmptyState,
  ...rest
}: IContent) => {
  const {
    palette: { fonts },
  } = useTheme();

  const { translate } = useTranslations('dns');

  const tabs: ITabsNumber[] = [
    {
      type: 'number',
      key: 'tags',
      dataFormat: 'show',
      title: translate('records.details.tags'),
      data: tagsAmount,
      titleCss: css`
        width: 2rem;
      `,
    },
    {
      type: 'number',
      key: 'systems',
      dataFormat: 'show',
      data: systemsAmount,
      title: translate('records.details.systems'),
      titleCss: css`
        width: 3.75rem;
      `,
    },
  ];

  return (
    <ContentContainer {...rest}>
      {isAutoGenerated ? (
        systemsContent
      ) : (
        <>
          <TabsWrapper>
            <TabButtons
              tabs={tabs}
              selectedKey={selectedTab}
              onChange={newKey => setSelectedTab(newKey as ITargetsTabs)}
              tabCss={css`
                :not(:first-child) {
                  margin-left: 1rem;
                }
              `}
            />
          </TabsWrapper>
          {selectedTab === 'tags' ? (
            <>
              {withEmptyState && tagsAmount === 0 ? (
                <EmptyTags />
              ) : (
                <>
                  <CustomText
                    css={css`
                      white-space: pre;
                      display: block;
                      margin-bottom: 1rem;
                    `}
                    type="body-2"
                    color={fonts.bodyLight}
                  >
                    {translate('records.details.tags_description')}
                  </CustomText>
                  {tagsContent}
                </>
              )}
            </>
          ) : (
            <>
              {withEmptyState && systemsAmount === 0 ? (
                <EmptySystems />
              ) : (
                <>
                  {edit && (
                    <CustomText
                      css={css`
                        white-space: pre;
                        display: block;
                      `}
                      type="body-2"
                      color={fonts.bodyLight}
                    >
                      {translate('records.details.systems_description')}
                    </CustomText>
                  )}
                  {systemsContent}
                </>
              )}
            </>
          )}
        </>
      )}
    </ContentContainer>
  );
};
