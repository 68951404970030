// libs
import { css, useTheme } from 'styled-components';

// components
import { CustomText } from 'typography/Text';
import { InputNumber, Radio } from 'components';
import { RadioChangeEvent } from 'antd/lib/radio';
import { RadioManualContent, UsesRemainingEditContainer } from './styled';

// hooks
import { useState } from 'react';
import { useTranslations } from 'hooks/useTranslations';
import { useDispatch, useSelector } from 'react-redux';

// actions
import { updateEnrolmentKeyDetails } from 'redux/actions/app';

// selectors
import { selectAppDetailsEnrolmentKeyNewUsesRemaining } from 'redux/selectors';

// types
import { IEnrolmentKeyUsesRemaining } from 'types';

// constants
import {
  MAX_ENROLMENT_KEYS_USES_REMAINING,
  REGEX_NON_DIGIT,
  UNLIMITED_USES_AMOUNT,
} from 'appConstants';

const RadioValues = {
  unlimited: 'unlimited',
  manual: 'manual',
};
export const UseLimitEdit = () => {
  const dispatch = useDispatch();

  const { translate } = useTranslations('enrolment-keys');

  const {
    palette: { fonts },
  } = useTheme();

  const newUsesRemaining = useSelector(selectAppDetailsEnrolmentKeyNewUsesRemaining);

  const isUnlimited = newUsesRemaining === UNLIMITED_USES_AMOUNT;

  const [lastValue, setLastValue] = useState(!isUnlimited ? newUsesRemaining : undefined);
  const [isWithPlaceholder, setIsWithPlaceholder] = useState(false);

  const handleUsesRemainingChange = (newUsesRemaining: IEnrolmentKeyUsesRemaining) => {
    dispatch(updateEnrolmentKeyDetails({ usesRemaining: newUsesRemaining }));
  };

  const onUsesRemainingManualChangeHandler = (value: string) => {
    const parsedValue = value.includes('-') ? '0' : value.replace(REGEX_NON_DIGIT, '');
    const resultValue = Math.min(Number(parsedValue), MAX_ENROLMENT_KEYS_USES_REMAINING);

    // if value.length !== parsedValue.length it means that value is not entirely number
    // in which case placeholder can't be shown otherwise it would allow of entering
    // symbols it shoudn't allow, like "-"

    if (value === '' && value.length === parsedValue.length) {
      !isWithPlaceholder && setIsWithPlaceholder(true);
    } else {
      isWithPlaceholder && setIsWithPlaceholder(false);
    }

    setLastValue(resultValue);
    handleUsesRemainingChange(resultValue);
  };

  const onRadioGroupChange = (event: RadioChangeEvent) => {
    if (event.target.value === RadioValues.unlimited) {
      handleUsesRemainingChange(-1);
      setIsWithPlaceholder(false);
    } else if (true) {
      handleUsesRemainingChange(lastValue === -1 || !lastValue ? 1 : lastValue);
    }
  };

  return (
    <UsesRemainingEditContainer>
      <Radio.Group
        value={isUnlimited ? RadioValues.unlimited : RadioValues.manual}
        onChange={onRadioGroupChange}
        css={css`
          display: flex;
          flex-direction: column;
          .ant-radio span,
          .ant-radio + * {
            padding-right: 0;
          }
        `}
        name="uses remaining"
      >
        <Radio value={RadioValues.unlimited}>
          <CustomText type="body-2" color={fonts.bodyDark}>
            {translate('details.unlimited')}
          </CustomText>
        </Radio>
        <Radio
          css={css`
            margin-top: 0.5rem;
          `}
          value={RadioValues.manual}
          data-test="enrolment-key-details-use-limit-manual"
        >
          <RadioManualContent>
            <CustomText
              css={css`
                margin-right: 1.1875rem;
              `}
              type="body-2"
              color={fonts.bodyDark}
            >
              {translate('details.limit_text')}
            </CustomText>
            <InputNumber
              disabled={isUnlimited}
              data-test="enrolment-key-details-use-limit-manual-input"
              type="text"
              placeholder={
                isWithPlaceholder ? translate('details.uses_remaining_placeholder') : undefined
              }
              size="middle"
              value={
                lastValue === null || lastValue === undefined || isWithPlaceholder || isUnlimited
                  ? undefined
                  : lastValue
              }
              stateMessageCss={css`
                display: none;
              `}
              labelCss={css`
                display: none;
              `}
              setValue={onUsesRemainingManualChangeHandler}
              width="9.125rem"
            />
          </RadioManualContent>
        </Radio>
      </Radio.Group>
    </UsesRemainingEditContainer>
  );
};
