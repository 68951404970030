// components
import { CustomText } from 'typography/Text';
import {
  StyledGroup,
  StyledButton,
  StyledOptionWrapper,
  StyledControllableIcon,
  StyledOptionDescription,
  StyledIconQuestionCircle,
  tooltipCss,
} from './styled';
import { Tooltip } from 'components';
import { IconEnrolmentKey } from 'assets/svg';

// hooks
import { useTheme } from 'styled-components';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';

// actions
import { updateEnrolmentKeyDetails } from 'redux/actions/app';

// selectors
import { selectAppDetailsEnrolmentKeyCurrentType } from 'redux/selectors';

// types
import { RadioChangeEvent } from 'antd/lib/radio';
import { IEnrolmentKey } from 'types';

// constants
import { DEFAULT_RETENTION_MINUTES } from 'appConstants';

const values: Record<IEnrolmentKey['type'], IEnrolmentKey['type']> = {
  GeneralPurpose: 'GeneralPurpose',
  Ephemeral: 'Ephemeral',
};

export const PurposeCreate = () => {
  const dispatch = useDispatch();

  const { translate } = useTranslations('enrolment-keys');

  const {
    palette: {
      fonts: { bodyLight },
    },
  } = useTheme();

  const purpose = useSelector(selectAppDetailsEnrolmentKeyCurrentType);

  const handlePurposeChange = useCallback(
    (event: RadioChangeEvent) => {
      if (event.target.value === values.Ephemeral) {
        dispatch(
          updateEnrolmentKeyDetails({
            type: event.target.value,
            disconnectedRetentionMinutes: DEFAULT_RETENTION_MINUTES,
          })
        );
      } else {
        dispatch(
          updateEnrolmentKeyDetails({
            type: event.target.value,
          })
        );
      }
    },
    [dispatch]
  );

  return (
    <StyledGroup defaultValue={purpose} onChange={handlePurposeChange}>
      <StyledButton value={values.GeneralPurpose}>
        <StyledOptionWrapper data-test="enrolment-key-create-details-select-general-purpose">
          <StyledControllableIcon
            state={purpose === 'GeneralPurpose' ? 'active' : 'default'}
            icon={<IconEnrolmentKey />}
            data-test={purpose === 'GeneralPurpose' ? 'selected' : 'unselected'}
          />
          <StyledOptionDescription>
            <CustomText type="sub-heading-2">
              {translate('details.ephemeral_switch.general_purpose')}
            </CustomText>
            <CustomText type="hint" color={bodyLight}>
              {translate('details.ephemeral_switch.general_purpose_hint')}
            </CustomText>
          </StyledOptionDescription>
          <Tooltip
            width="20rem"
            placement="bottomRight"
            trigger="hover"
            passedCss={tooltipCss}
            overlay={
              <CustomText type="body-2">
                {translate('details.ephemeral_switch.general_purpose_tooltip')}
              </CustomText>
            }
          >
            <StyledIconQuestionCircle />
          </Tooltip>
        </StyledOptionWrapper>
      </StyledButton>
      <StyledButton value={values.Ephemeral}>
        <StyledOptionWrapper data-test="enrolment-key-create-details-select-ephemeral-purpose">
          <StyledControllableIcon
            ephemeral
            state={purpose === 'Ephemeral' ? 'active' : 'default'}
            icon={<IconEnrolmentKey />}
            data-test={purpose === 'Ephemeral' ? 'selected' : 'unselected'}
          />
          <StyledOptionDescription>
            <CustomText type="sub-heading-2">
              {translate('details.ephemeral_switch.ephemeral_purpose')}
            </CustomText>
            <CustomText type="hint" color={bodyLight}>
              {translate('details.ephemeral_switch.ephemeral_purpose_hint')}
            </CustomText>
          </StyledOptionDescription>

          <Tooltip
            width="20rem"
            placement="bottomRight"
            trigger="hover"
            passedCss={tooltipCss}
            overlay={
              <CustomText type="body-2">
                {translate('details.ephemeral_switch.ephemeral_purpose_tooltip')}
              </CustomText>
            }
          >
            <StyledIconQuestionCircle />
          </Tooltip>
        </StyledOptionWrapper>
      </StyledButton>
    </StyledGroup>
  );
};
