import styled, { css, CSSProp } from 'styled-components';
import { CustomText } from 'typography/Text';

type HeaderContainerProps = {
  active: boolean;
};

type CardContainerProps = {
  active: boolean;
};

export type IconProps = {
  $enabled?: boolean;
  $enabledCss?: CSSProp;
  $disabledCss?: CSSProp;
};

export const CardWrapper = styled.div<{ enabled: boolean }>`
  position: relative;
  display: flex;

  ${({ enabled, theme }) =>
    !enabled &&
    css`
      ${ContentContainer} {
        span {
          color: ${theme.palette.fonts.disabled} !important;
        }
      }
      li svg path {
        fill: ${theme.palette.icons.disabled} !important;
      }
    `}

  width: calc(100% / 3);
  max-width: 16.875rem;

  flex-direction: column;

  :not(:last-child) {
    margin-right: 1rem;
  }
`;

export const CardContainer = styled('div')<CardContainerProps>`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palette.background.base};
  border: 0.0625rem solid ${({ theme }) => theme.palette.fills.strokeMedium};
  border-top-left-radius: ${({ active }) => (active ? '0' : '0.25rem')};
  border-top-right-radius: ${({ active }) => (active ? '0' : '0.25rem')};
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  flex-grow: 1;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  padding: 1rem;
`;

export const HeaderContainer = styled('div')<HeaderContainerProps>`
  height: 2rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ active, theme }) =>
    active ? theme.palette.fills.primaryPressed : theme.palette.background.panel};
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
`;

export const QuantitiesSelectContainer = styled.div`
  width: 100%;

  height: 2.5rem;
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  justify-content: center;
`;

export const PricingContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
  font-size: 2rem;

  sub {
    font-weight: 400;
    bottom: 0rem;
    font-size: 0.6em;
  }
`;

export const StyledIcon = styled.svg<IconProps>`
  ${({ $enabled, $enabledCss, $disabledCss }) => ($enabled ? $enabledCss : $disabledCss)};
`;

export const BillingMessageStyle = css`
  grid-column: span 2;
  margin-bottom: 2rem;
`;

export const UpdatePlanDescription = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palette.background.base};
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  flex-wrap: wrap;
`;

export const UpdatePlanItem = styled.div`
  display: flex;
  align-items: center;
  div {
    display: flex;
    align-items: center;
  }
  svg {
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
  }
`;

export const StyledModalSubHeader = styled(CustomText)`
  display: block;
  margin-bottom: 1.5rem;
`;

export const StyledKeyText = styled(CustomText)`
  width: 25%;
  margin-right: 5%;
  color: ${({ theme }) => theme.palette.fonts.bodyLight};
`;

export const List = styled.ul`
  list-style: none;
  margin-left: 0;
  padding-left: 0;

  svg {
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
    margin-top: 0.0625rem;
    flex-shrink: 0;

    path {
      fill: ${({ theme }) => theme.palette.icons.primary};
    }
  }

  li {
    display: flex;
    flex-direction: row;
  }
`;

export const DescriptionText = styled(CustomText)`
  margin-bottom: 1rem;
  display: block;
  min-height: 5rem;
`;

export const IsYearlyToggleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  margin-bottom: 1rem;
`;

export const StaticContentContainer = styled.div`
  flex: 1;
`;

export const ModifyContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;

  & > * {
    align-self: center;
  }
`;

export const CurrentPlanContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palette.background.base};
  border: 0.0625rem solid ${({ theme }) => theme.palette.fills.strokeMedium};
  border-radius: 0.25rem;
  padding: 1.5rem;
  margin-bottom: 3.375rem;
`;

export const CurrentPlanContent = styled.div`
  display: flex;
  flex-direction: row;
`;

export const CurrentPlanContentSection = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% / 3);
  padding-right: 1rem;

  :not(:first-child) {
    padding-left: 1rem;
    border-left: 0.0625rem solid ${({ theme }) => theme.palette.fills.strokeMedium};
  }

  :not(:last-child) {
    padding-left: 1rem;
  }
`;

export const CurrentPlanContentRow = styled.div`
  display: flex;
  flex-direction: row;

  :not(:last-child) {
    margin-bottom: 1.5rem;
  }
`;

export const CurrentPlanContentRowName = styled.div`
  min-width: 7.25rem;
  flex-grow: 1;
`;

export const CurrentPlanContentRowDescription = styled.div`
  flex: 1;
`;
