// Libs
import { css } from 'styled-components';

// Components
import { Details } from 'components';
import { OverviewSection } from './styled';
import { IconEnrolmentKeysDetailsOverviewSection } from 'assets/svg';
import { StatusBlock, LastUsedBlock, AutoTagBlock, NotesBlock } from '../../Blocks';

// hooks
import { useTranslations } from 'hooks/useTranslations';

export const Overview = () => {
  const { translate } = useTranslations('enrolment-keys');

  return (
    <OverviewSection>
      <Details.SectionHeader
        Icon={IconEnrolmentKeysDetailsOverviewSection}
        title={translate('details.overview')}
        css={css`
          margin-bottom: 1.8125rem;
        `}
      />

      <StatusBlock />
      <LastUsedBlock />
      <AutoTagBlock />
      <NotesBlock />
    </OverviewSection>
  );
};
