// components
import { Container } from './styled';
import { SafeZone } from './SafeZone';
import { DangerZone } from './DangerZone';

// hooks
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { closeDetails } from 'redux/actions/app';

export const ZoneProperties = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(closeDetails({}));
  }, [dispatch]);

  return (
    <Container>
      <SafeZone />
      <DangerZone />
    </Container>
  );
};
