import styled, { css } from 'styled-components';
import { stylesMap } from 'typography/Text';

type Props = {
  width?: string;
  height?: string;
  shownSelectClassSelector?: string;
  error: boolean;
  isMultiple: boolean;
  allowOptionWrap?: boolean;
};

export const SelectContainer = styled.div<{ fullWidth: boolean }>`
  position: relative;
  ${props => (props.fullWidth ? 'width: 100%;' : '')}
  .ant-select-dropdown {
    padding: 0;
    border: 0.0625rem solid ${({ theme }) => theme.palette.background.iconBackground};
    border-radius: 0.25rem;
    box-shadow: ${({ theme }) => theme.palette.elevationShadows[200]};

    .ant-select-item {
      border-radius: 0.25rem;
      padding: 0 0.75rem;

      .ant-select-item-option-content {
        padding: 0.5625rem 0;
      }
    }
  }
`;

export const selectCss = ({
  width,
  height,
  shownSelectClassSelector,
  error,
  isMultiple,
  allowOptionWrap,
}: Props) => css`
  border: none;
  box-sizing: border-box;

  width: ${width || '100%'};
  height: ${isMultiple ? 'auto' : height || '2.5rem'} !important;
  border-radius: 0.25rem !important;

  ${isMultiple &&
  css`
    min-height: 2.5rem;

    .ant-select-selection-search {
      flex: 1;
      // space for icon
      margin-right: 1.5rem;
    }

    .ant-select-selection-search:first-child > .ant-select-selection-search-input {
      margin-left: 0 !important;
    }

    .ant-select-item-option:active {
      background-color: ${({ theme }) => theme.palette.background.focus};
    }
  `}

  .select-icon-down {
    transition: transform 0.3s ease 0s;
  }

  &.ant-select-open .select-icon-down {
    transform: rotate(-180deg);
  }

  * {
    cursor: pointer !important;
  }

  .ant-select-selection-item div *:not(${shownSelectClassSelector}) {
    display: none;
  }
  ${isMultiple &&
  css`
    .ant-select-selection-item {
      border-radius: 0.25rem;
      background: none;
      border-color: transparent;

      :hover {
        background-color: ${({ theme }) => theme.palette.background.sections};
      }
    }

    .ant-select-item-option-selected {
      background: none;
    }

    .ant-select-item-option-active {
      background-color: ${({ theme }) => theme.palette.background.base};
    }

    .ant-select-item-option-state {
      display: flex;
      align-items: center;
    }
  `}

  ${allowOptionWrap &&
  css`
    .ant-select-item-option-content {
      overflow: auto;
      text-overflow: clip;
      white-space: normal;
    }
  `}

  .ant-select-selector {
    display: flex;
    align-items: center;

    min-height: ${isMultiple && 'inherit'};

    height: 100% !important;
    width: 100% !important;
    box-shadow: ${({ theme }) => theme.palette.elevationShadows[100]} !important;
    border-radius: 0.25rem !important;

    border: 0.075rem solid
      ${({ theme }) =>
        error ? theme.palette.state.error : theme.palette.background.iconBackground} !important;

    padding: 0 0.75rem !important;

    .ant-select-selection-search {
      display: flex;
      align-items: center;
    }
    .ant-select-selection-item {
      display: flex;
      align-items: center;
    }
  }
  .ant-select-arrow {
    display: flex;
    align-items: center;
    justify-content: center;

    pointer-events: none;
    width: auto;
  }
  .ant-select-selection-placeholder {
    ${stylesMap['body-2']}
    color: ${({ theme }) => theme.palette.fonts.disabled};
  }

  :hover {
    .ant-select-selector {
      ${!error &&
      css`
        border-color: ${({ theme }) => !error && theme.palette.fills.stroke} !important;
      `}
    }
  }

  :focus-within {
    .ant-select-selector {
      ${!error &&
      css`
        border-color: ${({ theme }) => !error && theme.palette.fills.primary} !important;
      `}
    }
  }

  input {
    ${stylesMap['body-2']}
    color: ${({ theme }) => theme.palette.fonts.title};
  }
`;

export const IconsContainer = styled.div`
  display: flex;
  align-items: center;

  & > * {
    :not(:last-child) {
      margin-right: 0.25rem;
    }
  }
`;

export const DropdownContainer = styled.div<{
  shownDropdownClassSelector?: string;
  showSelectedOptionInDropdown?: boolean;
  optionOverflow?: string;
}>`
  .rc-virtual-list-holder {
    padding: 0.5rem 0.25rem;
  }

  width: 100%;
  max-height: 33.5rem;
  .rc-virtual-list-scrollbar-thumb {
    background-color: ${({ theme }) => theme.palette.background.iconBackground} !important;
  }

  .ant-select-item-option-content
    *:not(${({ shownDropdownClassSelector }) => shownDropdownClassSelector}) {
    display: none;
  }
  .ant-select-item-option-content {
    ${({ optionOverflow }) => (optionOverflow ? `overflow: ${optionOverflow};` : '')};
  }
  .ant-select-item-option-active {
    background-color: ${({ theme }) => theme.palette.background.base};
  }
  .ant-select-item-option-selected {
    ${({ theme, showSelectedOptionInDropdown }) =>
      showSelectedOptionInDropdown
        ? `
        background-color: ${theme.palette.background.focus};
        `
        : `
        display: none;
         `}
  }
`;
