// libs
import styled, { css } from 'styled-components';

// components
import { CustomText } from 'typography/Text';
import { ViewContent } from './ViewContent';
import { EditContent } from './EditContent';

// hooks
import { useTheme } from 'styled-components';
import { useSelector } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';

// selectors
import { selectAppDetailsTabType } from 'redux/selectors';

const Container = styled.div`
  margin-left: 2rem;
`;

export const LocationsBlock = () => {
  const { translate } = useTranslations('trust-requirements');

  const {
    palette: { fonts },
  } = useTheme();

  const type = useSelector(selectAppDetailsTabType);

  return (
    <Container>
      <CustomText
        css={css`
          display: block;
          margin-bottom: 0.5rem;
        `}
        type="body-2"
        color={fonts.bodyLight}
      >
        {translate('details.types.PublicIp.locations')}
      </CustomText>
      <CustomText
        type="body-2"
        color={fonts.title}
        css={css`
          display: block;
          margin-bottom: 1.25rem;
        `}
      >
        {translate(
          `details.types.PublicIp.conditions_locations_description_${
            type === 'trust-requirement-view' ? 'view' : 'edit'
          }`
        )}
      </CustomText>
      {type === 'trust-requirement-view' && <ViewContent />}
      {(type === 'trust-requirement-edit' || type === 'trust-requirement-create') && (
        <EditContent />
      )}
    </Container>
  );
};
