// libs
import React from 'react';

// components
import { CustomText } from 'typography/Text';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { css, useTheme } from 'styled-components';
import { useSelector } from 'react-redux';

// selectors
import { selectAppDetailsTabType } from 'redux/selectors';

const Description = () => {
  const { translate } = useTranslations('policies');

  const type = useSelector(selectAppDetailsTabType);

  const {
    palette: { fonts },
  } = useTheme();

  return (
    <CustomText
      type="body-2"
      color={fonts.bodyLight}
      css={css`
        margin: 0 0 1rem 2rem;
      `}
    >
      {type === 'policy-view'
        ? translate('details.time_limitations.description_view')
        : translate('details.time_limitations.description_edit')}
    </CustomText>
  );
};

export default Description;
