// libs
import { call, put } from 'typed-redux-saga';

// actions
import { setModalIsLoading } from 'redux/actions/app';

// types
import { IActionPayload, IVerifyAccount2FACodeParams } from 'types';

// sagas
import { generateRecoveryCodesSaga } from './generateRecoveryCodesSaga';
import { validateStepUpTokenSaga } from './validateStepUpTokenSaga';
import { verify2FACodeSaga } from './verify2FACodeSaga';

export function* validateAuthCodeSaga({
  payload: { code },
}: IActionPayload<Omit<IVerifyAccount2FACodeParams, 'stepUpToken'>>) {
  yield put(setModalIsLoading(true));

  const token = yield* call(validateStepUpTokenSaga);

  if (token) {
    const success = yield* call(verify2FACodeSaga, {
      payload: { code },
    });

    if (success) yield call(generateRecoveryCodesSaga);
  }

  yield put(setModalIsLoading(false));
}
