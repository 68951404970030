// libs
import { put, all } from 'typed-redux-saga';

// actions
import { setDetailsContent } from 'redux/actions/app';

// utils
import { ApiTypeCaller } from '../../api/utils';

// types
import { IActionPayload, ILoadTrustRequirementDetails } from 'types';

// constants
import { TRUST_REQUIREMENT_INITIAL_VALIDATION_STATE } from 'appConstants';

// utils
import { getTrustRequirementValidationState, modifyTrustRequirementDetails } from 'utils';

export function* loadTrustRequirementDetailsSaga({
  payload: { requirementId, mode = 'view' },
}: IActionPayload<ILoadTrustRequirementDetails>) {
  const { result, success } = yield* ApiTypeCaller('getTrustRequirement', { requirementId });

  if (success && result) {
    const data = modifyTrustRequirementDetails(result);

    if (mode === 'edit') {
      yield all([
        put(
          setDetailsContent({
            type: 'trust-requirement-edit',
            data: data,
            dataNew: data,
            validationState: {
              ...TRUST_REQUIREMENT_INITIAL_VALIDATION_STATE,
              ...getTrustRequirementValidationState(data),
            },
            isLoading: false,
          })
        ),
      ]);
    } else if (mode === 'view') {
      yield all([
        put(
          setDetailsContent({
            type: 'trust-requirement-view',
            data: data,
            isLoading: false,
          })
        ),
      ]);
    }
  }
}
