// Components
import { CancelSaveEdit } from './Edit';
import { CancelSaveCreate } from './Create';

// Hooks
import { useSelector } from 'react-redux';

// selectors
import { selectAppDetailsTabType } from 'redux/selectors';

export const CancelSave = () => {
  const type = useSelector(selectAppDetailsTabType);

  return (
    <>
      {type === 'dns-record-edit' && <CancelSaveEdit />}
      {type === 'dns-record-create' && <CancelSaveCreate />}
    </>
  );
};
