// libs
import { css } from 'styled-components';

// components
import { Divider } from 'antd';
import { CustomText } from 'typography/Text';
import { OrganisationBadge } from 'components/OrganisationBadge';
import { CustomButton, Loader } from 'components';
import { InvitationDescription, InvitationRecord, ActionsWrapper } from './styled';

// hooks
import { useCallback, useState } from 'react';
import { useTranslations } from 'hooks/useTranslations';
import { useDispatch, useSelector } from 'react-redux';

// actions
import { acceptInvite, declineInvite } from 'redux/actions/account';

// selectors
import { selectAppIsLoading } from 'redux/selectors';

// types
import { IAccountInvite } from 'types';

export type InvitationProps = {
  invite: IAccountInvite;
  noDivider: boolean;
};

export const Invitation = ({ invite, noDivider }: InvitationProps) => {
  const dispatch = useDispatch();

  const { translate } = useTranslations('account');

  const isLoading = useSelector(selectAppIsLoading);

  const [accepting, setAccepting] = useState(false);
  const [declining, setDeclining] = useState(false);

  const isDisabled = isLoading && (accepting || declining);

  const handleAccept = useCallback(() => {
    setAccepting(true);
    dispatch(acceptInvite({ orgId: invite.orgId }));
  }, [dispatch, invite.orgId]);

  const handleDecline = useCallback(() => {
    setDeclining(true);
    dispatch(declineInvite({ orgId: invite.orgId }));
  }, [dispatch, invite.orgId]);

  return (
    <>
      <InvitationRecord noDivider={noDivider}>
        <InvitationDescription>
          <OrganisationBadge altName={invite.orgName} />
          <CustomText
            type="body-2"
            css={css`
              margin-right: 0.5rem;
            `}
          >
            {invite.orgName}
          </CustomText>
          <CustomText type="hint">
            {translate('organisation_invited_by', {
              name: `${invite.invitedByName}`,
            })}
          </CustomText>
        </InvitationDescription>
        <ActionsWrapper>
          <CustomButton
            width="5.9375rem"
            kind={isDisabled ? 'disabled' : 'secondary'}
            css={css`
              margin-right: 1rem;
            `}
            onClick={handleAccept}
          >
            {isLoading && accepting ? <Loader /> : translate('invite_accept')}
          </CustomButton>
          <CustomButton
            width="5.9375rem"
            kind={isDisabled ? 'disabled' : 'secondaryDanger'}
            onClick={handleDecline}
          >
            {isLoading && declining ? <Loader /> : translate('invite_decline')}
          </CustomButton>
        </ActionsWrapper>
      </InvitationRecord>
      {!noDivider && <Divider style={{ margin: '0 0 0.75rem 0' }} />}
    </>
  );
};
