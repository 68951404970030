import { createAction } from '@reduxjs/toolkit';

import {
  IGetOrganisationResponse,
  ISendUserInviteParams,
  ICancelUserInviteParams,
  IUpdateOrganisationParams,
  IGetOrganisationUsersResponse,
  IGetOrganisationInvitesResponse,
  IGetOrganisationPlansResponse,
  IRemoveOrganisationUserProps,
} from 'types';

export const setOrganisationInfo = createAction<IGetOrganisationResponse>(
  'organisation/SET_ORGANISATION'
);
export const getOrganisationUsers = createAction('organisation/GET_USERS');
export const getCurrentOrganisation = createAction('organisation/GET_INFO');
export const getOrganisationPlans = createAction('organisation/GET_PLANS');
export const getOrganisationInvites = createAction('organisation/GET_INVITES');
export const sendUserInvite = createAction<ISendUserInviteParams>('organisation/SEND_INVITE');
export const cancelUserInvite = createAction<ICancelUserInviteParams>('organisation/CANCEL_INVITE');
export const updateOrganisation = createAction<IUpdateOrganisationParams>('organisation/UPDATE');
export const setOrganisationUsers =
  createAction<IGetOrganisationUsersResponse['users']>('organisation/SET_USERS');
export const setOrganisationInvites = createAction<IGetOrganisationInvitesResponse['invites']>(
  'organisation/SET_INVITES'
);
export const setOrganisationPlans =
  createAction<IGetOrganisationPlansResponse>('organisation/SET_PLANS');
export const removeOrganisationUser = createAction<IRemoveOrganisationUserProps>(
  'organisation/REMOVE_USER'
);
