// components
import { PageTitle, TrialBanner } from 'components';
import { CustomText } from 'typography/Text';

import {
  AnnouncementSection,
  GettingStartedSection,
  RecentActivitySection,
  RecentlyEnrolledSection,
  SlackSection,
  SystemsEnrolledSection,
  SystemsNeedApprovalSection,
  TutorialsSection,
} from './Sections';
import {
  HeaderRow,
  GridContainer,
  LeftInternalGridContainer,
  RightInternalGridContainer,
  GridTopRow,
} from './styled';

// hooks
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import { useTranslations } from 'hooks/useTranslations';

// selectors
import { selectCurrentOrganisationInfo } from 'redux/selectors';

// actions
import { updateOrganisation } from 'redux/actions/organisation';

export const LandingPage = () => {
  const currentOrganizationInfo = useSelector(selectCurrentOrganisationInfo);
  const {
    palette: { fonts },
  } = useTheme();
  const { translate } = useTranslations('landing-page');
  const dispatch = useDispatch();

  useEffect(() => {
    // we are using info from organizations inside dashboard
    dispatch(updateOrganisation);
  }, [dispatch]);

  return (
    <>
      <PageTitle title={translate('dashboard')} />
      <HeaderRow>
        <CustomText type="heading-1" color={fonts.title}>
          {currentOrganizationInfo.name}
        </CustomText>
      </HeaderRow>
      <TrialBanner isDashboard={true} />
      <GridContainer>
        <GridTopRow>
          <SystemsNeedApprovalSection />
          <SystemsEnrolledSection />
          <SlackSection />
          <AnnouncementSection />
        </GridTopRow>
        <LeftInternalGridContainer>
          <RecentActivitySection />
          <RecentlyEnrolledSection />
        </LeftInternalGridContainer>
        <RightInternalGridContainer>
          <GettingStartedSection />
          <TutorialsSection />
        </RightInternalGridContainer>
      </GridContainer>
    </>
  );
};
