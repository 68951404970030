import { Tooltip } from 'components';
import { TooltipPlacement } from 'antd/lib/tooltip';
import { IconInfoButton } from 'assets/svg';
import styled, { css, CSSProp } from 'styled-components';
import { CustomText } from 'typography/Text';

const InfoIconContainer = styled.div`
  display: flex;
`;

interface IInfoIconButton {
  title: string;
  tooltipWidth?: string;
  tooltipBackgroundColor?: string;
  textColor?: string;
  textCss?: CSSProp;
  overlayCss?: CSSProp;
  containerCss?: CSSProp;
  iconContainerCss?: CSSProp;
  tooltipPlacement?: TooltipPlacement;
}

export const InfoIconButton = ({
  title,
  tooltipWidth,
  tooltipBackgroundColor,
  textColor,
  textCss,
  overlayCss,
  containerCss,
  iconContainerCss,
  tooltipPlacement = 'topLeft',
}: IInfoIconButton) => {
  return (
    <Tooltip
      placement={tooltipPlacement}
      trigger="click"
      width="20rem"
      overlay={
        <div
          css={css`
            ${overlayCss}
          `}
        >
          <CustomText
            css={css`
              ${textCss}
            `}
            type="body-2"
            color={textColor}
          >
            {title}
          </CustomText>
        </div>
      }
      overlayInnerStyle={{
        backgroundColor: tooltipBackgroundColor,
        width: tooltipWidth,
      }}
      overlayStyle={{
        whiteSpace: 'pre-wrap',
      }}
      passedCss={css`
        border-radius: 0.25rem;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 1.875rem;
        width: 1.875rem;

        cursor: pointer;

        :hover {
          background-color: ${({ theme }) => theme.palette.fills.iconHover};
          svg path {
            fill: ${({ theme }) => theme.palette.fills.primaryHover};
          }
        }

        &.ant-tooltip-open {
          background-color: ${({ theme }) => theme.palette.background.sections};
          border: 0.0625rem solid ${({ theme }) => theme.palette.fills.strokeMedium};
          svg path {
            fill: ${({ theme }) => theme.palette.icons.primary};
          }
        }

        ${containerCss}
      `}
      arrowPointAtCenter={true}
      title={title}
    >
      <InfoIconContainer css={iconContainerCss}>
        <IconInfoButton />
      </InfoIconContainer>
    </Tooltip>
  );
};
