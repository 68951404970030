// libs
import { put, select, call } from 'typed-redux-saga';
import { omit } from 'ramda';

// actions
import { setDetailsContent } from 'redux/actions/app';

// selectors
import { selectPathName } from 'redux/selectors';

// types
import { IActionPayload, ICreateTrustRequirementPayload, ITrustRequirementCreate } from 'types';

// utils
import { ApiTypeCaller } from 'redux/sagas/api/utils';
import { orgPush } from '../../utils';

// constants
import { TRUST_REQUIREMENT_INITIAL_VALIDATION_STATE } from 'appConstants';

export function* createTrustRequirementDetailsSaga({
  payload,
}: IActionPayload<ICreateTrustRequirementPayload | undefined>) {
  const requirementId = payload?.requirementId;
  const pathName = yield* select(selectPathName);

  if (new RegExp(/(trust-requirements\/\d+)/).test(pathName))
    yield call(orgPush, `/trust-requirements`);

  let dataNew: ITrustRequirementCreate = null;

  if (requirementId) {
    const { result } = yield* ApiTypeCaller('getTrustRequirement', {
      requirementId,
    });

    if (result) {
      const newDescription = `${result.description} (copy)`;
      dataNew = { ...omit(['id', 'created', 'modified'], result), description: newDescription };
    }
  }

  yield put(
    setDetailsContent({
      type: 'trust-requirement-create',
      dataNew,
      validationState: TRUST_REQUIREMENT_INITIAL_VALIDATION_STATE,
    })
  );
}
