// Lib
import { takeEvery } from 'redux-saga/effects';

// Actions
import { upgradePlan, getBillingPortal } from 'redux/actions/payment';

// Sagas
import { upgradePlanSaga } from './upgradePlanSaga';
import { getBillingPortalSaga } from './getBillingPortalSaga';

function* paymentSaga() {
  yield takeEvery(upgradePlan, upgradePlanSaga);
  yield takeEvery(getBillingPortal, getBillingPortalSaga);
}

export default paymentSaga;
