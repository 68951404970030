// components
import {
  PersonalInfoBlock,
  PasswordBlock,
  SettingsBlock,
  PATokensBlock,
  InvitesBlock,
  DangerZoneBlock,
  MessageBlock,
} from './components';
import { StyledTitle } from 'typography/Text';
import { AccountWrapper, TitleWrapper } from './styled';
import { PageTitle, LinkBack, PlanDependentContent } from 'components';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

// actions
import { getAccount } from 'redux/actions/account';

// selectors
import { selectCurrentOrganisationExists, selectCurrentOrganisationPlan } from 'redux/selectors';

// constants
import { EXTERNAL_PLAN, MSP_MANAGED_PLAN, MSP_PLAN } from 'appConstants';

export const Account = () => {
  const dispatch = useDispatch();

  const { translate } = useTranslations('account');

  const currentPlan = useSelector(selectCurrentOrganisationPlan);
  const DoesOrgExist = useSelector(selectCurrentOrganisationExists);

  useEffect(() => {
    dispatch(getAccount());
  }, [dispatch]);

  return (
    <AccountWrapper>
      <PageTitle title={translate('account')} />
      <TitleWrapper>
        {DoesOrgExist && <LinkBack />}
        <StyledTitle>{translate('my_account')}</StyledTitle>
      </TitleWrapper>
      <MessageBlock />
      {!DoesOrgExist && <InvitesBlock />}
      <PersonalInfoBlock />
      <PasswordBlock />
      <SettingsBlock />
      {DoesOrgExist && <InvitesBlock />}
      <PATokensBlock />
      <PlanDependentContent
        plan={currentPlan}
        hiddenWith={[EXTERNAL_PLAN, MSP_PLAN, MSP_MANAGED_PLAN]}
      >
        <DangerZoneBlock />
      </PlanDependentContent>
    </AccountWrapper>
  );
};
