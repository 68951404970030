import styled from 'styled-components';

export const Wrapper = styled.div`
  grid-row: 2 / span 1;
  grid-column: 2 / span 1;

  padding: 2rem;
  background-color: ${({ theme }) => theme.palette.background.panel};
  box-shadow: ${({ theme }) => theme.palette.elevationShadows[200]};
  border-radius: 0.25rem;
`;

export const EmailNotificationRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;
