// libs
import { css, useTheme } from 'styled-components';

// components
import { TagsList, GatewaysList } from 'components';
import { IconPoliciesTrafficAllowed, IconPoliciesTrafficDenied } from 'assets/svg';
import {
  Traffic,
  Container,
  TagTitleCustomText,
  IconContainer,
  TagListWrapper,
  TagsContainer,
} from '../styled';
import { SubnetFilterView } from './SubnetFilter/View';
import { GatewayPriorityView } from './GatewayPriority/View';

// hooks
import { useSelector } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';

// selectors
import {
  selectAppDetailCurrentPolicyGateways,
  selectAppDetailsPolicySenderTags,
  selectAppDetailsPolicyState,
} from 'redux/selectors';
import { Trans } from 'react-i18next';

export const PolicyMembersView = () => {
  const { translate } = useTranslations('policies');

  const {
    palette: { fonts },
  } = useTheme();

  const state = useSelector(selectAppDetailsPolicyState);
  const senderTags = useSelector(selectAppDetailsPolicySenderTags);
  const gateways = useSelector(selectAppDetailCurrentPolicyGateways);

  return (
    <Container>
      <TagsContainer>
        <Traffic>
          <TagTitleCustomText type="body-2" color={fonts.bodyLight}>
            <Trans
              i18nKey={translate('details.gateway_senders')}
              components={[
                <span
                  key={0}
                  css={css`
                    color: ${fonts.help};
                  `}
                />,
              ]}
            />
          </TagTitleCustomText>
          <TagListWrapper>
            <TagsList
              width="100%"
              tags={senderTags}
              css={css`
                padding: 0.5rem;
              `}
              likeSelect={true}
              blocksMode={true}
            />
          </TagListWrapper>
        </Traffic>
        <IconContainer>
          {state === 'InactiveNoAcls' || senderTags.length === 0 || gateways.length === 0 ? (
            <IconPoliciesTrafficDenied />
          ) : (
            <IconPoliciesTrafficAllowed />
          )}
        </IconContainer>
        <Traffic>
          <TagTitleCustomText type="body-2" color={fonts.bodyLight}>
            {translate('details.gateway_receivers')}
          </TagTitleCustomText>
          <TagListWrapper>
            <GatewaysList
              width="100%"
              gateways={gateways}
              css={css`
                padding: 0 0.25rem 0.5rem 0.625rem;
              `}
              likeSelect={true}
              blocksMode={true}
            />
          </TagListWrapper>
        </Traffic>
      </TagsContainer>

      <GatewayPriorityView />

      <SubnetFilterView />
    </Container>
  );
};
