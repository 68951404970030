// components
import { CustomText } from 'typography/Text';
import { PaginationTextContainer } from './styled';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { useMemo } from 'react';
import { useTheme } from 'styled-components';

// constants
import { ITEMS_PER_TABLE_PAGE } from 'appConstants';

interface IPaginationText {
  page: number;
  total: number;
}

export const PaginationText = ({ total, page }: IPaginationText) => {
  const {
    palette: { fonts },
  } = useTheme();

  const { translate: translateGlobal } = useTranslations('global');

  const paginationText = useMemo(() => {
    if (!total) {
      return '';
    } else {
      return translateGlobal('pagination.items_showing', {
        firstValue: 1 + page * ITEMS_PER_TABLE_PAGE,
        secondValue:
          (page + 1) * ITEMS_PER_TABLE_PAGE > total ? total : (page + 1) * ITEMS_PER_TABLE_PAGE,
        total,
      });
    }
    return '';
  }, [page, total, translateGlobal]);

  return (
    <PaginationTextContainer>
      <CustomText type="body-2" color={fonts.bodyLight}>
        {paginationText}
      </CustomText>
    </PaginationTextContainer>
  );
};
