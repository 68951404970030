// Hooks
import { useTheme } from 'styled-components';
import { useTranslations } from 'hooks/useTranslations';

// Components
import { ActionDropdown } from 'components';

// Types
import { IOrganisationInvite } from 'types';

export type InviteActions = 'resend' | 'cancel';

export type ActionHandler = (
  type: InviteActions,
  email: IOrganisationInvite['emailAddress']
) => void;

export type ActionMenuProps = {
  email: IOrganisationInvite['emailAddress'];
  onActionClick: ActionHandler;
};

export const InviteActionDropdown = ({ email, onActionClick }: ActionMenuProps) => {
  const {
    palette: { state },
  } = useTheme();
  const { translate } = useTranslations('settings');
  return (
    <ActionDropdown
      placement="bottomRight"
      data={[
        { text: translate('resend_invite'), onClick: () => onActionClick('resend', email) },
        {
          text: translate('cancel_invite'),
          onClick: () => onActionClick('cancel', email),
          color: state.error,
        },
      ]}
    />
  );
};
