// libs
import { memo } from 'react';

// components
import Skeleton from 'react-loading-skeleton';
import { CustomText } from 'typography/Text';

// hooks
import { useTheme } from 'styled-components';
import { useTranslations } from 'hooks/useTranslations';

// types
import { ISimpleEnrolmentKey } from 'types';

interface IApprovalMode {
  approvalMode: ISimpleEnrolmentKey['approvalMode'];
  isEnabled?: ISimpleEnrolmentKey['isEnabled'];
  ephemeral: boolean;
  isLoading: boolean;
}

export const ApprovalMode = memo(
  ({ approvalMode, isEnabled = true, isLoading, ephemeral }: IApprovalMode) => {
    const {
      palette: { fonts },
    } = useTheme();

    const { translate } = useTranslations('enrolment-keys');

    return (
      <>
        {isLoading ? (
          <Skeleton />
        ) : !ephemeral ? (
          <CustomText
            type="body-2"
            color={isEnabled ? fonts.bodyDark : fonts.disabled}
            css={`
              flex: 1;
            `}
          >
            {approvalMode === 'Manual'
              ? translate('details.gated')
              : translate('details.automatic')}
          </CustomText>
        ) : (
          '--'
        )}
      </>
    );
  }
);
