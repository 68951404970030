// libs
import { css, useTheme } from 'styled-components';

// components
import { Trans } from 'react-i18next';
import { InfoContainer, TabsContainer } from './styled';
import { TabButtons, CustomMessage, InternalLinkInline, TrialBanner } from 'components';
import { Docker, Kubernetes, MacOs, Windows, Linux, Android, IOS } from './OS';
import { StyledHeader, CustomText } from 'typography/Text';
import { OrderedList } from './components/List';
import { DocsLinkMessage } from './components';
import { tabs } from './utils';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// types
import { IInstallWindowsJson, OSType } from 'types';

// actions
import { setSelectedEnrolmentKey } from 'redux/actions/enrolment-keys';

// selectors
import { selectCurrentOrganisationInfo } from 'redux/selectors';

// constants
import { PLANS_N_SUBS_ANCHOR, LINK_TO_WINDOWS_BUILD, UNLIMITED_USES_AMOUNT } from 'appConstants';

export const EnrolNewSystem = () => {
  const {
    palette: { fonts },
  } = useTheme();
  const dispatch = useDispatch();

  const { translate } = useTranslations('enrol-new-system');

  const [selectedOS, setSelectedOS] = useState<OSType>(OSType.Windows);
  const [installWindowsLink, setInstallWindowsLink] = useState<string | undefined>();

  const { enrolledSystems, maxSystems, id } = useSelector(selectCurrentOrganisationInfo);

  useEffect(() => {
    fetch(LINK_TO_WINDOWS_BUILD)
      .then(result => {
        return result.json();
      })
      .then((jsonResult: IInstallWindowsJson) => {
        const url = jsonResult.ReleaseVersions.reverse().find(releaseVersion => {
          return releaseVersion.ReleaseType === 'GA';
        })?.Packages[0].Url;
        setInstallWindowsLink(url);
      });
    return () => {
      dispatch(setSelectedEnrolmentKey(null));
    };
  }, [dispatch]);

  return (
    <section>
      <StyledHeader>
        <CustomText data-test="systems-header" type="heading-1" color={fonts.title}>
          {translate('enrol_new_system')}
        </CustomText>
      </StyledHeader>
      <TrialBanner />
      {maxSystems !== UNLIMITED_USES_AMOUNT && enrolledSystems >= maxSystems && (
        <CustomMessage
          type="warning"
          message={
            <>
              <Trans
                i18nKey={translate('systems_limit.text')}
                components={[
                  <InternalLinkInline
                    key={0}
                    title={translate('uses_exhausted.link_title')}
                    to={`/org/${id}/settings#${PLANS_N_SUBS_ANCHOR}`}
                    inlineText={translate('systems_limit.link_title')}
                  />,
                ]}
              />
            </>
          }
          withIcon
        />
      )}
      <InfoContainer>
        <TabsContainer>
          <TabButtons
            onChange={value => setSelectedOS(value as OSType)}
            selectedKey={selectedOS}
            tabCss={css`
              :not(:first-child) {
                margin-left: 1rem;
              }
            `}
            tabs={tabs}
          ></TabButtons>
        </TabsContainer>
        <OrderedList>
          {selectedOS === 'Windows' && <Windows link={installWindowsLink} />}
          {selectedOS === 'Linux' && <Linux />}
          {selectedOS === 'MacOS' && <MacOs />}
          {selectedOS === 'Docker' && <Docker />}
          {selectedOS === 'Kubernetes' && <Kubernetes />}
          {selectedOS === 'Android' && <Android />}
          {selectedOS === 'iOS' && <IOS />}
        </OrderedList>
        <DocsLinkMessage OS={selectedOS} />
      </InfoContainer>
    </section>
  );
};
