import styled, { css } from 'styled-components';

export const tooltipCss = css`
  display: flex;

  .ant-tooltip-inner {
    background-color: ${props => props.theme.palette.background.panel} !important;
    box-shadow: ${props => props.theme.palette.elevationShadows[200]};
    border: 0.0625rem solid ${props => props.theme.palette.background.iconBackground};
  }
`;

export const EditContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
