// libs
import { css, useTheme } from 'styled-components';

// components
import {
  TrustRequirementsIconContainer,
  TrustRequirementsListWrapper,
  ListElement,
} from './styled';
import { CustomText } from 'typography/Text';
import { InternalLink } from 'components/Links/InternalLink';

// hooks
import { useSelector } from 'react-redux';

// selectors
import { selectOrgId } from 'redux/selectors';

// types
import { IUsedTrustRequirement } from 'types';

// constants
import { ROUTE_PATHS, TRUST_REQUIREMENTS_ICONS } from 'appConstants';

interface ITrustRequirementsProps {
  trustRequirements: IUsedTrustRequirement[];
  suffix?: ({ onClick }: { onClick?: () => void }) => JSX.Element;
  onSuffixClick?: (trustRequirements: IUsedTrustRequirement) => void;
}

export const TrustRequirementsList = ({
  trustRequirements,
  suffix: Suffix,
  onSuffixClick,
  ...rest
}: ITrustRequirementsProps) => {
  const {
    palette: { fonts },
  } = useTheme();

  const orgId = useSelector(selectOrgId);

  return (
    <TrustRequirementsListWrapper {...rest}>
      {trustRequirements.map((trustRequirement: IUsedTrustRequirement) => {
        const { id, description, type } = trustRequirement;
        const Icon = TRUST_REQUIREMENTS_ICONS[type];

        return (
          <ListElement key={id}>
            <TrustRequirementsIconContainer>
              <Icon />
              <CustomText
                ellipsis={true}
                title={description}
                type="body-2"
                color={fonts.title}
                {...rest}
              >
                {description}
              </CustomText>
              <InternalLink
                to={`/org/${orgId}${ROUTE_PATHS['trust-requirements']}/${id}`}
                size="medium"
                color={fonts.disabled}
                ellipsis={true}
                css={css`
                  margin-left: 0.625rem;
                `}
              >
                #{id}
              </InternalLink>
            </TrustRequirementsIconContainer>

            {Suffix && <Suffix onClick={() => onSuffixClick?.(trustRequirement)} />}
          </ListElement>
        );
      })}
    </TrustRequirementsListWrapper>
  );
};
