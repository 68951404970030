// libs
import { useTheme } from 'styled-components';

// components
import { CustomText } from 'typography/Text';
import {
  ZoneInfoContainer,
  ZoneInfoRow,
  ZoneInfoColumnName,
  ZoneInfoColumnDescription,
} from './styled';

interface IInfo {
  rows: { name: string; description: string | number; isBold?: boolean }[];
}

export const Info = ({ rows }: IInfo) => {
  const {
    palette: { fonts },
  } = useTheme();
  return (
    <ZoneInfoContainer>
      {rows.map(row => (
        <ZoneInfoRow key={row.name}>
          <ZoneInfoColumnName>
            <CustomText type="body-1" color={fonts.bodyLight}>
              {row.name}
            </CustomText>
          </ZoneInfoColumnName>
          <ZoneInfoColumnDescription>
            <CustomText type={row.isBold ? 'sub-heading-1' : 'body-1'} color={fonts.bodyDark}>
              {row.description}
            </CustomText>
          </ZoneInfoColumnDescription>
        </ZoneInfoRow>
      ))}
    </ZoneInfoContainer>
  );
};
