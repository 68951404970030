// libs
import { css, useTheme } from 'styled-components';

// components
import { SubnetInput } from './SubnetInput';
import { CloseButton } from './CloseButton';
import { CustomText } from 'typography/Text';
import { Container, ContentContainer, Row, SubnetTextContainer } from './styled';

// hooks
import { useTranslations } from 'hooks/useTranslations';

// types
import { ISystemGatewayRouteModified } from 'types';
import { SubnetName } from './SubnetName';
import { PreferredSelector } from './PreferredSelector';

interface ISubnet {
  route: ISystemGatewayRouteModified;
}

export const Subnet = ({ route }: ISubnet) => {
  const { translate } = useTranslations('systems');

  const {
    palette: { fonts },
  } = useTheme();

  return (
    <Container data-test="system-details-gateway-route">
      <ContentContainer>
        <Row>
          {route.userEntered ? (
            <SubnetInput route={route} />
          ) : (
            <SubnetTextContainer>
              <CustomText type="body-2" color={fonts.title}>
                {route.subnet}
              </CustomText>
              <CustomText
                type="body-2"
                css={css`
                  white-space: pre;
                `}
                color={fonts.help}
              >
                {' ' + translate('details.gateway.known')}
              </CustomText>
            </SubnetTextContainer>
          )}
          <PreferredSelector route={route} />
        </Row>
        <Row>
          <SubnetName route={route} />
        </Row>
      </ContentContainer>
      <CloseButton route={route} />
    </Container>
  );
};
