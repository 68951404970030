import styled from 'styled-components';
import { IconDown } from 'assets/svg';

export const SectionHeaderContainer = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const RightDirectionIcon = styled(IconDown)`
  transform: rotate(-90deg);
  width: 1rem;
  height: 1rem;
  path {
    fill: ${({ theme }) => theme.palette.icons.primary};
  }
`;
