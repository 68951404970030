import { useTheme } from 'styled-components';
import { ISystemGatewayRouteModified } from 'types';
import { CustomText } from 'typography/Text';

interface ISubnetName {
  route: ISystemGatewayRouteModified;
}

export const SubnetName = ({ route, ...rest }: ISubnetName) => {
  const {
    palette: { fonts },
  } = useTheme();

  return (
    <CustomText type="body-2" color={fonts.bodyLight} {...rest}>
      {route.name}
    </CustomText>
  );
};
