// libs
import { call, put } from 'typed-redux-saga';

// actions
import { setModalContent, setModalIsLoading } from 'redux/actions/app';

// types
import { IActionPayload, ICreateStepUpAndOpenModal } from 'types';

// sagas
import { createStepUpSaga } from './createStepUpSaga';
import { openEnable2FAModalSaga } from './openEnable2FAModalSaga';

export function* createStepUpAndOpenModalSaga({
  payload: { password, modalData, nextModal },
}: IActionPayload<ICreateStepUpAndOpenModal>) {
  yield put(setModalIsLoading(true));

  const success = yield* call(() =>
    createStepUpSaga({
      payload: { password },
    })
  );

  if (nextModal === 'delete-PAToken' && success) {
    yield put(setModalContent({ type: 'delete-PAToken', data: modalData }));
  }
  if (nextModal === 'enable-2FA' && success) {
    yield call(openEnable2FAModalSaga);
  }
  if (nextModal === 'disable-2FA' && success) {
    yield put(setModalContent({ type: 'disable-2FA', data: null }));
  }
  if (nextModal === 'create-PAToken' && success) {
    yield put(setModalContent({ type: 'create-PAToken', data: null }));
  }
  yield put(setModalIsLoading(false));
}
