// libs
import { useCallback, useState } from 'react';
import { css, useTheme } from 'styled-components';

// components
import { CustomButton, CheckboxWithDesc, Loader, CustomButtonLink } from 'components';
import { ModalFooter, ModalHeader, ModalScrollablePart } from 'components/Modal';
import { IconAccountDisable2FAModal } from 'assets/svg';
import { CustomText } from 'typography/Text';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { useDispatch, useSelector } from 'react-redux';
import { useSubmitOnEnter } from 'hooks/useSubmitOnEnter';

// actions
import { setModalContent } from 'redux/actions/app';
import { disable2FA } from 'redux/actions/account';

// selectors
import { selectModalIsLoading } from 'redux/selectors';

export const Disable2FAModal = () => {
  const dispatch = useDispatch();

  const { translate } = useTranslations('account');

  const {
    palette: {
      fonts: { bodyDark },
    },
  } = useTheme();

  const [isApproved, setIsApproved] = useState(false);

  const isLoading = useSelector(selectModalIsLoading);
  const isButtonEnabled = isApproved && !isLoading;

  const onClose = () => {
    dispatch(setModalContent({ type: null, data: null }));
  };

  const onDisable2FA = useCallback(() => {
    if (isApproved) {
      dispatch(disable2FA());
    }
  }, [dispatch, isApproved]);

  useSubmitOnEnter({ callback: isButtonEnabled ? onDisable2FA : () => {} });

  return (
    <>
      <ModalHeader
        title={translate('disable_2fa_modal.title')}
        icon={<IconAccountDisable2FAModal width="2.5rem" height="2.5rem" />}
        withCloseButton={true}
        onClose={onClose}
      />

      <ModalScrollablePart>
        <CustomText
          type="sub-heading-1"
          color={bodyDark}
          css={css`
            display: block;
          `}
        >
          {translate('disable_2fa_modal.confirmation')}
        </CustomText>
        <CustomText
          type="body-1"
          color={bodyDark}
          css={css`
            display: block;
            margin-bottom: 1.5rem;
          `}
        >
          {translate('disable_2fa_modal.desc')}
        </CustomText>
        <CheckboxWithDesc
          css={css`
            margin-bottom: 1.5rem;
          `}
          isDisabled={isApproved}
          onChange={() => setIsApproved(!isApproved)}
          color={bodyDark}
          desc={translate('disable_2fa_modal.checkbox_desc')}
        />
      </ModalScrollablePart>
      <ModalFooter>
        <CustomButtonLink size="large" onClick={onClose}>
          {translate('cancel')}
        </CustomButtonLink>
        <CustomButton
          size="large"
          width="5.5625rem"
          kind={isButtonEnabled ? 'primary' : 'disabled'}
          onClick={onDisable2FA}
        >
          {isLoading ? <Loader /> : translate('done')}
        </CustomButton>
      </ModalFooter>
    </>
  );
};
