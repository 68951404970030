// libs
import { css } from 'styled-components';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

// components
import { IconDetailsActiveHours } from 'assets/svg';
import { Details } from 'components';
import { ActiveHoursSection } from './styled';
import { ActiveHoursBlock } from '../../Blocks';
import { ActiveDaysBlock } from '../../Blocks';
import { ActiveHoursTimeZoneBlock } from '../../Blocks';
import Description from './Description';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { useState } from 'react';
import { useSelector } from 'react-redux';

// selectors
import {
  selectAppDetailsPolicyActiveHoursData,
  selectAppDetailsPolicyId,
  selectAppDetailsTabType,
} from 'redux/selectors';
import { createPolicyDetailsActiveHours, updatePolicyDetailsActiveHours } from 'redux/actions/app';

// types
import { IPolicyActiveHours } from 'types';

type IPolicyActiveHoursRememberedData = Partial<IPolicyActiveHours> | null;

export const ActiveHours = () => {
  const { translate } = useTranslations('policies');
  const dispatch = useDispatch();

  const policyId = useSelector(selectAppDetailsPolicyId);
  const type = useSelector(selectAppDetailsTabType);
  const activeHoursData = useSelector(selectAppDetailsPolicyActiveHoursData);

  const [isOpen, setIsOpen] = useState(false);
  const [rememberedData, setRememberedData] = useState<IPolicyActiveHoursRememberedData>(null);

  useEffect(() => {
    if (activeHoursData) {
      setIsOpen(true);
      setRememberedData(null);
    } else {
      setIsOpen(false);
    }
  }, [activeHoursData, setRememberedData]);

  useEffect(() => {
    if (isOpen && !activeHoursData) {
      if (rememberedData) {
        dispatch(updatePolicyDetailsActiveHours({ ...rememberedData }));
      } else {
        dispatch(createPolicyDetailsActiveHours());
      }
    }

    if (!isOpen && activeHoursData) {
      setRememberedData({ ...activeHoursData });
      dispatch(updatePolicyDetailsActiveHours(null));
    }
  }, [dispatch, isOpen, activeHoursData, rememberedData]);

  useEffect(() => {
    return () => {
      setRememberedData(null);
      setIsOpen(false);
    };
  }, [policyId]);

  if (type === 'policy-view' && !activeHoursData) {
    return null;
  }

  return (
    <ActiveHoursSection data-test="policy-details-active-hours-section">
      <Details.SectionHeader
        Icon={IconDetailsActiveHours}
        title={translate('details.time_limitations.title')}
        css={css`
          margin-bottom: 0.65rem;
        `}
        switchConfig={
          type === 'policy-edit' || type === 'policy-create'
            ? { value: isOpen, setValue: setIsOpen }
            : undefined
        }
      />
      <Description />
      <ActiveHoursBlock isOpen={isOpen || type === 'policy-view'} />
      <ActiveDaysBlock isOpen={isOpen || type === 'policy-view'} />
      <ActiveHoursTimeZoneBlock isOpen={isOpen || type === 'policy-view'} />
    </ActiveHoursSection>
  );
};
