//@ts-ignore
import { omit } from 'ramda';
import {
  IPolicyDetailsModified,
  IPolicyDetails,
  IPolicyModifiedAcl,
  IPolicyModifiedGatewayAllowedIpRange,
  IPolicyAcl,
  IPolicyGatewayAllowedIpRange,
} from 'types';
import { randomHash } from './hash';

export const modifyPolicyDetails = (policy: IPolicyDetails): IPolicyDetailsModified => {
  const newAcls: IPolicyModifiedAcl[] = policy.acls.map(acl => {
    return { ...acl, id: randomHash() };
  });
  const newGatewayAllowedIpRanges: IPolicyModifiedGatewayAllowedIpRange[] =
    policy.gatewayAllowedIpRanges.map(ipRange => {
      return { ...ipRange, id: randomHash() };
    });

  return { ...policy, acls: newAcls, gatewayAllowedIpRanges: newGatewayAllowedIpRanges };
};

export const demodifyPolicyDetails = (policy: IPolicyDetailsModified): IPolicyDetails => {
  const acls: IPolicyAcl[] | undefined = policy?.acls?.map(acl => ({
    ...omit(['id'], acl),
    protocol: acl.protocol || 'Any',
  }));

  const gatewayAllowedIpRanges: IPolicyGatewayAllowedIpRange[] | undefined =
    policy?.gatewayAllowedIpRanges?.map(range => ({
      ...omit(['id'], range),
    }));

  return {
    ...policy,
    acls,
    gatewayAllowedIpRanges,
  };
};
