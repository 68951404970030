import { IValidationState } from 'types';

export const ENROLMENT_KEYS_RANGE_VALIDATION_STATE_PREFIX = 'range-';

export const SYSTEM_EDIT_INITIAL_VALIDATION_STATE: IValidationState = {
  title: { state: { error: null } },
  customDnsWarning: { state: { error: null } },
  customDnsError: { state: { error: null } },
};

export const DNS_RECORD_INITIAL_VALIDATION_STATE: IValidationState = {
  title: { state: { error: null } },
};

export const POLICY_INITIAL_VALIDATION_STATE: IValidationState = {
  title: { state: { error: null } },
};

export const TRUST_REQUIREMENT_INITIAL_VALIDATION_STATE: IValidationState = {
  title: { state: { error: null } },
};

export const SYSTEM_GATEWAY_ROUTE_VALIDATION_STATE_PREFIX = 'gateway-route-';
export const POLICY_TRAFFIC_VALIDATION_STATE_PREFIX = 'traffic-';
export const POLICY_PROTOCOL_VALIDATION_STATE_PREFIX = 'protocol-';
export const POLICY_PORTS_VALIDATION_STATE_PREFIX = 'ports-';
export const POLICY_SUBNET_VALIDATION_STATE_PREFIX = 'subnet-';

export const TRUST_REQUIREMENT_IP_RANGE_VALIDATION_STATE_PREFIX = 'ip-range-';
