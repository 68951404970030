// components
import { CustomBadge } from 'components';
import { CustomText } from 'typography/Text';

// hooks
import { useTheme } from 'styled-components';
import { useTranslations } from 'hooks/useTranslations';

// types
import { IEnrolmentKey } from 'types';

interface IStatus {
  isEnabled?: IEnrolmentKey['isEnabled'];
}

export const Status = ({ isEnabled = true }: IStatus) => {
  const {
    palette: { fonts },
  } = useTheme();

  const { translate } = useTranslations('enrolment-keys');

  return (
    <>
      <CustomBadge color={isEnabled ? 'blue' : 'gray'} />
      <CustomText type="body-2" color={isEnabled ? fonts.bodyDark : fonts.disabled}>
        {isEnabled ? translate('enabled') : translate('disabled')}
      </CustomText>
    </>
  );
};
