// Lib
import { select, call } from 'typed-redux-saga';

// Selectors
import { selectPathSearch } from 'redux/selectors';

// Types
import { IActionPayload, RedirectPayload } from 'types';

// Utils
import { orgPush } from '../../../utils';

// Sagas
import { redirectToOrganisationSaga } from './locationChangeSaga/redirectToOrganisationSaga';

export function* redirectControlSaga({
  payload: { route, routePush },
}: IActionPayload<RedirectPayload>) {
  const querySearch = yield* select(selectPathSearch);

  yield call(orgPush, `${route}${querySearch}`, { routePush });
  yield call(redirectToOrganisationSaga);
}
