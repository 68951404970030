// libs
import { call, put, select } from 'typed-redux-saga';

// actions
import { closeDetails, pushNotification } from 'redux/actions/app';
import { setSystemsShouldUpdate } from 'redux/actions/unapproved-systems';

// selectors
import { selectAppDetailsSystemSystemId } from 'redux/selectors';

// sagas
import { getCurrentOrganisationSaga } from '../organisation/getCurrentOrganisationSaga';

// types
import { IActionPayload, IApproveUnapprovedSystemProps, INotificationType } from 'types';

// utils
import { orgPush } from '../../utils';
import { ApiTypeCaller } from '../../api/utils';
import { translator } from 'utils';

export function* approveSystemSaga({
  payload: { systemId },
}: IActionPayload<IApproveUnapprovedSystemProps>) {
  const selectedSystemId = yield* select(selectAppDetailsSystemSystemId);

  if (selectedSystemId !== undefined && systemId === selectedSystemId) {
    yield* put(closeDetails({}));
  }

  const { success } = yield* ApiTypeCaller('approveUnapprovedSystem', { systemId });

  if (success) {
    const translate = translator('notifications');

    yield put(setSystemsShouldUpdate());
    yield call(getCurrentOrganisationSaga);
    yield put(
      pushNotification({
        showType: INotificationType.Success,
        description: translate('system_approved', {
          systemId,
        }),
      })
    );
  }
  return { success };
}

export function* approveSystemFromDetailsSaga({
  payload: { systemId },
}: IActionPayload<IApproveUnapprovedSystemProps>) {
  const { success } = yield* ApiTypeCaller('approveUnapprovedSystem', { systemId });
  const translate = translator('notifications');

  if (success) {
    yield call(orgPush, '/unapproved-systems', { keepSearch: true });
    yield put(setSystemsShouldUpdate());
    yield put(
      pushNotification({
        showType: INotificationType.Success,
        description: translate('system_withId_approved', {
          systemId,
        }),
      })
    );
    yield call(getCurrentOrganisationSaga);
  }
}
