// libs
import { put, select, call } from 'typed-redux-saga';

// actions
import { setDetailsContent } from 'redux/actions/app';

// selectors
import { selectPathName } from 'redux/selectors';

// utils
import { orgPush } from '../../utils';
import { ApiTypeCaller } from 'redux/sagas/api/utils';
import { getPolicyAclsValidationState, randomHash, translator, modifyPolicyDetails } from 'utils';

// types
import {
  IActionPayload,
  IPolicyDetailsModified,
  IDuplicatePolicyPayload,
  IPolicyModifiedAcl,
} from 'types';

export function* createPolicyDetailsSaga({
  payload,
}: IActionPayload<IDuplicatePolicyPayload | undefined>) {
  const pathName = yield* select(selectPathName);
  const translate = translator('policies');
  const policyId = payload?.policyId;

  if (new RegExp(/(policies\/\d+)/).test(pathName)) yield call(orgPush, `/policies`);

  const acls: IPolicyModifiedAcl[] = [
    {
      protocol: 'Any',
      ports: null,
      description: translate('details.acls_table.description_create_default'),
      isDescriptionModified: false,
      id: randomHash(),
    },
  ];
  const validationState = getPolicyAclsValidationState({ acls });

  let dataNew: IPolicyDetailsModified = {
    id: '',
    created: '',
    state: 'Active',
    description: '',
    isEnabled: true,
    senderTags: [],
    receiverTags: [],
    senderTrustRequirements: [],
    acls,
    gateways: [],
    gatewayAllowedIpRanges: [],
    gatewayTrafficDirection: 'Exit',
    gatewayPriority: 'Balanced',
    notes: null,
    type: 'General',
    activeHours: null,
    autoExpire: null,
  };

  if (policyId) {
    const { result } = yield* ApiTypeCaller('getPolicy', { policyId });

    if (result) {
      const modifiedPolicy = modifyPolicyDetails(result);

      const newDescription = `${result.description} (copy)`;

      dataNew = {
        ...modifiedPolicy,
        description: newDescription,
      };
    }
  }

  yield put(
    setDetailsContent({
      type: 'policy-create',
      dataNew,
      validationState,
    })
  );
}
