// libs
import styled from 'styled-components';

export type IStatusColor = string | null;

export const StatusIcon = styled.div<{ colour: IStatusColor }>`
  display: ${({ colour: color }) => (color ? 'flex' : 'none')};
  background-color: ${({ colour: color }) => color};
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 50%;
  margin-right: 0.5rem;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ViewContainer = styled.div`
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
`;
