// libs
import { pick } from 'ramda';
import { call, put, select } from 'typed-redux-saga';

// actions
import { setDetailsContent, pushNotification } from 'redux/actions/app';
import { updatePolicy } from 'redux/actions/policies';

// selectors
import { selectAppDetailsTabData, selectAppDetailsTabDataNew } from 'redux/selectors';

// sagas
import { checkCreatedSaga } from '../tags/checkCreatedSaga';

// utils
import { ApiTypeCaller } from '../../api/utils';
import {
  translator,
  tagsToStrings,
  trustRequirementsToNumbers,
  demodifyPolicyDetails,
  modifyPolicyDetails,
  removeUndefinedKeys,
  diff,
} from 'utils';

// types
import { INotificationType, IPolicyDetailsModified, IUpdatePolicyPayload } from 'types';

export function* saveUpdatedPolicySaga() {
  const translate = translator('notifications');

  const data: IPolicyDetailsModified = yield select(selectAppDetailsTabData);
  const newData: IPolicyDetailsModified = yield select(selectAppDetailsTabDataNew);
  const demodifiedPolicy = demodifyPolicyDetails(diff(newData, data));

  const updatedParams = pick(
    [
      'description',
      'senderTags',
      'receiverTags',
      'senderTrustRequirements',
      'isEnabled',
      'notes',
      'acls',
      'type',
      'gateways',
      'gatewayTrafficDirection',
      'gatewayPriority',
      'senderTrustRequirements',
      'gatewayAllowedIpRanges',
      'autoExpire',
      'activeHours',
    ],
    demodifiedPolicy
  );

  const senderTags = updatedParams.senderTags ? tagsToStrings(updatedParams.senderTags) : undefined;
  const receiverTags = updatedParams.receiverTags
    ? tagsToStrings(updatedParams.receiverTags)
    : undefined;
  const senderTrustRequirements = updatedParams.senderTrustRequirements
    ? trustRequirementsToNumbers(updatedParams.senderTrustRequirements)
    : undefined;

  const gateways = updatedParams.gateways
    ? updatedParams.gateways.map(gw => ({
        systemId: gw.systemId,
        routes: gw.routes.map(r => r.route),
      }))
    : undefined;

  const payload: IUpdatePolicyPayload = {
    policyId: String(newData.id),
    updatedParams: removeUndefinedKeys(
      newData.type === 'General'
        ? {
            senderTags,
            receiverTags,
            senderTrustRequirements,
            acls: updatedParams.acls,
            description: updatedParams.description,
            notes: updatedParams.notes,
            isEnabled: updatedParams.isEnabled,
            activeHours: updatedParams.activeHours,
            autoExpire: updatedParams.autoExpire,
          }
        : {
            senderTags,
            senderTrustRequirements,
            acls: updatedParams.acls,
            gateways: gateways,
            gatewayAllowedIpRanges: updatedParams.gatewayAllowedIpRanges,
            gatewayPriority: updatedParams.gatewayPriority,
            description: updatedParams.description,
            notes: updatedParams.notes,
            isEnabled: updatedParams.isEnabled,
            activeHours: updatedParams.activeHours,
            autoExpire: updatedParams.autoExpire,
          }
    ),
  };

  const { result, success } = yield* ApiTypeCaller('updatePolicy', payload);

  if (success && result) {
    const modifiedPolicy = modifyPolicyDetails(result);

    yield put(
      setDetailsContent({
        data: modifiedPolicy,
        type: 'policy-view',
      })
    );
    yield put(updatePolicy(result));
    yield put(
      pushNotification({
        showType: INotificationType.Success,
        description: translate('policy_updated', {
          policyId: newData.id,
        }),
      })
    );
  }
  yield call(checkCreatedSaga, senderTags);
  yield call(checkCreatedSaga, receiverTags);
}
