import { useTheme } from 'styled-components';
import { CustomText } from 'typography/Text';
import { InfoRow, InfoRowName, InfoRowValue } from './styled';

interface IRow {
  name: string;
  value: string;
}

export const Row = ({ name, value }: IRow) => {
  const {
    palette: { fonts },
  } = useTheme();

  return (
    <InfoRow>
      <InfoRowName>
        <CustomText type="body-2" color={fonts.bodyLight}>
          {name}:
        </CustomText>
      </InfoRowName>

      <InfoRowValue>
        <CustomText type="sub-heading-2" color={fonts.bodyLight} lineBreak="anywhere">
          {value}
        </CustomText>
      </InfoRowValue>
    </InfoRow>
  );
};
