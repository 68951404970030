import styled from 'styled-components';
import { IconDown } from 'assets/svg';

export const ArrowsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ArrowContainer = styled.div`
  display: flex;
  height: 0.9375rem;
  align-items: center;
  justify-content: center;
  border-left: 0.0625rem solid ${({ theme }) => theme.palette.fills.strokeMedium};

  svg path {
    fill: ${({ theme }) => theme.palette.icons.secondary};
  }

  :hover {
    svg path {
      fill: ${({ theme }) => theme.palette.icons.primary};
    }
  }

  :first-child {
    border-bottom: 0.0625rem solid ${({ theme }) => theme.palette.fills.strokeMedium};
  }
`;

export const IconArrowDown = styled(IconDown)`
  width: 1rem;
  height: 1rem;
`;
export const IconArrowUp = styled(IconDown)`
  width: 1rem;
  height: 1rem;
  transform: rotate(180deg);
`;
