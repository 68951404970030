// Components
import { CancelSaveEdit } from './Edit';
import { CancelSaveCreate } from './Create';

// Hooks
import { useSelector } from 'react-redux';

// selectors
import { selectAppDetailsTabType } from 'redux/selectors';

export const CancelSave = () => {
  const type = useSelector(selectAppDetailsTabType);

  if (type === 'trust-requirement-view') {
    return null;
  }

  return (
    <>
      {type === 'trust-requirement-edit' && <CancelSaveEdit />}
      {type === 'trust-requirement-create' && <CancelSaveCreate />}
    </>
  );
};
