import styled from 'styled-components';
import { ReactComponent as IconArrowLeft } from 'assets/svg/arrow-left.svg';

export const PlanContainer = styled.div`
  margin: 2rem 0.75rem 0 0.75rem;
  background-color: ${({ theme }) => theme.palette.background.hover};
  border: 0.0625rem solid ${({ theme }) => theme.palette.fills.iconHover};
  border-radius: 0.25rem;
  display: flex;
  padding: 1rem;
`;

export const PlanDetails = styled.div`
  margin-left: 0.5rem;
  display: flex;
  flex-direction: column;
`;

export const UpgradeLink = styled.div`
  display: flex;
  align-items: center;
`;

export const IconArrowRight = styled(IconArrowLeft)`
  transform: rotate(180deg);
  width: 0.875rem;
  height: 0.875rem;
  margin-left: 0.5rem;
`;
