import { IconDetailsTopMenuButtonsClose, IconSearch } from 'assets/svg';
import styled, { css } from 'styled-components';
import { CustomText, stylesMap } from 'typography/Text';

export const styledInput = css`
  .ant-input-group-addon {
    display: none;
  }

  .ant-input-affix-wrapper {
    border-width: 0.0625rem;
    border-style: solid;
    border-color: ${({ theme }) => theme.palette.background.iconBackground};
    border-radius: 0.25rem !important;
    box-shadow: ${({ theme }) => theme.palette.elevationShadows[100]};
  }

  .ant-input-affix-wrapper:hover {
    border-color: ${({ theme }) => theme.palette.fills.stroke};
  }

  .ant-input-affix-wrapper-focused:hover,
  .ant-input-affix-wrapper-focused {
    border-color: ${({ theme }) => theme.palette.fills.primaryHover};
  }

  input {
    ${stylesMap['body-1']}
    width: 100%;
    color: ${({ theme }) => theme.palette.fonts.title};
  }
`;

const IconContainerLeft = styled.div`
  display: flex;
  width: 1.3125rem;
  margin-right: 0.3rem;
`;

const IconContainerRight = styled.div`
  display: flex;
  width: 1.3125rem;
`;

export const InputWrapper = styled.div<{ width?: string }>`
  position: relative;
  margin-right: 0.5rem;
  width: ${({ width }) => width || '100%'};
`;

const StyledSearchPrefix = styled(IconSearch)`
  path {
    fill: ${({ theme }) => theme.palette.icons.disabled};
  }
`;

const StyledSearchSuffix = styled(IconDetailsTopMenuButtonsClose)`
  width: 1.5rem;
  height: 1.5rem;
  path {
    fill: ${({ theme }) => theme.palette.icons.secondary};
  }
`;

export const SearchPrefix = () => (
  <IconContainerLeft>
    <StyledSearchPrefix />
  </IconContainerLeft>
);

type Props = {
  onSearchKeyUp: (value: string) => void;
  setSearchValue: (value: string) => void;
};

export const SearchSuffix = ({ onSearchKeyUp, setSearchValue }: Props) => (
  <IconContainerRight
    onClick={() => {
      setSearchValue?.('');
      onSearchKeyUp('');
    }}
  >
    <StyledSearchSuffix />
  </IconContainerRight>
);

export const DropdownContainer = styled.div<{ isOpen: boolean; minHeight: number | null }>`
  max-height: 30rem;
  min-height: ${({ minHeight }) => minHeight && `${minHeight / 16}rem`};
  overflow-y: auto;

  display: ${({ isOpen }) => !isOpen && 'none'};
  position: absolute;
  width: 100%;
  margin-top: 0.125rem;
  padding: 1rem 0.5rem;
  z-index: 1;
  border: 0.0625rem solid ${({ theme }) => theme.palette.fills.strokeMedium};
  background-color: ${({ theme }) => theme.palette.background.panel};
  border-radius: 0.25rem;
`;

export const DropdownContent = styled.div<{ isLoading: boolean }>`
  display: ${({ isLoading }) => isLoading && 'none'};
`;

export const FocusContainer = styled.div`
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-bottom: 0.5rem;
  border-bottom: 0.0625rem solid ${({ theme }) => theme.palette.background.iconBackground};
`;

export const HelpContainer = styled.div``;

export const DropdownDefaultKeyContainer = styled.div<{ isActiveByDefault?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 0.75rem;
  margin-bottom: 1.5rem;
  border-radius: 0.25rem;
  background: ${({ isActiveByDefault: isActive, theme }) =>
    isActive && theme.palette.fills.iconHover};
`;

export const DropdownKeyButton = styled.button<{ isActive?: boolean; isSelected?: boolean }>`
  width: 100%;
  border: none;
  text-align: inherit;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  padding: 0.75rem;
  border-radius: 0.25rem;

  :focus {
    outline: none;
  }

  background: none;

  background: ${({ isSelected, theme }) =>
    isSelected && `${theme.palette.background.sections} !important`};

  :hover,
  :focus {
    background: ${({ theme }) => theme.palette.background.sections};
  }

  background: ${({ isActive, theme }) => isActive && `${theme.palette.fills.iconHover} !important`};
`;

export const ColumnNameText = styled(CustomText)`
  display: block;
  width: 9.375rem;
  min-width: 9.375rem;
  margin-right: 0.625rem;
`;

export const EmptyContainer = styled.div`
  height: 15.625rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  text-align: center;
`;
