// libs
import { SyntheticEvent, useState } from 'react';
import { css } from 'styled-components';

// components
import { MenuItem } from './MenuItem';
import { SubMenu } from './SubMenu';
import { Container } from './styled';
import { Dropdown, Menu, Tooltip } from 'antd';
import { IconEllipsis } from 'assets/svg';
import { IconButton } from 'components';

// hooks
import { useTranslations } from 'hooks/useTranslations';

// types
import { DropDownProps } from 'antd/lib/dropdown';

interface ICommondData {
  color?: string;
  text: string;
}
interface IData extends ICommondData {
  isDropdown?: false;
  onClick: () => void;
}

export interface IDataDropdown<T> extends ICommondData {
  isDropdown: true;
  data: { text: string; value: T }[];
  onClick: (value: T, text: string) => void;
}

type IDataElement<T> = IData | IDataDropdown<T>;
interface IActionDropdown<T> {
  onContainerClick?: (event: SyntheticEvent) => void;
  data: IDataElement<T>[];
  placement?: DropDownProps['placement'];
}

export const ActionDropdown = <T extends any>({
  data,
  onContainerClick,
  placement,
  ...rest
}: IActionDropdown<T>) => {
  const { translate } = useTranslations('global');

  const [isVisible, setIsVisible] = useState(false);

  return (
    <Container onClick={onContainerClick} {...rest}>
      <span>
        <Dropdown
          visible={isVisible}
          onVisibleChange={setIsVisible}
          getPopupContainer={trigger => {
            return trigger?.parentElement || trigger;
          }}
          overlay={
            <Menu
              data-test="action-dropdown-menu"
              css={css`
                padding: 0.5rem 0.25rem;
                ${({ theme }) => theme.palette.elevationShadows[200]};
              `}
            >
              {data.map(dataElement => {
                if (!dataElement.isDropdown) {
                  return (
                    <MenuItem
                      data-test="action-dropdown-menu-item"
                      text={dataElement.text}
                      onClick={() => {
                        dataElement.onClick();
                        setIsVisible(false);
                      }}
                      color={dataElement.color}
                      key={dataElement.text}
                    />
                  );
                }
                if (dataElement.isDropdown) {
                  return (
                    <SubMenu
                      data-test="action-dropdown-menu-item"
                      key={dataElement.text}
                      text={dataElement.text}
                      subMenuData={dataElement}
                      onClickAdditional={() => {
                        setIsVisible(false);
                      }}
                    />
                  );
                }
              })}
            </Menu>
          }
          placement={placement || 'bottomRight'}
          trigger={['click']}
          css={css`
            border-radius: 0.25rem;
          `}
        >
          <Tooltip
            placement="top"
            title={translate('table_action_tooltip')}
            destroyTooltipOnHide={true}
            showArrow={false}
            mouseLeaveDelay={0}
          >
            <IconButton
              aria-label={translate('aria_labels.table_action')}
              onClick={() => setIsVisible(true)}
              icon={<IconEllipsis />}
            />
          </Tooltip>
        </Dropdown>
      </span>
    </Container>
  );
};
