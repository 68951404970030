import { createAction } from '@reduxjs/toolkit';

import { IGetSecurityEventsParams, IGetSecurityEventsResponse } from 'types';

export const getSecurityEvents = createAction<IGetSecurityEventsParams>(
  'security-events/GET_EVENTS'
);
export const setSecurityEvents = createAction<IGetSecurityEventsResponse>(
  'security-events/SET_EVENTS'
);
export const setIsLoading = createAction<boolean>('security-events/SET_IS_LOADING');
