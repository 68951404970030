// components
import { StyledCommandLineCode } from '../../../styled';
import { StyledItem } from '../../../components/List';

// hooks
import { useTranslations } from 'hooks/useTranslations';

export const LinuxDnf = () => {
  const { translate } = useTranslations('enrol-new-system');

  return (
    <>
      <StyledItem
        title={translate('linux.distros.rpm.1.title')}
        content={
          <StyledCommandLineCode
            code={`sudo dnf -y install dnf-plugins-core
sudo dnf config-manager --add-repo https://packages.enclave.io/rpm/enclave.repo
`}
          />
        }
      />
      <StyledItem
        title={translate('linux.distros.rpm.2.title')}
        content={<StyledCommandLineCode code={`sudo dnf install enclave`} />}
      />
      <StyledItem
        title={translate('linux.distros.rpm.3.title')}
        content={<StyledCommandLineCode code={`sudo enclave enrol`} />}
      />
    </>
  );
};
