// components
import { Description } from './Description';
import { ColumnTitle } from 'components';
import { PoliciesActionDropdown } from './ActionDropdown';
import { Status } from './Status';
import { PoliciesTags } from './Tags';
import { AclState } from './AclState';
import { PoliciesDirection } from './Direction';

// types
import { TFunction } from 'react-i18next/.';
import { IPolicy } from 'types';
import { translations } from 'types/translations';
import { ColumnType } from 'antd/lib/table';
import { PoliciesGateways } from './Gateways';
import { css } from 'styled-components';

interface IPoliciesCols {
  isLoading: boolean;
  translate: TFunction<translations>;
  bulkSelectedPolicies: IPolicy[];
  setBulkSelectedPolicies: (policies: IPolicy[]) => void;
  selectedPolicyId?: IPolicy['id'];
}

export const policiesCols = ({
  isLoading,
  translate,
  bulkSelectedPolicies,
  selectedPolicyId,
}: IPoliciesCols): ColumnType<IPolicy>[] => [
  {
    title: <ColumnTitle>{translate('description')}</ColumnTitle>,
    dataIndex: 'description',
    className: 'name-column',
    render: (description: IPolicy['description'], { id, isEnabled, type }: IPolicy) => {
      const isSelected =
        !!bulkSelectedPolicies.find(policy => policy.id === id) || selectedPolicyId === id;
      return (
        <Description
          description={description}
          isEnabled={isEnabled}
          state={isSelected ? 'active' : 'default'}
          isGateway={type === 'Gateway'}
          isLoading={isLoading}
        />
      );
    },
  },
  {
    title: <ColumnTitle>{translate('state')}</ColumnTitle>,
    dataIndex: 'isEnabled',
    render: (isEnabled: IPolicy['isEnabled']) => (
      <Status
        css={css`
          min-width: 5rem;
        `}
        isEnabled={isEnabled}
        isLoading={isLoading}
      />
    ),
  },
  {
    title: <ColumnTitle>{translate('senders')}</ColumnTitle>,
    dataIndex: '',
    render: ({ senderTags, isEnabled }) => {
      return <PoliciesTags tags={senderTags} isEnabled={isEnabled} isLoading={isLoading} />;
    },
  },
  {
    dataIndex: '',
    render: ({ state, senderTags, receiverTags, gateways, type, isEnabled }: IPolicy) => (
      <PoliciesDirection
        state={state}
        senderTags={senderTags}
        receiver={
          type === 'General'
            ? {
                type: 'tags',
                data: receiverTags,
              }
            : {
                type: 'gateways',
                data: gateways,
              }
        }
        isEnabled={isEnabled}
        isLoading={isLoading}
      />
    ),
  },
  {
    title: <ColumnTitle>{translate('acl')}</ColumnTitle>,
    dataIndex: '',
    render: ({ state, acls }: IPolicy) => (
      <AclState state={state} acls={acls} isLoading={isLoading} />
    ),
  },
  {
    title: <ColumnTitle>{translate('receivers')}</ColumnTitle>,
    dataIndex: '',
    render: ({ receiverTags, gateways, gatewayAllowedIpRanges, isEnabled, type }: IPolicy) => {
      if (type == 'Gateway') {
        return (
          <PoliciesGateways
            gateways={gateways}
            gatewayAllowedIps={gatewayAllowedIpRanges}
            isEnabled={isEnabled}
            isLoading={isLoading}
          />
        );
      } else {
        return <PoliciesTags tags={receiverTags} isEnabled={isEnabled} isLoading={isLoading} />;
      }
    },
  },
  {
    title: '',
    dataIndex: '',
    key: 'x',
    className: 'action-column',
    render: (policy: IPolicy) => <PoliciesActionDropdown record={policy} isLoading={isLoading} />,
  },
];
