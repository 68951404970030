// libs
import { put, select } from 'typed-redux-saga';

// actions
import { updateEnrolmentKeyState } from 'redux/actions/enrolment-keys';

// selectors
import { selectAppDetailsTabData, selectAppDetailsTabType } from 'redux/selectors';

// actions
import { setDetailsContent, pushNotification } from 'redux/actions/app';

// utils
import { ApiTypeCaller } from '../../api/utils';
import { translator } from 'utils';

// types
import {
  IActionPayload,
  INotificationType,
  IEnrolmentStoredKey,
  IEnableUntilEnrolmentKeyProps,
  EnableUntilType,
} from 'types';

export function* enableUntilEnrolmentKeySaga({
  payload: { enrolmentKeyId, updatedParams, text, action },
}: IActionPayload<IEnableUntilEnrolmentKeyProps & EnableUntilType>) {
  const keyDetails: IEnrolmentStoredKey = yield select(selectAppDetailsTabData);
  const type = yield* select(selectAppDetailsTabType);

  const { result, success } = yield* ApiTypeCaller('enableEnrolmentKeyUntil', {
    enrolmentKeyId,
    updatedParams,
  });

  const translate = translator('notifications');

  if (success && result && result.id !== undefined) {
    if (type === 'enrolment-key-view' && result.id === keyDetails.id) {
      yield put(setDetailsContent({ type: 'enrolment-key-view', data: result }));
    }

    yield put(updateEnrolmentKeyState(result));
    yield put(
      pushNotification({
        showType: INotificationType.Success,
        description: translate(
          action === 'EnableFor' ? 'enrolment_key_enabled_for' : 'enrolment_key_disabled_after',
          {
            time: text,
          }
        ),
      })
    );
  }
}
