// Lib
import { put } from 'typed-redux-saga';

// Utils
import { ApiTypeCaller } from '../../api/utils';

// Types
import { IActionPayload, IGetBillingPortalParams } from 'types';

// Actions
import { setBillingPortalUrl } from 'redux/actions/payment';

export function* getBillingPortalSaga({ payload }: IActionPayload<IGetBillingPortalParams>) {
  const { result, success } = yield* ApiTypeCaller('getBillingPortal', payload);
  if (success && result) yield put(setBillingPortalUrl(result.redirectUrl));
}
