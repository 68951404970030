// libs
import { css, useTheme } from 'styled-components';

// components
import { CustomText } from 'typography/Text';
import { Menu } from 'antd';

const { Item } = Menu;

interface IMenuItem {
  text?: string;
  onClick?: () => void;
  color?: string;
}
export const MenuItem = ({ text, onClick, color, ...rest }: IMenuItem) => {
  const {
    palette: {
      fonts: { bodyLight },
    },
  } = useTheme();

  return (
    <Item
      {...rest}
      css={css`
        background: transparent;
        padding: 0.5625rem 4.375rem 0.5625rem 0.75rem;
        :hover {
          background-color: ${({ theme }) => theme.palette.background.iconBackground};
        }
      `}
      onClick={() => {
        onClick?.();
      }}
    >
      <CustomText type="body-2" color={color || bodyLight}>
        {text}
      </CustomText>
    </Item>
  );
};
