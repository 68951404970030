// Lib
import { takeEvery } from 'redux-saga/effects';
import { enrolAuthority } from '../../../actions/authority';

import { enrolAuthoritySaga } from './enrollAuthoritySaga';

function* authoritySaga() {
  yield takeEvery(enrolAuthority, enrolAuthoritySaga);
}

export default authoritySaga;
