// libs
import { css } from 'styled-components';

// components
import { CustomButton, ExternalLink } from 'components';
import { StyledItem, EnrolmentKeySelect, GoToSystems } from '../components';
import { WindowsLink, DownloadLink } from '../styled';
import { Trans } from 'react-i18next';
import { CustomText } from 'typography/Text';

// hooks
import { useTranslations } from 'hooks/useTranslations';

// constants
import { LINK_TO_WINDOWS_UNATTENDED_SETUP_DOCS } from 'appConstants';

interface IWindows {
  link?: string;
}
export const Windows = ({ link }: IWindows) => {
  const { translate } = useTranslations('enrol-new-system');

  return (
    <>
      <StyledItem
        title={translate('windows.steps.1.title')}
        titleId="label-select-new-system-enrolment-key"
        hint={translate('windows.steps.1.hint')}
        content={
          <>
            <WindowsLink target="_blank" href={link}>
              <CustomButton kind="primary" size="large">
                {translate('windows.steps.1.button')}
              </CustomButton>
            </WindowsLink>
            <DownloadLink>{link}</DownloadLink>

            <CustomText
              type="hint"
              css={css`
                white-space: pre;
                margin-top: 0.5rem;
                display: block;
              `}
            >
              <Trans
                i18nKey={translate('windows.steps.1.text')}
                components={[
                  <ExternalLink
                    key={0}
                    textType="hint"
                    href={LINK_TO_WINDOWS_UNATTENDED_SETUP_DOCS}
                  />,
                ]}
              />
            </CustomText>
          </>
        }
      />
      <EnrolmentKeySelect />
      <GoToSystems />
    </>
  );
};
