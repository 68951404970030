// libs
import React, { forwardRef, Ref, useState } from 'react';
import { css, CSSProp, useTheme } from 'styled-components';

// components
import { DropdownContainer, IconsContainer, SelectContainer, selectCss } from './styled';
import { Tooltip } from 'components';
import { ITooltip } from 'components/Tooltip';
import { Select as SelectAntd } from 'antd';
import { CustomText } from 'typography/Text';
import { IconCheck, IconDown, IconInputError } from 'assets/svg';

// types
import { RefSelectProps, SelectProps } from 'antd/lib/select';

const { Option } = SelectAntd;

export interface ISearchSelect<T>
  extends SelectProps<React.ReactText | React.ReactText[] | undefined> {
  dataSource: T[];
  valueField: string | string[];
  keyField: string;
  titleField?: string;
  OptionElement: (record: T, index: number, isSelected: boolean) => React.ReactElement;
  defaultValueFieldValue?: string | number;
  setSelectedValue: (selectedValue: T) => void;
  setSearchValue?: (searchValue: string) => void;
  allowOptionWrap?: boolean;
  width?: string;
  fullWidth?: boolean;
  height?: string;
  placeholder?: string;
  notFoundContent?: React.ReactElement;
  alternativeDropdown?: React.ReactElement;
  shownSelectClassSelector?: string;
  shownDropdownClassSelector?: string;
  showSearch?: boolean;
  defaultKey?: React.ReactText;
  showSelectedOptionInDropdown?: boolean;
  additionalCss?: CSSProp;
  containerCss?: CSSProp;
  suffix?: React.ReactNode | null;
  error?: { message: string } | null;
  tooltipProps?: Partial<ITooltip>;
  optionOverflow?: string;
  fieldIsDisabled?: (field: T) => boolean;
}

const SearchSelect = <T extends Record<string, unknown>>(
  {
    width,
    height,
    dataSource,
    valueField,
    titleField,
    defaultValueFieldValue,
    keyField,
    allowOptionWrap,
    OptionElement,
    placeholder,
    notFoundContent,
    setSearchValue,
    setSelectedValue,
    shownSelectClassSelector,
    shownDropdownClassSelector,
    alternativeDropdown,
    additionalCss,
    showSelectedOptionInDropdown = true,
    showSearch = false,
    suffix,
    error,
    tooltipProps,
    containerCss,
    fullWidth = false,
    optionOverflow,
    fieldIsDisabled,
    ...rest
  }: ISearchSelect<T>,
  ref:
    | ((instance: RefSelectProps | null) => void)
    | React.RefObject<RefSelectProps>
    | null
    | undefined
) => {
  const {
    palette: { fonts },
  } = useTheme();

  const [selectedDataElementKey, setSelectedDataElementKey] = useState<undefined | React.ReactText>(
    undefined
  );
  const [selectedDataElementValue, setSelectedDataElementValue] = useState<
    undefined | React.ReactText
  >(undefined);

  return (
    <SelectContainer css={containerCss} fullWidth={fullWidth}>
      <SelectAntd
        ref={ref}
        virtual={false}
        dropdownAlign={{ offset: [0, 2] }}
        getPopupContainer={trigger => {
          return trigger?.parentElement;
        }}
        value={
          selectedDataElementValue
            ? String(selectedDataElementValue)
            : defaultValueFieldValue || defaultValueFieldValue === 0
            ? String(defaultValueFieldValue)
            : undefined
        }
        // for accessibility, otherwise required attrubute aria-expanded would not be present initially
        defaultOpen={false}
        onSelect={(selectedValue: any, optionData: any) => {
          setSelectedDataElementKey(optionData.key);
          setSelectedDataElementValue(selectedValue);

          const selectedDataSource = dataSource.find(dataSourceElement => {
            return String(dataSourceElement[keyField]) === String(optionData.key);
          });

          selectedDataSource && setSelectedValue(selectedDataSource);
        }}
        onSearch={
          showSearch
            ? searchValue => {
                setSearchValue?.(searchValue);
              }
            : undefined
        }
        notFoundContent={notFoundContent}
        menuItemSelectedIcon={rest.mode === 'multiple' && <IconCheck />}
        suffixIcon={
          <IconsContainer>
            {suffix}
            {error && (
              <Tooltip
                passedCss={css`
                  pointer-events: all;
                  display: flex;
                `}
                placement="top"
                overlay={
                  <CustomText type="body-2" color={fonts.primaryButton}>
                    {error.message}
                  </CustomText>
                }
                {...tooltipProps}
              >
                <IconInputError data-test="select-error-icon" width="1.25rem" height="1.25rem" />
              </Tooltip>
            )}
            <IconDown className="select-icon-down" />
          </IconsContainer>
        }
        css={css`
          ${selectCss({
            height,
            width,
            shownSelectClassSelector,
            error: !!error,
            isMultiple: rest.mode === 'multiple',
            allowOptionWrap,
          })}
          ${additionalCss}
        `}
        filterOption={(inputValue, option) => {
          if (String(option?.value).toLowerCase().includes(inputValue.toLowerCase())) {
            return true;
          } else return false;
        }}
        dropdownRender={originNode => {
          return (
            alternativeDropdown || (
              <DropdownContainer
                showSelectedOptionInDropdown={showSelectedOptionInDropdown}
                shownDropdownClassSelector={shownDropdownClassSelector}
                optionOverflow={optionOverflow}
              >
                {originNode}
              </DropdownContainer>
            )
          );
        }}
        showSearch={showSearch}
        placeholder={placeholder}
        {...rest}
      >
        {dataSource.map((dataElement, index) => {
          return (
            <Option
              disabled={fieldIsDisabled && fieldIsDisabled(dataElement)}
              key={dataElement[keyField] as React.ReactText}
              title={
                titleField && dataElement[titleField]
                  ? (dataElement[titleField] as string)
                  : undefined
              }
              // we are cheking search against value
              value={
                typeof valueField === 'string'
                  ? String(dataElement[valueField])
                  : valueField.reduce(
                      (accamulator, currentValue) =>
                        accamulator +
                        (dataElement[currentValue] ? ' ' + String(dataElement[currentValue]) : ''),
                      ''
                    )
              }
            >
              {OptionElement(
                dataElement,
                index,
                String(dataElement[keyField]) === String(selectedDataElementKey)
              )}
            </Option>
          );
        })}
      </SelectAntd>
    </SelectContainer>
  );
};

export const Select = forwardRef(SearchSelect) as <T extends Record<string, unknown>>(
  props: ISearchSelect<T> & { ref?: Ref<HTMLDivElement> }
) => React.ReactElement;
