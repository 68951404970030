// components
import { LocationsAccessView } from './Access/View';
import { LocationsCountriesView } from './Countries/View';
import { LocationsAccessContent } from './Access/Content';
import { LocationsCountriesContent } from './Countries/Content';
import { Empty } from '../Empty';

// hooks
import { useSelector } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';

// selectors
import { selectAppDetailsTrustRequirementCurrentLocations } from 'redux/selectors';

export const ViewContent = () => {
  const { translate } = useTranslations('trust-requirements');

  const locations = useSelector(selectAppDetailsTrustRequirementCurrentLocations);

  return (
    <>
      {locations?.length ? (
        <>
          <LocationsAccessContent>
            <LocationsAccessView />
          </LocationsAccessContent>
          <LocationsCountriesContent>
            <LocationsCountriesView />
          </LocationsCountriesContent>
        </>
      ) : (
        <Empty text={translate('details.types.PublicIp.locations_empty_state')} />
      )}
    </>
  );
};
