// libs
import { css } from 'styled-components';

// components
import { Details } from 'components';
import { FqdnView } from './View';
import { FqdnEdit } from './Edit';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { useSelector } from 'react-redux';
import { useState } from 'react';

// selectors
import { selectAppDetailsTabType } from 'redux/selectors';

export const FqdnBlock = () => {
  const type = useSelector(selectAppDetailsTabType);
  const { translate } = useTranslations('dns');
  const [visible, setVisible] = useState(false);

  return (
    <Details.KeyValue
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
      alignItems="center"
      height="2.375rem"
      css={css`
        margin-bottom: 1rem;
      `}
      descriptionCss={css`
        min-width: 0;
      `}
      title={translate('records.details.fqdn')}
    >
      {type === 'dns-record-view' && <FqdnView visilbe={visible} />}
      {(type === 'dns-record-edit' || type === 'dns-record-create') && (
        <FqdnEdit visilbe={visible} />
      )}
    </Details.KeyValue>
  );
};
