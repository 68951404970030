// components
import { Content } from './Content';

// hooks
import { useSelector } from 'react-redux';

// selectors
import { selectAppDetailsTabType } from 'redux/selectors';

export const ApproveDecline = () => {
  const type = useSelector(selectAppDetailsTabType);

  return <>{type === 'unapproved-system-view' && <Content />}</>;
};
