import styled from 'styled-components';
import { Section } from '../styled';

export const DangeZoneSection = styled(Section)``;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  :first-child {
    padding-bottom: 1.5rem;
    border-bottom: 0.0625rem solid ${({ theme }) => theme.palette.fills.strokeMedium};
  }
  :nth-child(2) {
    padding-top: 1rem;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Flex = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.media_queries.s1440} {
    flex-direction: row;
  }
`;

export const LinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  ${({ theme }) => theme.media_queries.s1440} {
    margin-left: 0.875rem;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${({ theme }) => theme.media_queries.s1440} {
    justify-content: flex-end;
  }
`;
