// components
import { CustomText } from 'typography/Text';

// hooks
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';

// selectors
import { selectAppDetailsDnsNewRecordType } from 'redux/selectors';

export const RecordTypeCreate = () => {
  const {
    palette: { fonts },
  } = useTheme();

  const recordType = useSelector(selectAppDetailsDnsNewRecordType);

  return (
    <CustomText type="body-2" color={fonts.title}>
      {recordType}
    </CustomText>
  );
};
