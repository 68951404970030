// libs
import { css } from 'styled-components';

// components
import { EmptyContainer } from 'components';
import { IconNoFoundTags } from 'assets/svg';

// hooks
import { useTranslations } from 'hooks/useTranslations';

export const EmptyTags = () => {
  const { translate } = useTranslations('dns');
  return (
    <EmptyContainer
      css={css`
        height: auto;
        margin-top: 3rem;
        padding: 0;
      `}
      titleCss={css`
        margin: 0.75rem 0 0.5rem 0;
      `}
      descriptionCss={css`
        width: 23.75rem;
        color: ${({ theme }) => theme.palette.fonts.bodyLight};
        text-align: center;
      `}
      Icon={<IconNoFoundTags />}
      primaryText={translate('records.details.tags_empty.title')}
      secondaryText={translate('records.details.tags_empty.description')}
    />
  );
};
