// libs
import { css } from 'styled-components';

// components
import { Details, InternalLink } from 'components';
import { CustomText } from 'typography/Text';

// selectors
import {
  selectAppDetailsSystemEnrolmentKeyId,
  selectAppDetailsSystemEnrolmentKeyDescription,
  selectOrgId,
  selectAppDetailsApprovedSystemEnrolmentKeyIsDeleted,
} from 'redux/selectors';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { useSelector } from 'react-redux';

export const EnrolmentKeyBlock = () => {
  const { translate } = useTranslations('systems');

  const orgId = useSelector(selectOrgId);
  const enrolmentKeyId = useSelector(selectAppDetailsSystemEnrolmentKeyId);
  const enrolmentKeyDescription = useSelector(selectAppDetailsSystemEnrolmentKeyDescription);
  const enrolmentKeyIsDeleted = useSelector(selectAppDetailsApprovedSystemEnrolmentKeyIsDeleted);

  return (
    <Details.KeyValue height="auto" title={translate('details.enrolment_key')}>
      {!enrolmentKeyIsDeleted ? (
        <InternalLink data-test="system-to-key-link" to={`/org/${orgId}/keys/${enrolmentKeyId}`}>
          {enrolmentKeyDescription}
        </InternalLink>
      ) : (
        <>
          <CustomText
            type="body-2"
            css={css`
              display: block;
              margin-bottom: 0.25rem;
            `}
          >
            {enrolmentKeyDescription}
          </CustomText>
          <CustomText type="hint">{translate('details.enrolment_key_deleted')}</CustomText>
        </>
      )}
    </Details.KeyValue>
  );
};
