// libs
import React from 'react';
import { css, CSSProp } from 'styled-components';

// components
import { Popover as AntdPopover } from 'antd';
import { PopoverProps } from 'antd/lib/popover';
import { Container } from './styled';

export interface IPopover extends PopoverProps {
  css?: CSSProp;
  popoverCss?: CSSProp;
  children: React.ReactNode;
  backgroundColor?: string;
  borderColor?: string;
}

export const Popover = ({
  css: passedCss,
  popoverCss,
  backgroundColor,
  borderColor,
  ...rest
}: IPopover) => {
  return (
    <Container
      backroundColor={backgroundColor}
      borderColor={borderColor}
      css={css`
        .ant-popover {
          ${popoverCss}
        }
      `}
    >
      <AntdPopover
        {...rest}
        css={css`
          ${passedCss}
        `}
        getTooltipContainer={trigger => {
          return trigger?.parentElement || trigger;
        }}
        showArrow={false}
      />
    </Container>
  );
};
