// libs
import { css } from 'styled-components';

// components
import { ActiveHoursContent } from './styled';
import { ActiveHoursView } from './View';
import { ActiveHoursEdit } from './Edit';
import { Details } from 'components';

// hooks
import { useSelector } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';

// selectors
import { selectAppDetailsTabType } from 'redux/selectors';

interface IActiveHoursBlock {
  isOpen: boolean;
}

export const ActiveHoursBlock = ({ isOpen }: IActiveHoursBlock) => {
  const { translate } = useTranslations('policies');

  const type = useSelector(selectAppDetailsTabType);

  return (
    <ActiveHoursContent isOpen={isOpen} data-test="policy-details-active-hours">
      <Details.KeyValue
        contentCss={css`
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
        `}
        css={css`
          padding: 0;
        `}
        title={translate('details.time_limitations.active_hours.title')}
      >
        {type === 'policy-view' && <ActiveHoursView />}
        {(type === 'policy-edit' || type === 'policy-create') && <ActiveHoursEdit />}
      </Details.KeyValue>
    </ActiveHoursContent>
  );
};
