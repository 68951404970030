// libs
import { memo } from 'react';

// components
import Skeleton from 'react-loading-skeleton';

// types
import { TagReference } from 'types';
import { TagsList } from 'components/Tags/TagList';

interface ITags {
  isLoading: boolean;
  tags: TagReference[];
}

export const Tags = memo(({ tags, isLoading }: ITags) => {
  return <>{isLoading ? <Skeleton /> : <TagsList tags={tags} />}</>;
});
