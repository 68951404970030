// Components
import { EmptyContainer } from 'components/NoDataFound/EmptyContainer/index';
import { Icon404 } from 'assets/svg';
import { CustomButton } from 'components';

// Hooks
import { useTranslations } from 'hooks/useTranslations';
import { useGoBack } from 'hooks/useGoBack';

export const Page404 = () => {
  const { translate } = useTranslations('global');

  const goBack = useGoBack();

  return (
    <EmptyContainer
      Icon={<Icon404 />}
      primaryText={translate('404.title')}
      secondaryText={translate('404.subtitle')}
      additionalText={<CustomButton onClick={goBack}>{translate('404.back_button')}</CustomButton>}
      transparent
    />
  );
};
