// libs
import styled, { css } from 'styled-components';

// components
import { IconDnsRecordDetailsOverviewSection } from 'assets/svg';
import { Details } from 'components';
import { ZoneBlock, FqdnBlock, RecordType, NotesBlock } from '../../Blocks';

// hooks
import { useTranslations } from 'hooks/useTranslations';

const OverviewSection = styled.section`
  display: flex;
  flex-direction: column;
`;

export const Overview = () => {
  const { translate } = useTranslations('dns');

  return (
    <OverviewSection>
      <Details.SectionHeader
        Icon={IconDnsRecordDetailsOverviewSection}
        title={translate('records.details.overview')}
        css={css`
          margin-bottom: 1.625rem;
        `}
      />
      <ZoneBlock />
      <FqdnBlock />
      <RecordType />
      <NotesBlock />
    </OverviewSection>
  );
};
