import { CustomBadge } from 'components';
import { useTheme } from 'styled-components';
import { CustomText } from 'typography/Text';
import { useTranslations } from 'hooks/useTranslations';

type Props = {
  isEnabled?: boolean;
};

export const Status = ({ isEnabled = true, ...props }: Props) => {
  const { translate } = useTranslations('policies');
  const {
    palette: { fonts },
  } = useTheme();
  return (
    <div {...props}>
      <CustomBadge color={isEnabled ? 'blue' : 'gray'} />
      <CustomText type="body-2" color={isEnabled ? fonts.bodyDark : fonts.disabled}>
        {isEnabled ? translate('enabled') : translate('disabled')}
      </CustomText>
    </div>
  );
};
