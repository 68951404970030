// components
import { Type } from './Type';
import { Port } from './Port';
import { Title } from '../Title';
import { Protocol } from './Protocol';
import { Description } from './Description';

// hooks
import { useTranslations } from 'hooks/useTranslations';

// types
import { IPolicyModifiedAcl } from 'types';
import { ColumnsType } from 'antd/lib/table';

export const useColumnsView = (): ColumnsType<{
  protocol: IPolicyModifiedAcl['protocol'];
  ports: IPolicyModifiedAcl['ports'];
  description: IPolicyModifiedAcl['description'];
  id: IPolicyModifiedAcl['id'];
}> => {
  const { translate } = useTranslations('policies');

  return [
    {
      title: <Title>{translate('details.acls_table.type')}</Title>,
      dataIndex: '',
      width: '20%',
      render: (_field, record) => <Type acl={record} />,
    },
    {
      title: <Title>{translate('details.acls_table.protocol')}</Title>,
      dataIndex: 'protocol',
      width: '20%',
      render: (protocol: IPolicyModifiedAcl['protocol']) => <Protocol protocol={protocol} />,
    },
    {
      title: <Title>{translate('details.acls_table.port_range')}</Title>,
      dataIndex: 'ports',
      width: '20%',
      render: (ports: string) => <Port>{ports}</Port>,
    },
    {
      title: <Title>{translate('details.acls_table.description')}</Title>,
      dataIndex: 'description',
      width: '40%',
      render: (description: string) => <Description>{description}</Description>,
    },
  ];
};
