// libs
import { css } from 'styled-components';

// components
import { Input } from 'components';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { BaseSyntheticEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// actions
import { updateTrustRequirementDetails } from 'redux/actions/app';

// selectors
import { selectAppDetailsTrustRequirementGoogleAuthorityNewDomainId } from 'redux/selectors';

// constants
import { USER_AUTHENTICATION_CONDITION_GOOGLE_DOMAIN_ID } from 'appConstants';

export const DomainIdEdit = () => {
  const dispatch = useDispatch();

  const { translate } = useTranslations('trust-requirements');

  const domainId = useSelector(selectAppDetailsTrustRequirementGoogleAuthorityNewDomainId);

  const onChange = (event: BaseSyntheticEvent) => {
    dispatch(
      updateTrustRequirementDetails({
        settings: {
          condition: {
            claim: USER_AUTHENTICATION_CONDITION_GOOGLE_DOMAIN_ID,
            value: event.target.value,
          },
        },
      })
    );
  };

  return (
    <Input
      stateMessageCss={css`
        display: none;
      `}
      placeholder={translate(
        'details.types.UserAuthentication.authorities.google.domain_id_placeholder'
      )}
      value={domainId}
      onChange={onChange}
    />
  );
};
