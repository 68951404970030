// libs
import { css } from 'styled-components';

// components
import { Input } from 'components';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { BaseSyntheticEvent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// actions
import {
  addValidationStateEntry,
  deleteValidationStateEntry,
  updateTrustRequirementDetails,
  updateValidationStateEntry,
} from 'redux/actions/app';

// selectors
import {
  selectAppDetailsTabValidationEntry,
  selectAppDetailsTrustRequirementNewOIDCAudience,
} from 'redux/selectors';

// constants
import { USER_AUTHENTICATION_CONFIGURATION_AUDIENCE } from 'appConstants';

const audienceErrorKey = 'audience';

export const AudienceEdit = () => {
  const dispatch = useDispatch();

  const { translate } = useTranslations('trust-requirements');

  const validationState = useSelector(selectAppDetailsTabValidationEntry(audienceErrorKey));
  const audience = useSelector(selectAppDetailsTrustRequirementNewOIDCAudience);

  const onChange = (event: BaseSyntheticEvent) => {
    dispatch(
      updateTrustRequirementDetails({
        settings: {
          configuration: {
            [USER_AUTHENTICATION_CONFIGURATION_AUDIENCE]: event.target.value,
          },
        },
      })
    );

    dispatch(
      updateValidationStateEntry({
        key: audienceErrorKey,
        state: {
          error: event.target.value
            ? null
            : {
                message: translate(
                  'details.types.UserAuthentication.authorities.duo.audience_empty_error'
                ),
                critical: true,
              },
        },
      })
    );
  };

  // Add validation for the audience
  useEffect(() => {
    dispatch(
      addValidationStateEntry({
        key: audienceErrorKey,
        state: {
          error: audience
            ? null
            : {
                message: translate(
                  'details.types.UserAuthentication.authorities.duo.audience_empty_error'
                ),
                critical: true,
              },
        },
      })
    );

    return () => {
      dispatch(deleteValidationStateEntry({ key: audienceErrorKey }));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, translate]);

  return (
    <Input
      stateMessageCss={css`
        display: none;
      `}
      placeholder={translate(
        'details.types.UserAuthentication.authorities.duo.audience_placeholder'
      )}
      value={audience}
      onChange={onChange}
      onBlur={() => {
        dispatch(updateValidationStateEntry({ key: audienceErrorKey }));
      }}
      showStateIcon={validationState?.updated && validationState?.state.error !== null}
      suffixMessage={validationState?.state.error?.message}
      error={
        validationState?.state.error && validationState?.updated
          ? { message: validationState.state.error.message }
          : null
      }
    />
  );
};
