// libs
import { put, select } from 'typed-redux-saga';

// actions
import { updateEnrolmentKeyState } from 'redux/actions/enrolment-keys';

// selectors
import { selectAppDetailsTabData, selectAppDetailsTabType } from 'redux/selectors';

// actions
import { setDetailsContent, pushNotification } from 'redux/actions/app';

// utils
import { ApiTypeCaller } from '../../api/utils';
import { translator } from 'utils';

// types
import {
  IActionPayload,
  IEnableEnrolmentKeyProps,
  IDisableEnrolmentKeyProps,
  INotificationType,
  IEnrolmentStoredKey,
} from 'types';

export function* enableDisableEnrolmentKeySaga(
  {
    payload: { enrolmentKeyId },
  }: IActionPayload<IEnableEnrolmentKeyProps | IDisableEnrolmentKeyProps>,
  enable = true
) {
  const keyDetails: IEnrolmentStoredKey = yield select(selectAppDetailsTabData);
  const type = yield* select(selectAppDetailsTabType);

  const { result, success } = yield* ApiTypeCaller(
    enable ? 'enableEnrolmentKey' : 'disableEnrolmentKey',
    { enrolmentKeyId }
  );

  const translate = translator('notifications');

  if (success && result && result.id !== undefined) {
    if (type === 'enrolment-key-view' && result.id === keyDetails.id) {
      yield put(setDetailsContent({ type: 'enrolment-key-view', data: result }));
    }

    yield put(updateEnrolmentKeyState(result));
    yield put(
      pushNotification({
        showType: INotificationType.Success,
        description: translate('enrolm_key_state_changed', {
          keyId: result.id,
          state: enable ? translate('enabled_lowercase') : translate('disabled_lowercase'),
        }),
      })
    );
  }
}
