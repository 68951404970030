// libs
import { css } from 'styled-components';

// components
import { CustomText } from 'typography/Text';

// hooks
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from 'styled-components';

// selectors
import { selectAppDetailsDnsRecordNewZoneName, selectDnsSelectedZone } from 'redux/selectors';

// actions
import { updateDnsRecordDetails } from 'redux/actions/app';

export const ZoneCreate = () => {
  const {
    palette: { fonts },
  } = useTheme();

  const dispatch = useDispatch();
  const zoneName = useSelector(selectAppDetailsDnsRecordNewZoneName);
  const selectedZone = useSelector(selectDnsSelectedZone);

  useEffect(() => {
    dispatch(updateDnsRecordDetails({ zoneId: selectedZone?.id, zoneName: selectedZone?.name }));
  }, [dispatch, selectedZone]);

  return (
    <CustomText
      css={css`
        display: block;
      `}
      ellipsis={true}
      title={zoneName}
      type="body-2"
      color={fonts.title}
    >
      {zoneName}
    </CustomText>
  );
};
