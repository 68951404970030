// components
import { CustomText } from 'typography/Text';

// hooks
import { useTheme } from 'styled-components';
import { useSelector } from 'react-redux';

// selectors
import { selectAppDetailsTrustRequirementOIDCAuthorityUri } from 'redux/selectors';

export const AuthorityUriView = () => {
  const {
    palette: { fonts },
  } = useTheme();

  const authorityUri = useSelector(selectAppDetailsTrustRequirementOIDCAuthorityUri);

  return (
    <CustomText type="body-2" color={fonts.title}>
      {authorityUri}
    </CustomText>
  );
};
