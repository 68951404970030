// libs
import { css } from 'styled-components';

// components
import { CustomTable } from 'components';
import { ViewContainer } from './styled';

// hooks
import { useColumnsView } from './Columns';
import { useSelector } from 'react-redux';

// selectors
import { selectAppDetailsSystemCurrentRoutes } from 'redux/selectors';

export const tableCss = css`
  td {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
    vertical-align: text-top;
  }

  cursor: default;

  tbody tr {
    cursor: default;
  }
`;

export const GatewayView = () => {
  const routes = useSelector(selectAppDetailsSystemCurrentRoutes);

  const columns = useColumnsView();

  return (
    <ViewContainer>
      {routes?.length && (
        <CustomTable
          data-test="system-details-gateway-routes-table"
          withBorder={false}
          columns={columns}
          headerHeight="2.375rem"
          rowHeight="auto"
          css={tableCss}
          rowKey="id"
          dataSource={routes}
        />
      )}
    </ViewContainer>
  );
};
