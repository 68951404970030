import { useTheme } from 'styled-components';
import { CustomText } from 'typography/Text';

interface ITitle {
  children: React.ReactNode;
}

export const Title = ({ children }: ITitle) => {
  const theme = useTheme();

  return (
    <CustomText type="sub-heading-2" color={theme.palette.fonts.bodyDark}>
      {children}
    </CustomText>
  );
};
