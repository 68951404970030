// libs
import { css, CSSProp, useTheme } from 'styled-components';

// components
import { CustomTag } from 'components';
import { tagCss, TagListWrapper } from './styled';
import { CustomText } from 'typography/Text';

// hooks
import { useTranslations } from 'hooks/useTranslations';

// types
import { TagReference } from 'types';

interface ITagsProps {
  tags: TagReference[];
  width?: string;
  blocksMode?: boolean;
  customTagCss?: CSSProp;
  likeSelect?: boolean;
}

export const TagsList = ({
  tags,
  width,
  blocksMode = false,
  customTagCss,
  likeSelect = false,
  ...rest
}: ITagsProps) => {
  const { translate } = useTranslations('tags');

  const {
    palette: { fonts },
  } = useTheme();

  return tags.length ? (
    <TagListWrapper blocksMode={blocksMode} width={width} {...rest}>
      {tags &&
        tags.map(tag => (
          <CustomTag
            customTagCss={customTagCss}
            customTagBoxCss={tagCss}
            tag={tag}
            key={`tag_${tag.tag}`}
          />
        ))}
    </TagListWrapper>
  ) : likeSelect ? (
    <CustomText
      type="body-2"
      color={fonts.disabled}
      css={css`
        display: block;
        margin-left: 0.7rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
      `}
    >
      {translate('none_selected')}
    </CustomText>
  ) : (
    <CustomText type="body-2" color={fonts.bodyDark}>
      {translate('none')}
    </CustomText>
  );
};
