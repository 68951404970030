// components
import { Empty } from './Empty';
import { Suggestion } from './Suggestion';

// hooks
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// actions
import { getSystems, setSystems } from 'redux/actions/systems';

// selectors
import { selectApprovedSystems } from 'redux/selectors';

// constants
import { initialSystemsResponse } from 'redux/reducers/systems';

// types
import { ISearchKey } from 'types';
import { IRange, ISearchQueryDetail } from '../helpers';
import { IInputArrowConfig, ISelectedIndex } from '..';

// utils
import { debounce } from 'utils';

interface ISystemSuggestions {
  keyName: ISearchKey['name'];
  canHaveMultiple: ISearchKey['canHaveMultiple'];
  existingValues: ISearchQueryDetail['values'];
  searchValue: string | null;
  addValue: (newValue: string) => void;
  setInputArrowNavigationConfig: (config: IInputArrowConfig) => void;
  selectedIndex: ISelectedIndex;
  range: IRange | null;
}

const SEARCH_FIELD = 'description';

export const SystemSuggestions = ({
  searchValue,
  keyName,
  addValue,
  canHaveMultiple,
  existingValues,
  setInputArrowNavigationConfig,
  selectedIndex,
  range,
}: ISystemSuggestions) => {
  const dispatch = useDispatch();

  const systems = useSelector(selectApprovedSystems);

  // making sure not to show selected values in suggestions
  const filteredSystems = useMemo(() => {
    return canHaveMultiple
      ? systems.filter(system => {
          if (
            !system[SEARCH_FIELD] ||
            existingValues.some(existingValue => existingValue.value === system[SEARCH_FIELD])
          ) {
            return false;
          }
          return true;
        })
      : systems.filter(system => {
          if (!system[SEARCH_FIELD]) {
            return false;
          }
          return true;
        });
  }, [canHaveMultiple, existingValues, systems]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchSystems = useCallback(
    debounce((newSearchValue: string) => {
      dispatch(
        getSystems({
          search: newSearchValue,
          syncQueryParams: false,
        })
      );
    }, 250),
    []
  );

  useEffect(() => {
    searchValue !== null && searchSystems(searchValue);
  }, [dispatch, searchSystems, searchValue]);

  useEffect(() => {
    return () => {
      dispatch(setSystems(initialSystemsResponse));
    };
  }, [dispatch]);

  // for navigation input with UP and DOWN arrows
  useEffect(() => {
    setInputArrowNavigationConfig({
      length: filteredSystems.length,
      onEnter: () => {
        if (selectedIndex !== null) {
          const value = filteredSystems?.[selectedIndex]?.[SEARCH_FIELD];

          value && addValue(value);
        }
      },
      isKeyInactive: false,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIndex, setInputArrowNavigationConfig, range, filteredSystems.length]);

  return (
    <>
      {filteredSystems.length ? (
        filteredSystems.map((system, index) => {
          const value = system[SEARCH_FIELD];

          if (!value) {
            return null;
          }

          return (
            <Suggestion
              isSelected={index === selectedIndex}
              key={system.systemId}
              keyName={keyName}
              value={value}
              onClick={() => {
                const value = system[SEARCH_FIELD];
                value && addValue(value);
              }}
            />
          );
        })
      ) : (
        <Empty searchKeyName={keyName} />
      )}
    </>
  );
};
