// libs
import styled from 'styled-components';

export const DnsSection = styled.section<{ changed?: boolean }>`
  width: ${({ theme }) => theme.layout_sizes.detailsWide};
  background-color: ${({ changed, theme }) => changed && theme.palette.background.base};

  padding-top: 1.5rem;
  margin-top: -1.5rem;

  margin-bottom: -2.5rem;
  padding-bottom: 2.5rem;

  padding-left: 2.5rem;
  margin-left: -2.5rem;

  padding-right: 2.5rem;
  margin-right: -2.5rem;
`;
