// libs
import { ChangeEvent, useCallback, useState } from 'react';
import { css, useTheme } from 'styled-components';

// components
import { IconAccountCreatePATokenModal } from 'assets/svg';
import { CustomButton, CustomButtonLink, CustomMessage, Input } from 'components';
import { Loader } from 'components/Loader';
import { ModalFooter, ModalHeader, ModalScrollablePart } from 'components/Modal';
import { CustomText } from 'typography/Text';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { useDispatch, useSelector } from 'react-redux';
import { useSubmitOnEnter } from 'hooks/useSubmitOnEnter';

// actions
import { clearErrors, clearFormError, setModalContent } from 'redux/actions/app';
import { createAccessToken } from 'redux/actions/account';

// selectors
import { selectFormError, selectModalIsLoading } from 'redux/selectors';

// constants
import {
  ACCOUNT_CREATE_ACCESS_TOKEN_ERROR_FIELD,
  ACCOUNT_CREATE_ACCESS_TOKEN_WARNING_ERROR_FIELD,
} from 'appConstants';

export const CreatePATokenModal = () => {
  const dispatch = useDispatch();

  const { translate } = useTranslations('account');

  const {
    palette: { fonts },
  } = useTheme();

  const [description, setDescription] = useState('');

  const isLoading = useSelector(selectModalIsLoading);
  const inputError = useSelector(selectFormError(ACCOUNT_CREATE_ACCESS_TOKEN_ERROR_FIELD));
  const warningError = useSelector(
    selectFormError(ACCOUNT_CREATE_ACCESS_TOKEN_WARNING_ERROR_FIELD)
  );

  const isButtonEnabled = !isLoading;

  const onClose = () => {
    dispatch(clearErrors());
    dispatch(setModalContent({ type: null, data: null }));
  };

  const onChangeDescription = (e: ChangeEvent<HTMLInputElement>) => {
    if (inputError) dispatch(clearFormError(ACCOUNT_CREATE_ACCESS_TOKEN_ERROR_FIELD));
    setDescription(e.target.value);
  };

  const onCreatePAToken = useCallback(() => {
    if (inputError || warningError) dispatch(clearErrors());
    dispatch(createAccessToken({ description }));
  }, [inputError, warningError, dispatch, description]);

  useSubmitOnEnter({ callback: isButtonEnabled ? onCreatePAToken : () => {} });

  return (
    <>
      <ModalHeader
        title={translate('create_access_token_modal.title')}
        icon={<IconAccountCreatePATokenModal width="2.5rem" height="2.5rem" />}
        withCloseButton={true}
        onClose={onClose}
      />

      <ModalScrollablePart>
        {warningError && (
          <CustomMessage
            type="error"
            message={warningError}
            withIcon
            css={css`
              margin-bottom: 1rem;
              width: auto;
            `}
          />
        )}
        <CustomText
          type="body-1"
          color={fonts.bodyLight}
          css={css`
            display: block;
            margin-bottom: 2rem;
          `}
        >
          {translate('create_access_token_modal.desc')}
        </CustomText>
        <Input
          containerCss={css`
            margin-bottom: 0.75rem;
          `}
          type="text"
          name="description"
          value={description}
          maxLength={128}
          placeholder={translate('create_access_token_modal.desc_placeholder')}
          onChange={onChangeDescription}
          label={translate('create_access_token_modal.add_a_desc')}
          error={{ message: inputError || '' }}
          showStateIcon={!!description.length || !!inputError}
        />
      </ModalScrollablePart>

      <ModalFooter>
        <CustomButtonLink size="large" onClick={onClose}>
          {translate('cancel')}
        </CustomButtonLink>
        <CustomButton
          size="large"
          width="6.125rem"
          kind={isButtonEnabled ? 'primary' : 'disabled'}
          onClick={onCreatePAToken}
        >
          {isLoading ? <Loader /> : translate('create_access_token_modal.create')}
        </CustomButton>
      </ModalFooter>
    </>
  );
};
