// libs
import { notification } from 'antd';

// components
import { CustomText } from 'typography/Text';
import {
  IconNotificationSuccessCircle,
  IconNotificationWarningCircle,
  IconNotificationErrorTriangle,
  IconNotificationInfoCircle,
  IconNotificationClose,
} from 'assets/svg';

// hooks
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { css, useTheme } from 'styled-components';

// actions
import { cleanNotifications } from 'redux/actions/app';

// selectors
import { selectNotificationData, selectNotificationIsLocked } from 'redux/selectors';

// types
import { INotificationType } from 'types';
import { ArgsProps } from 'antd/lib/notification';

// constants
import { NOTIFICATION_MESSAGE_DURATION, NOTIFICATION_POSITION } from 'appConstants';

// utils
import { equals } from 'ramda';

export const useNotification = () => {
  const dispatch = useDispatch();
  const notifications = useSelector(selectNotificationData, equals);
  const isLocked = useSelector(selectNotificationIsLocked);

  const {
    palette: { fills, state, fonts },
  } = useTheme();

  const configs: Record<
    INotificationType,
    { borderColor: string; Icon: React.ReactElement; action: (args: ArgsProps) => void }
  > = useMemo(() => {
    return {
      [INotificationType.Error]: {
        action: notification.error,
        borderColor: state.error,
        Icon: <IconNotificationErrorTriangle width={20} height={20} />,
      },
      [INotificationType.Warning]: {
        action: notification.warning,
        borderColor: state.alert,
        Icon: <IconNotificationWarningCircle width={20} height={20} />,
      },
      [INotificationType.Success]: {
        action: notification.success,
        borderColor: state.success,
        Icon: <IconNotificationSuccessCircle width={20} height={20} />,
      },
      [INotificationType.Info]: {
        action: notification.info,
        borderColor: fills.primary,
        Icon: <IconNotificationInfoCircle width={20} height={20} />,
      },
    };
  }, [fills.primary, state.alert, state.error, state.success]);

  const popNotifications = useCallback(() => {
    notifications?.map(({ showType, message, description, ...rest }) => {
      const config = showType && configs[showType];
      config &&
        config.action({
          message:
            typeof message === 'string' ? (
              <CustomText
                type="sub-heading-2"
                color={fonts.bodyDark}
                css={css`
                  overflow-wrap: anywhere;
                `}
              >
                {message}
              </CustomText>
            ) : (
              message
            ),
          placement: NOTIFICATION_POSITION,
          duration: NOTIFICATION_MESSAGE_DURATION,

          description:
            typeof description === 'string' ? (
              <CustomText
                type="body-2"
                color={fonts.bodyDark}
                css={css`
                  overflow-wrap: anywhere;
                `}
              >
                {description}
              </CustomText>
            ) : (
              description
            ),
          style: {
            display: 'flex',
            flexDirection: 'row',
            borderLeft: `0.1875rem solid ${config.borderColor}`,
            borderRadius: '0.25rem',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '0.5625rem 3rem 0.5625rem 3.25rem',
            maxWidth: '40rem',
            minWidth: '24rem',
            width: 'fit-content',
          },
          icon: config.Icon,
          closeIcon: <IconNotificationClose />,

          ...rest,
        });
    });
    if (notifications?.length) {
      dispatch(cleanNotifications());
    }
  }, [configs, dispatch, fonts.bodyDark, notifications]);

  useEffect(() => {
    if (!isLocked) {
      popNotifications();
    }
  }, [isLocked, popNotifications]);
};
