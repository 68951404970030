import styled, { css, CSSProp } from 'styled-components';
import { stylesMap } from 'typography/Text';
export const inherentTabsCss = ({ tabcss }: { tabcss?: CSSProp }) => css`
  .ant-tabs-nav {
    margin: 0;
    ::before {
      display: none;
    }
  }

  .ant-tabs-ink-bar {
    height: 0.125rem !important;
    background-color: ${({ theme }) => theme.palette.fills.primary};
  }

  .ant-tabs-tab {
    border-bottom: 0.125rem solid transparent;

    :not(:first-child) {
      margin-left: 1rem !important;
    }

    margin: 0;

    .text {
      color: ${({ theme }) => theme.palette.fonts.bodyLight};
    }
    .iconContainer svg path {
      fill: ${({ theme }) => theme.palette.icons.disabled};
    }
    .badge {
      background-color: ${({ theme }) => theme.palette.background.iconBackground};
    }

    .title {
      color: ${({ theme }) => theme.palette.fonts.bodyLight};
      ${stylesMap['body-2']};
    }

    .title-active {
      color: ${({ theme }) => theme.palette.fonts.title};
      ${stylesMap['body-2']};
      font-weight: bold;
      text-shadow: none;
    }

    &.ant-tabs-tab-active {
      transition: border-bottom 0.5s ease-out;
    }

    &:not(.ant-tabs-tab-active) {
      :hover {
        border-color: ${({ theme }) => theme.palette.fills.strokeDark};

        .text {
          color: ${({ theme }) => theme.palette.fills.primaryHover};
        }
        .iconContainer svg path {
          fill: ${({ theme }) => theme.palette.icons.secondary};
        }
      }

      :focus-within {
        border-color: ${({ theme }) => theme.palette.fills.strokeDark};

        .title:not(.title-active) {
          color: ${({ theme }) => theme.palette.fonts.bodyLight};
          ${stylesMap['sub-heading-2']};
          letter-spacing: 0.03125rem;
        }

        .iconContainer svg path {
          fill: ${({ theme }) => theme.palette.fills.primaryHover};
        }
        .badge {
        }
      }
    }
    ${tabcss}
  }
`;

export const IconContainer = styled.div<{ isSelected: boolean }>`
  display: flex;
  svg path {
    fill: ${props => props.isSelected && props.theme.palette.icons.primary} !important;
  }
`;

export const TabPaneContainer = styled.div<{ width?: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: ${props => props.width};
  padding-right: 0.5rem;
`;

export const Badge = styled.div`
  margin-left: 0.5rem;
  padding: 0.0625rem 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  background-color: ${({ theme }) => theme.palette.fills.primary} !important;
`;
