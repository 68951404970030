// Lib
import { put, all } from 'typed-redux-saga';

// Actions
import { setDetailsContent } from 'redux/actions/app';

// Types
import {
  IActionPayload,
  ILoadUnapprovedSystemDetails,
  IDetailsUnapprovedSystemView,
  IDetailsUnapprovedSystemEdit,
} from 'types';

// Utils
import { ApiTypeCaller } from '../../api/utils';
import { SYSTEM_EDIT_INITIAL_VALIDATION_STATE } from 'appConstants';

// constants

export function* loadSystemDetailsSaga({
  payload: { systemId, mode = 'view' },
}: IActionPayload<ILoadUnapprovedSystemDetails>) {
  const { result, success } = yield* ApiTypeCaller('getUnapprovedSystem', { systemId });

  if (success && result) {
    const tabData: IDetailsUnapprovedSystemView | IDetailsUnapprovedSystemEdit =
      mode === 'edit'
        ? {
            type: 'unapproved-system-edit',
            data: result,
            dataNew: result,
            isLoading: false,
            validationState: SYSTEM_EDIT_INITIAL_VALIDATION_STATE,
          }
        : { type: 'unapproved-system-view', data: result, dataNew: null, isLoading: false };

    yield all([put(setDetailsContent(tabData))]);
  }
}
