// libs
import { SyntheticEvent, useCallback, memo, useMemo, useEffect } from 'react';

// hooks
import { useDispatch, useSelector } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';

// components
import { CustomTable, TableSelectionContainer } from 'components';
import { policiesCols } from '../Columns';

// utils
import { emptyPolicies } from '../helpers';
import { equals } from 'ramda';

// actions
import { redirectControl } from 'redux/actions/app';

// selectors
import { selectPolicies } from 'redux/selectors';

// Types
import { IPolicy } from 'types';
import { TableProps } from 'antd/lib/table';

// constants
import { ITEMS_PER_TABLE_PAGE } from 'appConstants/ui';

interface ITable {
  total: number;
  page: number;
  setPage: (page: number) => void;
  isLoading: boolean;
  bulkSelectedPolicies: IPolicy[];
  setBulkSelectedPolicies: (bulkSelectedUSystems: IPolicy[]) => void;
  selectedPolicyId: string | undefined;
  emptyText?: React.ReactNode;
}
export const Table = memo(
  ({
    page,
    setPage,
    total,
    isLoading,
    bulkSelectedPolicies,
    setBulkSelectedPolicies,
    selectedPolicyId,
    emptyText,
  }: ITable) => {
    const dispatch = useDispatch();

    const { translate } = useTranslations('policies');
    const { translate: translateGlobal } = useTranslations('global');

    const policies = useSelector(selectPolicies);

    const onRowClick = useCallback(
      (policy: IPolicy) => {
        if (!isLoading) {
          dispatch(redirectControl({ route: `/policies/${String(policy.id)}`, routePush: true }));
        }
      },
      [dispatch, isLoading]
    );

    const columns = policiesCols({
      isLoading,
      translate,
      bulkSelectedPolicies,
      setBulkSelectedPolicies,
      selectedPolicyId,
    });

    const rowSelectionRender = useCallback(
      (_checked: boolean, _record: IPolicy, _index: number, originNode: React.ReactNode) => (
        <TableSelectionContainer
          data-test="policies-table-selection"
          onClick={(event: SyntheticEvent) => event.stopPropagation()}
        >
          {originNode}
        </TableSelectionContainer>
      ),
      []
    );

    const rowSelection: TableProps<IPolicy>['rowSelection'] = useMemo(
      () => ({
        checkStrictly: true,
        onChange: (_, selectedSystems: IPolicy[]) => {
          if (!isLoading) {
            setBulkSelectedPolicies(selectedSystems);
          }
        },
        selectedRowKeys: bulkSelectedPolicies.map(e => e.id),
        renderCell: rowSelectionRender,
        //@ts-ignore
        getCheckboxProps: () => ({
          'aria-label': translateGlobal('aria_labels.select_row'),
        }),
      }),
      [
        bulkSelectedPolicies,
        isLoading,
        rowSelectionRender,
        setBulkSelectedPolicies,
        translateGlobal,
      ]
    );

    // when we bulk select element and then remove it through action dropdown
    // bulkSelected elements doesn't update without this useEffect
    useEffect(() => {
      const newBulkSelectedPolicies = bulkSelectedPolicies.filter(bulkSelectedPolicy =>
        policies.some(policy => policy.id === bulkSelectedPolicy.id)
      );
      if (!equals(newBulkSelectedPolicies, bulkSelectedPolicies)) {
        setBulkSelectedPolicies(newBulkSelectedPolicies);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [policies]);

    return (
      <CustomTable
        data-test="policies-table"
        locale={{ emptyText }}
        headerHeight="2.5rem"
        rowHeight="3.375rem"
        rowKey="id"
        columns={columns}
        dataSource={isLoading && !policies.length ? emptyPolicies : policies}
        onRowClick={onRowClick}
        elevated={true}
        selectedRowKey={selectedPolicyId}
        rowSelection={rowSelection}
        pagination={{
          showSizeChanger: false,
          hideOnSinglePage: true,
          pageSize: ITEMS_PER_TABLE_PAGE,
          position: ['bottomCenter'],
          total: total,
          defaultCurrent: page + 1,
          onChange: page => {
            setPage(page - 1);
          },
        }}
      />
    );
  },
  equals
);
