// Types
import { ITabsIcon } from 'components/TabButtons/IconTabPane';
import { OSType } from 'types';

// Components
import {
  IconWindowsOS,
  IconLinuxOS,
  IconMacOS,
  IconDocker,
  IconKubernetes,
  IconAndroidOS,
  IconIosOS,
} from 'assets/svg';

export const tabs: ITabsIcon[] = [
  {
    type: 'icon',
    icon: <IconWindowsOS />,
    key: OSType.Windows,
    title: OSType.Windows,
    width: '6.75rem',
  },
  {
    type: 'icon',
    icon: <IconLinuxOS />,
    key: OSType.Linux,
    title: OSType.Linux,
    width: '5.0625rem',
  },
  {
    type: 'icon',
    icon: <IconMacOS />,
    key: OSType.MacOS,
    title: OSType.MacOS,
    width: '5.6875rem',
  },
  {
    type: 'icon',
    icon: <IconAndroidOS />,
    key: OSType.Android,
    title: OSType.Android,
    width: '6rem',
  },
  {
    type: 'icon',
    icon: <IconIosOS />,
    key: OSType.IOS,
    title: OSType.IOS,
    width: '5.25rem',
  },
  {
    type: 'icon',
    icon: <IconDocker />,
    key: OSType.Docker,
    title: OSType.Docker,
    width: '5.875rem',
  },
  {
    type: 'icon',
    icon: <IconKubernetes />,
    key: OSType.Kubernetes,
    title: OSType.Kubernetes,
    width: '7.75rem',
  },
];
