// components
import {
  IconTrustRequirementDetailsGenericOIDCAuthority,
  IconTrustRequirementDetailsEnclaveAuthority,
  IconTrustRequirementDetailsGithubAuthority,
  IconTrustRequirementDetailsGoogleAuthority,
  IconTrustRequirementDetailsMicrosoftAuthority,
  IconTrustRequirementDetailsDuoAuthority,
  IconTrustRequirementDetailsOktaAuthority,
} from 'assets/svg';

import ImageTrustRequirementDetailsJumpCloudAuthority from 'assets/png/TrustAuthorities/jump-cloud.png';

// types
import { ITypeTrustRequirementUserAuthenticationAuthority } from 'types';

export const Icons: Record<ITypeTrustRequirementUserAuthenticationAuthority, React.ReactNode> = {
  portal: <IconTrustRequirementDetailsEnclaveAuthority />,
  azure: <IconTrustRequirementDetailsMicrosoftAuthority />,
  google: <IconTrustRequirementDetailsGoogleAuthority />,
  github: <IconTrustRequirementDetailsGithubAuthority />,
  oidc: <IconTrustRequirementDetailsGenericOIDCAuthority />,
  duo: <IconTrustRequirementDetailsDuoAuthority />,
  okta: <IconTrustRequirementDetailsOktaAuthority />,
  jumpcloud: (
    <img
      src={ImageTrustRequirementDetailsJumpCloudAuthority}
      style={{ width: '18px', height: '18px', marginRight: '1rem' }}
    />
  ),
};
