// libs
import { css, useTheme } from 'styled-components';

// components
import { CustomButton, TagSelect, Textarea } from 'components';
import { SafeZoneSection, Row, Column } from './styled';
import { DescriptionContainer, ContentContainer } from '../styled';
import { CustomText } from 'typography/Text';

// hooks
import { useEffect, useState } from 'react';
import { useTranslations } from 'hooks/useTranslations';
import { useDispatch, useSelector } from 'react-redux';

// selectors
import { selectDnsSelectedZone } from 'redux/selectors';

// acitons
import { updateDnsZone } from 'redux/actions/dns';

// constants
import { NOTES_CHARACTERS_LIMIT } from 'appConstants';

import { TagReference } from 'types';

// utils
import { formatTime } from 'utils';
import { Label } from 'components/Textarea/styled';

export const SafeZone = () => {
  const dispatch = useDispatch();
  const { translate } = useTranslations('dns');

  const {
    palette: { fonts },
  } = useTheme();

  const selectedZone = useSelector(selectDnsSelectedZone);

  const [textareaInput, setTextareaInput] = useState<string | null>(null);
  const [selectedTags, setSelectedTags] = useState<TagReference[] | null>(null);

  useEffect(() => {
    textareaInput !== null && setTextareaInput(null);
    selectedTags !== null && setSelectedTags(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedZone]);

  useEffect(() => {
    if (textareaInput === null && selectedZone !== null) {
      setTextareaInput(selectedZone.notes);
    }
    if (selectedTags === null && selectedZone !== null) {
      setSelectedTags(selectedZone.autoDnsTags);
    }
  }, [selectedZone, textareaInput, selectedTags]);

  const areTagsEqual = (left: TagReference[], right: TagReference[]) => {
    return left.length === right.length && left.every((val, index) => val === right[index]);
  };

  const onUpdateClick = () => {
    selectedZone &&
      (textareaInput !== selectedZone.notes ||
        !areTagsEqual(selectedTags || [], selectedZone.autoDnsTags)) &&
      dispatch(
        updateDnsZone({
          zoneId: String(selectedZone.id),
          updatedParams: { notes: textareaInput, autoDnsTags: selectedTags?.map(x => x.tag) },
        })
      );
  };

  return (
    <SafeZoneSection>
      <DescriptionContainer>
        <CustomText
          type="sub-heading-1"
          color={fonts.title}
          css={css`
            margin-bottom: 0.1875rem;
            display: block;
          `}
        >
          {translate('zone_properties.safe_zone.zone_suffix')}:
        </CustomText>
        <CustomText type="body-1" color={fonts.bodyDark}>
          {selectedZone?.name}
        </CustomText>
      </DescriptionContainer>
      <ContentContainer>
        <Row>
          <Column>
            <CustomText
              type="body-2"
              color={fonts.bodyLight}
              css={css`
                display: block;
                margin-bottom: 0.1875rem;
              `}
            >
              {translate('zone_properties.safe_zone.created')}
            </CustomText>
            <CustomText type="body-2" color={fonts.bodyLight}>
              {selectedZone?.created &&
                formatTime({ date: selectedZone?.created, format: 'ddd, MMM D, YYYY h:mm A GMT' })}
            </CustomText>
          </Column>
          <Column>
            <CustomText
              type="body-2"
              color={fonts.bodyLight}
              css={css`
                margin-bottom: 0.1875rem;
                display: block;
              `}
            >
              {translate('zone_properties.safe_zone.records')}
            </CustomText>

            {selectedZone?.recordTypeCounts &&
              Object.keys(selectedZone?.recordTypeCounts).map(key => {
                return (
                  <CustomText
                    key={key}
                    css={css`
                      display: block;
                    `}
                    type="body-2"
                    color={fonts.bodyLight}
                  >
                    {selectedZone?.recordTypeCounts[key]} {key}
                  </CustomText>
                );
              })}
          </Column>
        </Row>
        <Textarea
          data-test="dns-zone-properties-textarea"
          value={textareaInput || ''}
          label={translate('zone_properties.safe_zone.notes_label')}
          passedCss={css`
            margin-bottom: 2rem;
          `}
          placeholder={translate('zone_properties.safe_zone.notes_placeholder')}
          charactersLimit={{
            characters: textareaInput?.length || 0,
            charactersLimit: NOTES_CHARACTERS_LIMIT,
          }}
          onChange={e => {
            selectedZone !== null && setTextareaInput(e.currentTarget.value);
          }}
        >
          {textareaInput}
        </Textarea>
        <Label>{translate('zone_properties.safe_zone.dns_tags_label')}</Label>
        <CustomText type="hint" color={fonts.bodyLight}>
          {translate('zone_properties.safe_zone.dns_tags_info')}
        </CustomText>
        <TagSelect
          preSelected={selectedTags || []}
          changeCallback={tags => {
            selectedZone !== null && setSelectedTags(tags);
          }}
          containerCss={css`
            margin-bottom: 2.5rem;
          `}
        />
        <CustomButton
          data-test="dns-zone-properties-update-button"
          kind="secondary"
          onClick={onUpdateClick}
        >
          {translate('zone_properties.safe_zone.update')}
        </CustomButton>
      </ContentContainer>
    </SafeZoneSection>
  );
};
