// libs
import { memo } from 'react';

// components
import { Status as CustomStatus } from '../Status';
import Skeleton from 'react-loading-skeleton';

interface IStatus {
  isLoading: boolean;
  isEnabled: boolean;
}

export const Status = memo(({ isEnabled, isLoading, ...props }: IStatus) => {
  return (
    <>
      {isLoading ? (
        <Skeleton />
      ) : (
        <CustomStatus
          data-test="policies-table-status-column"
          isEnabled={isEnabled}
          {...props}
        ></CustomStatus>
      )}
    </>
  );
});
