import { useSelector } from 'react-redux';
import { selectAccountSignInMethodIsLocal } from 'redux/selectors';
import { SettingsBlockForeign } from './ForeignSignIn';
import { SettingsBlockLocal } from './LocalSignIn';

export const SettingsBlock = () => {
  const signInMethodIsLocal = useSelector(selectAccountSignInMethodIsLocal);

  if (signInMethodIsLocal) {
    return <SettingsBlockLocal />;
  }

  return <SettingsBlockForeign />;
};
