// components
import { CustomText } from 'typography/Text';

// hooks
import { useTheme } from 'styled-components';

// types
import { IEnrolmentKey } from 'types';

interface ISystemsEnrolled {
  enrolledCount: IEnrolmentKey['enrolledCount'];
  isEnabled?: IEnrolmentKey['isEnabled'];
}

export const SystemsEnrolled = ({ enrolledCount, isEnabled = true, ...rest }: ISystemsEnrolled) => {
  const theme = useTheme();

  return (
    <CustomText
      type="body-2"
      color={isEnabled ? theme.palette.fonts.bodyDark : theme.palette.fonts.disabled}
      {...rest}
      title={String(enrolledCount)}
    >
      {enrolledCount}
    </CustomText>
  );
};
