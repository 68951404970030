// Lib
import { put } from 'typed-redux-saga';

// Utils
import { ApiTypeCaller } from '../../api/utils';

// Actions
import { updatePolicy } from 'redux/actions/policies';
import { pushNotification } from 'redux/actions/app';

// utils
import { translator } from 'utils';

// types
import { IActionPayload, IEnablePolicyProps, IDisablePolicyProps, INotificationType } from 'types';

export function* enableDisablePolicySaga(
  { payload: { policyId } }: IActionPayload<IEnablePolicyProps | IDisablePolicyProps>,
  enable = true
) {
  const { success, result } = yield* ApiTypeCaller(enable ? 'enablePolicy' : 'disablePolicy', {
    policyId,
  });

  const translate = translator('notifications');

  if (success && result) {
    yield put(updatePolicy(result));
    yield put(
      pushNotification({
        showType: INotificationType.Success,
        description: translate('policy_state_changed', {
          policyId: result.id,
          state: enable ? translate('enabled_lowercase') : translate('disabled_lowercase'),
        }),
      })
    );
  }
}
