// libs
import { css } from 'styled-components';

// components
import { Input } from 'components';

// hooks
import { useDispatch } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';
import { useCallback, useEffect, useState } from 'react';

// actions
import { updatePolicyDetailsAllowedSubnet } from 'redux/actions/app';

// types
import { BaseSyntheticEvent } from 'react';
import { IPolicyModifiedGatewayAllowedIpRange } from 'types';

interface IDescription {
  allowedIpRange: IPolicyModifiedGatewayAllowedIpRange;
}

export const Description = ({ allowedIpRange }: IDescription) => {
  const dispatch = useDispatch();

  const { translate } = useTranslations('policies');

  const [value, setValue] = useState(allowedIpRange.description || '');

  useEffect(() => {
    setValue(allowedIpRange.description || '');
  }, [allowedIpRange.description]);

  const onChange = useCallback((event: BaseSyntheticEvent) => {
    setValue(event.target.value);
  }, []);

  const onBlur = useCallback(
    (event: BaseSyntheticEvent) => {
      const newValue = event.target.value;

      dispatch(
        updatePolicyDetailsAllowedSubnet({
          id: allowedIpRange.id,
          range: {
            description: newValue,
          },
        })
      );
    },
    [allowedIpRange.id, dispatch]
  );

  return (
    <Input
      aria-label={translate('details.aria_labels.allowed_gateway_description')}
      placeholder={translate('details.allowed_gateway_description_placeholder')}
      textStyle="body-2"
      size="middle"
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      labelCss={css`
        display: none;
      `}
      stateMessageCss={css`
        display: none;
      `}
      containerCss={css`
        flex: 1;
        margin-left: 0.75rem;
      `}
    />
  );
};
