// libs
import { css } from 'styled-components';

// components
import { Details } from 'components';

// Hooks
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// actions
import { saveSystemDetails as saveUnapprovedSystemDetails } from 'redux/actions/unapproved-systems';
import { cancelEditDetails, updateValidationStateUpdates } from 'redux/actions/app';

// selectors
import { selectAppDetailsTabIsValid } from 'redux/selectors';

export const CancelSaveUnapproved = () => {
  const dispatch = useDispatch();

  const isValid = useSelector(selectAppDetailsTabIsValid);

  const handleCancel = useCallback(() => {
    dispatch(cancelEditDetails());
  }, [dispatch]);

  const handleSave = useCallback(() => {
    if (isValid) {
      dispatch(saveUnapprovedSystemDetails());
    } else {
      dispatch(updateValidationStateUpdates());
    }
  }, [dispatch, isValid]);

  return (
    <Details.CancelSave
      css={css`
        margin: 0 2.5rem;
      `}
      handleCancel={handleCancel}
      handleSave={handleSave}
    />
  );
};
