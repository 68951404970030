// libs
import { css } from 'styled-components';

// components
import { Details, CustomTag } from 'components';

// hooks
import { useSelector } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';

// selectors
import {
  selectAppDetailsTagColour,
  selectAppDetailsTagDescription,
  selectAppDetailsTagNewColour,
  selectAppDetailsTagNewDescription,
  selectAppDetailsTagRef,
} from 'redux/selectors';

// constants
import { TAGS_DEFAULT_COLOUR } from 'appConstants/tags';

export const PreviewBlockEdit = () => {
  const { translate } = useTranslations('tags');

  const colour = useSelector(selectAppDetailsTagColour);
  const newColour = useSelector(selectAppDetailsTagNewColour);
  const description = useSelector(selectAppDetailsTagDescription);
  const newDescription = useSelector(selectAppDetailsTagNewDescription);
  const tagRef = useSelector(selectAppDetailsTagRef);

  return (
    <Details.KeyValue
      contentCss={css`
        align-items: center;
      `}
      css={css`
        min-height: 3rem;
      `}
      title={translate('details.preview')}
    >
      {tagRef && description && (
        <div
          css={css`
            display: flex;
          `}
        >
          <CustomTag
            disableHoverEffect
            tag={{
              ref: tagRef,
              colour: newColour || colour || TAGS_DEFAULT_COLOUR,
              tag: newDescription !== undefined ? newDescription : description,
            }}
          />
        </div>
      )}
    </Details.KeyValue>
  );
};
