// libs
import styled from 'styled-components';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { useSelector } from 'react-redux';

// selectors
import { selectAppDetailsSystemAutoExpireAction } from 'redux/selectors';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const ExpirationActionView = () => {
  const { translate } = useTranslations('systems');

  const selectedAction = useSelector(selectAppDetailsSystemAutoExpireAction);

  return (
    <Container>
      {selectedAction === 'Delete' &&
        translate('details.auto_expire.expiration_action.description.remove')}
      {selectedAction === 'Disable' &&
        translate('details.auto_expire.expiration_action.description.disable')}
    </Container>
  );
};
