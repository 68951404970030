// components
import { Details } from 'components';
import { InputTooltip } from './InputTooltip';
import { ScrolledOnTopContext } from '../..';

// hooks
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';

// selectors
import {
  selectAppDetailsDnsRecordNewName,
  selectAppDetailsTabValidationStateEntries,
  selectFormError,
} from 'redux/selectors';

// actions
import {
  clearErrors,
  clearFormError,
  updateDnsRecordDetails,
  updateValidationStateEntry,
} from 'redux/actions/app';

// utils
import { equals } from 'ramda';

// types
import { BaseSyntheticEvent } from 'react';
import { TextAreaRef } from 'antd/lib/input/TextArea';

// constants
import { REGEX_VALID_DNS_RECORD } from 'appConstants';

const titleErrorKey = 'title';
const errorFormKey = 'name';

export const TitleEdit = () => {
  const nameError = useSelector(selectFormError(errorFormKey));

  const dispatch = useDispatch();

  const { translate } = useTranslations('dns');

  const validationState = useSelector(
    selectAppDetailsTabValidationStateEntries([titleErrorKey]),
    equals
  );
  const newName = useSelector(selectAppDetailsDnsRecordNewName);

  const [isFocused, setisFocused] = useState(true);
  const [hadError, setHadError] = useState(false);

  const ref = useRef<TextAreaRef>(null);

  const scrolledOnTop = useContext(ScrolledOnTopContext);

  useEffect(() => {
    isFocused &&
      (!!validationState?.[titleErrorKey]?.state?.error || !!nameError) &&
      setHadError(true);
  }, [isFocused, nameError, validationState]);

  useEffect(() => {
    return () => {
      dispatch(clearErrors());
    };
  }, [dispatch, nameError]);

  useEffect(() => {
    nameError &&
      dispatch(
        updateValidationStateEntry({ key: titleErrorKey, state: { error: { message: nameError } } })
      );
  }, [dispatch, nameError]);

  const onNameChange = useCallback(
    (event: BaseSyntheticEvent) => {
      const name = event.target.value;
      nameError && dispatch(clearFormError(errorFormKey));

      const isInvalid = !REGEX_VALID_DNS_RECORD.test(name) || name.includes(' ') === true;

      const isEmpty = name.length === 0;

      const error = isEmpty
        ? { message: translate('records.details.title_error_empty') }
        : isInvalid
        ? { message: translate('records.details.title_error_invalid') }
        : null;

      if (
        validationState?.[titleErrorKey]?.state?.error !== error ||
        !validationState?.[titleErrorKey]?.updated
      ) {
        dispatch(updateValidationStateEntry({ key: titleErrorKey, state: { error } }));
      }

      dispatch(updateDnsRecordDetails({ name }));
    },
    [dispatch, nameError, translate, validationState]
  );

  const onBlur = useCallback(
    (event: BaseSyntheticEvent) => {
      onNameChange(event);
      isFocused && setisFocused(false);
      hadError && setHadError(false);
    },
    [isFocused, onNameChange, hadError]
  );

  const onFocus = useCallback(() => {
    setisFocused(true);
  }, []);

  useEffect(() => {
    ref?.current?.focus();
  }, [ref]);

  return (
    <InputTooltip isVisible={hadError && isFocused && scrolledOnTop}>
      <Details.Input
        ref={ref}
        value={newName ?? ''}
        placeholder={translate('records.details.title_placeholder')}
        onChange={onNameChange}
        error={validationState?.[titleErrorKey]?.state?.error}
        onBlur={onBlur}
        onFocus={onFocus}
      />
    </InputTooltip>
  );
};
