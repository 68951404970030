// libs
import { css } from 'styled-components';

// components
import { CustomToggle } from 'components';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// selectors
import { selectAccountEmailNotif, selectAppIsLoading } from 'redux/selectors';

// actions
import { updateAccount } from 'redux/actions/account';

export const EmailNotificationToggle = () => {
  const dispatch = useDispatch();

  const isLoading = useSelector(selectAppIsLoading);
  const emailNotifStatus = useSelector(selectAccountEmailNotif);

  const { translate } = useTranslations('account');

  const [isEmailNotificationOn, setIsEmailNotificationOn] = useState<boolean | null>(null);

  const onUpdateEmail = useCallback(
    (status: boolean) => {
      dispatch(
        updateAccount({
          emailNotificationsEnabled: status,
        })
      );
      setIsEmailNotificationOn(status);
    },
    [dispatch]
  );

  const onClick = useCallback(() => {
    onUpdateEmail(!isEmailNotificationOn);
  }, [isEmailNotificationOn, onUpdateEmail]);

  const updateEmailNotificationStatus = useCallback(() => {
    setIsEmailNotificationOn(emailNotifStatus);
  }, [emailNotifStatus]);

  useEffect(() => {
    updateEmailNotificationStatus();
  }, [updateEmailNotificationStatus]);

  return (
    <CustomToggle
      css={css`
        margin-right: 0.5rem;
      `}
      aria-label={translate('aria_labels.switch_email_notifications')}
      labelLeft
      enabled={!!isEmailNotificationOn}
      loading={isLoading}
      label={isEmailNotificationOn ? 'On' : 'Off'}
      labelType="sub-heading-2"
      onClick={onClick}
    />
  );
};
