// libs
import { memo } from 'react';
import styled from 'styled-components';
import { css, useTheme } from 'styled-components';

// components
import { CustomText } from 'typography/Text';
import Skeleton from 'react-loading-skeleton';

// types
import { ITrustRequirementBasic } from 'types';

interface ISummary {
  summary: ITrustRequirementBasic['summary'];
  isLoading: boolean;
}

const SummaryContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

export const Summary = memo(({ summary, isLoading }: ISummary) => {
  const theme = useTheme();

  return (
    <>
      {isLoading ? (
        <Skeleton />
      ) : (
        <SummaryContainer>
          <CustomText
            css={css`
              overflow-wrap: anywhere;
              white-space: pre-wrap;

              display: block;
            `}
            type="body-2"
            color={theme.palette.fonts.bodyDark}
          >
            {summary}
          </CustomText>
        </SummaryContainer>
      )}
    </>
  );
});
