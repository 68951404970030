// components
import { StateApprovedView } from './ApprovedView';
import { StateApprovedEdit } from './ApprovedEdit';

// selectors
import { selectAppDetailsTabType } from 'redux/selectors';

// hooks
import { useSelector } from 'react-redux';

export const StateBlock = () => {
  const type = useSelector(selectAppDetailsTabType);

  return (
    <>
      {type === 'system-view' && <StateApprovedView />}
      {type === 'system-edit' && <StateApprovedEdit />}
    </>
  );
};
