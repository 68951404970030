import { createAction } from '@reduxjs/toolkit';
import {
  ICreateDnsZoneParams,
  IDeleteDnsRecordProps,
  IDeleteDnsRecordsProps,
  IDeleteDnsZoneProps,
  IGetDnsRecordsParams,
  IGetDnsRecordsResponse,
  IGetDnsZoneProps,
  IGetDnsZoneResponse,
  IGetDnsZonesParams,
  IGetDnsZonesResponse,
  ISearchKey,
  IUpdateDnsZoneProps,
  IZoneBasic,
  IZonesDataPagesItems,
} from 'types';

export const getDnsZones = createAction<IGetDnsZonesParams>('dns/GET_ZONES');
export const setDnsZones = createAction<IGetDnsZonesResponse>('dns/SET_ZONES');
export const createDnsZone = createAction<ICreateDnsZoneParams>('dns/CREATE_ZONE');
export const updateDnsZone = createAction<IUpdateDnsZoneProps>('dns/UPDATE_ZONE_DETAILS');
export const deleteDnsZone = createAction<IDeleteDnsZoneProps>('dns/DELETE_ZONE_DETAILS');
export const getDnsZoneDetails = createAction<IGetDnsZoneProps>('dns/GET_ZONE_DETAILS');
export const setDnsSelectedZone = createAction<IGetDnsZoneResponse | null>('dns/SET_SELECTED_ZONE');
export const addDnsZonesDataPage = createAction<{ page: number; data: IZoneBasic[] }>(
  'dns/ADD_ZONE_DATA_PAGE'
);
export const setDnsZonesDataPages = createAction<IZonesDataPagesItems>('dns/SET_ZONE_DATA_PAGE');
export const setDnsZonesShouldUpdate = createAction('dns/ZONES_SHOULD_UPDATE');

export const getDnsRecords = createAction<IGetDnsRecordsParams>('dns/GET_RECORDS');
export const setDnsRecords = createAction<IGetDnsRecordsResponse>('dns/SET_RECORDS');
export const createDnsRecord = createAction('dns/CREATE_RECORD');
export const updateDnsRecord = createAction('dns/UPDATE_RECORD');
export const deleteDnsRecord = createAction<IDeleteDnsRecordProps>('dns/DELETE_RECORD');
export const deleteDnsRecords = createAction<IDeleteDnsRecordsProps>('dns/DELETE_RECORDS');
export const setDnsRecordsShouldUpdate = createAction('dns/RECORDS_SHOULD_UPDATE');

export const setZonesIsLoading = createAction<boolean>('dns/ZONES_IS_LOADING');
export const setRecordsIsLoading = createAction<boolean>('dns/RECORDS_IS_LOADING');

export const getRecordSearchKeys = createAction('dns/GET_SEARCH_KEYS');
export const setRecordSearchKeys = createAction<ISearchKey[]>('dns/SET_SEARCH_KEYS');
