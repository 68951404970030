// libs
import styled, { css, useTheme } from 'styled-components';

// components
import { IconTrustRequirementBlocked, IconTrustRequirementRequired } from 'assets/svg';
import { CustomText } from 'typography/Text';

// hooks
import { useTranslations } from 'hooks/useTranslations';

interface IIsBlocked {
  isBlocked: boolean;
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
`;

export const IsBlocked = ({ isBlocked }: IIsBlocked) => {
  const { translate } = useTranslations('trust-requirements');

  const {
    palette: { fonts },
  } = useTheme();

  return (
    <Container>
      {isBlocked ? (
        <IconTrustRequirementBlocked width="0.75rem" height="0.75rem" />
      ) : (
        <IconTrustRequirementRequired width="0.75rem" height="0.75rem" />
      )}
      <CustomText
        css={css`
          margin-left: 0.25rem;
          overflow-wrap: anywhere;
        `}
        type="body-2"
        color={fonts.title}
      >
        {translate(`details.types.PublicIp.${isBlocked ? 'deny' : 'allow'}`)}
      </CustomText>
    </Container>
  );
};
