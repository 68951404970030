// libs
import React from 'react';

// components
import { CustomText } from 'typography/Text';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { css, useTheme } from 'styled-components';
import { useSelector } from 'react-redux';

// selectors
import {
  selectAppDetailsEnrolmentKeyAutoExpireAction,
  selectAppDetailsTabType,
} from 'redux/selectors';

const Description = () => {
  const { translate } = useTranslations('enrolment-keys');

  const type = useSelector(selectAppDetailsTabType);
  const expiryAction = useSelector(selectAppDetailsEnrolmentKeyAutoExpireAction);

  const {
    palette: { fonts },
  } = useTheme();

  return (
    <CustomText
      type="body-2"
      color={fonts.bodyLight}
      css={css`
        margin: 0 0 1rem 2rem;
      `}
    >
      {(type === 'enrolment-key-edit' || type === 'enrolment-key-create') &&
        translate('details.auto_expire.description_edit')}
      {type === 'enrolment-key-view' &&
        expiryAction === 'Delete' &&
        translate('details.auto_expire.description_view.remove')}
      {type === 'enrolment-key-view' &&
        expiryAction === 'Disable' &&
        translate('details.auto_expire.description_view.disable')}
    </CustomText>
  );
};

export default Description;
