// libs
import { css } from 'styled-components';

// components
import { StyledNavLink } from './styled';
import { CustomText } from 'typography/Text';

// types
import { BaseSyntheticEvent } from 'react';
import { NavLinkProps } from 'react-router-dom';
import { TextTypes } from 'types';

type ILinkSizes = 'medium' | 'large' | 'small';

interface ILinkProps extends NavLinkProps {
  size?: ILinkSizes;
  textType?: TextTypes;
  disabled?: boolean;
  width?: string;
  ellipsis?: boolean;
  title?: string;
  icon?: React.ReactNode;
  children?: React.ReactNode;
}

type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

const linkSizes: Record<ILinkSizes, TextTypes> = {
  small: 'hint',
  medium: 'body-2',
  large: 'body-1',
};

export const InternalLink = ({
  size = 'medium',
  textType,
  children,
  disabled,
  to = '',
  width,
  ellipsis,
  title,
  icon,
  ...rest
}: PartialBy<ILinkProps, 'to'>) => {
  const handleClick = (e: BaseSyntheticEvent) => {
    if (disabled) e.preventDefault();
  };

  return (
    <StyledNavLink
      width={width}
      to={to}
      onClick={handleClick}
      tabIndex={disabled ? -1 : 0}
      {...rest}
    >
      <CustomText
        type={textType || linkSizes[size]}
        ellipsis={ellipsis}
        title={title}
        css={css`
          ${ellipsis &&
          css`
            display: block;
          `}
        `}
      >
        {children}
      </CustomText>
      {icon}
    </StyledNavLink>
  );
};

interface IInlineLinkProps extends Omit<ILinkProps, 'children'> {
  inlineText: string;
}
export const InternalLinkInline = ({ inlineText, ...rest }: PartialBy<IInlineLinkProps, 'to'>) => (
  <InternalLink {...rest}>{inlineText}</InternalLink>
);
