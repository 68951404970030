import styled from 'styled-components';

export const SystemsListWrapper = styled.div<{ width?: string }>`
  width: ${({ width }) => width};
  display: block;
`;

export const SystemsIconContainer = styled.div`
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  border: 0.0625rem solid ${({ theme }) => theme.palette.background.iconBackground};
  padding-left: 0.5rem;
  padding-right: 0.5rem;

  :not(:last-child) {
    margin-bottom: 0.375rem;
    margin-right: 0.375rem;
  }
`;
