// components
import { CustomText } from 'typography/Text';
import { InvitationDescription, InvitationRecord } from './styled';

// hooks
import { useTheme } from 'styled-components';
import { useTranslations } from 'hooks/useTranslations';

// types
import { IAccountInvite } from 'types';

type Props = {
  invite: IAccountInvite;
};

export const InvitationDeclined = ({ invite }: Props) => {
  const { translate } = useTranslations('account');

  const {
    palette: { fonts },
  } = useTheme();

  return (
    <InvitationRecord>
      <InvitationDescription>
        <CustomText type="body-2" color={fonts.bodyLight}>
          {translate('declined_invitation', { orgName: invite.orgName })}
        </CustomText>
      </InvitationDescription>
    </InvitationRecord>
  );
};
