// libs
import { css } from 'styled-components';
import { useEffect, useRef } from 'react';

// hooks
import { useDispatch } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';
import { useState } from 'react';
import { useSelector } from 'react-redux';

// components
import { IconDetailsAutoExpire } from 'assets/svg';
import { Details } from 'components';
import { AutoExpireSection } from './styled';
import { AutoExpireTimeZoneBlock, ExpirationActionBlock, ExpiryDateBlock } from '../../Blocks';
import Description from './Description';

// selectors
import {
  selectAppDetailsPolicyAutoExpireData,
  selectAppDetailsPolicyAutoExpireScrollTo,
  selectAppDetailsPolicyId,
  selectAppDetailsTabType,
} from 'redux/selectors';

// actions
import { createPolicyDetailsAutoExpire, updatePolicyDetailsAutoExpire } from 'redux/actions/app';

// types
import { IPolicyAutoExpire } from 'types';

type IPolicyAutoExpireRememberedData = Partial<IPolicyAutoExpire> | null;

export const AutoExpire = () => {
  const dispatch = useDispatch();

  const { translate } = useTranslations('policies');

  const myRef = useRef<HTMLDivElement>(null);

  const policyId = useSelector(selectAppDetailsPolicyId);
  const type = useSelector(selectAppDetailsTabType);
  const autoExpireData = useSelector(selectAppDetailsPolicyAutoExpireData);
  const scrollToAutoExpire = useSelector(selectAppDetailsPolicyAutoExpireScrollTo);

  const [isOpen, setIsOpen] = useState(scrollToAutoExpire || false);
  const [rememberedData, setRememberedData] = useState<IPolicyAutoExpireRememberedData>(null);

  useEffect(() => {
    if (autoExpireData) {
      setIsOpen(true);
      setRememberedData(null);
    } else {
      setIsOpen(false);
    }
  }, [autoExpireData, setRememberedData]);

  useEffect(() => {
    if (isOpen && !autoExpireData) {
      if (rememberedData) {
        dispatch(updatePolicyDetailsAutoExpire({ ...rememberedData }));
      } else {
        dispatch(createPolicyDetailsAutoExpire());
      }
    }

    if (!isOpen && autoExpireData) {
      setRememberedData({ ...autoExpireData });
      dispatch(updatePolicyDetailsAutoExpire(null));
    }
  }, [dispatch, isOpen, autoExpireData, rememberedData]);

  useEffect(() => {
    return () => {
      setRememberedData(null);
      setIsOpen(false);
    };
  }, [policyId]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      scrollToAutoExpire && myRef.current?.scrollIntoView();
    });

    return () => {
      clearTimeout(timeout);
    };
  }, [scrollToAutoExpire]);

  if (type === 'enrolment-key-view' && !autoExpireData) {
    return null;
  }

  if (type === 'policy-view' && !autoExpireData) {
    return null;
  }

  return (
    <AutoExpireSection data-test="policy-details-auto-expire-section" ref={myRef}>
      <Details.SectionHeader
        Icon={IconDetailsAutoExpire}
        title={translate('details.auto_expire.title')}
        css={css`
          margin-bottom: 0.65rem;
        `}
        switchConfig={
          type === 'policy-edit' || type === 'policy-create'
            ? { value: isOpen, setValue: setIsOpen }
            : undefined
        }
      />
      <Description />
      <ExpiryDateBlock isOpen={isOpen} />
      <AutoExpireTimeZoneBlock isOpen={isOpen} />
      <ExpirationActionBlock isOpen={isOpen} />
    </AutoExpireSection>
  );
};
