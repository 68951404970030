// Lib
import { put, select } from 'typed-redux-saga';

// selectors
import { selectAppDetailsDnsRecordId } from 'redux/selectors';

// actions
import { setDnsRecordsShouldUpdate, setDnsZonesShouldUpdate } from 'redux/actions/dns';
import {
  closeDetails,
  setModalContent,
  setModalIsLoading,
  pushNotification,
} from 'redux/actions/app';

// types
import { IActionPayload, IDeleteDnsRecordProps, INotificationType } from 'types';

// Utils
import { ApiTypeCaller } from '../../api/utils';
import { translator } from 'utils';

export function* deleteRecordSaga({ payload }: IActionPayload<IDeleteDnsRecordProps>) {
  yield put(setModalIsLoading(true));

  const selectedRecordId = yield* select(selectAppDetailsDnsRecordId);

  if (selectedRecordId !== undefined && Number(payload.recordId) === selectedRecordId) {
    yield* put(closeDetails({}));
  }

  const { success, result } = yield* ApiTypeCaller('deleteDnsRecord', payload);

  if (success && result) {
    const translate = translator('notifications');

    yield put(setDnsZonesShouldUpdate());
    yield put(setDnsRecordsShouldUpdate());
    yield put(setModalContent({ type: null, data: null }));
    yield put(
      pushNotification({
        showType: INotificationType.Success,
        description: translate('dns_record_removed', { recordId: result.id, fqdn: result.fqdn }),
      })
    );
  }
  yield put(setModalIsLoading(false));
}
