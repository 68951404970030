// components
import { CustomText } from 'typography/Text';

// hooks
import { useTheme } from 'styled-components';

// types
import { IPolicyAclType } from 'types';

// utils
import { useAclTypeToText } from '../../helpers';

interface ITypeOption {
  type: IPolicyAclType;
}

export const TypeOption = ({ type, ...rest }: ITypeOption) => {
  const {
    palette: { fonts },
  } = useTheme();

  const text = useAclTypeToText(type);

  return (
    <CustomText type="body-2" color={fonts.title} {...rest}>
      {text}
    </CustomText>
  );
};
