// Libraries
import { useState, useEffect } from 'react';
import { css, useTheme } from 'styled-components';

// Components
import { CustomButton, StyledCol, StyledRow, TabButtons, TrialBanner } from 'components';
import { ToApproveTable } from './ToApproveTable';
import { EnrolledTable } from './EnrolledTable';
import { StyledHeader, CustomText } from 'typography/Text';

// Hooks
import { useTranslations } from 'hooks/useTranslations';
import { useDispatch, useSelector } from 'react-redux';

// Selectors
import { selectPathName, selectOrgId, selectCurrentOrgSystemsUnapproved } from 'redux/selectors';

// Actions
import { getCurrentOrganisation } from 'redux/actions/organisation';

// Types
import { ISystemsType } from 'types';
import { ITabs } from 'components/TabButtons';
import { useNavigate } from 'react-router';

export const Systems = () => {
  const navigate = useNavigate();
  const orgId = useSelector(selectOrgId);
  const pathName = useSelector(selectPathName);
  const [mode, setMode] = useState<ISystemsType>(
    pathName.includes('/systems') ? 'systems' : 'unapproved-systems'
  );
  const systemsUnapprovedAmount = useSelector(selectCurrentOrgSystemsUnapproved);
  const dispatch = useDispatch();
  const { translate } = useTranslations('systems');
  const {
    palette: { fonts },
  } = useTheme();

  useEffect(() => {
    if (pathName.includes('/systems') && mode !== 'systems') setMode('systems');
    if (pathName.includes('/unapproved-systems') && mode !== 'unapproved-systems') {
      setMode('unapproved-systems');
    }
  }, [pathName, mode]);

  const onChangeEnrolled = (newState: ISystemsType) => {
    navigate(`/org/${orgId}/${newState}`);
  };

  useEffect(() => {
    dispatch(getCurrentOrganisation());
  }, [dispatch]);

  const tabs: ITabs[] = [
    {
      type: 'default',
      key: 'systems',
      title: translate('enrolled'),
      titleCss: css`
        width: 3.5625rem;
      `,
    },
    {
      type: 'number',
      data: systemsUnapprovedAmount,
      dataFormat: 'show-if-more-than-0',
      key: 'unapproved-systems',
      title: translate('to_approve'),
      titleCss: css`
        width: 4.875rem;
      `,
    },
  ];
  return (
    <>
      <StyledRow justify="space-between">
        <StyledCol>
          <StyledHeader>
            <CustomText data-test="systems-header" type="heading-1" color={fonts.title}>
              {translate('systems')}
            </CustomText>
          </StyledHeader>
        </StyledCol>
        <StyledCol>
          <CustomButton
            onClick={() => {
              navigate(`/org/${orgId}/new-system`);
            }}
            type="primary"
            size="large"
          >
            {translate('enroll_new_system')}
          </CustomButton>
        </StyledCol>
      </StyledRow>
      <TrialBanner />
      <StyledRow mb="2rem">
        <StyledCol
          span={24}
          css={css`
            border-bottom: 0.0625rem solid ${({ theme }) => theme.palette.fills.strokeMedium};
          `}
        >
          <TabButtons
            data-test="systems-tabs"
            onChange={activeKey => onChangeEnrolled(activeKey as ISystemsType)}
            selectedKey={mode}
            tabCss={css`
              :not(:first-child) {
                margin-left: 1rem;
              }
            `}
            tabs={tabs}
          />
        </StyledCol>
      </StyledRow>
      {mode === 'systems' ? <EnrolledTable /> : <ToApproveTable />}
    </>
  );
};
