// libs
import { memo } from 'react';
import { css, useTheme } from 'styled-components';

// components
import { CustomText } from 'typography/Text';
import Skeleton from 'react-loading-skeleton';

// hooks
import { useTranslations } from 'hooks/useTranslations';

// utils
import { formatTime } from 'utils/timeFormatting';

// types
import { ISimpleEnrolmentKey } from 'types';

interface ILastUsed {
  lastUsed: ISimpleEnrolmentKey['lastUsed'];
  isEnabled?: ISimpleEnrolmentKey['isEnabled'];
  isLoading: boolean;
}

export const LastUsed = memo(({ lastUsed, isEnabled = true, isLoading }: ILastUsed) => {
  const {
    palette: { fonts },
  } = useTheme();

  const { translate } = useTranslations('enrolment-keys');

  const text = lastUsed ? formatTime({ date: lastUsed, format: 'ago' }) : translate('never_used');
  return (
    <>
      {isLoading ? (
        <Skeleton />
      ) : (
        <CustomText
          css={css`
            display: block;
          `}
          ellipsis={true}
          title={text || undefined}
          type="body-2"
          color={isEnabled ? fonts.bodyDark : fonts.disabled}
        >
          {text}
        </CustomText>
      )}
    </>
  );
});
