// libs
import styled from 'styled-components';

// hooks
import { useSelector } from 'react-redux';

// selectors
import { selectAppDetailsSystemAutoExpireDate } from 'redux/selectors';

// utils
import { formDateTimeViewString } from 'utils';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const ExpiryDateView = () => {
  const selectedDate = useSelector(selectAppDetailsSystemAutoExpireDate);

  return <Container>{selectedDate && formDateTimeViewString(selectedDate)}</Container>;
};
