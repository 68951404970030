// Lib
import { IAccountStore } from 'types';
import { createReducer } from '@reduxjs/toolkit';
import {
  setAccount,
  setStepUpToken,
  takeAwayAccessToken,
  set2FAQRCode,
  set2FAStatus,
  setOrganisations,
  setCurrentOrganisation,
  setInviteStatus,
  setSessionLogoutUrl,
} from '../actions/account';
import { ORG_ID_KEY } from 'appConstants/localstorage';

const initialState: IAccountStore = {
  stepUpToken: null,
  sessionLogoutUrl: null,
  qrCode: null,
  profile: {
    id: '',
    email: '',
    firstName: null,
    lastName: null,
    fullName: '',
    informalName: '',
    signInMethod: 'Local',
    twoFactorEnabled: false,
    emailNotificationsEnabled: false,
    memberSince: '',
    userName: '',
    tokens: [],
    invites: [],
  },
  orgs: [],
  currentOrg: null,
  inviteStatus: {},
};

export const accountReducer = createReducer(initialState, builder => {
  builder
    .addCase(setOrganisations, (state, { payload }) => {
      state.orgs = payload;
    })
    .addCase(setCurrentOrganisation, (state, { payload }) => {
      state.currentOrg = payload;

      if (payload) {
        localStorage.setItem(ORG_ID_KEY, payload);
      }
    })
    .addCase(set2FAStatus, (state, { payload: { twoFactorEnabled } }) => {
      state.profile.twoFactorEnabled = twoFactorEnabled;
    })
    .addCase(setAccount, (state, { payload }) => {
      state.profile = payload;
    })
    .addCase(setStepUpToken, (state, { payload }) => {
      state.stepUpToken = payload;
    })
    .addCase(takeAwayAccessToken, (state, { payload }) => {
      state.profile.tokens = state.profile.tokens
        ? state.profile.tokens.filter(token => token.reference !== payload)
        : [];
    })
    .addCase(set2FAQRCode, (state, { payload }) => {
      state.qrCode = payload;
    })
    .addCase(setInviteStatus, (state, { payload: { orgId, status } }) => {
      state.inviteStatus[orgId] = status;
    })
    .addCase(setSessionLogoutUrl, (state, { payload }) => {
      state.sessionLogoutUrl = payload;
    });
});
