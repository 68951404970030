import styled from 'styled-components';
import { stylesMap } from 'typography/Text';

export const FilterAndBulkActionsContainer = styled.div`
  height: 2.125rem;
  margin-bottom: 1rem;
`;

export const HeaderRow = styled.div`
  width: 100%;
  margin-bottom: 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CreateKey = styled.span`
  cursor: pointer;
  display: inline-block;
  color: ${({ theme }) => theme.palette.icons.primary};
  ${stylesMap['body-1']}
`;
