// libs
import { css } from 'styled-components';

// components
import { Input } from 'components';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { BaseSyntheticEvent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// actions
import {
  addValidationStateEntry,
  deleteValidationStateEntry,
  updateTrustRequirementDetails,
  updateValidationStateEntry,
} from 'redux/actions/app';

// selectors
import {
  selectAppDetailsTabValidationEntry,
  selectAppDetailsTrustRequirementNewOIDCAuthorityUri,
} from 'redux/selectors';

// constants
import { USER_AUTHENTICATION_CONFIGURATION_AUTHORITY_URI } from 'appConstants';

const authorityUriErrorKey = 'authority-uri';

export const AuthorityUriEdit = () => {
  const dispatch = useDispatch();

  const { translate } = useTranslations('trust-requirements');

  const validationState = useSelector(selectAppDetailsTabValidationEntry(authorityUriErrorKey));
  const authorityUri = useSelector(selectAppDetailsTrustRequirementNewOIDCAuthorityUri);

  const onChange = (event: BaseSyntheticEvent) => {
    dispatch(
      updateTrustRequirementDetails({
        settings: {
          configuration: {
            [USER_AUTHENTICATION_CONFIGURATION_AUTHORITY_URI]: event.target.value,
          },
        },
      })
    );

    dispatch(
      updateValidationStateEntry({
        key: authorityUriErrorKey,
        state: {
          error: event.target.value
            ? null
            : {
                message: translate(
                  'details.types.UserAuthentication.authorities.oidc.authority_uri_empty_error'
                ),
                critical: true,
              },
        },
      })
    );
  };

  // Add validation for the authorityUri
  useEffect(() => {
    dispatch(
      addValidationStateEntry({
        key: authorityUriErrorKey,
        state: {
          error: authorityUri
            ? null
            : {
                message: translate(
                  'details.types.UserAuthentication.authorities.oidc.authority_uri_empty_error'
                ),
                critical: true,
              },
        },
      })
    );

    return () => {
      dispatch(deleteValidationStateEntry({ key: authorityUriErrorKey }));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, translate]);

  return (
    <Input
      stateMessageCss={css`
        display: none;
      `}
      placeholder={translate(
        'details.types.UserAuthentication.authorities.oidc.authority_uri_placeholder'
      )}
      value={authorityUri}
      onChange={onChange}
      onBlur={() => {
        dispatch(updateValidationStateEntry({ key: authorityUriErrorKey }));
      }}
      showStateIcon={validationState?.updated && validationState?.state.error !== null}
      suffixMessage={validationState?.state.error?.message}
      error={
        validationState?.state.error && validationState?.updated
          ? { message: validationState.state.error.message }
          : null
      }
    />
  );
};
