import styled from 'styled-components';

export const OptionContainer = styled.div<{ isDisabled: boolean }>`
  display: flex;
  flex-direction: row;

  align-items: center;

  svg {
    margin-right: 1rem;
  }

  path {
    ${({ isDisabled, theme }) => isDisabled && `fill:${theme.palette.fonts.disabled}`};
  }
`;

export const ViewAuthorityContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    margin-right: 1rem;
  }
`;
