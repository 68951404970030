import { fork } from 'redux-saga/effects';

import systemsSaga from './business/systems';
import unapprovedSystemsSaga from './business/unapproved-systems';
import enrolmentKeysSaga from './business/enrolment-keys';
import userSaga from './business/user';
import appSaga from './business/app';
import authoritySaga from './business/authority';
import securityEventsSaga from './business/security-events';
import accountSaga from './business/account';
import tagsSaga from './business/tags';
import policiesSaga from './business/policies';
import organisationSaga from './business/organisation';
import paymentSaga from './business/payment';
import dnsSaga from './business/dns';
import trustRequirementsSaga from './business/trust-requirements';
import routerEventsSaga from './business/router';

function* rootSaga() {
  yield fork(routerEventsSaga);
  yield fork(appSaga);
  yield fork(systemsSaga);
  yield fork(unapprovedSystemsSaga);
  yield fork(userSaga);
  yield fork(enrolmentKeysSaga);
  yield fork(authoritySaga);
  yield fork(securityEventsSaga);
  yield fork(accountSaga);
  yield fork(tagsSaga);
  yield fork(policiesSaga);
  yield fork(organisationSaga);
  yield fork(paymentSaga);
  yield fork(dnsSaga);
  yield fork(trustRequirementsSaga);
}

export default rootSaga;
