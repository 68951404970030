// libs
import { css } from 'styled-components';

// components
import { Details, CustomTag } from 'components';

// hooks
import { useSelector } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';

// selectors
import { selectAppDetailsTagNewColour, selectAppDetailsTagNewDescription } from 'redux/selectors';

//constants
import { TAGS_DEFAULT_COLOUR } from 'appConstants/tags';

export const PreviewBlockCreate = () => {
  const { translate } = useTranslations('tags');

  const newColour = useSelector(selectAppDetailsTagNewColour);
  const newDescription = useSelector(selectAppDetailsTagNewDescription);

  return (
    <Details.KeyValue
      contentCss={css`
        align-items: center;
      `}
      css={css`
        min-height: 3rem;
      `}
      title={translate('details.preview')}
    >
      <div
        css={css`
          display: flex;
        `}
      >
        <CustomTag
          disableHoverEffect
          tag={{
            ref: '',
            colour: newColour || TAGS_DEFAULT_COLOUR,
            tag: newDescription || '',
          }}
        />
      </div>
    </Details.KeyValue>
  );
};
