// components
import { CustomButton, InputNumber } from 'components';
import { CustomText } from 'typography/Text';
import {
  CurrentPlanContainer,
  CurrentPlanContent,
  CurrentPlanContentRow,
  CurrentPlanContentRowDescription,
  CurrentPlanContentRowName,
  CurrentPlanContentSection,
} from '../styled';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { css, useTheme } from 'styled-components';

// actions
import { setModalContent } from 'redux/actions/app';

// selectors
import {
  selectCurrentOrganisationCapacity,
  selectCurrentOrganisationBillingCurrencySymbol,
  selectCurrentOrganisationIsOwner,
  selectCurrentOrganisationBillingIsYearly,
  selectCurrentOrganisationPlan,
  selectCurrentOrganisationBillingRate,
  selectCurrentOrganisationUsedCapacity,
} from 'redux/selectors';

// constants
import {
  BUSINESS_PLAN,
  ENTERPRISE_PLAN,
  MAX_BUSINESS_CAPACITY,
  MAX_ENTERPRISE_CAPACITY,
  MIN_BUSINESS_CAPACITY,
  REGEX_NON_DIGIT,
  STARTER_PLAN,
} from 'appConstants';

// utils
import { formatCurrencyAmount } from 'utils';

export const CurrentPlan = () => {
  const dispatch = useDispatch();

  const {
    palette: { fonts },
  } = useTheme();

  const { translate } = useTranslations('settings');

  const capacity = useSelector(selectCurrentOrganisationCapacity);
  const usedCapacity = useSelector(selectCurrentOrganisationUsedCapacity);
  const isYearly = useSelector(selectCurrentOrganisationBillingIsYearly);
  const plan = useSelector(selectCurrentOrganisationPlan);
  const rate = useSelector(selectCurrentOrganisationBillingRate);
  const currencySymbol = useSelector(selectCurrentOrganisationBillingCurrencySymbol);
  const isOwner = useSelector(selectCurrentOrganisationIsOwner);

  const [newCapacity, setNewCapacity] = useState(capacity);

  const formattedRate = rate
    ? formatCurrencyAmount({
        value: isYearly ? rate * 12 * newCapacity : rate * newCapacity,
      })
    : null;

  const setNumberValue = (value: string) => {
    const parsedValue = value.includes('-') ? 0 : value.replace(REGEX_NON_DIGIT, '');
    const resultValue = Math.max(
      Math.min(
        Number(parsedValue),
        plan === ENTERPRISE_PLAN ? MAX_ENTERPRISE_CAPACITY : MAX_BUSINESS_CAPACITY
      )
    );
    setNewCapacity(Number(resultValue));
  };

  const handlePlanUpgrade = () => {
    if ((plan === BUSINESS_PLAN || plan === ENTERPRISE_PLAN) && rate) {
      dispatch(
        setModalContent({
          type: 'update-plan-capacity',
          data: {
            type: plan,
            capacity: newCapacity,
            currencySymbol,
            isCurrentPlan: true,
            isYearly,
            rate: rate,
          },
        })
      );
    }
  };

  return (
    <CurrentPlanContainer>
      <CustomText
        type="sub-heading-1"
        css={css`
          margin-bottom: 1rem;
        `}
        color={fonts.title}
      >
        {translate('plans.current_plan.title')}
      </CustomText>
      <CurrentPlanContent>
        <CurrentPlanContentSection>
          <CurrentPlanContentRow>
            <CurrentPlanContentRowName>
              <CustomText type="body-2" color={fonts.bodyDark}>
                {translate('plans.current_plan.current_plan_field')}
              </CustomText>
            </CurrentPlanContentRowName>
            <CurrentPlanContentRowDescription>
              <CustomText type="body-2" color={fonts.bodyDark}>
                {translate(`plans.${plan.toLowerCase()}`)}
              </CustomText>
            </CurrentPlanContentRowDescription>
          </CurrentPlanContentRow>
          <CurrentPlanContentRow>
            <CurrentPlanContentRowName>
              <CustomText type="body-2" color={fonts.bodyDark}>
                {translate('plans.current_plan.systems_field')}
              </CustomText>
            </CurrentPlanContentRowName>
            <CurrentPlanContentRowDescription>
              <CustomText type="body-2" color={fonts.bodyDark}>
                {`${usedCapacity}/${capacity}`}
              </CustomText>
            </CurrentPlanContentRowDescription>
          </CurrentPlanContentRow>
        </CurrentPlanContentSection>
        <CurrentPlanContentSection>
          <CurrentPlanContentRow>
            <CurrentPlanContentRowName>
              <CustomText type="body-2" color={fonts.bodyDark}>
                {translate('plans.current_plan.payment_type_field')}
              </CustomText>
            </CurrentPlanContentRowName>
            <CurrentPlanContentRowDescription>
              <CustomText type="body-2" color={fonts.bodyDark}>
                {translate(`plans.current_plan.${isYearly ? 'yearly' : 'monthly'}`)}
              </CustomText>
            </CurrentPlanContentRowDescription>
          </CurrentPlanContentRow>
          <CurrentPlanContentRow
            css={css`
              flex-direction: column;
              align-items: flex-start;
            `}
          >
            <CurrentPlanContentRowName
              css={css`
                min-width: unset;
              `}
            >
              <CustomText type="body-2" color={fonts.bodyDark}>
                {translate('plans.current_plan.total_field')}
              </CustomText>
            </CurrentPlanContentRowName>
            <CurrentPlanContentRowDescription>
              <CustomText
                css={css`
                  line-break: anywhere;
                `}
                type="heading-2"
                color={fonts.bodyDark}
              >
                {plan === STARTER_PLAN && translate('plans.current_plan.free')}
                {plan !== STARTER_PLAN && formattedRate && currencySymbol + formattedRate}
              </CustomText>
            </CurrentPlanContentRowDescription>
          </CurrentPlanContentRow>
        </CurrentPlanContentSection>
        <CurrentPlanContentSection>
          {isOwner && rate && (
            <>
              <CurrentPlanContentRow>
                <CustomText type="body-2" color={fonts.bodyDark}>
                  {translate('plans.current_plan.adjust_systems')}
                </CustomText>
              </CurrentPlanContentRow>
              <CurrentPlanContentRow>
                <InputNumber
                  type="text"
                  size="middle"
                  value={newCapacity}
                  containerCss={css`
                    flex: 1;
                    margin-right: 1rem;
                  `}
                  stateMessageCss={css`
                    display: none;
                  `}
                  labelCss={css`
                    display: none;
                  `}
                  setValue={setNumberValue}
                />

                <CustomButton
                  disabled={
                    capacity === newCapacity ||
                    newCapacity < MIN_BUSINESS_CAPACITY ||
                    newCapacity < usedCapacity
                  }
                  kind="primary"
                  onClick={handlePlanUpgrade}
                  size="middle"
                  css={css`
                    align-self: center;
                  `}
                >
                  {translate('plans.upgrade_plan')}
                </CustomButton>
              </CurrentPlanContentRow>
            </>
          )}
        </CurrentPlanContentSection>
      </CurrentPlanContent>
    </CurrentPlanContainer>
  );
};
