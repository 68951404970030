// Components
import { Details } from 'components';

import { StatusView } from './View';
import { StatusEdit } from './Edit';

// hooks
import { useSelector } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';

// selectors
import { selectAppDetailsTabType } from 'redux/selectors';

export const StatusBlock = () => {
  const { translate } = useTranslations('enrolment-keys');

  const type = useSelector(selectAppDetailsTabType);

  if (type === 'enrolment-key-create') return null;

  return (
    <Details.KeyValue height="2.875rem" title={translate('details.state')}>
      {type === 'enrolment-key-view' && <StatusView />}
      {type === 'enrolment-key-edit' && <StatusEdit />}
    </Details.KeyValue>
  );
};
