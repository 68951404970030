// Lib
import { pick } from 'ramda';
import { call, put, select } from 'typed-redux-saga';

// actions
import { setSystem } from 'redux/actions/systems';
import { setDetailsContent, pushNotification } from 'redux/actions/app';

// selectors
import { selectAppDetailsTabData, selectAppDetailsTabDataNew } from 'redux/selectors';

// types
import { INotificationType, IUpdateSystemParams, ISystemModelModified } from 'types';

// Utils
import { checkCreatedSaga } from '../tags/checkCreatedSaga';
import { ApiTypeCaller } from '../../api/utils';
import {
  translator,
  modifySystemDetails,
  demodifySystemDetails,
  diff,
  removeUndefinedKeys,
} from 'utils';

export function* updateSystemSaga() {
  const system: ISystemModelModified = yield select(selectAppDetailsTabData);
  const updatedSystem: ISystemModelModified = yield select(selectAppDetailsTabDataNew);
  const demodifiedSystem = demodifySystemDetails(diff(updatedSystem, system));

  const updatedParams: IUpdateSystemParams = pick(
    ['description', 'isEnabled', 'notes', 'gatewayRoutes', 'autoExpire'],
    demodifiedSystem
  );
  updatedParams.tags = demodifiedSystem.tags
    ? demodifiedSystem.tags.map(({ tag }) => tag)
    : undefined;

  const { success, result } = yield* ApiTypeCaller('updateSystem', {
    updatedParams: removeUndefinedKeys(updatedParams),
    systemId: updatedSystem.systemId,
  });

  if (success && result) {
    const translate = translator('notifications');

    const updatedResult = modifySystemDetails(result);

    yield put(setSystem(result));
    yield put(setDetailsContent({ data: updatedResult, type: 'system-view', dataNew: null }));
    yield put(
      pushNotification({
        showType: INotificationType.Success,
        description: translate('system_updated', {
          systemId: updatedSystem.systemId,
        }),
      })
    );
  }

  yield call(checkCreatedSaga, updatedParams.tags);
}
