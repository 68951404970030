import { ActionReducerMapBuilder, createReducer } from '@reduxjs/toolkit';
import {
  initRequest,
  pushNotification,
  setModalContent,
  setAppLoaded,
  setStripeLoaded,
  setModalIsLoading,
  unlockNotifications,
  cleanNotifications,
  setNetworkConnectionError,
} from '../../actions/app';

import { IAppState, IModalContent, IDetailsTabData, IErrorsType } from 'types';

import { detailsTabValidationReducerSlice } from './detailTab/validation';
import { detailsTabEnrolmentKeysReducerSlice } from './detailTab/enrolmentKeys';
import { detailsTabPoliciesReducerSlice } from './detailTab/policies';
import { detailsTabSystemsReducerSlice } from './detailTab/systems';
import { detailsTabDnsRecordsReducerSlice } from './detailTab/dnsRecords';
import { detailsTabTagsReducerSlice } from './detailTab/tags';
import { detailsTabDefaultReducerSlice } from './detailTab/default';
import { detailsTabErrorReducerSlice } from './errors';
import { detailsTabTrustRequirementsReducerSlice } from './detailTab/trust-requirements';

export const initialDetailsTab: IDetailsTabData = {
  type: null,
  data: null,
  dataNew: null,
  isLoading: false,
};

const initialModal: IModalContent = {
  type: null,
  data: null,
  isLoading: false,
};

export const initialErrors: IErrorsType = {
  form: {},
};

const initialState: IAppState = {
  isLoading: false,
  loaded: false,
  pollingSource: null,
  detailsTab: initialDetailsTab,
  notifications: { data: null, isLocked: false },
  modal: initialModal,
  errors: initialErrors,
  stripeLoaded: false,
  networkConnectionError: null,
};

export type appReducerBuilder = (builder: ActionReducerMapBuilder<typeof initialState>) => void;

export const appReducer = createReducer(initialState, builder => {
  detailsTabErrorReducerSlice(builder);

  detailsTabDefaultReducerSlice(builder);
  detailsTabValidationReducerSlice(builder);
  detailsTabEnrolmentKeysReducerSlice(builder);
  detailsTabTagsReducerSlice(builder);
  detailsTabPoliciesReducerSlice(builder);
  detailsTabSystemsReducerSlice(builder);
  detailsTabDnsRecordsReducerSlice(builder);
  detailsTabTrustRequirementsReducerSlice(builder);

  builder
    .addCase(setAppLoaded, state => {
      state.loaded = true;
    })
    .addCase(setStripeLoaded, state => {
      state.stripeLoaded = true;
    })
    .addCase(initRequest, (state, { payload }) => {
      state.isLoading = payload;
    })
    .addCase(pushNotification, (state, { payload: { isLocked, ...rest } }) => {
      if (state.notifications.data === null) state.notifications.data = [];
      state.notifications.data.push(rest);

      if (isLocked !== undefined) {
        state.notifications.isLocked = isLocked;
      }
    })
    .addCase(cleanNotifications, state => {
      state.notifications.data = null;
      state.notifications.isLocked = false;
    })
    .addCase(unlockNotifications, state => {
      state.notifications.isLocked = false;
    })
    .addCase(setModalContent, (state, { payload: { isLoading = false, ...rest } }) => {
      state.modal = { isLoading, ...rest };
    })
    .addCase(setModalIsLoading, (state, { payload }) => {
      state.modal.isLoading = payload;
    })
    .addCase(setNetworkConnectionError, (state, { payload }) => {
      state.networkConnectionError = payload;
    });
});
