// components
import { EmptyWrapper } from './styled';
import { CustomText } from 'typography/Text';

// hooks
import { css, useTheme } from 'styled-components';
import { useTranslations } from 'hooks/useTranslations';

export const Empty = () => {
  const { palette } = useTheme();

  const { translate } = useTranslations('policies');

  return (
    <EmptyWrapper data-test="policy-gateway-select-empty-state">
      <CustomText type="label-2" color={palette.fonts.bodyLight}>
        {translate('details.gateway_select_empty_state.title')}
      </CustomText>
      <CustomText
        type="hint"
        color={palette.fonts.bodyLight}
        css={css`
          text-align: center;
        `}
      >
        {translate('details.gateway_select_empty_state.description')}
      </CustomText>
    </EmptyWrapper>
  );
};
