// Lib
import { put } from 'typed-redux-saga';

// Actions
import { setDnsSelectedZone, setDnsZonesShouldUpdate } from 'redux/actions/dns';
import { setModalContent, setModalIsLoading, pushNotification } from 'redux/actions/app';

// Types
import { IActionPayload, INotificationType, IUpdateDnsZoneProps } from 'types';

// Utils
import { ApiTypeCaller } from '../../api/utils';
import { translator } from 'utils';
import { DNS_UPDATE_ZONE_ERROR_FIELD } from 'appConstants';

export function* updateZoneSaga({ payload }: IActionPayload<IUpdateDnsZoneProps>) {
  yield put(setModalIsLoading(true));

  const translate = translator('notifications');

  const { result, success } = yield* ApiTypeCaller('updateDnsZone', payload, {
    customErrorField: DNS_UPDATE_ZONE_ERROR_FIELD,
  });

  if (success && result) {
    yield put(setModalContent({ data: null, type: null }));
    yield put(setDnsSelectedZone(result));
    yield put(setDnsZonesShouldUpdate());

    yield put(
      pushNotification({
        showType: INotificationType.Success,
        description: translate('dns_zone_updated', { zoneId: result.id, name: result.name }),
      })
    );
  }
  yield put(setModalIsLoading(false));
}
