// Lib
import { select } from 'typed-redux-saga';
import { put } from 'redux-saga/effects';

// Actions
import { closeDetails } from 'redux/actions/app';

// Selectors
import { selectPathName, selectAppDetailsTabType } from 'redux/selectors';

export function* closeDetailsOnModuleChangeSaga() {
  const pathName = yield* select(selectPathName);
  const detailsTabType = yield* select(selectAppDetailsTabType);

  let shouldCloseDetails = false;

  // New route is not "systems"
  if (
    detailsTabType &&
    ['system-view', 'system-edit'].includes(detailsTabType) &&
    !pathName.includes('/systems')
  )
    shouldCloseDetails = true;

  // New route is not "unapproved-systems"
  if (
    detailsTabType &&
    ['unapproved-system-view', 'unapproved-system-edit'].includes(detailsTabType) &&
    !pathName.includes('/unapproved-systems')
  )
    shouldCloseDetails = true;

  // New route is not "keys"
  if (
    detailsTabType &&
    ['enrolment-key-view', 'enrolment-key-edit', 'enrolment-key-create'].includes(detailsTabType) &&
    !pathName.includes('/keys')
  )
    shouldCloseDetails = true;

  // New route is not "tags"
  if (
    detailsTabType &&
    ['tag-view', 'tag-edit', 'tag-create'].includes(detailsTabType) &&
    !pathName.includes('/tags')
  )
    shouldCloseDetails = true;

  // New route is not "policies"
  if (
    detailsTabType &&
    ['policy-view', 'policy-edit', 'policy-create'].includes(detailsTabType) &&
    !pathName.includes('/policies')
  )
    shouldCloseDetails = true;

  // New route is not "dns"
  if (
    detailsTabType &&
    ['dns-record-view', 'dns-record-edit', 'dns-record-create'].includes(detailsTabType) &&
    !pathName.includes('/dns')
  )
    shouldCloseDetails = true;

  // New route is not "trust-requirements"
  if (
    detailsTabType &&
    ['trust-requirement-view', 'trust-requirement-edit', 'trust-requirement-create'].includes(
      detailsTabType
    ) &&
    !pathName.includes('/trust-requirements')
  )
    shouldCloseDetails = true;

  if (shouldCloseDetails) yield put(closeDetails({ redirect: false }));
}
