// libs
import { memo } from 'react';

// components
import Skeleton from 'react-loading-skeleton';
import { CustomText } from 'typography/Text';
import { SystemStatusBadge } from 'components';

// hooks
import styled, { useTheme } from 'styled-components';
import { useTranslations } from 'hooks/useTranslations';

// types
import { ISystemSummaryModel } from 'types';

interface IConnectedFrom
  extends Required<Pick<ISystemSummaryModel, 'isEnabled' | 'connectedFrom' | 'state'>> {
  isLoading: boolean;
}

const Container = styled.div`
  position: relative;
`;

export const Status = memo(
  ({ isEnabled, isLoading, connectedFrom, state, ...rest }: IConnectedFrom) => {
    const {
      palette: { fonts },
    } = useTheme();
    const { translate } = useTranslations('systems');

    const connectedFromText: Record<ISystemSummaryModel['state'], string | null> = {
      Connected: connectedFrom,
      Disabled: translate('disabled'),
      Disconnected: translate('disconnected'),
    };

    return (
      <>
        {isLoading ? (
          <Skeleton />
        ) : (
          <Container>
            <SystemStatusBadge state={state} />
            <CustomText type="body-2" color={isEnabled ? fonts.title : fonts.disabled} {...rest}>
              {connectedFromText[state]}
            </CustomText>
          </Container>
        )}
      </>
    );
  }
);
