// libs
import { css } from 'styled-components';

// components
import { Input } from 'components';

// hooks
import { useDispatch } from 'react-redux';

// actions
import { updateTrustRequirementDetails } from 'redux/actions/app';

// constants
import {
  USER_AUTHENTICATION_CONFIGURATION_AUTHORITY_URI,
  USER_AUTHENTICATION_JUMPCLOUD_AUTHORITY_URI,
} from 'appConstants';

export const AuthorityUriEdit = () => {
  const dispatch = useDispatch();
  const authorityUri = USER_AUTHENTICATION_JUMPCLOUD_AUTHORITY_URI;

  dispatch(
    updateTrustRequirementDetails({
      settings: {
        configuration: {
          [USER_AUTHENTICATION_CONFIGURATION_AUTHORITY_URI]: authorityUri,
        },
      },
    })
  );

  return (
    <Input
      stateMessageCss={css`
        display: none;
      `}
      value={authorityUri}
      disabled={true}
    />
  );
};
