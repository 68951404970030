// libs
import { css } from 'styled-components';

// components
import { CustomText } from 'typography/Text';
import { Button } from './styled';

// hooks
import { IButtonTypes, useStylesMap } from './useStylesMap';

// types
import { TextTypes } from 'types';
import { ButtonProps } from 'antd/lib/button';
import { CSSProp } from 'styled-components';

type IButtonForms = 'middle' | 'large';

export interface IButtonProps extends Omit<ButtonProps, 'size'> {
  kind?: IButtonTypes;
  size?: IButtonForms;
  width?: string;
  allowDisabledFunctionality?: boolean;
  css?: CSSProp;
}

const buttonSizes: Record<IButtonForms, TextTypes> = {
  middle: 'body-2',
  large: 'body-1',
};

export const CustomButton = ({
  children,
  kind = 'primary',
  size = 'middle',
  allowDisabledFunctionality = true,
  width,
  css: passedCss,
  ...rest
}: IButtonProps) => {
  const styles = useStylesMap(kind);

  return (
    <Button
      disabled={allowDisabledFunctionality && kind === 'disabled'}
      css={passedCss}
      size={size}
      $styles={styles}
      $width={width}
      {...rest}
    >
      <CustomText
        type={buttonSizes[size]}
        color={styles.color}
        css={css`
          display: flex !important;
          align-items: center;
        `}
      >
        {children}
      </CustomText>
    </Button>
  );
};
