// libs
import { CSSProp } from 'styled-components';

// components
import { ExternalLink } from 'components';

// hooks
import { useSelector } from 'react-redux';
import { useEmptyText } from 'hooks/useEmptyText';
import { useTranslations } from 'hooks/useTranslations';
import { useMemo } from 'react';

// selector
import { selectApprovedSystemsIsLoading } from 'redux/selectors';

// constants
import { LINK_TO_QUICK_START_GUIDE, SYSTEMS_MODULE } from 'appConstants';

type Props = {
  searchValue: string;
  css?: CSSProp;
};

export const useSystemsEmptyText = ({ searchValue, css }: Props) => {
  const { translate } = useTranslations(SYSTEMS_MODULE);
  const isLoading = useSelector(selectApprovedSystemsIsLoading);

  const noDataText = useMemo(
    () => ({ title: translate('no_enrolled'), description: translate('start_using') }),
    [translate]
  );

  const noDataFoundText = useMemo(
    () => ({
      title: translate('no_data_found'),
      description: translate('search_in_docs'),
    }),
    [translate]
  );

  const additionalText = useMemo(
    () => (
      <ExternalLink
        textType="body-2"
        text={translate('start_using_link')}
        href={LINK_TO_QUICK_START_GUIDE}
      />
    ),
    [translate]
  );

  const { emptyText } = useEmptyText({
    searchValue,
    noDataText,
    noDataFoundText,
    isLoading,
    additionalText,
    module: SYSTEMS_MODULE,
    css,
  });

  return {
    emptyText,
  };
};
