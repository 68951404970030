import styled from 'styled-components';

export const Container = styled.div`
  height: calc(100vh - ${({ theme }) => theme.layout_sizes.headerHeight});
  width: ${({ theme }) => theme.layout_sizes.dnsZonesWidth};
  // for some reason it resizes if we don't add min-width
  min-width: ${({ theme }) => theme.layout_sizes.dnsZonesWidth};
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palette.background.panel};
`;

export const Header = styled.div`
  height: 5.5rem;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  padding: 2.5rem 1.5rem 1rem 1.5rem;
  border-bottom: 0.0625rem solid ${({ theme }) => theme.palette.background.iconBackground};
`;
