interface IFormatCurrency {
  value: number;
}
export const formatCurrencyAmount = ({ value }: IFormatCurrency) => {
  if (Number.isInteger(value)) {
    return String(value);
  } else {
    return value.toFixed(2);
  }
};
