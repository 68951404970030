// Lib
import { call, put } from 'typed-redux-saga';

// actions
import { setDnsZonesShouldUpdate } from 'redux/actions/dns';
import { setModalContent, setModalIsLoading, pushNotification } from 'redux/actions/app';

// itils
import { orgPush } from '../../utils';

// constants
import { DNS_CREATE_ZONE_ERROR_FIELD, ROUTE_PATHS } from 'appConstants';

// Utils
import { ApiTypeCaller } from '../../api/utils';
import { translator } from 'utils';

// types
import { IActionPayload, ICreateDnsZoneParams, INotificationType } from 'types';
import { getCurrentOrganisationSaga } from '../organisation/getCurrentOrganisationSaga';
export function* createZoneSaga({ payload }: IActionPayload<ICreateDnsZoneParams>) {
  yield put(setModalIsLoading(true));

  const translate = translator('notifications');

  const { result, success } = yield* ApiTypeCaller('createDnsZone', payload, {
    customErrorField: DNS_CREATE_ZONE_ERROR_FIELD,
  });

  if (success && result) {
    yield call(orgPush, `${ROUTE_PATHS.dns}/zones/${result.id}`, { keepSearch: true });
    yield put(setDnsZonesShouldUpdate());
    yield call(getCurrentOrganisationSaga);
    yield put(setModalContent({ type: null, data: null }));
    yield put(
      pushNotification({
        showType: INotificationType.Success,
        description: translate('dns_zone_created', { zoneId: result.id, name: result.name }),
      })
    );
  }
  yield put(setModalIsLoading(false));
}
