import { createAction } from '@reduxjs/toolkit';

import {
  IDeleteTagProps,
  IDeleteTagsParams,
  IExtendTagDetailsParams,
  IExtendTagsModalConnectionsParams,
  IGetTags,
  ISearchKey,
  ISetTagsPayload,
  ITag,
} from 'types';

export const getTags = createAction<IGetTags>('tags/GET_TAGS');
export const setTags = createAction<ISetTagsPayload>('tags/SET_TAGS');
export const setIsLoading = createAction<boolean>('tags/SET_IS_LOADING');
export const createTag = createAction('tags/CREATE_TAG');
export const updateTagsId = createAction('tags/UPDATE_TAGS_REF');
export const updateTagState = createAction<ITag>('tags/UPDATE_TAG_STATE');
export const saveTagDetails = createAction('tags/SAVE_TAG_DETAILS');
export const extendTagDetails = createAction<IExtendTagDetailsParams>(
  'tags/GET_TAG_DETAILS_SYSTEMS'
);
export const extendTagsModalConnections = createAction<IExtendTagsModalConnectionsParams>(
  'tags/EXTEND_TAGS_MODAL_CONNECTIONS'
);
export const deleteTags = createAction<IDeleteTagsParams>('tags/DELETE_TAGS');

export const deleteTag = createAction<IDeleteTagProps>('tags/DELETE_TAG');

export const getTagSearchKeys = createAction('tags/GET_SEARCH_KEYS');

export const setTagSearchKeys = createAction<ISearchKey[]>('tags/SET_SEARCH_KEYS');
