// libs
import { css } from 'styled-components';

// components
import {
  CustomButton,
  Input,
  CustomButtonLink,
  Loader,
  ColumnTitle,
  CustomTable,
} from 'components';
import { CustomMessage } from 'components';
import { IconModalDelete } from 'assets/svg';
import { ModalFooter, ModalHeader, ModalScrollablePart } from 'components/Modal';
import { CustomText } from 'typography/Text';
import { tableStyles } from './styled';
import { SystemsEnrolled } from './Columns/SystemsEnrolled';
import { Description, Status } from './Columns';

// hooks
import { useSelector, useDispatch } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';
import { useSubmitOnEnter } from 'hooks/useSubmitOnEnter';
import { useEffect, useState, useRef, useCallback } from 'react';

// selectors
import { selectModalData, selectModalIsLoading } from 'redux/selectors';

// actions
import { setModalContent } from 'redux/actions/app';
import { deleteEnrolmentKey, deleteEnrolmentKeys } from 'redux/actions/enrolment-keys';

// types
import { IEnrolmentKey, IModalRemoveEnrolmentKey } from 'types';

// constants
import { REMOVE_MODAL_CONFIRMATION_TEXT } from 'appConstants';

export const RemoveEnrolmemntKeyModal = () => {
  const { translate } = useTranslations('enrolment-keys');

  const dispatch = useDispatch();

  const [enabled, setEnabled] = useState(false);
  const [inputText, setInputText] = useState('');

  const modalData = useSelector(selectModalData) as IModalRemoveEnrolmentKey['data'] | null;
  const isLoading = useSelector(selectModalIsLoading);

  const isButtonEnabled = enabled && !isLoading;

  const inputRef = useRef<HTMLInputElement>(null);

  const onClose = useCallback(() => {
    dispatch(setModalContent({ type: null, data: null }));
  }, [dispatch]);

  const onRemove = useCallback(() => {
    if (modalData && modalData.keys.length > 1) {
      dispatch(
        deleteEnrolmentKeys({
          keyIds: modalData?.keys.map(requiremnet => requiremnet.id),
        })
      );
    }
    if (modalData && modalData.keys.length === 1) {
      dispatch(
        deleteEnrolmentKey({
          enrolmentKeyId: String(modalData.keys[0].id),
        })
      );
    }
  }, [dispatch, modalData]);

  const columns = [
    {
      title: <ColumnTitle>{translate('name')}</ColumnTitle>,
      key: 'descriptionModal',
      dataIndex: 'description',
      render: (description: IEnrolmentKey['description']) => (
        <Description description={description} />
      ),
      ellipsis: true,
    },
    {
      title: <ColumnTitle>{translate('state')}</ColumnTitle>,
      key: 'isEnabledModal',
      dataIndex: 'isEnabled',
      render: (isEnabled: IEnrolmentKey['isEnabled']) => <Status isEnabled={isEnabled} />,
      ellipsis: true,
    },
    {
      title: <ColumnTitle>{translate('systems_enrolled')}</ColumnTitle>,
      key: 'systemsEnrolledModal',
      dataIndex: 'enrolledCount',
      render: (enrolledCount: IEnrolmentKey['enrolledCount']) => (
        <SystemsEnrolled enrolledCount={enrolledCount} />
      ),
      ellipsis: true,
    },
  ];

  useSubmitOnEnter({ callback: isButtonEnabled ? onRemove : () => {} });

  useEffect(() => {
    if (inputText === REMOVE_MODAL_CONFIRMATION_TEXT) {
      setEnabled(true);
    } else {
      if (enabled) setEnabled(false);
    }
  }, [inputText, enabled]);

  useEffect(() => {
    inputRef?.current?.focus();
  }, [inputRef]);

  return (
    <div data-test="enrolment-keys-remove-modal">
      <ModalHeader
        title={translate('remove_keys_modal.title', {
          count: modalData?.keys.length || 0,
        })}
        icon={<IconModalDelete width="2.5rem" height="2.5rem" />}
        withCloseButton={true}
        onClose={onClose}
      />
      <ModalScrollablePart>
        <CustomMessage
          type="error"
          message={translate('remove_keys_modal.warning')}
          css={css`
            margin-bottom: 2.25rem;
          `}
        />
        <CustomText
          type="sub-heading-2"
          css={css`
            display: block;
            margin-bottom: 0.5rem;
          `}
        >
          {translate('remove_keys_modal.selected_keys', {
            keysAmount: modalData?.keys.length || 0,
          })}
        </CustomText>
        <CustomTable
          elevated={false}
          rowHeight="2.375rem"
          css={tableStyles}
          rowKey="id"
          columns={columns}
          dataSource={modalData?.keys}
        />
        <Input
          ref={inputRef}
          data-test="enrolment-keys-remove-modal-confirm-input-wrapper"
          type="text"
          name="yescheck"
          value={inputText}
          label={translate('remove_keys_modal.input_label')}
          maxLength={128}
          placeholder={translate('remove_keys_modal.input_placeholder')}
          onChange={e => setInputText(e.target.value.toUpperCase())}
          containerCss={css`
            margin-bottom: 0.75rem;
          `}
        />
      </ModalScrollablePart>
      <ModalFooter>
        <CustomButtonLink size="large" onClick={onClose}>
          {translate('remove_keys_modal.cancel')}
        </CustomButtonLink>
        <CustomButton
          data-test="enrolment-keys-remove-submit"
          width="6.75rem"
          size="large"
          kind={isButtonEnabled ? 'primaryDanger' : 'disabled'}
          onClick={onRemove}
        >
          {isLoading ? <Loader /> : translate('remove_keys_modal.remove')}
        </CustomButton>
      </ModalFooter>
    </div>
  );
};
