// components
import { CustomText } from 'typography/Text';
import { ControllableOSIcon } from 'components';
import { Container } from './styled';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { useSelector } from 'react-redux';
import { css, useTheme } from 'styled-components';

// selectors
import { selectAppDetailsSystemPlatformType, selectAppDetailsSystemType } from 'redux/selectors';

// types
import { OSTypes } from 'types';

export const PurposeBlock = () => {
  const { translate } = useTranslations('systems');

  const {
    palette: { fonts },
  } = useTheme();

  const purpose = useSelector(selectAppDetailsSystemType);
  const platformType = useSelector(selectAppDetailsSystemPlatformType) as OSTypes;

  return (
    <Container>
      <ControllableOSIcon
        platformType={platformType || null}
        state="default"
        size="medium"
        ephemeral={purpose === 'Ephemeral'}
        withPadding={true}
      />
      <CustomText
        css={css`
          margin-left: 0.75rem;
        `}
        type="label-1"
        color={fonts.bodyLight}
      >
        {purpose == 'Ephemeral'
          ? translate('details.purpose.ephemeral')
          : translate('details.purpose.general')}
      </CustomText>
    </Container>
  );
};
