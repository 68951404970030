// libs
import { css } from 'styled-components';

// components
import { Details } from 'components';

// hooks
import { useTranslations } from 'hooks/useTranslations';

interface ILocationsCountriesContent {
  children: React.ReactNode;
  edit?: boolean;
}

export const LocationsCountriesContent = ({
  children,
  edit = false,
}: ILocationsCountriesContent) => {
  const { translate } = useTranslations('trust-requirements');

  return (
    <Details.KeyValue
      css={css`
        min-height: 2.5rem;
        padding-left: unset;
      `}
      nameCss={
        edit
          ? css`
              margin-top: 0.5rem;
            `
          : undefined
      }
      title={translate('details.types.PublicIp.location_countries')}
    >
      {children}
    </Details.KeyValue>
  );
};
