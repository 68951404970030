import styled from 'styled-components';

export const EditContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
`;

export const SubnetsContainer = styled.div`
  margin-top: 1rem;
`;

export const ButtonsContainer = styled.div`
  margin-top: 1.5rem;
  display: flex;
  flex-direction: row;
`;
