import { Layout, Button, Menu as MenuComponent } from 'antd';
import { NavLink } from 'react-router-dom';

const Menu = MenuComponent;
const { Item } = Menu;

import styled, { css, CSSProp } from 'styled-components';

const { Sider, Header } = Layout;

export const LayoutContent = styled.div<{ isDnsPage: boolean }>`
  padding-top: 2.5rem;
  background: none;

  ${({ isDnsPage }) =>
    isDnsPage &&
    `
      padding-top: 0 !important;
  `}
`;

export const StyledLayoutWrapper = styled.div`
  display: flex;
  height: calc(100vh - ${({ theme }) => theme.layout_sizes.headerHeight});

  background-color: ${({ theme }) => theme.palette.background.base};
`;

export const LayoutContentWrapper = styled.div<{
  withDetailsPane: boolean;
  isDnsPage: boolean;
}>`
  // if we don't have this value dns scroll page would be too long
  width: 100%;
  max-width: 70rem;
  min-width: 62.5rem;

  flex-grow: 1;

  margin: 0 auto;
  margin-bottom: 2.5rem;

  // padding-right is at LayoutContentContainer to account for scrollbar
  padding-left: 2.5rem;

  ${props => props.theme.media_queries.s1600} {
    ${({ withDetailsPane }) =>
      !withDetailsPane &&
      css`
        padding-left: 5rem;
      `};
  }

  ${props => props.theme.media_queries.s1440} {
    max-width: ${({ withDetailsPane }) => (withDetailsPane ? '100rem' : '80rem')};
    min-width: 70rem;
  }

  ${({ isDnsPage }) =>
    isDnsPage &&
    css`
      min-width: initial !important;
      max-width: none !important;
      margin: 0 !important;
      padding: 0 !important;
    `}
`;

export const StyledLayout = styled.div<{ withSideMenu?: boolean; withCustomScroll: boolean }>`
  // for layout scrollbar issue #238
  overflow: ${({ withCustomScroll }) => (withCustomScroll ? 'hidden' : 'auto')};

  background-color: ${({ theme }) => theme.palette.background.base};

  ${({ theme, withSideMenu }) =>
    withSideMenu
      ? css`
          width: calc(100vw - ${theme.layout_sizes.siderWidth});
        `
      : css`
          width: 100vw;
        `}
`;

export const LayoutContentContainer = styled.div<{
  withCustomScroll: boolean;
  withSideMenu: boolean;
  withDetailsPane: boolean;
}>`
  // for layout scrollbar issue #238
  display: flex;
  flex-direction: row;

  &:after {
    content: '';

    ${({ withCustomScroll, withSideMenu, withDetailsPane }) =>
      !withCustomScroll && withSideMenu
        ? css`
            min-width: calc(
              2.5rem - (100vw - 100% - ${({ theme }) => theme.layout_sizes.siderWidth})
            );

            ${props => props.theme.media_queries.s1600} {
              ${!withDetailsPane &&
              css`
                min-width: calc(
                  5rem - (100vw - 100% - ${({ theme }) => theme.layout_sizes.siderWidth})
                );
              `};
            }
          `
        : css`
            min-width: calc(2.5rem - (100vw - 100%));
            max-width: 2.5rem;

            ${props => props.theme.media_queries.s1600} {
              ${!withDetailsPane &&
              css`
                min-width: calc(5rem - (100vw - 100%));
              `};
            }
          `}
  }
`;
export const StyledSider = styled(Sider)`
  background-color: ${({ theme }) => theme.palette.background.base};
  height: calc(100vh - ${({ theme }) => theme.layout_sizes.headerHeight}) !important;
  width: ${({ theme }) => theme.layout_sizes.siderWidth};
  overflow-y: auto;
`;

export const StyledMenuContainer = styled.div`
  height: 100%;
  padding: 2rem 0 0 0;
  background-clip: padding-box;
  display: flex;
  flex-direction: column;
  border-right: 0.0625rem solid ${({ theme }) => theme.palette.background.iconBackground};
`;

export const StyledHeader = styled(Header)`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  overflow: auto;
  position: relative;
  z-index: 100;
  background-color: ${({ theme }) => theme.palette.background.panel};
  height: ${props => props.theme.layout_sizes.headerHeight};
  padding: 0 1.25rem 0 1.5rem;
  box-shadow: ${({ theme }) => theme.palette.elevationShadows[200]};
`;

export const StyledLogoContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  & .ant-image {
    display: flex;
    align-items: center;
  }
`;

export const StyledButton = styled(Button)`
  height: 100%;
  padding: 0 1rem 0 1rem;
  border: none;
  border-bottom: none;
  line-height: initial;
  box-shadow: none;
  display: flex;
  align-items: center;
  &:not(:last-child) {
    border-right: 0.0625rem solid ${({ theme }) => theme.palette.fills.strokeMedium};
    .anticon {
      display: block;
    }
  }
`;

interface IAsideMenu {
  shown?: boolean;
  width: string;
}

export const StyledDetails = styled('aside')<IAsideMenu>`
  background: ${({ theme }) => theme.palette.background.panel};
  height: calc(100vh - ${({ theme }) => theme.layout_sizes.headerHeight}) !important;
  width: ${({ width }) => width};
  top: ${({ theme }) => theme.layout_sizes.headerHeight};
  right: 0;
  transform: translateX(${({ shown }) => (shown ? `0%` : '100%')});
  transition: transform ease-in 0.3s;
  z-index: 1;
  position: fixed;
  padding: 0;
  box-shadow: ${({ theme }) => theme.palette.elevationShadows[200]};
`;

export const StyledSideMenuNavLink = styled(NavLink)`
  display: flex;
  width: 100%;
`;

export const StyledSideMenuItem = styled(Item)<{ $customSelectedStateIconCss?: CSSProp }>`
  display: flex;
  width: 100%;
  padding: 0 1.5rem !important;
  transition: none !important;
  margin: 0.5rem 0;
  border: none;
  align-items: center;
  flex-direction: row;

  &:hover {
    background-color: ${({ theme }) => theme.palette.background.iconBackground};

    ${({ $customSelectedStateIconCss, theme: { palette } }) =>
      $customSelectedStateIconCss
        ? $customSelectedStateIconCss
        : css`
            svg {
              path {
                fill: ${palette.icons.white};
              }
              rect {
                fill: ${palette.icons.primary};
              }
            }
          `}
  }

  &:focus-within {
    outline: none;
    background-color: ${({ theme }) => theme.palette.fills.iconHover};
  }
`;

export const StyledTopMenu = styled(Menu)`
  box-shadow: none;
  border: 0.0625rem solid ${props => props.theme.palette.fills.strokeMedium};
  border-radius: 0.25rem;
  padding: 0.5rem 0.25rem;
`;

export const StyledTopMenuItem = styled(Menu.Item)<{ $error?: boolean }>`
  padding-left: 1rem;
  :hover {
    background-color: ${({
      $error,
      theme: {
        palette: { state, background },
      },
    }) => ($error ? state.errorBackground : background.iconBackground)};
  }
`;
