// components
import { CustomText } from 'typography/Text';
import { DnsContent, ListContainer } from './styled';
import { ListElement } from './ListElement';

// hooks
import { useTheme } from 'styled-components';
import { useTranslations } from 'hooks/useTranslations';
import { useSelector } from 'react-redux';

// selectors
import { selectApprovedSystemDns } from 'redux/selectors';

export const DnsBlock = () => {
  const { translate } = useTranslations('systems');

  const {
    palette: { fonts },
  } = useTheme();

  const dns = useSelector(selectApprovedSystemDns);

  return (
    <DnsContent>
      <CustomText type="body-2" color={fonts.bodyLight}>
        {translate('details.dns.info')}
      </CustomText>
      <ListContainer>
        {dns?.map(dnsEntry => (
          <ListElement key={dnsEntry.fqdn} dnsEntry={dnsEntry} />
        ))}
      </ListContainer>
    </DnsContent>
  );
};
