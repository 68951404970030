// libs
import { useCallback, useEffect, useState } from 'react';

// components
import { PageTitle, CustomButton, Filter, BulkActions, TrialBanner } from 'components';
import { CustomText } from 'typography/Text';
import { FilterAndBulkActionsContainer, HeaderRow } from './styled';
import { Table } from './Table/Table';

// hooks
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import { useTranslations } from 'hooks/useTranslations';
import { usePoliciesEmptyText } from './usePoliciesEmptyText';
import { useParams } from 'react-router';

// actions
import {
  disablePolicies,
  enablePolicies,
  getPolicies,
  getPolicySearchKeys,
  setPolicies,
} from 'redux/actions/policies';
import { loadPolicyDetails } from 'redux/actions/app';
import { closeDetails, createPolicySideMenu, setModalContent } from 'redux/actions/app';

// selectors
import {
  selectAppDetailsPolicyId,
  selectAppDetailsTabType,
  selectIsPoliciesModule,
  selectPathQueryIncludeDisabled,
  selectPathQuerySearch,
  selectPathQuerySort,
  selectPoliciesIsLoading,
  selectPoliciesTotal,
  selectPoliciesUpdateId,
  selectPolicySearchKeys,
} from 'redux/selectors';

// utils
import { isDisabled, isEnabled, mapPolicyId } from './helpers';

// constants
import { POLICIES_SORT_OPTIONS } from 'appConstants';
import { initialPoliciesResponse } from 'redux/reducers/policies';

// types
import { IPolicy, PolicyRecordActions } from 'types';

type IPolicyParams = {
  policyId?: string;
};

export const Policies = () => {
  const dispatch = useDispatch();

  const { translate } = useTranslations('policies');

  const total = useSelector(selectPoliciesTotal);
  const updateId = useSelector(selectPoliciesUpdateId);
  const isLoading = useSelector(selectPoliciesIsLoading);
  const detailsType = useSelector(selectAppDetailsTabType);
  const queryParamsSort = useSelector(selectPathQuerySort);
  const queryParamsSearch = useSelector(selectPathQuerySearch);
  const queryParamsIncludeDisabed = useSelector(selectPathQueryIncludeDisabled);
  const isPoliciesModule = useSelector(selectIsPoliciesModule);
  const selectedPolicyId = useSelector(selectAppDetailsPolicyId);
  const searchKeys = useSelector(selectPolicySearchKeys);

  const [page, setPage] = useState(0);
  const [searchValue, setSearchValue] = useState<string>(queryParamsSearch || '');
  const [showDisabled, setShowDisabled] = useState<boolean>(queryParamsIncludeDisabed || false);
  const [bulkSelectedPolicies, setBulkSelectedPolicies] = useState<IPolicy[]>([]);
  const [sortBy, setSortBy] = useState(
    POLICIES_SORT_OPTIONS.find(option => option.value === queryParamsSort) ||
      POLICIES_SORT_OPTIONS[0]
  );
  const {
    palette: { fonts },
  } = useTheme();

  const { policyId } = useParams<IPolicyParams>();

  const createNewPolicy = useCallback(() => dispatch(createPolicySideMenu()), [dispatch]);

  const { emptyText } = usePoliciesEmptyText({ searchValue, createNewPolicy });

  useEffect(() => {
    if (isPoliciesModule && policyId) {
      if (detailsType !== 'policy-view' && detailsType !== 'policy-edit') {
        dispatch(loadPolicyDetails({ policyId }));
        return;
      }
      if (
        (detailsType === 'policy-view' || detailsType === 'policy-edit') &&
        policyId !== String(selectedPolicyId)
      ) {
        dispatch(loadPolicyDetails({ policyId }));
        return;
      }
    }

    if (!policyId && detailsType === 'policy-view') dispatch(closeDetails({}));

    /*
      Temporary solution. Covers back-button behavior that should close details pane in edit-mode
      Causes bug, that prevents details pane from opening via context-menu
    
    if (!policyId && selectedPolicyId) dispatch(closeDetails({}));
    */
  }, [policyId, detailsType, selectedPolicyId, dispatch, isPoliciesModule]);

  const onBulkActionClick = useCallback(
    (type: PolicyRecordActions) => {
      switch (type) {
        case 'enable': {
          const policyIds = bulkSelectedPolicies.filter(isDisabled).map(mapPolicyId);
          if (policyIds.length) dispatch(enablePolicies({ policyIds }));
          setBulkSelectedPolicies([]);
          break;
        }

        case 'disable': {
          const policyIds = bulkSelectedPolicies.filter(isEnabled).map(mapPolicyId);
          if (policyIds.length) dispatch(disablePolicies({ policyIds }));
          setBulkSelectedPolicies([]);
          break;
        }

        case 'remove': {
          if (bulkSelectedPolicies.length) {
            dispatch(setModalContent({ type: 'policies-remove', data: bulkSelectedPolicies }));
          }
          break;
        }
      }
    },
    [bulkSelectedPolicies, setBulkSelectedPolicies, dispatch]
  );

  const fetchData = useCallback(
    () =>
      dispatch(
        getPolicies({
          include_disabled: showDisabled,
          search: searchValue,
          page: page || 0,
          sort: sortBy.value,
        })
      ),
    [dispatch, page, searchValue, showDisabled, sortBy]
  );

  useEffect(() => {
    dispatch(getPolicySearchKeys());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchData());
  }, [dispatch, updateId, fetchData]);

  useEffect(() => {
    return () => {
      dispatch(setPolicies(initialPoliciesResponse));
    };
  }, [dispatch]);

  useEffect(() => {
    setPage(0);
  }, [searchValue]);

  return (
    <>
      <PageTitle title={translate('page_title')} />
      <HeaderRow>
        <CustomText data-test="policies-header" type="heading-1" color={fonts.title}>
          {translate('policies')}
        </CustomText>
        <CustomButton
          kind="primary"
          size="large"
          onClick={createNewPolicy}
          disabled={detailsType === 'policy-create'}
        >
          {translate('create_new_policy')}
        </CustomButton>
      </HeaderRow>
      <TrialBanner />
      <FilterAndBulkActionsContainer>
        {bulkSelectedPolicies.length ? (
          <BulkActions
            data-test="policies-bulk-actions"
            translate={translate}
            selectedItems={bulkSelectedPolicies}
            onActionClick={onBulkActionClick}
            resetSelection={() => setBulkSelectedPolicies([])}
          />
        ) : (
          <Filter
            {...{ total, page, fetchData }}
            {...{ searchValue, setSearchValue }}
            {...{ showDisabled, setShowDisabled }}
            {...{ sortBy, setSortBy, isLoading, searchKeys }}
            sortOptions={POLICIES_SORT_OPTIONS}
          />
        )}
      </FilterAndBulkActionsContainer>
      <Table
        emptyText={emptyText}
        bulkSelectedPolicies={bulkSelectedPolicies}
        setBulkSelectedPolicies={setBulkSelectedPolicies}
        isLoading={isLoading}
        page={page}
        setPage={setPage}
        selectedPolicyId={policyId}
        total={total}
      />
    </>
  );
};
