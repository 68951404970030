// libs
import { put, all } from 'typed-redux-saga';

// actions
import { setDetailsContent } from 'redux/actions/app';

// Types
import {
  IActionPayload,
  IDetailsPolicyEdit,
  IDetailsPolicyView,
  ILoadPoliciesDetails,
} from 'types';

// utils
import { ApiTypeCaller } from '../../api/utils';
import { getPolicyAclsValidationState } from 'utils';
import { modifyPolicyDetails } from 'utils/policies';

// constants
import { POLICY_INITIAL_VALIDATION_STATE } from 'appConstants';

export function* loadPolicyDetailsSaga({
  payload: { policyId, mode = 'view', scrollToAutoExpire },
}: IActionPayload<ILoadPoliciesDetails>) {
  const { result, success } = yield* ApiTypeCaller('getPolicy', { policyId });

  if (success && result) {
    const modifiedPolicy = modifyPolicyDetails(result);

    const tabData: IDetailsPolicyView | IDetailsPolicyEdit =
      mode === 'edit'
        ? {
            type: 'policy-edit',
            data: modifiedPolicy,
            dataNew: modifiedPolicy,
            scrollToAutoExpire,
            validationState: {
              ...POLICY_INITIAL_VALIDATION_STATE,
              ...getPolicyAclsValidationState({ acls: modifiedPolicy.acls }),
            },
            isLoading: false,
          }
        : { type: 'policy-view', data: modifiedPolicy, dataNew: null, isLoading: false };

    yield all([put(setDetailsContent(tabData))]);
  }
}
